import './businessDivisionOPUChart.scss';

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { OptionChartSPandBU, OptionNormal } from '../../share';

const BusinessDivisionOPUChart = ({ data, categories }) => {
  const [series, setSeries] = useState([]);
  let option = {
    ...OptionChartSPandBU,
    xaxis: {
      categories: categories,
      labels: OptionNormal.labels,
    },
    legend: {
      ...OptionNormal.legendSPandBU,
      colors: ['#344563'],
      offsetX: 70,
      offsetY: 15,
      markers: {
        width: 17,
        height: 17,
        radius: 5,
        fillColors: ['#90A0CD', '#7AC8C5', '#8BC5F5', '#BAC7D5', '#BB9FCC'],
        offsetX: -5,
        offsetY: 0,
      },
      itemMargin: {
        vertical: 10,
      },
    },
  };
  const [options] = React.useState(option);
  useEffect(() => {
    setSeries(data);
  }, [data]);
  return (
    <div id="businessChart">
      <div>
        {' '}
        <Chart options={options} series={series} type="bar" height={386} width={1201} />
      </div>
    </div>
  );
};
export default BusinessDivisionOPUChart;
