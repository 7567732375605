import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import CustomAntModal from '../../../../../../../components/CustomAntModal/CustomAntModal';
import { SET_MATCHING_CRITERIA_STATUS } from '../../../../../../../utils/constants';
import MatchingCriteriaSummary from './CriteriaSummary/MatchingCriteriaSummary';
import styles from './form-popout.module.scss';
import FormPopout from './FormPopout';

const PopoutSetName = (props) => {
  const { popoutSetName, setPopoutSetName, currentSetDetail } = props;

  const renderSetMatchingStatus = useMemo(() => {
    let tempColor = '#677C91';
    switch (currentSetDetail.set_matching_criteria[0]?.status) {
      case SET_MATCHING_CRITERIA_STATUS.COMPLETED:
        tempColor = '#0B882E';
        break;
      case SET_MATCHING_CRITERIA_STATUS.FAILED:
        tempColor = '#DA2228';
        break;
      case SET_MATCHING_CRITERIA_STATUS.RUNNING:
        tempColor = '#3685D3';
        break;
      case SET_MATCHING_CRITERIA_STATUS.QUEUE:
        tempColor = '#edab3d';
        break;
      default:
        break;
    }
    return (
      <span style={{ color: `${tempColor}` }}>
        {currentSetDetail.set_matching_criteria[0]?.status}
      </span>
    );
  }, [currentSetDetail]);

  const getLastUpdate = useMemo(() => {
    return (
      (currentSetDetail.set_matching_criteria[0]?.end_date &&
        moment(currentSetDetail.set_matching_criteria[0]?.end_date).format('DD MMMM YYYY')) ||
      (currentSetDetail.set_matching_criteria[0]?.start_date &&
        moment(currentSetDetail.set_matching_criteria[0]?.start_date).format('DD MMMM YYYY')) ||
      '--'
    );
  }, [currentSetDetail]);

  return (
    <div>
      <CustomAntModal
        open={popoutSetName}
        setOpen={setPopoutSetName}
        width={1393}
        destroyOnClose
        hideDefaultBtn={true}
        styles={{
          containerPaddingTopBottom: 50,
          containerPaddingLeftRight: 50,
          closePosition: 50,
        }}
      >
        <div className={styles.setContainer}>
          <div className={styles.setHeader}>
            <div className={styles.title}>
              {currentSetDetail?.position_name}{' '}
              {currentSetDetail?.position_code && `(${currentSetDetail?.position_code})`}
            </div>
            {!isEmpty(currentSetDetail.set_matching_criteria) && (
              <>
                <div className={styles.mainTitle}>
                  <h3>
                    <span>Set {currentSetDetail.set_matching_criteria[0]?.set_number}:</span>{' '}
                    {currentSetDetail.set_matching_criteria[0]?.set_name}
                  </h3>
                  <div className={styles.setStatus}>
                    <span>Last Update : {getLastUpdate}</span>
                    <span>Status: {renderSetMatchingStatus}</span>
                  </div>
                </div>
                <div style={{}}>
                  {currentSetDetail.set_matching_criteria[0]?.selected_criteria.map((item) => (
                    <FormPopout
                      key={item.criteria}
                      data={item}
                      currentSetMatching={currentSetDetail.set_matching_criteria}
                    />
                  ))}
                  <MatchingCriteriaSummary currentSetDetail={currentSetDetail} />
                </div>
              </>
            )}
          </div>
        </div>
      </CustomAntModal>
    </div>
  );
};

export default PopoutSetName;
