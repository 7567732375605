import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { BasicButton } from '../../../../../assets/common';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { CommonFilterManaging } from '../../../../../components/CommonFilterManaging';
import styles from './menu-bar.module.scss';

export default function MenuBar(props) {
  const { setIsEdit, isEdit, setQuery, query, showFilter, setShowFilter } = props;

  const [searchInput, setSearchInput] = useState('');

  const handleSearch = () => {
    setQuery((prev) => {
      return {
        ...prev,
        keyword: searchInput,
        page: 1,
      };
    });
  };

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <input
                data-testid="search-input"
                placeholder="Search Staff Name or Staff ID"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              />
            </Col>
            <Col>
              <BasicButton mode="teal" on onKeyDown={() => {}} onClick={() => handleSearch()}>
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              {!isEdit ? (
                <div className={styles.icons}>
                  <img
                    src={showFilter ? finder_filter_enable : finder_filter_disable}
                    alt="Filter"
                    on
                    onKeyDown={() => {}}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                </div>
              ) : (
                <BasicButton mode="cancel" on onKeyDown={() => {}} onClick={() => setIsEdit(null)}>
                  Back
                </BasicButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <Row style={{ marginTop: 22 }}>
          <Col>
            <Row gutter={16}>
              <Col>
                <CommonFilterManaging
                  selectedOptions={query.topTalentStatus}
                  setSelectedOptions={(arr) => {
                    setQuery((prev) => {
                      return {
                        ...prev,
                        topTalentStatus: arr,
                        page: 1,
                      };
                    });
                  }}
                  setIsSearch={() => {}}
                  isMinWidth={true}
                  allOptions={[
                    {
                      label: 'Business Talent',
                      value: 'business_talent',
                    },
                    {
                      label: 'Corporate Talent',
                      value: 'corporate_talent',
                    },
                    {
                      label: 'Other Talent',
                      value: 'other_talent',
                    },
                    {
                      label: 'All',
                      value: 'all',
                    },
                  ]}
                  placeholder="Top Talent Status"
                />
              </Col>
              <Col>
                <CommonFilterManaging
                  selectedOptions={query.successor}
                  setSelectedOptions={(arr) => {
                    setQuery((prev) => {
                      return {
                        ...prev,
                        successor: arr,
                        page: 1,
                      };
                    });
                  }}
                  setIsSearch={() => {}}
                  isMinWidth={true}
                  allOptions={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                    {
                      label: 'All',
                      value: 'all',
                    },
                  ]}
                  placeholder="Successor"
                />
              </Col>
              <Col>
                <CommonFilterManaging
                  selectedOptions={query.missingData}
                  setSelectedOptions={(arr) => {
                    setQuery((prev) => {
                      return {
                        ...prev,
                        missingData: arr,
                        page: 1,
                      };
                    });
                  }}
                  setIsSearch={() => {}}
                  isMinWidth={true}
                  allOptions={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                    {
                      label: 'All',
                      value: 'all',
                    },
                  ]}
                  placeholder="Missing Data"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}
