import { isEmpty, slice } from 'lodash';
import React from 'react';
import { handleShowPosition } from '../../utils/helper';
import styles from './search-position-box.module.scss';

const ListItem = ({ item, onClick, index }) => {
  return (
    <li
      data-testid={`${index}-pos`}
      className={styles.search_item}
      onKeyDown={() => {}}
      onClick={onClick}
    >
      <div className={styles.individual}>
        <div className={styles.name}>
          <p>
            {item.position_name}
            {item.business_unit && ', '}
            {item.business_unit}
            {item.position_code && '('}
            {item.position_code}
            {item.position_code && ')'}
          </p>
        </div>
      </div>
    </li>
  );
};

const SearchListOption = (props) => {
  const { data, loading, debounceSearchQuery, setSearchTerm } = props;

  const handleSelectOption = async (item) => {
    let result = await handleShowPosition(item);
    setSearchTerm({ value: item, type: 'submit', showTerm: result });
  };

  return (
    <>
      {loading && isEmpty(data) && (
        <div className={styles.search_list} data-testid="loading">
          <div data-testid="loading-pos" className={styles.dot_flashing}></div>
        </div>
      )}

      {!loading && !isEmpty(data) && (
        <ul className={styles.search_list} data-testid="data-is-true">
          {slice(data, 0, 5).map((item, index) => (
            <ListItem
              key={index}
              item={item}
              index={index}
              onKeyDown={() => {}}
              onClick={() => handleSelectOption(item)}
            />
          ))}
        </ul>
      )}

      {!loading &&
        isEmpty(data) &&
        debounceSearchQuery.type === 'search' &&
        !isEmpty(debounceSearchQuery.value) && (
          <div className={styles.search_list} data-testid="data-is-false">
            <p className={styles.search_notFound}>Search not found ...</p>
          </div>
        )}
    </>
  );
};

export default SearchListOption;
