import { isEmpty, size } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { fetchKeystrengthsDevDetails } from '../../services/talentProfiles';
import CommonLoading from '../CommonLoading/CommonLoading';
import {
  AiTable,
  Aih3,
  Aili,
  Aiul,
  Content,
  LeftSide,
  RightSide,
  SubTitle,
  WrapContent,
} from './Styled';
import { TEXT_LABEL, TYPE_KEY_STR_DEV } from './constants';

const TalentSketch = ({ id, roleUser }) => {
  const [listKeyDevOption, setListKeyDevOption] = useState([]);
  const [listKeyDev, setListKeyDev] = useState([]);
  const [selectEle, setSelectEle] = useState({
    typeKeys: '',
    subTypes: '',
    value: '',
  });
  const page = 1;
  const limit = 8;
  const [loading, setLoading] = useState(false);

  const getKeystrengthsDevDetails = async () => {
    setLoading(true);
    const pagination = {
      limit,
      page,
    };
    try {
      let response = await fetchKeystrengthsDevDetails({ pagination, roleUser, id });

      if (size(response.data.result) > 0) {
        let arrayKeyDev = [];
        if (
          Object?.keys(response.data.result?.keyStrengthsAll)?.length === 0 &&
          Object?.keys(response.data.result?.keyDevelopmentAll)?.length === 0
        ) {
          setLoading(false);
          return;
        }
        for (let i in response.data.result) {
          switch (i) {
            case TYPE_KEY_STR_DEV.KEY_STRENGTH_DETAIL:
              arrayKeyDev[0] = response.data.result[i].filter((item) => item !== null);
              break;
            case TYPE_KEY_STR_DEV.KEY_DEV_DETAIL:
              arrayKeyDev[1] = response.data.result[i].filter(
                (item) => item.feedback_display !== ''
              );
              break;
            default:
              break;
          }
        }
        let newArrayKeyDev0 = [];
        for (const obj1 of arrayKeyDev[0]) {
          if (isEmpty(newArrayKeyDev0)) {
            newArrayKeyDev0.push(obj1);
            continue;
          }
          if (newArrayKeyDev0.find((f) => f.feedback_display === obj1.feedback_display)) {
            newArrayKeyDev0 = newArrayKeyDev0.map((item) => {
              if (item.feedback_display === obj1.feedback_display) {
                return {
                  ...item,
                  ...obj1,
                  edge: item?.edgeCheck ? item?.edge : obj1?.edge,
                  attr27: obj1?.attr27Check ? obj1?.attr27 : item?.attr27,
                };
              }
              return item;
            });
          } else {
            newArrayKeyDev0.push(obj1);
          }
        }
        arrayKeyDev[0] = newArrayKeyDev0;

        let newArrayKeyDev1 = [];
        for (const obj1 of arrayKeyDev[1]) {
          if (isEmpty(newArrayKeyDev1)) {
            newArrayKeyDev1.push(obj1);
            continue;
          }
          if (newArrayKeyDev1.find((f) => f.feedback_display === obj1.feedback_display)) {
            newArrayKeyDev1 = newArrayKeyDev1.map((item) => {
              if (item.feedback_display === obj1.feedback_display) {
                return {
                  ...item,
                  ...obj1,
                  edge: item?.edgeCheck ? item?.edge : obj1?.edge,
                  attr27: obj1?.attr27Check ? obj1?.attr27 : item?.attr27,
                };
              }
              return item;
            });
          } else {
            newArrayKeyDev1.push(obj1);
          }
        }
        arrayKeyDev[1] = newArrayKeyDev1;

        if (
          Object?.keys(response.data.result.keyStrengths)?.length === 0 &&
          Object?.keys(response.data.result.DevArea)?.length === 0
        )
          return;
        let arrayKeyDev2 = [];
        for (let i in response.data.result) {
          switch (i) {
            case TYPE_KEY_STR_DEV.KEY_STENGTH_GENERAL:
              arrayKeyDev2[0] = response.data.result[i];
              break;
            case TYPE_KEY_STR_DEV.KEY_DEV_GENERAL:
              arrayKeyDev2[1] = response.data.result[i];
              break;
            default:
              break;
          }
        }
        setListKeyDevOption(arrayKeyDev2);
        if (arrayKeyDev.length > 0 && (arrayKeyDev[0].length > 0 || arrayKeyDev[1].length > 0)) {
          await handleCheckLengthOfArray(arrayKeyDev);
          let listStrengthDev = await handleConvertData(arrayKeyDev);
          setListKeyDev(listStrengthDev);
        } else {
          setListKeyDev([]);
        }
      } else {
        setListKeyDevOption([]);
        setListKeyDev([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCheckLengthOfArray = async (arrayKeyDev) => {
    let differenceNumber = Number(arrayKeyDev[0].length - arrayKeyDev[1].length);
    if (differenceNumber > 0) {
      for (let i = 0; i < Math.abs(differenceNumber); i++) {
        arrayKeyDev[1].push({ feedback_display: '' });
      }
    } else if (differenceNumber < 0) {
      for (let i = 0; i < Math.abs(differenceNumber); i++) {
        arrayKeyDev[0].push({ feedback_display: '' });
      }
    }
    return arrayKeyDev;
  };

  const handleConvertData = async (arrayKeyDev) => {
    let arrayAfterConvert = [];
    for (let i = 0; i < arrayKeyDev[0].length; i++) {
      let newObject = {
        strength: arrayKeyDev[0][i],
        development: arrayKeyDev[1][i],
      };
      arrayAfterConvert.push(newObject);
    }
    return arrayAfterConvert;
  };
  const handleDataKeyDevOption = (keyDevOption) => {
    if (!keyDevOption) return;
    return keyDevOption.map((ele, index) => {
      if (index === 0) {
        return (
          <LeftSide key={index}>
            <Aih3>{TEXT_LABEL.KEY_STRENGTH}</Aih3>
            <Aiul>
              {ele.map((subele, subidx) => {
                return renderKeyDevOptionItem(subele, subidx);
              })}
            </Aiul>
          </LeftSide>
        );
      } else if (index === 1) {
        return (
          <RightSide key={index}>
            <Aih3>{TEXT_LABEL.DEVELOPMENT_AREA}</Aih3>
            <Aiul>
              {ele.map((subele, subIdx) => {
                return renderKeyDevOptionItem(subele, subIdx);
              })}
            </Aiul>
          </RightSide>
        );
      }
      return null;
    });
  };
  const renderKeyDevOptionItem = (ele, eleIdx) => {
    return (
      <Aili
        onClick={() => handleSelectElement(ele)}
        isSelected={selectEle.value === ele.edge || selectEle.value === ele.attr27}
        key={eleIdx}
      >
        {ele.edge || ele.attr27 || ele.extracted_keyword}
      </Aili>
    );
  };
  const handleSelectElement = (item) => {
    if (item.attr27Check && item.type === 'keyStrengthsAttr27') {
      setSelectEle((preState) => ({
        ...preState,
        value: item.attr27.toString(),
        typeKeys: 'Strength',
        subTypes: 'attr27',
      }));
    }
    if (item.edgeCheck && item.type === 'keyStrengthsEdge') {
      setSelectEle((preState) => ({
        ...preState,
        value: item.edge.toString(),
        typeKeys: 'Strength',
        subTypes: 'edge',
      }));
    }
    if (item.edgeCheck && item.type === 'keyDevelopmentEdge') {
      setSelectEle((preState) => ({
        ...preState,
        typeKeys: 'Development',
        subTypes: 'edge',
        value: item.edge.toString(),
      }));
    }
    if (item.attr27Check && item.type === 'keyDevelopmentAttr27') {
      setSelectEle((preState) => ({
        ...preState,
        subTypes: 'attr27',
        value: item.attr27.toString(),
        typeKeys: 'Development',
      }));
    }
  };

  useEffect(() => {
    if (!roleUser.roleActive.roleId) return;
    setListKeyDev([]);
    setListKeyDevOption([]);
    getKeystrengthsDevDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, id]);

  const handleCheck = (item) => {
    const result = true;
    if (
      item.edgeCheck &&
      selectEle.subTypes === 'edge' &&
      item.feedback_dimension === selectEle.typeKeys &&
      selectEle.value === item.edge
    ) {
      return result;
    }
    if (
      item.attr27Check &&
      selectEle.subTypes === 'attr27' &&
      item.feedback_dimension === selectEle.typeKeys &&
      selectEle.value === item.attr27
    ) {
      return result;
    }
    return !result;
  };

  return (
    <WrapContent data-testid="talent-sketch">
      <SubTitle>{handleDataKeyDevOption(listKeyDevOption)}</SubTitle>
      <AiTable>
        <table>
          <thead>
            <tr>
              <th>
                <h3>{TEXT_LABEL.KEY_STRENGTH}</h3>
              </th>
              <th>
                <h3>{TEXT_LABEL.DEVELOPMENT_AREA}</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              listKeyDev.length > 0 &&
              listKeyDev.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Content isSelected={handleCheck(item.strength)}>
                        <p className="mb-2">{item?.strength?.feedback_display || ''}</p>
                        <p className="source">
                          {item?.strength?.feedback_display &&
                            `${
                              item.strength?.document_source
                                ? `${item.strength.document_source}, `
                                : ''
                            } 
                             ${item.strength.doc_year} (${item.strength.source})`}
                        </p>
                      </Content>
                    </td>
                    <td>
                      <Content isSelected={handleCheck(item.development)}>
                        <p className="mb-2">{item?.development?.feedback_display || ''}</p>
                        <p className="source">
                          {item?.development?.feedback_display &&
                            `${
                              item.development?.document_source
                                ? `${item.development.document_source}, `
                                : ''
                            } 
                             ${item.development.doc_year} (${item.development.source})`}
                        </p>
                      </Content>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {loading && <CommonLoading />}
      </AiTable>
    </WrapContent>
  );
};
export default memo(TalentSketch);
