import { Select } from 'antd';
import React from 'react';

import { dropdown_icon } from '../../../../assets/img';
import { ASSESSMENT_FILTER_TYPES, ASSESSMENT_TYPES } from '../ExternalAssessments/constant_assessments';
import styles from './external-assessments-fiter.module.scss';

const ExternalAssessmentsFilter = (props) => {
  const { dataOptions, setQuery, filterType, defaultValue, ...otherProps } = props;

  const onFilterChange = (value, type) => {
    if (type === ASSESSMENT_FILTER_TYPES.TYPE) {
      setQuery((prev) => ({
        ...prev,
        filterType: {
          value,
          label: ASSESSMENT_TYPES.filter((option) => option.value === value)[0].label,
        },
      }));
    } else {
      const tempDataOption = dataOptions.filter((option) => option.value === value)[0];
      setQuery((prev) => ({
        ...prev,
        filterDate: {
          value,
          label: tempDataOption.label,
        },
        filterStatus: tempDataOption.status,
      }));
      if (otherProps?.setAssessmentStatus) {
        otherProps.setAssessmentStatus(tempDataOption.status);
      }
    }
  };

  return (
    <div className={styles.selectFieldItem}>
      <div className={styles.selectFieldItemLabel}>{filterType}</div>
      <Select
        suffixIcon={<img src={dropdown_icon} alt="" />}
        className={styles.customSelect}
        value={defaultValue ?? 'N/A'}
        style={{
          minWidth: 220,
        }}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={dataOptions}
        onChange={(value) => onFilterChange(value, filterType)}
      />
    </div>
  );
};

export default ExternalAssessmentsFilter;
