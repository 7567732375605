import { combineReducers } from '@reduxjs/toolkit';
import agendaReducer from './agendaSlice';
import meetingDetailReducer from './meetingDetailSlice';
import remarksReducer from './remarksSlice';
import stepperReducer from './stepperSlice';

const reducer = combineReducers({
  preMeeting: meetingDetailReducer,
  agenda: agendaReducer,
  remarks: remarksReducer,
  stepperDetail: stepperReducer,
});

export default reducer;
