import { Spin } from 'antd';
import React from 'react';

import TalentFinderFilter from './TalentFinderFilter';

const ListTalentFinderColumn = ({
  filterOptions,
  loadingFilter,
  setFilterFinderColumns,
  applyFinderFilterColumn,
  deleteColumn,
  isDeleteFinderColumn,
  setIsDeleteFinderColumn,
}) => {
  return (
    <>
      <Spin spinning={loadingFilter} size="large">
        <TalentFinderFilter
          filterOptions={filterOptions}
          setFilterFinderData={setFilterFinderColumns}
          applyFinderFilterColumn={applyFinderFilterColumn}
          deleteColumn={deleteColumn}
          isDeleteFinderColumn={isDeleteFinderColumn}
          setIsDeleteFinderColumn={setIsDeleteFinderColumn}
        />
      </Spin>
    </>
  );
};

export default ListTalentFinderColumn;
