import { map } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { avatar } from '../../../../../assets/img';
import { useProfilesSearch } from '../../../../../hooks/useTalentSearch';
import { decodeBase64ToImage } from '../../../../../utils/helper';
import { makeSelectStyle } from '../FieldSelect/styled';
import { Avatar, Individual, Name, SearchItem } from './styled';

const CustomOption = ({ data, innerProps, innerRef }) => {
  return (
    <SearchItem ref={innerRef} {...innerProps}>
      <Avatar>
        <img src={decodeBase64ToImage(data.value && data.value.image) || avatar} alt="avatar" />
      </Avatar>
      <Individual>
        <Name>{data.value.birthName}</Name>
        <div>{data.value.opu}</div>
        <div>{data.value.sg}</div>
      </Individual>
    </SearchItem>
  );
};

export default React.memo(
  ({ field: { value, name }, form: { setFieldValue, getFieldMeta }, ...props }) => {
    const { loading, searchProfiles } = useProfilesSearch({
      mapData: (profiles) =>
        map(profiles, ({ birthName, opu, sg, image, staffId }) => ({
          label: birthName || '-',
          value: { birthName, opu, sg, image, staffId },
        })),
    });

    const timerRef = useRef(null);
    const rejectRef = useRef(null);

    const cancelSearchProfilesRequest = useCallback(() => {
      // Clear previous timeout
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Cancel previous promise
      if (rejectRef.current) {
        rejectRef.current({ canceled: true });
      }
    }, []);

    const loadOptions = useCallback(
      (searchInput) => {
        return new Promise((resolve, reject) => {
          cancelSearchProfilesRequest();

          rejectRef.current = reject;
          timerRef.current = setTimeout(() => {
            searchProfiles(searchInput)
              .then(resolve)
              .catch(reject)
              .finally(() => {
                rejectRef.current = null;
              });
          }, 500);
        });
      },
      [searchProfiles, cancelSearchProfilesRequest]
    );

    // Cancel request When unmount component
    useEffect(() => {
      return () => {
        cancelSearchProfilesRequest();
      };
    }, [cancelSearchProfilesRequest]);

    const handleChange = useCallback(
      (option) => {
        setFieldValue(name, option);
      },
      [setFieldValue, name]
    );
    const meta = getFieldMeta(name);

    return (
      <AsyncSelect
        {...props}
        isLoading={loading}
        cacheOptions
        isSearchable
        isClearable
        value={value}
        styles={makeSelectStyle({ meta })}
        menuPlacement="auto"
        maxMenuHeight={180}
        loadOptions={loadOptions}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => <></>,
          DropdownIndicator: () => <></>,
          Option: CustomOption,
        }}
      />
    );
  }
);
