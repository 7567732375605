export const TOP_TALENT_CATEGORY_TABLE_ROW_LIMIT = 15;
export const TOP_TALENT_CATEGORY_MESSAGE = {
  SEARCH_NOT_FOUND: 'No matching category found.',
  DELETE_SUCCESS: 'Top Talent Status Category successfully deleted',
  EDIT_SUCCESS: 'Top Talent Status Category successfully edited',
};

export const POSITION_CATEGORY_TABLE_ROW_LIMIT = 15;
export const POSITION_CATEGORY_MESSAGE = {
  SEARCH_NOT_FOUND: 'No matching category found.',
};
