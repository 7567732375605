import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BasicButton } from '../../../../../assets/common';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import SPManagementSelectFilter from '../../../../../components/SPManagementSelectFilter/SPManagementSelectFilter';
import { adminApi } from '../../../../../services/admin';
import styles from './menu-bar.module.scss';

const activeSP = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
];

export default function MenuBar(props) {
  const {
    showFilter,
    setShowFilter,
    positionLabelSelected,
    setPositionLabelSelected,
    businessSelected,
    setBusinessSelected,
    activeSPSelected,
    setActiveSPSelected,
    setKeyword,
    fetchData,
    keyword,
  } = props;

  const [businessOptions, setBusinessOptions] = useState([]);
  const [positionLabelOptions, setPositionLabelOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();

  const handleAddNew = () => {
    history.push(`/admin/sp-management/approved-succession-planning/create`);
  };

  const fetchFilterOptions = async () => {
    try {
      const res = await adminApi.getApprovedSPFilter();
      if (res.status === 200) {
        setBusinessOptions(res.data.result.business);
        setPositionLabelOptions(res.data.result.position_labels);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <input
                data-testid="search-input"
                placeholder="Search Plan Name or Position Code"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton
                mode="teal"
                onKeyDown={() => {}}
                onClick={() => {
                  keyword === searchInput ? fetchData() : setKeyword(searchInput);
                }}
              >
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleAddNew}>
                Add New
              </BasicButton>
            </Col>
            <Col>
              <div className={styles.icons}>
                <img
                  src={showFilter ? finder_filter_enable : finder_filter_disable}
                  alt="Filter"
                  onKeyDown={() => {}}
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <Row style={{ marginTop: 22 }}>
          <Col>
            <Row gutter={16}>
              <Col>
                <SPManagementSelectFilter
                  selectedOptions={businessSelected}
                  setSelectedOptions={setBusinessSelected}
                  allOptions={[
                    ...businessOptions,
                    {
                      label: 'All',
                      value: 'all',
                    },
                  ]}
                  placeholder="Business"
                />
              </Col>
              <Col>
                <SPManagementSelectFilter
                  selectedOptions={positionLabelSelected}
                  setSelectedOptions={setPositionLabelSelected}
                  allOptions={[
                    ...positionLabelOptions,
                    {
                      label: 'All',
                      value: 'all',
                    },
                  ]}
                  placeholder="Position Label"
                />
              </Col>
              <Col>
                <SPManagementSelectFilter
                  selectedOptions={activeSPSelected}
                  setSelectedOptions={setActiveSPSelected}
                  allOptions={activeSP}
                  placeholder="Active SP"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}
