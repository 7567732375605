import { Empty, Select } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';

import { BasicAutoComplete } from '../../assets/common';
import { chevron_down_arrow } from '../../assets/img';
import { CONTENT_FUNCTION_TYPE } from '../../utils/constants';
import styles from './search-function-modal.module.scss';

const { Option } = Select;

const SearchFunctionModal = (props) => {
  const {
    record,
    searchParams,
    setSearchParams,
    functions,
    inputValue,
    setInputValue,
    inputData,
    index,
    disabled,
    searchIndex,
    setSearchIndex,
  } = props;
  const selectRef = useRef(null);

  const handleSelectedRowItem = useCallback(
    (item) => {
      let tempValue = '';
      switch (record?.function) {
        case CONTENT_FUNCTION_TYPE.FUNCTION:
        case CONTENT_FUNCTION_TYPE.EXCEPTION_FUNCTION:
          tempValue = item.name;
          break;
        case CONTENT_FUNCTION_TYPE.SUB_JOB_FAMILY:
        case CONTENT_FUNCTION_TYPE.EXCEPTION_SUB_JOB_FAMILY:
          tempValue = item.sub_job_family;
          break;
        case CONTENT_FUNCTION_TYPE.JOB_FAMILY:
          tempValue = item.job_family;
          break;
        default:
          break;
      }
      inputData(index, 'content', tempValue, setInputValue);
    },
    [index, inputData, record, setInputValue]
  );

  const columnTypeActive = useMemo(() => {
    let columnType = '';
    switch (record?.function) {
      case CONTENT_FUNCTION_TYPE.FUNCTION:
      case CONTENT_FUNCTION_TYPE.EXCEPTION_FUNCTION:
        columnType = CONTENT_FUNCTION_TYPE.FUNCTION;
        break;
      case CONTENT_FUNCTION_TYPE.SUB_JOB_FAMILY:
      case CONTENT_FUNCTION_TYPE.EXCEPTION_SUB_JOB_FAMILY:
        columnType = CONTENT_FUNCTION_TYPE.SUB_JOB_FAMILY;
        break;
      case CONTENT_FUNCTION_TYPE.JOB_FAMILY:
        columnType = CONTENT_FUNCTION_TYPE.JOB_FAMILY;
        break;
      default:
        break;
    }
    return columnType;
  }, [record]);

  const renderSelect = useMemo(() => {
    return {
      options: (
        <>
          <Option disabled value={-1}>
            <div className={styles.function_header}>
              <div
                className={
                  columnTypeActive === CONTENT_FUNCTION_TYPE.JOB_FAMILY
                    ? styles.function_header_active
                    : ''
                }
              >
                Job Family
              </div>
              <div
                className={
                  columnTypeActive === CONTENT_FUNCTION_TYPE.SUB_JOB_FAMILY
                    ? styles.function_header_active
                    : ''
                }
              >
                Sub Job Family
              </div>
              <div
                className={
                  columnTypeActive === CONTENT_FUNCTION_TYPE.FUNCTION
                    ? styles.function_header_active
                    : ''
                }
              >
                Function
              </div>
            </div>
          </Option>
          {isEmpty(functions) && (
            <Option disabled value={0}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Option>
          )}
          {!isEmpty(functions) &&
            functions.map((item, itemIndex) => (
              <Option key={item?.id} value={inputValue || ''} option={item}>
                <div
                  className={styles.function_rows}
                  onClick={() => {
                    handleSelectedRowItem(item);
                  }}
                  onKeyDown={() => {}}
                >
                  <div
                    className={`${
                      columnTypeActive === CONTENT_FUNCTION_TYPE.JOB_FAMILY &&
                      styles.function_row_active
                    }`}
                  >
                    {item.job_family}
                  </div>
                  <div
                    className={`${
                      columnTypeActive === CONTENT_FUNCTION_TYPE.SUB_JOB_FAMILY &&
                      styles.function_row_active
                    }`}
                  >
                    {item.sub_job_family}
                  </div>
                  <div
                    className={`${
                      columnTypeActive === CONTENT_FUNCTION_TYPE.FUNCTION &&
                      styles.function_row_active
                    }`}
                  >
                    {item.name}
                  </div>
                </div>
              </Option>
            ))}
        </>
      ),
      class: styles.function_select,
      placeholder: '',
    };
  }, [columnTypeActive, functions, handleSelectedRowItem, inputValue]);

  const handleScroll = (event) => {
    if (searchParams.isAll || searchParams.fetching) return;
    const {
      target: { scrollTop, offsetHeight, scrollHeight },
    } = event;
    const isSearch = scrollTop + offsetHeight >= scrollHeight - 1;
    if (isSearch) {
      setSearchParams((prev) => ({ ...prev, page: prev.page + 1, fetching: true }));
    }
  };

  const handleSearch = (value) => {
    if (selectRef.current) {
      selectRef.current.scrollTo(0);
    }
    inputData(index, 'content', value, setInputValue);
    setSearchParams((prev) => ({ ...prev, search: value, page: 1, searching: true }));
  };

  return (
    <div
      className={styles.searchWrapper}
      style={searchIndex === index ? { zIndex: 10 } : { zIndex: 1 }}
    >
      <div className={styles.modal_container}>
        <div className={renderSelect.class}>
          <BasicAutoComplete
            ref={selectRef}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            loading={searchParams.fetching || searchParams.searching}
            showSearch
            filterOption={false}
            placeholder={renderSelect.placeholder}
            onPopupScroll={handleScroll}
            onSearch={handleSearch}
            value={inputValue || undefined}
            disabled={disabled}
            onFocus={() => setSearchIndex(index)}
            onBlur={() => setSearchIndex(null)}
          >
            {renderSelect.options}
          </BasicAutoComplete>
        </div>
        <img className={styles.search_icon} src={chevron_down_arrow} alt="" />
      </div>
    </div>
  );
};

export default SearchFunctionModal;
