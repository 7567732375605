import { Col, Row, Space } from 'antd';
import { useFormik } from 'formik';
import __, { filter, find, get, isEmpty, isNil, map, negate, size } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { AiAlert, AiButton, AiSpin } from '../../../assets/common';
import { tc_meeting_breadcrumb } from '../../../assets/img';
import {
  AlertMessage,
  AsyncMultipleSelect,
  BreadCrumb,
  FieldError,
  ModelTC,
} from '../../../components';
import { useCreateMeeting } from '../../../hooks/useCreateMeeting';
import { isCheckError } from '../../../hooks/useFormik';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import { useUpdateMeeting } from '../../../hooks/useUpdateMeeting';
import { meetingApi } from '../../../services/tcMeeting';
import { DATE_TIME_FORMAT, NOTIFICATION_TYPE } from '../../../utils/constants';
import { getAsyncOptionAdvocatorsHrPartners } from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import MeetingStatus from '../components/MeetingStatus/MeetingStatus';
import StepBar from '../components/StepBar/StepBar';
import { clearAgendaState } from '../redux/agendaSlice';
import { addOrUpdateMeeting, clearMeeting } from '../redux/meetingDetailSlice';
import { addOrUpdateStepper } from '../redux/stepperSlice';
import ModalMeetingDetails from './ModalMeetingDetails';
import {
  AiContainerForm,
  AiContainerGrBtn,
  AiDatePicker,
  AiH3,
  AiInput,
  AiLabel,
  AiTimePickerField,
  MeetingTitle,
} from './Styled';
import MeetingCategory from './components/MeetingCategory';
import styles from './meeting-details.module.scss';
import { validationSchema } from './meeting-validations';

const MeetingDetails = () => {
  const [visibleRemoveMeeting, setVisibleRemoveMeeting] = useState(false);
  const [visibleSaveAsDraft, setVisibleSaveAsDraft] = useState(false);
  const [visible, setVisible] = useState(false);

  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingDeleteMeeting, setLoadingDeleteMeeting] = useState(false);

  // Category Value
  const [isChangeCategory, setIsChangeCategory] = useState(false);

  useEffect(() => {
    if (isChangeCategory) {
      formik.setFieldValue('category_value', null);
      setIsChangeCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeCategory]);

  const [breadCrumbList] = useState([
    { name: 'TC Meeting', icon: tc_meeting_breadcrumb },
    { name: 'Meeting Details' },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();

  let { idMeeting } = useParams();

  const isEditting = negate(isEmpty)(idMeeting);

  const meeting_category_option = [
    {
      id: '1',
      name: 'PETRONAS',
    },
    {
      id: '2',
      name: 'Business',
    },
    {
      id: '3',
      name: 'OPU/Division',
    },
  ];

  const {
    data: meetingDetail,
    fetch: fetchMetting,
    loading: loadingGetMeeting,
  } = useGetMeeting({ idMeeting });

  const { getAsyncOptionsForSecretarial, secretarialOptions, opuDivisionOption, businessOption } =
    useSecretarialOptions('add_meeting');

  useEffect(() => {
    if (meetingDetail?.meeting_id) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(meeting_category_option, {
            name: meetingDetail.category,
          }),
          category_value: {
            name: meetingDetail.category_value,
            category_label: meetingDetail.category_label,
          },
          meeting_secretariats: filter(secretarialOptions, ({ value }) => {
            return find(meetingDetail.meeting_secretariats, {
              bu_unit_id: value.bu_unit_id,
              business_type: value.business_type,
              role_id: value.role_id,
            });
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail, secretarialOptions, dispatch]);

  const convertMeetingFromStore = useCallback((meeting) => {
    const {
      category,
      chairman,
      meeting_committee,
      meeting_facilitator,
      meeting_secretariats,
      end_time,
      start_time,
      date,
      category_value,
      ...rest
    } = meeting;
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    return {
      ...rest,
      meeting_category: category.name,
      chairman_id: __(chairman).castArray().head()?.value,
      meeting_committee_ids: map(meeting_committee, 'value'),
      meeting_facilitator_ids: map(meeting_facilitator, 'value'),
      meeting_secretariats: map(meeting_secretariats, 'value'),
      end_time: moment(`${dateWithFormat} ${end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      start_time: moment(`${dateWithFormat} ${start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      on_step: 1,
      category_value: category_value?.name || null,
      category_label: category_value?.category_label || null,
    };
  }, []);

  const {
    createMeeting,
    isSuccess: isCreateMeetingSuccess,
    error: createMeetingError,
  } = useCreateMeeting({
    onSuccess: (data) => {
      history.push(`/meeting/${data.result.meeting_id}`);
    },
  });

  const {
    updateMeeting,
    isSuccess: isUpdateMeetingSuccess,
    error: updateMeetingError,
  } = useUpdateMeeting({
    onSuccess: fetchMetting,
  });

  const createOrEditMeeting = useCallback(
    (meeting) => {
      const data = convertMeetingFromStore(meeting);
      dispatch(
        addOrUpdateStepper({ currentTabActive: 2, currentTabFinished: [1], tabsActive: [1, 2] })
      );
      if (!isEditting) {
        return createMeeting(data);
      } else {
        return updateMeeting({ idMeeting, ...data });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditting, createMeeting, convertMeetingFromStore]
  );

  const handleSubmit = async (values) => {
    dispatch(addOrUpdateMeeting(values));
    const response = await createOrEditMeeting(values);
    if (response) {
      handleAddOrUpdateMeetingToStore(response.result);
      await handleUpdateStepNumber(1);
    }
    setVisible(false);
    return response;
  };

  const handleUpdateStepNumber = async (stepNumber) => {
    if (!idMeeting) return;

    await meetingApi.updateStepMeeting({
      body: {
        on_step: stepNumber,
      },
      idMeeting,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const categoryValueOptions = useMemo(() => {
    return formik.values.category.name === meeting_category_option[1].name
      ? businessOption
      : opuDivisionOption;
  }, [businessOption, formik.values.category.name, meeting_category_option, opuDivisionOption]);

  useEffect(() => {
    formik.setValues(meeting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting, formik.setValues]);
  const onChangeInputData = (value, key) => {
    formik.setFieldValue(key, value);
  };

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const onChangeSelectData = (event, key) => {
    formik.setFieldValue(key, event);
  };

  const handleCheckValidateForm = () => {
    setIsSubmit(true);
    if (size(formik.errors) > 0) {
      dispatch(addOrUpdateMeeting(formik.values));
      setVisible(true);
    } else {
      setIsSubmit(false);
    }
  };

  const exitCreateOrEditMeeting = (mode = 'create') => {
    dispatch(clearMeeting());
    dispatch(clearAgendaState());
    history.push('/calendar');
  };
  const handleExit = () => {
    if (!formik.dirty) return history.push('/calendar');
    setVisibleSaveAsDraft(true);
  };

  const handleNext = async () => {
    const errRes = await formik.validateForm();
    dispatch(addOrUpdateMeeting(formik.values));
    if (!isEmpty(errRes)) {
      setVisible(true);
      return;
    }
    const response = await createOrEditMeeting(formik.values);
    if (response) {
      handleAddOrUpdateMeetingToStore(response.result);
      idMeeting = get(response, ['result', 'meeting_id']);
    }
    if (!idMeeting) return;

    dispatch(
      addOrUpdateStepper({ currentTabActive: 2, currentTabFinished: [1], tabsActive: [1, 2] })
    );
    await handleUpdateStepNumber(1);
    await handleUpdateStepNumber(2);
    history.push(`/meeting/${idMeeting}/agendas`);
  };

  const handleAddOrUpdateMeetingToStore = (data) => {
    const newData = handleConvertDataToSaveStore(data);
    dispatch(
      addOrUpdateMeeting({
        ...newData,
        category: find(meeting_category_option, {
          name: newData.category,
        }),
        category_value: {
          name: newData.category_value,
          category_label: newData.category_label,
        },
        meeting_secretariats: filter(secretarialOptions, ({ value }) => {
          return find(newData.meeting_secretariats, {
            bu_unit_id: value.bu_unit_id,
            business_type: value.business_type,
            role_id: value.role_id,
          });
        }),
      })
    );
  };

  // Convert the post response to store
  const handleConvertDataToSaveStore = (data) => {
    const tempChairman = size(data.roles.chairman) > 0 ? data.roles.chairman : [];
    const tempCommittee = size(data.roles.committee) > 0 ? data.roles.committee : [];
    const tempFacilitator = size(data.roles.facilitator) > 0 ? data.roles.facilitator : [];
    const tempSecretariat = size(data.roles.secretariat) > 0 ? data.roles.secretariat : [];
    const { name, start_at, end_at, ...rest } = data;

    return {
      ...rest,
      meeting_name: name,
      date: moment(new Date(start_at)).format(DATE_TIME_FORMAT.DATE_SPACE),
      start_time: getTime(start_at),
      end_time: getTime(end_at),
      chairman: map(tempChairman, (it) => ({ label: it.name, value: it.id })),
      meeting_committee: map(tempCommittee, ({ name, id }) => ({
        label: name,
        value: id,
      })),
      meeting_facilitator: map(tempFacilitator, ({ name, id }) => ({
        label: name,
        value: id,
      })),
      meeting_secretariats: map(tempSecretariat, (it) => ({
        business_type: it.business_type,
        role_id: it.role_id,
        bu_unit_id: it.business_value,
      })),
    };
  };

  const getTime = (dateInput) => {
    if (!dateInput) return '';
    const date = moment(dateInput).local().toDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    hour = `0${hour}`.slice(-2);
    minutes = `0${minutes}`.slice(-2);

    return `${hour}:${minutes}`;
  };

  return (
    <>
      <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
      <StepBar />
      {loadingGetMeeting ? (
        <AiSpin tip="Loading...">
          <AiAlert />
        </AiSpin>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <AiContainerForm>
            {size(formik.errors) > 0 && isSubmit && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage
                  message="There are items that require your attention. Please fill out this field."
                  type="error"
                />
              </div>
            )}

            {isCreateMeetingSuccess && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting has been created successfully." type="success" />
              </div>
            )}

            {!isNil(createMeetingError) && !isEmpty(createMeetingError) && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting hasn't been created." type="error" />
              </div>
            )}

            {isUpdateMeetingSuccess && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting has been updated successfully." type="success" />
              </div>
            )}

            {!isNil(updateMeetingError) && !isEmpty(updateMeetingError) && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting hasn't been updated." type="error" />
              </div>
            )}

            <MeetingTitle>
              <AiH3>Meeting Details</AiH3>
              <MeetingStatus status={meeting.status} />
            </MeetingTitle>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Name</AiLabel>
                <AiInput
                  placeholder="Enter Meeting Name"
                  name="meeting_name"
                  value={formik.values.meeting_name}
                  onChange={formik.handleChange}
                  status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
                />
                <FieldError name="meeting_name" {...formik} />
              </Col>
            </Row>
            <div className={styles.rowInput}>
              <div className={styles.inputItem}>
                <AiLabel>Date</AiLabel>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <AiDatePicker
                    onChange={onChange}
                    format={DATE_TIME_FORMAT.DATE_SPACE}
                    status={isCheckError(formik, 'date') ? 'error' : ''}
                    value={
                      formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)
                    }
                    placeholder="Select Date"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                  <FieldError name="date" {...formik} />
                </Space>
              </div>
              <div className={styles.inputItem}>
                <AiLabel>Meeting Category</AiLabel>
                <MeetingCategory
                  selected={formik.values.category}
                  setSelected={(event) => {
                    formik.setFieldValue('category', event);
                    setIsChangeCategory(true);
                  }}
                  options={meeting_category_option}
                  placeholder="Select Meeting Category"
                  status={isCheckError(formik, 'category') ? 'error' : ''}
                />
                <FieldError name="category.id" {...formik} />
              </div>
              {!isEmpty(formik.values.category) &&
                formik.values.category.name !== meeting_category_option[0].name && (
                  <div className={styles.inputItem}>
                    <AiLabel>{formik.values.category.name}</AiLabel>
                    <MeetingCategory
                      selected={formik.values.category_value}
                      setSelected={(event) => {
                        formik.setFieldValue('category_value', event);
                      }}
                      options={categoryValueOptions}
                      placeholder={`Select ${formik.values.category.name}`}
                      status={isCheckError(formik, 'category_value') ? 'error' : ''}
                    />
                    <FieldError name="category_value" {...formik} />
                  </div>
                )}
              <div className={styles.inputItem}>
                <AiLabel>Start time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={
                    formik.values.start_time
                      ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                      : null
                  }
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'start_time')}
                  status={isCheckError(formik, 'start_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
                <FieldError name="start_time" {...formik} />
              </div>
              <div className={styles.inputItem}>
                <AiLabel>End time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={
                    formik.values.end_time
                      ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                      : null
                  }
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'end_time')}
                  status={isCheckError(formik, 'end_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
                <FieldError name="end_time" {...formik} />
              </div>
            </div>

            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Location</AiLabel>
                <AiInput
                  placeholder="Enter Location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Chairman</AiLabel>
                <AsyncMultipleSelect
                  value={formik.values.chairman}
                  placeholder="Enter Chairman Name"
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'chairman')}
                  status={isCheckError(formik, 'chairman') ? 'error' : ''}
                  isDisabled={false}
                />
                <FieldError name="chairman" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Committee Members</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Committee Members Name'}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'meeting_committee')}
                  isDisabled={false}
                  value={formik.values.meeting_committee}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Facilitators</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Meeting Facilitators Name'}
                  onChange={(e) => onChangeSelectData(e, 'meeting_facilitator')}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  isDisabled={false}
                  value={formik.values.meeting_facilitator}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Secretariat</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Meeting Secretariat Name'}
                  onChange={(e) => onChangeSelectData(e, 'meeting_secretariats')}
                  loadOptions={getAsyncOptionsForSecretarial}
                  status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                  value={formik.values.meeting_secretariats}
                  isDisabled={false}
                />
                <FieldError name="meeting_secretariats" {...formik} />
              </Col>
            </Row>
          </AiContainerForm>
          <AiContainerGrBtn>
            <div>
              <AiButton mode="danger" onClick={() => setVisibleRemoveMeeting(true)}>
                Delete Meeting
              </AiButton>
            </div>
            <div>
              <AiButton htmlType="submit" onClick={handleCheckValidateForm}>
                Save as Draft
              </AiButton>
              <AiButton className="ml-2" onClick={handleExit}>
                Exit
              </AiButton>
              <AiButton mode="teal" className="ml-2" onClick={handleNext}>
                Next
              </AiButton>
            </div>
          </AiContainerGrBtn>
        </form>
      )}
      {/*
       * IMPLEMENT MODEL
       */}
      <ModelTC
        info={{
          type: 'deleteMeeting',
          visible: visibleRemoveMeeting,
          setVisible: setVisibleRemoveMeeting,
          handleSubmit: async () => {
            setLoadingDeleteMeeting(true);
            if (!idMeeting) {
              exitCreateOrEditMeeting();
            } else {
              try {
                let res = await meetingApi.deleteMeeting({ idMeeting });
                if (res.status === 200) {
                  exitCreateOrEditMeeting();
                  setVisibleRemoveMeeting(false);
                  setLoadingDeleteMeeting(false);
                }
              } catch (error) {
                showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
                setVisibleRemoveMeeting(false);
                setLoadingDeleteMeeting(false);
              }
            }
          },
          onClose: () => {},
          loading: loadingDeleteMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: visibleSaveAsDraft,
          setVisible: setVisibleSaveAsDraft,
          onClose: exitCreateOrEditMeeting,
          handleSubmit: async () => {
            setVisibleSaveAsDraft(false);
            const values = formik.values;
            const result = await formik.validateForm(values);

            if (isEmpty(result)) {
              await handleSubmit(values);
              setTimeout(() => {
                history.push('/calendar');
              }, 2000);
            } else {
              dispatch(addOrUpdateMeeting(formik.values));
              setVisible(true);
            }
          },
        }}
      />
      <ModalMeetingDetails
        visible={visible}
        setVisible={setVisible}
        initialValues={formik.values}
        initialErrors={formik.errors}
        setValues={formik.setValues}
        validationSchema={validationSchema}
        getAsyncOptionsForSecretarial={getAsyncOptionsForSecretarial}
        opuDivisionOption={opuDivisionOption}
        businessOption={businessOption}
      />
    </>
  );
};
export default MeetingDetails;
