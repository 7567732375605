import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { OptionNormal, OptionchartReAndNa } from '../../share';
import './retirementForeCastChart.scss';

const RetirementForeCastChart = ({ data, categories }) => {
  const [series, setSeries] = useState([]);
  let option = {
    ...OptionchartReAndNa,
    colors: ['#BB9FCC', '#7AC8C5', '#BAC7D5'],
    plotOptions: {
      bar: {
        ...OptionNormal.barReandNa,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: categories,
      labels: OptionNormal.labels,
    },
    legend: {
      ...OptionNormal.legendSPandBU,
      colors: ['#444444'],
      markers: {
        width: 18,
        height: 18,
        radius: 5,
        fillColors: ['#BB9FCC', '#7AC8C5', '#BAC7D5'],
        offsetX: -5,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 50,
        vertical: 40,
      },
    },
  };
  const [options] = React.useState(option);

  useEffect(() => {
    setSeries(data);
  }, [data, categories]);
  return (
    <div id="retirementForeCastChart">
      <div>
        {' '}
        <Chart options={options} series={series} type="bar" height={386} width={1201} />
      </div>
    </div>
  );
};
export default RetirementForeCastChart;
