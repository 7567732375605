import styled from 'styled-components';
import { BasicTable } from '../../../../../../assets/common';

export const CriteriaSummaryCustomTable = styled(BasicTable)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #3f3c4c;

  .ant-table-tbody > tr > td {
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-table-thead > tr > th:first-child, 
  .ant-table-tbody > tr > td:first-child {
    padding-left: 80px;
  }
  
  .ant-table-tbody > tr > td{
    border-bottom:none;
  }

  .ant-table-tbody> tr:last-child > td{
    border-bottom: 1px solid #d3dee8;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #fff;
  }
`;
