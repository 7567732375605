import React from 'react';
import styles from './warning.module.scss';

const Warning = (props) => {
  const { children, ...rest } = props;
  return (
    <div className={styles.text__error} {...rest} data-testid="warning">
      {children}
    </div>
  );
};

export default Warning;
