import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { adminApi } from '../services/admin';
import { PERMISSION, USER_ID } from '../utils/constants';

export const useRoles = (sourcePage = '') => {
  const roleActive = useSelector((state) => state.user.roleActive);

  const [opuDivisionList, setOpuDivisionList] = useState([]);
  const [jobFamilyList, setJobFamilyList] = useState([]);
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [opuDivisionDropdown, setOpuDivisionDropdown] = useState([]);

  useEffect(() => {
    if (!roleActive.roleId) return;
    async function fetchApi() {
      const roles = {
        role: roleActive,
        id: USER_ID,
        permission: PERMISSION.SEARCH_USER_BY_EMAIL,
      };

      await Promise.allSettled([
        adminApi.getAllOpuAndDivsion(roles, sourcePage),
        adminApi.getJobFamilies(roles),
        adminApi.getBusinessUnit(roles),
        adminApi.getOpuDivisionDropdown(roles),
      ])
        .then((results) => {
          setOpuDivisionList(results[0].status === 'fulfilled' ? results[0].value.data.result : []);
          setJobFamilyList(results[1].status === 'fulfilled' ? results[1].value.data.result : []);
          setBusinessUnitList(
            results[2].status === 'fulfilled' ? results[2].value.data.result : []
          );
          setOpuDivisionDropdown(
            results[3].status === 'fulfilled' ? results[3].value.data.result : []
          );
        })
        .catch((error) => console.log(error));
    }

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive]);

  return { opuDivisionList, jobFamilyList, businessUnitList, opuDivisionDropdown };
};
