export const LABEL_MILES_STONES = {
  JOB_GRADE: 'Job Grade',
  SALARY_GRADE: 'Salary Grade',
  SG_SINCE: '',
  SUPERRIOR: 'Superior',
  DATE_IN_POSITION: 'Date in Position (Years in Position)',
  DATE_IN_COMPANY: 'Date in Company',
  DATE_IN_PETRONAS: 'Date in PETRONAS',
  POSITION_ID: 'Position ID',
};

export const USER_INFO_DETAIL = {
  IS_MALAYSIA: 'Malaysia',
};
