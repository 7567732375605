import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { business_list_not_found } from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import { masterDataManagementApi } from '../../../../services/masterDataManagementApi';
import * as styles from './business-list.module.scss';
import DataTable from './DataTable/DataTable';
import MenuBar from './MenuBar/MenuBar';

export default function BusinessList(props) {
  const {
    handleShowSearchNotFound,
    handleAddBusinessExist,
    handleAddBusinessSuccess,
    handleEditBusinessSuccess,
    handleDeleteBusinessMultipleSuccess,
    handleDeleteBusinessWarning,
  } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [deleteItem, setDeleteItem] = useState([]);
  const [isEdit, setIsEdit] = useState('');
  const [query, setQuery] = useState({ page: 1, totalPage: 0, limit: 15, keyword: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getBusinessList = async () => {
    setLoading(true);
    try {
      const response = await masterDataManagementApi.getBusinessUnitList(roleActive, {
        keyword: query.keyword,
        _limit: query.limit,
        _page: query.page,
      });
      const data = get(response, 'data.result.data') || [];
      const totalPage = get(response, 'data.result.total') || 0;
      setData(data);
      setQuery((prev) => {
        return {
          ...prev,
          totalPage,
        };
      });
      if (query.keyword && isEmpty(data)) handleShowSearchNotFound();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteBusiness = async (ids) => {
    try {
      const res = await masterDataManagementApi.deleteBusinessUnit(ids, roleActive);
      setDeleteItem([]);
      setDeleteSelecteds([]);
      const warning = get(res, 'data.result.list_in_use');
      if (!isEmpty(warning)) handleDeleteBusinessWarning();
      const success = get(res, 'data.result.list_deleted');
      if (!isEmpty(success)) handleDeleteBusinessMultipleSuccess(success);
      if (success.length === data.length) {
        setQuery(prev => ({
          ...prev,
          page: 1,
          keyword: ''
        }))
        return;

      }
      getBusinessList();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBusinessList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.keyword]);

  return (
    <Spin spinning={loading} size="large">
      <MenuBar
        deleteSelecteds={deleteSelecteds}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        query={query}
        setQuery={setQuery}
        handleAddBusinessExist={handleAddBusinessExist}
        handleAddBusinessSuccess={handleAddBusinessSuccess}
        getBusinessList={getBusinessList}
        query={query}
        setShowDeleteModal={setShowDeleteModal}
      />
      {!isEmpty(data) ? (
        <DataTable
          data={data}
          setDeleteSelecteds={setDeleteSelecteds}
          deleteSelecteds={deleteSelecteds}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          query={query}
          setQuery={setQuery}
          getBusinessList={getBusinessList}
          handleAddBusinessExist={handleAddBusinessExist}
          handleEditBusinessSuccess={handleEditBusinessSuccess}
          setDeleteItem={setDeleteItem}
          setShowDeleteModal={setShowDeleteModal}
        />
      ) : (
        <div className={styles.not_found}>
          <img src={business_list_not_found} alt="not_found" />
        </div>
      )}
      <ModelTC
        info={{
          type: 'deleteBusinessUnit',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => {
            setShowDeleteModal(false);
            setDeleteItem([]);
            setDeleteSelecteds([]);
          },
          handleSubmit: () => {
            setShowDeleteModal(false);
            !isEmpty(deleteItem) ? deleteBusiness(deleteItem) : deleteBusiness(deleteSelecteds);
          },
        }}
      />
    </Spin>
  );
}
