import { Col, Row, Space } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { AiButton } from '../../../../assets/common';
import { CategoryDropdownCustom } from '../../../../components';
import { isCheckError, onChangeInputData, showError } from '../../../../hooks/useFormik';
import { DATE_TIME_FORMAT, FORM_TYPES } from '../../../../utils/constants';
import {
  AiDatePicker,
  AiFormWrapper,
  AiInput,
  AiLabel,
  AiTitle,
} from '../NewSuccessionPlanningForm/Styled';
import SearchBoxCustom from '../SearchBoxCustom/SearchBoxCustom';
import DataTable from './DataTable/DataTable';

const readiness_value = [
  { id: 'ready_now', name: '1st Line' },
  { id: 'no readiness', name: '2nd Line' },
  { id: '5 years', name: '3rd Line' },
];

const NewCandidateForm = (props) => {
  const { positionCode } = useParams();
  const {
    mode,
    allFilter,
    setCandidateList,
    setCandidateToUpdate,
    candidateToUpdate,
    setDeleteItems,
  } = props;

  // Use state
  const [candidates, setCandidates] = useState([]);
  const [select] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validationSchema = yup.object({
    staff_id: yup.string().required(true),
    candidate_name: yup.string().required(true),
    readiness: yup.object().required(true),
    ranking: yup.object().required(true),
    active_approved_successor: yup.object().required(true),
    // talent_review_date: yup.string().required(true),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      staff_id: '',
      candidate_name: '',
      readiness: '',
      ranking: '',
      active_approved_successor: '',
      talent_review_date: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  const handleAddNew = async () => {
    setIsSubmitted(true);
    await formik.submitForm();
    const values = formik.values;
    const isInvalidForm = handleCheckEmptyValue(values);
    const newItem = handleMapDataToShowTable(values);

    if (isInvalidForm) {
      return;
    }
    setCandidates((prevState) => {
      return [...prevState, newItem];
    });
    formik.resetForm();
    setSearchList([]);
    setSearchTerm({ type: '', value: '', showTerm: '' });
    setIsSubmitted(false);
    formik.setErrors({});
    formik.setTouched({}, false);
    return formik.values;
  };

  const onChangetalent_review_date = (date, dateString) => {
    formik.setFieldValue('talent_review_date', dateString);
  };

  useEffect(() => {
    if (!isEmpty(searchList)) {
      const data = searchList[0];
      formik.setFieldValue('staff_id', data?.staff_id || '');
      formik.setFieldValue('candidate_name', data?.birth_name || '');
      formik.setErrors({
        readiness: true,
        ranking: true,
        active_approved_successor: true,
        talent_review_date: true,
      });
    } else {
      formik.setFieldValue('staff_id', '');
      formik.setFieldValue('candidate_name', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchList]);

  const handleMapDataToShowTable = (values) => {
    return {
      key: uuidv4(),
      candidate_name: values?.candidate_name,
      staff_id: values?.staff_id,
      readiness: values?.readiness,
      ranking: values?.ranking,
      active_approved_successor: values?.active_approved_successor,
      talent_review_date: values?.talent_review_date,
    };
  };

  const handleCheckEmptyValue = (values) => {
    let isCheck = false;
    Object.keys(values).forEach((key) => {
      if (isEmpty(values[key]) && key !== 'talent_review_date' && key !== 'candidate_name') {
        isCheck = true;
      }
    });
    return isCheck;
  };

  useEffect(() => {
    setCandidateList(candidates);

    return () => {
      setCandidateList([]);
    };
  }, [candidates, setCandidateList]);

  useEffect(() => {
    if (mode !== FORM_TYPES.EDIT || isNaN(positionCode) || isEmpty(candidateToUpdate?.successor)) {
      return;
    }

    const newData = convertSuccessorFromStore(candidateToUpdate?.successor);
    setCandidates(newData);

    return () => {
      setCandidates([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCode, mode, candidateToUpdate]);

  const convertSuccessorFromStore = (data) => {
    return !isEmpty(data)
      ? data.map((item) => {
          return {
            key: !isNaN(item?.id) ? item?.id : null,
            candidate_name: item?.birth_name || '',
            staff_id: item?.staff_id || '',
            readiness: item?.readiness
              ? {
                  id: item?.readiness,
                  name: handleGetReadnessName(item?.readiness),
                }
              : '',
            ranking: item?.ranking
              ? {
                  id: item?.ranking,
                  name: item?.ranking,
                }
              : '',
            active_approved_successor: item?.active_approved_successor
              ? {
                  id: item?.active_approved_successor,
                  name: item?.active_approved_successor === 1 ? 'Active' : 'Inactive',
                }
              : '',
            talent_review_date: item?.talent_review_date ? moment(item?.talent_review_date) : '',
          };
        })
      : [];
  };

  const handleGetReadnessName = (value) => {
    if (isEmpty(value)) return;

    let name = '';
    readiness_value.forEach((item) => {
      if (item.id === value) {
        name = item.name;
      }
    });
    return name;
  };

  return (
    <>
      <AiFormWrapper>
        <AiTitle>Successor</AiTitle>
        <Row gutter={[16, 16]} className="mb-3">
          <Col span={4}>
            <AiLabel>
              Candidate ID<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <SearchBoxCustom
              selectedSp={select}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchList={searchList}
              setSearchList={setSearchList}
              fieldName={'staff_id'}
              searchPlaceholder={'Enter Staff ID'}
              isSubmitted={isSubmitted}
            />
            {showError(formik, 'staff_id')}
          </Col>
          <Col span={6}>
            <AiLabel>Candidate Name</AiLabel>
            <AiInput
              placeholder="Search Candidate Name"
              name="candidate_name"
              value={formik.values.candidate_name}
              onChange={(_event) =>
                onChangeInputData(formik, _event.target.value, 'candidate_name')
              }
              status={isCheckError(formik, 'candidate_name') ? 'error' : ''}
              disabled={true}
            />
            {showError(formik, 'candidate_name')}
          </Col>

          <Col span={3}>
            <AiLabel>
              Readiness<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.readiness}
              setSelected={(event) => formik.setFieldValue('readiness', event)}
              options={
                !isEmpty(allFilter.readiness)
                  ? allFilter.readiness.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.label}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'readiness') ? 'error' : ''}
            />
            {showError(formik, 'readiness')}
          </Col>
          <Col span={3}>
            <AiLabel>
              Ranking<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.ranking}
              setSelected={(event) => formik.setFieldValue('ranking', event)}
              options={
                !isEmpty(allFilter.ranking)
                  ? allFilter.ranking.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.label}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'ranking') ? 'error' : ''}
            />
            {showError(formik, 'ranking')}
          </Col>
          <Col span={4}>
            <AiLabel>
              Active Approved Successor<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.active_approved_successor}
              setSelected={(event) => formik.setFieldValue('active_approved_successor', event)}
              options={
                !isEmpty(allFilter.active_approved_successor)
                  ? allFilter.active_approved_successor.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.value === 1 ? 'Active' : 'Inactive'}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'active_approved_successor') ? 'error' : ''}
            />
            {showError(formik, 'active_approved_successor')}
          </Col>
          <Col span={4}>
            <AiLabel>Talent Review Date</AiLabel>
            <Space direction="vertical" style={{ width: '100%' }}>
              <AiDatePicker
                disabledDate={false}
                onChange={onChangetalent_review_date}
                format={DATE_TIME_FORMAT.DATE_SPACE}
                status={isCheckError(formik, 'talent_review_date') ? 'error' : ''}
                value={
                  formik.values.talent_review_date &&
                  moment(formik.values.talent_review_date, DATE_TIME_FORMAT.DATE_SPACE)
                }
                placeholder="Select Date"
                getPopupContainer={(trigger) => trigger.parentElement}
              />
              {/* {showError(formik, 'talent_review_date')} */}
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="end">
          <AiButton mode={'teal'} type={'submit'} onClick={() => handleAddNew()}>
            Add New Candidate
          </AiButton>
        </Row>
        {!isEmpty(candidates) && (
          <DataTable
            mode={mode}
            data={candidates}
            setCandidates={setCandidates}
            setCandidateToUpdate={setCandidateToUpdate}
            setDeleteItems={setDeleteItems}
          />
        )}
      </AiFormWrapper>
    </>
  );
};

export default NewCandidateForm;
