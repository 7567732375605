import React from 'react';
import styles from './switch-mode.module.scss';

const SwitchMode = ({ isHide, setIsHide, id }) => {
  const handleHidden = () => {
    setIsHide(!isHide);
  };

  return (
    <div className={styles.content}>
      <label className={styles.toggle} htmlFor={`hide_mode_talent-${id?.toString()}`}>
        <input
          type="checkbox"
          id={`hide_mode_talent-${id?.toString()}`}
          onClick={() => handleHidden()}
          value={isHide}
        />
        <div className={styles.slider}></div>
      </label>
      <p className={styles.hideText}>{!isHide ? 'Hide' : 'Visible'}</p>
    </div>
  );
};
export default SwitchMode;
