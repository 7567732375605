import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ReactExport from 'react-data-export';
import { BasicButton, BasicInput } from '../../../../assets/common';
import {
  finder_grid_disable,
  finder_grid_enable,
  finder_list_disable,
  finder_list_enable,
} from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { REPORT_BUILDER_MESSAGE } from '../../constants';
import PopoutAddTalentBuilder from '../PopoutAddTalentBuilder/PopoutAddTalentBuilder';
import * as styles from './talent-list-search-bar.module.scss';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TalentListSearchBar = ({
  initData,
  setInitData,
  listOfTalents,
  setListOfTalents,
  selectedTalents,
  setSelectedTalents,
  setModeView,
  modeView,
  handleAddTalent,
  isCheckErrorAddNewTalent,
  setShowConfirmAddTalentModal,
  setShowAddTalentModal,
  showAddTalentModal,
}) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleChangeSearch = (event) => {
    setKeyword(event.target.value);
  };

  const handleClickSearch = () => {
    const searchParam = keyword.toLowerCase();
    const result = initData.list_of_talents.filter((item) => {
      return (
        item?.birth_name?.toLowerCase()?.includes(searchParam) ||
        item?.staff_id?.includes(searchParam)
      );
    });
    setSelectedTalents([]);
    setListOfTalents(result);
  };

  const handleClickDelete = () => {
    setShowDeleteConfirm(true);
  };

  const handleDeleteOnClient = () => {
    const selectedTalentIds = selectedTalents.map((talent) => talent.staff_id);
    setListOfTalents((prev) =>
      prev.filter((talent) => !selectedTalentIds.includes(talent.staff_id))
    );
    setInitData((prev) => ({
      ...prev,
      list_of_talents: prev.list_of_talents.filter(
        (talent) => !selectedTalentIds.includes(talent.staff_id)
      ),
    }));
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.DELETE_TALENT,
        isScroll: true,
      })
    );
    setSelectedTalents([]);
  };

  const handleDeleteTalent = async () => {
    if (isEmpty(selectedTalents)) return;
    const deleteInDbIds = selectedTalents
      .filter((talent) => talent.talent_id)
      .map((talent) => talent.talent_id);
    if (!isEmpty(deleteInDbIds)) {
      try {
        setDeleting(true);
        const res = await reportBuilderApi.deleteTalent(deleteInDbIds);
        if (res.status === 200) {
          handleDeleteOnClient();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDeleting(false);
        setShowDeleteConfirm(false);
      }
    } else {
      handleDeleteOnClient();
      setShowDeleteConfirm(false);
    }
  };

  const exportDataSet = useMemo(() => {
    if (isEmpty(listOfTalents)) return;
    const uniqueColumns = listOfTalents[0].columns.map((column) => ({ title: column.name }));
    const columns = [
      { title: 'No' },
      { title: 'Staff ID' },
      { title: 'Staff Name' },
      ...uniqueColumns,
    ];
    const data = listOfTalents.map((talent, index) => [
      index + 1,
      talent.staff_id,
      talent.birth_name,
      ...uniqueColumns.map((colName) => {
        const column = talent.columns.find((col) => col.name === colName.title);
        if (column?.type === 'Status Selection') {
          return {
            value: '',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: column.value.replace('#', '') } },
            },
          };
        }
        return column?.value;
      }),
    ]);
    return [
      {
        columns,
        data,
      },
    ];
  }, [listOfTalents]);

  const handleClickAddTalent = () => {
    if (isCheckErrorAddNewTalent) {
      setShowConfirmAddTalentModal(true);
    } else {
      setShowAddTalentModal(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.search}>
        <BasicInput
          height={44}
          width={416}
          placeholder="Search by Staff Name or Staff ID"
          onChange={handleChangeSearch}
        />
        <BasicButton mode="teal" height={36} onClick={handleClickSearch}>
          Search
        </BasicButton>
      </div>
      <div className={styles.buttons}>
        <BasicButton mode="danger" onClick={handleClickDelete} disabled={isEmpty(selectedTalents)}>
          Confirm Delete Talent
        </BasicButton>

        <ExcelFile
          filename={initData.report_name}
          element={<BasicButton disabled={isEmpty(listOfTalents)}>Export</BasicButton>}
        >
          <ExcelSheet dataSet={exportDataSet} name="Sheet1" />
        </ExcelFile>
        <BasicButton mode="teal" onClick={handleClickAddTalent}>
          Add Talent
        </BasicButton>
        <button
          type={'button'}
          style={{ marginLeft: '-6px' }}
          className={styles.customBtn}
          onClick={() => {
            setModeView(false);
          }}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={!modeView ? finder_grid_enable : finder_grid_disable}
            alt="Grid"
            data-testid="talent-finder-btn-grid"
          />
        </button>
        <button
          type={'button'}
          style={{ marginLeft: '-4px' }}
          className={styles.customBtn}
          onClick={() => {
            setModeView(true);
          }}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={modeView ? finder_list_enable : finder_list_disable}
            alt="List"
            data-testid="talent-finder-btn-list"
          />
        </button>
      </div>

      <ModelTC
        info={{
          type: 'deleteTalentReportBuilder',
          visible: showDeleteConfirm,
          setVisible: setShowDeleteConfirm,
          handleSubmit: handleDeleteTalent,
          loading: deleting,
          onClose: () => setShowDeleteConfirm(false),
        }}
      />

      {showAddTalentModal && (
        <PopoutAddTalentBuilder
          open={showAddTalentModal}
          setOpen={setShowAddTalentModal}
          setSelectedTalents={setSelectedTalents}
          handleAddTalent={handleAddTalent}
          initData={initData}
        />
      )}
    </div>
  );
};

export default TalentListSearchBar;
