import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BasicButton } from '../../../../../assets/common';
import { masterDataManagementApi } from '../../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  FUNCTION_LIST_MESSAGE,
  MESSAGE_TYPES,
} from '../../../../../utils/constants';
import * as styles from './add-function-list.module.scss';
import AddFunctionListForm from './components/AddFunctionListForm/AddFunctionListForm';
import { formValidationSchema, handleCheckEmptyValue } from './formValidation';

const AddFunctionList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      job_family: '',
      sub_job_family: '',
      functions: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  const handleAddNewFunctionList = async () => {
    await formik.submitForm();
    const values = formik.values;
    const body = {
      functions: values.functions.trim(),
      job_family: values.job_family.trim(),
      sub_job_family: values.sub_job_family.trim(),
    };
    const isCheck = handleCheckEmptyValue(values);
    if (!formik.isValid || isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, FUNCTION_LIST_MESSAGE.REQUIRED_FIELDS);
      return;
    }
    try {
      const res = await masterDataManagementApi.addFunctionList(body, roleActive?.roleId);
      if (res.status === 200) {
        history.push('/admin/master-data-management/function-list');
        handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, FUNCTION_LIST_MESSAGE.FUNCTION_ADD_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      const errors = error.response.data.errors;
      switch (errors) {
        case FUNCTION_LIST_MESSAGE.FUNCITON_ALREADY_EXIST:
          handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, FUNCTION_LIST_MESSAGE.FUNCITON_ALREADY_EXIST);
          break;
        case FUNCTION_LIST_MESSAGE.FUNCITON_INVALID:
          handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, FUNCTION_LIST_MESSAGE.FUNCITON_INVALID);
          break;
        case FUNCTION_LIST_MESSAGE.AN_UNEXPECTED_ERROR:
          handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, FUNCTION_LIST_MESSAGE.AN_UNEXPECTED_ERROR);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="max-width">
      <h3 className={styles.mainTitle}>
        Add New Function
        <p className={styles.noteTitle} data-testid="noteTitle">
          Function List
        </p>
      </h3>
      <AddFunctionListForm formik={formik} />
      <div className="d-flex justify-content-end mb-2">
        <BasicButton
          mode="cancel"
          onClick={() => history.push('/admin/master-data-management/function-list')}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </BasicButton>
        <BasicButton mode="teal" onClick={() => handleAddNewFunctionList()}>
          Add
        </BasicButton>
      </div>
    </div>
  );
};

export default AddFunctionList;
