/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { SearchDataTableNotFound } from '../../../../components';
import { masterDataManagementApi } from '../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  CONTENT_SEARCH_NOT_FOUND,
  FUNCTION_LIST_MESSAGE,
  MAPPING_DATA_TYPES,
  MESSAGE_TYPES,
  SELECT_ALL_LABEL,
} from '../../../../utils/constants';
import { getMappingData } from '../../../../utils/helper';
import DataTableManaging from './DataTableManaging/DataTableManaging';
import MenuBarMapping from './MenuBarMapping/MenuBarMapping';

export default function FunctionList(props) {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [seletedRows, setSeletedRows] = useState([]);
  const [updateStatusVisibleDeleteMultiple, setUpdateStatusVisibleDeleteMultiple] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 15;
  const [filterData, setFilterData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRecordEdit, setSelectedRecordEdit] = useState(null);
  const [filterOptions, setFilterOptions] = useState({});

  const fetchFilterOptions = useCallback(async () => {
    try {
      const res = await masterDataManagementApi.getFunctionListFilter(roleActive?.roleId);
      if (res.status === 200) {
        setFilterOptions({ function_list: res.data.result || [] });
      }
    } catch (error) {
      console.error(error);
    }
  }, [roleActive]);

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  const tempFilter = !isEmpty(filterData?.function_list)
    ? filterData?.function_list.filter((item) => item.label !== SELECT_ALL_LABEL)
    : [];

  const fetchFunctionList = useCallback(async () => {
    setData([]);
    try {
      setLoading(true);

      const body = {
        keyword,
        filters: tempFilter,
      };
      const res = await masterDataManagementApi.getFunctionList(body, roleActive?.roleId);
      if (res.status === 200 && !isEmpty(res?.data?.result?.data)) {
        const tempResult = res.data.result;
        let tempJobFamily = {};
        tempResult.data.forEach((item) => {
          tempJobFamily[`${item?.job_family?.label}`] = [];
        });
        const tempData = getMappingData(
          tempJobFamily,
          tempResult.data,
          MAPPING_DATA_TYPES.FUNCTION_LIST
        );
        setData(tempData);
        setTotalData(tempResult.total);
      } else {
        handleResetData();
        handleShowMessage(MESSAGE_TYPES.ERROR, FUNCTION_LIST_MESSAGE.SEARCH_NOT_FOUND);
      }
    } catch (error) {
      handleResetData();
      console.error(error);
    }
    setLoading(false);
  }, [keyword, page, filterData, setData, setTotalData]);

  const handleResetData = () => {
    setData([]);
    setTotalData(0);
    setLoading(false);
  };

  const handleDeleteRecord = useCallback(async () => {
    try {
      if (!isEmpty(deleteRecord) && !updateStatusVisibleDeleteMultiple) {
        setLoading(true);
        const res = await masterDataManagementApi.deleteFunctionList(
          [deleteRecord.id],
          roleActive?.roleId
        );
        if (res.status === 200) {
          handleShowMessage(MESSAGE_TYPES.SUCCESS, FUNCTION_LIST_MESSAGE.FUNCTION_DELETE_SUCCESS);
          setDeleteRecord(null);
          if (data.length === 1) {
            setPage(1);
            setKeyword('');
          } else {
            fetchFunctionList();
          }
        }
      } else {
        setLoading(true);
        const res = await masterDataManagementApi.deleteFunctionList(
          deleteSelecteds,
          roleActive?.roleId
        );
        if (res.status === 200) {
          handleShowMessage(MESSAGE_TYPES.SUCCESS, FUNCTION_LIST_MESSAGE.FUNCTION_DELETE_SUCCESS);
          setDeleteSelecteds([]);
          setSeletedRows([]);
          if (data.length === deleteSelecteds.length) {
            setPage(1);
            setKeyword('');
          } else {
            fetchFunctionList();
          }
        }
      }
    } catch (error) {
      console.error(error);
      const dataError = error?.response?.data;
      if (
        dataError?.code === 500 &&
        dataError?.errors.includes(FUNCTION_LIST_MESSAGE.FUNCTION_IN_USE.TITLE)
      ) {
        handleShowMessage(MESSAGE_TYPES.WARNING, dataError?.errors);
      } else {
        handleShowMessage(MESSAGE_TYPES.ERROR, FUNCTION_LIST_MESSAGE.AN_UNEXPECTED_ERROR);
      }
    }
    setUpdateStatusVisibleDeleteMultiple(false);
    setLoading(false);
  }, [
    data,
    deleteRecord,
    deleteSelecteds,
    roleActive,
    updateStatusVisibleDeleteMultiple,
    fetchFunctionList,
  ]);

  const hancleEditRecord = useCallback(async () => {
    try {
      if (isEmpty(selectedRecordEdit)) return;

      setLoading(true);
      const res = await masterDataManagementApi.editFunctionList(
        selectedRecordEdit,
        roleActive?.roleId
      );
      if (res.status === 200) {
        setSelectedRecordEdit(null);
        fetchFunctionList();
        handleShowMessage(MESSAGE_TYPES.SUCCESS, FUNCTION_LIST_MESSAGE.FUNCTION_EDIT_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      const errors = error.response.data.errors;
      switch (errors) {
        case FUNCTION_LIST_MESSAGE.FUNCITON_ALREADY_EXIST:
          handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, FUNCTION_LIST_MESSAGE.FUNCITON_ALREADY_EXIST);
          break;
        case FUNCTION_LIST_MESSAGE.FUNCITON_INVALID:
          handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, FUNCTION_LIST_MESSAGE.FUNCITON_INVALID);
          break;
        case FUNCTION_LIST_MESSAGE.AN_UNEXPECTED_ERROR:
          handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, FUNCTION_LIST_MESSAGE.AN_UNEXPECTED_ERROR);
          break;
        default:
          break;
      }
    }
    setIsEdit(false);
    setLoading(false);
  }, [roleActive, selectedRecordEdit, setIsEdit, fetchFunctionList]);

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  // Get data to view
  useEffect(() => {
    if ((keyword || !showFilter || (showFilter && !isEmpty(filterData))) && !isEdit) {
      fetchFunctionList();
    }
  }, [keyword, showFilter, filterData, isEdit, fetchFunctionList]);

  useEffect(() => {
    if (!deleteRecord) return;
    handleDeleteRecord();
  }, [deleteRecord]);

  useEffect(() => {
    if (!selectedRecordEdit) return;
    hancleEditRecord();
  }, [selectedRecordEdit, hancleEditRecord]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarMapping
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        setDeleteSelecteds={setDeleteSelecteds}
        deleteSelecteds={deleteSelecteds}
        updateStatusVisible={updateStatusVisibleDeleteMultiple}
        setUpdateStatusVisible={setUpdateStatusVisibleDeleteMultiple}
        handleDeleteRecord={handleDeleteRecord}
        loading={loading}
        setFilterData={setFilterData}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        filterOptions={filterOptions}
      />
      {!isEmpty(data) ? (
        <DataTableManaging
          data={data}
          setDeleteRecord={setDeleteRecord}
          setDeleteSelecteds={setDeleteSelecteds}
          setSeletedRows={setSeletedRows}
          seletedRows={seletedRows}
          handleDeleteRecord={handleDeleteRecord}
          totalData={totalData}
          setPage={setPage}
          page={page}
          limit={limit}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          setSelectedRecordEdit={setSelectedRecordEdit}
        />
      ) : (
        <SearchDataTableNotFound
          content={CONTENT_SEARCH_NOT_FOUND.FUNCTION_LIST}
          notFoundIcon={search_data_not_found}
        />
      )}
    </Spin>
  );
}
