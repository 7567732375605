import { Col, Row, Spin } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AiButton } from '../../../../../assets/common';
import { CategoryDropdownCustom } from '../../../../../components';
import { isCheckError, showError } from '../../../../../hooks/useFormik';
import {
  useGetBusinessUnitOptions,
  useGetDataContentOptions,
} from '../../../../../hooks/useGetBuMapping';
import { masterDataManagementApi } from '../../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  BU_MAPPING_MESSAGE,
  DATA_FIELD_IN_ORACLE,
  MESSAGE_TYPES,
  SELECT_ALL_LABEL,
} from '../../../../../utils/constants';
import {
  AiFormWrapper,
  AiLabel,
  AiTitle,
} from '../../../components/NewSuccessionPlanningForm/Styled';
import fetch_data_option from '../MenuBarMapping/fetch-filter-option.json';
import * as styles from './add-new-mapping-bu-opu-devision.module.scss';
import { formMappingvalidationSchema, handleCheckEmptyValue } from './formMappingValidation';

const AddNewMappingBuOpuDevision = () => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const history = useHistory();
  const dispatch = useDispatch();

  // Use state
  const [loadingPage, setLoadingPage] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      business_unit: '',
      data_field_in_oracle: '',
      data_content_in_oracle: '',
    },
    validationSchema: formMappingvalidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  // Use hooks
  const { dataContent: dataContentOptions } = useGetDataContentOptions({
    dataField: formik?.values?.data_field_in_oracle?.id,
  });
  const { buOptions } = useGetBusinessUnitOptions();

  const handleAddNewBuMapping = async () => {
    await formik.submitForm();
    const values = formik.values;
    const isCheck = handleCheckEmptyValue(values);
    if (!formik.isValid || isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, BU_MAPPING_MESSAGE.FILL_ALL_FIELD_REQUIRED);
      return;
    }

    fetchApiToAddNewBuMapping();
  };

  const fetchApiToAddNewBuMapping = async () => {
    try {
      setLoadingPage(true);
      const values = formik.values;
      const body = {
        business_unit: values?.business_unit?.id,
        field_in_oracle: getDataFieldInOracle(Number(values?.data_field_in_oracle?.id)),
        data_in_oracle: values?.data_content_in_oracle?.id,
      };
      const res = await masterDataManagementApi.createBuMapping(body, roleActive?.roleId);
      if (res.status === 200) {
        handleCancel();
        handleResetForm();
        handleShowMessage(MESSAGE_TYPES.SUCCESS, BU_MAPPING_MESSAGE.BU_ADD_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      const dataError = error?.response?.data;
      if (
        dataError?.code === 500 &&
        dataError?.errors.includes(BU_MAPPING_MESSAGE.MAPPING_ALREADY_EXIST)
      ) {
        handleShowMessage(MESSAGE_TYPES.WARNING, BU_MAPPING_MESSAGE.MAPPING_ALREADY_EXIST);
      } else if (dataError?.errors === BU_MAPPING_MESSAGE.DATA_ORACLE_EXISTED) {
        handleShowMessage(MESSAGE_TYPES.ERROR, BU_MAPPING_MESSAGE.DATA_ORACLE_EXISTED);
      } else {
        handleShowMessage(MESSAGE_TYPES.ERROR, BU_MAPPING_MESSAGE.AN_UNEXPECTED_ERROR);
      }
    }
    setLoadingPage(false);
  };

  const getDataFieldInOracle = (id) => {
    let tempValue = '';
    switch (id) {
      case 0:
        tempValue = DATA_FIELD_IN_ORACLE.BUSINESS_UNIT;
        break;
      case 1:
        tempValue = DATA_FIELD_IN_ORACLE.COMPANY;
        break;
      case 2:
        tempValue = DATA_FIELD_IN_ORACLE.DIVISION;
        break;
      case 3:
        tempValue = DATA_FIELD_IN_ORACLE.JOB_FAMILY;
        break;
      default:
        break;
    }

    return tempValue;
  };

  const handleCancel = () => {
    history.push(`/admin/master-data-management/mapping-for-business`);
  };

  const handleResetForm = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({}, false);
  };

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  // Reset Data Content in Oracle
  useEffect(() => {
    if (formik.values.data_field_in_oracle) {
      formik.setFieldValue('data_content_in_oracle', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.data_field_in_oracle]);

  return (
    <div className="max-width">
      <h3 className={styles.mainTitle}>
        Add New Record
        <span className={styles.subText} data-testid="noteTitle">
          Mapping for Business/OPU/Division
        </span>
      </h3>
      <Spin spinning={loadingPage} size="large">
        <AiFormWrapper>
          <AiTitle>Add New Details</AiTitle>
          <Row gutter={[16, 16]} className="mb-3">
            <Col span={8}>
              <AiLabel>Business Unit</AiLabel>
              <CategoryDropdownCustom
                selected={formik.values.business_unit}
                setSelected={(event) => formik.setFieldValue('business_unit', event)}
                options={
                  !isEmpty(buOptions)
                    ? buOptions
                        .filter((item) => item.label !== SELECT_ALL_LABEL)
                        .map((option) => {
                          return {
                            id: `${option?.id}`,
                            name: `${option?.label}`,
                          };
                        })
                    : []
                }
                placeholder="Select"
                status={isCheckError(formik, 'business_unit') ? 'error' : ''}
              />
              {showError(formik, 'business_unit')}
            </Col>
            <Col span={6}>
              <AiLabel>Data Field in Oracle</AiLabel>
              <CategoryDropdownCustom
                selected={formik.values.data_field_in_oracle}
                setSelected={(event) => formik.setFieldValue('data_field_in_oracle', event)}
                options={
                  !isEmpty(fetch_data_option.data_field)
                    ? fetch_data_option.data_field
                        .filter((item) => item.label !== SELECT_ALL_LABEL)
                        .map((option) => {
                          return {
                            id: `${option?.value}`,
                            name: `${option?.label}`,
                          };
                        })
                    : []
                }
                placeholder="Select"
                status={isCheckError(formik, 'data_field_in_oracle') ? 'error' : ''}
              />
              {showError(formik, 'data_field_in_oracle')}
            </Col>
            <Col span={8}>
              <AiLabel>Data Content in Oracle</AiLabel>
              <CategoryDropdownCustom
                disabled={isEmpty(dataContentOptions)}
                selected={formik.values.data_content_in_oracle}
                setSelected={(event) => formik.setFieldValue('data_content_in_oracle', event)}
                options={
                  !isEmpty(dataContentOptions)
                    ? dataContentOptions
                        .filter((item) => item.label !== SELECT_ALL_LABEL)
                        .map((option) => {
                          return {
                            id: `${option?.id}`,
                            name: `${option?.label}`,
                          };
                        })
                    : []
                }
                placeholder="Select"
                status={isCheckError(formik, 'data_content_in_oracle') ? 'error' : ''}
              />
              {showError(formik, 'data_content_in_oracle')}
            </Col>
          </Row>
        </AiFormWrapper>
      </Spin>

      <div className={styles.controlBar} style={{ marginBottom: '30px' }}>
        <AiButton onClick={handleCancel}>Cancel</AiButton>
        <AiButton mode={'teal'} onClick={() => handleAddNewBuMapping()}>
          Add
        </AiButton>
      </div>
    </div>
  );
};

export default AddNewMappingBuOpuDevision;
