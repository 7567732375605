import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { BasicButton } from '../../../../../../assets/common';
import { add_external_link, external_trash } from '../../../../../../assets/img';
import FormInput from '../../../../../../components/Form/FormInput/FormInput';
import styles from './assessment.module.scss';

export const FirstRow = (props) => {
  const { data, setData } = props;

  return (
    <Row justify="space-between" className={styles.margin}>
      <Col style={{ width: '48%' }}>
        <div className={styles.sub_title}>Performance Rating</div>
        {data.performance_rating.map((item, index) => (
          <Row justify="space-between" key={`performance-rating-${index}`}>
            <Col style={{ width: '25%' }}>
              <FormInput borderColor="#D3DEE8" placeholder="Year" value={item.year} />
            </Col>
            <Col style={{ width: '70%' }}>
              <FormInput
                borderColor="#D3DEE8"
                placeholder="Description"
                value={item.description}
                setFormData={(value) => {
                  let newPerformance = data.performance_rating;
                  newPerformance = newPerformance.map((newItem) => {
                    if (newItem.year === item.year) newItem.description = value;
                    return newItem;
                  });
                  setData('assessment', { ...data, performance_rating: newPerformance });
                }}
              />
            </Col>
          </Row>
        ))}
      </Col>
      <Col style={{ width: '48%' }}>
        <div className={styles.sub_title}>BePCB</div>
        {data.be_pcb.map((item, index) => (
          <Row justify="space-between" key={`be-pcb-${index}`}>
            <Col style={{ width: '25%' }}>
              <FormInput borderColor="#D3DEE8" placeholder="Year" value={item.year} />
            </Col>
            <Col style={{ width: '70%' }}>
              <FormInput
                borderColor="#D3DEE8"
                placeholder="Description"
                value={item.description}
                setFormData={(value) => {
                  let newBePcb = data.be_pcb;
                  newBePcb = newBePcb.map((newItem) => {
                    if (newItem.year === item.year) newItem.description = value;
                    return newItem;
                  });
                  setData('assessment', { ...data, be_pcb: newBePcb });
                }}
              />
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
};

export const SecondRow = (props) => {
  const { data, setData } = props;
  const externalLinks = data.external_link;

  function addExternalLinks() {
    setData('assessment', { ...data, external_link: [...externalLinks, ''] });
  }

  function delExternalLinks(delIndex) {
    let newExternalLinks = externalLinks;
    setData('assessment', {
      ...data,
      external_link: newExternalLinks.filter((_item, index) => delIndex !== index),
    });
  }

  return (
    <>
      <div className={styles.margin} style={{ padding: '20px' }}>
        <div className={styles.sub_title}>External Link</div>
        {!isEmpty(externalLinks) &&
          externalLinks.map((item, index) => (
            <Row key={`external-link-${index}`}>
              <div style={{ width: '400px' }}>
                <FormInput
                  borderColor="#D3DEE8"
                  placeholder="Enter External Link"
                  value={item}
                  setFormData={(value) => {
                    let newLinks = externalLinks;
                    newLinks = newLinks.map((newItem, newIndex) => {
                      if (index === newIndex) newItem = value;
                      return newItem;
                    });
                    setData('assessment', { ...data, external_link: newLinks });
                  }}
                />
              </div>
              <img
                src={external_trash}
                alt={external_trash}
                className={styles.btn}
                onKeyDown={() => {}}
                onClick={() => {
                  delExternalLinks(index);
                }}
              />
            </Row>
          ))}
        <div style={{ marginTop: '15px', width: '200px' }}>
          <BasicButton mode="border-teal" onKeyDown={() => {}} onClick={addExternalLinks}>
            <img src={add_external_link} alt={add_external_link} />
          </BasicButton>
        </div>
      </div>
    </>
  );
};

const AssessmentExternal = (props) => {
  const { data, setData } = props;

  return (
    <div className={styles.margin}>
      <div className={styles.title} data-testid="external-assessment">
        Assessment
      </div>
      <FirstRow data={data} setData={setData} />
      <SecondRow data={data} setData={setData} />
    </div>
  );
};

export default AssessmentExternal;
