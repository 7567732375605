import { get, isEmpty, union } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { meetingApi } from '../../../../../../services/tcMeeting';
import MeetingTalentData from './MeetingTalentData/MeetingTalentData';
import MenuBarMeetingOptionBuilder from './MenuBarMeetingOptionBuilder/MenuBarMeetingOptionBuilder';

const MeetingOption = (props) => {
  const { setShowError, setLoading, handleAddToReport } = props;
  const [query, setQuery] = useState({
    search: '',
    page: 1,
    totalPage: 0,
    limit: 15,
    from_date: null,
    to_date: null,
    isFirstRender: true,
  });

  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [talents, setTalents] = useState([]);

  const roleActive = useSelector((state) => state.user.roleActive);

  async function getResult() {
    try {
      setLoading(true);
      const { totalPage, isFirstRender, ...params } = query;
      const res = await meetingApi.getTalentToBuilder(params, roleActive);
      const data = get(res, 'data.result.agendas') || [];
      const totalPageRes = get(res, 'data.result.total') || 0;
      setData(data);
      setQuery((prev) => ({ ...prev, totalPage: totalPageRes }));
    } catch (error) {
      console.log(error);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (query.isFirstRender) return setQuery((prev) => ({ ...prev, isFirstRender: false }));
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.search, query.from_date, query.to_date]);

  useEffect(() => {
    setSelectedRows([]);
    setTalents([]);
  }, [query.search, query.from_date, query.to_date]);

  async function addToReport() {
    const staffIds = union(talents.map((item) => item.staff_id)).join(',');
    await handleAddToReport(talents, staffIds, roleActive);
  }

  return (
    <div>
      <MenuBarMeetingOptionBuilder
        setQuery={setQuery}
        query={query}
        setShowError={setShowError}
        getResult={getResult}
      />

      {!isEmpty(data) && (
        <>
          <div className="search-result">
            Search Result: <span className="search-keyword">{query.search}</span>
          </div>
          <MeetingTalentData
            data={data}
            setData={setData}
            query={query}
            setQuery={setQuery}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            talents={talents}
            setTalents={setTalents}
          />
          <div className="d-flex justify-content-end">
            <div className="button report-button" onKeyDown={() => {}} onClick={addToReport}>
              Add to Report
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MeetingOption;
