import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { radio_select, radio_unselect } from '../../../../assets/img';
import ManualRunStatus from './ManualRunStatus/ManualRunStatus';
import PositionWithMatchingCriteria from './PositionWithMatchingCriteria/PositionWithMatchingCriteria';

const Wrapper = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.235px;
  display: flex;
  margin: 20px 0;
  gap: 30px;
`;

const TABLE_TYPES = {
  MATCHING_CRITERIA: 1,
  MANUAL_RUN_STATUS: 2,
};

export default function ManagingCriteriaCP(props) {
  const history = useHistory();
  const { search } = useLocation();

  const objSearch = queryString.parse(search);
  const [selected, setSelected] = useState(TABLE_TYPES.MATCHING_CRITERIA);

  const onSelected = (value) => {
    history.push({
      pathname: '/admin/sp-management/managing-criteria-for-critical-position',
      search: '?type=' + value,
    });
  };

  useEffect(() => {
    if (objSearch?.type) {
      setSelected(Number(objSearch.type));
    }
  }, [objSearch]);

  return (
    <>
      <Wrapper>
        <div
          className="d-flex align-items-center"
          style={{ cursor: 'pointer' }}
          onKeyDown={() => {}}
          onClick={() => onSelected(TABLE_TYPES.MATCHING_CRITERIA)}
        >
          <img
            src={selected === TABLE_TYPES.MATCHING_CRITERIA ? radio_select : radio_unselect}
            alt=""
          />
          &nbsp;&nbsp;&nbsp;
          <span
            style={{
              color: `${selected === TABLE_TYPES.MATCHING_CRITERIA ? '#009089' : '#3F3C4C'}`,
            }}
          >
            Position With Matching Criteria
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ cursor: 'pointer' }}
          onKeyDown={() => {}}
          onClick={() => onSelected(TABLE_TYPES.MANUAL_RUN_STATUS)}
        >
          <img src={selected === 2 ? radio_select : radio_unselect} alt="" />
          &nbsp;&nbsp;&nbsp;
          <span style={{ color: `${selected === 2 ? '#009089' : '#3F3C4C'}` }}>
            Manual Run Status
          </span>
        </div>
      </Wrapper>
      {selected === TABLE_TYPES.MATCHING_CRITERIA && <PositionWithMatchingCriteria />}
      {selected === TABLE_TYPES.MANUAL_RUN_STATUS && <ManualRunStatus />}
    </>
  );
}
