import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  address,
  calendar_meeting_detail,
  close_meeting_detail,
  time,
} from '../../../../assets/img';
import { calendarApi } from '../../../../services/calendarApi';
import { CALENDAR, DATE_TIME_FORMAT, MEETING_STATUS } from '../../../../utils/constants';
import { changeMeetingStatus } from '../../../Meeting/redux/meetingDetailSlice';
import { Container } from './styled';

const PopupDetail = (props) => {
  const { setIsComponentVisible, currentDate, limit, params } = props;

  const user = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentDate) return;
    const query = {
      dateFilterType: 'date',
      date: currentDate,
      limit: limit,
    };
    async function getMeetingByViewMonth() {
      setLoading(true);
      try {
        const res = await calendarApi.getMeetings({ ...query, ...params }, user.roleActive);
        if (res.status === 200) {
          setData(res.data.result[0].meetings);
        }
      } catch (error) {
        console.log(error);
        setData([]);
      }
      setLoading(false);
    }
    getMeetingByViewMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.roleActive]);

  const handleClickMeeting = (meeting) => {
    return meeting.status === CALENDAR.MEETING_STATUS.IN_PLANNING &&
      meeting.is_secretariat_or_facilitator === 0
      ? null
      : getRedirectUrl(meeting);
  };

  const getRedirectUrl = (meeting) => {
    dispatch(changeMeetingStatus(false));
    return history.push(
      meeting.status === CALENDAR.MEETING_STATUS.IN_PLANNING
        ? `/meeting/${meeting.meeting_id}`
        : `/meeting/detail/${meeting.meeting_id}`
    );
  };

  return (
    <Container data-testid="popup-detail">
      <div className="d-flex justify-content-between align-items-center">
        <div className="meeting-detail">My Calendar</div>
        <img
          src={close_meeting_detail}
          alt=""
          onKeyDown={() => {}}
          onClick={() => setIsComponentVisible(false)}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {loading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          {!isEmpty(data) &&
            data.map((d, i) => (
              <div style={{ marginTop: '15px' }} key={`popup-${i}`} data-testid="popup-detail-data">
                <div
                  style={{
                    backgroundColor: MEETING_STATUS.find((v) => v.status === d.status)?.color,
                    borderRadius: '6px',
                    color: 'white',
                    display: 'inline-block',
                    padding: '3px 12px',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  {d.status}
                </div>
                <div
                  className="meeting-detail-name"
                  onKeyDown={() => {}}
                  onClick={() => handleClickMeeting(d)}
                  style={
                    d.status === CALENDAR.MEETING_STATUS.IN_PLANNING &&
                    !d.is_secretariat_or_facilitator
                      ? { cursor: 'default' }
                      : null
                  }
                >
                  {d.name}
                </div>

                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{ gap: '10px', margin: '10px 0' }}
                >
                  <img src={calendar_meeting_detail} alt="" />
                  <div className="meeting-detail">
                    {moment(currentDate).format(DATE_TIME_FORMAT.DATE_SPACE)}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{ gap: '10px', margin: '10px 0' }}
                >
                  <img src={address} alt="" style={{ width: '15px', height: '15px' }} />
                  <div className="meeting-detail">{d.location}</div>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{ gap: '10px', margin: '10px 0' }}
                >
                  <img src={time} alt="" />
                  <div className="meeting-detail">
                    {moment(d.start_at).format(DATE_TIME_FORMAT.time)} -{' '}
                    {moment(d.end_at).format(DATE_TIME_FORMAT.time)}
                  </div>
                </div>
                <Divider style={{ backgroundColor: '#fff' }} />
              </div>
            ))}
        </div>
      )}
    </Container>
  );
};

export default PopupDetail;
