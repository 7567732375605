import { unwrapResult } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { useFormik } from 'formik';
import { isEmpty, join, size } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import CalendarMeeting from '../../../../../assets/img/CalendarMeeting.svg';
import { AlertMessage } from '../../../../../components';
import { SelectMultipleSearch } from '../../../../../components/SelectMultiple/SelectMultiple';
import { isCheckError, onChangeInputData, showError } from '../../../../../hooks/useFormik';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { DATE_TIME_FORMAT, MESSAGE_TYPES, USER_ID } from '../../../../../utils/constants';
import { rootStyles } from '../../../../../utils/stylesHelper';
import { searchUserSync, sendSyndication } from '../../../../Agenda/store/syndicationSlice';
import { syndicationSelector } from '../../../selector';
import { AiTimePickerField } from '../../Styled';
import Input from '../Input/Input';
import Label from '../Label/Label';
import { FIELD, NOTIFICATION, REQUIRED_TEXT, SYNDICATION_TEXT } from './constants';
import styles from './syndication-dialog.module.scss';

const validationSchema = yup.object({
  [FIELD.title]: yup.string().required(REQUIRED_TEXT.SYNDICATION),
  [FIELD.date]: yup.string().required(REQUIRED_TEXT.DATE),
  [FIELD.start_time]: yup
    .string()
    .required(REQUIRED_TEXT.START_TIME)
    .test('is-greater', 'Start Time must be less than or equal to End Time', function (value) {
      const { end_time } = this.parent;
      const startTimeMoment = moment(value, DATE_TIME_FORMAT.TIME);
      const endTimeMoment = moment(end_time, DATE_TIME_FORMAT.TIME);

      if (isEmpty(end_time) || end_time === value) return true;
      return endTimeMoment.isAfter(startTimeMoment);
    }),
  [FIELD.end_time]: yup.string().required(REQUIRED_TEXT.END_TIME),
  [FIELD.invite_ids]: yup.array().min(1, REQUIRED_TEXT.INVITE_LIST),
});

const SyndicationDialog = (props) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const { loading } = useSelector(syndicationSelector);
  const { show, setShow } = props;
  const [isActive, setIsActive] = useState(false);
  const header = { role: roleActive, id: USER_ID };
  const agendaDetail = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const { date } = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const dispatch = useDispatch();
  const ref = useRef();

  const [isSubmit, setIsSubmit] = useState(false);

  if (size(agendaDetail) > 0) {
    // do something
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [FIELD.start_time]: moment(`${date} ${agendaDetail.start_time}`).format(
        DATE_TIME_FORMAT.TIME
      ),
      [FIELD.end_time]: moment(`${date} ${agendaDetail.end_time}`).format(DATE_TIME_FORMAT.TIME),
      [FIELD.title]:
        size(agendaDetail) > 0 &&
        join(
          agendaDetail.positions.map((item) => {
            let text = item.position_name;
            if (item.position_label) {
              text += ', ' + item.position_label;
            }
            if (item.business_unit) {
              text += ', ' + item.business_unit;
            }
            if (item.position_code) {
              text += '(' + item.position_code + ')';
            }
            if (item.job_grade) {
              text += ', JG:' + item.job_grade;
            }
            return text;
          }),
          ','
        ),
      [FIELD.date]: moment(date).format(DATE_TIME_FORMAT.SHORT_DAY),
      [FIELD.invite_ids]: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const inviteList = values.invite_ids.map((item) => Number(item));

      const result = dispatch(
        sendSyndication({
          ...header,
          agenda_id: agendaDetail.agenda_id,
          version: agendaDetail.version,
          body: {
            start_time: moment(values.start_time, DATE_TIME_FORMAT.TIME).toDate(),
            end_time: moment(values.end_time, DATE_TIME_FORMAT.TIME).toDate(),
            title: values.title,
            date: values.date,
            invite_ids: inviteList,
          },
        })
      );
      const resultUnwrap = unwrapResult(result);
      if (!resultUnwrap) {
        notification.success({
          message: `${NOTIFICATION.SUCCESS}`,
          placement: 'topRight',
          duration: 1.5,
        });
        formik.resetForm();
        handleClose();
        dispatch(
          pushMessage({
            message: 'Syndication has been sent successfully',
            type: MESSAGE_TYPES.SUCCESS,
          })
        );
      } else {
        notification.error({
          message: `${NOTIFICATION.ERROR}`,
          placement: 'topRight',
          duration: 1.5,
        });
      }
    },
  });

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };

  const handleSubmit = async () => {
    await formik.submitForm();
    setIsSubmit(true);
  };

  const syndicationStyles = {
    ...rootStyles,
    control: (provided, _state) => ({
      ...provided,
      minHeight: 44,
      borderColor: (isCheckError(formik, FIELD.invite_ids) && 'red') || 'hsl(0, 0%, 80%)',
    }),
  };

  /// handle data
  const handleChangeDate = (e) => {
    formik.setFieldValue(FIELD.date, e._d);
    setIsActive(false);
  };

  const onChangeSelectData = (event, key) => {
    let list = [];
    if (event.length > 0) {
      list = event.map((e) => e.value.toString());
    }
    formik.setFieldValue(key, list);
  };

  const getAsyncOptions = async (inputValue) => {
    try {
      const resultAction = await dispatch(
        searchUserSync({
          ...header,
          keyword: inputValue,
          agenda_id: agendaDetail.agenda_id,
          version: agendaDetail.version,
        })
      );
      const result = unwrapResult(resultAction);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  useEffect(() => {
    if (!show) {
      formik.setErrors({});
    }
  }, [formik, show]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        dialogClassName={styles.modal__size}
        data-testid="edit-tagging"
      >
        <Modal.Header closeButton className={styles.modal__header}>
          <Modal.Title className={styles.modal__title}>{SYNDICATION_TEXT.TITLE}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.modal__body}>
          <p className={styles.modal__sub_title}>{SYNDICATION_TEXT.SUB_TITLE}</p>
          <div className={styles.modal__form}>
            <form onSubmit={formik.handleSubmit}>
              {size(formik.errors) > 0 && isSubmit && (
                <div style={{ marginBottom: '20px' }}>
                  <AlertMessage
                    message="There are items that require your attention. Please fill out this field."
                    type="error"
                  />
                </div>
              )}
              <div className={styles.modal__row1}>
                <div className={styles.modal__group}>
                  <div>
                    <Label>{SYNDICATION_TEXT.SYNDICATION}</Label>
                    <Input
                      name={FIELD.title}
                      value={formik.values[FIELD.title]}
                      onChange={formik.handleChange}
                      placeholder={SYNDICATION_TEXT.PLACE_HOLDER_SYNDICATION}
                      style={{ borderColor: isCheckError(formik, FIELD.title) && 'red' }}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className={styles.modal__group}>
                  <Label>{SYNDICATION_TEXT.DATE}</Label>
                  <div
                    className={`${styles.dropdown} ${
                      isCheckError(formik, FIELD.date) && styles.error
                    }`}
                    ref={ref}
                  >
                    <div
                      className={styles.dropdownBtn}
                      onKeyDown={() => {}}
                      onClick={() => setIsActive(!isActive)}
                    >
                      {formik.values.date ? (
                        moment(formik.values.date).format(DATE_TIME_FORMAT.SHORT_DAY)
                      ) : (
                        <div className={styles.text_placeholder}>
                          {moment().format(DATE_TIME_FORMAT.SHORT_DAY)}
                        </div>
                      )}

                      <div>
                        <img src={CalendarMeeting} alt="" />
                      </div>
                    </div>

                    <div className={styles.dropdownContent}>
                      {isActive && (
                        <Datetime
                          className={styles.dropdownContentDatePicker}
                          input={false}
                          timeFormat={false}
                          onChange={(e) => handleChangeDate(e)}
                          dateFormat={DATE_TIME_FORMAT.DATE_STANDARD}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.modal__group}>
                  <Label>{SYNDICATION_TEXT.START_TIME}</Label>
                  <AiTimePickerField
                    placeholder={'Select Time'}
                    value={
                      formik.values.start_time
                        ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                        : null
                    }
                    style={{ width: '100%' }}
                    onChange={(_event, value) => onChangeInputData(formik, value, FIELD.start_time)}
                    status={isCheckError(formik, FIELD.start_time) ? 'error' : ''}
                    showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                    format={DATE_TIME_FORMAT.TIME}
                    showNow={false}
                    inputReadOnly={true}
                    allowClear={false}
                  />
                </div>

                <div className={styles.modal__group}>
                  <Label>{SYNDICATION_TEXT.END_TIME}</Label>
                  <AiTimePickerField
                    placeholder={'Select Time'}
                    value={
                      formik.values.start_time
                        ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                        : null
                    }
                    style={{ width: '100%' }}
                    onChange={(_event, value) => onChangeInputData(formik, value, FIELD.end_time)}
                    status={isCheckError(formik, FIELD.end_time) ? 'error' : ''}
                    showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                    format={DATE_TIME_FORMAT.TIME}
                    showNow={false}
                    inputReadOnly={true}
                    allowClear={false}
                  />
                </div>
              </div>

              <div className={`${styles.modal__row1} ${styles.modal__row1__mb}`}>
                <div className={styles.modal__group}>{showError(formik, FIELD.title)}</div>
                <div className={styles.modal__group}>{showError(formik, FIELD.date)}</div>
                <div className={styles.modal__group}>{showError(formik, FIELD.start_time)}</div>
                <div className={styles.modal__group}>{showError(formik, FIELD.end_time)} </div>
              </div>

              <div className={styles.modal__row2}>
                <div className={styles.modal__group__row2}>
                  <Label>{SYNDICATION_TEXT.INVITE_LIST}</Label>
                  <SelectMultipleSearch
                    cacheOptions
                    isMulti
                    isSearchable
                    placeholder={SYNDICATION_TEXT.PLACE_HOLDER_INVITE_LIST}
                    onChange={(e) => onChangeSelectData(e, FIELD.invite_ids)}
                    styles={syndicationStyles}
                    loadOptions={getAsyncOptions}
                  />
                </div>
              </div>

              <div className={styles.modal__row2}>
                <div className={styles.modal__group__row2}>
                  {showError(formik, FIELD.invite_ids)}
                </div>
              </div>

              <div className={styles.modal__btn}>
                <button
                  className="btn btn__label--blue btn__bg--white"
                  onKeyDown={() => {}}
                  onClick={handleClose}
                  disabled={loading}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className={`btn btn__label--white btn__bg--blue btn__margin-left ${styles.disabled_hover}`}
                  type="submit"
                  disabled={loading}
                  style={{ width: '200px' }}
                >
                  {loading && <Spinner animation="border" variant="light" size="sm" />}
                  {!loading && (
                    <span onKeyDown={() => {}} onClick={handleSubmit}>
                      Send Syndication
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SyndicationDialog;
