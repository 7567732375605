import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Pagination } from '..';
import { fetchContractDetails } from '../../services/talentProfiles';
import { CURRENT_YEAR } from '../../utils/constants';
import styles from './contract-details.module.scss';

const ContractDetails = (props) => {
  const { profileId, roleUser, isPrintPdf } = props;
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const limit = 4;

  useEffect(() => {
    async function fetchApi(body) {
      try {
        setLoading(true);
        const response = await fetchContractDetails(profileId, roleUser, body);
        const contracts = get(response, 'data.result') || [];
        setContracts(contracts);
        isEmpty(contracts) ? setTotalResults(1) : setTotalResults(contracts[0].total);
      } catch (error) {
        setContracts([]);
      } finally {
        setLoading(false);
      }
    }
    if (roleUser.roleActive.roleId && profileId) {
      if (!isPrintPdf) {
        fetchApi({ page, limit });
      } else {
        fetchApi({ isPrintPdf });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, profileId, page, isPrintPdf]);

  return (
    <div className={styles.SectionPlan} data-testid="contract-details">
      <div className={styles.SectionName}>Contract Details</div>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table">
            <thead>
              <tr className={styles.tablePlanThead}>
                <th scope="col" className="text-center">
                  No.
                </th>
                <th scope="col" style={{ width: '45%' }}>
                  Position
                </th>
                <th scope="col" className="text-center">
                  Business
                </th>
                <th scope="col" className="text-center">
                  Start Date
                </th>
                <th scope="col" className="text-center">
                  End Date
                </th>
              </tr>
            </thead>
            <tbody>
              {!!contracts && contracts.length > 0 ? (
                contracts?.map((contract, idx) => (
                  <tr key={idx} className={styles.tablePlanBody}>
                    <td className="text-center">{(page - 1) * limit + idx + 1}</td>
                    <td>{contract.position_name || '-'}</td>
                    <td className="text-center">{contract.business_unit || '-'}</td>
                    <td className="text-center">
                      {contract?.contract_start_date
                        ? moment(contract.contract_start_date).format('D MMMM YYYY')
                        : '-'}
                    </td>
                    <td className="text-center">
                      {!isEmpty(contract?.contract_end_date) &&
                      !contract.contract_end_date.includes(CURRENT_YEAR)
                        ? moment(contract?.contract_end_date).format('D MMMM YYYY')
                        : '-'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr key="0" className={styles.tablePlanBody}>
                  <td className="text-center">-</td>
                  <td>-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      {contracts && contracts.length > 0 && !isPrintPdf && (
        <Pagination page={page} rowPerPages={4} totalResults={totalResults} setPage={setPage} />
      )}
    </div>
  );
};

export default ContractDetails;
