import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AiButton, BasicButton } from '../../../../../assets/common';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { MULTIPLE_FILTER_TYPE } from '../../../../../utils/constants';
import AuditLogFilter from '../../../AuditLogs/components/AuditLogFilter/AuditLogFilter';
import * as styles from './menu-bar-mapping.module.scss';

export default function MenuBarMapping(props) {
  const {
    showFilter,
    setShowFilter,
    keyword,
    setKeyword,
    updateStatusVisible,
    setUpdateStatusVisible,
    deleteSelecteds,
    handleDeleteRecord,
    loading,
    setFilterData,
    setIsEdit,
    isEdit,
    filterOptions,
  } = props;

  const history = useHistory();

  // Use state
  const [searchInput, setSearchInput] = useState('');

  const handleAddNew = () => {
    history.push(`/admin/master-data-management/add-new-function`);
  };

  useEffect(() => {
    setSearchInput(keyword);
  }, [keyword]);

  return (
    <>
      {!isEdit && (
        <div>
          <Row align="bottom" justify="space-between">
            <ModelTC
              info={{
                type: 'deleteFunctionList',
                visible: updateStatusVisible,
                setVisible: setUpdateStatusVisible,
                handleSubmit: () => handleDeleteRecord(),
                loading,
                onClose: () => {
                  setUpdateStatusVisible(false);
                },
              }}
            />
            <Col span={16}>
              <Row align="bottom" gutter={8}>
                <Col span={18}>
                  <input
                    data-testid="search-input"
                    placeholder="Search Function List"
                    className={styles.searchInputField}
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                </Col>
                <Col>
                  <BasicButton
                    mode="teal"
                    onKeyDown={() => {}}
                    onClick={() => setKeyword(searchInput)}
                  >
                    Search
                  </BasicButton>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row align="bottom" justify="space-between" gutter={8}>
                <Col>
                  <BasicButton
                    onKeyDown={() => {}}
                    onClick={() => deleteSelecteds.length > 0 && setUpdateStatusVisible(true)}
                    className={`${styles.button} ${
                      !deleteSelecteds.length ? styles.inActiveDelBtn : styles.activeDelBtn
                    }`}
                    disabled={!deleteSelecteds.length}
                  >
                    Confirm Delete List
                  </BasicButton>
                </Col>
                <Col>
                  <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleAddNew}>
                    Add New
                  </BasicButton>
                </Col>
                <Col>
                  <div className={styles.icons}>
                    <img
                      src={showFilter ? finder_filter_enable : finder_filter_disable}
                      alt="Filter"
                      onKeyDown={() => {}}
                      onClick={() => setShowFilter(!showFilter)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {showFilter && (
            <Row style={{ marginTop: 22 }} gutter={16}>
              <AuditLogFilter
                filterOptions={filterOptions}
                setFilterFinderData={setFilterData}
                filterType={MULTIPLE_FILTER_TYPE.FUNCTION_LIST}
                isClearFilter={true}
                minWidth={'338'}
              />
            </Row>
          )}
        </div>
      )}

      {isEdit && (
        <Row align="bottom" justify="end">
          <AiButton onKeyDown={() => {}} onClick={() => setIsEdit(false)}>
            Back
          </AiButton>
        </Row>
      )}
    </>
  );
}
