import { Button } from 'antd';
import styled from 'styled-components';

import { color, font } from '../../../../../assets/common';

export const AiButton = styled(Button)`
  color: white;
  font-family: ${font.inter};
  font-weight: 600;
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: ${color.teal};
  &:hover,
  &:focus {
    background-color: ${color.teal};
    opacity: 0.85;
    border-color: ${color.teal};
    color: white;
  }
`;
