import React from 'react';
import styles from './wrap-profile.module.scss';
const WrapProfile = ({ children }) => {
  return (
    <>
      <div className={styles.SectionPlan} data-testid="wrap-profile">
        {children}
      </div>
    </>
  );
};

export default WrapProfile;
