import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;
  color: #fff !important;
  border-radius: 6px;

  width: 360px;
  height: 750px;
  background-color: #3f3c4c;
  padding: 20px;
  overflow: auto;

  position: absolute;
  top: 0;
  z-index: 10;
  transform: translate(-110%, -280px);

  .meeting-detail-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-decoration: underline;
    margin: 15px 0;
    cursor: pointer;
  }

  .meeting-detail {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
  }
`;
