import { notification } from 'antd';
import { debounce, isEmpty, unionBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { close_modal } from '../../../../../assets/img';
import { getListPosition } from '../../../../../services/successionplanningratio';
import { getListExcludePositions, handleShowPosition } from '../../../../../utils/helper';
import { TALENT_REVIEW_MESSAGE } from '../../../constants';
import styles from './search-position.module.scss';

const SearchPosition = (props) => {
  const {
    isShowSearchPopup,
    setIsShowSearchPopup,
    setAddTalent,
    title,
    addTalent,
    handleShowMessage,
  } = props;
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [searchList, setSearchList] = useState([]);

  const handleClose = () => setIsShowSearchPopup(false);

  const onClickAddTalent = async () => {
    if (isEmpty(searchTerm.value)) {
      return notification.error({
        message: `Please select a position`,
        placement: 'topRight',
        duration: 1.5,
      });
    } else {
      const positionDetail = searchTerm.value;
      setAddTalent((prev) => {
        return {
          ...prev,
          succession_planning: {
            first_line:
              title === '1st Line'
                ? [...prev.succession_planning.first_line, positionDetail]
                : prev.succession_planning.first_line,
            second_line:
              title === '2nd Line'
                ? [...prev.succession_planning.second_line, positionDetail]
                : prev.succession_planning.second_line,
            third_line:
              title === '3rd Line'
                ? [...prev.succession_planning.third_line, positionDetail]
                : prev.succession_planning.third_line,
            agreed_to_drop: prev.succession_planning.agreed_to_drop,
          },
        };
      });
      handleShowMessage('success', TALENT_REVIEW_MESSAGE.ADD_POSITION_SUCCESS);
      setIsShowSearchPopup(false);
    }
  };

  const handleSelectOption = async (item) => {
    let result = await handleShowPosition(item);
    await setSearchTerm({ value: item, type: 'submit', showTerm: result });
  };

  const handleShowListItems = () => {
    return searchList.map((item, index) => {
      return (
        <li
          data-testid={`${index}-pos`}
          className={styles.search_item}
          key={index}
          onKeyDown={() => {}}
          onClick={() => handleSelectOption(item)}
        >
          <div className={styles.individual}>
            <div className={styles.name}>
              {item.position_name} {item.job_grade && `(${item.job_grade})`}
            </div>
            {item.position_code && <div>{item.position_code}</div>}
            {(item.department_name || item.business_unit) && (
              <div>
                {item.department_name} {item.department_name && `, `} {item.business_unit}
              </div>
            )}
            {(item.division || item.opu_name) && (
              <div>
                {item.division}
                {item.opu_name && `, ${item.opu_name}`}
              </div>
            )}
            {(item.birth_name || item.staff_id) && (
              <div>
                {item.birth_name} {item.staff_id && `(${item.staff_id})`}
              </div>
            )}
          </div>
        </li>
      );
    });
  };

  const roleActive = useSelector((state) => state.user.roleActive);

  const handleOnChange = (value) => {
    setSearchTerm({ value, type: 'search', showTerm: value });
  };
  useEffect(() => {
    if (searchTerm.type !== 'search') return;
    setLoading(true);

    if (isEmpty(searchTerm.value)) {
      setSearchList([]);
      setLoading(false);
      return;
    }
    async function fetchAPI() {
      try {
        const excludePositions = getListExcludePositions(addTalent);
        const response = await getListPosition(searchTerm.value, excludePositions, roleActive);
        let result = unionBy(response.data.result, 'position_code');
        setSearchList(result);
      } catch (error) {
        setSearchList([]);
      }
      setLoading(false);
    }

    const debouncedSearch = debounce(async () => {
      await fetchAPI();
    }, 300);
    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm.value, roleActive.roleId]);

  return (
    <div>
      <Modal
        show={isShowSearchPopup}
        onHide={handleClose}
        dialogClassName={styles.talent_search_form}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <div className={styles.talent_search_content}>
          <Modal.Header className={styles.modal_header}>
            <div className="d-flex justify-content-end w-100">
              <img
                src={close_modal}
                alt="close_modal"
                style={{ cursor: 'pointer', pointerEvents: 'all' }}
                onKeyDown={() => {}}
                onClick={handleClose}
              />
            </div>
            <div style={{ fontWeight: '700', fontSize: '24px', color: '#3D3D3D' }}>
              Add Position to Succession Planning
            </div>
            <div style={{ fontWeight: '400', fontSize: '16px', color: '#7a7a7a' }}>
              Search position for succession planning
            </div>
          </Modal.Header>

          <Modal.Body className={styles.modal_body}>
            <div className={styles.form}>
              <div className={styles.search_box} tabIndex={0} data-testid="search-box">
                <input
                  id="position"
                  data-testid="position-input"
                  name="position"
                  placeholder="Search Position Code, Position Name or Staff Name, Staff ID"
                  className={styles.position_input}
                  onChange={(e) => handleOnChange(e.target.value)}
                  value={searchTerm.showTerm}
                  autoComplete="off"
                  // disabled={selectedSp}
                />
                {searchTerm.value && searchTerm.type === 'search' && (
                  <>
                    {/* Loading */}
                    {loading && (
                      <div className={styles.search_list} data-testid="loading">
                        <div data-testid="loading-pos" className={styles.dot_flashing}></div>
                      </div>
                    )}

                    {/* Show item */}
                    {!loading && !isEmpty(searchList) && (
                      <ul className={styles.search_list} data-testid="data-is-true">
                        {handleShowListItems()}
                      </ul>
                    )}

                    {/* Not Found */}
                    {!loading &&
                      isEmpty(searchList) &&
                      searchTerm.type === 'search' &&
                      !isEmpty(searchTerm.value) && (
                        <div className={styles.search_list} data-testid="data-is-false">
                          <p className={styles.search_notFound}>Search not found ...</p>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>

            <div className={styles.talent_search_button}>
              <button
                className={styles.add_talent_btn}
                onKeyDown={() => {}}
                onClick={onClickAddTalent}
              >
                Add
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default SearchPosition;
