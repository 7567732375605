import { cloneDeep } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
  board_pack,
  // dashboard,
  homepage_icon,
  knowledge_base,
  mobility_simulation,
  report_builder,
  settings,
  sp_chevron_down,
  sp_nav_icon,
  talent_finder,
} from '../../assets/img/';
import { roleIncumbentSelector } from '../../pages/selector';
import { PERMISSION } from '../../utils/constants';
import { isPermittedItem } from '../../utils/helper';
import styles from './menu-sidebar.module.scss';

const main_navigation = [
  {
    id: 666,
    icon: homepage_icon,
    name: 'Home',
    path: '/homepage',
    type: 'dashboard',
  },
  // {
  //   id: 1,
  //   icon: dashboard,
  //   name: 'Dashboard',
  //   path: '/dashboard',
  //   type: 'dashboard',
  // },
  // {
  //   id: 2,
  //   icon: analytics,
  //   name: 'Analytics',
  //   path: '/analytics',
  //   type: 'analytics',
  // },
  {
    id: 3,
    icon: talent_finder,
    name: 'Talent Finder',
    path: '/talent-finder',
    type: 'talent-finder',
  },
  {
    id: 4,
    icon: board_pack,
    name: 'TC Meeting',
    path: '/calendar',
    type: 'calendar',
    children: [{ id: 4, name: 'TC Meeting', path: '/meeting', type: 'meeting' }],
  },
  {
    id: 5,
    icon: sp_nav_icon,
    name: 'Succession Planning',
    path: '/succession-planning-page',
    type: 'succession-planning-page',
    permission: PERMISSION.SEARCH_VIEW_SP_DASHBOARD,
  },
  {
    id: 6,
    icon: mobility_simulation,
    name: 'Mobility Simulation',
    path: '/mobility-simulation',
    type: 'mobility-simulation',
  },
  {
    id: 9,
    icon: report_builder,
    name: 'Report Builder',
    path: '/report-builder',
    type: 'report-builder',
    permission: PERMISSION.REPORT_BUILDER,
  },
  {
    id: 7,
    icon: settings,
    name: 'Admin',
    children: [
      {
        id: 10,
        icon: '',
        name: 'User Access Management',
        path: '/admin/user-access-management',
        type: 'user-access-management',
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
      {
        id: 11,
        icon: '',
        name: 'Profile Management',
        path: '/admin/profile-management',
        type: 'profile-management',
        permission: PERMISSION.AD_PROFILE_MANAGEMENT,
      },
      {
        id: 12,
        icon: '',
        name: 'SP Management',
        path: '/admin/sp-management',
        type: 'sp-management',
        permission: [
          PERMISSION.EDIT_APPROVED_SUCCESSION_PLANNING,
          PERMISSION.MANAGING_CRITERIA_FOR_CRITICAL_POSITION,
          PERMISSION.MANAGING_CRITICAL_POSITION,
          PERMISSION.COPY_SUCCESSION_PLANNING_TO_OTHER_POSITION,
        ],
      },
      {
        id: 13,
        icon: '',
        name: 'Role Management',
        path: '/admin/role-management',
        type: 'role-management',
        permission: PERMISSION.AD_ROLE_MANAGEMENT,
      },
      {
        id: 14,
        icon: '',
        name: 'Master Data Management',
        path: '/admin/master-data-management',
        type: 'master-data-management',
        permission: PERMISSION.AD_MASTER_DATA_MANAGEMENT,
      },
      {
        id: 15,
        icon: '',
        name: 'Audit Logs',
        path: '/admin/audit-logs',
        type: 'audit-logs',
        permission: PERMISSION.AD_AUDIT_LOGS,
      },
    ],
  },
];

const support = [
  {
    id: 8,
    icon: knowledge_base,
    name: 'Help Document',
    path: '/help-document',
    type: 'help-document',
  },
  // { id: 9, icon: help, name: 'Help', path: '/help', type: 'support' },
];

const ChildMenu = (props) => {
  const { statusLeftMenu, item, itemActive } = props;
  const [open, setOpen] = useState(false);

  const openMenu = () => {
    if (!statusLeftMenu) {
      setOpen(false);
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (item.children && item.children.length > 0) {
      item.children.forEach((d) => {
        if (d.id === itemActive.id) {
          setOpen(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemActive.id]);

  return (
    <>
      <li className={styles.menu_parent} onKeyDown={() => {}} onClick={openMenu}>
        <div className={styles.icon}>
          <img src={item.icon} alt="" />
        </div>
        {statusLeftMenu && (
          <div className="d-flex justify-content-space-around align-items-center w-100">
            <div className={styles.text}>{item.name}</div>
            <div className={styles.chevron}>
              <img src={sp_chevron_down} alt="" className={open ? styles.animate : ``} />
            </div>
          </div>
        )}
      </li>

      <div className={`${styles.child_menu} ${open && styles.show_dropdown}`}>
        {statusLeftMenu &&
          item.children &&
          item.children.length > 0 &&
          item.children.map((d, i) => (
            <Link to={d.path} className={styles.link} key={i}>
              <div
                className={
                  d.id === itemActive.id
                    ? `${styles.menu_li}  ${styles.menu_sub_position} ${styles.active}`
                    : `${styles.menu_li}  ${styles.menu_sub_position}`
                }
              >
                <div className={`${styles.text} ${styles.children_name}`}>{d.name}</div>
              </div>
            </Link>
          ))}
      </div>
    </>
  );
};

const MenuList = (props) => {
  const { items, itemActive, statusLeftMenu } = props;

  const listItems = items.map((item, index) => (
    <Fragment key={index}>
      {item.path ? (
        <Link to={item.path} className={styles.link}>
          <li
            className={
              item.id === itemActive.id ? `${styles.menu_li} ${styles.active}` : styles.menu_li
            }
          >
            <div className={styles.icon}>
              <img
                style={item.name === 'Report Builder' ? { width: '24px', height: 'initial' } : {}}
                src={item.icon}
                alt=""
              />
            </div>
            {statusLeftMenu && <div className={styles.text}>{item.name}</div>}
          </li>
        </Link>
      ) : (
        <ChildMenu statusLeftMenu={statusLeftMenu} item={item} itemActive={itemActive} />
      )}
    </Fragment>
  ));
  return <ul className={styles.menu_ul}>{listItems}</ul>;
};

export const MenuSidebar = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [mainNavigation, setMainNavigation] = useState(main_navigation);
  const [itemActive, setItemActive] = useState(mainNavigation[0]);
  const roleInCumbent = useSelector(roleIncumbentSelector);
  const permissions = useSelector((state) => state.user.permissions);

  const { statusLeftMenu } = props;
  useEffect(() => {
    onSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleInCumbent]);
  useEffect(() => {
    setFilteredMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);
  //change menu active
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelected = useCallback(() => {
    let itemSelected = {};
    let arrChildren = [];

    mainNavigation.forEach((d) => {
      if (d.children && d.children.length > 0) {
        arrChildren = [...arrChildren, ...d.children];
      }
    });

    itemSelected = [...mainNavigation, ...support, ...arrChildren].find((item) => {
      return pathname.includes(item.type);
    });
    itemSelected ? setItemActive(itemSelected) : setItemActive(mainNavigation[0]);
  });

  useEffect(() => {
    onSelected();
  }, [onSelected, pathname]);

  const filterPermittedItemMainNavigation = (menus) => {
    const filteredMenus = [];
    for (const menu of menus) {
      if (!menu) continue;

      const isPermitted = isPermittedItem(menu.permission, permissions);
      if (!isPermitted) continue;

      let children = cloneDeep(menu.children);
      if (menu.children && menu.children.length > 0) {
        children = filterPermittedItemMainNavigation(menu.children);
      }
      const newMenu = { ...menu, children };
      filteredMenus.push(newMenu);
    }

    return filteredMenus;
  };

  const setFilteredMenu = () => {
    const menus = cloneDeep(main_navigation);
    const filteredMenus = filterPermittedItemMainNavigation(menus);
    setMainNavigation([...filteredMenus]);
  };

  return (
    <div data-testid="menu">
      {statusLeftMenu ? (
        <div className={styles.menu_wrapper}>
          <div className="main_navigation">
            <div className={styles.title}>main navigation</div>
            <MenuList
              items={mainNavigation}
              itemActive={itemActive}
              statusLeftMenu={statusLeftMenu}
            />
          </div>
          <div className="support">
            <div className={styles.title}>support</div>
            <MenuList
              items={support}
              onSelected={onSelected}
              itemActive={itemActive}
              statusLeftMenu={statusLeftMenu}
            />
          </div>
        </div>
      ) : (
        <div className={styles.menu_wrapper}>
          <div className="main_navigation">
            <div className={styles.title}></div>
            <MenuList
              items={mainNavigation}
              itemActive={itemActive}
              statusLeftMenu={statusLeftMenu}
            />
          </div>
          <div className="support">
            <div className={styles.title}></div>
            <MenuList
              items={support}
              onSelected={onSelected}
              itemActive={itemActive}
              statusLeftMenu={statusLeftMenu}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuSidebar;
