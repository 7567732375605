import { Dropdown, Space, Switch, Tooltip } from 'antd';
import { cloneDeep, isEmpty, isNumber, size } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { BasicButton } from '../../../../../../assets/common';
import { deleteNote, duplicate_icon, editNote, tree_dot_icon } from '../../../../../../assets/img';
import { pushMessage } from '../../../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  CRITERIA_BOOLEAN_TYPE,
  CRITERIA_COMPLEMENTARY_TYPE,
  CRITERIA_MANDATORY_TYPE,
  CRITERIA_NO_RANKING,
  CRITERIA_SPIKE_TYPE,
  CRITERIA_TYPE,
  FILL_OUT_REQUIRE_MESSAGE,
  FORM_TYPES,
  SET_MATCHING_CRITERIA_STATUS,
} from '../../../../../../utils/constants';
import { compareArrays } from '../../../../../../utils/helper';
import {
  resetCriteriaState,
  summaryEnteredCriteria,
  summaryUnRankEntered,
  updateIsEditSetMatching,
  updateIsSavedSetMatching,
  updateIsValidForm,
  updateMainMatching,
  updatePrevSetMatching,
} from '../../../redux/manageCriteriaSetMatchingSlice';
import AddCriteriaPopOutSetMatching from '../AddCriteriaPopOut/AddCriteriaPopOutSetMatching';
import MatchingCriteriaSummary from '../CriteriaSummary/MatchingCriteriaSummary';
import SelectedMatchingCriteriaForm from '../SelectedCriteriaForm/SelectedMatchingCriteriaForm';
import styles from './set-criteria-component.module.scss';

const SetCriteriaComponent = (props) => {
  const {
    mode,
    isSummary,
    setIsSummary,
    setShowSaveConfirm,
    setShowEditConfirm,
    setKeepRanking,
    keepRanking,
    currentTab,
    setShowDeleteModal,
    setShowDuplicateModal,
    setShowRunConfirm,
    rankingData,
    currentSetMatching,
    isNewSetMatching,
    setRankingData,
    duplicateSetMatching,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    position_code,
    position_name,
    set_index,
    set_matching_criteria,
    prevSetMatching,
    isSavedSetMatching,
  } = useSelector((state) => state.app.adminModule.manageCriteriaSetMatching);

  const [isUpdateSelectCriteria, setIsUpdateSelectCriteria] = useState(false);

  const currentSetMatchingFromStore = useMemo(() => {
    return set_matching_criteria[set_index];
  }, [set_matching_criteria, set_index]);

  const isDisabledEditBtn = useMemo(() => {
    return (
      isNewSetMatching ||
      (currentSetMatchingFromStore?.run_id &&
        ![SET_MATCHING_CRITERIA_STATUS.COMPLETED, SET_MATCHING_CRITERIA_STATUS.FAILED].includes(
          currentSetMatchingFromStore?.status
        ))
    );
  }, [currentSetMatchingFromStore, isNewSetMatching]);

  const isEnabledEditBtn = useMemo(() => {
    return (
      (!currentSetMatchingFromStore?.run_id &&
        [SET_MATCHING_CRITERIA_STATUS.NOT_AVAILABLE].includes(
          currentSetMatchingFromStore?.status
        )) ||
      (currentSetMatchingFromStore?.run_id &&
        [SET_MATCHING_CRITERIA_STATUS.COMPLETED, SET_MATCHING_CRITERIA_STATUS.FAILED].includes(
          currentSetMatchingFromStore?.status
        ))
    );
  }, [currentSetMatchingFromStore]);

  const isDisabledRunBtn = useMemo(() => {
    return !(
      +set_matching_criteria[set_index]?.set_id > 0 &&
      set_matching_criteria[set_index]?.history_not_uses?.length > 0 &&
      ![SET_MATCHING_CRITERIA_STATUS.COMPLETED, SET_MATCHING_CRITERIA_STATUS.FAILED].includes(
        set_matching_criteria[set_index]?.status
      )
    );
  }, [set_matching_criteria, set_index]);

  const menuProps = {
    items: [
      {
        key: '0',
        icon: (
          <img
            style={isDisabledEditBtn ? { opacity: 0.5, cursor: 'no-drop' } : {}}
            src={editNote}
            alt="edit"
          />
        ),
        label: (
          <div
            className={`${styles.commonBtn} ${styles.editBtn}`}
            style={isDisabledEditBtn ? { opacity: 0.5, cursor: 'no-drop' } : {}}
          >
            Edit
          </div>
        ),
        onClick: () => {
          if (isEnabledEditBtn) {
            dispatch(updateIsEditSetMatching({ set_index, value: true }));
            dispatch(updateIsSavedSetMatching(true));
          }
        },
      },
      {
        key: '1',
        icon: <img src={duplicate_icon} alt="duplicate" />,
        label: <div className={`${styles.commonBtn} ${styles.duplicateBtn}`}>Duplicate</div>,
        onClick: () => setShowDuplicateModal(true),
      },
      {
        key: '2',
        icon: <img src={deleteNote} alt="delete" />,
        label: <div className={`${styles.commonBtn} ${styles.deleteBtn}`}>Delete</div>,
        onClick: () => {
          setShowDeleteModal(true);
        },
      },
    ],
  };

  const isDisableSaveBtn = useMemo(() => {
    if (set_matching_criteria[set_index]?.entered_criteria) {
      return set_matching_criteria[set_index].entered_criteria.find(
        (item) => !item?.rank && !CRITERIA_NO_RANKING.includes(item.criteria)
      );
    } else {
      return false;
    }
  }, [set_matching_criteria, set_index]);

  const isDuplicateRanking = useMemo(() => {
    if (set_matching_criteria[set_index]?.entered_criteria) {
      const ranks = set_matching_criteria[set_index].entered_criteria
        .filter((item) => item.rank)
        .map((item) => item.rank);
      return ranks.some((item, index) => ranks.indexOf(item) !== index);
    } else {
      return false;
    }
  }, [set_matching_criteria, set_index]);

  const checkFillOutRequired = useCallback(() => {
    const formCriteria =
      !isEmpty(set_matching_criteria[set_index].entered_criteria) &&
      set_matching_criteria[set_index].entered_criteria.find(
        (item) => item.criteria === CRITERIA_TYPE.EDGE
      );
    if (!formCriteria) return true;

    const nullEdge = formCriteria.value.find((item) => !item.proficiency);
    return nullEdge ? false : true;
  }, [set_index, set_matching_criteria]);

  const handleNextToSummary = async () => {
    if (!prevSetMatching) {
      const tempListMatching = cloneDeep(set_matching_criteria);
      dispatch(
        updatePrevSetMatching(
          JSON.parse(
            JSON.stringify({
              position_code,
              position_name,
              set_index,
              set_matching_criteria: tempListMatching.map((item) => ({
                ...item,
                selected_criteria: item?.selected_criteria || [],
                entered_criteria: item?.entered_criteria || [],
                list_edit: item?.entered_criteria || [],
              })),
            })
          )
        )
      );
    }

    let cloneEntered = [];
    let enteredCriteria = [];
    if (prevSetMatching && !isSavedSetMatching) {
      cloneEntered = cloneDeep(prevSetMatching.set_matching_criteria[set_index].entered_criteria);
    } else {
      cloneEntered = cloneDeep(set_matching_criteria[set_index]?.entered_criteria);
    }
    let unRankEntered = [];
    let booleanEntered = [];

    const CRITERIA_SPIKE_MAP = {
      [CRITERIA_TYPE.EDGE]: CRITERIA_SPIKE_TYPE.EDGE_SPIKE,
      [CRITERIA_TYPE.ATTRIBUTES]: CRITERIA_SPIKE_TYPE.ATTRIBUTES_SPIKE,
      [CRITERIA_TYPE.BUSINESS_EXPOSURE_OPTIONAL]: CRITERIA_SPIKE_TYPE.BUSINESS_EXPOSURE_SPIKE,
    };

    const CRITERIA_COMPLEMENTARY_MAP = {
      [CRITERIA_TYPE.FUNCTIONAL]: CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_MANDATORY,
      [CRITERIA_TYPE.FUNCTIONAL_OPTIONAL]: CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_OPTIONAL,
      [CRITERIA_TYPE.FUNCTIONAL_SPIKE]: CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_SPIKE,
    };

    const checkSpikeOrComplementaryField = (item, index) => {
      if (Object.keys(CRITERIA_SPIKE_MAP).includes(item.criteria)) {
        if (item.criteria === CRITERIA_TYPE.EDGE) {
          const checkedEdgeSpike = item.value.filter((i) => i.spike);
          if (checkedEdgeSpike.length) {
            const edgeSpike = { criteria: CRITERIA_SPIKE_TYPE.EDGE_SPIKE, value: item.value };
            cloneEntered.push(edgeSpike);
          } else {
            const edgeSpike = {
              criteria: CRITERIA_SPIKE_TYPE.EDGE_SPIKE,
              value: item.value.map((i) => ({ ...i, proficiency: 0 })),
            };
            unRankEntered.push({ ...edgeSpike, rank: 0 });
          }
        } else {
          const remainCriteria = item.value.filter((i) => !i.spike);
          if (cloneEntered[index]?.value) {
            cloneEntered[index].value = remainCriteria;
          }
          const spikeCriteria = {
            criteria: CRITERIA_SPIKE_MAP[item.criteria],
            value: item.value.filter((i) => i.spike),
          };
          if (!isEmpty(spikeCriteria.value)) {
            cloneEntered.push(spikeCriteria);
          }
        }
      }

      if (Object.keys(CRITERIA_COMPLEMENTARY_MAP).includes(item.criteria)) {
        const checkedComplementary = item.value.filter((i) => i.complementary);
        if (checkedComplementary.length) {
          const complementary = {
            criteria: CRITERIA_COMPLEMENTARY_MAP[item.criteria],
            value: item.value.filter((i) => i.complementary),
            rank: 0,
          };
          cloneEntered.push(complementary);
        }

        const remainComplementaryCriteria = item.value.filter((i) => !i.complementary);
        if (cloneEntered[index]?.value) {
          cloneEntered[index].value = remainComplementaryCriteria;
        }
      }
    };

    const checkBooleanField = (item) => {
      if (!CRITERIA_BOOLEAN_TYPE.includes(item.criteria)) return;
      if (item.value[0]?.content !== null && item.value[0]?.content !== 'No') return;
      booleanEntered.push(item.criteria);
      unRankEntered.push({ ...item, rank: 0 });
    };

    const checkMandatoryField = (item) => {
      if (CRITERIA_MANDATORY_TYPE.includes(item.criteria)) {
        unRankEntered.push({ ...item, rank: 0 });
      }
    };

    let tempSetMatching = [];
    if (prevSetMatching && !isSavedSetMatching) {
      tempSetMatching = cloneDeep(
        prevSetMatching.set_matching_criteria[set_index].entered_criteria
      );
    } else {
      tempSetMatching = cloneDeep(set_matching_criteria[set_index]?.entered_criteria);
    }
    !isEmpty(tempSetMatching) &&
      tempSetMatching.forEach((item, index) => {
        checkSpikeOrComplementaryField(item, index);
        checkBooleanField(item);
        checkMandatoryField(item);
      });

    enteredCriteria = cloneEntered.filter(
      (item) =>
        !isEmpty(item.value) &&
        !CRITERIA_MANDATORY_TYPE.includes(item.criteria) &&
        !booleanEntered.includes(item.criteria)
    );
    if (
      !isEmpty(rankingData[set_index]) &&
      (enteredCriteria.length < rankingData[set_index].length ||
        (enteredCriteria.length === rankingData[set_index].length &&
          compareArrays(enteredCriteria, rankingData[set_index], 'criteria').length))
    ) {
      setKeepRanking(false);
    }
    if (
      (keepRanking || !isEmpty(rankingData[set_index])) &&
      (isNumber(set_matching_criteria[set_index]?.set_id) ||
        set_matching_criteria[set_index]?.isDuplicate === true)
    ) {
      const updatedRankingCriteria = enteredCriteria.map((item) => {
        const editRank = rankingData[set_index]?.find((i) => i.criteria === item.criteria);
        return editRank?.rank ? { ...item, rank: editRank.rank } : item;
      });
      dispatch(summaryEnteredCriteria(updatedRankingCriteria));
    } else {
      dispatch(summaryEnteredCriteria(enteredCriteria));
    }
    dispatch(summaryUnRankEntered(unRankEntered));
  };

  useEffect(() => {
    if (isSummary || isSavedSetMatching) {
      handleNextToSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSummary, isSavedSetMatching]);

  const handleViewResult = () => {
    history.push(
      `/admin/sp-management/view-result-matching/${position_code}/${set_matching_criteria[set_index]?.run_id}`
    );
  };

  const handleBackToStep1 = () => {
    if (isSavedSetMatching) {
      dispatch(updateIsSavedSetMatching(false));
    }
    if (!isNumber(set_matching_criteria[set_index]?.set_id) && !isSavedSetMatching) {
      setRankingData((prev) => {
        if (isEmpty(prev[set_index])) return prev;
        prev[set_index] = undefined;
        return prev;
      });
    }
    if (isSummary) {
      setIsSummary(false);
    }
  };

  const handleClickNextBtn = () => {
    if (!checkFillOutRequired()) {
      dispatch(updateIsValidForm(false));
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: ALERT_MESSAGE_TYPE.ERROR,
        })
      );
    } else {
      dispatch(updateIsValidForm(true));
      setIsSummary(true);
    }
  };

  const renderSetMatchingStatus = useMemo(() => {
    let tempColor = '#677C91';
    switch (set_matching_criteria[set_index]?.status) {
      case SET_MATCHING_CRITERIA_STATUS.COMPLETED:
        tempColor = '#0B882E';
        break;
      case SET_MATCHING_CRITERIA_STATUS.FAILED:
        tempColor = '#DA2228';
        break;
      case SET_MATCHING_CRITERIA_STATUS.RUNNING:
        tempColor = '#3685D3';
        break;
      case SET_MATCHING_CRITERIA_STATUS.QUEUE:
        tempColor = '#edab3d';
        break;
      default:
        break;
    }
    return (
      <span style={{ color: `${tempColor}` }}>{set_matching_criteria[set_index]?.status}</span>
    );
  }, [set_matching_criteria, set_index]);

  const getLastUpdate = useMemo(() => {
    return (
      (set_matching_criteria[set_index]?.end_date &&
        moment(set_matching_criteria[set_index]?.end_date).format('DD MMMM YYYY')) ||
      (set_matching_criteria[set_index]?.start_date &&
        moment(set_matching_criteria[set_index]?.start_date).format('DD MMMM YYYY')) ||
      '--'
    );
  }, [set_matching_criteria, set_index]);

  return (
    <>
      <div className={styles.setContainer}>
        <div className={styles.setHeader}>
          <div className={styles.mainTitle}>
            <h3>
              <span>Set {currentTab + 1}:</span> {set_matching_criteria[set_index].set_name}
              {set_matching_criteria[set_index].is_set_main && (
                <span className={styles.mainLabel}>Main</span>
              )}
            </h3>
            <div className={styles.setStatus}>
              <span>Last Update : {getLastUpdate}</span>
              <span>Status: {renderSetMatchingStatus}</span>
            </div>
          </div>
          {!isSummary && (
            <div className={styles.groupButton}>
              {!set_matching_criteria[set_index].is_set_main && (
                <div className={styles.asMainToggle}>
                  <Switch
                    onChange={(checked) => {
                      dispatch(updateMainMatching({ set_index, value: checked }));
                    }}
                    checked={set_matching_criteria[set_index].set_as_main}
                    style={{
                      backgroundColor: `${
                        set_matching_criteria[set_index].set_as_main ? '#009089' : ''
                      }`,
                    }}
                    disabled={!set_matching_criteria[set_index]?.isEditSetMatching}
                  />
                  <span className={styles.toggleLabel}>Set As Main</span>
                </div>
              )}
              <BasicButton
                disabled={
                  ![SET_MATCHING_CRITERIA_STATUS.COMPLETED].includes(
                    set_matching_criteria[set_index]?.status
                  )
                }
                style={{ marginRight: '12px' }}
                onClick={() => handleViewResult()}
              >
                View Result
              </BasicButton>
              <BasicButton
                style={{ marginRight: '12px' }}
                onClick={() => {
                  setIsUpdateSelectCriteria(true);
                  dispatch(updateIsEditSetMatching({ set_index, value: true }));
                }}
                disabled={!set_matching_criteria[set_index]?.isEditSetMatching}
              >
                Add Matching Criteria
              </BasicButton>
              <Dropdown
                menu={menuProps}
                trigger={['click']}
                style={{ minWidth: 110 }}
                placement="bottomRight"
                getPopupContainer={(trigger) => trigger.parentElement}
                overlayClassName={
                  (set_matching_criteria[set_index]?.isEditSetMatching ||
                    !Number(set_matching_criteria[set_index].set_id) ||
                    size(set_matching_criteria) >= 5 ||
                    isNewSetMatching) &&
                  'dropdown_disabledDuplicateBtn'
                }
              >
                <Space style={{ cursor: 'pointer' }}>
                  <img src={tree_dot_icon} alt="tree_dot_icon" />
                </Space>
              </Dropdown>
            </div>
          )}
        </div>

        <AddCriteriaPopOutSetMatching
          open={isUpdateSelectCriteria}
          setOpen={setIsUpdateSelectCriteria}
          position_code={position_code}
          position_name={position_name}
          isNewSetMatching={false}
          setIsSummary={setIsSummary}
        />

        {/* List Form */}
        {isSummary && <MatchingCriteriaSummary />}
        {!isSummary &&
          !isEmpty(currentSetMatchingFromStore?.selected_criteria) &&
          currentSetMatchingFromStore?.selected_criteria.map((item) => {
            return (
              <SelectedMatchingCriteriaForm
                data={item}
                key={!duplicateSetMatching ? `${item.criteria}.${set_index}` : item.criteria}
                mode={mode}
                currentSetMatching={currentSetMatching?.set_matching_criteria[set_index]}
                currentSetMatchingFromStore={currentSetMatchingFromStore}
                duplicateSetMatching={duplicateSetMatching}
              />
            );
          })}
      </div>
      <div className={styles.bottomBtnBar}>
        <Link to="/admin/sp-management/managing-criteria-for-critical-position">
          <BasicButton onClick={() => dispatch(resetCriteriaState())}>Cancel</BasicButton>
        </Link>
        {isSummary ? (
          <>
            <BasicButton style={{ minWidth: '75px' }} onClick={() => handleBackToStep1()}>
              Back
            </BasicButton>
            <BasicButton
              style={{ minWidth: '75px' }}
              mode="teal"
              disabled={
                isDisableSaveBtn ||
                isDuplicateRanking ||
                !set_matching_criteria[set_index]?.isEditSetMatching
              }
              onClick={() =>
                mode === FORM_TYPES.CREATE || !isNumber(set_matching_criteria[set_index]?.set_id)
                  ? setShowSaveConfirm(true)
                  : setShowEditConfirm(true)
              }
            >
              Save
            </BasicButton>
            {!isNumber(set_matching_criteria[set_index]?.set_id) && !isSavedSetMatching ? (
              <Tooltip
                className={styles.customTooltip}
                overlayClassName={'customTooltip'}
                overlayStyle={{ minWidth: '181px' }}
                title={
                  <div className={styles.tooltipContent}>
                    <span>Please save updated matching criteria set before you run. </span>
                  </div>
                }
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
              >
                <div className={styles.customRunBtn}>Run</div>
              </Tooltip>
            ) : (
              <BasicButton
                disabled={
                  isDisabledRunBtn && size(currentSetMatchingFromStore?.history_not_uses) <= 0
                }
                style={{ minWidth: '75px' }}
                mode="teal"
                onClick={() => {
                  setShowRunConfirm(true);
                }}
              >
                Run
              </BasicButton>
            )}
          </>
        ) : (
          !isEmpty(set_matching_criteria[set_index].selected_criteria) && (
            <BasicButton
              style={{ minWidth: '75px' }}
              mode="teal"
              onClick={() => handleClickNextBtn()}
            >
              Next
            </BasicButton>
          )
        )}
      </div>
    </>
  );
};

export default SetCriteriaComponent;
