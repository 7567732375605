import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { meetingApi } from '../services/tcMeeting';

export const useGetMeeting = ({ idMeeting }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const response = await meetingApi.getMeeting({ idMeeting, roleUser: user });
      setData(response.data.result);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMeeting, user.roleActive.roleId]);

  useEffect(() => {
    if (isEmpty(idMeeting)) {
      return;
    }
    fetch();
  }, [idMeeting, fetch]);

  return { data, loading, error, fetch };
};
