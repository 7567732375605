import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 18px;
  font-weight: 600;
  color: #666769;

  .meeting-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 40px;

    div {
      cursor: pointer;
    }
  }

  .active {
    color: #3f3c4c;
    font-weight: 700;
    border-bottom: 3px solid #3f3c4c;
    padding: 15px 0;
  }

  .meeting-status-legend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
`;
