import React from 'react';
import styles from './other-infor.module.scss';
import { Col, Row } from 'antd';
import FormInput from '../../../../../../components/Form/FormInput/FormInput';

export const FirstCol = (props) => {
  const { data, setData } = props;

  return (
    <>
      <div className={styles.sub_title}>Focal</div>
      <FormInput
        borderColor="#D3DEE8"
        placeholder="Enter Focal"
        value={data.focal}
        setFormData={(value) => setData('others', { ...data, focal: value })}
      />
    </>
  );
};

export const SecondCol = (props) => {
  const { data, setData } = props;

  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Source</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Source"
          value={data.source}
          setFormData={(value) => setData('others', { ...data, source: value })}
        />
      </div>
    </>
  );
};

export const ThirdCol = () => {
  return <></>;
};

const OtherInfor = (props) => {
  return (
    <div className={styles.margin}>
      <div className={styles.title} data-testid="external-other">
        Others
      </div>
      <Row justify="space-between">
        <Col style={{ width: '31%' }}>
          <FirstCol {...props} />
        </Col>
        <Col style={{ width: '31%' }}>
          <SecondCol {...props} />
        </Col>
        <Col style={{ width: '31%' }}>
          <ThirdCol />
        </Col>
      </Row>
    </div>
  );
};

export default OtherInfor;
