import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { CONTENT_SEARCH_NOT_FOUND, MESSAGE_TYPES } from '../../../../utils/constants';
import { PROFILE_MANAGEMENT_MESSAGES } from '../constant';
import UnsearchableSearchBar from './UnsearchableSearchBar/UnsearchableSearchBar';
import UnsearchableTalentTable from './UnsearchableTalentTable/UnsearchableTalentTable';

const UnsearchableTalentList = () => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({ list: [], total: 0 });
  const [searchParams, setSearchParams] = useState({
    keyword: '',
    page: 1,
    limit: 15,
  });
  const dispatch = useDispatch();

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
        })
      );
    },
    [dispatch]
  );

  const fetchData = useCallback(
    async (pageOne) => {
      if (pageOne) {
        setSearchParams({ ...searchParams, page: pageOne, keyword: '' });
        return;
      }
      try {
        setFetching(true);
        const res = await adminApi.getUnsearchableTalentList({
          ...searchParams,
          page: searchParams.page,
        });
        if (res.status === 200) {
          const resData = res.data.result;
          if (!isEmpty(resData?.unsearchableTalentList)) {
            setData({ list: resData.unsearchableTalentList, total: resData.total });
          } else {
            setData({ list: [], total: 0 });
            handleShowMessage(MESSAGE_TYPES.ERROR, PROFILE_MANAGEMENT_MESSAGES.SEARCH_NOT_FOUND);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    },
    [searchParams, handleShowMessage]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Spin spinning={fetching} size="large" wrapperClassName={'customLoading'}>
        <UnsearchableSearchBar
          fetchData={fetchData}
          setSearchParam={setSearchParams}
          searchParams={searchParams}
        />
        {!isEmpty(data?.list) ? (
          <UnsearchableTalentTable
            data={data}
            fetchData={fetchData}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        ) : (
          <SearchDataTableNotFound
            content={CONTENT_SEARCH_NOT_FOUND.LIST_OF_PROFILES}
            notFoundIcon={search_data_not_found}
          />
        )}
      </Spin>
    </>
  );
};

export default UnsearchableTalentList;
