import { Spin, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BasicButton } from '../../assets/common';
import { edit_talent_profile } from '../../assets/img';
import talentProfileApi, { getMainMobilities, getMobilities } from '../../services/talentProfiles';
import { pushMessage } from '../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  DATA_FROM,
  DATE_TIME_FORMAT,
  MOBILITY_PLAN_MESSAGE,
  PERMISSION,
} from '../../utils/constants';
import { formatMainMobilityPlan } from '../../utils/helper';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import { ModelTC } from '../Model';
import More3DotDropdown from '../More3DotDropdown/More3DotDropdown';
import Pagination from '../Pagination/Pagination';
import AddEditMobilityPlan from './AddEditMobilityPlan';
import styles from './mobility-plan.module.scss';

const DataTable = (props) => {
  const {
    loading,
    isModal,
    listMobilities,
    total,
    page,
    setPage,
    limit,
    handleEdit,
    handleDelete,
  } = props;
  return (
    <>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  No.
                </th>
                <th scope="col">Type</th>
                <th scope="col">Detail</th>
                <th scope="col">Platform</th>
                <th scope="col">Platform Date</th>
                {isModal && <th scope="col" className="text-center"></th>}
              </tr>
            </thead>
            <tbody>
              {!loading && !!listMobilities && listMobilities.length > 0 ? (
                listMobilities?.map((data, idx) => (
                  <tr data-testid="list-mobilities" key={idx}>
                    <td className="text-center">{(page - 1) * limit + idx + 1}</td>
                    <td data-testid="type">{data.type || '-'}</td>
                    <td data-testid="detail" style={{ maxWidth: '300px' }}>
                      {data.detail || '-'}
                    </td>
                    <td data-testid="platform">{data.platform || '-'}</td>
                    <td data-testid="platform_date">
                      {moment(data.platform_date).format('D MMM YYYY') || '-'}
                    </td>
                    {isModal && (
                      <td data-testid="more">
                        <More3DotDropdown
                          item={data}
                          handleClickEdit={handleEdit}
                          handleClickDelete={handleDelete}
                          editable={data.from === DATA_FROM.MANUAL}
                        />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr data-testid="no-list-mobilities" key="0" className={styles.tablePlanBody}>
                  <td className="text-center">-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      <div>
        {!loading && listMobilities && listMobilities.length > 0 && (
          <div data-testid="list-mobilities-paging">
            <Pagination page={page} rowPerPages={limit} totalResults={total} setPage={setPage} />
          </div>
        )}
      </div>
    </>
  );
};

export const MobilityPlan = ({ profileId, roleUser }) => {
  const dispatch = useDispatch();
  const { roleActive, permissions } = roleUser;
  const [mobilities, setMobilities] = useState([]);
  const [listMobilities, setListMobilities] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [page, setPage] = useState(1);
  const [pageLatest, setPageLatest] = useState(1);
  const [totalResults, setTotalResults] = useState(1);
  const [totalLatest, setTotalLatest] = useState(0);
  const limit = 6;
  const limitLatest = 4;

  const isEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_MOBILITY_PLAN);
  }, [permissions]);

  const getListMobility = async () => {
    try {
      setLoading(true);
      const res = await getMobilities({
        roleUser,
        id: profileId,
        limit: openViewModal ? limit : 3,
        page: openViewModal ? page : 1,
      });
      if (res.status === 200) {
        setListMobilities(res.data.result.mobilities);
        setTotalResults(res.data.result.total);
        setIsModal(true);
      }
    } catch (error) {
      console.log(error);
      setIsModal(false);
    } finally {
      setLoading(false);
    }
  };

  const getLatestMobilities = useCallback(async () => {
    try {
      setLoadingMain(true);
      const res = await getMainMobilities(
        { roleActive, permissions },
        profileId,
        pageLatest,
        limitLatest
      );
      if (res.status === 200) {
        const rows = formatMainMobilityPlan(res.data.result);
        setMobilities(rows);
        setTotalLatest(res.data.result.total);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMain(false);
    }
  }, [pageLatest, profileId, permissions, roleActive]);

  const handleClickAdd = () => {
    setDataEdit(null);
    setIsEdit(false);
    setOpenAddEdit(true);
    setOpenViewModal(false);
  };

  const handleClickEdit = (item) => {
    setDataEdit(item);
    setIsEdit(true);
    setOpenAddEdit(true);
    setOpenViewModal(false);
  };

  const handleClickDelete = (item) => {
    setDataEdit(item);
    setOpenDelete(true);
  };

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 0,
        isScroll: true,
      })
    );
  };

  const handleDelete = async () => {
    try {
      const res = await talentProfileApi.deleteMobilityPlanRecord(dataEdit.id, roleActive.roleId);
      if (res.status === 200) {
        setPage(1);
        getListMobility();
        getLatestMobilities();
        setOpenDelete(false);
        setOpenViewModal(false);
        handleShowMessage(
          ALERT_MESSAGE_TYPE.SUCCESS,
          `${MOBILITY_PLAN_MESSAGE.MOBILITY_PLAN} deleted`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitAddEdit = async (data, handleResetForm) => {
    const body = {
      staff_id: profileId,
      ...data,
    };
    const handleSuccess = () => {
      handleResetForm();
      setOpenAddEdit(false);
      getListMobility();
      getLatestMobilities();
      handleShowMessage(
        ALERT_MESSAGE_TYPE.SUCCESS,
        `${MOBILITY_PLAN_MESSAGE.MOBILITY_PLAN} ${isEdit ? 'edited' : 'added'}`
      );
    };
    try {
      if (isEdit) {
        const res = await talentProfileApi.editMobilityPlan(dataEdit.id, body, roleActive.roleId);
        if (res.status === 200) {
          handleSuccess();
        }
      } else {
        const res = await talentProfileApi.addMobilityPlanRecord(body, roleActive.roleId);
        if (res.status === 200) {
          handleSuccess();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListMobility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, openViewModal]);

  useEffect(() => {
    getLatestMobilities();
  }, [getLatestMobilities]);
  useEffect(() => {}, [roleActive, roleUser]);

  return (
    <>
      <div className={styles.SectionPlan}>
        <div className={styles.header}>
          <div className={styles.SectionName}>Mobility Plan</div>
          {isEditPermission && (
            <img
              alt="edit"
              src={edit_talent_profile}
              style={{ cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setOpenViewModal(true)}
            />
          )}
        </div>
        <Spin spinning={loadingMain} size="small">
          <div className={styles.tablePlan}>
            <table className="table">
              <thead>
                <tr className={styles.tablePlanThead}>
                  <th scope="col">Plan</th>
                  <th scope="col">Next Mobility</th>
                  <th scope="col">Next 2 Mobility</th>
                  <th scope="col" style={{ width: '35%' }}>
                    End State
                  </th>
                </tr>
              </thead>
              <tbody>
                {mobilities.length && !loadingMain ? (
                  mobilities.map((item, index) => (
                    <tr key={index} className={styles.tablePlanBody}>
                      <td>
                        <div className={styles.detail}>
                          {item?.nextMobility?.plan ||
                            item?.next2Mobility?.plan ||
                            item?.endState?.plan}
                        </div>
                      </td>
                      {Object.keys(item).map((key) => (
                        <td key={key}>
                          {item?.[key] ? (
                            <>
                              {!isEmpty(item[key]?.position) && (
                                <div className={styles.positionCards}>
                                  {item[key].position.map((val) => (
                                    <div className={styles.card} key={val?.position_code}>
                                      {val?.position_name} (<span>{val?.position_code}</span>)
                                    </div>
                                  ))}
                                </div>
                              )}
                              {!isEmpty(item[key]?.position) && item[key]?.detail && (
                                <div className={styles.divider} />
                              )}
                              {item[key]?.detail && (
                                <div className={styles.detail}>{item[key].detail}</div>
                              )}
                              <div className={styles.date}>
                                {item[key]?.platform && `${item[key]?.platform}, `}
                                {item[key]?.platform_date &&
                                  moment(item[key]?.platform_date).format(
                                    DATE_TIME_FORMAT.DATE_SPACE
                                  )}
                              </div>
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr className={styles.tablePlanBody}>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Spin>
        <div>
          {!loading && mobilities && mobilities.length > 0 && (
            <div data-testid="list-mobilities-paging">
              <Pagination
                page={pageLatest}
                rowPerPages={limitLatest}
                totalResults={totalLatest}
                setPage={setPageLatest}
              />
            </div>
          )}
        </div>
      </div>

      <CustomAntModal
        open={openViewModal}
        setOpen={setOpenViewModal}
        title={'Mobility Plan'}
        width={1000}
        styles={{
          titleFontWeight: 600,
          titleColor: '#787587',
        }}
        hideDefaultBtn
      >
        <div className="d-flex justify-content-end">
          <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickAdd}>
            Add Mobility Plan
          </BasicButton>
        </div>
        <DataTable
          isModal={isModal}
          listMobilities={listMobilities}
          total={totalResults}
          page={page}
          setPage={setPage}
          limit={limit}
          handleEdit={handleClickEdit}
          handleDelete={handleClickDelete}
          loading={loading}
        />
      </CustomAntModal>

      {openAddEdit && (
        <AddEditMobilityPlan
          open={openAddEdit}
          setOpen={setOpenAddEdit}
          isEdit={isEdit}
          dataEdit={dataEdit}
          handleSubmit={handleSubmitAddEdit}
        />
      )}
      <ModelTC
        info={{
          type: 'deleteMobilityPlanData',
          visible: openDelete,
          setVisible: setOpenDelete,
          handleSubmit: () => handleDelete(),
          onClose: () => setOpenDelete(false),
        }}
      />
    </>
  );
};

export default MobilityPlan;
