import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { mask_icon } from '../../../assets/img';
import { BUSINESS_NAME_TIME_LINE } from '../../../utils/constants';
import styles from '../career-journey.module.scss';
const CareerJourneyItem = ({ item }) => {
  const handleCheckCorlor = () => {
    if (!item) return;
    return colorForBusiness(item.business);
  };
  const colorForBusiness = (business) => {
    if (business === BUSINESS_NAME_TIME_LINE.CORPORATE) return '#625E70';
    if (business === BUSINESS_NAME_TIME_LINE.DOWNSTREAM) return '#FCAA1B';
    if (business === BUSINESS_NAME_TIME_LINE.GAS_NEW_ENERGY) return '#183288';
    if (business === BUSINESS_NAME_TIME_LINE.UPSTREAM) return '#308196';
    if (business === BUSINESS_NAME_TIME_LINE.PDT) return '#B1CD24';
    if (business === BUSINESS_NAME_TIME_LINE.OTHERS) return '#566879';
    if (business === BUSINESS_NAME_TIME_LINE.GAS) return '#72CB96';
    if (!business) return '';
    return '#00A19C';
  };
  const shortFormForBusiness = (business) => {
    if (business === BUSINESS_NAME_TIME_LINE.CORPORATE) return 'Corp';
    if (business === BUSINESS_NAME_TIME_LINE.DOWNSTREAM) return 'DS';
    if (business === BUSINESS_NAME_TIME_LINE.GAS_NEW_ENERGY) return 'GNE';
    if (business === BUSINESS_NAME_TIME_LINE.UPSTREAM) return 'US';
    if (business === BUSINESS_NAME_TIME_LINE.PDT) return 'PD&T';
    if (business === BUSINESS_NAME_TIME_LINE.OTHERS) return 'Oth';
    if (business === BUSINESS_NAME_TIME_LINE.GAS) return 'GAS';
    if (!business) return '';
    return business.slice(4);
  };
  const handleCovertYearsMonths = (duration) => {
    let unit = duration.split(' ');
    if ((unit[1] === 'Years' || unit[1] === 'Year') && Number(unit[2]) >= 6) {
      unit[0] = Number(unit[0]) + 1;
    }
    if ((unit[1] === 'Years' || unit[1] === 'Year') && Number(unit[0]) <= 0) {
      unit[0] = Number(unit[2]);
      unit[1] = unit[0] > 1 ? 'Months' : 'Month';
    }
    if (unit[1] === 'Months' && Number(unit[0]) >= 6) {
      unit[0] = 1;
      unit[1] = 'Years';
    }

    let convertUnit = unit.map((ele) => {
      if (ele === 'Years' || ele === 'Year') return 'Y';
      if (ele === 'Months' || ele === 'Month') return 'M';
      return ele;
    });
    return convertUnit.slice(0, 2).join('');
  };
  const handleCovertRoles = (roles) => {
    if (roles === 'Executive') return 'Exec';
    if (roles === 'General Manager') return 'GM';
    if (roles === 'Manager') return 'Mgr';
    if (roles === 'Senior General Manager') return 'SGM';
    if (roles === 'Senior Manager') return 'SM';
    if (!roles) return '';
    return roles.slice(0, 3);
  };
  const timelineStyle = {
    width: `${item.widthByPercent}%`,
  };
  const colorContentStyle = {
    backgroundColor: handleCheckCorlor(),
  };

  return (
    <div
      className={styles.CareerJourney__item}
      style={timelineStyle}
      data-testid="career-journey-item"
    >
      <div className={styles.CareerJourney__point}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className={styles.CareerJourney__tooltip}>{item.date_from_str}</Tooltip>
          }
        >
          <img src={mask_icon} alt="1" className={styles.CareerJourney__mask} />
        </OverlayTrigger>
      </div>
      <div className={styles.CareerJourney__content} style={colorContentStyle}>
        {shortFormForBusiness(item.business)}
      </div>
      <div className={styles.CareerJourney__detail}>
        <p className={styles.CareerJourney__years}>{handleCovertYearsMonths(item.duration)}</p>
        <p className={styles.CareerJourney__position}>{handleCovertRoles(item.role)} </p>
      </div>
    </div>
  );
};
export default CareerJourneyItem;
