import { get } from 'lodash';
import React, { memo } from 'react';
import Warning from '../Warning/Warning';

const FieldError = ({ name, forceTouched, ...formik }) => {
  const errorMsg = get(formik.errors, name);
  const touched = forceTouched || get(formik.touched, name);
  if (errorMsg && touched && typeof errorMsg === 'string') {
    return <Warning>{errorMsg}</Warning>;
  }

  return null;
};
export default memo(FieldError);
