import { Spin, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { auditLogApi } from '../../../../../services/auditLog';
import { DATE_TIME_FORMAT } from '../../../../../utils/constants';
import * as styles from './ai-pipeline-run.module.scss';
import * as SC from './styled';

const statusStyle = {
  Failed: {
    backgroundColor: '#FBE2E2',
    color: '#A91A1F',
    height: '30px',
    padding: '5px 10px',
    borderRadius: '6px',
  },
  Running: {
    backgroundColor: '#E1F2FE',
    color: '#00488F',
    height: '30px',
    padding: '5px 10px',
    borderRadius: '6px',
  },
  Completed: {
    backgroundColor: '#E7F4E8',
    color: '#096924',
    height: '30px',
    padding: '5px 10px',

    borderRadius: '6px',
  },
  Queue: {
    backgroundColor: '#FEF2DC',
    color: '#65490E',
    height: '30px',
    padding: '5px 10px',
    borderRadius: '6px',
  },
};

export default function AiPipelineRun() {
  const [query, setQuery] = useState({
    page: 1,
    limit: 15,
    total: 0,
  });
  const [data, setData] = useState([]);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(query.page - 1) * query.limit + 1 + index}</span>
      ),
      width: '5%',
      align: 'center',
      verticalAlign: 'center',
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      width: '30%',
    },
    {
      title: 'Start Date & Time',
      dataIndex: 'start_date',
      width: '15%',
    },
    {
      title: 'End Date & Time',
      dataIndex: 'end_date',
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
    },
  ];

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = (query.page - 1) * query.limit + query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > query.total ? query.total : toResults}</b> of&nbsp;
        <b>{query.total}</b> results
      </>
    ),
  };

  async function getAiPipelineRun() {
    const { page, limit } = query;
    try {
      setLoading(true);
      const res = await auditLogApi.getAiPipelineRun({ page, limit }, roleActive);
      const data = get(res, 'data.result.data') || [];
      const total = get(res, 'data.result.total');
      setData(data);
      setQuery((prev) => ({ ...prev, total }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!roleActive.roleId) return;
    getAiPipelineRun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive.roleId, query.page]);

  useEffect(() => {
    if (!roleActive.roleId) return;
    const interval = setInterval(() => {
      getAiPipelineRun();
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive.roleId, query.page]);

  return (
    <Spin spinning={loading} size="large">
      {!isEmpty(data) && <div className={styles.title}>Daily AI Run</div>}
      <SC.TableAuditLog>
        <Table
          columns={columns}
          dataSource={data.map((item, _index) => ({
            key: item.position_code,
            activity: `Daily Run ${moment(item.start_date).format(DATE_TIME_FORMAT.DATE_SPACE)} `,
            position_name: <span className={styles.positionName}>{item.position_name}</span>,
            start_date: (
              <div className={styles.font}>
                <span>{moment(item.start_date).format(DATE_TIME_FORMAT.DATE_SLASH)}</span>
                <br />
                <span>{moment(item.start_date).format(DATE_TIME_FORMAT.TIME)}</span>
              </div>
            ),
            end_date: (
              <div className={styles.font}>
                <span>{moment(item.end_date).format(DATE_TIME_FORMAT.DATE_SLASH)}</span>
                <br />
                <span>{moment(item.end_date).format(DATE_TIME_FORMAT.TIME)}</span>
              </div>
            ),
            status: (
              <>
                <span className={styles.font} style={statusStyle[item.status]}>
                  {item.status}
                </span>
                {item.status === 'Failed' && item.reason && (
                  <div style={{ marginTop: 4, fontSize: 12 }}>{item.reason}</div>
                )}
              </>
            ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableAuditLog>
    </Spin>
  );
}
