import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicButton } from '../../../../../assets/common';
import styles from './menu-bar-sp-readiness.module.scss';

export default function MenuBarSpReadiness({
  setSearchParams,
  deleteParams,
  setShowDeleteConfirm,
  setFirstTimeRender,
}) {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');

  const emptySelected = useMemo(
    () => isEmpty(deleteParams.selectedRecords),
    [deleteParams.selectedRecords]
  );
  const activeClass = useMemo(
    () => (emptySelected ? styles.inActiveDelBtn : styles.activeDelBtn),
    [emptySelected]
  );

  const handleClickAddNew = () => {
    history.push(`/admin/sp-management/readiness-rules/add`);
  };

  const handleClickSearch = () => {
    setFirstTimeRender(true);
    setSearchParams({ keyword: searchInput, page: 1 });
  };

  const handleClickDelete = () => {
    setShowDeleteConfirm(true);
  };

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <input
                data-testid="search-input"
                placeholder="Search Rules"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton mode="teal" onClick={handleClickSearch} data-testid="search-readiness">
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <BasicButton
                mode="danger"
                onClick={handleClickDelete}
                className={`${styles.button} ${activeClass}`}
                disabled={emptySelected}
              >
                Confirm Delete Rules
              </BasicButton>
            </Col>
            <Col>
              <BasicButton mode="teal" onClick={handleClickAddNew}>
                Add New
              </BasicButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
