import React from 'react';
import { DropdownUserManagement } from '../../../../../../components';

export function Filter(props) {
  const { filters, filterUser, selectedOptions, setSelectedOptions, setIsFiltering } = props;
  const selectOption = (type, item) => {
    filterUser({ type, value: item && item.value });
  };

  return (
    <div>
      <div
        className="d-flex justify-content-space-around align-items-center"
        style={{ gap: '16px' }}
        data-testid="component-filter-user-access"
      >
        <DropdownUserManagement
          selected={selectedOptions?.role}
          size={{ width: '201px', height: '44px' }}
          label={'Role'}
          options={filters.roles}
          setSelected={(item) => {
            setSelectedOptions({ role: item });
            selectOption('role', { value: item?.id });
            setIsFiltering(true);
          }}
        />
        <DropdownUserManagement
          selected={selectedOptions?.accessLevel}
          size={{ width: '201px', height: '44px' }}
          label={'Access Level'}
          options={filters.accessLevels}
          setSelected={(item) => {
            setSelectedOptions({ role: selectedOptions?.role, accessLevel: item });
            selectOption('accessLevel', { value: item?.value });
            setIsFiltering(true);
          }}
        />
        <DropdownUserManagement
          selected={selectedOptions?.buAccess}
          size={{ width: '201px', height: '44px' }}
          label={'Business Access'}
          options={filters.buAccessLevels}
          setSelected={(item) => {
            setSelectedOptions({ ...selectedOptions, buAccess: item });
            selectOption('businessAccess', { value: item?.id });
            setIsFiltering(true);
          }}
        />
      </div>
    </div>
  );
}
