import moment from 'moment/moment';
import React, { useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { delete_position, move_to_agree, remark_drop } from '../../../../../assets/img';
import { handleShowYIPAndYISG } from '../../../../../utils/helper';
import { Draggable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Draggable';
import { Droppable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Droppable';
import styles from './position-card.module.scss';
import { DATE_TIME_FORMAT } from '../../../../../utils/constants';

const PositionCard = (props) => {
  const {
    item,
    backgroundcolor,
    removepositioncard,
    title,
    readOnly,
    index,
    isAgreeToDrop,
    setOpenConfirm,
    handleDragEnd,
    viewPopupSuccession,
  } = props;
  const isApprovedDate =
    item?.approved_date && item?.last_approved_date
      ? moment(moment(item.approved_date).format(DATE_TIME_FORMAT.DATE_DASH)).isSame(
          moment(item.last_approved_date).format(DATE_TIME_FORMAT.DATE_DASH)
        )
      : false;

  const [isShowMoveIcon, setIsShowMoveIcon] = useState(false);
  const [isDisableDrag, setIsDisableDrag] = useState(false);

  const handleRevert = () => {
    if (!handleDragEnd) return;
    handleDragEnd({
      active: {
        id: `${title}-drap-${index}`,
        data: {
          current: { ...item, remark: '' },
        },
      },
      over: {
        id: `${item.revert_line || item.line}-drap-${index}`,
        data: {},
        disabled: false,
      },
      isCallback: true,
    });
  };

  const handleDropCard = () => {
    if (!setOpenConfirm) return;
    setOpenConfirm({
      position_code: item.position_code,
      event: {
        active: {
          id: `${title}-drap-${index}`,
          data: {
            current: {
              ...item,
              line: title,
              revert_line: title,
            },
          },
        },
        over: {
          id: 'Agreed to Drop',
          data: {},
          disabled: false,
        },
        isCallback: true,
      },
    });
  };

  return (
    <>
      {!readOnly && (
        <Droppable
          id={`${title}-drap-${index}`}
          key={`${title}-drap-${index}`}
          className={styles.container}
          data={{ ...item, index }}
          style={{
            borderLeft: `8px solid ${backgroundcolor}`,
            marginBottom: `${isAgreeToDrop && readOnly === false ? '0px' : '10px'}`,
            width: '235px',
          }}
          showborder={`true`}
          onMouseEnter={() => setIsShowMoveIcon(true)}
          onMouseLeave={() => setIsShowMoveIcon(false)}
        >
          <Draggable
            id={`${title}-drap-${index}`}
            showborder={`true`}
            data={{ ...item, index }}
            disabled={isDisableDrag}
          >
            <div style={{ width: '195px' }}>
              <div className={styles.position_name}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="button-tooltip">
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '19px',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          backgroundcolor: '#000',
                          color: '#fff',
                        }}
                      >
                        {item.birth_name || 'N/A'} (YIP:{' '}
                        {handleShowYIPAndYISG(item.years_in_position, item.date_in_position)})
                      </span>
                    </Tooltip>
                  }
                >
                  <span
                    onKeyDown={() => {}}
                    onClick={() =>
                      window.open(
                        '/agenda/succession-planning-dashboard/' + item.position_code,
                        '_blank'
                      )
                    }
                    onMouseEnter={() => setIsDisableDrag(true)}
                    onMouseLeave={() => setIsDisableDrag(false)}
                  >
                    {item.position_name} {item.position_code && ` (${item.position_code})`}
                  </span>
                </OverlayTrigger>
              </div>
              <div className={styles.content}>
                <span>{item.job_grade || 'N/A'}</span>
                <br />
                <span>{item.department_name || 'N/A'}</span>
                <br />
                <span>{item.business_unit || 'N/A'}</span>
                <br />
                <span>
                  {item.division || 'N/A'}, {item.opu_name || 'N/A'}
                </span>
              </div>
              <div>
                {isApprovedDate ? (
                  <span className={styles.approve}>
                    Approved :{' '}
                    {item.talent_review_date &&
                    moment(
                      moment(item.talent_review_date).format(DATE_TIME_FORMAT.DATE_DASH)
                    ).isSameOrAfter(moment(item.approved_date).format(DATE_TIME_FORMAT.DATE_DASH))
                      ? moment(item.talent_review_date).format(DATE_TIME_FORMAT.DATE_SPACE)
                      : moment(item.approved_date).format(DATE_TIME_FORMAT.DATE_SPACE)}
                  </span>
                ) : (
                  <span className={styles.new}>New</span>
                )}
              </div>
            </div>
          </Draggable>
          {!isApprovedDate && readOnly === false && (
            <div
              onKeyDown={() => {}}
              onClick={() => removepositioncard(item.position_code, title)}
              style={{ cursor: 'pointer' }}
            >
              <img src={delete_position} alt={delete_position} />
            </div>
          )}
          {isApprovedDate && readOnly === false && isShowMoveIcon && !isAgreeToDrop && (
            <div
              onKeyDown={() => {}}
              onClick={handleDropCard}
              style={{ cursor: 'pointer', width: '5%' }}
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    <span
                      style={{
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        backgroundcolor: '#000',
                        color: '#fff',
                      }}
                    >
                      Drop this card
                    </span>
                  </Tooltip>
                }
              >
                <img src={move_to_agree} alt={move_to_agree} />
              </OverlayTrigger>
            </div>
          )}
          {isApprovedDate && readOnly === false && isAgreeToDrop && (
            <div className={styles.revertRemark}>
              <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    <span
                      style={{
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        backgroundcolor: '#000',
                        color: '#fff',
                      }}
                    >
                      Revert
                    </span>
                  </Tooltip>
                }
              >
                <img
                  src={move_to_agree}
                  alt={move_to_agree}
                  style={{ rotate: '180deg', cursor: 'pointer' }}
                  onKeyDown={() => {}}
                  onClick={handleRevert}
                />
              </OverlayTrigger>
              {item.remark && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="button-tooltip" {...props}>
                      <span
                        style={{
                          fontSize: '14px',
                          lineHeight: '19px',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          backgroundcolor: '#000',
                          color: '#fff',
                        }}
                      >
                        {item.remark}
                      </span>
                    </Tooltip>
                  }
                >
                  <img
                    src={remark_drop}
                    alt={remark_drop}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  />
                </OverlayTrigger>
              )}
            </div>
          )}
        </Droppable>
      )}
      {readOnly && (
        <div
          className={styles.container}
          style={{
            borderLeft: `8px solid ${backgroundcolor}`,
            marginBottom: `${isAgreeToDrop && readOnly === false ? '0px' : '10px'}`,
            width: viewPopupSuccession ? '365px' : '355px',
          }}
        >
          <div>
            <div className={styles.position_name}>
              <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    <span
                      style={{
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        backgroundcolor: '#000',
                        color: '#fff',
                      }}
                    >
                      {item.birth_name || 'N/A'} (YIP:{' '}
                      {handleShowYIPAndYISG(item.years_in_position, item.date_in_position)})
                    </span>
                  </Tooltip>
                }
              >
                <span>
                  {item.position_name} {item.position_code && ` (${item.position_code})`}
                </span>
              </OverlayTrigger>
            </div>
            <div className={styles.content}>
              <span>{item.job_grade || 'N/A'}</span>
              <br />
              <span>{item.department_name || 'N/A'}</span>
              <br />
              <span>{item.business_unit || 'N/A'}</span>
              <br />
              <span>
                {item.division || 'N/A'}, {item.opu_name || 'N/A'}
              </span>
            </div>
            <div>
              {isApprovedDate ? (
                <span className={styles.approve}>
                  Approved :{' '}
                  {item.talent_review_date &&
                  moment(
                    moment(item.talent_review_date).format(DATE_TIME_FORMAT.DATE_DASH)
                  ).isSameOrAfter(moment(item.approved_date).format(DATE_TIME_FORMAT.DATE_DASH))
                    ? moment(item.talent_review_date).format(DATE_TIME_FORMAT.DATE_SPACE)
                    : moment(item.approved_date).format(DATE_TIME_FORMAT.DATE_SPACE)}
                </span>
              ) : (
                <span className={styles.new}>New</span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PositionCard;
