import { Spin } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AiButton } from '../../../../assets/common';
import { ModelTC } from '../../../../components';
import AlertNotification from '../../../../components/AlertMessage/AlertNotification';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  FORM_TYPES,
  MESSAGE_TYPES,
  SUCCESSION_PLANNING_MANAGEMENT,
} from '../../../../utils/constants';
import { convertDataToAddNewApprovedSp } from '../../../../utils/helper';
import NewCandidateForm from '../NewCandidateForm/NewCandidateForm';
import NewSuccessionPlanningForm from '../NewSuccessionPlanningForm/NewSuccessionPlanningForm';
import * as styles from './add-edit-sucession-planning.module.scss';

const activeApprovedSuccessor = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
];

const AddEditSucessionPlanning = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { positionCode, approvedDate } = useParams();
  const refNewSPForm = useRef(null);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [mode, setMode] = useState(FORM_TYPES.CREATE);

  const [loadingPage, setLoadingPage] = useState(false);
  const [spDetail, setSpDetail] = useState({});
  const [candidateList, setCandidateList] = useState([]);
  const [candidateToUpdate, setCandidateToUpdate] = useState({
    successor: [],
  });
  const [deleteItems, setDeleteItems] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Alert state
  const [showValidateFormFail, setShowValidateFormFail] = useState(false);
  const [showUnExpected, setShowUnExpected] = useState(false);
  const [showExistSp, setShowExistSp] = useState(false);

  const [allFilter, setAllFilter] = useState({
    active_approved_successor: [],
    business_unit: [],
    group_code: [],
    included_in_ratio: [],
    position_label: [],
    ranking: [],
    readiness: [],
  });

  const getRankingFilter = () => {
    let tempArr = [];
    for (let i = 1; i <= 50; i++) {
      tempArr.push({
        value: i,
        label: i,
      });
    }
    return tempArr;
  };

  //Modal state
  const [showAddNewSP, setShowAddNewSP] = useState(false);
  const [showEditSP, setShowEditSP] = useState(false);

  useEffect(() => {
    const fetchAllFilter = async () => {
      try {
        setLoadingPage(true);
        const rankingValue = getRankingFilter();
        const res = await adminApi.getAllFilterAddNewSP(roleActive?.roleId);
        if (res.status === 200) {
          setAllFilter({
            ...res.data.result,
            ranking: rankingValue,
            active_approved_successor: activeApprovedSuccessor,
          });
        }
        setLoadingPage(false);
      } catch (error) {
        setLoadingPage(false);
        console.error(error);
      }
    };

    fetchAllFilter();

    return () => {
      setAllFilter();
    };
  }, [roleActive]);

  useEffect(() => {
    if (isNaN(positionCode)) {
      setMode(FORM_TYPES.CREATE);
      return;
    }

    setMode(FORM_TYPES.EDIT);
    const fetchDetailApprovedSP = async () => {
      try {
        setLoadingPage(true);
        const res = await adminApi.getDetailApprovedSpByPositionCode(
          positionCode,
          approvedDate,
          roleActive?.roleId
        );
        if (res.status === 200) {
          setSpDetail(res.data.result);
          setCandidateToUpdate((prevState) => {
            return { ...prevState, successor: res.data.result.successor };
          });
        }
        setLoadingPage(false);
      } catch (error) {
        setLoadingPage(false);
        console.error(error);
      }
    };

    fetchDetailApprovedSP();

    return () => {
      setSpDetail({});
      setCandidateList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCode, roleActive]);

  const handleAddOrEditApprovedSP = async () => {
    setIsSubmitted(true);
    const data = await refNewSPForm.current.handleSubmitForm();

    if (!data.isValid) {
      setShowValidateFormFail(true);
      return;
    }

    if (mode === FORM_TYPES.CREATE) {
      setShowAddNewSP(true);
    } else {
      setShowEditSP(true);
    }
  };

  const handleConfirmAddNew = async () => {
    setShowAddNewSP(false);
    const data = await refNewSPForm.current.handleSubmitForm();
    await addNewApprovedSP(data.formValue);
  };

  const addNewApprovedSP = async (data) => {
    const body = convertDataToAddNewApprovedSp({ ...data, successor: candidateList });
    try {
      setLoadingPage(true);
      const response = await adminApi.addNewApprovedSuccessionPlanning(body, roleActive?.roleId);
      if (response.status === 200) {
        const spName = `${response.data.result[0]?.position_code} ${response.data.result[0]?.position_label}`;
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${spName} successfully added`,
          })
        );
        setLoadingPage(false);
        setCandidateList([]);
        handleCancel();
      }
    } catch (error) {
      setLoadingPage(false);
      const exist = get(error, 'response.data.errors');
      if (exist === SUCCESSION_PLANNING_MANAGEMENT.ADD_EDIT_APPROVED_EXIST) setShowExistSp(true);
      return console.error(error);
    }
  };

  const handleConfirmEditSP = async () => {
    setShowEditSP(false);
    const data = await refNewSPForm.current.handleSubmitForm();
    await updateApprovedSP(data.formValue);
  };

  const updateApprovedSP = async (data) => {
    const body = convertDataToAddNewApprovedSp({
      ...data,
      successor: candidateList,
      delete_successor: deleteItems,
      approved_date: data?.approved_date && moment(data.approved_date).format('YYYY-MM-DD'),
    });
    try {
      setLoadingPage(true);
      const response = await adminApi.updateDetailApprovedSp(body, roleActive?.roleId);
      if (response.status === 200) {
        const spName = `${body?.position_code} ${body?.position_label}`;
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${spName} successfully edited`,
          })
        );
        setLoadingPage(false);
        setCandidateList([]);
        handleCancel();
      }
    } catch (error) {
      setLoadingPage(false);
      return console.error(error);
    }
  };

  const handleCancel = () => {
    history.push(`/admin/sp-management/edit-approved-succession-planning`);
  };

  return (
    <div className="max-width">
      <>
        <AlertNotification
          type="error"
          message="There are items that require your attention. Please fill out this field(s)."
          show={showValidateFormFail}
          setShow={setShowValidateFormFail}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="error"
          message="An unexpected error occurred."
          show={showUnExpected}
          setShow={setShowUnExpected}
          timeShow={5000}
          style={{ margin: '25px 0 30px 0' }}
        />
        <AlertNotification
          type="error"
          message={SUCCESSION_PLANNING_MANAGEMENT.ADD_EDIT_APPROVED_EXIST}
          show={showExistSp}
          setShow={setShowExistSp}
          timeShow={5000}
          style={{ margin: '0 0 30px 0' }}
        />
      </>

      <h3 className={styles.mainTitle}>
        {mode === FORM_TYPES.CREATE
          ? 'Add New Succession Planning'
          : 'Edit Approved Succession Planning'}
        <span className={styles.subText} data-testid="noteTitle">
          Approved Succession Planning
        </span>
      </h3>

      <Spin spinning={loadingPage} size="large">
        <NewSuccessionPlanningForm
          mode={mode}
          ref={refNewSPForm}
          allFilter={allFilter}
          spDetail={spDetail}
          isSubmitted={isSubmitted}
        />
      </Spin>
      <Spin spinning={loadingPage} size="large">
        <NewCandidateForm
          mode={mode}
          allFilter={allFilter}
          setCandidateList={setCandidateList}
          setCandidateToUpdate={setCandidateToUpdate}
          candidateToUpdate={candidateToUpdate}
          setDeleteItems={setDeleteItems}
          isSubmitted={isSubmitted}
        />
      </Spin>

      <div className={styles.controlBar} style={{ marginBottom: '30px' }}>
        <AiButton onClick={handleCancel}>Cancel</AiButton>
        <AiButton mode={'teal'} onClick={() => handleAddOrEditApprovedSP()}>
          {mode === FORM_TYPES.CREATE ? 'Add' : 'Save'}
        </AiButton>
      </div>

      <ModelTC
        info={{
          type: 'addNewApprovedSuccessionPlanning',
          visible: showAddNewSP,
          setVisible: setShowAddNewSP,
          handleSubmit: () => handleConfirmAddNew(),
          onClose: () => setShowAddNewSP(false),
        }}
      />
      <ModelTC
        info={{
          type: 'updateApprovedSuccessionPlanning',
          visible: showEditSP,
          setVisible: setShowEditSP,
          handleSubmit: () => handleConfirmEditSP(),
          onClose: () => setShowEditSP(false),
        }}
      />
    </div>
  );
};

export default AddEditSucessionPlanning;
