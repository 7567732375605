import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  agenda_icon,
  agenda_icon_active,
  checked,
  meeting_detail_icon,
  publish_invite,
  publish_invite_active,
} from '../../../../assets/img';
import { addOrUpdateStepper } from '../../redux/stepperSlice';
import styles from './stepbar.module.scss';

const StepBar = () => {
  const dispatch = useDispatch();
  const stepperDetail = useSelector((state) => state.app.meetingModule.stepperDetail.stepper);
  const [stepbar] = useState([
    {
      id: 1,
      icon: meeting_detail_icon,
      step: 'STEP 1',
      iconActive: meeting_detail_icon,
      stepName: 'Meeting Detail',
    },
    {
      id: 2,
      icon: agenda_icon,
      step: 'STEP 2',
      iconActive: agenda_icon_active,
      stepName: 'Agenda',
    },
    {
      id: 3,
      icon: publish_invite,
      step: 'STEP 3',
      iconActive: publish_invite_active,
      stepName: 'Publish and Send Invite',
    },
  ]);

  const history = useHistory();
  const { idMeeting } = useParams();

  function handleChecked(item) {
    handleRedirectUrl(item.id);

    switch (item.id) {
      case 1:
        dispatchMeetingTabActive(1, [], [1]);
        break;
      case 2:
        dispatchMeetingTabActive(2, [], [1, 2]);
        break;
      case 3:
        dispatchMeetingTabActive(3, [1, 2], [1, 2, 3]);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    dispatchMeetingTabActive(1, [1], [1, 2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchMeetingTabActive = (tabActive, tabsPublished, tabsActive) => {
    if (stepperDetail.tabsActive.includes(3)) {
      handleAddOrUpdateStepper(1, [1, 2], [1, 2, 3]);
    } else {
      handleAddOrUpdateStepper(tabActive, tabsPublished, tabsActive);
    }
  };

  const handleAddOrUpdateStepper = (tabActive, tabsPublished, tabs) => {
    if (!idMeeting) {
      return dispatch(
        addOrUpdateStepper({ currentTabActive: 1, currentTabFinished: [], tabsActive: [1] })
      );
    }

    dispatch(
      addOrUpdateStepper({
        currentTabActive: tabActive,
        currentTabFinished: tabsPublished,
        tabsActive: tabs,
      })
    );
  };

  const handleRedirectUrl = (tabActive) => {
    if (!idMeeting) {
      return history.push(`/meeting`);
    }

    switch (tabActive) {
      case 1:
        history.push(`/meeting/${idMeeting}`);
        break;
      case 2:
        history.push(`/meeting/${idMeeting}/agendas`);
        break;
      case 3:
        history.push(`/meeting/overall/${idMeeting}`);
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.progress_meeting_details}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          width: '100%',
        }}
      >
        {stepbar.map((item, index) => (
          <div
            key={index}
            className={`d-flex flex-column justify-content-center align-items-center ${
              stepperDetail.tabsActive.includes(item.id) ? 'active' : null
            }`}
            style={{ width: '33%', cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() => {
              handleChecked(item);
            }}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: `${
                  stepperDetail.tabsActive.includes(item.id) ? '#009089' : '#8D8D8D'
                }`,
                height: '4px',
              }}
            ></div>
            <div
              className="d-flex justify-content-flex-start align-items-center w-100"
              style={{ gap: '10px', padding: '20px' }}
            >
              <img
                src={stepperDetail.tabsActive.includes(item.id) ? item.iconActive : item.icon}
                alt={meeting_detail_icon}
              />
              <div className="col-10">
                <div
                  className={styles.step}
                  style={{
                    color: `${stepperDetail.tabsActive.includes(item.id) ? '#009089' : '#8D8D8D'}`,
                  }}
                >
                  {item.step}
                </div>
                <div className={styles.step_children}>{item.stepName}</div>
              </div>
              <img
                src={stepperDetail.currentTabFinished.includes(item.id) ? checked : null}
                alt={stepperDetail.currentTabFinished.includes(item.id) ? checked : null}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default StepBar;
