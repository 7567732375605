import { includes, isEmpty, size } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AiCheckBox } from '../../../../assets/common';
import {
  arrow_forward,
  deleteIcon,
  iconPaperBringToForward,
  user,
  warning_icon_mini,
} from '../../../../assets/img';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  AGENDA_STATUS,
  AGENDA_TYPES_LABEL,
  DATE_TIME_FORMAT,
  MEETING_MESSAGE,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  ROUTE_BACK_HISTORY,
} from '../../../../utils/constants';
import { showNotification } from '../../../../utils/notification';
import { AGENDA_TYPES } from '../../../MobilitySimulation/constants';
import AgendaStatus from '../AgendaStatus/AgendaStatus';
import styles from './AgendaItem.module.scss';
import { AiDesc, AiGroupAction, AiImg, AiTime, AiTitleAgenda } from './Styled';

const AgendaItem = (props) => {
  const { idMeeting } = useParams();
  const {
    item,
    avatar = true,
    isShownCheckbox,
    handleCheckedItem,
    handleDeleteAgenda,
    handeUpdateAgendaExisted,
    isViewDetail,
    prevPageName,
    remarkMsg,
    tempArrChecked,
  } = props;

  const history = useHistory();
  const handleOnChange = (event) => {
    const isChecked = event.target.checked;
    handleCheckedItem({ ...item, isChecked });
  };

  const handleShowTime = (time) => {
    if (moment(time).format(DATE_TIME_FORMAT.TIME) === 'Invalid date') {
      return time;
    }
    return moment(time).format(DATE_TIME_FORMAT.TIME);
  };
  const handleShowStatus = () => {
    if (!item.status || item.status === 'Paper in Progress') {
      return <AgendaStatus status={'progress'} />;
    }
    if (item.status === 'Paper bring to forward') {
      return <AgendaStatus status={'forward'} />;
    }
    if (item.status === 'Paper Ready') {
      return <AgendaStatus status={'ready'} />;
    }
    if (item.status === 'Paper Finalized') {
      return <AgendaStatus status={'finalized'} />;
    }
  };
  const isShownAction = () => {
    return item.status !== 'Paper bring to forward';
  };

  const isWarningExistsPaperReady =
    remarkMsg &&
    remarkMsg.message === MEETING_MESSAGE.WARNING_EXISTS_PAPER_READY &&
    item.status === AGENDA_STATUS.PAPER_READY;

  const { existedAgenda } = useSelector((state) => state.app.meetingModule.agenda);
  const dispatch = useDispatch();

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: [MESSAGE_TYPES.WARNING].includes(type),
        })
      );
    },
    [dispatch]
  );

  const handleRedirectUrl = () => {
    if (
      size(existedAgenda) > 0 &&
      existedAgenda.map((item) => item.agenda_id).includes(item.agenda_id)
    ) {
      if (prevPageName === ROUTE_BACK_HISTORY.MEETING_AGENDA_STEP2) {
        handleShowMessage(
          MESSAGE_TYPES.WARNING,
          `Please click the 'Save as Draft' button to ensure the added existing agenda data is saved.`
        );
      } else {
        handleShowMessage(
          MESSAGE_TYPES.WARNING,
          `Please click the 'Done Edit Meeting' button to ensure the added existing agenda data is saved.`
        );
      }
      return;
    }

    switch (item?.type_agenda) {
      case AGENDA_TYPES.MS:
        history.push(
          `/meeting/${idMeeting || item.meeting_id}/mobility-agenda/${
            item.agenda_id
          }?history=${prevPageName}`
        );
        break;
      case AGENDA_TYPES.TALENT:
        history.push(
          `/meeting/${idMeeting || item.meeting_id}/talent-review-agenda/${
            item.agenda_id
          }?history=${prevPageName}`
        );
        break;
      case AGENDA_TYPES.OTHERS:
        history.push(
          `/meeting/${idMeeting || item.meeting_id}/others-agenda/${
            item.agenda_id
          }?history=${prevPageName}`
        );
        break;

      default:
        history.push(
          `/meeting/${idMeeting || item.meeting_id}/agenda/${
            item.agenda_id
          }?history=${prevPageName}`
        );
        break;
    }
  };

  return (
    <div className="mb-3 w-100">
      <div
        className={`${styles.agendaItem} ${isWarningExistsPaperReady && styles.exists_paper_ready}`}
      >
        {!isShownCheckbox && (
          <div
            style={{ cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() => handleRedirectUrl()}
          >
            <div
              className="d-flex align-items-start justify-content-around"
              style={{ gap: '20px' }}
            >
              <div className="" style={{ width: '180px' }}>
                <AiTime>
                  {handleShowTime(item?.start_time)} - {handleShowTime(item?.end_time)}
                </AiTime>
                <AiDesc>Advocator Name</AiDesc>
                {!isEmpty(item.advocators) &&
                  item.advocators.map((value, index) => (
                    <AiDesc key={index}>{value.label || value.name}</AiDesc>
                  ))}
              </div>
              <div
                style={{ width: '450px' }}
                className="d-flex align-items-center align-self-center"
              >
                <AiTitleAgenda>
                  {item?.agenda_name || item?.succession_planning_name || item?.title}
                  {isWarningExistsPaperReady && (
                    <span style={{ marginLeft: '20px' }}>
                      <img src={warning_icon_mini} alt="warning_icon_mini" />
                    </span>
                  )}
                </AiTitleAgenda>
              </div>
              <div
                className="d-flex justify-content-start align-items-center align-self-center"
                style={{ gap: '15px', width: '210px' }}
              >
                <img
                  src={AGENDA_TYPES_LABEL.find((f) => f.type_agenda === item.type_agenda)?.icon}
                  alt="other_type"
                />
                <div className={styles.agenda_type}>
                  {AGENDA_TYPES_LABEL.find((f) => f.type_agenda === item.type_agenda)?.label}
                </div>
              </div>
              <div
                className="d-flex align-items-end"
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  width: '160px',
                }}
              >
                {!isViewDetail && (
                  <>
                    {isShownAction() && (
                      <AiGroupAction>
                        <AiImg
                          src={iconPaperBringToForward}
                          alt=""
                          onKeyDown={() => {}}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (includes(item.agenda_id, '-')) {
                              showNotification(
                                `Please click Save as Draft first to proceed to Paper bring to forward action`,
                                NOTIFICATION_TYPE.FAILED
                              );
                              return;
                            }
                            handeUpdateAgendaExisted(item.agenda_id, item.type_agenda);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                        <AiImg
                          src={deleteIcon}
                          alt=""
                          className="ml-3"
                          onKeyDown={() => {}}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAgenda(item.agenda_id);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </AiGroupAction>
                    )}
                    {handleShowStatus()}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {isShownCheckbox && !isEmpty(item) && (
          <div className="d-flex justify-content-around align-items-start" style={{ gap: '15px' }}>
            <div
              className="d-flex align-self-stretch checkbox"
              style={{ flexDirection: 'column', justifyContent: 'center', alignSelf: 'stretch' }}
            >
              <AiCheckBox
                checked={
                  isEmpty(tempArrChecked.find((v) => v.agenda_id === item.agenda_id)) ? false : true
                }
                onChange={handleOnChange}
                data-testid="exist-agenda-checkbox"
                type="checkbox"
                id={item.agenda_id}
              />
            </div>

            {avatar && (
              <div className="col-1">
                <div className={styles.avatar_user}>
                  <img src={user} alt="" />
                </div>
              </div>
            )}

            <div className="" style={{ gap: '50px', width: '160px' }}>
              <div className="d-flex mb-1" style={{ gap: '30px' }}>
                <div>
                  {moment(item.start_time).format(DATE_TIME_FORMAT.TIME)} -{' '}
                  {moment(item.end_time).format(DATE_TIME_FORMAT.TIME)}
                </div>
              </div>
              <AiDesc>Advocator Name</AiDesc>
              {!isEmpty(item.advocators) &&
                item.advocators
                  ?.filter((f) => f?.type === 'Advocator')
                  .map((value, index) => <AiDesc key={index}>{value.label || value.name}</AiDesc>)}
            </div>
            <div
              style={{ fontWeight: 600, width: '450px', wordBreak: 'break-word' }}
              className="d-flex align-items-center align-self-center"
            >
              {item.agenda_name}
              {isWarningExistsPaperReady && (
                <span style={{ marginLeft: '20px' }}>
                  <img src={warning_icon_mini} alt="warning_icon_mini" />
                </span>
              )}
            </div>
            <div
              className="d-flex justify-content-start align-items-center align-self-center"
              style={{ gap: '15px', width: '210px' }}
            >
              <img
                src={AGENDA_TYPES_LABEL.find((f) => f.type_agenda === item.type_agenda)?.icon}
                alt="other_type"
              />
              <div className={styles.agenda_type}>
                {AGENDA_TYPES_LABEL.find((f) => f.type_agenda === item.type_agenda)?.label}
              </div>
            </div>
            <div
              className="d-flex"
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignSelf: 'stretch',
                width: '163px',
              }}
            >
              <div className={styles.agendaItem__Status}>
                <img src={arrow_forward} alt="" /> {item?.status}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AgendaItem;
