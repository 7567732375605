import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiRangePicker } from '../../pages/Admin/components/NewSuccessionPlanningForm/Styled';

const CustomRangePicker = ({
  dateRange,
  setDateRange,
  height,
  placeholder,
  onSelect,
  format = 'DD MMM YYYY',
}) => {
  const [isOpenDateTimepicker, setIsOpenDateTimepicker] = useState(false);
  const [value, setValue] = useState([null, null]);

  const onClickDateTimepicker = () => {
    setIsOpenDateTimepicker(true);
  };

  const onSelectDateRange = () => {
    setIsOpenDateTimepicker(false);
    if (isEmpty(value[0])) {
      setDateRange([moment(new Date()).format(format), moment(new Date()).format(format)]);
      return;
    }
    setDateRange([moment(value[0]).format(format), moment(value[1]).format(format)]);
    onSelect && onSelect();
  };

  const onChangeDateAt = (dates) => {
    setValue(dates);
  };

  useEffect(() => {
    if (!value) {
      setValue([null, null]);
      setDateRange([null, null]);
    }
  }, [setDateRange, value]);

  return (
    <AiRangePicker
      height={height}
      format={format}
      value={value}
      onChange={onChangeDateAt}
      placeholder={placeholder}
      getPopupContainer={(trigger) => trigger.parentElement}
      open={isOpenDateTimepicker}
      renderExtraFooter={() => (
        <div className={'groupBtnBottom'}>
          <button className={'cancelBtn'} onClick={() => setIsOpenDateTimepicker(false)}>
            Cancel
          </button>
          <button className={'selectBtn'} onClick={() => onSelectDateRange()}>
            Select
          </button>
        </div>
      )}
      onClick={onClickDateTimepicker}
      className={
        dateRange.fromDate ? 'customInputDateRangepicker hasValue' : 'customInputDateRangepicker'
      }
      popupClassName={'customDateRangepicker'}
    />
  );
};

export default CustomRangePicker;
