import { Pagination } from 'antd';
import { isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { comment_stack } from '../../assets/img';
import AgendaItem from '../../pages/Meeting/components/AgendaItem/AgendaItem';
import {
  updateExistedAgendas,
  updateExistedAgendasToList,
} from '../../pages/Meeting/redux/agendaSlice';
import { meetingApi } from '../../services/tcMeeting';
import { AGENDA_STATUS } from '../../utils/constants';
import styles from './model-add-existing-agenda.module.scss';

const numEachPage = 6;

const ModalAddExistingAgenda = ({ show, setShow, idsAgenda }) => {
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [agendas, setAgendas] = useState([]);
  const [tempArrChecked, setTempArrChecked] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);
  const roleActive = useSelector((state) => state.user.roleActive);

  const getAgendas = async () => {
    try {
      setLoading(true);
      setAgendas([]);
      const response = await meetingApi.getAgendasExisted({ keyword, roleActive });
      if (response.status === 200) {
        setAgendas(response.data.result);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAgendas([]);
    }
  };
  useEffect(() => {
    if (show) {
      getAgendas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleCheckedItem = (data) => {
    if (data.isChecked) {
      setTempArrChecked((prev) => [...prev, data]);
    } else {
      setTempArrChecked((prev) => [...prev].filter((item) => item.agenda_id !== data.agenda_id));
    }
  };

  const handleAddExistingAgenda = () => {
    if (tempArrChecked.length <= 0) return;
    const selectedExistingAgenda = tempArrChecked.map((item) => ({
      ...item,
      advocators: item.advocators.filter((i) => i.type === 'Advocator'),
      status: AGENDA_STATUS.PAPER_IN_PROGRESS,
    }));
    //Update existed Agenda to => list update and list show
    dispatch(updateExistedAgendas(selectedExistingAgenda));
    dispatch(updateExistedAgendasToList(selectedExistingAgenda));
    setShow(false);
  };

  const handleChange = (value) => {
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
  };

  const agendaExistingDisplay = !isEmpty(idsAgenda)
    ? agendas.filter((item) => !idsAgenda.includes(item.agenda_id))
    : agendas;

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName={styles.containerModal}
        data-testid="modal-form-add-experiences"
      >
        <Modal.Header closeButton className={styles.existingAgender__header}>
          <Modal.Title className={styles.existingAgender__title}>Add Existing Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.existingAgender__body}>
          <div className={styles.existingAgender__form} style={{ marginBottom: '32px' }}>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  className={`${styles.input}`}
                  placeholder="Search Agenda"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
              <div className={`col-2 d-flex align-items-end`}>
                <div className={styles.btn_green} onClick={getAgendas}>
                  <span
                    className={styles.text_btn}
                    style={{ color: '#ffffff', maxWidth: '90px' }}
                    onKeyDown={() => {}}
                  >
                    Search
                  </span>
                </div>
              </div>
              <div className={`col-4 d-flex align-items-center justify-content-end`}>
                <div
                  className={`${styles.btn_green} ${
                    !(tempArrChecked.length > 0) ? styles.btn_disabled : ''
                  }`}
                  onKeyDown={() => {}}
                  onClick={handleAddExistingAgenda}
                >
                  <span className={styles.text_btn} style={{ color: '#ffffff' }}>
                    Confirm Add Existing Agenda
                  </span>
                </div>
              </div>
            </div>
          </div>
          {!loading && size(agendaExistingDisplay) > 0 && (
            <div className={styles.existingAgender__list}>
              {agendaExistingDisplay.slice(minValue, maxValue).map((item) => (
                <div key={item.agenda_id}>
                  <AgendaItem
                    item={{ ...item, isChecked: false }}
                    key={item.agenda_id}
                    avatar={false}
                    isShownCheckbox={true}
                    handleCheckedItem={handleCheckedItem}
                    tempArrChecked={tempArrChecked}
                  />
                </div>
              ))}

              <Pagination
                total={agendaExistingDisplay.length}
                showTotal={(total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} results`
                }
                defaultCurrent={1}
                defaultPageSize={numEachPage}
                showSizeChanger={false}
                onChange={handleChange}
              />
            </div>
          )}

          {agendaExistingDisplay.length <= 0 && (
            <div className={styles.existingAgender__list}>
              <div className={styles.noAgendata_added}>
                <img className={styles.noAgendata_added_img} src={comment_stack} alt="" />
                <span className={styles.noAgendata_added_title}>No Agenda added, yet.</span>
                <span className={styles.noAgendata_added_desc}>
                  No Agenda added in this section.
                </span>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ModalAddExistingAgenda;
