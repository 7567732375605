import { notification } from 'antd';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { remark_calendar, triangle_edit } from '../../../../../../assets/img';
import { AlertMessage } from '../../../../../../components';
import { useComponentVisible } from '../../../../../../hooks';
import { formatDate } from '../../../../../../utils/helper';
import { saveRemarks } from '../../../../redux/remarksSlice';
import styles from './add-remark.module.scss';

const OPTIONS = [
  { id: 1, category: 'Strength' },
  { id: 2, category: 'Development Areas' },
  { id: 3, category: 'Notes' },
  { id: 4, category: 'Development Plan' },
  { id: 5, category: 'Next Mobility' },
  { id: 6, category: 'Next 2 Mobility' },
  { id: 7, category: 'End State' },
  { id: 8, category: 'Additional Input' },
];

const Category = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { setCategory, category, remarkMsg } = props;

  const handleClickInput = (item) => {
    setCategory(item?.category);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div className="col" ref={ref}>
      <div className={styles.label}>Category</div>
      <input
        onKeyDown={() => {}}
        onClick={() => handleClickInput()}
        readOnly
        value={category}
        className={`${styles.category} ${remarkMsg && !category ? styles.error : ''}`}
        placeholder='Select Category'
      />
      <img src={triangle_edit} className={styles.arrow} alt="" />

      {isComponentVisible && (
        <div className={styles.dropdown}>
          {OPTIONS.map((item) => (
            <div key={item.id} onKeyDown={() => {}} onClick={() => handleClickInput(item)}>
              {item.category}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AddRemark = (props) => {
  const { setIsVisible, showAddRemark, agendaDetail, setShowAddRemarkSuccess } = props;
  const { ref } = useComponentVisible(false);
  const [category, setCategory] = useState('');
  const [remark, setRemark] = useState('');
  const { meeting_name, date: meetingDate } = useSelector(
    (state) => state.app.meetingModule.preMeeting.meeting
  );

  const { agenda_id, version } = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const [remarkMsg, setRemarkMsg] = useState(null);
  const { agendaId } = useParams();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const validate = () => {
    if (!remark || !category) {
      setRemarkMsg({
        type: 'error',
        message: 'There are items that require your attention. Please fill out this field.',
        onClose: () => setRemarkMsg(null),
        timeShow: 5000,
      });

      return true;
    } else {
      return false;
    }
  };

  const handleClickAdd = async () => {
    const isValidate = validate();
    if (isValidate) return;
    const params = {
      staffId: showAddRemark.staff.staff_id,
      agendaId: agendaDetail?.agenda_id || agenda_id || agendaId,
      remarks: remark,
      remarksCategory: category,
      version: version || 0,
      birthName: showAddRemark.staff.birth_name,
      meetingDate: meetingDate,
      remarkId: '',
      type: 'create',
      meetingName: meeting_name || agendaDetail?.meeting_name,
    };
    dispatch(saveRemarks(params));
    agendaDetail?.agenda_id
      ? setShowAddRemarkSuccess(true)
      : notification.success({
          message: 'Remark Added Successfully',
          placement: 'topRight',
          duration: 2,
        });
    setIsVisible(false);
  };

  return (
    <Modal show={true} onHide={() => handleCancel()} dialogClassName={styles.modal} aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header className={styles.modal_header} closeButton>
        <div>Add Remark</div>
      </Modal.Header>
      {remarkMsg && (
        <div style={{ padding: '0 80px', marginBottom: '40px' }}>
          <AlertMessage {...remarkMsg} />
        </div>
      )}
      <Modal.Body className={styles.table}>
        <div className={styles.row}>
          <div className="col-7">
            <div className={styles.label}>Name</div>
            <input readOnly value={showAddRemark.staff.birth_name} />
          </div>
          <Category setCategory={setCategory} category={category} remarkMsg={remarkMsg} />
        </div>

        <div className={styles.row}>
          <div className="col-6">
            <div className={styles.label}>Meeting Name</div>
            <input readOnly value={meeting_name || agendaDetail?.meeting_name} />
          </div>
          <div className="col" ref={ref}>
            <div className={`${styles.label} ${styles.date}`}>Date</div>
            <input onKeyDown={() => {}} onClick={handleClick} value={formatDate(meetingDate)} readOnly className={styles.dateInput} />
            <div className={styles.calendar}>
              <img src={remark_calendar} alt="remark_calendar" style={{ pointerEvents: 'all' }} />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className="col">
            <div className={styles.label}>Remark</div>
            <textarea onChange={(e) => setRemark(e.target.value)} value={remark} className={remarkMsg && !remark ? styles.error : ''} />
          </div>
        </div>

        <div className={styles.row}>
          <div className="col d-flex justify-content-end align-items-center">
            <div className={styles.add} onKeyDown={() => {}} onClick={() => handleClickAdd()}>
              <div>Add Remark</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddRemark;
