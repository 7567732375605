import { Input, Select, Space } from 'antd';
import { cloneDeep, isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BasicButton, BasicLoading } from '../../../assets/common';
import {
  deleteIcon,
  duplicate_green_icon,
  edit_delete,
  edit_green_icon,
  edit_report_name,
  grab,
  green_option,
  grey_option,
  rb_talent_not_found,
  red_option,
  yellow_option,
} from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { reportBuilderApi } from '../../../services/reportBuilderApi';
import { pushMessage } from '../../../store/alertMessageSlice';
import { MESSAGE_TYPES, TALENT_FILTER_OPTIONS } from '../../../utils/constants';
import AddEditManualColumn from '../components/AddManualColumn/AddManualColumn';
import DuplicateColumnModal from '../components/EditReportNameModal/DuplicateColumnModal';
import EditColumnNameModal from '../components/EditReportNameModal/EditColumnNameModal';
import EditReportNameModal from '../components/EditReportNameModal/EditReportNameModal';
import ListOfTalentTable from '../components/ListOfTalents/ListOfTalentTable';
import ListOtherColumn from '../components/ListOtherColumn/ListOtherColumn';
import ListTalentFinderColumn from '../components/ListTalentFinderColumn/ListTalentFinderColumn';
import TalentListSearchBar from '../components/TalentListSearchBar/TalentListSearchBar';
import {
  RB_BREAD_CRUMB,
  REPORT_BUILDER_MESSAGE,
  REPORT_COLUMN_TYPE,
  REPORT_COLUMN_TYPE_VALUE,
  VALUE_TYPES,
} from '../constants';
import styles from './report-details.module.scss';

const { Option } = Select;

const TitlePosition = ({ isEditMode, column }) => {
  return (
    <div className={`${isEditMode && 'drag-handle'} ${styles.dragDrop}`}>
      {isEditMode && (
        <span>
          <img src={grab} alt={'grab'} />
        </span>
      )}
      <span style={{ marginLeft: 10 }}>{column.name}</span>
    </div>
  );
};
TitlePosition.propTypes = {
  isEditMode: PropTypes.bool,
  column: PropTypes.object,
};

export const ReportDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isEdit, reportId } = useParams();
  const { roleId } = useSelector((state) => state.user.roleActive);

  const [modeView, setModeView] = useState(true);
  const [open, setOpen] = useState(false);
  const [newColumn, setNewColumn] = useState(null);
  const [currentColumns, setCurrentColumns] = useState([]);
  const [initialListOfTalentData, setInitialListOfTalentData] = useState([]);
  const [selectedTalents, setSelectedTalents] = useState([]);
  const [listOfTalents, setListOfTalents] = useState([]);
  const [listOfColumnName, setListOfColumnName] = useState([]);
  const [initData, setInitData] = useState({ report_name: '', list_of_talents: [], report_id: 0 });
  const [openEditName, setOpenEditName] = useState(false);
  const [openEditColumnName, setOpenEditColumnName] = useState(false);
  const [openDuplicateColumn, setOpenDuplicateColumn] = useState(false);
  const [editColumn, setEditColumn] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEdited, setIsEdited] = useState(Boolean(isEdit));
  const [isSubmit, setIsSubmit] = useState(false);
  const [showDeleteColumnModal, setShowDeleteColumnModal] = useState(false);
  const [deleteColumn, setDeleteColumn] = useState(null);
  const [cancelUpdateReport, setCancelUpdateReport] = useState(false);
  const [duplicatedColumn, setDuplicatedColumn] = useState([]);
  const [isDeleteFinderColumn, setIsDeleteFinderColumn] = useState(false);
  const timestamp = new Date().getTime();
  const [listExistingColumn, setListExistingColumn] = useState([]);
  const [manualColumnEdit, setManualColumnEdit] = useState(null);
  const [showConfirmAddTalentModal, setShowConfirmAddTalentModal] = useState(false);
  const [showAddTalentModal, setShowAddTalentModal] = useState(false);

  // Filter Talent Finder
  const [filterOptions, setFilterOptions] = useState({});
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filterFinderColumns, setFilterFinderColumns] = useState([]);

  //Loading State
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const getListOfColumnName = (listOfTalents) => {
    return listOfTalents[0]?.columns.map((item) => item.name) || [];
  };

  // Get columns when apply finder filter
  const applyFinderFilterColumn = async (columns) => {
    const tempBody = {
      staff_ids: listOfTalents?.map((item) => item.staff_id),
      column_options: columns,
    };

    try {
      setLoading(true);
      const res = await reportBuilderApi.getColumnsByFinderFilter(tempBody);
      const data = res?.data?.result;
      if (res.status === 200 && !isEmpty(data)) {
        let newListOfTalent = cloneDeep(listOfTalents);
        newListOfTalent.forEach((item) => {
          data.forEach((talent) => {
            if (Number(item.staff_id) === Number(talent.staff_id)) {
              item.columns.push(...talent.columns);
            }
          });
        });
        setListOfTalents(newListOfTalent);
        setListOfColumnName((prevState) => [
          ...prevState,
          ...data[0].columns.map((column) => column.label),
        ]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFinderFilterOptions = useCallback(async () => {
    try {
      setLoadingFilter(true);
      const filterRes = await reportBuilderApi.getFinderFilterOptions(reportId, roleId);
      if (filterRes.status === 200 && filterRes?.data?.result) {
        setFilterOptions(filterRes.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFilter(false);
    }
  }, [reportId, roleId]);

  useEffect(() => {
    fetchFinderFilterOptions();
  }, [fetchFinderFilterOptions]);

  const getDefaultValueOfInput = (type) => {
    switch (type) {
      case REPORT_COLUMN_TYPE[1].value:
        return '#7A7A7A';
      case REPORT_COLUMN_TYPE[2].value:
        return 'NA';
      default:
        return '';
    }
  };

  const getDataManualColumn = async (newColumn) => {
    const newColumns = (talent, value) => [
      ...(talent?.columns || []),
      {
        ...newColumn,
        id: null,
        name: newColumn.name,
        type: newColumn.type,
        is_manual: true,
        value: newColumn?.value || value,
      },
    ];
    const reloadTable = () => {
      setNewColumn(null);
      setListOfColumnName((prevState) => [...prevState, newColumn.name]);
    };

    if (!newColumn.id) {
      setListOfTalents((prevState) => {
        return prevState.map((talent) => {
          return {
            ...talent,
            columns: newColumns(talent, ''),
          };
        });
      });
      reloadTable();
      return;
    }
    try {
      setLoading(true);
      const params = {
        report_id: newColumn?.report_id || null,
        column_id: newColumn.id,
      };
      const res = await reportBuilderApi.getColumnDataManual(params, roleId);
      if (res.status === 200) {
        const resResult = res.data.result;
        setListOfTalents((prevState) => {
          return prevState.map((talent) => {
            const recentColumnData = resResult.find((item) => item.staff_id === talent.staff_id);
            const defaultValue = getDefaultValueOfInput(newColumn.type);
            const value = recentColumnData ? recentColumnData.value : defaultValue;
            return {
              ...talent,
              columns: newColumns(talent, value),
            };
          });
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      reloadTable();
    }
  };

  const fetchManualColumns = useCallback(async () => {
    try {
      const res = await reportBuilderApi.getListColumnManual(reportId, roleId);
      if (res.status === 200 && res.data.result) {
        setCurrentColumns(
          res.data.result.map((item) => ({
            name: item.column_name,
            type: item.column_type,
            is_manual: true,
            id: item.id,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [setCurrentColumns, reportId, roleId]);

  useEffect(() => {
    fetchManualColumns();
  }, [fetchManualColumns]);

  useEffect(() => {
    if (newColumn) {
      setIsSubmit(false);
      getDataManualColumn(newColumn);
    }

    if (!isEmpty(listExistingColumn)) {
      listExistingColumn.forEach((column) => {
        getDataManualColumn(column);
      });
      setIsSubmit(false);
      setNewColumn(null);
      setListExistingColumn([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newColumn, listExistingColumn]);

  const handleChange = (value, record, columnName) => {
    setTimeout(() => {
      setListOfTalents((prevState) => {
        if (!isArray(prevState)) return [];

        return prevState.map((item) => {
          if (item.staff_id !== record.staff_id) {
            return item;
          }

          return {
            ...item,
            columns: item.columns.map((column) => {
              if (column.name === columnName) {
                return {
                  ...column,
                  name: columnName,
                  type: column.type,
                  value,
                };
              }
              return column;
            }),
          };
        });
      });
    }, 500);
  };

  const getCustomMenuDropdown = (column, confirm) => {
    return (
      <div className={styles.customDropdown}>
        <button
          type={'button'}
          onClick={() => {
            setOpenEditColumnName(true);
            setEditColumn(column);
            confirm({
              closeDropdown: true,
            });
          }}
          disabled={!column?.is_manual}
        >
          <img src={edit_report_name} alt="Edit Column Name" /> Rename
        </button>
        <button
          type={'button'}
          onClick={() => {
            setOpenDuplicateColumn(true);
            setEditColumn(column);
            confirm({
              closeDropdown: true,
            });
          }}
          disabled={isEditMode || !column?.is_manual}
        >
          <img src={duplicate_green_icon} alt="Duplicate Column" /> Duplicate
        </button>
        <button
          type={'button'}
          onClick={() => {
            setShowDeleteColumnModal(true);
            setDeleteColumn(column);
          }}
        >
          <img src={deleteIcon} alt="Delete Column" /> Delete
        </button>
      </div>
    );
  };

  const getContentStatusSelection = (value) => {
    let tempHTML = '';
    switch (value) {
      case REPORT_COLUMN_TYPE_VALUE.STATUS_SELECTION[0].value:
        tempHTML = <img src={green_option} alt="Status Selection" />;
        break;
      case REPORT_COLUMN_TYPE_VALUE.STATUS_SELECTION[1].value:
        tempHTML = <img src={yellow_option} alt="Status Selection" />;
        break;
      case REPORT_COLUMN_TYPE_VALUE.STATUS_SELECTION[2].value:
        tempHTML = <img src={red_option} alt="Status Selection" />;
        break;
      case REPORT_COLUMN_TYPE_VALUE.STATUS_SELECTION[3].value:
        tempHTML = <img src={grey_option} alt="Status Selection" />;
        break;
      default:
        break;
    }
    return tempHTML;
  };

  const handleReturnValue = (value, type) => {
    let tempHTML = '';
    if ([TALENT_FILTER_OPTIONS.PERSONALITY].includes(type)) {
      switch (value) {
        case VALUE_TYPES.HIGH:
          tempHTML = <img src={green_option} alt="Status Selection" />;
          break;
        case VALUE_TYPES.MEDIUM:
          tempHTML = <img src={yellow_option} alt="Status Selection" />;
          break;
        case VALUE_TYPES.LOW:
          tempHTML = <img src={red_option} alt="Status Selection" />;
          break;
        default:
          tempHTML = <img src={grey_option} alt="Status Selection" />;
          break;
      }
    } else {
      tempHTML = <span>{value ?? 'N/A'}</span>;
    }
    return tempHTML;
  };

  const getNewColumn = (column) => {
    return {
      position: column.position,
      title: <TitlePosition isEditMode={isEditMode} column={column} />,
      dataIndex: `${column.name}.${timestamp}`,
      key: `${column.name}.${timestamp}`,
      align: 'left',
      width: 200,
      filterIcon: <img src={edit_delete} alt="" />,
      filterDropdown: ({ confirm }) => {
        return getCustomMenuDropdown(column, confirm);
      },
      render: (_, record) => {
        let tempHTML = '';
        switch (column.type) {
          case REPORT_COLUMN_TYPE[0].value:
            tempHTML = (
              <div className={'normalContent'} key={`${REPORT_COLUMN_TYPE[0].value}.${timestamp}`}>
                {isEditMode ? (
                  <Input
                    key={record.staff_id}
                    className={`customInput`}
                    placeholder={'Enter Details'}
                    onChange={(e) => handleChange(e.target.value, record, column.name)}
                    defaultValue={
                      record.columns.filter((item) => item.name === column.name)[0]?.value || null
                    }
                  />
                ) : (
                  <span>
                    {record.columns.filter((item) => item.name === column.name)[0]?.value}
                  </span>
                )}
              </div>
            );
            break;
          case REPORT_COLUMN_TYPE[1].value:
            tempHTML = (
              <div className={'normalContent'}>
                {isEditMode ? (
                  <Space wrap key={`${REPORT_COLUMN_TYPE[1].value}.${timestamp}`}>
                    <Select
                      key={record.staff_id}
                      style={{ width: 168 }}
                      onChange={(value) => handleChange(value, record, column.name)}
                      placeholder={'Status Selection'}
                      optionLabelProp="label"
                      className={`customSelectStatus`}
                      defaultValue={
                        record.columns.filter((item) => item.name === column.name)[0]?.value || null
                      }
                    >
                      {REPORT_COLUMN_TYPE_VALUE.STATUS_SELECTION.map((option) => {
                        let tempHTML = getContentStatusSelection(option.value);
                        return (
                          <Option
                            key={option.value}
                            value={option.label}
                            label={option.label}
                            className={'customSelectOption'}
                          >
                            <Space>{tempHTML}</Space>
                          </Option>
                        );
                      })}
                    </Select>
                  </Space>
                ) : (
                  getContentStatusSelection(
                    record.columns.filter((item) => item.name === column.name)[0]?.value
                  )
                )}
              </div>
            );
            break;
          case REPORT_COLUMN_TYPE[2].value:
            tempHTML = (
              <div className={'normalContent'}>
                {isEditMode ? (
                  <Space wrap key={`${REPORT_COLUMN_TYPE[2].value}.${timestamp}`}>
                    <Select
                      key={record.staff_id}
                      style={{ width: 168 }}
                      onChange={(value) => handleChange(value, record, column.name)}
                      placeholder={'Yes/No Selection'}
                      defaultValue={
                        record.columns.filter((item) => item.name === column.name)[0]?.value || null
                      }
                    >
                      {REPORT_COLUMN_TYPE_VALUE.YES_NO_SELECTION.map((option) => {
                        return (
                          <Option
                            key={option.value}
                            value={option.label}
                            label={option.label}
                            className={'customSelectOption'}
                          >
                            <Space>{option.label}</Space>
                          </Option>
                        );
                      })}
                    </Select>
                  </Space>
                ) : (
                  <span>
                    {record.columns.filter((item) => item.name === column.name)[0]?.value}
                  </span>
                )}
              </div>
            );
            break;

          default:
            tempHTML = (
              <div className={'normalContent'}>
                {handleReturnValue(
                  record.columns.filter((item) => item.name === column.name)[0]?.value,
                  record.columns.filter((item) => item.name === column.name)[0]?.type
                )}
              </div>
            );
            break;
        }
        return tempHTML;
      },
    };
  };

  const handleEditManualColumns = (column) => {
    setManualColumnEdit(column);
    setOpen(true);
  };

  const customColumns = useMemo(() => {
    return listOfTalents[0]?.columns.map((column) => getNewColumn(column)) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfColumnName, isEditMode, isSubmit, duplicatedColumn, filterFinderColumns]);

  const addNewColumn = () => {
    setManualColumnEdit(null);
    setOpen(true);
  };

  const confirmUpdateReport = () => {
    setCancelUpdateReport(false);
    handleSave(true);
  };

  const confirmCancelUpdateReport = async () => {
    history.push('/report-builder');
  };

  const fetchReportDetail = useCallback(async () => {
    try {
      setLoading(true);
      const res = await reportBuilderApi.getReportDetails(reportId, roleId);
      if (res.status === 200) {
        const result = res.data.result;
        setInitData(result);
        setListOfTalents(result.list_of_talents);
        setListOfColumnName(getListOfColumnName(result.list_of_talents));
        setInitialListOfTalentData(result.list_of_talents);
      }
    } catch (error) {
      console.error(error);
      if ([403, 401].includes(error?.response?.status)) {
        history.push('/access-denied');
      }
    } finally {
      setLoading(false);
    }
  }, [history, reportId, roleId]);

  useEffect(() => {
    fetchReportDetail();
  }, [fetchReportDetail]);

  const fetchUpdateReport = async (talents) => {
    const tempBody = {
      report_id: Number(reportId),
      list_of_talents: talents.map((item) => ({
        staff_id: item.staff_id,
        columns: item.columns || [],
      })),
    };

    try {
      setLoading(true);
      const res = await reportBuilderApi.updateReport(tempBody);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: REPORT_BUILDER_MESSAGE.AN_UNEXPECTED_ERROR,
          isScroll: true,
        })
      );
      return false;
    } finally {
      setLoading(false);
      setIsEditMode(false);
      setDuplicatedColumn([]);
    }
  };

  const handleReturnMessage = () => {
    if (isEdit || isEdited || !isEmpty(initData.list_of_talents)) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: REPORT_BUILDER_MESSAGE.EDIT_SUCCESS,
          isScroll: true,
        })
      );
      return;
    }
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.ADD_COLUMN_SUCCESS,
        isScroll: true,
      })
    );
    setIsEdited(true);
  };

  const handleSave = async (isBack) => {
    const res = await fetchUpdateReport(listOfTalents);
    if (!res) return;
    await fetchFinderFilterOptions();
    await fetchManualColumns();
    handleReturnMessage();
    if (isBack) {
      history.push('/report-builder');
    } else {
      await fetchReportDetail();
    }
  };

  const handleAddTalent = async (newListOfTalent, setOpenPopup) => {
    const res = await fetchUpdateReport(newListOfTalent);
    if (!res) return;
    setOpenPopup(false);
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.ADD_TALENT_SUCCESS,
        isScroll: true,
      })
    );
    await fetchReportDetail();
  };

  const confirmAddNewColumn = async () => {
    setShowConfirmAddTalentModal(false);
    setShowAddTalentModal(true);
    await handleSave();
  };

  const confirmCancelAddNewTalent = async () => {
    setShowConfirmAddTalentModal(false);
    setShowAddTalentModal(true);
    await fetchFinderFilterOptions();
    await fetchReportDetail();
  };

  const handleDuplicateOnClient = (columns) => {
    let newListOfTalent = cloneDeep(listOfTalents);
    newListOfTalent.forEach((item, index) => {
      columns.forEach((column, columnIndex) => {
        if (index === columnIndex) {
          item.columns.push(column);
        }
      });
    });
    setListOfTalents(newListOfTalent);
    setListOfColumnName((prev) => [...prev, columns[0].name]);
    setCurrentColumns((prev) => [
      ...prev,
      { name: columns[0].name, type: columns[0].type, id: null },
    ]);
    setDuplicatedColumn(null);
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.DUPLICATE_COLUMN_SUCCESS,
        isScroll: true,
      })
    );
  };

  const handleDuplicatedColumn = async (columns) => {
    if (columns[0]?.id) {
      let newInitData = cloneDeep(initData.list_of_talents);
      newInitData.forEach((item, index) => {
        columns.forEach((column, columnIndex) => {
          if (index === columnIndex) {
            item.columns.push({
              ...column,
              id: null,
            });
          }
        });
      });
      try {
        setLoading(true);
        const tempBody = {
          report_id: Number(reportId),
          list_of_talents: newInitData.map((item) => ({
            staff_id: item.staff_id,
            columns: item.columns || [],
          })),
        };
        const res = await reportBuilderApi.updateReport(tempBody);
        if (res.status === 200) {
          handleDuplicateOnClient(columns);
          setInitData((prev) => ({ ...prev, list_of_talents: newInitData }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      handleDuplicateOnClient(columns);
    }
  };

  const handleEditName = () => {
    setOpenEditName(true);
  };

  const handleDeleteColumnOnClient = (newListOfTalents) => {
    setListOfTalents(newListOfTalents);
    setInitialListOfTalentData(newListOfTalents);
    setListOfColumnName((prev) => prev.filter((name) => name !== deleteColumn?.name));
    if (deleteColumn?.is_manual) {
      setDeleteColumn(null);
    }
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.DELETE_COLUMN,
        isScroll: true,
      })
    );
  };

  const handleDeleteColumn = async () => {
    if (!deleteColumn?.is_manual) {
      setIsDeleteFinderColumn(true);
    }
    if (!deleteColumn?.name) return;
    const isCallAPIDelete = getListOfColumnName(initData.list_of_talents).includes(
      deleteColumn?.name
    );
    const newListOfTalent = listOfTalents.map((talent) => ({
      ...talent,
      columns: talent.columns.filter((column) => column.name !== deleteColumn?.name),
    }));
    const newInitData = initData.list_of_talents.map((talent) => ({
      ...talent,
      columns: talent.columns.filter((column) => column.name !== deleteColumn?.name),
    }));
    if (isCallAPIDelete) {
      try {
        setDeleting(true);
        const tempBody = {
          report_id: Number(reportId),
          list_of_talents: newInitData.map((item) => ({
            staff_id: item.staff_id,
            columns: item.columns || [],
          })),
        };
        const res = await reportBuilderApi.updateReport(tempBody);
        if (res.status === 200) {
          await fetchFinderFilterOptions();
          handleDeleteColumnOnClient(newListOfTalent);
          setInitData((prev) => ({ ...prev, list_of_talents: newInitData }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDeleting(false);
      }
    } else {
      handleDeleteColumnOnClient(newListOfTalent);
    }
    await fetchManualColumns();
    setShowDeleteColumnModal(false);
  };

  const handleEditColumn = () => {
    setIsEditMode(true);
  };

  const handleAddRemoveColumn = (column) => {
    if (listOfColumnName.includes(column.name)) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: REPORT_BUILDER_MESSAGE.DUPLICATE_COLUMN_NAME,
          isScroll: true,
        })
      );
      return;
    }

    setNewColumn({
      ...column,
      id: column?.id || null,
      name: column.name,
      type: column.type,
      value: '',
    });
  };

  const confirmEditManualColumn = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const body = {
          report_id: data?.report_id,
          column_id: data.id,
          column_name: data.column_name,
          role_ids: data.roles ?? [],
          businesses: data.businesses ?? [],
          hr_partners: data.hr_partners ?? [],
        };
        await reportBuilderApi.updateColumnName(body);
        await fetchManualColumns();
        await fetchReportDetail();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: REPORT_BUILDER_MESSAGE.EDIT_MANUAL_COLUMN_SUCCESS,
            isScroll: true,
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchManualColumns, fetchReportDetail]
  );

  const isCheckErrorAddNewTalent = useMemo(() => {
    if (!isEmpty(listOfTalents)) {
      return !isEqual(initialListOfTalentData, listOfTalents);
    } else {
      return false;
    }
  }, [initialListOfTalentData, listOfTalents]);

  return (
    <div className={styles.wrapperContainer}>
      <BreadCrumb level={2} breadCrumbList={RB_BREAD_CRUMB} />
      <GlobalAlertMessage />

      <BasicLoading spinning={loading}>
        {initData?.report_name && (
          <h2 className={styles.reportName}>
            <span>{initData.report_name}</span>
            <img
              src={edit_report_name}
              alt="edit_report_name"
              onClick={handleEditName}
              onKeyDown={handleEditName}
            />
          </h2>
        )}
        <EditReportNameModal
          open={openEditName}
          setOpen={setOpenEditName}
          initData={initData}
          fetchReportDetail={fetchReportDetail}
        />
        <EditColumnNameModal
          open={openEditColumnName}
          setOpen={setOpenEditColumnName}
          editColumn={{ ...editColumn, report_id: reportId }}
          setListOfTalents={setListOfTalents}
          setListOfColumnName={setListOfColumnName}
          currentColumns={currentColumns}
          setCurrentColumns={setCurrentColumns}
          fetchManualColumns={fetchManualColumns}
        />
        <DuplicateColumnModal
          open={openDuplicateColumn}
          setOpen={setOpenDuplicateColumn}
          editColumn={editColumn}
          currentColumns={currentColumns}
          listOfTalents={listOfTalents}
          setDuplicatedColumn={setDuplicatedColumn}
          handleDuplicatedColumn={handleDuplicatedColumn}
          fetchManualColumns={fetchManualColumns}
        />
        <TalentListSearchBar
          initData={initData}
          setInitData={setInitData}
          listOfTalents={listOfTalents}
          setListOfTalents={setListOfTalents}
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
          setModeView={setModeView}
          modeView={modeView}
          setIsEditMode={setIsEditMode}
          handleAddTalent={handleAddTalent}
          isCheckErrorAddNewTalent={isCheckErrorAddNewTalent}
          setShowConfirmAddTalentModal={setShowConfirmAddTalentModal}
          setShowAddTalentModal={setShowAddTalentModal}
          showAddTalentModal={showAddTalentModal}
        />

        {!isEmpty(listOfTalents) ? (
          <>
            <div className={styles.groupColumn}>
              <h3 className={styles.columnTitle}>
                {isEditMode && <span>You may select the field to add into the table.</span>}
                <button type={'button'} className={styles.editBtn} onClick={handleEditColumn}>
                  <img src={edit_green_icon} alt="" /> Edit Column
                </button>
              </h3>
              {isEditMode && (
                <ListTalentFinderColumn
                  isEditMode={isEditMode}
                  filterOptions={filterOptions}
                  loadingFilter={loadingFilter}
                  setFilterFinderColumns={setFilterFinderColumns}
                  applyFinderFilterColumn={applyFinderFilterColumn}
                  deleteColumn={deleteColumn}
                  isDeleteFinderColumn={isDeleteFinderColumn}
                  setIsDeleteFinderColumn={setIsDeleteFinderColumn}
                />
              )}
              <ListOtherColumn
                isEditMode={isEditMode}
                currentColumns={currentColumns}
                handleAddRemoveColumn={handleAddRemoveColumn}
                listColumnName={listOfColumnName}
                addNewColumn={addNewColumn}
                setListExistingColumn={setListExistingColumn}
                handleEditColumns={handleEditManualColumns}
              />
            </div>
            <h3 className={styles.mainTitle}>List of Talents</h3>
            <ListOfTalentTable
              data={listOfTalents}
              loading={false}
              customColumns={customColumns}
              mode={modeView}
              isEditMode={isEditMode}
              selectedTalents={selectedTalents}
              setSelectedTalents={setSelectedTalents}
              setListOfTalents={setListOfTalents}
              setListOfColumnName={setListOfColumnName}
            />

            <div className={styles.groupBotButton}>
              <BasicButton
                style={{ marginRight: '10px' }}
                onClick={() => setCancelUpdateReport(true)}
                disabled={!isEditMode}
              >
                Cancel
              </BasicButton>
              <BasicButton mode="teal" onClick={() => handleSave()} disabled={!isEditMode}>
                Save
              </BasicButton>
            </div>
          </>
        ) : (
          <img className={styles.notFound} src={rb_talent_not_found} alt="rb_talent_not_found" />
        )}

        <AddEditManualColumn
          open={open}
          setOpen={setOpen}
          setNewColumn={setNewColumn}
          currentColumns={currentColumns}
          setCurrentColumns={setCurrentColumns}
          manualColumnEdit={manualColumnEdit}
          confirmEditManualColumn={confirmEditManualColumn}
        />
      </BasicLoading>

      <ModelTC
        info={{
          type: 'deleteColumnTalentReportBuilder',
          visible: showDeleteColumnModal,
          setVisible: setShowDeleteColumnModal,
          loading: deleting,
          handleSubmit: handleDeleteColumn,
          onClose: () => setShowDeleteColumnModal(false),
        }}
      />
      <ModelTC
        info={{
          type: 'cancelUpdateReportBuilder',
          visible: cancelUpdateReport,
          setVisible: setCancelUpdateReport,
          handleSubmit: confirmUpdateReport,
          onClose: confirmCancelUpdateReport,
        }}
      />
      <ModelTC
        info={{
          type: 'confirmAddNewTalent',
          visible: showConfirmAddTalentModal,
          setVisible: setShowConfirmAddTalentModal,
          handleSubmit: confirmAddNewColumn,
          onClose: confirmCancelAddNewTalent,
        }}
      />
    </div>
  );
};

export default ReportDetails;
