import { Table } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

import { MODE_TYPES } from '../../../../utils/constants';
import TalentFinderPagination from '../Pagination/TalentFinderPagination';
import TalentFinderTooltip from '../TalentFinderTooltip/TalentFinderTooltip';
import styles from './talent-finder-list.module.scss';

const columns = [
  {
    title: 'No.',
    dataIndex: 'key',
    width: 50,
  },
  {
    title: '',
    dataIndex: 'avatar',
    width: 30,
  },
  {
    title: 'Name/Staff ID',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Position Name',
    dataIndex: 'positionName',
  },
  {
    title: 'Department',
    dataIndex: 'department',
  },
  {
    title: 'Company/OPU',
    dataIndex: 'companyOpu',
  },
  {
    title: 'Business Unit',
    dataIndex: 'businessUnit',
  },
  {
    title: '',
    dataIndex: 'action',
    width: 150,
  },
];
const TalentFinderList = (props) => {
  const { page, toResults, limit, fromResult, totalResult, data, onPageChange } = props;

  const handleRenderPagination = () => (
    <>
      {!isEmpty(data) && (
        <TalentFinderPagination
          currentPage={page}
          totalResult={totalResult}
          limit={limit}
          startRecordNumber={fromResult}
          endRecordNumber={toResults > totalResult ? totalResult : toResults}
          onPageChange={onPageChange}
        />
      )}
    </>
  );

  return (
    <div className={styles.detail_list_section} data-testid="talent-finder-list">
      <Table
        columns={columns}
        dataSource={data.map((d) => {
          return {
            ...d,
            action: (
              <div
                className={styles.action}
                onKeyDown={() => {}}
                onClick={() => window.open(`/profile/${d.staffId}`, '_blank')}
              >
                View Talent
              </div>
            ),
            avatar: (
              <>
                <div className={styles.avatar_wrapper}>
                  <img
                    src={d.avatar}
                    alt=""
                    width={40}
                    height={40}
                    style={{ borderRadius: '50%', border: '3px solid #F0F3F7' }}
                  />
                  <TalentFinderTooltip item={d} mode={MODE_TYPES.LIST} />
                </div>
              </>
            ),
            name: (
              <div>
                <div className={styles.table_name} style={{ marginBottom: '5px' }}>
                  {d.name}
                </div>
                <div className={styles.table_except_name}>Staff ID : {d.staffId}</div>
              </div>
            ),
            positionName: <div className={styles.table_except_name}>{d.positionName}</div>,
            department: <div className={styles.table_except_name}>{d.department}</div>,
            companyOpu: <div className={styles.table_except_name}>{d.companyOpu}</div>,
            businessUnit: <div className={styles.table_except_name}>{d.businessUnit}</div>,
          };
        })}
        className={styles.detail_list_table}
        pagination={false}
        footer={handleRenderPagination}
      />
    </div>
  );
};

export default TalentFinderList;
