import { Dropdown, Space, Table } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AiButton } from '../../../../../assets/common';
import { deleteNote, edit_delete, editNote } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { isCheckError, onChangeInputData } from '../../../../../hooks/useFormik';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { FUNCTION_LIST_MESSAGE, MESSAGE_TYPES } from '../../../../../utils/constants';
import { formValidationSchema, handleCheckEmptyValue } from '../AddFunctionList/formValidation';
import * as styles from './data-table-managing.module.scss';
import { AiInputCustom, TableManagement } from './styled';

export default function DataTableManaging({
  data,
  setDeleteRecord,
  setDeleteSelecteds,
  setSelectedRecordEdit,
  setSeletedRows,
  seletedRows,
  totalData,
  limit,
  setIsEdit,
  isEdit,
}) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      job_family: '',
      sub_job_family: '',
      functions: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useEffect(() => {
    if (data.length) {
      const tempData = data.map((item, index) => {
        return {
          ...item,
          key: `${item.role_level}-${index + 1}.${item.id}.${
            item.only_one_item || item.first_item || item.last_item || ''
          }`,
        };
      });
      setNewData(tempData);
      setCurrentPage(1);
    }
  }, [data]);

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: type === MESSAGE_TYPES.ERROR,
      })
    );
  };

  const handleEditFunction = async () => {
    await formik.submitForm();
    const values = formik.values;
    const isCheck = handleCheckEmptyValue(values);
    if (!formik.isValid || isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, FUNCTION_LIST_MESSAGE.REQUIRED_FIELDS);
      return;
    }
    setSelectedRecordEdit({
      job_family: values?.job_family.trim(),
      sub_job_family: values?.sub_job_family.trim(),
      functions: values?.functions.trim(),
      id: selectedRecord?.id,
    });
  };

  const handleResetForm = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({}, false);
  };

  useEffect(() => {
    if (!isEdit) {
      handleResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const menuItems = {
    items: [
      {
        icon: <img src={editNote} alt="edit" />,
        label: (
          <div
            style={{ textDecoration: 'none' }}
            className={styles.editButton}
            onKeyDown={() => {}}
            onClick={() => handleClickEditBtn()}
          >
            Edit
          </div>
        ),
        key: '0',
      },
      {
        icon: <img src={deleteNote} alt="delete" />,
        label: <div className={styles.deleteButton}>Delete</div>,
        key: '1',
        onClick: () => {
          setShowDeleteModal(true);
        },
      },
    ],
  };
  const handleClickEditBtn = async () => {
    const job_family = selectedRecord?.job_family;
    const sub_job_family = selectedRecord?.sub_job_family;
    const functions = selectedRecord?.function;

    formik.setValues({
      job_family: job_family || '',
      sub_job_family: sub_job_family || '',
      functions: functions || '',
    });
    setIsEdit(true);
  };

  const columns = [
    {
      title: 'Job Family',
      dataIndex: 'job_family',
      align: 'left',
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <AiInputCustom
              placeholder=""
              name="job_family"
              value={formik.values.job_family}
              onChange={(_event) => onChangeInputData(formik, _event.target.value, 'job_family')}
              status={isCheckError(formik, 'job_family') ? 'error' : ''}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span
              className={`${styles.textField} text_field`}
              style={{ width: '306px', fontWeight: 700, color: '#3F3C4C' }}
            >
              {record?.job_family}
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Sub Job Family',
      dataIndex: 'sub_job_family',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id ? (
            <div className={styles.inputFieldWrapper}>
              <AiInputCustom
                placeholder=""
                name="sub_job_family"
                value={formik.values.sub_job_family}
                onChange={(_event) =>
                  onChangeInputData(formik, _event.target.value, 'sub_job_family')
                }
                status={isCheckError(formik, 'sub_job_family') ? 'error' : ''}
              />
            </div>
          ) : (
            <span
              className={`${styles.textField}`}
              style={{ width: '306px', fontWeight: 700, color: '#3F3C4C' }}
            >
              {record?.sub_job_family}
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Function',
      dataIndex: 'function',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <AiInputCustom
              placeholder=""
              name="functions"
              value={formik.values.functions}
              onChange={(_event) => onChangeInputData(formik, _event.target.value, 'functions')}
              status={isCheckError(formik, 'functions') ? 'error' : ''}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span className={styles.textField}>{record?.function}</span>
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
      render: (_, record) => (
        <>
          {!isEdit && (
            <Dropdown
              menu={menuItems}
              trigger={['click']}
              style={{ minWidth: 110 }}
              placement="bottomRight"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Space style={{ cursor: 'pointer' }}>
                <img
                  src={edit_delete}
                  alt="dot"
                  onKeyDown={() => {}}
                  onClick={() => setSelectedRecord(record)}
                />
              </Space>
            </Dropdown>
          )}

          {isEdit && record?.id === selectedRecord?.id && (
            <AiButton mode={'teal'} onKeyDown={() => {}} onClick={() => handleEditFunction()}>
              Save
            </AiButton>
          )}
        </>
      ),
    },
  ];

  const paginationConfig = {
    total: totalData,
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => {
      pageNum !== currentPage && setCurrentPage(pageNum);
      formik.setValues({
        job_family: '',
        sub_job_family: '',
        functions: '',
      });
      setIsEdit(false);
    },
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      const seletedRows = handleConvertSelectedRows(_selectedRows);
      setSeletedRows(selectedRowKeys);
      setDeleteSelecteds(seletedRows);
    },
    selectedRowKeys: seletedRows,
  };

  const handleConvertSelectedRows = (data) => {
    let tempArr = [];
    if (!isEmpty(data)) {
      data.forEach((item) => {
        const tempId = !isEmpty(item?.key) ? item?.key.split('.')[1] : '';
        tempArr.push(Number(tempId));
      });
    }
    return tempArr;
  };

  return (
    <div style={{ marginTop: 24 }}>
      <TableManagement>
        <Table
          rowSelection={
            !isEdit && {
              type: 'checkbox',
              ...rowSelection,
            }
          }
          className={isEdit ? 'customMappingGroupTable editMode' : 'customMappingGroupTable'}
          columns={columns}
          rowClassName={(record) => {
            return `row_${record?.id} ${
              record?.only_one_item || record?.first_item || record?.last_item
            }`;
          }}
          dataSource={newData}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </TableManagement>
      <ModelTC
        info={{
          type: 'deleteFunctionList',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => setShowDeleteModal(false),
          handleSubmit: () => {
            setDeleteRecord(selectedRecord);
            setShowDeleteModal(false);
          },
        }}
      />
    </div>
  );
}
