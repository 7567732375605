import { Table } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

import { chevron_down_arrow } from '../../../../../assets/img';
import CustomFilterTable from '../../../../../components/CustomFilterTable';
import * as styles from './data-table-view-details.module.scss';
import { TableViewResult } from './styled';

export default function DataTableViewDetails({
  data,
  totalData,
  limit,
  filterData,
  page,
  setPage,
  setFilterViewDetials,
  filterViewDetails,
}) {
  const onApplyTopTalentStatus = useCallback(
    (filters) => {
      setFilterViewDetials((prev) => {
        return { ...prev, top_talent_status: filters };
      });
    },
    [setFilterViewDetials]
  );
  const onApplyRoleLevel = useCallback(
    (filters) => {
      setFilterViewDetials((prev) => {
        return { ...prev, role_level: filters };
      });
    },
    [setFilterViewDetials]
  );

  const onApplySalaryGrade = useCallback(
    (filters) => {
      setFilterViewDetials((prev) => {
        return {
          ...prev,
          salary_grade: filters,
        };
      });
    },
    [setFilterViewDetials]
  );

  const onApplyBusinessUnit = useCallback(
    (filters) => {
      setFilterViewDetials((prev) => {
        return { ...prev, business_unit: filters };
      });
    },
    [setFilterViewDetials]
  );

  const handleReturnFilter = (data, setListSelected, listSelected, onApplyFilters, close) => {
    return (
      <CustomFilterTable
        setSelectedKeys={setListSelected}
        selectedKeys={listSelected}
        filters={
          (!isEmpty(data) &&
            data.map((item) => ({
              value: item,
              text: item,
              isChecked: item.value,
            }))) ||
          []
        }
        onApplyFilters={onApplyFilters}
        isLoading={false}
        close={close}
      />
    );
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'left',
      render: (_, record) => {
        return <span className={styles.normalContent}>{record.no}</span>;
      },
    },
    {
      title: 'Staff Name',
      dataIndex: 'staffName',
      align: 'left',
      className: `custom-text-field`,
      width: 300,
      render: (_, record) => {
        return (
          <div className={styles.expandWrapper}>
            <span className={styles.normalContent}>{`${record?.staffName || ''}`}</span>
          </div>
        );
      },
    },
    {
      title: 'Staff ID',
      dataIndex: 'staffId',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return <span className={styles.normalContent}>{record?.staffId || ''}</span>;
      },
    },
    {
      title: 'Top Talent Status',
      dataIndex: 'topTalentStatus',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return (
          <span className={styles.normalContent}>
            {record?.topTalentStatus || record?.top_talent_status || ''}
          </span>
        );
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterData?.topTalentStatus,
          setFilterViewDetials,
          filterViewDetails.top_talent_status,
          onApplyTopTalentStatus,
          close
        ),
    },
    {
      title: 'Salary Grade',
      dataIndex: 'salaryGrade',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return (
          <span className={styles.normalContent}>{record?.salaryGrade || record?.sg || ''}</span>
        );
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterData?.salaryGrade,
          setFilterViewDetials,
          filterViewDetails.salary_grade,
          onApplySalaryGrade,
          close
        ),
    },
    {
      title: 'Role Level',
      dataIndex: 'role_level',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return <span className={styles.normalContent}>{record?.role_level || ''}</span>;
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterData?.roleLevel,
          setFilterViewDetials,
          filterViewDetails.role_level,
          onApplyRoleLevel,
          close
        ),
    },
    {
      title: 'Business Unit',
      dataIndex: 'business',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return <span className={styles.normalContent}>{record?.business || ''}</span>;
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterData?.business,
          setFilterViewDetials,
          filterViewDetails.business_unit,
          onApplyBusinessUnit,
          close
        ),
    },
  ];

  const showContentPaging = (total, range) => {
    return (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    );
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
    }
  };

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNumber) => handlePageChange(pageNumber),
    showTotal: (total, range) => showContentPaging(total, range),
  };

  return (
    <div style={{ marginTop: 24 }}>
      <TableViewResult>
        <Table
          className={`customTable`}
          columns={columns}
          dataSource={
            data.length
              ? data.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    key: `${item.staffId}.${index}
              }`,
                  };
                })
              : []
          }
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </TableViewResult>
    </div>
  );
}
