import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const initState = {
  position_code: null,
  position_name: '',
  set_index: 0,
  set_matching_criteria: [],
  prevSetMatching: null,
  isSavedSetMatching: false,
  isValidForm: true,
  list_ranking: [],
  isFirstInitSetMatching: false,
};

const manageCriteriaSetMatchingSlice = createSlice({
  name: 'addEditSetMatchingCriteria',
  initialState: initState,
  reducers: {
    updateSetMatchingIndex: (state, action) => {
      state.set_index = action.payload;
    },
    updateListRanking: (state, action) => {
      state.list_ranking = action.payload;
    },
    addNewSetMatchingCriteria: (state, action) => {
      state.set_matching_criteria.push({
        set_id: uuidv4(),
        set_name: action.payload.set_name,
        is_set_main: action.payload.is_set_main,
        set_number: action.payload.set_number,
        last_update: action.payload.last_update,
        status: action.payload.status,
        selected_criteria: action.payload.selected_criteria || [],
        entered_criteria: action.payload.entered_criteria || [],
        entered_un_rank: action.payload.entered_un_rank || [],
        list_edit: [],
        set_as_main: false,
        isEditSetMatching: true,
        isDeleteCriteria: false,
        isEditCriteria: false,
        isDuplicate: action.payload.isDuplicate ?? false,
      });
    },
    updateSetMatchingName: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index].set_name = action.payload;
      }
    },
    updatePrevSetMatching: (state, action) => {
      state.prevSetMatching = action.payload;
    },
    updateIsFirstInitSetMatching: (state, action) => {
      state.isFirstInitSetMatching = action.payload;
    },
    updateMainMatching: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        const { set_index, value } = action.payload;
        if (value) {
          state.set_matching_criteria = cloneDeep(state.set_matching_criteria).map(
            (item, index) => {
              return {
                ...item,
                set_as_main: set_index === index ? true : false,
              };
            }
          );
        } else {
          state.set_matching_criteria = cloneDeep(state.set_matching_criteria).map(
            (item, index) => {
              return {
                ...item,
                set_as_main: set_index === index ? false : item.is_set_main,
              };
            }
          );
        }
      }
    },
    updateSetAsMainListMatching: (state, action) => {
      if (action.payload) {
        state.set_matching_criteria = action.payload;
      }
    },
    updateIsSavedSetMatching: (state, action) => {
      state.isSavedSetMatching = action.payload;
    },
    updateIsEditSetMatching: (state, action) => {
      const { set_index, value } = action.payload;
      if (set_index !== 'All') {
        state.set_matching_criteria = cloneDeep(state.set_matching_criteria).map((item, index) => {
          return {
            ...item,
            isEditSetMatching: set_index === index ? value : item.isEditSetMatching,
          };
        });
      }
      if (set_index === 'All') {
        state.set_matching_criteria = state.set_matching_criteria.map((i) => {
          return {
            ...i,
            isEditSetMatching: false,
          };
        });
      }
    },
    updateIsDeleteCriteria: (state, action) => {
      const { set_index, value } = action.payload;
      state.set_matching_criteria = cloneDeep(state.set_matching_criteria).map((item, index) => {
        return {
          ...item,
          isDeleteCriteria: index === set_index ? value : item.isDeleteCriteria || false,
        };
      });
    },
    updateIsEditCriteria: (state, action) => {
      const { set_index, value } = action.payload;
      state.set_matching_criteria = cloneDeep(state.set_matching_criteria).map((item, index) => {
        return {
          ...item,
          isEditCriteria: index === set_index ? value : item.isEditCriteria || false,
        };
      });
    },
    updateIsValidForm: (state, action) => {
      state.isValidForm = action.payload;
    },
    updateSetMatchingByIndex: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index] = action.payload;
      }
    },
    updateSelectedCriteria: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index].selected_criteria = action.payload;
      }
    },
    updateEnteredMatchingCriteria: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index].entered_criteria = action.payload;
      }
    },
    addOrUpdateMatchingCriteria: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        let index = 0;
        const isDup = state.set_matching_criteria[state.set_index].entered_criteria.find(
          (item, idx) => {
            if (item.criteria === action.payload.criteria) {
              index = idx;
              return item;
            }
            return null;
          }
        );
        if (isEmpty(isDup)) {
          state.set_matching_criteria[state.set_index].entered_criteria.push(action.payload);
        } else {
          state.set_matching_criteria[state.set_index].entered_criteria[index] = action.payload;
        }
      }
    },
    updateUnRank: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        const index = state.set_matching_criteria[state.set_index].entered_un_rank.findIndex(
          (item, idx) => {
            return item.criteria === action.payload.criteria;
          }
        );
        state.set_matching_criteria[state.set_index].entered_un_rank[index] = action.payload;
      }
    },
    removeMatchingCriteria: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index].selected_criteria =
          state.set_matching_criteria[state.set_index].selected_criteria.filter(
            (item) => item.criteria !== action.payload
          );
        state.set_matching_criteria[state.set_index].entered_criteria = state.set_matching_criteria[
          state.set_index
        ].entered_criteria.filter((item) => item.criteria !== action.payload);
        state.set_matching_criteria[state.set_index].list_edit = state.set_matching_criteria[
          state.set_index
        ].list_edit.filter((item) => item.criteria !== action.payload);
      }
    },
    updateMatchingPosition: (state, action) => {
      state.position_code = action.payload.position_code;
      state.position_name = action.payload.position_name;
    },
    resetCriteriaState: (state) => {
      state.position_code = null;
      state.position_name = '';
      state.set_index = 0;
      state.set_matching_criteria = [];
      state.prevSetMatching = null;
      state.isSavedSetMatching = false;
      state.isValidForm = true;
      state.list_ranking = [];
    },
    updateInitCriteriaEditState: (state, action) => {
      state.position_code = action.payload.position_code;
      state.position_name = action.payload.position_name;
      state.set_matching_criteria = action.payload.set_matching_criteria.map((i) => {
        return {
          ...i,
          isEditSetMatching:
            cloneDeep(state.set_matching_criteria).find((item) => item.set_id === i.set_id)
              ?.isEditSetMatching ?? false,
        };
      });
    },
    summaryEnteredCriteria: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index].entered_criteria = action.payload;
      }
    },
    summaryUnRankEntered: (state, action) => {
      if (state.set_matching_criteria[state.set_index]) {
        state.set_matching_criteria[state.set_index].entered_un_rank = action.payload;
      }
    },
    removeSetMatchingCriteria: (state, action) => {
      state.set_matching_criteria = state.set_matching_criteria.filter(
        (s, index) => s.set_id !== action.payload
      );
    },
    duplicateListRanking: (state, action) => {
      const { set_index, list_ranking } = action.payload;
      state.list_ranking[set_index] = list_ranking;
    },
  },
});
const { actions, reducer: manageCriteriaSetMatchingReducer } = manageCriteriaSetMatchingSlice;
export const {
  updateEnteredMatchingCriteria,
  addOrUpdateMatchingCriteria,
  updateUnRank,
  removeMatchingCriteria,
  updateSetMatchingIndex,
  updateListRanking,
  addNewSetMatchingCriteria,
  updateSetMatchingName,
  updateMainMatching,
  updateSetAsMainListMatching,
  updateIsSavedSetMatching,
  updateIsEditSetMatching,
  updateIsDeleteCriteria,
  updateIsEditCriteria,
  updateIsValidForm,
  updateSetMatchingByIndex,
  updateSelectedCriteria,
  updateMatchingPosition,
  resetCriteriaState,
  updateInitCriteriaEditState,
  summaryEnteredCriteria,
  summaryUnRankEntered,
  updatePrevSetMatching,
  updateIsFirstInitSetMatching,
  removeSetMatchingCriteria,
  duplicateListRanking,
} = actions;
export default manageCriteriaSetMatchingReducer;
