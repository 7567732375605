import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { calendarApi } from '../../../../services/calendarApi';
import CalendarYearView from '../../components/CalendarYearView/CalendarYearView';
import DetailMonthlyView from '../../components/DetailMonthlyView/DetailMonthlyView';
import MenuCalendar from '../../components/MenuCalendar/MenuCalendar';
import { Container } from './styled';
import { CALENDAR } from '../../../../utils/constants';
import CommonLoading from '../../../../components/CommonLoading/CommonLoading';

const Calendar = (props) => {
  const { meetingType } = props;
  const user = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState(new Date());
  const [isMonthlyView, setIsMonthlyView] = useState(true);
  const [category, setCategory] = useState(CALENDAR.MEETING_CATEGORY);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [toDate, setToDate] = useState(moment(startDate).endOf('month').format('YYYY-MM-DD'));
  const [fromDate, setFromDate] = useState(moment(startDate).startOf('month').format('YYYY-MM-DD'));

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    if (isMonthlyView === true) {
      setToDate(moment(startDate).endOf('month').format('YYYY-MM-DD'));
      setFromDate(moment(startDate).startOf('month').format('YYYY-MM-DD'));
    } else {
      setToDate(moment(startDate).endOf('year').format('YYYY-MM-DD'));
      setFromDate(moment(startDate).startOf('year').format('YYYY-MM-DD'));
    }
  }, [startDate, isMonthlyView]);

  useEffect(() => {
    const query = {
      dateFilterType: 'range',
      fromDate: fromDate,
      toDate: toDate,
      limit: 2,
      category: category.toString(),
      meetingType: meetingType,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    async function getMeeting() {
      setLoading(true);
      try {
        const res = await calendarApi.getMeetings(query, user.roleActive);
        if (res.status === 200) {
          setData(res.data.result);
          scrollToElement(startDate.getDate());
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    getMeeting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, meetingType, category.toString(), user.roleActive.roleId]);

  return (
    <Container>
      {isMonthlyView ? (
        <>
          {loading ? (
            <div className="center-loading">
              <CommonLoading />
            </div>
          ) : (
            <DatePicker
              selected={startDate}
              inline
              minDate={new Date(fromDate)}
              maxDate={new Date(toDate)}
              formatWeekDay={(nameOfDay) => {
                return moment(nameOfDay).isValid() === true
                  ? moment(nameOfDay).format('dddd')
                  : nameOfDay;
              }}
              renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
                const handleDecreaseMonth = () => {
                  const newDay = new Date(moment(date).subtract(1, 'months'));
                  decreaseMonth();
                  setStartDate(newDay);
                };
                const handleIncreaseMonth = () => {
                  const newDay = new Date(moment(date).add(1, 'months'));
                  increaseMonth();
                  setStartDate(newDay);
                };
                return (
                  <MenuCalendar
                    scrollToElement={scrollToElement}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    decrease={handleDecreaseMonth}
                    increase={handleIncreaseMonth}
                    date={startDate}
                    setStartDate={setStartDate}
                    isMonthlyView={isMonthlyView}
                    setIsMonthlyView={setIsMonthlyView}
                    category={category}
                    setCategory={setCategory}
                    permissions={user.permissions}
                  />
                );
              }}
              renderDayContents={(day, date) => (
                <DetailMonthlyView
                  day={day}
                  date={date}
                  data={data}
                  params={{
                    category: category.toString(),
                    meetingType,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }}
                />
              )}
              onChange={(date) => setStartDate(date)}
              calendarStartDay={1}
            />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <CalendarYearView
              scrollToElement={scrollToElement}
              setFromDate={setFromDate}
              setToDate={setToDate}
              date={startDate}
              setStartDate={setStartDate}
              isMonthlyView={isMonthlyView}
              setIsMonthlyView={setIsMonthlyView}
              category={category}
              setCategory={setCategory}
              permissions={user.permissions}
              data={data}
              params={{
                category: category.toString(),
                meetingType,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Calendar;
