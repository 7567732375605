import React from 'react';
import styles from './input.module.scss';

const Input = (props) => {
  const { ...rest } = props;
  return (
    <>
      <input type="text" className={styles.input} {...rest} />
    </>
  );
};
export default Input;
