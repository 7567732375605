import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { utils } from 'xlsx-js-style';

import { ROLES_DASHBOARD } from '../../../constants';
import {
  HISTORICAL_COMMON_STYLE,
  historicalExportDeclareWorkSheet,
  historicalExportSetMergeAndColWidth,
  historicalExportStyleAllCells,
  historicalExportUnshiftHeader,
  historicalSetColorsAndWriteFile,
} from './CommonExportHistorical';

const ExportRetirementForecast = (props) => {
  const { setExportData, historicalData } = props;

  const convertDataToExport = useCallback(async () => {
    const data = [];
    const length = ROLES_DASHBOARD.length + 2;
    historicalData.forEach((item) => {
      let malaysianData = Array(length).fill('N/A');
      let othersData = Array(length).fill('N/A');
      malaysianData[0] = item.month;
      malaysianData[1] = 'Malaysian';
      othersData[0] = '';
      othersData[1] = 'Others';
      if (!isEmpty(item.value)) {
        ROLES_DASHBOARD.forEach((r, index) => {
          const existedValue = item.value.find((v) => v.type === r);
          if (existedValue) {
            malaysianData[index + 2] = existedValue.M;
            othersData[index + 2] = existedValue.O;
          }
        });
      }

      data.push(malaysianData);
      data.push(othersData);
    });
    return data;
  }, [historicalData]);
  const exportRetirementForecast = useCallback(async () => {
    const fileName = 'Nationality';
    let arr = [];
    arr = await convertDataToExport();
    const length = ROLES_DASHBOARD.length + 2;
    const headers1 = new Array(length).fill('');
    headers1[0] = 'Months (End of months)';
    headers1[1] = 'Nationality';
    const headers2 = new Array(length).fill('');
    headers2[0] = 'Months (End of months)';
    headers2[1] = 'Role';
    ROLES_DASHBOARD.forEach((r, index) => {
      headers2[index + 2] = r;
    });

    historicalExportUnshiftHeader(arr, [headers2, headers1]);

    if (arr.length > 0) {
      let [ws, wb] = historicalExportDeclareWorkSheet(arr);
      utils.book_append_sheet(wb, ws, 'Nationality');

      const shortHandMerges = [
        [0, 0],
        [1, 0],
        [0, 1],
        [0, length - 1],
      ];
      historicalExportSetMergeAndColWidth(ws, shortHandMerges, length, 10);

      historicalExportStyleAllCells(ws, (i) => {
        const regrex = new RegExp(/(\d+)/);
        const index = i.match(regrex);
        if (index && index.length > 0 && Number(index[0]) === 2) {
          ws[i].s = {
            ...ws[i].s,
            ...HISTORICAL_COMMON_STYLE,
            fill: { fgColor: { rgb: '7030a0' } },
          };
        }
      });

      const colorCellMapping = {
        '00a9b1': ['A1', 'B1'],
      };

      historicalSetColorsAndWriteFile(ws, wb, fileName, colorCellMapping);
    }
  }, [convertDataToExport]);

  useEffect(() => {
    exportRetirementForecast();
    setExportData(false);
  }, [setExportData, exportRetirementForecast]);
  return <></>;
};

ExportRetirementForecast.propTypes = {
  setExportData: PropTypes.func,
  historicalData: PropTypes.array,
};

export default ExportRetirementForecast;
