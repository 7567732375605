import axiosClient from './axiosClient';
import { PERMISSION } from '../utils/constants';

export const fetchCompanyFilterType = ({ role }) => {
  return axiosClient.get('bu-access-type', {
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};
export const fetchListCompanyByTpe = ({ type, role }) => {
  return axiosClient.get('list-bu-or-opu-by-type', {
    params: {
      type,
    },
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};
export const fetchTopTalentReviewStatus = (params, role) => {
  return axiosClient.get('/talent-review/top-talents-review-status', {
    params: {
      ...params,
    },
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role,
    },
  });
};
