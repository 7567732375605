import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { managing_ccp_data_not_found } from '../../../../../assets/img';
import { adminApi } from '../../../../../services/admin';
import { MANUAL_RUN_SP_CRITICAL_POSITION_STATUS } from '../../constants';
import MenuBarManualStatus from '../MenuBarManualStatus/MenuBarManualStatus';
import DataTable from './DataTable/DataTable';
import * as styles from './manual-run-status.module.scss';

export default function ManualRunStatus() {
  const roleActive = useSelector((state) => state.user.roleActive);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [prevQuery, setPrevQuery] = useState();
  const [sortStartDateAndEndDate, setSortStartDateAndEndDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [query, setQuery] = useState({
    limit: 15,
    status: null,
    dateRange: [null, null],
    keyword: '',
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      setPrevQuery(query);
      const queryApi = {
        page: page,
        limit: query.limit,
        keyword: query.keyword,
        status: query.status === 'All' || query.status === null ? '' : query.status,
        start_date:
          query.dateRange && query.dateRange.length > 0 && query.dateRange[0]
            ? moment(query.dateRange[0]).format('YYYY-MM-DD')
            : '',
        end_date:
          query.dateRange && query.dateRange.length > 1 && query.dateRange[1]
            ? moment(query.dateRange[1]).format('YYYY-MM-DD')
            : '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sort_by_start_date: sortStartDateAndEndDate.startDate,
        sort_by_end_date: sortStartDateAndEndDate.endDate,
      };

      const res = await adminApi.getManualRunCriticalPosition(queryApi, roleActive);
      if (res.status === 200) {
        const totalRecord = !isEmpty(res.data.result) ? res.data.result.total : 0;
        const newData = res.data.result.result ?? [];
        if (newData.length > 0) {
          newData.forEach((item) => {
            item.matching_criteria_set = `Set ${item.set_number}: ${item.name}`;
            if (
              item.status === MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.RUNNING ||
              item.status === MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.QUEUE
            ) {
              item.end_date = '--';
            }
          });
        }
        setData(newData);
        setTotalPage(totalRecord);
      }
    } catch (error) {
      console.log(error);
      setData([]);
      setTotalPage(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!firstTimeLoad) {
      if (JSON.stringify(prevQuery) !== JSON.stringify(query) && page > 1) {
        setPage(1);
      } else {
        fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query.dateRange, query.status, query.keyword, sortStartDateAndEndDate]);

  useEffect(() => {
    setFirstTimeLoad(false);
  }, []);

  return (
    <Spin spinning={loading} size="large">
      <MenuBarManualStatus
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        query={query}
        setQuery={setQuery}
        fetchData={fetchData}
      />

      {!isEmpty(data) ? (
        <>
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.235px',
              fontSize: '18px',
              color: '#3F3C4C',
              margin: '30px 0 15px',
            }}
          >
            Manual Run Status
          </div>
          <DataTable
            data={data}
            query={query}
            page={page}
            setPage={setPage}
            totalPage={totalPage}
            setSortStartDateAndEndDate={setSortStartDateAndEndDate}
          />
        </>
      ) : (
        <div className={styles.not_found}>
          <img src={managing_ccp_data_not_found} alt="not_found" />
        </div>
      )}
    </Spin>
  );
}
