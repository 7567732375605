import { Col, Row, Select } from 'antd';
import { get } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { AiButton, AiLabel, AiTextarea } from '../../../../../assets/common';
import { dropdown_icon } from '../../../../../assets/img';
import { adminApi } from '../../../../../services/admin';
import styles from './edit-top-talent-dialog.module.scss';

const EditTopTalentDialog = (props) => {
  const {
    openModal,
    setOpenModal,
    setAddTalent,
    setShowSaveTalentStatusEdited,
    data,
    setData,
    setprevStatus,
    prevState,
  } = props;
  const [option, setOption] = useState('');
  const [allOptions, setAllOptions] = useState([]);
  const [remark, setRemark] = useState(data.remarks);
  const { roleActive } = useSelector((state) => state.user);

  function handleChange(value) {
    const item = allOptions.find((d) => d.value === value);
    setOption(item.value);
  }
  function handleChangeRemark(value) {
    setRemark(value);
  }

  function handleSave() {
    if (!prevState) {
      setprevStatus(data?.top_talent_status);
    }

    setAddTalent((prevState) => {
      return {
        ...prevState,
        top_talent: {
          ...prevState?.top_talent,
          top_talent_status: option,
          top_talent_status_agenda: option,
          old_top_talent_status:
            prevState?.top_talent?.old_top_talent_status ||
            prevState?.top_talent?.top_talent_status,
          remarks: remark,
          is_updated: true,
        },
      };
    });
    setData((prevState) => {
      return {
        ...prevState,
        top_talent_status: option,
        top_talent_status_agenda: option,
        remarks: remark,
      };
    });
    setOpenModal(false);
    setShowSaveTalentStatusEdited(true);
  }

  useEffect(() => {
    setOption(data?.top_talent_status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.top_talent_status]);

  useEffect(() => {
    if (!roleActive.roleId) return;
    (async function searchTopTalentCategory() {
      const res = await adminApi.searchTopTalentCategory(
        { keyword: '', page: 1, limit: 'all' },
        roleActive.roleId
      );
      const options = get(res, 'data.result.top_talent_category') || [];
      setAllOptions(
        options.map((item) => {
          return {
            label: item.category,
            value: item.category,
          };
        })
      );
    })();
  }, [roleActive.roleId]);

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        dialogClassName={styles.remark}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
        centered
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div className={styles.title} data-testid="modal-remark-title">
            Edit Top Talent Status
          </div>
        </Modal.Header>
        <Row className="mt-3" style={{ padding: '33px' }}>
          <Col span={12} className={styles.customize}>
            <AiLabel>Top Talent Status</AiLabel>
            <Select
              data-testid="included-in-ratio-option"
              placeholder="Select Status"
              suffixIcon={<img src={dropdown_icon} alt="dropdown_icon" />}
              style={{ width: '256px', marginBottom: '14px' }}
              options={allOptions}
              value={option}
              onChange={handleChange}
              getPopupContainer={(trigger) => trigger.parentElement}
              listHeight={200}
            />
          </Col>
          <Col span={24}>
            <AiLabel>Remarks</AiLabel>
            <AiTextarea
              rows={4}
              placeholder="Enter your remark here"
              name="remark"
              onChange={(e) => handleChangeRemark(e.target.value)}
              value={remark}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end" style={{ margin: '0 33px 45px 0px' }}>
          <AiButton mode={'teal'} onClick={handleSave} data-testid="submit">
            Save
          </AiButton>
        </div>
      </Modal>
    </div>
  );
};

export default memo(EditTopTalentDialog);
