import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './list-option.module.scss';

const ListOption = ({ loading, data, debounceSearchQuery, handleShowListItems }) => {
  return (
    <>
      {/* Loading */}
      {loading && isEmpty(data) && (
        <div className={styles.search_list} data-testid="loading">
          <div data-testid="loading-pos" className={styles.dot_flashing}></div>
        </div>
      )}

      {/* Show item */}
      {!loading && !isEmpty(data) && (
        <ul className={styles.search_list} data-testid="data-is-true">
          {handleShowListItems()}
        </ul>
      )}

      {/* Not Found */}
      {!loading &&
        isEmpty(data) &&
        debounceSearchQuery.type === 'search' &&
        !isEmpty(debounceSearchQuery.value) && (
          <div className={styles.search_list} data-testid="data-is-false">
            <p className={styles.search_notFound}>Search not found ...</p>
          </div>
        )}
    </>
  );
};

ListOption.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  debounceSearchQuery: PropTypes.object,
  handleShowListItems: PropTypes.func,
};

export default ListOption;
