import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { icon_user, triangle_down } from '../../assets/img/';
import { getPermissions, getUser, setRoleActive } from '../../store/userSlice';
import { ROLE_FULLNAME } from '../../utils/constants';
import CommonLoading from '../CommonLoading/CommonLoading';
import styles from './user-role.module.scss';

function RoleList(props) {
  const { roleActive, user, onSelectedRole } = props;

  const listItems = user.roles.map((role, index) => (
    <li
      key={index}
      onKeyDown={() => {}}
      onClick={() => onSelectedRole(role.roleId)}
      className={
        role.roleId === roleActive.roleId ? `${styles.option} ${styles.active}` : styles.option
      }
    >
      <div className={styles.icon}>
        <img src={icon_user} alt="" />
      </div>
      <div className={styles.user_group}>
        <span className={styles.user_name}>@{user.employeeName}</span>
        <span className={styles.user_role}>
          {ROLE_FULLNAME.find((item) => item.roleName === role.roleName)?.roleFullName ||
            role.roleName}
        </span>
      </div>
    </li>
  ));
  return (
    <ul className={styles.select} data-testid="custom-element">
      {listItems}
    </ul>
  );
}

function RoleSelect(props) {
  const { roleActive, user, statusLeftMenu } = props;
  return statusLeftMenu ? (
    <div className={styles.selected}>
      <div className={styles.user_selected}>
        <div className={styles.icon}>
          <img src={icon_user} alt="" />
        </div>
        <div className={styles.user_group}>
          <span className={styles.user_name}>@{user.employeeName}</span>
          <span className={styles.user_role}>
            {ROLE_FULLNAME.find((item) => item.roleName === roleActive.roleName)?.roleFullName ||
              roleActive.roleName}
          </span>
        </div>
      </div>
      <div className={styles.triangle}>
        <img src={triangle_down} alt="" />
      </div>
    </div>
  ) : (
    <div className={styles.selected}>
      <div className={styles.user_selected}>
        <div className={styles.icon}>
          <img src={icon_user} alt="" />
        </div>
      </div>
    </div>
  );
}

export const UserRole = (props) => {
  const { statusLeftMenu } = props;
  const [closeOptions, setCloseOptions] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const roleActive = useSelector((state) => state.user.roleActive);
  const loading = useSelector((state) => state.user.loading);
  const userId = useSelector((state) => state.user.userId);

  const onSelectedRole = async (id) => {
    const roleSelected = user.roles.find((role) => {
      return role.roleId === id;
    });
    dispatch(setRoleActive(roleSelected));
    setCloseOptions(false);
  };
  const handleMouseLeave = () => {
    setCloseOptions(false);
  };
  const handleMouseEnter = () => {
    setCloseOptions(true);
  };

  useEffect(() => {
    dispatch(getPermissions(roleActive.roleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive.roleId]);

  useEffect(() => {
    dispatch(getUser(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div data-testid="user-role" style={{ position: 'relative', minHeight: '30px' }}>
        {loading ? (
          <div className="center-loading">
            <CommonLoading />
          </div>
        ) : user?.employeeName ? (
          statusLeftMenu ? (
            <div
              className={styles.select_wrap}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
              data-testid="left-menu-true-hover"
            >
              <RoleSelect roleActive={roleActive} user={user} statusLeftMenu={statusLeftMenu} />
              <div data-testid="role-list">
                {closeOptions && (
                  <RoleList user={user} onSelectedRole={onSelectedRole} roleActive={roleActive} />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.select_wrap} data-testid="left-menu-false-hover">
              <RoleSelect roleActive={roleActive} user={user} statusLeftMenu={statusLeftMenu} />
            </div>
          )
        ) : (
          <div>No data</div>
        )}
      </div>
    </>
  );
};

export default UserRole;
