import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CareerJourney } from '../../../../components';
import styles from './career-journey-section.module.scss';
import CareerJourneyDetail from './CareerJourneyDetail/CareerJourneyDetail';
import TalentReviewCollapse from '../TalentReviewCollapse/TalentReviewCollapse';

const customStyle = {
  mainTitle: {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#3D3D3D',
  },
  chartWrapper: {
    padding: 10,
  },
  chartWrapperPrint: {
    border: '1px solid #d9d9d9',
    borderRadius: '7px',
    marginTop: '20px',
  },
  chartLeft: {
    width: '100%',
  },
};

const CareerJourneySection = ({ addTalent, isPrinting }) => {
  const user = useSelector((state) => state.user);
  const roleUser = {
    id: addTalent?.top_talent?.staff_id,
    roleActive: user.roleActive,
    permissions: user.permissions,
  };
  const [careerJourneyData, setCareerJourneyData] = useState(null);

  return (
    <>
      {!isPrinting && (
        <TalentReviewCollapse
          print={isPrinting}
          header={
            <div className="d-flex justify-content-between align-items-center pt-3 mr-1">
              <div className={styles.mainTitle}>Career Journey </div>
            </div>
          }
        >
          <div>
            <div className={!isPrinting ? styles.container : styles.container_print}>
              <div className="mb-3 w-100">
                <CareerJourney
                  id={addTalent?.top_talent?.staff_id}
                  roleUser={roleUser}
                  setCareerJourneyData={setCareerJourneyData}
                  noDetail={true}
                  customStyle={customStyle}
                  isPrinting={!isPrinting}
                />
              </div>

              <div className={styles.scrollContent} style={!isPrinting ? {} : { padding: '28px' }}>
                <CareerJourneyDetail data={careerJourneyData?.careerJourneyDetail} />
              </div>
            </div>
          </div>
        </TalentReviewCollapse>
      )}
      {isPrinting && (
        <div>
          {isPrinting && <h3 style={customStyle?.mainTitle || {}}>Career Journey </h3>}
          <div className={!isPrinting ? styles.container : styles.container_print}>
            <div
              className="mb-3"
              style={!isPrinting ? { width: '100%' } : { width: '100%', padding: '28px' }}
            >
              <CareerJourney
                id={addTalent?.top_talent?.staff_id}
                roleUser={roleUser}
                setCareerJourneyData={setCareerJourneyData}
                noDetail={true}
                customStyle={customStyle}
                isPrinting={isPrinting}
              />
            </div>

            <div className={styles.scrollContent} style={!isPrinting ? {} : { padding: '28px' }}>
              <CareerJourneyDetail data={careerJourneyData?.careerJourneyDetail} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CareerJourneySection;
