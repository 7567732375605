import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { not_found_readiness_rules } from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { checkDeleteResetPage } from '../../../../utils/helper';
import { READINESS_DELETE_SUCCESS, READINESS_TABLE_ROW_LIMIT } from '../constants';
import MenuBarSpReadiness from './MenuBarSpReadiness/MenuBarSpReadiness';
import * as styles from './sp-readiness-rules.module.scss';
import SpReadinessRulesTable from './SpReadinessRulesTable/SpReadinessRulesTable';

export default function SpReadinessRules(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [firstTimeRender, setFirstTimeRender] = useState(false);

  const [data, setData] = useState({ sp_readiness_rules: [], total: 0 });
  const [searchParams, setSearchParams] = useState({ keyword: '', page: 1 });
  const [deleteParams, setDeleteParams] = useState({
    selectedRecords: [],
    deleteRecord: null,
  });
  const roleActive = useSelector((state) => state.user.roleActive);

  const handleDeleteSuccess = (arrDeleted) => {
    const { sp_readiness_rules, total } = data;
    const { page } = searchParams;
    const groupRow = 3;

    checkDeleteResetPage(arrDeleted, sp_readiness_rules, total, page, groupRow)
      ? setSearchParams({ ...searchParams, page: 1 })
      : fetchData();

    setDeleteParams({
      selectedRecords: [],
      deleteRecord: null,
    });
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: READINESS_DELETE_SUCCESS,
      })
    );
  };

  const handleDeleteRecord = async () => {
    const { selectedRecords, deleteRecord } = deleteParams;
    if (isEmpty(selectedRecords) && !deleteRecord) return;

    setDeleting(true);
    const roles = deleteRecord
      ? [deleteRecord?.group_id]
      : selectedRecords.map((item) => item.group_id);

    try {
      const res = await adminApi.deleteReadinessRules(roles, roleActive.roleId);
      if (res.status === 200) {
        handleDeleteSuccess(roles);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await adminApi.searchReadinessRules(
        searchParams.keyword,
        searchParams.page,
        READINESS_TABLE_ROW_LIMIT,
        roleActive.roleId
      );
      if (res.status === 200) {
        setData(res.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [roleActive.roleId, searchParams.keyword, searchParams.page]);

  useEffect(() => {
    if (!firstTimeRender) return;
    fetchData();
  }, [fetchData, firstTimeRender]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarSpReadiness
        setSearchParams={setSearchParams}
        deleteParams={deleteParams}
        setShowDeleteConfirm={setShowDeleteConfirm}
        setFirstTimeRender={setFirstTimeRender}
      />

      {!isEmpty(data.sp_readiness_rules) ? (
        <>
          <SpReadinessRulesTable
            data={data}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setDeleteParams={setDeleteParams}
            setShowDeleteConfirm={setShowDeleteConfirm}
          />
          <ModelTC
            info={{
              type: 'deleteReadinessRules',
              visible: showDeleteConfirm,
              setVisible: setShowDeleteConfirm,
              handleSubmit: handleDeleteRecord,
              loading: deleting,
              onClose: () => {
                setShowDeleteConfirm(false);
              },
            }}
          />
        </>
      ) : (
        <div className={styles.not_found}>
          <img src={not_found_readiness_rules} alt="not_found" />
        </div>
      )}
    </Spin>
  );
}
