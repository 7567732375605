import { isEmpty, size } from 'lodash';
import React, { memo, useEffect, useState } from 'react';

import { Checkbox } from '..';
import { filter_icon } from '../../assets/img';
import { useComponentVisible } from '../../hooks';
import styles from './filter-multiple.module.scss';

const FilterMultiple = ({ setListSelectType, filtersOption }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [select, setSelect] = useState([]);

  const handleSearch = () => {
    let listSelect = select.filter((ele) => ele?.isChecked === true);
    setListSelectType(listSelect);
    setIsComponentVisible(false);
  };

  const handleSetDefault = () => {
    if (filtersOption.length === 0) return;
    let listSelection = filtersOption.map((ele) => {
      return ele;
    });
    setSelect(listSelection);
  };
  useEffect(() => {
    if (size(filtersOption) === 0) {
      setSelect([]);
    } else {
      handleSetDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersOption]);
  const selectAll = {
    label: 'All',
    value: 'all',
  };
  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={styles.dropdown__icon}
        onKeyDown={() => {}}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <img src={filter_icon} alt="" />
      </div>
      {isComponentVisible && (
        <div className={styles.dropdown__list}>
          {size(select) > 1 && (
            <div className={styles.dropdown__item}>
              <Checkbox item={selectAll} select={select} setSelect={setSelect} />
            </div>
          )}
          {!isEmpty(select) &&
            select.map((item, index) => {
              return (
                <div className={styles.dropdown__item} key={index}>
                  <Checkbox item={item} select={select} setSelect={setSelect} />
                </div>
              );
            })}
          <div className={styles.dropdown__apply}>
            <button
              className={styles.dropdown__btn}
              onKeyDown={() => {}}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(FilterMultiple);
