import { Tooltip } from 'antd';
import { chunk } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  avatar,
  chevron_down,
  in_active_icon,
  red_dot,
  suspended_icon,
  warning_dot,
} from '../../../../assets/img/';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
  infoPosition,
} from '../../../../utils/helper';
import styles from './box-dsp.module.scss';
import { ApprovedLabel } from '../../../Meeting/AgendaDetails/components/Recommendations/Box/ApprovedLabel';

const CardBox = (props) => {
  const { item, index, incumbents } = props;
  const { agendaId } = useParams();

  return (
    <div className={styles.card_box} data-testid="view-card-box">
      <div
        className="row no-gutters justify-content-center align-items-stretch"
        style={{ height: '100%' }}
      >
        <div className={`col-4 ${styles.left}`}>
          <div className={styles.number}>{index + 1}</div>

          <div className="row flex-column no-gutters align-items-center mt-3">
            <div className={styles.image}>
              <img src={decodeBase64ToImage(item?.image) || avatar} alt="" />
              {['INACTIVE', 'SUSPENDED'].includes(item?.assignment_status_type) && (
                <Tooltip
                  placement="top"
                  title={
                    <div
                      style={{
                        padding: '5px',
                      }}
                    >
                      <div
                        style={{
                          color: '#F3F3F3',
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 600,
                          marginBottom: '10px',
                        }}
                      >
                        {item?.assignment_status_type === 'INACTIVE' ? 'In-active' : 'Suspended'}
                      </div>
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{
                          gap: '10px',
                        }}
                      >
                        <img
                          src={item?.assignment_status_type === 'INACTIVE' ? red_dot : warning_dot}
                          alt="icon"
                        />
                        <div
                          style={{
                            color: '#F3F3F3',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                          }}
                        >
                          {item?.action_name} {item?.action_reason && `(${item?.action_reason})`}
                        </div>
                      </div>
                    </div>
                  }
                  color={'black'}
                  key={'black'}
                  overlayInnerStyle={{ borderRadius: '6px' }}
                  data-testid="show-tooltip"
                >
                  <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <img
                      src={
                        item?.assignment_status_type === 'INACTIVE'
                          ? in_active_icon
                          : suspended_icon
                      }
                      style={{ height: '15px', width: '15px' }}
                      alt="icon"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
            <div style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>
              {item?.identity_number || item?.staff_id}
            </div>

            <div className={styles.position}>
              <Tooltip
                placement="bottom"
                title={handleShowTextTooltip(item, incumbents)}
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
                data-testid="show-tooltip"
              >
                {handleShowTooltip(item, incumbents)}
              </Tooltip>
            </div>
            <div style={{ marginTop: '10px' }}>
              <ApprovedLabel positions={incumbents} item={item} />
            </div>
          </div>
        </div>

        <div className={`col-8 ${styles.right}`}>
          <div className="row no-gutters justify-content-between align-items-stretch">
            <div className={styles.name}>
              <Link
                to={`/profile/${item?.staff_id}`}
                target="_blank"
                className={styles.recommendation__name}
                draggable="false"
              >
                {item?.birth_name} &#160;
                {!hideOtherTalentLabel(
                  item.assignment_status_type,
                  item.role_level,
                  agendaId ? item?.ct : item?.top_talent_status
                ) &&
                  !['119463', '1032501'].includes(item?.staff_id) &&
                  handleShowCT(agendaId ? item?.ct : item?.top_talent_status)}
                {item?.percentage ? `${item?.percentage}%` : ''}
              </Link>
            </div>
          </div>

          <div className="mb-2">
            <Tooltip
              placement="top"
              title={infoPosition(
                item.position_name,
                item.department_name,
                item.division,
                item.opu,
                item.business_unit
              )}
              color={'black'}
              key={'black'}
              overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
            >
              <span>Position: </span>
              <span data-testid="value-position-business-unit">
                {item?.position_name}, {item?.business_unit}
              </span>
            </Tooltip>
          </div>

          <div className="mb-2">
            <span>YIP: </span>
            <span data-testid="time-position-value">
              {handleShowYIPAndYISG(
                item?.years_in_position ?? item?.year_in_position,
                item?.date_in_position
              )}
            </span>
            &#160;&#160;&#160;<span>YISG: </span>
            <span data-testid="job-grade-value">
              {item?.sg} (
              {handleShowYIPAndYISG(item?.years_in_sg ?? item?.year_in_sg, item?.date_in_sg)})
            </span>
          </div>

          <div className="mb-2">
            <span>Age: </span>
            <span data-testid="age-value">{item?.age}</span>
            &#160;&#160;&#160;<span>Retirement: </span>
            <span data-testid="retirement-date-value">
              {handleShowRetirementDate(item.retirement_date)}
            </span>{' '}
            <span data-testid="retirement-age-value">
              {item?.retirement_date && (item?.dateOfBirth || item?.birth_date)
                ? '(' +
                  `${
                    Number(moment(item?.retirement_date).format('YYYY')) -
                    Number(
                      moment(
                        item?.dateOfBirth || item?.birth_date,
                        agendaId ? 'DD-MM-YYYY' : ''
                      ).format('YYYY')
                    )
                  }` +
                  ')'
                : ''}
            </span>
          </div>

          <div className="mb-2">
            <span>Performance Rating: </span>
            <span data-testid="rating-value">{item?.performance_rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

CardBox.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  incumbents: PropTypes.array,
};

const BoxDSP = (props) => {
  const { color, text, line, incumbents, numRows1, numRows2 } = props;
  const containerRef = useRef(null);
  const [collapseMenu, setCollapseMenu] = useState(true);
  const [maxHeight, setMaxHeight] = useState(null);
  const chunkLine = line.length ? chunk(line, 3) : [];

  const isPageBreak = () => {
    const page1Rows = 4;
    const pageRows = 6;
    const numRows1And2 = numRows1 + numRows2;
    switch (text) {
      case '1st Line':
        break;
      case '2nd Line':
        if ((numRows1 - 4) % pageRows === 0 && line?.length) return true;
        break;
      default:
        if ((numRows1 === 0 && numRows2 === page1Rows) || (numRows1And2 - 4) % pageRows === 0) {
          return true;
        }
        break;
    }
    return false;
  };

  useEffect(() => {
    if (containerRef.current && line.length) {
      setMaxHeight(containerRef.current.offsetHeight);
    }
  }, [line]);

  return (
    <>
      <div
        style={
          isPageBreak()
            ? {
                breakBefore: 'page',
                breakAfter: 'page',
                breakInside: 'auto',
              }
            : null
        }
      />
      <div className={`${styles.container}`}>
        <table className={`${styles.collapse} ${styles.printTable}`} data-testid="box-line">
          <thead>
            <tr>
              <th colSpan={3}>
                <div
                  onKeyDown={() => {}}
                  onClick={() => setCollapseMenu(!collapseMenu)}
                  className={styles.collapseHeader}
                  style={{ backgroundColor: color }}
                  data-testid="click-collapse"
                >
                  <div data-testid="name-box-line" className={styles.left}>
                    {text}
                  </div>
                  <img src={chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            data-testid="view-collapse-body"
            className={styles.collapseBody}
            style={{ maxHeight: !collapseMenu ? '15px' : maxHeight }}
            ref={containerRef}
          >
            {chunkLine &&
              chunkLine?.length > 0 &&
              chunkLine?.map((items, idx) => {
                return (
                  <tr key={idx}>
                    {items.map((item, index) => (
                      <td key={index}>
                        <CardBox item={item} index={index + 3 * idx} incumbents={incumbents} />
                      </td>
                    ))}
                    {items.length === 1 && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                    {items.length === 2 && <td></td>}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

BoxDSP.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  line: PropTypes.array,
  incumbents: PropTypes.array,
  numRows1: PropTypes.number,
  numRows2: PropTypes.number,
};

export default BoxDSP;
