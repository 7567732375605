import { Checkbox, Input, Tooltip } from 'antd';
import { flatten, isEmpty, isNaN, union } from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { avatar, chevron_down, sp_chevron_down } from '../../../../../../../assets/img';
import { talentReviewApi } from '../../../../../../../services/talentReviewApi';
import {
  calculateTime,
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  infoPosition,
} from '../../../../../../../utils/helper';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { BasicButton } from '../../../../../../../assets/common';
import RangePickerCustom from '../../../../../../../components/RangePickerCustom/RangePickerCustom';
import { meetingApi } from '../../../../../../../services/tcMeeting';
import AddRemark from '../../AddRemark/AddRemark';
import { Draggable } from '../../wrappers/Draggable';
import styles from './from-talent-review.module.scss';
import PropTypes from 'prop-types';
import { DATE_TIME_FORMAT } from '../../../../../../../utils/constants';

const CustomInput = styled.div`
  .ant-input::placeholder {
    color: #787587;
  }
  .ant-input {
    color: #787587;
    padding-left: 25px;
  }
`;
const handleShowRetirement = (item) => {
  let total =
    Number(moment(item?.retirement_date).format('YYYY')) -
    Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
  if (!isNaN(total)) {
    return '(' + total + ')';
  }
};
const Item = (props) => {
  const {
    item,
    index,
    text,
    positionsCode,
    setTable,
    handleSelected,
    selected,
    showCheckBox,
    table,
  } = props;
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const positions = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent.positions
  );

  const [showAddRemark, setShowAddRemark] = useState({
    status: false,
    staff: '',
  });
  function showHide() {
    setCollapseMenu(!collapseMenu);
  }

  useEffect(() => {
    async function getDetailRecommedation() {
      try {
        setLoading(true);
        const res = await talentReviewApi.getRecommendationDetailFromTalentReview(
          item.staff_id,
          positionsCode.join(',')
        );

        setTable((prev) => ({
          ...prev,
          [table]: prev[table].map((item) => {
            if (item.staff_id === res.data.result.staff_id) {
              return { ...item, ...res.data.result, is_talent_review: 1, isHasData: true };
            } else {
              return { ...item, is_talent_review: 1 };
            }
          }),
        }));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    if (collapseMenu === true && !item.isHasData) {
      getDetailRecommedation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseMenu]);
  return (
    <>
      {showAddRemark.status && (
        <AddRemark setIsVisible={setShowAddRemark} showAddRemark={showAddRemark} />
      )}
      <table>
        <tbody>
          <tr
            onKeyDown={() => {}}
            onClick={() => showHide()}
            className={styles.switch}
            id={item.staff_id}
            style={{ border: '1px solid #E7E7F0', backgroundColor: '#fff' }}
          >
            {showCheckBox && (
              <td
                style={{ width: '7%', textAlign: 'right' }}
                onKeyDown={() => {}}
                onClick={(event) => event.stopPropagation()}
              >
                <Checkbox
                  onChange={() => handleSelected(item.staff_id)}
                  checked={selected.includes(item.staff_id)}
                />
              </td>
            )}
            <td style={{ width: '5%', padding: '5px' }}>{index + 1}</td>
            <td
              className={styles.name}
              style={{
                width: '35%',
                whitespace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Link
                to={`/profile/${item.staff_id}`}
                target="_blank"
                className={styles.recommendation__name}
                draggable="false"
              >
                {item.birth_name}&#160; {handleShowCT(item.top_talent_status)}
              </Link>
            </td>
            <td style={{ width: '20%' }}>
              {item.talent_review_date &&
                moment(item.talent_review_date).format(DATE_TIME_FORMAT.DATE_SPACE)}
            </td>
            <td
              style={{
                width: '15%',
                whitespace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.talent_review_title}
            </td>
            {item.type === 'Change of Readiness' && (
              <td style={{ width: '15%' }}>
                {item.old_line} to {item.new_line}
              </td>
            )}
            <td style={{ width: '10%' }}>
              <img src={sp_chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
            </td>
          </tr>

          <Collapse in={collapseMenu}>
            <tr className={styles.tableBodyCard} style={!collapseMenu ? { height: 0 } : {}}>
              <td colSpan={6} style={!collapseMenu ? { padding: 0 } : {}} id={item.staff_id}>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.card_box}>
                    <Draggable
                      id={`${text}-drap-${index}`}
                      className={`collapseBody__lineRecommendation ${styles.left}`}
                      data={{ ...item, index }}
                    >
                      <div className="d-flex flex-column no-gutters align-items-center mt-3">
                        <div className={styles.image}>
                          <img src={decodeBase64ToImage(item.image) || avatar} alt="" />
                        </div>
                        <div style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>
                          {item.staff_id}
                        </div>
                        <div className={styles.position}>
                          <Tooltip
                            placement="bottom"
                            title={handleShowTextTooltip(item, positions)}
                            color={'black'}
                            key={'black'}
                            overlayInnerStyle={{ borderRadius: '6px' }}
                          >
                            {handleShowTooltip(item, positions)}
                          </Tooltip>
                        </div>
                      </div>
                    </Draggable>

                    <div className={`${styles.right}`}>
                      <Draggable
                        id={`${text}-drap-${index}`}
                        className="collapseBody__line"
                        data={{ ...item, index }}
                      >
                        <div className="mb-2" style={{ fontWeight: ' 600', color: '#50a4b9' }}>
                          <span>
                            {item.birth_name} &#160; {handleShowCT(item.ct)}
                          </span>
                          <span>
                            {item.matching_percentage}
                            {item.matching_percentage && '%'}
                          </span>
                        </div>
                        <div className="mb-2">
                          <Tooltip
                            placement="top"
                            title={infoPosition(
                              item.position_name,
                              item.department_name,
                              item.division,
                              item.opu,
                              item.business_unit
                            )}
                            color={'black'}
                            key={'black'}
                            overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
                          >
                            <span>Position: </span>
                            {item.position_name}, {item.business_unit}
                          </Tooltip>
                        </div>
                        <div className="mb-2">
                          <span>YIP: </span>
                          {calculateTime(item.date_in_position)} &#160;&#160;
                          <span>YISG: </span>
                          {item.salary_grade} ({calculateTime(item.date_in_sg)})
                        </div>
                        <div className="mb-2">
                          <span>Age: </span>
                          {item.age} &#160;&#160;
                          <span>Retirement: </span>
                          {handleShowRetirementDate(item.retirement_date)}&#160;
                          {handleShowRetirement(item)}
                        </div>
                        <div className="mb-2">
                          <span>Performance Rating: </span>
                          {item.performance_rating}
                        </div>
                      </Draggable>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </Collapse>
        </tbody>
      </table>
    </>
  );
};

const Table = (props) => {
  const { showTable, text, title, selected, setSelected, showCheckBoxA, showCheckBoxB } = props;
  const [collapseMenu, setCollapseMenu] = useState(false);
  const arr = showTable?.filter((item) => item.type === title);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = arr.map((row) => row.staff_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelected = (_id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  return (
    <div className={styles.collapse}>
      <div
        onKeyDown={() => {}}
        onClick={() => setCollapseMenu(!collapseMenu)}
        className={styles.collapseHeader}
        style={{ backgroundColor: '#50A4B9' }}
        data-testid="show-hide"
      >
        <div className="d-flex">
          {(showCheckBoxA || showCheckBoxB) && (
            <div style={{ width: '5%', marginRight: '20px' }}>
              <Checkbox
                onChange={handleSelectAllClick}
                checked={!!(selected?.length === arr.length && arr.length !== 0)}
                onKeyDown={() => {}}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}
          <div className={styles.left}>{title}</div>
        </div>

        <img src={chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
      </div>
      <Collapse in={collapseMenu}>
        <div className={styles.collapseBody}>
          {arr && arr.length > 0 ? (
            arr.map((item, index) => (
              <Item
                key={index}
                item={item}
                index={index}
                {...props}
                text={text}
                handleSelected={handleSelected}
                selected={selected}
                showCheckBox={showCheckBoxA || showCheckBoxB}
              />
            ))
          ) : (
            <div
              className="w-100 p-2"
              style={{
                textAlign: 'center',
                gridColumn: '1/3',
                color: '#000',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '20px',
              }}
            >
              No data
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

const FromTalentReview = (props) => {
  const {
    showTable,
    setTable,
    positionsCode,
    text,
    fromTalentParam,
    setFromTalentParam,
    showFilterRecommendation,
    getTalentReviewTableA,
    getTalentReviewTableB,
    roleActive,
    addFromAToB,
    temporaryTableB,
    setTemporaryTableB,
    setStatusTableB,
    statusTableB,
    setCollapse,
  } = props;
  const [loading] = useState(false);

  const { agendaId } = useParams();

  const [selected, setSelected] = useState({
    selected1: [],
    selected2: [],
    selected3: [],
    selected4: [],
  });

  const checkFilterMatching = (talent) => {
    if (fromTalentParam.birth_name && !talent.birth_name.includes(fromTalentParam.birth_name)) {
      return false;
    }
    return !(
      fromTalentParam.from_date &&
      fromTalentParam.to_date &&
      !moment(moment(talent.talent_review_date).format('DD-MMM-YYYY')).isBetween(
        moment(fromTalentParam?.from_date, 'DD-MM-YYYY'),
        moment(fromTalentParam?.to_date, 'DD-MM-YYYY')
      )
    );
  };
  async function saveTableB() {
    try {
      //save table B
      await meetingApi.saveTableBFromTalentReview(roleActive.roleId, {
        agenda_id: Number(agendaId),
        position_codes: positionsCode,
        staff_ids: union([
          ...union(flatten(Object.values(selected))),
          ...showTable.filter((i) => i.isTableB).map((i) => i.staff_id),
        ]),
      });
      //get table B and A
      getTalentReviewTableB();
      setTable((prev) => ({
        ...prev,
        tableA: [
          ...prev.tableA,
          ...temporaryTableB.filter(
            (item) => !union(flatten(Object.values(selected))).includes(item.staff_id)
          ),
        ],
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setSelected({
        selected1: [],
        selected2: [],
        selected3: [],
        selected4: [],
      });
      setStatusTableB((prev) => ({ ...prev, isAddedFilter: false }));
      setCollapse(['filtered-result']);
    }
  }
  async function removeTableB() {
    const remainingTableB = showTable
      .filter((i) => !union(flatten(Object.values(selected))).includes(i.staff_id))
      .filter((item) => !temporaryTableB.map((i) => i.staff_id).includes(item.staff_id));
    setTemporaryTableB((prev) =>
      prev.filter((i) => !union(flatten(Object.values(selected))).includes(i.staff_id))
    );
    try {
      //save table B
      await meetingApi.saveTableBFromTalentReview(roleActive.roleId, {
        agenda_id: Number(agendaId),
        position_codes: positionsCode,
        staff_ids: remainingTableB.map((item) => item.staff_id),
      });
      //get table B
      getTalentReviewTableB(true);

      const revertToRecommendations = union(flatten(Object.values(selected)))
        .map((i) => showTable.find((val) => val.staff_id === i))
        .map(({ isTableB, ...val }) => val)
        .filter((item) => checkFilterMatching(item));
      setTable((prev) => ({
        ...prev,
        tableA: union([...prev.tableA, ...revertToRecommendations]),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setSelected({
        selected1: [],
        selected2: [],
        selected3: [],
        selected4: [],
      });
    }
  }

  return (
    <div
      style={{
        fontWeight: 500,
        fontSize: '14px',
        marginBottom: '15px',
        textAlign: 'center',
      }}
    >
      <div className={styles.table}>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {showFilterRecommendation && text !== 'filtered' && (
              <>
                <div className="w-100">
                  <CustomInput>
                    <Input
                      value={fromTalentParam.birth_name}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFromTalentParam((prev) => ({ ...prev, birth_name: value }));
                      }}
                      placeholder="Talent Name"
                      style={{ height: '46px' }}
                    />
                  </CustomInput>
                </div>
                <div className="w-100">
                  <RangePickerCustom query={fromTalentParam} setQuery={setFromTalentParam} />
                </div>

                <div className="d-flex mt-3">
                  <BasicButton
                    mode="teal"
                    style={{ width: '145px', height: '32px' }}
                    className="mr-2"
                    onKeyDown={() => {}}
                    onClick={() => getTalentReviewTableA(fromTalentParam)}
                  >
                    Search
                  </BasicButton>
                  <BasicButton
                    mode="teal"
                    style={{ width: '145px', height: '32px' }}
                    className="mr-2"
                    disabled={isEmpty(union(flatten(Object.values(selected))))}
                    onKeyDown={() => {}}
                    onClick={() => {
                      addFromAToB(union(flatten(Object.values(selected))));
                      setSelected({
                        selected1: [],
                        selected2: [],
                        selected3: [],
                        selected4: [],
                      });
                      setStatusTableB((prev) => ({ ...prev, isAddedFilter: true }));
                    }}
                  >
                    Add to Filtered
                  </BasicButton>
                  <BasicButton
                    mode="border-teal"
                    style={{ width: '145px', height: '32px' }}
                    onKeyDown={() => {}}
                    onClick={() => {
                      setFromTalentParam({ birth_name: '', from_date: '', to_date: '' });
                      getTalentReviewTableA({
                        birth_name: '',
                        from_date: '',
                        to_date: '',
                      });
                    }}
                  >
                    Reset
                  </BasicButton>
                </div>
              </>
            )}
            {statusTableB?.edit && text === 'filtered' && (
              <div className="d-flex justify-content-end mb-2">
                <BasicButton
                  mode="border-teal"
                  onKeyDown={() => {}}
                  onClick={() => {
                    setSelected({
                      selected1: [],
                      selected2: [],
                      selected3: [],
                      selected4: [],
                    });
                    setStatusTableB((prev) => ({ ...prev, edit: false }));
                  }}
                  className="mr-2"
                >
                  Cancel
                </BasicButton>
                <BasicButton
                  mode="teal"
                  disabled={!!isEmpty(union(flatten(Object.values(selected))))}
                  onKeyDown={() => {}}
                  onClick={() => {
                    removeTableB();
                    setStatusTableB((prev) => ({ ...prev, edit: false }));
                  }}
                >
                  Remove
                </BasicButton>
              </div>
            )}
            {statusTableB?.isAddedFilter &&
              !statusTableB?.edit &&
              text === 'filtered' &&
              !isEmpty(showTable) && (
                <div className="d-flex justify-content-end mb-2">
                  <BasicButton
                    mode="teal"
                    disabled={!!isEmpty(union(flatten(Object.values(selected))))}
                    onKeyDown={() => {}}
                    onClick={saveTableB}
                  >
                    Finalize
                  </BasicButton>
                </div>
              )}
            {(text === 'recommendation' || (text === 'filtered' && !isEmpty(showTable))) && (
              <>
                <Table
                  showTable={showTable}
                  setTable={setTable}
                  {...props}
                  text={text}
                  title="New Proposal"
                  selected={selected.selected1}
                  setSelected={(e) => setSelected((prev) => ({ ...prev, selected1: e }))}
                />
                <Table
                  showTable={showTable}
                  setTable={setTable}
                  {...props}
                  text={text}
                  title="Change of Readiness"
                  selected={selected.selected2}
                  setSelected={(e) => setSelected((prev) => ({ ...prev, selected2: e }))}
                />
                <Table
                  showTable={showTable}
                  setTable={setTable}
                  {...props}
                  text={text}
                  title="Proposed to Drop"
                  selected={selected.selected3}
                  setSelected={(e) => setSelected((prev) => ({ ...prev, selected3: e }))}
                />
                <Table
                  showTable={showTable}
                  setTable={setTable}
                  {...props}
                  text={text}
                  title="Talent Aspiration"
                  selected={selected.selected4}
                  setSelected={(e) => setSelected((prev) => ({ ...prev, selected4: e }))}
                />
              </>
            )}
            {text === 'filtered' && isEmpty(showTable) && (
              <div
                className="w-100 p-2"
                style={{
                  textAlign: 'center',
                  color: '#000',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
              >
                No filtered result has been added.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
Item.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  text: PropTypes.string,
  positionsCode: PropTypes.array,
  setTable: PropTypes.func,
  handleSelected: PropTypes.func,
  selected: PropTypes.array,
  showCheckBox: PropTypes.bool,
  table: PropTypes.string,
};
Table.propTypes = {
  showTable: PropTypes.array,
  text: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  showCheckBoxA: PropTypes.bool,
  showCheckBoxB: PropTypes.bool,
};

FromTalentReview.propTypes = {
  showTable: PropTypes.array,
  setTable: PropTypes.func,
  positionsCode: PropTypes.array,
  text: PropTypes.string,
  fromTalentParam: PropTypes.object,
  setFromTalentParam: PropTypes.func,
  showFilterRecommendation: PropTypes.bool,
  getTalentReviewTableA: PropTypes.func,
  getTalentReviewTableB: PropTypes.func,
  roleActive: PropTypes.object,
  addFromAToB: PropTypes.func,
  temporaryTableB: PropTypes.array,
  setTemporaryTableB: PropTypes.func,
  setStatusTableB: PropTypes.func,
  statusTableB: PropTypes.object,
  setCollapse: PropTypes.func,
};
export default memo(FromTalentReview);
