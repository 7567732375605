import { Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { dashboard_breadcrumb } from '../../../assets/img';
import { BreadCrumb } from '../../../components';
import AlertNotification from '../../../components/AlertMessage/AlertNotification';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import BusinessList from './BusinessList/BusinessList';
import AddFunctionList from './FunctionList/AddFunctionList/AddFunctionList';
import FunctionList from './FunctionList/FunctionList';
import AddManagingPositionCategory from './ManagingPositionCategory/AddManagingPositionCategory/AddManagingPositionCategory';
import ManagingPositionCategory from './ManagingPositionCategory/ManagingPositionCategory';
import AddNewMappingBuOpuDevision from './MappingBuOpuDevision/AddNewMappingBuOpuDevision/AddNewMappingBuOpuDevision';
import MappingBuOpuDevisionList from './MappingBuOpuDevision/MappingBuOpuDevisionList';
import RoleLevelList from './RoleLevelList/RoleLevelList';
import CreateNewSGJGMapping from './SGJGMappingToRole/CreateNewSGJGMapping/CreateNewSGJGMapping';
import SGJGMappingToRole from './SGJGMappingToRole/SGJGMappingToRole';
import AddTopTalentCategory from './TopTalentCategory/AddTopTalentCategory/AddTopTalentCategory';
import TopTalentCategory from './TopTalentCategory/TopTalentCategory';
import AlertMessagePlace from './components/AlertMessagePlace/AlertMessagePlace';

const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Admin' },
  { name: 'Master Data Management' },
];

const tabs = [
  {
    key: '0',
    label: 'Function List',
    path: 'function-list',
    title: 'Master Data Management',
    subTitle: 'List of Function',
    tabAble: true,
  },
  {
    key: '1',
    label: 'Business Unit List',
    path: 'business-unit-list',
    title: 'Master Data Management',
    subTitle: 'List of Business Unit',
    tabAble: true,
  },
  {
    key: '2',
    label: 'Role Level List',
    path: 'role-level-list',
    title: 'Master Data Management',
    subTitle: 'List of Role Level',
    tabAble: true,
  },
  {
    key: '3',
    label: 'Mapping for Business/OPU/Division',
    path: 'mapping-for-business',
    title: 'Master Data Management',
    subTitle: 'List of Mapping for Business/OPU/Division',
    tabAble: true,
  },
  {
    key: '4',
    label: 'SG/JG Mapping (To Role)',
    path: 'sg-jg-mapping',
    title: 'Master Data Management',
    subTitle: 'List of SG/JG Mapping (To Role)',
    tabAble: true,
  },
  {
    key: '5',
    label: 'Top Talent Category',
    path: 'top-talent-category',
    title: 'Master Data Management',
    subTitle: 'List of Top Talent Status Category',
    tabAble: true,
  },
  {
    key: '6',
    label: 'Managing Position Category',
    path: 'managing-position-category',
    title: 'Master Data Management',
    subTitle: 'List of Managing Position Category',
    tabAble: true,
  },
];

const PATH = '/admin/master-data-management';

export default function MasterDataManagement() {
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState();
  const [message, setMessage] = useState({
    isOpen: false,
    isError: false,
    content: '',
  });
  const alertRef = useRef(null);

  const handleShowSearchNotFound = () => {
    alertRef.current.handleShowSearchNotFound();
  };

  const handleAddBusinessSuccess = () => {
    alertRef.current.handleAddBusinessSuccess();
  };
  const handleAddBusinessExist = () => {
    alertRef.current.handleAddBusinessExist();
  };
  const handleEditBusinessSuccess = () => {
    alertRef.current.handleEditBusinessSuccess();
  };
  const handleDeleteBusinessSuccess = () => {
    alertRef.current.handleDeleteBusinessSuccess();
  };
  const handleDeleteBusinessMultipleSuccess = (deletes) => {
    alertRef.current.handleDeleteBusinessMultipleSuccess(deletes);
  };
  const handleDeleteBusinessWarning = () => {
    alertRef.current.handleDeleteBusinessWarning();
  };
  const onChangeTab = (key) => {
    const tab = tabs.find((tab) => Number(tab.key) === Number(key));
    history.push(tab.path);
  };

  useEffect(() => {
    if (location.pathname) {
      const tab = tabs.find((tab) => {
        const paths = location.pathname.split('/');
        const lastIndex = paths.length - 1;
        return paths[lastIndex] === tab.path;
      });
      setCurrentTab(tab);
      return;
    }
  }, [location.pathname]);

  return (
    <div className="max-width">
      <div>
        <BreadCrumb level={3} breadCrumbList={breadCrumbList} />
      </div>
      <GlobalAlertMessage />
      {message.isOpen && (
        <AlertNotification
          type={message.isError ? 'error' : 'success'}
          message={message.content}
          show={message?.isOpen}
          setShow={() => setMessage(false)}
          timeShow={3000}
        />
      )}
      <AlertMessagePlace ref={alertRef} />
      <div className="title-global">
        <h3>{currentTab?.title}</h3>
        <p>{currentTab?.subTitle}</p>
      </div>
      {currentTab?.tabAble && (
        <Tabs
          defaultActiveKey="0"
          activeKey={currentTab?.key}
          onChange={onChangeTab}
          items={tabs}
        />
      )}
      <Switch>
        <Route path={PATH} exact>
          <Redirect to={`${PATH}/${tabs[0].path}`} />
        </Route>
        <Route path={`${PATH}/${tabs[0].path}`}>
          <FunctionList setMessage={setMessage} />
        </Route>
        <Route path={`${PATH}/add-new-function`}>
          <AddFunctionList />
        </Route>
        <Route path={`${PATH}/${tabs[1].path}`}>
          <BusinessList
            handleShowSearchNotFound={handleShowSearchNotFound}
            handleAddBusinessSuccess={handleAddBusinessSuccess}
            handleAddBusinessExist={handleAddBusinessExist}
            handleEditBusinessSuccess={handleEditBusinessSuccess}
            handleDeleteBusinessSuccess={handleDeleteBusinessSuccess}
            handleDeleteBusinessMultipleSuccess={handleDeleteBusinessMultipleSuccess}
            handleDeleteBusinessWarning={handleDeleteBusinessWarning}
          />
        </Route>
        <Route path={`${PATH}/${tabs[2].path}`}>
          <RoleLevelList />
        </Route>
        <Route path={`${PATH}/${tabs[3].path}`}>
          <MappingBuOpuDevisionList setMessage={setMessage} />
        </Route>
        <Route path={`${PATH}/add-new-mapping-for-business`}>
          <AddNewMappingBuOpuDevision />
        </Route>
        <Route path={`${PATH}/${tabs[4].path}`}>
          <SGJGMappingToRole />
        </Route>
        <Route path={`${PATH}/add-new-sg-jg-mapping`}>
          <CreateNewSGJGMapping />
        </Route>
        <Route path={`${PATH}/${tabs[5].path}`}>
          <TopTalentCategory />
        </Route>
        <Route path={`${PATH}/add-new-category`}>
          <AddTopTalentCategory />
        </Route>
        <Route path={`${PATH}/${tabs[6].path}`} exact>
          <ManagingPositionCategory />
        </Route>
        <Route path={`${PATH}/${tabs[6].path}/add`} exact>
          <AddManagingPositionCategory />
        </Route>
        <Route path={`${PATH}/${tabs[6].path}/:id`} exact>
          <AddManagingPositionCategory />
        </Route>
      </Switch>
    </div>
  );
}
