import { isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useSelector } from 'react-redux';

import { close, triangle_edit } from '../../../../../assets/img';
import { useComponentVisible } from '../../../../../hooks';
import { fetchPositions } from '../../../../../services/successionplanningratio';
import { handleShowPosition } from '../../../../../utils/helper';
import styles from './add-position-dialog.module.scss';

const PositionListByCategory = (props) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const { isShowReview, successPlanning, setPositionToBeReviewed, positionToBeReviewed } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSelectOption = (item) => {
    setPositionToBeReviewed({
      value: item,
      showTerm: handleShowPosition(item),
    });
    setIsComponentVisible((preState) => !preState);
  };

  const handleDisplayDropdown = () => {
    setIsComponentVisible((preState) => !preState);
  };

  const handleDelete = () => {
    setPositionToBeReviewed({
      value: '',
      showTerm: '',
    });
    setIsComponentVisible((preState) => !preState);
  };

  useEffect(() => {
    async function fetchAPI() {
      try {
        setLoading(true);
        setPositionToBeReviewed({
          value: '',
          showTerm: '',
        });
        const response = await fetchPositions(successPlanning, 'position_type', roleActive.roleId);
        if (response.data && response.data.result.data.length > 0) {
          setOptions(response.data.result.data);
        } else {
          setOptions([]);
        }
        setLoading(false);
      } catch (error) {
        setOptions([]);
      }
    }
    successPlanning && fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successPlanning]);

  return (
    <>
      {isShowReview && (
        <div className={styles.form}>
          <div>Position</div>
          <div ref={ref}>
            <input
              id="position"
              data-testid="position-input"
              name="position"
              placeholder="Position name, business (position id)"
              className={styles.position_input}
              onKeyDown={() => {}}
              onClick={handleDisplayDropdown}
              value={positionToBeReviewed.showTerm}
              readOnly
            />
            {positionToBeReviewed.value && (
              <span className={styles.close_icon} onKeyDown={() => {}} onClick={handleDelete}>
                <img src={close} alt="" />
              </span>
            )}
            <img src={triangle_edit} alt="" />
            <Collapse
              in={isComponentVisible}
              aria-controls="example-collapse-text"
              aria-expanded={isComponentVisible}
            >
              <div className={styles.dropdown_positions}>
                {!loading &&
                  options &&
                  options.length > 0 &&
                  options.map((item, index) => (
                    <div key={index} onKeyDown={() => {}} onClick={() => handleSelectOption(item)}>
                      {!isNull(item.position_name) && item?.position_name}
                      {!isNull(item.business_unit) && !isNull(item.position_name) && ', '}
                      {item.business_unit}
                      {item.position_code && '('}
                      {item.position_code}
                      {item.position_code && ')'}
                    </div>
                  ))}
                {!loading && options && options.length === 0 && <div>No Options</div>}
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </>
  );
};
export default PositionListByCategory;
