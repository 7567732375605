import { Modal } from 'antd';
import styled from 'styled-components';
import { color, font, fontSize } from '../../../../../assets/common';

export const AiModel = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 118px 116px;
  }
`;
export const AiTitle = styled.p`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.superLarge};
  color: ${color.brightGray2};
  line-height: 38.73px;
  padding-bottom: 20px;
  width: 90%;
`;
export const AiDesc = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: 13;
  color: ${color.santasGray};
  line-height: 20px;
  padding-bottom: 20px;
`;
