import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { SearchDataTableNotFound } from '../../../../components';
import { profileManagementApi } from '../../../../services/profileManagementApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { CONTENT_SEARCH_NOT_FOUND, MESSAGE_TYPES } from '../../../../utils/constants';
import { PROFILE_MANAGEMENT_MESSAGES } from '../constant';
import DataTable from './DataTable/DataTable';
import MenuBar from './MenuBar/MenuBar';
import PillManaging from './PillManaging/PillManaging';

export default function StaffSgJgManagement() {
  const [data, setData] = useState([]);
  const [isLoadingList, setLoadingList] = useState(false);
  const [isLoadingOptions, setLoadingOptions] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [isEdit, setIsEdit] = useState(null);
  const [query, setQuery] = useState({
    page: 1,
    totalPage: 0,
    limit: 15,
    keyword: '',
    successor: [],
    topTalentStatus: [],
    missingData: [],
  });
  const [showFilter, setShowFilter] = useState(false);
  const [sgJgOptions, setSgJgOption] = useState({
    sgOptions: [],
    jgOptions: [],
  });
  const dispatch = useDispatch();

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
        })
      );
    },
    [dispatch]
  );

  const getStaffSgJgManagement = async () => {
    setLoadingList(true);
    try {
      const missingData = query.missingData
        .map((item) => item.label)
        .filter((item) => item !== 'All')
        .join(',');
      const response = await profileManagementApi.getStaffSgJgList(roleActive, {
        keyword: query.keyword,
        _limit: query.limit,
        _page: query.page,
        successor: query.successor
          .map((item) => item.label)
          .filter((item) => item !== 'All')
          .join(','),
        top_talent_status: query.topTalentStatus
          .map((item) => item.label)
          .filter((item) => item !== 'All')
          .join(','),
        missing_data: !missingData && !query.keyword ? 'Yes' : missingData,
      });
      const data = get(response, 'data.result.data') || [];
      const totalPage = get(response, 'data.result.total') || 0;
      if (!isEmpty(data)) {
        setData(data);
      } else {
        setData([]);
        handleShowMessage(MESSAGE_TYPES.ERROR, PROFILE_MANAGEMENT_MESSAGES.SEARCH_NOT_FOUND);
      }
      setQuery((prev) => {
        return {
          ...prev,
          totalPage,
        };
      });
      setLoadingList(false);
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  useEffect(() => {
    getStaffSgJgManagement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.page,
    query.keyword,
    query.successor.length,
    query.topTalentStatus.length,
    query.missingData.length,
  ]);

  useEffect(() => {
    async function getSgJgDataOptions() {
      setLoadingOptions(true);
      try {
        const response = await profileManagementApi.getSgJgDataOptions(roleActive);
        const data = get(response, 'data.result') || [];
        setSgJgOption({
          sgOptions: data.salary_grades,
          jgOptions: data.job_grades,
        });
        setLoadingOptions(false);
      } catch (error) {
        console.log(error);
        setLoadingOptions(false);
      }
    }

    getSgJgDataOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={isLoadingList || isLoadingOptions} size="large">
      <MenuBar
        query={query}
        setQuery={setQuery}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <PillManaging showFilter={showFilter} query={query} setQuery={setQuery} />
      {!isEmpty(data) ? (
        <DataTable
          data={data}
          query={query}
          setQuery={setQuery}
          getStaffSgJgManagement={getStaffSgJgManagement}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          sgJgOptions={sgJgOptions}
        />
      ) : (
        <SearchDataTableNotFound
          content={CONTENT_SEARCH_NOT_FOUND.LIST_OF_PROFILES}
          notFoundIcon={search_data_not_found}
        />
      )}
    </Spin>
  );
}
