import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { utils, writeFile } from 'xlsx-js-style';
import { genCommonStyle, historicalExportDeclareWorkSheet } from './CommonExportHistorical';

function exportSuccesionPlanning(type, roles, data) {
  const fileName = type.label;
  const arr = [];
  data.forEach((item) => {
    const rows = Object.values(item).filter((val) => !Array.isArray(val));
    arr.push(Object.values(rows));
  });
  const length = arr[0].length;

  const headers1 = new Array(length).fill('');
  headers1[0] = 'Months (End of months)';
  headers1[1] = 'No of SP conducted';
  headers1[2] = `${fileName} Overall Ratio`;
  headers1[3] = `${fileName} Overall Position Deliberated`;
  headers1[4] = `${fileName} Overall Critical position `;

  headers1[5] = `${fileName} ${roles[0]['abbreviation']} Ratio`;
  headers1[6] = `${fileName} ${roles[0]['abbreviation']} Position Deliberated`;
  headers1[7] = `${fileName} ${roles[0]['abbreviation']} Critical position`;
  headers1[8] = `${fileName} ${roles[1]['abbreviation']} Ratio`;
  headers1[9] = `${fileName} ${roles[1]['abbreviation']} Position Deliberated`;
  headers1[10] = `${fileName} ${roles[1]['abbreviation']} Critical position`;
  headers1[11] = `${fileName} ${roles[2]['abbreviation']} Ratio`;
  headers1[12] = `${fileName} ${roles[2]['abbreviation']} Position Deliberated`;
  headers1[13] = `${fileName} ${roles[2]['abbreviation']} Critical position`;

  arr.unshift(headers1);
  if (length > 0) {
    let [ws, wb] = historicalExportDeclareWorkSheet(arr);
    utils.book_append_sheet(wb, ws, 'Succession Planning');

    const colWidth = new Array(length).fill({ width: 11 });
    ws['!cols'] = colWidth;
    const commonStyle = genCommonStyle({
      style: 'thick',
      fontColor: '000000',
      bold: false,
      fontName: 'Calibri',
    });

    for (let property in ws) {
      if (typeof ws[property] === 'object' && ws[property]?.s) ws[property].s = commonStyle;
    }

    for (let i = 65; i <= 78; i++) {
      const value1 = ws[`${String.fromCharCode(i)}${1}`]?.s;
      if (ws[`${String.fromCharCode(i)}${1}`]?.s) {
        ws[`${String.fromCharCode(i)}${1}`].s = {
          ...value1,
          ...commonStyle,
          fill: { fgColor: { rgb: '00a9b1' } },
          font: {
            color: {
              rgb: 'fffff',
            },
            name: 'Calibri',
            bold: true,
            sz: '10',
          },
        };
      }
    }

    for (let j = 65; j <= 78; j++) {
      for (let i = 2; i <= 13; i++) {
        const value1 = ws[`${String.fromCharCode(j)}${i}`]?.s;
        if (i === 13) {
          if (ws[`${String.fromCharCode(j)}${i}`]?.s) {
            ws[`${String.fromCharCode(j)}${i}`].s = {
              ...value1,
              border: {
                ...value1?.border,
                top: {
                  style: 'thin',
                  color: '000000',
                },
              },
            };
          }
        } else {
          if (ws[`${String.fromCharCode(j)}${i}`]?.s) {
            ws[`${String.fromCharCode(j)}${i}`].s = {
              ...value1,
              border: {
                ...value1?.border,
                top: {
                  style: 'thin',
                  color: '000000',
                },
                bottom: {
                  style: 'thin',
                  color: '000000',
                },
              },
            };
          }
        }
      }
    }
    ['C', 'D', 'F', 'G', 'I', 'J', 'L', 'M'].forEach((item) => {
      for (let i = 1; i <= 13; i++) {
        const value1 = ws[`${item}${i}`]?.s;
        if (value1) {
          ws[`${item}${i}`].s = {
            ...value1,
            border: {
              ...value1?.border,
              right: {
                style: 'thin',
                color: '000000',
              },
              left: {
                style: 'thin',
                color: '000000',
              },
            },
          };
        }
      }
    });

    ['E', 'H', 'K', 'N'].forEach((item) => {
      for (let i = 1; i <= 13; i++) {
        const value1 = ws[`${item}${i}`]?.s;
        if (value1) {
          ws[`${item}${i}`].s = {
            ...value1,
            border: {
              ...value1?.border,

              left: {
                style: 'thin',
                color: '000000',
              },
            },
          };
        }
      }
    });
    writeFile(wb, `${fileName}.xlsx`);
  }
}
const ExportSuccesionPlanning = (props) => {
  const { setExportData, data, selectedTalentOverview, roles } = props;
  useEffect(() => {
    exportSuccesionPlanning(selectedTalentOverview, roles, data);
    setExportData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

ExportSuccesionPlanning.propTypes = {
  setExportData: PropTypes.func,
  data: PropTypes.array,
  selectedTalentOverview: PropTypes.object,
  roles: PropTypes.array,
};

export default ExportSuccesionPlanning;
