import { Spin } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicButton, BasicInput, BasicLabel } from '../../../../assets/common';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import { ACCESS_FIELD } from '../../constants';
import AccessControlField from '../AccessControlFields/AccessControlFields';
import * as styles from './create-report-modal.module.scss';

const CUSTOM_STYLE = {
  closePosition: 30,
  headerPaddingBottom: 40,
  containerPaddingTopBottom: 40,
  titleFontSize: 20,
  titleFontWeight: 500,
};

const CreateReportModal = ({ open, setOpen }) => {
  const history = useHistory();
  const [creating, setCreating] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      ...ACCESS_FIELD.reduce((acc, field) => ({ ...acc, [field.name]: [] }), {}),
    },
    onSubmit: async (values) => {
      try {
        setCreating(true);
        const res = await reportBuilderApi.createReport(values);
        if (res.status === 200) {
          history.push(`/report-builder/${res.data.result.id}`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setCreating(false);
      }
    },
  });

  const handleOnChange = (event) => {
    const target = event.target;
    formik.setFieldValue(target.name, target.value);
  };

  const handleOnCancel = () => {
    formik.resetForm();
  };

  return (
    <CustomAntModal
      title="Create New Report"
      hideDefaultBtn
      destroyOnClose={false}
      onCancel={handleOnCancel}
      width={724}
      open={open}
      setOpen={setOpen}
      styles={CUSTOM_STYLE}
    >
      <Spin spinning={creating}>
        <BasicLabel required htmlFor="title" color="#525252">
          Title of Report
        </BasicLabel>
        <BasicInput
          data-testid="report-name"
          id="name"
          name="name"
          placeholder="Enter Report Name"
          autoComplete="off"
          maxLength="20"
          height={44}
          value={formik.values.name}
          onChange={handleOnChange}
        />
        <AccessControlField
          formikSetFieldValue={formik.setFieldValue}
          formikFieldValues={formik.values}
          marginTop={40}
        />
        <div className={styles.btnWrapper}>
          <BasicButton
            type="submit"
            onClick={formik.submitForm}
            mode="teal"
            height={44}
            disabled={!formik.values.name}
          >
            Create Report
          </BasicButton>
        </div>
      </Spin>
    </CustomAntModal>
  );
};

CreateReportModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default CreateReportModal;
