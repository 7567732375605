import { chunk } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { full_arrow_left, full_arrow_right, full_arrow_top } from '../../../../assets/img';
import MobilityPositionCard from '../MobilityPositionCard/MobilityPositionCard';
import * as styles from './mobility-chain-simulation.module.scss';

const MobilityChainSimulation = (props) => {
  const { data } = props;
  const [maxWidthCard, setMaxWidthCard] = useState('100%');
  const wrapperRef = useRef(null);

  const chunkData = useMemo(() => {
    return chunk(data, 3);
  }, [data]);

  const isLastItem = (array, index) => {
    return array.length === index + 1;
  };

  useEffect(() => {
    if (!wrapperRef?.current?.offsetWidth) return;
    setMaxWidthCard(`${(wrapperRef.current.offsetWidth - 120) / 3}px`);
  }, [wrapperRef]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {chunkData.map((dataRow, indexRow) => {
        const isOddRow = indexRow % 2 !== 0;
        return (
          <div key={indexRow} className={`${styles.row} ${isOddRow && styles.rowReverse}`}>
            {dataRow.map((card, indexCard) => {
              const isLastCard = isLastItem(dataRow, indexCard);
              return (
                <React.Fragment key={indexCard}>
                  <div className={styles.card} style={{ maxWidth: maxWidthCard || `370px` }}>
                    {isLastCard ? (
                      <>
                        <MobilityPositionCard data={card} width={maxWidthCard} />
                        {!isLastItem(chunkData, indexRow) && (
                          <img
                            src={full_arrow_top}
                            alt="full_arrow_top"
                            className={styles.arrowTop}
                          />
                        )}
                      </>
                    ) : (
                      <MobilityPositionCard data={card} width={maxWidthCard} />
                    )}
                  </div>
                  {!isLastCard && (
                    <img src={isOddRow ? full_arrow_right : full_arrow_left} alt="full_arrow_top" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default MobilityChainSimulation;
