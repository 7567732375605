import moment from 'moment';
import React from 'react';

import { DATE_TIME_FORMAT } from '../../../../../../utils/constants';
import styles from '../../../../../TalentReviewAgenda/components/SuccessionPlanningSection/RecommendationTable/recommendations.module.scss';

const PositionItem = React.forwardRef((props, ref) => {
  const { item, draggingItem } = props;
  const isApprovedDate =
    item?.approved_date && item?.last_approved_date
      ? moment(moment(item.approved_date).format('YYYY-MM-DD')).isSame(
          moment(item.last_approved_date).format('YYYY-MM-DD')
        )
      : false;

  return (
    <div className={styles.card_box} ref={ref}>
      <div className="row no-gutters justify-content-start align-items-stretch">
        <div
          className="row justify-content-between no-gutters align-items-center mt-1"
          style={{
            fontSize: '12px',
            fontWeight: '600',
            color: '#50A4B9',
            gap: '5px',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <div>
            {item.position_name} {item.position_code && ` (${item.position_code})`}
          </div>
        </div>
        <div className={styles.position}>
          <span>{item?.job_grade || 'N/A'}</span>
          <br />
          <span>{item?.department_name || item?.department || 'N/A'}</span>
          <br />
          <span>{item?.business_unit || item?.business || 'N/A'}</span>
          <br />
          <span>
            {item?.division || 'N/A'}, {item?.opu_name || item?.opu || 'N/A'}
          </span>
        </div>
        {!draggingItem.startsWith('recommendation') && (
          <div>
            {isApprovedDate ? (
              <span className={styles.approve}>
                Approved : {moment(item.last_approved_date).format(DATE_TIME_FORMAT.DATE_SPACE)}
              </span>
            ) : (
              <span className={styles.new}>New</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
export default PositionItem;
