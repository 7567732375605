import { invoke } from 'lodash';
import { useCallback, useState } from 'react';
import talentProfileApi from '../services/talentProfiles';

export const useCreateRemarkInMeeting = () => {
  const [isLoading, setLoading] = useState(false);

  const createRemark = useCallback(async (data, options) => {
    try {
      setLoading(true);
      const response = await talentProfileApi.createMeetingRemark(data);
      invoke(options, 'onSuccess', response.data);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      invoke(options, 'onError', err);
      return {};
    }
  }, []);

  return { createRemark, isLoading };
};
