import { Tabs } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { dashboard_breadcrumb } from '../../../assets/img';
import { BreadCrumb } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { PERMISSION } from '../../../utils/constants';
import AddEditSucessionPlanning from '../components/AddEditSuccessionPlaning/AddEditSucessionPlanning';
import { TAB_URL_INDEX } from './constants';
import CopySuccessionPlanning from './CopySuccessionPlanning/CopySuccessionPlanning';
import ManageApprovedSP from './ManageApprovedSP/ManageApprovedSP';
import AddEditCriteriaSetMatching from './ManagingCriteriaCP/AddEditCriteriaSetMatching/AddEditCriteriaSetMatching';
import ManagingCriteriaCP from './ManagingCriteriaCP/ManagingCriteriaCP';
import ViewResultSetMatching from './ManagingCriteriaCP/ViewResultSetMatching/ViewResultSetMatching';
import CreateOrEdit from './ManagingCriticalPosition/CreateOrEditCriticalPosition/CreateOrEdit';
import ManagingCriticalPosition from './ManagingCriticalPosition/ManagingCriticalPosition';
import AddNewSpReadiness from './SpReadinessRules/AddNewSpReadiness/AddNewSpReadiness';
import EditSpReadiness from './SpReadinessRules/EditSpReadiness/EditSpReadiness';
import SpReadinessRules from './SpReadinessRules/SpReadinessRules';

const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Admin' },
  { name: 'Succession Planning (SP Management)' },
];

const tabs = [
  {
    key: '0',
    label: 'Edit Approved Succession Planning',
    path: 'edit-approved-succession-planning',
    title: 'Succession Planning Management',
    subTitle: 'List of Approved Succession Planning',
    tabAble: true,
    permission: PERMISSION.EDIT_APPROVED_SUCCESSION_PLANNING,
  },
  {
    key: '1',
    label: 'Managing Criteria for Critical Position',
    path: 'managing-criteria-for-critical-position',
    title: 'Succession Planning Management',
    subTitle: 'List of Managing Criteria for Critical Position',
    tabAble: true,
    permission: PERMISSION.MANAGING_CRITERIA_FOR_CRITICAL_POSITION,
  },
  {
    key: '2',
    label: 'Managing Critical Position',
    path: 'managing-critical-position',
    title: 'Succession Planning Management',
    subTitle: 'List of Managing Criteria for Critical Position',
    tabAble: true,
    permission: PERMISSION.MANAGING_CRITICAL_POSITION,
  },
  {
    key: '3',
    label: 'Copy Succession Planning to Other Position',
    path: 'copy-succession-planning-to-other-position',
    title: 'Succession Planning Management',
    subTitle: 'List of Copy Succession Planning to Other Position',
    tabAble: true,
    permission: PERMISSION.COPY_SUCCESSION_PLANNING_TO_OTHER_POSITION,
  },
  {
    key: '4',
    path: 'add-new-critical-position',
    title: 'Add New Critical Position',
    subTitle: 'Succession Planning Management',
    tabAble: false,
    permission: null,
  },
  {
    key: '5',
    path: 'edit-critical-position',
    title: 'Edit Critical Position',
    subTitle: 'Succession Planning Management',
    tabAble: false,
    permission: null,
  },
  {
    key: '6',
    label: 'SP Readiness Rules',
    path: 'readiness-rules',
    title: 'Succession Planning Management',
    subTitle: 'List of Succession Planning Readiness Rules',
    tabAble: true,
    permission: PERMISSION.SP_READINESS_RULE,
  },
  {
    key: '7',
    path: 'add-new-readiness',
    title: 'Add New Rules',
    subTitle: 'Succession Planning Readiness Rules',
    tabAble: false,
    permission: null,
  },
  {
    key: '8',
    path: 'edit-readiness',
    title: 'Edit Rules',
    subTitle: 'Succession Planning Readiness Rules',
    tabAble: false,
    permission: null,
  },
];

export default function SPManagement() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState();
  const [newTab, setNewTab] = useState([]);
  const user = useSelector((state) => state.user);

  const onChangeTab = (key) => {
    const tab = tabs.find((tab) => Number(tab.key) === Number(key));
    history.push(tab.path);
  };

  useEffect(() => {
    if (user?.permissions) {
      let temArr = [];
      tabs.forEach((item) => {
        if (
          (item?.tabAble && item?.permission === null) ||
          user?.permissions.includes(item.permission)
        ) {
          temArr.push(item);
        }
        setNewTab(temArr);
      });
      if (location.pathname.split('/').length > TAB_URL_INDEX) {
        handleResetTab();
      }
      if (!isEmpty(temArr) && location.pathname.split('/').length <= TAB_URL_INDEX) {
        const tab = temArr.find((tab) => location.pathname.split('/').includes(tab.path));
        if (tab) {
          setCurrentTab(tab);
          history.push(
            tab.path === 'managing-criteria-for-critical-position' && !location.search
              ? tab.path + '?type=1'
              : tab.path
          );
        } else {
          handleResetTab(temArr[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.pathname, user]);

  const handleResetTab = (data) => {
    if (!data) {
      setCurrentTab();
    } else {
      setCurrentTab(data);
      history.push(data?.path);
    }
  };

  return (
    <div className="max-width">
      <div>
        <BreadCrumb level={3} breadCrumbList={breadCrumbList} />
      </div>
      <GlobalAlertMessage />
      <div className="title-global">
        <h3>{currentTab?.title}</h3>
        <p>{currentTab?.subTitle}</p>
      </div>
      {currentTab?.tabAble && (
        <Tabs
          defaultActiveKey="0"
          activeKey={currentTab?.key}
          onChange={onChangeTab}
          items={newTab.filter((item) => item.tabAble)}
        />
      )}
      <Switch>
        <Route path={'/admin/sp-management'} exact>
          <Redirect to={`${path}/${tabs[0].path}`} />
        </Route>
        <Route path={`${path}/edit-approved-succession-planning`} exact>
          <ManageApprovedSP />
        </Route>
        <Route path={`${path}/approved-succession-planning/:positionCode`} exact>
          <AddEditSucessionPlanning />
        </Route>
        <Route path={`${path}/approved-succession-planning/:positionCode/:approvedDate`} exact>
          <AddEditSucessionPlanning />
        </Route>
        <Route path={`${path}/managing-critical-position`} exact>
          <ManagingCriticalPosition />
        </Route>
        <Route path={`${path}/managing-critical-position/add`} exact>
          <CreateOrEdit mode="create" />
        </Route>
        <Route path={`${path}/edit-critical-position/:positionCode`}>
          <CreateOrEdit mode="edit" />
        </Route>
        <Route path={`${path}/${tabs[1].path}/add`} exact>
          <AddEditCriteriaSetMatching mode="create" />
        </Route>
        <Route path={`${path}/edit-criteria/:positionCode`} exact>
          <AddEditCriteriaSetMatching mode="edit" />
        </Route>
        <Route path={`${path}/view-result-matching/:positionCode/:runId`} exact>
          <ViewResultSetMatching />
        </Route>
        <Route path={`${path}/${tabs[3].path}`} exact>
          <CopySuccessionPlanning />
        </Route>
        <Route path={`${path}/${tabs[1].path}`} exact>
          <ManagingCriteriaCP />
        </Route>
        <Route path={`${path}/readiness-rules/`} exact>
          <SpReadinessRules />
        </Route>
        <Route path={`${path}/readiness-rules/add`} exact>
          <AddNewSpReadiness />
        </Route>
        <Route path={`${path}/edit-readiness/:group_id`} exact>
          <EditSpReadiness />
        </Route>
      </Switch>
    </div>
  );
}
