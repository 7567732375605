import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { pets_chevron_right } from '../../assets/img/';
import styles from './bread-crumb.module.scss';

const BreadCrumb = (props) => {
  const { level, breadCrumbList } = props;
  const history = useHistory();
  const [cursor, setCursor] = useState(null);

  useEffect(() => {
    setCursor(breadCrumbList[0]?.url ? 'pointer' : null);
  }, [breadCrumbList]);
  const onClick = () => {
    if (breadCrumbList[0]?.url) {
      history.push(breadCrumbList[0]?.url);
    }
  };
  const renderBreadCrumb = () => {
    let breadCrumb = {};
    switch (level) {
      case 2:
        breadCrumb = (
          <div className={styles.breadcrumb}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '10px', cursor }}
              onKeyDown={() => {}}
              onClick={onClick}
            >
              <img src={breadCrumbList[0]?.icon} alt={''} />
              <p>{breadCrumbList[0]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <p className={styles.talent_profile}>{breadCrumbList[1]?.name}</p>
          </div>
        );
        break;
      case 3:
        breadCrumb = (
          <div className={styles.breadcrumb}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '10px', cursor }}
              onKeyDown={() => {}}
              onClick={onClick}
            >
              <img src={breadCrumbList[0]?.icon} alt={''} />
              <p>{breadCrumbList[0]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <div style={{ color: '#00847C', cursor }} onKeyDown={() => {}} onClick={onClick}>
              <p>{breadCrumbList[1]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <p className={styles.talent_profile}>{breadCrumbList[2]?.name}</p>
          </div>
        );
        break;
      case 4:
        breadCrumb = (
          <div className={styles.breadcrumb}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '10px', cursor }}
              onKeyDown={() => {}}
              onClick={onClick}
            >
              <img src={breadCrumbList[0]?.icon} alt={''} />
              <p>{breadCrumbList[0]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <div
              style={{ color: '#00847C', cursor: breadCrumbList[1]?.url ? 'pointer' : null }}
              onKeyDown={() => {}}
              onClick={breadCrumbList[1]?.url ? () => history.push(breadCrumbList[1]?.url) : null}
            >
              <p>{breadCrumbList[1]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <div
              style={{ color: '#00847C', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => history.goBack()}
            >
              <p>{breadCrumbList[2]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <p className={styles.talent_profile}>{breadCrumbList[3]?.name}</p>
          </div>
        );
        break;
      case 5:
        breadCrumb = (
          <div className={styles.breadcrumb}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '10px', cursor }}
              onKeyDown={() => {}}
              onClick={onClick}
            >
              <img src={breadCrumbList[0]?.icon} alt={''} />
              <p style={{ color: '#000000' }}>{breadCrumbList[0]?.name}</p>
            </div>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <div>
              <p style={{ color: '#000000' }}>{breadCrumbList[1]?.name}</p>
            </div>
            {breadCrumbList[2]?.name && <img src={pets_chevron_right} alt="pets_chevron_right" />}
            <p className={styles.talent_profile} style={{ color: '#00847C' }}>
              {breadCrumbList[2]?.name}
            </p>
          </div>
        );
        break;
      default:
        break;
    }
    return breadCrumb;
  };
  return <>{renderBreadCrumb()}</>;
};

export default BreadCrumb;
