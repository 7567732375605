import { isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { getOriginalColor } from '../../../../utils/helper';
import { Title } from '../DevelopmentPlan/FormDevelopmentPlan/styled';
import TalentReviewCollapse from '../TalentReviewCollapse/TalentReviewCollapse';
import AddEditSucession from './AddEditSucession/AddEditSucession';
import styles from './succession-planning.module.scss';
import SucessionView from './SucessionView/SucessionView';
import SucessionViewPopup from './SucessionViewPopup/SucessionViewPopup';

const spReadiness = [
  {
    title: '1st Line',
    backgroundcolor: '#66B2C4',
    className: styles.line_1st,
    classNameExpand: styles.grid_expand_1st,
    code: 'first_line',
  },
  {
    title: '2nd Line',
    backgroundcolor: '#BDB6EA',
    className: styles.line_2nd,
    classNameExpand: styles.grid_expand_2nd,

    code: 'second_line',
  },
  {
    title: '3rd Line',
    backgroundcolor: '#BCC6E1',
    className: styles.line_3rd,
    classNameExpand: styles.grid_expand_3rd,
    code: 'third_line',
  },
  {
    title: 'Agreed to Drop',
    backgroundcolor: '#FCAA1B',
    className: styles.agreed_to_drop,
    code: 'agreed_to_drop',
  },
];

const SuccessionPlanning = (props) => {
  const {
    addTalent,
    setAddTalent,
    staffTalentReview,
    handleShowMessage,
    agendaDetail,
    onlyViewTalentAgenda,
    isPrinting,
  } = props;

  const [showSucessionView, setShowSucessionView] = useState(false);
  const isModeCreate = !onlyViewTalentAgenda;

  useEffect(() => {
    if (
      addTalent?.succession_planning?.first_line === undefined ||
      addTalent?.succession_planning?.second_line === undefined ||
      addTalent?.succession_planning?.third_line === undefined ||
      addTalent?.succession_planning?.agreed_to_drop === undefined
    ) {
      setAddTalent((prev) => {
        return {
          ...prev,
          succession_planning: {
            first_line:
              addTalent?.succession_planning?.first_line !== undefined
                ? addTalent?.succession_planning?.first_line
                : [],
            second_line:
              addTalent?.succession_planning?.second_line !== undefined
                ? addTalent?.succession_planning?.second_line
                : [],
            third_line:
              addTalent?.succession_planning?.third_line !== undefined
                ? addTalent?.succession_planning?.third_line
                : [],
            agreed_to_drop:
              addTalent?.succession_planning?.agreed_to_drop !== undefined
                ? addTalent?.succession_planning?.agreed_to_drop
                : [],
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTalent]);

  useEffect(() => {
    if (isPrinting || isEmpty(agendaDetail?.succession_planning)) return;

    setAddTalent((prev) => {
      return {
        ...prev,
        succession_planning: {
          first_line: agendaDetail.succession_planning.first_line.map((item) => {
            return { ...item, color: getOriginalColor(item.old_line) };
          }),
          second_line: agendaDetail.succession_planning.second_line.map((item) => {
            return { ...item, color: getOriginalColor(item.old_line) };
          }),
          third_line: agendaDetail.succession_planning.third_line.map((item) => {
            return { ...item, color: getOriginalColor(item.old_line) };
          }),
          agreed_to_drop: agendaDetail.succession_planning.agreed_to_drop.map((item) => {
            return { ...item, color: getOriginalColor(item.old_line) };
          }),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaDetail]);

  useEffect(() => {
    if (
      isEmpty(addTalent?.succession_planning?.first_line) &&
      isEmpty(addTalent?.succession_planning?.second_line) &&
      isEmpty(addTalent?.succession_planning?.third_line) &&
      isEmpty(addTalent?.succession_planning?.agreed_to_drop)
    )
      return;
    setAddTalent((prev) => {
      return {
        ...prev,
        succession_planning: {
          first_line: isEmpty(addTalent?.succession_planning?.first_line)
            ? []
            : orderBy(
                addTalent?.succession_planning?.first_line,
                [
                  (v) => {
                    return v.rank || '';
                  },
                  (v) => {
                    return v.position_code || '';
                  },
                ],
                ['desc', 'asc']
              ),
          second_line: isEmpty(addTalent?.succession_planning?.second_line)
            ? []
            : orderBy(
                addTalent?.succession_planning?.second_line,
                [
                  (v) => {
                    return v.rank || '';
                  },
                  (v) => {
                    return v.position_code || '';
                  },
                ],
                ['desc', 'asc']
              ),
          third_line: isEmpty(addTalent?.succession_planning?.third_line)
            ? []
            : orderBy(
                addTalent?.succession_planning?.third_line,
                [
                  (v) => {
                    return v.rank || '';
                  },
                  (v) => {
                    return v.position_code || '';
                  },
                ],
                ['desc', 'asc']
              ),
          agreed_to_drop: isEmpty(addTalent?.succession_planning?.agreed_to_drop)
            ? []
            : orderBy(
                addTalent?.succession_planning?.agreed_to_drop,
                [
                  (v) => {
                    return v.rank || '';
                  },
                  (v) => {
                    return v.position_code || '';
                  },
                ],
                ['desc', 'asc']
              ),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(addTalent.succession_planning)]);

  const renderView = () => {
    return (
      <div className={!isPrinting ? styles.container : styles.container_print}>
        {isModeCreate ? (
          <>
            <AddEditSucession
              addTalent={addTalent}
              setAddTalent={setAddTalent}
              spReadiness={spReadiness}
              handleShowMessage={handleShowMessage}
              staffTalentReview={staffTalentReview}
            />
            {showSucessionView && (
              <SucessionViewPopup
                setIsShowSearchPopup={setShowSucessionView}
                isShowSearchPopup={showSucessionView}
                data={addTalent}
                spReadiness={spReadiness}
                isPrinting={true}
              />
            )}
          </>
        ) : (
          <SucessionView data={addTalent} spReadiness={spReadiness} isPrinting={isPrinting} />
        )}
      </div>
    );
  };

  return (
    <>
      {!isPrinting && !isEmpty(addTalent?.succession_planning) && (
        <TalentReviewCollapse
          print={isPrinting}
          header={
            <div className="d-flex justify-content-between align-items-center">
              <div className={styles.title}>Succession Planning</div>
              {isModeCreate && (
                <div
                  className={styles.view_btn}
                  onKeyDown={() => {}}
                  onClick={() => setShowSucessionView(true)}
                >
                  View Succession Plan
                </div>
              )}
            </div>
          }
        >
          {renderView()}
        </TalentReviewCollapse>
      )}
      {isPrinting && !isEmpty(addTalent?.succession_planning) && (
        <div
          style={{
            borderRadius: 0,
            marginTop: '48px',
          }}
        >
          <Title>{'Succession Planing'}</Title>
          {renderView()}
        </div>
      )}
    </>
  );
};

export default SuccessionPlanning;
