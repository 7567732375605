import Axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { DocumentModal } from '..';
import downloadFile from '../../assets/img/Download.svg';
import file from '../../assets/img/fileIcon.svg';
import { getDocuments } from '../../services/talentProfiles';
import { FILE_EXTENSION } from '../../utils/constants';
import { getContentTypeByExtension } from '../../utils/helper';
import styles from './documents.module.scss';
import { useShowAlertUploadDownloadWhenNavigate } from '../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../hooks/useShowAlertWhenRefresh';

const DocumentList = (props) => {
  const { notes, onDownload, onPreview, index, loading, loadingFiles } = props;

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 mb';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <span
          style={{
            fontSize: '14px',
            lineHeight: '19px',
            fontStyle: 'normal',
            fontWeight: 'normal',
          }}
        >
          {notes?.file_name}
        </span>
      </Tooltip>
    );
  };

  return (
    <div className={styles.content_general}>
      <div className={styles.content_general_file}>
        {loadingFiles && loadingFiles.length > 0 && loadingFiles.includes(index) ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className={styles.content_file}>
            <img src={file} alt="" width="17" height="22" />
          </div>
        )}

        <div className={styles.content_name}>
          <OverlayTrigger placement="top-end" delay={{ show: 80, hide: 0 }} overlay={renderTooltip}>
            <div
              className={styles.file_name}
              onKeyDown={() => {}}
              onClick={() => onPreview(notes?.file_url, notes?.file_name, index)}
              style={{ cursor: 'pointer' }}
            >
              {notes?.file_name}
            </div>
          </OverlayTrigger>
          <div className={styles.file_size}>{formatBytes(notes.size)}</div>
        </div>
      </div>
      {loading.status && loading.location === index && loading.type === 'normal' ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <img
          src={downloadFile}
          alt=""
          className={styles.icon_download}
          onKeyDown={() => {}}
          onClick={() => onDownload(notes?.file_url, notes?.file_name, index, 'normal')}
        />
      )}
    </div>
  );
};

DocumentList.propTypes = {
  notes: PropTypes.object,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  index: PropTypes.number,
  loading: PropTypes.object,
  loadingFiles: PropTypes.array,
};

const Documents = (props) => {
  const { profileId, roleUser, isHasEditPermission } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState({
    status: false,
    location: '',
    type: '',
  });
  const showViewMore = () => {
    setIsOpen(true);
  };
  const [loadingFiles, setLoadingFile] = useState([]);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const onPreview = async (file, fileName, index) => {
    setLoadingFile(loadingFiles.concat(index));
    const link = document.createElement('a');
    const { contentType, responseType, dataType, extension } = getContentTypeByExtension(fileName);
    let url;
    switch (extension) {
      case FILE_EXTENSION.PDF:
      case FILE_EXTENSION.MP4:
        const options = {
          url: file,
          method: 'GET',
          responseType,
        };
        if (dataType) options[dataType] = dataType;

        const blob = await Axios({
          url: file,
          method: 'GET',
          responseType,
          dataType,
        });
        url = window.URL.createObjectURL(new Blob([blob.data], { type: contentType }));
        break;
      case FILE_EXTENSION.DOCX:
      case FILE_EXTENSION.DOC:
      case FILE_EXTENSION.PPT:
      case FILE_EXTENSION.PPTX:
      case FILE_EXTENSION.XLS:
      case FILE_EXTENSION.XLSX:
        url = `${process.env.REACT_APP_OFFICE_VIEW}?src=${file}`;
        console.log(url);
        break;
      default:
        break;
    }

    const filterLoadingFiles = loadingFiles.filter((i) => i !== index);
    setLoadingFile(filterLoadingFiles);

    link.href = `/preview?url=${url}&name=${fileName}`;
    link.target = '_blank';
    link.click();
  };

  const onDownload = (fileId, fileName, location, type) => {
    setLoading({
      status: true,
      location: location,
      type: type,
    });
    setIsUploadingOrDownloading(true);
    setShowAlertRefresh(true);
    Axios({
      url: fileId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading({
        status: false,
        location: '',
        type: '',
      });
      setIsUploadingOrDownloading(false);
      setShowAlertRefresh(false);
    });
  };
  useEffect(() => {
    async function fetchApi() {
      try {
        const response = await getDocuments({ profileId, roleUser });
        if (response && response?.data && response?.data?.result?.length > 0) {
          setDocumentList(response.data.result);
        } else {
          setDocumentList([]);
        }
      } catch (error) {
        console.log(error);
        setDocumentList([]);
      }
    }
    if (profileId && roleUser.roleActive.roleId) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, reload, profileId]);

  return (
    <div className={styles.documents} data-testid="documents">
      <h1>Documents</h1>

      <div className={styles.menu}>
        {documentList &&
          documentList.length > 0 &&
          documentList?.map(
            (item, idx) =>
              idx < 5 && (
                <DocumentList
                  key={idx}
                  index={idx}
                  notes={item}
                  onDownload={onDownload}
                  onPreview={onPreview}
                  loading={loading}
                  loadingFiles={loadingFiles}
                />
              )
          )}
      </div>

      <p
        className={styles.view_more}
        onKeyDown={() => {}}
        onClick={showViewMore}
        data-testid="show-more"
      >
        View more document
      </p>
      {/* TO DO will do popup document at another US */}
      <DocumentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={documentList}
        onPreview={onPreview}
        onDownload={onDownload}
        profileId={profileId}
        reload={reload}
        setReload={setReload}
        setDocumentList={setDocumentList}
        loading={loading}
        roleUser={roleUser}
        isHasEditPermission={isHasEditPermission}
      />
    </div>
  );
};

Documents.propTypes = {
  profileId: PropTypes.string,
  roleUser: PropTypes.object,
  isHasEditPermission: PropTypes.bool,
};

export default Documents;
