import React from 'react';
import { CALENDAR } from '../../../../utils/constants';
import { Container } from './styled';

const MeetingStatus = (props) => {
  const { selected, setSelected } = props;
  return (
    <Container>
      <div className="meeting-status" data-testid="meeting-status">
        {CALENDAR.MEETING_TYPE.map((d, index) => (
          <div
            className={d.param.includes(selected) ? 'active' : null}
            onKeyDown={() => {}}
            onClick={() => setSelected(d.param)}
            key={index}
            data-testid="meeting-status-click"
          >
            {d.display}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default MeetingStatus;
