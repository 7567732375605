import { slice } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ListOption } from '..';
import { handleShowPosition } from '../../utils/helper';
import styles from './search-sp-box.module.scss';

const SearchListOption = (props) => {
  const { data, loading, debounceSearchQuery, setSearchTerm, idCopy } = props;

  const handleSelectOption = async (item) => {
    let result = await handleShowPosition(item);
    if (idCopy) {
      await setSearchTerm({
        value: { sp_id: idCopy, position_code: item.position_code },
        type: 'submit',
        showTerm: result,
      });
      return;
    }
    await setSearchTerm({ value: item, type: 'submit', showTerm: result });
  };

  const handleShowListItems = () => {
    return slice(data, 0, 5).map((item, index) => {
      return (
        <li
          data-testid={`${index}-pos`}
          className={styles.search_item}
          key={index}
          onKeyDown={() => {}}
          onClick={() => handleSelectOption(item)}
        >
          <div className={styles.individual}>
            <div className={styles.name}>
              <p>
                {item.position_name}
                {item.business_unit && ', '}
                {item.business_unit}
                {item.position_label && ', '}
                {item.position_label}
                {item.position_code && '('}
                {item.position_code}
                {item.position_code && ')'}
              </p>
            </div>
          </div>
        </li>
      );
    });
  };

  return (
    <ListOption
      loading={loading}
      data={data}
      debounceSearchQuery={debounceSearchQuery}
      handleShowListItems={handleShowListItems}
    />
  );
};

SearchListOption.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  debounceSearchQuery: PropTypes.object,
  setSearchTerm: PropTypes.func,
  idCopy: PropTypes.string,
};

export default SearchListOption;
