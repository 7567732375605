import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { avatar, dashboard_breadcrumb, talent_finder_not_found } from '../../assets/img';
import { AlertMessage, BreadCrumb } from '../../components';
import talentFinderApi from '../../services/talentFinderApi';
import { PERMISSION } from '../../utils/constants';
import { decodeBase64ToImage } from '../../utils/helper';
import FilterFinder from './FilterFinder/FilterFinder';
import MenuBar from './MenuBar/MenuBar';
import styles from './talent-finder.module.scss';
import TalentFinderDetail from './TalentFinderDetail/TalentFinderDetail';

const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Talent Finder' },
];

function convertToDisplay(talentFinderList) {
  if (isEmpty(talentFinderList)) return [];
  const data = [];
  talentFinderList.forEach((d, i) => {
    data.push({
      ...d,
      key: i + 1,
      avatar: d.image ? decodeBase64ToImage(d.image) : avatar,
      name: d.birth_name,
      staffId: d.staff_id,
      positionName: d.position_name,
      department: d.department_name,
      companyOpu: d.opu_name,
      businessUnit: d.business_unit,
      action: 'View Talent',
      isUnsearchable: d.isUnsearchable,
      isPermitted: d.isPermitted,
    });
  });
  return data;
}

const TalentFinder = () => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const [showFilter, setShowFilter] = useState(false);
  const [mode, setMode] = useState(true);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const limit = 16;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [showError, setShowError] = useState(false);
  const [showExporting, setShowExporting] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filterFinderData, setFilterFinderData] = useState({});
  const [isFilter, setIsFilter] = useState(false);
  const [talentSearchResult, setTalentSearchResult] = useState('');
  const [keySearch, setKeySearch] = useState('');

  function onPageChange(value) {
    setPage(value);
  }

  function onClear() {
    setData([]);
    setTotalData(0);
  }

  const fetchApiListFilter = useCallback(
    async (filter, page, limit, keyword) => {
      try {
        setLoading(true);
        const res = await talentFinderApi.getList(
          { ...filter, page, limit, keyword, filter_search_result: talentSearchResult, is_talent_finder: true },
          roleActive,
          PERMISSION.TALENT_FINDER
        );
        if (res.status === 200) {
          const talentFinderList = get(res, 'data.result.profiles', []);
          const totalProfiles = get(res, 'data.result.totalProfiles', 0);
          setTotalData(totalProfiles);
          setData(convertToDisplay(talentFinderList));
        }
      } catch (error) {
        console.error(error);
        setData([]);
        setTotalData(0);
      }
      setLoading(false);
    },
    [roleActive, talentSearchResult]
  );

  useEffect(() => {
    if (!isEmpty(filterFinderData) || isSearch) {
      fetchApiListFilter(filterFinderData, page, limit, keyword);
    } else {
      onClear();
    }
  }, [keyword, isSearch, filterFinderData, talentSearchResult, page, fetchApiListFilter]);

  useEffect(() => {
    async function fetchApiOptions() {
      try {
        setLoadingFilter(true);
        const filterRes = await talentFinderApi.getTalentfilters(true);
        if (filterRes && filterRes.status === 200) {
          setFilterOptions(filterRes.data.result);
        }
      } catch (error) {
        console.error(error);
        setFilterOptions([]);
      }
      setLoadingFilter(false);
    }
    fetchApiOptions();
  }, []);

  useEffect(() => {
    if (showError === false) return;
    const timeout = setTimeout(() => {
      setShowError(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showError]);
  return (
    <div className={styles.max_width} data-testid="talent-finder">
      <div className={styles.control}>
        <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
        <div style={{ marginBottom: '20px' }}>
          {showError && (
            <AlertMessage
              message="You need to enter a search term before pressing search"
              type="error"
              onClose={() => {
                setTimeout(() => {
                  setShowError(false);
                }, 300);
              }}
            />
          )}
          <div className={styles.show_exporting} style={showExporting ? { height: '62px' } : { height: '0' }}>
            {showExporting && (
              <AlertMessage
                message="Talent Data is exporting. Please stay on this page to ensure the file downloaded successfully"
                type="success"
                onClose={() => {
                  setTimeout(() => {
                    setShowExporting(false);
                  }, 100);
                }}
              />
            )}
          </div>
        </div>
        <h3 className={styles.title}>Talent Finder</h3>
      </div>
      <MenuBar
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        mode={mode}
        setMode={setMode}
        setKeyword={setKeyword}
        keyword={keyword}
        data={data}
        setShowError={setShowError}
        setIsSearch={setIsSearch}
        setPage={setPage}
        onClear={onClear}
        filterFinderData={filterFinderData}
        role={roleActive}
        totalData={totalData}
        setShowExporting={setShowExporting}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        keySearch={keySearch}
        setKeySearch={setKeySearch}
      />
      {showFilter && (
        <Spin spinning={loadingFilter} size="large">
          <FilterFinder
            filterOptions={filterOptions}
            setFilterFinderData={setFilterFinderData}
            setIsFilter={setIsFilter}
            keySearch={keySearch}
            setTalentSearchResult={setTalentSearchResult}
            setKeyword={setKeyword}
            setPage={setPage}
          />
        </Spin>
      )}
      {!isEmpty(data) ? (
        <Spin spinning={loading} size="large">
          <TalentFinderDetail data={data} mode={mode} page={page} limit={limit} onPageChange={onPageChange} totalData={totalData} />
        </Spin>
      ) : (
        <div className={`${styles.not_found} position-relative`}>
          <div className="center-loading">
            <Spin spinning={loading} size="large"></Spin>
          </div>
          <img src={talent_finder_not_found} alt="talent_finder_not_found" style={loading ? { opacity: '0.3' } : null} />
        </div>
      )}
    </div>
  );
};

export default TalentFinder;
