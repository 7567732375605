import React from 'react';

import { AUDIT_LOG_TABS } from '../../../../../utils/constants';
import styles from './menu-bar.module.scss';

const MenuBar = (props) => {
  const { handleSearch, setKeySearch, keySearch, searchType } = props;

  return (
    <div className={styles.search_section}>
      <input
        type="text"
        placeholder={
          searchType === AUDIT_LOG_TABS[0] ? 'Search Audit Log' : 'Search User Activity Log'
        }
        className={styles.search}
        onChange={(e) => setKeySearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSearch();
        }}
        value={keySearch}
        data-testid="talent-finder-input"
        style={searchType === AUDIT_LOG_TABS[0] ? { width: '869px' } : { width: '969px' }}
      />
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex justify-content-between align-items-end">
          <div
            className={styles.button}
            onClick={handleSearch}
            onKeyDown={handleSearch}
            data-testid="talent-finder-btn-search"
          >
            Search
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
