import styled from 'styled-components';
import { Table } from 'antd';
import { color } from '../../../../assets/common';

export const TableCheckbox = styled(Table)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${color.teal};
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${color.teal};
  }

  .ant-checkbox-checked::after {
    border: none !important;
  }

  .ant-checkbox-input: focus + .ant-checkbox-inner {
    border-color: ${color.teal};
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
  }

  .ant-table th.ant-table-selection-column {
    width: 100px;
  }

  .ant-table th.ant-table-selection-column {
    text-align: left;
    width: 100px;
    padding-left: 24px;
  }

  .ant-table-thead > tr > th {
    background-color: ${color.white};
    color: ${color.loading};
    font-weight: 700;
    font-size: 14px;

    &:nth-child(3),
    &:nth-child(4) {
      text-align: center;
    }
  }

  .ant-space-item {
    color: ${color.darkBlue};
    font-size: 16px;
    font-weight: 700;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
