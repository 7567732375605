import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';

import { DEVELOPMENT_PLAN_MODE, TR_AGENDA_FILE_NAME_SUBFIX } from '../../../../utils/constants';
import DevelopmentPlan from '../DevelopmentPlan/DevelopmentPlan';
import SuccessionPlanning from '../SuccessionPlanningSection/SuccessionPlanning';
import TalentReviewAndInfoSP from '../TalentReviewAndInfoSP/TalentReviewAndInfoSP';
import TalentReviewMobilityPlan from '../TalentReviewMobilityPlan/TalentReviewMobilityPlan';
import styles from './talent-review-agenda-print-preview.module.scss';
import CareerJourneySection from '../CareerJourneySection/CareerJourneySection';
import KeyStrengthAndDevelopmentAreas from '../KeyStrengthAndDevelopmentAreas/KeyStrengthAndDevelopmentAreas';

const TalentReviewAgendaPrintPreview = (props) => {
  const {
    addTalent,
    setAddTalent,
    agendaDetail,
    setShowSaveTalentStatusEdited,
    staffTalentReview,
    handleShowMessage,
    setStaffTalentReview,
    isPrinting,
    onlyViewTalentAgenda,
    isCreateOrEditRemarks,
    remarksOfConcludedAgenda,
  } = props;
  const mode = DEVELOPMENT_PLAN_MODE.CREATE;
  const printRef = React.createRef();
  const staffNameStr = `${
    staffTalentReview.birth_name
      ? staffTalentReview.birth_name.trim().replace(/[^a-zA-Z0-9]/g, '_')
      : ''
  }`;
  let fileName = `${staffTalentReview.staff_id}`;
  if (staffNameStr.length > 0) {
    fileName = fileName + `_${staffNameStr}`;
  } else {
    fileName = fileName + TR_AGENDA_FILE_NAME_SUBFIX;
  }

  return (
    <Modal
      onHide={() => props?.closeModal()}
      dialogClassName={styles.containerModal}
      show={isPrinting}
      style={{ overflowX: 'auto' }}
      centered
    >
      <Modal.Body>
        <div ref={printRef} className={styles.containerPrint}>
          <TalentReviewAndInfoSP
            setAddTalent={setAddTalent}
            addTalent={addTalent}
            setShowSaveTalentStatusEdited={setShowSaveTalentStatusEdited}
            data={staffTalentReview}
            setData={setStaffTalentReview}
            agendaDetail={agendaDetail}
            handleShowMessage={handleShowMessage}
            isCreateOrEditRemarks={isCreateOrEditRemarks}
            staffTalentReview={staffTalentReview}
            onlyViewTalentAgenda={onlyViewTalentAgenda}
            isPrinting={isPrinting}
          />
          <CareerJourneySection addTalent={addTalent} isPrinting={isPrinting} />
          <KeyStrengthAndDevelopmentAreas
            agendaDetail={agendaDetail}
            staffTalentReview={staffTalentReview}
            onlyViewTalentAgenda={onlyViewTalentAgenda}
            handleShowMessage={handleShowMessage}
            isPrinting={isPrinting}
            remarksOfConcludedAgenda={remarksOfConcludedAgenda}
          />
          {!isEmpty(addTalent) && (
            <SuccessionPlanning
              agendaDetail={agendaDetail}
              addTalent={addTalent}
              setAddTalent={setAddTalent}
              setShowSaveTalentStatusEdited={setShowSaveTalentStatusEdited}
              staffTalentReview={staffTalentReview}
              handleShowMessage={handleShowMessage}
              onlyViewTalentAgenda={onlyViewTalentAgenda}
              isPrinting={isPrinting}
            />
          )}
          <TalentReviewMobilityPlan
            setAddTalent={setAddTalent}
            addTalent={addTalent}
            staffId={staffTalentReview}
            agendaDetail={agendaDetail}
            onlyViewTalentAgenda={onlyViewTalentAgenda}
            isPrinting={isPrinting}
          />
          <DevelopmentPlan
            mode={mode}
            addTalent={addTalent}
            setAddTalent={setAddTalent}
            agendaDetail={agendaDetail}
            onlyViewTalentAgenda={onlyViewTalentAgenda}
            isPrinting={isPrinting}
          />
        </div>
        <ReactToPrint
          trigger={() => (
            <Row justify="start" align="middle" styles={{ width: '100%' }}>
              <Col>
                <Button className={`mt-3 ${styles.btnPrint}`}>Print</Button>
              </Col>
            </Row>
          )}
          content={() => printRef.current}
          removeAfterPrint
          documentTitle={fileName}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TalentReviewAgendaPrintPreview;
