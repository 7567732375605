import { Spin } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BasicButton } from '../../assets/common';
import { user_guide_image, user_guide_text } from '../../assets/img';
import { BreadCrumb } from '../../components';
import GlobalAlertMessage from '../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useShowAlertUploadDownloadWhenNavigate } from '../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../hooks/useShowAlertWhenRefresh';
import { adminApi } from '../../services/admin';
import { pushMessage } from '../../store/alertMessageSlice';
import { HELP_DOCUMENT, MESSAGE_TYPES } from '../../utils/constants';
import { getBreadCrumbList } from '../../utils/helper';
import styles from './help-document.module.scss';

const HelpDocument = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  useEffect(() => {
    (async function getDocuments() {
      setLoading(true);
      try {
        const res = await adminApi.getHelpDocument();
        const documents = get(res, 'data.result') || [];
        setDocuments(documents);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    })();
  }, []);

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  const downloadFile = async (url, fileName) => {
    if (!url) {
      handleShowMessage(MESSAGE_TYPES.ERROR, 'File not found !');
      return;
    }
    setLoading(true);
    setIsUploadingOrDownloading(true);
    setShowAlertRefresh(true);
    fetch(url)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = href;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setIsUploadingOrDownloading(false);
        setShowAlertRefresh(false);
      });
  };

  return (
    <Spin spinning={loading} size="small">
      <div className={styles.max_width} data-testid="talent-finder">
        <div className={styles.control}>
          <BreadCrumb
            level={2}
            breadCrumbList={getBreadCrumbList([`Dashboard`, `Help Document`])}
          />
          <div style={{ marginBottom: '20px' }}></div>
          <GlobalAlertMessage />
          <h3 className={styles.title}>Help Document</h3>
          <div className="d-flex" style={{ gap: '20px' }}>
            <div className={styles.container}>
              <img src={user_guide_image} alt={user_guide_image} className={styles.image} />
              <img src={user_guide_text} alt={user_guide_text} className={styles.image} />
              <div className="d-flex justify-content-center mt-3">
                <BasicButton
                  mode="teal"
                  style={{ marginRight: '10px', width: '100px' }}
                  onClick={() =>
                    downloadFile(
                      documents.find((item) => item.file_name === HELP_DOCUMENT.GUIDE_DOCUMENT_USER)
                        ?.path,
                      documents.find((item) => item.file_name === HELP_DOCUMENT.GUIDE_DOCUMENT_USER)
                        ?.file_name
                    )
                  }
                >
                  For User
                </BasicButton>
                <BasicButton
                  mode="border-teal"
                  style={{ width: '100px' }}
                  onClick={() =>
                    downloadFile(
                      documents.find(
                        (item) => item.file_name === HELP_DOCUMENT.GUIDE_DOCUMENT_ADMIN
                      )?.path,
                      documents.find(
                        (item) => item.file_name === HELP_DOCUMENT.GUIDE_DOCUMENT_ADMIN
                      )?.file_name
                    )
                  }
                >
                  For Admin
                </BasicButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default HelpDocument;
