import { Empty, Select } from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, { useMemo, useRef } from 'react';

import { BasicAutoComplete } from '../../assets/common';
import { Rectangle } from '../../assets/img';
import { useComponentVisible } from '../../hooks';
import styles from './search-job-function.module.scss';

const { Option } = Select;

const SearchJobFunctions = (props) => {
  const {
    item,
    searchParams,
    setSearchParams,
    functions,
    setValueEdit,
    setSelectedValue,
    selectedValue,
  } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const selectRef = useRef(null);

  const renderSelect = useMemo(() => {
    return {
      options: (
        <>
          <Option disabled value={-1}>
            <div className={styles.function_header}>
              <div>Job Family</div>
              <div>Sub Job Family</div>
              <div>Function</div>
            </div>
          </Option>
          {isEmpty(functions) && (
            <Option disabled value={0}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Option>
          )}
          {!isEmpty(functions) &&
            functions.map((item, index) => (
              <Option key={item?.id} value={item.name} option={item}>
                <div className={styles.function_rows}>
                  <div>{item.job_family}</div>
                  <div>{item.sub_job_family}</div>
                  <div>{item.name}</div>
                </div>
              </Option>
            ))}
        </>
      ),
      class: styles.function_select,
      placeholder: 'Search by Function, Job Family & Sub-Job Family',
      label: 'Edit Function',
    };
  }, [functions]);

  const handleScroll = (event) => {
    if (searchParams.isAll || searchParams.fetching) return;
    const {
      target: { scrollTop, offsetHeight, scrollHeight },
    } = event;
    const isSearch = scrollTop + offsetHeight >= scrollHeight - 1;

    if (isSearch) {
      setSearchParams((prev) => ({ ...prev, page: prev.page + 1, fetching: true }));
    }
  };

  const handleSearch = debounce((value) => {
    if (selectRef.current) {
      selectRef.current.scrollTo(0);
    }
    setSearchParams((prev) => ({ ...prev, search: value, page: 1, searching: true }));
    setValueEdit(null);
  }, 500);

  const handleSave = (value) => {
    if (value) {
      setValueEdit({ job_function: value.option, record: item });
      setSearchParams((prev) => ({ ...prev, search: '', page: 1, searching: true }));
      setIsComponentVisible(!isComponentVisible);
      setSelectedValue('');
    }
  };

  const handleCancel = () => {
    setValueEdit({});
    setSearchParams((prev) => ({ ...prev, search: '', page: 1, searching: true }));
    setIsComponentVisible(!isComponentVisible);
    setSelectedValue('');
  };

  const handleSelectOption = (value, option) => {
    setSelectedValue(option);
  };

  return (
    <div className={styles.menu}>
      <div ref={ref} className={styles.searchWrapper}>
        <div
          className={styles.dropdownIcon}
          onKeyDown={() => {}}
          onClick={() => {
            setIsComponentVisible(!isComponentVisible);
            setValueEdit(null);
          }}
        >
          <img src={Rectangle} alt="JobFunction" style={{ cursor: 'pointer' }} />
        </div>
        {isComponentVisible && (
          <div className={styles.modal_container}>
            <div className={renderSelect.class}>
              <div className={styles.label}>{renderSelect.label}</div>
              <BasicAutoComplete
                ref={selectRef}
                getPopupContainer={(trigger) => trigger.parentElement}
                virtual={false}
                loading={searchParams.fetching || searchParams.searching}
                showSearch
                filterOption={false}
                placeholder={renderSelect.placeholder}
                onPopupScroll={handleScroll}
                onSearch={handleSearch}
                onSelect={(value, option) => handleSelectOption(value, option)}
                value={selectedValue || undefined}
              >
                {renderSelect.options}
              </BasicAutoComplete>
              <div className={styles.buttonContainer}>
                <button className={styles.cancelButton} onKeyDown={() => {}} onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  onKeyDown={() => {}}
                  onClick={() => handleSave(selectedValue)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchJobFunctions;
