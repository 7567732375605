import styled from 'styled-components';
import { Alert, Spin } from 'antd';
import { color, font } from '../../assets/common';

export const AiAlert = styled(Alert)`
  height: 100px;
`;
export const AiSpin = styled(Spin)`
  .ant-spin-text {
    color: ${color.loading};
    font-family: ${font.inter};
    font-size: 12px;
  }
  .ant-spin-dot-item {
    background-color: ${color.teal};
  }
`;

export const Wrapper = styled.div`
  .ant-picker-dropdown {
    top: 44px !important;
  }
`;

export const AiMenu = styled.div`
  .ant-menu-horizontal {
    line-height: unset;
    border: 0;
    border-bottom: none;
  }
  .ant-menu {
    color: #8896a7;
  }
`;

export const TitleDropdown = styled.div`
  color: #344563;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
