import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { avatar } from '../../../../../assets/img';
import { INIT_POSITION_VALUES } from '../../../../../utils/constants';
import {
  decodeBase64ToImage,
  getRetirementContractEndLabel,
  handleShowCT,
  handleShowInformation,
  handleShowRetirementDate,
  handleShowRetirementToView,
  handleShowYIPAndYISG,
} from '../../../../../utils/helper';
import BoxPrintSP from '../../../../Agenda/components/BoxDSP/BoxPrintSP';
import PrintApprovedSp from '../../../../Agenda/components/PrintApprovedSP/PrintApprovedSp';
import styles from './view-sucession-plan-popup.module.scss';
import { blockStyleForSP } from './ViewSucessionPlanPopup';

const ROW_STYLES = {
  row_item: { alignContent: 'flex-start', width: '960px' },
  row_only_one_item: { width: '480px', alignContent: 'start' },
  row_first_item: { width: '480px', alignContent: 'start', paddingRight: '30px' },
};

const PrintSucessionPlanPopup = (props) => {
  const { isShowSuccessionPlanPopup, setIsShowSuccessionPlanPopup, positions, isDisabledPrintBtn } =
    props;

  const agendaIncumbent = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const [line1, setLine1] = useState([]);
  const [line2, setLine2] = useState([]);
  const [line3, setLine3] = useState([]);
  const [information, setInformation] = useState({
    ...INIT_POSITION_VALUES,
  });
  const printRef = React.createRef();
  const customFileName = useMemo(() => {
    const lastPosition = !isEmpty(agendaIncumbent?.positions)
      ? agendaIncumbent?.positions[agendaIncumbent.positions.length - 1]
      : null;
    return lastPosition
      ? `SP_${lastPosition?.position_name}_${
          lastPosition?.last_approved_date
            ? moment(lastPosition.last_approved_date).format('YYYYMMDD')
            : ''
        }`
      : `View_Succession_Plan`;
  }, [agendaIncumbent]);

  useEffect(() => {
    setLine1(agendaIncumbent.first_line);
    setLine2(agendaIncumbent.second_line);
    setLine3(agendaIncumbent.third_line);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(agendaIncumbent.first_line),
    JSON.stringify(agendaIncumbent.second_line),
    JSON.stringify(agendaIncumbent.third_line),
  ]);

  const handleDataInformationStaff = useCallback(() => {
    setInformation({
      ...INIT_POSITION_VALUES,
    });

    positions.map((item) => {
      if (!isEmpty(item?.incumbent)) {
        setInformation((preState) => {
          return {
            ...preState,
            name: [
              ...preState.name,
              `${item.incumbent?.name ?? 'None '} ${
                item.incumbent?.ct ? handleShowCT(item.incumbent?.ct) : ''
              }`,
            ],
            age: [...preState.age, item.incumbent.age],
            retirementOrContractEndDate: [
              ...preState.retirementOrContractEndDate,
              `${handleShowRetirementDate(
                item.incumbent?.retirement_date || item.incumbent?.contract_end_date
              )} (${
                item.incumbent?.retirement_date || item.incumbent?.contract_end_date
                  ? handleShowRetirementToView(item.incumbent)
                  : 'None'
              })`,
            ],
            proposedSuccessionPlanning: [
              ...preState.proposedSuccessionPlanning,
              `${item.incumbent?.position_name} (Position ID: ${item?.position_code})`,
            ],
            roleAndJG: [
              ...preState.roleAndJG,
              `${item.incumbent?.role_level} ${
                item.incumbent?.jg_new ? `(${item.incumbent?.jg_new})` : ''
              }`,
            ],
            yearsInPositionAndSince: [
              ...preState.yearsInPositionAndSince,
              `${handleShowYIPAndYISG(
                item.incumbent?.years_in_position,
                item.incumbent?.date_in_position
              )} ${
                item.incumbent?.date_in_position
                  ? `(since ${moment(new Date(item.incumbent?.date_in_position)).format(
                      'DD/MM/YYYY'
                    )})`
                  : ''
              }`,
            ],
            incumbents: !isEmpty(item?.incumbent)
              ? [...preState.incumbents, item.incumbent]
              : preState.incumbents,
          };
        });
      }
      return null;
    });
  }, [positions]);

  useEffect(() => {
    handleDataInformationStaff();
  }, [positions, handleDataInformationStaff]);

  return (
    <div>
      <Modal
        show={isShowSuccessionPlanPopup}
        onHide={() => setIsShowSuccessionPlanPopup(false)}
        dialogClassName={styles.viewSusccessionPlanPopup}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div
          className={styles.viewSusccessionPlanContent}
          style={{ marginBottom: 0, paddingBottom: 0 }}
          ref={printRef}
        >
          <Modal.Header
            className={styles.modalHeader}
            closeButton={true}
            style={{ border: `none` }}
          >
            <div className={styles.inforWrapper}>
              <h2 className={`${styles.mainTitle} mainTitleHeader`}>
                Proposed Succession Planning -{' '}
                {handleShowInformation(information.proposedSuccessionPlanning)}
              </h2>
              <table className={styles.wrapperInfo} role="presentation" aria-hidden="true">
                <tbody>
                  <tr>
                    <td style={{ paddingRight: `20px` }}>
                      <div className={styles.mainAvatar} style={{ marginRight: 0 }}>
                        <img
                          className={`mainAvatar`}
                          src={
                            !isEmpty(positions)
                              ? decodeBase64ToImage(positions[0]?.incumbent?.image)
                              : avatar
                          }
                          alt="avatar"
                        />
                      </div>
                    </td>
                    <td className={`itemInfoPosition`}>
                      <tr className={styles.rowItem} style={ROW_STYLES.row_item}>
                        <td className={styles.fontMinimal} style={ROW_STYLES.row_first_item}>
                          Name: {handleShowInformation(information.name)}
                        </td>
                        <td
                          className={styles.fontMinimal}
                          style={{ width: '480px', alignContent: 'start' }}
                        >
                          Role: {handleShowInformation(information.roleAndJG)}
                        </td>
                      </tr>
                      <tr className={styles.rowItem} style={ROW_STYLES.row_item}>
                        <td className={styles.fontMinimal} style={ROW_STYLES.row_first_item}>
                          Age: {handleShowInformation(information.age)}
                        </td>
                        <td
                          className={styles.fontMinimal}
                          style={{ width: '480px', alignContent: 'start' }}
                        >
                          {getRetirementContractEndLabel(information.incumbents)}{' '}
                          {handleShowInformation(information.retirementOrContractEndDate)}
                        </td>
                      </tr>
                      <tr className={styles.rowItem} style={ROW_STYLES.row_item}>
                        <td className={styles.fontMinimal} style={ROW_STYLES.row_only_one_item}>
                          Years In Position:{' '}
                          {handleShowInformation(information.yearsInPositionAndSince)}
                        </td>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Header>

          <Modal.Body className={styles.modalBody} style={{ marginBottom: 0, paddingBottom: 0 }}>
            <div className="d-flex justify-content-between align-items-start no-gutters">
              <div className={`col-12`}>
                <BoxPrintSP
                  text={'1st Line'}
                  line={line1}
                  blockStyle={blockStyleForSP.first_line}
                />
                <BoxPrintSP
                  text={'2nd Line'}
                  line={line2}
                  blockStyle={blockStyleForSP.second_line}
                />
                <BoxPrintSP
                  text={'3rd Line'}
                  line={line3}
                  blockStyle={blockStyleForSP.third_line}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
        <div className={styles.wrapperButton}>
          <PrintApprovedSp
            componentRef={printRef}
            positionName={''}
            dateApproved={''}
            customFileName={customFileName}
            style={{ backgroundColor: isDisabledPrintBtn ? '#6AC2BE' : '#00a19c', color: 'white' }}
            isDisabled={isDisabledPrintBtn}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PrintSucessionPlanPopup;
