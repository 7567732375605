import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { avatar, deleteNote, warning, warningClose } from '../../assets/img';
import { getTalentDetails } from '../../services/talentProfiles';
import {
  decodeBase64ToImage,
  formatDate,
  getPermissionsByRole,
  handleShowYIPAndYISG,
} from '../../utils/helper';
import Spinner from '../Loading/Spinner';
import SearchTalentComparison from '../SearchTalentComparison/SearchTalentComparison';
import SwitchMode from './components/SwitchMode';
import styles from './talent-comparison.module.scss';

const Item = (props) => {
  const { item, onDeleteProfile, user } = props;
  const roleActive = useSelector((state) => state.user.roleActive);

  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (!roleActive.roleId) return;
  }, [roleActive.roleId]);

  const handleCheckHiddenTalentComparison = () => {
    let isVisible = getPermissionsByRole({
      part: 'TP_HIDE_UNHIDE_TALENT_PROFILE',
      permissions: user.permissions,
    });
    return Boolean(isVisible);
  };

  const handleShowSwitch = () => {
    let isVisible = handleCheckHiddenTalentComparison();
    if (isVisible) return { opacity: 1 };
    return { opacity: 0, visibility: 'hidden' };
  };

  return (
    <div className="col-4">
      <div className={styles.content_profile_talent} data-testid="talent-profile">
        <div
          data-testid="option-talent"
          className="mb-3 d-flex align-items-center justify-content-between"
        >
          <div style={handleShowSwitch()} data-testid="click-hidden">
            {<SwitchMode isHide={isHide} setIsHide={setIsHide} id={item.id} />}
          </div>

          <img
            src={deleteNote}
            className={styles.deleteIcon}
            onKeyDown={() => {}}
            onClick={() => onDeleteProfile(item)}
            alt="delete"
            data-testid="click-delete-talent"
          />
        </div>

        {!isHide && (
          <div className="mb-3" data-testid="view-talent-info">
            <div className="mb-3 text-center">
              <img
                src={decodeBase64ToImage(item?.info?.image) || avatar}
                alt="avatar_profile"
                width="137"
                height="137"
                style={{ borderRadius: '100px' }}
              />
            </div>

            <div className="mb-3" style={{ padding: '15px 16px' }}>
              <div
                data-testid="talent-name"
                className={`mb-2 text-center ${styles.text_profile_name}`}
              >
                {item?.info?.birth_name}
              </div>
              <div
                data-testid="talent-position"
                className={`mb-2 text-center ${styles.text_profile_unit}`}
              >
                {item?.info?.position_name}
              </div>
            </div>
          </div>
        )}

        <div className="mb-3">
          <div className={`mb-3 ${styles.section_text_profile_talent}`}>Talent Details</div>

          <div className="d-flex" style={{ flexWrap: 'wrap' }}>
            <div className="col-6">
              <div className={`mb-2 ${styles.text_details_left}`}>Years in Position</div>
            </div>

            <div className="col-6">
              <div data-testid="date-position" className={`mb-2 ${styles.text_details_right}`}>
                {handleShowYIPAndYISG(
                  item?.details?.years_in_position,
                  item?.details?.date_in_position
                )}
              </div>
            </div>

            <div className="col-6">
              <div className={`mb-2 ${styles.text_details_left}`}>Age</div>
            </div>

            <div className="col-6">
              <div data-testid="age" className={`mb-2 ${styles.text_details_right}`}>
                {item?.info?.age}
              </div>
            </div>

            <div className="col-6">
              <div className={`mb-2 ${styles.text_details_left}`}>Retirement Date</div>
            </div>

            <div className="col-6">
              <div data-testid="retirement-date" className={`mb-2 ${styles.text_details_right}`}>
                {formatDate(item?.details?.retirement_date)}
              </div>
            </div>

            <div className="col-6">
              <div className={`mb-2 ${styles.text_details_left}`}>Years in SG</div>
            </div>

            <div className="col-6">
              <div data-testid="date-sg" className={`mb-2 ${styles.text_details_right}`}>
                {handleShowYIPAndYISG(item?.details?.years_in_sg, item?.details?.date_in_sg)}
              </div>
            </div>
          </div>
        </div>

        <div data-testid="experience" style={{ marginBottom: '30px' }}>
          <div className={styles.section_text_profile_talent} style={{ marginBottom: '30px' }}>
            Experiences
          </div>

          {item?.experiences?.function &&
          item?.experiences?.business &&
          item?.experiences?.function?.length > 0 &&
          item?.experiences?.business?.length > 0 ? (
            <div className="d-flex" style={{ flexWrap: 'wrap', gap: '15px' }}>
              {item?.experiences?.function?.map((functionDev, index) => (
                <span
                  data-testid="function"
                  className={`py-2 px-3 ${styles.text_active}`}
                  key={index}
                >
                  {functionDev}
                </span>
              ))}

              {item?.experiences?.business?.map((business, index) => (
                <span
                  data-testid="business"
                  className={`py-2 px-3 ${styles.text_no_active}`}
                  key={index}
                >
                  {business}
                </span>
              ))}
            </div>
          ) : (
            <div className={styles.text_no_data}>No Experiences</div>
          )}
        </div>

        <div data-testid="key-strength" style={{ marginBottom: '30px' }}>
          <div className={styles.section_text_profile_talent} style={{ marginBottom: '30px' }}>
            Key Strength
          </div>

          {item?.keystrength && item?.keystrength.length > 0 ? (
            <div className="d-flex" style={{ flexWrap: 'wrap', gap: '15px' }}>
              {item?.keystrength?.map((keyStrength, index) => (
                <span data-testid="key" className={`py-2 px-3 ${styles.text_active}`} key={index}>
                  {keyStrength}
                </span>
              ))}
            </div>
          ) : (
            <div className={styles.text_no_data}>No Key Strength</div>
          )}
        </div>

        <div data-testid="development-area" style={{ marginBottom: '30px' }}>
          <div className={styles.section_text_profile_talent} style={{ marginBottom: '30px' }}>
            Development Areas
          </div>

          {item?.dev_area && item?.dev_area.length > 0 ? (
            <div className="d-flex" style={{ flexWrap: 'wrap', gap: '15px' }}>
              {item?.dev_area?.map((devArea, index) => (
                <span
                  data-testid="devArea"
                  className={`py-2 px-3 ${styles.text_active}`}
                  key={index}
                >
                  {devArea}
                </span>
              ))}
            </div>
          ) : (
            <div className={styles.text_no_data}>No Development Area</div>
          )}
        </div>

        <div data-testid="personal-archetype" style={{ marginBottom: '30px' }}>
          <div className={styles.section_text_profile_talent} style={{ marginBottom: '30px' }}>
            Personality Archetypes
          </div>

          {item?.personality_archetypes && item?.personality_archetypes.length > 0 ? (
            <div className="d-flex" style={{ flexWrap: 'wrap', gap: '15px' }}>
              {item?.personality_archetypes?.map((personal, index) => (
                <span
                  data-testid="personal"
                  className={`py-2 px-3 ${styles.text_active}`}
                  key={index}
                >
                  {personal}
                </span>
              ))}
            </div>
          ) : (
            <div className={styles.text_no_data}>No Personality Archetypes</div>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <div
            className={styles.btn_open_profile}
            onKeyDown={() => {}}
            onClick={() => {
              window.open(`/profile/${item?.id}`, '_blank');
            }}
            data-testid="view-new-tab"
          >
            Open Talent Profile
          </div>
        </div>
      </div>
    </div>
  );
};

const TalentComparison = (props) => {
  const { talentProfileDetails, isPopupTalentComparison, setIsPopupTalentComparison } = props;
  const user = useSelector((state) => state.user);
  const [talentList, setTalentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [textWarning, setTextWarning] = useState('');

  const addTalent = async (data) => {
    if (!!talentList && talentList.length === 3) {
      setIsWarning(true);
      setTextWarning('Maximum 3 Talents only for Comparison');
    } else {
      const talents = [...talentList];
      const listIdTalent = talents.map((talent) => talent.id);
      if (listIdTalent.includes(data?.staffId)) {
        setIsWarning(true);
        setTextWarning('Talent has already been added');
        return;
      }
      try {
        setLoading(true);
        const response = await getTalentDetails({ staffId: data?.staffId, roleUser: user });
        if (response && response?.data?.result) {
          const talentDetails = {
            ...response?.data?.result,
            id: data?.staffId,
          };
          talentList.push(talentDetails);
          setTalentList([...talentList]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const onDeleteProfile = (item) => {
    const index = talentList.findIndex((talent) => talent.id === item.id);
    if (index > -1) {
      const deletedItem = talentList.splice(index, 1);
      const listTalent = [...talentList];
      const result = listTalent.filter((talent) => talent !== deletedItem);
      setTalentList(result);
    }
  };

  useEffect(() => {
    if (!talentProfileDetails.id) return;
    setTalentList([talentProfileDetails]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentProfileDetails.id]);

  return (
    <div>
      <Modal
        show={isPopupTalentComparison}
        onHide={() => setIsPopupTalentComparison(false)}
        dialogClassName={styles.view_talent_comparison}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
        data-testid="modal-talent-comparison"
      >
        <div style={{ padding: '43px 67px 30px 42px', minHeight: '300px' }}>
          <Modal.Header className={styles.modal_header} closeButton>
            <h1 data-testid="view-talent-comparison-title">Talent Comparison</h1>
          </Modal.Header>

          {!!isWarning && (
            <div
              data-testid="view-warning"
              className="d-flex align-items-center justify-content-between"
              style={{ margin: '0px 16px 16px', background: '#FFF7E5', padding: '8px' }}
            >
              <div className="d-flex align-items-center">
                <img src={warning} alt="" width="16" height="16" style={{ margin: '5px 10px' }} />
                <div className={styles.text_warning}>{textWarning}</div>
              </div>

              <div
                onKeyDown={() => {}}
                onClick={() => setIsWarning(false)}
                style={{ cursor: 'pointer' }}
              >
                <img src={warningClose} alt="" width="14" />
              </div>
            </div>
          )}

          <div style={{ padding: '0 16px 16px' }}>
            <div className={styles.title_search} data-testid="view-title-search">
              Search Talents
            </div>
            <div>
              <div className="d-flex">
                <div className="col-12 p-0">
                  <SearchTalentComparison placeholder="Placeholder" addTalent={addTalent} />
                </div>
              </div>
            </div>
          </div>

          <Modal.Body className={styles.modal_body}>
            <div style={{ marginBottom: 25 }}>
              <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                {talentList?.length > 0 &&
                  talentList?.map((talent, index) => (
                    <Item key={index} item={talent} onDeleteProfile={onDeleteProfile} user={user} />
                  ))}

                {loading && talentList.length < 4 && (
                  <div className="col-4">
                    <div
                      className={
                        talentList.length > 0
                          ? styles.content_profile_talent_loading
                          : styles.content_profile_talent_loading__2__
                      }
                    >
                      <Spinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default TalentComparison;
