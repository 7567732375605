import { Divider, Spin } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AiButton, BasicPopover, BasicSelect, BasicTable } from '../../assets/common';
import { close_modal_2, edit_report_name, Rectangle, right_open_arrow } from '../../assets/img';
import talentProfileApi, { fetchFunctionExperiences } from '../../services/talentProfiles';
import { INIT_PARAMS, PERMISSION } from '../../utils/constants';
import { paginationConfig } from '../../utils/helper';
import CommonLoading from '../CommonLoading/CommonLoading';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import { ModelTC } from '../Model';
import SearchJobFunctions from '../SearchJobFunction/SearchJobFunction';
import styles from './career-aspiration.module.scss';
import CareerAspirationModalTable from './CareerAspirationModalTable';

const Wrapper = styled.div`
  .ant-table-container table tbody td:first-child {
    border-bottom-left-radius: 0px !important;
  }
  .ant-table-container .ant-table-tbody td:last-child {
    border-bottom-right-radius: 0px !important;
  }
`;
const modalStyle = {
  borderRadius: 8,
  closePosition: [32, 26],
  containerPaddingLeftRight: 32,
  containerPaddingTopBottom: 26,
  headerPaddingBottom: 12,
  headerBorderBottom: 'none',
  titleFontSize: 20,
  titleFontWeight: 700,
  titleColor: '#3F3C4C',
};
const tableStyle = {
  borderRadius: 8,
  headerWeight: 500,
  headerColor: '#3F3C4C',
};
const popoverStyle = {
  w: 630,
};
const tablePositionStyle = {
  headerWeight: 500,
  headerBackground: '#F2F4F8',
  headerColor: '#181818',
};
const LIMIT_ROWS = 10;
const optionHide = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

const ListPositionTable = React.memo(({ data, setViewPositionsIndex }) => {
  const columns = [
    {
      title: 'Position Code',
      width: '30%',
      dataIndex: 'position_code',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
    {
      title: 'Position Name',
      width: '70%',
      dataIndex: 'position_name',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
  ];

  return (
    <div className={styles.positionTable}>
      <img
        src={close_modal_2}
        alt="close_view_list_position"
        className={styles.close}
        onKeyDown={() => {}}
        onClick={() => setViewPositionsIndex(-1)}
      />
      <Wrapper>
        <BasicTable
          rowKey={(record) => `${record.position_code}${record.position_name}`}
          dataSource={data}
          columns={columns}
          pagination={false}
          styles={tablePositionStyle}
          scroll={{ y: 275 }}
        />
      </Wrapper>
    </div>
  );
});

ListPositionTable.propTypes = {
  data: PropTypes.array,
  setViewPositionsIndex: PropTypes.func,
};

const ModalTable = ({ openModal, setOpenModal, isEdit, getTalentProfileSummary, roleUser }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [aspirationMatching, setAspirationMatching] = useState({ total: 0, resAspMatching: [] });
  const [cloneAspirationMatching, setcloneAspirationMatching] = useState({
    total: 0,
    resAspMatching: [],
  });
  const [page, setPage] = useState(1);
  const [viewPositionsIndex, setViewPositionsIndex] = useState(-1);

  //Edit state
  const [functionParams, setFunctionParams] = useState(INIT_PARAMS);
  const [valueEdit, setValueEdit] = useState(null);
  const [functions, setFunctions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const isDisabledBtn = useMemo(() => {
    return (
      JSON.stringify(cloneAspirationMatching.resAspMatching) ===
      JSON.stringify(aspirationMatching.resAspMatching)
    );
  }, [aspirationMatching.resAspMatching, cloneAspirationMatching.resAspMatching]);

  useEffect(() => {
    if (!isEmpty(valueEdit)) {
      setAspirationMatching((prevState) => {
        return {
          ...prevState,
          resAspMatching: prevState.resAspMatching.map((item) => {
            if (item.no === valueEdit.record.no) {
              return {
                ...item,
                job_function: valueEdit.job_function.name,
              };
            } else {
              return item;
            }
          }),
        };
      });

      setDataToUpdate((prevState) => {
        const isCheck = prevState.filter((item) => item.no === valueEdit.record.no).length > 0;
        if (isCheck) {
          return prevState.map((item) => {
            if (item.no === valueEdit.record.no) {
              return {
                job_function: valueEdit.job_function,
                list_of_position:
                  !isEmpty(valueEdit?.record?.list_of_position) &&
                  valueEdit.record.list_of_position.map((item) => ({
                    id: item.id,
                    position_code: item.position_code,
                    position_name: item.position_name,
                  })),
                is_hide: valueEdit.record.is_hide || false,
              };
            } else {
              return item;
            }
          });
        } else {
          return [
            ...prevState,
            {
              no: valueEdit.record.no,
              job_function: valueEdit.job_function,
              list_of_position:
                !isEmpty(valueEdit?.record?.list_of_position) &&
                valueEdit.record.list_of_position.map((item) => ({
                  id: item.id,
                  position_code: item.position_code,
                  position_name: item.position_name,
                })),
              is_hide: valueEdit.record.is_hide || false,
            },
          ];
        }
      });
    }
  }, [valueEdit]);

  const fetchFunctionList = useCallback(async () => {
    const { search, page, fetching, searching } = functionParams;
    if (!roleUser?.roleActive?.roleId || !roleUser?.permissions || (!fetching && !searching))
      return;
    try {
      let res = await fetchFunctionExperiences({
        roleId: roleUser.roleActive.roleId,
        permissions: roleUser.permissions,
        search,
        page,
        limit: INIT_PARAMS.limit,
      });
      if (res.status !== 200) throw Error();
      const result = res.data.result;
      const isAll = result.length < INIT_PARAMS.limit;

      if (fetching) {
        setFunctions((prev) => [...prev, ...res.data.result]);
        setFunctionParams((prev) => ({ ...prev, fetching: false, isAll }));
      }

      if (searching) {
        setFunctions(result);
        setFunctionParams((prev) => ({ ...prev, searching: false, isAll }));
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser?.permissions?.length, roleUser?.roleActive?.roleId, functionParams]);

  const isHasEditPermission = useMemo(() => {
    if (isEmpty(roleUser?.permissions)) return false;
    return roleUser.permissions.includes(PERMISSION.TP_EDIT_EXPERIENCE);
  }, [roleUser]);

  useEffect(() => {
    if (!isHasEditPermission) return;
    fetchFunctionList();
  }, [isHasEditPermission, fetchFunctionList]);

  const handleChangeHideOption = (value, record) => {
    setAspirationMatching((prevState) => {
      return {
        ...prevState,
        resAspMatching: prevState.resAspMatching.map((item) => {
          if (item.no === record.no) {
            return {
              ...item,
              is_hide: value || false,
            };
          } else {
            return item;
          }
        }),
      };
    });

    setDataToUpdate((prevState) => {
      const isCheck = prevState.filter((item) => item.no === record.no).length > 0;
      if (isCheck) {
        return prevState.map((item) => {
          if (item.no === record.no) {
            return {
              ...item,
              is_hide: value || false,
            };
          } else {
            return item;
          }
        });
      } else {
        return [
          ...prevState,
          {
            no: record.no,
            job_function: record.job_function,
            list_of_position:
              !isEmpty(record?.list_of_position) &&
              record.list_of_position.map((item) => ({
                id: item.id,
                position_code: item.position_code,
                position_name: item.position_name,
              })),
            is_hide: value || false,
          },
        ];
      }
    });
  };

  const getTableData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { page, limit: LIMIT_ROWS };
      const res = await talentProfileApi.getAspirationMatching(id, params);
      let result = res?.data?.result;
      if (res.status === 200 && result) {
        setAspirationMatching({
          total: result.total,
          resAspMatching: !isEmpty(result?.resAspMatching)
            ? result.resAspMatching.map((item, index) => ({ ...item, no: index }))
            : [],
        });
      }
    } catch (error) {
      console.error(error);
      setAspirationMatching({ total: 0, resAspMatching: [] });
    } finally {
      setLoading(false);
    }
  }, [id, page]);

  const getTableDataToEdit = useCallback(async () => {
    try {
      setLoading(true);
      const params = { page, limit: LIMIT_ROWS };
      const res = await talentProfileApi.getAspirationMatchingToEdit(
        id,
        params,
        roleUser.roleActive
      );
      let result = res?.data?.result;
      if (res.status === 200 && result) {
        setAspirationMatching({
          total: result.total,
          resAspMatching: !isEmpty(result?.resAspMatching)
            ? result.resAspMatching.map((item, index) => ({ ...item, no: index }))
            : [],
        });
        setcloneAspirationMatching({
          total: result.total,
          resAspMatching: !isEmpty(result?.resAspMatching)
            ? result.resAspMatching.map((item, index) => ({ ...item, no: index }))
            : [],
        });
      }
    } catch (error) {
      console.error(error);
      setAspirationMatching({ total: 0, resAspMatching: [] });
      setcloneAspirationMatching({ total: 0, resAspMatching: [] });
    } finally {
      setLoading(false);
    }
  }, [id, page, roleUser.roleActive]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Job Function',
        width: '17%',
        dataIndex: 'job_function',
        render: (text, record) => (
          <>
            {isEdit ? (
              <div className={styles.fieldWrapper}>
                <span className={styles.text}>{text}</span>
                <SearchJobFunctions
                  item={record}
                  rowIndex={0}
                  searchParams={functionParams}
                  setSearchParams={setFunctionParams}
                  functions={functions}
                  setValueEdit={setValueEdit}
                  setSelectedValue={setSelectedValue}
                  selectedValue={selectedValue}
                />
              </div>
            ) : (
              <span className={styles.text}>{text}</span>
            )}
          </>
        ),
      },
      {
        title: '',
        width: '15%',
        dataIndex: 'list_of_position',
        render: (data, _, index) => (
          <BasicPopover
            content={
              <ListPositionTable data={data} setViewPositionsIndex={setViewPositionsIndex} />
            }
            trigger="click"
            placement="bottomLeft"
            getPopupContainer={(trigger) => trigger}
            open={index === viewPositionsIndex}
            onOpenChange={(open) => {
              if (!open) {
                setViewPositionsIndex(-1);
              }
            }}
            styles={popoverStyle}
          >
            <div
              className={`${styles.textListPosition} ${
                isEmpty(data) || (!data[0].position_code && !data[0].position_name)
                  ? styles.disable
                  : ''
              }`}
              onKeyDown={() => {}}
              onClick={() => setViewPositionsIndex(index)}
            >
              View list of position
            </div>
          </BasicPopover>
        ),
      },
      {
        title: 'Source',
        width: '10%',
        dataIndex: 'source',
        render: (text) => <span className={styles.textInfo}>{text}</span>,
      },
      {
        title: 'Confidence Score',
        width: '10%',
        dataIndex: 'confidence_score',
        render: (text) => <span className={styles.textInfo}>{text}</span>,
      },
      {
        title: 'Talent Statement',
        dataIndex: 'talent_statement',
        render: (text) => <span className={styles.textInfo}>{text}</span>,
      },
      {
        title: 'Hide',
        hidden: !isEdit,
        width: '7%',
        dataIndex: 'is_hide',
        render: (text, record) => (
          <div className={styles.fieldWrapper}>
            <BasicSelect
              no_border="true"
              font_color="#3F3C4C"
              suffixIcon={<img src={Rectangle} alt="hidden-option" />}
              options={optionHide}
              getPopupContainer={(trigger) => trigger}
              defaultValue={text ? `Yes` : `No`}
              onChange={(value) => handleChangeHideOption(value, record)}
            />
          </div>
        ),
      },
    ].filter((item) => !item.hidden);
  }, [functionParams, functions, isEdit, selectedValue, viewPositionsIndex]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      if (isEdit && !isDisabledBtn) {
        setOpenCancelModal(true);
      }
      setPage(pageNum);
    };
    return paginationConfig(aspirationMatching.total, page, onChange, LIMIT_ROWS);
  }, [aspirationMatching.total, page, isDisabledBtn, isEdit]);

  useEffect(() => {
    if (!openModal) {
      handleResetData();
      return;
    }

    if (openModal && !isEdit) {
      getTableData();
    } else {
      getTableDataToEdit();
    }
  }, [getTableData, getTableDataToEdit, openModal, isEdit]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await talentProfileApi.updateAspirationMatching(
        id,
        dataToUpdate,
        roleUser?.roleActive
      );
      if (res.status === 200) {
        await getTableDataToEdit();
        await getTalentProfileSummary();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setValueEdit(null);
      setDataToUpdate([]);
      setIsCancel(false);
    }
  };

  const handleConfirmSaveChanges = async () => {
    setOpenCancelModal(false);
    if (isCancel) {
      setOpenModal(false);
    }
    await handleSave();
  };

  const handleExit = async () => {
    setOpenCancelModal(false);
    if (isCancel) {
      setOpenModal(false);
      handleResetData();
    }
  };

  const onCancelModal = () => {
    if (isEmpty(aspirationMatching?.resAspMatching)) {
      setOpenModal(false);
      handleResetData();
    } else if (isEdit && !isDisabledBtn) {
      setOpenCancelModal(true);
      setIsCancel(true);
    } else {
      setOpenModal(false);
      handleResetData();
    }
  };

  const handleResetData = () => {
    setAspirationMatching({ total: 0, resAspMatching: [] });
    setcloneAspirationMatching({ total: 0, resAspMatching: [] });
    setIsCancel(false);
    setPage(1);
  };

  return (
    <CustomAntModal
      hideDefaultBtn
      styles={modalStyle}
      open={openModal}
      setOpen={(value) => {
        setOpenModal(value);
        setPage(1);
      }}
      title={isEdit ? 'Edit Aspiration Matching' : 'View Aspiration Matching'}
      width={1396}
      closeType={1}
      onCancelModal={onCancelModal}
      isEdit={isEdit}
    >
      <Spin spinning={loading}>
        <div className={styles.table}>
          <BasicTable
            rowKey={(record) =>
              `${record.job_function}-${record.source}-${record.confidence_score}`
            }
            styles={tableStyle}
            dataSource={aspirationMatching.resAspMatching}
            columns={columns}
            pagination={pagination}
          />
        </div>

        {isEdit && (
          <div className={styles.controlBar}>
            <AiButton
              style={{ borderColor: '#00a19c', borderRadius: '4px' }}
              onKeyDown={() => {}}
              onClick={onCancelModal}
            >
              Cancel
            </AiButton>
            <AiButton
              mode={'teal'}
              onKeyDown={() => {}}
              onClick={handleSave}
              disabled={isDisabledBtn}
            >
              Save
            </AiButton>
          </div>
        )}
      </Spin>
      <ModelTC
        info={{
          type: 'cancelModalAspirationMatching',
          visible: openCancelModal,
          setVisible: setOpenCancelModal,
          handleSubmit: handleConfirmSaveChanges,
          onClose: handleExit,
        }}
      />
    </CustomAntModal>
  );
};

ModalTable.propTypes = {
  openModal: PropTypes.bool,
  setOpen: PropTypes.func,
  isEdit: PropTypes.bool,
  getTalentProfileSummary: PropTypes.func,
  roleUser: PropTypes.object,
};

const CareerAspiration = ({
  carreerAspiration,
  isFromTalentReview,
  isPrinting,
  getTalentProfileSummary,
  loading,
}) => {
  const user = useSelector((state) => state.user);
  const roleUser = { roleActive: user.roleActive, permissions: user.permissions };
  const { desiredCareerPath, aspirationMatching } = carreerAspiration;
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const editPermission = useMemo(() => {
    return (
      !isEmpty(roleUser?.permissions) &&
      roleUser.permissions.includes(PERMISSION.EDIT_ASPIRATION_MATCHING)
    );
  }, [roleUser]);

  const handleOpenModalView = () => {
    setOpenModal(true);
  };

  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };

  return (
    <div
      className={styles.container}
      style={!isPrinting ? { backgroundColor: '#f4f5f8' } : { backgroundColor: '#ffffff' }}
    >
      {!isPrinting && (
        <h3 className={`${isFromTalentReview ? styles.title : ''}`}>Career Statement </h3>
      )}

      <div>
        {!loading ? (
          !isEmpty(desiredCareerPath) &&
          desiredCareerPath.map((item, index) => (
            <p className={`${styles.item} ${isFromTalentReview ? styles.content : ''}`} key={index}>
              {item.career_statement || item.aspiration || ''}
            </p>
          ))
        ) : (
          <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
            <CommonLoading />
          </div>
        )}
      </div>
      {!isFromTalentReview && (
        <>
          <Divider className={styles.divider} />
          <div className={styles.aspirationMatching}>
            <h3>Aspiration Matching</h3>
            <div className={styles.tags}>
              {!loading ? (
                !isEmpty(aspirationMatching) &&
                aspirationMatching.map((tag, index) => (
                  <div key={index} className={styles.item}>
                    {tag}
                  </div>
                ))
              ) : (
                <div style={{ width: '785.04px' }} className="d-flex justify-content-center">
                  <CommonLoading />
                </div>
              )}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.disclaimer}>
              NOTES: Data derived from the results of AI Talent Aspiration
            </div>
            <div className={styles.openModal}>
              <div className={styles.view} onKeyDown={() => {}} onClick={handleOpenModalView}>
                <span>VIEW ASPIRATION</span>
                <img src={right_open_arrow} alt="open" />
              </div>
              {editPermission && (
                <img
                  src={edit_report_name}
                  alt="edit"
                  className={styles.edit}
                  onKeyDown={() => {}}
                  onClick={handleOpenModalEdit}
                />
              )}
            </div>
          </div>
          <CareerAspirationModalTable
            openModal={openModal}
            setOpenModal={setOpenModal}
            getTalentProfileSummary={getTalentProfileSummary}
            roleUser={roleUser}
          />
          <ModalTable
            openModal={openModalEdit}
            setOpenModal={setOpenModalEdit}
            isEdit={true}
            getTalentProfileSummary={getTalentProfileSummary}
            roleUser={roleUser}
          />
        </>
      )}
    </div>
  );
};

CareerAspiration.propTypes = {
  isFromTalentReview: PropTypes.bool,
  isPrinting: PropTypes.bool,
  getTalentProfileSummary: PropTypes.func,
  loading: PropTypes.bool,
};

export default memo(CareerAspiration);
