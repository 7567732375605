import { useEffect, useState } from 'react';
import { useShowPopupSaveAgenda } from './useShowPopupSaveAgenda';

export const useShowAlertUploadDownloadWhenNavigate = (processType = 'download') => {
  const [isUploadingOrDownloading, setIsUploadingOrDownloading] = useState(false);
  const {
    isShowPopupSaveAgenda: isShowAlert,
    handleKeepNavigate,
    setIsShowPopupSaveAgenda: setIsShowAlert,
  } = useShowPopupSaveAgenda(isUploadingOrDownloading);

  useEffect(() => {
    if (!isShowAlert) return;
    const isKeepGoing = window.confirm(
      `Your ${processType} has not been completed. Do you still want to exit this page?`
    );
    if (isKeepGoing) {
      handleKeepNavigate();
      return;
    }
    setIsShowAlert(false);
  }, [handleKeepNavigate, isShowAlert, processType, setIsShowAlert]);

  return { isUploadingOrDownloading, setIsUploadingOrDownloading };
};
