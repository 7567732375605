import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { utils } from 'xlsx-js-style';

import { ROLES_DASHBOARD } from '../../../constants';
import {
  historicalExportDeclareWorkSheet,
  historicalExportSetMergeAndColWidth,
  historicalExportStyleAllCells,
  historicalExportUnshiftHeader,
  historicalSetColorsAndWriteFile,
} from './CommonExportHistorical';

const ExportRetirementForecast = (props) => {
  const { setExportData, historicalData } = props;

  const convertDataToExport = useCallback(async () => {
    let tempData = [];
    historicalData.forEach((item) => {
      if (!isEmpty(item.value)) {
        ROLES_DASHBOARD.forEach((role, index) => {
          tempData.push({
            month: index === 0 ? item.month : '',
            role: `${role}: `,
            lessthan1year: `${item.value[0][role]}`,
            lessthan3year: `${item.value[1][role]}`,
            lessthan5year: `${item.value[2][role]}`,
          });
        });
      }
    });
    return tempData;
  }, [historicalData]);

  const exportRetirementForecast = useCallback(async () => {
    const fileName = 'Retirement Forecast';

    const arr = [];
    const newData = await convertDataToExport();
    newData.forEach((item) => {
      const rows = [
        item.month,
        item.role,
        item.lessthan1year,
        item.lessthan3year,
        item.lessthan5year,
      ].flat();
      arr.push(rows);
    });

    const length = 5;
    const headers1 = new Array(length).fill('');
    headers1[0] = 'Months (End of months)';
    headers1[1] = 'Retirement Forecast';
    const headers2 = new Array(length).fill('');
    headers2[0] = 'Months (End of months)';
    headers2[1] = 'Role';
    headers2[2] = '< 1 year';
    headers2[3] = '1-3 years';
    headers2[4] = '> 3-5 years';

    historicalExportUnshiftHeader(arr, [headers2, headers1]);

    if (arr.length > 0) {
      let [ws, wb] = historicalExportDeclareWorkSheet(arr);
      utils.book_append_sheet(wb, ws, 'Retirement Forecast');

      const shortHandMerges = [
        [0, 0],
        [1, 0],
        [0, 1],
        [0, length - 1],
      ];
      historicalExportSetMergeAndColWidth(ws, shortHandMerges, length, 10);

      historicalExportStyleAllCells(ws);

      const colorCellMapping = {
        '00a9b1': ['A1', 'B1'],
        '7030a0': ['B2', 'C2', 'D2', 'E2'],
      };

      historicalSetColorsAndWriteFile(ws, wb, fileName, colorCellMapping);
    }
  }, [convertDataToExport]);

  useEffect(() => {
    exportRetirementForecast();
    setExportData(false);
  }, [setExportData, exportRetirementForecast]);
  return <></>;
};

ExportRetirementForecast.propTypes = {
  setExportData: PropTypes.func,
  historicalData: PropTypes.array,
};

export default ExportRetirementForecast;
