import styled from 'styled-components';
import { color, font, fontSize } from '../../../assets/common';

export const AiTitle = styled.p`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.superLarge};
  color: ${color.brightGray2};
  line-height: 38.73px;
  padding-bottom: 20px;
  width: 90%;
`;
export const AiDesc = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: 13;
  color: ${color.santasGray};
  line-height: 20px;
  padding-bottom: 35px;
`;

export const Container = styled.div`
  background-color: white;
  margin: 20px;
  padding: 80px;
  height: 670px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
