import { useDraggable } from '@dnd-kit/core';
import React from 'react';

export function Draggable(props) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: props.id,
    data: props.data,
    disabled: props.disabled,
  });

  let className = props.className || '';
  if (isDragging) className += ' dragging';

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} {...props} className={className}>
      {props.children}
    </div>
  );
}
