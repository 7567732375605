import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as styles from './experiences.module.scss';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import { BasicTable } from '../../assets/common';
import { getExperiencesEditHistorical } from '../../services/talentProfiles';
import { paginationConfig } from '../../utils/helper';
import moment from 'moment';
import { TALENT_PROFILE_EXPERIENCES } from '../../utils/constants';

const MODEL_STYLES = {
  titleFontWeight: 700,
  titleColor: '#4a4a4a',
  titleFontSize: 20,
  closePosition: 30,
  headerPaddingBottom: 10,
};

const TABLE_STYLES = {
  headerColor: '#8896A7',
  headerWeight: 700,
  borderRadius: 4,
  firstColumnGap: 50,
};

const ROWS_LIMIT = 10;

const ExperiencesEditHistorical = ({ item, open, setOpen }) => {
  const { value, type } = item;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = useCallback(async () => {
    if (!open || !type) return;
    try {
      setLoading(true);
      const params = {
        staff_id: value?.id,
        position_code: value?.position_code,
        sg: value?.sg,
        job_grade: value?.job_grade,
        date_from_raw: value?.date_from_raw,
        date_to_raw: value?.date_to_raw,
        type_edit: type,
        limit: ROWS_LIMIT,
        page,
      };
      const res = await getExperiencesEditHistorical(params);
      if (res.status === 200) {
        const { result } = res.data;
        setData(result);
        setTotal(result[0].total);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [value, type, page, open]);

  let capitalType = type?.charAt(0).toUpperCase() + type?.slice(1);

  const COLUMNS = [
    {
      title: 'Updated on',
      dataIndex: 'updated_on',
      render: (text) => (
        <span className={styles.tableText}>{text ? moment(text).format('DD MMM YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Updated by',
      dataIndex: 'updated_by',
      width: '30%',
      render: (text) => <span className={styles.tableText}>{text}</span>,
    },
    {
      title: `Previous ${capitalType}`,
      dataIndex: `previous_${type}`,
      render: (text) => <span className={styles.tableText}>{text}</span>,
    },
    {
      title: `Current ${capitalType}`,
      dataIndex: type,
      render: (text) => <span className={styles.tableText}>{text}</span>,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (open) return;
    setData([]);
  }, [open]);

  const setToOpen = (status) => {
    setPage(1);
    setOpen(status);
  };
  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setPage(pageNum);
    };
    return paginationConfig(total || 0, page, onChange, ROWS_LIMIT);
  }, [total, page]);

  return (
    <CustomAntModal
      closeType={1}
      open={open}
      setOpen={setToOpen}
      title={TALENT_PROFILE_EXPERIENCES[type]}
      width={1131}
      styles={MODEL_STYLES}
      hideDefaultBtn
    >
      <BasicTable
        loading={loading}
        rowKey="rowNum"
        dataSource={data}
        columns={COLUMNS}
        pagination={pagination}
        styles={TABLE_STYLES}
      />
    </CustomAntModal>
  );
};

export default ExperiencesEditHistorical;
