import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const SelectMultiple = ({ options, ...res }) => {
  return <Select options={options} {...res} />;
};

export default SelectMultiple;

export const SelectMultipleSearch = ({ ...res }) => {
  return <AsyncSelect {...res} />;
};
