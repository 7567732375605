import moment from 'moment';
import React, { useEffect } from 'react';
import { utils, writeFile } from 'xlsx-js-style';

function exportSuccesionPlanning(params, data) {
  const fileName =
    params.type_position === 'CD'
      ? `No of SP Conducted as of ${moment(new Date()).format('DDMMYY')}`
      : `${params.type} - ${
          params.type_position === 'PD'
            ? 'Position Deliberated'
            : params.type_position === 'KP'
            ? 'Critical Position'
            : 'Conducted'
        } for ${params.role} as of ${moment(new Date()).format('DDMMYY')}`;

  const arr = [];
  data = data.map((item, index) => {
    return {
      key: index + 1,
      position_name: item.position_name,
      position_code: item.position_code,
      business_unit: item.business_unit,
      role_level: item.role_level,
    };
  });
  data.forEach((item) => {
    arr.push(Object.values(item));
  });

  arr.unshift(['No', 'Position Name', 'Position Code', 'Business Unit', 'Job Grade']);
  let ws = utils.aoa_to_sheet(arr);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet 1');
  writeFile(wb, `${fileName}.xlsx`);
}

const ExportSuccesionPlanningDetail = (props) => {
  const { setExportData, data, params } = props;

  useEffect(() => {
    exportSuccesionPlanning(params, data);
    setExportData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default ExportSuccesionPlanningDetail;
