import React, { useMemo } from 'react';

import { icon_after } from '../../../../../assets/img';
import { LIST_OF_PROFILES_FILTER_TYPES } from '../../constant';
import styles from './pill-managing.module.scss';

export default function PillManaging(props) {
  const { searchParams, setSearchParams } = props;
  const { roleLevelSelected, businessSelected } = searchParams;

  const isSelectAllBusiness = useMemo(() => businessSelected.find((item) => item.value === 'all'), [businessSelected]);

  const isSelectAllPositionLabel = useMemo(() => roleLevelSelected.find((item) => item.value === 'all'), [roleLevelSelected]);

  const onRemove = (type, value) => {
    let remain;
    switch (type) {
      case LIST_OF_PROFILES_FILTER_TYPES.ROLE_LEVEL:
        remain = roleLevelSelected.filter((item) => item.value !== value);
        setSearchParams((prev) => ({
          ...prev,
          roleLevelSelected: remain,
        }));
        return;
      case LIST_OF_PROFILES_FILTER_TYPES.BUSINESS:
        remain = businessSelected.filter((item) => item.value !== value);
        setSearchParams((prev) => ({
          ...prev,
          businessSelected: remain,
        }));
        return;
      default:
        return;
    }
  };

  return (
    <div className={styles.wrapper} style={{ marginTop: roleLevelSelected.length + businessSelected.length > 0 ? 22 : 0 }}>
      {isSelectAllPositionLabel ? (
        <span className={styles.pillItem}>
          <span>Role Level - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() =>
              setSearchParams((prev) => ({
                ...prev,
                roleLevelSelected: [],
              }))
            }
            alt="remove-all-business"
          />
        </span>
      ) : (
        roleLevelSelected.map((item, idx) => {
          return (
            <span key={item.value} className={styles.pillItem}>
              <span>{item.label}</span>
              <img
                data-testid={`position-${idx}`}
                src={icon_after}
                onKeyDown={() => {}}
                onClick={() => onRemove(LIST_OF_PROFILES_FILTER_TYPES.ROLE_LEVEL, item.value)}
                alt="remove"
              />
            </span>
          );
        })
      )}
      {isSelectAllBusiness ? (
        <span className={styles.pillItem}>
          <span>Business - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() =>
              setSearchParams((prev) => ({
                ...prev,
                businessSelected: [],
              }))
            }
            alt="remove-all-position"
          />
        </span>
      ) : (
        businessSelected.map((item, idx) => {
          return (
            <span
              data-testid={`business-${idx}`}
              key={item.value}
              className={styles.pillItem}
              onKeyDown={() => {}}
              onClick={() => onRemove(LIST_OF_PROFILES_FILTER_TYPES.BUSINESS, item.value)}
            >
              <span>{item.label}</span>
              <img src={icon_after} alt="remove" />
            </span>
          );
        })
      )}
    </div>
  );
}
