import { Table, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import { BasicButton } from '../../../../../../assets/common';
import { chevron_down_arrow, chevron_up_arrow } from '../../../../../../assets/img';
import CustomFilterTable from '../../../../../../components/CustomFilterTable';
import PopupMatchingCriteriaList from '../../../../../../components/PopupMatchingCriteriaList/PopupMatchingCriteriaList';

import { handleShowRetirementDate, handleShowYIPAndYISG } from '../../../../../../utils/helper';
import * as styles from './data-table-view-result.module.scss';
import { TableViewResult } from './styled';

export default function DataTableViewResult({
  data,
  positionName,
  totalData,
  limit,
  filterMatching,
  page,
  setPage,
  setRoleLevelSelected,
  roleLevelSelected,
  setSalaryGradelSelected,
  salaryGradelSelected,
  setSpReadinessSelected,
  spReadinessSelected,
  setSortMatchingPercentage,
  expandRecord,
  setExpandRecord,
}) {
  const [newData, setNewData] = useState([]);
  const [isOpenPopupMatching, setIsOpenPopupMatching] = useState(false);
  const [viewDetailParams, setViewDetailParams] = useState({
    staffId: '',
    idSet: '',
    positionCode: '',
    positionName: '',
    nameSet: '',
  });

  useEffect(() => {
    setNewData(
      !isEmpty(data)
        ? data.map((item, index) => {
            return {
              ...item,
              no: index + 1,
              key: `${item.staff_id}.${index}`,
            };
          })
        : []
    );
  }, [data]);

  const onApplyRoleLevel = useCallback(
    (filters) => {
      setRoleLevelSelected(filters);
      setPage(1);
    },
    [setRoleLevelSelected, setPage]
  );

  const onApplySalaryGrade = useCallback(
    (filters) => {
      setSalaryGradelSelected(filters);
      setPage(1);
    },
    [setSalaryGradelSelected, setPage]
  );

  const onApplySpReadiness = useCallback(
    (filters) => {
      setSpReadinessSelected(filters);
      setPage(1);
    },
    [setSpReadinessSelected, setPage]
  );

  const handleReturnFilter = (data, setListSelected, listSelected, onApplyRoleLevel, close) => {
    return (
      <CustomFilterTable
        setSelectedKeys={setListSelected}
        selectedKeys={listSelected}
        filters={
          (!isEmpty(data) &&
            data.map((item) => ({
              value: item.value,
              text: item.label,
              isChecked: item.value,
            }))) ||
          []
        }
        onApplyFilters={onApplyRoleLevel}
        isLoading={false}
        close={close}
      />
    );
  };

  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }
  };

  const handleViewDetail = (item) => {
    setViewDetailParams({
      positionCode: item.position_code,
      staffId: item.staff_id,
      idSet: item.set_id,
      nameSet: item.set_name,
    });
    setIsOpenPopupMatching(true);
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'left',
      render: (_, record) => {
        return <span className={styles.normalContent}>{(page - 1) * limit + record.no}</span>;
      },
    },
    {
      title: 'Staff Name',
      dataIndex: 'birth_name',
      align: 'left',
      className: `custom-text-field`,
      width: 300,
      render: (_, record) => {
        return (
          <div className={styles.expandWrapper}>
            <h3 className={styles.expandLabel}>
              <span style={{ fontWeight: 700 }}>{`${record.birth_name}`}</span>
              <br />
              <span style={{ fontWeight: 500, color: '#95A3B2' }}>
                {record.staff_id && `${record.staff_id}`}
              </span>
            </h3>
            <button
              type={'button'}
              className={styles.expandBtn}
              onClick={() =>
                setExpandRecord((prev) => {
                  return prev?.no !== record?.no ? record : null;
                })
              }
            >
              <img
                className={styles.iconExpand}
                src={expandRecord?.no === record?.no ? chevron_up_arrow : chevron_down_arrow}
                alt="iconExpand"
              />
            </button>
            {!isEmpty(expandRecord) && expandRecord.no === record.no && (
              <div className={styles.expandContent}>
                <span>
                  YIP:{' '}
                  {handleShowYIPAndYISG(
                    expandRecord?.years_in_position,
                    expandRecord?.date_in_position
                  )}
                </span>
                <span>Age: {expandRecord?.age}</span>
                <span>
                  YISG: {expandRecord?.salary_grade} (
                  {handleShowYIPAndYISG(expandRecord?.years_in_sg, expandRecord?.date_in_sg)})
                </span>
                <span>
                  Retirement: {handleShowRetirementDate(expandRecord?.retirement_date)}{' '}
                  {handleShowRetirement(expandRecord)}
                </span>
                <span>Performance Rating: {expandRecord?.performance_rating}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Current Position',
      dataIndex: 'position_name',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return (
          <span className={styles.normalContent}>
            <Tooltip
              className={styles.customTooltip}
              overlayClassName={'customTooltip'}
              overlayStyle={{ minWidth: '320px' }}
              title={
                <div className={styles.tooltipContent}>
                  <span>{record.position_name}</span>
                  <ul>
                    {record?.department_name && <li>Department: {record?.department_name}</li>}
                    {record?.division && <li>Division: {record?.division}</li>}
                    {record?.opu_name && <li>OPU: {record?.opu_name}</li>}
                    {record?.business_unit && <li>Business: {record?.business_unit}</li>}
                  </ul>
                </div>
              }
              color={'black'}
              key={'black'}
              overlayInnerStyle={{ borderRadius: '6px' }}
            >
              {record.position_name}
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: 'Role Level',
      dataIndex: 'role_level',
      align: 'left',
      className: `custom-text-field`,
      render: (_, record) => {
        return <span className={styles.normalContent}>{record.role_level}</span>;
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterMatching?.role_level,
          setRoleLevelSelected,
          roleLevelSelected,
          onApplyRoleLevel,
          close
        ),
    },
    {
      title: 'Salary Grade',
      dataIndex: 'salary_grade_bfr',
      align: 'left',
      width: 150,
      className: `custom-text-field`,
      render: (_, record) => {
        return <span className={styles.normalContent}>{record?.salary_grade_bfr || 'N/A'}</span>;
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterMatching?.salary_grade,
          setSalaryGradelSelected,
          salaryGradelSelected,
          onApplySalaryGrade,
          close
        ),
    },
    {
      title: 'Matching Percentage',
      dataIndex: 'matching_percentage',
      align: 'left',
      className: `custom-text-field`,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.matching_percentage - b.matching_percentage,
      render: (_, record) => {
        return (
          <div className={styles.wapperMatching}>
            <span className={styles.normalContent}>
              {record.matching_percentage && `${record.matching_percentage}%`}
            </span>
            <BasicButton
              onClick={() => handleViewDetail(record)}
              style={{ marginLeft: '20px', minWidth: '110px' }}
            >
              View Details
            </BasicButton>
          </div>
        );
      },
    },
    {
      title: 'SP Readiness',
      dataIndex: 'sp_readiness',
      align: 'left',
      width: 150,
      className: `custom-text-field`,
      render: (_, record) => {
        return <span className={styles.normalContent}>{record.sp_readiness}</span>;
      },
      filterIcon: <img src={chevron_down_arrow} alt="" />,
      filterDropdown: ({ clearFilters, close }) =>
        handleReturnFilter(
          filterMatching?.sp_readiness,
          setSpReadinessSelected,
          spReadinessSelected,
          onApplySpReadiness,
          close
        ),
    },
  ];

  const showContentPaging = (total, range) => {
    return (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    );
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
    }
  };

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNumber) => handlePageChange(pageNumber),
    showTotal: (total, range) => showContentPaging(total, range),
  };

  const onChange = (pagination, filters, sorter) => {
    setSortMatchingPercentage(sorter?.order);
  };

  return (
    <div style={{ marginTop: 24 }}>
      <PopupMatchingCriteriaList
        open={isOpenPopupMatching}
        setOpen={setIsOpenPopupMatching}
        positionName={positionName}
        {...viewDetailParams}
      />
      <TableViewResult>
        <Table
          className={`customTable`}
          columns={columns}
          dataSource={newData}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
          onChange={onChange}
        />
      </TableViewResult>
    </div>
  );
}
