import { isEmpty } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { pagination_button_left, pagination_button_right } from '../../assets/img';
import styles from './pagination.module.scss';

const Pagination = (props) => {
  const history = useHistory();
  const { page, rowPerPages, totalResults, setPage } = props;
  const pageTotal = Math.ceil(totalResults / rowPerPages);
  const fromResult = (page - 1) * rowPerPages + 1;
  const toResults = (page - 1) * rowPerPages + rowPerPages;
  return (
    <div
      className={`row no-gutters justify-content-between mb-3 ${styles.pagination}`}
      data-testid="pagination"
    >
      <div
        className={`row no-gutters justify-content-center align-items-center ${styles.pagination_left}`}
      >
        Showing <span>{fromResult}</span> to
        <span>{toResults > totalResults ? totalResults : toResults}</span> of
        <span>{totalResults}</span> results
      </div>
      <nav aria-label="Page navigation example">
        <ul className={styles.pagination_right}>
          <li
            className={page === 1 ? `${styles.page} ${styles.disabled}` : styles.page}
            onKeyDown={() => {}}
            onClick={() => {
              if (!isEmpty(props?.search)) {
                history.push(`/search-talent-profile/${props.search}/${1}/${rowPerPages}`);
              } else {
                setPage(1);
              }
            }}
          >
            <img src={pagination_button_left} alt="pagination_left" />
          </li>
          {page !== 1 && (
            <li
              className={styles.page}
              onKeyDown={() => {}}
              onClick={() => {
                if (!isEmpty(props?.search)) {
                  history.push(`/search-talent-profile/${props.search}/${page - 1}/${rowPerPages}`);
                } else {
                  setPage(page - 1);
                }
              }}
            >
              {page - 1}
            </li>
          )}
          <li className={`${styles.page} ${styles.page_active}`}>{page}</li>
          {page !== pageTotal && (
            <li
              className={styles.page}
              onKeyDown={() => {}}
              onClick={() => {
                if (!isEmpty(props?.search)) {
                  history.push(`/search-talent-profile/${props.search}/${page + 1}/${rowPerPages}`);
                } else {
                  setPage(page + 1);
                }
              }}
            >
              {page + 1}
            </li>
          )}
          <li
            className={page === pageTotal ? `${styles.page} ${styles.disabled}` : styles.page}
            onKeyDown={() => {}}
            onClick={() => {
              if (!isEmpty(props?.search)) {
                history.push(`/search-talent-profile/${props.search}/${pageTotal}/${rowPerPages}`);
              } else {
                setPage(pageTotal);
              }
            }}
          >
            <img src={pagination_button_right} alt="pagination_right" />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
