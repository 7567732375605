import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { isEmpty, map } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { AiButton } from '../../../../../assets/common';
import { isCheckError } from '../../../../../hooks/useFormik';
import { useProfilesSearch } from '../../../../../hooks/useTalentSearch';
import userApi from '../../../../../services/userApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  AGENDA_SHORT_TYPES,
  AGENDA_TYPES,
  CATEGORY_OPTIONS,
  FILL_OUT_REQUIRE_MESSAGE,
  MESSAGE_TYPES,
  PERMISSION,
} from '../../../../../utils/constants';
import { AiContainerForm, AiH3, AiLabel, AiTextarea, Hidden, RemarkTitle } from '../../Styled';
import TalentNameField from '../TalentNameField/TalentNameField';
import RemarkCategory from './RemarkCategory';

const REMARK_TYPE_OPTIONS = [
  { value: 'Talent', label: 'Talent' },
  { value: 'General', label: 'General' },
];

const INITIAL_VALUES = {
  remarkType: null,
  title: '',
  staff: null,
  remark: '',
  category: null,
  agenda: null,
};

const validationSchema = yup.object().shape({
  remarkType: yup.object().nullable().required('Remarks Type is required.'),
  remark: yup.string().nullable().required('Remarks is required.'),
  category: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Category is required.'),
    }),
  staff: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Talent Name is required.'),
    }),
  agenda: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: null,
      otherwise: (schema) => schema.required('Agenda is required.'),
    }),
});

const validationSchemaForOtherAgenda = yup.object().shape({
  remarkType: yup.object().nullable().required('Remarks Type is required.'),
  remark: yup.string().nullable().required('Remarks is required.'),
  category: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Category is required.'),
    }),
  staff: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Talent Name is required.'),
    }),
});

const DisplayBox = React.memo(({ children, isDisplay }) => {
  return (
    <>{isDisplay ? children : React.Children.map(children, (child) => <Hidden>{child}</Hidden>)}</>
  );
});

const EditRemarkOtherAgenda = (props) => {
  const {
    agendaOptions,
    currentRemarkToUpdate,
    onEditRemark,
    agendaType,
    setCompareData,
    setCurrentRemarkToUpdate,
    setSubmittedRemarkForm,
  } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const { isSubmittedFormChanges } = useSelector((state) => state.app.meetingModule.preMeeting);
  const dispatch = useDispatch();
  const roleId = roleActive?.roleId;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema:
      agendaType !== AGENDA_TYPES.OTHERS_AGENDA ? validationSchema : validationSchemaForOtherAgenda,
    onSubmit: async (values) => {
      onEditRemark({ ...values });
    },
  });

  const handleCheckValidateForm = () => {
    if (!isEmpty(formik.errors)) {
      dispatch(
        pushMessage({
          message: FILL_OUT_REQUIRE_MESSAGE,
          type: MESSAGE_TYPES.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    if (currentRemarkToUpdate) {
      const data = {
        remarkType: {
          value: currentRemarkToUpdate?.remarkType || currentRemarkToUpdate?.remark_type,
          label: currentRemarkToUpdate?.remarkType || currentRemarkToUpdate?.remark_type,
        },
        title: currentRemarkToUpdate?.title || '',
        staff: {
          value: currentRemarkToUpdate?.staffId,
          label:
            currentRemarkToUpdate?.talentName ||
            currentRemarkToUpdate?.talent_name ||
            currentRemarkToUpdate?.birthName ||
            '-',
        },
        remark: currentRemarkToUpdate?.remarks || currentRemarkToUpdate?.remark || '',
        category: {
          value: currentRemarkToUpdate?.category,
          label: currentRemarkToUpdate?.category,
        },
        agenda: {
          value: currentRemarkToUpdate?.agendaId || currentRemarkToUpdate?.agenda_id,
          label: currentRemarkToUpdate?.agendaName || currentRemarkToUpdate?.agenda_name,
          type: currentRemarkToUpdate?.agendaType || currentRemarkToUpdate?.agenda_type,
        },
        remarkId: currentRemarkToUpdate?.remarkId || currentRemarkToUpdate?.remark_id,
      };
      if (
        [REMARK_TYPE_OPTIONS[1].value].includes(
          currentRemarkToUpdate?.remark_type || currentRemarkToUpdate?.remarkType
        )
      ) {
        const initValues = {
          ...data,
          category: null,
          staff: null,
        };
        formik.setValues(initValues);
        setCompareData((prev) => ({
          ...prev,
          currentData: {
            ...prev.currentData,
            remark_fields: initValues,
          },
          initData: {
            ...prev.initData,
            remark_fields: initValues,
          },
        }));
      } else {
        formik.setValues(data);
        getAsyncOptions(currentRemarkToUpdate?.talentName);
        setCompareData((prev) => ({
          ...prev,
          currentData: { ...prev.currentData, remark_fields: data },
          initData: { ...prev.initData, remark_fields: data },
        }));
        setSubmittedRemarkForm((prev) => ({
          ...prev,
          currentRemarkItem: { ...data, isEdit: true },
        }));
      }
      formik.setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRemarkToUpdate]);

  useEffect(() => {
    if (!isEmpty(formik.values)) {
      setCompareData((prev) => ({
        ...prev,
        currentData: { ...prev.currentData, remark_fields: formik.values },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (
      !isEmpty(formik.values) &&
      formik.values?.remarkType?.value ===
        (currentRemarkToUpdate?.remarkType || currentRemarkToUpdate?.remark_type) &&
      formik.values?.remarkType?.value === REMARK_TYPE_OPTIONS[1].value
    ) {
      const newRemarkFields = { ...formik.values, category: null, staff: null };
      setCompareData((prev) => ({
        ...prev,
        currentData: { ...prev.currentData, remark_fields: newRemarkFields },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.remarkType?.value]);

  useEffect(() => {
    if (isSubmittedFormChanges) {
      formik.handleSubmit();
      handleCheckValidateForm();
      setSubmittedRemarkForm({
        isSubmitted: true,
        hasError: !isEmpty(formik.errors) ? true : false,
        currentRemarkItem: { ...formik.values, isEdit: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmittedFormChanges]);

  async function saveCurrentRemark() {
    formik.handleSubmit();
    handleCheckValidateForm();

    if (isEmpty(formik.errors)) {
      setCurrentRemarkToUpdate(null);
      setCompareData((prev) => ({
        ...prev,
        currentData: { ...prev.currentData, remark_fields: INITIAL_VALUES },
        initData: { ...prev.initData, remark_fields: INITIAL_VALUES },
      }));
    }
  }

  const handleCancelUpdateRemark = () => {
    onEditRemark(null);
  };

  const getAsyncOptions = async (inputValue) => {
    let res = [];
    const headers = {
      permission: PERMISSION.SEARCH_TALENT_PROFILE,
      role: roleId,
    };
    const body = { search: inputValue, limit: 4, page: 1 };
    try {
      const response = await userApi.getSearchList(headers, body);
      if (response?.data?.result && response.data.result.profiles?.length >= 0) {
        res = response.data.result.profiles.map((item) => ({
          label: `${item.birthName || ''} ${item.opu || ''} ${item.sg || ''}`,
          value: item.staffId,
          hasBirthName: item?.birthName,
        }));
        return res;
      }
      return res;
    } catch (error) {
      return res;
    }
  };

  // Trigger to search talent profile for talent agenda
  const { searchProfiles } = useProfilesSearch({
    mapData: (profiles) =>
      map(profiles, ({ birthName, birth_name, opu, sg, image, staffId }) => ({
        label: birthName || birth_name || '-',
        value: { birthName, birth_name, opu, sg, image, staffId },
      })),
  });
  const fetchTalentProfile = async (value) => {
    try {
      const res = await searchProfiles('', value);
      if (!isEmpty(res)) {
        formik.setFieldValue('staff', {
          value: res[0].value,
          label: res[0].label || '-',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedAgenda = (event) => {
    formik.setFieldValue('agenda', event);

    if (event?.type === AGENDA_SHORT_TYPES.TALENT_REVIEW && event?.value) {
      fetchTalentProfile(event.value);
    } else {
      formik.setFieldValue('staff', null);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <AiContainerForm style={{ padding: 0 }}>
        <RemarkTitle>
          <AiH3>Edit Remark</AiH3>
        </RemarkTitle>
        <Row gutter={16} className="mb-3">
          <Col span={4} className="gutter-row">
            <AiLabel>Remarks Type</AiLabel>
            <RemarkCategory
              selected={formik.values.remarkType}
              setSelected={(event) => formik.setFieldValue('remarkType', event)}
              options={REMARK_TYPE_OPTIONS}
              placeholder="Select"
              status={isCheckError(formik, 'remarkType') ? 'error' : ''}
            />
          </Col>
          <DisplayBox isDisplay={formik.values.remarkType?.value === 'Talent'}>
            <Col span={6} className="gutter-row">
              <AiLabel>Category</AiLabel>
              <RemarkCategory
                selected={formik.values.category}
                setSelected={(event) => formik.setFieldValue('category', event)}
                options={
                  AGENDA_TYPES.OTHERS_AGENDA ? CATEGORY_OPTIONS : [...CATEGORY_OPTIONS, { value: 'Additional Input', label: 'Additional Input' }]
                }
                placeholder="Select Category"
                status={isCheckError(formik, 'category') ? 'error' : ''}
              />
            </Col>
          </DisplayBox>
          {agendaType !== AGENDA_TYPES.OTHERS_AGENDA && (
            <DisplayBox isDisplay={!!formik.values.remarkType}>
              <Col span={6} className="gutter-row">
                <AiLabel>Agenda</AiLabel>
                <RemarkCategory
                  selected={formik.values.agenda}
                  setSelected={(event) => {
                    handleSelectedAgenda(event);
                  }}
                  options={agendaOptions}
                  placeholder="Select Agenda"
                  status={isCheckError(formik, 'agenda') ? 'error' : ''}
                  agendaType={agendaType}
                />
              </Col>
            </DisplayBox>
          )}
          <DisplayBox isDisplay={formik.values.remarkType?.value === 'Talent'}>
            <Col span={8}>
              <AiLabel>Talent Name</AiLabel>
              <TalentNameField
                field={{ value: formik.values.staff, name: 'staff' }}
                form={{ setFieldValue: formik.setFieldValue, getFieldMeta: formik.getFieldMeta }}
                placeholder="Enter Talent Name"
                formValues={formik.values}
                agendaType={agendaType}
                disabled={formik.values.agenda?.type === AGENDA_SHORT_TYPES.TALENT_REVIEW}
              />
            </Col>
          </DisplayBox>
        </Row>
        <Row className="mt-3">
          <Col span={24}>
            <AiLabel>Remarks</AiLabel>
            <AiTextarea
              rows={4}
              placeholder="Enter your remark here"
              name="remark"
              value={formik.values.remark}
              onChange={formik.handleChange}
              status={isCheckError(formik, 'remark') ? 'error' : ''}
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: '30px' }}>
          <AiButton border={true} borderColor={`#00a19c`} style={{ width: `100px` }} onClick={handleCancelUpdateRemark}>
            Cancel
          </AiButton>
          <AiButton htmlType="submit" onClick={saveCurrentRemark} mode="teal">
            Save Remarks
          </AiButton>
        </Row>
      </AiContainerForm>
    </form>
  );
};
export default EditRemarkOtherAgenda;
