import React from 'react';

import CommonLoading from '../CommonLoading/CommonLoading';
import { BusinessDivisionOPUChart, NationalityChart, RetirementForeCastChart, SuccessorChart } from './components';
import { VALUES_SELECT } from './constants';

const wrapperLoading = { display: 'flex', alignItems: 'center', margin: '0 auto', height: '291px' };

const DashboardChartView = ({
  selectedDemographic,
  loading,
  retirementForecast,
  categoriesRetirement,
  nationality,
  categoriesNationality,
  successor,
  categories,
  businessOPU,
  categoriesBUandOPU,
}) => {
  const chartrender = () => {
    switch (selectedDemographic.value) {
      case VALUES_SELECT.RETIREMENT_FORECAST: {
        if (!loading) {
          return (
            <div data-testid="retirement-chart">
              <RetirementForeCastChart data={retirementForecast} categories={categoriesRetirement} />
            </div>
          );
        } else {
          return (
            <div style={wrapperLoading}>
              <CommonLoading />
            </div>
          );
        }
      }
      case VALUES_SELECT.NATIONALITY: {
        if (!loading) {
          return (
            <div data-testid="national-chart">
              <NationalityChart data={nationality} categories={categoriesNationality} />;
            </div>
          );
        } else {
          return (
            <div style={wrapperLoading}>
              <CommonLoading />
            </div>
          );
        }
      }
      case VALUES_SELECT.SUCCESSOR: {
        if (!loading) {
          return (
            <div data-testid="successor">
              <SuccessorChart data={successor} categories={categories} />
            </div>
          );
        } else {
          return (
            <div style={wrapperLoading}>
              <CommonLoading />
            </div>
          );
        }
      }
      case VALUES_SELECT.BUSINESS_DIVISION_OPU: {
        if (!loading) {
          return (
            <div data-testid="business-chart">
              <BusinessDivisionOPUChart data={businessOPU} categories={categoriesBUandOPU} />
            </div>
          );
        } else {
          return (
            <div style={wrapperLoading}>
              <CommonLoading />
            </div>
          );
        }
      }
      default:
        break;
    }
  };

  return <>{chartrender()}</>;
};
export default DashboardChartView;
