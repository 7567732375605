import { isEmpty, unionBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDebounce } from '../../hooks';
import { fetchPositions } from '../../services/successionplanningratio';
import styles from './search-position-box.module.scss';
import SearchListOption from './SearchListOption';

const SearchPositionBox = (props) => {
  const { selectedSp, searchTerm, setSearchTerm, searchList, setSearchList } = props;
  const [loading, setLoading] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const debounceSearchQuery = useDebounce(searchTerm, 500);

  const handleOnChange = (value) => {
    setSearchTerm({ value, type: 'search', showTerm: value });
  };
  useEffect(() => {
    if (debounceSearchQuery.type === 'search') {
      fetchAPI();
    }
    async function fetchAPI() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await fetchPositions(
            debounceSearchQuery.value,
            'search',
            roleActive.roleId
          );
          let result = unionBy(response.data.result.data, 'position_code');
          setSearchList(result);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }
  }, [debounceSearchQuery, roleActive, setSearchList]);

  return (
    <div className={styles.search_box} tabIndex={0} data-testid="search-box">
      <input
        id="position"
        data-testid="position-input"
        name="position"
        placeholder="Search"
        className={styles.position_input}
        onChange={(e) => handleOnChange(e.target.value)}
        value={searchTerm.showTerm}
        autoComplete="off"
        disabled={selectedSp}
      />
      {searchTerm.value && searchTerm.type === 'search' && (
        <SearchListOption
          data={searchList}
          setSearchTerm={setSearchTerm}
          loading={loading}
          debounceSearchQuery={debounceSearchQuery}
        />
      )}
    </div>
  );
};

export default SearchPositionBox;
