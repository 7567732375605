import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { avatar, grid_active, grid_no_active, list_active, list_no_active } from '../../assets/img';
import { Pagination } from '../../components';
import useApi from '../../services/userApi';
import { MODE_TYPES, PERMISSION } from '../../utils/constants';
import { decodeBase64ToImage } from '../../utils/helper';
import TalentFinderTooltip from '../TalentFinderPage/TalentFinderDetail/TalentFinderTooltip/TalentFinderTooltip';
import styles from './search-talent-header.module.scss';

export const Grid = (props) => {
  const { data, showDetail } = props;
  const listItems = data.map((item, index) => (
    <li key={`${item.staffId}`} className={`row no-gutters ${styles.left_card} `}>
      <div className={`row no-gutters ${styles.person} `}>
        <div className={styles.avatar_wrapper} style={{ marginBottom: '20px' }}>
          <img
            className={styles.avatar}
            src={decodeBase64ToImage(item && item.image) || avatar}
            alt="avatar"
            style={{ borderRadius: '50%', border: '5px solid #F0F3F7' }}
          />
          <TalentFinderTooltip item={item} mode={MODE_TYPES.GIRD} />
        </div>
        <div className={`${styles.name} ${styles.ellipsis}`}>{item.birthName}</div>
        <div className={styles.ellipsis}>{item.positionName}</div>
        <div className={styles.ellipsis}>{item.department}</div>
        <div className={styles.ellipsis}>{item.businessUnit}</div>
        <div className={styles.ellipsis}>{item.opu}</div>
        <div className={styles.ellipsis}>{item.sg}</div>
      </div>
      <div
        className={styles.btn_view_talent}
        onKeyDown={() => {}}
        onClick={() => showDetail(item.staffId)}
        data-testid="grid-click"
      >
        <p>View Talent</p>
      </div>
    </li>
  ));

  return (
    <ul className={`row no-gutters ${styles.grid} `} data-testid="data-grid">
      {listItems}
    </ul>
  );
};

export const List = (props) => {
  const { data, showDetail } = props;
  const listItems = data.map((item, index) => (
    <li key={`${item.staffId}`} className="row no-gutters w-100">
      <div className={`row no-gutters ${styles.left_card} `}>
        <div className={`row no-gutters ${styles.person} `}>
          <div className={styles.avatar_wrapper} style={{ marginBottom: '20px' }}>
            <img
              className={styles.avatar}
              src={decodeBase64ToImage(item && item.image) || avatar}
              alt="avatar"
              style={{ borderRadius: '50%', border: '5px solid #F0F3F7' }}
            />
            <TalentFinderTooltip item={item} mode={MODE_TYPES.GIRD} />
          </div>
          <div className={styles.name}>{item.birthName}</div>
          <div className={styles.ellipsis}>{item.positionName}</div>
          <div className={styles.ellipsis}>{item.department}</div>
          <div className={styles.ellipsis}>{item.businessUnit}</div>
          <div className={styles.ellipsis}>{item.opu}</div>
          <div className={styles.ellipsis}>{item.sg}</div>
        </div>
        <div
          className={styles.btn_view_talent}
          onKeyDown={() => {}}
          onClick={() => showDetail(item.staffId)}
          data-testid="list-click"
        >
          <p>View Talent</p>
        </div>
      </div>
      <div className={styles.right_card}>
        <div className={`col-3 row no-gutters ${styles.column} `}>
          <div>
            <div className={styles.right_card_title}>Job Grade</div>
            <div className={styles.right_card_value}>{item.jg}</div>
          </div>
          <div>
            <div className={styles.right_card_title}>Years in Position</div>
            <div className={styles.right_card_value}>
              {item.datePosition ? moment(item.datePosition).format('DD-MMM-YYYY') : '-'}
            </div>
          </div>
        </div>
        <div className={`col-3 row no-gutters ${styles.column} `}>
          <div>
            <div className={styles.right_card_title}>Salary Grade</div>
            <div className={styles.right_card_value}>{item.sg}</div>
          </div>
          <div>
            <div className={styles.right_card_title}>Years in Company</div>
            <div className={styles.right_card_value}>
              {item.dateCompany ? moment(item.dateCompany).format('DD-MMM-YYYY') : '-'}
            </div>
          </div>
        </div>
        <div className={`col-3 row no-gutters ${styles.column} `}>
          <div>
            <div className={styles.right_card_title}>SG Since</div>
            <div className={styles.right_card_value}>
              {item.dateSg ? moment(item.dateSg).format('DD-MMM-YYYY') : '-'}
            </div>
          </div>
          <div>
            <div className={styles.right_card_title}>Years in PETRONAS</div>
            <div className={styles.right_card_value}>
              {item.datePet ? moment(item.datePet).format('DD-MMM-YYYY') : '-'}
            </div>
          </div>
        </div>
        <div className={`col-3 row no-gutters ${styles.column} `}>
          <div>
            <div className={styles.right_card_title}>Superior</div>
            <div className={styles.right_card_value}>{item.supervisorName}</div>
          </div>
          <div>
            <div className={styles.right_card_title}>Position ID</div>
            <div className={styles.right_card_value}>{item.positionId}</div>
          </div>
        </div>
      </div>
    </li>
  ));

  return (
    <ul className={`row no-gutters ${styles.grid} `} data-testid="data-list">
      {listItems}
    </ul>
  );
};

const SearchTalentHeader = () => {
  const history = useHistory();
  const { searchKey, pageDefault, mode } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [totalResults, setTotalResults] = useState(1);
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const limit = Number(mode) === 16 || Number(mode) === 4 ? Number(mode) : 16;

  function showDetail(id) {
    history.push(`/profile/${id}`);
  }

  function switchMode() {
    history.push(`/search-talent-profile/${searchKey}/1/${limit === 16 ? 4 : 16}`);
  }

  function convertData(arr) {
    arr.map((result) => {
      return {
        staffId: result.staffId || '-',
        birthName: result.birthName || '-',
        positionName: result.positionName || '-',
        department: result.deparment || '-',
        businessUnit: result.businessUnit || '-',
        opu: result.opu || '-',
        sg: result.sg || '-',
        jg: result.jg || '-',
        datePosition: result.datePosition || '',
        dateCompany: result.dateCompany || '',
        dateSg: result.dateSg || '',
        datePet: result.datePet || '',
        supervisorName: result.supervisorName || '-',
        positionId: result.positionId || '-',
      };
    });
    return arr;
  }
  useEffect(() => {
    async function fetchAPI() {
      setLoading(true);
      try {
        const permission = {
          permission: PERMISSION.SEARCH_TALENT_PROFILE,
          role: roleActive.roleId,
        };
        const body = { search: searchKey, limit, page: Number(pageDefault) };
        const response = await useApi.getSearchList(permission, body);
        if (response.data && response.data.result && !isEmpty(response.data.result.profiles)) {
          const rs = response.data.result.profiles;
          const data = rs && rs.length > 0 ? convertData(rs) : [];
          setSearchList(data);
          setTotalResults(response.data.result.totalProfiles);
        } else {
          setSearchList([]);
          setTotalResults(0);
        }
      } catch (error) {
        setSearchList([]);
        setTotalResults(0);
      }
      setLoading(false);
    }
    if (!searchKey || !Number(pageDefault)) return;
    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, Number(pageDefault), limit, roleActive.roleId]);

  return (
    <>
      {loading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className={styles.talent_finder} data-testid="talent-finder-wrapper">
          <div className={`row no-gutters ${styles.title} mb-3 w-100`}>
            <div className="col-10">
              <h1 className="">Search result : {searchKey ? searchKey : ''}</h1>
              <p>Talent search result</p>
            </div>
            {searchList && searchList.length > 0 && (
              <div className={`col-2 row no-gutters ${styles.icon}`}>
                <div
                  data-testid="switch-mode-grid"
                  onKeyDown={() => {}}
                  onClick={() => switchMode()}
                  style={limit === 16 ? { pointerEvents: 'none' } : null}
                >
                  <img src={limit === 16 ? grid_active : grid_no_active} alt="" />
                </div>
                <div
                  data-testid="switch-mode-list"
                  onKeyDown={() => {}}
                  onClick={() => switchMode()}
                  style={limit === 4 ? { pointerEvents: 'none' } : null}
                >
                  <img src={limit === 4 ? list_active : list_no_active} alt="" />
                </div>
              </div>
            )}
          </div>
          <div className={`${styles.content}`}>
            {limit === 16 ? (
              <Grid data={searchList} showDetail={showDetail} />
            ) : (
              <List data={searchList} showDetail={showDetail} />
            )}
          </div>
          {searchList && searchList.length > 0 ? (
            <Pagination
              page={Number(pageDefault)}
              rowPerPages={limit}
              search={searchKey}
              totalResults={totalResults}
            />
          ) : (
            <h1>No result</h1>
          )}
        </div>
      )}
    </>
  );
};

export default SearchTalentHeader;
