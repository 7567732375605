import React, { useState } from 'react';
import { BasicButton, BasicInput } from '../../../../assets/common';
import CreateReportModal from '../CreateReportModal/CreateReportModal';
import * as styles from './report-builder-search-bar.module.scss';

const ReportBuilderSearchBar = ({
  setSearchParams,
  setShowDeleteConfirm,
  selectedReports,
  isFirstRender,
  setIsFirstRender,
}) => {
  const [keyword, setKeyword] = useState('');
  const [showCreateReportModal, setShowCreateReportModal] = useState(false);

  const handleChangeSearch = (event) => {
    setKeyword(event.target.value);
  };

  const handleClickSearch = () => {
    setSearchParams((prev) => ({ ...prev, keyword }));
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  };

  const handleDeleteReport = () => {
    setShowDeleteConfirm(true);
  };

  const handleClickCreateReport = () => {
    setShowCreateReportModal(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.search}>
        <BasicInput
          height={44}
          width={416}
          placeholder="Search by Report Name and Owner"
          onChange={handleChangeSearch}
        />
        <BasicButton mode="teal" height={36} onClick={handleClickSearch}>
          Search
        </BasicButton>
      </div>
      <div className={styles.buttons}>
        <BasicButton mode="danger" onClick={handleDeleteReport} disabled={!selectedReports.length}>
          Confirm Delete Report
        </BasicButton>
        <BasicButton mode="teal" onClick={handleClickCreateReport}>
          Create Report
        </BasicButton>
      </div>

      <CreateReportModal open={showCreateReportModal} setOpen={setShowCreateReportModal} />
    </div>
  );
};

export default ReportBuilderSearchBar;
