import { Input, Modal, Pagination, Table } from 'antd';
import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const TitleDiv = styled.div`
  color: #4a4a4a;
  font-family: ${font.inter};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
`;

export const Title = styled.div`
  color: #4a4a4a;
  font-family: ${font.inter};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
  margin-bottom: 36px;
`;

export const Wrapper = styled(Modal)`
  .ant-modal-header {
    padding: 40px 40px 0 40px;
    border: none;
  }
  .ant-modal-close-x {
    margin-right: 20px;
    margin-top: 22px;
  }
  .ant-modal-body {
    padding: 0 40px;
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin: 36px 0;
`;

export const InputSearch = styled(Input)`
  border-radius: 4px;
  border: 1px solid var(--Blue-Grays-100, #d3dee8);
  background: var(--General-White, #fff);
  height: 44px;
  width: 656px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const SearchBtn = styled.div`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Light-Primary-600, #00908c);
  cursor: pointer;
  color: #fff;
`;

export const TableDiv = styled.div`
  padding-bottom: 40px;
`;

export const TableData = styled(Table)`
  .ant-table {
    border-radius: 6px;
    border: 1px solid rgba(151, 151, 151, 0.5);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    background-color: #fff;
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: ${font.inter};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ColumnNo = styled.div`
  color: var(--Emphasis-Body-N800, #3f3c4c);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ColumnName = styled.div`
  color: #000;
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;
`;

export const ColumnPosition = styled.div`
  color: #000;
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
`;

export const ColumnPlatformAndDate = styled.div`
  color: var(--Emphasis-Body-N800, #3f3c4c);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CustomNumber = styled.span`
  color: var(--gray-700, #374151);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const Text = styled.span`
  color: var(--gray-700, #374151);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const TablePagination = styled(Pagination)`
  .ant-pagination-item {
    color: #00645c;
    border: none;
  }
  .ant-pagination-item-active {
    color: #00645c;
    border-radius: 4px;
    background: var(--Primary-100, #afdddc);
  }
`;
