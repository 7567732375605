import { cloneDeep, isEmpty, isNumber } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ModelTC } from '../../../../components';
import { talentReviewApi } from '../../../../services/talentReviewApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { FORM_MODE_DEVELOPMENT_PLAN } from '../../../../utils/constants';
import { DEVELOPMENT_PLAN_MESSAGE } from '../../constants';
import TalentReviewCollapse from '../TalentReviewCollapse/TalentReviewCollapse';
import styles from './development-plan.module.scss';
import FormDevelopentPlan from './FormDevelopmentPlan/FormDevelopentPlan';
import { Title } from './FormDevelopmentPlan/styled';
import TableDevelopmentPlan from './TableDevelopmentPlan/TableDevelopmentPlan';

const init = {
  id: null,
  no: null,
  category: null,
  details: null,
};

export default function DevelopmentPlan({
  mode,
  addTalent,
  setAddTalent,
  agendaDetail,
  onlyViewTalentAgenda,
  isPrinting,
}) {
  const dispatch = useDispatch();
  const [flagReset, setFlagReset] = useState(false);
  const [formMode, setFormMode] = useState(FORM_MODE_DEVELOPMENT_PLAN.CREATE);
  const [dataSelected, setDataSelected] = useState({
    no: null,
    category: null,
    details: null,
  });
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [data, setData] = useState(agendaDetail?.development_plan || []);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!isPrinting) {
      if (!isEmpty(data)) {
        const tempArr = data.map((item, index) => {
          return {
            id: item?.id,
            no: index + 1,
            category: item?.category,
            details: item?.details,
          };
        });
        setAddTalent((prevState) => {
          return {
            ...prevState,
            development_plan: tempArr,
          };
        });
      } else {
        setAddTalent((prevState) => {
          return {
            ...prevState,
            development_plan: [],
          };
        });
      }
    }
  }, [data, setAddTalent, isPrinting]);

  useEffect(() => {
    if (!isPrinting && !isEmpty(agendaDetail)) {
      setData(
        agendaDetail?.development_plan.map((item, index) => {
          return {
            id: item.id,
            no: index + 1,
            category: item.category,
            details: item.details,
          };
        })
      );
    }
  }, [agendaDetail, isPrinting]);

  const onEdit = (value) => {
    handleScrollToElem('start');
    setDataSelected(value);
    setFormMode(FORM_MODE_DEVELOPMENT_PLAN.EDIT);
  };

  const onSelectDelete = (value) => {
    setDataSelected(value);
    setShowPopupDelete(true);
  };

  const onSave = (value) => {
    let tempArr = cloneDeep(data);
    tempArr.forEach((item) => {
      if (item.id === dataSelected.id) {
        item.category = value.category;
        item.details = value.details;
      }
    });
    setData(tempArr);
    onCancel();
    handleScrollToElem('end');
  };

  const onAdd = (value) => {
    const { category, details } = value;
    if (!category || !details.trim()) return;

    setData((prevState) => {
      return [...prevState, { ...value, id: uuidv4() }];
    });
    onCancel();
    handleScrollToElem('end');
  };

  const onCancel = () => {
    setDataSelected(init);
    setFlagReset(!flagReset);
    setFormMode(FORM_MODE_DEVELOPMENT_PLAN.CREATE);
  };

  const onAcceptDelete = () => {
    if (isEmpty(dataSelected)) {
      return;
    }

    let deleteIndex = data.findIndex((item) => item?.id === dataSelected?.id);
    if (deleteIndex !== -1) {
      let tempArr = cloneDeep(data);
      tempArr.splice(deleteIndex, 1);
      setData(tempArr);
      setShowPopupDelete(false);
      dispatch(
        pushMessage({
          type: 'success',
          message: DEVELOPMENT_PLAN_MESSAGE.DELETED,
        })
      );
    }
  };

  const handleScrollToElem = (position) => {
    if (typeof contentRef?.current['scrollIntoView'] === 'function') {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: position });
    }
  };

  const renderView = () => {
    return (
      <div className={!isPrinting ? styles.container : styles.container_print} ref={contentRef}>
        <ModelTC
          info={{
            type: 'deleteDevelopmentPlan',
            visible: showPopupDelete,
            setVisible: setShowPopupDelete,
            handleSubmit: () => onAcceptDelete(),
            onClose: () => {
              setShowPopupDelete(false);
            },
          }}
        />
        {!onlyViewTalentAgenda && (
          <FormDevelopentPlan
            flagReset={flagReset}
            onSave={onSave}
            onAdd={onAdd}
            onCancel={onCancel}
            dataSelected={dataSelected}
            mode={formMode}
          />
        )}
        <TableDevelopmentPlan
          data={!isEmpty(addTalent?.development_plan) ? addTalent?.development_plan : []}
          onSelectEdit={onEdit}
          onSelectDelete={onSelectDelete}
          onlyViewTalentAgenda={onlyViewTalentAgenda}
          isPrinting={isPrinting}
        />
      </div>
    );
  };

  return (
    <>
      {!isPrinting && (
        <TalentReviewCollapse header={<Title>{'Development Plan'}</Title>}>
          {renderView()}
        </TalentReviewCollapse>
      )}
      {isPrinting && (
        <div
          style={{
            borderRadius: 0,
            marginTop: '48px',
          }}
        >
          <Title>{'Development Plan List'}</Title>
          <div style={{ marginTop: '32px' }}>{renderView()}</div>
        </div>
      )}
    </>
  );
}
