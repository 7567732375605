import styled from 'styled-components';
import { Modal } from 'antd';
import { font } from '../../../assets/common';

export const ModalMobilityPreview = styled(Modal)`
  .ant-modal-header {
    border-radius: 6px 6px 0 0;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 24px;
  }
  .ant-modal-content {
    border-radius: 6px;
    padding: 11px;
    font-family: ${font.inter}
  }
  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
  }
`;
