import { Checkbox } from 'antd';
import React, { useLayoutEffect, useState } from 'react';

import * as SC from './styled';

const CheckboxGroup = Checkbox.Group;

const CheckBoxList = (props) => {
  const { checkedList, setCheckedList, options, label } = props;
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options : []);
    setCheckAll(e.target.checked);
  };

  useLayoutEffect(() => {
    setCheckAll(checkedList.length === options.length);
  }, [checkedList, options]);

  return (
    <SC.Dropdown>
      <div>{label}</div>
      <Checkbox onChange={onCheckAllChange} checked={checkAll}>
        All
      </Checkbox>
      <CheckboxGroup options={options} value={checkedList} onChange={onChange} />
    </SC.Dropdown>
  );
};

export default CheckBoxList;
