import styled from 'styled-components';
import { color, font } from '../../../../../assets/common';
const TableManagement = styled.div`
  width: 100%;
  margin-top: 20px;
  font-family: ${font.inter};

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    accent-color: #00a19c;
    padding: 2px;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: #8896a7;
      ${({ mode }) =>
        mode === 'historical-dashboard-detail' &&
        `
    background-color: #E7EDF3;
    color: #4F5F6E !important;
  `}
      &::before {
        content: none !important;
      }
    }
  }

  .ant-pagination {
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;
      background-color: transparent;
    }
  }

  .ant-pagination-item-active {
    background: #00a19c;
    color: #fff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00a19c;
    border-color: #00a19c;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #fff;
    border-color: #00a19c;

    &:after {
      background-color: #00a19c;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: white;
  }

  .ant-table-tbody .ant-table-cell {
    height: 100px;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead .ant-table-cell {
    color: #8896a7;
    font-size: 14px;
    font-weight: 700;
  }

  table tr td.ant-table-selection-column {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 23px;
    padding-right: 50px;
  }

  table tr th.ant-table-selection-column {
    padding-right: 50px;
    padding-left: 23px;
    border-left: 1px solid #d3dee8;
  }

  table tr td.ant-table-selection-column {
    border-left: 1px solid #d3dee8;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #d3dee8;
    border-top: 1px solid #d3dee8;
  }

  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #d3dee8;
  }

  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #d3dee8;
  }

  .ant-table-thead .ant-table-cell {
    &:last-child {
      border-right: 1px solid #d3dee8;
    }
    &:first-child {
      border-left: 1px solid #d3dee8;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #d3dee8;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: #d9d9d9;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: white;
  }

  .pagination-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  .ant-pagination-item-active {
    border-color: ${color.teal};
  }

  .ant-pagination-item:hover {
    border-color: ${color.teal};
  }

  .avatar {
    text-align: right;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
`;

export { TableManagement };
