import styled from 'styled-components';

import { color } from '../../../../../../assets/common';

export const TableViewResult = styled.div`
  width: 100%;
  margin-top: 20px;

  .ant-table-thead {
    .ant-table-cell {
      color: #8896a7;

      &::before {
        content: none !important;
      }
    }
  }

  .ant-pagination {
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;
      background-color: transparent;
    }
  }

  .ant-pagination-item-active {
    background: #00a19c;
    color: #fff;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: white;
  }

  .ant-table-tbody .ant-table-cell {
    height: 100px;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead .ant-table-cell {
    color: #8896a7;
    font-size: 14px;
    font-weight: 700;
  }

  table tr td.ant-table-selection-column {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 23px;
    padding-right: 50px;
  }

  table tr th.ant-table-selection-column {
    padding-right: 50px;
    padding-left: 23px;
    border-left: 1px solid #d3dee8;
  }

  table tr td.ant-table-selection-column {
    border-left: 1px solid #d3dee8;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #d3dee8;
    border-top: 1px solid #d3dee8;
  }

  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #d3dee8;
  }

  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #d3dee8;
  }

  .ant-table-thead .ant-table-cell {
    &:last-child {
      border-right: 1px solid #d3dee8;
    }
    &:first-child {
      border-left: 1px solid #d3dee8;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #d3dee8;
  }

  .pagination-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  .ant-pagination-item-active {
    border-color: ${color.teal};
  }

  .ant-pagination-item:hover {
    border-color: ${color.teal};
  }
`;
