import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { edit_historical_talent_status } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import { CURRENT_YEAR, PERMISSION } from '../../utils/constants';
import { ModelTC } from '../Model';
import styles from './historical-talent-status.module.scss';
import HistoricalPopupAdd from './HistoricalPopupAdd';
import HistoricalPopupList from './HistoricalPopupList';
import { Spin } from 'antd';
import { pushMessage } from '../../store/alertMessageSlice';

const HistoricalTalentStatus = (props) => {
  const { profileId } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [historical, setHistorical] = useState([]);
  const [showPopupHistorical, setShowPopupHistorical] = useState(false);
  const [showPopupHistoricalAdd, setShowPopupHistoricalAdd] = useState(false);
  const [showPopupHistoricalDelete, setShowPopupHistoricalDelete] = useState(false);
  const [historicalEdit, setHistoricalEdit] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const isEditPermission = useMemo(() => {
    if (isEmpty(user?.permissions)) return false;
    return user.permissions.includes(PERMISSION.TP_EDIT_HISTORICAL_TALENT_STATUS);
  }, [user.permissions]);

  async function fetchApi() {
    try {
      setLoading(true);
      const response = await talentProfileApi.fetchHistoricalTalentStatus({
        profileId,
        roleUser: user,
      });
      if (response.status === 200) {
        const newArr = [];
        response.data.result.forEach((item) => {
          if (
            !item.platform === false ||
            !item.start_date === false ||
            !item.end_date === false ||
            !item.status === false
          ) {
            newArr.push(item);
          }
        });
        setHistorical(newArr);
      }
    } catch (error) {
      setHistorical([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user.roleActive) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleActive.roleId, profileId]);

  async function deleteHistorical() {
    setHistoricalEdit({})
    try {
      setLoadingDelete(true);
      await talentProfileApi.deleteHistoricalStatus(user.roleActive, profileId, historicalEdit.id);
      fetchApi();
      dispatch(pushMessage({
        type: 'success',
        message: 'Historical Talent Status successfully deleted ',
        timeShow: 0,
      }));
    } catch (error) {
      console.log(error);
    }
    setLoadingDelete(false);
    setShowPopupHistoricalDelete(false);
  }

  return (
    <div className={styles.SectionPlan} data-testid="historical-talent">
      {showPopupHistorical && (
        <HistoricalPopupList
          historical={historical}
          show={showPopupHistorical}
          setShow={setShowPopupHistorical}
          setShowPopupHistoricalAdd={setShowPopupHistoricalAdd}
          setHistoricalEdit={setHistoricalEdit}
          setShowPopupHistoricalDelete={setShowPopupHistoricalDelete}
        />
      )}
      {showPopupHistoricalAdd && (
        <HistoricalPopupAdd
          show={showPopupHistoricalAdd}
          setShow={setShowPopupHistoricalAdd}
          fetchApi={fetchApi}
          historicalEdit={historicalEdit}
          setHistoricalEdit={setHistoricalEdit}
        />
      )}
      {showPopupHistoricalDelete && (
        <ModelTC
          info={{
            type: 'deleteHistorical',
            loading: loadingDelete,
            visible: showPopupHistoricalDelete,
            setVisible: setShowPopupHistoricalDelete,
            handleSubmit: () => deleteHistorical(),
            onClose: () => {
              setShowPopupHistoricalDelete(false);
              setShowPopupHistorical(true);
              setHistoricalEdit({})
            },
          }}
        />
      )}

      <div className={styles.SectionName}>
        <span>Historical Talent Status</span>
        {isEditPermission && (
          <img
            src={edit_historical_talent_status}
            alt="edit_historical_talent_status"
            style={{ pointerEvents: 'all', cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() => setShowPopupHistorical(true)}
          />
        )}
      </div>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table table-borderless">
            <thead>
              <tr className={styles.tablePlanThead}>
                <th scope="col">Status</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Platform</th>
              </tr>
            </thead>
            <tbody>
              {!!historical &&
                historical.length > 0 &&
                historical?.map((data, index) => (
                  <tr data-testid="list-historical" key={index} className={styles.tablePlanBody}>
                    <td data-testid="talent-status" className={styles.tablePlanBodyStatus} style={{ width: '202px' }}>
                      {!data.status ? '-' : data.status}
                    </td>
                    <td data-testid="talent-start-date">
                      {moment(data.start_date).format('D MMMM YYYY') !== 'Invalid date'
                        ? moment(data.start_date).format('D MMMM YYYY')
                        : '-'}
                    </td>
                    <td data-testid="talent-end-date">
                      {moment(data.end_date).format('D MMMM YYYY') !== 'Invalid date' &&
                      !data.end_date.includes(CURRENT_YEAR)
                        ? moment(data.end_date).format('D MMMM YYYY')
                        : '-'}
                    </td>
                    <td data-testid="talent-platform" style={{ maxWidth: '300px' }}>
                      {!data.platform ? '-' : data.platform}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Spin>
    </div>
  );
};

export default HistoricalTalentStatus;
