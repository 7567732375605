import { Spin } from 'antd';
import { cloneDeep, get, isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicButton } from '../../../../../../assets/common';
import { adminApi } from '../../../../../../services/admin';
import { pushMessage } from '../../../../../../store/alertMessageSlice';
import { CRITERIA_TYPE, SET_MATCHING_CRITERIA_STATUS } from '../../../../../../utils/constants';
import {
  addNewSetMatchingCriteria,
  duplicateListRanking,
  updateIsSavedSetMatching,
  updatePrevSetMatching,
  updateSetMatchingIndex,
} from '../../../redux/manageCriteriaSetMatchingSlice';
import * as styles from './add-criteria-pop-out.module.scss';
import { CriteriaCheckbox, SelectCriteriaModal } from './styled';

const AddCriteriaPopOutSetMatchingForDuplicate = (props) => {
  const {
    open,
    setOpen,
    position_code,
    position_name,
    setIsSummary,
    rankingData,
    setRankingData,
    setIsNewSetMatching,
    setDuplicateSetMatching,
  } = props;

  const dispatch = useDispatch();
  const { set_index, set_matching_criteria, list_ranking } =
    useSelector((state) => state.app.adminModule.manageCriteriaSetMatching) || [];

  const [listSelectedCriteria, setListSelectedCriteria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputvalue] = useState('');

  const handleChangeCheckbox = (e, criteria) => {
    const isChecked = e.target.checked;
    const cloneState = cloneDeep(listSelectedCriteria);
    if (isChecked) {
      cloneState.unshift(criteria);
      setListSelectedCriteria(cloneState);
    } else {
      setListSelectedCriteria(cloneState.filter((item) => item !== criteria));
    }
  };

  const handleAddNewMatching = async () => {
    try {
      setLoading(true);

      const res = await adminApi.getSelectedCriteria(listSelectedCriteria);
      if (res.status === 200) {
        const isExist = get(res, 'data.result').map((a) => a.criteria);
        dispatch(
          addNewSetMatchingCriteria({
            ...set_matching_criteria[set_index],
            set_name: inputValue,
            is_set_main: false,
            set_number: size(set_matching_criteria) + 1,
            last_update: null,
            status: SET_MATCHING_CRITERIA_STATUS.NOT_AVAILABLE,
            selected_criteria: res.data.result,
            entered_criteria:
              set_matching_criteria[set_index].entered_criteria?.filter((i) =>
                isExist.includes(i.criteria)
              ) || [],
            entered_un_rank:
              set_matching_criteria[set_index].entered_un_rank?.filter((i) =>
                isExist.includes(i.criteria)
              ) || [],
            isDuplicate: true,
          })
        );
        const duplicateRanking = cloneDeep(rankingData);
        duplicateRanking[size(set_matching_criteria)] = rankingData[set_index];
        setRankingData(duplicateRanking);
        setIsNewSetMatching(true);
        setDuplicateSetMatching(true);
        dispatch(updateSetMatchingIndex(size(set_matching_criteria)));
        dispatch(updatePrevSetMatching(null));
        dispatch(updateIsSavedSetMatching(false));
        dispatch(
          duplicateListRanking({
            set_index: size(set_matching_criteria),
            list_ranking: list_ranking[set_index] || [],
          })
        );
        setInputvalue('');
        dispatch(
          pushMessage({
            type: 'success',
            message: `Matching Criteria Set ${set_index + 1}: ${
              set_matching_criteria[set_index].set_name
            } is successfully duplicate to “Matching Criteria Set ${
              size(set_matching_criteria) + 1
            }: ${inputValue}”.`,
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSummary(false);
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      setListSelectedCriteria(
        set_matching_criteria[set_index].selected_criteria.map((item) => item.criteria)
      );
    }
  }, [open, set_index, set_matching_criteria]);

  return (
    <SelectCriteriaModal
      forceRender={true}
      width={700}
      open={open}
      footer={null}
      onCancel={() => setOpen(false)}
    >
      <Spin spinning={loading}>
        <h2 className={styles.mainTitleLevel2}>{`Position: ${position_name}(${position_code})`}</h2>
        <div className={styles.setMatchingName}>
          <h4 className={styles.subTitleLevel2}>Matching Criteria Set Name</h4>
          <input
            placeholder={`Name Your Matching Criteria Set ${size(set_matching_criteria) + 1}`}
            className={styles.inputMatchingField}
            type="text"
            value={inputValue}
            onChange={(e) => {
              setInputvalue(e.target.value);
            }}
            maxLength={20}
          />
        </div>

        <h4 className={styles.subTitleLevel2}>
          Select Matching Criteria
          <p className={styles.subTitle}>Tick on related matching criteria to the position</p>
        </h4>
        <div className={styles.gridLayout}>
          {Object.values(CRITERIA_TYPE).map((item) => (
            <CriteriaCheckbox
              data-testid={item}
              key={item}
              checked={listSelectedCriteria.includes(item)}
              onChange={(e) => {
                handleChangeCheckbox(e, item);
              }}
              disabled={set_matching_criteria[set_index]?.selected_criteria
                .map((i) => i.criteria)
                .includes(item)}
            >
              {item}
            </CriteriaCheckbox>
          ))}
        </div>
        <div className={styles.description}>
          Disclaimer: For criteria External Results and Personality Archetype will not be included
          in AI Recommendation for the time being.
        </div>
        <div className={styles.buttonBar}>
          <BasicButton
            onClick={() => {
              setOpen(false);
              setInputvalue('');
            }}
          >
            Cancel
          </BasicButton>
          <BasicButton
            mode="teal"
            onClick={handleAddNewMatching}
            disabled={!listSelectedCriteria.length || isEmpty(inputValue)}
          >
            Add
          </BasicButton>
        </div>
      </Spin>
    </SelectCriteriaModal>
  );
};

export default AddCriteriaPopOutSetMatchingForDuplicate;
