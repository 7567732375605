export const SPS = {
  CCP_VP_RATIO: 'Ratio = Total of  Unique Successors ÷ Vice President positions',
  CCP_SGM_RATIO: 'Ratio = Total of Unique Successors  ÷ Senior General Manager positions',
  CCP_GM_RATIO: 'Ratio = Total of Unique Successors  ÷ General Manager positions',
  BCP_SGM_RATIO: 'Ratio = Total of Unique Successors  ÷ Senior General Manager positions',
  BCP_GM_RATIO: 'Ratio = Total of Unique Successors  ÷ General Manager positions',
  BCP_SM_RATIO: 'Ratio = Total of Unique Successors  ÷ Senior  Manager positions',
  DUE_TO_ATTRITION:
    'Succession planning of the position which Incumbent of the position will retire or end of contract within end of the next calendar year yet to be reviewed',
  NOT_REVIEWED_UP_3_YEARS:
    'Succession planning of the position that is yet to be reviewed for more than 3 years',
  NEW_POSITION: 'Succession planning of the position that is yet to be conducted yet',
  POSITION_RATIO_DOWN_3:
    'Succession planning in of the position that has less than 3 first line successor',
  NON_SUCCESSORS: 'Top Talent who has yet to be identified as successor to any key position​',
  NOT_REVIEWED_UP_5_YEARS: 'Top Talent who has yet to be reviewed for more than 5 years​',
  YET_TO_BE_REVIEWED: 'New Top Talent who has yet to be reviewed​',
};

export const HISTORICAL_TALENT_OVERVIEW = [
  {
    value: 'Talent Review Status',
    label: 'Talent Review Status',
  },
  {
    value: 'Age and Gender',
    label: 'Age and Gender',
  },
  {
    value: 'Successor',
    label: 'Successor',
  },
  {
    value: 'Nationality',
    label: 'Nationality',
  },
  {
    value: 'Retirement Forecast',
    label: 'Retirement Forecast',
  },
  {
    value: 'Business',
    label: 'Business',
  },
];

export const HISTORICAL_SUCCESSION_PLANNING = [
  {
    value: 'BCP',
    label: 'BCP',
  },
  {
    value: 'CCP',
    label: 'CCP',
  },
];

export const SUCCESSION_PLANNING_DEFAULT_OPTION = ['BCP', 'CCP'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'Septemper',
  'October',
  'November',
  'December',
];

export const HISTORICAL_DASHBOARD_START_IN_YEAR = 2023;

export const HISTORICAL_TALENT_REVIEW_STATUS_TYPES = {
  TALENT_REVIEW_STATUS: 'Talent Review Status',
  AGE_AND_GENDER: 'Age and Gender',
  SUCCESSOR: 'Successor',
  NATIONALITY: 'Nationality',
  RETIREMENT_FORECAST: 'Retirement Forecast',
  BUSINESS: 'Business',
};

export const optionsFilterTalent = [
  { label: 'All Talent', value: 'all talent' },
  { label: 'Top Talent', value: 'top talent' },
  { label: 'Successors', value: 'successors' },
];

export const ROLES_DASHBOARD = ['SGM', 'GM', 'SM', 'M', 'E'];

export const AGE_AND_GENDER_TYPES = {
  LESS_THAN_30: 'lessthan30',
  LESS_THAN_40: 'lessthan40',
  LESS_THAN_50: 'lessthan50',
  LESS_THAN_60: 'lessthan60',
  GREATER_THAN_60: 'greaterthan60',
};

export const RETIREMENT_FORECAST_TYPES = {
  LESS_THAN_1_YEAR: 'lessthan1year',
  LESS_THAN_3_YEAR: 'lessthan3year',
  LESS_THAN_5_YEAR: 'lessthan5year',
};

export const BUSINESS_TYPES = {
  UPSTREAM: 'Upstream',
  DOWNSTREAM: 'Downstream',
  GAS: 'GAS',
  PD_T: 'Project Delivery & Technology',
  CORPORATE: 'Corporate',
  OTHER: 'Others',
};

export const SUCCESSOR_TYPES = {
  BOTH_SUCCESSOR: 'Both_Successor',
  NON_SUCCESSOR: 'Non_Successor',
};

export const GENDER_TYPES = {
  MALE: 'male',
  FEMALE: 'female',
};

export const TALENT_REVIEW_STATUS_TYPES = {
  NO_OF_TALENT_REVIEW_CONDUCTED: 'noOfReviewConducted',
  NON_SUCCESSOR: 'nonSuccessors',
  NON_REVIEW_MORE_THAN_FIVE_YEARS: 'nonReviewMoreThanFiveYears',
  YET_TO_BE_REVIEWED: 'yetToBeReviewed',
};

export const initAgeGender = [
  {
    gender: 'male',
    lessthan30: 0,
    lessthan40: 0,
    lessthan50: 0,
    lessthan60: 0,
    greaterthan60: 0,
    total: 0,
  },
  {
    gender: 'female',
    lessthan30: 0,
    lessthan40: 0,
    lessthan50: 0,
    lessthan60: 0,
    greaterthan60: 0,
    total: 0,
  },
];

export const optionsDemographic = [
  { label: 'Successor', value: 'Successor' },
  { label: 'Nationality', value: 'Nationality' },
  { label: 'Retirement Forecast', value: 'Retirement Forecast' },
  { label: 'Business', value: 'Business' },
];
export const optionsDemographicWithoutPET = [
  { label: 'Successor', value: 'Successor' },
  { label: 'Nationality', value: 'Nationality' },
  { label: 'Retirement Forecast', value: 'Retirement Forecast' },
];

export const initTopTalent = {
  new_top_talents: 0,
  non_review_more_than_five_years: 0,
  non_successors: 0,
  total: 0,
};

export const initialSpSatus = {
  statisticSuccess: { firstLineSuccessors: [], overallPositions: 0, overallRatio: 0 },
  positionDeliberated: { total: 0, total_role: [] },
  keyPositionData: { total: 0, total_role: [] },
};

export const initialTotalRetirement = () => {
  return {
    attrition: { total: 0, total_role: [] },
    new_position: { total: 0, total_role: [] },
    not_review: { total: 0, total_role: [] },
    position_ratio: { total: 0, total_role: [] },
  };
};
