import { Input } from 'antd';
import React from 'react';
import styles from './form-text-area.module.scss';
const { TextArea } = Input;

const FromTextArea = (props) => {
  const {
    label,
    messageErr,
    required,
    placeholder,
    value,
    setFormData,
    isError,
    borderColor,
    rows,
  } = props;

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.label}>{label}</span>
        <span style={{ color: '#FF787F' }}>{required && '*'}</span>
      </div>
      <div>
        <TextArea
          rows={rows || 4}
          placeholder={placeholder}
          style={{
            width: '100%',
            borderColor: `${borderColor}`,
            borderRadius: '4px ',
          }}
          value={value}
          onChange={(e) => setFormData && setFormData(e.target.value)}
        />
      </div>
      <div className={styles.error}>{isError && messageErr}</div>
    </div>
  );
};

export default FromTextArea;
