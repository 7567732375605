import { Dropdown, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { deleteNote, edit_delete, editNote } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import * as SC from '../../ManagingCriticalPosition/DataTable/styled';
import * as styles from './data-table.module.scss';

export default function DataTable({ data, setDeleteRecord }) {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data.length) setCurrentPage(1);
  }, [data]);

  const handleClickEditBtn = () => {
    history.push(
      `/admin/sp-management/approved-succession-planning/${selectedRecord?.position_code}/${
        selectedRecord?.approved_date && moment(selectedRecord.approved_date).format('YYYY-MM-DD')
      }`
    );
  };

  const items = [
    {
      key: '0',
      icon: <img src={editNote} alt="edit" />,
      label: (
        <div style={{ textDecoration: 'none' }} className={styles.editButton}>
          Edit
        </div>
      ),
      onClick: () => handleClickEditBtn(),
    },
    {
      key: '1',
      icon: <img src={deleteNote} alt="delete" />,
      label: <div className={styles.deleteButton}>Delete</div>,
      onClick: () => {
        setShowDeleteModal(true);
      },
    },
  ];

  const menuProps = {
    items,
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(currentPage - 1) * 15 + 1 + index}</span>
      ),
    },
    {
      title: 'Position Code',
      dataIndex: 'position_code',
      width: '25%',
    },
    {
      title: 'Position Name',
      dataIndex: 'plan_name',
      width: '40%',
    },
    {
      title: 'Approved Date',
      dataIndex: 'approved_date',
      width: '20%',
      align: ' center',
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
    },
  ];

  const handleShowTotalChange = (total, range) => {
    return (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    );
  };

  const paginationConfig = {
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: 15,
    onChange: (pageNum) => pageNum !== currentPage && setCurrentPage(pageNum),
    showTotal: (total, range) => handleShowTotalChange(total, range),
  };

  return (
    <div style={{ marginTop: 24 }}>
      <SC.TableManagement>
        <Table
          columns={columns}
          dataSource={data.map((item, index) => ({
            key: item.id,
            position_code: <span className={styles.positionCode}>{item.position_code}</span>,
            plan_name: <span className={styles.positionName}>{item.plan_name}</span>,
            approved_date: (
              <span className={styles.approvedDate}>
                {moment(item.approved_date).format('DD/MM/YYYY')}
              </span>
            ),
            more: (
              <Dropdown
                menu={menuProps}
                trigger={['click']}
                style={{ minWidth: 110 }}
                placement="bottomRight"
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Space style={{ cursor: 'pointer' }}>
                  <img
                    src={edit_delete}
                    alt="dot"
                    onKeyDown={() => {}}
                    onClick={() => setSelectedRecord(item)}
                  />
                </Space>
              </Dropdown>
            ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
      <ModelTC
        info={{
          type: 'deleteApprovedSuccessionPlanning',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => setShowDeleteModal(false),
          handleSubmit: () => {
            setDeleteRecord(selectedRecord);
            setShowDeleteModal(false);
          },
        }}
      />
    </div>
  );
}
