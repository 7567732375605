import { PERMISSION } from '../utils/constants';
import axiosClient from './axiosClient';

export const reportBuilderApi = {
  searchReportBuilder: (params, role) => {
    const { keyword, page } = params;
    const url = `/report-builder/get-list?search=${keyword}&page=${page}`;
    return axiosClient.get(url, {
      headers: { role, permission: PERMISSION.REPORT_BUILDER },
    });
  },

  createReport: (body) => {
    const url = `/report-builder/create`;
    return axiosClient.post(url, body);
  },

  deleteReportBuilder: (ids) => {
    const url = `/report-builder/delete-rb`;
    return axiosClient.delete(url, {
      data: {
        ids,
      },
    });
  },

  getReportDetails: (reportId, role) => {
    const url = `/report-builder/get-details/${reportId}`;
    return axiosClient.get(url, { headers: { role, permission: PERMISSION.REPORT_BUILDER } });
  },

  deleteTalent: (talent_ids) => {
    const url = `/report-builder/report-builder-talent`;
    return axiosClient.delete(url, {
      data: {
        talent_ids,
      },
    });
  },

  getListColumnManual: (reportId, roleId) => {
    const url = `/report-builder/get-column-manual/${reportId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  updateReport: (body) => {
    const url = `/report-builder/update`;
    return axiosClient.put(url, body);
  },

  getTalentsDetail: (staffIds, roleActive) => {
    const url = `/report-builder/get-talent-info?staff_ids=${staffIds}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  updateColumnName: (body) => {
    const url = `/report-builder/update-column`;
    return axiosClient.put(url, body);
  },

  getFinderFilterOptions: (reportId, roleId) => {
    const url = `/report-builder/get-filter-columns/${reportId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getColumnsByFinderFilter: (body) => {
    const url = `/report-builder/get-details/by-filter`;
    return axiosClient.post(url, body);
  },

  getColumnDataManual: (params, roleId) => {
    const url = `report-builder/get-data-manual-column`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params,
    });
  },

  getExistingManualColumn: (params, roleActive) => {
    const url = `/report-builder/get-list-existing`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
      params,
    });
  },

  getColumnPermission: () => {
    const url = `/report-builder/column-permission`;
    return axiosClient.get(url);
  },
};
