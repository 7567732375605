import PropTypes from 'prop-types';
import React from 'react';
import { avatar_not_found } from '../../../../assets/img';
import { ListOption } from '../../../../components';
import {
  decodeBase64ToImage,
  getPositionAndStaffId,
  handleShowPosition,
  handleShowTalent,
} from '../../../../utils/helper';
import * as styles from './mobility-search-position-option.module.scss';

const MobilitySearchPositionOption = (props) => {
  const { data, loading, debounceSearchQuery, setSearchTerm, isAddPosition } = props;

  const handleSelectOption = (item) => {
    let result = isAddPosition ? handleShowPosition(item) : handleShowTalent(item);
    let query = getPositionAndStaffId(item);
    setSearchTerm({ value: item, type: 'submit', showTerm: result, query: query });
  };

  const handleShowListItems = () => {
    return data.map((item, index) => {
      return (
        <li
          data-testid={`${index}-pos`}
          className={styles.search_item}
          key={index}
          onKeyDown={() => {}}
          onClick={() => handleSelectOption(item)}
        >
          <div className={styles.individual}>
            <div className={styles.avatar}>
              <img
                alt="avatar_not_found"
                src={
                  decodeBase64ToImage(isAddPosition ? item?.photo : item?.image) || avatar_not_found
                }
              />
            </div>
            <div className={styles.info}>
              <p className={styles.name}>
                {(isAddPosition ? item?.staff_name : item?.birthName) || 'N/A'}
              </p>
              <p>{(isAddPosition ? item?.staff_id : item?.staffId) || 'N/A'}</p>
              <p>{item?.position_name || 'N/A'}</p>
              <p>
                {item?.division || 'N/A'}, {item?.business || 'N/A'}
              </p>
              <p>{item?.company || 'N/A'}</p>
            </div>
          </div>
        </li>
      );
    });
  };
  return (
    <ListOption
      loading={loading}
      data={data}
      debounceSearchQuery={debounceSearchQuery}
      handleShowListItems={handleShowListItems}
    />
  );
};

MobilitySearchPositionOption.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  debounceSearchQuery: PropTypes.object,
  setSearchTerm: PropTypes.func,
  isAddPosition: PropTypes.bool,
};

export default MobilitySearchPositionOption;
