import { Spin } from 'antd';
import { cloneDeep, isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicButton } from '../../../../../../assets/common';
import { adminApi } from '../../../../../../services/admin';
import { CRITERIA_TYPE, SET_MATCHING_CRITERIA_STATUS } from '../../../../../../utils/constants';
import {
  addNewSetMatchingCriteria,
  updateIsEditCriteria,
  updateIsEditSetMatching,
  updateIsFirstInitSetMatching,
  updateIsSavedSetMatching,
  updateIsValidForm,
  updateMainMatching,
  updatePrevSetMatching,
  updateSelectedCriteria,
  updateSetMatchingByIndex,
  updateSetMatchingIndex,
  updateSetMatchingName,
} from '../../../redux/manageCriteriaSetMatchingSlice';
import * as styles from './add-criteria-pop-out.module.scss';
import { CriteriaCheckbox, SelectCriteriaModal } from './styled';

const AddCriteriaPopOutSetMatching = (props) => {
  const {
    open,
    setOpen,
    position_code,
    position_name,
    isNewSetMatching,
    setIsSummary,
    setIsNewSetMatching,
  } = props;

  const dispatch = useDispatch();
  const { set_index, set_matching_criteria, prevSetMatching } =
    useSelector((state) => state.app.adminModule.manageCriteriaSetMatching) || [];

  const [listSelectedCriteria, setListSelectedCriteria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputvalue] = useState('');

  const handleChangeCheckbox = (e, criteria) => {
    const isChecked = e.target.checked;
    const cloneState = cloneDeep(listSelectedCriteria);
    if (isChecked) {
      cloneState.unshift(criteria);
      setListSelectedCriteria(cloneState);
    } else {
      setListSelectedCriteria(cloneState.filter((item) => item !== criteria));
    }
  };

  const handleAddNewMatching = async () => {
    try {
      setLoading(true);
      const res = await adminApi.getSelectedCriteria(listSelectedCriteria);
      if (res.status === 200) {
        setOpen(false);
        if (isNewSetMatching) {
          if (!isEmpty(prevSetMatching)) {
            dispatch(updateSetMatchingByIndex(prevSetMatching.set_matching_criteria[set_index]));
          }
          dispatch(
            addNewSetMatchingCriteria({
              set_name: inputValue,
              is_set_main: size(set_matching_criteria) < 1,
              set_as_main: size(set_matching_criteria) < 1,
              set_number: size(set_matching_criteria) + 1,
              start_date: null,
              end_date: null,
              status: SET_MATCHING_CRITERIA_STATUS.NOT_AVAILABLE,
              selected_criteria: res.data.result,
            })
          );
          dispatch(
            updateSetMatchingIndex(
              size(set_matching_criteria) > 0 ? size(set_matching_criteria) : 0
            )
          );
          dispatch(updateIsFirstInitSetMatching(true));
          dispatch(
            updateIsEditSetMatching({ set_index: size(set_matching_criteria), value: true })
          );
          dispatch(updatePrevSetMatching(null));
          dispatch(updateIsSavedSetMatching(false));
          dispatch(
            updateMainMatching({
              set_index: size(set_matching_criteria),
              value: size(set_matching_criteria) < 1,
            })
          );
        } else {
          dispatch(updateSetMatchingName(inputValue));
          dispatch(updateSetMatchingIndex(set_index));
          dispatch(updateIsEditSetMatching({ set_index, value: true }));
          dispatch(updateIsEditCriteria({ set_index, value: true }));
          dispatch(updateSelectedCriteria(res.data.result));
          dispatch(updateIsFirstInitSetMatching(false));
          dispatch(updatePrevSetMatching(null));
        }

        dispatch(updateIsValidForm(true));
        setInputvalue('');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSummary(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setListSelectedCriteria(
        !isNewSetMatching
          ? set_matching_criteria[set_index].selected_criteria.map((item) => item.criteria)
          : []
      );
      setInputvalue(!isNewSetMatching ? set_matching_criteria[set_index].set_name : '');
    }
  }, [open, set_index, set_matching_criteria, isNewSetMatching]);

  const handleCancel = () => {
    setOpen(false);
    setInputvalue('');
    if (setIsNewSetMatching) {
      setIsNewSetMatching(false);
    }
  };

  return (
    <SelectCriteriaModal
      forceRender={true}
      width={700}
      open={open}
      footer={null}
      onCancel={() => {
        handleCancel();
      }}
    >
      <Spin spinning={loading}>
        <h2 className={styles.mainTitleLevel2}>{`Position: ${position_name}(${position_code})`}</h2>
        <div className={styles.setMatchingName}>
          <h4 className={styles.subTitleLevel2}>Matching Criteria Set Name</h4>
          <input
            placeholder={`Name Your Matching Criteria Set ${size(set_matching_criteria) + 1}`}
            className={styles.inputMatchingField}
            type="text"
            value={inputValue}
            onChange={(e) => {
              setInputvalue(e.target.value);
            }}
            maxLength={20}
          />
        </div>

        <h4 className={styles.subTitleLevel2}>
          Select Matching Criteria
          <p className={styles.subTitle}>Tick on related matching criteria to the position</p>
        </h4>
        <div className={styles.gridLayout}>
          {Object.values(CRITERIA_TYPE).map((item) => (
            <CriteriaCheckbox
              data-testid={item}
              key={item}
              checked={listSelectedCriteria.includes(item)}
              onChange={(e) => {
                handleChangeCheckbox(e, item);
              }}
              disabled={
                !isNewSetMatching &&
                set_matching_criteria[set_index]?.selected_criteria
                  .map((i) => i.criteria)
                  .includes(item)
              }
            >
              {item}
            </CriteriaCheckbox>
          ))}
        </div>
        <div className={styles.description}>
          Disclaimer: For criteria External Results and Personality Archetype will not be included
          in AI Recommendation for the time being.
        </div>
        <div className={styles.buttonBar}>
          <BasicButton onClick={() => handleCancel()}>Cancel</BasicButton>
          <BasicButton
            mode="teal"
            onClick={handleAddNewMatching}
            disabled={
              (isNewSetMatching && !listSelectedCriteria.length) ||
              (isNewSetMatching && isEmpty(inputValue))
            }
          >
            Add
          </BasicButton>
        </div>
      </Spin>
    </SelectCriteriaModal>
  );
};

export default AddCriteriaPopOutSetMatching;
