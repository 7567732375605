import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

import styles from './career-journey-detail.module.scss';

const CareerJourneyDetail = ({ data }) => {
  return (
    <div
      className={styles.detaiWrapper}
      style={data && data.length <= 6 ? { overflowX: 'initial' } : { overflowX: 'auto' }}
    >
      {!isEmpty(data) &&
        data.map((item) => {
          return (
            <div
              className={styles.cardDetailItem}
              style={data.length <= 6 ? { flex: '1 1 0', maxWidth: 'initial' } : {}}
              key={item.role}
            >
              <div className={styles.roleName}>{item.role}</div>
              <div className={styles.durationLabel}>{item.duration}</div>
              <div className={styles.roleLabels}>
                {item?.position_list && (
                  <ul>
                    {item.position_list.map((pos, index) => {
                      return (
                        <li key={index}>
                          <Tooltip
                            className={styles.customTooltip}
                            overlayClassName={'customTooltip'}
                            overlayStyle={{ minWidth: '320px' }}
                            title={
                              <div className={styles.tooltipContent}>
                                <span>
                                  {pos.position_name}{' '}
                                  {pos.position_code && `(${pos.position_code})`}
                                </span>
                                <ul>
                                  {pos.department && <li>Department: {pos.department}</li>}
                                  {pos.division && <li>Division: {pos.division}</li>}
                                  {pos.opu && <li>OPU: {pos.opu}</li>}
                                  {pos.business && <li>Business: {pos.business}</li>}
                                </ul>
                              </div>
                            }
                            color={'black'}
                            key={'black'}
                            overlayInnerStyle={{ borderRadius: '6px' }}
                          >
                            {pos.position_name}
                          </Tooltip>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CareerJourneyDetail;
