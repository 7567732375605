import React from 'react';
import { meeting_detail_triangle } from '../../../../assets/img';
import { useComponentVisible } from '../../../../hooks';
import PopupDetail from '../../components/PopupDetail/PopupDetail';

const DetailList = (props) => {
  const { total, currentDate, limit } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <div className="container-popup" ref={ref}>
      <div className="add-more" onKeyDown={() => {}} onClick={() => setIsComponentVisible(true)}>
        And {total - 2} more
      </div>
      {isComponentVisible && (
        <>
          <PopupDetail
            setIsComponentVisible={setIsComponentVisible}
            currentDate={currentDate}
            limit={limit}
            {...props}
          />
          <div className="meeting-path">
            <img src={meeting_detail_triangle} alt="" />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailList;
