import React, { useState, useRef, useEffect } from 'react';
import styles from './dropdown-custom-employee.module.scss';
import Rectangle from '../../assets/img/Rectangle.svg';

const DropdownCustomEmployee = ({ selected, setSelected, options, type }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={`${styles.dropdownBtn} ${type ? styles.dropdownBtnSm : styles.dropdownBtnLg}`}
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
      >
        <p className={styles.dropdownSelect__label}>{selected?.value}</p>
        <img src={Rectangle} alt="" />
      </div>

      <div
        className={`${styles.dropdownContent} ${
          type ? styles.dropdownContentSm : styles.dropdownContentLg
        }`}
      >
        {isActive &&
          options &&
          options.map((option) => {
            return (
              <div
                key={option.id}
                onKeyDown={() => {}}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={`${styles.dropdownItem} ${
                  type ? styles.dropdownItemSm : styles.dropdownItemLg
                }`}
              >
                {option.value}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DropdownCustomEmployee;
