import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiButton } from '../../../../../assets/common';
import { delete_ms_from_agenda } from '../../../../../assets/img';
import { AiDesc, AiModel, AiTitle } from './Styled';

const configure = {
  confirmToDelete: {
    title: '',
    desc: `You can't undo this action.`,
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
};

const ConfirmDelete = (dataModal, { ...rest }) => {
  let { info } = dataModal;
  const [itemIncumbent, setItemIncumbent] = useState({
    title: '',
    desc: '',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: false,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: '',
    img: '',
  });

  useEffect(() => {
    if (info.title && info.desc) {
      setItemIncumbent({ ...configure[info.type], title: info.title, desc: info.desc });
      return;
    }
    setItemIncumbent(configure[info.type]);
  }, [info.type, info.title, info.desc]);

  const handleClose = () => {
    info.setVisible(null);
    info.onClose && info.onClose();
  };

  return (
    <AiModel
      {...rest}
      centered
      open={info.visible}
      width={1283}
      height={670}
      footer={null}
      onCancel={() => {
        info.setVisible(null);
        info.onClose && info.onClose();
      }}
      zIndex={2000}
    >
      <Row align="middle">
        <Col span={12}>
          <AiTitle style={{ width: '430px' }}>{itemIncumbent.title}</AiTitle>
          <AiDesc>{itemIncumbent.desc}</AiDesc>
          <div style={{ width: '430px' }} className="d-flex justify-content-end">
            <AiButton
              border={`${itemIncumbent?.borderCancel}`}
              mode={itemIncumbent.modeCancel}
              onClick={handleClose}
              disabled={info.loading}
              data-testid="btn-cancel-modelTC"
              style={{
                color: '#000',
              }}
            >
              {itemIncumbent.btnCancel}
            </AiButton>
            <AiButton
              border={`${itemIncumbent?.borderSubmit}`}
              mode={itemIncumbent.modeSubmit}
              className="ml-2"
              onClick={() => info.handleSubmit()}
              disabled={info.loading}
              loading={info.loading}
              data-testid="btn-submit-modelTC"
              style={{
                backgroundColor: '#da2228',
                color: '#fff',
              }}
            >
              {itemIncumbent.btnSubmit}
            </AiButton>
          </div>
        </Col>
        <Col span={12}>
          <img src={itemIncumbent.img} alt="img" />
        </Col>
      </Row>
    </AiModel>
  );
};
export default ConfirmDelete;
