import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import { BasicButton } from '../../../../../../../assets/common';
import {
  calendar_meeting_builder,
  circle_checked,
  next_icon,
  prev_icon,
  super_next_icon,
  super_prev_icon,
} from '../../../../../../../assets/img';
import FormInput from '../../../../../../../components/Form/FormInput/FormInput';
import { DATE_TIME_FORMAT } from '../../../../../../../utils/constants';

const { RangePicker } = DatePicker;

const Picker = styled.div`
  height: 46px;
  margin-top: 5px;
  margin: 4px 10px 0;
  position: relative;

  .custom-range-picker {
    height: 100%;
    border-radius: 6px;
  }
  .ant-picker-input {
    padding-left: 20px !important;
  }
  .checked {
    position: absolute;
    left: 10px;
    top: 11px;
    z-index: 10;
  }
  .ant-picker-header-view {
    white-space: nowrap;
    color: var(--emphasis-body-n-800, #3f3c4c);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .image-custom {
    padding: 8px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .ant-picker-header,
  .ant-picker-body {
    border: none;
  }

  .footer {
    margin: 10px;
    display: flex;
    justify-content: end;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba(52, 69, 99, 0.2);
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    background: rgba(52, 69, 99, 0.2);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(
      .ant-picker-cell-range-start
    )
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(
      .ant-picker-cell-range-end
    )
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50%;
    background: #00a19c;
  }
`;

const MenuBarMeetingOptionBuilder = (props) => {
  const { query, setQuery, getResult } = props;
  const [keySearch, setKeySearch] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [open, setOpen] = useState(false);

  function getResultBySearch() {
    if (
      query.search === keySearch &&
      query.page === 1 &&
      query.from_date === dateRange[0] &&
      query.from_date === dateRange[1]
    ) {
      getResult();
    } else {
      setQuery((prev) => ({
        ...prev,
        search: keySearch,
        page: 1,
        from_date: dateRange[0],
        to_date: dateRange[1],
      }));
    }
  }

  const onSelectDateRange = () => {
    if (!dateRange.includes(null)) {
      setQuery((prev) => ({ ...prev, from_date: dateRange[0], to_date: dateRange[1] }));
    }
    setOpen(false);
  };

  const onChange = (date, dateString) => {
    setDateRange([dateString[0] || null, dateString[1] || null]);
  };

  const onCancel = () => {
    setDateRange([query.from_date, query.to_date]);
    setOpen(false);
  };

  return (
    <div className="d-flex justify-content-start">
      <div style={{ width: '500px' }}>
        <FormInput
          value={keySearch}
          placeholder="Search by Meeting Name or Position Code"
          setFormData={setKeySearch}
          borderColor="#D3DEE8"
        />
      </div>
      <>
        {open === false && dateRange.includes(null) ? (
          <img
            src={calendar_meeting_builder}
            className="filter"
            alt="Filter"
            height={57}
            onKeyDown={() => {}}
            onClick={() => setOpen(true)}
          />
        ) : (
          <Picker>
            {open === false && !dateRange.includes(null) && (
              <div className="checked">
                <img src={circle_checked} alt="" />
              </div>
            )}
            <RangePicker
              open={open}
              format={DATE_TIME_FORMAT.DATE_SPACE}
              value={[
                dateRange[0] ? moment(dateRange[0], DATE_TIME_FORMAT.DATE_SPACE) : null,
                dateRange[1] ? moment(dateRange[1], DATE_TIME_FORMAT.DATE_SPACE) : null,
              ]}
              onChange={onChange}
              allowClear={false}
              getPopupContainer={(trigger) => trigger.parentElement}
              renderExtraFooter={(mode) => (
                <>
                  {mode === 'date' && (
                    <div className="footer">
                      <BasicButton
                        mode="border-teal"
                        onKeyDown={() => {}}
                        onClick={() => onCancel()}
                        style={{ marginRight: '10px' }}
                      >
                        Cancel
                      </BasicButton>
                      <BasicButton
                        mode="teal"
                        onKeyDown={() => {}}
                        onClick={() => onSelectDateRange()}
                      >
                        Apply
                      </BasicButton>
                    </div>
                  )}
                </>
              )}
              onKeyDown={() => {}}
              onClick={() => setOpen(true)}
              className="custom-range-picker"
              nextIcon={<img src={next_icon} className="image-custom mr-2" alt="" />}
              prevIcon={<img src={prev_icon} className="image-custom" alt="" />}
              superNextIcon={<img src={super_next_icon} className="image-custom " alt="" />}
              superPrevIcon={<img src={super_prev_icon} className="image-custom  mr-2" alt="" />}
            />
          </Picker>
        )}
      </>

      <div className="button" onKeyDown={() => {}} onClick={getResultBySearch}>
        Search
      </div>
    </div>
  );
};

export default MenuBarMeetingOptionBuilder;
