import { AutoComplete, Empty, Spin, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BasicAutoComplete, BasicButton } from '../../../../../../assets/common';
import { advanced_search_closed, advanced_search_opened, avatar, external_talent } from '../../../../../../assets/img';
import CustomAntModal from '../../../../../../components/CustomAntModal/CustomAntModal';
import { meetingApi } from '../../../../../../services/tcMeeting';
import { decodeBase64ToImage } from '../../../../../../utils/helper';
import styles from './add-talent.module.scss';
import AdvancedSearchTalent from './AdvancedSearchTalent';

const { Option } = AutoComplete;

const customStyleModal = {
  titleColor: '#344563',
  titleFontWeight: 500,
  titleFontSize: 20,
  containerPaddingTopBottom: 40,
  headerPaddingBottom: 40,
  closePosition: [30, 40],
};
const LIMIT_ROWS = 5;
const NotFoundContent = () => (
  <Spin spinning={true} size="small">
    <div className="py-2" />
  </Spin>
);

const AddTalent = (props) => {
  const { isVisible, setIsVisible, addItem, positionsCode, currentLine } = props;
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [openAdvanced, setOpenAdvanced] = useState(false);
  const [searchParams, setSearchParams] = useState({ keyword: '', page: 1, searching: false });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState({});

  const isFetchAll = useMemo(() => {
    const total = data?.[0]?.total;
    return searchParams.page * LIMIT_ROWS >= total;
  }, [data, searchParams.page]);

  const fetchAPI = useCallback(
    async (params, setData, setLoading, isAdvanced = 0) => {
      const {
        keyword,
        page,
        limit,
        staff_name,
        staff_id,
        position_code,
        position_name,
        filter_search_result,
      } = params;
      if (!isAdvanced && !keyword) {
        setData([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const searchParams = {
          not_in_position_codes: positionsCode.toString(),
          keyword,
          advanced_search: isAdvanced,
          staff_id,
          staff_name,
          position_code,
          position_name,
          filter_search_result,
          page,
          limit,
        };
        const response = await meetingApi.addTalentOutOfAi(searchParams, roleId);
        if (response.status !== 200) throw Error();
        const result = response.data.result;
        if (!isAdvanced && page !== 1) {
          setData((prev) => [...prev, ...result]);
        } else {
          setData(result);
        }
      } catch (error) {
        setData([]);
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [positionsCode, roleId]
  );

  const handleClickAdvanced = () => {
    setOpenAdvanced(!openAdvanced);
  };

  const handleClickAdd = () => {
    setIsVisible(false);
    addItem(value);
  };

  const handleSearch = debounce((keyword) => {
    setValue({});
    setData([]);
    setSearchParams({ keyword, page: 1, searching: true });
  }, 500);

  const handleSelect = (id) => {
    const item = data.find((item) => item.staff_id === id);
    if (item) setValue(item);
  };

  const handleScroll = (event) => {
    if (loading || isFetchAll) return;
    const {
      target: { scrollTop, offsetHeight, scrollHeight },
    } = event;
    const isSearch = scrollTop + offsetHeight >= scrollHeight - 10;
    if (isSearch) {
      setSearchParams((prev) => ({ ...prev, page: prev.page + 1, searching: true }));
    }
  };

  useEffect(() => {
    if (!searchParams.searching) return;
    fetchAPI({ ...searchParams, limit: LIMIT_ROWS }, setData, setLoading);
    setSearchParams((prev) => ({ ...prev, searching: false }));
  }, [fetchAPI, searchParams]);

  return (
    <>
      <CustomAntModal
        hideDefaultBtn
        open={isVisible}
        setOpen={setIsVisible}
        title="Name / Staff ID"
        styles={customStyleModal}
        width={openAdvanced ? 1200 : 600}
      >
        <div className={styles.basicSearch}>
          <BasicAutoComplete
            placeholder="Search by Name or Staff ID"
            getPopupContainer={(trigger) => trigger.parentElement}
            virtual={false}
            showSearch
            disabled={openAdvanced}
            filterOption={false}
            notFoundContent={loading ? <NotFoundContent /> : null}
            value={value.birth_name || (value.staff_id ? `Staff ID: ${value.staff_id}` : undefined)}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onPopupScroll={handleScroll}
          >
            {!isEmpty(data) ? (
              <>
                {data.map((d, index) => (
                  <Option
                    key={index}
                    data-key={index}
                    value={d.staff_id}
                    style={{ borderBottom: '1px solid #EEEEEE' }}
                  >
                    <div className={styles.option}>
                      <div className={styles.avatar}>
                        <img src={decodeBase64ToImage(d.image) || avatar} alt="avatar" />
                      </div>
                      <div className={styles.external_talent}>
                        <div>
                          <strong>{d.birth_name}</strong>
                        </div>
                        <div>Staff ID: {d.staff_id}</div>
                        <div>
                          {d.position_name} {d.position_name && ','} {d.business_unit}{' '}
                          {d?.external_talent && (
                            <img src={external_talent} alt="external_talent" />
                          )}
                        </div>
                      </div>
                    </div>
                  </Option>
                ))}
                {loading && (
                  <Option value="null" disabled>
                    <NotFoundContent />
                  </Option>
                )}
              </>
            ) : (
              loading === false && (
                <Option value="null" disabled>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Option>
              )
            )}
          </BasicAutoComplete>
          <Tooltip
            placement={'top'}
            title="Advanced Search"
            overlayClassName={styles.tooltipContainer}
          >
            <span className={styles.advancedIcon}  onKeyDown={() => {}} onClick={handleClickAdvanced}>
              {openAdvanced ? (
                <img src={advanced_search_opened} alt="advanced_search_opened" />
              ) : (
                <img src={advanced_search_closed} alt="advanced_search_closed" />
              )}
            </span>
          </Tooltip>
        </div>

        {openAdvanced && (
          <AdvancedSearchTalent
            limit={LIMIT_ROWS}
            fetchData={fetchAPI}
            currentLine={currentLine}
            setIsVisible={setIsVisible}
          />
        )}
        {!openAdvanced && (
          <div className="d-flex justify-content-end mt-3">
            <BasicButton mode="teal" disabled={!value.staff_id}  onKeyDown={() => {}} onClick={handleClickAdd}>
              Add
            </BasicButton>
          </div>
        )}
      </CustomAntModal>
    </>
  );
};

export default AddTalent;
