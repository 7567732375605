import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { full_arrow_top, sp_trash, tooltip_green_icon } from '../../../../assets/img';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  MESSAGE_TYPES,
  MS_AGENDA_DISCLAIMER,
  SP_NOTES_PERFORMANCE_RATING,
} from '../../../../utils/constants';
import { convertPositionSimulation, convertTalentSimulation } from '../../../../utils/helper';
import MobilityPositionCard from '../MobilityPositionCard/MobilityPositionCard';
import * as styles from './mobility-chain-simulation-agenda-vertical.module.scss';

const MobilityChainSimulationAgendaVertical = (props) => {
  const {
    data,
    handleIsMain,
    isMain,
    handleEditSimulation,
    handleDeleteSimulation,
    isHiddenEditMSField,
    isShowRemark,
    agendaDetail,
    setShowAddRemarkSuccess,
  } = props;

  const dispatch = useDispatch();
  const listChain = useMemo(() => {
    let positions = [];
    let talents = [];
    if (!isEmpty(data.position)) {
      positions = data.position.map((item) => {
        return convertPositionSimulation(item);
      });
    }
    if (!isEmpty(data.talents)) {
      talents = data.talents.map((item) => {
        return convertTalentSimulation(item);
      });
    }
    return [...positions, ...talents];
  }, [data]);

  const wrapperRef = useRef(null);
  const isLastItem = (array, index) => {
    return array.length === index + 1;
  };

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {!isHiddenEditMSField && (
        <button
          type="button"
          className={styles.deleteBtn}
          onKeyDown={() => {}}
          onClick={() => handleDeleteSimulation(data.mbsm_id)}
        >
          <img src={sp_trash} alt="sp_trash" className={styles.sp_trash_top} />
        </button>
      )}
      <div className={styles.mainTitleWrapper}>
        <h3 className={styles.mainTitle}>
          {data.name}
          <span
            onKeyDown={() => {}}
            onClick={() =>
              dispatch(
                pushMessage({
                  message: SP_NOTES_PERFORMANCE_RATING,
                  type: MESSAGE_TYPES.NORMAL,
                  timeShow: 0,
                })
              )
            }
          >
            <Tooltip
              className={styles.customTooltip}
              overlayStyle={{ minWidth: '317px' }}
              title={
                <div className={styles.tooltipContent}>
                  <span>{MS_AGENDA_DISCLAIMER[0].label}</span>
                </div>
              }
              color={'white'}
              key={'white'}
              overlayInnerStyle={{ borderRadius: '6px' }}
            >
              <img className={styles.tooltipIcon} src={tooltip_green_icon} alt="" />
            </Tooltip>
          </span>
        </h3>
        {!isHiddenEditMSField && (
          <label className={styles.radioContainer}>
            Set as Main
            <input
              type="radio"
              name="setAsMain"
              onChange={() => handleIsMain(data.mbsm_id)}
              checked={isMain}
            />
            <span className={styles.radioCheckmark}></span>
          </label>
        )}
      </div>
      <div className={styles.btnWrapper}>
        {!isHiddenEditMSField && (
          <button
            type="button"
            className={styles.editBtn}
            onKeyDown={() => {}}
            onClick={() => handleEditSimulation(data.mbsm_id)}
          >
            Edit Mobility Simulation
          </button>
        )}
      </div>
      <div className={`${styles.row}`}>
        {listChain.map((card, indexCard) => {
          return (
            <React.Fragment key={indexCard}>
              <div className={styles.card} style={{ maxWidth: 'auto' }}>
                <>
                  <MobilityPositionCard
                    data={card}
                    isAutoWidth={true}
                    isShowRemark={isShowRemark}
                    agendaDetail={agendaDetail}
                    setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                  />
                  {!isLastItem(listChain, indexCard) && (
                    <img src={full_arrow_top} alt="full_arrow_top" className={styles.arrowTop} />
                  )}
                </>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MobilityChainSimulationAgendaVertical;
