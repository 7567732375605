import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BasicAutoComplete,
  BasicButton,
  BasicErrorText,
  BasicLabel,
  BasicLoading,
} from '../../../../../assets/common';
import { avatar } from '../../../../../assets/img';
import CustomAntModal from '../../../../../components/CustomAntModal/CustomAntModal';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../../utils/helper';
import {
  ADD_TALENT_FORM_TYPE,
  PROFILE_MANAGEMENT_MESSAGES,
  SEARCH_INPUT_PLACEHOLDER,
} from '../../constant';
import * as styles from './add-talent-form.module.scss';

const AddTalentForm = ({ open, setOpen, fetchData, type }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState(false);
  const [adding, setAdding] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState();
  const roleActive = useSelector((state) => state.user.roleActive);

  const customStyle = {
    containerPaddingLeftRight: 76,
    containerPaddingTopBottom: 41,
    titleFontSize: 18,
    titleFontWeight: 600,
    borderRadius: 6,
    closePosition: 27,
  };

  const functionByType = useMemo(() => {
    switch (type) {
      case ADD_TALENT_FORM_TYPE.LIST_PROFILES:
        return {
          search: adminApi.searchTalentsListProfiles,
          add: adminApi.addToListProfiles,
          message: PROFILE_MANAGEMENT_MESSAGES.LIST_PROFILE_ADD_SUCCESS,
          value: 'birth_name',
        };
      case ADD_TALENT_FORM_TYPE.UNSEARCHABLE_LIST:
        return {
          search: adminApi.searchTalentUnsearchable,
          add: adminApi.addUnsearchableTalentList,
          message: PROFILE_MANAGEMENT_MESSAGES.UNSEARCHABLE_ADD_SUCCESS,
          value: 'staff_id',
        };
      default:
        return;
    }
  }, [type]);

  const renderOptions = (data) => {
    return data.map((item) => {
      return {
        item: item,
        value: item[functionByType.value] || item.staff_id,
        label: (
          <div className={styles.optWrapper}>
            <div className={styles.optLeft}>
              <img src={decodeBase64ToImage(item.photo) || avatar} alt="avatar" />
            </div>
            <div className={styles.optRight}>
              <div className={styles.optName}>{item.birth_name}</div>
              <div className={styles.optText}>{item.opu_name}</div>
              <div className={styles.optText}>{item.sg}</div>
            </div>
          </div>
        ),
      };
    });
  };

  const onSearch = async (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    try {
      setSearching(true);
      const res = await functionByType.search(searchText);
      if (res.status === 200) {
        setOptions(renderOptions(res.data.result));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  };

  const onSelect = (_, options) => {
    setData(options.item);
  };

  const onChange = () => {
    setData(null);
    error && setError(false);
  };

  const handleAdd = async () => {
    try {
      setAdding(true);
      const res = await functionByType.add(data.staff_id, roleActive?.roleId);
      if (res.status === 200) {
        fetchData();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${data.birth_name || ''} ${functionByType.message}`,
          })
        );
        setOpen(false);
      }
    } catch (error) {
      if (error.response.status === 500 && type === ADD_TALENT_FORM_TYPE.UNSEARCHABLE_LIST) {
        setError(true);
        setData(null);
      }
    } finally {
      setAdding(false);
    }
  };

  const resetAfterClose = () => {
    setData(null);
    setOptions([]);
    error && setError(false);
  };

  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      title="Add Talent"
      width={655}
      styles={customStyle}
      hideDefaultBtn
      afterClose={resetAfterClose}
    >
      <div className={styles.body}>
        <BasicLabel htmlFor="search-talent-lp">Staff Name or Staff ID</BasicLabel>
        <div className={styles.rowInput}>
          <div className={styles.inputContainer}>
            <BasicAutoComplete
              id="search-talent-lp"
              loading={searching}
              options={options}
              onSelect={onSelect}
              onSearch={debounce(onSearch, 300)}
              onChange={onChange}
              getPopupContainer={(trigger) => trigger.parentElement}
              notFoundContent={PROFILE_MANAGEMENT_MESSAGES.SEARCH_NOT_FOUND}
              placeholder={SEARCH_INPUT_PLACEHOLDER}
              autoFocus
            />
            <BasicErrorText show={error}>
              Talent is already in the Unsearchable Talent List
            </BasicErrorText>
          </div>
          <BasicButton mode="teal" disabled={adding || !data} onClick={handleAdd}>
            Add Talent <BasicLoading spinning={adding} pxsize={10} inline="true" />
          </BasicButton>
        </div>
      </div>
    </CustomAntModal>
  );
};

export default AddTalentForm;
