import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const WrapBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;
  border: ${(props) => props.border};
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
`;
