import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  width: 450px;

  position: absolute;
  z-index: 10;
  transform: translateY(3px);

  border: 1px solid #c4c3d1;
  border-radius: 6px;
  background-color: white;
  width: 175px;

  .button-month-calendar {
    margin-top: 10px;
    background-color: white;
    color: #00a19c;
    padding: 8px 20px;
    border: 1px solid #00a19c;
    border-radius: 6px;
    font-size: 10px;
    cursor: pointer;
  }
`;
