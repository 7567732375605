import { Button } from 'antd';
import styled from 'styled-components';

import { color, font } from '../../../../assets/common';

const Container = styled.div`
  width: 1287px;
  margin: auto;
`;

const Navigate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #00a19c;
  }

  img {
    transform: translateY(-40%);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 40px;
      font-family: ${font.rubik};
      font-weight: 700;
      font-size: 24px;
      color: #4a4a4a;
    }

    .sub-title {
      margin-top: 5px;
      font-family: ${font.rubik};
      font-weight: 400;
      font-size: 14px;
      color: #999a9c;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      cursor: pointer;
      &:active {
        transform: translate(0, 1px);
      }
    }
    .button {
      color: white;
      font-family: ${font.inter};
      font-weight: 600;
      font-size: 12px;
      padding: 5px 20px;
      border-radius: 6px;
      background-color: #e7e7f0;
    }

    .btn-delete-user {
      background-color: #e7e7f0;

      &_active {
        background-color: #ff787f;
        cursor: pointer;

        &:active {
          transform: translate(0, 1px);
        }
      }
    }

    .button-active {
      background-color: #00a19c;
      cursor: pointer;

      &:active {
        transform: translate(0, 1px);
      }
    }
  }
`;

const TableManagement = styled.div`
  width: 100%;
  margin-top: 20px;
  .ant-pagination-options {
    display: none;
  }
  .ant-spin-text {
    color: ${color.loading};
    font-family: ${font.inter};
    font-size: 12px;
  }
  .ant-spin-dot-item {
    background-color: ${color.teal};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${color.teal};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${color.teal};
  }
  .ant-table-thead {
    .ant-table-cell {
      color: #8896a7;

      &::before {
        content: none !important;
      }
    }
  }

  .ant-pagination {
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;
      background-color: transparent;
    }
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active {
    background: #00a19c;
    color: #fff;
  }

  .avatar {
    text-align: right;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
`;
const AiButtonDelete = styled(Button)`
  background-color: ${color.froly};
  border-color: ${color.froly};
  color: white;
  border-radius: 6px;
  height: 28px;
  padding: 0px 20px;
  font-weight: 600;
  font-size: 12px;
  font-family: ${font.inter};
  &:hover {
    background-color: ${color.froly};
    border-color: ${color.froly};
    color: white;
  }
  &:focus {
    background-color: ${color.froly};
    border-color: ${color.froly};
    color: white;
  }
  cursor: none;
`;
const AiButton = styled(Button)`
  color: white;
  font-family: ${font.inter};
  font-weight: 600;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 6px;
  background-color: ${color.teal};
  &:hover,
  &:focus {
    background-color: ${color.teal};
    opacity: 0.85;
    border-color: ${color.teal};
    color: white;
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background: ${color.teal};
    border-color: ${color.teal};
  }
`;

export { Container, Navigate, Header, TableManagement, AiButtonDelete, AiButton };
