import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { BasicButton } from '../../../../../assets/common';
import styles from './menu-bar-role-level-list.module.scss';

export function SearchListRoleLevel(props) {
  const { data, setSearchInput, loading, setIsSearchValue } = props;

  const handleOnClick = (item) => {
    setSearchInput(item);
    setIsSearchValue(false);
  };

  const Loading = () => {
    return (
      <div className={styles.search_list} data-testid="loading">
        <div className={styles.dot_flashing}></div>
      </div>
    );
  };

  if (loading === true) {
    return <Loading />;
  }

  if (data && data.length > 0) {
    const listItems = data.map((item, index) => {
      return (
        index < 4 && (
          <li
            className={styles.search_item}
            key={item.id}
            onMouseDown={() => handleOnClick(item.role_level)}
          >
            <div className={styles.individual}>
              <div className={styles.name}>{item.role_level}</div>
            </div>
          </li>
        )
      );
    });
    return (
      <ul className={styles.search_list} data-testid="data-is-true">
        {listItems}
      </ul>
    );
  } else {
    return (
      <div className={styles.search_list} data-testid="data-is-false">
        Search not found ...
      </div>
    );
  }
}

export default function MenuBarRoleLevelList(props) {
  const {
    setKeyword,
    loading,
    setIsShow,
    setSearchInput,
    searchInput,
    setPage,
    data,
    setIsSearchValue,
    isSearchValue,
    isEdit,
    setIsEdit,
    setShowDeleteModal,
    deleteSelecteds,
  } = props;

  const typingTimeoutRef = useRef(null);

  const handleOnchange = (value) => {
    setSearchInput(value);
    setPage(1);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {}, 500);
    value ? setIsSearchValue(true) : setIsSearchValue(false);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  return (
    <div>
      <Row align="bottom" justify="space-between">
        {!isEdit && (
          <Col span={16}>
            <Row align="bottom" gutter={8}>
              <Col span={18}>
                <input
                  data-testid="search-input"
                  placeholder="Search Role Level"
                  className={styles.searchInput}
                  onChange={(e) => handleOnchange(e.target.value)}
                  value={searchInput}
                />
              </Col>
              {isSearchValue && (
                <SearchListRoleLevel
                  data={data}
                  loading={loading}
                  setSearchInput={setSearchInput}
                  setIsSearchValue={setIsSearchValue}
                />
              )}
              <Col>
                <BasicButton
                  mode="teal"
                  onClick={() => {
                    setIsSearchValue(false);
                    setKeyword(searchInput);
                    setPage(1);
                  }}
                >
                  Search
                </BasicButton>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row align="bottom" justify="end" gutter={8}>
        {!isEdit && (
          <Col>
            <BasicButton
              mode="danger"
              className={styles.inActiveDelBtn}
              onClick={handleDelete}
              disabled={isEmpty(deleteSelecteds) ? true : false}
            >
              Confirm Delete Role
            </BasicButton>
          </Col>
        )}
        {!isEdit && (
          <Col>
            <BasicButton mode="teal" onClick={() => setIsShow(true)}>
              Add New
            </BasicButton>
          </Col>
        )}
        {isEdit && (
          <Col>
            <BasicButton mode="cancel" onClick={() => setIsEdit(null)}>
              Back
            </BasicButton>
          </Col>
        )}
      </Row>
    </div>
  );
}
