import { Col, Row, Space } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useState, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CategoryDropdownCustom } from '../../../../components';
import { isCheckError, onChangeInputData, showError } from '../../../../hooks/useFormik';
import { DATE_TIME_FORMAT, FORM_TYPES } from '../../../../utils/constants';
import SearchBoxCustom from '../SearchBoxCustom/SearchBoxCustom';
import { AiDatePicker, AiFormWrapper, AiInput, AiLabel, AiTitle } from './Styled';

const NewSuccessionPlanningForm = forwardRef((props, ref) => {
  const { positionCode } = useParams();
  const { allFilter, mode, spDetail, isSubmitted } = props;

  // Use state
  const [select] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  const validationSchema = yup.object({
    position_code: yup.string().required(true),
    plan_name: yup.string().required(true),
    business_unit: yup.object().required(true),
    role: yup.string().required(true),
    position_label: yup.object().required(true),
    // group_code: yup.object().required(true),
    platform: yup.string().required(true),
    approved_date: mode === FORM_TYPES.CREATE && yup.string().required(true),
    included_in_ratio: yup.object().required(true),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      position_code: '',
      plan_name: '',
      business_unit: '',
      role: '',
      position_label: '',
      group_code: null,
      platform: '',
      approved_date: '',
      included_in_ratio: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      handleSubmitForm: () => {
        formik.submitForm();
        return { formValue: formik.values, isValid: formik.isValid };
      },
      handleResetForm: () => {
        formik.resetForm();
      },
    }),
    [formik]
  );

  const onChangeApprovedDate = (date, dateString) => {
    formik.setFieldValue('approved_date', dateString);
  };

  useEffect(() => {
    if (mode !== FORM_TYPES.CREATE) return;

    if (searchTerm.value?.position_code) {
      const data = searchTerm.value;
      formik.setFieldValue(
        'position_code',
        data?.position_code ? data?.position_code : formik.values.position_code
      );
      formik.setFieldValue(
        'plan_name',
        data?.plan_name ? data?.plan_name : formik.values.plan_name
      );
      formik.setFieldValue(
        'business_unit',
        data?.business_unit
          ? {
              id: data?.business_unit,
              name: data?.business_unit,
            }
          : formik.values.business_unit
      );
      formik.setFieldValue('role', data?.role ? data?.role : formik.values.role);
      formik.setFieldValue(
        'position_label',
        data?.position_label
          ? {
              id: data?.position_label,
              name: data?.position_label,
            }
          : formik.values.position_label
      );
      formik.setFieldValue('platform', data?.platform ? data?.platform : formik.values.platform);
      formik.setErrors({
        group_code: true,
        approved_date: true,
        included_in_ratio: true,
      });
    } else {
      formik.setFieldValue('position_code', '');
      formik.setFieldValue('plan_name', '');
      formik.setFieldValue('business_unit', '');
      formik.setFieldValue('role', '');
      formik.setFieldValue('position_label', '');
      formik.setFieldValue('platform', '');
      formik.setTouched({}, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (mode !== FORM_TYPES.EDIT || isNaN(positionCode) || isEmpty(spDetail)) return;

    setSearchTerm({
      type: '',
      value: spDetail?.position_code,
      showTerm: spDetail?.position_code,
    });
    formik.setValues({
      position_code: spDetail?.position_code || '',
      plan_name: spDetail?.plan_name || '',
      business_unit: spDetail?.business_unit
        ? {
            id: spDetail?.business_unit,
            name: spDetail?.business_unit,
          }
        : '',
      role: spDetail?.role || '',
      position_label: spDetail?.position_label
        ? {
            id: spDetail?.position_label,
            name: spDetail?.position_label,
          }
        : '',
      group_code: spDetail?.group_code
        ? {
            id: spDetail?.group_code,
            name: spDetail?.group_code,
          }
        : null,
      platform: spDetail?.platform || '',
      approved_date: moment(spDetail?.approved_date).format(DATE_TIME_FORMAT.DATE_SPACE) || '',
      included_in_ratio: spDetail?.included_in_ratio
        ? {
            id: spDetail?.included_in_ratio,
            name: spDetail?.included_in_ratio,
          }
        : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCode, mode, spDetail]);

  return (
    <>
      <AiFormWrapper>
        <AiTitle>Details</AiTitle>
        <Row gutter={[16, 16]} className="mb-3">
          <Col span={9}>
            <AiLabel>
              Position Code<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <SearchBoxCustom
              selectedSp={select}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchList={searchList}
              setSearchList={setSearchList}
              fieldName={'position_code'}
              searchPlaceholder={'Enter Position Code'}
              isSubmitted={isSubmitted}
              isReadOnly={mode === FORM_TYPES.EDIT}
            />
            {showError(formik, 'position_code')}
          </Col>
          <Col span={9}>
            <AiLabel>Plan Name</AiLabel>
            <AiInput
              placeholder="Enter Plan Name"
              name="plan_name"
              value={formik.values.plan_name}
              onChange={(_event) => onChangeInputData(formik, _event.target.value, 'plan_name')}
              status={isCheckError(formik, 'plan_name') ? 'error' : ''}
              disabled={true}
            />
            {showError(formik, 'plan_name')}
          </Col>

          <Col span={6}>
            <AiLabel>Business Unit</AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.business_unit}
              setSelected={(event) => formik.setFieldValue('business_unit', event)}
              options={
                !isEmpty(allFilter.business_unit)
                  ? allFilter.business_unit.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.label}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'business_unit') ? 'error' : ''}
              disabled={true}
            />
            {showError(formik, 'business_unit')}
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mb-3">
          <Col span={9}>
            <AiLabel>Role</AiLabel>
            <AiInput
              placeholder="Search Role"
              name="role"
              value={formik.values.role}
              onChange={(_event) => onChangeInputData(formik, _event.target.value, 'role')}
              status={isCheckError(formik, 'role') ? 'error' : ''}
              disabled={true}
            />
            {showError(formik, 'role')}
          </Col>
          <Col span={9}>
            <AiLabel>Position Label</AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.position_label}
              setSelected={(event) => formik.setFieldValue('position_label', event)}
              options={
                !isEmpty(allFilter.position_label)
                  ? allFilter.position_label.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.label}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'position_label') ? 'error' : ''}
              disabled={true}
            />
            {showError(formik, 'position_label')}
          </Col>

          <Col span={6}>
            <AiLabel>Group Code</AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.group_code}
              setSelected={(event) => formik.setFieldValue('group_code', event)}
              options={
                !isEmpty(allFilter.group_code)
                  ? allFilter.group_code.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.label}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              clearIcon={
                formik.values.group_code ? () => formik.setFieldValue('group_code', null) : null
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mb-3">
          <Col span={9}>
            <AiLabel>
              Platforms<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <AiInput
              placeholder="Enter Platform"
              name="platform"
              value={formik.values.platform}
              onChange={(_event) => onChangeInputData(formik, _event.target.value, 'platform')}
              status={isCheckError(formik, 'platform') ? 'error' : ''}
            />
            {showError(formik, 'platform')}
          </Col>
          <Col span={9}>
            <AiLabel>
              Approved Date<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <Space direction="vertical" style={{ width: '100%' }}>
              <AiDatePicker
                disabledDate={false}
                format={DATE_TIME_FORMAT.DATE_SPACE}
                status={isCheckError(formik, 'approved_date') ? 'error' : ''}
                value={
                  formik.values.approved_date &&
                  moment(formik.values.approved_date, DATE_TIME_FORMAT.DATE_SPACE)
                }
                onChange={onChangeApprovedDate}
                placeholder="Select Date"
                getPopupContainer={(trigger) => trigger.parentElement}
                disabled={mode !== FORM_TYPES.CREATE}
              />
              {mode === FORM_TYPES.CREATE && showError(formik, 'approved_date')}
            </Space>
          </Col>
          <Col span={6}>
            <AiLabel>
              Included in Ratio<span style={{ color: '#FF787F' }}>*</span>
            </AiLabel>
            <CategoryDropdownCustom
              selected={formik.values.included_in_ratio}
              setSelected={(event) => formik.setFieldValue('included_in_ratio', event)}
              options={
                !isEmpty(allFilter.included_in_ratio)
                  ? allFilter.included_in_ratio.map((option) => {
                      return {
                        id: `${option?.value}`,
                        name: `${option?.label}`,
                      };
                    })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'included_in_ratio') ? 'error' : ''}
            />
            {showError(formik, 'included_in_ratio')}
          </Col>
        </Row>
      </AiFormWrapper>
    </>
  );
});

NewSuccessionPlanningForm.propTypes = {
  allFilter: PropTypes.object,
  isSubmitted: PropTypes.bool,
  mode: PropTypes.string,
  spDetail: PropTypes.object,
};

export default NewSuccessionPlanningForm;
