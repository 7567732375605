import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';

export const Label = styled.span`
  /* Body/Small */

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 3px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* Grays/500 */

  color: #525252;
`;

export const FieldGroupStyled = styled(({ width, ...props }) => <Col {...props} />)`
  width: ${(props) => props.width};
`;
