import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as styles from './filter-pill.module.scss';
import Pill from './Pill';

const FilterPill = ({ data, setData, onRemove, style }) => {
  const pillData = useMemo(() => {
    const result = [];
    Object.keys(data).forEach((fieldName) => {
      const element = data[fieldName];
      const optionAll = element.find((i) => i.value === 'all');
      if (optionAll) {
        result.push({
          label: `All - ${optionAll.field}`,
          value: `all-${fieldName}`,
        });
      } else {
        result.push(...element);
      }
    });
    return result;
  }, [data]);

  const handleRemove = (item) => {
    const { value } = item;
    Object.keys(data).forEach((fieldName) => {
      const element = data[fieldName];
      const set = setData[fieldName];
      if (item.value !== `all-${fieldName}`) {
        set(element.filter((i) => i.value !== value));
      } else {
        set([]);
      }
    });
    if (onRemove) onRemove();
  };

  return (
    <>
      {!isEmpty(pillData) && (
        <div className={styles.wrapper} style={style}>
          {pillData.map((item) => (
            <Pill key={item.value} label={item.label} handleRemove={() => handleRemove(item)} />
          ))}
        </div>
      )}
    </>
  );
};

FilterPill.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.object,
  onRemove: PropTypes.func,
  style: PropTypes.object,
}

export default FilterPill;
