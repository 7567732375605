import React from 'react';
import SpReadinessNew from '../SpReadinessNew/SpReadinessNew';
import styles from './sucession-view.module.scss';

const SucessionView = (props) => {
  const { data, spReadiness, isPrinting } = props;
  return (
    <div>
      <div className={styles.header}>Agreed to Maintain / Include</div>
      <div className={styles.succession_view}>
        {spReadiness.map((item) => (
          <div className={item.className} key={item.title}>
            <SpReadinessNew
              title={item.title}
              backgroundcolor={item.backgroundcolor}
              isExpand={false}
              isExistExpand={false}
              data={data.succession_planning[item.code]}
              readOnly={true}
              isPrinting={isPrinting}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SucessionView;
