import React from 'react';
import { exclamation_point_blue, x_close_popup } from '../../../../../assets/img';
import { TALENT_REVIEW_ROLE } from '../../../constants';
import styles from './disclaimer.module.scss';

const Disclaimer = (props) => {
  const { setShow, staffTalentReview } = props;

  return (
    <div className={styles.container}>
      <div className={styles.accent_border}></div>
      <div className={styles.form}>
        <div className={styles.title_info}>
          <img src={exclamation_point_blue} alt="exclamation_point_blue" />
          <span style={{ marginLeft: '14px', verticalAlign: 'middle' }}>Disclaimer</span>
          <div
            style={{ float: 'right', margin: '-8px 23px', cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() => setShow(false)}
            data-testid="btn-close-disclaim"
          >
            <img src={x_close_popup} alt="x_close_popup" />
          </div>
        </div>
        <div>
          <ul className={styles.content}>
            Development plan for talents identified as first line VP & above successors must include
            job exposure to the following functions:
            {TALENT_REVIEW_ROLE.SM_M_EXECUTIVE.includes(staffTalentReview.role) ? (
              <>
                <li className={styles.content_li}>
                  Strategy / Planning : Corporate or Cross Business
                </li>
                <li className={styles.content_li}>
                  Enterprise Governance : Audit or Risk Management
                </li>
                <li className={styles.content_li}>Executive Assistant to VP & Above</li>
              </>
            ) : (
              <>
                <li className={styles.content_li}>
                  Business Country Head / Country Chair / N-1 country
                </li>
                <li className={styles.content_li}>Board membership within / cross business</li>
                <li className={styles.content_li}>Cross business mobility</li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
