import React from 'react';
import { Container } from './styled';
import MenuCalendar from '../../components/MenuCalendar/MenuCalendar';
import QuaterCollapse from '../QuaterCollapse/QuaterCollapse';

const CalendarYearView = (props) => {
  const {
    scrollToElement,
    setFromDate,
    setToDate,
    date,
    setStartDate,
    isMonthlyView,
    setIsMonthlyView,
    category,
    setCategory,
    permissions,
  } = props;

  const decreaseYear = () => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() - 1);
    setStartDate(newDate);
  };
  const increaseYear = () => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + 1);
    setStartDate(newDate);
  };
  return (
    <Container>
      <div className="menu-calendar">
        <MenuCalendar
          scrollToElement={scrollToElement}
          setFromDate={setFromDate}
          setToDate={setToDate}
          decrease={decreaseYear}
          increase={increaseYear}
          date={date}
          setStartDate={setStartDate}
          isMonthlyView={isMonthlyView}
          setIsMonthlyView={setIsMonthlyView}
          category={category}
          setCategory={setCategory}
          permissions={permissions}
        />
      </div>

      <QuaterCollapse date={date} {...props} />
    </Container>
  );
};

export default CalendarYearView;
