import { Table } from 'antd';
import React from 'react';
import { MODE_TYPES } from '../../../../../../../utils/constants';
import * as styles from './data-table.module.scss';
import * as SC from './styled';
import TalentFinderTooltip from '../../../../../../TalentFinderPage/TalentFinderDetail/TalentFinderTooltip/TalentFinderTooltip';

export default function FinderTalentData({
  data,
  limit,
  page,
  setPage,
  totalPage,
  selectedRows,
  setSelectedRows,
}) {
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(page - 1) * limit + 1 + index}</span>
      ),
      width: 5,
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'avatar',
      width: 10,
    },
    {
      title: 'Name/Staff ID',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: 'Position Name',
      dataIndex: 'positionName',
      width: 300,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: 300,
    },
    {
      title: 'Company/OPU',
      dataIndex: 'companyOpu',
      width: 300,
    },
    {
      title: 'Business Unit',
      dataIndex: 'businessUnit',
      width: 200,
    },
  ];

  const fromResult = (page - 1) * limit + 1;
  const toResults = (page - 1) * limit + limit;

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows,
  };

  const paginationConfig = {
    current: page,
    total: totalPage,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== page && setPage(pageNum),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > totalPage ? totalPage : toResults}</b> of&nbsp;
        <b>{totalPage}</b> results
      </>
    ),
  };

  return (
    <div data-testid="table-finder-option" style={{ marginTop: 24, marginBottom: 24 }}>
      <SC.TableManagement>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data.map((d, _index) => ({
            key: d.staffId,
            avatar: (
              <>
                <div className={styles.avatar_wrapper}>
                  <img
                    src={d.avatar}
                    alt=""
                    width={40}
                    height={40}
                    style={{ borderRadius: '50%', border: '3px solid #F0F3F7' }}
                  />
                  <TalentFinderTooltip item={d} mode={MODE_TYPES.LIST} />
                </div>
              </>
            ),
            name: (
              <div>
                <div className={styles.table_name} style={{ marginBottom: '5px' }}>
                  {d.name}
                </div>
                <div className={styles.table_except_name}>Staff ID : {d.staffId}</div>
              </div>
            ),
            positionName: <div className={styles.table_except_name}>{d.positionName}</div>,
            department: <div className={styles.table_except_name}>{d.department}</div>,
            companyOpu: <div className={styles.table_except_name}>{d.companyOpu}</div>,
            businessUnit: <div className={styles.table_except_name}>{d.businessUnit}</div>,
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
}
