import React, { useState, useRef, useEffect } from 'react';
import styles from './dropdown-custom.module.scss';
import shage from '../../assets/img/Shape.svg';

const DropdownCustom = ({
  selected,
  setSelected,
  options,
  maxWidth = '66px',
  maxHeight = '36px',
  topbottom = '12px',
  leftright = '10px',
  space = '5px',
  shadow = false,
  color,
}) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  const display = {
    selected: {
      maxHeight: maxHeight,
      padding: `${topbottom} ${leftright}`,
      boxShadow: shadow ? 'none' : '',
    },
    color: {
      color: color ? color : '',
    },
    options: isActive
      ? `${styles.dropdownContent} ${styles.dropdownContent__active}`
      : `${styles.dropdownContent}`,
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref} style={{ maxWidth: maxWidth }}>
      <div
        className={styles.dropdownBtn}
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
        style={display.selected}
      >
        <p className={styles.dropdownSelect__label} style={{ color: '#00A19C' }}>
          {selected.label}
        </p>
        <span style={{ paddingLeft: space }}>
          <img src={shage} alt="" />
        </span>
      </div>

      <div className={display.options}>
        {isActive &&
          options &&
          options.map((option, index) => {
            return (
              <div
                key={index}
                onKeyDown={() => {}}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={styles.dropdownItem}
              >
                <p className={styles.dropdownItem__label} style={display.color}>
                  {option.label}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default DropdownCustom;
