import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BasicButton } from '../../../../assets/common';
import { mobility_endorsed, mobility_simulation_not_found } from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import mobilitySimulationApi from '../../../../services/mobilitySimulationApi';
import { ENDORSED_STATUS, MOBILITY_LIST_VIEW_COLUMNS, MOBILITY_SIMULATION } from '../../constants';
import MobilityDropdown from '../MobilityDropdown/MobilityDropdown';
import * as styles from './mobility-list-view.module.scss';
import { TableCheckbox } from './styled';

const MobilityListView = (props, ref) => {
  const { setShowDeleteSuccess, setIsDisableDelete, search } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [focusId, setFocusId] = useState(null);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const defaultPageSize = 10;

  const handleDeleteMultiple = async () => {
    const isDeleteSuccess = await fetchDeleteMobilities(
      selected,
      MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE
    );
    if (isDeleteSuccess) {
      handleResetMobilityList();
    }
  };

  const handleDeletePerRow = async (id) => {
    const isDeleteSuccess = await fetchDeleteMobilities(
      [id],
      MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE
    );
    if (isDeleteSuccess) {
      handleResetMobilityList();
    }
  };

  const fetchListMobilitySimulation = async () => {
    const query = { page, limit: defaultPageSize, keyword: search.keyword };
    const res = await mobilitySimulationApi.getList(query, roleActive.roleId);
    if (res.status === 200) {
      if (
        isEmpty(res.data.result?.mobilitity_simulations) &&
        res.data.result?.total > 0 &&
        page > 1
      ) {
        const prevPageNumber = Number(page) - 1;
        handleOnChangePagination(prevPageNumber);
      }
    }
  };

  const handleResetMobilityList = async () => {
    await fetchListMobilities({
      page,
      limit: defaultPageSize,
      keyword: search.keyword,
      from: MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE,
    });
    setShowDeleteSuccess(true);
    setIsDisableDelete(true);
    setSelected([]);
    await fetchListMobilitySimulation();
  };

  const handleView = async (id) => {
    history.push(`/mobility-simulations/${id}/details`);
  };

  const handleEdit = (id) => {
    history.push(`/mobility-simulation/${id}/edit`);
  };

  const handleDuplicate = (id) => {
    history.push(`/mobility-simulation/${id}/duplicate`);
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: [...selected],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRowKeys);
      setIsDisableDelete(!selectedRows.length);
    },
    getCheckboxProps: (record) => ({
      disabled: ENDORSED_STATUS.includes(record.status),
      name: record.mbsm_id,
    }),
  };

  const paginationConfig = {
    defaultPageSize: defaultPageSize,
    total: totalRecord,
    current: page,
    onChange: (page) => {
      handleOnChangePagination(page);
    },
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  const handleOnChangePagination = (page) => {
    setPage(page);
    setSelected([]);
    setIsDisableDelete(true);
    fetchListMobilities({
      page,
      limit: defaultPageSize,
      keyword: search,
      from: MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE,
    });
  };

  const fetchListMobilities = async (query) => {
    try {
      setLoading(true);
      const res = await mobilitySimulationApi.getList(query, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result.mobilitity_simulations);
        setTotalRecord(res.data.result.total);
      }
    } catch (error) {
      setData([]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDeleteMobilities = async (ids, type) => {
    try {
      setLoading(true);
      const res = await mobilitySimulationApi.delete(ids, type);
      if (res.status === 200) {
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return false;
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      handleDeleteMultiple: () => {
        setFocusId(null);
        setVisibleDelete(true);
      },
      handleSearch: (keyword) => {
        fetchListMobilities({
          page: 1,
          limit: defaultPageSize,
          keyword: keyword,
          from: MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE,
        });
        setPage(1);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchListMobilities({
      page: 1,
      limit: defaultPageSize,
      keyword: search,
      from: MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive]);

  return (
    <div className={styles.mobility_list}>
      {!isEmpty(data) ? (
        <Spin spinning={loading} size="large">
          <TableCheckbox
            columns={MOBILITY_LIST_VIEW_COLUMNS}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            dataSource={data.map((d) => {
              return {
                key: d.mbsm_id,
                status: d.status,
                name: (
                  <div className={styles.simulation_name}>
                    <span>{d.name}</span>
                    {ENDORSED_STATUS.includes(d.status) && (
                      <img className={styles.endorsed} src={mobility_endorsed} alt="endorsed" />
                    )}
                  </div>
                ),
                last_updated_by: <div className={styles.last_update_by}>{d.last_updated_by}</div>,
                last_updated_at: (
                  <div className={styles.last_update_date}>
                    {moment(d.last_updated_at).format('D MMMM YYYY')}
                  </div>
                ),
                view: (
                  <BasicButton
                    className={styles.viewBtn}
                    onKeyDown={() => {}}
                    onClick={() => handleView(d.mbsm_id)}
                  >
                    View Mobility Simulation
                  </BasicButton>
                ),
                more: (
                  <div onKeyDown={() => {}} onClick={() => setFocusId(d.mbsm_id)}>
                    <MobilityDropdown
                      id={d.mbsm_id}
                      endorsed={d.status}
                      setVisibleDelete={setVisibleDelete}
                      handleEdit={handleEdit}
                      handleDuplicate={handleDuplicate}
                    />
                  </div>
                ),
              };
            })}
            className={styles.detail_list_table}
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
          <ModelTC
            info={{
              type: 'confirmDeleteMobilitySimulation',
              visible: visibleDelete,
              setVisible: setVisibleDelete,
              handleSubmit: () => {
                if (focusId) {
                  handleDeletePerRow(focusId);
                } else {
                  handleDeleteMultiple();
                }
                setVisibleDelete(false);
              },
              loading: false,
              onClose: () => setVisibleDelete(false),
            }}
          />
        </Spin>
      ) : (
        <div className={`${styles.not_found} position-relative`}>
          <div className="center-loading">
            <Spin spinning={loading} size="large"></Spin>
          </div>
          <img
            src={mobility_simulation_not_found}
            className={styles.notFound}
            alt="talent_finder_not_found"
            style={loading ? { opacity: '0.3' } : null}
          />
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(MobilityListView);
