import { Col, Modal, Progress, Row } from 'antd';
import Axios from 'axios';
import { get } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import {
  cancel_upload,
  close_upload_file,
  cloud,
  upload_failed,
  upload_suceeded,
  uploading,
} from '../../../../../../../assets/img';
import { profileManagementApi } from '../../../../../../../services/profileManagementApi';
import styles from './upload-infor.module.scss';

const initObj = {
  status: 'upload', // upload, uploading, succeeded, failed
  message: 'Limit 10MB per file e.g. : .jpeg, .png',
  file_name: '',
  progress: {
    percentage: 0,
    time_remaining: 0,
  },
  title: 'Drag and drop you image here',
  url: '',
};

const Upload = (props) => {
  const { handleUploadFile, beforeUpload, upload } = props;
  const onDrop = (acceptedFiles) => {
    console.log('acceptedFiles', acceptedFiles);
    const isValidate = beforeUpload(acceptedFiles[0]);
    if (isValidate) handleUploadFile(acceptedFiles[0]);
  };
  const maxFiles = 1;
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles });

  return (
    <div className={styles.upload} {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <img src={cloud} alt={cloud} />
      </div>
      <div className={styles.title_upload}>{upload.title}</div>
      <div className={styles.message_upload}>{upload.message}</div>
      <div className={styles.or}>OR</div>
      <div className={styles.btn_upload}>Browse File</div>
    </div>
  );
};

const Uploading = (props) => {
  const { upload, cancelUpload } = props;
  return (
    <div className={styles.upload}>
      <div>
        <img src={uploading} alt={uploading} />
      </div>
      <div className={styles.title_upload}>{upload.title}</div>
      <Row justify="space-between" style={{ width: '100%', padding: '0 70px 0 30px' }}>
        <div className={styles.file_name} style={{ fontWeight: 'bold' }}>
          {upload.file_name}
        </div>
        <div className={styles.file_name} style={{ fontWeight: 'bold' }}>
          {upload.progress.percentage}%
        </div>
      </Row>
      <Row justify="space-between" style={{ width: '100%', padding: '0 30px' }}>
        <Col style={{ width: '90%' }}>
          <Progress
            percent={upload.progress.percentage}
            showInfo={false}
            strokeColor="#00A19C"
            trailColor="#EBEBEB"
          />
        </Col>
        <Col style={{ width: '10%', textAlign: 'end' }}>
          <img
            src={cancel_upload}
            alt={cancel_upload}
            onKeyDown={() => {}}
            onClick={cancelUpload}
            className={styles.img}
          />
        </Col>
      </Row>
      <div
        className={styles.file_name}
        style={{ width: '100%', textAlign: 'start', padding: '0 30px', color: '#7A7A7A' }}
      >
        Time remaining:{' '}
        <span style={{ fontWeight: 'bold' }}>{upload.progress.time_remaining} sec</span>
      </div>
    </div>
  );
};

const Suceeded = (props) => {
  const { upload, setUpload } = props;
  return (
    <div className={styles.upload} style={{ border: '1px solid #0B882E' }}>
      <div>
        <img src={upload_suceeded} alt={upload_suceeded} />
      </div>

      <div className={styles.title_upload} style={{ color: '#0B882E' }}>
        {upload.title}
      </div>
      <div className={styles.file_name}>{upload.file_name}</div>
      <div className={styles.btn_upload} onKeyDown={() => {}} onClick={() => setUpload(initObj)}>
        Remove File
      </div>
    </div>
  );
};

const Failed = (props) => {
  const { handleUploadFile, beforeUpload, upload } = props;
  const onDrop = (acceptedFiles) => {
    const isValidate = beforeUpload(acceptedFiles[0]);
    if (isValidate) handleUploadFile(acceptedFiles[0]);
  };
  const maxFiles = 1;
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles });

  return (
    <div className={styles.upload} {...getRootProps()} style={{ border: '1px solid #DA2228' }}>
      <input {...getInputProps()} />
      <div>
        <img src={upload_failed} alt={upload_failed} />
      </div>
      <div className={styles.title_upload} style={{ color: '#DA2228' }}>
        {upload.title}
      </div>
      <div className={styles.message_upload} style={{ color: '#DA2228' }}>
        {upload.message}
      </div>

      <div className={styles.btn_upload} style={{ marginTop: '40px' }}>
        Browse File
      </div>
    </div>
  );
};
const UploadInfor = (props) => {
  const { isModalOpen, setIsModalOpen, setFile } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const abortController = useRef(null);
  const [upload, setUpload] = useState(initObj);

  async function handleUploadFile(file) {
    try {
      let formData = new FormData();
      formData.append('file', file);
      const time_submitted = new Date().getTime();
      const CancelToken = Axios.CancelToken;
      abortController.current = CancelToken.source();
      const config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = loaded / total;
          const timeSpent = new Date().getTime() - time_submitted;
          const secondsRemaining = Math.round((timeSpent / progress - timeSpent) / 1000);
          setUpload({
            status: 'uploading',
            message: '',
            file_name: file.name,
            progress: {
              percentage: Math.floor((loaded * 100) / total),
              time_remaining: secondsRemaining,
            },
            title: 'Uploading...',
          });
        },
        headers: {
          'content-type': 'multipart/form-data',
          role: roleActive.roleId,
        },
        cancelToken: abortController.current.token,
      };

      const res = await profileManagementApi.uploadPhoto(formData, config);
      setUpload((prev) => {
        return {
          ...prev,
          url: get(res, 'data.result.url') || '',
          status: 'succeeded',
          message: 'Upload Successful',
          file_name: file.name,
          title: 'Upload Successful',
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      setUpload((prev) => {
        return {
          ...prev,
          url: '',
          status: 'failed',
          message: `Please upload file in .jpeg or .png format.`,
          file_name: '',
          title: 'Upload Failed',
        };
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      setUpload((prev) => {
        return {
          ...prev,
          url: '',
          status: 'failed',
          message: `The file you uploaded has exceeded 10MB.`,
          file_name: '',
          title: 'Upload Failed',
        };
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const cancelUpload = () => {
    abortController.current && abortController.current.cancel('Operation canceled by the user.');
    setUpload(initObj);
  };
  return (
    <>
      <Modal
        title={<span className={styles.title}>Upload Profile Picture</span>}
        open={isModalOpen}
        closeIcon={
          <img
            src={close_upload_file}
            alt={close_upload_file}
            onKeyDown={() => {}}
            onClick={() => setIsModalOpen(false)}
          />
        }
        footer={null}
      >
        {upload.status === 'upload' && (
          <Upload handleUploadFile={handleUploadFile} beforeUpload={beforeUpload} upload={upload} />
        )}
        {upload.status === 'uploading' && <Uploading upload={upload} cancelUpload={cancelUpload} />}
        {upload.status === 'succeeded' && <Suceeded upload={upload} setUpload={setUpload} />}
        {upload.status === 'failed' && (
          <Failed handleUploadFile={handleUploadFile} beforeUpload={beforeUpload} upload={upload} />
        )}
        <Row justify="end">
          <div
            className={styles.btn}
            onKeyDown={() => {}}
            onClick={() => {
              cancelUpload();
              setIsModalOpen(false);
            }}
          >
            Cancel
          </div>
          <div
            className={`${styles.disabled} ${upload.status === 'succeeded' && styles.confirm}`}
            onKeyDown={() => {}}
            onClick={() => {
              if (upload.status === 'succeeded') {
                setFile(upload.url);
                setIsModalOpen(false);
              }
            }}
          >
            Confirm
          </div>
        </Row>
      </Modal>
    </>
  );
};
export default UploadInfor;
