import React from 'react';
import styles from './avatar.module.scss';

export const Avatar = () => {
  return (
    <>
      <div className={styles.avatar}>
        <img src="" alt="" />
      </div>
    </>
  );
};

export default Avatar;
