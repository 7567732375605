import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { BasicTable } from '../../../../../assets/common';
import More3DotDropdown from '../../../../../components/More3DotDropdown/More3DotDropdown';
import { paginationConfig } from '../../../../../utils/helper';
import { TOP_TALENT_TABLE_LIMIT } from '../../constant';
import * as styles from './top-talent-status-table.module.scss';

const TopTalentStatusTable = ({
  data,
  searchParams,
  setSearchParams,
  setDeleteParams,
  setShowDeleteConfirm,
}) => {
  const history = useHistory();

  const handleClickDelete = (record) => {
    setShowDeleteConfirm(true);
    setDeleteParams((prevState) => ({ ...prevState, deleteRecord: record }));
  };

  const handleClickEdit = (record) => {
    history.push(
      `/admin/profile-management/edit-top-talent-status/${record.top_talent_status_id}/${record.assessment_id}`
    );
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'No.',
        dataIndex: 'RowNum',
        render: (text) => (
          <>
            <span className={styles.noField}>{text}</span>
          </>
        ),
      },
      {
        title: 'Staff Id',
        dataIndex: 'staff_id',
        render: (text) => <span className={styles.staffId}>{text}</span>,
      },
      {
        title: 'From Date',
        dataIndex: 'from_date',
        align: 'center',
        render: (text) => (
          <span className={styles.textField}>{text ? moment(text).format('DD/MM/yyyy') : '-'}</span>
        ),
      },
      {
        title: 'To Date',
        dataIndex: 'to_date',
        align: 'center',
        render: (text) => (
          <span className={styles.textField}>{text ? moment(text).format('DD/MM/yyyy') : '-'}</span>
        ),
      },
      {
        title: 'Top Talent Status',
        dataIndex: 'top_talent_status',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Assessment Date',
        dataIndex: 'assessment_date',
        align: 'center',
        render: (text) => (
          <span className={styles.textField}>{text ? moment(text).format('DD/MM/yyyy') : '-'}</span>
        ),
      },
      {
        title: 'Assessment Type',
        dataIndex: 'assessment_type',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        width: 80,
        render: (_, record) => (
          <More3DotDropdown
            item={record}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(data.total, searchParams.page, onChange, TOP_TALENT_TABLE_LIMIT);
  }, [data.total, searchParams.page, setSearchParams]);

  const rowSelection = {
    onSelect: (_, __, selectedRows) => {
      setDeleteParams({
        selectedRecords: selectedRows.filter((item) => item !== undefined),
        deleteRecord: null,
      });
    },
    onSelectAll: (_, selectedRows) => {
      setDeleteParams({
        selectedRecords: selectedRows,
        deleteRecord: null,
      });
    },
  };

  return (
    <>
      <BasicTable
        rowKey="top_talent_status_id"
        rowSelection={rowSelection}
        dataSource={data.searched_data}
        columns={columns}
        pagination={pagination}
      />
    </>
  );
};

export default TopTalentStatusTable;
