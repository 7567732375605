import styled from 'styled-components';

import { color } from '../../assets/common';

export const AiDropdown = styled.div`
  background: ${(props) => (props.disabled ? '#e0e0e0' : 'white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  padding: 10px 16px;
  min-height: 44px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  img {
    color: #bababa;
  }
`;

export const AiDropdownCustom = styled.div`
  background: ${(props) => (props.disabled ? '#e0e0e0' : '#EEEEEE')};
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  padding: 2px 4px;
  min-height: 24px;
  min-width: 76px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  img {
    color: #bababa;
  }
`;
