import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { BasicTable } from '../../../../../assets/common';
import More3DotDropdown from '../../../../../components/More3DotDropdown/More3DotDropdown';
import { paginationConfig } from '../../../../../utils/helper';
import { EXTERNAL_TALENTS_TABLE_ROW_LIMIT } from '../../constant';
import * as styles from './external-talents-table.module.scss';
import { DATE_TIME_FORMAT } from '../../../../../utils/constants';

const ExternalTalentsTable = ({
  data,
  searchParams,
  setSearchParams,
  setDeleteParams,
  setShowDeleteConfirm,
}) => {
  const history = useHistory();

  const columns = useMemo(() => {
    const handleClickDelete = (record) => {
      setShowDeleteConfirm(true);
      setDeleteParams((prevState) => ({ ...prevState, deleteRecord: record }));
    };

    const handleClickEdit = (record) => {
      history.push(`/admin/profile-management/edit-external-talent/${record.id}`);
    };

    return [
      {
        title: 'Name and Email',
        width: 250,
        render: (_, record) => (
          <>
            <span className={styles.name}>{record.name}</span>
            <span className={styles.email}>{record.email}</span>
          </>
        ),
      },
      {
        title: 'Company',
        width: 120,
        dataIndex: 'company',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Position Title',
        width: 200,
        dataIndex: 'position_title',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Role Level',
        width: 220,
        dataIndex: 'role_level',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Source',
        width: 100,
        dataIndex: 'source',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Focal',
        width: 100,
        dataIndex: 'focal',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Last Update',
        width: 160,
        dataIndex: 'last_update',
        render: (text) => (
          <span className={styles.textField}>
            {moment(text).format(DATE_TIME_FORMAT.DATE_SPACE)}
          </span>
        ),
      },
      {
        title: 'Action',
        width: 65,
        align: 'center',
        render: (_, record) => (
          <More3DotDropdown
            item={record}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
          />
        ),
      },
    ];
  }, [history, setDeleteParams, setShowDeleteConfirm]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(
      data.total,
      searchParams.page,
      onChange,
      EXTERNAL_TALENTS_TABLE_ROW_LIMIT
    );
  }, [data.total, searchParams.page, setSearchParams]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setDeleteParams({
        selectedRecords: selectedRows,
        deleteRecord: null,
      });
    },
  };

  return (
    <div className="mt-4">
      <BasicTable
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={data.external_talents}
        columns={columns}
        pagination={pagination}
        styles={{ headerBackground: '#E7EDF3', headerColor: '#4F5F6E', selectPadding: 17 }}
      />
    </div>
  );
};

export default ExternalTalentsTable;
