import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { avatar } from '../../../../assets/img';
import { ASSIGNMENT_STATUS_TYPES, PAGE_NAME, PERMISSION } from '../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../utils/helper';
import TalentFinderTooltip from '../../../TalentFinderPage/TalentFinderDetail/TalentFinderTooltip/TalentFinderTooltip';
import { avatarSelector, profileSelector } from '../../selector';
import NameAndPosition from './components/NameAndPosition';
import UserCareer from './components/UserCareer';
import UserInfoDetail from './components/UserInfoDetail';
import UserMileStone from './components/UserMileStone';
import styles from './overall-profile-user.module.scss';

const OverAllProfileUser = ({ isPrintPdf }) => {
  const { roleActive, permissions } = useSelector((state) => state.user);
  const avatarImage = useSelector(avatarSelector);
  const { firstSectionProfile, loading } = useSelector(profileSelector);
  const [isActive, setIsActive] = useState(true);

  const isHasEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_HIDE_UNHIDE_TALENT_PROFILE);
  }, [permissions]);

  useEffect(() => {
    if (!roleActive) return;
    setIsActive(true);
  }, [roleActive]);

  const returnBackgroundImage = () => {
    let bgColor = '';
    switch (firstSectionProfile?.assignment_status_type) {
      case ASSIGNMENT_STATUS_TYPES.ACTIVE:
        bgColor = '#14919B';
        break;
      case ASSIGNMENT_STATUS_TYPES.INACTIVE:
        bgColor = '#DA2228';
        break;
      case ASSIGNMENT_STATUS_TYPES.SUSPENDED:
        bgColor = '#FC930D';
        break;
      default:
        bgColor = '#F0F3F7';
        break;
    }
    return bgColor;
  };

  return (
    <>
      <div
        className={styles.userInfo}
        style={
          isActive === false
            ? { justifyContent: 'flex-end', paddingBottom: '20px' }
            : { justifyContent: 'space-between' }
        }
      >
        {isActive && (
          <div className={styles.avatar_wrapper} style={{ marginBottom: '20px' }}>
            <img
              src={(!loading && decodeBase64ToImage(avatarImage)) || avatar}
              alt=""
              width={155}
              height={155}
              style={{
                borderRadius: '50%',
                borderWidth: '3px',
                borderStyle: 'solid',
                borderColor: (!loading && returnBackgroundImage()) || '#F0F3F7',
              }}
            />
            {!loading && firstSectionProfile && (
              <TalentFinderTooltip item={firstSectionProfile} pageName={PAGE_NAME.TALENT_PROFILE} />
            )}
          </div>
        )}
        {isActive && <NameAndPosition />}
        <UserInfoDetail
          isActive={isActive}
          setIsActive={setIsActive}
          isPrintPdf={isPrintPdf}
          isHasEditPermission={isHasEditPermission}
        />
      </div>
      <div className={styles.userCareer}>
        <div className={styles.userCareerLeft}>
          <UserCareer />
        </div>
        <div className={styles.userCareerRight}>
          <UserMileStone />
        </div>
      </div>
    </>
  );
};
export default OverAllProfileUser;
