import React, { useEffect, useRef, useState } from 'react';
import Rectangle from '../../assets/img/Rectangle.svg';
import styles from './dropdown-meeting-category.module.scss';

const DropdownMeetingCategory = ({ selected, setSelected, options, placeholder }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={styles.dropdownBtn}
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
      >
        <p className={styles.dropdownSelect__label}>
          {selected?.name || (
            <span style={{ color: '#8D8D8D', fontSize: '14px' }}>{placeholder}</span>
          )}
        </p>
        <img src={Rectangle} alt="" />
      </div>

      <div
        className={styles.dropdownContent}
        style={{ height: isActive && '200px', overflowY: isActive && 'auto' }}
      >
        {isActive &&
          options &&
          options?.map((option) => {
            return (
              <div
                key={option?.id}
                onKeyDown={() => {}}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={styles.dropdownItem}
              >
                {option?.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DropdownMeetingCategory;
