import { Table } from 'antd';
import React, { useEffect, useState } from 'react';

import * as SC from './TableStyled';

const DataTable = ({ data }) => {
  const [newData, setNewData] = useState([]);

  const columns = [
    {
      title: 'Key Strength',
      dataIndex: 'key_strength',
      key: 'key_strength',
      width: '50%',
      render: (_, record) => {
        return (
          record.key_strength && <span className={'normalContent'}>{record.key_strength}</span>
        );
      },
    },
    {
      title: 'Development Areas',
      dataIndex: 'development_areas',
      key: 'development_areas',
      width: '50%',
      render: (_, record) => {
        return (
          record.development_areas && (
            <span className={'normalContent'}>{record.development_areas}</span>
          )
        );
      },
    },
  ];

  useEffect(() => {
    if (data) {
      const tempArr = data.map((item, index) => ({
        ...item,
        key: `${item.key_strength}.${index}`,
      }));
      setNewData(tempArr);
    }
  }, [data, setNewData]);

  return (
    <SC.KeyStrengthTable>
      <Table dataSource={newData} columns={columns} className={'customTable'} pagination={false} />
    </SC.KeyStrengthTable>
  );
};

export default DataTable;
