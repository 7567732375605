import React from 'react';
import { AiButton } from '../../Styled';

const TopButtonMeetingDetail = ({ backBtn, saveBtn, concludeBtn, notifyBtn }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        gap: '8px',
      }}
    >
      {saveBtn?.show && <AiButton onClick={saveBtn?.onClick}>Save</AiButton>}
      {backBtn?.show && <AiButton onClick={backBtn?.onClick}>Back</AiButton>}
      {concludeBtn?.show && (
        <AiButton mode={'teal'} onClick={concludeBtn?.onClick} disabled={concludeBtn?.disabled}>
          Conclude Meeting
        </AiButton>
      )}
      {notifyBtn?.show && (
        <AiButton mode={'teal'} onClick={notifyBtn?.onClick}>
          Notify Facilitator
        </AiButton>
      )}
    </div>
  );
};

export default TopButtonMeetingDetail;
