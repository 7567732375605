import { createSlice } from '@reduxjs/toolkit';
import { isArray } from 'lodash';

const initialStateMeeting = {
  meeting_id: null,
  meeting_name: '',
  start_time: '',
  end_time: '',
  date: '',
  category: {},
  category_value: null,
  location: '',
  chairman: [],
  meeting_committee: [],
  meeting_facilitator: [],
  meeting_secretariats: [],
  status: '',
};
const meetingDetailSlice = createSlice({
  name: 'meetingDetail',
  initialState: {
    meeting: initialStateMeeting,
    isEdittingMeeting: false,
    meetingRole: {},
    loading: false,
    isSubmittedFormChanges: false,
  },
  reducers: {
    addOrUpdateMeeting: (state, action) => {
      if (isArray(action.payload.chairman)) {
        state.meeting = action.payload;
      } else {
        state.meeting = {
          ...action.payload,
          chairman: [action.payload.chairman],
          start_time: action.payload.start_time,
          end_time: action.payload.end_time,
        };
      }
    },
    updateRoles: (state, action) => {
      state.meetingRole = action.payload;
    },
    clearMeeting: (state) => {
      state.meeting = initialStateMeeting;
    },
    changeMeetingStatus: (state, action) => {
      state.isEdittingMeeting = action.payload;
    },
    updateSubmittedFormChanges: (state, action) => {
      state.isSubmittedFormChanges = action.payload;
    },
  },
});
const { actions, reducer: meetingDetailReducer } = meetingDetailSlice;
export const {
  getMeeting,
  addOrUpdateMeeting,
  clearMeeting,
  changeMeetingStatus,
  updateRoles,
  updateSubmittedFormChanges,
} = actions;
export default meetingDetailReducer;
