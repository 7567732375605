import { notification } from 'antd';
import { NOTIFICATION_TYPE } from './constants';

export function getNotification(type) {
  let notification = { isShow: true };
  if (type === 'successful') {
    notification = { ...notification, alert: 'success', message: 'Update successful !!' };
  } else if (type === 'failed') {
    notification = { ...notification, alert: 'danger', message: 'Update failed !!' };
  }
  return notification;
}

export function clearNotification(type) {
  return { isShow: false };
}
export function showNotification(text, type) {
  let notify;
  if (type === NOTIFICATION_TYPE.SUCCESSFUL) {
    notify = notification.success({
      message: text,
      placement: 'topRight',
      duration: 2,
    });
  }
  if (type === NOTIFICATION_TYPE.FAILED) {
    notify = notification.error({
      message: text,
      placement: 'topRight',
      duration: 2,
    });
  }
  return notify;
}
