import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';

import { BasicButton, BasicInput, BasicLabel } from '../../../../assets/common';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { REPORT_BUILDER_MESSAGE } from '../../constants';
import * as styles from './edit-report-name-modal.module.scss';

const CUSTOM_STYLE = {
  closePosition: 45,
  headerPaddingBottom: 40,
  containerPaddingTopBottom: 40,
  titleFontSize: 20,
  titleFontWeight: 500,
};

const DuplicateColumnModal = ({
  open,
  setOpen,
  editColumn,
  currentColumns,
  listOfTalents,
  setDuplicatedColumn,
  handleDuplicatedColumn,
  fetchManualColumns,
}) => {
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const listColumnName = useMemo(() => {
    return (!isEmpty(currentColumns) && currentColumns.map((item) => item.name)) || [];
  }, [currentColumns]);

  const handleOnChange = (event) => {
    setTitle(event.target.value);
  };

  const handleClose = (event) => {
    setOpen(false);
    setTitle('');
    setErrorMessage(null);
  };

  const handleDuplicate = async () => {
    if (listColumnName.includes(title.trim()) || !title.trim()) {
      return setErrorMessage(REPORT_BUILDER_MESSAGE.DUPLICATE_COLUMN_NAME);
    }

    setOpen(false);
    let tempTalents = [];
    listOfTalents.forEach((talent) => {
      if (!isEmpty(talent.columns)) {
        talent.columns.forEach((column) => {
          if (column.name === editColumn.name) {
            tempTalents.push({
              ...column,
              name: title,
            });
          }
        });
      }
    });
    setDuplicatedColumn(tempTalents);
    await handleDuplicatedColumn(tempTalents);
    await fetchManualColumns();
  };

  return (
    <CustomAntModal
      title="Duplicate Column"
      hideDefaultBtn
      destroyOnClose
      width={420}
      styles={CUSTOM_STYLE}
      open={open}
      setOpen={setOpen}
    >
      <BasicLabel>Column Name</BasicLabel>
      <BasicInput
        id="title"
        name="title"
        placeholder="Enter Column Name"
        autoComplete="off"
        maxLength="20"
        height={40}
        onChange={handleOnChange}
        style={errorMessage ? { borderColor: '#F36568' } : {}}
      />
      {errorMessage && <div className={styles.errorMsg}>{errorMessage}</div>}
      <div className={styles.btnWrapper}>
        <BasicButton height={36} onClick={handleClose}>
          Cancel
        </BasicButton>
        <BasicButton mode="teal" height={36} onClick={handleDuplicate} disabled={!title.trim()}>
          Save
        </BasicButton>
      </div>
    </CustomAntModal>
  );
};

export default DuplicateColumnModal;
