import { Spin, Tooltip } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDragListView from 'react-drag-listview';

import { avatar, chevron_down_arrow, chevron_up_arrow } from '../../../../assets/img';
import { MODE_TYPES } from '../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowYIPAndYISG,
  infoPosition,
} from '../../../../utils/helper';
import TalentFinderTooltip from '../../../TalentFinderPage/TalentFinderDetail/TalentFinderTooltip/TalentFinderTooltip';
import { AiTable } from './styled';

const ListOfTalentTable = ({
  data,
  loading,
  customColumns,
  mode,
  isEditMode,
  selectedTalents,
  setSelectedTalents,
  setListOfTalents,
  setListOfColumnName,
}) => {
  const [itemExpanded, setItemExpanded] = useState(null);

  useEffect(() => {
    setItemExpanded(null);
  }, [mode]);

  const initialColumns = [
    {
      title: !mode ? (
        <span>
          No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name/Staff
          ID
        </span>
      ) : (
        <span>Name/Staff ID</span>
      ),
      dataIndex: 'name',
      fixed: 'left',
      key: 'name',
      align: 'left',
      width: 365,
      className: 'staffNameColumn',
      render: (_, record, index) => {
        return (
          <div className={'normalContent'}>
            {mode ? (
              <div className={'listViewItem'}>
                <div
                  className={'itemTitle'}
                  onKeyDown={() => {}}
                  onClick={() => handleClickExpandContent(record)}
                >
                  <button type="button" className={'expandBtn'}>
                    {record.staff_id === itemExpanded ? (
                      <img src={chevron_up_arrow} alt="chevron_up_arrow" />
                    ) : (
                      <img src={chevron_down_arrow} alt="chevron_down_arrow" />
                    )}
                  </button>
                  <div style={{ position: 'relative' }}>
                    <img
                      className={'avatar'}
                      src={decodeBase64ToImage(record?.image) || avatar}
                      alt=""
                    />
                    <TalentFinderTooltip item={record} mode={MODE_TYPES.LIST} />
                  </div>
                  <div className={'title'}>
                    <span className={'staffName'}>{record?.birth_name}</span>
                    <span className={'staffId'}>
                      Staff ID: {record?.identity_number || record?.staff_id}
                    </span>
                  </div>
                </div>
                {record.staff_id === itemExpanded && (
                  <div className={'itemContent'}>
                    <div className="mb-2">
                      <span style={{ marginRight: '15px' }}>
                        YIP:{' '}
                        {handleShowYIPAndYISG(record?.year_in_position, record?.date_in_position)}
                      </span>
                      <span>Age: {record?.age}</span>
                    </div>
                    <div className="mb-2">
                      <span>
                        YISG: {record?.salary_grade} (
                        {handleShowYIPAndYISG(record?.year_in_sg, record?.date_in_sg)})
                      </span>
                    </div>
                    <div className="mb-2">
                      <span>
                        Retirement: {handleShowRetirementDate(record.retirement_date)}
                        {handleShowRetirement(record)}
                      </span>
                    </div>
                    <div className="mb-2">
                      <span>Performance Rating: {record?.performance_rating}</span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={'cardViewItem'}>
                <div className={'itemTitle'}>
                  <div className={'itemNumber'}>{index + 1}</div>
                  <img
                    className={'avatar'}
                    src={decodeBase64ToImage(record?.image) || avatar}
                    alt=""
                  />
                  <div className={'title'}>
                    <div className={'staffId'}>{record?.identity_number || record?.staff_id}</div>
                    <div className={'roleList'}>
                      <span>VP: {record?.VP}</span>
                      <span>SGM: {record?.SGM}</span>
                      <span>GM: {record?.GM}</span>
                    </div>
                  </div>
                </div>
                <div className={'itemContent'}>
                  <div className={'staffName mb-2'}>
                    {record?.birth_name}&#160;{handleShowCT(record?.ct)}
                    {record?.matching_percentage ? `${record?.matching_percentage}%` : ''}{' '}
                  </div>
                  <div className={'positionName mb-2'}>
                    <Tooltip
                      placement="top"
                      title={infoPosition(
                        record?.position_name,
                        record?.department_name,
                        record?.division,
                        record?.opu,
                        record?.business_unit
                      )}
                      color={'black'}
                      key={'black'}
                      overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
                    >
                      <span>Position: </span>
                      {record?.position_name}
                    </Tooltip>
                  </div>
                  <div className="mb-2">
                    <span style={{ marginRight: '15px' }}>
                      YIP:{' '}
                      {handleShowYIPAndYISG(record?.year_in_position, record?.date_in_position)}
                    </span>
                    <span>
                      YISG: {record?.salary_grade} (
                      {handleShowYIPAndYISG(record?.year_in_sg, record?.date_in_sg)})
                    </span>
                  </div>
                  <div className="mb-2">
                    <span style={{ marginRight: '15px' }}>Age: {record?.age}</span>
                    <span>
                      Retirement: {handleShowRetirementDate(record.retirement_date)}
                      {handleShowRetirement(record)}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span>Performance Rating: {record?.performance_rating}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedTalents(selectedRows);
    },
    selectedRowKeys: selectedTalents.map((talent) => talent.staff_id),
  };

  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }
  };

  const handleClickExpandContent = (record) => {
    setItemExpanded((prev) => {
      if (!isEmpty(prev) && prev === record.staff_id) {
        return null;
      } else {
        return record.staff_id;
      }
    });
  };

  const getDefaultColumn = useMemo(() => {
    return mode
      ? [
          {
            title: 'No.',
            dataIndex: 'no',
            key: 'no',
            fixed: 'left',
            align: 'left',
            width: 30,
            className: 'noColumn',
            render: (_, __, index) => {
              return (
                <div className={'normalContent'} style={{ width: '80px' }}>
                  {index + 1}
                </div>
              );
            },
          },
          ...initialColumns,
        ]
      : initialColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, itemExpanded]);

  const getColumns = useMemo(() => {
    return [...getDefaultColumn, ...customColumns];
  }, [getDefaultColumn, customColumns]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const columns = getColumns;
      if (toIndex < 3) return;
      const item = columns.splice(fromIndex - 1, 1)[0];
      columns.splice(toIndex - 1, 0, item);
      setListOfTalents((prev) =>
        prev.map((item) => {
          const cols = cloneDeep(item.columns);
          const itemMove = cols.splice(fromIndex - 3, 1)[0];
          cols.splice(toIndex - 3, 0, itemMove);
          return {
            ...item,
            columns: cols,
          };
        })
      );
      setListOfColumnName((prev) => cloneDeep(prev));
    },
    nodeSelector: 'th',
    handleSelector: '.drag-handle',
    ignoreSelector: 'react-resizable-handle',
  };

  return (
    <Spin spinning={loading} size="large">
      <ReactDragListView.DragColumn {...dragProps}>
        <AiTable
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={data}
          columns={getColumns}
          className={`customReportTable ${!isEditMode && 'isViewMode'}`}
          pagination={false}
          scroll={{
            x: 'max-content',
          }}
          rowKey="staff_id"
        />
      </ReactDragListView.DragColumn>
    </Spin>
  );
};

export default ListOfTalentTable;
