import './box.scss';

import { notification, Tooltip } from 'antd';
import { isEmpty, isNaN } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { avatar, chevron_down, plus_collapse, sp_comment, sp_trash } from '../../../../../../assets/img';
import { DATE_TIME_FORMAT, MEETING_DETAIL_STATUS, SHOW_REMARK_ICON_STATUS } from '../../../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
  infoPosition,
} from '../../../../../../utils/helper';
import { removeTalents } from '../../../../redux/agendaSlice';
import AddRemark from '../AddRemark/AddRemark';
import AddTalent from '../AddTalent/AddTalent';
import { Draggable } from '../wrappers/Draggable';
import { Droppable } from '../wrappers/Droppable';
import { ApprovedLabel } from './ApprovedLabel';
import styles from './box.module.scss';
import { remarksRemainingSelector } from '../../../../selector';

const Box = (props) => {
  const { color, text, line, positionsCode, addItem, isReadOnly, code, lines } = props;
  const agendaParam = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.spTemplate);
  const remarks = useSelector(remarksRemainingSelector);
  const { positions, agenda_as_main_type, agenda_as_main_platform, agenda_as_main, agenda_as_main_start_at } = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent
  );
  const { status, is_secretariat_or_facilitator } = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const [collapseMenu, setCollapseMenu] = useState(true);
  const [addTalent, setAddTalent] = useState(false);
  const [showAddRemark, setShowAddRemark] = useState({
    status: false,
    staff: '',
  });

  function additionalInputRemark(cardItem) {
    if (isEmpty(remarks)) return null;
    const remarkItems = remarks.filter((remark) => remark.staffId === cardItem?.staff_id && remark?.remarksCategory === 'Additional Input');

    if (isEmpty(remarkItems)) return null;
    return (
      <div className={styles.additionalInput}>
        <ul>
          {remarkItems.map((item) => (
            <li key={item.remarks}>{item.remarks}</li>
          ))}
        </ul>
      </div>
    );
  }

  function showHide() {
    setCollapseMenu(!collapseMenu);
  }

  function deleteItem(id) {
    const newLine = [...line].filter((item) => item.staff_id !== id);
    dispatch(removeTalents({ code: code, line: newLine }));
    notification.success({
      message: `Delete successful.`,
      placement: 'topRight',
      duration: 2,
    });
  }

  function addItemNotFromAi(item) {
    const existedItem = !isEmpty(lines) && lines.find((d) => d.staff_id === item.staff_id);
    if (existedItem) {
      notification.error({
        message: `Talent is already in SP readiness table.`,
        placement: 'topRight',
        duration: 2,
      });
    } else {
      addItem(item, text);
      notification.success({
        message: `Add successful.`,
        placement: 'topRight',
        duration: 2,
      });
    }
  }

  const handleShowRetirement = (item) => {
    let total = Number(moment(item?.retirement_date).format('YYYY')) - Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }
  };

  return (
    <>
      <div className={styles.collapse}>
        <div
          onKeyDown={() => {}}
          onClick={() => showHide()}
          className={styles.collapseHeader}
          style={{ backgroundColor: color }}
          data-testid="show-hide"
        >
          <div className={styles.left}>{text}</div>
          <img src={chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
        </div>
        {!isReadOnly &&
          status !== MEETING_DETAIL_STATUS.CONCLUDED &&
          !(status === MEETING_DETAIL_STATUS.IN_REVIEW && !is_secretariat_or_facilitator) && (
            <div data-testid="show-add-talent" className={styles.icon} onKeyDown={() => {}} onClick={() => setAddTalent(true)}>
              <img src={plus_collapse} alt="" />
            </div>
          )}
        <Droppable disabled={isReadOnly} id={`${text}`} key={`${text}`}>
          <Collapse in={collapseMenu}>
            <div className={styles.collapseBody}>
              <div style={{ gap: '10px', padding: '15px 15px' }} className={styles.collapseBody__grid}>
                {loading.addPosition ? (
                  <div className="w-100" style={{ textAlign: 'center', gridColumn: '1/3' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {line && line.length > 0 ? (
                      line.map((item, index) => (
                        <Droppable
                          id={`${text}-drap-${index}`}
                          key={`${text}-drap-${index}`}
                          className="collapseBody__line"
                          data={{ ...item, index }}
                          disabled={isReadOnly}
                        >
                          <div className={styles.card_box}>
                            <Draggable
                              id={`${text}-drap-${index}`}
                              className={`collapseBody__line col-4 ${styles.left}`}
                              data={{ ...item, index }}
                              disabled={isReadOnly}
                            >
                              <div className="row flex-column no-gutters  align-items-center h-100">
                                <div className={styles.number}>{index + 1}</div>
                                <div className={styles.image}>
                                  <img src={decodeBase64ToImage(item?.image) || avatar} alt="" />
                                </div>
                                <div
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item?.identity_number || item?.staff_id}
                                </div>
                                {!item?.isUnsearchable && (
                                  <div className={styles.position}>
                                    <Tooltip
                                      placement="bottom"
                                      title={handleShowTextTooltip(item, positions)}
                                      color={'black'}
                                      key={'black'}
                                      overlayInnerStyle={{ borderRadius: '6px' }}
                                    >
                                      {handleShowTooltip(item, positions)}
                                    </Tooltip>
                                  </div>
                                )}

                                <ApprovedLabel positions={positions} item={item} isOldUI={true} />
                                {agenda_as_main && agenda_as_main === item.agenda_as_main && (
                                  <Tooltip
                                    overlayClassName={styles.tooltip}
                                    placement="top"
                                    title={
                                      <div className={styles.platformName}>
                                        <span>Platform Name: {agenda_as_main_platform}</span>
                                        <br />
                                        {agenda_as_main_start_at && (
                                          <span>Date: {moment(agenda_as_main_start_at).format(DATE_TIME_FORMAT.DATE_SPACE)}</span>
                                        )}
                                      </div>
                                    }
                                  >
                                    <div className={styles.mainAgendaStatus}>{agenda_as_main_type || 'N/A'}</div>
                                  </Tooltip>
                                )}
                              </div>
                            </Draggable>
                            <div className={`col-8 ${styles.right}`}>
                              <div className="row  no-gutters justify-content-between align-item?s-stretch">
                                <div className={styles.name}>
                                  <Link to={`/profile/${item?.staff_id}`} target="_blank" className={styles.recommendation__name} draggable="false">
                                    {item?.birth_name} &#160;
                                    {!item?.isUnsearchable &&
                                      !hideOtherTalentLabel(item.assignment_status_type, item.role_level, item.ct) &&
                                      !['119463', '1032501'].includes(item?.staff_id) &&
                                      handleShowCT(item.ct)}
                                    {!item?.isUnsearchable ? (item?.matching_percentage ? `${item?.matching_percentage}%` : '') : ''}
                                  </Link>
                                </div>
                                <div className={styles.icon_card}>
                                  {!isReadOnly &&
                                    !isNaN(Number(agendaParam?.agendaId)) &&
                                    SHOW_REMARK_ICON_STATUS.includes(status) &&
                                    status !== MEETING_DETAIL_STATUS.CONCLUDED &&
                                    !(status === MEETING_DETAIL_STATUS.IN_REVIEW && !is_secretariat_or_facilitator) && (
                                      <img
                                        data-testid="show-remark"
                                        src={sp_comment}
                                        alt=""
                                        draggable="false"
                                        onKeyDown={() => {}}
                                        onClick={() =>
                                          setShowAddRemark({
                                            status: true,
                                            staff: item,
                                          })
                                        }
                                      />
                                    )}
                                  {!isReadOnly &&
                                    status !== MEETING_DETAIL_STATUS.CONCLUDED &&
                                    !(status === MEETING_DETAIL_STATUS.IN_REVIEW && !is_secretariat_or_facilitator) && (
                                      <img
                                        data-testid="delete"
                                        src={sp_trash}
                                        alt=""
                                        onKeyDown={() => {}}
                                        onClick={() => deleteItem(item?.staff_id)}
                                        draggable="false"
                                      />
                                    )}
                                </div>
                              </div>
                              <Draggable id={`${text}-drap-${index}`} className="collapseBody__line" data={{ ...item, index }} disabled={isReadOnly}>
                                <div className="mb-2">
                                  <Tooltip
                                    placement="top"
                                    title={infoPosition(item.position_name, item.department_name, item.division, item.opu, item.business_unit)}
                                    color={'black'}
                                    key={'black'}
                                    overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
                                  >
                                    <span>Position: </span>
                                    {item?.position_name}
                                  </Tooltip>
                                </div>
                                {!item?.isUnsearchable && (
                                  <div className="mb-2">
                                    <span>YIP: </span>
                                    {handleShowYIPAndYISG(item?.year_in_position, item?.date_in_position)}
                                    &#160;&#160;&#160;
                                    <span>YISG: </span>
                                    {item?.salary_grade} ({handleShowYIPAndYISG(item?.year_in_sg, item?.date_in_sg)})
                                  </div>
                                )}

                                <div className="mb-2">
                                  <span>Age: </span>
                                  {item?.age}&#160;&#160;&#160;
                                  {!item?.isUnsearchable && (
                                    <>
                                      <span>Retirement: </span>
                                      {handleShowRetirementDate(item.retirement_date)}
                                      {handleShowRetirement(item)}
                                    </>
                                  )}
                                </div>
                                {!item?.isUnsearchable && (
                                  <div className="mb-2">
                                    <span>Performance Rating: </span>
                                    {item?.performance_rating}
                                  </div>
                                )}
                                {additionalInputRemark(item)}
                              </Draggable>
                            </div>
                          </div>
                        </Droppable>
                      ))
                    ) : (
                      <div className="w-100" style={{ textAlign: 'center', gridColumn: '1/3' }}>
                        No data
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Collapse>
        </Droppable>
      </div>
      {showAddRemark.status && <AddRemark setIsVisible={setShowAddRemark} showAddRemark={showAddRemark} />}
      {addTalent && (
        <AddTalent isVisible={addTalent} setIsVisible={setAddTalent} addItem={addItemNotFromAi} positionsCode={positionsCode} currentLine={text} />
      )}
    </>
  );
};

export default Box;
