/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { managing_ccp_data_not_found } from '../../../../../assets/img';
import DataTableManagingCriteriaCP from '../DataTableManagingCriteriaCP/DataTableManagingCriteriaCP';
import MenuBarManagingCriteriaCP from '../MenuBarManagingCriteriaCP/MenuBarManagingCriteriaCP';
import { adminApi } from '../../../../../services/admin';
import * as styles from './position-with-matching-criteria.module.scss';
import PillManagingCriteriaCP from '../PillManagingCriteriaCP/PillManagingCriteriaCP';
import { Spin } from 'antd';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';

export default function PositionWithMatchingCriteria(props) {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [showFilter, setShowFilter] = useState(false);
  const [positionLabelSelected, setPositionLabelSelected] = useState([]);
  const [businessSelected, setBusinessSelected] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [updateStatusVisibleDeleteMultiple, setUpdateStatusVisibleDeleteMultiple] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const limit = 15;

  const fetchData = useCallback(async () => {
    // if (isEmpty(keyword) && isEmpty(businessSelected) && isEmpty(positionLabelSelected)) {
    //   setData([]);
    //   return;
    // }
    try {
      setLoading(true);
      const query = {
        page,
        limit,
        keyword,
        business: businessSelected.map((item) => item.value).filter((item) => item !== 'all'),
        position_label: positionLabelSelected
          .map((item) => item.value)
          .filter((item) => item !== 'all'),
      };
      const res = await adminApi.getManagingCCP(query, roleActive);
      if (res.status === 200) {
        const totalRecord = !isEmpty(res.data.result) ? res.data.result.total : 0;
        setData(res.data.result.result);
        setTotalData(totalRecord);
      }
    } catch (error) {
      console.log(error);
      setData([]);
      setTotalData(0);
    }
    setLoading(false);
  }, [keyword, businessSelected.length, positionLabelSelected.length, page]);

  const handleDeleteRecord = useCallback(async () => {
    try {
      if (deleteRecord?.position_code) {
        setLoading(true);
        const res = await adminApi.deleteManagingCCP([deleteRecord?.position_code]);
        if (res.status === 200) {
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: `${deleteRecord.position_code} ${deleteRecord.position_name} successfully deleted`,
            })
          );
          fetchData();
          setDeleteRecord(null);
        }
      } else {
        const multiSelect = deleteSelecteds.map((x) => {
          let arr = data?.find((a) => x === a?.number);
          let positionCodes = arr.position_code;
          let mess = {
            position_code: arr.position_code,
            position_name: arr.position_name,
          };
          return { positionCodes, mess };
        });
        let position_codes = multiSelect.map((pos) => pos?.positionCodes);
        let message = multiSelect.map((mes) => mes.mess);
        setLoading(true);
        const res = await adminApi.deleteManagingCCP(position_codes);
        if (res.status === 200) {
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: `${
                !isEmpty(message) &&
                message.map((item) => `${item.position_code} ${item.position_name}`).join(', ')
              } successfully deleted`,
            })
          );
          fetchData();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteSelecteds([]);
    setUpdateStatusVisibleDeleteMultiple(false);
    setLoading(false);
  }, [data, deleteRecord, deleteSelecteds, fetchData]);

  useEffect(() => {
    if (!firstTimeRender) return;
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setFirstTimeRender(true);
  }, []);

  useEffect(() => {
    if (!deleteRecord) return;
    handleDeleteRecord();
  }, [deleteRecord]);

  return (
    <Spin spinning={loading} size="large">
      <MenuBarManagingCriteriaCP
        positionLabelSelected={positionLabelSelected}
        setPositionLabelSelected={setPositionLabelSelected}
        businessSelected={businessSelected}
        setBusinessSelected={setBusinessSelected}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        fetchData={fetchData}
        setDeleteSelecteds={setDeleteSelecteds}
        deleteSelecteds={deleteSelecteds}
        updateStatusVisible={updateStatusVisibleDeleteMultiple}
        setUpdateStatusVisible={setUpdateStatusVisibleDeleteMultiple}
        handleDeleteRecord={handleDeleteRecord}
        loading={loading}
      />
      <PillManagingCriteriaCP
        showFilter={showFilter}
        positionLabelSelected={positionLabelSelected}
        setPositionLabelSelected={setPositionLabelSelected}
        businessSelected={businessSelected}
        setBusinessSelected={setBusinessSelected}
      />

      {!isEmpty(data) ? (
        <>
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.235px',
              fontSize: '18px',
              color: '#3F3C4C',
              margin: '30px 0 15px',
            }}
          >
            Position With Matching Criteria
          </div>
          <DataTableManagingCriteriaCP
            data={data}
            setDeleteRecord={setDeleteRecord}
            setDeleteSelecteds={setDeleteSelecteds}
            deleteSelecteds={deleteSelecteds}
            handleDeleteRecord={handleDeleteRecord}
            totalData={totalData}
            setPage={setPage}
            page={page}
            limit={limit}
          />
        </>
      ) : (
        <div className={styles.not_found}>
          <img src={managing_ccp_data_not_found} alt="not_found" />
        </div>
      )}
    </Spin>
  );
}
