import { Input, TimePicker, Tooltip } from 'antd';
import TimeField from 'react-simple-timefield';
import styled, { css } from 'styled-components';
import { color, font, fontSize } from '../../../assets/common';

export const AiHeader = styled.h3`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.large};
  color: ${color.brightGray2};
  margin-bottom: 32px;
  word-break: break-word;
`;
export const AiGrBtnHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;
export const AiAgendaForm = styled.div`
  background-color: white;
  padding: 26px 39px 44px 33px;
  margin-bottom: 24px;
  border-radius: 6px;
`;
export const AiTitle = styled.p`
  font-family: ${font.inter};
  font-size: ${fontSize.sizable};
  font-weight: 600;
  color: ${color.brightGray2};
`;
export const AiLabel = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${fontSize.small};
  position: relative;
  margin-bottom: 5px;
  ${(props) => {
    if (!props.require) {
      return css``;
    }

    return css`
      &::after {
        position: absolute;
        content: '*';
        color: ${color.danger};
      }
    `;
  }}
`;
export const AiInput = styled(Input)`
  border-radius: 4px;
  padding: 10px 16px;
  font-family: ${font.inter};
  &:hover,
  &:focus {
    border-color: ${color.teal};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
`;
export const AiTimeField = styled(TimeField)`
  padding: 9px 16px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  border-radius: 4px;
`;
export const AiTimePickerField = styled(TimePicker)`
  padding: 10px 16px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  border-radius: 4px;
`;
export const AiGrBtnMiddle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;
export const AiPositionsInformation = styled.div`
  background-color: white;
  padding: 24px 42px;
`;
export const AiNoAgenda = styled.div`
  text-align: center;
  padding: 88px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
`;
export const AiNoAgendaTitle = styled.span`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #3f3c4c;
  margin-bottom: 5px;
`;
export const AiNoAgendaDesc = styled.span`
  color: #9f9eae;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-transform: none;
  max-width: 300px;
`;
export const AiImg = styled.img`
  max-width: 105px;
`;

export const TooltipStyled = styled(Tooltip)`
  .custom-tooltip {
    .ant-tooltip-content {
      width: 500px !important;
    }
  }
`;
