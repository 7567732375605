import { Tooltip } from 'antd';
import React from 'react';

import { active_icon, in_active_icon, suspended_icon } from '../../../../assets/img';
import { ASSIGNMENT_STATUS_TYPES, MODE_TYPES, PAGE_NAME } from '../../../../utils/constants';
import styles from './talent-finder-tooltip.module.scss';

const TalentFinderTooltip = ({ item, mode, pageName }) => {
  const handleRenderTalentStatus = () => {
    let iconName = '';
    switch (item?.assignment_status_type) {
      case ASSIGNMENT_STATUS_TYPES.ACTIVE:
        iconName = active_icon;
        break;
      case ASSIGNMENT_STATUS_TYPES.INACTIVE:
        iconName = in_active_icon;
        break;
      case ASSIGNMENT_STATUS_TYPES.SUSPENDED:
        iconName = suspended_icon;
        break;
      default:
        break;
    }

    const getIconSize = () => {
      return (
        (pageName === PAGE_NAME.TALENT_PROFILE && 26.67) || (mode === MODE_TYPES.GIRD ? 20 : 13.33)
      );
    };

    const getStyleForProfilePage = () => {
      return pageName === PAGE_NAME.TALENT_PROFILE
        ? {
            left: '70%',
            background: '#FFFFFF',
            borderRadius: '50%',
          }
        : {};
    };

    return item?.assignment_status_type !== ASSIGNMENT_STATUS_TYPES.ACTIVE ? (
      <Tooltip
        placement={'bottomRight'}
        className={styles.customTooltip}
        overlayClassName={'custom-finder-tooltip'}
        overlayStyle={{ minWidth: '288px' }}
        title={
          <div className={styles.tooltip_content}>
            <span className={styles.tooltip_status_type}>
              {item?.assignment_status_type === ASSIGNMENT_STATUS_TYPES.INACTIVE
                ? `In-active`
                : `Suspended`}
            </span>
            <span
              className={`${styles.tooltip_status_content} ${
                item?.assignment_status_type === ASSIGNMENT_STATUS_TYPES.INACTIVE
                  ? styles.tooltip_in_active
                  : styles.tooltip_suspended
              }`}
            >
              {item?.action_name} {item?.action_reason && `(${item.action_reason})`}
            </span>
          </div>
        }
        color={'black'}
        key={'black'}
        overlayInnerStyle={{ borderRadius: '6px' }}
      >
        {iconName && (
          <div
            style={getStyleForProfilePage()}
            className={`${styles.tooltip_icon} ${
              mode === MODE_TYPES.LIST && styles.tooltip_view_list
            }`}
          >
            <img
              src={iconName}
              alt="Talent Status"
              width={getIconSize()}
              height={getIconSize()}
              style={{ borderRadius: '50%', backgroundColor: '#FFFFFF' }}
            />
          </div>
        )}
      </Tooltip>
    ) : (
      <>
        {iconName && (
          <div
            style={getStyleForProfilePage()}
            className={`${styles.tooltip_icon} ${
              mode === MODE_TYPES.LIST && styles.tooltip_view_list
            }`}
          >
            <img
              src={iconName}
              alt="Talent Status"
              width={getIconSize()}
              height={getIconSize()}
              style={{ borderRadius: '50%', backgroundColor: '#FFFFFF' }}
            />
          </div>
        )}
      </>
    );
  };

  return <>{handleRenderTalentStatus()}</>;
};

export default TalentFinderTooltip;
