import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import ReactExport from 'react-data-export';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { AiButton, BasicButton } from '../../../../../assets/common';
import { useDebounce } from '../../../../../hooks';
import { HISTORICAL_DATA_TALENT_TYPE } from '../../../../../utils/constants';
import { returnDataToExportHistoricalDetails } from '../../../../../utils/helper';
import { EXPORT_DETAILS_COLUMNS } from './export-details.constant';
import * as styles from './menu-bar-view-details.module.scss';
import { Tooltip } from 'antd';
import { commonTooltipStyles } from '../../../../../assets/common/tooltipStyles';

const MenuBarViewDetails = ({
  searchTerm,
  setSearchTerm,
  exportData,
  setIsExport,
  loadingExport,
  total,
}) => {
  const history = useHistory();
  const { typeOfTalent } = useParams();
  const debounceSearchQuery = useDebounce(searchTerm, 1000);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const getFileNameToExport = useMemo(() => {
    let tempFileName;
    switch (typeOfTalent) {
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_TOP_TALENT:
        tempFileName = 'No of Top Talent';
        break;
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_CORPORATE_TALENT:
        tempFileName = 'No of Corporate Talent';
        break;
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_BUSINESS_TALENT:
        tempFileName = 'No of Business Talent';
        break;
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_REVIEW_CONDUCTED:
        tempFileName = 'No of Talent Review Conducted';
        break;
      default:
        tempFileName = '';
        break;
    }
    return `${tempFileName} as of ${moment(new Date()).format('DDMMYY')}`;
  }, [typeOfTalent]);

  const handleBack = () => {
    history.push({
      pathname: '/dashboard',
      state: { isShowHistorical: true },
    });
  };

  const handleOnChange = (value) => {
    if (debounceSearchQuery.type === 'search') {
      setSearchTerm({ type: 'search', value });
    }
  };

  const generateDataSet = () => {
    const tempDataExport = returnDataToExportHistoricalDetails(exportData ?? []);
    return [
      {
        columns: [...EXPORT_DETAILS_COLUMNS],
        data: [...tempDataExport],
      },
    ];
  };

  return (
    <div className={styles.menuWrapper}>
      <div style={{ width: '416px' }} className={styles.searchBox}>
        <input
          name={`search`}
          placeholder={'Search by Staff Name and Staff ID'}
          className={`${styles.searchInput}`}
          onChange={(e) => handleOnChange(e.target.value)}
          value={searchTerm.showTerm}
          autoComplete="off"
        />
      </div>

      <div className={styles.groupBtn} style={{ width: '220px' }}>
        <BasicButton style={{ minWidth: '100px', height: '36px' }} onClick={() => handleBack()}>
          Back
        </BasicButton>
        {loadingExport || total <= 0 ? (
          <Tooltip title="No record to export" {...commonTooltipStyles}>
            <AiButton
              mode="teal"
              style={{ minWidth: '100px', height: '36px' }}
              onClick={() => setIsExport(true)}
              disabled={loadingExport || total <= 0}
            >
              Export
            </AiButton>
          </Tooltip>
        ) : (
          <AiButton
            mode="teal"
            style={{ minWidth: '100px', height: '36px' }}
            onClick={() => setIsExport(true)}
            disabled={loadingExport || total <= 0}
          >
            Export
          </AiButton>
        )}

        {!loadingExport && !isEmpty(exportData) && (
          <ExcelFile hideElement={true} filename={getFileNameToExport}>
            <ExcelSheet dataSet={generateDataSet()} name={getFileNameToExport} />
          </ExcelFile>
        )}
      </div>
    </div>
  );
};

export default MenuBarViewDetails;
