import React, { useEffect, useState, useRef } from 'react';
import Rectangle from '../../assets/img/Rectangle.svg';
import styles from './dropdown-edge-year.module.scss';
const DropdownEDGEyear = ({
  selected,
  setSelected,
  options,
  isDisableYearAllOption,
  isYearsDisabled,
  isAllBtnDisabled,
}) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div style={isAllBtnDisabled ? { cursor: 'not-allowed' } : null}>
        <div
          className={styles.dropdownBtn}
          onKeyDown={() => {}}
          onClick={() => setIsActive(!isActive)}
          style={isAllBtnDisabled ? { pointerEvents: 'none' } : null}
        >
          {selected}
          <img src={Rectangle} alt="" />
        </div>
      </div>
      <div className={styles.dropdownContent}>
        {isActive &&
          options &&
          options.map((option, index) => {
            if (option === selected) {
              return null;
            }
            let classes = styles.dropdownItem;
            if (isDisableYearAllOption && option === 'All') {
              classes += ' ' + styles['disable-all-option'];
            }
            if (isYearsDisabled && option !== 'All') {
              classes += ' ' + styles['disable-all-option'];
            }
            return (
              <div
                key={index}
                value={option}
                onKeyDown={() => {}}
                onClick={() => {
                  if (isDisableYearAllOption && option === 'All') return;
                  if (isYearsDisabled && option !== 'All') return;
                  setSelected(option);
                  setIsActive(false);
                }}
                className={classes}
              >
                {option}
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default DropdownEDGEyear;
