import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import pageReducer from './pageSlice';
import alertMessageReducer from './alertMessageSlice';
import afterLoginReducer from './afterLoginSlice';
import spTemplateReducer from './spTemplateSlice';
import app from '../pages/store';
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cancelUploadReducer from './cancelUploadSlice';

const rolePersist = {
  key: 'role',
  storage: storage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  page: pageReducer,
  user: userReducer,
  alertMessage: alertMessageReducer,  
  cancelUpload: cancelUploadReducer,
  afterLogin: afterLoginReducer,
  spTemplate: spTemplateReducer,
  app,
});

const persistedReducer = persistReducer(rolePersist, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});

let persistor = persistStore(store);
export { store, persistor };
