import { Tooltip } from 'antd';
import React from 'react';
import styles from './tooltip-poisition.module.scss';
import { TYPE_EKP, TYPE_BKP } from '../../utils/constants';

export const TooltipPoisition = React.memo((props) => {
  const { item } = props;
  const content = () => {
    return <span>JG: {item?.job_grade}</span>;
  };
  const handleShowPosition = () => {
    let text = item.position_name;
    if (item.business_unit) {
      text += ', ' + item.business_unit;
    }
    if (item.position_code) {
      text += ' (Position ID: ' + item.position_code + ')';
    }
    return text;
  };
  const handleShowRole = () => {
    if (!item.role) return ', Role:';
    return ', Role:' + item.role;
  };

  let labelClass = styles.other; // Default class for other labels
  if (item?.position_label === TYPE_BKP) {
    labelClass = styles.BCP;
  } else if (item?.position_label === TYPE_EKP) {
    labelClass = styles.CCP;
  }

  return (
    <>
      <span className={styles.title}>{handleShowPosition()}</span>
      <Tooltip placement="bottom" title={content} overlayClassName={styles.tooltip}>
        <span className={styles.JG_hover}> {handleShowRole()}</span>
      </Tooltip>
      {item?.position_label && (
        <span className={`${styles.position_label} ${labelClass}`}>{item?.position_label}</span>
      )}
    </>
  );
});
