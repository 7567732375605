import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;

  .ant-collapse-borderless > .ant-collapse-item {
    border-top: 2px solid #344563;
    border-bottom: none;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .ant-collapse-header {
    font-size: 24px;
    font-weight: 700;
  }
`;
