import { Row } from 'antd';
import { FastField, Formik, useFormikContext } from 'formik';
import _, { get, isEmpty, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { AiButton } from '../../../../../assets/common';
import { useProfilesSearch } from '../../../../../hooks/useTalentSearch';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  AGENDA_SHORT_TYPES,
  AGENDA_TYPES,
  CATEGORY_OPTIONS,
  FILL_OUT_REQUIRE_MESSAGE,
  MESSAGE_TYPES,
  REMARK_TYPES,
} from '../../../../../utils/constants';
import { AiH3, AiLabel, AiTitle, FieldRow, Hidden } from '../../Styled';
import AgendaSelectField from '../AgendaSelectField/AgendaSelectField';
import RemarkCategory from '../EditRemark/RemarkCategory';
import FieldGroup from '../FieldGroup/FieldGroup';
import FieldInput from '../FieldInput/FieldInput';
import FieldSelect from '../FieldSelect/FieldSelect';
import TalentNameField from '../TalentNameField/TalentNameField';

const REMARK_TYPE_OPTIONS = [
  { value: 'Talent', label: 'Talent' },
  { value: 'General', label: 'General' },
];

const RemarkTypeSelect = React.memo((props) => {
  return (
    <FastField
      name="remarkType"
      options={REMARK_TYPE_OPTIONS}
      component={FieldSelect}
      placeholder="Select"
      {...props}
    />
  );
});

const CategorySelect = React.memo((props) => {
  return (
    <FastField
      name="category"
      options={props?.isMeetingDetail ? [...CATEGORY_OPTIONS, { value: 'Additional Input', label: 'Additional Input' }] : CATEGORY_OPTIONS}
      component={FieldSelect}
      placeholder="Select Category"
      {...props}
    />
  );
});

const ErrorListener = React.memo(({ isValidating, setIsValidating }) => {
  const { errors } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isValidating && !isEmpty(errors)) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: FILL_OUT_REQUIRE_MESSAGE,
          timeShow: 0,
        })
      );
      setIsValidating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isValidating]);

  return <></>;
});

const InitFormValues = React.memo(
  ({
    formValues,
    setCompareData,
    isSubmittedFormChanges,
    handleSubmit,
    setSubmittedRemarkForm,
    errors,
    setValidateOnChange,
    setIsValidating,
    compareData,
  }) => {
    useEffect(() => {
      if (
        formValues?.remarkType?.value &&
        formValues?.remarkType?.value !== compareData.currentData?.remark_fields?.remarkType?.value
      ) {
        setCompareData((prev) => ({
          ...prev,
          currentData: {
            ...prev.currentData,
            remark_fields: {
              ...INITIAL_VALUES,
              remarkType: {
                label: formValues?.remarkType?.value,
                value: formValues?.remarkType?.value,
              },
              remark: formValues?.remark,
            },
          },
        }));
      } else {
        setCompareData((prev) => ({
          ...prev,
          currentData: { ...prev.currentData, remark_fields: formValues },
        }));
      }
    }, [formValues, setCompareData]);

    useEffect(() => {
      if (isSubmittedFormChanges) {
        handleSubmit();
        setValidateOnChange(true);
        setIsValidating(true);
      }
    }, [isSubmittedFormChanges, handleSubmit]);

    useEffect(() => {
      if (!isEmpty(errors)) {
        setSubmittedRemarkForm((prev) => {
          return {
            ...prev,
            isSubmitted: true,
            hasError: true,
            valuesSubmitted: null,
          };
        });
      }
    }, [errors, isSubmittedFormChanges, setSubmittedRemarkForm]);
  }
);

const INITIAL_VALUES = {
  remarkType: null,
  title: '',
  staff: null,
  remark: '',
  category: null,
  agenda: null,
};

const validationSchema = yup.object().shape({
  remarkType: yup.object().nullable().required('Remarks Type is required.'),
  remark: yup.string().nullable().required('Remarks is required.'),
  category: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Category is required.'),
    }),
  staff: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Talent Name is required.'),
    }),
  agenda: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: null,
      otherwise: (schema) => schema.required('Agenda is required.'),
    }),
});

const validationSchemaForOtherAgenda = yup.object().shape({
  remarkType: yup.object().nullable().required('Remarks Type is required.'),
  remark: yup.string().nullable().required('Remarks is required.'),
  category: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Category is required.'),
    }),
  staff: yup
    .object()
    .nullable()
    .when('remarkType.value', {
      is: 'Talent',
      then: (schema) => schema.required('Talent Name is required.'),
    }),
});

const GENERAL_REMARK_KEYS = ['remarkType', 'agenda', 'remark'];
const TALENT_REMARK_KEYS = [...GENERAL_REMARK_KEYS, 'category', 'staff'];

const DisplayBox = React.memo(({ children, isDisplay }) => {
  return (
    <>{isDisplay ? children : React.Children.map(children, (child) => <Hidden>{child}</Hidden>)}</>
  );
});

const getFp = (key) => (ob) => get(ob, key);

export default React.memo(
  ({
    onAddRemark,
    agendaOptions,
    loading,
    remarks,
    agendaType,
    setCompareData,
    compareData,
    setSubmittedRemarkForm,
  }) => {
    const { isSubmittedFormChanges } = useSelector((state) => state.app.meetingModule.preMeeting);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [isValidating, setIsValidating] = useState(true);

    const { searchProfiles } = useProfilesSearch({
      mapData: (profiles) =>
        map(profiles, ({ birthName, birth_name, opu, sg, image, staffId }) => ({
          label: birthName || birth_name || '-',
          value: { birthName, birth_name, opu, sg, image, staffId },
        })),
    });

    const mapDataConfigure = {
      remarkType: getFp('value'),
      agenda: getFp('value'),
      staff:
        agendaType !== AGENDA_TYPES.OTHERS_AGENDA ? getFp(['value', 'staffId']) : getFp(['value']),
      category: getFp('value'),
    };

    const handleSubmit = useCallback(
      (values, actions, isSubmitted) => {
        const valuesSubmitted = _(values)
          .pick(values.remarkType.value === 'Talent' ? TALENT_REMARK_KEYS : GENERAL_REMARK_KEYS)
          .mapValues((value, key) => (mapDataConfigure[key] ? mapDataConfigure[key](value) : value))
          .value();
        onAddRemark(valuesSubmitted, actions);
        setCompareData((prev) => ({
          ...prev,
          initData: {
            ...prev.initData,
            remark_fields: INITIAL_VALUES,
          },
          currentData: { ...prev.currentData, remark_fields: INITIAL_VALUES },
        }));

        if (isSubmitted) {
          setSubmittedRemarkForm({
            isSubmitted: true,
            hasError: false,
          });
        }

        setValidateOnChange(false);
        actions.resetForm();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onAddRemark]
    );

    const isCheckError = (errors, name, touched) => {
      if (!errors[`${name}`] && !touched[`${name}`]) return false;
      if (errors[`${name}`] && touched[`${name}`]) return true;
    };

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={validateOnChange}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, actions) => handleSubmit(values, actions, isSubmittedFormChanges)}
        validationSchema={
          agendaType !== AGENDA_TYPES.OTHERS_AGENDA
            ? validationSchema
            : validationSchemaForOtherAgenda
        }
      >
        {({ values, errors, setFieldValue, getFieldMeta, handleSubmit, touched, setValues }) => {
          // Trigger to search talent profile for talent agenda
          const fetchTalentProfile = async (agendaId) => {
            try {
              const res = await searchProfiles('', agendaId);
              if (!isEmpty(res)) {
                setFieldValue('staff', {
                  value: res[0].value,
                  label: res[0].label || '-',
                });
              }
            } catch (error) {
              console.error(error);
            }
          };

          let newRemarks = [];
          remarks.forEach((item) => {
            if (item?.remarkType === REMARK_TYPES.GENERAL) {
              newRemarks.push({
                label: item?.agendaName,
                value: item?.agendaId,
              });
            }
          });
          let newAgendaOptions = [];
          if (agendaType !== AGENDA_TYPES.OTHERS_AGENDA) {
            if (values?.remarkType?.value === REMARK_TYPES.GENERAL) {
              agendaOptions.forEach((option) => {
                const tempArr = newRemarks.filter((item) => item?.value === option?.value);
                if (tempArr.length <= 0) {
                  newAgendaOptions.push(option);
                }
              });
            } else {
              newAgendaOptions = [...agendaOptions];
            }
          }

          const handleCancel = () => {
            setValues({
              ...INITIAL_VALUES,
            });
          };

          return (
            <>
              {/* Listen errors */}
              <ErrorListener isValidating={isValidating} setIsValidating={setIsValidating} />
              <InitFormValues
                errors={errors}
                formValues={values}
                handleSubmit={handleSubmit}
                setCompareData={setCompareData}
                isSubmittedFormChanges={isSubmittedFormChanges}
                setSubmittedRemarkForm={setSubmittedRemarkForm}
                setValidateOnChange={setValidateOnChange}
                setIsValidating={setIsValidating}
                compareData={compareData}
              />
              <AiTitle as="div">
                <AiH3>Add Remark</AiH3>
              </AiTitle>
              {agendaType === AGENDA_TYPES.OTHERS_AGENDA ? (
                <Row className="my-3">
                  <FieldRow className="w-100">
                    <FieldGroup width="241.5px" label={<AiLabel>Remarks Type</AiLabel>}>
                      <RemarkTypeSelect />
                    </FieldGroup>
                    <DisplayBox isDisplay={values.remarkType?.value === REMARK_TYPES.TALENT}>
                      <FieldGroup width="300px" label={<AiLabel>Category</AiLabel>}>
                        <CategorySelect />
                      </FieldGroup>
                    </DisplayBox>
                    <DisplayBox isDisplay={values.remarkType?.value === REMARK_TYPES.TALENT}>
                      <FieldGroup width="335px" label={<AiLabel>Talent Name</AiLabel>}>
                        <TalentNameField
                          field={{ value: values.staff, name: 'staff' }}
                          form={{
                            setFieldValue: setFieldValue,
                            getFieldMeta: getFieldMeta,
                          }}
                          placeholder="Enter Talent Name"
                          formValues={values}
                          agendaType={agendaType}
                          disabled={values?.agenda?.type === AGENDA_SHORT_TYPES.TALENT_REVIEW}
                        />
                      </FieldGroup>
                    </DisplayBox>
                  </FieldRow>
                  <FieldGroup width="100%" label={<AiLabel>Remarks</AiLabel>} className="mt-3">
                    <FieldInput
                      fieldAs="textarea"
                      height="unset"
                      rows={5}
                      name="remark"
                      placeholder="Enter your remarks here"
                    />
                  </FieldGroup>
                </Row>
              ) : (
                <Row className="my-3">
                  <FieldRow className="w-100">
                    <FieldGroup width="241.5px" label={<AiLabel>Remarks Type</AiLabel>}>
                      <RemarkTypeSelect />
                    </FieldGroup>
                    <DisplayBox
                      isDisplay={
                        !!values.remarkType && values.remarkType?.value === REMARK_TYPES.GENERAL
                      }
                    >
                      <FieldGroup width="241.5px" label={<AiLabel>Agenda</AiLabel>}>
                        <AgendaSelectField options={newAgendaOptions} />
                      </FieldGroup>
                    </DisplayBox>
                    <DisplayBox isDisplay={values.remarkType?.value === REMARK_TYPES.TALENT}>
                      <FieldGroup width="300px" label={<AiLabel>Category</AiLabel>}>
                        <CategorySelect isMeetingDetail/>
                      </FieldGroup>
                      {agendaType !== AGENDA_TYPES.OTHERS_AGENDA && (
                        <DisplayBox isDisplay={!!values.remarkType}>
                          <FieldGroup width="241.5px" label={<AiLabel>Agenda</AiLabel>}>
                            <RemarkCategory
                              selected={values.agenda}
                              setSelected={(event) => {
                                setFieldValue('agenda', event);
                                if (
                                  event?.type === AGENDA_SHORT_TYPES.TALENT_REVIEW &&
                                  event?.value
                                ) {
                                  fetchTalentProfile(event.value);
                                } else {
                                  setFieldValue('staff', null);
                                }
                              }}
                              options={newAgendaOptions}
                              placeholder="Select Agenda"
                              status={isCheckError(errors, 'agenda', touched) ? 'error' : ''}
                              agendaType={agendaType}
                            />
                          </FieldGroup>
                        </DisplayBox>
                      )}
                      <FieldGroup width="335px" label={<AiLabel>Talent Name</AiLabel>}>
                        <TalentNameField
                          field={{ value: values.staff, name: 'staff' }}
                          form={{
                            setFieldValue: setFieldValue,
                            getFieldMeta: getFieldMeta,
                          }}
                          placeholder="Enter Talent Name"
                          formValues={values}
                          agendaType={agendaType}
                          disabled={values?.agenda?.type === AGENDA_SHORT_TYPES.TALENT_REVIEW}
                        />
                      </FieldGroup>
                    </DisplayBox>
                  </FieldRow>
                  <FieldGroup width="100%" label={<AiLabel>Remarks</AiLabel>} className="mt-3">
                    <FieldInput
                      fieldAs="textarea"
                      height="unset"
                      rows={5}
                      name="remark"
                      placeholder="Enter your remarks here"
                    />
                  </FieldGroup>
                </Row>
              )}

              <Row justify="end">
                <AiButton
                  border={true}
                  borderColor={`#00a19c`}
                  style={{ width: `100px` }}
                  onClick={handleCancel}
                >
                  Cancel
                </AiButton>
                <AiButton
                  onKeyDown={() => {}}
                  onClick={() => {
                    handleSubmit();
                    setValidateOnChange(true);
                    setIsValidating(true);
                  }}
                  mode="teal"
                  disabled={loading}
                >
                  Add Remark
                </AiButton>
              </Row>
            </>
          );
        }}
      </Formik>
    );
  }
);
