import { get } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReactExport from 'react-data-export';

import { auditLogApi } from '../../../../../services/auditLog';
import { UserActivityLogExportColumns } from './audit-log-export.constant';
import { AiButton } from '../../../../../assets/common';
import { Tooltip } from 'antd';
import { commonTooltipStyles } from '../../../../../assets/common/tooltipStyles';
import { useShowAlertWhenRefresh } from '../../../../../hooks/useShowAlertWhenRefresh';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../../../hooks/useShowAlertUploadDownloadWhenNavigate';

const UserActivityLogExport = (props) => {
  const { searchData, roleId, totalData, setShowExporting } = props;
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const fileName = 'User Activity Log';
  const [isExport, setIsExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const handleOnExport = async () => {
    setIsLoading(true);
    setShowExporting(true);
    setIsUploadingOrDownloading(true);
    setShowAlertRefresh(true);
    try {
      const res = await auditLogApi.getUserActivityLogs(
        { ...searchData, page: null, limit: null, isExport: true },
        roleId
      );
      if (res.status === 200) {
        const tempData = get(res, 'data.result', []);
        setData(tempData.length ? tempData : [{}]);
        setIsExport(true);
        setTimeout(() => {
          setIsExport(false);
        }, 300);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setShowExporting(false);
      setIsUploadingOrDownloading(false);
      setShowAlertRefresh(false);
    }
  };

  const generateDataSet = (data) => {
    if (!(data || data?.length)) return;
    const dataExport = data.map((item, index) => {
      return [
        { value: index + 1 },
        { value: item?.email || '' },
        { value: item?.type || '' },
        {
          value: item?.url || '',
        },
        { value: item?.time ? moment(item?.time).format('DD-MMMM-YYYY HH:mm:ss') : '' },
      ];
    });
    return [
      {
        columns: [...UserActivityLogExportColumns],
        data: [...dataExport],
      },
    ];
  };

  return (
    <>
      {isLoading || !totalData ? (
        <div style={{ marginLeft: '230px', marginTop: '8px' }}>
          <Tooltip title="No record to export" {...commonTooltipStyles}>
            <AiButton mode="teal" disabled={!totalData || isLoading} onClick={handleOnExport}>
              Export {isLoading && <Spinner animation="border" size="sm" />}
            </AiButton>
          </Tooltip>
        </div>
      ) : (
        <div style={{ marginLeft: '230px', marginTop: '8px' }}>
          <AiButton mode="teal" disabled={!totalData || isLoading} onClick={handleOnExport}>
            Export {isLoading && <Spinner animation="border" size="sm" />}
          </AiButton>
        </div>
      )}
      {isExport && (
        <ExcelFile hideElement={true} filename={fileName}>
          <ExcelSheet dataSet={generateDataSet(data)} name="Sheet1" />
        </ExcelFile>
      )}
    </>
  );
};

export default UserActivityLogExport;
