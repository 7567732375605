import { Col, Pagination, Row, Space } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { tc_meeting_breadcrumb, paperForward } from '../../../assets/img';
import StepBar from '../components/StepBar/StepBar';

import __, { filter, find, isEmpty, size } from 'lodash';
import { AlertMessage, AsyncMultipleSelect, ModelTC, BreadCrumb } from '../../../components';
import { useGetAgendas } from '../../../hooks/useGetAgendas';
import { useGetMeeting } from '../../../hooks/useGetMeeting';
import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import AgendaItem from '../components/AgendaItem/AgendaItem';
import { meetingApi } from '../../../services/tcMeeting';
import MeetingCategory from '../MeetingDetails/components/MeetingCategory';
import { addOrUpdateMeeting, clearMeeting } from '../redux/meetingDetailSlice';
import {
  AiContainerStartMeeting,
  AiDescStartMeeting,
  AiGroupBtn,
  AiTitleStartMeeting,
} from './Styled';
import {
  AiInput,
  AiDatePicker,
  AiTimeField,
  AiLabel,
  AiContainerForm,
  AiH2,
  AiH3,
  MeetingTitle,
  AiAgendaContainer,
  AiAgendaLineHeader,
  AiAgendaTitle,
} from '../MeetingDetails/Styled';
import { clearAgendaState } from '../redux/agendaSlice';
import { addOrUpdateStepper } from '../redux/stepperSlice';
import {
  DATE_TIME_FORMAT,
  MEETING_DETAIL_STATUS,
  NOTIFICATION_TYPE,
} from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import MeetingStatus from '../components/MeetingStatus/MeetingStatus';
import { AiButton, AiSpin, AiAlert } from '../../../assets/common';
import styles from './overall-meeting.module.scss';

const numEachPage = 6;

const OverallMeeting = () => {
  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [visibleSaveAsDraft, setVisibleSaveAsDraft] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowStartMeetingMsg, setIsShowStartMeetingMsg] = useState(false);
  const { idMeeting } = useParams();
  const { data: listAgenda, loading: loadingGetAgenda } = useGetAgendas({
    idMeeting,
  });
  const [clonelistAgendas, setClonelistAgendas] = useState([]);
  useEffect(() => {
    if (size(listAgenda) > 0) {
      const list = __(listAgenda)
        .sortBy((item) => moment(item.start_time, true).format(DATE_TIME_FORMAT.TIME))
        .uniqBy('agenda_id')
        .value();

      setClonelistAgendas(list);
    }
  }, [listAgenda]);

  const [isError, setIsError] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);
  const [visibleStartMeeting, setVisibleStartMeeting] = useState(false);
  const [selected, setSelected] = useState({});
  const [loadingDeleteMeeting, setLoadingDeleteMeeting] = useState(false);
  const [visibleRemoveMeeting, setVisibleRemoveMeeting] = useState(false);
  const [breadCrumbList] = useState([
    { name: 'TC Meeting', icon: tc_meeting_breadcrumb },
    { name: 'Meeting Details' },
    { name: 'Agenda' },
    { name: 'Publish and Send Invite' },
  ]);

  useEffect(() => {
    setSelected(meeting.category);
  }, [meeting.category]);

  const { data: meetingDetail, loading: loadingGetMeeting } = useGetMeeting({ idMeeting });
  const meeting_category_option = [
    {
      id: '1',
      name: 'PETRONAS',
    },
    {
      id: '2',
      name: 'Business',
    },
    {
      id: '3',
      name: 'OPU/Division',
    },
  ];

  const { secretarialOptions } = useSecretarialOptions('add_meeting');

  useEffect(() => {
    if (meetingDetail && meetingDetail.meeting_id) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(meeting_category_option, {
            name: meetingDetail.category,
          }),
          category_value: {
            name: meetingDetail.category_value,
            category_label: meetingDetail.category_label,
          },
          meeting_secretariats: filter(secretarialOptions, ({ value }) => {
            return find(meetingDetail.meeting_secretariats, {
              bu_unit_id: value.bu_unit_id,
              business_type: value.business_type,
              role_id: value.role_id,
            });
          }),
        })
      );
      if (meetingDetail.status === MEETING_DETAIL_STATUS.PUBLISHED) {
        dispatch(
          addOrUpdateStepper({
            currentTabActive: 3,
            currentTabFinished: [1, 2, 3],
            tabsActive: [1, 2, 3],
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail, secretarialOptions, dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting,
  });

  useEffect(() => {
    formik.setValues(meeting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting, formik.setValues]);

  const updateStatusOfMeeting = async () => {
    setLoading(true);
    try {
      const res = await meetingApi.updateStatusOfMeeting({
        meetingId: idMeeting,
        roleUser: user,
      });
      if (res.status === 200) {
        setLoading(false);
        setVisibleSaveAsDraft(false);
        if (!meeting?.is_secretariat_or_facilitator) {
          exitAgenda();
        } else {
          setVisibleStartMeeting(true);
        }
        setIsError(false);
        dispatch(
          addOrUpdateStepper({
            currentTabActive: 3,
            currentTabFinished: [1, 2, 3],
            tabsActive: [1, 2, 3],
          })
        );
      }
    } catch (error) {
      setLoading(false);
      setVisibleSaveAsDraft(false);
      setIsError(true);
    }
  };

  const handlePageChange = (value) => {
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
  };
  const exitAgenda = () => {
    dispatch(clearMeeting());
    dispatch(clearAgendaState());
    history.push('/calendar');
  };
  const handleStartMeeting = async () => {
    try {
      let response = await meetingApi.startMeetingById(idMeeting);
      if (response.status === 200) {
        const redirectUrl = `/meeting/detail/${idMeeting}`;
        dispatch(clearMeeting());
        dispatch(clearAgendaState());
        setIsShowStartMeetingMsg(true);
        handleRedirectUrl(redirectUrl);
        return response;
      }
    } catch (error) {
      setIsShowStartMeetingMsg(false);
      return console.error(error);
    }
  };

  const handleRedirectUrl = (url) => {
    setTimeout(() => {
      history.push(url);
    }, 500);
  };

  return (
    <>
      <BreadCrumb level={4} breadCrumbList={breadCrumbList} />
      <StepBar />
      <div style={{ marginBottom: '20px' }}>
        {isError && <AlertMessage message="Publish and Send Invite Failed" type="error" />}
      </div>
      {isShowStartMeetingMsg && (
        <div style={{ marginBottom: '20px' }}>
          <AlertMessage message={`${meetingDetail?.meeting_name} is started!`} type="success" />
        </div>
      )}
      {!visibleStartMeeting && ![MEETING_DETAIL_STATUS.PUBLISHED].includes(meeting?.status) && (
        <>
          {loadingGetAgenda || loadingGetMeeting ? (
            <AiSpin tip="Loading...">
              <AiAlert />
            </AiSpin>
          ) : (
            <>
              <MeetingTitle>
                <AiH2>{meeting.meeting_name}</AiH2>
                <MeetingStatus status={meeting.status} />
              </MeetingTitle>
              <AiContainerForm>
                <MeetingTitle>
                  <AiH3>Meeting Details</AiH3>
                </MeetingTitle>

                <Row className="mb-3">
                  <Col span={24}>
                    <AiLabel>Meeting Name</AiLabel>
                    <AiInput
                      placeholder="Meeting Name"
                      name="meeting_name"
                      value={formik.values.meeting_name}
                      disabled
                    />
                  </Col>
                </Row>
                <div className={styles.rowInput}>
                  <div className={styles.inputItem}>
                    <AiLabel>Date</AiLabel>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <AiDatePicker
                        picker="Date"
                        format={DATE_TIME_FORMAT.DATE_SPACE}
                        value={
                          formik.values.date &&
                          moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)
                        }
                        disabled
                      />
                    </Space>
                  </div>
                  <div className={styles.inputItem}>
                    <AiLabel>Meeting Category</AiLabel>
                    <MeetingCategory
                      selected={selected}
                      setSelected={setSelected}
                      disabled={true}
                    />
                  </div>
                  {!isEmpty(formik.values.category) &&
                    formik.values.category.name !== meeting_category_option[0].name && (
                      <div className={styles.inputItem}>
                        <AiLabel>{formik.values.category.name}</AiLabel>
                        <MeetingCategory selected={formik.values.category_value} disabled={true} />
                      </div>
                    )}
                  <div className={styles.inputItem}>
                    <AiLabel>Start time</AiLabel>
                    <AiTimeField
                      value={formik.values.start_time}
                      style={{ width: '100%' }}
                      disabled
                    />
                  </div>
                  <div className={styles.inputItem}>
                    <AiLabel>End time</AiLabel>
                    <AiTimeField
                      value={formik.values.end_time}
                      style={{ width: '100%' }}
                      disabled
                    />
                  </div>
                </div>

                <Row className="mb-3">
                  <Col span={24}>
                    <AiLabel>Location</AiLabel>
                    <AiInput
                      placeholder="Enter Location"
                      name="location"
                      value={formik.values.location}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <AiLabel>Chairman</AiLabel>
                    <AsyncMultipleSelect
                      value={formik.values.chairman}
                      placeholder="Enter Chairman Name"
                      isDisabled
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <AiLabel>Committee Members</AiLabel>
                    <AsyncMultipleSelect
                      placeholder={'Enter Committee Members Name'}
                      value={formik.values.meeting_committee}
                      isDisabled
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <AiLabel>Meeting Facilitators</AiLabel>
                    <AsyncMultipleSelect
                      value={formik.values.meeting_facilitator}
                      placeholder={'Enter Meeting Facilitators Name'}
                      isDisabled
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <AiLabel>Meeting Secretariat</AiLabel>
                    <AsyncMultipleSelect
                      placeholder={'Enter Meeting Secretariat Name'}
                      value={formik.values.meeting_secretariats}
                      isDisabled
                    />
                  </Col>
                </Row>
              </AiContainerForm>
              <AiAgendaContainer>
                <AiAgendaLineHeader>
                  <AiAgendaTitle>Agenda</AiAgendaTitle>
                </AiAgendaLineHeader>
                <div>
                  {!!clonelistAgendas &&
                    clonelistAgendas.length > 0 &&
                    clonelistAgendas.slice(minValue, maxValue).map((item, idx) => (
                      <div key={idx} style={{ cursor: 'pointer', margin: '5px' }}>
                        <AgendaItem item={item} key={idx} avatar={false} isViewDetail={true} />
                      </div>
                    ))}

                  {clonelistAgendas && clonelistAgendas.length > 0 && (
                    <Pagination
                      total={clonelistAgendas.length}
                      showTotal={(total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} results`
                      }
                      defaultCurrent={1}
                      defaultPageSize={numEachPage}
                      showSizeChanger={false}
                      onChange={handlePageChange}
                    />
                  )}
                </div>
              </AiAgendaContainer>
              <AiGroupBtn className="mb-4">
                <div>
                  <AiButton mode={'danger'} onClick={() => setVisibleRemoveMeeting(true)}>
                    Delete Meeting
                  </AiButton>
                </div>
                <div>
                  <AiButton
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </AiButton>
                  <AiButton
                    mode={'teal'}
                    className="ml-2"
                    onClick={() => setVisibleSaveAsDraft(true)}
                    data-testid="publishSendInviteBtn"
                  >
                    Publish and Send Invite
                  </AiButton>
                </div>
              </AiGroupBtn>
            </>
          )}
        </>
      )}

      <ModelTC
        data-testid="push-model"
        info={{
          type: 'pushAndSendInvite',
          visible: visibleSaveAsDraft,
          setVisible: setVisibleSaveAsDraft,
          handleSubmit: updateStatusOfMeeting,
          loading: loading,
          onClose: () => {},
        }}
      />

      {visibleStartMeeting && (
        <AiContainerStartMeeting>
          <Row>
            <Col span={12}>
              <AiTitleStartMeeting>
                Great! Meeting has been published and invitation has been sent!
              </AiTitleStartMeeting>
              <AiDescStartMeeting>
                Would you like to start the meeting now? or you can back to Home menu.
              </AiDescStartMeeting>
              <AiButton
                border={true}
                // mode={itemIncumbent.modeCancel}
                onClick={exitAgenda}
                // disabled={info.loading}
                data-testid="back-to-calendar"
              >
                Back to Calendar
              </AiButton>
              <AiButton
                // border={itemIncumbent.borderSubmit}
                mode={'teal'}
                className="ml-2"
                onClick={handleStartMeeting}
                // disabled={info.loading}
                // loading={info.loading}
              >
                Start Meeting Now
              </AiButton>
            </Col>
            <Col span={12}>
              <img src={paperForward} alt="" />
            </Col>
          </Row>
        </AiContainerStartMeeting>
      )}
      <ModelTC
        info={{
          type: 'deleteMeeting',
          visible: visibleRemoveMeeting,
          setVisible: setVisibleRemoveMeeting,
          handleSubmit: async () => {
            setLoadingDeleteMeeting(true);
            try {
              let res = await meetingApi.deleteMeeting({ idMeeting });
              if (res.status === 200) {
                exitAgenda();
                setVisibleRemoveMeeting(false);
                setLoadingDeleteMeeting(false);
              }
            } catch (error) {
              showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
              setVisibleRemoveMeeting(false);
              setLoadingDeleteMeeting(false);
            }
          },
          onClose: () => {},
          loading: loadingDeleteMeeting,
        }}
      />
    </>
  );
};
export default OverallMeeting;
