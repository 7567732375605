import { notification } from 'antd';
import { findIndex, get, isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { AiAlert, AiSpin, WrapperBtn } from '../../../../../assets/common';
import { SearchPositionBox } from '../../../../../components';
import { getPositionGroup } from '../../../../../services/successionplanningratio';
import { PERMISSION } from '../../../../../utils/constants';
import { addPosition, getAgendaTalents } from '../../../redux/agendaSlice';
import styles from './add-position-dialog.module.scss';
import PositionListByCategory from './PositionListByCategory';
import SearchPositionByCategory from './SearchPositionByCategory';
import { AiButton } from './Styled';

const AddPositionDialog = (props) => {
  const { setIsVisible, ...otherProps } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const [options] = useState([
    'Attrition within the next 1 year',
    'Last reviewed more than 3 years',
    'Succession planning not conducted yet',
    'Less than 3 first line successors',
  ]);
  const [select, setSelect] = useState('');
  const [isShowReview, setIsShowReview] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const { positions } = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const { loading } = useSelector((state) => state.spTemplate);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [positionToBeReviewed, setPositionToBeReviewed] = useState({
    value: '',
    showTerm: '',
  });
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (select) {
      setIsShowReview(true);
    } else {
      setIsShowReview(false);
    }
  }, [select]);

  const handleCheckExisted = async (item) => {
    let result = findIndex(positions, item.value);
    const existedListPosistion = searchList.find(
      (search) => item.value && search.position_code === item.value.position_code
    );
    if (!existedListPosistion && !positionToBeReviewed.value) {
      return notification.error({
        message: `Please select an option`,
        placement: 'topRight',
        duration: 1.5,
      });
    }

    if (result === -1 && (existedListPosistion || positionToBeReviewed.value)) {
      const roles = {
        role: roleActive,
        permission: PERMISSION.CREATE_MEETING,
      };
      let res = await getPositionGroup(item.value.position_code, 1, roles);
      if (res.data.result.data && res.data.result.data.length > 1) {
        const newPositions = res.data.result.data;
        newPositions.forEach((item) => {
          dispatch(
            addPosition({
              ...item,
            })
          );
        });
      } else {
        dispatch(
          addPosition({
            ...item.value,
          })
        );
      }

      dispatch(getAgendaTalents({ positionCode: item.value.position_code }));

      setSearchTerm({
        type: '',
        value: '',
        showTerm: '',
      });

      notification.success({
        message: `Added successful position`,
        placement: 'topRight',
        duration: 1.5,
      });
      otherProps?.setIsPositionChanges && otherProps.setIsPositionChanges(true);
      otherProps?.setPrevAgendaData &&
        otherProps.setPrevAgendaData((prev) => ({
          ...prev,
          positions: [],
        }));
      handleCancel();
      return;
    }
    notification.error({
      message: `Position has already been added`,
      placement: 'topRight',
      duration: 1.5,
    });
  };

  const handleAddPosition = () => {
    if (isEmpty(positionToBeReviewed.value) && isEmpty(searchTerm.value)) {
      return notification.error({
        message: `Please select an option`,
        placement: 'topRight',
        duration: 1.5,
      });
    }

    if (!isEmpty(positionToBeReviewed.value)) {
      handleCheckExisted(positionToBeReviewed);
      setPositionToBeReviewed({
        value: '',
        showTerm: '',
      });
    }

    if (!isEmpty(searchTerm.value)) {
      let positionInDraft = get(searchTerm, 'drafted_in', {});
      if (size(positionInDraft) > 0) {
        return notification.error({
          message: `Position has already been added in another agenda`,
          placement: 'topRight',
          duration: 1.5,
        });
      }
      if (isEmpty(searchList)) {
        return notification.error({
          message: `Position isn't existed`,
          placement: 'topRight',
          duration: 1.5,
        });
      }
      handleCheckExisted(searchTerm);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleCancel}
        dialogClassName={styles.modal}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div>Add Position</div>
          <p>Add Position in Succession Planning</p>
        </Modal.Header>
        <Modal.Body className={styles.table}>
          {loading.addPosition && (
            <AiSpin tip="Loading...">
              <AiAlert />
            </AiSpin>
          )}
          {!loading.addPosition && (
            <>
              <div className={styles.form}>
                <div>Search Position</div>
                <SearchPositionBox
                  selectedSp={select}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  searchList={searchList}
                  setSearchList={setSearchList}
                />
              </div>
              <div className={styles.form}>
                <div>Or Category </div>
                <SearchPositionByCategory
                  setSelect={setSelect}
                  select={select}
                  searchTerm={searchTerm}
                  options={options}
                />
              </div>
              <PositionListByCategory
                isShowReview={isShowReview}
                successPlanning={select}
                positionToBeReviewed={positionToBeReviewed}
                setPositionToBeReviewed={setPositionToBeReviewed}
              />
            </>
          )}

          <WrapperBtn isLoading={loading.addPosition}>
            <AiButton disabled={loading.addPosition} onClick={handleAddPosition}>
              <span data-testid="add-pos-btn">Add Position</span>
            </AiButton>
          </WrapperBtn>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPositionDialog;
