import { notification } from 'antd';
import { concat, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { MOBILITY_MESSAGE, MOBILITY_MODAL_ADD_TYPE } from '../../constants';
import MobilityPosition from '../MobilityPosition/MobilityPosition';
import MobilityRecommendedAgenda from '../MobilityRecommendedAgenda/MobilityRecommendedAgenda';
import styles from './mobility-drag-drop.module.scss';

const MobilityDragDrop = (props) => {
  const {
    setMobilitySimulation,
    mobilitySimulation,
    handleShowMessageSuccess,
    removeItemOnChain,
    isHighlighted,
    setIsHighlighted,
    setIsSeniorManager,
    isSeniorManager,
  } = props;
  const [dataRecommended, setDataRecommended] = useState({
    approveList: [],
    aiList: [],
    reportList: [],
  });
  const [modalAddType, setModalAddType] = useState(MOBILITY_MODAL_ADD_TYPE.POSITION);

  function addPositionOrTalent(item) {
    const isExists = mobilitySimulation.dataChain.find((d) => Number(d.key) === Number(item.key));
    if (isExists)
      return notification.error({
        message: 'Talent added.',
        placement: 'topRight',
        duration: 1.5,
      });

    const convertItemSelected = {
      ...item,
      isRemove: true,
      isDisableIndex: true,
      removeItemOnChain: removeItemOnChain,
    };

    setIsHighlighted({
      key: Number(convertItemSelected.key),
      position_code: convertItemSelected.position_code,
    });
    setMobilitySimulation((prev) => {
      return {
        ...prev,
        dataChain: [...prev.dataChain, convertItemSelected],
      };
    });
    if (item.type === MOBILITY_MODAL_ADD_TYPE.POSITION)
      handleShowMessageSuccess(MOBILITY_MESSAGE.ADD_POSITION_SUCCESSFUL);
    if (item.type === MOBILITY_MODAL_ADD_TYPE.TALENT)
      handleShowMessageSuccess(MOBILITY_MESSAGE.ADD_TALENT_SUCCESSFUL);
  }

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) return;

    const itemSelected = concat(
      dataRecommended.approveList,
      dataRecommended.aiList,
      dataRecommended.reportList
    ).find((item) => Number(item.key) === source.index);

    const convertItemSelected = {
      ...itemSelected,
      isRemove: true,
      isDisableIndex: true,
      removeItemOnChain: removeItemOnChain,
      type: MOBILITY_MODAL_ADD_TYPE.TALENT,
    };

    setIsHighlighted({
      key: Number(convertItemSelected.key),
      position_code: convertItemSelected.position_code,
    });

    setMobilitySimulation((prev) => {
      return {
        ...prev,
        dataChain: [...prev.dataChain, convertItemSelected],
      };
    });
    if (itemSelected.type === MOBILITY_MODAL_ADD_TYPE.TALENT)
      handleShowMessageSuccess(MOBILITY_MESSAGE.ADD_TALENT_SUCCESSFUL);
  }

  useEffect(() => {
    if (isEmpty(mobilitySimulation.dataChain))
      return setModalAddType(MOBILITY_MODAL_ADD_TYPE.POSITION);
    mobilitySimulation.dataChain.some(
      (element) => element.item === MOBILITY_MODAL_ADD_TYPE.POSITION
    )
      ? setModalAddType(MOBILITY_MODAL_ADD_TYPE.POSITION)
      : setModalAddType(MOBILITY_MODAL_ADD_TYPE.TALENT);
  }, [mobilitySimulation.dataChain]);

  return (
    <div className={styles.section}>
      <DragDropContext onDragEnd={onDragEnd}>
        <MobilityPosition
          mobilitySimulation={mobilitySimulation}
          addPositionOrTalent={addPositionOrTalent}
          removeItemOnChain={removeItemOnChain}
          isHighlighted={isHighlighted}
          modalAddType={modalAddType}
          setMobilitySimulation={setMobilitySimulation}
          handleShowMessageSuccess={handleShowMessageSuccess}
          setIsHighlighted={setIsHighlighted}
          setIsSeniorManager={setIsSeniorManager}
          isSeniorManager={isSeniorManager}
        />
        <MobilityRecommendedAgenda
          mobilitySimulation={mobilitySimulation}
          modalAddType={modalAddType}
          setDataRecommended={setDataRecommended}
          dataRecommended={dataRecommended}
          isHighlighted={isHighlighted}
          isSeniorManager={isSeniorManager}
          isShowRemark={false}
          agendaDetail={null}
          setShowAddRemarkSuccess={() => {}}
        />
      </DragDropContext>
    </div>
  );
};

export default MobilityDragDrop;
