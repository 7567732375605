import { Col, Row, Select } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

import { BasicButton } from '../../../../../assets/common';
import {
  checked_manual_run_status,
  dropdown_icon,
  finder_filter_disable,
  finder_filter_enable,
} from '../../../../../assets/img';
import { MANUAL_RUN_SP_CRITICAL_POSITION_STATUS } from '../../constants';
import FilterCalendar from '../components/FilterCalendar/FilterCalendar';
import styles from './menu-bar-manual-status.module.scss';

const { Option } = Select;

const Wrapper = styled.div`
  position: relative;

  .checked {
    position: absolute;
    left: 10px;
    top: 12px;
    width: 18px;
  }
  .custom-select-manual-status {
    .ant-select-selection-item {
      padding-left: 30px;
    }
  }
`;
const ClearFilter = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #00847c;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
  user-select: none;
`;

export default function MenuBarManualStatus(props) {
  const { showFilter, setShowFilter, query, setQuery, fetchData } = props;
  const { keyword, status } = query;
  const [searchInput, setSearchInput] = useState('');

  function resetState() {
    setSearchInput('');
    setQuery((prev) => {
      return {
        ...prev,
        dateRange: [null, null],
        status: null,
        keyword: '',
        page: 1,
      };
    });
  }

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <input
                data-testid="search-input"
                placeholder="Search Position Code or Position Name"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton
                mode="teal"
                onKeyDown={() => {}}
                onClick={() => {
                  keyword === searchInput
                    ? fetchData()
                    : setQuery((prev) => {
                        return {
                          ...prev,
                          keyword: searchInput,
                        };
                      });
                }}
              >
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <div className={styles.icons}>
                <img
                  src={showFilter ? finder_filter_enable : finder_filter_disable}
                  alt="Filter"
                  onKeyDown={() => {}}
                  onClick={() => {
                    setShowFilter(!showFilter);
                    if (showFilter) {
                      resetState();
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <Row style={{ marginTop: 22 }} className="w-100">
          <Col>
            <Row gutter={16}>
              <Col>
                <FilterCalendar query={query} setQuery={setQuery} />
              </Col>
              <Col>
                <Wrapper>
                  <Select
                    placeholder="Select"
                    suffixIcon={<img src={dropdown_icon} alt="" />}
                    style={{ width: '200px' }}
                    value={status}
                    onChange={(e) =>
                      setQuery((prev) => {
                        return {
                          ...prev,
                          status: e,
                        };
                      })
                    }
                    className="custom-select-manual-status"
                  >
                    <Option key="Running" value={MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.RUNNING}>
                      {MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.RUNNING}
                    </Option>
                    <Option key="Queue" value={MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.QUEUE}>
                      {MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.QUEUE}
                    </Option>
                    <Option key="Failed" value={MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.FAILED}>
                      {MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.FAILED}
                    </Option>
                    <Option
                      key="Completed"
                      value={MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.COMPLETED}
                    >
                      {MANUAL_RUN_SP_CRITICAL_POSITION_STATUS.COMPLETED}
                    </Option>
                    <Option key="All" value="All">
                      All
                    </Option>
                  </Select>
                  {!isEmpty(status) && (
                    <img src={checked_manual_run_status} className="checked" alt="" />
                  )}
                </Wrapper>
              </Col>
              <Col>
                {(query.dateRange[0] || query.status) && (
                  <ClearFilter
                    onKeyDown={() => {}}
                    onClick={() => {
                      resetState();
                    }}
                  >
                    Clear Filters
                  </ClearFilter>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}
