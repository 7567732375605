import { Col, Row } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AiButton, BasicButton } from '../../../../../assets/common';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { MULTIPLE_FILTER_TYPE } from '../../../../../utils/constants';
import AuditLogFilter from '../../../AuditLogs/components/AuditLogFilter/AuditLogFilter';
import SearchBoxCustom from '../../../components/SearchBoxCustom/SearchBoxCustom';
import * as styles from './menu-bar-mapping.module.scss';

export default function MenuBarMapping(props) {
  const {
    showFilter,
    setShowFilter,
    updateStatusVisible,
    setUpdateStatusVisible,
    deleteSelecteds,
    handleDeleteRecord,
    loading,
    searchParams,
    setSearchParams,
    setIsEdit,
    isEdit,
    filterOptions,
  } = props;
  const { keyword } = searchParams;
  const history = useHistory();

  // State search field
  const [select] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [cloneFilterOption, setcloneFilterOption] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  useEffect(() => {
    const cloneData = cloneDeep(filterOptions);
    setcloneFilterOption(cloneData);
  }, [filterOptions, setcloneFilterOption]);

  const handleAddNew = () => {
    history.push(`/admin/master-data-management/add-new-sg-jg-mapping`);
  };

  const setSelectedOptions = (selectedOption, type) => {
    setSearchParams((prev) => ({
      ...prev,
      [type]: selectedOption,
    }));
  };

  useEffect(() => {
    if (keyword) return;
    setSearchTerm({
      type: '',
      value: '',
      showTerm: '',
    });
  }, [keyword]);

  return (
    <>
      {!isEdit && (
        <div>
          <Row align="bottom" justify="space-between">
            <ModelTC
              info={{
                type: 'deleteSGJGMapping',
                visible: updateStatusVisible,
                setVisible: setUpdateStatusVisible,
                handleSubmit: () => handleDeleteRecord(),
                loading,
                onClose: () => {
                  setUpdateStatusVisible(false);
                },
              }}
            />
            <Col span={16}>
              <Row align="bottom" gutter={8}>
                <Col span={18}>
                  <SearchBoxCustom
                    selectedSp={select}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchList={searchList}
                    setSearchList={setSearchList}
                    fieldName={'SG_JG_in_Oracle'}
                    searchPlaceholder={'Search SG in Oracle or JG in Oracle'}
                  />
                </Col>
                <Col>
                  <BasicButton mode="teal" onKeyDown={() => {}} onClick={() => setSelectedOptions(searchTerm?.value?.value || '', 'keyword')}>
                    Search
                  </BasicButton>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row align="bottom" justify="space-between" gutter={8}>
                <Col>
                  <BasicButton
                    onKeyDown={() => {}}
                    onClick={() => deleteSelecteds.length > 0 && setUpdateStatusVisible(true)}
                    className={`${styles.button} ${!deleteSelecteds.length ? styles.inActiveDelBtn : styles.activeDelBtn}`}
                    disabled={!deleteSelecteds.length}
                  >
                    Confirm Delete Position
                  </BasicButton>
                </Col>
                <Col>
                  <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleAddNew}>
                    Add New
                  </BasicButton>
                </Col>
                <Col>
                  <div className={styles.icons}>
                    <img
                      src={showFilter ? finder_filter_enable : finder_filter_disable}
                      alt="Filter"
                      onKeyDown={() => {}}
                      onClick={() => setShowFilter(!showFilter)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {showFilter && (
            <Row style={{ marginTop: 22 }} gutter={16}>
              <AuditLogFilter
                filterOptions={cloneFilterOption}
                setFilterFinderData={(state) => {
                  setSelectedOptions(state, 'filterData');
                }}
                filterType={MULTIPLE_FILTER_TYPE.SG_JG_MAPPING}
                isClearFilter={true}
                minWidth={'186'}
              />
            </Row>
          )}
        </div>
      )}

      {isEdit && (
        <Row align="bottom" justify="end">
          <AiButton onKeyDown={() => {}} onClick={() => setIsEdit(false)}>
            Back
          </AiButton>
        </Row>
      )}
    </>
  );
}
