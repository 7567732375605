import { isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorIcon, Successfully, WarningBlueIcon, warning_icon } from '../../assets/img';
import { removeMessage, removeMessageByContainer, resetGlobalMessage } from '../../store/alertMessageSlice';
import { useLocation } from 'react-router-dom';
import { AiAlert, AiIconAlert } from '../AlertMessage/Styled';
import * as styles from './global-alert-message.module.scss';

/* Help document for use this component
paste this component at the place you want the message is displayed, you can style inline it for margin or padding. The id props for specific 
the component when you have more than 1 component in the same page.

use dispatch to show the message like the example below
dispatch(
  pushMessage({
    message: 'The message you want to show',
    description: 'If you want your message have 2 lines',
    type: MESSAGE_TYPES.SUCCESS,
    timeShow: 3000, //time ms before the message auto close, default is 5000, 0 mean do not auto close
    isScroll: true, //make your page scroll to the message auto, default is false
    containerId: //only show message for matched id with the container GlobalAlertMessage component. Use when you have conflict message
  })
); 
*/

const GlobalAlertMessage = (props) => {
  const { style, id, ...otherProps } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const messageRef = useRef(null);
  const alertRef = useRef(null);
  const alertMessages = useSelector((state) => state.alertMessage);
  const icons = {
    error: ErrorIcon,
    success: Successfully,
    normal: WarningBlueIcon,
    warning: warning_icon,
  };

  const handleClose = (index) => {
    dispatch(removeMessage(index));
  };

  useEffect(() => {
    if (isEmpty(alertMessages)) return;
    const alertDoms = alertRef.current.querySelectorAll('.ant-alert');

    const isScroll = alertMessages.filter((item) => item.isScroll);
    if (!isEmpty(isScroll)) {
      const position = messageRef.current.getBoundingClientRect();
      document.querySelector('body').scrollTo({
        top: position.top,
        left: position.left,
        behavior: 'smooth',
      });
    }

    alertMessages.forEach((item, index) => {
      const closeBtn = alertDoms[index]?.querySelector('.ant-alert-close-icon');
      if (item.timeShow !== 0) {
        setTimeout(() => {
          closeBtn?.click && closeBtn.click();
          if (otherProps?.setIsShowFirstTime) {
            otherProps.setIsShowFirstTime(false);
          }
        }, item.timeShow || 5000);
      }
    });
  }, [alertMessages]);

  //handle reset message when change URL and remove message by container id when unmount
  useEffect(() => {
    if (otherProps?.isShowFirstTime) return;

    if (!id) {
      dispatch(resetGlobalMessage());
      return;
    }
    dispatch(removeMessageByContainer(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div ref={messageRef}>
      {!isEmpty(alertMessages) && (
        <div ref={alertRef} style={style} className={styles.wrapper}>
          {alertMessages.map((item, index) => (
            <React.Fragment key={item.id}>
              {((!item.containerId && !id) || item.containerId === id) && (
                <AiAlert
                  setShow={true}
                  message={item.message}
                  description={item.description}
                  type={item.type}
                  showIcon
                  icon={<AiIconAlert src={icons[item.type]} />}
                  closable
                  afterClose={() => handleClose(index)}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default GlobalAlertMessage;
