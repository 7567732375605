import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { BasicButton, BasicSelect } from '../../../../assets/common';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { isCheckError } from '../../../../hooks/useFormik';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { AiInput, AiLabel } from '../../../Admin/components/NewSuccessionPlanningForm/Styled';
import { ACCESS_FIELD, REPORT_BUILDER_MESSAGE, REPORT_COLUMN_TYPE } from '../../constants';
import AccessControlField from '../AccessControlFields/AccessControlFields';
import styles from './add-manual-column.module.scss';

const AddEditManualColumn = ({
  open,
  setOpen,
  setNewColumn,
  currentColumns,
  setCurrentColumns,
  manualColumnEdit,
  confirmEditManualColumn,
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);

  const formValidationSchema = yup.object().shape({
    column_name: yup.string().required(true),
    column_type: yup.string().required(true),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      column_name: '',
      column_type: null,
      ...ACCESS_FIELD.reduce((acc, field) => ({ ...acc, [field.name]: [] }), {}),
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  const listColumnName = useMemo(() => {
    return (!isEmpty(currentColumns) && currentColumns.map((item) => item.name)) || [];
  }, [currentColumns]);

  useEffect(() => {
    if (!isEmpty(manualColumnEdit)) {
      formik.setValues({
        column_name: manualColumnEdit.column_name,
        column_type: manualColumnEdit.column_type,
        [ACCESS_FIELD[0].name]: manualColumnEdit.role_ids ?? [],
        [ACCESS_FIELD[1].name]: manualColumnEdit.businesses ?? [],
        [ACCESS_FIELD[2].name]: manualColumnEdit.hr_partners ?? [],
      });
    } else {
      handleResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualColumnEdit]);

  const handleSave = async () => {
    formik.submitForm();
    const values = formik.values;
    if (isEmpty(values.column_name) || isEmpty(values.column_type)) {
      return;
    }

    if (isEmpty(manualColumnEdit)) {
      if (listColumnName.includes(values.column_name)) {
        return setErrorMessage(REPORT_BUILDER_MESSAGE.DUPLICATE_COLUMN_NAME);
      }

      const tempData = {
        name: values.column_name,
        type: values.column_type,
        role_ids: values[ACCESS_FIELD[0].name] ?? [],
        businesses: values[ACCESS_FIELD[1].name] ?? [],
        hr_partners: values[ACCESS_FIELD[2].name] ?? [],
      };
      handleCancel();
      setNewColumn({
        ...tempData,
        value: '',
        id: null,
      });
      setCurrentColumns((prevState) => {
        return [...prevState, tempData];
      });
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: REPORT_BUILDER_MESSAGE.ADD_COLUMN_SUCCESS,
          isScroll: true,
        })
      );
    } else {
      handleCancel();
      await confirmEditManualColumn({ ...manualColumnEdit, ...values });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    handleResetForm();
  };

  const handleResetForm = () => {
    formik.resetForm();
    setErrorMessage(null);
  };

  useEffect(() => {
    if (!open) {
      handleResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const showError = (name) => {
    if (!formik.errors[`${name}`] && !formik.touched[`${name}`]) return null;
    if (formik.errors[`${name}`] && formik.touched[`${name}`]) return true;
  };

  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      width={866}
      destroyOnClose={false}
      hideDefaultBtn={true}
      styles={{
        containerPaddingTopBottom: 24,
        containerPaddingLeftRight: 24,
        closePosition: 40,
      }}
    >
      <div className={styles.modalContent}>
        <h3 className={styles.title}>{!manualColumnEdit ? `Add Column` : `Edit Column`}</h3>
        <div className={styles.formWrapper}>
          <div className={`${styles.formGroup} gap-10`}>
            <div className={styles.formField}>
              <AiLabel>Column Name</AiLabel>
              <AiInput
                type="input"
                name="column_name"
                placeholder={'Placeholder'}
                className={styles.formInput}
                value={formik.values.column_name}
                onChange={(e) => {
                  setErrorMessage(null);
                  formik.setFieldValue('column_name', e.target.value);
                }}
                maxLength="20"
                style={
                  errorMessage || isCheckError(formik, 'column_name')
                    ? { borderColor: '#F36568' }
                    : {}
                }
              />
              {errorMessage && !showError('column_name') && (
                <div className={styles.errorMsg}>{errorMessage}</div>
              )}
            </div>
            <div className={styles.formField}>
              <AiLabel>Column Type</AiLabel>
              <BasicSelect
                showArrow
                options={REPORT_COLUMN_TYPE}
                getPopupContainer={(trigger) => trigger.parentElement}
                name="column_type"
                placeholder={'Please Select Column Type'}
                value={formik.values.column_type}
                onChange={(value) => formik.setFieldValue('column_type', value)}
                optionFilterProp="label"
                style={isCheckError(formik, 'column_type') ? { borderColor: '#F36568' } : {}}
                disabled={!isEmpty(manualColumnEdit)}
              />
            </div>
          </div>
          <AccessControlField
            formikSetFieldValue={formik.setFieldValue}
            formikFieldValues={formik.values}
          />
        </div>
        <div className={styles.groupButton}>
          <BasicButton style={{ marginRight: '10px' }} onClick={handleCancel}>
            Cancel
          </BasicButton>
          <BasicButton
            mode="teal"
            onClick={handleSave}
            type="submit"
            disabled={isEmpty(formik.values.column_name) || isEmpty(formik.values.column_type)}
          >
            {!manualColumnEdit ? `Add` : `Save Changes`}
          </BasicButton>
        </div>
      </div>
    </CustomAntModal>
  );
};

AddEditManualColumn.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setNewColumn: PropTypes.func,
  currentColumns: PropTypes.array,
  setCurrentColumns: PropTypes.func,
  manualColumnEdit: PropTypes.object,
  confirmEditManualColumn: PropTypes.func,
};

export default AddEditManualColumn;
