import React, { useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { BasicTable } from '../../../../assets/common';
import { report_builder_not_found } from '../../../../assets/img';
import More3DotDropdown from '../../../../components/More3DotDropdown/More3DotDropdown';
import { paginationConfig } from '../../../../utils/helper';
import { REPORT_BUILDER_ROW_LIMIT } from '../../constants';
import * as styles from './report-builder-table.module.scss';

const ReportBuilderTable = ({
  searchParams,
  setSearchParams,
  data,
  setShowDeleteConfirm,
  setDeleteParams,
  isFirstRender,
}) => {
  const history = useHistory();
  const columns = useMemo(() => {
    const handleClickDelete = (record) => {
      setShowDeleteConfirm(true);
      setDeleteParams((prevState) => ({ ...prevState, deleteRecord: record }));
    };

    const handleClickEdit = (record) => {
      history.push(`/report-builder/edit/${record.id}`);
    };

    return [
      {
        title: 'No.',
        dataIndex: 'rowNum',
        className: styles.noText,
      },
      {
        title: 'Report Name',
        dataIndex: 'report_name',
        width: '20%',
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        width: '30%',
      },
      {
        title: 'Access',
        dataIndex: 'access',
        width: '15%',
      },
      {
        title: 'Update Date & Time',
        dataIndex: 'updated_at',
        width: '20%',
        render: (value) => (
          <div className={styles.dateText}>
            {value ? moment(value).format('DD/MM/YYYY h:mmA') : '-'}
          </div>
        ),
      },
      {
        title: '',
        width: '5%',
        align: 'right',
        render: (_, record) => (
          <div className="mr-4">
            <More3DotDropdown
              item={record}
              handleClickEdit={handleClickEdit}
              handleClickDelete={handleClickDelete}
            />
          </div>
        ),
      },
    ];
  }, [history, setDeleteParams, setShowDeleteConfirm]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(data.total, searchParams.page, onChange, REPORT_BUILDER_ROW_LIMIT);
  }, [data, searchParams.page, setSearchParams]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setDeleteParams({
        selectedReports: selectedRows,
        deleteRecord: null,
      });
    },
  };

  return (
    <div className={styles.wrapper}>
      {isFirstRender && !data.total ? (
        <img className={styles.notFound} src={report_builder_not_found} alt="Not found" />
      ) : (
        <div className={styles.table}>
          <h3>List of Report</h3>
          <BasicTable
            rowKey="id"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data.reports}
            pagination={pagination}
          />
        </div>
      )}
    </div>
  );
};

export default ReportBuilderTable;
