import './box.scss';

import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { agree_to_drop, chevron_down, plus_collapse } from '../../../../../assets/img';
import { Droppable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Droppable';
import PositionCard from '../PositionCard/PositionCard';
import SearchPosition from '../SearchPosition/SearchPosition';
import styles from './sp-readiness.module.scss';

const SpReadinessNew = (props) => {
  const {
    addTalent,
    title,
    backgroundcolor,
    setAddTalent,
    data,
    handleShowMessage,
    readOnly,
    staffTalentReview,
    isPrinting,
    setOpenConfirm,
    handleDragEnd,
    viewPopupSuccession,
  } = props;

  const [isShowSearchPopup, setIsShowSearchPopup] = useState(false);
  const [open, setOpen] = useState(true);

  const removepositioncard = (position_code, title) => {
    setAddTalent((prev) => {
      return {
        ...prev,
        succession_planning: {
          first_line:
            title === '1st Line'
              ? prev.succession_planning.first_line.filter(
                  (item) => item.position_code !== position_code
                )
              : prev.succession_planning.first_line,
          second_line:
            title === '2nd Line'
              ? prev.succession_planning.second_line.filter(
                  (item) => item.position_code !== position_code
                )
              : prev.succession_planning.second_line,
          third_line:
            title === '3rd Line'
              ? prev.succession_planning.third_line.filter(
                  (item) => item.position_code !== position_code
                )
              : prev.succession_planning.third_line,
          agreed_to_drop: prev.succession_planning.agreed_to_drop,
        },
      };
    });
  };

  const isAgreeToDrop = title === 'Agreed to Drop' ? true : false;

  return (
    <div style={{ borderRadius: '6px', marginBottom: '15px', overflow: 'hidden' }}>
      <div
        className={styles.collapseHeader}
        style={{ backgroundColor: backgroundcolor }}
        data-testid="show-hide"
      >
        <div className={styles.left}>{title}</div>
        {readOnly === false && !isAgreeToDrop && (
          <div data-testid="show-add-position" className={styles.right}>
            <img
              src={plus_collapse}
              alt="plus_collapse"
              style={{ pointerEvents: 'cursor' }}
              onKeyDown={() => {}}
              onClick={() => setIsShowSearchPopup(true)}
            />
            <img
              src={chevron_down}
              alt="chevron_down"
              style={{ pointerEvents: 'cursor', marginLeft: '16px' }}
              onKeyDown={() => {}}
              onClick={() => setOpen(!open)}
              className={open ? styles.animate : ``}
            />
          </div>
        )}
      </div>
      <Droppable
        className={`${open ? styles.open : styles.close} ${
          isPrinting ? styles.bodyWithoutScroll : styles.bodyWithScroll
        }`}
        id={title}
        key={title}
        showborder={`true`}
      >
        {!isEmpty(data) &&
          data.map((item, index) => (
            <PositionCard
              item={{ ...item, line: item.line || title }}
              key={index}
              index={index}
              backgroundcolor={item.color ? item.color : backgroundcolor}
              removepositioncard={removepositioncard}
              title={title}
              readOnly={readOnly}
              isAgreeToDrop={isAgreeToDrop}
              setOpenConfirm={setOpenConfirm}
              handleDragEnd={handleDragEnd}
              isPrinting={isPrinting}
              viewPopupSuccession={viewPopupSuccession}
            />
          ))}

        {isEmpty(data) && readOnly === false && (
          <div className={styles.dragDropBox}>
            <img src={isAgreeToDrop ? agree_to_drop : ''} alt="" />
            <div>
              {!isAgreeToDrop
                ? 'Drag and drop the card here to Add Talent'
                : 'Drag and drop the card here to Agreed to Drop Position'}
            </div>
          </div>
        )}
      </Droppable>
      {isShowSearchPopup && (
        <SearchPosition
          isShowSearchPopup={isShowSearchPopup}
          setIsShowSearchPopup={setIsShowSearchPopup}
          setAddTalent={setAddTalent}
          title={title}
          addTalent={addTalent}
          handleShowMessage={handleShowMessage}
          staffTalentReview={staffTalentReview}
        />
      )}
    </div>
  );
};

export default SpReadinessNew;
