import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BasicLabel, BasicSelect } from '../../../../assets/common';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import { ACCESS_FIELD } from '../../constants';

const AccessControlField = ({ formikSetFieldValue, formikFieldValues, marginTop = 24 }) => {
  const [accessOptions, setAccessOptions] = useState(
    ACCESS_FIELD.reduce((acc, field) => ({ ...acc, [field.name]: [] }), {})
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFilterPermission = async () => {
      try {
        setLoading(true);
        const res = await reportBuilderApi.getColumnPermission();
        const data = res.data.result;
        if (res.status !== 200 || !data) return;
        const formatData = (items) =>
          !isEmpty(items)
            ? items
                .filter((item) => item.value !== null)
                .map((item) => ({ value: Number(item.id), label: item?.fullname || item?.name }))
            : [];
        setAccessOptions({
          roles: formatData(data?.roles),
          businesses: formatData(data?.businesses),
          hr_partners: formatData(data?.hr_partners),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchFilterPermission();
  }, []);

  return (
    <>
      {ACCESS_FIELD.map((field) => (
        <React.Fragment key={field.name}>
          <BasicLabel htmlFor={field.name} color="#525252" margin_top={marginTop}>
            {field.label}
          </BasicLabel>
          <BasicSelect
            id={field.name}
            name={field.name}
            mode="multiple"
            showArrow
            checkbox="true"
            virtual={false}
            placeholder={`Please Select ${field.label}`}
            height={44}
            optionFilterProp="label"
            optionLabelProp="label"
            loading={loading}
            getPopupContainer={(trigger) => trigger}
            value={formikFieldValues[field.name]}
            onChange={(value) => formikSetFieldValue(field.name, value)}
          >
            {accessOptions[field.name].map((option) => (
              <BasicSelect.Option key={option.value} value={option.value} label={option.label}>
                {option.label}
              </BasicSelect.Option>
            ))}
          </BasicSelect>
        </React.Fragment>
      ))}
    </>
  );
};

AccessControlField.propTypes = {
  formikSetFieldValue: PropTypes.func,
  formikFieldValues: PropTypes.object,
  marginTop: PropTypes.number,
};

export default AccessControlField;
