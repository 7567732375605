import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScrollComponent from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { avatar, external_talent, lookup } from '../../assets/img';
import useApi from '../../services/userApi';
import { PERMISSION } from '../../utils/constants';
import { decodeBase64ToImage } from '../../utils/helper';
import styles from './search-box.module.scss';

const styleInfiniteScroll = {
  borderRadius: '6px',
  borderBottom: '1px solid #e9edf0',
  boxShadow: ' inset 0px 2px 3px rgba(0, 0, 0, 0.04)',
  position: 'absolute',
  width: '100%',
  top: '40px',
  paddingBottom: 0,
};

const Loading = () => {
  return (
    <div className={styles.search_loading} data-testid="loading">
      <div className={styles.dot_flasing}></div>
    </div>
  );
};

const SearchBox = ({ placeholder, isTalentComparison }) => {
  const { roleActive } = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const typingTimeoutRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const limit = 4;
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    search: '',
  });

  function expand() {
    setExpanded(true);
  }

  function close() {
    setExpanded(false);
  }

  const handleOnchange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setLoading(true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setSearchList([]);
      setQuery({
        page: 1,
        total: 0,
        search: value,
      });
    }, 500);
    value ? expand() : close();
  };

  const handleOnClick = (item) => {
    close();
    history.push(`/profile/${item.staffId}`);
  };

  const handleKeyDown = (event) => {
    if (isTalentComparison) return;
    const value = event.target.value;
    if (event.key === 'Enter' && value) {
      document.getElementById('search').blur();
      close();
      history.push(`/search-talent-profile/${value}/1/16`);
    }
  };

  const fetchSearchListApi = async () => {
    if (query.page === 1) {
      setLoading(true);
    } else {
      setIsMoreLoading(true);
    }
    try {
      const permission = {
        permission: PERMISSION.SEARCH_TALENT_PROFILE,
        role: roleActive?.roleId,
      };
      const body = { search: query.search, limit: limit, page: query.page };
      const response = await useApi.getSearchList(permission, body);
      if (response.status === 200 && response?.data?.result?.profiles) {
        const tempData = response.data.result.profiles || [];
        setSearchList((prev) => {
          return !isEmpty(prev) ? [...prev, ...tempData] : tempData;
        });
        setQuery((prev) => ({ ...prev, total: response?.data?.result?.totalProfiles || 0 }));
      }
    } catch (error) {
      setQuery((prev) => ({ ...prev, total: 0 }));
      console.error(error);
    } finally {
      setLoading(false);
      setIsMoreLoading(false);
    }
  };

  useEffect(() => {
    if (query.search) {
      fetchSearchListApi();
    } else {
      setSearchList([]);
    }
  }, [query.search, query.page]);

  const fetchMoreData = () => {
    setQuery((prev) => ({ ...prev, page: prev.page + 1 }));

    if (searchList.length >= query.total) {
      setHasMore(false);
    }
  };

  return (
    <div
      className={styles.search_box}
      tabIndex={0}
      onBlur={close}
      onFocus={searchTerm ? expand : close}
      data-testid="search-box"
    >
      <input
        type="search"
        id="search"
        data-testid="search-input"
        name="search"
        aria-label="cost-input"
        placeholder={placeholder}
        className={isTalentComparison ? styles.search_talent : styles.search_input}
        onChange={(e) => handleOnchange(e)}
        value={searchTerm}
        autoComplete="off"
        onKeyDown={handleKeyDown}
      />
      {!isTalentComparison && <img src={lookup} alt="lookup" className={styles.lookup} />}
      {expanded && (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              {!isEmpty(searchList) ? (
                <InfiniteScrollComponent
                  dataLength={searchList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  style={{ ...styleInfiniteScroll, height: query.total > 3 ? '280px' : 'auto' }}
                  height={280}
                >
                  <ul className={styles.search_list} data-testid="data-is-true">
                    {searchList.map((item) => (
                      <li
                        className={styles.search_item}
                        key={item.staffId}
                        onMouseDown={() => handleOnClick(item)}
                        data-testid="list-click"
                      >
                        <div className={styles.avatar}>
                          <img
                            src={item?.image ? decodeBase64ToImage(item.image) : avatar}
                            alt="avatar"
                          />
                        </div>
                        <div className={styles.invidual}>
                          <div data-testid="name" className={styles.name}>
                            {item.birthName}
                          </div>
                          <div data-testid="opu" className={styles.name1}>
                            {item.opu}
                          </div>
                          <div data-testid="sg" className={styles.name1}>
                            {item.sg}{' '}
                            {item?.external_talent && (
                              <img src={external_talent} alt="" style={{ marginTop: '-2px' }} />
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                    {isMoreLoading && (
                      <li className={styles.search_item}>
                        <div className={styles.dot_flasing}></div>
                      </li>
                    )}
                  </ul>
                </InfiniteScrollComponent>
              ) : (
                <div className={styles.search_not_found} data-testid="data-is-false">
                  Search not found ...
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchBox;
