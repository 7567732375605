import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { DATE_TIME_FORMAT, MEETING_DETAIL_STATUS } from '../../utils/constants';
import styles from './task.module.scss';

const MEETING_REVIEW_STATUS = ['pending'];
const IS_FACILITATOR = 1;

const Task = ({ tasks, viewTask }) => {
  const styleColorProcess = (step) => {
    switch (step) {
      case 1:
        return '#8B5CA7 4%, #26AFAB 22%';
      case 2:
        return '#8B5CA7 32%, #26AFAB 85%';
      default:
        return '#8B5CA7 205%, #26AFAB 205%';
    }
  };

  return (
    <div className={styles.container}>
      {!isEmpty(tasks) &&
        tasks.map((d, i) => (
          <div key={i}>
            {d.status === MEETING_DETAIL_STATUS.IN_PLANNING && (
              <div data-testid="in-planning">
                <div
                  onKeyDown={() => {}}
                  onClick={() => viewTask(d)}
                  className={styles.task_detail}
                >
                  <div
                    className={styles.task_process}
                    style={{
                      background: `linear-gradient(90deg, ${styleColorProcess(d.on_step)})`,
                    }}
                  />
                  <div className={styles.step}>Step {d.on_step} of 3</div>
                  <div className={styles.task_type}>Meeting {d.status}</div>
                  <div className={styles.title}>{d.name}</div>
                  <div className={styles.description}>
                    {`${moment(d.start_at).format(DATE_TIME_FORMAT.DATE_SPACE)}, ${moment(
                      d.start_at
                    ).format(DATE_TIME_FORMAT.TIME)} -
                  ${moment(d.end_at).format(DATE_TIME_FORMAT.TIME)}`}
                  </div>
                </div>
              </div>
            )}
            {d.status === MEETING_DETAIL_STATUS.IN_REVIEW &&
              !MEETING_REVIEW_STATUS.includes(d.concluded_status) && (
                <div data-testid="in-review">
                  <div
                    onKeyDown={() => {}}
                    onClick={() => viewTask(d)}
                    className={styles.task_detail}
                  >
                    <div
                      className={styles.task_process}
                      style={{
                        background: `linear-gradient(90deg, ${styleColorProcess(d.on_step)})`,
                      }}
                    />
                    <div className={styles.task_type}>MoM In-Planning</div>
                    <div className={styles.title}>{d.name}</div>
                    <div className={styles.description}>
                      {`${moment(d.start_at).format(DATE_TIME_FORMAT.DATE_SPACE)}, ${moment(
                        d.start_at
                      ).format(DATE_TIME_FORMAT.TIME)} -
                  ${moment(d.end_at).format(DATE_TIME_FORMAT.TIME)}`}
                    </div>
                  </div>
                </div>
              )}
            {d.status === MEETING_DETAIL_STATUS.IN_REVIEW &&
              MEETING_REVIEW_STATUS.includes(d.concluded_status) &&
              d.is_facilitator === IS_FACILITATOR && (
                <div data-testid="facilitator">
                  <div
                    onKeyDown={() => {}}
                    onClick={() => viewTask(d)}
                    className={styles.task_detail}
                  >
                    <div
                      className={styles.task_process}
                      style={{
                        background: `linear-gradient(90deg, ${styleColorProcess(d.on_step)})`,
                      }}
                    />
                    <div className={styles.task_type}>Meeting Pending Conclude</div>
                    <div className={styles.title}>{d.name}</div>
                    <div className={styles.description}>
                      {`${moment(d.start_at).format(DATE_TIME_FORMAT.DATE_SPACE)}, ${moment(
                        d.start_at
                      ).format(DATE_TIME_FORMAT.TIME)} -
                  ${moment(d.end_at).format(DATE_TIME_FORMAT.TIME)}`}
                    </div>
                  </div>
                </div>
              )}
          </div>
        ))}
    </div>
  );
};

export default withRouter(Task);
