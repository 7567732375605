/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Spin, Tooltip } from 'antd';
import { debounce, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { AiButton, BasicButton } from '../../../../assets/common';
import { dashboard_breadcrumb } from '../../../../assets/img';
import { BreadCrumb } from '../../../../components';
import { dashboardApi } from '../../../../services/dashboardApi';
import ExportSuccesionPlanningDetail from '../components/ExportHistorical/ExportSuccesionPlanningDetail';
import DataTable from './DataTable/DataTable';
import styles from './succession-planning-dashboard.module.scss';
import queryString from 'query-string';
import { commonTooltipStyles } from '../../../../assets/common/tooltipStyles';

export default function SuccessionPlanningDashboard() {
  const params = useParams();
  const { search } = useLocation();
  const filter = queryString.parse(search);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoadingList, setLoadingList] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [query, setQuery] = useState({
    page: 1,
    totalPage: 0,
    limit: 15,
    keyword: '',
  });
  const [searchInput, setSearchInput] = useState('');
  const [breadCrumbList, setBreadCrumbList] = useState([
    { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
    { name: 'Succession Planning Overview' },
  ]);
  const [exportData, setExportData] = useState(false);
  const title =
    params.type_position === 'CD'
      ? 'Number of SP Conducted'
      : `${params.type} - ${
          params.type_position === 'PD'
            ? 'Position Deliberated'
            : params.type_position === 'KP'
            ? 'Critical Position'
            : 'Conducted'
        } of ${params.role}`;

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        setQuery((prev) => {
          return {
            ...prev,
            keyword: val,
            page: 1,
          };
        });
      }, 1200),
    [setQuery]
  );
  useEffect(() => {
    handleSearch(searchInput);
  }, [searchInput]);
  const fetchData = async () => {
    setLoadingList(true);
    try {
      const queryApi = {
        type: params.type,
        year: params.year,
        month: moment().month(params.month).format('M'),
        type_position: params.type_position,
        keyword: query.keyword,
        ...filter,
      };

      if (['KP', 'PD'].includes(params.type_position)) queryApi.role = params.role;
      const res = await dashboardApi.getHistoricalDashboardDetail(queryApi, roleActive);

      const data = get(res, 'data.result') || [];
      setQuery((prev) => {
        return {
          ...prev,
          totalPage: data.length,
        };
      });
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setLoadingList(false);
  };

  useEffect(() => {
    if (Object.values(params).every((k) => k)) fetchData();
  }, [query.keyword]);

  useEffect(() => {
    if (params.type_position === 'CD') {
      setBreadCrumbList([
        { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
        { name: `Historical Dashboard` },
      ]);
    }
    if (['KP', 'PD'].includes(params.type_position)) {
      setBreadCrumbList([
        { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
        { name: `Succession Planning Overview` },
        {
          name: `${params.type} - ${
            params.type_position === 'PD'
              ? 'Position Deliberated'
              : params.type_position === 'KP'
              ? 'Critical Position'
              : 'Conducted'
          } of ${params.role}`,
        },
      ]);
    }
  }, [params.type_position]);

  return (
    <>
      <div className={styles.control}>
        <BreadCrumb level={5} breadCrumbList={breadCrumbList} />
        <h3 className={styles.title}>{title}</h3>
      </div>
      <Spin spinning={isLoadingList} size="large">
        <Row align="bottom" justify="space-between">
          <Col span={10}>
            <input
              data-testid="search-input"
              placeholder="Search by Position Name or Position Code"
              className={styles.searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchInput)}
            />
          </Col>
          <Col>
            <BasicButton
              mode="border-teal"
              onClick={() =>
                history.push({
                  pathname: '/dashboard',
                  state: { isShowHistorical: true },
                })
              }
              style={{ marginRight: '20px' }}
            >
              Back
            </BasicButton>
            {!data || data.length === 0 ? (
              <Tooltip title="No record to export" {...commonTooltipStyles}>
                <AiButton
                  mode="teal"
                  onClick={() => setExportData(true)}
                  disabled={!data || data.length === 0}
                >
                  Export
                </AiButton>
              </Tooltip>
            ) : (
              <AiButton
                mode="teal"
                onClick={() => setExportData(true)}
                disabled={!data || data.length === 0}
              >
                Export
              </AiButton>
            )}
          </Col>
        </Row>
        <DataTable data={data} query={query} setQuery={setQuery} />
        {exportData && (
          <ExportSuccesionPlanningDetail
            setExportData={setExportData}
            data={data}
            params={params}
          />
        )}
      </Spin>
    </>
  );
}
