import { size } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import shage from '../../assets/img/Shape.svg';
import { fetchCompanyFilterType, fetchListCompanyByTpe } from '../../services/filterDashboard';
import { addParamsToGetSuccessionPlanning } from '../../services/successionplanningratio';
import {
  BU_ACCESS_ALL,
  BU_ACCESS_OPU_DIV,
  BU_ACCESS_PETRONAS_AND_FAMILY,
  BU_ACCESS_PETRONAS_AND_OWN_BU,
  BUSINESS,
  DIVISION,
  OPU,
  PESTRONAS,
} from '../../utils/constants';
import styles from './dashboard-filter.module.scss';

const DashboardFilter = ({
  selectedBU,
  setSelectedBU,
  selectedCompany,
  setSelectedCompany,
  setLoading,
  loading,
}) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const [isActive, setIsActive] = useState(false);
  const [isActiveCompanyFilter, setIsActiveCompanyFilter] = useState(false);
  const [isActiveBUFilter, setIsActiveBUFilter] = useState(false);
  const ref = useRef();
  const [companyFilterType, setCompanyFilterType] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [loadingGetCompany, setLoadingGetCompany] = useState(false);
  const [loadingGetBu, setLoadingGetBu] = useState(false);

  const getCompanyFilterType = async () => {
    const response = await fetchCompanyFilterType({ role: roleActive.roleId });
    setLoadingGetCompany(true);
    setCompanyFilterType([]);
    if (response.data.code === 200) {
      let { buAccess } = response.data.result;
      setLoadingGetCompany(false);
      let result = await handleCompanyFilterType(buAccess);
      setCompanyFilterType(result);
    }
  };

  useEffect(() => {
    if (size(companyFilterType) > 0) {
      setSelectedCompany(companyFilterType[0]);
    }
  }, [companyFilterType, setSelectedCompany]);
  /*
   * Function: Check BU Access of Role and list option
   * Goal : based on the Bu access of role and list options
   * Input: id: number, role: object
   * Return array of object
   */
  const handleCompanyFilterType = async (data) => {
    // = ["PESTRONAS", "Business", "OPU", "Division"];
    let companyFilterOption;
    switch (data) {
      case BU_ACCESS_PETRONAS_AND_FAMILY:
      case BU_ACCESS_ALL:
        companyFilterOption = [
          { label: PESTRONAS, value: 'all' },
          { label: BUSINESS, value: 'business' },
          { label: OPU, value: 'opu' },
          { label: DIVISION, value: 'division' },
        ];
        break;
      case BU_ACCESS_PETRONAS_AND_OWN_BU:
        companyFilterOption = [
          { label: BUSINESS, value: 'business' },
          { label: OPU, value: 'opu' },
          { label: DIVISION, value: 'division' },
        ];
        break;
      case BU_ACCESS_OPU_DIV:
        companyFilterOption = [
          { label: OPU, value: 'opu' },
          { label: DIVISION, value: 'division' },
        ];
        break;
      default:
        break;
    }
    return companyFilterOption;
  };
  const getListCompanyByType = async () => {
    let selectType;
    let jobFamily = [];
    addParamsToGetSuccessionPlanning({ companyFilterType: selectedCompany });
    switch (selectedCompany.label) {
      case BUSINESS:
        selectType = 'business';
        break;
      case OPU:
        selectType = 'opu';
        break;
      case DIVISION:
        selectType = 'division';
        break;
      default:
        break;
    }
    setSelectedBU({});
    setLoadingGetBu(true);
    try {
      const response = await fetchListCompanyByTpe({
        type: selectType,
        role: roleActive.roleId,
      });
      if (response.data.code === 200) {
        let resCombine = [...response.data.result, ...jobFamily];
        let result = await handleListCompanyByType(resCombine);
        setLoadingGetBu(false);
        setBusinessUnit(result);
      }
    } catch (error) {
      setLoadingGetBu(false);
    }
  };

  const handleListCompanyByType = async (data) => {
    return data.map((item) => {
      return {
        value: item.id,
        label:
          (selectedCompany.label === BUSINESS && item.businessUnit) ||
          (selectedCompany.label === OPU && item.opu) ||
          (selectedCompany.label === DIVISION && item.division + '-' + item.type),
        type: item.type,
      };
    });
  };

  useEffect(() => {
    if (roleActive.roleId) {
      getCompanyFilterType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive.roleId]);

  useEffect(() => {
    if (selectedCompany.value && roleActive.roleId) {
      getListCompanyByType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany.value, roleActive.roleId]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
        setIsActiveCompanyFilter(false);
        setIsActiveBUFilter(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  const handleSelectedBU = (option) => {
    setSelectedBU(option);
    addParamsToGetSuccessionPlanning({
      companyFilterType: selectedCompany,
      companyFilterValue: option,
    });
  };

  const onChangeCompanyFilter = () => {
    if (!loading) {
      setIsActiveBUFilter(false);
      setIsActiveCompanyFilter(!isActiveCompanyFilter);
    }
  };

  const onChangeBuOption = (option) => {
    if (!loading) {
      setSelectedCompany(option);
      setIsActiveCompanyFilter(false);
    }
  };

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={`${styles.dropdownBtn} ${styles.dropdownBtnLg}`}
        style={{ cursor: 'pointer' }}
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
      >
        Dashboard Filter
        <img src={shage} alt="" />
      </div>

      {isActive && (
        <div className={`${styles.dropdownContent} `}>
          <div className={`${styles.dropdownItem} ${styles.dropdownItemflex}`}>
            <div className={styles.dropdownSubItemOption}>
              <div className={styles.dropdownContentItemSub}>Company filter</div>
              <div
                className={`${styles.dropdownSub} ${
                  !selectedCompany && styles.dropdownSubBeforeSelect
                } ${loading ? styles.dropdownSubDisabled : ''}`}
                onKeyDown={() => {}}
                onClick={() => onChangeCompanyFilter()}
              >
                <div className={styles.dropdownSubbtn}>
                  {selectedCompany.label ? selectedCompany.label : ' -Select-'}
                </div>
                <img src={shage} alt="" />
              </div>
            </div>
            <div
              className={`${styles.dropdownContentSub} ${
                loading ? styles.dropdownContentSubDisabled : ''
              }`}
            >
              {isActiveCompanyFilter &&
                companyFilterType &&
                companyFilterType.map((option, index) => {
                  return (
                    <div
                      className={styles.dropdownItemSub}
                      key={index}
                      onKeyDown={() => {}}
                      onClick={(e) => onChangeBuOption(option)}
                    >
                      {loadingGetCompany ? 'Loading...' : option.label}
                    </div>
                  );
                })}
            </div>
          </div>
          {selectedCompany.value && selectedCompany.label !== PESTRONAS && (
            <div className={`${styles.dropdownItem} ${styles.dropdownItemflex}`}>
              <div className={styles.dropdownSubItemOption}>
                <div className={styles.dropdownContentItemSub}>Business Unit</div>
                <div
                  className={`${styles.dropdownSub} ${
                    !selectedBU.label && styles.dropdownSubBeforeSelect
                  } ${loading ? styles.dropdownSubDisabled : ''}`}
                  onKeyDown={() => {}}
                  onClick={() => {
                    if (!loading) {
                      setIsActiveBUFilter(!isActiveBUFilter);
                      setIsActiveCompanyFilter(false);
                    }
                  }}
                >
                  <div className={styles.dropdownSubbtn}>
                    {selectedBU.label ? selectedBU.label : ' -Select-'}
                  </div>
                  <img src={shage} alt="" />
                </div>
              </div>
              <div
                className={`${styles.dropdownContentSub} ${
                  loading ? styles.dropdownContentSubDisabled : ''
                }`}
              >
                {loadingGetBu && <div className={styles.dropdownItemSub}>'Loading ...'</div>}
                {isActiveBUFilter &&
                  !loadingGetBu &&
                  businessUnit &&
                  businessUnit.map((option, index) => {
                    return (
                      <div
                        className={styles.dropdownItemSub}
                        key={index}
                        onKeyDown={() => {}}
                        onClick={() => {
                          if (!loading) {
                            handleSelectedBU(option);
                            setIsActiveBUFilter(false);
                          }
                        }}
                      >
                        {option.label}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default memo(DashboardFilter);
