import React, { useEffect, useMemo } from 'react';
import { icon_after } from '../../../../../assets/img';
import styles from './sp-management-pill.module.scss';

const types = {
  POSITION_LABEL: 'POSITION_LABEL',
  BUSINESS: 'BUSINESS',
  ACTIVE_SP: 'ACTIVE_SP',
};

export default function SPManagementPill(props) {
  const {
    showFilter,
    positionLabelSelected,
    setPositionLabelSelected,
    businessSelected,
    setBusinessSelected,
    activeSPSelected,
    setActiveSPSelected,
  } = props;

  const isSelectAllBusiness = useMemo(
    () => businessSelected.find((item) => item.value === 'all'),
    [businessSelected]
  );
  const isSelectAllPositionLabel = useMemo(
    () => positionLabelSelected.find((item) => item.value === 'all'),
    [positionLabelSelected]
  );

  const onRemove = (type, value) => {
    let remain;
    switch (type) {
      case types.ACTIVE_SP:
        remain = activeSPSelected.filter((item) => item.value !== value);
        setActiveSPSelected(remain);
        return;
      case types.BUSINESS:
        remain = businessSelected.filter((item) => item.value !== value);
        setBusinessSelected(remain);
        return;
      case types.POSITION_LABEL:
        remain = positionLabelSelected.filter((item) => item.value !== value);
        setPositionLabelSelected(remain);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    setPositionLabelSelected([]);
    setBusinessSelected([]);
    setActiveSPSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter]);

  return (
    <div
      className={styles.wrapper}
      style={{ marginTop: positionLabelSelected.length + businessSelected.length > 0 ? 22 : 0 }}
    >
      {isSelectAllBusiness ? (
        <span className={styles.pillItem}>
          <span>Business - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() => setBusinessSelected([])}
            alt="remove-all-position"
          />
        </span>
      ) : (
        businessSelected.map((item, idx) => {
          return (
            <span
              data-testid={`business-${idx}`}
              key={idx}
              className={styles.pillItem}
              onKeyDown={() => {}}
              onClick={() => onRemove(types.BUSINESS, item.value)}
            >
              <span>{item.label}</span>
              <img src={icon_after} alt="remove" />
            </span>
          );
        })
      )}
      {isSelectAllPositionLabel ? (
        <span className={styles.pillItem}>
          <span>Position Label - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() => setPositionLabelSelected([])}
            alt="remove-all-business"
          />
        </span>
      ) : (
        positionLabelSelected.map((item, idx) => {
          return (
            <span key={idx} className={styles.pillItem}>
              <span>{item.label}</span>
              <img
                data-testid={`position-${idx}`}
                src={icon_after}
                onKeyDown={() => {}}
                onClick={() => onRemove(types.POSITION_LABEL, item.value)}
                alt="remove"
              />
            </span>
          );
        })
      )}
      {activeSPSelected.map((item, idx) => {
        return (
          <span
            data-testid={`active-${idx}`}
            key={idx}
            className={styles.pillItem}
            onKeyDown={() => {}}
            onClick={() => onRemove(types.ACTIVE_SP, item.value)}
          >
            <span>{item.label}</span>
            <img src={icon_after} alt="remove" />
          </span>
        );
      })}
    </div>
  );
}
