import React, { useState } from 'react';
import { Container } from './styled';
import { sp_chevron_down } from '../../../../assets/img';

const monthCalendar = [
  { id: 0, value: 'Jan' },
  { id: 1, value: 'Feb' },
  { id: 2, value: 'Mar' },
  { id: 3, value: 'Apr' },
  { id: 4, value: 'May' },
  { id: 5, value: 'Jun' },
  { id: 6, value: 'Jul' },
  { id: 7, value: 'Aug' },
  { id: 8, value: 'Sep' },
  { id: 9, value: 'Oct' },
  { id: 10, value: 'Nov' },
  { id: 11, value: 'Dec' },
];
const MonthCalendar = (props) => {
  const { monthSelectedAntd, setIsShowMonthCalendar, setMonthSelectedAntd } = props;
  const [monthSelected, setMonthSelected] = useState(monthSelectedAntd.getMonth());
  const [yearSelected, setYearSelected] = useState(monthSelectedAntd.getFullYear());

  const handleApplyMonth = () => {
    const newDate = new Date();
    newDate.setFullYear(yearSelected);
    newDate.setMonth(monthSelected);
    setMonthSelectedAntd(newDate);
    setIsShowMonthCalendar(true);
  };
  return (
    <Container>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: '0 10px', marginBottom: '10px' }}
      >
        <div className="year-selected">{yearSelected}</div>
        <div>
          <img
            src={sp_chevron_down}
            alt=""
            style={{ pointerEvents: 'all' }}
            onKeyDown={() => {}}
            onClick={() => {
              setYearSelected(yearSelected - 1);
            }}
          />
          <img
            src={sp_chevron_down}
            style={{ transform: 'rotate(180deg)', marginLeft: '10px', pointerEvents: 'all' }}
            alt=""
            onKeyDown={() => {}}
            onClick={() => {
              setYearSelected(yearSelected + 1);
            }}
          />
        </div>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              {monthCalendar.map((item) => (
                <td
                  style={{ minWidth: '60px' }}
                  key={item.id}
                  className={item.id === monthSelected ? 'active' : null}
                  onKeyDown={() => {}}
                  onClick={() => setMonthSelected(item.id)}
                >
                  {item.value}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end align-items-center" style={{ padding: '0 10px' }}>
        <div
          className="button-month-calendar"
          onKeyDown={() => {}}
          onClick={() => {
            setIsShowMonthCalendar(true);
          }}
        >
          Cancel
        </div>
        <div
          className="button-month-calendar"
          style={{ marginLeft: '10px', backgroundColor: '#00A19C', color: 'white' }}
          onKeyDown={() => {}}
          onClick={handleApplyMonth}
        >
          Apply
        </div>
      </div>
    </Container>
  );
};

export default MonthCalendar;
