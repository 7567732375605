import { Spin } from 'antd';
import { get, isEmpty, union } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { avatar } from '../../../../../../assets/img';
import talentFinderApi from '../../../../../../services/talentFinderApi';
import { PERMISSION } from '../../../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../../../utils/helper';
import FilterFinderTalentBuilder from './FilterFinderTalentBuilder/FilterFinderTalentBuilder';
import FinderTalentData from './FinderTalentData/FinderTalentData';
import MenuBarFilterTalentBuilder from './MenuBarFilterTalentBuilder/MenuBarFilterTalentBuilder';

function convertToDisplay(talentFinderList) {
  if (isEmpty(talentFinderList)) return [];
  const data = [];
  talentFinderList.forEach((d, i) => {
    data.push({
      ...d,
      key: i + 1,
      avatar: d.image ? decodeBase64ToImage(d.image) : avatar,
      name: d.birth_name,
      staffId: d.staff_id,
      positionName: d.position_name,
      department: d.department_name,
      companyOpu: d.opu_name,
      businessUnit: d.business_unit,
      action: 'View Talent',
      isUnsearchable: d.isUnsearchable,
      isPermitted: d.isPermitted,
    });
  });
  return data;
}

const FinderOption = (props) => {
  const { setShowError, setLoading, showError, handleAddToReport } = props;
  const [keyword, setKeyword] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterFinderData, setFilterFinderData] = useState({});
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const limit = 15;
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const roleActive = useSelector((state) => state.user.roleActive);

  async function fetchApiListFilter(filter, page, limit, keyword) {
    try {
      setLoading(true);
      const res = await talentFinderApi.getList(
        { ...filter, page, limit, keyword, is_search_staff_id: true },
        roleActive,
        PERMISSION.REPORT_BUILDER
      );
      if (res.status === 200) {
        const talentFinderList = get(res, 'data.result.profiles', []);
        const totalProfiles = get(res, 'data.result.totalProfiles', 0);
        setTotalData(totalProfiles);
        setData(convertToDisplay(talentFinderList));
      }
    } catch (error) {
      console.error(error);
      setData([]);
      setTotalData(0);
    }
    setLoading(false);
  }

  useEffect(() => {
    setSelectedRows([]);
    if (!isEmpty(filterFinderData) || isSearch) {
      if (page === 1) {
        fetchApiListFilter(filterFinderData, page, limit, keyword);
        return;
      }
      setPage(1);
      return;
    }
    if ((isEmpty(filterFinderData) || isSearch) && keyword) {
      if (page === 1) {
        fetchApiListFilter(filterFinderData, page, limit, keyword);
        return;
      }
      setPage(1);
      return;
    }
    setData([]);
    setTotalData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, isSearch, filterFinderData]);

  useEffect(() => {
    if (!isEmpty(filterFinderData) || isSearch) {
      fetchApiListFilter(filterFinderData, page, limit, keyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, roleActive.roleId]);

  useEffect(() => {
    async function fetchApiOptions() {
      try {
        setLoadingFilter(true);
        const filterRes = await talentFinderApi.getTalentfilters();
        if (filterRes && filterRes.status === 200) {
          setFilterOptions(filterRes.data.result);
        }
      } catch (error) {
        console.error(error);
        setFilterOptions([]);
      }
      setLoadingFilter(false);
    }
    fetchApiOptions();
  }, []);

  useEffect(() => {
    if (showError === false) return;
    const timeout = setTimeout(() => {
      setShowError(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError]);

  async function addToReport() {
    const staffIds = union(selectedRows).join(',');
    await handleAddToReport(selectedRows, staffIds, roleActive);
  }

  return (
    <div>
      <MenuBarFilterTalentBuilder
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setKeyword={setKeyword}
        keyword={keyword}
        setShowError={setShowError}
        setIsSearch={setIsSearch}
        filterFinderData={filterFinderData}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
      />
      {showFilter && (
        <Spin spinning={loadingFilter} size="large">
          <FilterFinderTalentBuilder
            filterOptions={filterOptions}
            setFilterFinderData={setFilterFinderData}
            setIsFilter={setIsFilter}
          />
        </Spin>
      )}
      {!isEmpty(data) && (
        <>
          <div className="search-result">
            Search Result: <span className="search-keyword">{keyword}</span>
          </div>
          <FinderTalentData
            data={data}
            limit={limit}
            page={page}
            setPage={setPage}
            totalPage={totalData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          <div className="d-flex justify-content-end">
            <div className="button report-button" onKeyDown={() => {}} onClick={addToReport}>
              Add to Report
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FinderOption;
