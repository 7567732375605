import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { dashboard_breadcrumb } from '../../../assets/img';
import { BreadCrumb } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import ListOfProfiles from './ListOfProfiles/ListOfProfiles';
import UnsearchablePositions from './UnsearchablePositions/UnsearchablePositions';
import StaffSgJgManagement from './StaffSgJgManagement/StaffSgJgManagement';
import UnsearchableTalentList from './UnsearchableTalentList/UnsearchableTalentList';
import TopTalentStatus from './TopTalentStatus/TopTalentStatus';
import CreateOrEditTopTalentStatus from './TopTalentStatus/CreateOrEditTopTalentStatus/CreateOrEditTopTalentStatus';
import ExternalTalents from './ExternalTalents/ExternalTalents';
import { getCurrentTab } from '../../../utils/helper';
import ExternalTalentsForm from './ExternalTalents/ExternalTalentsForm/ExternalTalentsForm';
const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Admin' },
  { name: 'Profile Management' },
];

const tabs = [
  {
    key: '0',
    label: 'Unsearchable Talent List',
    path: 'unsearchable-talent-list',
    title: 'Profile Management',
    subTitle: 'List of Unsearchable Talent',
    tabAble: true,
  },
  {
    key: '1',
    label: 'Unsearchable Position List',
    path: 'unsearchable-position-list',
    title: 'Profile Management',
    subTitle: 'List of Unsearchable Position',
    tabAble: true,
  },
  {
    key: '2',
    label: 'Staff SG/JG Management',
    path: 'staff-sg-jg-management',
    title: 'Profile Management',
    subTitle: 'List of Staff SG/JG Management',
    tabAble: true,
  },
  {
    key: '3',
    label: 'List of Profiles',
    path: 'list-of-profiles',
    title: 'Profile Management',
    subTitle: 'List of Profiles',
    tabAble: true,
  },
  {
    key: '4',
    label: 'Top Talent Status',
    path: 'top-talent-status',
    title: 'Profile Management',
    subTitle: 'List of Top Talent Status',
    tabAble: true,
    subTab: [
      {
        key: '0',
        path: 'add-new-top-talent-status',
        title: 'Add Top Talent Status',
        subTitle: 'Profile Management',
        tabAble: false,
        permission: null,
      },
      {
        key: '1',
        path: 'edit-top-talent-status',
        title: 'Edit Top Talent Status',
        subTitle: 'Profile Management',
        tabAble: false,
        permission: null,
      },
    ],
  },
  {
    key: '5',
    label: 'External Talents',
    path: 'external-talents',
    title: 'Profile Management',
    subTitle: 'List of Staff SG/JG Management',
    tabAble: true,
    subTab: [
      {
        key: '0',
        path: 'add-external-talent',
        title: 'Add External Talents',
        tabAble: false,
        permission: null,
      },
      {
        key: '1',
        path: 'edit-external-talent',
        title: 'Edit External Talents',
        tabAble: false,
        permission: null,
      },
    ],
  },
];

export default function ProfileManagement() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState();

  const onChangeTab = (key) => {
    const tab = tabs.find((tab) => Number(tab.key) === Number(key));
    history.push(tab.path);
  };

  useEffect(() => {
    if (location.pathname) {
      const tab = getCurrentTab(tabs, location.pathname);
      setCurrentTab(tab);
      return;
    }
    setCurrentTab();
  }, [location.pathname]);

  return (
    <div className="max-width">
      <div>
        <BreadCrumb level={3} breadCrumbList={breadCrumbList} />
      </div>
      <div>
        <GlobalAlertMessage />
      </div>
      <div className="title-global">
        <h3>{currentTab?.title}</h3>
        <p>{currentTab?.subTitle}</p>
      </div>
      {currentTab?.tabAble && (
        <Tabs
          defaultActiveKey="0"
          activeKey={currentTab?.key}
          onChange={onChangeTab}
          items={tabs}
        />
      )}
      <Switch>
        <Route path={'/admin/profile-management'} exact>
          <Redirect to={`${path}/${tabs[0].path}`} />
        </Route>
        <Route path={`${path}/${tabs[0].path}`}>
          <UnsearchableTalentList />
        </Route>
        <Route path={`${path}/${tabs[1].path}`}>
          <UnsearchablePositions />
        </Route>
        <Route path={`${path}/${tabs[2].path}`}>
          <StaffSgJgManagement />
        </Route>
        <Route path={`${path}/${tabs[3].path}`}>
          <ListOfProfiles />
        </Route>
        <Route path={`${path}/${tabs[4].path}`}>
          <TopTalentStatus />
        </Route>
        <Route path={`${path}/${tabs[4].subTab[0].path}`} exact>
          <CreateOrEditTopTalentStatus mode="create" />
        </Route>
        <Route path={`${path}/${tabs[4].subTab[1].path}/:topTalentStatusId/:assessmentId`} exact>
          <CreateOrEditTopTalentStatus mode="edit" />
        </Route>
        <Route path={`${path}/${tabs[5].path}`} exact>
          <ExternalTalents />
        </Route>
        <Route path={`${path}/${tabs[5].subTab[0].path}`} exact>
          <ExternalTalentsForm />
        </Route>
        <Route path={`${path}/${tabs[5].subTab[1].path}/:id`} exact>
          <ExternalTalentsForm />
        </Route>
      </Switch>
    </div>
  );
}
