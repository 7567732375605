import { Spin, Tooltip } from 'antd';
import { uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { font } from '../../assets/common';
import { info_circle } from '../../assets/img';
import { convertDataCompentenciesExternalAssessment, returnBgdColorByScoreVal } from '../../utils/helper';

const Wrapper = styled.div`
  border: 1px solid #d3dee8;
  border-radius: 4px;
  margin-top: 30px;
  background-color: #ffffff;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #8896a7;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${font.inter};
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  padding: 14px 10px;
  border-bottom: 1px solid #d3dee8;
`;

const Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Legend = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${(props) => props?.color};
`;

const TooltipCss = styled.div`
  color: 'var(--base-white, #FFF)';
  font-size: 12px;
  font-weight: ${(props) => props?.fontWeight};
  line-height: 18px;
  font-family: ${font.inter};
`;

export const ExternalAssessmentCompetencies = (props) => {
  const { assessmentData, loading } = props;
  const data = convertDataCompentenciesExternalAssessment(assessmentData);

  return (
    <Spin spinning={loading} size="small">
      <div>
        <Wrapper style={{ marginTop: '30px' }}>
          <Item>
            <Header>Competencies</Header>
            <Header
              className="d-flex justify-content-center align-items-center "
              style={{ marginRight: '85px' }}
            >
              Score
            </Header>
          </Item>

          {data?.map((item) => (
            <Item key={uniqueId()}>
              <Flex>
                <div>
                  <span style={{ color: '#3D3D3D' }}>{item.competency}</span>
                  <Tooltip
                    placement="top"
                    title={
                      <>
                        {item.popup_content.map((popup) => (
                          <div key={uniqueId()} className="mt-1 mb-3">
                            <TooltipCss fontWeight={600}>{popup.header}</TooltipCss>
                            <TooltipCss fontWeight={400}>{popup.content}</TooltipCss>
                          </div>
                        ))}
                      </>
                    }
                    overlayClassName="custom-tooltip"
                    {...{
                      overlayInnerStyle: {
                        width: '400px',
                        padding: '10px',
                        borderRadius: '4px',
                        backgroundColor: '#000',
                      },
                    }}
                  >
                    <img
                      src={info_circle}
                      alt="info"
                      style={{ marginLeft: '6px', cursor: 'pointer' }}
                    />
                  </Tooltip>
                </div>
              </Flex>
              <div
                className="d-flex justify-content-center align-items-center "
                style={{
                  gap: '10px',
                  marginRight: isNaN(item.score) ? '100px' : '80px',
                  color: '#181818',
                }}
              >
                {isNaN(item.score) ? (
                  '-'
                ) : (
                  <>
                    {item.score} <Legend color={returnBgdColorByScoreVal(item)} />
                  </>
                )}
              </div>
            </Item>
          ))}
        </Wrapper>
      </div>
    </Spin>
  );
};

export default ExternalAssessmentCompetencies;
