import { Tooltip } from 'antd';
import React from 'react';

import { SPS } from '../../pages/Dashboard/constants';
import CommonLoading from '../CommonLoading/CommonLoading';
import styles from './talent-review-status.module.scss';

export const TalentReviewStatus = (props) => {
  const { talentReviewData, selectedTalentReviewStatus, selectedTopTalent, loading } = props;

  return (
    <>
      <div className={`${styles.talentReviewStatus}`}>
        <div className={styles.talentReviewTitle}>Talent Review Status</div>
        {loading ? (
          <div className="center-loading">
            <CommonLoading />
          </div>
        ) : (
          <>
            <div className={`${styles.talentReviewTotal} ${styles.totalTalent}`}>
              <div
                className={styles.talentReviewTotalNumber}
                onKeyDown={() => {}}
                onClick={() =>
                  selectedTalentReviewStatus({
                    value: talentReviewData.all,
                    label: 'Total Top Talents',
                    type: selectedTopTalent.value,
                  })
                }
              >
                <p className={styles.totalNumber}>{talentReviewData.total}</p>
              </div>
              <p className={styles.totalTitle}>Talents</p>
            </div>
            <div className={styles.talentReviewList}>
              <div
                className={styles.talentReviewItem}
                onKeyDown={() => {}}
                onClick={() =>
                  selectedTalentReviewStatus({
                    value: talentReviewData.non_successors,
                    label: 'Non Successors',
                    type: selectedTopTalent.value,
                  })
                }
              >
                <p>Non Successors</p>
                <Tooltip title={SPS.NON_SUCCESSORS}>
                  <p>{talentReviewData.non_successors}</p>
                </Tooltip>
              </div>
              <div
                className={styles.talentReviewItem}
                onKeyDown={() => {}}
                onClick={() =>
                  selectedTalentReviewStatus({
                    value: talentReviewData.non_review_more_than_five_years,
                    label: 'Not reviewed > 5 years',
                    type: selectedTopTalent.value,
                  })
                }
              >
                <p>{`Not reviewed > 5 years`}</p>
                <Tooltip title={SPS.NOT_REVIEWED_UP_5_YEARS}>
                  <p>{talentReviewData.non_review_more_than_five_years}</p>
                </Tooltip>
              </div>
              <div
                className={styles.talentReviewItem}
                onKeyDown={() => {}}
                onClick={() =>
                  selectedTalentReviewStatus({
                    value: talentReviewData.new_top_talents,
                    label: 'Yet to be reviewed',
                    type: selectedTopTalent.value,
                  })
                }
              >
                <p>Yet to be reviewed</p>
                <Tooltip title={SPS.YET_TO_BE_REVIEWED}>
                  <p>{talentReviewData.new_top_talents}</p>
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TalentReviewStatus;
