import { Spin } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BasicButton, BasicInput } from '../../../../assets/common';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { ACCESS_FIELD, REPORT_BUILDER_MESSAGE } from '../../constants';
import AccessControlField from '../AccessControlFields/AccessControlFields';
import * as styles from './edit-report-name-modal.module.scss';

const CUSTOM_STYLE = {
  closePosition: 30,
  headerPaddingBottom: 40,
  containerPaddingTopBottom: 40,
  titleFontSize: 20,
  titleFontWeight: 500,
};

const EditReportNameModal = ({ open, setOpen, initData, fetchReportDetail }) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    initialValues: {
      report_name: initData.report_name,
      ...ACCESS_FIELD.reduce(
        (acc, field) => ({ ...acc, [field.name]: initData[field.name] || [] }),
        {}
      ),
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setSaving(true);
        const body = {
          report_id: initData.report_id,
          ...values,
        };
        const res = await reportBuilderApi.updateReport(body);
        if (res.status === 200) {
          await fetchReportDetail();
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: REPORT_BUILDER_MESSAGE.EDIT_NAME_SUCCESS,
              isScroll: true,
            })
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
        setOpen(false);
      }
    },
  });

  const disableSaveBtn = useMemo(() => {
    if (!formik.values.report_name) return true;
    return !formik.dirty || !formik.values.report_name;
  }, [formik.dirty, formik.values.report_name]);

  const handleOnChange = (event) => {
    const target = event.target;
    formik.setFieldValue(target.name, target.value);
  };

  const handleClose = (event) => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <CustomAntModal
      title="Edit Report Name"
      destroyOnClose={false}
      onCancel={handleClose}
      hideDefaultBtn
      width={692}
      styles={CUSTOM_STYLE}
      open={open}
      setOpen={setOpen}
    >
      <Spin spinning={saving}>
        <BasicInput
          data-testid="report-name"
          id="report_name"
          name="report_name"
          placeholder="Enter Report Name"
          autoComplete="off"
          maxLength="20"
          height={40}
          value={formik.values.report_name}
          onChange={handleOnChange}
        />
        <AccessControlField
          formikSetFieldValue={formik.setFieldValue}
          formikFieldValues={formik.values}
          marginTop={40}
        />
        <div className={styles.btnWrapper}>
          <BasicButton height={36} onClick={handleClose}>
            Cancel
          </BasicButton>
          <BasicButton
            type="submit"
            onClick={formik.submitForm}
            mode="teal"
            height={36}
            disabled={disableSaveBtn}
          >
            Save
          </BasicButton>
        </div>
      </Spin>
    </CustomAntModal>
  );
};

EditReportNameModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  initData: PropTypes.object,
  fetchReportDetail: PropTypes.func,
};

export default EditReportNameModal;
