import React from 'react';
import * as SC from './styled';

export function Name(props) {
  const { birthName, positionName, departmentName, businessUnit } = props;
  return (
    <>
      <SC.Container data-testid="component-name-user-access">
        <div className="name">{birthName}</div>
        <div className="text">{positionName}</div>
        <div className="text">{departmentName}</div>
        <div className="text">{businessUnit}</div>
      </SC.Container>
    </>
  );
}
