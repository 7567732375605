import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { add_position, edit_icon_role, full_arrow_top } from '../../../../assets/img';
import { MOBILITY_MODAL_ADD_TYPE } from '../../constants';
import MobilityAddPositionModal from '../MobilityAddPositionModal/MobilityAddPositionModal';
import MobilityPositionCard from '../MobilityPositionCard/MobilityPositionCard';
import styles from './mobility-position-agenda.module.scss';

const MobilityPositionAgenda = (props) => {
  const {
    mobilitySimulation,
    setMobilitySimulation,
    addPositionOrTalent,
    isHighlighted,
    modalAddType,
    handleShowMessageSuccess,
    isShowRemark,
    agendaDetail,
    setShowAddRemarkSuccess,
    setIsHighlighted,
    removeItemOnChain,
    setIsSeniorManager,
    isSeniorManager,
  } = props;
  const [show, setShow] = useState(false);
  const [isChangeTitle, setIsChangeTitle] = useState(false);
  const flavoursContainer = document.getElementById('mobility');
  const flavoursScrollWidth = flavoursContainer?.scrollWidth;
  const nameRef = useRef(null);

  const handleOpenAddModal = () => {
    setShow(true);
  };

  useEffect(() => {
    if (!flavoursContainer && !flavoursScrollWidth) return;
    flavoursContainer.scrollLeft = flavoursScrollWidth;
  }, [flavoursContainer, flavoursScrollWidth]);

  const handleChangeTitlePositioin = () => {
    setIsChangeTitle(true);
  };

  const onChangedTitlePositioin = () => {
    const inputValue = nameRef.current.value;
    setMobilitySimulation((prev) => ({ ...prev, name: inputValue }));
    setIsChangeTitle(false);
  };

  return (
    <div className={styles.container} id="mobility">
      {!isChangeTitle ? (
        <h3 className={styles.mainTitle}>
          {mobilitySimulation.name}
          <img
            className={styles.editTitleBtn}
            src={edit_icon_role}
            width={40}
            height={40}
            alt="edit_icon_role"
            onKeyDown={() => {}}
            onClick={handleChangeTitlePositioin}
          />
        </h3>
      ) : (
        <h3 className={styles.mainTitle}>
          <input
            ref={nameRef}
            className={styles.inputTextTitle}
            defaultValue={mobilitySimulation.name}
          />
          <img
            className={styles.editTitleBtn}
            src={edit_icon_role}
            width={40}
            height={40}
            alt="edit_icon_role"
            onKeyDown={() => {}}
            onClick={onChangedTitlePositioin}
          />
        </h3>
      )}
      {!isEmpty(mobilitySimulation.dataChain) &&
        mobilitySimulation.dataChain.map((item, index) => (
          <React.Fragment key={index}>
            <MobilityPositionCard
              data={item}
              index={index}
              isHighlighted={isHighlighted}
              handleShowMessageSuccess={handleShowMessageSuccess}
              isShowRemark={isShowRemark}
              agendaDetail={agendaDetail}
              setShowAddRemarkSuccess={setShowAddRemarkSuccess}
              removeItemOnChain={removeItemOnChain}
              setIsHighlighted={setIsHighlighted}
            />
            <div
              className="align-self-center"
              style={
                isSeniorManager && mobilitySimulation.dataChain.length === index + 1
                  ? { display: `none` }
                  : {}
              }
            >
              <img src={full_arrow_top} width={24} height={40} alt="full_arrow_top" />
            </div>
          </React.Fragment>
        ))}
      {!isSeniorManager && (
        <Droppable droppableId="mobilityPosition">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              onKeyDown={() => {}}
              onClick={handleOpenAddModal}
            >
              <div
                className={`${styles.addPosition} ${
                  !mobilitySimulation.isValidate &&
                  isEmpty([mobilitySimulation.dataChain]) &&
                  styles.addPositionError
                }`}
              >
                <img src={add_position} alt="add_position" />
                <div>
                  {modalAddType === MOBILITY_MODAL_ADD_TYPE.POSITION
                    ? 'Click plus icon to add Position'
                    : 'Click plus icon or drag and drop the card here to add Talent'}
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )}

      {show && (
        <MobilityAddPositionModal
          show={show}
          setShow={setShow}
          type={modalAddType}
          addPositionOrTalent={addPositionOrTalent}
          setIsSeniorManager={setIsSeniorManager}
        />
      )}
    </div>
  );
};

export default MobilityPositionAgenda;
