import { Button, Col, Menu, Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ellipsis } from '../../../../../assets/img';
import shapeSvg from '../../../../../assets/img/Shape.svg';
import { AiTitle } from '../../Styled';

export const ShapeIcon = styled('img').attrs({
  src: shapeSvg,
  alt: 'Dropdown Icon',
})`
  filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);
  padding-left: 17px;
`;

export const Title = styled(AiTitle)`
  width: unset;
  display: flex;
  align-items: center;
`;

export const FilterCol = styled(Row)`
  gap: 8px;
`;

export const RemarksBody = styled(Row)`
  justify-content: center;
  align-items: center;
  min-height: 152px;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

export const NoRemarkCol = styled(Row)`
  justify-content: center;
  align-items: center;
  min-height: 152px;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

export const NoRemarkTitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* Emphasis/Body N800 */

  color: #3f3c4c;
`;

export const NoRemarkDescription = styled.p`
  width: 323px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #9f9eae;
`;

export const RemarkItemStyled = styled(Col)`
  width: 100%;
  min-height: 100px;
  padding: 28px 0px;
  background: #ffffff;
  border: 1px solid #d3dee8;
  border-radius: 6px;
`;

export const RemarkIcon = styled.span`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;

  width: 32px;
  height: 32px;

  background: #edf1f8;
  border: 2px solid #ffffff;
  border-radius: 32px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  text-transform: uppercase;

  /* Blue Grays/600 */

  color: #677c91;
`;

export const RemarkTalentName = styled(Space)`
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  max-width: 90%;

  /* Emphasis/Body N800 */

  color: #3f3c4c;
  height: fit-content;
`;

export const RemarkCategory = styled(Col)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #9f9eae;
`;

export const RemarkHeader = styled(Row)`
  width: 100%;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
`;

export const RemarkItemBody = styled(Row)`
  gap: 13px;
  flex-direction: column;
`;

export const RemarkItemCardTitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  word-break: break-word;
  /* Emphasis/Body N800 */

  color: #3f3c4c;
`;

export const RemarkItemCardDescription = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  color: #9f9eae;
`;

export const MenuWithStyle = styled(Menu)`
  width: 114px;
  min-width: unset;
`;

export const MenuItemStyled = styled(Menu.Item)`
  width: 114px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: ${(props) => (props.$isDelete ? '#ff787f' : '#3F3C4C')};
`;

export const EllipsisButton = styled(Button).attrs({
  children: <img src={ellipsis} alt="Action Icon" />,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;

  &:hover {
    background-color: #f6f7f9;
  }
`;
