import styled from 'styled-components';
import { font, fontSize } from '../assets/common';
export const TooltipText = styled.span`
  display: inline-block;
  font-family: ${font.inter};
  font-size: ${(props) => (props.size === 'small' ? fontSize.small : '9px')};
  color: ${(props) => (props.hadColor ? 'red' : '')};
  font-weight: ${(props) => (props.hadColor ? 'bold !important' : '')};
  margin-right: 4px;
`;
