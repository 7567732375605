import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Rectangle from '../../../../../assets/img/Rectangle.svg';
import styles from './dropdown.module.scss';

export function Dropdown({ selected, setSelected, options, isError }) {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={`${styles.dropdown} ${isError && !selected ? styles.error : ''}`} ref={ref}>
      <div
        className={styles.dropdown_btn}
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
        data-testid="dropdown-user-access"
      >
        <p className={styles.dropdown_select_label} data-testid="name">
          {selected || 'Select'}
        </p>
        {<img src={Rectangle} alt="" />}
      </div>

      {isActive && (
        <div
          className={styles.dropdown_content}
          style={{ maxHeight: '200px', overflowY: 'auto' }}
          data-testid="dropdown-user-access-list"
        >
          {!isEmpty(options) &&
            options.map((option, index) => {
              return (
                <div
                  key={index}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className={styles.dropdown_item}
                  data-testid="click-dropdown-user-access"
                >
                  {option}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
