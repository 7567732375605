import { isEmpty } from 'lodash';
import * as yup from 'yup';

export const formMappingvalidationSchema = yup.object({
  business_unit: yup.object().required(true),
  data_field_in_oracle: yup.object().required(true),
  data_content_in_oracle: yup.object().required(true),
});

export const handleCheckEmptyValue = (values) => {
  let isCheck = false;
  Object.keys(values).forEach((key) => {
    if (isEmpty(values[key])) {
      isCheck = true;
      return true;
    }
  });
  return isCheck;
};
