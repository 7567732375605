import React from 'react';
import { dashboard_breadcrumb, talent_block } from '../../assets/img';
import { BreadCrumb } from '../../components';
import styles from './talentProfilePage.module.scss';
import { useHistory } from 'react-router-dom';

const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Talent Profile' },
];

const TalentProfileBlock = () => {
  const history = useHistory();
  return (
    <>
      <div className={styles.maxWidth}>
        <div className={styles.control}>
          <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
          <h3 className={styles.title}>Talent Profile</h3>
          <div style={{ position: 'relative' }}>
            <img src={talent_block} alt="" width="100%" />
            <div
              style={{
                position: 'absolute',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
                color: 'transparent',
                fontFamily: `'Inter', sans-serif`,
                top: '67%',
                left: '50%',
                letterSpacing: '0.4px',
                transform: 'translate(-5px, -3px)',
                cursor: 'pointer',
              }}
              onKeyDown={() => {}}
              onClick={() => history.push('/talent-finder')}
            >
              Go back.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentProfileBlock;
