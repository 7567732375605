import styled from 'styled-components';

import { font } from '../../../../assets/common';

export const FileUploadContainer = styled.section`
  position: relative;
  border: 2px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

export const FilePreviewContainer = styled.article`
  position: relative;

  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  width: 20%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;

export const DocumentIcon = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
`;

export const FileUploadTitle = styled.h3`
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: #3f3c4c;
  width: 100%;
  margin-bottom: 0;

  .progress-file-size {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #9f9eae;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background: rgba(0, 161, 156, 0.1);
  border: 1px solid rgba(0, 161, 156, 0.1);
  border-left-width: 4px;
  border-left-color: #00a19c;
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 35px;
  padding-right: 24px;
`;
