import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AsyncMultipleSelect } from '../../../../components';
import { isCheckError, onChangeInputData, showError } from '../../../../hooks/useFormik';
import { useGetRoleInMeeting } from '../../../../hooks/useGetRoleInMeeting';
import { DATE_TIME_FORMAT } from '../../../../utils/constants';
import {
  getAsyncOptionAdvocatorsHrPartners,
  isDisabledFiledInAgendaForm,
  isDisabledRemarkFieldInAgenda,
} from '../../../../utils/helper';
import {
  AiAgendaForm,
  AiInput,
  AiLabel,
  AiTextarea,
  AiTimePickerField,
  AiTitle,
} from '../../../Meeting/MeetingDetails/Styled';

const MobilityAgendaForm = (props, ref) => {
  const { agendaDetail, setCompareData } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const { idMeeting, agendaId } = useParams();
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const isDisabledField = useMemo(() => {
    return isDisabledFiledInAgendaForm(agendaId, agendaDetail, meetingRole);
  }, [agendaDetail, agendaId, meetingRole]);

  const isDisabledRemarkField = useMemo(() => {
    return isDisabledRemarkFieldInAgenda(agendaId, agendaDetail, meetingRole);
  }, [agendaDetail, agendaId, meetingRole]);

  const validationSchema = yup.object({
    title: yup.string().required('Title is required'),
    start_time: yup
      .string()
      .required('Start Time is required.')
      .test('is-greater', 'Start Time must be less than or equal to End Time', function (value) {
        const startTimeMoment = moment(value, DATE_TIME_FORMAT.TIME);
        const endTimeMoment = moment(formik.values.end_time, DATE_TIME_FORMAT.TIME);
        if (isEmpty(formik.values.end_time) || formik.values.end_time === value) return true;
        return endTimeMoment.isAfter(startTimeMoment);
      }),
    end_time: yup.string().required('End Time is required.'),
    advocators: yup.array().min(1, 'Advocator is required'),
    hr_partners: yup.array().min(1, 'HR Partners is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      title: agendaDetail?.title || '',
      start_time: agendaDetail?.start_time,
      end_time: agendaDetail?.end_time,
      advocators: agendaDetail?.advocators || [],
      hr_partners: agendaDetail?.hr_partners || [],
      remarks: agendaDetail?.remarks || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      handleSubmitForm: async () => {
        return await formik.submitForm();
      },
    }),
    [formik]
  );

  useEffect(() => {
    if (isEmpty(agendaDetail)) return;
    setCompareData((prev) => ({ ...prev, currentData: { ...prev.currentData, ...formik.values } }));
  }, [formik.values]);

  return (
    <AiAgendaForm>
      <AiTitle>Mobility Discussion Session Details</AiTitle>
      <Row gutter={16} className="mb-3">
        <Col span={16} className="gutter-row">
          <AiLabel>Title</AiLabel>
          <AiInput
            placeholder="Enter Mobility Discussion Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            status={isCheckError(formik, 'title') ? 'error' : ''}
            disabled={isDisabledField}
          />
          {showError(formik, 'title')}
        </Col>

        <Col span={4} className="gutter-row">
          <AiLabel>Start time</AiLabel>
          <AiTimePickerField
            placeholder={'Select Time'}
            value={
              formik.values.start_time
                ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                : null
            }
            style={{ width: '100%' }}
            onChange={(_event, value) => onChangeInputData(formik, value, 'start_time')}
            status={isCheckError(formik, 'start_time') ? 'error' : ''}
            showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
            format={DATE_TIME_FORMAT.TIME}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={isDisabledField}
          />
          {showError(formik, 'start_time')}
        </Col>
        <Col span={4} className="gutter-row">
          <AiLabel>End time</AiLabel>
          <AiTimePickerField
            placeholder={'Select Time'}
            value={
              formik.values.end_time
                ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                : null
            }
            style={{ width: '100%' }}
            onChange={(_event, value) => onChangeInputData(formik, value, 'end_time')}
            status={isCheckError(formik, 'end_time') ? 'error' : ''}
            showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
            format={DATE_TIME_FORMAT.TIME}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={isDisabledField}
          />
          {showError(formik, 'end_time')}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <AiLabel>Advocators</AiLabel>
          <AsyncMultipleSelect
            value={formik.values.advocators}
            placeholder="Enter Advocators Name"
            loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
            onChange={(e) => onChangeInputData(formik, e, 'advocators')}
            status={isCheckError(formik, 'advocators') ? 'error' : ''}
            isDisabled={isDisabledField}
          />
          {showError(formik, 'advocators')}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <AiLabel>HR Partners</AiLabel>
          <AsyncMultipleSelect
            name="hr_partners"
            value={formik.values.hr_partners}
            placeholder="Enter HR Partners Name"
            loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
            onChange={(e) => onChangeInputData(formik, e, 'hr_partners')}
            status={isCheckError(formik, 'hr_partners') ? 'error' : ''}
            isDisabled={isDisabledField}
          />
          {showError(formik, 'hr_partners')}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AiLabel noRequire={true}>Remarks</AiLabel>
          <AiTextarea
            rows={5}
            name="remarks"
            value={formik.values.remarks}
            placeholder="Enter your remarks here"
            onChange={formik.handleChange}
            status={isCheckError(formik, 'remark') ? 'error' : ''}
            disabled={isDisabledRemarkField}
          />
        </Col>
      </Row>
    </AiAgendaForm>
  );
};

export default React.forwardRef(MobilityAgendaForm);
