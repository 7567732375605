export const rootStyles = {
  valueContainer: (provided, _state) => ({
    ...provided,
    padding: '2px 8px 2px 15px',
    gap: '8px',
  }),
  multiValue: (provided, _state) => ({
    ...provided,
    flexDirection: 'row-reverse',
    background: '#FFFFFF',
    border: '1px solid #BDBDBD',
    borderRadius: '11px',
    margin: 0,
    padding: '3px 8px 3px 3px',
    alignItems: 'center',
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    color: '#8d8d8d',
  }),
  multiValueLabel: (provided, _state) => ({
    ...provided,
    padding: 0,
    paddingLeft: 0,
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'uppercase',
  }),
  multiValueRemove: (provided, _state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'unset',
      color: '#535D70',
    },
  }),
};
