import React, { useState } from 'react';
import { dashboard_breadcrumb } from '../../assets/img';
import { BreadCrumb } from '../../components';
import Calendar from './components/Calendar/Calendar';
import MeetingStatus from './components/MeetingStatus/MeetingStatus';
import styles from './talent-committee.module.scss';

const TalentCommittee = () => {
  const breadCrumbList = [
    { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
    { name: 'Talent Committee Meeting' },
  ];
  const [meetingTypeSelected, setMeetingTypeSelected] = useState('all');

  return (
    <>
      <div className={styles.maxWidth} data-testid="talent-committee">
        <div className={styles.control}>
          <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
          <h3 className={styles.title}>Talent Committee Meeting</h3>
          <MeetingStatus selected={meetingTypeSelected} setSelected={setMeetingTypeSelected} />
          <div className="mt-3">
            <div className={styles.overallProfileUser}>
              <Calendar meetingType={meetingTypeSelected} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentCommittee;
