import React, { useMemo, useRef, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import Overlay from 'react-bootstrap/Overlay';
import styled from 'styled-components';

import { mobility_plan, remove_card, sp_recomendation_table_comment } from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import { useComponentVisible } from '../../../../hooks';
import { decodeBase64ToImage, handleShowYIPAndYISG, releaseChecklistColor, retiredAge } from '../../../../utils/helper';
import AddRemark from '../../../Meeting/AgendaDetails/components/Recommendations/AddRemark/AddRemark';
import MobilityLegend from '../../components/MobilityLegend/MobilityLegend';
import { MOBILITY_CATEGORY_COLOR, MOBILITY_MESSAGE, TOP_TALENT } from '../../constants';
import MobilityPlanTooltip from '../MobilityPlanTooltip/MobilityPlanTooltip';
import * as styles from './mobility-position-card.module.scss';
import { useSelector } from 'react-redux';
import { remarksRemainingSelector } from '../../../Meeting/selector';
import { isEmpty } from 'lodash';

export const TooltipStyle = styled(Tooltip)`
  .tooltip-inner {
    max-width: 387px;
    max-height: 228px;
    overflow-y: scroll;
  }
`;

const MobilityPositionCard = (props) => {
  const {
    data,
    provided,
    index,
    isHighlighted,
    width,
    isAutoWidth,
    handleShowMessageSuccess,
    isShowRemark,
    agendaDetail,
    setShowAddRemarkSuccess,
    removeItemOnChain,
    setIsHighlighted,
  } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const target = useRef(null);
  const remarks = useSelector(remarksRemainingSelector);

  const isDisableIndex = data?.isDisableIndex ? data.isDisableIndex : false;
  const isTalentCard = data?.type ? (data.type === 'talent' ? true : false) : true;

  const [showRemovePosition, setShowRemovePosition] = useState(false);
  const [showRemoveTalent, setShowRemoveTalent] = useState(false);
  const [showAddRemark, setShowAddRemark] = useState({
    status: false,
    staff: '',
  });
  const handleShowRemove = () => {
    isTalentCard ? setShowRemoveTalent(true) : setShowRemovePosition(true);
  };

  const additionalInputRemark = useMemo(() => {
    if (isEmpty(remarks)) return null;
    const remarkItems = remarks.filter((remark) => remark.staffId === data?.staff_id && remark?.remarksCategory === 'Additional Input');

    if (isEmpty(remarkItems)) return null;
    return (
      <div className={styles.additionalInput}>
        <ul>
          {remarkItems.map((item) => (
            <li key={item.remarks}>{item.remarks}</li>
          ))}
        </ul>
      </div>
    );
  }, [remarks, data]);

  return (
    <div
      className={`${styles.card_box} ${isAutoWidth ? styles.auto_width : ''}`}
      data-testid="view-card-box"
      style={{
        borderColor: `${Number(data.key) === isHighlighted?.key ? '#50A4B9' : '#e7e7f0'}`,
        minWidth: width,
        cursor: 'pointer',
      }}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      {isTalentCard && (
        <div
          className={`${styles.left}`}
          style={{
            backgroundColor: `${(data?.category && MOBILITY_CATEGORY_COLOR.find((item) => item.name === data.category)?.color) || '#fff'}`,
          }}
          onKeyDown={() => {}}
          onClick={() => {
            data?.isRemove &&
              setIsHighlighted({
                key: Number(data.key),
                position_code: data.position_code,
              });
          }}
        >
          <div className="row flex-column no-gutters align-items-center mt-1">
            <div className={styles.index}>{!isDisableIndex && data?.index}</div>
            <div className={styles.image}>
              <img src={decodeBase64ToImage(data?.image)} alt="" />
            </div>
            <div className={styles.staffId}>{data?.staff_id}</div>

            <div className={styles.position}>
              <span>VP: {data?.vp}</span>
              <span>SGM: {data?.sgm}</span>
              <span>GM: {data?.gm}</span>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.right}`} style={{ paddingLeft: `${isTalentCard ? '' : '30px'}` }}>
        <div
          style={{ width: '100%' }}
          onKeyDown={() => {}}
          onClick={() => {
            data?.isRemove &&
              setIsHighlighted({
                key: Number(data.key),
                position_code: data.position_code,
              });
          }}
        >
          {isTalentCard && (
            <div className={styles.name}>
              <div>
                <span>
                  {data?.birth_name}&nbsp;
                  {(data?.ct && TOP_TALENT.find((item) => item.name === data.ct)?.symbol) || 'N/A'}
                </span>
                <span ref={ref}>
                  <img
                    src={mobility_plan}
                    alt="mobility_plan"
                    className={styles.mobilityPlan}
                    ref={target}
                    onKeyDown={() => {}}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                    style={{ cursor: 'pointer', pointerEvents: 'all' }}
                  />
                  <Overlay target={target.current} show={isComponentVisible} placement="bottom">
                    {(props) => (
                      <TooltipStyle id="overlay-example" {...props}>
                        <MobilityPlanTooltip mobilityPlan={data?.mobility_plan} releaseChecklist={data?.release_checklist} />
                      </TooltipStyle>
                    )}
                  </Overlay>
                </span>
              </div>
              <div>
                <span className={styles.percentage}>{data?.matching_percentage}</span>
              </div>
              {isShowRemark && (
                <div
                  onKeyDown={() => {}}
                  onClick={() =>
                    setShowAddRemark({
                      status: true,
                      staff: data,
                    })
                  }
                  className={styles.click}
                >
                  <img src={sp_recomendation_table_comment} alt="sp_recomendation_table_comment" />
                </div>
              )}
              {showAddRemark.status && (
                <AddRemark
                  setIsVisible={setShowAddRemark}
                  showAddRemark={showAddRemark}
                  agendaDetail={agendaDetail}
                  setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                />
              )}
            </div>
          )}

          <div className="mb-2">
            {isTalentCard && <span>Position: </span>}
            <span data-testid="value-position-business-unit" className={isTalentCard ? styles.textThin : styles.name}>
              {data?.position_name}, {data?.business}
            </span>
          </div>
          {!isTalentCard && (
            <div className="mb-2">
              <span>Current Incumbent: </span>
              <span data-testid="value-position-business-unit" className={styles.textThin}>
                {data?.current_incumbent}
              </span>
            </div>
          )}

          <div className="mb-2">
            <span>YIP: </span>
            <span data-testid="time-position-value" className={styles.textThin}>
              {handleShowYIPAndYISG(data?.years_in_position, data?.date_in_position)}
            </span>
            &#160;&#160;&#160;<span>YISG: </span>
            <span data-testid="job-grade-value" className={styles.textThin}>
              {data?.salary_grade}&nbsp; ({handleShowYIPAndYISG(data?.years_in_sg, data?.date_in_sg)})
            </span>
          </div>

          <div className="mb-2">
            <span>Performance Rating: </span>
            <span data-testid="rating-value" className={styles.textThin}>
              {data?.performance_rating}
            </span>
          </div>

          <div className="mb-2">
            <span>Retire: </span>
            <span data-testid="retirement-date-value" className={styles.textThin}>
              {data?.retirement_date}&nbsp;
            </span>
            <span data-testid="retirement-age-value">
              {retiredAge(data?.retirement_date, data?.birth_date) ? `${retiredAge(data?.retirement_date, data?.birth_date)}` : '(N/A)'}
            </span>
            &#160;&#160;&#160;
            <span>Age: </span>
            <span data-testid="age-value" className={styles.textThin}>
              {data?.age}
            </span>
          </div>

          {isTalentCard && (
            <div>
              <span>Release Checklist: </span>
              {releaseChecklistColor(data?.release_checklist) ? <MobilityLegend color={releaseChecklistColor(data?.release_checklist)} /> : 'N/A'}
            </div>
          )}
          {additionalInputRemark}
        </div>

        {data?.isRemove && (
          <div>
            <img src={remove_card} alt="remove_card" style={{ cursor: 'pointer' }} onKeyDown={() => {}} onClick={handleShowRemove} />
          </div>
        )}
      </div>
      <ModelTC
        info={{
          type: 'confirmDeletePosition',
          visible: showRemovePosition,
          setVisible: setShowRemovePosition,
          onClose: () => setShowRemovePosition(false),
          handleSubmit: () => {
            removeItemOnChain(index);
            handleShowMessageSuccess(MOBILITY_MESSAGE.DELETE_POSITION_SUCCESS);
          },
        }}
      />
      <ModelTC
        info={{
          type: 'confirmDeleteTalent',
          visible: showRemoveTalent,
          setVisible: setShowRemoveTalent,
          onClose: () => setShowRemoveTalent(false),
          handleSubmit: () => {
            removeItemOnChain(index);
            handleShowMessageSuccess(MOBILITY_MESSAGE.DELETE_TALENT_SUCCESS);
          },
        }}
      />
    </div>
  );
};

export default MobilityPositionCard;
