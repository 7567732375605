import React from 'react'

export const LayoutPreivew = (props) => {
    const { children } = props;

    return (


        <div style={{ position: 'fixed', left: 0, width: '100vw', height: '100vh' }} >{children}</div>
    )
}