export const NON_MALAYSIAN = 'Other Nationalities';
export const LESS_THAN_1_YEAR = '<1 year';
export const LESS_THAN_3_YEAR = '1 - 3 years';
export const LESS_THAN_5_YEAR = '>3 -5 years';
export const CCP_SUCCESSOR = 'CCP Successor';
export const BCP_SUCCESSOR = 'BCP Successor';
export const NON_SUCCESSOR_TEXT = 'Non Successor';
export const BOTH_CCP_AND_BCP = 'Both CCP and BCP';
export const SENIOR_GENERAL_MANAGER = 'Senior General Manager';
export const SENIOR_GENERAL = 'Senior General';
export const GENERAL_MANAGER = 'General Manager';
export const SENIOR_MANAGER = 'Senior Manager';
export const MANAGER = 'Manager';
export const BUSINESS_LABEL_CHART = {
  PDT_1_: 'Project Delivery',
  PDT_2_: '& Technology',
};
export const RETIREMENT_LABEL_CHART = {
  PDT_1_: 'Senior General',
  PDT_2_: 'Manager',
};
