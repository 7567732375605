import { useEffect, useState } from 'react';

export const useShowAlertWhenRefresh = () => {
  const [showAlertRefresh, setShowAlertRefresh] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showAlertRefresh) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showAlertRefresh]);

  return { showAlertRefresh, setShowAlertRefresh };
};
