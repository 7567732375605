import React from 'react';
import { DatePicker, Select } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { ASSESSMENT_TYPE, ROLE_LEVEL_LIST } from '../../utils/constants';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './assessment.module.scss';

const AddEditAssessment = ({ open, setOpen, isEdit, handleSubmit, dataEdit }) => {
  const formik = useFormik({
    initialValues: {
      type: dataEdit?.type || null,
      date: dataEdit?.dates ? moment(dataEdit.dates) : null,
      assessment_result: dataEdit?.assessment_result || '',
    },
    validationSchema: Yup.object({
      type: Yup.string().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      date: Yup.date().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      assessment_result: Yup.string().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (value) => {
      const dataNote = {
        ...value,
        date: moment(value.date).format(),
      };
      handleSubmit(dataNote, formik.handleReset);
    },
  });
  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      title={isEdit ? 'Edit Assessment Data' : 'Add Assessment Data'}
      onOk={formik.submitForm}
      onCancel={() => formik.handleReset()}
      okText={isEdit ? 'Save Changes' : 'Add Assessment Data'}
      width={1000}
      destroyOnClose
    >
      <div className={styles.body}>
        <h4 className={styles.title}>
          {isEdit ? 'Edit Assessment Data for the Talent' : 'Add Assessment Data for the Talent'}
        </h4>

        <div className="row mb-3">
          <div className="col">
            <div className={`${styles.label}`}>
              Assessment Type<span style={{ color: 'red' }}>*</span>
            </div>
            <Select
              data-testid="type"
              size="middle"
              options={ASSESSMENT_TYPE}
              getPopupContainer={(trigger) => trigger.parentElement}
              name="type"
              style={{ width: '100%' }}
              placeholder="Select Assessment Type"
              value={formik.values.type}
              onChange={(value) => {
                formik.setFieldValue('type', value);
              }}
            />
            {formik.errors.type && formik.touched.type && (
              <div className={styles.text_error}> {formik.errors.type}</div>
            )}
          </div>
          <div className="col">
            <div className={`${styles.label}`}>
              Date<span style={{ color: 'red' }}>*</span>
            </div>
            <DatePicker
              data-testid="date"
              name="date"
              getPopupContainer={(trigger) => trigger.parentElement}
              value={formik.values.date}
              className={styles.formInput}
              onChange={(newDate) => {
                formik.setFieldValue('date', newDate);
              }}
              format="DD MMMM YYYY"
              inputReadOnly
              placeholder="Select Date"
            />
            {formik.errors.date && formik.touched.date && (
              <div className={styles.text_error}> {formik.errors.date}</div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <div className={`${styles.label}`} style={{ alignItems: 'unset' }}>
            Recommendation Result<span style={{ color: 'red' }}>*</span>
          </div>
          <textarea
            data-testid="assessment_result"
            name="assessment_result"
            className={styles.formInput}
            value={formik.values.assessment_result}
            onChange={formik.handleChange}
            style={{ height: '94px' }}
          />
          {formik.errors.assessment_result && formik.touched.assessment_result && (
            <div className={styles.text_error}> {formik.errors.assessment_result}</div>
          )}
        </div>
      </div>
    </CustomAntModal>
  );
};

export default AddEditAssessment;
