import { DatePicker, Input, TimePicker } from 'antd';
import TimeField from 'react-simple-timefield';
import styled, { css } from 'styled-components';

import { color, font, fontSize } from '../../../../assets/common';

const { RangePicker } = DatePicker;

export const AiHeader = styled.h3`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.large};
  color: ${color.brightGray2};
  margin-bottom: 32px;
`;
export const AiGrBtnHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;
export const AiFormWrapper = styled.div`
  background-color: white;
  padding: 26px 39px 44px 33px;
  margin-bottom: 24px;
  border-radius: 6px;
`;
export const AiTitle = styled.p`
  font-family: ${font.inter};
  font-size: ${fontSize.sizable};
  font-weight: 600;
  color: ${color.brightGray2};
`;
export const AiLabel = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${fontSize.small};
  position: relative;
  margin-bottom: 5px;
  ${(props) => {
    if (!props.require) {
      return css``;
    }

    return css`
      &::after {
        position: absolute;
        content: '*';
        color: ${color.danger};
      }
    `;
  }}
`;
export const AiInput = styled(Input)`
  border-radius: 4px;
  padding: 10px 16px;
  font-family: ${font.inter};
  &:hover,
  &:focus {
    border-color: ${(props) => (props?.errors ? '#F36568' : `#9fb0c2`)};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
  &:disabled {
    background: #e0e0e0 !important;
    border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid #e0e0e0`)};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  border-color: ${(props) => (props?.errors ? '#F36568' : `none`)};
`;
export const AiTimeField = styled(TimeField)`
  padding: 9px 16px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  border-radius: 4px;
`;
export const AiTimePickerField = styled(TimePicker)`
  padding: 10px 16px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  border-radius: 4px;
`;
export const AiGrBtnMiddle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;
export const AiImg = styled.img`
  max-width: 105px;
`;
export const AiDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px 16px;
  border-radius: 4px;

  &:focus {
    border-color: ${color.teal} !important;
  }
`;
export const AiRangePicker = styled(RangePicker)`
  ${({ height }) => {
    return css`
      width: 100%;
      height: ${height || 44}px;
      padding: 10px 16px;
      border-radius: 4px;

      &:hover,
      &:focus {
        border-color: ${color.teal} !important;
      }
    `;
  }}
`;
