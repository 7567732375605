import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiSpin, BasicButton } from '../../assets/common';
import { my_talent_x_logo, petronas_logo } from '../../assets/img';
import { loginRequest } from '../../msalConfig';
import userApi from '../../services/userApi';
import { setData, setRoleActive, setRoles, setUsername } from '../../store/userSlice';
import { ACTION_LOG, ERROR_MESSAGE } from '../../utils/constants';
import * as styles from './system-maintenance.module.scss';

const SystemMaintenance = () => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const username = useSelector((state) => state.user.username);
  const [loading, setLoading] = useState(false);

  const handleClickLogin = async () => {
    instance.loginRedirect(loginRequest);
  };

  async function getEmployee(userId) {
    try {
      setLoading(true);
      const response = await userApi.getUserById(userId);
      userApi.logAction({ url: '', type: ACTION_LOG.LOGIN });
      if (response.status === 200) {
        if (!roleActive.roleId) {
          dispatch(setRoleActive(response.data.result.roles[0]));
        }
        dispatch(setRoles(response.data.result.roles));
        dispatch(setData(response.data.result));
        dispatch(setUsername(response.data.result.employeeName));
        history.push('/homepage');
      }
    } catch (err) {
      const res = err.response;
      if (res?.status === 500) {
        if (res.data.message === ERROR_MESSAGE) {
          notification.error({
            message: 'User not exist in system',
            duration: 5,
          });
          localStorage.clear();
        }
      }
      if (res?.status === 403) {
        localStorage.clear();
        history.push('/');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentAccount?.name) {
      getEmployee();
    }
    if (currentAccount?.name && username) {
      return history.push('/homepage');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount?.name]);

  return (
    <>
      <MsalAuthenticationTemplate />
      <Row className={styles.wrapper}>
        <Col className={styles.leftSide}>
          <img src={my_talent_x_logo} alt="logo" />
        </Col>
        <Col className={styles.rightSide}>
          <div className={styles.content}>
            <div className={styles.logo}>
              <img src={petronas_logo} alt="logo" />
            </div>
            <div className={styles.title}>System Stabilization Period Extended</div>
            <div className={styles.detail}>
              Dear valued users, <br />
              This system is currently undergoing Go-Live Preparation to ensure you get the best
              experience. <br />
              Due to unforeseen circumstances and unexpected delays, we have decided to extend
              stabilization period from <span>18 March 2023 - 24 March 2023</span> until{' '}
              <span>31 March 2023.</span>
              <br />
              We understand that this may cause some inconvenience and disruption to your schedule.
              We apologize for any inconvenience this may cause. We are working diligently to
              stabilize the system as soon as possible and deliver the best possible results.
              <br />
              We sincerely thank you for your understanding and cooperation.
            </div>
            {!loading ? (
              <>
                <BasicButton mode="teal" className={styles.loginBtn} onClick={handleClickLogin}>
                  Login
                </BasicButton>
              </>
            ) : (
              <AiSpin className={styles.loading}></AiSpin>
            )}
            <div className={styles.contact}>
              For inquiries and assistance, please contact ICT Service Desk at <br />
              <span>ict.servicedesk@petronas.com</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default SystemMaintenance;
