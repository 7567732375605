import { combineReducers } from '@reduxjs/toolkit';
import agenda from '../Agenda/store';
import profiles from '../TalentProfilePage/store';
import dashboard from '../Dashboard/store';
import adminModule from '../Admin/adminSlice';
import preMeeting from '../Meeting/redux';
const appReducers = combineReducers({
  meetingTC: agenda,
  talentProfile: profiles,
  dashboard: dashboard,
  adminModule,
  meetingModule: preMeeting,
});

export default appReducers;
