import { Tooltip } from 'antd';
import { chunk, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { DATE_TIME_FORMAT } from '../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowRetirementForSP,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
  infoPosition,
} from '../../../../utils/helper';
import { ApprovedLabel } from '../../../Meeting/AgendaDetails/components/Recommendations/Box/ApprovedLabel';
import styles from './box-dsp.module.scss';

const CardBoxPrintSP = (props) => {
  const { item, index, blockStyle } = props;
  const { positions, agenda_as_main_type, agenda_as_main_platform, agenda_as_main, agenda_as_main_start_at } = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent
  );

  return (
    <div className={styles.card_box} data-testid="view-card-box">
      <div className="row no-gutters justify-content-center align-items-stretch" style={{ height: '100%' }}>
        <div className={`col-4 ${styles.left}`} style={{ backgroundColor: blockStyle.inforColor }}>
          <div className={styles.number}>{index + 1}</div>

          <div className="row flex-column no-gutters align-items-center mt-3">
            <div className={styles.image}>
              <img src={decodeBase64ToImage(item?.image)} alt="" />
            </div>
            <div style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>{item?.identity_number || item?.staff_id}</div>

            {!item?.isUnsearchable && (
              <div className={styles.position}>
                <Tooltip
                  placement="bottom"
                  title={handleShowTextTooltip(item, positions)}
                  color={'black'}
                  key={'black'}
                  overlayInnerStyle={{ borderRadius: '6px' }}
                >
                  {handleShowTooltip(item, positions)}
                </Tooltip>
              </div>
            )}
            <div style={{ marginTop: '10px' }}>
              <ApprovedLabel positions={positions} item={item} isOldUI={true} blockStyle={blockStyle} />
            </div>
            {agenda_as_main && agenda_as_main === item.agenda_as_main && (
              <Tooltip
                overlayClassName={styles.tooltip}
                placement="top"
                title={
                  <div className={styles.platformName}>
                    <span>Platform Name: {agenda_as_main_platform}</span>
                    <br />
                    {agenda_as_main_start_at && <span>Date: {moment(agenda_as_main_start_at).format(DATE_TIME_FORMAT.DATE_SPACE)}</span>}
                  </div>
                }
              >
                <div className={styles.mainAgendaStatus}>{agenda_as_main_type || 'N/A'}</div>
              </Tooltip>
            )}
          </div>
        </div>

        <div className={`col-8 ${styles.right}`}>
          <div className="row no-gutters justify-content-between align-items-stretch">
            <div className={styles.name}>
              <Link to={`/profile/${item?.staff_id}`} target="_blank" className={styles.recommendation__name} draggable="false">
                {item?.birth_name} &#160;
                {!item?.isUnsearchable && !hideOtherTalentLabel(item.assignment_status_type, item.role_level, item.ct) && handleShowCT(item.ct)}
                {!item?.isUnsearchable ? (item?.matching_percentage ? `${item?.matching_percentage}%` : '') : ''}
              </Link>
            </div>
          </div>

          <div className="mb-2">
            <Tooltip
              placement="top"
              title={infoPosition(item.position_name, item.department_name, item.division, item.opu, item.business_unit)}
              color={'black'}
              key={'black'}
              overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
            >
              <span>Position: </span>
              <span>{item?.position_name}</span>
            </Tooltip>
          </div>

          <div className="mb-2">
            {!item?.isUnsearchable && (
              <div style={{ marginBottom: '5px' }}>
                <span>YIP: </span>
                {handleShowYIPAndYISG(item?.year_in_position, item?.date_in_position)}
                &#160;&#160;&#160;
                <span>YISG: </span>
                {item?.salary_grade} ({handleShowYIPAndYISG(item?.year_in_sg, item?.date_in_sg)})
              </div>
            )}
          </div>

          <div className="mb-2">
            <span>Age: </span>
            <span data-testid="age-value">{item?.age}</span>
            &#160;&#160;&#160;
            {!item?.isUnsearchable && (
              <>
                <span>Retirement: </span>
                <span data-testid="retirement-date-value">
                  {handleShowRetirementDate(item.retirement_date)}
                  {handleShowRetirementForSP(item)}
                </span>
              </>
            )}
          </div>
          {!item?.isUnsearchable && (
            <div className="mb-2">
              <span>Performance Rating: </span>
              <span data-testid="rating-value">{item?.performance_rating}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CardBoxPrintSP.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

const BoxPrintSP = (props) => {
  const { text, line, blockStyle } = props;
  const containerRef = useRef(null);
  const chunkLine = line.length ? chunk(line, 3) : [];

  return (
    <>
      <div style={{ breakInside: 'auto' }} />
      <div className={`${styles.container}`}>
        <table className={`${styles.collapse} ${styles.printTable}`} data-testid="box-line">
          <thead>
            <tr>
              <th colSpan={3}>
                <div className={styles.collapseHeader} style={{ backgroundColor: blockStyle.headerColor }}>
                  <div data-testid="name-box-line" className={styles.left}>
                    {text}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody data-testid="view-collapse-body" className={styles.collapseBody} ref={containerRef}>
            {!isEmpty(line) ? (
              <>
                {chunkLine.map((items, idx) => {
                  return (
                    <tr key={idx}>
                      {items.map((item, index) => (
                        <td key={index}>
                          <CardBoxPrintSP item={item} index={index + 3 * idx} blockStyle={blockStyle} />
                        </td>
                      ))}
                      {items.length === 1 && (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}
                      {items.length === 2 && <td></td>}
                    </tr>
                  );
                })}
              </>
            ) : (
              <div className="w-100" style={{ textAlign: 'center' }}>
                No data
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

BoxPrintSP.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  line: PropTypes.array,
  numRows1: PropTypes.number,
  numRows2: PropTypes.number,
};

export default BoxPrintSP;
