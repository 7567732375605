const { createSlice } = require('@reduxjs/toolkit');

const afterLoginSlice = createSlice({
  name: 'afterLogin',
  initialState: { isAfterLogin: false },
  reducers: {
    updateIsAfterLogin: (state, action) => {
      state.isAfterLogin = action.payload;
    },
  },
});

const { reducer, actions } = afterLoginSlice;
export const { updateIsAfterLogin } = actions;
export default reducer;
