import React from 'react';
import styled, { css } from 'styled-components';
import { font } from '../../assets/common';
import { uniqueId } from 'lodash';
import { info_circle } from '../../assets/img';
import { Spin, Tooltip } from 'antd';
import { returnBgdColorByScoreVal } from '../../utils/helper';

const EdgeWrapper1 = styled.div`
  font-family: ${font.inter};
  display: grid;
  grid-template-columns: [col-1] 140px [col-2] 78px [col-3] 238px [col-4] 74px [col-5] 119px [col-6] 143px;
  grid-template-rows: repeat(21, auto);
  background-color: #ffffff;
  grid-gap: 1px;
`;

const Item = styled.div`
  outline: 1px solid #d3dee8;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props?.color ? props?.color : '#181818')};
  padding: 10px;
  ${(props) => {
    if (props?.from_row && props?.to_row && props?.from_col && props?.to_col) {
      return css`
        grid-column: ${(props) => props?.from_col} / ${(props) => props?.to_col};
        grid-row: ${(props) => props?.from_row} / ${(props) => props?.to_row};
      `;
    }
    if (props?.from_col && props?.to_col) {
      return css`
        grid-column: ${(props) => props?.from_col} / ${(props) => props?.to_col};
      `;
    }
    if (props?.from_row && props?.to_row && props?.col) {
      return css`
        grid-column: ${(props) => props?.col};
        grid-row: ${(props) => props?.from_row} / ${(props) => props?.to_row};
      `;
    }
  }}
`;

const Factor = styled.div`
  font-weight: 700;
`;
const SubFactor = styled.div`
  font-weight: 400;
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  outline: 1px solid #d3dee8;
  color: #8896a7;
  ${(props) => {
    if (props?.from_col && props?.to_col) {
      return css`
        grid-column: ${(props) => props?.from_col} / ${(props) => props?.to_col};
        grid-row: 1;
      `;
    }
  }}
`;

const Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Legend = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${(props) => props?.color};
`;

const TooltipCss = styled.div`
  color: 'var(--base-white, #FFF)';
  font-size: 12px;
  font-weight: ${(props) => props?.fontWeight};
  line-height: 18px;
  font-family: ${font.inter};
`;

export const ExternalAssessmentTraits = (props) => {
  const { assessmentData, loading } = props;

  const agreeableness = assessmentData?.data?.superFactors?.find(
    (f) => f.superFactor.split('-')[0] === 'Agreeableness'
  );
  const agility = assessmentData?.data?.superFactors?.find(
    (f) => f.superFactor.split('-')[0] === 'Agility'
  );
  const positivity = assessmentData?.data?.superFactors?.find(
    (f) => f.superFactor.split('-')[0] === 'Positivity'
  );
  const presence = assessmentData?.data?.superFactors?.find(
    (f) => f.superFactor.split('-')[0] === 'Presence'
  );
  const striving = assessmentData?.data?.superFactors?.find(
    (f) => f.superFactor.split('-')[0] === 'Striving'
  );
  const data = {
    number_of_traits_met: assessmentData?.data?.numberOfTraitsMet ?? '-',
    superFactors: [
      {
        super_factor: 'Agreeableness',
        sub_super_factor: 'Considerable, collaborative, inclusive',
        css: { from_row: 2, to_row: 6, from_col: 1, to_col: 3 },
        score: agreeableness?.score ?? '-',
        categories: [
          {
            label: 'Affiliation',
            score: agreeableness?.categories?.find((f) => f.name === 'Affiliation')?.score ?? '-',
            fit_potential:
              agreeableness?.categories?.find((f) => f.name === 'Affiliation')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Affiliation')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content || '-',
              },
              {
                header: 'High Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Affiliation')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content || '-',
              },
              {
                header: 'Low Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Affiliation')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content || '-',
              },
            ],
          },
          {
            label: 'Humility',
            score: agreeableness?.categories?.find((f) => f.name === 'Humility')?.score ?? '-',
            fit_potential:
              agreeableness?.categories?.find((f) => f.name === 'Humility')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Humility')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Humility')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Humility')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Openness to Differences',
            score:
              agreeableness?.categories?.find((f) => f.name === 'Openness to Differences')?.score ??
              '-',
            fit_potential:
              agreeableness?.categories?.find((f) => f.name === 'Openness to Differences')
                ?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Openness to Differences')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Openness to Differences')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Openness to Differences')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Trust',
            score: agreeableness?.categories?.find((f) => f.name === 'Trust')?.score ?? '-',
            fit_potential:
              agreeableness?.categories?.find((f) => f.name === 'Trust')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Trust')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Trust')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agreeableness?.categories
                    ?.find((f) => f.name === 'Trust')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
        ],
      },
      {
        super_factor: 'Agility',
        sub_super_factor: 'Analytical, flexible, exploratory',
        css: { from_row: 6, to_row: 11, from_col: 1, to_col: 3 },
        score: agility?.score ?? '-',
        categories: [
          {
            label: 'Adaptability',
            score: agility?.categories?.find((f) => f.name === 'Adaptability')?.score ?? '-',
            fit_potential:
              agility?.categories?.find((f) => f.name === 'Adaptability')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Adaptability')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Adaptability')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Adaptability')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Curiosity',
            score: agility?.categories?.find((f) => f.name === 'Curiosity')?.score ?? '-',
            fit_potential:
              agility?.categories?.find((f) => f.name === 'Curiosity')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Curiosity')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Curiosity')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Curiosity')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Focus',
            score: agility?.categories?.find((f) => f.name === 'Focus')?.score ?? '-',
            fit_potential:
              agility?.categories?.find((f) => f.name === 'Focus')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Focus')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Focus')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Focus')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Risk-Taking',
            score: agility?.categories?.find((f) => f.name === 'Risk-Taking')?.score ?? '-',
            fit_potential:
              agility?.categories?.find((f) => f.name === 'Risk-Taking')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Risk-Taking')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Risk-Taking')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Risk-Taking')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Tolerance of Ambiguity',
            score:
              agility?.categories?.find((f) => f.name === 'Tolerance of Ambiguity')?.score ?? '-',
            fit_potential:
              agility?.categories?.find((f) => f.name === 'Tolerance of Ambiguity')?.fitPotential ??
              '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Tolerance of Ambiguity')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Tolerance of Ambiguity')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  agility?.categories
                    ?.find((f) => f.name === 'Tolerance of Ambiguity')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
        ],
      },
      {
        super_factor: 'Positivity',
        sub_super_factor: 'Well-adjusted, aware,\xa0\xa0\xa0\xa0\xa0\xa0\xa0 good-natured',
        css: { from_row: 11, to_row: 14, from_col: 1, to_col: 3 },
        score: positivity?.score ?? '-',
        categories: [
          {
            label: 'Composure',
            score: positivity?.categories?.find((f) => f.name === 'Composure')?.score ?? '-',
            fit_potential:
              positivity?.categories?.find((f) => f.name === 'Composure')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Composure')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Composure')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Composure')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Optimism',
            score: positivity?.categories?.find((f) => f.name === 'Optimism')?.score ?? '-',
            fit_potential:
              positivity?.categories?.find((f) => f.name === 'Optimism')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Optimism')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Optimism')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Optimism')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Situational Self-Awareness',
            score:
              positivity?.categories?.find((f) => f.name === 'Situational Self-Awareness')?.score ??
              '-',
            fit_potential:
              positivity?.categories?.find((f) => f.name === 'Situational Self-Awareness')
                ?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Situational Self-Awareness')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Situational Self-Awareness')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  positivity?.categories
                    ?.find((f) => f.name === 'Situational Self-Awareness')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
        ],
      },
      {
        super_factor: 'Presence',
        sub_super_factor: 'Sociable, persuasive, commanding, poised',
        css: { from_row: 14, to_row: 18, from_col: 1, to_col: 3 },
        score: presence?.score ?? '-',
        categories: [
          {
            label: 'Assertiveness',
            score: presence?.categories?.find((f) => f.name === 'Assertiveness')?.score ?? '-',
            fit_potential:
              presence?.categories?.find((f) => f.name === 'Assertiveness')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Assertiveness')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Assertiveness')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Assertiveness')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Empathy',
            score: presence?.categories?.find((f) => f.name === 'Empathy')?.score ?? '-',
            fit_potential:
              presence?.categories?.find((f) => f.name === 'Empathy')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Empathy')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Empathy')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Empathy')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Influence',
            score: presence?.categories?.find((f) => f.name === 'Influence')?.score ?? '-',
            fit_potential:
              presence?.categories?.find((f) => f.name === 'Influence')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Influence')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Influence')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Influence')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Sociability',
            score: presence?.categories?.find((f) => f.name === 'Sociability')?.score ?? '-',
            fit_potential:
              presence?.categories?.find((f) => f.name === 'Sociability')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Sociability')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Sociability')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  presence?.categories
                    ?.find((f) => f.name === 'Sociability')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
        ],
      },
      {
        super_factor: 'Striving',
        sub_super_factor: 'Driven, reliable, persistent',
        css: { from_row: 18, to_row: 22, from_col: 1, to_col: 3 },
        score: striving?.score ?? '-',
        categories: [
          {
            label: 'Confidence',
            score: striving?.categories?.find((f) => f.name === 'Confidence')?.score ?? '-',
            fit_potential:
              striving?.categories?.find((f) => f.name === 'Confidence')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Confidence')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Confidence')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Confidence')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Credibility',
            score: striving?.categories?.find((f) => f.name === 'Credibility')?.score ?? '-',
            fit_potential:
              striving?.categories?.find((f) => f.name === 'Credibility')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Credibility')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Credibility')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Credibility')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Need for Achievement',
            score:
              striving?.categories?.find((f) => f.name === 'Need for Achievement')?.score ?? '-',
            fit_potential:
              striving?.categories?.find((f) => f.name === 'Need for Achievement')?.fitPotential ??
              '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Need for Achievement')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Need for Achievement')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Need for Achievement')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
          {
            label: 'Persistence',
            score: striving?.categories?.find((f) => f.name === 'Persistence')?.score ?? '-',
            fit_potential:
              striving?.categories?.find((f) => f.name === 'Persistence')?.fitPotential ?? '-',
            popup_content: [
              {
                header: 'Definition',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Persistence')
                    ?.popupContent?.find((p) => p.header === 'Definition')?.content ?? '-',
              },
              {
                header: 'High Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Persistence')
                    ?.popupContent?.find((p) => p.header === 'High Score')?.content ?? '-',
              },
              {
                header: 'Low Score',
                content:
                  striving?.categories
                    ?.find((f) => f.name === 'Persistence')
                    ?.popupContent?.find((p) => p.header === 'Low Score')?.content ?? '-',
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <Spin spinning={loading} size="small">
      <div>
        <EdgeWrapper1>
          <Header from_col={1} to_col={3}>
            SuperFactors
          </Header>
          <Header from_col={3} to_col={5}>
            <Flex>
              <div className="col-7 p-0"> Personality Profile</div>
              <div>Score</div>
            </Flex>
          </Header>
          <Header>Potential</Header>
          <Header>No. of Potential met</Header>
          {data.superFactors.map((item) => (
            <React.Fragment key={uniqueId()}>
              <Item
                from_row={item.css.from_row}
                to_row={item.css.to_row}
                from_col={item.css.from_col}
                to_col={item.css.to_col}
              >
                <Flex>
                  <div
                    className="d-flex justify-content-center align-items-start "
                    style={{ flexDirection: 'column', width: '55%' }}
                  >
                    <Factor>{item.super_factor}</Factor>
                    <SubFactor>{item.sub_super_factor}</SubFactor>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ gap: '10px', color: '#181818' }}
                  >
                    {item.score === '-' ? (
                      '-'
                    ) : (
                      <>
                        {item.score} <Legend color={returnBgdColorByScoreVal(item)} />
                      </>
                    )}
                  </div>
                </Flex>
              </Item>
              {item.categories.map((per) => (
                <React.Fragment key={uniqueId()}>
                  <Item color="#3D3D3D" from_col={3} to_col={5}>
                    <Flex>
                      <div>
                        {per.label}
                        <Tooltip
                          placement="top"
                          title={
                            <>
                              {per.popup_content.map((popup) => (
                                <div key={uniqueId()} className="mt-1 mb-3">
                                  <TooltipCss fontWeight={600}>{popup.header}</TooltipCss>
                                  <TooltipCss fontWeight={400}>{popup.content}</TooltipCss>
                                </div>
                              ))}
                            </>
                          }
                          overlayClassName="custom-tooltip"
                          {...{
                            overlayInnerStyle: {
                              width: '400px',
                              padding: '10px',
                              borderRadius: '4px',
                              backgroundColor: '#000',
                            },
                          }}
                        >
                          <img
                            src={info_circle}
                            alt="info"
                            style={{ marginLeft: '6px', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ gap: '10px', color: '#181818' }}
                      >
                        {per.score === '-' ? (
                          '-'
                        ) : (
                          <>
                            {per.score} <Legend color={returnBgdColorByScoreVal(per)} />
                          </>
                        )}
                      </div>
                    </Flex>
                  </Item>
                  <Item>{per.fit_potential}</Item>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <Item from_row={2} to_row={22} col={6}>
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              {data.number_of_traits_met}
            </div>
          </Item>
        </EdgeWrapper1>
      </div>
    </Spin>
  );
};

export default ExternalAssessmentTraits;
