import React from 'react';
import { Collapse } from 'antd';
import { collapse_chevron } from '../../../../assets/img';

const CustomCollapseIcon = ({ isActive }) => {
  return (
    <img
      src={collapse_chevron}
      alt="collapse"
      style={{
        transform: isActive ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
        transitionDuration: '0.3s',
      }}
    />
  );
};

const TalentReviewCollapse = (props) => {
  return (
    <Collapse
      defaultActiveKey={props?.isCollapsed ? 0 : 1}
      collapsible="icon"
      ghost
      expandIconPosition="end"
      expandIcon={CustomCollapseIcon}
      style={{
        backgroundColor: '#F4F5F8',
        borderRadius: 0,
        padding: '0px 4px',
        marginTop: '16px',
      }}
    >
      <Collapse.Panel header={props.header} key="1">
        {props.children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default TalentReviewCollapse;
