import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BasicPopover, BasicTable } from '../../assets/common';
import { close_modal_2 } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import { paginationConfig } from '../../utils/helper';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './career-aspiration.module.scss';
import { END_GROUP_ROW_CLASS_NAME } from '../../utils/constants';

const Wrapper = styled.div`
  .ant-table-container table tbody td:first-child {
    border-bottom-left-radius: 0px !important;
  }
  .ant-table-container .ant-table-tbody td:last-child {
    border-bottom-right-radius: 0px !important;
  }
`;
const modalStyle = {
  borderRadius: 8,
  closePosition: [32, 26],
  containerPaddingLeftRight: 32,
  containerPaddingTopBottom: 26,
  headerPaddingBottom: 12,
  headerBorderBottom: 'none',
  titleFontSize: 20,
  titleFontWeight: 700,
  titleColor: '#3F3C4C',
};
const tableStyle = {
  borderRadius: 8,
  headerWeight: 500,
  headerColor: '#3F3C4C',
};
const tablePositionStyle = {
  headerWeight: 500,
  headerBackground: '#F2F4F8',
  headerColor: '#181818',
};
const LIMIT_ROWS = 10;

const ListPositionTable = React.memo(({ data, setViewPositionsIndex }) => {
  const columns = [
    {
      title: 'Position Code',
      width: '30%',
      dataIndex: 'position_code',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
    {
      title: 'Position Name',
      width: '70%',
      dataIndex: 'position_name',
      render: (text) => <span className={styles.text}>{text}</span>,
    },
  ];

  return (
    <div className={styles.positionTable}>
      <img
        src={close_modal_2}
        alt="close_view_list_position"
        className={styles.close}
        onKeyDown={() => {}}
        onClick={() => setViewPositionsIndex(-1)}
      />
      <Wrapper>
        <BasicTable
          rowKey={(record) => `${record.position_code}${record.position_name}`}
          dataSource={data}
          columns={columns}
          pagination={false}
          styles={tablePositionStyle}
          scroll={{ y: 275 }}
        />
      </Wrapper>
    </div>
  );
});

ListPositionTable.propTypes = {
  data: PropTypes.array,
  setViewPositionsIndex: PropTypes.func,
};

const CareerAspirationModalTable = ({ openModal, setOpenModal }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [aspirationMatching, setAspirationMatching] = useState({ total: 0, resAspMatching: [] });
  const [page, setPage] = useState(1);
  const [viewPositionsIndex, setViewPositionsIndex] = useState(-1);

  const getTableData = useCallback(async () => {
    try {
      setLoading(true);
      const params = { page, limit: LIMIT_ROWS };
      const res = await talentProfileApi.getAspirationMatching(id, params);
      let result = res?.data?.result;
      if (res.status === 200 && result) {
        setAspirationMatching({
          total: result.total,
          resAspMatching: !isEmpty(result?.resAspMatching)
            ? result.resAspMatching.map((item, index, array) => {
                if (
                  index === array.length - 1 ||
                  (item.is_updated && !array?.[index + 1]?.is_updated)
                ) {
                  return { ...item, no: index, endGroup: true };
                }
                return { ...item, no: index, endGroup: false };
              })
            : [],
        });
      }
    } catch (error) {
      console.error(error);
      setAspirationMatching({ total: 0, resAspMatching: [] });
    } finally {
      setLoading(false);
    }
  }, [id, page]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Job Function',
        width: '40%',
        dataIndex: 'list_of_position',
        render: (data, record, index) => (
          <div className={styles.groupCol}>
            <div className='d-flex align-items-center'>
              <span className={record.is_updated ? styles.admin : styles.modalOutput} />
              <span className={styles.text}>{record.job_function}</span>
            </div>
            {!record.is_updated && (
              <BasicPopover
                content={
                  <ListPositionTable data={data} setViewPositionsIndex={setViewPositionsIndex} />
                }
                trigger="click"
                placement="bottomLeft"
                getPopupContainer={(trigger) => trigger}
                open={index === viewPositionsIndex}
                onOpenChange={(open) => {
                  if (!open) {
                    setViewPositionsIndex(-1);
                  }
                }}
                styles={{
                  w: 630,
                }}
              >
                <button
                  className={`${styles.textListPosition} ${
                    isEmpty(data) || (!data[0].position_code && !data[0].position_name)
                      ? styles.disable
                      : ''
                  }`}
                  onKeyDown={() => {}}
                  onClick={() => setViewPositionsIndex(index)}
                >
                  View list of position
                </button>
              </BasicPopover>
            )}
          </div>
        ),
      },
      {
        title: 'Source',
        width: '10%',
        dataIndex: 'source',
        render: (text) => <span className={styles.textInfo}>{text}</span>,
      },
      {
        title: 'Confidence Score',
        width: '10%',
        dataIndex: 'confidence_score',
        render: (text) => <span className={styles.textInfo}>{text}</span>,
      },
      {
        title: 'Aspiration Statement',
        dataIndex: 'aspiration_statement',
        render: (text, record) => (
          <BasicPopover
            content={`Talent Statement: ${record.talent_statement}`}
            trigger="hover"
            placement="bottom"
            getPopupContainer={(trigger) => trigger}
            styles={{
              hideArrow: false,
              px: 12,
              py: 12,
              w: 700,
              bg: '#181818',
              color: '#fff',
            }}
          >
            <span className={styles.textInfo}>{text}</span>
          </BasicPopover>
        ),
      },
    ];
  }, [viewPositionsIndex]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setPage(pageNum);
    };
    return paginationConfig(aspirationMatching.total, page, onChange, LIMIT_ROWS);
  }, [aspirationMatching.total, page]);

  useEffect(() => {
    if (openModal) {
      getTableData();
    }
  }, [getTableData, openModal]);

  const onCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <CustomAntModal
      hideDefaultBtn
      styles={modalStyle}
      open={openModal}
      setOpen={(value) => {
        setOpenModal(value);
        setPage(1);
      }}
      title="View Aspiration Matching"
      width={1500}
      closeType={1}
      onCancelModal={onCancelModal}
    >
      <div className={styles.jobFuncDesc}>
        <div>
          <div className={styles.modalOutput} />
          Modal Output
        </div>
        <div>
          <div className={styles.admin} />
          Updated by Admin
        </div>
      </div>
      <Spin spinning={loading}>
        <div className={styles.table}>
          <BasicTable
            groupRow
            rowKey="no"
            styles={tableStyle}
            dataSource={aspirationMatching.resAspMatching}
            columns={columns}
            pagination={pagination}
            rowClassName={(record) => {
              return record.endGroup ? END_GROUP_ROW_CLASS_NAME : '';
            }}
          />
        </div>
      </Spin>
    </CustomAntModal>
  );
};

CareerAspirationModalTable.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default CareerAspirationModalTable;
