import { combineReducers } from '@reduxjs/toolkit';
import rolesReducer from './RoleManagement/RoleList/rolesSlice';
import manageCriteriaReducer from './SPManagement/redux/manageCriteriaSlice';
import manageCriteriaSetMatchingReducer from './SPManagement/redux/manageCriteriaSetMatchingSlice';

const reducer = combineReducers({
  roleManagement: rolesReducer,
  manageCriteria: manageCriteriaReducer,
  manageCriteriaSetMatching: manageCriteriaSetMatchingReducer,
});

export default reducer;
