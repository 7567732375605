import { BUSINESS_ACCESS } from './constants';

export const filterDemongraphic = ({ selectedCompany, selectedBU, selectedTopTalent }) => {
  let paramItem;
  if (selectedBU?.value && selectedCompany?.value) {
    paramItem = {
      talentFilter: selectedTopTalent.value,
      companyFilterType:
        selectedBU.type === BUSINESS_ACCESS.JOB_FAMILY
          ? BUSINESS_ACCESS.JOB_FAMILY
          : selectedCompany.value,
      companyFilterValue: selectedBU.value,
      companyFilterLabel: selectedCompany.label,
      companyFilterValueLabel: selectedBU.label,
    };
  } else {
    paramItem = {
      talentFilter: selectedTopTalent.value,
    };
  }
  return paramItem;
};
