import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AiButton } from '../../../assets/common';
import { pets_chevron_right } from '../../../assets/img';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import {
  getPositionByCode,
  getSuccessionPlanningViewDetail,
} from '../../../services/successionplanningratio';
import { SP_NOTES_PERFORMANCE_RATING } from '../../../utils/constants';
import { PositionsDashboardSP, RecommendationsDSP } from '../../Agenda/components';
import PrintApprovedSp from '../components/PrintApprovedSP/PrintApprovedSp';
import styles from './dashboardSp.module.scss';
import { Title, TitleDiv } from './dashboardSpStyled.js';
import HistoricalApprovedSpPopout from './HistoricalApprovedSpPopout/HistoricalApprovedSpPopout';
import { meetingApi } from '../../../services/tcMeeting.js';

const DashboardSP = ({ match }) => {
  const { agendaId, positionCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataPosition, setDataPosition] = useState([]);
  const { roleId } = useSelector((state) => state.user.roleActive);
  const user = useSelector((state) => state.user);
  const [incumbents, setIncumbents] = useState([]);
  const [remarks, setRemarks] = useState('');
  const [openApprovedSp, setOpenApprovedSp] = useState(false);
  const history = useHistory();
  const printRef = useRef(null);

  const positionName = useMemo(() => {
    return incumbents
      .map((d) => {
        return d.position_name;
      })
      .join(' / ');
  }, [incumbents]);

  const dateApproved = useMemo(() => {
    if (!incumbents.length) return null;
    return incumbents[0].last_approved_date;
  }, [incumbents]);

  const getPositionByCodeAPI = async (code) => {
    const res = await getPositionByCode({ positionCode: code, roleUser: user });
    if (res.status === 200) {
      return res.data.result;
    }
  };

  const fetchPositionsSPDashboardAPI = async (code) => {
    const res = await getSuccessionPlanningViewDetail({
      position_codes: code,
      roleUser: user,
      check_group: 1,
    });
    if (res.status === 200) {
      return res.data.result;
    }
  };

  useEffect(() => {
    async function fetchAPI() {
      const code = match?.params?.positionCode;
      setLoading(true);
      Promise.all([getPositionByCodeAPI(code), fetchPositionsSPDashboardAPI(code)])
        .then((res) => {
          if (!res[0] && !res[1]) {
            return;
          }
          if (code === '2226942' || code === '2227900') {
            setDataPosition(res[0]);
            res[1].data[0].incumbent = {
              age: 45,
              contract_end_date: null,
              date_in_position: '2021/02/01',
              date_in_sg: '2021/08/01',
              date_of_birth: '1977-07-30T00:00:00.000Z',
              name: 'Aadrin Azly',
              retirement_date: '2032/07/30',
              salary_grade: 'G12',
              staff_id: '106165',
            };
            setIncumbents(res[1].data);
          } else {
            setDataPosition(res[0]);
            setIncumbents(res[1].data);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.code === 403) {
            history.push('/access-denied');
            return;
          }
          console.error(err);
          setDataPosition([]);
          setIncumbents([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (positionCode) {
      fetchAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCode, roleId]);

  const mapDataAgendaToPosition = (firstLine, secondLine, thirdLine) => {
    return [
      ...firstLine.map((item) => ({ ...item, successorType: '1st Line' })),
      ...secondLine.map((item) => ({ ...item, successorType: '2nd Line' })),
      ...thirdLine.map((item) => ({ ...item, successorType: '3rd Line' })),
    ];
  };

  const getHistoricalApproveSp = useCallback(async () => {
    if (!agendaId) return;
    try {
      setLoading(true);
      const res = await meetingApi.getAgendaDetail({ agendaId, roleId, isHistorical: true });
      if (res.status === 200) {
        const data = res.data.result;
        setIncumbents(data?.positions || []);
        setRemarks(data?.remarks || '');
        setDataPosition(
          mapDataAgendaToPosition(
            data?.first_line || [],
            data?.second_line || [],
            data?.third_line || []
          )
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [agendaId, roleId]);

  useEffect(() => {
    getHistoricalApproveSp();
  }, [getHistoricalApproveSp]);

  return (
    <div className={styles.maxWidth} data-testid="view-detail-sp-page">
      <div className={styles.control}>
        <div className={styles.breadcrumb}>
          <Link to="/">
            <p data-testid="link-1">Dashboard</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <Link to="/succession-planning">
            <p data-testid="link-2">Succession Planning Dashboard</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <p data-testid="link-active" className={styles.talent_profile}>
            Succession Planning Dashboard
          </p>
        </div>
      </div>

      <div className={styles.control}>
        <div data-testid="title-page" className={styles.title_page}>
          {positionName}
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center " style={{ gap: '10px' }}>
        <PrintApprovedSp
          componentRef={printRef}
          positionName={positionName}
          dateApproved={dateApproved}
        />
        {!agendaId && (
          <>
            {/* <TitleDiv onClick={() => setOpenApprovedSp(true)}>
              <Title>Historical Approved SP</Title>
            </TitleDiv> */}
            {openApprovedSp && (
              <HistoricalApprovedSpPopout
                openApprovedSp={openApprovedSp}
                setOpenApprovedSp={setOpenApprovedSp}
                positionCode={positionCode}
                roleId={roleId}
              />
            )}
          </>
        )}
      </div>

      <div className={styles.dashboardSP} ref={printRef}>
        <div data-testid="view-position-recommend">
          <PositionsDashboardSP incumbents={incumbents} loading={loading} remarks={remarks} />
          <Disclaimer
            dataContent={[{ id: 1, label: SP_NOTES_PERFORMANCE_RATING }]}
            customStyle={{ marginBottom: '30px' }}
          />
          <RecommendationsDSP positions={dataPosition} incumbents={incumbents} />
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ marginBottom: '20px' }}>
        {!agendaId && (
          <AiButton onClick={() => history.push('/succession-planning-page')}>Back</AiButton>
        )}
      </div>
    </div>
  );
};

DashboardSP.propTypes = {
  match: PropTypes.object,
};

export default DashboardSP;
