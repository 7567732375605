import { Spin, Tooltip } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import talentProfileApi from '../../services/talentProfiles';
import {
  CustomNumber,
  TableData,
  TableDiv,
  Text,
  Title,
  Wrapper,
} from './complementaryExperienceStyled';

export default function ComplementaryExperience(props) {
  const { openView, setOpenView, roleId, staffId } = props;
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    limit: 10,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Job Function',
      dataIndex: 'job_function',
      key: 'job_function',
      width: '25%',
    },
    {
      title: 'Confidence Score',
      dataIndex: 'confidence_score',
      key: 'confidence_score',
      width: '15%',
    },
    {
      title: 'Sub-Statement',
      dataIndex: 'sub_statement',
      key: 'sub_statement',
      width: '60%',
    },
  ];

  useEffect(() => {
    if (!roleId || !staffId) return;

    (async function getView() {
      setLoading(true);
      try {
        const res = await talentProfileApi.viewComplementaryExperience(
          staffId,
          { page: query.page, limit: query.limit },
          roleId
        );
        const data = get(res, 'data.result.matchingOutput') || [];
        const total = get(res, 'data.result.total') || 0;
        setData(data);
        setQuery((prev) => ({ ...prev, total }));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [roleId, query.page, staffId]);

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = query.page * query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <Text>
        Showing <CustomNumber>{fromResult}</CustomNumber> to{' '}
        <CustomNumber>{toResults > query.total ? query.total : toResults}</CustomNumber> of{' '}
        <CustomNumber>{query.total}</CustomNumber> results
      </Text>
    ),
  };

  return (
    <Wrapper
      title={<Title>View Complementary Experience</Title>}
      open={openView}
      onCancel={() => setOpenView(false)}
      width={1450}
      footer={null}
    >
      <Spin size="large" spinning={loading}>
        <TableDiv>
          <TableData
            dataSource={
              !isEmpty(data) &&
              data.map((item, _index) => ({
                job_function: <Text>{item.job_function}</Text>,
                confidence_score: (
                  <Text>
                    {item.confidence_score && Number.parseFloat(item.confidence_score).toFixed(2)}
                  </Text>
                ),
                sub_statement: (
                  <>
                    <Tooltip
                      title={item.gtky_text && <div>GTKY Text: {item.gtky_text}</div>}
                      {...{
                        overlayInnerStyle: {
                          width: '500px',
                          fontSize: '12px',
                          lineHeight: '18px',
                          fontWeight: 400,
                          fontFamily: '"Inter", sans-serif',
                          padding: '12px',
                          borderRadius: '4px',
                          backgroundColor: '#000',
                        },
                      }}
                    >
                      <Text>{item.sub_statement}</Text>
                    </Tooltip>
                  </>
                ),
              }))
            }
            columns={columns}
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
        </TableDiv>
      </Spin>
    </Wrapper>
  );
}
