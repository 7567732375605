import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './filter-pill.module.scss';
import { icon_after } from '../../assets/img';

const Pill = ({ label, handleRemove }) => {
  return (
    <div className={styles.pill}>
      <span>{label}</span>
      <img src={icon_after} alt="remove-pill" onKeyDown={() => {}} onClick={handleRemove} />
    </div>
  );
};

Pill.propTypes = {
  label: PropTypes.string,
  handleRemove: PropTypes.func,
};

export default Pill;
