import { Spin } from 'antd';
import React, { useMemo } from 'react';

import { GeneralTable } from '../ExternalAssessments/ExternalAssessmentStyled';
import styles from './general-external-assessments.module.scss';

const GeneralExternalAssessments = (props) => {
  const { assessmentData, loading } = props;

  const convertData = useMemo(() => {
    return assessmentData?.type === `GENERAL` ? assessmentData.data : [];
  }, [assessmentData]);

  const columns = [
    {
      title: 'General',
      dataIndex: 'mainCategory',
      key: 'mainCategory',
      width: '57%',
      render: (_, record) => {
        return <span>{record.mainCategory ?? '-'}</span>;
      },
    },
    {
      title: 'Details ',
      dataIndex: 'detail',
      key: 'detail',
      width: `43%`,
      render: (_, record) => {
        return record.detail ?? '-';
      },
    },
  ];

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.tableContent}>
        <Spin spinning={loading} size="small">
          <GeneralTable dataSource={convertData} columns={columns} pagination={false} />
        </Spin>
      </div>
    </div>
  );
};

export default GeneralExternalAssessments;
