import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { BasicTable, BasisSwitch } from '../../../../../assets/common';
import More3DotDropdown from '../../../../../components/More3DotDropdown/More3DotDropdown';
import { DATE_TIME_FORMAT } from '../../../../../utils/constants';
import { paginationConfig } from '../../../../../utils/helper';
import { POSITION_CATEGORY_TABLE_ROW_LIMIT } from '../../constants';
import * as styles from './managing-position-category-table.module.scss';

const ManagingPositionCategoryTable = ({
  data,
  searchParams,
  setSearchParams,
  selectedRecords,
  setSelectedRecords,
  setShowDeleteConfirm,
  setSingleDeleteRecord,
}) => {
  const history = useHistory();

  const handleClickDelete = (record) => {
    setShowDeleteConfirm(true);
    setSingleDeleteRecord(record.id);
  };

  const handleClickEdit = (record) => {
    history.push(`/admin/master-data-management/managing-position-category/${record.id}`);
  };

  const columns = [
    {
      title: 'No.',
      width: 100,
      dataIndex: 'no',
      align: 'center',
      render: (text) => <span className={styles.textField}>{text}</span>,
    },
    {
      title: 'Category',
      width: 200,
      dataIndex: 'category',
      render: (text) => <span className={styles.category}>{text}</span>,
    },
    {
      title: 'Abbreviations',
      width: 150,
      dataIndex: 'abbreviation',
      render: (text) => <span className={styles.category}>{text}</span>,
    },
    {
      title: 'Last Updated by',
      width: 150,
      dataIndex: 'updated_by',
      align: 'center',
      render: (text) => <span className={styles.textField}>{text}</span>,
    },
    {
      title: 'Updated Date & Time',
      width: 150,
      dataIndex: 'updated_at',
      align: 'left',
      render: (text) => (
        <span className={styles.textField}>
          {moment(text).format(DATE_TIME_FORMAT.DATE_SLASH)}
          <br />
          {moment(text).format(DATE_TIME_FORMAT.TIME)}
        </span>
      ),
    },
    {
      title: 'Show in Dashboard',
      width: 150,
      dataIndex: 'updated_at',
      align: 'left',
      render: (_, record) => (
        <BasisSwitch checked={record.is_dashboard} disabled={true} opacity={1} />
      ),
    },
    {
      title: '',
      width: 100,
      align: 'right',
      render: (_, record) => (
        <div className="mr-4">
          <More3DotDropdown
            item={record}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
          />
        </div>
      ),
    },
  ];

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(
      data.total,
      searchParams.page,
      onChange,
      POSITION_CATEGORY_TABLE_ROW_LIMIT
    );
  }, [data.total, searchParams.page, setSearchParams]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedRecords(selectedRows.map((item) => item.id));
    },
    selectedRowKeys: selectedRecords,
  };

  return (
    <div className="mt-4">
      <BasicTable
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={data.position_categories}
        columns={columns}
        pagination={pagination}
        styles={{ selectPadding: '0' }}
      />
    </div>
  );
};

export default ManagingPositionCategoryTable;
