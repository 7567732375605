import React, { useCallback } from 'react';
import Select from 'react-select';
import { makeSelectStyle } from './styled';

export default React.memo(
  ({ field: { value, name }, form: { setFieldValue, getFieldMeta }, options, ...props }) => {
    const handleChange = useCallback(
      (option) => {
        setFieldValue(name, option);
      },
      [setFieldValue, name]
    );
    const meta = getFieldMeta(name);
    return (
      <Select
        {...props}
        value={value}
        onChange={handleChange}
        options={options}
        isClearable={false}
        isSearchable={false}
        styles={makeSelectStyle({ meta })}
        components={{
          IndicatorSeparator: () => <></>,
        }}
      />
    );
  }
);
