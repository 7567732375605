import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { OptionNormal, OptionchartReAndNa } from '../../share';
import './nationalityChart.scss';
const NationalityChart = ({ data, categories }) => {
  const [series, setSeries] = useState([]);
  let option = {
    ...OptionchartReAndNa,
    colors: ['#9F79B7', '#FECE66'],
    plotOptions: {
      bar: {
        columnWidth: '35%',
        ...OptionNormal.barReandNa,
      },
    },
    xaxis: {
      categories: categories,
      labels: OptionNormal.labels,
    },
    legend: {
      ...OptionNormal.legendSPandBU,
      colors: ['#444444'],
      markers: {
        width: 18,
        height: 18,
        radius: 5,
        fillColors: ['#9F79B7', '#FECE66'],
        offsetX: -5,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 120,
        vertical: 40,
      },
    },
  };
  const [options] = React.useState(option);

  useEffect(() => {
    setSeries(data);
  }, [data]);

  return (
    <div id="nationalChart">
      <div>
        {' '}
        <Chart options={options} series={series} type="bar" height={386} width={1201} />
      </div>
    </div>
  );
};
export default NationalityChart;
