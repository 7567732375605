import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TimeField from 'react-simple-timefield';

import { Warning } from '../../components';
import { SelectMultipleSearch } from '../../components/SelectMultiple/SelectMultiple';
import userApi from '../../services/userApi';
import { saveSuccessionPlanningSession } from '../../store/spTemplateSlice';
import { USER_ID } from '../../utils/constants';
import styles from './details.module.scss';

const SuccessionPlanningDetails = (props) => {
  const { isValid, viewVersion } = props;
  const dispatch = useDispatch();
  const { agendaId } = useParams();
  const agendaDetail = useSelector((state) => state.spTemplate.agendaDetail);

  const [successionPlanningDetails, setSuccessionPlanningDetails] = useState({
    startTime: moment().format('HH:mm'),
    endTime: moment().format('HH:mm'),
    name: '',
  });

  useEffect(() => {
    if (!agendaId || !agendaDetail.agenda_id) return;
    setSuccessionPlanningDetails({
      name: agendaDetail.agenda_name,
      startTime: moment(agendaDetail.start_time).format('HH:mm'),
      endTime: moment(agendaDetail.end_time).format('HH:mm'),
      advocators: agendaDetail.advocators.map((item) => {
        return { label: item.name, value: item.id };
      }),
      cc: agendaDetail.ccList.map((item) => {
        return { label: item.name, value: item.id };
      }),
    });
  }, [agendaId, agendaDetail]);

  useEffect(() => {
    dispatch(
      saveSuccessionPlanningSession({
        succession_planning_name: successionPlanningDetails.name,
        start_time: successionPlanningDetails.startTime,
        end_time: successionPlanningDetails.endTime,
        advocator_ids:
          successionPlanningDetails.advocators &&
          successionPlanningDetails.advocators.length > 0 &&
          successionPlanningDetails.advocators.map((item) => {
            return item.value;
          }),
        cc_ids:
          successionPlanningDetails.cc &&
          successionPlanningDetails.cc.length > 0 &&
          successionPlanningDetails.cc.map((item) => {
            return item.value;
          }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successionPlanningDetails]);

  const roleActive = useSelector((state) => state.user.roleActive);

  const onChangeInputData = (value, key) => {
    successionPlanningDetails[key] = value;
    const newData = { successionPlanningDetails, ...successionPlanningDetails };
    setSuccessionPlanningDetails(newData);
  };

  const onChangeSelectData = (event, key) => {
    successionPlanningDetails[key] = event && event.length > 0 ? event.map((e) => e) : [];
    const newData = { successionPlanningDetails, ...successionPlanningDetails };
    setSuccessionPlanningDetails(newData);
  };

  const isValidStartEndTime = (startTime, endTime) => {
    const result = false;
    const startTimeMoment = moment(startTime, 'HH:mm');
    const endTimeMoment = moment(endTime, 'HH:mm');
    if (startTimeMoment.isAfter(endTimeMoment)) return result;
    return !result;
  };

  const getUserOptions = async (inputValue) => {
    const params = { keyword: inputValue, limit: 5 };
    let response = await userApi.getUsers(roleActive.roleId, params);
    if (!isEmpty(response) && response.data && response.data.code === 200) {
      const rs = !isEmpty(response.data.result) && response.data.result.data;
      const data =
        rs && rs.length > 0
          ? rs.map((user) => {
              return { label: user.name, value: user.id };
            })
          : [];
      return data;
    }
  };

  const getCustomStyles = (isValid) => {
    const customStyles = {
      control: (provided) => ({
        ...provided,
        minHeight: 44,
        borderColor: (isValid && '#d56565') || 'hsl(0, 0%, 80%)',
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '2px 8px 2px 16px',
        gap: '8px',
      }),
      multiValue: (provided) => ({
        ...provided,
        flexDirection: 'row-reverse',
        background: '#FFFFFF',
        border: '1px solid #BDBDBD',
        borderRadius: '11px',
        margin: 0,
        padding: '3px 8px 3px 3px',
        alignItems: 'center',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '17px',
        color: '#8d8d8d',
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        padding: 0,
        paddingLeft: 0,
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        '&:hover': {
          backgroundColor: 'unset',
          color: '#535D70',
        },
      }),
    };

    return customStyles;
  };

  return (
    <div data-testid="view-sp-detail">
      <div className="d-flex justify-content-between align-items-center">
        <div className={styles.title} data-testid="view-title-sp-details">
          Succession Planning Session Details
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div className="row">
          <div className="col-9">
            <div className={styles.title_success_planning}>
              Succession Planning Name<span style={{ color: 'red' }}>*</span>
            </div>
            <input
              type="text"
              data-testid="input-sp-name"
              className={`${styles.input} ${
                !isValid && !successionPlanningDetails.name && styles.input_error
              }`}
              value={successionPlanningDetails.name}
              placeholder="Board Management Template"
              onChange={(event) => onChangeInputData(event.target.value, 'name')}
              readOnly={viewVersion}
            />
            {!isValid && !successionPlanningDetails.name && (
              <Warning data-testid="error-sp-name">Succession Planning Name is required</Warning>
            )}
          </div>

          <div className="col-3">
            <div className="row">
              <div className="col-6">
                <div className={styles.title_success_planning}>
                  Start Time<span style={{ color: 'red' }}>*</span>
                </div>
                <TimeField
                  readOnly={viewVersion}
                  value={successionPlanningDetails.startTime}
                  style={{ width: '100%' }}
                  className={`${styles.input} ${styles.time_input} ${
                    !isValid &&
                    (!successionPlanningDetails.startTime ||
                      !isValidStartEndTime(
                        successionPlanningDetails.startTime,
                        successionPlanningDetails.endTime
                      )) &&
                    styles.input_error
                  }`}
                  data-testid="input-start-time"
                  onChange={(event, value) => onChangeInputData(value, 'startTime')}
                />
                {!isValid && !successionPlanningDetails.startTime && (
                  <Warning data-testid="error-start-time">Start Time is required</Warning>
                )}
              </div>

              <div className="col-6">
                <div className={styles.title_success_planning}>
                  End Time<span style={{ color: 'red' }}>*</span>
                </div>
                <TimeField
                  readOnly={viewVersion}
                  value={successionPlanningDetails.endTime}
                  style={{ width: '100%' }}
                  className={`${styles.input} ${styles.time_input} ${
                    !isValid &&
                    (!successionPlanningDetails.endTime ||
                      !isValidStartEndTime(
                        successionPlanningDetails.startTime,
                        successionPlanningDetails.endTime
                      )) &&
                    styles.input_error
                  }`}
                  data-testid="input-end-time"
                  onChange={(event, value) => onChangeInputData(value, 'endTime')}
                />
                {!isValid && !successionPlanningDetails.endTime && (
                  <Warning data-testid="error-end-time">End Time is required</Warning>
                )}
              </div>
            </div>
            {!isValid &&
              !isValidStartEndTime(
                successionPlanningDetails.startTime,
                successionPlanningDetails.endTime
              ) && (
                <Warning data-testid="error-less-date">
                  Start Time is less than or equal to End Time
                </Warning>
              )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div className="row">
          <div className="col-12">
            <div className={styles.title_success_planning}>
              Advocators<span style={{ color: 'red' }}>*</span>
            </div>

            <SelectMultipleSearch
              isDisabled={viewVersion}
              value={
                successionPlanningDetails.advocators &&
                successionPlanningDetails.advocators.length > 0 &&
                successionPlanningDetails.advocators.map((item) => item)
              }
              styles={getCustomStyles(
                !isValid &&
                  (!successionPlanningDetails.advocators ||
                    successionPlanningDetails.advocators.length === 0)
              )}
              isMulti
              cacheOptions
              loadOptions={getUserOptions}
              placeholder="Advocators"
              data-testid="select-advocators"
              onChange={(e) => onChangeSelectData(e, 'advocators')}
            />

            {!isValid &&
              (!successionPlanningDetails.advocators ||
                successionPlanningDetails.advocators.length === 0) && (
                <Warning data-testid="error-advocators">Advocators is required</Warning>
              )}
          </div>
        </div>
      </div>
      {!viewVersion && (
        <>
          <div style={{ marginTop: '24px' }}>
            <div className="row">
              <div className="col-12">
                <div className={styles.title_success_planning}>
                  CC List<span style={{ color: 'red' }}>*</span>
                </div>

                <SelectMultipleSearch
                  value={
                    successionPlanningDetails.cc &&
                    successionPlanningDetails.cc.length > 0 &&
                    successionPlanningDetails.cc.map((item) => item)
                  }
                  styles={getCustomStyles(
                    !isValid &&
                      (!successionPlanningDetails.cc || successionPlanningDetails.cc.length === 0)
                  )}
                  isMulti
                  cacheOptions
                  loadOptions={getUserOptions}
                  placeholder="CC List"
                  onChange={(e) => onChangeSelectData(e, 'cc')}
                  data-testid="select-cc-list"
                />

                {!isValid &&
                  (!successionPlanningDetails.cc || successionPlanningDetails.cc.length === 0) && (
                    <Warning>CC List is required</Warning>
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(SuccessionPlanningDetails);
