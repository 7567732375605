import React from 'react';

import { isCheckError, onChangeInputData } from '../../../../../../../hooks/useFormik';
import { AiInput, AiLabel } from '../../../../../components/NewSuccessionPlanningForm/Styled';
import * as styles from './add-function-list-form.module.scss';

const AddFunctionListForm = (props) => {
  const { formik } = props;

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Add New Details </h4>
      <div className="row">
        <div className="col-4">
          <AiLabel>Job Family</AiLabel>
          <AiInput
            name="job_family"
            value={formik.values.job_family}
            status={isCheckError(formik, 'job_family') ? 'error' : ''}
            placeholder="Enter Job Family"
            onChange={(e) => onChangeInputData(formik, e.target.value, 'job_family')}
          />
        </div>
        <div className="col-3">
          <AiLabel>Sub Job Family</AiLabel>
          <AiInput
            name="sub_job_family"
            value={formik.values.sub_job_family}
            status={isCheckError(formik, 'sub_job_family') ? 'error' : ''}
            placeholder="Enter Sub Job Family"
            onChange={(e) => onChangeInputData(formik, e.target.value, 'sub_job_family')}
          />
        </div>
        <div className="col-3">
          <AiLabel>Function</AiLabel>
          <AiInput
            name="functions"
            value={formik.values.function}
            status={isCheckError(formik, 'functions') ? 'error' : ''}
            placeholder="Enter Function"
            onChange={(e) => onChangeInputData(formik, e.target.value, 'functions')}
          />
        </div>
      </div>
    </div>
  );
};

export default AddFunctionListForm;
