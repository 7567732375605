import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BasicButton } from '../../assets/common';
import { next_icon, prev_icon, super_next_icon, super_prev_icon } from '../../assets/img';

const { RangePicker } = DatePicker;

const Picker = styled.div`
  height: 46px;
  margin-top: 10px;
  position: relative;

  .custom-range-picker {
    height: 100%;
    border-radius: 6px;
    width: 100% !important;
  }
  .ant-picker-input {
    padding-left: 20px !important;
  }
  .checked {
    position: absolute;
    left: 10px;
    top: 11px;
    z-index: 10;
  }
  .ant-picker-header-view {
    white-space: nowrap;
    color: var(--emphasis-body-n-800, #3f3c4c);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .image-custom {
    padding: 8px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .ant-picker-header,
  .ant-picker-body {
    border: none;
  }

  .footer {
    margin: 10px;
    display: flex;
    justify-content: end;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba(52, 69, 99, 0.2);
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    background: rgba(52, 69, 99, 0.2);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(
      .ant-picker-cell-range-start
    )
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(
      .ant-picker-cell-range-end
    )
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50%;
    background: #00a19c;
  }
  .ant-picker-input {
    input::placeholder {
      color: #787587;
    }
  }
`;

const RangePickerCustom = (props) => {
  const { query, setQuery } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [open, setOpen] = useState(false);

  const onSelectDateRange = () => {
    if (!dateRange.includes(null)) {
      setQuery((prev) => ({ ...prev, from_date: dateRange[0], to_date: dateRange[1] }));
    }
    setOpen(false);
  };

  const onChange = (date, dateString) => {
    setDateRange([dateString[0] || null, dateString[1] || null]);
  };

  const onCancel = () => {
    setDateRange([query.from_date, query.to_date]);
    setOpen(false);
  };

  useEffect(() => {
    setDateRange([query.from_date, query.to_date]);
  }, [query]);
  return (
    <>
      <Picker>
        <RangePicker
          open={open}
          format={'DD-MM-YYYY'}
          value={[
            dateRange[0] ? moment(dateRange[0], 'DD-MM-YYYY') : null,
            dateRange[1] ? moment(dateRange[1], 'DD-MM-YYYY') : null,
          ]}
          placeholder={['Review Date', '']}
          onChange={onChange}
          allowClear={false}
          getPopupContainer={(trigger) => trigger.parentElement}
          renderExtraFooter={(mode) => (
            <>
              {mode === 'date' && (
                <div className="footer">
                  <BasicButton
                    mode="border-teal"
                    onClick={() => onCancel()}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </BasicButton>
                  <BasicButton mode="teal" onClick={() => onSelectDateRange()}>
                    Apply
                  </BasicButton>
                </div>
              )}
            </>
          )}
          onClick={() => setOpen(true)}
          className="custom-range-picker"
          nextIcon={<img alt="" src={next_icon} className="image-custom mr-2" />}
          prevIcon={<img alt="" src={prev_icon} className="image-custom" />}
          superNextIcon={<img alt="" src={super_next_icon} className="image-custom " />}
          superPrevIcon={<img alt="" src={super_prev_icon} className="image-custom  mr-2" />}
        />
      </Picker>
    </>
  );
};

export default RangePickerCustom;
