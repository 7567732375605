import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import talentProfileApi from '../../../../services/talentProfiles';
import styles from './assessment-print.module.scss';

const Assessment = (props) => {
  const { profileId, isPrintPdf } = props;
  const user = useSelector((state) => state.user);
  const [listAssessment, setListAssessment] = useState([]);

  useEffect(() => {
    async function fetchApi() {
      const body = { isPrintPdf };
      const response = await talentProfileApi.fetchAssessmentData({
        profileId,
        roleUser: user,
        body,
      });
      if (
        !isEmpty(response) &&
        response?.data &&
        response?.data?.result?.profiles &&
        response?.data?.result?.profiles?.length > 0
      ) {
        setListAssessment(response?.data?.result?.profiles);
      }
    }
    if (user.roleActive) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleActive, profileId, isPrintPdf]);

  return (
    <div className={styles.SectionPlan}>
      <div className={styles.SectionName}>Assessment Data</div>
      <div className={styles.tablePlan}>
        <table className="table">
          <thead>
            <tr className={styles.tablePlanThead}>
              <th scope="col" className="text-center">
                No.
              </th>
              <th scope="col" className="text-center">
                Assessment Type
              </th>
              <th scope="col">Date</th>
              <th scope="col" style={{ width: '45%' }}>
                Recommendation Result
              </th>
            </tr>
          </thead>
          <tbody>
            {!!listAssessment && listAssessment.length > 0 ? (
              listAssessment?.map((data, idx) => (
                <tr data-testid="list-assessment" key={idx} className={styles.tablePlanBody}>
                  <td className="text-center">{idx + 1}</td>
                  <td data-testid="type" className="text-center">
                    {data.type || '-'}
                  </td>
                  <td data-testid="dates">{moment(data.dates).format('D MMMM YYYY') || '-'}</td>
                  <td data-testid="assessment_result">{data.assessment_result || '-'}</td>
                </tr>
              ))
            ) : (
              <tr data-testid="no-list-assessment" key="0" className={styles.tablePlanBody}>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Assessment;
