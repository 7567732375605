import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ALLOW_URL_NAVIGATE } from '../utils/constants';

export const useDectectNavigate = (props) => {
  const { blockNavigateByData } = props;
  const history = useHistory();
  const [pathname, setPathname] = useState('');

  const handleKeepNavigate = () => {
    history.push(pathname);
  };

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (!blockNavigateByData) return;
    const unblock = history.block((location) => {
      if (showPopup || ALLOW_URL_NAVIGATE.some((element) => location.pathname.includes(element))) {
        return true;
      }

      setPathname(location.pathname);
      setShowPopup(true);
      return false;
    });

    return () => {
      unblock();
    };
  }, [showPopup, blockNavigateByData, history]);

  return { showPopup, setShowPopup, handleKeepNavigate };
};
