import { Input, Modal, Pagination, Table, Select } from 'antd';
import styled from 'styled-components';
import { font } from '../../assets/common';

export const SelectAnt = styled(Select)`
  color: var(--Base-Black, #181818);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.235px;

  .ant-select-selector {
    padding: 0 !important;
  }
`;

export const TitleDiv = styled.div`
  color: #4a4a4a;
  font-family: ${font.inter};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
`;

export const Title = styled.div`
  color: #4a4a4a;
  font-family: ${font.inter};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
  margin-bottom: 36px;
`;

export const Wrapper = styled(Modal)`
  .ant-modal-header {
    padding: 40px 40px 0 40px;
    border: none;
  }
  .ant-modal-close-x {
    margin-right: 20px;
    margin-top: 22px;
  }
  .ant-modal-body {
    padding: 0 40px;
  }
`;

export const TableDiv = styled.div`
  padding-bottom: 40px;
`;

export const TableData = styled(Table)`
  .ant-table {
    border-radius: 6px;
    border: 1px solid rgba(151, 151, 151, 0.5);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    background-color: #fff;
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: ${font.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ColumnName = styled.div`
  color: #000;
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;
`;

export const CustomNumber = styled.span`
  color: var(--gray-700, #374151);
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const Text = styled.span`
  color: '#3F3C4C';
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const ButtonMore = styled.div`
  color: #00908c;
  font-family: ${font.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const ShowMoreLess = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  img {
    transform: ${(props) => (props?.readmore ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition-duration: 500ms;
    transition-property: transform;
  }
`;

export const TablePagination = styled(Pagination)`
  .ant-pagination-item {
    color: #00645c;
    border: none;
  }
  .ant-pagination-item-active {
    color: #00645c;
    border-radius: 4px;
    background: var(--Primary-100, #afdddc);
  }
`;
