import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { search_data_not_found } from '../../../../assets/img';
import { ModelTC, SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { checkDeleteResetPage } from '../../../../utils/helper';
import { EXTERNAL_TALENT_MESSAGE, EXTERNAL_TALENTS_TABLE_ROW_LIMIT } from '../constant';
import MenuBarExternalTalents from './MenuBarExternalTalents/MenuBarExternalTalents';
import ExternalTalentsTable from './ExternalTalentsTable/ExternalTalentsTable';

const INIT_SEARCH_PARAMS = {
  keyword: '',
  page: 1,
  limit: EXTERNAL_TALENTS_TABLE_ROW_LIMIT,
  role: [],
  company: [],
};

const ExternalTalents = (props) => {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [data, setData] = useState({ external_talents: [], total: 0 });
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [deleteParams, setDeleteParams] = useState({
    selectedRecords: [],
    deleteRecord: null,
  });

  const handleDeleteSuccess = (arrDeleted) => {
    const { external_talents, total } = data;
    const { page } = searchParams;

    checkDeleteResetPage(arrDeleted, external_talents, total, page) ? setSearchParams(INIT_SEARCH_PARAMS) : fetchData();

    setDeleteParams({
      selectedRecords: deleteParams.selectedRecords.filter((item) => !arrDeleted.includes(item.id)),
      deleteRecord: null,
    });
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: EXTERNAL_TALENT_MESSAGE.DELETE_SUCCESS,
      })
    );
  };

  const handleDeleteRecord = async () => {
    const { selectedRecords, deleteRecord } = deleteParams;
    if (isEmpty(selectedRecords) && !deleteRecord) return;

    setDeleting(true);
    const talentIds = deleteRecord ? [deleteRecord?.id] : selectedRecords.map((item) => item.id);

    try {
      const res = await adminApi.deleteExternalTalent(talentIds, roleActive.roleId);
      if (res.status === 200) {
        handleDeleteSuccess(talentIds);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await adminApi.searchExternalTalents(searchParams, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result);
        if (!isEmpty(res.data.result.external_talents) || isFirstRender) {
          setIsFirstRender(false);
          return;
        }
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: EXTERNAL_TALENT_MESSAGE.SEARCH_NOT_FOUND,
          })
        );
      }
    } catch (error) {
      setData({ external_talents: [], total: 0 });
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive.roleId, searchParams]);

  useEffect(() => {
    // eslint-plugin-no-floating-promise
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarExternalTalents
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        deleteParams={deleteParams}
        setShowDeleteConfirm={setShowDeleteConfirm}
      />

      {!isEmpty(data.external_talents) ? (
        <>
          <ExternalTalentsTable
            data={data}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setDeleteParams={setDeleteParams}
            setShowDeleteConfirm={setShowDeleteConfirm}
          />
          <ModelTC
            info={{
              type: 'deleteExternalTalent',
              visible: showDeleteConfirm,
              setVisible: setShowDeleteConfirm,
              handleSubmit: handleDeleteRecord,
              loading: deleting,
              onClose: () => {
                setShowDeleteConfirm(false);
              },
            }}
          />
        </>
      ) : (
        <SearchDataTableNotFound content={EXTERNAL_TALENT_MESSAGE.SEARCH_NOT_FOUND} notFoundIcon={search_data_not_found} />
      )}
    </Spin>
  );
};

export default ExternalTalents;
