import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BasicButton } from '../../assets/common';
import { close_modal } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import FormDate from '../Form/FormDate/FormDate';
import FormInput from '../Form/FormInput/FormInput';
import FromTextArea from '../Form/FormTextArea/FromTextArea';
import styles from './add-talent-review-modal.module.scss';
import { pushMessage } from '../../store/alertMessageSlice';

const HistoricalPopupAdd = (props) => {
  const { show, setShow, fetchApi, talentReviewObj, setTalentReviewObj } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    platform: '',
    date: '',
    remarks: '',
  });
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const roleActive = useSelector((state) => state.user.roleActive);
  const { id } = useParams();

  async function addOrEditTalentReview() {
    try {
      setLoading(true);
      if (isEmpty(talentReviewObj)) {
        await talentProfileApi.addTalentReview(roleActive, {
          ...formData,
          date: moment(formData.date).format(),
          staff_id: id,
        });
        dispatch(
          pushMessage({
            type: 'success',
            message: 'Talent Review successfully added ',
            timeShow: 0,
          })
        );
      }

      if (!isEmpty(talentReviewObj)) {
        await talentProfileApi.editTalentReview(roleActive, {
          ...formData,
          date: moment(formData.date).format(),
          staff_id: id,
          id: talentReviewObj.id,
        });
        dispatch(
          pushMessage({
            type: 'success',
            message: 'Talent Review successfully edited ',
            timeShow: 0,
          })
        );
      }
      fetchApi();
      handleResetForm();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    setShow(false);
  }

  const handleResetForm = () => {
    setFormData({
      platform: '',
      date: '',
      remarks: '',
    });
  };

  function validateFrom() {
    if (!(formData.remarks && formData.date && formData.platform)) return setIsValid(true);
    addOrEditTalentReview();
  }

  useEffect(() => {
    if (isEmpty(talentReviewObj)) return;
    setFormData({
      remarks: talentReviewObj.remarks,
      date: talentReviewObj.reviewDate,
      platform: talentReviewObj.platform,
    });
    return () => setTalentReviewObj({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentReviewObj]);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={styles.talent_search_form}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <div className={styles.talent_search_content}>
          <Modal.Header className={styles.modal_header}>
            <div className="d-flex flex-column justify-content-start">
              <h1>{isEmpty(talentReviewObj) ? 'Add New' : 'Edit'} Talent Review</h1>
              <p>
                {isEmpty(talentReviewObj)
                  ? 'Add New Talent Review on the platform'
                  : 'Edit Current Talent Review'}
              </p>
            </div>
            <img
              src={close_modal}
              alt="close_modal"
              style={{ pointerEvents: 'all', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setShow(false)}
            />
          </Modal.Header>

          <Modal.Body className={styles.modal_body}>
            <Spin spinning={loading} delay={500}>
              <div
                className="d-flex justify-content-between no-gutters"
                style={{ marginBottom: '10px' }}
              >
                <div className="col-5" style={{ paddingRight: '10px' }}>
                  <FormInput
                    label="Platform"
                    messageErr="Please fill in the required fields"
                    required={true}
                    placeholder="Select Platform"
                    value={formData.platform}
                    isError={isValid && !formData.platform}
                    setFormData={(val) =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          platform: val,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-7" style={{ paddingLeft: '10px' }}>
                  <FormDate
                    label="Date"
                    messageErr="Please fill in the required fields"
                    required={true}
                    placeholder="Select Date"
                    value={formData.date}
                    isError={isValid && !formData.date}
                    setFormData={(val) =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          date: val,
                        };
                      })
                    }
                  />
                </div>
              </div>
              <FromTextArea
                label="Remarks"
                messageErr="Please fill in the required fields"
                required={true}
                placeholder=""
                value={formData.remarks}
                isError={isValid && !formData.remarks}
                setFormData={(val) =>
                  setFormData((prev) => {
                    return {
                      ...prev,
                      remarks: val,
                    };
                  })
                }
              />
              <div className="mt-3 d-flex justify-content-end ">
                <BasicButton
                  style={{ marginRight: '10px' }}
                  onKeyDown={() => {}}
                  onClick={() => setShow(false)}
                >
                  Cancel
                </BasicButton>
                <BasicButton mode="teal" onKeyDown={() => {}} onClick={() => validateFrom()}>
                  {isEmpty(talentReviewObj) ? 'Add' : 'Save Changes'}
                </BasicButton>
              </div>
            </Spin>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default HistoricalPopupAdd;
