import { Select, Checkbox } from 'antd';
import styled from 'styled-components';
import { BasicTable, color } from '../../../../../../../assets/common';

export const CriteriaCustomTable = styled(BasicTable)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #3f3c4c;

  .ant-table-tbody > tr > td {
    vertical-align: top;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding-left: 80px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #fff;
  }
`;

export const CustomSelect = styled(Select)`
  font-weight: 400;
  text-align: left;

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    color: #8d8d8d !important;
  }
`;

export const CriteriaCheckbox = styled(Checkbox)`
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.54px;
  color: #4a4a4a;
  margin-bottom: 24px;
  text-transform: none;

  & + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${color.teal};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }

  .ant-checkbox-checked::after {
    border-color: ${color.teal};
  }

  .ant-checkbox + span {
    padding-left: 11px;
  }

  .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: #dff1f1;
  }
  .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ffffff;
  }

  .ant-checkbox-disabled {
    opacity: 0.5;
  }
`;
