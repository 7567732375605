import { DndContext, DragOverlay } from '@dnd-kit/core';
import { findIndex } from 'lodash';
import moment from 'moment/moment';
import React, { useState } from 'react';
import PositionItem from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/PositionItem';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import Recommendations from '../RecommendationTable/Recommendations';
import SpReadinessNew from '../SpReadinessNew/SpReadinessNew';
import styles from './add-edit-sucession.module.scss';

const AddEditSucession = (props) => {
  const { addTalent, setAddTalent, spReadiness, handleShowMessage, staffTalentReview } = props;
  const [draggingItem, setDraggingItem] = useState(null);
  const [dragItem, setDragItem] = useState({});
  const [draggingOver, setDraggingOver] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [useWorkaround] = useState(false);

  const [recommendationList, setRecommendationList] = useState([]);

  function handleDragStart(event) {
    setDraggingItem(event.active.id);
    if (event.active.data.current) {
      setDragItem(event.active.data.current);
    }
  }

  function handleDragOver(event) {
    setDraggingOver(event.over?.id || null);
  }
  const handeDefineTypeAndIndex = (data, item) => {
    const arrayType = data.split('-');
    let arrayLine = [];
    if (arrayType[0] === '1st Line') {
      arrayLine = [...addTalent.succession_planning.first_line];
    }
    if (arrayType[0] === '2nd Line') {
      arrayLine = [...addTalent.succession_planning.second_line];
    }
    if (arrayType[0] === '3rd Line') {
      arrayLine = [...addTalent.succession_planning.third_line];
    }
    if (arrayType[0] === 'Agreed to Drop') {
      arrayLine = [...addTalent.succession_planning.agreed_to_drop];
    }
    if (arrayType[0] === 'recommendation') {
      arrayLine = [...recommendationList];
    }

    return {
      name: arrayType[0],
      index: arrayType[2],
      arrayLine,
      item,
      setLine: setAddTalent,
    };
  };

  function swap(array, indexA, indexB) {
    let tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
  }

  function handleDragEnd(event) {
    const idActive = event.active.id;
    if (!event.over) return;
    const idOver = event.over.id;
    const itemActive = event.active.data.current;
    const itemOver = event.over.data.current;
    const activeInfo = handeDefineTypeAndIndex(idActive, itemActive);
    const overInfo = handeDefineTypeAndIndex(idOver, itemOver);

    if (activeInfo.name === 'recommendation' && overInfo.name === 'Agreed to Drop') {
      return;
    }
    const isApprovedDate =
      activeInfo.item.approved_date && activeInfo.item.last_approved_date
        ? moment(moment(activeInfo.item.approved_date).format('YYYY-MM-DD')).isSame(
            moment(activeInfo.item.last_approved_date).format('YYYY-MM-DD')
          )
        : false;

    if (!isApprovedDate && overInfo.name === 'Agreed to Drop') {
      return;
    }

    if (
      overInfo.name === 'Agreed to Drop' &&
      activeInfo.name !== 'Agreed to Drop' &&
      activeInfo.name !== 'recommendation' &&
      !event.isCallback
    ) {
      setOpenConfirm({
        position_code: activeInfo.item.position_code,
        event: {
          ...event,
          active: {
            ...event.active,
            data: {
              current: {
                ...event.active.data.current,
                line: event.active.data.current.line || activeInfo.name,
                revert_line: event.active.data.current.line || activeInfo.name,
              },
            },
          },

          isCallback: true,
        },
      });
      return;
    }

    if (activeInfo.name === overInfo.name) {
      if (!overInfo.index && activeInfo.index) {
        return;
      }
      swap(activeInfo.arrayLine, activeInfo.index, overInfo.index);
      activeInfo.setLine((prev) => {
        return {
          ...prev,
          succession_planning: {
            first_line:
              activeInfo.name === '1st Line'
                ? activeInfo.arrayLine
                : prev.succession_planning.first_line,
            second_line:
              activeInfo.name === '2nd Line'
                ? activeInfo.arrayLine
                : prev.succession_planning.second_line,
            third_line:
              activeInfo.name === '3rd Line'
                ? activeInfo.arrayLine
                : prev.succession_planning.third_line,
            agreed_to_drop:
              activeInfo.name === 'Agreed to Drop'
                ? activeInfo.arrayLine
                : prev.succession_planning.agreed_to_drop,
          },
        };
      });
    }

    if (activeInfo.name !== 'recommendation') {
      if (activeInfo.name !== overInfo.name) {
        if (overInfo.index) {
          activeInfo.arrayLine.splice(activeInfo.index, 1);
          activeInfo.setLine((prev) => {
            return {
              ...prev,
              succession_planning: {
                first_line:
                  activeInfo.name === '1st Line'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.first_line,
                second_line:
                  activeInfo.name === '2nd Line'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.second_line,
                third_line:
                  activeInfo.name === '3rd Line'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.third_line,
                agreed_to_drop:
                  activeInfo.name === 'Agreed to Drop'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.agreed_to_drop,
              },
            };
          });
          overInfo.arrayLine.splice(overInfo.index + 1, 0, activeInfo.item);
          overInfo.setLine((prev) => {
            return {
              ...prev,
              succession_planning: {
                first_line:
                  overInfo.name === '1st Line'
                    ? overInfo.arrayLine
                    : prev.succession_planning.first_line,
                second_line:
                  overInfo.name === '2nd Line'
                    ? overInfo.arrayLine
                    : prev.succession_planning.second_line,
                third_line:
                  overInfo.name === '3rd Line'
                    ? overInfo.arrayLine
                    : prev.succession_planning.third_line,
                agreed_to_drop:
                  overInfo.name === 'Agreed to Drop'
                    ? overInfo.arrayLine
                    : prev.succession_planning.agreed_to_drop,
              },
            };
          });
        }
        if (!overInfo.index) {
          activeInfo.arrayLine.splice(activeInfo.index, 1);
          activeInfo.setLine((prev) => {
            return {
              ...prev,
              succession_planning: {
                first_line:
                  activeInfo.name === '1st Line'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.first_line,
                second_line:
                  activeInfo.name === '2nd Line'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.second_line,
                third_line:
                  activeInfo.name === '3rd Line'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.third_line,
                agreed_to_drop:
                  activeInfo.name === 'Agreed to Drop'
                    ? activeInfo.arrayLine
                    : prev.succession_planning.agreed_to_drop,
              },
            };
          });
          overInfo.arrayLine.push(activeInfo.item);
          overInfo.setLine((prev) => {
            return {
              ...prev,
              succession_planning: {
                first_line:
                  overInfo.name === '1st Line'
                    ? overInfo.arrayLine
                    : prev.succession_planning.first_line,
                second_line:
                  overInfo.name === '2nd Line'
                    ? overInfo.arrayLine
                    : prev.succession_planning.second_line,
                third_line:
                  overInfo.name === '3rd Line'
                    ? overInfo.arrayLine
                    : prev.succession_planning.third_line,
                agreed_to_drop:
                  overInfo.name === 'Agreed to Drop'
                    ? overInfo.arrayLine
                    : prev.succession_planning.agreed_to_drop,
              },
            };
          });
        }
      }
    }
    //add from recommendation table
    if (activeInfo.name === 'recommendation') {
      const totalArray = [
        ...addTalent.succession_planning.first_line,
        ...addTalent.succession_planning.second_line,
        ...addTalent.succession_planning.third_line,
        ...addTalent.succession_planning.agreed_to_drop,
      ];
      let isCheck = findIndex(
        totalArray,
        (item) => Number(item.position_code) === Number(activeInfo.item.position_code)
      );

      if (isCheck !== -1) {
        if (
          findIndex(
            addTalent.succession_planning.first_line,
            (item) => Number(item.position_code) === Number(activeInfo.item.position_code)
          ) !== -1
        ) {
          handleShowMessage('warning', 'Talent already exist in “1st Line“ table');
          return;
        }
        if (
          findIndex(
            addTalent.succession_planning.second_line,
            (item) => Number(item.position_code) === Number(activeInfo.item.position_code)
          ) !== -1
        ) {
          handleShowMessage('warning', 'Talent already exist in “2nd Line“ table');
          return;
        }
        if (
          findIndex(
            addTalent.succession_planning.third_line,
            (item) => Number(item.position_code) === Number(activeInfo.item.position_code)
          ) !== -1
        ) {
          handleShowMessage('warning', 'Talent already exist in “3rd Line“ table');
          return;
        }
        if (
          findIndex(
            addTalent.succession_planning.agreed_to_drop,
            (item) => Number(item.position_code) === Number(activeInfo.item.position_code)
          ) !== -1
        ) {
          handleShowMessage('warning', 'Talent already exist in “Agreed to Drop“ table');
          return;
        }
        return;
      }

      if (!overInfo.index) {
        overInfo.arrayLine.push(activeInfo.item);
      }
      if (overInfo.index) {
        overInfo.arrayLine.splice(overInfo.index + 1, 0, activeInfo.item);
      }
      overInfo.setLine((prev) => {
        return {
          ...prev,
          succession_planning: {
            first_line:
              overInfo.name === '1st Line'
                ? overInfo.arrayLine
                : prev.succession_planning.first_line,
            second_line:
              overInfo.name === '2nd Line'
                ? overInfo.arrayLine
                : prev.succession_planning.second_line,
            third_line:
              overInfo.name === '3rd Line'
                ? overInfo.arrayLine
                : prev.succession_planning.third_line,
            agreed_to_drop: prev.succession_planning.agreed_to_drop,
          },
        };
      });
    }

    //// Swap item
    setDraggingItem(null);
    setDraggingOver(null);
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      autoScroll={{ enabled: useWorkaround ? !!draggingOver : true }}
    >
      <div className="d-flex justify-content-center">
        <div className="col-8">
          {spReadiness.map((item) => (
            <div className={item.className} key={item.title}>
              <SpReadinessNew
                title={item.title}
                backgroundcolor={item.backgroundcolor}
                setAddTalent={setAddTalent}
                data={addTalent.succession_planning[item.code]}
                addTalent={addTalent}
                handleShowMessage={handleShowMessage}
                readOnly={false}
                staffTalentReview={staffTalentReview}
                setOpenConfirm={setOpenConfirm}
                handleDragEnd={handleDragEnd}
              />
            </div>
          ))}
        </div>
        <div className={`col-4 ${styles.recommendation} `}>
          <Recommendations
            staffTalentReview={staffTalentReview}
            recommendationList={recommendationList}
            setRecommendationList={setRecommendationList}
          />
          {openConfirm?.position_code && (
            <ConfirmPopup
              info={{
                type: 'confirmToMoveTalentCard',
                visible: openConfirm?.position_code,
                setVisible: setOpenConfirm,
                handleSubmit: (value, title) => {
                  handleDragEnd({
                    ...openConfirm.event,
                    active: {
                      ...openConfirm.event.active,
                      data: {
                        current: {
                          ...openConfirm.event.active.data?.current,
                          remark: value,
                        },
                      },
                    },
                  });
                  setOpenConfirm(null);
                },
                onClose: () => setOpenConfirm(null),
              }}
            />
          )}
        </div>
      </div>
      <DragOverlay
        dropAnimation={{
          duration: 500,
        }}
      >
        {draggingItem ? (
          <div style={{ backgroundColor: 'white', minWidth: '270px', border: '1px solid #bdb6ea' }}>
            <PositionItem item={dragItem} index={dragItem.index} draggingItem={draggingItem} />
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default AddEditSucession;
