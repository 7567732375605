import __, {
  concat,
  filter,
  flatMap,
  includes,
  isEmpty,
  isNil,
  lowerCase,
  map,
  negate,
  toNumber,
} from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import userApi from '../services/userApi';
import { useRoles } from './useRoles';

export const useSecretarialOptions = (sourcePage = '') => {
  const user = useSelector((state) => state.user);
  const [secretarialData, setSecretarialData] = useState([]);
  const { businessUnitList, jobFamilyList, opuDivisionList, opuDivisionDropdown } =
    useRoles(sourcePage);

  const convertSecretarialOptions = useCallback(
    (secretarials) => {
      return flatMap(secretarials, ({ label: roleName, value: roleId }) => {
        if (['HR BP', 'HR BP Leaders', 'HR Sector'].includes(roleName)) {
          const businessList = map(businessUnitList, (business) => ({
            value: { business_type: 'Business', role_id: roleId, bu_unit_id: business.id },
            label: `${roleName} - ${business.businessUnit}`,
            transientValue: { roleName, businessId: business.id },
          }));
          const opuDivisions = map(opuDivisionList, (opu) => {
            return {
              value: { business_type: opu.type, role_id: roleId, bu_unit_id: opu.id },
              label: __([roleName, opu.opu, opu.division]).filter(negate(isEmpty)).join(' - '),
              transientValue: { roleName, businessId: opu.buId },
            };
          });
          return concat(businessList, opuDivisions);
        }

        if ('HR BP - Secretariat' === roleName) {
          return __(jobFamilyList)
            .filter((job) => ['Legal', 'Finance', 'Human Resource Management'].includes(job.name))
            .map(({ id: jobId, name: jobName }) => ({
              value: { business_type: 'JobFamily', bu_unit_id: jobId, role_id: roleId },
              label: `${roleName} - ${jobName}`,
              transientValue: { roleName },
            }))
            .value();
        }

        return [
          {
            label: roleName,
            value: { bu_unit_id: 0, role_id: roleId, business_type: 'null' },
            transientValue: { roleName },
          },
        ];
      }).map((option) => ({
        ...option,
        value: {
          ...option.value,
          bu_unit_id: isNil(option.value.bu_unit_id)
            ? option.value.bu_unit_id
            : toNumber(option.value.bu_unit_id),
        },
      }));
    },
    [businessUnitList, opuDivisionList, jobFamilyList]
  );

  const fetchSecretarial = useCallback(async (inputValue) => {
    let res = [];
    try {
      const params = { keyword: inputValue };
      let response = await userApi.getSecretarial({ roleUser: user, params });
      if (response.status === 200 && response.data.result.length > 0) {
        res = response.data.result.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        return res;
      }
      return res;
    } catch (error) {
      return res;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSecretarial().then((options) => {
      setSecretarialData(options);
    });
  }, [setSecretarialData, fetchSecretarial]);

  const secretarialOptions = useMemo(
    () => (isEmpty(secretarialData) ? secretarialData : convertSecretarialOptions(secretarialData)),
    [convertSecretarialOptions, secretarialData]
  );

  const getAsyncOptionsForSecretarial = useCallback(
    async (inputValue) =>
      filter(secretarialOptions, ({ label }) => includes(lowerCase(label), lowerCase(inputValue))),
    [secretarialOptions]
  );

  const opuDivisionOption = useMemo(() => {
    return opuDivisionDropdown.map((item, id) => {
      let category_label = item.label;
      let category_value = item.value;
      return { id, name: category_value, category_label };
    });
  }, [opuDivisionDropdown]);

  const businessOption = useMemo(() => {
    return businessUnitList.map((item, id) => {
      let category_label = 'business';
      let category_value = item.businessUnit;
      return { id, name: category_value, category_label };
    });
  }, [businessUnitList]);

  return { secretarialOptions, getAsyncOptionsForSecretarial, opuDivisionOption, businessOption };
};
