import { isEmpty } from 'lodash';
import * as yup from 'yup';

export const formValidationSchema = yup.object({
  job_family: yup.string().required(true),
  sub_job_family: yup.string().required(true),
  functions: yup.string().required(true),
});

export const handleCheckEmptyValue = (values) => {
  let isCheck = false;
  Object.keys(values).forEach((key) => {
    if (isEmpty(values[key])) {
      isCheck = true;
      return true;
    }
  });
  return isCheck;
};
