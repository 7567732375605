export const VALUES_SELECT = {
  RETIREMENT_FORECAST: 'Retirement Forecast',
  NATIONALITY: 'Nationality',
  SUCCESSOR: 'Successor',
  BUSINESS_DIVISION_OPU: 'Business',
};
export const TYPE_ROLE_RESPONSE = {
  EXECUTIVE: 'Executive',
  M: 'M',
  SM: 'SM',
  SGM: 'SGM',
  GM: 'GM',
  OTHERS: 'Others',
};
export const TYPE_ROLE_RESPONSE_2 = {
  SENIOR_GENERAL_MANAGER: 'Senior General Manager',
  SENIOR_GENERAL: 'Senior General',
  GENERAL_MANAGER: 'General Manager',
  SENIOR_MANAGER: 'Senior Manager',
  MANAGER: 'Manager',
  VICE_PRESIDENT: 'Vice President',
  EXECUTIVE: 'Executive',
  OTHERS: 'Other',
};

export const ROLE_LEVEL = 'role_level';
export const TYPE_NATIONAL = {
  MALAYSIAN: 'Malaysian',
  NONMALAYSIAN: 'NonMalaysian',
  NON_MALAYSIAN: 'Other Nationalities',
};
export const TYPE_SUCCESSOR = {
  EKP_SUCCESSOR: 'EKP_Successor',
  BKP_SUCCESSOR: 'BKP_Successor',
  NON_SUCCESSOR: 'Non_Successor',
  BOTH_SUCCESSOR: 'Both_Successor',
  CCP_SUCCESSOR: 'CCP Successor',
  BCP_SUCCESSOR: 'BCP Successor',
  NON_SUCCESSOR_TEXT: 'Non Successor',
  BOTH_CCP_AND_BCP: 'Both CCP and BCP',
  SUCCESSOR: 'Successor',
};
export const TYPE_RETIREMENT = {
  LESSTHAN1YEAR: 'lessthan1year',
  LESSTHAN3YEAR: 'lessthan3year',
  LESSTHAN5YEAR: 'lessthan5year',
  LESS_THAN_1_YEAR: '<1 year',
  LESS_THAN_3_YEAR: '1 - 3 years',
  LESS_THAN_5_YEAR: '>3 -5 years',
};
export const BUSINESS = {
  UPSTEAM: 'Upstream',
  DOWNSTREAM: 'Downstream',
  GAS: 'GAS',
  GNE: 'Gas & New Energy',
  PDT: 'Project Delivery & Technology',
  CORPORATE: 'Corporate',
  PETG: 'PETRONAS Gas',
  OTHERS: 'Others',
};

export const HANDLE_DATA_TYPE = {
  BUSINESS: 'business',
  RETIREMENT_FORECAST: 'Retirement Forecast',
  NATIONALITY: 'Nationality',
  SUCCESSOR: 'Successor',
};
