import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './form-drop-down.module.scss';

const FormDropdown = (props) => {
  const {
    label,
    messageErr,
    required,
    placeholder,
    value,
    options,
    setFormData,
    isError,
    disabled,
    borderColor,
    iconArrow,
  } = props;
  const [defaultValue, setDefaultValue] = useState(null);

  const handleChange = (value) => {
    setFormData && setFormData(value);
  };

  useEffect(() => {
    setDefaultValue(value || undefined);
  }, [value]);

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.label}>{label}</span>
        <span style={{ color: '#FF787F' }}>{required && '*'}</span>
      </div>
      <div className={isError && styles.borderError}>
        <Select
          placeholder={placeholder}
          value={defaultValue}
          style={{
            width: '100%',
            borderColor: `${borderColor}`,
            borderRadius: '4px ',
          }}
          onChange={handleChange}
          options={options}
          disabled={disabled}
          data-testid="form-dropdown"
          suffixIcon={iconArrow}
        />
      </div>
      <div className={styles.error}>{isError && messageErr}</div>
    </div>
  );
};

export default FormDropdown;
