import { Dropdown, Space, Table, Tooltip } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AiButton } from '../../../../../assets/common';
import {
  deleteNote,
  edit_delete,
  editNote,
  tooltip_black_icon,
  tooltip_white_icon,
} from '../../../../../assets/img';
import { CategoryDropdownCustom, ModelTC } from '../../../../../components';
import { useDebounce } from '../../../../../hooks';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  MESSAGE_TYPES,
  SELECT_ALL_LABEL,
  SG_JG_MAPPING_MESSAGE,
} from '../../../../../utils/constants';
import SearchBoxCustom from '../components/SearchBoxCustom/SearchBoxCustom';
import {
  formMappingvalidationSchema,
  handleCheckEmptyValue,
} from '../CreateNewSGJGMapping/formMappingValidation';
import * as styles from './data-table-managing.module.scss';
import { TableManagement } from './styled';
import { isCheckError } from '../../../../../hooks/useFormik';

export default function DataTableManaging({
  data,
  setDeleteRecord,
  setDeleteSelecteds,
  setSelectedRecordEdit,
  setSeletedRows,
  seletedRows,
  totalData,
  limit,
  setIsEdit,
  isEdit,
  filterOptions,
  setIsInvalidJGInOracle,
  isInvalidJGInOracle,
  setIsInvalidJGInPSeries,
  isInvalidJGInPSeries,
}) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);
  const [isFirstValueChange, setIsFirstValueChange] = useState(false);

  // State search field
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [searchTermJG, setSearchTermJG] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      role_level: '',
      sg_in_oracle: '',
      jg_in_oracle: '',
      jg_in_p_series: '',
    },
    validationSchema: formMappingvalidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useEffect(() => {
    if (!isEmpty(searchTerm?.value)) {
      formik.setFieldValue('sg_in_oracle', searchTerm.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (!isEmpty(searchTermJG?.value)) {
      formik.setFieldValue('jg_in_oracle', searchTermJG.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermJG]);

  // Use Debounce to get value
  const jgInPSeriesValue = useDebounce(formik.values.jg_in_p_series, 300);
  const sgInOracleValue = useDebounce(formik.values.sg_in_oracle, 300);
  const jgInOracleValue = useDebounce(formik.values.jg_in_oracle, 300);

  useEffect(() => {
    setIsInvalidJGInOracle(false);
    setIsInvalidJGInPSeries(false);
  }, [
    sgInOracleValue,
    jgInOracleValue,
    jgInPSeriesValue,
    setIsInvalidJGInOracle,
    setIsInvalidJGInPSeries,
  ]);

  useEffect(() => {
    if (data.length) {
      const tempData = data.map((item) => {
        return {
          ...item,
          key: `${item.role_level}.${item.id}.${
            item.only_one_item || item.first_item || item.last_item || ''
          }`,
        };
      });
      setNewData(tempData);
      setCurrentPage(1);
    }
  }, [data]);

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: type === MESSAGE_TYPES.ERROR,
      })
    );
  };

  const handleEditNewSGJGMapping = async () => {
    setIsFirstValueChange(false);

    await formik.submitForm();
    const values = formik.values;
    const isCheck = handleCheckEmptyValue(values);
    if (isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, SG_JG_MAPPING_MESSAGE.FILL_ALL_FIELD_REQUIRED);
      return;
    }

    if (!formik.isValid && formik?.errors?.sg_in_oracle) {
      handleShowMessage(MESSAGE_TYPES.ERROR, SG_JG_MAPPING_MESSAGE.SG_JG_UPPERCASE);
      return;
    }

    if (!formik.isValid && formik?.errors?.jg_in_oracle) {
      handleShowMessage(
        MESSAGE_TYPES.ERROR,
        SG_JG_MAPPING_MESSAGE.FIRST_CHARACTER_MUST_BE_UPPERCASE
      );
      return;
    }

    setSelectedRecordEdit({
      ...values,
      role_level: values?.role_level?.name,
      jg_in_p_series: values?.jg_in_p_series?.name,
      id: selectedRecord?.id,
    });
  };

  const handleResetForm = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({}, false);
  };

  useEffect(() => {
    if (!isEdit) {
      handleResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const handleClickEditBtn = () => {
    setIsFirstValueChange(true);
    const tempRoleLevel =
      !isEmpty(filterOptions?.role_level) &&
      filterOptions?.role_level
        .filter((item) => item?.value === selectedRecord?.role_level)
        .map((option) => {
          return {
            id: `${option?.id}`,
            name: `${option?.label}`,
          };
        });

    const tempJgInPSeries =
      !isEmpty(filterOptions?.jg_in_p_series) &&
      filterOptions?.jg_in_p_series
        .filter((item) => item?.value === selectedRecord?.jg_in_p_series)
        .map((option) => {
          return {
            id: `${option?.value}`,
            name: `${option?.label}`,
          };
        });

    setSearchTerm({
      type: 'search',
      value: selectedRecord?.sg_in_oracle || '',
      showTerm: selectedRecord?.sg_in_oracle || '',
    });

    setSearchTermJG({
      type: 'search',
      value: selectedRecord?.jg_in_oracle || '',
      showTerm: selectedRecord?.jg_in_oracle || '',
    });

    formik.setValues({
      role_level: tempRoleLevel.length ? tempRoleLevel[0] : null,
      sg_in_oracle: selectedRecord?.sg_in_oracle || null,
      jg_in_oracle: selectedRecord?.jg_in_oracle || null,
      jg_in_p_series: tempJgInPSeries.length ? tempJgInPSeries[0] : null,
    });
    setIsEdit(true);
  };

  const menuProps = {
    items: [
      {
        key: '0',
        icon: <img src={editNote} alt="edit" />,
        label: (
          <div style={{ textDecoration: 'none' }} className={styles.editButton}>
            Edit
          </div>
        ),
        onClick: () => handleClickEditBtn(),
      },
      {
        key: '1',
        icon: <img src={deleteNote} alt="delete" />,
        label: <div className={styles.deleteButton}>Delete</div>,
        onClick: () => {
          setShowDeleteModal(true);
        },
      },
    ],
  };

  const columns = [
    {
      title: 'Role Level',
      dataIndex: 'role_level',
      align: 'left',
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <CategoryDropdownCustom
              selected={formik.values.role_level}
              setSelected={(event) => formik.setFieldValue('role_level', event)}
              options={
                !isEmpty(filterOptions?.role_level)
                  ? filterOptions?.role_level
                      .filter((item) => item.label !== SELECT_ALL_LABEL)
                      .map((option) => {
                        return {
                          id: `${option?.value}`,
                          name: `${option?.label}`,
                        };
                      })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'role_level') ? 'error' : ''}
              hasCustom={true}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span
              className={`${styles.textField} text_field`}
              style={{ width: '306px', fontWeight: 700, color: '#3F3C4C' }}
            >
              {record?.role_level}
            </span>
          )}
        </>
      ),
    },
    {
      title: 'SG in Oracle',
      dataIndex: 'sg_in_oracle',
      align: 'center',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id ? (
            <div className={styles.inputFieldWrapper}>
              <SearchBoxCustom
                setIsFirstValueChange={setIsFirstValueChange}
                isFirstValueChange={isFirstValueChange}
                isEdit={isEdit}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchList={filterOptions?.sg_in_oracle ?? []}
                fieldName={'SG_In_Oracle'}
                searchPlaceholder={'Select or type SG value'}
                status={isCheckError(formik, 'sg_in_oracle') || isInvalidJGInOracle ? 'error' : ''}
              />
              <Tooltip
                className={styles.customTooltip}
                overlayClassName={'custom-tooltip-mapping'}
                overlayStyle={{ minWidth: '354px' }}
                title={
                  <div className={styles.tooltipContent}>
                    <img className={styles.tooltipIcon} src={tooltip_white_icon} alt="" />
                    <span>{SG_JG_MAPPING_MESSAGE.CHARACTER_MUST_BE_UPPERCASE_TOOLTIP}</span>
                  </div>
                }
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
              >
                <img className={styles.tooltipIcon} src={tooltip_black_icon} alt="" />
              </Tooltip>
            </div>
          ) : (
            record?.sg_in_oracle
          )}
        </>
      ),
    },
    {
      title: 'JG in Oracle',
      dataIndex: 'jg_in_oracle',
      align: 'center',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id ? (
            <div className={styles.inputFieldWrapper}>
              <SearchBoxCustom
                setIsFirstValueChange={setIsFirstValueChange}
                isFirstValueChange={isFirstValueChange}
                isEdit={isEdit}
                searchTerm={searchTermJG}
                setSearchTerm={setSearchTermJG}
                searchList={filterOptions?.jg_in_oracle ?? []}
                fieldName={'JG_In_Oracle'}
                searchPlaceholder={'Select or type JG value'}
                status={isCheckError(formik, 'jg_in_oracle') || isInvalidJGInOracle ? 'error' : ''}
              />
              <Tooltip
                className={styles.customTooltip}
                overlayClassName={'custom-tooltip-mapping'}
                overlayStyle={{ minWidth: '358px' }}
                title={
                  <div className={styles.tooltipContent}>
                    <img className={styles.tooltipIcon} src={tooltip_white_icon} alt="" />
                    <span>{SG_JG_MAPPING_MESSAGE.FIRST_CHARACTER_MUST_BE_UPPERCASE_TOOLTIP}</span>
                  </div>
                }
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
              >
                <img className={styles.tooltipIcon} src={tooltip_black_icon} alt="" />
              </Tooltip>
            </div>
          ) : (
            record?.jg_in_oracle
          )}
        </>
      ),
    },
    {
      title: 'JG in P Series',
      dataIndex: 'jg_in_p_series',
      align: 'center',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <CategoryDropdownCustom
              selected={formik.values.jg_in_p_series}
              setSelected={(event) => formik.setFieldValue('jg_in_p_series', event)}
              options={
                !isEmpty(filterOptions?.jg_in_p_series)
                  ? filterOptions?.jg_in_p_series
                      .filter((item) => item.label !== SELECT_ALL_LABEL)
                      .map((option) => {
                        return {
                          id: `${option?.value}`,
                          name: `${option?.label}`,
                        };
                      })
                  : []
              }
              placeholder="Select"
              status={
                isCheckError(formik, 'jg_in_p_series') ||
                isInvalidJGInPSeries ||
                isInvalidJGInOracle
                  ? 'error'
                  : ''
              }
              hasCustom={true}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span className={styles.textField}>{record?.jg_in_p_series}</span>
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
      render: (_, record) => (
        <>
          {!isEdit && (
            <Dropdown
              menu={menuProps}
              trigger={['click']}
              style={{ minWidth: 110 }}
              placement="bottomRight"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Space style={{ cursor: 'pointer' }}>
                <img
                  src={edit_delete}
                  alt="dot"
                  onKeyDown={() => {}}
                  onClick={() => setSelectedRecord(record)}
                />
              </Space>
            </Dropdown>
          )}

          {isEdit && record?.id === selectedRecord?.id && (
            <AiButton mode={'teal'} onKeyDown={() => {}} onClick={() => handleEditNewSGJGMapping()}>
              Save
            </AiButton>
          )}
        </>
      ),
    },
  ];

  const handleShowTotal = (total, range) => {
    return (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    );
  };

  const paginationConfig = {
    total: totalData,
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== currentPage && setCurrentPage(pageNum),
    showTotal: (total, range) => handleShowTotal(total, range),
  };

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      const seletedRows = handleConvertSelectedRows(_selectedRows);
      setSeletedRows(selectedRowKeys);
      setDeleteSelecteds(seletedRows);
    },
    selectedRowKeys: seletedRows,
  };

  const handleConvertSelectedRows = (data) => {
    let tempArr = [];
    if (!isEmpty(data)) {
      data.forEach((item) => {
        const tempId = !isEmpty(item?.key) ? item?.key.split('.')[1] : '';
        tempArr.push(Number(tempId));
      });
    }
    return tempArr;
  };

  return (
    <div style={{ marginTop: 24 }}>
      <TableManagement>
        <Table
          rowSelection={
            !isEdit && {
              type: 'checkbox',
              ...rowSelection,
            }
          }
          className={isEdit ? 'customMappingGroupTable editMode' : 'customMappingGroupTable'}
          columns={columns}
          rowClassName={(record) => {
            return `row_${record?.id} ${
              record?.only_one_item || record?.first_item || record?.last_item
            }`;
          }}
          dataSource={newData}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </TableManagement>
      <ModelTC
        info={{
          type: 'deleteSGJGMapping',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => setShowDeleteModal(false),
          handleSubmit: () => {
            setDeleteRecord(selectedRecord);
            setShowDeleteModal(false);
          },
        }}
      />
    </div>
  );
}
