import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Dropdown, Form, Menu, Modal, Row, Table } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { AiForm, Aih3, AiInput, AiLink, Aip, AiSpin } from '../../../../assets/common';
import { editNote, ellipsis, pets_chevron_right } from '../../../../assets/img';
import CommonLoading from '../../../../components/CommonLoading/CommonLoading';
import { NOTIFICATION_TYPE, USER_ID } from '../../../../utils/constants';
import { showNotification } from '../../../../utils/notification';
import { roleIncumbentSelector } from '../../../selector';
import { createRoles, deleteRoles, getRoles } from './rolesSlice';
import { AiButton, AiButtonDelete, Container, Header, Navigate, TableManagement } from './Styled';

const RoleList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { confirm } = Modal;

  // Role of User
  const roleActive = useSelector((state) => state.user.roleActive);
  const roleInCumbent = useSelector(roleIncumbentSelector);
  // List Roles ,loading
  const { roles, loading } = useSelector((state) => state.app.adminModule.roleManagement);
  // form
  const [form] = Form.useForm();
  const [visibleCreateForm, setVisibleCreateForm] = useState(false);
  // table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalRes, setTotalRes] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  // User permission
  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
    },
    {
      title: 'Created by',
      dataIndex: 'created',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
    },
    {
      title: '',
      dataIndex: 'action',
      render: (_, record) => {
        const menu = (
          <Menu style={{ minWidth: 110 }}>
            <Menu.Item key={`${record.key}`} icon={<img src={editNote} alt="" />}>
              <AiLink to={`/admin/role-management/${record.key}/update`}>
                <span>Edit</span>
              </AiLink>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <div style={{ cursor: 'pointer' }}>
              <img src={ellipsis} alt="" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const resetForm = () => {
    form.resetFields();
  };

  /*
   * Function: get roles
   * Goal : get list role and show it on the table
   * Input: id: number, role: object
   * Return array of object
   */
  const getListRoles = async (page, pageSize) => {
    dispatch(
      getRoles({
        id: USER_ID,
        role: roleActive,
        page,
        limit: pageSize,
      })
    ).then((res) => {
      setTotalRes(res.payload);
    });
  };

  /* TO DO implement API create role
   * Function: Create role
   * Goal : Create role in system , Get list Permission and role levels of the new role
   * Input: id: number, role: object, body: object
   * Return roleId : number
   */
  const createRole = async (value) => {
    const body = {
      fullname: value.roleName,
      updated_by: USER_ID,
    };
    dispatch(createRoles({ id: USER_ID, role: roleActive, body })).then((data) => {
      if (data?.payload?.roleId) {
        setVisibleCreateForm(false);
        showNotification('Add new role successful', NOTIFICATION_TYPE.SUCCESSFUL);
        history.push(`/admin/role-management/${data?.payload?.roleId}/create`);
      } else {
        showNotification('Add new role failed', NOTIFICATION_TYPE.FAILED);
      }
    });
  };

  /**
   * Delete roles by array of ids
   */
  const deleteRole = async () => {
    const body = {
      role_ids: selectedRowKeys,
    };

    dispatch(deleteRoles({ id: USER_ID, role: roleActive, body })).then((data) => {
      if (data?.payload) {
        showNotification('Delete roles successful', NOTIFICATION_TYPE.SUCCESSFUL);
        setSelectedRowKeys([]);
        getListRoles(page, pageSize);
      } else {
        showNotification('Delete roles failed', NOTIFICATION_TYPE.FAILED);
      }
    });
  };

  useEffect(() => {
    if (visibleCreateForm) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleCreateForm]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getListRoles(page, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleInCumbent]);

  function onChange(pagination, filters, sorter, extra) {}

  const showConfirm = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <div>Are you sure you want to delete role(s)</div>,
      className: 'antd-modal-confirm-custom',
      onOk() {
        deleteRole();
      },
      onCancel() {},
    });
  };
  const paginationGetListRoles = (page, pageSize) => {
    getListRoles(page, pageSize);
    setPage(page);
    setPageSize(pageSize);
  };
  return (
    <Container>
      <>
        <Navigate>
          <Link to="/admin/role-management">
            <p>Admin</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <p
            style={{
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '24px',
              color: '#212121',
              textTransform: 'capitalize',
            }}
          >
            Role Management
          </p>
        </Navigate>
        <Header>
          <div className="left">
            <div className="title">Role Management</div>
            <div className="sub-title">List of roles management</div>
          </div>
          <div className="right">
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <AiButton
                  type="primary"
                   onKeyDown={() => {}} onClick={handleDeleteVersionHistory}
                  disabled={!hasSelected || loading}
                  loading={loading}
                >
                  Delete Version History
                </AiButton>
              </div> */}
            <AiButtonDelete
              // className={`button ${
              //   isEmpty(selectedRowKeys) ? 'btn-delete-user' : 'btn-delete-user_active'
              // }`}
              onKeyDown={() => {}}
              onClick={showConfirm}
              disabled={!hasSelected}
            >
              Confirm delete role
            </AiButtonDelete>
            <div
              className="button button-active"
              onKeyDown={() => {}}
              onClick={() => {
                setVisibleCreateForm(true);
              }}
            >
              Create Role
            </div>
          </div>
        </Header>
        <TableManagement>
          {loading.getRoles ? (
            <div className="center-loading">
              <CommonLoading />
            </div>
          ) : (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={roles}
              onChange={onChange}
              pagination={{
                pageSize: pageSize,
                current: page,
                total: totalRes,
                showLessItems: false,
                onChange: (page, pageSize) => paginationGetListRoles(page, pageSize),
                showTotal: (_total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${totalRes} results`,
              }}
            />
          )}
        </TableManagement>
      </>

      <Modal
        open={visibleCreateForm}
        closable={true}
        style={{
          padding: 0,
        }}
        onCancel={() => {
          setVisibleCreateForm(false);
        }}
        footer={null}
      >
        <Aih3>Add Role</Aih3>
        <Aip>
          Role Name<span style={{ color: 'red' }}>*</span>
        </Aip>
        <AiForm name="normal_login" className="login-form" onFinish={createRole} form={form}>
          <Row wrap={false} align="middle" gutter={16}>
            <Col flex="auto">
              <Form.Item
                name="roleName"
                rules={[
                  {
                    required: true,
                    message: 'Please input Role Name!',
                  },
                ]}
              >
                <AiInput placeholder="Enter Role Name" />
              </Form.Item>
            </Col>

            <Col flex="none">
              <Form.Item>
                <AiButton
                  htmlType="submit"
                  className="login-form-button"
                  disabled={loading.createRole}
                >
                  {!loading.createRole && 'Add'}
                  {loading.createRole && <AiSpin size="small" color="white" />}
                </AiButton>
              </Form.Item>
            </Col>
          </Row>
        </AiForm>
      </Modal>
    </Container>
  );
};
export default memo(RoleList);
