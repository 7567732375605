import styled from 'styled-components';

const $borderColor = '#eeeeee';
const $textColor = '#999a9c';
const $nameTextColor = '#000';

export const SearchItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 74px;
  gap: 20px;
  padding: 5px;
  border-bottom: 1px solid ${$borderColor};
  cursor: pointer;
`;

export const Avatar = styled.div`
  img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const Individual = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${$textColor};
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Name = styled.div`
  color: ${$nameTextColor};
  font-weight: 600;
  line-height: 16px;
`;
