import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { deleteNote, editNote, ellipsis } from '../../../src/assets/img';
import { BasicButton } from '../../assets/common';
import { DATA_FROM, PERMISSION } from '../../utils/constants';
import styles from './viewnote.module.scss';

const NoteModal = (props) => {
  const {
    note,
    setIsPopupDelete,
    setIsOpen,
    onChooseItem,
    setIsEdit,
    setIsOpenNote,
    index,
    length,
  } = props;
  const { permissions } = useSelector((state) => state.user);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  const isShowActionNonPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return (
      permissions.includes(PERMISSION.TP_EDIT_NON_PRIVATE_NOTE) && note.from === DATA_FROM.MANUAL
    );
  }, [permissions, note]);

  const isShowActionPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return (
      permissions.includes(PERMISSION.VIEW_EDIT_PRIVACY_NOTES) && note.from === DATA_FROM.MANUAL
    );
  }, [permissions, note]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.table_body} data-testid="note-modal">
      <div className={styles.col_1}>{note.platform}</div>
      <div className={styles.col_1}>{moment(note.date).format('DD-MMM-YYYY')}</div>
      <div className={styles.col_2}>{note.notes_content}</div>
      {((isShowActionNonPrivateNote && !note.is_private) ||
        (isShowActionPrivateNote && note.is_private)) && (
        <div className={styles.col_3} ref={ref}>
          <div
            className={styles.ellipsis}
            onKeyDown={() => {}}
            onClick={() => setIsActive(!isActive)}
            data-testid="click-to-update"
          >
            {note.is_editable !== 0 && <img src={ellipsis} alt="ellipsis" className={styles.dot} />}
            {isActive && (
              <div
                className={
                  index + 1 === length
                    ? `${styles.dropdownContent} ${styles.lastItem}`
                    : styles.dropdownContent
                }
              >
                <div
                  className={styles.function}
                  onKeyDown={() => {}}
                  onClick={() => {
                    onChooseItem(note);
                    setIsOpenNote(true);
                    setIsEdit(true);
                    setIsOpen(false);
                  }}
                  data-testid="click-edit"
                >
                  <img src={editNote} className={`mr-2 ${styles.editIcon}`} alt="edit_note" />
                  <div className={styles.content_edit} data-testid="edit-title">
                    Edit
                  </div>
                </div>
                <div
                  className={styles.function}
                  onKeyDown={() => {}}
                  onClick={() => {
                    onChooseItem(note);
                    setIsPopupDelete(true);
                    setIsOpen(false);
                  }}
                  data-testid="click-delete"
                >
                  <img src={deleteNote} className={`mr-2 ${styles.deleteIcon}`} alt="delete_note" />
                  <div className={styles.content_delete} data-testid="delete-title">
                    Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const ViewNote = ({
  isOpen,
  setIsOpen,
  setIsOpenNote,
  data,
  setIsPopupDelete,
  onChooseItem,
  setIsEdit,
}) => {
  const { permissions } = useSelector((state) => state.user);

  const isAddNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return (
      permissions.includes(PERMISSION.TP_EDIT_NON_PRIVATE_NOTE) ||
      permissions.includes(PERMISSION.VIEW_EDIT_PRIVACY_NOTES)
    );
  }, [permissions]);

  const handleRenderData = useMemo(() => {
    return data?.map((item, idx) => (
      <NoteModal
        note={item}
        key={idx || item?.id}
        setIsPopupDelete={setIsPopupDelete}
        setIsOpen={setIsOpen}
        setIsEdit={setIsEdit}
        setIsOpenNote={setIsOpenNote}
        onChooseItem={onChooseItem}
        index={idx}
        length={data.length}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        dialogClassName={styles.view_note}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <h1 data-testid="view-note-title">Notes</h1>
        </Modal.Header>
        {isAddNote && (
          <div className={styles.content}>
            <BasicButton
              mode="teal"
              onKeyDown={() => {}}
              onClick={() => {
                setIsOpen(false);
                setIsOpenNote(true);
              }}
              data-testid="click-add-note"
            >
              Add Note
            </BasicButton>
          </div>
        )}
        <Modal.Body className={styles.table}>
          <div className={styles.table_header}>
            <div className={styles.col_1}>Platform</div>
            <div className={styles.col_1}>Dates</div>
            <div className={styles.col_2}>Notes</div>
          </div>
          <div data-testid="render-note">{handleRenderData}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewNote;
