import React, { useEffect, useRef, useState } from 'react';

import Rectangle from '../../assets/img/Rectangle.svg';
import { ITEM_EDIT_TAGGING } from '../../utils/constants';
import styles from './dropdown-edit-tagging.module.scss';

const DropdownEditTagging = ({
  listOption,
  type,
  setIsCheckDropdown,
  kind,
  ele,
  loading,
  indexEleEdit,
  setItemChoose,
  listOptionRaw,
}) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  const display = {
    Options: isActive
      ? `${styles.dropdownContent} ${styles.dropdownContent__visible} `
      : styles.dropdownContent,
    width: () => {
      if (type === 'Short') return { maxWidth: '36px' };
    },
  };

  useEffect(() => {
    if (isActive && kind !== 'HIDE') {
      setIsCheckDropdown((preState) => {
        return {
          ...preState,
          kind,
          isChecked: true,
          query: handleCheckQuery(),
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
        setIsCheckDropdown((preState) => {
          return {
            ...preState,
            isChecked: false,
          };
        });
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive, setIsCheckDropdown]);

  const handleCheckQuery = () => {
    if (kind === 'ATTRIBUTE') return ele.edge;
    if (kind === 'EDGE') return ele.attr27;
  };
  const handleShowSelectLabel = () => {
    if (kind === 'EDGE') return ele.edge;
    if (kind === 'ATTRIBUTE') return ele.attr27;
    if (kind === 'HIDE') {
      if (Number(ele?.hide_flag) === 0) {
        return 'No';
      }
      if (Number(ele?.hide_flag) === 1) {
        return 'Yes';
      }
      if (Boolean(ele?.status) === false || ele?.status == null) {
        return 'No';
      }

      return 'Yes';
    }
  };
  const handleShowListOption = (eleOption) => {
    if (kind === 'EDGE' || kind === 'ATTRIBUTE') return eleOption;
    if (kind === 'HIDE') return eleOption ? 'Yes' : 'No';
  };
  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={styles.dropdownSelect}
        onKeyDown={() => {}}
        onClick={() => {
          setIsActive((preState) => !preState);
        }}
        style={type && display.width()}
      >
        <p className={styles.dropdownSelect__label}>{handleShowSelectLabel()}</p>
        <span>
          <img src={Rectangle} alt="rectangle" />
        </span>
      </div>
      <div className={display.Options}>
        <div className={styles.dropdownContent__list}>
          {loading ? (
            <div className={styles.dropdown__loading}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            listOption.map((item, index) => {
              return (
                <div
                  className={styles.dropdownContent__item}
                  key={index}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setItemChoose((preState) => {
                      switch (kind) {
                        case ITEM_EDIT_TAGGING.EDGE: {
                          let attr27 = ele.attr27;
                          if (ele.attr27 === 'Not applicable') {
                            let correspondingAttri = listOptionRaw.find(
                              (itemRaw) => itemRaw.edge === item
                            );
                            attr27 = correspondingAttri.attr27;
                          }
                          return {
                            ...preState,
                            ...ele,
                            attr27: attr27,
                            indexEleEdit: indexEleEdit,
                            edge: item,
                            edit: true,
                          };
                        }
                        case ITEM_EDIT_TAGGING.ATTRIBUTE: {
                          return {
                            ...preState,
                            ...ele,
                            indexEleEdit: indexEleEdit,
                            attr27: item,
                            edit: true,
                          };
                        }

                        case ITEM_EDIT_TAGGING.HIDE: {
                          return {
                            ...preState,
                            ...ele,
                            indexEleEdit: indexEleEdit,
                            hide_flag: item ? 1 : 0,
                            status: item,
                            edit: true,
                          };
                        }
                        default:
                          break;
                      }
                    });
                    setIsActive(false);
                  }}
                >
                  <p className={styles.dropdownContent__itemLabel}>{handleShowListOption(item)}</p>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
export default DropdownEditTagging;
