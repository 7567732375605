import React from 'react';
import styles from './working-experience.module.scss';

import { Col, Row, Switch } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { BasicButton } from '../../../../../../assets/common';
import { add_working_experience, external_trash } from '../../../../../../assets/img';
import FormDate from '../../../../../../components/Form/FormDate/FormDate';
import FormDropdown from '../../../../../../components/Form/FormDropdown/FormDropdown';
import FormInput from '../../../../../../components/Form/FormInput/FormInput';
import FromTextArea from '../../../../../../components/Form/FormTextArea/FromTextArea';
import moment from 'moment';

export const FirstCol = (props) => {
  const { data, convert, index } = props;

  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>
          <Row justify="space-between">
            <Col style={{ width: '41%' }}>Position</Col>
            <Col style={{ width: '41%' }}>
              <span style={{ marginRight: '10px' }}>Current Position</span>
              <Switch
                onChange={(checked) => {
                  convert(index, 'current_position', checked);
                }}
                checked={data.current_position}
                style={{ backgroundColor: `${data.current_position ? '#009089' : ''}` }}
              />
            </Col>
          </Row>
        </div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Position"
          value={data.position}
          setFormData={(value) => {
            convert(index, 'position', value);
          }}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Company</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Company"
          value={data.company}
          setFormData={(value) => {
            convert(index, 'company', value);
          }}
        />
      </div>
      <div className={styles.sub_title}>Job Description</div>
      <FromTextArea
        borderColor="#D3DEE8"
        placeholder="Enter Job description"
        rows={6}
        value={data.job_description}
        setFormData={(value) => {
          convert(index, 'job_description', value);
        }}
      />
    </>
  );
};

FirstCol.propTypes = {
  convert: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
};

export const SecondCol = (props) => {
  const { data, convert, isError, role_levels, index } = props;

  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Department</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Department"
          value={data.department}
          setFormData={(value) => {
            convert(index, 'department', value);
          }}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Business</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Business"
          value={data.business}
          setFormData={(value) => {
            convert(index, 'business', value);
          }}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Role</div>
        <FormDropdown
          borderColor="#D3DEE8"
          placeholder="Select Role"
          value={data.role}
          setFormData={(value) => {
            convert(index, 'role', value);
          }}
          options={role_levels}
        />
      </div>
      <div>
        <div className={styles.sub_title}>From Date</div>
        <FormDate
          borderColor="#D3DEE8"
          placeholder="Enter Date"
          value={data.from_date}
          setFormData={(value) => {
            convert(index, 'from_date', moment(value).format());
          }}
          isError={
            isError &&
            data.from_date &&
            data.to_date &&
            moment(moment(data.from_date).format('YYYY-MM-DD')).isAfter(
              moment(moment(data.to_date).format('YYYY-MM-DD'))
            )
          }
          messageErr="From date must be less than or equal To date."
        />
      </div>
    </>
  );
};

SecondCol.propTypes = {
  convert: PropTypes.func,
  data: PropTypes.object,
  isError: PropTypes.bool,
  role_levels: PropTypes.array,
  index: PropTypes.number,
};

export const ThirdCol = (props) => {
  const { data, convert, isError, index } = props;

  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Division</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Division"
          value={data.division}
          setFormData={(value) => {
            convert(index, 'division', value);
          }}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Function</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Function"
          value={data.function}
          setFormData={(value) => {
            convert(index, 'function', value);
          }}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Industry</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Industry"
          value={data.industry}
          setFormData={(value) => {
            convert(index, 'industry', value);
          }}
        />
      </div>
      <div>
        <div className={styles.sub_title}>To Date</div>
        <FormDate
          borderColor="#D3DEE8"
          placeholder="Enter Date"
          value={data.to_date}
          setFormData={(value) => {
            convert(index, 'to_date', moment(value).format());
          }}
          isError={
            isError &&
            data.from_date &&
            data.to_date &&
            moment(moment(data.from_date).format('YYYY-MM-DD')).isAfter(
              moment(moment(data.to_date).format('YYYY-MM-DD'))
            )
          }
          messageErr="From date must be less than or equal To date."
        />
      </div>
    </>
  );
};

ThirdCol.propTypes = {
  convert: PropTypes.func,
  data: PropTypes.object,
  isError: PropTypes.bool,
  index: PropTypes.number,
};

const WorkingExperience = (props) => {
  const { data, setData, isError, role_levels } = props;
  function addWorkingExperiencs() {
    setData('working_experience', [
      ...data,
      {
        position: '',
        current_position: false,
        company: '',
        job_description: '',
        department: '',
        business: '',
        role: '',
        from_date: undefined,
        division: '',
        function: '',
        industry: '',
        to_date: undefined,
      },
    ]);
  }

  function delWorkingExperiencs(delIndex) {
    let newWorkingExperience = data;
    setData(
      'working_experience',
      newWorkingExperience.filter((item, index) => index !== delIndex)
    );
  }

  function convert(editIndex, field, value) {
    let newData = data;

    newData = newData.map((item, index) => {
      if (field === 'current_position') {
        item.current_position = false;
      }
      if (index === editIndex) item[field] = value;
      return item;
    });
    setData('working_experience', newData);
  }
  return (
    <div className={styles.margin}>
      <div className={styles.title}>Working Experience</div>
      {!isEmpty(data) &&
        data
          .sort(function (x, y) {
            return x.current_position === y.current_position ? 0 : x.current_position ? -1 : 1;
          })
          .map((item, index) => (
            <div style={{ padding: '20px' }} key={`working-experiences-${index}`}>
              <Row justify="space-between">
                <div className={styles.title_form}>Career {index + 1}</div>
                <img
                  src={external_trash}
                  alt={external_trash}
                  className={styles.btn}
                  onKeyDown={() => {}}
                  onClick={() => {
                    delWorkingExperiencs(index);
                  }}
                />
              </Row>
              <Row justify="space-between">
                <Col style={{ width: '31%' }}>
                  <FirstCol data={item} convert={convert} index={index} />
                </Col>
                <Col style={{ width: '31%' }}>
                  <SecondCol
                    data={item}
                    convert={convert}
                    isError={isError}
                    role_levels={role_levels}
                    index={index}
                  />
                </Col>
                <Col style={{ width: '31%' }}>
                  <ThirdCol data={item} convert={convert} isError={isError} index={index} />
                </Col>
              </Row>
            </div>
          ))}

      <div style={{ marginTop: '15px', width: '200px' }}>
        <BasicButton mode="border-teal" onKeyDown={() => {}} onClick={addWorkingExperiencs}>
          <img src={add_working_experience} alt={add_working_experience} />
        </BasicButton>
      </div>
    </div>
  );
};

WorkingExperience.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
  isError: PropTypes.bool,
  role_levels: PropTypes.array,
};

export default WorkingExperience;
