import { isEmpty } from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import AlertNotification from '../../../../../components/AlertMessage/AlertNotification';
import { MASTER_DATA_MANAGEMENT } from '../../../../../utils/constants';

const AlertMessagePlace = (props, ref) => {
  const STYLE_ALERT = { margin: '20px 0 -2px 0' };
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showSearchNotFound, setShowSearchNotFound] = useState(false);
  const [showAddBusinessSuccess, setShowAddBusinessSuccess] = useState(false);
  const [showAddBusinessExist, setShowAddBusinessExist] = useState(false);
  const [showEditBusinessSuccess, setShowEditBusinessSuccess] = useState(false);
  const [showDeleteBusinessMultipleSuccess, setShowDeleteBusinessMultipleSuccess] = useState(false);
  const [showDeleteBusinessWarning, setShowDeleteBusinessWarning] = useState(false);
  const [deleteBusinessMultiple, setDeleteBusinessMultiple] = useState(null);

  useImperativeHandle(
    ref,
    () => ({
      handleShowDeleteSuccess: (record) => {
        setDeleteRecord(record);
      },
      handleShowSearchNotFound: () => {
        setShowSearchNotFound(true);
      },
      handleAddBusinessSuccess: () => {
        setShowAddBusinessSuccess(true);
      },
      handleAddBusinessExist: () => {
        setShowAddBusinessExist(true);
      },
      handleEditBusinessSuccess: () => {
        setShowEditBusinessSuccess(true);
      },
      handleDeleteBusinessMultipleSuccess: (deletes) => {
        setDeleteBusinessMultiple(deletes);
        setShowDeleteBusinessMultipleSuccess(true);
      },
      handleDeleteBusinessWarning: () => {
        setShowDeleteBusinessWarning(true);
      },
    }),
    []
  );

  useEffect(() => {
    if (!deleteRecord) return;
    setShowDeleteSuccess(true);
  }, [deleteRecord]);

  return (
    <>
      <AlertNotification
        type="success"
        message={`${deleteRecord?.position_code} ${deleteRecord?.plan_name} successfully deleted`}
        show={showDeleteSuccess}
        setShow={setShowDeleteSuccess}
        timeShow={5000}
        style={STYLE_ALERT}
      />
      <AlertNotification
        type="error"
        message={MASTER_DATA_MANAGEMENT.BUSINESS_SEARCH_NOT_FOUND}
        show={showSearchNotFound}
        setShow={setShowSearchNotFound}
        timeShow={5000}
        style={STYLE_ALERT}
      />
      <AlertNotification
        type="success"
        message={MASTER_DATA_MANAGEMENT.ADD_BUSINESS_SUCCESS}
        show={showAddBusinessSuccess}
        setShow={setShowAddBusinessSuccess}
        timeShow={5000}
        style={STYLE_ALERT}
      />
      <AlertNotification
        type="error"
        message={MASTER_DATA_MANAGEMENT.ADD_BUSINESS_EXIST}
        show={showAddBusinessExist}
        setShow={setShowAddBusinessExist}
        timeShow={5000}
        style={STYLE_ALERT}
      />
      <AlertNotification
        type="success"
        message={MASTER_DATA_MANAGEMENT.EDIT_BUSINESS_SUCCESS}
        show={showEditBusinessSuccess}
        setShow={setShowEditBusinessSuccess}
        timeShow={5000}
        style={STYLE_ALERT}
      />
      {/* <AlertNotification
        type="success"
        message={MASTER_DATA_MANAGEMENT.DELETE_BUSINESS_SUCCESS}
        show={showDeleteBusinessSuccess}
        setShow={setShowDeleteBusinessSuccess}
        timeShow={5000}
        style={STYLE_ALERT}
      /> */}
      <AlertNotification
        type="success"
        message={
          !isEmpty(deleteBusinessMultiple)
            ? `Business Unit ${deleteBusinessMultiple
                .map((item) => `${item.business_unit}`)
                .join(',')} successfully deleted`
            : ''
        }
        show={showDeleteBusinessMultipleSuccess}
        setShow={setShowDeleteBusinessMultipleSuccess}
        timeShow={5000}
        style={STYLE_ALERT}
      />
      <AlertNotification
        type="warning"
        message={
          <div>
            <div>Business Unit is in use.</div>
            <div>
              Please ensure Business Unit is not relate with other module in order to proceed with
              the business unit deletion.
            </div>
          </div>
        }
        show={showDeleteBusinessWarning}
        setShow={setShowDeleteBusinessWarning}
        timeShow={5000}
        style={STYLE_ALERT}
      />
    </>
  );
};

export default React.forwardRef(AlertMessagePlace);
