import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { stylesDanger, stylesDisabled, stylesNormal, theme } from './Styled';

const AsyncMultipleSelect = ({
  loadOptions,
  placeholder,
  onChange,
  value,
  status,
  isDisabled,
  isMulti = true,
  className,
}) => {
  const [currentStatus, setCurrentStatus] = useState(stylesNormal);
  useEffect(() => {
    setCurrentStatus(status === 'error' ? stylesDanger : stylesNormal)
  }, [status])
  return (
    <AsyncSelect
      isDisabled={isDisabled}
      cacheOptions
      isMulti={isMulti}
      isSearchable
      placeholder={placeholder}
      styles={isDisabled ? stylesDisabled : currentStatus}
      theme={theme}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      menuPlacement="auto"
      maxMenuHeight={180}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      className={className}
    />
  );
};
export default AsyncMultipleSelect;
