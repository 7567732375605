import React, { useMemo } from 'react';
import { ar_down } from '../../assets/img';

import { AiTreeSelect } from './styled';

const initStyle = {
  height: 42,
  hasChild: false,
};

const CustomDropdownTreeSelect = ({ placeholder, data, value, setValue, onChange, styles }) => {
  const treeData = useMemo(
    () => [{ label: 'All', value: 'all', field: placeholder }, ...data],
    [data, placeholder]
  );

  const handleOnChange = (values, _, { triggerValue, checked }) => {
    const allValues = treeData.map((item) => item);
    if (triggerValue === 'all') {
      setValue(checked ? allValues : []);
    } else {
      const isSelectAll = treeData.length - 1 === values.length;
      const valuesNoAll = data.filter((item) => values.includes(item.value));
      setValue(isSelectAll && checked ? allValues : valuesNoAll);
    }
    onChange && onChange();
  };

  return (
    <AiTreeSelect
      maxTagCount={0}
      treeCheckable={true}
      showArrow={true}
      showSearch={false}
      suffixIcon={<img src={ar_down} alt="drop_down" />}
      getPopupContainer={(trigger) => trigger.parentElement}
      treeData={treeData}
      value={value ? value.map((item) => item.value) : []}
      placeholder={placeholder}
      maxTagPlaceholder={placeholder}
      onChange={handleOnChange}
      styles={{ ...initStyle, ...styles }}
    />
  );
};

export default CustomDropdownTreeSelect;
