import { DatePicker, Select } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { isCheckError } from '../../hooks/useFormik';
import { AiInput } from '../../pages/Admin/components/NewSuccessionPlanningForm/Styled';
import { AiTextarea } from '../../pages/Meeting/MeetingDetails/Styled';
import { MOBILITY_PLAN_TYPE_OPTION, ROLE_LEVEL_LIST } from '../../utils/constants';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './mobility-plan.module.scss';

const AddEditMobilityPlan = ({ open, setOpen, isEdit, handleSubmit, dataEdit }) => {
  const formik = useFormik({
    initialValues: {
      type: dataEdit?.type || null,
      platform: dataEdit?.platform || null,
      date: dataEdit?.platform_date ? moment(dataEdit.platform_date) : null,
      detail: dataEdit?.detail || '',
    },
    validationSchema: Yup.object({
      type: Yup.string().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      platform: Yup.string().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      date: Yup.date().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      detail: Yup.string().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (value) => {
      const dataNote = {
        type: value?.type?.trim(),
        platform: value?.platform?.trim(),
        detail: value?.detail?.trim(),
        date: moment(value?.date).format('YYYY-MM-DD'),
      };
      handleSubmit(dataNote, formik.handleReset);
    },
  });

  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      title={isEdit ? 'Edit Mobility Plan' : 'Add Mobility Plan'}
      onOk={formik.submitForm}
      onCancel={() => formik.handleReset()}
      okText={isEdit ? 'Save Changes' : 'Add Mobility Plan'}
      width={1000}
      destroyOnClose
    >
      <div className={styles.body}>
        <h4 className={styles.title}>
          {isEdit ? 'Edit Mobility Plan for the Talent' : 'Add Mobility Plan for the Talent'}
        </h4>

        <div className="row mb-3">
          <div className="col-4">
            <div className={`${styles.label}`}>
              Mobility Plan Type<span style={{ color: 'red' }}>*</span>
            </div>
            <Select
              size="middle"
              options={MOBILITY_PLAN_TYPE_OPTION}
              getPopupContainer={(trigger) => trigger.parentElement}
              name="type"
              style={
                formik.errors.type && formik.touched.type
                  ? {
                      border: '1px solid red',
                      borderRadius: '4px',
                      height: '0px',
                      width: '100%',
                    }
                  : {
                      borderRadius: '4px',
                      height: '0px',
                      width: '100%',
                    }
              }
              placeholder="Select Mobility Plan Type"
              value={formik.values.type}
              onChange={(value) => {
                formik.setFieldValue('type', value);
              }}
            />
            {formik.errors.type && formik.touched.type && (
              <div className={styles.text_error}> {formik.errors.type}</div>
            )}
          </div>
          <div className="col-4">
            <div className={`${styles.label}`} style={{ alignItems: 'unset' }}>
              Platform<span style={{ color: 'red' }}>*</span>
            </div>
            <AiInput
              name="platform"
              placeholder="Enter Platform Name"
              className={styles.formInput}
              value={formik.values.platform}
              onChange={formik.handleChange}
              status={isCheckError(formik, 'platform') ? 'error' : ''}
              data-testid="platform"
            />
            {formik.errors.platform && formik.touched.platform && (
              <div className={styles.text_error}> {formik.errors.platform}</div>
            )}
          </div>
          <div className="col-4">
            <div className={`${styles.label}`}>
              Date<span style={{ color: 'red' }}>*</span>
            </div>
            <DatePicker
              name="date"
              getPopupContainer={(trigger) => trigger.parentElement}
              value={formik.values.date}
              className={styles.formInput}
              onChange={(newDate) => {
                formik.setFieldValue('date', newDate);
              }}
              format="DD MMMM YYYY"
              inputReadOnly
              placeholder="Select Date"
              style={
                formik.errors.date && formik.touched.date
                  ? { border: '1px solid red' }
                  : { border: '1px solid #e0e0e0' }
              }
            />
            {formik.errors.date && formik.touched.date && (
              <div className={styles.text_error}> {formik.errors.date}</div>
            )}
          </div>
          <div className="col-12" style={{ marginTop: '16px' }}>
            <div className={`${styles.label}`} style={{ alignItems: 'unset' }}>
              Detail<span style={{ color: 'red' }}>*</span>
            </div>
            <AiTextarea
              name="detail"
              placeholder="Enter Detail"
              className={styles.formInput}
              value={formik.values.detail}
              onChange={formik.handleChange}
              status={isCheckError(formik, 'detail') ? 'error' : ''}
              data-testid="note-textarea"
              style={{ padding: '10px', height: '132px' }}
              rows={5}
            />
            {formik.errors.detail && formik.touched.detail && (
              <div className={styles.text_error}> {formik.errors.detail}</div>
            )}
          </div>
        </div>
      </div>
    </CustomAntModal>
  );
};

export default AddEditMobilityPlan;
