import React from 'react';
import PropTypes from 'prop-types';

import styles from './approved-sp.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDate } from '../../utils/helper';

const ApprovedSpItem = ({ line }) => {
  return line.map((element) => (
    <div key={element.position} className={styles.item}>
      <p>
        <OverlayTrigger
          overlay={
            <Tooltip id="button-tooltip" className={styles.my_tooltip}>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '0.173px',
                  color: '#fff',
                  textAlign: 'left',
                  padding: '10px 20px',
                }}
              >
                {element.position}
                <div>
                  {element.department && (
                    <div
                      className="d-flex justify-content-start align-items-center pl-2"
                      style={{ gap: '10px' }}
                    >
                      <div
                        style={{
                          width: '5px',
                          height: '5px',
                          borderRadius: '50%',
                          background: '#fff',
                        }}
                      ></div>
                      Department: {element.department}
                    </div>
                  )}
                  {element.division && (
                    <div
                      className="d-flex justify-content-start align-items-center pl-2"
                      style={{ gap: '10px' }}
                    >
                      <div
                        style={{
                          width: '5px',
                          height: '5px',
                          borderRadius: '50%',
                          background: '#fff',
                        }}
                      ></div>
                      Division: {element.division}
                    </div>
                  )}
                  {element.opu && (
                    <div
                      className="d-flex justify-content-start align-items-center pl-2"
                      style={{ gap: '10px' }}
                    >
                      <div
                        style={{
                          width: '5px',
                          height: '5px',
                          borderRadius: '50%',
                          background: '#fff',
                        }}
                      ></div>
                      Opu: {element.opu}
                    </div>
                  )}
                  {element.business && (
                    <div
                      className="d-flex justify-content-start align-items-center pl-2"
                      style={{ gap: '10px' }}
                    >
                      <div
                        style={{
                          width: '5px',
                          height: '5px',
                          borderRadius: '50%',
                          background: '#fff',
                        }}
                      ></div>
                      Business: {element.business}
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
          }
        >
          <span
            style={{ cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() =>
              window.open(
                '/agenda/succession-planning-dashboard/' + element.position_code,
                '_blank'
              )
            }
            role='button'
          >
            {element.position}
          </span>
        </OverlayTrigger>
      </p>
      <p>
        {element.platform} {formatDate(element.approved_date)}
      </p>
    </div>
  ));
};

ApprovedSpItem.propTypes = {
  line: PropTypes.array,
};

export default ApprovedSpItem;
