import { Select } from 'antd';
import { isEmpty, size } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import {
  ADVANCED_FILTER_OPTION,
  SELECT_ALL_LABEL,
  TALENT_FINDER_FILTER_OPTIONS,
  TALENT_FINDER_FILTERS,
  TALENT_SEARCH_RESULT_OPTIONS,
} from '../../../utils/constants';
import DropdownTreeSelectContainer from '../DropdownTreeSelectContainer/DropdownTreeSelectContainer';
import data from './data.json';

let tempFilter = {
  top_talent_status: [],
  business: [],
  salary_grade: [],
  complementary_experience: [],
  personality: [],
  age: [],
  business_experience: [],
  years_in_position: [],
  nationality: [],
  state_of_birth: [],
  years_to_retire: [],
  gender: [],
  functional_experience: [],
  edge: [],
  traits: [],
  derailment_risks: [],
  learning_agility: [],
  drivers: [],
  successor: [],
  attr27: [],
  competencies: [],
};

let filterTabActive = [];
let preTalentFilterOptions = {};

const FilterFinder = (props) => {
  const { Option } = Select;
  const {
    filterOptions,
    setFilterFinderData,
    setIsFilter,
    keySearch,
    setTalentSearchResult,
    setKeyword,
    setPage,
  } = props;
  const [talentFilterOptions, setTalentFilterOptions] = useState({});
  const [isBlur, setIsBlur] = useState(false);

  const isDisableTalentSearchResult = useMemo(() => {
    return isEmpty(keySearch);
  }, [keySearch]);

  const cloneState = (state) => {
    return JSON.parse(JSON.stringify(state));
  };

  const updateStateData = (filterName, options, newData) => {
    tempFilter[filterName] = options;
    setTalentFilterOptions(newData);
  };

  const getAllKeys = (tree) => {
    let result = [];
    tree.forEach((item) => {
      let childKeys = [];
      if (item?.children) {
        childKeys = getAllKeys(item.children);
      }
      result.push(...[{ label: item?.label, checked: item?.checked }, ...childKeys]);
    });

    return result;
  };

  const onChange = (currentNode) => {
    const filterName = currentNode.path.split('.')[0];
    let indexArr = getIndexList(currentNode.path);
    const newData = cloneState(talentFilterOptions);
    let options = newData[filterName];
    let selectedOption;
    const optionParentLv2 = options[indexArr[0]];

    const handleCheckedParent = (parentOption) => {
      const optionsChecked = parentOption.children.filter((optionLv2) => {
        return optionLv2.checked === true;
      });

      if (optionsChecked.length > 0) {
        parentOption.checked = true;
      } else {
        parentOption.checked = false;
      }
    };

    switch (indexArr.length) {
      case 1: //onChange lv1 of dropdown select tree
        selectedOption = options[indexArr[0]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        options.forEach((option) => {
          if (option.label !== currentNode.label) {
            option.expanded = false;

            if (option.children?.length) {
              option.children.forEach((childItem) => {
                childItem.expanded = false;
              });
            }
          }
        });

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
            childOption.expanded = true;

            if (childOption.children?.length) {
              childOption.children.forEach((i) => {
                i.checked = currentNode.checked;
              });
            }
          });
        }
        break;
      case 2: //onChange lv2 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
          });
        }

        handleCheckedParent(optionParentLv2);
        break;
      case 3: //onChange lv3 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]].children[indexArr[2]];
        selectedOption.checked = currentNode.checked;
        const optionParentLv3 = options[indexArr[0]]?.children[indexArr[1]];
        handleCheckedParent(optionParentLv3);
        handleCheckedParent(optionParentLv2);
        break;
      default:
    }

    //handle checked (Select All)
    const allKeys = getAllKeys(options);
    const tempUncheckedItem = allKeys.filter(
      (item) => !item.checked && item.label !== SELECT_ALL_LABEL
    );
    if (tempUncheckedItem.length > 0) {
      options[0].checked = false;
    } else {
      options[0].checked = true;
    }

    updateStateData(filterName, options, newData);
  };

  const onClickSelectAll = (filterName, isChecked) => {
    const newData = cloneState(talentFilterOptions);
    assignObjectPaths(newData[filterName], filterName, isChecked);
    updateStateData(filterName, newData[filterName], newData);
  };

  const onNodeToggle = (currentNode) => {
    const filterName = currentNode.path.split('.')[0];
    let indexArr = getIndexList(currentNode.path);
    const newData = cloneState(talentFilterOptions);
    const options = newData[filterName];
    let currentOption;

    const handleExpandChild = (option, isExpanded) => {
      if (option.children?.length) {
        option.children.forEach((childItem) => {
          childItem.expanded = isExpanded;
        });
      }
    };

    switch (indexArr.length) {
      case 1: //Expand level 1
        currentOption = options[indexArr[0]];

        if (currentNode.expanded) {
          currentOption.expanded = true;

          options.forEach((option) => {
            if (option.label !== currentNode.label) {
              option.expanded = false;
            }
            handleExpandChild(option, false);
          });

          handleExpandChild(currentOption, true);
        } else {
          currentOption.expanded = false;
          handleExpandChild(currentOption, false);
        }
        break;
      case 2: //Expand level 2
        currentOption = options[indexArr[0]].children[indexArr[1]];

        if (!currentNode.expanded) {
          currentOption.expanded = false;
        } else {
          currentOption.expanded = true;
        }
        break;
      default:
    }

    updateStateData(filterName, options, newData);
  };

  const getIndexList = (path) => {
    if (!path.includes('.')) return [];

    let tempArr = path.match(/\.\d+/g);
    tempArr =
      size(tempArr) > 0
        ? tempArr.map((item) => {
            return `${item.replace('.', '')}`;
          })
        : [];
    return tempArr;
  };

  const assignObjectPaths = (obj, stack, isChecked) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === 'object') {
        if (!stack && node[0]?.label !== SELECT_ALL_LABEL) {
          node.unshift({
            label: SELECT_ALL_LABEL,
            checked: isChecked === undefined ? true : isChecked,
          });
        }
        node.path = stack ? `${stack}.${k}` : k;
        node.checked = isChecked === undefined ? true : isChecked;
        assignObjectPaths(node, node.path, isChecked);
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(filterOptions)) {
      assignObjectPaths(data);
      assignObjectPaths(filterOptions);
      setTalentFilterOptions({ ...filterOptions, ...data });
      preTalentFilterOptions = { ...filterOptions, ...data };
    }

    //  Handle clean the state
    return () => {
      handleClearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const handleClickApplyFilter = (item, key) => {
    if (size(tempFilter[key]) <= 0) {
      tempFilter[key] = item;
    }

    if (!filterTabActive.includes(key)) {
      filterTabActive.push(key);
    }

    const dataToPost = getDataToPost();
    setKeyword(keySearch);
    setFilterFinderData(dataToPost);
    preTalentFilterOptions = cloneState(talentFilterOptions);
    setIsFilter(true);
    setPage(1);
  };

  const getDataToPost = () => {
    const top_talent_status = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.TOP_TALENT_STATUS);
    const business_unit = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.BUSINESS);
    const sg = tempFilter[TALENT_FINDER_FILTER_OPTIONS.SALARY_GRADE];
    const complementary_experience = handleGetFilterData(
      TALENT_FINDER_FILTER_OPTIONS.COMPLEMENTARY_EXPERIENCE
    );
    const personality_archetype = tempFilter[TALENT_FINDER_FILTER_OPTIONS.PERSONALITY];
    const age_range = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.AGE);
    const business = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.BUSINESS_EXPERIENCE);
    const previous_business_experience = handleGetFilterData(
      TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE
    );
    const years_in_position = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.YEARS_IN_POSITION);
    const years_to_retirement = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.YEARS_TO_RETIRE);
    const nationality = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.NATIONALITY);
    const state_of_birth = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.STATE_OF_BIRTH);
    const gender = handleGetFilterData(TALENT_FINDER_FILTER_OPTIONS.GENDER);
    const successor = tempFilter[TALENT_FINDER_FILTER_OPTIONS.SUCCESSOR];
    const functional_experience = tempFilter[TALENT_FINDER_FILTER_OPTIONS.FUNCTIONAL_EXPERIENCE];
    const previous_functional_experience =
      tempFilter[TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE];
    const edge = tempFilter[TALENT_FINDER_FILTER_OPTIONS.EDGE];
    const traits = tempFilter[TALENT_FINDER_FILTER_OPTIONS.TRAITS];
    const derailment_risks = tempFilter[TALENT_FINDER_FILTER_OPTIONS.DERAILMENT_RISKS];
    const learning_agility = tempFilter[TALENT_FINDER_FILTER_OPTIONS.LEARNING_AGILITY];
    const drivers = tempFilter[TALENT_FINDER_FILTER_OPTIONS.DRIVERS];
    const attr27 = tempFilter[TALENT_FINDER_FILTER_OPTIONS.ATTRIBUTES];
    const competencies = tempFilter[TALENT_FINDER_FILTER_OPTIONS.COMPETENCIES];
    return {
      top_talent_status,
      business_unit,
      sg,
      complementary_experience,
      personality_archetype,
      age_range,
      business,
      previous_business_experience,
      years_in_position,
      years_to_retirement,
      nationality,
      state_of_birth,
      gender,
      successor,
      functional_experience,
      previous_functional_experience,
      edge,
      traits,
      derailment_risks,
      attr27,
      competencies,
      learning_agility,
      drivers,
      attr27,
    };
  };

  const handleGetFilterData = (filterName) => {
    return tempFilter[filterName]
      ? tempFilter[filterName].filter((d) => d.checked).map((item) => item.value)
      : [];
  };

  const onBlur = (item, key) => {
    talentFilterOptions[key] = item;
    setTalentFilterOptions(preTalentFilterOptions);
    setIsBlur(true);
  };

  const handleClearFilter = () => {
    Object.keys(tempFilter).forEach((key) => {
      tempFilter[key] = [];
    });
    filterTabActive = [];
    setIsFilter(false);
    setFilterFinderData({});
    assignObjectPaths(data);
    assignObjectPaths(filterOptions);
    setTalentFilterOptions({ ...filterOptions, ...data });
    preTalentFilterOptions = { ...filterOptions, ...data };
    setPage(1);
  };

  const handleReturnNumberColumns = (key) => {
    if (
      [
        TALENT_FINDER_FILTER_OPTIONS.EDGE,
        TALENT_FINDER_FILTER_OPTIONS.BUSINESS_EXPERIENCE,
        TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE,
        TALENT_FINDER_FILTER_OPTIONS.FUNCTIONAL_EXPERIENCE,
        TALENT_FINDER_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE,
        TALENT_FINDER_FILTER_OPTIONS.COMPLEMENTARY_EXPERIENCE,
      ].includes(key)
    ) {
      return 4;
    } else if (
      [
        TALENT_FINDER_FILTER_OPTIONS.LEARNING_AGILITY,
        TALENT_FINDER_FILTER_OPTIONS.TRAITS,
        TALENT_FINDER_FILTER_OPTIONS.DRIVERS,
        TALENT_FINDER_FILTER_OPTIONS.DERAILMENT_RISKS,
        TALENT_FINDER_FILTER_OPTIONS.COMPETENCIES,
      ].includes(key)
    ) {
      return 2.5;
    } else {
      return 2;
    }
  };

  const handleChange = (value) => {
    if (TALENT_SEARCH_RESULT_OPTIONS[0].value === value) {
      setTalentSearchResult(ADVANCED_FILTER_OPTION.SHOW_ONLY_MATCHING_RESULT);
    } else {
      setTalentSearchResult(ADVANCED_FILTER_OPTION.SHOW_ALL_RELATED_RESULT);
    }
  };

  return (
    <div className={'filter_section row px-0'}>
      <div className={'col-2 px-1'} style={{ position: 'relative' }}>
        <Select
          style={{
            width: 208,
          }}
          placeholder="Talent Search Result"
          className={'customFinderSelect'}
          onChange={handleChange}
          disabled={isDisableTalentSearchResult}
        >
          {TALENT_SEARCH_RESULT_OPTIONS.map((item) => (
            <Option
              className={'customFinderOption'}
              value={item.value}
              label={item.label}
              key={item.value}
            />
          ))}
        </Select>
      </div>
      {!isEmpty(talentFilterOptions) &&
        TALENT_FINDER_FILTERS.map((item) =>
          Object.keys(talentFilterOptions).map((key, index) => {
            if (item.value === key) {
              return (
                <DropdownTreeSelectContainer
                  key={`${key}.${index}`}
                  keyWord={key}
                  filterTabActive={filterTabActive}
                  label={item.label}
                  placeholder={item.label}
                  data={talentFilterOptions[key]}
                  onChange={onChange}
                  onNodeToggle={onNodeToggle}
                  onBlur={() => onBlur(talentFilterOptions[key], key)}
                  onClickSelectAll={(isChecked) => onClickSelectAll(key, isChecked)}
                  isBlur={isBlur}
                  onApply={() => handleClickApplyFilter(talentFilterOptions[key], key)}
                  filterName={key}
                  columns={handleReturnNumberColumns(key)}
                />
              );
            }
            return null;
          })
        )}
      {size(filterTabActive) > 0 && (
        <div className={'col-12 px-0'}>
          <button className={'clear_filter_button'} onClick={handleClearFilter}>
            Clear Filters
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterFinder;
