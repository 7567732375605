import { isEmpty, size, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DropdownEDGE, DropdownEDGEyear, EDGEChart, EditTagging, ModalFeedbackSummary } from '..';
import arrowRight from '../../assets/img/arrowRight.svg';
import pen from '../../assets/img/vector.svg';
import talentProfileApi, { fetchLeaderShipsFeedback } from '../../services/talentProfiles';
import { EDGE_OPTION_LABEL, FEED_BACK, PERMISSION } from '../../utils/constants';
import styles from './edge-view.module.scss';

const EDGEView = ({ id, roleUser }) => {
  const [selectedYear, setSelectedYear] = useState('All');
  const [fullOptionYears, setFulloptionYears] = useState([]);
  const [optionYearsAfterChosen, setOptionYearsAfterChosen] = useState([]);
  const [listSelectType, setListSelectType] = useState([]);
  const [listSelectTypeEdge, setListSelectTypeEdge] = useState([]);
  const [filtersOption, setFiltersOption] = useState([]);
  const [feedbacks10, setFeedbacks10] = useState([]);
  const [isCheckSubmitFB, setIsCheckSubmitFB] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loadingFeedbackSummary, setLoadingFeedbackSummary] = useState(false);
  const [isDisableYearAllOption, setIsDisableYearAllOption] = useState(false);
  const [isYearsDisabled, setIsYearsDisabled] = useState(true);
  const [isAllBtnDisabled, setIsAllBtnDisabled] = useState(false);
  const limitFB = 500;
  const handleShow = () => setShow(true);
  const info = {
    roleUser,
    id,
  };
  const { permissions } = roleUser;

  const isHasEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_VERBATIM_FEEDBACK_TAGGING);
  }, [permissions]);

  const optionYear = () => {
    let year = Number(moment().year());
    let listOptionYear = [year];

    for (let i = 1; i <= 4; i++) {
      listOptionYear.push(--year);
    }
    listOptionYear.unshift('All');
    return listOptionYear;
  };

  useEffect(() => {
    let fullyear = [...fullOptionYears];
    setOptionYearsAfterChosen(fullyear);
  }, [selectedYear, fullOptionYears]);

  useEffect(() => {
    setFulloptionYears(optionYear());
  }, []);

  const getLeaderShipFeedback = async (limitPerPage) => {
    let edgeSearch = {};
    if (listSelectTypeEdge.length > 0) {
      let edge = listSelectTypeEdge.map((item) => {
        return `'` + item.label + `'`;
      });
      edgeSearch = { edge: edge.join(',') };
    }
    let params = {};
    if (selectedYear === 'All') {
      params = {
        latestYears: 5,
        limit: limitPerPage,
        page: 1,
      };
    } else {
      params = {
        year: selectedYear,
        limit: limitPerPage,
        page: 1,
      };
    }
    setLoadingFeedbackSummary(true);
    let optionFilter = [];
    if (size(listSelectType) > 0) {
      let filter = [];
      await Promise.all(
        listSelectType.map(async (eleS) => {
          let res = await talentProfileApi.getFilterFeedbacks({
            profileId: id,
            roleUser,
            params: { type: eleS.value },
          });
          if (res.status === 200 && size(res.data.result) > 0) {
            res.data.result.forEach((ele) => {
              filter.push(ele.edge);
            });
          }
        })
      );
      let uniqFilter = uniq(filter);
      optionFilter = uniqFilter.map((eleUniq) => ({
        label: eleUniq,
        value: eleUniq,
      }));
    }
    try {
      let response = await fetchLeaderShipsFeedback({ roleUser, params, id, body: edgeSearch });
      if (response && response.data && response.data.code) {
        if (response.data && response.data.result && response.data.result.feedbacks) {
          setFeedbacks10(response.data.result.feedbacks);
          setFiltersOption(optionFilter);
        }
      }
      setLoadingFeedbackSummary(false);
    } catch (error) {
      setLoadingFeedbackSummary(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (show) {
      getLeaderShipFeedback(limitFB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, roleUser.roleActive.roleId, id, show, isCheckSubmitFB, listSelectTypeEdge]);

  const handleShowEdit = () => setShowEdit(true);

  const onChangeSelectedYear = (value, allChart) => {
    let year = 'All';
    let disableAllOption = isDisableYearAllOption;
    let yearsDisabled = isYearsDisabled;
    if (allChart !== 3) {
      switch (value) {
        case EDGE_OPTION_LABEL.EXTERNAL:
        case EDGE_OPTION_LABEL.SMA:
          year = 2023;
          disableAllOption = true;
          yearsDisabled = false;
          break;
        case EDGE_OPTION_LABEL.MODEL:
          yearsDisabled = true;
          disableAllOption = false;
          break;
        default:
          yearsDisabled = false;
          disableAllOption = false;
          break;
      }
      setSelectedYear(year);
      setIsDisableYearAllOption(disableAllOption);
      setIsYearsDisabled(yearsDisabled);
    } else {
      setSelectedYear(year);
    }
  };

  return (
    <>
      <div className={styles.edge} data-testid="edge">
        <div className={styles.edge__header}>
          <h3 className={styles.edge__h3}>EDGE</h3>
          <div className={styles.edge__filter}>
            <DropdownEDGE
              listSelectType={listSelectType}
              setListSelectType={setListSelectType}
              onChangeSelectedYear={onChangeSelectedYear}
              setIsAllBtnDisabled={setIsAllBtnDisabled}
            />
            <div className={styles.edge__dropcs}>
              <DropdownEDGEyear
                options={optionYearsAfterChosen || []}
                setSelected={setSelectedYear}
                selected={selectedYear}
                isDisableYearAllOption={isDisableYearAllOption}
                isYearsDisabled={isYearsDisabled}
                isAllBtnDisabled={isAllBtnDisabled}
              />
            </div>
          </div>
        </div>
        <div className={styles.edge__chart}>
          <EDGEChart
            selectedYear={selectedYear}
            listSelectType={listSelectType}
            id={id}
            roleUser={roleUser}
          />
        </div>
        <div className={styles.feedback__viewMore}>
          <div onKeyDown={() => {}} onClick={handleShow} className={styles.feedback__viewMoreText}>
            <p> View feedback</p>
            <img src={arrowRight} alt="" className={styles.feedback__arrowRight} />
          </div>
          {isHasEditPermission && (
            <img
              src={pen}
              alt="pen"
              style={{ cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={handleShowEdit}
              className={styles.feedback__pen}
            />
          )}
        </div>
      </div>
      <EditTagging
        show={showEdit}
        setShow={setShowEdit}
        type={FEED_BACK}
        info={info}
        isCheckSubmitFB={isCheckSubmitFB}
        setIsCheckSubmitFB={setIsCheckSubmitFB}
      />
      {show && (
        <ModalFeedbackSummary
          listSelectTypeEdge={listSelectTypeEdge}
          setListSelectTypeEdge={setListSelectTypeEdge}
          show={show}
          setShow={setShow}
          feedbacks={feedbacks10}
          loadingFeedbackSummary={loadingFeedbackSummary}
          filtersOption={filtersOption}
        />
      )}
    </>
  );
};
export default EDGEView;
