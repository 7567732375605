import { Dropdown, Menu, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { BasicButton } from '../../assets/common';
import { deleteNote, editNote, edit_delete, close_modal } from '../../assets/img';
import { CURRENT_YEAR, DATA_FROM } from '../../utils/constants';
import styles from './historical-talent-status.module.scss';

const HistoricalPopupList = (props) => {
  const {
    show,
    setShow,
    historical,
    setShowPopupHistoricalAdd,
    setHistoricalEdit,
    setShowPopupHistoricalDelete,
  } = props;

  const menu = (data) => {
    return (
      <Menu
        items={[
          {
            icon: <img src={editNote} alt="edit" />,
            label: (
              <div
                className={styles.editButton}
                onKeyDown={() => {}}
                onClick={() => {
                  setHistoricalEdit(data);
                  setShow(false);
                  setShowPopupHistoricalAdd(true);
                }}
              >
                Edit
              </div>
            ),
            key: '0',
          },
          {
            icon: <img src={deleteNote} alt="delete" />,
            label: (
              <div
                className={styles.deleteButton}
                onKeyDown={() => {}}
                onClick={() => {
                  setHistoricalEdit(data);
                  setShow(false);
                  setShowPopupHistoricalDelete(true);
                }}
              >
                Delete
              </div>
            ),
            key: '1',
          },
        ]}
      />
    );
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={styles.talent_search_form}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className={styles.talent_search_content}>
          <Modal.Header className={styles.modal_header}>
            <h1>Historical Talent Status</h1>
            <img
              src={close_modal}
              alt="close_modal"
              style={{ pointerEvents: 'all', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setShow(false)}
            />
          </Modal.Header>

          <Modal.Body className={styles.modal_body}>
            <div className="d-flex justify-content-end">
              <BasicButton
                mode="teal"
                data-testid="submit"
                onKeyDown={() => {}}
                onClick={() => {
                  setShow(false);
                  setShowPopupHistoricalAdd(true);
                }}
              >
                Add Historical Talent Status
              </BasicButton>
            </div>
            <div className={styles.tablePlan}>
              <table className="table table-borderless">
                <thead>
                  <tr className={styles.tablePlanThead}>
                    <th scope="col">Status</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Platform</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {!!historical &&
                    historical.length > 0 &&
                    historical?.map((data, index) => (
                      <tr
                        data-testid="list-historical"
                        key={index}
                        className={styles.tablePlanBody}
                      >
                        <td data-testid="talent-status" className={styles.tablePlanBodyStatus}>
                          {!data.status ? '-' : data.status}
                        </td>
                        <td data-testid="talent-start-date">
                          {moment(data.start_date).format('D MMMM YYYY') !== 'Invalid date'
                            ? moment(data.start_date).format('D MMMM YYYY')
                            : '-'}
                        </td>
                        <td data-testid="talent-end-date">
                          {moment(data.end_date).format('D MMMM YYYY') !== 'Invalid date' &&
                          !data.end_date.includes(CURRENT_YEAR)
                            ? moment(data.end_date).format('D MMMM YYYY')
                            : '-'}
                        </td>
                        <td data-testid="talent-platform">
                          {!data.platform ? '-' : data.platform}
                        </td>
                        <td>
                          {data.from === DATA_FROM.MANUAL && (
                            <Dropdown
                              overlay={menu(data)}
                              trigger={['click']}
                              style={{ minWidth: 110 }}
                              placement="bottomRight"
                              getPopupContainer={(trigger) => trigger.parentElement}
                            >
                              <Space style={{ cursor: 'pointer' }}>
                                <img src={edit_delete} alt="dot" />
                              </Space>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default HistoricalPopupList;
