import styled from 'styled-components';
import { font } from '../../../../assets/common';

const Container = styled.div`
  width: 1287px;
  margin: auto;
`;

const Navigate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #00a19c;
  }

  img {
    transform: translateY(-40%);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 22px;
      font-family: ${font.rubik};
      font-weight: 700;
      font-size: 24px;
      color: #4a4a4a;
    }

    .sub-title {
      margin-top: 2px;
      font-family: ${font.rubik};
      font-weight: 400;
      font-size: 14px;
      color: #999a9c;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      cursor: pointer;
      &:active {
        transform: translate(0, 1px);
      }
    }
    .button {
      color: white;
      font-family: ${font.inter};
      font-weight: 600;
      font-size: 12px;
      padding: 5px 20px;
      border-radius: 6px;
      background-color: #e7e7f0;
    }

    .btn-delete-user {
      background-color: #e7e7f0;
      cursor: not-allowed;

      &_active {
        background-color: #ff787f;
        cursor: pointer;

        &:active {
          transform: translate(0, 1px);
        }
      }
    }

    .button-active {
      background-color: #00a19c;
      cursor: pointer;

      &:active {
        transform: translate(0, 1px);
      }
    }
  }
`;

const ToolBar = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: flex-end;
    .searchInput {
      width: 100%;
      height: 44px;
      border: 1px solid #d3dee8;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      color: #8d8d8d;
      font-size: 14px;
      padding: 0 16px;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    img {
      cursor: pointer;
      &:active {
        transform: translate(0, 1px);
      }
    }
    .button {
      color: white;
      font-family: ${font.inter};
      font-weight: 600;
      font-size: 12px;
      padding: 8px 16px;
      border-radius: 6px;
      background-color: #e7e7f0;
      cursor: pointer;
    }

    .btn-delete-user {
      background-color: #e7e7f0;
      cursor: not-allowed;

      &_active {
        background-color: #ff787f;
        cursor: pointer;

        &:active {
          transform: translate(0, 1px);
        }
      }
    }

    .button-active {
      background-color: #00a19c;
      cursor: pointer;

      &:active {
        transform: translate(0, 1px);
      }
    }

    .filterButton {
      margin-left: -8px;
    }
  }
`;

const TableManagement = styled.div`
  width: 100%;
  margin-top: 24px;
  table {
    background-color: #feffff;
  }

  tr {
    border: 1px solid #d3dee8;
  }

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    accent-color: #00a19c;
    padding: 2px;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: #8896a7;

      &::before {
        content: none !important;
      }
    }
  }

  .ant-pagination {
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;
      background-color: transparent;
    }
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active {
    background: #00a19c;
    color: #fff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00a19c;
    border-color: #00a19c;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #fff;
    border-color: #00a19c;

    &:after {
      background-color: #00a19c;
    }
  }
  .pagination-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 20px 0;
  }
  .avatar {
    text-align: right;
    img {
      width: 50px;
      height: 50px;
      boder-radius: 50%;
    }
  }
`;
export { Container, Navigate, Header, TableManagement, ToolBar };
