import styled from 'styled-components';
import { Checkbox, Modal } from 'antd';
import { color, font } from '../../../../../../assets/common';

export const SelectCriteriaModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: none;
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 8px;
    padding: 37px 30px;
    font-family: ${font.inter};
  }

  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
  }
`;

export const CriteriaCheckbox = styled(Checkbox)`
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.54px;
  color: #4a4a4a;
  margin-bottom: 24px;
  text-transform: none;

  & + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${color.teal};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }

  .ant-checkbox-checked::after {
    border-color: ${color.teal};
  }

  .ant-checkbox + span {
    padding-left: 11px;
  }

  .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: #dff1f1;
  }
  .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ffffff;
  }

  .ant-checkbox-disabled {
    opacity: 0.5;
  }
`;
