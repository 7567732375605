/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { search_data_not_found } from '../../../../assets/img';
import { SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  MESSAGE_TYPES,
  UNSEARCHABLE_POSITION_MESSAGE,
} from '../../../../utils/constants';
import AddUnsearchablePositions from './AddUnsearchablePositions/AddUnsearchablePositions';
import DataTableUnsearchable from './DataTableUnsearchable/DataTableUnsearchable';
import MenuBarUnsearchable from './MenuBarUnsearchable/MenuBarUnsearchable';

export default function UnsearchablePositions() {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [keyword, setKeyword] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [searchInputAdd, setSearchInputAdd] = useState('');
  const [positionNameAdd, setPositionNameAdd] = useState('');
  const [isSearchValueAdd, setIsSearchValueAdd] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [alreadyExistAddPosition, setAlreadyExistAddPosition] = useState(false);
  const [data, setData] = useState(null);
  const [dataOnChangeAdd, setDataOnChangeAdd] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 15;

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: true,
        })
      );
    },
    [dispatch]
  );

  const handleResetData = useCallback(() => {
    setData([]);
    setTotalData(0);
    setLoading(false);
  }, [setData, setTotalData, setLoading]);

  const fetchData = useCallback(async () => {
    setData([]);
    if (isSearch) {
      setPage(1);
    }
    try {
      setLoading(true);
      const query = {
        page: !isSearch ? page : 1,
        limit: limit,
        keyword: keyword,
      };
      const res = await adminApi.getUnsearchablePosition(query, roleActive?.roleId);
      if (res.status === 200 && !isEmpty(res.data.result)) {
        setData(res.data.result);
        setTotalData(res.data.result[0].total);
      } else {
        data &&
          handleShowMessage(MESSAGE_TYPES.ERROR, UNSEARCHABLE_POSITION_MESSAGE.LIST_OF_POSITION);
        handleResetData();
      }
    } catch (error) {
      handleResetData();
      console.error(error);
    }
    setLoading(false);
  }, [
    keyword,
    page,
    roleActive,
    isSearch,
    handleResetData,
    setData,
    setTotalData,
    handleShowMessage,
  ]);

  const fetchDataOnChangeAdd = useCallback(async () => {
    if (!isSearchValueAdd) return;
    try {
      setLoading(true);
      const query = {
        page,
        limit,
        keyword: searchInputAdd,
      };
      const res = await adminApi.getUnsearchablePositionFilter(query, roleActive?.roleId);
      if (res.status === 200) {
        setDataOnChangeAdd(res.data.result);
      }
    } catch (error) {
      console.error(error);
      setDataOnChangeAdd([]);
    }
    setLoading(false);
  }, [page, searchInputAdd, isSearchValueAdd]);

  const handleAdd = async () => {
    const positionCode = searchInputAdd.trim();
    if (!positionCode) return;
    const body = {
      position_code: positionCode,
    };
    try {
      if (isSelected) {
        await adminApi.addUnsearchablePosition(body, roleActive?.roleId);
        handleShowMessage(
          ALERT_MESSAGE_TYPE.SUCCESS,
          (positionNameAdd ? positionNameAdd : 'Position') +
            ' ' +
            UNSEARCHABLE_POSITION_MESSAGE.ADD_SUCCESS
        );
        fetchData();
        setIsShow(false);
      } else {
        fetchDataOnChangeAdd();
      }
    } catch (e) {
      const messageErrors = e.response.data.errors;
      if (messageErrors === UNSEARCHABLE_POSITION_MESSAGE.EXIST_UNSEARCHABLE_POSITION) {
        setAlreadyExistAddPosition(true);
      }
      console.error(e);
      setIsShow(true);
    }
  };

  const handleDeleteRecord = useCallback(async () => {
    try {
      if (deleteRecord?.id) {
        setLoading(true);
        const res = await adminApi.deleteUnsearchablePosition(deleteRecord?.id, roleActive.roleId);
        if (res.status === 200) {
          setDeleteRecord(null);
          handleShowMessage(
            ALERT_MESSAGE_TYPE.SUCCESS,
            (deleteRecord?.position_name ? deleteRecord?.position_name : 'Position') +
              ' ' +
              UNSEARCHABLE_POSITION_MESSAGE.DELETE_SUCCESS
          );
          if (data.length === 1) {
            setKeyword('');
            setSearchInput('');
            setPage(1);
            return;
          }
          fetchData();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [deleteRecord, fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchDataOnChangeAdd();
    if (!isShow) {
      setAlreadyExistAddPosition(false);
      setIsSelected(false);
    }
  }, [fetchDataOnChangeAdd, isShow, searchInputAdd]);

  useEffect(() => {
    if (!deleteRecord) return;
    handleDeleteRecord();
  }, [deleteRecord]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarUnsearchable
        keyword={keyword}
        setKeyword={setKeyword}
        handleDeleteRecord={handleDeleteRecord}
        loading={loading}
        setIsSearch={setIsSearch}
        setIsShow={setIsShow}
        data={data}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setSearchInputAdd={setSearchInputAdd}
      />
      {!isEmpty(data) ? (
        <DataTableUnsearchable
          data={data}
          setDeleteRecord={setDeleteRecord}
          handleDeleteRecord={handleDeleteRecord}
          totalData={totalData}
          setPage={setPage}
          page={page}
          limit={limit}
          setIsSearch={setIsSearch}
        />
      ) : (
        <SearchDataTableNotFound
          content={UNSEARCHABLE_POSITION_MESSAGE.LIST_OF_POSITION}
          notFoundIcon={search_data_not_found}
        />
      )}
      {isShow && (
        <AddUnsearchablePositions
          setIsShow={setIsShow}
          isShow={isShow}
          searchInputAdd={searchInputAdd}
          setSearchInputAdd={setSearchInputAdd}
          isSearchValueAdd={isSearchValueAdd}
          setIsSearchValueAdd={setIsSearchValueAdd}
          fetchData={fetchDataOnChangeAdd}
          data={dataOnChangeAdd}
          loading={loading}
          setPositionNameAdd={setPositionNameAdd}
          handleAdd={handleAdd}
          alreadyExistAddPosition={alreadyExistAddPosition}
          setAlreadyExistAddPosition={setAlreadyExistAddPosition}
          setIsSelected={setIsSelected}
          isSelected={isSelected}
        />
      )}
    </Spin>
  );
}
