import styled from 'styled-components';
import { Button, Input } from 'antd';
import { color, font } from '../../../../../assets/common';

export const AiTable = styled.div`
  .ant-spin-text {
    color: ${color.loading};
    font-family: ${font.inter};
    font-size: 12px;
  }
  .ant-spin-dot-item {
    background-color: ${color.teal};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${color.teal};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${color.teal};
  }
  .editable-row {
    .ant-table-filter-dropdown {
      min-width: 150px !important;
    }
  }

  .ant-table-filter-trigger {
    margin: -4px 20px -4px -30px;
    z-index: 2;
  }
  .ant-btn-sm {
    width: 97px;
    height: 19px;
  }
  ul {
    li {
      .ant-pagination-item-link {
        border: none;
        background-color: transparent;
      }
    }
    .ant-pagination-item {
      background-color: #00a19c;
      border-radius: 50%;
      border: none;
      color: white;
    }
    .ant-pagination-options {
      display: none;
    }
  }

  .ant-btn-link {
    background-color: red;
  }
`;
export const AiButton = styled(Button)`
  background-color: ${color.teal};
  border-color: ${color.teal};
  &:hover {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  &:focus {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
  cursor: none;
`;
export const Text = styled.span`
  font-family: ${font.inter};
  display: flex;
  align-items: center;
  font-size: '14px';
  color: ${color.brightGray};
`;
export const AiInput = styled(Input)`
  border-radius: 4px;
  padding: 10px 16px;
  font-family: ${font.inter};
  &:focus {
    border-color: ${color.teal};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
`;
export const Search = styled(Button)`
  background: ${color.teal};
  border-color: ${color.teal};
  margin-left: 8px;
  border-radius: 4px;
  &:focus {
    background: ${color.teal};
    border-color: ${color.teal};
  }
  &:hover {
    background-color: ${color.teal};
    border-color: ${color.teal};
  }
`;
export const Ai2Button = styled(Button)`
  border-radius: 4px;
  border-color: #00a19c;
  font-family: ${font.inter};
  color: ${(props) => (props.color === 'blue' ? '#00A19C' : 'white')};
  background-color: ${(props) => (props.bgcolor === 'blue' ? '#00A19C' : 'white')};
  margin-left: ${(props) => (props.mgl ? props.mgl : '')};
  font-size: 12px;
  &:hover {
    color: #00a19c;
    border-color: #00a19c;
  }
  &:focus {
    color: #00a19c;
    border-color: #00a19c;
  }
`;
export const WrapPagination = styled.div`
  background-color: 'white';
  display: flex;
  justify-content: flex-end;
`;
