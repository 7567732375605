import { Tooltip } from 'antd';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { BasicButton, BasicLoading } from '../../../../assets/common';
import { exclamation_point, mask_icon } from '../../../../assets/img';
import { talentReviewApi } from '../../../../services/talentReviewApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { TALENT_REVIEW_BUSINESS_COLOR } from '../../../../utils/constants';
import { getBusinessShortName, getRoleShortName } from '../../../../utils/helper';
import { Title } from '../DevelopmentPlan/FormDevelopmentPlan/styled';
import Disclaimer from '../SuccessionPlanningSection/Disclaimer/Disclaimer';
import TalentReviewCollapse from '../TalentReviewCollapse/TalentReviewCollapse';
import MobilityForm from './MobilityForm/MobilityForm';
import * as styles from './talent-review-mobility-plan.module.scss';

const TalentReviewMobilityPlan = (props) => {
  const {
    setAddTalent,
    staffTalentReview,
    agendaDetail,
    onlyViewTalentAgenda,
    isPrinting,
    addTalent,
    setFormMobilityPlan,
  } = props;

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [tabIndex, setTabIndex] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalYear, setTotalYear] = useState(0);
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
  const elCardRef = useRef();
  const [height, setHeight] = useState(0);
  const [positionOptions, setPositionOptions] = useState([]);

  useEffect(() => {
    setHeight(elCardRef?.current?.clientHeight || 0);
  }, [tabIndex, elCardRef]);

  const timelineStyles = useMemo(() => {
    const totalYear = data.reduce((total, item) => {
      return total + item.duration;
    }, 0);
    setTotalYear(totalYear);
    return data.map((item) => {
      return (item.duration / totalYear) * 100;
    });
  }, [data]);

  const handleDuration = (duration) => {
    if (duration >= 1) {
      const year = Math.round(duration);
      return `${year} ${year === 1 ? 'Year' : 'Years'}`;
    }
    const month = Math.round(duration * 12);
    return `${month} ${month === 1 ? 'Month' : 'Months'}`;
  };

  const fetchData = useCallback(async () => {
    if (!staffTalentReview?.staff_id) return;
    try {
      setLoading(true);
      const res = await talentReviewApi.getTalentReviewMobilityPlan(staffTalentReview.staff_id);
      if (res.status === 200) {
        const convertData = handleConvertData(res.data.result);
        setData(convertData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [staffTalentReview?.staff_id]);

  useEffect(() => {
    const getMobilityPositionOptions = async () => {
      try {
        const res = await talentReviewApi.getMobilityPositions();
        if (res.status === 200) {
          setPositionOptions(res.data.result);
        }
      } catch (error) {
        console.error(error);
        setPositionOptions([]);
      }
    };
    getMobilityPositionOptions();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isPrinting || isEmpty(agendaDetail?.mobility_plan)) return;

    setAddTalent((prev) => {
      return {
        ...prev,
        mobility_plan: !isEmpty(agendaDetail?.mobility_plan)
          ? agendaDetail.mobility_plan.map((item) => {
              return {
                ...item,
                idx: uuidv4(),
              };
            })
          : [
              {
                next_mobility: { manual: '', selection: [] },
                next_2_mobility: { manual: '', selection: [] },
                end_state: { manual: '', selection: [] },
                idx: uuidv4(),
              },
            ],
      };
    });
  }, [agendaDetail, setAddTalent, isPrinting]);

  const handleConvertData = (data) => {
    return !isEmpty(data)
      ? data.map((item) => {
          return {
            ...item,
            businessShortName: getBusinessShortName(item?.business),
            roleShortName: getRoleShortName(item?.role),
          };
        })
      : [];
  };

  const handleTabClick = (index) => {
    let arr = cloneDeep(tabIndex);
    if (arr.includes(index)) {
      arr = arr.filter((item) => item !== index);
      setTabIndex(arr);
      return;
    }
    if (arr.length >= 3) {
      dispatch(
        pushMessage({
          message:
            'You have exceeded the maximum number of role selected. Please unselect to view other role',
          type: 'error',
          isScroll: true,
        })
      );
    } else {
      arr.push(index);
    }
    setTabIndex(arr);
  };

  const currentStaffId = useMemo(() => {
    return (
      staffTalentReview?.staff_id ||
      addTalent?.top_talent?.staff_id ||
      addTalent?.top_talent?.staffId
    );
  }, [staffTalentReview, addTalent]);

  const renderView = () => {
    return (
      <>
        {isShowDisclaimer && !onlyViewTalentAgenda && (
          <Disclaimer setShow={setIsShowDisclaimer} staffTalentReview={staffTalentReview} />
        )}
        <BasicLoading spinning={loading}>
          {!isPrinting ? (
            <div className={styles.wrapper}>
              <h4 className={styles.title}>{handleDuration(totalYear)} in PETRONAS</h4>
              <div className={styles.borderDashed}>
                <div
                  className={styles.borderDashedBefore}
                  style={!isEmpty(tabIndex) ? { height: `${height + 180}px` } : { height: '180px' }}
                />
                <div
                  className={styles.journey}
                  style={
                    !isEmpty(tabIndex) ? { paddingBottom: `85px` } : { paddingBottom: '110px' }
                  }
                >
                  {!isEmpty(data) ? (
                    data.map((item, index) => (
                      <div
                        key={index}
                        data-testid={`tab-${index}`}
                        className={`${styles.journeyItem} ${
                          tabIndex.includes(index) ? styles.active : ''
                        }`}
                        onKeyDown={() => {}}
                        onClick={() => {
                          handleTabClick(index);
                        }}
                        style={{
                          width: `${timelineStyles[index]}%`,
                          background: tabIndex.includes(index)
                            ? TALENT_REVIEW_BUSINESS_COLOR[item?.business]
                            : '',
                        }}
                      >
                        <Tooltip
                          className={styles.CareerJourney__tooltip}
                          title={item?.yearTo || item?.yearFrom}
                        >
                          <img src={mask_icon} alt="mask" className={styles.mask} />
                        </Tooltip>
                        <div className={styles.businessTitle} title={item?.business}>
                          {item?.businessShortName || item?.business}
                        </div>
                        <div className={styles.roleTitle}>{item?.roleShortName || item.role}</div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.journeyNull}>
                      <img src={mask_icon} alt="mask" className={styles.mask} />
                    </div>
                  )}
                </div>

                <div style={{ padding: '0 42px' }} ref={elCardRef}>
                  {!isEmpty(tabIndex) && (
                    <div className={styles.detailContainer}>
                      {tabIndex.map((idx) => (
                        <div key={idx} className={styles.detail}>
                          <div
                            className={styles.borderTop}
                            style={{
                              borderColor: TALENT_REVIEW_BUSINESS_COLOR[data[idx]?.business],
                            }}
                          />
                          <div className={styles.content}>
                            <h5>{`${data[idx]?.role} (${handleDuration(data[idx].duration)})`}</h5>
                            <ul>
                              {data[idx]?.positions?.map((item, i) => (
                                <li key={i}>{item}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={styles.formEdit} style={{ marginTop: '108px' }}>
                  {isArray(addTalent?.mobility_plan) &&
                    addTalent.mobility_plan.map((item, index) => (
                      <MobilityForm
                        key={item.idx}
                        index={index}
                        onlyViewTalentAgenda={onlyViewTalentAgenda}
                        item={item}
                        setAddTalent={setAddTalent}
                        isTheLast={index === addTalent.mobility_plan.length - 1}
                        lengthMobility={addTalent.mobility_plan.length}
                        positionOptions={positionOptions}
                        addTalent={addTalent}
                        currentStaffId={currentStaffId}
                        isPrinting={isPrinting}
                        setFormMobilityPlan={setFormMobilityPlan}
                      />
                    ))}
                  {!onlyViewTalentAgenda && (
                    <div className="d-flex justify-content-end">
                      <BasicButton
                        mode="border-teal"
                        onKeyDown={() => {}}
                        onClick={() => {
                          setAddTalent((prev) => {
                            return {
                              ...prev,
                              mobility_plan: [
                                ...(isArray(prev?.mobility_plan) ? prev.mobility_plan : []),
                                {
                                  next_mobility: { manual: '', selection: [] },
                                  next_2_mobility: { manual: '', selection: [] },
                                  end_state: { manual: '', selection: [] },
                                  idx: uuidv4(),
                                },
                              ],
                            };
                          });
                        }}
                        style={{ width: '200px' }}
                      >
                        Add Mobility Plan
                      </BasicButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={`${styles.wrapper} ${styles.wrapperPrint}`}>
              <div
                className={`${styles.formEdit} ${styles.formPrint}`}
                style={{ marginTop: '50px' }}
              >
                {addTalent.mobility_plan.map((item, index) => (
                  <MobilityForm
                    key={item.idx}
                    index={index}
                    onlyViewTalentAgenda={onlyViewTalentAgenda}
                    item={item}
                    setAddTalent={setAddTalent}
                    addTalent={addTalent}
                    isTheLast={index === addTalent.mobility_plan.length - 1}
                    lengthMobility={addTalent.mobility_plan.length}
                    isPrinting={isPrinting}
                    positionOptions={positionOptions}
                    currentStaffId={currentStaffId}
                  />
                ))}
              </div>
            </div>
          )}
        </BasicLoading>
      </>
    );
  };

  return (
    <>
      {!isPrinting && (
        <TalentReviewCollapse
          header={
            <h2 className={styles.title}>
              Mobility Plan
              {!onlyViewTalentAgenda && (
                <img
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  src={exclamation_point}
                  alt="exclamation_point"
                  onKeyDown={() => {}}
                  onClick={() => setIsShowDisclaimer(true)}
                  data-testid="btn-disclaim"
                />
              )}
            </h2>
          }
        >
          {renderView()}
        </TalentReviewCollapse>
      )}
      {isPrinting && (
        <div
          style={{
            borderRadius: 0,
            marginTop: '48px',
          }}
        >
          <Title>{'Mobility Plan'}</Title>
          {renderView()}
        </div>
      )}
    </>
  );
};

TalentReviewMobilityPlan.propTypes = {
  addTalent: PropTypes.object,
  setAddTalent: PropTypes.func,
  onlyViewTalentAgenda: PropTypes.bool,
  staffTalentReview: PropTypes.object,
  isPrinting: PropTypes.bool,
  agendaDetail: PropTypes.object,
};

export default TalentReviewMobilityPlan;
