import { Input } from 'antd';
import React from 'react';
import styles from './form-input.module.scss';

const FormInput = (props) => {
  const { label, messageErr, required, placeholder, value, setFormData, isError, borderColor, disabled } = props;

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.label}>{label}</span>
        <span style={{ color: '#FF787F' }}>{required && '*'}</span>
      </div>
      <div>
        <Input
          placeholder={placeholder}
          style={{
            width: '100%',
            height: '44px',
            borderColor: `${borderColor}`,
            borderRadius: '4px ',
          }}
          value={value}
          onChange={(e) => setFormData && setFormData(e.target.value)}
          disabled={disabled}
          className={isError ? styles.borderErr : ''}
        />
      </div>
      <div className={styles.error}>{isError && messageErr}</div>
    </div>
  );
};

export default FormInput;
