import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { sg_jg_not_found } from '../../../../assets/img';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MAPPING_DATA_TYPES, MESSAGE_TYPES, SELECT_ALL_LABEL, SG_JG_MAPPING_MESSAGE } from '../../../../utils/constants';
import { getMappingData } from '../../../../utils/helper';
import DataTableManaging from './DataTableManaging/DataTableManaging';
import MenuBarMapping from './MenuBarMapping/MenuBarMapping';
import fetch_filter_option from './MenuBarMapping/fetch-filter-option.json';
import * as styles from './sg-jg-mapping-to-role.module.scss';

const INIT_SEARCH_PARAMS = {
  keyword: '',
  filterData: {},
};

export default function SGJGMappingToRole() {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [seletedRows, setSeletedRows] = useState([]);
  const [updateStatusVisibleDeleteMultiple, setUpdateStatusVisibleDeleteMultiple] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const limit = 15;
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRecordEdit, setSelectedRecordEdit] = useState(null);
  const [filterOptions, setFilterOptions] = useState({});
  const [isInvalidJGInOracle, setIsInvalidJGInOracle] = useState(false);
  const [isInvalidJGInPSeries, setIsInvalidJGInPSeries] = useState(false);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const res = await adminApi.getSGJGMappingFilter(roleActive?.roleId, {
        sg: '',
        job_grade: '',
      });
      if (res.status === 200) {
        setFilterOptions({
          role_level: res.data.result?.role_level,
          sg_in_oracle: res.data.result?.sg_in_oracle,
          jg_in_oracle: res.data.result?.jg_in_oracle,
          jg_in_p_series: fetch_filter_option.jg_in_p_series,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [roleActive.roleId]);

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  const fetchData = useCallback(async () => {
    setData([]);
    const { keyword, filterData } = searchParams;
    try {
      setLoading(true);
      const query = {
        page: '',
        limit: '',
        keyword,
        role_level: !isEmpty(filterData.role_level)
          ? filterData?.role_level
              .filter((item) => item?.checked && item?.label !== SELECT_ALL_LABEL)
              .map((item) => {
                return item?.value || item?.label;
              })
          : [],
        jg_in_p_series: !isEmpty(filterData.jg_in_p_series)
          ? filterData?.jg_in_p_series
              .filter((item) => item?.checked && item?.label !== SELECT_ALL_LABEL)
              .map((item) => {
                return item?.value || item?.label;
              })
          : [],
      };
      const res = await adminApi.getSGJGMapping(query, roleActive?.roleId);
      if (res.status === 200 && !isEmpty(res.data.result)) {
        let tempRoleLevels = {};
        res.data.result.forEach((item) => {
          tempRoleLevels[`${item.role_level}`] = [];
        });
        const tempData = getMappingData(tempRoleLevels, res.data.result, MAPPING_DATA_TYPES.SG_JG_MAPPING);
        setData(tempData);
        setTotalData(res.data.result[0].total);
      } else {
        handleResetData();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: SG_JG_MAPPING_MESSAGE.NO_RESULT_FOUND,
          })
        );
      }
    } catch (error) {
      handleResetData();
      console.error(error);
    }
    setLoading(false);
  }, [searchParams, roleActive.roleId, dispatch]);

  const handleResetData = () => {
    setData([]);
    setTotalData(0);
    setLoading(false);
  };

  const handleDeleteRecord = useCallback(async () => {
    try {
      if (!isEmpty(deleteRecord) && !updateStatusVisibleDeleteMultiple) {
        setLoading(true);
        const res = await adminApi.deleteSGJGMapping([deleteRecord.id], roleActive?.roleId);
        if (res.status === 200) {
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: SG_JG_MAPPING_MESSAGE.RECORD_DELETE_SUCCESS,
            })
          );
          if (data.length === 1) {
            setSearchParams(INIT_SEARCH_PARAMS);
          } else {
            fetchData();
          }
          setDeleteRecord(null);
        }
      } else {
        setLoading(true);
        const res = await adminApi.deleteSGJGMapping(deleteSelecteds, roleActive?.roleId);
        if (res.status === 200) {
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: SG_JG_MAPPING_MESSAGE.RECORD_DELETE_SUCCESS,
            })
          );
          setDeleteSelecteds([]);
          setSeletedRows([]);
          if (data.length === deleteSelecteds.length) {
            setSearchParams(INIT_SEARCH_PARAMS);
          } else {
            fetchData();
          }
        }
      }
    } catch (error) {
      console.error(error);
      const errorData = error?.response?.data;
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: errorData?.code === 500 && errorData?.errors ? errorData?.errors : SG_JG_MAPPING_MESSAGE.AN_UNEXPECTED_ERROR,
        })
      );
    }
    setUpdateStatusVisibleDeleteMultiple(false);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, deleteSelecteds, dispatch, fetchData, roleActive.roleId, updateStatusVisibleDeleteMultiple]);

  // Get data to view
  useEffect(() => {
    if (!isEdit) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, fetchData]);

  useEffect(() => {
    if (!deleteRecord) return;
    handleDeleteRecord();
  }, [deleteRecord, handleDeleteRecord]);

  const handleEditRecord = useCallback(async () => {
    try {
      if (isEmpty(selectedRecordEdit)) return;

      setLoading(true);
      const res = await adminApi.updateSGJGMappingById(selectedRecordEdit, roleActive?.roleId);
      if (res.status === 200) {
        setSelectedRecordEdit(null);
        fetchFilterOptions();
        fetchData();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: SG_JG_MAPPING_MESSAGE.SG_JG_MAPPING_UPDATE_SUCCESS,
          })
        );
        setIsEdit(false);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorData = error?.response?.data;
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: errorData?.code === 500 && errorData?.errors ? errorData?.errors : SG_JG_MAPPING_MESSAGE.AN_UNEXPECTED_ERROR,
        })
      );
      if (errorData?.errors) {
        switch (errorData?.errors) {
          case SG_JG_MAPPING_MESSAGE.JG_IN_P_SERIES_SAME_ROLE:
            setIsInvalidJGInPSeries(true);
            break;
          case SG_JG_MAPPING_MESSAGE.SG_JG_MAPPING_ALREADY_EXIST:
            setIsInvalidJGInOracle(true);
            break;
          default:
            break;
        }
      }
    }
  }, [selectedRecordEdit, roleActive.roleId, fetchFilterOptions, fetchData, dispatch]);

  useEffect(() => {
    if (!selectedRecordEdit) return;
    handleEditRecord();
  }, [selectedRecordEdit, handleEditRecord]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarMapping
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        fetchData={fetchData}
        setDeleteSelecteds={setDeleteSelecteds}
        deleteSelecteds={deleteSelecteds}
        updateStatusVisible={updateStatusVisibleDeleteMultiple}
        setUpdateStatusVisible={setUpdateStatusVisibleDeleteMultiple}
        handleDeleteRecord={handleDeleteRecord}
        loading={loading}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        filterOptions={filterOptions}
      />
      {!isEmpty(data) ? (
        <DataTableManaging
          data={data}
          setDeleteRecord={setDeleteRecord}
          setDeleteSelecteds={setDeleteSelecteds}
          setSeletedRows={setSeletedRows}
          seletedRows={seletedRows}
          handleDeleteRecord={handleDeleteRecord}
          totalData={totalData}
          limit={limit}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          setSelectedRecordEdit={setSelectedRecordEdit}
          filterOptions={filterOptions}
          setIsInvalidJGInOracle={setIsInvalidJGInOracle}
          isInvalidJGInOracle={isInvalidJGInOracle}
          setIsInvalidJGInPSeries={setIsInvalidJGInPSeries}
          isInvalidJGInPSeries={isInvalidJGInPSeries}
        />
      ) : (
        <div className={styles.not_found}>
          <img src={sg_jg_not_found} alt="not_found" />
        </div>
      )}
    </Spin>
  );
}
