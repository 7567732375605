import React from 'react';
import * as SC from './styled';
import { Select } from 'antd';
import { arrow_dropdown } from '../../assets/img';

const DropdownUserManagement = (props) => {
  const { size, label, options, selected, setSelected } = props;
  const { Option } = Select;

  function handleChange(value) {
    const item = options.find((d) => d.id === value);
    setSelected(item);
  }

  return (
    <SC.Dropdown
      size={size}
      style={{ position: 'relative' }}
      id="area"
      data-testid="dropdown-user-management"
    >
      <div>{label || 'No label'}</div>
      <Select
        value={selected}
        placeholder="Select"
        allowClear
        suffixIcon={<img src={arrow_dropdown} alt="" />}
        onChange={handleChange}
        // showArrow={selected?.id ? false : true}
        getPopupContainer={() => document.getElementById('area')}
      >
        {options &&
          options.length > 0 &&
          options.map((item, index) => (
            <Option
              key={`${label}_${index}`}
              value={item.id}
              className="d-flex align-items-center"
              style={{ height: `${size?.height || '45px'}` }}
            >
              <span> {item.value}</span>
            </Option>
          ))}
      </Select>
    </SC.Dropdown>
  );
};

export default DropdownUserManagement;
