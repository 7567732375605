import { Spin } from 'antd';
import React from 'react';

const CommonLoading = ({ minHeight }) => {
  return (
    <div
      style={{ position: 'relative', minHeight: minHeight || '40px' }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spin size="large" />
    </div>
  );
};

export default CommonLoading;
