import { createSlice } from '@reduxjs/toolkit';

const initialCancelUploadSlice = {
  uploadMoM: { ...JSON.parse(localStorage.getItem('cancelUploadMoM')), is_refresh: true },
  uploadDocument: { ...JSON.parse(localStorage.getItem('cancelUploadDocument')), is_refresh: true },
};
const cancelUploadSlice = createSlice({
  name: 'cancelUpload',
  initialState: initialCancelUploadSlice,
  reducers: {
    setDataCancelUploadMoM: (state, action) => {
      const cancelUploadMom = action.payload;
      localStorage.setItem('cancelUploadMoM', JSON.stringify(cancelUploadMom));
      state.uploadMoM = cancelUploadMom;
    },
    clearDataCancelUploadMoM: (state) => {
      localStorage.removeItem('cancelUploadMoM');
      state.uploadMoM = null;
    },
    setDataCancelUploadDocument: (state, action) => {
      const cancelUploadDocument = action.payload;
      localStorage.setItem('cancelUploadDocument', JSON.stringify(cancelUploadDocument));
      state.uploadMoM = cancelUploadDocument;
    },
    clearDataCancelUploadDocument: (state) => {
      localStorage.removeItem('cancelUploadDocument');
      state.uploadMoM = null;
    },
  },
});
const { actions, reducer: cancelUploadReducer } = cancelUploadSlice;
export const {
  clearDataCancelUploadMoM,
  setDataCancelUploadMoM,
  clearDataCancelUploadDocument,
  setDataCancelUploadDocument,
} = actions;
export default cancelUploadReducer;
