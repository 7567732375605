import React, { useState, useRef, useEffect } from 'react';
import styles from './meeting-category.module.scss';
import Rectangle from '../../../../assets/img/Rectangle.svg';
import { AiDropdown } from './Styled';

const MeetingCategory = ({ selected, setSelected, options, placeholder, status, disabled }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);
  return (
    <div className={styles.dropdown} ref={ref}>
      <AiDropdown
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
        status={status}
        disabled={disabled}
      >
        <p
          className={styles.dropdownSelect__label}
          title={selected?.name && selected?.name.length >= 50 ? selected?.name : ''}
        >
          {selected?.name || (
            <span style={{ color: '#8D8D8D', fontSize: '14px' }}>{placeholder}</span>
          )}
        </p>
        <img src={Rectangle} alt="" width="10" height="10" />
      </AiDropdown>

      <div
        className={styles.dropdownContent}
        style={{ maxHeight: isActive && '260px', overflowY: isActive && 'auto' }}
      >
        {isActive &&
          !disabled &&
          options &&
          options?.map((option) => {
            return (
              <div
                key={option?.id}
                onKeyDown={() => {}}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={styles.dropdownItem}
              >
                {option?.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MeetingCategory;
