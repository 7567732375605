import styled from 'styled-components';
import { color, font, fontSize } from '../../assets/common';

export const Content = styled.div`
  p {
    font-family: ${font.inter};
    font-size: ${fontSize.normal};
    &:first-child {
      transition: all 0.15s ease-in;
      font-weight: ${(props) => {
        if (props.isSelected) {
          return 700;
        }
        return 400;
      }};
    }
    &::first-letter {
      text-transform: capitalize;
    }
    &:last-child {
      font-weight: 700;
    }
  }
`;
export const Aili = styled.li`
  font-size: 14px;
  color: ${color.teal};
  padding: 7px 16px;
  background: rgba(0, 161, 156, 0.05);
  border-radius: 16px;
  margin-right: 10px;
  margin-top: 5px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s all ease;
  background-color: ${(props) => {
    if (props.isSelected) return 'rgba(0, 161, 156, 0.25)';
    return 'rgba(0, 161, 156, 0.05)';
  }};
  &:hover {
    background: rgba(0, 161, 156, 0.25);
  }
  &:focus {
    background: rgba(0, 161, 156, 0.25);
  }
`;
export const WrapContent = styled.div`
  padding: 25px 22px 20px 29px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
`;
export const SubTitle = styled.div`
  font-family: ${font.inter};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: 'rgba(0, 0, 0, 0.0001)';
  box-shadow: inset 0px -1px 0px #cccccc;
  padding-bottom: 31px;
`;

export const LeftSide = styled.div`
  width: 50%;
`;
export const RightSide = styled.div`
  width: 50%;
`;
export const Aih3 = styled.h3`
  font-weight: 600;
  color: ${color.darkGray};
  font-size: ${fontSize.large};
  margin-bottom: 27px;
`;
export const Aiul = styled.ul`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const AiTable = styled.div`
  background: #ffffff;
  border-radius: 6px;
  table {
    width: 100%;
  }
  th,
  td {
    &:first-child {
      padding-left: 26px;
      padding-right: 35px;
    }
  }
  th {
    &:first-child {
      width: 54%;
    }
    &:last-child {
      width: 46%;
      padding-right: 15px;
    }
    padding: 17px 0 13px 0;
    background: rgba(0, 0, 0, 0.0001);
    box-shadow: inset 0px -1px 0px #ececec;
    h3 {
      font-family: ${font.inter};
      font-size: ${fontSize.normal};
      font-weight: 500;
      line-height: 16.94px;
      color: ${color.darkBlue};
      margin-bottom: 0;
    }
  }
  tr {
    &:not(:last-child) {
      td {
        box-shadow: inset 0px -1px 0px #ececec;
      }
    }
  }
  td {
    &:first-child {
      width: 54%;
    }
    &:last-child {
      width: 46%;
      padding-right: 15px;
    }
    vertical-align: top;
    padding: 11px 0 16px 0;
    background: rgba(0, 0, 0, 0.0001);
    p {
      font-family: ${font.inter};
      width: 100%;
      font-size: ${fontSize.normal};
      font-weight: 400;
      line-height: 16px;
      color: ${color.arkBlue};
      word-break: break-word;
    }
  }
  .source {
    color: var(--emphasis-body-n-800, #3f3c4c);
    text-align: right;
    font-family: ${font.inter};
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
  }
`;
