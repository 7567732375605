import { isEmpty, slice } from 'lodash';
import React from 'react';

import styles from './search-box-custom.module.scss';

const SearchListOption = (props) => {
  const { data, loading, debounceSearchQuery, setSearchTerm, fieldName } = props;

  const handleSelectOption = async (item) => {
    let name = getNameField(item);
    await setSearchTerm({ value: item, type: 'submit', showTerm: name });
  };

  const getNameField = (item) => {
    let name = '';
    switch (fieldName) {
      case 'position_code':
      case 'position_code_criteria':
      case 'position_code_critical':
        name = item?.position_code;
        break;
      case 'staff_id':
        name = item?.staff_id;
        break;
      case 'action_by':
        name = item?.label;
        break;
      case 'user_log_email':
        name = item?.label;
        break;
      case 'SG_JG_in_Oracle':
        name = item?.label;
        break;
      case 'staff_id_top_talent_status':
        name = item?.staff_id;
        break;
      default:
        break;
    }
    return name;
  };

  const handleShowListItems = (fieldName) => {
    if (fieldName === 'staff_id_top_talent_status') {
      return (
        <ul className={styles.search_list_scroll} data-testid="data-is-true">
          {data.map((item, index) => {
            return (
              <li
                data-testid={`${index}-pos`}
                className={styles.search_item}
                key={index}
                onKeyDown={() => {}}
                onClick={() => handleSelectOption(item)}
              >
                <div className={styles.individual}>
                  <div className={styles.name}>
                    <p>{getNameField(item)}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul className={styles.search_list} data-testid="data-is-true">
        {slice(data, 0, 10).map((item, index) => {
          return (
            <li
              data-testid={`${index}-pos`}
              className={styles.search_item}
              key={index}
              onKeyDown={() => {}}
              onClick={() => handleSelectOption(item)}
            >
              <div className={styles.individual}>
                <div className={styles.name}>
                  <p>{getNameField(item)}</p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {/* Loading */}
      {loading && isEmpty(data) && (
        <div className={styles.search_list} data-testid="loading">
          <div data-testid="loading-pos" className={styles.dot_flashing}></div>
        </div>
      )}

      {/* Show item */}
      {!loading && !isEmpty(data) && <>{handleShowListItems(fieldName)}</>}

      {/* Not Found */}
      {!loading &&
        isEmpty(data) &&
        debounceSearchQuery.type === 'search' &&
        !isEmpty(debounceSearchQuery.value) && (
          <div className={styles.search_list} data-testid="data-is-false">
            <p className={styles.search_notFound}>Search not found ...</p>
          </div>
        )}
    </>
  );
};

export default SearchListOption;
