import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { next_calendar, prev_calendar } from '../../../../assets/img';

const WeekCalendar = (props) => {
  const {
    date,
    setIsShowMonthCalendar,
    setStartDate,
    isShowMonthCalendar,
    scrollToElement,
    setShowMonthOfWeek,
    monthSelectedAntd,
    isShowMonthOfWeek,
    setMonthSelectedAntd,
  } = props;
  const [weekSelected, setWeekSelected] = useState(date);
  const [monthDisplay, setMonthDisplay] = useState(date);
  const onChange = (date, _dateString) => {
    setWeekSelected(new Date(date));
  };

  function calculateMonth(month, action) {
    let newDate = '';
    if (action === 'minus') {
      newDate = new Date(moment(month).subtract(1, 'months'));
      setMonthDisplay(newDate);
    } else {
      newDate = new Date(moment(month).add(1, 'months'));
      setMonthDisplay(newDate);
    }
    setWeekSelected(newDate);
    setMonthSelectedAntd(newDate);
  }

  const handleApplyWeek = () => {
    setStartDate(weekSelected);
    setIsShowMonthCalendar(false);
    scrollToElement(weekSelected.getDate());
  };

  useEffect(() => {
    setMonthDisplay(weekSelected);
  }, [weekSelected]);

  useEffect(() => {
    monthSelectedAntd ? setWeekSelected(monthSelectedAntd) : setWeekSelected(date);
  }, [date, monthSelectedAntd]);

  return (
    <>
      {isShowMonthOfWeek && (
        <DatePicker
          className="datePicker"
          open={isShowMonthCalendar}
          value={moment(weekSelected, 'YYYY-MM-DD')}
          onChange={onChange}
          picker="week"
          dateRender={(currentDate) => (
            <div
              className={
                moment(currentDate.format('YYYY-MM-DD')).isSame(
                  moment(weekSelected).startOf('week').format('YYYY-MM-DD')
                )
                  ? 'start-of-week'
                  : moment(currentDate.format('YYYY-MM-DD')).isSame(
                      moment(weekSelected).endOf('week').format('YYYY-MM-DD')
                    )
                  ? 'end-of-week'
                  : moment(currentDate.format('YYYY-MM-DD')).isBetween(
                      moment(weekSelected).startOf('week').format('YYYY-MM-DD'),
                      moment(weekSelected).endOf('week').format('YYYY-MM-DD'),
                      null,
                      '[]'
                    )
                  ? 'custom-day-of-week'
                  : 'custom-day'
              }
            >
              {currentDate.format('D')}
              {(moment(currentDate.format('YYYY-MM-DD')).isSame(
                moment(weekSelected).startOf('week').format('YYYY-MM-DD')
              ) ||
                moment(currentDate.format('YYYY-MM-DD')).isSame(
                  moment(weekSelected).endOf('week').format('YYYY-MM-DD')
                )) && <div className="override-day">{currentDate.format('D')}</div>}
            </div>
          )}
          nextIcon={
            <img
              src={next_calendar}
              alt={next_calendar}
              width="50px"
              height="50px"
              style={{ cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => {
                calculateMonth(monthDisplay, '');
              }}
            />
          }
          prevIcon={
            <img
              src={prev_calendar}
              alt={prev_calendar}
              width="50px"
              height="50px"
              style={{ cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => {
                calculateMonth(monthDisplay, 'minus');
              }}
            />
          }
          getPopupContainer={() => document.getElementById('area12')}
          renderExtraFooter={() => (
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ padding: '0 10px' }}
            >
              <div
                className="button-month-calendar"
                onKeyDown={() => {}}
                onClick={() => {
                  setIsShowMonthCalendar(false);
                  setMonthSelectedAntd(date);
                }}
              >
                Cancel
              </div>
              <div
                className="button-month-calendar"
                style={{ marginLeft: '10px', backgroundColor: '#00A19C', color: 'white' }}
                onKeyDown={() => {}}
                onClick={handleApplyWeek}
              >
                Apply
              </div>
              <div
                className="date-in-month"
                onKeyDown={() => {}}
                onClick={() => setShowMonthOfWeek(false)}
              >
                {moment(monthDisplay).format('MMMM')} {moment(monthDisplay).format('YYYY')}
              </div>
            </div>
          )}
        />
      )}
    </>
  );
};

WeekCalendar.propTypes = {
  date: PropTypes.object,
  setIsShowMonthCalendar: PropTypes.func,
  setStartDate: PropTypes.func,
  isShowMonthCalendar: PropTypes.bool,
  scrollToElement: PropTypes.func,
  setShowMonthOfWeek: PropTypes.func,
  monthSelectedAntd: PropTypes.object,
  isShowMonthOfWeek: PropTypes.bool,
  setMonthSelectedAntd: PropTypes.func,
};

export default WeekCalendar;
