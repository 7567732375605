import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { AiButton, AiInput, AiLabel } from '../../../../../assets/common';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { ALERT_MESSAGE_TYPE, ROLE_LEVEL_LIST, USER_ID } from '../../../../../utils/constants';
import styles from './addnew-role-level-dialog.module.scss';
import { isCheckError } from '../../../../../hooks/useFormik';

const AddNewRoleLevelDialog = (props) => {
  const dispatch = useDispatch();
  const { setIsShow, isShow, fetchData } = props;
  const [alreadyExistRoleLevel, setAlreadyExistRoleLevel] = useState(false);
  const [alreadyExistRoleLevelSP, setAlreadyExistRoleLevelSP] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);

  const validationSchema = yup.object({
    role_level: yup.string().required(true),
    role_level_sp: yup.string().required(true),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      role_level: '',
      role_level_sp: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  const onChangeInputData = async (value, key) => {
    formik.setFieldValue(key, value);
    if (key === ROLE_LEVEL_LIST.ROLE_LEVEL) {
      setAlreadyExistRoleLevel(false);
    } else {
      setAlreadyExistRoleLevelSP(false);
    }
  };

  const handleShowMessage = (type, message, description) => {
    dispatch(
      pushMessage({
        type,
        message,
        description,
        timeShow: 3000,
      })
    );
  };

  const handleAdd = async () => {
    await formik.submitForm();
    const values = formik.values;
    const roleLevelTrim = `${values.role_level ? values.role_level.trim() : ''}`;
    const roleLevelSPTrim = `${values.role_level_sp ? values.role_level_sp.trim() : ''}`;
    const valuesTrim = {
      role_level: roleLevelTrim,
      role_level_sp: roleLevelSPTrim,
    };
    try {
      const roleId = {
        role: roleActive,
        id: USER_ID,
      };
      await adminApi.addNewRoleLevel(roleId, valuesTrim);
      handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, `${values.role_level} successfully added`);
      fetchData();
      setIsShow(false);
    } catch (e) {
      const messageErrors = e.response.data.errors;
      if (messageErrors === ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL) {
        setAlreadyExistRoleLevel(true);
        setAlreadyExistRoleLevelSP(false);
      }
      if (messageErrors === ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_SP) {
        setAlreadyExistRoleLevelSP(true);
        setAlreadyExistRoleLevel(false);
      }
      console.log(e);
      setIsShow(true);
    }
  };

  return (
    <div>
      <Modal
        show={isShow}
        onHide={() => setIsShow(false)}
        dialogClassName={styles.remark}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
        centered
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div className={styles.title} data-testid="modal-remark-title">
            Add New
          </div>
        </Modal.Header>
        <Row className="mt-3" style={{ margin: '24px 84px 0 76px' }}>
          <Col span={24} className={styles.customize}>
            <AiLabel>Role Level</AiLabel>
            <AiInput
              placeholder="Role Level"
              name="role_level"
              style={{ width: '395px', height: '44px' }}
              status={isCheckError(formik, 'role_level') ? 'error' : ''}
              onChange={(_event) => onChangeInputData(_event.target.value, 'role_level')}
              value={formik.values.role_level}
            />
            {(formik.errors.role_level || alreadyExistRoleLevel) && (
              <span style={{ color: '#f85032', fontSize: '12px' }}>
                {alreadyExistRoleLevel
                  ? ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_MESSAGE
                  : ROLE_LEVEL_LIST.REQUIRED_FIELDS}
              </span>
            )}
          </Col>
          <Col span={24}>
            <AiLabel style={{ marginTop: '16px' }}>Role Level for Succession Planning</AiLabel>
            <AiInput
              placeholder="Role Level for Succession Planning"
              name="role_level_sp"
              style={{ width: '395px', height: '44px' }}
              status={isCheckError(formik, 'role_level_sp') ? 'error' : ''}
              onChange={(_event) => onChangeInputData(_event.target.value, 'role_level_sp')}
              value={formik.values.role_level_sp}
            />
            {(formik.errors.role_level_sp || alreadyExistRoleLevelSP) && (
              <span style={{ color: '#f85032', fontSize: '12px' }}>
                {alreadyExistRoleLevelSP
                  ? ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_SP_MESSAGE
                  : ROLE_LEVEL_LIST.REQUIRED_FIELDS}
              </span>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end" style={{ margin: '16px 82px 33px 0px' }}>
          <AiButton mode={'teal'} onClick={handleAdd} data-testid="submit">
            Add
          </AiButton>
        </div>
      </Modal>
    </div>
  );
};

export default memo(AddNewRoleLevelDialog);
