import queryString from 'query-string';
import axiosClient from './axiosClient';
import { generateFilterUrl } from '../utils/helper';

export const profileManagementApi = {
  getStaffSgJgList: (role, query) => {
    const stringified = queryString.stringify(query);
    const url = `/staff-sg-jg-management?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  getSgJgDataOptions: (role) => {
    const url = `/staff-sg-jg-management/data-options`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  editSgJgStaffManagement: (role, body) => {
    const url = `/staff-sg-jg-management/${body.staff_id}/update`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },
  uploadPhoto: (body, config) => {
    const url = `/external-talent-photos`;
    return axiosClient.post(url, body, config);
  },

  getExternalOptions: (roleId) => {
    const url = `/external-talent-options`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  addExternalTalent: (body, roleId) => {
    const url = '/external-talents';
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getDetailExternalTalent: (talentId, roleId) => {
    const url = `/external-talents/${talentId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  editExternalTalent: (talentId, body, roleId) => {
    const url = `/external-talents/${talentId}`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getExternalAssessmentDates: (staffId, roleId) => {
    const url = `/talent-profiles/external-assessments/assessment-dates/${staffId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getExternalAssessmentsByType: (staffId, roleId, params) => {
    const queryParams = generateFilterUrl(params);
    const url = `/talent-profiles/external-assessments/${staffId}${queryParams}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
};
