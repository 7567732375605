import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Tooltip as TooltipAntd } from 'antd';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as styles from './add-talent-video.module.scss';
import { BasicButton, BasicInput, BasicLabel } from '../../../../../../assets/common';
import { FILL_OUT_REQUIRE_MESSAGE, GLOBAL_ALERT_MESSAGE_CONTAINER_ID, MESSAGE_TYPES } from '../../../../../../utils/constants';
import { pushMessage, removeMessageByContainer } from '../../../../../../store/alertMessageSlice';
import GlobalAlertMessage from '../../../../../../components/GlobalAlertMessage/GlobalAlertMessage';
import talentProfileApi from '../../../../../../services/talentProfiles';
import { TooltipDesc } from './styled';
import { TALENT_VIDEO } from '../../constants';
import { info_circle } from '../../../../../../assets/img';

const INVALID_LINK_MESSAGE = 'Input is invalid. Please enter a URL in Attachment Link.';

const AddTalentVideo = (props) => {
  const { isViewMore, setOpenPopupAdd, profileId, roleId } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: '',
      link: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required(FILL_OUT_REQUIRE_MESSAGE),
      link: Yup.string().required(FILL_OUT_REQUIRE_MESSAGE).url(INVALID_LINK_MESSAGE),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await talentProfileApi.addTalentVideo(profileId, values, roleId);
        if (res.status === 200) {
          dispatch(
            pushMessage({
              message: 'Talent Video successfully added.',
              type: MESSAGE_TYPES.SUCCESS,
              isOnly: true,
              timeShow: 0,
              containerId: isViewMore ? GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_VIDEO : null,
            })
          );
          setOpenPopupAdd({ open: false, isViewMore: false, isAdded: true });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleSubmit = async () => {
    dispatch(removeMessageByContainer(GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_VIDEO_ADD));
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    } else {
      Object.values(errors).forEach((error) => {
        dispatch(
          pushMessage({
            message: error,
            type: MESSAGE_TYPES.ERROR,
            timeShow: 0,
            containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_VIDEO_ADD,
          })
        );
      });
    }
  };

  const handleClickCancel = () => {
    setOpenPopupAdd({ open: false, isViewMore: false, isAdded: false });
  };

  const handleChangeValue = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    formik.setFieldValue(fieldName, value);
  };

  return (
    <div className={styles.wrapper}>
      <GlobalAlertMessage id={GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_VIDEO_ADD} />
      <Spin spinning={loading}>
        <div className={styles.titleInput}>
          <BasicLabel required>Title</BasicLabel>
          <BasicInput width={375} name="title" placeholder="Enter Video Title" error={formik.errors.title} onBlur={handleChangeValue} />
        </div>
        <div className={styles.linkInput}>
          <BasicLabel required>
            Attachment Link
            <TooltipAntd
              title={
                <TooltipDesc>
                  {TALENT_VIDEO.FISRT_MESSAGE}
                  <span className={styles.https_msg}>{TALENT_VIDEO.HTTPS_MSG}</span>
                  {' or '}
                  <span className={styles.https_msg}>{TALENT_VIDEO.HTTP_MSG}</span>
                  {', e.g., '}
                  <span className={styles.https_msg}>{TALENT_VIDEO.HTTPS_EXAMPLE}</span>
                  {' or '}
                  <span className={styles.https_msg}>{TALENT_VIDEO.HTTP_EXAMPLE}</span>
                </TooltipDesc>
              }
              {...{
                overlayInnerStyle: {
                  minWidth: '250px',
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontWeight: 600,
                  fontFamily: `'Inter', sans-serif`,
                  padding: '12px',
                  borderRadius: '4px',
                  backgroundColor: '#000',
                },
              }}
            >
              <img src={info_circle} alt="" className={styles.info_circle} />
            </TooltipAntd>
          </BasicLabel>
          <BasicInput placeholder="Enter Video Link" name="link" error={formik.errors.link} onBlur={handleChangeValue} />
        </div>
        <div className={styles.buttons}>
          <BasicButton onClick={handleClickCancel}>Cancel</BasicButton>
          <BasicButton mode="teal" type="submit" onClick={handleSubmit}>
            Add Video
          </BasicButton>
        </div>
      </Spin>
    </div>
  );
};

AddTalentVideo.propTypes = {
  isViewMore: PropTypes.bool,
  setOpenPopupAdd: PropTypes.func,
  profileId: PropTypes.string,
  roleId: PropTypes.number,
};

export default AddTalentVideo;
