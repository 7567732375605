/* eslint-disable array-callback-return */
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';

export const remarksSelector = (state) => state.app.meetingModule.remarks;

export const remarks = (state) => state.app.meetingModule.remarks.remarks;
export const filterList = (state) => state.app.meetingModule.remarks.filterList;

export const syndicationSelector = (state) => state.app.meetingTC.agenda.syndication;

export const positionsSelector = (state) =>
  state.app.meetingModule.agenda.agendaIncumbent.positions;
export const meetingStatusSelector = (state) =>
  state.app.meetingModule.preMeeting.isEdittingMeeting;
export const remarksRemainingSelector = createSelector(
  filterList,
  remarks,
  (filterList, remarks) => {
    if (
      (isEmpty(filterList.listSelectName) && isEmpty(filterList.listSelectCategory)) ||
      isEmpty(filterList)
    ) {
      return remarks;
    }
    if (!isEmpty(filterList.listSelectName) && !isEmpty(filterList.listSelectCategory)) {
      let filterRemarksByName = remarks.filter((item) => {
        for (let selectItem of filterList.listSelectName) {
          if (isEqual(item.birthName, selectItem.value)) {
            return item;
          }
        }
      });
      let filterRemarksByCategory = filterRemarksByName.filter((item) => {
        for (let selectItem of filterList.listSelectCategory) {
          if (isEqual(item.remarksCategory, selectItem.value)) {
            return item;
          }
        }
      });
      return filterRemarksByCategory;
    }
    if (!isEmpty(filterList.listSelectName)) {
      let filterRemarksByName = remarks.filter((item) => {
        for (let selectItem of filterList.listSelectName) {
          if (isEqual(item.birthName, selectItem.value)) {
            return item;
          }
        }
      });
      return filterRemarksByName;
    }
    if (!isEmpty(filterList.listSelectCategory)) {
      let filterRemarksByCategory = remarks.filter((item) => {
        for (let selectItem of filterList.listSelectCategory) {
          if (isEqual(item.remarksCategory, selectItem.value)) {
            return item;
          }
        }
      });
      return filterRemarksByCategory;
    }
  }
);
