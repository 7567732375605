import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { Checkbox } from '../../../';
import { filter_icon } from '../../../../assets/img';
import styles from './drop-down.module.scss';

const Dropdown = ({ setListSelectType, filtersOption }) => {
  const [select, setSelect] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  const handleSearch = () => {
    let listSelect = select.filter((ele) => ele?.isChecked === true);
    setListSelectType(listSelect);
    setIsActive(false);
  };
  const handleSetDefault = () => {
    if (filtersOption.length === 0) return;
    let listSelection = filtersOption.map((ele) => {
      return ele;
    });
    setSelect(listSelection);
  };
  useEffect(() => {
    handleSetDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersOption]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={styles.dropdown__icon}
        onKeyDown={() => {}}
        onClick={() => setIsActive(!isActive)}
      >
        <img src={filter_icon} alt="" />
      </div>
      {isActive && (
        <div className={styles.dropdown__list}>
          {!isEmpty(select) &&
            select.map((item, index) => {
              return (
                <div className={styles.dropdown__item} key={index}>
                  <Checkbox item={item} select={select} setSelect={setSelect} />
                </div>
              );
            })}
          <div className={styles.dropdown__apply}>
            <button
              className={styles.dropdown__btn}
              onKeyDown={() => {}}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dropdown;
