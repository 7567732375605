import { cloneDeep, concat, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { dashboard_breadcrumb } from '../../../assets/img';
import { AlertMessage, AsyncMultipleSelect, BreadCrumb, ModelTC } from '../../../components';
import mobilitySimulationApi from '../../../services/mobilitySimulationApi';
import {
  convertDataChain,
  convertPositionSimulation,
  convertTalentSimulation,
  getAsyncOptionAdvocatorsHrPartners,
  handleShowMessageSuccessMS,
} from '../../../utils/helper';
import MobilityChainSimulation from '../components/MobilityChainSimulation/MobilityChainSimulation';
import MobilityDragDrop from '../components/MobilityDragDrop/MobilityDragDrop';
import { MOBILITY_MESSAGE, MOBILITY_SIMULATION } from '../constants';
import styles from './mobility-simulation-add.module.scss';
import { ModalMobilityPreview } from './styled';

const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Mobility Simulation' },
];

const MobilitySimulationAdd = () => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const { idEdit, idDuplicate } = useParams();
  const [mobilitySimulation, setMobilitySimulation] = useState({
    isValidate: true,
    isShowMessage: {},
    name: '',
    dataChain: [],
  });
  const [preview, setPreview] = useState([]);
  const [modalSaveSimulation, setModalSaveSimulation] = useState(false);
  const [modalDuplicateSimulation, setModalDuplicateSimulation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState({ key: 0, position_code: 0 });
  const [isSeniorManager, setIsSeniorManager] = useState(false);

  const history = useHistory();

  const handleShowPreview = () => {
    let data = cloneDeep(mobilitySimulation.dataChain);
    data = data.map((item) => {
      delete item.isRemove;
      return item;
    });
    setPreview(data);
  };

  const handleResetSimulation = () => {
    setIsHighlighted({ key: 0, position_code: 0 });
    setMobilitySimulation((prev) => {
      return {
        ...prev,
        dataChain: [],
      };
    });
  };

  const removeItemOnChain = (index) => {
    let newArr = cloneDeep(mobilitySimulation).dataChain.slice(0, index);
    const lastItem = newArr[newArr.length - 1];
    setIsHighlighted({
      key: lastItem ? Number(lastItem.key) : 0,
      position_code: lastItem ? lastItem.position_code : 0,
    });

    setMobilitySimulation((prev) => {
      return {
        ...prev,
        dataChain: newArr,
      };
    });
  };

  const validateSaveSimulation = () => {
    if (!mobilitySimulation.name || isEmpty(mobilitySimulation.dataChain)) {
      setMobilitySimulation((prev) => {
        return {
          ...prev,
          isValidate: false,
          isShowMessage: {
            type: 'error',
            message: MOBILITY_MESSAGE.IN_VALID,
            onClose: () =>
              setMobilitySimulation((prev) => {
                return {
                  ...prev,
                  isShowMessage: {},
                };
              }),
            timeShow: 5000,
          },
        };
      });
      return;
    }
    setModalSaveSimulation(true);
  };

  const handleSaveSimulation = async () => {
    try {
      const body = {
        mbsm_id: idEdit ? idEdit : '',
        name: mobilitySimulation.name,
        position_code: mobilitySimulation.dataChain[0].position_code,
        hr_partners: !isEmpty(mobilitySimulation.hrPartners)
          ? mobilitySimulation.hrPartners.map((item) => {
              return item.value;
            })
          : [],
        talent_chain: convertDataChain(mobilitySimulation.dataChain),
        created_from: MOBILITY_SIMULATION.CREATED_FROM_MS_MODULE,
      };
      setLoading(true);
      const response = idEdit
        ? await mobilitySimulationApi.editMobilitySimulation(body, roleActive)
        : await mobilitySimulationApi.saveMobilitySimulation(body, roleActive);
      if (response.status === 200) {
        setLoading(false);
        setModalSaveSimulation(false);

        const pathname =
          idEdit || idDuplicate
            ? '/mobility-simulation'
            : `/mobility-simulations/${response.data.result.mbsm_id}/details`;
        const message =
          (idEdit && 'edit-success') || (idDuplicate && 'duplicate-success') || 'add-success';
        history.push({
          pathname,
          state: { message },
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setModalSaveSimulation(false);
      return setMobilitySimulation((prev) => {
        return {
          ...prev,
          isValidate: false,
          isShowMessage: {
            type: 'error',
            message: error.message,
            onClose: () =>
              setMobilitySimulation((prev) => {
                return {
                  ...prev,
                  isShowMessage: {},
                };
              }),
            timeShow: 5000,
          },
        };
      });
    }
  };

  useEffect(() => {
    if (!idEdit && !idDuplicate) return;
    async function fetchMobilityDetail() {
      try {
        setLoading(true);
        const res = await mobilitySimulationApi.getMobilityDetail(
          idEdit || idDuplicate,
          roleActive.roleId
        );

        let positions = [];
        let talents = [];
        if (!isEmpty(res.data.result.position)) {
          const objPosition = res.data.result.position[0];
          if (objPosition.role_level === 'Senior Manager') {
            setIsSeniorManager(true);
          }
          positions = res.data.result.position.map((item) => {
            return convertPositionSimulation(item);
          });
        }
        if (!isEmpty(res.data.result.talents)) {
          const arrTalent = res.data.result.talents;
          const isSM = arrTalent.some((x) => x.role_level === 'Senior Manager');
          if (isSM) {
            setIsSeniorManager(true);
          }
          talents = res.data.result.talents.map((item) => {
            return convertTalentSimulation(item);
          });
        }
        const dataChain = concat(positions, talents).map((item) => {
          return {
            ...item,
            isRemove: true,
            isDisableIndex: true,
          };
        });
        setMobilitySimulation({
          isValidate: true,
          isShowMessage: {},
          name: idEdit ? get(res, 'data.result.name') : '',
          dataChain,
          hrPartners: get(res, 'data.result.hr_partners')
            ? get(res, 'data.result.hr_partners').map((item) => {
                return { label: item.name, id: item.id, value: item.id };
              })
            : [],
        });

        const lastItem = dataChain[dataChain.length - 1];
        setIsHighlighted({
          key: lastItem ? Number(lastItem.key) : 0,
          position_code: lastItem ? lastItem.position_code : 0,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchMobilityDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEdit, idDuplicate]);
  return (
    <div className={styles.maxWidth}>
      <BreadCrumb level={2} breadCrumbList={breadCrumbList} />
      {!isEmpty(mobilitySimulation.isShowMessage) && (
        <div style={{ marginBottom: '20px' }}>
          <AlertMessage {...mobilitySimulation.isShowMessage} />
        </div>
      )}

      <ModalMobilityPreview
        title="Mobility Chain Simulation"
        width={1283}
        open={!isEmpty(preview)}
        footer={null}
        onCancel={() => setPreview([])}
      >
        <MobilityChainSimulation data={preview} />
      </ModalMobilityPreview>

      <ModelTC
        info={{
          type: 'saveSimulation',
          visible: modalSaveSimulation,
          setVisible: setModalSaveSimulation,
          onClose: () => setModalSaveSimulation(false),
          handleSubmit: () => handleSaveSimulation(),
          loading,
        }}
      />
      <ModelTC
        info={{
          type: 'duplicateSimulation',
          visible: modalDuplicateSimulation,
          setVisible: setModalDuplicateSimulation,
          onClose: () => setModalDuplicateSimulation(false),
          handleSubmit: () => handleSaveSimulation(),
          loading,
        }}
      />
      <h3 className={styles.title}>Mobility Simulation</h3>
      <div className={styles.buttonPosition}>
        <div
          className={`${styles.button} ${styles.buttonColorWhite} ${
            isEmpty(mobilitySimulation.dataChain) && styles.buttonDisable
          }`}
          onKeyDown={() => {}}
          onClick={handleShowPreview}
        >
          Preview Simulation
        </div>
        <div
          className={`${styles.button} ${styles.buttonColorWhite}`}
          onKeyDown={() => {}}
          onClick={handleResetSimulation}
        >
          Reset
        </div>
        <div
          className={`${styles.button} ${styles.buttonColorWhite}`}
          onKeyDown={() => {}}
          onClick={() => history.push('/mobility-simulation')}
        >
          Cancel
        </div>
        <div className={styles.button} onKeyDown={() => {}} onClick={validateSaveSimulation}>
          Save Simulation
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.titleDetail}>Mobility Simulation Details</div>
        <div className={styles.form}>
          <div>
            Mobility Simulation Name<span style={{ color: '#FF787F' }}>*</span>
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter Mobility Simulation Name"
              className={`${styles.input} ${
                !mobilitySimulation.isValidate && !mobilitySimulation.name && styles.inputError
              }`}
              onChange={(e) => {
                const name = e.target.value;
                return setMobilitySimulation((prev) => {
                  return {
                    ...prev,
                    name,
                  };
                });
              }}
              value={mobilitySimulation.name}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div>HR Partners</div>
          <div data-testid="hr-select">
            <AsyncMultipleSelect
              value={mobilitySimulation.hrPartners}
              placeholder="Enter HR Partners Name"
              loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
              onChange={(e) =>
                setMobilitySimulation((prev) => {
                  return {
                    ...prev,
                    hrPartners: e,
                  };
                })
              }
            />
          </div>
        </div>
      </div>
      <MobilityDragDrop
        setMobilitySimulation={setMobilitySimulation}
        mobilitySimulation={mobilitySimulation}
        handleShowMessageSuccess={(message) =>
          handleShowMessageSuccessMS(message, setMobilitySimulation)
        }
        removeItemOnChain={removeItemOnChain}
        isHighlighted={isHighlighted}
        setIsHighlighted={setIsHighlighted}
        setIsSeniorManager={setIsSeniorManager}
        isSeniorManager={isSeniorManager}
      />
    </div>
  );
};

export default MobilitySimulationAdd;
