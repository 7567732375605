import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteNote, editNote, ellipsis } from '../../../../../../assets/img';
import { Button, ModelTC } from '../../../../../../components';
import DropdownCategory from '../../../../../Agenda/components/DropdownCategory/DropdownCategory';
import { editRemark } from '../../../../../Meeting/redux/remarksSlice';
import styles from './remark-table-dialog.module.scss';

const mock_data_category = [
  { id: 1, name: 'Notes' },
  { id: 2, name: 'Development Areas' },
  { id: 3, name: 'Key Strength' },
];

const RemarkTableBody = (props) => {
  const { item, index, length, onSaveItem, onDeleteItem, isViewModeAgenda, setIdEdit, idEdit } =
    props;
  const [isActive, setIsActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [remarkClone, setRemarkClone] = useState(item.remarks);
  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();

  const handleUpdate = (category) => {
    dispatch(
      editRemark({
        remarkId: item.remarkId,
        category: category?.name || '',
        remarks: remarkClone || '',
      })
    );
  };

  const handleSave = () => {
    onSaveItem({
      remarkId: item.remarkId,
      category: item.remarksCategory,
      remarks: remarkClone || '',
    });
  };

  const handleCancel = () => {
    setRemarkClone(item.remarks);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.table_body} data-testid="item-remark-modal">
      <ModelTC
        info={{
          type: 'deleteRemarks',
          visible: showDelete,
          setVisible: setShowDelete,
          handleSubmit: () => {
            onDeleteItem(item.remarkId);
            setShowDelete(false);
          },
          onClose: () => setShowDelete(false),
        }}
      />
      <div style={{ width: '21%' }}>
        {!isEdit ? (
          item.remarksCategory
        ) : (
          <DropdownCategory
            selected={item.remarksCategory}
            setSelected={(e) => handleUpdate(e)}
            options={mock_data_category}
          />
        )}
      </div>

      <div style={{ width: '72%' }}>
        {!isEdit ? (
          remarkClone && <span className={styles.normalText}>{remarkClone}</span>
        ) : (
          <div>
            <textarea
              placeholder="remark"
              className={styles.input_remark}
              value={remarkClone}
              onChange={(e) => setRemarkClone(e.target.value)}
            ></textarea>
            <div style={{ marginBottom: '22px' }} className="d-flex justify-content-end">
              <Button
                className={styles.btn_cancel}
                onKeyDown={() => {}}
                onClick={() => {
                  setIsEdit(false);
                  setIdEdit(null);
                  handleCancel();
                }}
              >
                <span className={styles.text_btn_cancel}>Cancel</span>
              </Button>
              <Button
                className={styles.btn_save}
                onKeyDown={() => {}}
                onClick={() => {
                  setIsEdit(false);
                  setIdEdit(null);
                  handleSave();
                }}
              >
                <span className={styles.text_btn_save}>Save</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      {!isEdit && !idEdit && !isViewModeAgenda && (
        <div className={styles.col_1} ref={ref}>
          <img
            className={styles.ellipsis}
            src={ellipsis}
            alt="ellipsis"
            onKeyDown={() => {}}
            onClick={(e) => setIsActive(!isActive)}
            data-testid="click-to-update"
          />

          {isActive && (
            <div
              className={
                index + 1 === length
                  ? `${styles.dropdownContent} ${styles.lastItem}`
                  : styles.dropdownContent
              }
            >
              <div
                className={styles.function}
                onKeyDown={() => {}}
                onClick={() => {
                  setIsEdit(true);
                  setIdEdit(item.remarkId);
                  setIsActive(false);
                }}
                data-testid="click-edit"
              >
                <img src={editNote} className={`mr-2 ${styles.editIcon}`} alt={'editNote'} />
                <div className={styles.content_edit} data-testid="edit-title">
                  Edit
                </div>
              </div>

              <div
                className={styles.function}
                onKeyDown={() => {}}
                onClick={() => {
                  setIsActive(false);
                  setShowDelete(true);
                }}
                data-testid="click-delete"
              >
                <img src={deleteNote} className={`mr-2 ${styles.deleteIcon}`} alt={'deleteNote'} />
                <div className={styles.content_delete} data-testid="delete-title">
                  Delete
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RemarkTableBody;
