import React from 'react';
import * as SC from './styled';
import { Select } from 'antd';
import { arrow_dropdown } from '../../assets/img';

const DropdownMeeting = (props) => {
  const { size, label, options, selected, setSelected, placeholder } = props;
  const { Option } = Select;

  function handleChange(value) {
    const item = options.find((d) => d.id === value);
    setSelected(item);
  }

  return (
    <SC.Dropdown
      size={size}
      style={{ position: 'relative' }}
      id="area"
      data-testid="dropdown-user-management"
    >
      <div style={{ width: '30%' }}>{label || 'No label'}</div>
      <Select
        placeholder={placeholder}
        suffixIcon={<img src={arrow_dropdown} alt="" />}
        onChange={handleChange}
        getPopupContainer={() => document.getElementById('area')}
        value={selected.name}
      >
        {options &&
          options.length > 0 &&
          options.map((item, index) => (
            <Option
              key={`${label}_${index}`}
              value={item.id}
              className="d-flex align-items-center"
              style={{ height: `${size?.height || '45px'}` }}
            >
              {item.name}
            </Option>
          ))}
      </Select>
    </SC.Dropdown>
  );
};

export default DropdownMeeting;
