import { Checkbox, Table } from 'antd';
import { difference, isEmpty, isEqual, union, uniqWith } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { dropdown_builder } from '../../../../../../../assets/img';
import * as styles from './data-table.module.scss';
import * as SC from './styled';

const Icon = styled.div`
  img {
    &:hover {
      cursor: pointer;
    }
    pointer-events: all;
  }

  .rotate {
    transform: rotate(180deg);
  }
`;

const ChildTable = styled.div`
  font-family: 'Inter', sans-serif;
  height: 100%;

  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
  }

  .header-font {
    color: var(--light-gray-500, #7a7a7a);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  .header-background {
    height: 52px;
    background: var(--light-blue-grays-200, #e7edf3);
  }

  .body-background {
    background: var(--light-blue-grays-100, #f2f4f8);
    border: 1px solid #d3dee8;
  }

  .hidden {
    visibility: hidden;
  }
  .name {
    color: var(--emphasis-body-n-800, #3f3c4c);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
  }
  .text {
    color: var(--light-gray-500, #7a7a7a);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  .checkbox-width {
    width: 133px;
    margin-left: 24px;
  }

  .staff-name-width {
    width: 270px;
  }

  .position-width {
    width: 268px;
  }
`;
export default function MeetingTalentData({
  query,
  data,
  setQuery,
  selectedRows,
  setSelectedRows,
  talents,
  setTalents,
}) {
  const { totalPage, page, limit } = query;
  const [expanded, setExpanded] = useState([]);

  const expandRef = useRef([]);
  const addExpand = (agenda_id) => {
    expandRef.current = expanded;
    setExpanded((prev) => [...prev, agenda_id]);
  };

  const removeExpand = (agenda_id) => {
    expandRef.current = expanded;
    setExpanded((prev) => prev.filter((item) => item !== agenda_id));
  };

  const onChange = (e, talent) => {
    const checked = e.target.checked;
    if (checked) {
      const newTalents = [...talents, talent];
      const isAllTalentsAdded = data
        .find((i) => i.agenda_id === talent.agenda_id)
        .talents.every((item) => newTalents.map((d) => d.staff_id).includes(item.staff_id));
      setTalents(newTalents);
      if (isAllTalentsAdded) {
        setSelectedRows((prev) => union([...prev, talent.agenda_id]));
      }
    } else {
      const newTalents = [...talents].filter((item) => item.staff_id !== talent.staff_id);
      const isAllTalentsAdded = data
        .find((i) => i.agenda_id === talent.agenda_id)
        .talents.every((item) => [...talents].map((d) => d.staff_id).includes(item.staff_id));
      setTalents(newTalents);

      if (isAllTalentsAdded) {
        setSelectedRows((prev) => prev.filter((item) => item !== talent.agenda_id));
      }
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(page - 1) * limit + 1 + index}</span>
      ),
      width: 5,
      align: 'center',
    },

    {
      title: 'Meeting Name',
      dataIndex: 'meeting_name',
      width: 300,
    },
    {
      title: 'Agenda Name',
      dataIndex: 'agenda_name',
      width: 300,
    },
    {
      title: 'Position Name and Code',
      dataIndex: 'position_code',
      width: 300,
    },
    {
      title: 'Updated Date and Time',
      dataIndex: 'start_at',
      width: 300,
    },
    {
      title: '',
      dataIndex: 'arrow_icon',
      width: 100,
      align: 'center',
    },
  ];

  const fromResult = (page - 1) * limit + 1;
  const toResults = (page - 1) * limit + limit;

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      if (selectedRows.length < selectedRowKeys.length) {
        const differrenceList = difference(selectedRowKeys, selectedRows);
        let newTalents = [];
        differrenceList.forEach((i) => {
          const agendaTalents = data.find((item) => i === item.agenda_id)?.talents;
          if (isEmpty(agendaTalents)) return;
          newTalents = [
            ...newTalents,
            ...agendaTalents.map((item) => ({
              staff_id: item.staff_id,
              agenda_id: item.agenda_id,
            })),
          ];
        });
        setTalents(uniqWith([...talents, ...newTalents], isEqual));
      }
      if (selectedRows.length > selectedRowKeys.length) {
        const differrenceList = difference(selectedRows, selectedRowKeys);
        const agendaRemove = selectedRows.filter((item) => !differrenceList.includes(item));
        setTalents((prev) => prev.filter((item) => agendaRemove.includes(item.agenda_id)));
      }
      setSelectedRows(selectedRowKeys);
    },
    selectedRowKeys: selectedRows,
    preserveSelectedRowKeys: true,
  };

  const paginationConfig = {
    current: page,
    total: totalPage,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== page && setQuery((prev) => ({ ...prev, page: pageNum })),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > totalPage ? totalPage : toResults}</b> of&nbsp;
        <b>{totalPage}</b> results
      </>
    ),
  };

  return (
    <div style={{ marginTop: 24, marginBottom: 24 }}>
      <SC.TableManagement>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data.map((d, _index) => ({
            key: d.agenda_id,
            talents: d.talents,
            position_name: d.position_name,
            meeting_name: <div className={styles.table_name}>{d.meeting_name}</div>,
            agenda_name: <div className={styles.table_name}>{d.agenda_name}</div>,
            position_code: (
              <div>
                <div
                  className={styles.table_name}
                  style={{ marginBottom: '5px', fontSize: 13, fontWeight: 400 }}
                >
                  {d.position_name}
                </div>
                <div className={styles.table_except_name}>{d.position_code}</div>
              </div>
            ),
            start_at: (
              <div
                className={styles.table_name}
                style={{ marginBottom: '5px', fontSize: 13, fontWeight: 400 }}
              >
                {moment(d.start_at).format('DD/MM/YYYY hh:mm A')}
              </div>
            ),
            arrow_icon: (
              <Icon>
                {expanded.includes(d.agenda_id) ? (
                  <img
                    src={dropdown_builder}
                    onKeyDown={() => {}}
                    onClick={() => removeExpand(d.agenda_id)}
                    className="rotate"
                    alt=""
                  />
                ) : (
                  <img
                    src={dropdown_builder}
                    onKeyDown={() => {}}
                    onClick={() => addExpand(d.agenda_id)}
                    alt=""
                  />
                )}
              </Icon>
            ),
          }))}
          expandable={{
            expandedRowRender: (record) => (
              <ChildTable>
                <div className="flex header-background">
                  <div className="hidden checkbox-width">Checkbox</div>
                  <div className="header-font staff-name-width">Staff Name and Staff ID</div>
                  <div className="header-font position-width">Position</div>
                  <div className="header-font readiness-width">SP Readiness</div>
                </div>
                {!isEmpty(record?.talents) &&
                  record.talents.map((d) => (
                    <div className="flex body-background">
                      <div className="checkbox-width">
                        <Checkbox
                          onChange={(e) =>
                            onChange(e, { staff_id: d.staff_id, agenda_id: d.agenda_id })
                          }
                          checked={talents.find(
                            (i) => i.staff_id === d.staff_id && i.agenda_id === d.agenda_id
                          )}
                        />
                      </div>
                      <div className="staff-name-width">
                        <div className="name">{d.birth_name}</div>
                        <div className="text">Staff ID : {d.staff_id}</div>
                      </div>
                      <div className="position-width">
                        <div className={styles.table_except_name}>{record.position_code}</div>
                      </div>
                      <div className="text readiness-width">{d.line}</div>
                    </div>
                  ))}
              </ChildTable>
            ),
          }}
          showExpandColumn={false}
          expandedRowKeys={expanded}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
}
