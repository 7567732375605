import { Spin, Tooltip } from 'antd';
import { isEmpty, isNumber, orderBy, size } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { BasicButton } from '../../../../../assets/common';
import { no_criteria } from '../../../../../assets/img';
import { AlertMessage, ModelTC } from '../../../../../components';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  FORM_TYPES,
  MESSAGE_TYPES,
  SET_MATCHING_CRITERIA_STATUS,
} from '../../../../../utils/constants';
import {
  removeSetMatchingCriteria,
  resetCriteriaState,
  updateInitCriteriaEditState,
  updateIsEditCriteria,
  updateIsEditSetMatching,
  updateIsFirstInitSetMatching,
  updateIsSavedSetMatching,
  updateListRanking,
  updatePrevSetMatching,
  updateSetAsMainListMatching,
  updateSetMatchingByIndex,
  updateSetMatchingIndex,
} from '../../redux/manageCriteriaSetMatchingSlice';
import AddCriteriaPopOutSetMatching from '../components/AddCriteriaPopOut/AddCriteriaPopOutSetMatching';
import AddCriteriaPopOutSetMatchingForDuplicate from '../components/AddCriteriaPopOut/AddCriteriaPopOutSetMatchingForDuplicate';
import AddMatchingCriteriaPositionForm from '../components/AddCriteriaPositionForm/AddMatchingCriteriaPositionForm';
import SetCriteriaComponent from '../components/SetCriteriaComponent/SetCriteriaComponent';
import * as styles from './add-edit-criteria.module.scss';

const AddEditCriteriaSetMatching = (props) => {
  const { mode } = props;

  const {
    position_code,
    position_name,
    set_index,
    set_matching_criteria,
    prevSetMatching,
    isSavedSetMatching,
  } = useSelector((state) => state.app.adminModule.manageCriteriaSetMatching);
  const history = useHistory();
  const dispatch = useDispatch();
  const { positionCode } = useParams();

  const [isSummary, setIsSummary] = useState(false);
  const [showSelectCriteria, setShowSelectCriteria] = useState(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [showConfirmRunCriteria, setShowConfirmRunCriteria] = useState(false);
  const [showProcessRunSetMatching, setShowProcessRunSetMatching] = useState(false);
  const [showEditConfirm, setShowEditConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [keepRanking, setKeepRanking] = useState(mode === FORM_TYPES.EDIT);
  const [currentTab, setCurrentTab] = useState(set_index);
  const [isNewSetMatching, setIsNewSetMatching] = useState(false);
  const [duplicateSetMatching, setDuplicateSetMatching] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDuplicateSetMatching, setIsDuplicateSetMatching] = useState(false);
  const [rankingData, setRankingData] = useState([]);
  const [currentSetMatching, setcurrentSetMatching] = useState(null);
  const [isSavedMatching, setIsSavedMatching] = useState(false);
  const [oneTimeToShowMessage, setOneTimeToShowMessage] = useState(false);
  const [viewResultMessage, setViewResultMessage] = useState(false);

  const cloneSetMatchingCriteria = useMemo(() => {
    return set_matching_criteria;
  }, [set_matching_criteria]);

  // initial current tab
  useEffect(() => {
    setCurrentTab(set_index);
  }, [set_index]);

  useEffect(() => {
    if (
      set_matching_criteria[set_index]?.status === SET_MATCHING_CRITERIA_STATUS.COMPLETED &&
      !oneTimeToShowMessage &&
      position_code == positionCode
    ) {
      setOneTimeToShowMessage(true);
      setViewResultMessage(true);
    }
  }, [set_matching_criteria, set_index, oneTimeToShowMessage]);

  // Re-update data when duplicate set matching
  useEffect(() => {
    if (duplicateSetMatching) {
      setcurrentSetMatching({
        position_code: position_code,
        position_name: position_name,
        set_index: set_index,
        set_matching_criteria: cloneSetMatchingCriteria,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateSetMatching, position_code, position_name]);

  const handleSaveSetMatching = async () => {
    try {
      setIsLoading(true);
      const res = await adminApi.saveMatchingCriteria({
        position_code,
        set_matching_criteria: [
          {
            set_name: set_matching_criteria[set_index].set_name,
            is_set_main:
              size(set_matching_criteria) <= 1 ||
              (size(set_matching_criteria) > 1 && set_matching_criteria[set_index]?.set_as_main),
            set_number: set_matching_criteria[set_index].set_number,
            end_date: set_matching_criteria[set_index].end_date,
            status: set_matching_criteria[set_index].status,
            selected_criteria: [
              ...set_matching_criteria[set_index].entered_criteria,
              ...set_matching_criteria[set_index].entered_un_rank,
            ],
          },
        ],
      });
      if (res.status === 201) {
        setIsSummary(true);
        setShowSaveConfirm(false);
        setShowConfirmRunCriteria(true);
        setIsNewSetMatching(false);
        setDuplicateSetMatching(false);
        setIsSavedMatching(true);
        dispatch(updateIsSavedSetMatching(true));
        dispatch(updateIsEditSetMatching({ set_index, value: false }));
        dispatch(updateIsEditCriteria({ set_index, value: false }));
        dispatch(
          updateSetAsMainListMatching(
            set_matching_criteria.map((item, index) => ({
              ...item,
              is_set_main:
                set_index === index && item.set_as_main
                  ? true
                  : item.is_set_main && item.set_as_main,
              isEditSetMatching: false,
              history_not_uses: res?.data?.result[0]?.history_ids,
              set_id: res?.data?.result[0]?.set_id,
            }))
          )
        );
        dispatch(
          updatePrevSetMatching({
            ...prevSetMatching,
            set_matching_criteria: prevSetMatching.set_matching_criteria.map((item, index) => ({
              ...item,
              is_set_main:
                set_index === index && item.set_as_main
                  ? true
                  : item.is_set_main && item.set_as_main,
              isEditSetMatching: false,
              history_not_uses: res?.data?.result[0]?.history_ids,
              set_id: res?.data?.result[0]?.set_id,
            })),
          })
        );
        history.push(`/admin/sp-management/edit-criteria/${position_code}`);
      }
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `An unexpected error occurred.`,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditSetMatching = async () => {
    try {
      setIsLoading(true);
      const res = await adminApi.editMatchingCriteria({
        position_code,
        set_matching_criteria: [
          {
            set_id: isNumber(set_matching_criteria[set_index].set_id)
              ? set_matching_criteria[set_index].set_id
              : null,
            set_name: set_matching_criteria[set_index].set_name,
            is_set_main: set_matching_criteria[set_index].set_as_main
              ? true
              : set_matching_criteria[set_index].is_set_main,
            set_number: set_matching_criteria[set_index].set_number,
            end_date: set_matching_criteria[set_index].end_date,
            status: set_matching_criteria[set_index].status,
            selected_criteria: [
              ...set_matching_criteria[set_index].entered_criteria,
              ...set_matching_criteria[set_index].entered_un_rank,
            ],
          },
        ],
      });
      if (res.status === 200) {
        dispatch(
          pushMessage({
            type: 'success',
            message: `Matching Criteria Set ${
              set_index + 1
            } of ${position_code} ${position_name}  successfully edited. `,
          })
        );
        await fetchRanking();
        setIsSummary(true);
        setShowSaveConfirm(false);
        setShowEditConfirm(false);
        setIsNewSetMatching(false);
        setDuplicateSetMatching(false);
        setIsSavedMatching(true);
        dispatch(updateIsSavedSetMatching(true));
        dispatch(updateIsEditSetMatching({ set_index, value: false }));
        dispatch(updateIsEditCriteria({ set_index, value: false }));
        if (!isSummary) {
          dispatch(updatePrevSetMatching(null));
        } else {
          dispatch(
            updateSetAsMainListMatching(
              set_matching_criteria.map((item, index) => ({
                ...item,
                is_set_main:
                  set_index === index && item.set_as_main
                    ? true
                    : item.is_set_main && item.set_as_main,
                isEditSetMatching: false,
                history_not_uses: index === set_index ? res?.data?.result[0]?.history_ids : [],
                set_id: res?.data?.result[0]?.set_id,
              }))
            )
          );
          dispatch(
            updatePrevSetMatching({
              ...prevSetMatching,
              set_matching_criteria: prevSetMatching.set_matching_criteria.map((item, index) => ({
                ...item,
                is_set_main:
                  set_index === index && item.set_as_main
                    ? true
                    : item.is_set_main && item.set_as_main,
                isEditSetMatching: false,
                history_not_uses: index === set_index ? res?.data?.result[0]?.history_ids : [],
                set_id: res?.data?.result[0]?.set_id,
              })),
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `An unexpected error occurred.`,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRunMatching = async () => {
    try {
      if (
        !set_matching_criteria[set_index].set_id ||
        !set_matching_criteria[set_index].history_not_uses
      ) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: `Run matching criteria failed.`,
          })
        );
        setShowConfirmRunCriteria(false);
        return;
      }
      setIsLoading(true);
      const res = await adminApi.runMatchingCriteria({
        position_code,
        set_id: set_matching_criteria[set_index].set_id,
        history_ids: set_matching_criteria[set_index].history_not_uses,
        created_at: new Date(),
      });
      if (res.status === 200) {
        const data = res?.data?.result;
        if (data) {
          dispatch(
            updateSetMatchingByIndex({
              ...set_matching_criteria[set_index],
              set_id: data?.set_id,
              start_date: data?.start_date,
              end_date: data?.end_date,
              status: data?.status,
              run_id: data?.id,
              history_not_uses: [],
            })
          );
          dispatch(
            updatePrevSetMatching({
              ...prevSetMatching,
              set_matching_criteria: prevSetMatching.set_matching_criteria.map((item, index) => {
                if (index === set_index) {
                  return {
                    ...item,
                    set_id: data?.set_id,
                    start_date: data?.start_date,
                    end_date: data?.end_date,
                    status: data?.status,
                    run_id: data?.id,
                    history_not_uses: [],
                  };
                } else {
                  return item;
                }
              }),
            })
          );
        }
        dispatch(
          pushMessage({
            type: 'success',
            message: `Run Criteria Set ${
              set_index + 1
            } of ${position_code} ${position_name}  successfully edited. `,
          })
        );
        setShowConfirmRunCriteria(false);
        setShowProcessRunSetMatching(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setShowConfirmRunCriteria(false);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `An unexpected error occurred.`,
        })
      );
    }
  };

  const fetchRanking = async () => {
    try {
      const resRanking = await adminApi.getEditRankingCriteria(positionCode);
      let tempListRanking = [];
      resRanking.data.result.set_matching_criteria.forEach((item) => {
        tempListRanking.push(item.selected_criteria.filter((item) => item.rank !== 0));
      });
      setRankingData(tempListRanking);
      dispatch(updateListRanking(tempListRanking));
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `An unexpected error occurred.`,
        })
      );
    }
  };

  const fetchEditDetail = useCallback(async () => {
    try {
      setIsLoading(true);
      await fetchRanking();
      const selectedRes = await adminApi.getSelectedCriteriaWithPositionCode(positionCode);
      const enteredRes = await adminApi.getEditMatchingCriteria(positionCode);
      if (enteredRes.status !== 200) return;
      const editSetMatchingData = selectedRes.data.result;
      if (selectedRes.status === 200) {
        let tempArr = [];
        let tempSelected = orderBy(
          selectedRes.data.result.set_matching_criteria,
          ['set_id'],
          ['asc']
        );
        let tempEntered = orderBy(
          enteredRes.data.result.set_matching_criteria,
          ['set_id'],
          ['asc']
        );
        tempSelected.forEach((select) => {
          tempEntered.forEach((enter) => {
            if (select.set_id === enter.set_id) {
              tempArr.push({
                ...select,
                status: select?.status || SET_MATCHING_CRITERIA_STATUS.NOT_AVAILABLE,
                selected_criteria: select.selected_criteria,
                entered_criteria: enter.selected_criteria,
                is_set_main: select.is_set_main,
                set_as_main: select.is_set_main,
                start_date: select.start_date,
                end_date: select.end_date,
              });
            }
          });
        });

        const tempDataToUpdate = {
          position_code: editSetMatchingData.position_code,
          position_name: editSetMatchingData.position_name,
          set_index: editSetMatchingData.set_index,
          set_matching_criteria: tempArr.map((item) => ({
            ...item,
            selected_criteria: item?.selected_criteria || [],
            entered_criteria: item?.entered_criteria || [],
            entered_un_rank: item?.entered_un_rank || [],
            list_edit: item?.list_edit || [],
          })),
        };
        setcurrentSetMatching(tempDataToUpdate);
        if (isSummary && !isSavedMatching) {
          dispatch(updateInitCriteriaEditState(tempDataToUpdate));
        }
        dispatch(updatePrevSetMatching(tempDataToUpdate));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionCode, set_index, setIsLoading]);

  useEffect(() => {
    if (!isSummary && !isEmpty(prevSetMatching)) {
      dispatch(updateInitCriteriaEditState(prevSetMatching));
      dispatch(updatePrevSetMatching(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSummary, prevSetMatching]);

  useEffect(() => {
    if (mode !== FORM_TYPES.EDIT || isNewSetMatching) return;

    fetchEditDetail();
  }, [mode, isNewSetMatching, fetchEditDetail]);

  useEffect(() => {
    dispatch(resetCriteriaState());
  }, [dispatch]);

  const handleChangeTab = async (tabIndex, data) => {
    // remove ranking for duplicate
    if (!isNumber(set_matching_criteria[set_index]?.set_id) && !isSavedSetMatching) {
      setRankingData((prev) => {
        if (isEmpty(prev[set_index])) return prev;
        prev[set_index] = undefined;
        return prev;
      });
    }
    if (!isNumber(set_matching_criteria[tabIndex]?.set_id)) {
      dispatch(updateIsFirstInitSetMatching(true));
    } else {
      dispatch(updateIsFirstInitSetMatching(false));
    }
    setIsSummary(false);
    setCurrentTab(tabIndex);
    if (mode === FORM_TYPES.EDIT) {
      const tempDataToUpdate = {
        position_code: position_code,
        position_name: position_name,
        set_index: tabIndex,
        set_matching_criteria: data.map((item) => ({
          ...item,
          selected_criteria: item?.selected_criteria || [],
          entered_criteria: item?.entered_criteria || [],
          entered_un_rank: item?.entered_un_rank || [],
          list_edit: item?.list_edit || [],
        })),
      };
      if (!isEmpty(prevSetMatching)) {
        dispatch(updateInitCriteriaEditState(prevSetMatching));
        dispatch(updatePrevSetMatching(prevSetMatching));
      } else {
        dispatch(updateInitCriteriaEditState(tempDataToUpdate));
        dispatch(updatePrevSetMatching(tempDataToUpdate));
      }
    }
    setIsSavedMatching(false);
    dispatch(updateSetMatchingIndex(tabIndex));
    dispatch(updateIsSavedSetMatching(false));
  };

  const handleDeleteSetCriteria = async () => {
    try {
      setIsLoading(true);
      let res;
      if (isNumber(set_matching_criteria[set_index]?.set_id)) {
        res = await adminApi.deleteMatchingSetCriteria(
          position_code,
          set_matching_criteria[set_index]?.set_id
        );
        if (res?.status === 200) {
          if (set_index > 0) {
            await handleChangeTab(set_index - 1, set_matching_criteria);
            dispatch(updateSetMatchingIndex(set_index - 1));
          }
          dispatch(
            pushMessage({
              type: 'success',
              message: `Matching Criteria Set ${set_index + 1} successfully deleted. `,
            })
          );
          fetchEditDetail();
          setShowDeleteModal(false);
          setIsLoading(false);
        }
        return;
      }

      dispatch(removeSetMatchingCriteria(set_matching_criteria[set_index]?.set_id));
      const tempData = set_matching_criteria.filter(
        (s, index) => s.set_id !== set_matching_criteria[set_index]?.set_id
      );
      dispatch(
        pushMessage({
          type: 'success',
          message: `Matching Criteria Set ${set_index + 1} successfully deleted. `,
        })
      );
      setShowDeleteModal(false);
      setIsNewSetMatching(false);
      setDuplicateSetMatching(false);
      setIsLoading(false);
      if (set_index > 0) await handleChangeTab(set_index - 1, tempData);
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `An unexpected error occurred.`,
        })
      );
    }
  };

  const handleViewResult = (value) => {
    if (value) {
      history.push(
        `/admin/sp-management/view-result-matching/${position_code}/${set_matching_criteria[set_index]?.run_id}`
      );
    }
  };

  return (
    <div className="max-width">
      {viewResultMessage && (
        <AlertMessage
          type={MESSAGE_TYPES.SUCCESS}
          message={`Matching Criteria Run for Set ${
            set_index + 1
          } is completed, you can view the result now.`}
          replaceMessage={`View Result`}
          closable={false}
          styles={{ marginBottom: '30px' }}
          onReplace={handleViewResult}
          timeShow={5000}
          onClose={() => setViewResultMessage(false)}
        />
      )}
      <h3 className={styles.mainTitle}>
        {mode === FORM_TYPES.CREATE ? 'Add New Criteria' : 'Edit Criteria'}
        <p className={styles.noteTitle} data-testid="noteTitle">
          Succession Planning Management
        </p>
      </h3>

      <Spin spinning={isLoading}>
        <AddMatchingCriteriaPositionForm isEdit={mode === FORM_TYPES.EDIT} isSummary={isSummary} />

        <div className="d-flex justify-content-end mb-2">
          <BasicButton
            mode="teal"
            onClick={() => {
              setShowSelectCriteria(true);
              setIsNewSetMatching(true);
            }}
            disabled={!position_code || size(set_matching_criteria) >= 5 || isNewSetMatching}
          >
            Add Matching Criteria Set
          </BasicButton>
        </div>

        <AddCriteriaPopOutSetMatching
          open={showSelectCriteria}
          setOpen={setShowSelectCriteria}
          position_code={position_code}
          position_name={position_name}
          isNewSetMatching={isNewSetMatching}
          setIsSummary={setIsSummary}
          setIsNewSetMatching={setIsNewSetMatching}
        />
        {isDuplicateSetMatching && (
          <AddCriteriaPopOutSetMatchingForDuplicate
            open={isDuplicateSetMatching}
            setOpen={setIsDuplicateSetMatching}
            position_code={position_code}
            position_name={position_name}
            setIsSummary={setIsSummary}
            setRankingData={setRankingData}
            rankingData={rankingData}
            setIsNewSetMatching={setIsNewSetMatching}
            setDuplicateSetMatching={setDuplicateSetMatching}
            setcurrentSetMatching={setcurrentSetMatching}
          />
        )}

        {!isEmpty(cloneSetMatchingCriteria) && (
          <div className={styles.criteriaTabWrapper}>
            {size(cloneSetMatchingCriteria) > 1 && (
              <div className={styles.criteriaTab}>
                {cloneSetMatchingCriteria.map((setItem, index) => (
                  <div
                    key={setItem.id}
                    onClick={() => handleChangeTab(index, cloneSetMatchingCriteria)}
                    onKeyDown={() => handleChangeTab(index, cloneSetMatchingCriteria)}
                    className={currentTab === index ? styles.active : null}
                    style={{ cursor: 'pointer', padding: '10px 15px' }}
                  >
                    {setItem.is_set_main ? (
                      <Tooltip
                        className={styles.customTooltip}
                        overlayClassName={'customTooltip'}
                        overlayStyle={{ minWidth: '181px' }}
                        title={
                          <div className={styles.tooltipContent}>
                            <span>Main Matching Criteria Set </span>
                          </div>
                        }
                        color={'black'}
                        key={'black'}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                      >
                        <span className={styles.isMain}>
                          Set {index + 1}: {setItem.set_name}
                        </span>
                      </Tooltip>
                    ) : (
                      <span>
                        Set {index + 1}: {setItem.set_name}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className={styles.criteriaTabContent}>
              {!isEmpty(set_matching_criteria) && (
                <SetCriteriaComponent
                  mode={mode}
                  isSummary={isSummary}
                  setIsSummary={setIsSummary}
                  setShowSaveConfirm={setShowSaveConfirm}
                  setShowEditConfirm={setShowEditConfirm}
                  setKeepRanking={setKeepRanking}
                  keepRanking={keepRanking}
                  currentTab={currentTab}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowDuplicateModal={() => {
                    setIsDuplicateSetMatching(true);
                  }}
                  setShowRunConfirm={setShowConfirmRunCriteria}
                  rankingData={rankingData}
                  currentSetMatching={currentSetMatching}
                  isNewSetMatching={isNewSetMatching}
                  setRankingData={setRankingData}
                  setDuplicateSetMatching={setDuplicateSetMatching}
                  duplicateSetMatching={duplicateSetMatching}
                />
              )}
            </div>
          </div>
        )}

        {isEmpty(set_matching_criteria) && (
          <>
            <img src={no_criteria} alt="no_criteria" width={'100%'} style={{ marginTop: '20px' }} />
            <div className={styles.bottomBtnBar}>
              <Link to="/admin/sp-management/managing-criteria-for-critical-position">
                <BasicButton onClick={() => dispatch(resetCriteriaState())}>Cancel</BasicButton>
              </Link>
            </div>
          </>
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'saveMatchingCriteria',
          visible: showSaveConfirm,
          setVisible: setShowSaveConfirm,
          handleSubmit: handleSaveSetMatching,
          loading: isLoading,
          onClose: () => setShowSaveConfirm(false),
        }}
      />
      <ModelTC
        info={{
          type: 'confirmRunMatchingCriteria',
          visible: showConfirmRunCriteria,
          setVisible: setShowConfirmRunCriteria,
          handleSubmit: handleRunMatching,
          loading: isLoading,
          onClose: () => setShowConfirmRunCriteria(false),
        }}
      />
      <ModelTC
        info={{
          type: 'processRunSetMatchingCriteria',
          title: `Matching Criteria Set ${set_index + 1} is running in progress...`,
          desc: `Once completed, you can view the result in the matching criteria interface.`,
          visible: showProcessRunSetMatching,
          setVisible: setShowProcessRunSetMatching,
          handleSubmit: () => setShowProcessRunSetMatching(false),
          loading: isLoading,
          onClose: () => setShowProcessRunSetMatching(false),
        }}
      />
      <ModelTC
        info={{
          type: 'editSetMatchingCriteria',
          title: `Matching Criteria Set ${set_index + 1} has been edited.`,
          desc: 'Please ensure the Criteria detail is correct. Would you like to save it now?',
          visible: showEditConfirm,
          setVisible: setShowEditConfirm,
          handleSubmit: handleEditSetMatching,
          loading: isLoading,
          onClose: () => setShowEditConfirm(false),
        }}
      />
      <ModelTC
        info={{
          type: 'deleteSetMatchingCriteria',
          title: `Are you sure you want to delete ${set_matching_criteria[set_index]?.set_name}?`,
          desc: `You can't undo this action`,
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          handleSubmit: handleDeleteSetCriteria,
          loading: isLoading,
          onClose: () => setShowDeleteModal(false),
        }}
      />
    </div>
  );
};

export default AddEditCriteriaSetMatching;
