import { Dropdown, Space, Table } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AiButton } from '../../../../../assets/common';
import { deleteNote, edit_delete, editNote } from '../../../../../assets/img';
import { CategoryDropdownCustom, ModelTC } from '../../../../../components';
import { isCheckError } from '../../../../../hooks/useFormik';
import { useGetBusinessUnitOptions } from '../../../../../hooks/useGetBuMapping';
import { masterDataManagementApi } from '../../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  BU_MAPPING_MESSAGE,
  DATA_FIELD_IN_ORACLE,
  MESSAGE_TYPES,
  SELECT_ALL_LABEL,
} from '../../../../../utils/constants';
import {
  formMappingvalidationSchema,
  handleCheckEmptyValue,
} from '../AddNewMappingBuOpuDevision/formMappingValidation';
import * as styles from './data-table-managing.module.scss';
import { TableManagement } from './styled';

export default function DataTableManaging({
  data,
  setDeleteRecord,
  setDeleteSelecteds,
  setSelectedRecordEdit,
  setSeletedRows,
  seletedRows,
  totalData,
  limit,
  setIsEdit,
  isEdit,
  filterOptions,
}) {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);
  const [dataContent, setDataContent] = useState([]);
  const [prevBusinessUnit, setPrevBusinessUnit] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      business_unit: '',
      data_field_in_oracle: '',
      data_content_in_oracle: '',
    },
    validationSchema: formMappingvalidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  // Use hooks
  const { buOptions } = useGetBusinessUnitOptions();

  useEffect(() => {
    if (!isEmpty(data)) {
      const tempData = data.map((item) => {
        return {
          ...item,
          key: `${item.role_level}.${item.id}.${
            item.only_one_item || item.first_item || item.last_item || ''
          }`,
        };
      });
      setNewData(tempData);
      setCurrentPage(1);
    }
  }, [data]);

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: type === MESSAGE_TYPES.ERROR,
      })
    );
  };

  const handleEditBuMapping = async () => {
    await formik.submitForm();
    const values = formik.values;
    const isCheck = handleCheckEmptyValue(values);
    if (!formik.isValid || isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, BU_MAPPING_MESSAGE.FILL_ALL_FIELD_REQUIRED);
      return;
    }

    setSelectedRecordEdit({
      ...values,
      prev_business_unit: prevBusinessUnit,
      business_unit: values?.business_unit?.id,
      field_in_oracle: getDataFieldInOracle(Number(values?.data_field_in_oracle?.id)),
      data_in_oracle: values?.data_content_in_oracle?.id,
      id: selectedRecord?.id,
    });
    setIsEdit(false);
  };

  const getDataFieldInOracle = (id) => {
    let tempValue = '';
    switch (id) {
      case 0:
        tempValue = DATA_FIELD_IN_ORACLE.BUSINESS_UNIT;
        break;
      case 1:
        tempValue = DATA_FIELD_IN_ORACLE.COMPANY;
        break;
      case 2:
        tempValue = DATA_FIELD_IN_ORACLE.DIVISION;
        break;
      case 3:
        tempValue = DATA_FIELD_IN_ORACLE.JOB_FAMILY;
        break;
      default:
        break;
    }

    return tempValue;
  };

  const handleResetForm = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({}, false);
  };

  const fetchApiToGetDataContent = useCallback(
    async (dataField) => {
      try {
        const res = await masterDataManagementApi.getBuMappingFilters(
          [...dataField],
          roleActive?.roleId
        );
        if (res.status === 200 && res?.data?.result) {
          setDataContent(res.data.result);
          return res.data.result;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [roleActive]
  );

  useEffect(() => {
    if (!isEdit) {
      handleResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const handleClickEditBtn = async () => {
    const tempBuOptions = buOptions
      .filter((item) => item?.label.includes(selectedRecord?.business_unit))
      .map((option) => {
        return {
          id: `${option?.id}`,
          name: `${option?.label}`,
        };
      });

    const tempDataFieldName =
      selectedRecord?.data_field_in_oracle !== DATA_FIELD_IN_ORACLE.BUSINESS_UNIT
        ? selectedRecord?.data_field_in_oracle
        : 'Business';
    const tempDataFieldOptions =
      !isEmpty(filterOptions?.data_field) &&
      filterOptions?.data_field
        .filter((item) => item?.label === tempDataFieldName)
        .map((option) => {
          return {
            id: `${option?.value}`,
            name: `${option?.label}`,
          };
        });

    const tempId = tempDataFieldOptions.length ? tempDataFieldOptions[0].id : null;
    let tempDataContentOptions = [];
    if (tempId) {
      const newDataContentOptions = await fetchApiToGetDataContent(tempId);
      tempDataContentOptions = !isEmpty(newDataContentOptions)
        ? newDataContentOptions
            .filter((item) => item?.label.includes(selectedRecord?.data_content_in_oracle))
            .map((option) => {
              return {
                id: `${option?.id}`,
                name: `${option?.label}`,
              };
            })
        : [];
    }

    formik.setValues({
      business_unit: tempBuOptions.length ? tempBuOptions[0] : null,
      data_field_in_oracle: tempDataFieldOptions.length ? tempDataFieldOptions[0] : null,
      data_content_in_oracle: tempDataContentOptions.length ? tempDataContentOptions[0] : null,
    });
    setPrevBusinessUnit(tempBuOptions.length ? tempBuOptions[0]?.id : null);
    setIsEdit(true);
  };

  const handleChangeDataField = async (value) => {
    formik.setFieldValue('data_field_in_oracle', value);
    formik.setFieldValue('data_content_in_oracle', '');
    await fetchApiToGetDataContent(value?.id);
  };

  const items = [
    {
      key: '0',
      icon: <img src={editNote} alt="edit" />,
      label: (
        <div style={{ textDecoration: 'none' }} className={styles.editButton}>
          Edit
        </div>
      ),
      onClick: () => handleClickEditBtn(),
    },
    {
      key: '1',
      icon: <img src={deleteNote} alt="delete" />,
      label: <div className={styles.deleteButton}>Delete</div>,
      onClick: () => {
        setShowDeleteModal(true);
      },
    },
  ];

  const menuProps = {
    items,
  };

  const columns = [
    {
      title: 'Business Unit',
      dataIndex: 'business_unit',
      align: 'left',
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <CategoryDropdownCustom
              selected={formik.values.business_unit}
              setSelected={(event) => formik.setFieldValue('business_unit', event)}
              options={
                !isEmpty(buOptions)
                  ? buOptions
                      .filter((item) => item.label !== SELECT_ALL_LABEL)
                      .map((option) => {
                        return {
                          id: `${option?.id}`,
                          name: `${option?.label}`,
                        };
                      })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'business_unit') ? 'error' : ''}
              hasCustom={true}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span
              className={`${styles.textField} text_field`}
              style={{ width: '306px', fontWeight: 700, color: '#3F3C4C' }}
            >
              {record?.business_unit}
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Data Field in Oracle',
      dataIndex: 'data_field_in_oracle',
      align: 'center',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <CategoryDropdownCustom
              selected={formik.values.data_field_in_oracle}
              setSelected={(event) => handleChangeDataField(event)}
              options={
                !isEmpty(filterOptions?.data_field)
                  ? filterOptions?.data_field
                      .filter((item) => item.label !== SELECT_ALL_LABEL)
                      .map((option) => {
                        return {
                          id: `${option?.value}`,
                          name: `${option?.label}`,
                        };
                      })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'data_field_in_oracle') ? 'error' : ''}
              hasCustom={true}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span className={styles.textField}>{record?.data_field_in_oracle}</span>
          )}
        </>
      ),
    },
    {
      title: 'Data Content in Oracle',
      dataIndex: 'data_content_in_oracle',
      align: 'center',
      className: `custom-text-field`,
      render: (_, record) => (
        <>
          {isEdit && record?.id === selectedRecord?.id && (
            <CategoryDropdownCustom
              selected={formik.values.data_content_in_oracle}
              setSelected={(event) => formik.setFieldValue('data_content_in_oracle', event)}
              options={
                !isEmpty(dataContent)
                  ? dataContent
                      .filter((item) => item.label !== SELECT_ALL_LABEL)
                      .map((option) => {
                        return {
                          id: `${option?.id}`,
                          name: `${option?.label}`,
                        };
                      })
                  : []
              }
              placeholder="Select"
              status={isCheckError(formik, 'data_content_in_oracle') ? 'error' : ''}
              hasCustom={true}
            />
          )}

          {(!isEdit || (isEdit && record?.id !== selectedRecord?.id)) && (
            <span className={styles.textField}>{record?.data_content_in_oracle}</span>
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
      render: (_, record) => (
        <>
          {!isEdit && (
            <Dropdown
              menu={menuProps}
              trigger={['click']}
              style={{ minWidth: 110 }}
              placement="bottomRight"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Space style={{ cursor: 'pointer' }}>
                <img
                  src={edit_delete}
                  alt="dot"
                  onKeyDown={() => {}}
                  onClick={() => setSelectedRecord(record)}
                />
              </Space>
            </Dropdown>
          )}

          {isEdit && record?.id === selectedRecord?.id && (
            <AiButton mode={'teal'} onKeyDown={() => {}} onClick={() => handleEditBuMapping()}>
              Save
            </AiButton>
          )}
        </>
      ),
    },
  ];

  const handleChangePage = (total, range) => {
    return (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    );
  };

  const paginationConfig = {
    total: totalData,
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== currentPage && setCurrentPage(pageNum),
    showTotal: (total, range) => handleChangePage(total, range),
  };

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      const seletedRows = handleConvertSelectedRows(_selectedRows);
      setSeletedRows(selectedRowKeys);
      setDeleteSelecteds(seletedRows);
    },
    selectedRowKeys: seletedRows,
  };

  const handleConvertSelectedRows = (data) => {
    let tempArr = [];
    if (!isEmpty(data)) {
      data.forEach((item) => {
        const tempId = !isEmpty(item?.key) ? item?.key.split('.')[1] : '';
        tempArr.push(Number(tempId));
      });
    }
    return tempArr;
  };

  return (
    <div style={{ marginTop: 24 }}>
      <TableManagement>
        <Table
          rowSelection={
            !isEdit && {
              type: 'checkbox',
              ...rowSelection,
            }
          }
          className={isEdit ? 'customMappingGroupTable editMode' : 'customMappingGroupTable'}
          columns={columns}
          rowClassName={(record) => {
            return `row_${record?.id} ${
              record?.only_one_item || record?.first_item || record?.last_item
            }`;
          }}
          dataSource={newData}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </TableManagement>
      <ModelTC
        info={{
          type: 'deleteBuMapping',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => setShowDeleteModal(false),
          handleSubmit: () => {
            setDeleteRecord(selectedRecord);
            setShowDeleteModal(false);
          },
        }}
      />
    </div>
  );
}
