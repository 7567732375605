import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiAlert, AiSpin } from '../../assets/common';
import { sort_icon } from '../../assets/img';
import { Dropdown } from './component';
import styles from './ModalFeedbackSummary.module.scss';

const ModalFeedbackSummary = ({
  show,
  setShow,
  feedbacks,
  loadingFeedbackSummary,
  listSelectTypeEdge,
  setListSelectTypeEdge,
  filtersOption,
}) => {
  const [keySort] = useState('');
  const [sort] = useState({
    edge: false,
  });
  const [sortNewest, setSortNewest] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSortNewest(false);
    setListSelectTypeEdge([]);
  };

  const handleCheckType = () => {
    return ['Document', 'Document Dimension​', 'Year', 'Sentiment Score', 'Sentence'];
  };

  const renderTableHeader = () => {
    let listLabelHeader = handleCheckType();
    return listLabelHeader.map((item, index) => {
      if (item === 'Year') {
        return (
          <th key={item}>
            <div
              className={styles.sort}
              onKeyDown={() => {}}
              onClick={() => setSortNewest(!sortNewest)}
            >
              <p>{item}</p>
              <img src={sort_icon} alt="sort" className={sortNewest ? styles.newest : ''} />
            </div>
          </th>
        );
      }
      return (
        <th key={item}>
          <p>{item}</p>
        </th>
      );
    });
  };

  const renderTableBodyFb = () => {
    if (feedbacks.length === 0) return;
    return showSortList.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <div>{item.edge || ''}</div>
          </td>
          <td>
            <div>{item.document || ''}</div>
          </td>
          <td>
            <div>{item.documentDimesion || ''}</div>
          </td>
          <td>
            <div>{item.year || ''}</div>
          </td>
          <td>
            <div>{item.proficiency || ''}</div>
          </td>
          <td>
            <div>{item.feedbackDisplay || ' '}</div>
          </td>
        </tr>
      );
    });
  };

  const showSortList = useMemo(() => {
    let result = [];
    if (keySort === 'edge') {
      if (sort.edge) {
        result = [...feedbacks].sort((a, b) => String(a.edge[0]).localeCompare(String(b.edge[0])));
      } else {
        result = [...feedbacks].sort((a, b) => String(b.edge[0]).localeCompare(String(a.edge[0])));
      }
    } else result = [...feedbacks];

    if (sortNewest) {
      result = result.sort((a, b) => Number(a.year) - Number(b.year));
    }
    return result;
  }, [feedbacks, keySort, sort.edge, sortNewest]);

  const renderTableBody = () => {
    return renderTableBodyFb();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName={styles.containerModal}
        data-testid="edit-tagging"
      >
        <Modal.Header closeButton className={styles.editTagging__header}>
          <Modal.Title className={styles.editTagging__title}>Verbatim Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.editTagging__body}>
          <div className={styles.editTagging__table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <div className={styles.editTagging__filter}>
                      <p>Edge</p>
                      <Dropdown
                        listSelectType={listSelectTypeEdge}
                        setListSelectType={setListSelectTypeEdge}
                        filtersOption={filtersOption}
                      />
                    </div>
                  </th>
                  {renderTableHeader()}
                </tr>
              </thead>

              <tbody>{!loadingFeedbackSummary && renderTableBody()}</tbody>
            </table>
            {loadingFeedbackSummary && (
              // <div className={styles.editTagging__loading}>
              //   <div className="spinner-border text-primary" role="status">
              //     <span className="sr-only">Loading...</span>
              //   </div>

              // </div>
              <AiSpin tip="Loading...">
                <AiAlert />
              </AiSpin>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ModalFeedbackSummary;
