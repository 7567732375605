import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchListOption } from '../../../../../../components';
import { useDebounce } from '../../../../../../hooks';
import { adminApi } from '../../../../../../services/admin';
import styles from './search-position-box.module.scss';

const SearchPositionBoxCopy = (props) => {
  const { selectedSp, searchTerm, setSearchTerm, searchList, dataCopyBtn, setSearchList } = props;
  const [loading, setLoading] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const debounceSearchQuery = useDebounce(searchTerm, 500);

  const handleOnChange = (value) => {
    setSearchTerm({ ...searchTerm, value, type: 'search', showTerm: value });
  };
  useEffect(() => {
    if (debounceSearchQuery.type === 'search' || debounceSearchQuery.type === '') {
      fetchAPI();
    }
    async function fetchAPI() {
      setLoading(true);
      try {
        let res;
        if (debounceSearchQuery.type === 'search') {
          if (isEmpty(debounceSearchQuery.value)) {
            setSearchList([]);
            setSearchTerm({ value: '', type: 'search', showTerm: '' });
          }
          res = await adminApi.getCopyApprovedSP(debounceSearchQuery.value, roleActive);
          setLoading(false);
          setSearchList(res.data.result);
          return;
        }
      } catch (error) {
        setLoading(false);
      }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchQuery.showTerm, roleActive.roleId]);

  return (
    <div className={styles.search_box} tabIndex={0} data-testid="search-box">
      <input
        id="position"
        data-testid="position-input"
        name="position"
        placeholder="Search Position Code or Name"
        className={styles.position_input}
        onChange={(e) => handleOnChange(e.target.value)}
        value={searchTerm.showTerm}
        autoComplete="off"
        disabled={selectedSp}
      />
      {searchTerm.value && searchTerm.type === 'search' && (
        <SearchListOption
          data={searchList}
          setSearchTerm={setSearchTerm}
          loading={loading}
          debounceSearchQuery={debounceSearchQuery}
          idCopy={dataCopyBtn.id}
        />
      )}
    </div>
  );
};

SearchPositionBoxCopy.propTypes = {
  selectedSp: PropTypes.string,
  searchTerm: PropTypes.object,
  setSearchTerm: PropTypes.func,
  searchList: PropTypes.array,
  dataCopyBtn: PropTypes.object,
  setSearchList: PropTypes.func,
};

export default SearchPositionBoxCopy;
