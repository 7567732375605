import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const initState = {
  position_code: 0,
  position_name: '',
  selected_criteria: [], //list criteria was selected from pop out
  entered_criteria: [], //list criteria with input value was entered by user
  entered_un_rank: [], //list criteria mandatory with input value was entered by user
  list_edit: [], //list criteria will get init data from get edit criteria
};

const manageCriteriaSlice = createSlice({
  name: 'editAddCriteria',
  initialState: initState,
  reducers: {
    updateSelectedCriteria: (state, action) => {
      state.selected_criteria = action.payload;
    },
    addOrUpdateCriteria: (state, action) => {
      let index = 0;
      const isDup = state.entered_criteria.find((item, idx) => {
        if (item.criteria === action.payload.criteria) {
          index = idx;
          return item;
        }
        return null;
      });
      if (isEmpty(isDup)) {
        state.entered_criteria.push(action.payload);
      } else {
        state.entered_criteria[index] = action.payload;
      }
    },
    updateUnRank: (state, action) => {
      const index = state.entered_un_rank.findIndex((item, idx) => {
        return item.criteria === action.payload.criteria;
      });
      state.entered_un_rank[index] = action.payload;
    },
    removeCriteria: (state, action) => {
      state.selected_criteria = state.selected_criteria.filter(
        (item) => item.criteria !== action.payload
      );
      state.entered_criteria = state.entered_criteria.filter(
        (item) => item.criteria !== action.payload
      );
      state.list_edit = state.list_edit.filter((item) => item.criteria !== action.payload);
    },
    updatePosition: (state, action) => {
      state.position_code = action.payload.position_code;
      state.position_name = action.payload.position_name;
    },
    resetCriteriaState: (state) => {
      state.position_code = 0;
      state.position_name = '';
      state.selected_criteria = [];
      state.entered_criteria = [];
      state.list_edit = [];
      state.entered_un_rank = [];
    },
    updateInitCriteriaEditState: (state, action) => {
      state.position_code = action.payload.position_code;
      state.position_name = action.payload.position_name;
      state.selected_criteria = action.payload.selected_criteria;
      state.entered_criteria = action.payload.entered_criteria;
      state.list_edit = action.payload.entered_criteria;
    },
    summaryEnteredCriteria: (state, action) => {
      state.entered_criteria = action.payload;
    },
    summaryUnRankEntered: (state, action) => {
      state.entered_un_rank = action.payload;
    },
  },
});
const { actions, reducer: manageCriteriaReducer } = manageCriteriaSlice;
export const {
  addOrUpdateCriteria,
  updateUnRank,
  removeCriteria,
  updateSelectedCriteria,
  updatePosition,
  resetCriteriaState,
  updateInitCriteriaEditState,
  summaryEnteredCriteria,
  summaryUnRankEntered,
} = actions;
export default manageCriteriaReducer;
