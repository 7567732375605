import { Dropdown, Space } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AiButton, BasicButton, BasicInput, BasicTable } from '../../../../assets/common';
import { edit_delete, editNote, plus_green_icon } from '../../../../assets/img';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import { paginationConfig } from '../../../../utils/helper';
import * as styles from './popup-list-existing-columns.module.scss';

const INIT_SEARCH_PARAMS = { search: '', page: 1, limit: 15 };

const PopupListExistingColumns = ({ setListExistingColumn, handleEditColumns, listColumnName }) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const { reportId } = useParams();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ list_existing_columns: [], total: 0 });
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const inputRef = useRef(null);

  const fetchData = useCallback(async () => {
    const params = {
      ...searchParams,
      report_id: reportId,
    };
    try {
      setLoading(true);
      const res = await reportBuilderApi.getExistingManualColumn(params, roleActive);
      if (res.status === 200) {
        setData(res.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, reportId, roleActive]);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [fetchData, open]);

  const handleClickSearch = (e) => {
    const searchKeyword = inputRef.current.value;
    setSearchParams((prev) => ({ ...prev, search: searchKeyword, page: 1 }));
  };

  const handleClickShowPopup = () => {
    setOpen(true);
    setSearchParams(INIT_SEARCH_PARAMS);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setSelectedRows(_selectedRows);
      setSelectedKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.disabled,
      name: record.column_name,
    }),
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedKeys,
  };

  const columns = useMemo(() => {
    const menuProps = {
      items: [
        {
          key: '0',
          icon: <img src={editNote} alt="edit" />,
          label: (
            <div style={{ textDecoration: 'none' }} className={styles.editButton}>
              Edit
            </div>
          ),
          onClick: () => {
            handleEditColumns(selectedRecord);
            setOpen(false);
          },
        },
      ],
    };
    return [
      {
        title: 'No.',
        width: '5%',
        dataIndex: 'no',
        render: (text) => (
          <span className={styles.textField}>
            {text + (searchParams.page - 1) * searchParams.limit}
          </span>
        ),
      },
      {
        title: 'Report Name',
        width: '20%',
        dataIndex: 'report_name',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Column Name',
        width: '20%',
        dataIndex: 'column_name',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Owner',
        width: '15%',
        dataIndex: 'owner',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Access',
        width: '10%',
        dataIndex: 'access',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Update Date & Time',
        width: '20%',
        dataIndex: 'updated_at',
        render: (text) => (
          <span className={styles.textField}>
            {!isEmpty(text) && (
              <>
                {moment(text).format('DD MMMM YYYY')}
                <br />
                {moment(text).format('hh:mmA')}
              </>
            )}
          </span>
        ),
      },
      {
        title: '',
        width: '10%',
        render: (text, record) => (
          <Dropdown
            menu={menuProps}
            trigger={['click']}
            style={{ minWidth: 110 }}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Space style={{ cursor: 'pointer' }}>
              <img
                src={edit_delete}
                alt="dot"
                onKeyDown={() => {}}
                onClick={() => setSelectedRecord(record)}
              />
            </Space>
          </Dropdown>
        ),
      },
    ];
  }, [handleEditColumns, searchParams.limit, searchParams.page, selectedRecord]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(data.total, searchParams.page, onChange, searchParams.limit);
  }, [data.total, searchParams.page, setSearchParams, searchParams.limit]);

  const handleAddToReport = async () => {
    setOpen(false);
    setSearchParams(INIT_SEARCH_PARAMS);
    setSelectedKeys([]);
    setListExistingColumn(
      !isEmpty(selectedRows)
        ? selectedRows.map((column) => ({
            businesses: column?.businesses || [],
            hr_partners: column?.hr_partners || [],
            id: column?.id,
            name: column?.column_name,
            role_ids: column?.role_ids || [],
            type: column?.column_type,
            value: column?.value,
            report_id: column?.report_id,
          }))
        : []
    );
  };

  return (
    <>
      <AiButton
        className={`ml-2 ${styles.addColumnBtn}`}
        onKeyDown={() => {}}
        onClick={handleClickShowPopup}
      >
        <img src={plus_green_icon} alt="" />
        {`Add Existing Column`}
      </AiButton>
      <CustomAntModal
        title={`List of Existing Column`}
        width={1330}
        open={open}
        setOpen={setOpen}
        hideDefaultBtn
        styles={{
          containerPaddingLeftRight: 50,
          containerPaddingTopBottom: 40,
          closePosition: 40,
          titleFontSize: 20,
          headerPaddingBottom: 34,
        }}
      >
        <div className={styles.searchBar}>
          <BasicInput width={415} placeholder="Search by Column Name" ref={inputRef} />
          <BasicButton height={36} mode="teal" onKeyDown={() => {}} onClick={handleClickSearch}>
            Search
          </BasicButton>
        </div>
        <BasicTable
          loading={loading}
          rowKey="no"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={
            !isEmpty(data.list_existing_columns) &&
            data.list_existing_columns.map((item, index) => ({
              ...item,
              no: index + 1,
              disabled: listColumnName.includes(item.column_name) || item.disabled,
            }))
          }
          columns={columns}
          pagination={pagination}
        />
        <div className={styles.groupBottom}>
          <BasicButton
            height={36}
            mode="teal"
            onKeyDown={() => {}}
            onClick={handleAddToReport}
            disabled={isEmpty(selectedKeys)}
          >
            Add To Report
          </BasicButton>
        </div>
      </CustomAntModal>
    </>
  );
};

export default PopupListExistingColumns;
