import { Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { dashboard_breadcrumb } from '../../../../../assets/img';
import { BreadCrumb } from '../../../../../components';
import { dashboardApi } from '../../../../../services/dashboardApi';
import { HISTORICAL_DATA_TALENT_TYPE } from '../../../../../utils/constants';
import DataTableViewDetails from '../../components/DataTableViewDetails/DataTableViewDetails';
import MenuBarViewDetails from '../../components/MenuBarViewDetails/MenuBarViewDetails';
import * as styles from './historical-view-details.module.scss';

const HistoricalViewDetails = () => {
  const {
    typeOfTalent,
    year,
    month,
    companyFilterType,
    companyFilterValue,
    talentFilter,
    companyFilterLabel,
    companyFilterValueLabel,
  } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(1);
  const [isExport, setIsExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const limit = 15;

  const [filterViewDetails, setFilterViewDetials] = useState({
    top_talent_status: [],
    salary_grade: [],
    role_level: [],
    business_unit: [],
  });

  const [searchTerm, setSearchTerm] = useState({
    type: 'search',
    value: '',
  });

  const getPageTitle = useMemo(() => {
    let tempTitle;
    switch (typeOfTalent) {
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_TOP_TALENT:
        tempTitle = 'No. of Top Talent';
        break;
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_CORPORATE_TALENT:
        tempTitle = 'No. of Corporate Talent';
        break;
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_BUSINESS_TALENT:
        tempTitle = 'No. of Business Talent';
        break;
      case HISTORICAL_DATA_TALENT_TYPE.NO_OF_REVIEW_CONDUCTED:
        tempTitle = 'No. of Talent Review Conducted';
        break;
      default:
        tempTitle = '';
        break;
    }
    return tempTitle;
  }, [typeOfTalent]);

  useEffect(() => {
    const fetchFilter = async () => {
      try {
        const response = await dashboardApi.getFilterHistoricalDetails(roleActive?.roleId);
        if (response.status === 200 && response?.data?.result) {
          setFilterData(response?.data?.result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchFilter();
  }, [roleActive]);

  useEffect(() => {
    const fetchHistoricalDetails = async () => {
      try {
        setLoading(true);
        const params = {
          month,
          year,
          roleId: roleActive?.roleId,
          page,
          limit,
          isExport,
          topTalentCategory: filterViewDetails.top_talent_status ?? [],
          salaryGrade: filterViewDetails.salary_grade ?? [],
          roleLevel: filterViewDetails.role_level ?? [],
          business: filterViewDetails.business_unit ?? [],
          keyword: searchTerm.value ?? '',
          typeOfTalent,
          talentFilter: talentFilter ?? '',
          companyFilterType: companyFilterType ?? '',
          companyFilterValue: companyFilterValue ?? '',
          companyFilterLabel: companyFilterLabel ?? '',
          companyFilterValueLabel: companyFilterValueLabel ?? '',
        };

        if (isExport) {
          setLoading(false);
          setLoadingExport(true);
        }

        const response = await dashboardApi.getHistoricalDetails(params);
        if (response.status === 200 && response?.data?.result) {
          setData(response?.data?.result);
          if (isExport) {
            setExportData(response?.data?.result?.talents);
            setIsExport(false);
            setLoadingExport(false);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        setLoadingExport(false);
      }
    };

    fetchHistoricalDetails();
  }, [
    roleActive,
    isExport,
    page,
    limit,
    searchTerm,
    filterViewDetails,
    typeOfTalent,
    year,
    month,
    talentFilter,
    companyFilterType,
    companyFilterValue,
    companyFilterLabel,
    companyFilterValueLabel,
  ]);

  const breadCrumbList = [
    { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
    { name: 'Talent Overview', icon: '', url: '/dashboard' },
    { name: getPageTitle },
  ];

  return (
    <div className="max-width">
      <div className={styles.control}>
        <BreadCrumb level={5} breadCrumbList={breadCrumbList} />
        <h3 className={styles.mainTitle}>{getPageTitle}</h3>
      </div>

      <Spin spinning={loading} size="large">
        <MenuBarViewDetails
          setIsExport={setIsExport}
          total={data?.total}
          exportData={exportData}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          loadingExport={loadingExport}
        />
        <DataTableViewDetails
          data={data?.talents || []}
          totalData={data?.total || 0}
          filterData={filterData}
          page={page}
          setPage={setPage}
          limit={limit}
          setFilterViewDetials={setFilterViewDetials}
          filterViewDetails={filterViewDetails}
        />
      </Spin>
    </div>
  );
};

export default HistoricalViewDetails;
