import { FastField } from 'formik';
import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  padding: 14px 16px;

  width: 100%;
  height: ${(props) => props.$height || '44px'};
  /* General/White */
  background: #ffffff;
  border: 1px solid
    ${({ name, $getFieldMeta }) => {
      const meta = $getFieldMeta(name);
      return !meta || !meta.touched || !meta.error ? '#d3dee8' : '#ff787f';
    }};
  border-radius: 4px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #8d8d8d;

  &:disabled {
    background: #e0e0e0;
    color: #787587;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #c4c3d1;
  }
`;

export default React.memo(
  React.forwardRef(({ fieldAs, height, name, ...props }, ref) => (
    <FastField name={name}>
      {({ field, form: { getFieldMeta } }) => (
        <StyledInput
          {...field}
          {...props}
          ref={ref}
          as={fieldAs}
          $getFieldMeta={getFieldMeta}
          $height={height}
        />
      )}
    </FastField>
  ))
);
