const titles = [
  'Staff ID',
  'Staff Name',
  'Gender',
  'Age',
  'SG',
  'JG',
  'Pos ID',
  'SG Since',
  'Date in Position',
  'Nationality',
  'Position',
  'Division',
  'Company',
  'Business',
  'Job Family',
  'Sub Job Family',
  'Function',
  'Retirement Date',
  'Top Talent Status',
  'Language Name',
  'Assessment Date (Latest)',
  'Assessment Type (Latest)',
  'Assessment Result (Latest)',
  // Personalities
  'The Auditor',
  'The Coach',
  'The Collaborator',
  'The Designer',
  'The Mastermind',
  'The Optimist',
  'The Peacemaker',
  'The Problem Solver',
  'The Scientist',
  'The Service Oriented',
  // Other Experiences
  'Knowledge in managing P&L for specific unit/ function',
  'Experience managing government (local or foreign)',
  'Knowledge of business value chain',
  'Experience working in multi country',
  'Experience in managing project',
  'Experience in Business or Strategic Negotiations',
  'Lead Medium/Large - sized function/ business',
  'Experience in business transformation/ growth ',
  'Experience in Core Operational & Execution Management',
  'Experience in technical or technology development',
  'Developed Strategy for Business or Function',
  'Multi Business Exposure',
  // Attributes 27
  'Sociable',
  'Reactive',
  'Commercially Oriented',
  'Forward Thinking',
  'Service Orientation',
  'Structured',
  'Empathetic',
  'Expressive',
  'Openness',
  'Risk Tolerant',
  'Dependable',
  'Learning Agility',
  'Ambiguity and Tolerance',
  'Collaborative',
  'Quality Focused',
  'Trusting',
  'Conceptual',
  'Motivating',
  'Evaluative',
  'Driven',
  'Dominance',
  'Problem Solving',
  'Judgement',
  'Innovative',
  'Mood Stability',
  'Persuasive',
  'Ambition',
];

export const talentExportColumns = titles.map((title) => ({ title }));
