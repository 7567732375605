import { isEmpty, unionBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDebounce } from '../../../../hooks';
import { adminApi } from '../../../../services/admin';
import { auditLogApi } from '../../../../services/auditLog';
import styles from './search-box-custom.module.scss';
import SearchListOption from './SearchListOption';

const SearchBoxCustom = (props) => {
  const {
    selectedSp,
    searchTerm,
    setSearchTerm,
    searchList,
    setSearchList,
    searchPlaceholder,
    fieldName,
    isSubmitted,
    isActive,
    isReadOnly,
    isShowRequired
  } = props;
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const debounceSearchQuery = useDebounce(searchTerm, 500);

  const handleOnChange = (value) => {
    setSearchTerm({ value, type: 'search', showTerm: value });
  };

  useEffect(() => {
    if (isSubmitted) {
      setHasError(isEmpty(searchTerm?.showTerm));
    }
  }, [isSubmitted, searchTerm]);

  useEffect(() => {
    if (debounceSearchQuery.type === 'search') {
      if (fieldName === 'position_code') {
        fetchAPISearchByPositionCode();
      }
      if (fieldName === 'position_code_criteria') {
        fetchAPISearchByPositionCodeCriteria();
      }
      if (fieldName === 'position_code_critical') {
        fetchAPISearchByPositionCodeCritical();
      }

      if (fieldName === 'staff_id') {
        fetchAPISearchByStaffId();
      }

      if (fieldName === 'action_by') {
        fetchAPISearchByActionBy();
      }

      if (fieldName === 'user_log_email') {
        fetchAPISearchByUserLogEmail();
      }
      if (fieldName === 'SG_JG_in_Oracle') {
        fetchSGJGMappingSuggestions();
      }
      if (fieldName === 'staff_id_top_talent_status') {
        fetchAPISearchStaffIdTopTalentStatus();
      }
    }

    async function fetchAPISearchByPositionCode() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await adminApi.getSPDetailByPositionId(
            debounceSearchQuery.value,
            roleActive.roleId
          );
          let result = unionBy(response.data.result, fieldName);
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchAPISearchByPositionCodeCriteria() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await adminApi.getPositionCriteria(
            debounceSearchQuery.value,
            roleActive
          );
          let result = unionBy(response.data.result, 'position_code');
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchAPISearchByPositionCodeCritical() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await adminApi.getPositionNameByPositionCode({
            positionCode: debounceSearchQuery.value,
            role: roleActive,
          });
          let result = unionBy(response.data.result, 'position_code');
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setSearchList([]);
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchAPISearchByStaffId() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await adminApi.getSuccessorDetailByStaffId(
            debounceSearchQuery.value,
            'search',
            roleActive.roleId
          );
          let result = unionBy(response.data.result, fieldName);
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchAPISearchByActionBy() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await auditLogApi.getAuditLogFilterByEmail(
            debounceSearchQuery.value,
            'search',
            roleActive.roleId
          );
          let result = unionBy(response.data.result, 'value');
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchAPISearchByUserLogEmail() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await auditLogApi.getAuditLogFilterByUserLogEmail(
            debounceSearchQuery.value,
            'search',
            roleActive.roleId
          );
          let result = unionBy(response.data.result, 'value');
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchSGJGMappingSuggestions() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await adminApi.getSGJGMappingSuggestions(
            debounceSearchQuery.value,
            'search',
            roleActive.roleId
          );
          let result = unionBy(response.data?.result?.suggestions, 'value');
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }

    async function fetchAPISearchStaffIdTopTalentStatus() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = await adminApi.searchStaffIdTopTalentStatus(
            { keyword: debounceSearchQuery.value, _page: 1, _limit: 100 },
            roleActive
          );
          let result = unionBy(response.data.result, 'staff_id');
          setSearchList(result);
          setHasError(isEmpty(result));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchQuery, roleActive, fieldName]);

  return (
    <div className={styles.search_box} tabIndex={0} data-testid="search-box">
      <input
        id={fieldName}
        data-testid={fieldName}
        name={fieldName}
        placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
        className={`${styles.position_input} ${(hasError && isSubmitted) || isShowRequired ? styles.error : ''}`}
        onChange={(e) => handleOnChange(e.target.value)}
        value={searchTerm.showTerm}
        autoComplete="off"
        disabled={selectedSp}
        style={isActive ? { paddingLeft: '20px' } : {}}
        readOnly={isReadOnly}
      />
      {searchTerm.value && searchTerm.type === 'search' && (
        <SearchListOption
          data={searchList}
          setSearchTerm={setSearchTerm}
          loading={loading}
          debounceSearchQuery={debounceSearchQuery}
          fieldName={fieldName}
        />
      )}
    </div>
  );
};

export default SearchBoxCustom;
