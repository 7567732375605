import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AiButton } from '../../../assets/common';
import { complete_meeting_image } from '../../../assets/img';
import { AiDesc, AiTitle, Container, Row } from './Styled';

const itemIncumbent = {
  title: 'Great! You have finished the Meeting!',
  desc: 'Would you like to generate MoM now? ',
  btnCancel: 'Back to Home',
  btnSubmit: 'Generate MOM',
  borderCancel: true,
  borderSubmit: false,
  modeCancel: '',
  modeSubmit: 'teal',
  img: complete_meeting_image,
};
const MeetingConfirmScreen = () => {
  const { meetingId } = useParams();
  const history = useHistory();

  return (
    <>
      <Container data-testid="meeting-confirm">
        <Row>
          <div>
            <AiTitle>{itemIncumbent.title}</AiTitle>
            <AiDesc>{itemIncumbent.desc}</AiDesc>
            <AiButton
              border={itemIncumbent?.borderCancel}
              mode={itemIncumbent.modeCancel}
              onClick={() => history.push('/homepage')}
              data-testid="cancel"
            >
              {itemIncumbent.btnCancel}
            </AiButton>
            <AiButton
              border={itemIncumbent?.borderSubmit}
              mode={itemIncumbent.modeSubmit}
              className="ml-2"
              onClick={() => history.push(`/meeting/detail/${meetingId}`)}
              data-testid="submit"
            >
              {itemIncumbent.btnSubmit}
            </AiButton>
          </div>
          <div>
            <img src={itemIncumbent.img} alt="" />
          </div>
        </Row>
      </Container>
    </>
  );
};
export default MeetingConfirmScreen;
