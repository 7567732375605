import {
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import userApi from './services/userApi';
import { ACTION_LOG } from './utils/constants';
import { history } from './utils/history';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: process.env.REACT_APP_AUTHORITY, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: process.env.REACT_APP_NAVIGATE_TO_LOGIN_REQUEST_URL === 'true', // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE === 'true', // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            return;
          default:
            break;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_SCOPES_API],
  prompt: process.env.REACT_APP_PROMPT, // Types: consent, login, select_account
};

export const protectedResources = {
  graphMe: {
    endpoint: process.env.REACT_APP_API_USER_PROFILE,
    scopes: [process.env.REACT_APP_SCOPES_USER_PROFILE],
  },
  accessTokenRequest: {
    scopes: [process.env.REACT_APP_SCOPES_API],
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const accessTokenRequest = {
  scopes: protectedResources.accessTokenRequest.scopes,
  account: msalInstance.getActiveAccount(),
};

export const getAccessToken = () => {
  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then((accessTokenResponse) => {
      return accessTokenResponse;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance
          .acquireTokenRedirect(accessTokenRequest)
          .then(async (accessTokenResponse) => {
            try {
              await userApi.logAction({ url: '', type: ACTION_LOG.TIMEOUT }, accessTokenResponse);
            } catch (error) {
              console.log(error);
            }
            return accessTokenResponse;
          })
          .catch((error) => {
            localStorage.clear();
            history.push('/error');
          });
      }
    });
};
