import React from 'react';
import { CheckBoxList } from '../../../../components';
import { CALENDAR } from '../../../../utils/constants';
import { Container } from './styled';

const MeetingFilter = (props) => {
  const { meetingCategory, setMeetingCategory } = props;
  return (
    <Container>
      <CheckBoxList
        checkedList={meetingCategory}
        setCheckedList={setMeetingCategory}
        options={CALENDAR.MEETING_CATEGORY}
        label={'Business'}
      />
    </Container>
  );
};

export default MeetingFilter;
