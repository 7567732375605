import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { getMainMobilities } from '../../../../services/talentProfiles';
import { DATE_TIME_FORMAT } from '../../../../utils/constants';
import { formatMainMobilityPlan } from '../../../../utils/helper';
import styles from './mobility-plan-print.module.scss';

export const MobilityPlanPrint = ({ profileId, roleUser, isPrintPdf }) => {
  const [mobilities, setMobilities] = useState(null);

  const getListMobility = async () => {
    try {
      const res = await getMainMobilities(roleUser, profileId);
      if (res.status === 200) {
        const rows = formatMainMobilityPlan(res.data.result);
        setMobilities(rows);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListMobility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);
  return (
    <div className={styles.SectionPlan}>
      <div className={styles.SectionName}>Mobility Plan</div>
      <div className={styles.tablePlan}>
        <table className="table">
          <thead>
            <tr className={styles.tablePlanThead}>
              <th scope="col">Next Mobility</th>
              <th scope="col">Next 2 Mobility</th>
              <th scope="col" style={{ width: '35%' }}>
                End State
              </th>
            </tr>
          </thead>
          <tbody>
            {mobilities?.length > 0 &&
              mobilities.map((item, index) => (
                <tr key={index} className={styles.tablePlanBody}>
                  <td>
                    <div className={styles.detail}>{item?.nextMobility?.detail}</div>
                    <div className={styles.date}>
                      {item?.nextMobility?.platform},{' '}
                      {item?.nextMobility?.platform_date &&
                        moment(item?.nextMobility?.platform_date).format(
                          DATE_TIME_FORMAT.DATE_SPACE
                        )}
                    </div>
                  </td>
                  <td>
                    <div className={styles.detail}>{item?.next2Mobility?.detail}</div>
                    <div className={styles.date}>
                      {item?.next2Mobility?.platform},{' '}
                      {item?.next2Mobility?.platform_date &&
                        moment(item?.next2Mobility?.platform_date).format(
                          DATE_TIME_FORMAT.DATE_SPACE
                        )}
                    </div>
                  </td>
                  <td>
                    <div className={styles.detail}>{item?.endState?.detail}</div>
                    <div className={styles.date}>
                      {item?.endState?.platform},{' '}
                      {item?.endState?.platform_date &&
                        moment(item?.endState?.platform_date).format(DATE_TIME_FORMAT.DATE_SPACE)}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MobilityPlanPrint;
