import { get, isEmpty } from 'lodash';
import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { ModelTC, SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MANAGING_POSITION_CATEGORY, MESSAGE_TYPES } from '../../../../utils/constants';
import { POSITION_CATEGORY_MESSAGE, POSITION_CATEGORY_TABLE_ROW_LIMIT } from '../constants';
import ManagingPositionCategoryTable from './ManagingPositionCategoryTable/ManagingPositionCategoryTable';
import MenuBarManagingPositionCategory from './MenuBarManagingPositionCategory/MenuBarManagingPositionCategory';

const ManagingPositionCategory = () => {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [data, setData] = useState({ position_categories: [], total: 0 });
  const [searchParams, setSearchParams] = useState({
    keyword: '',
    page: 1,
    limit: POSITION_CATEGORY_TABLE_ROW_LIMIT,
  });
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [singleDeleteRecord, setSingleDeleteRecord] = useState(null);

  const handleDeleteRecord = async () => {
    if (isEmpty(selectedRecords) && !singleDeleteRecord) return;
    setDeleting(true);
    try {
      await adminApi.deletePositionCategory(
        singleDeleteRecord ? [singleDeleteRecord] : selectedRecords,
        roleActive.roleId
      );
      setSelectedRecords([]);
      setSingleDeleteRecord(null);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: MANAGING_POSITION_CATEGORY.DELETE_SUCCESS,
        })
      );
      const isReset = singleDeleteRecord
        ? data.position_categories.length === 1
        : selectedRecords.length === data.position_categories.length;
      if (isReset) {
        setSearchParams((prev) => ({
          ...prev,
          page: 1,
          keyword: '',
        }));
        return;
      }
      fetchData();
    } catch (error) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: error.response.data.errors,
        })
      );
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await adminApi.getPositionCategory(searchParams, roleActive.roleId);
      const data = get(res, 'data.result');
      if (!data?.total) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: POSITION_CATEGORY_MESSAGE.SEARCH_NOT_FOUND,
          })
        );
      }
      setData(data);
    } catch (error) {
      setData({ position_categories: [], total: 0 });
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, roleActive.roleId, searchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarManagingPositionCategory
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        selectedRecords={selectedRecords}
        setShowDeleteConfirm={setShowDeleteConfirm}
      />

      {!isEmpty(data.position_categories) ? (
        <>
          <ManagingPositionCategoryTable
            data={data}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
            setShowDeleteConfirm={setShowDeleteConfirm}
            setSingleDeleteRecord={setSingleDeleteRecord}
          />
          <ModelTC
            info={{
              type: 'deleteManagingCategory',
              visible: showDeleteConfirm,
              setVisible: setShowDeleteConfirm,
              handleSubmit: handleDeleteRecord,
              loading: deleting,
              onClose: () => {
                setShowDeleteConfirm(false);
                setSelectedRecords([]);
                setSingleDeleteRecord(null);
              },
            }}
          />
        </>
      ) : (
        <SearchDataTableNotFound
          content={POSITION_CATEGORY_MESSAGE.SEARCH_NOT_FOUND}
          notFoundIcon={search_data_not_found}
        />
      )}
    </Spin>
  );
};

export default ManagingPositionCategory;
