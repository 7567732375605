import React, { useState, useRef, useEffect } from 'react';
import styles from './dropdown-user-access.module.scss';
import Rectangle from '../../../../../assets/img/Rectangle.svg';
import { isEmpty } from 'lodash';

export function DropdownUserAccess({ selected, setSelected, options }) {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={
          !isEmpty(options)
            ? styles.dropdown_btn
            : `${styles.dropdown_btn} ${styles.dropdown_disabled}`
        }
        onKeyDown={() => {}}
        onClick={!isEmpty(options) ? () => setIsActive(!isActive) : null}
        data-testid="dropdown-user-access"
      >
        <p className={styles.dropdown_select_label} data-testid="name">
          {selected?.name || selected}
        </p>
        {!isEmpty(options) && <img src={Rectangle} alt="" />}
      </div>

      {isActive && (
        <div
          className={styles.dropdown_content}
          style={{ maxHeight: '200px', overflowY: 'auto' }}
          data-testid="dropdown-user-access-list"
        >
          {!isEmpty(options) &&
            options.map((option, index) => {
              return (
                <div
                  key={index}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setSelected({
                      id: option.id,
                      name: option?.name || option?.businessUnit || `${option?.opu_division}`,
                      type: option.type,
                      isDelete: true,
                      from: option?.from || null,
                    });
                    setIsActive(false);
                  }}
                  className={styles.dropdown_item}
                  data-testid="click-dropdown-user-access"
                >
                  {option?.name || option?.businessUnit || `${option?.opu_division}`}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
