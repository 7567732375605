import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { ar_down } from '../../assets/img';
import styles from './sp-management-filter.module.scss';

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#00a19c';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <Row gutter={4}>
        <Col>
          <input
            data-testid="checkbox"
            className={styles.checkedFilter}
            type="checkbox"
            onChange={(e) => e.preventDefault()}
            checked={isSelected}
          />
        </Col>
        <Col>{children}</Col>
      </Row>
    </components.Option>
  );
};
InputOption.propTypes = {
  getStyles: PropTypes.func,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

const SPManagementSelectFilter = (props) => {
  const { allOptions, placeholder, selectedOptions, setSelectedOptions } = props;

  const handleOnChange = (options, action, selectedOption) => {
    if (!Array.isArray(options)) return;

    const isSelect = action === 'select-option';
    const isDeselect = action === 'deselect-option';
    const isSelectAll = action === 'select-option' && selectedOption.value === 'all';
    const isDeSelectAll = action === 'deselect-option' && selectedOption.value === 'all';
    const numOptionsWithoutAllOption = allOptions.length - 1;

    if (!isSelectAll && !isDeSelectAll) {
      if (isSelect && options.length === numOptionsWithoutAllOption && allOptions.length !== 2) {
        setSelectedOptions(allOptions);
        return;
      }
      if (isDeselect && options.length === numOptionsWithoutAllOption) {
        setSelectedOptions(options.filter((item) => item.value !== 'all'));
        return;
      }
      setSelectedOptions(options);
      return;
    }

    if (isDeSelectAll) {
      setSelectedOptions([]);
      return;
    }
    if (isSelectAll) {
      setSelectedOptions(allOptions);
    }
  };

  return (
    <div data-testid="select">
      <Select
        value={selectedOptions}
        placeholder={placeholder}
        isMulti
        defaultValue={[]}
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        onChange={(options, { action, option }) => {
          handleOnChange(options, action, option);
        }}
        options={allOptions}
        components={{
          Option: InputOption,
          IndicatorSeparator: null,
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <img data-testid="ar-down" src={ar_down} alt="drop_down" />
            </components.DropdownIndicator>
          ),
        }}
        styles={{
          control: (base) => ({
            ...base,
            cursor: 'pointer',
            margin: 0,
            height: 40,
            width: 186,
            border: '1px solid #D3DEE8',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
            borderRadius: 4,
            '&:hover': {
              border: '1px solid #D3DEE8',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
            },
          }),
          option: (provided) => ({
            ...provided,
            padding: 10,
            fontSize: '12px',
            backgroundColor: 'white',
            '&:active': {
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: 'white',
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            color: '#8D8D8D',
            fontSize: 14,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            paddingRight: 20,
          }),
          menu: (provided) => ({
            ...provided,
            width: '214px',
          }),
        }}
      />
    </div>
  );
};

SPManagementSelectFilter.propTypes = {
  allOptions: PropTypes.array,
  placeholder: PropTypes.string,
  selectedOptions: PropTypes.array,
  setSelectedOptions: PropTypes.func,
};

export default SPManagementSelectFilter;
