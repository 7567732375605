import { Divider, notification } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { BasicButton } from '../../../../assets/common';
import mobilitySimulationApi from '../../../../services/mobilitySimulationApi';
import { ROLE_SG_MAPPING } from '../../../../utils/constants';
import { convertPositionSimulation, convertTalentSimulation } from '../../../../utils/helper';
import { MOBILITY_MODAL_ADD_TYPE } from '../../constants';
import MobilitySearchPositionBox from '../MobilitySearchPositionBox/MobilitySearchPositionBox';
import * as styles from './mobility-add-position-modal.module.scss';

const MobilityAddPositionModal = (props) => {
  const { show, setShow, addPositionOrTalent, type, setIsSeniorManager } = props;
  const roleActive = useSelector((state) => state.user.roleActive);

  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
    query: {},
  });

  const isAddPosition = useMemo(() => {
    return type === MOBILITY_MODAL_ADD_TYPE.POSITION;
  }, [type]);

  const handleCancel = () => {
    setShow(false);
  };

  const getDetailPositionOrTalent = async (query) => {
    try {
      setLoading(true);
      const response = isAddPosition
        ? await mobilitySimulationApi.getPositionDetail(query, roleActive)
        : await mobilitySimulationApi.getTalentDetail(query, roleActive);
      const data = get(response, 'data.result');
      setLoading(false);
      return data || {};
    } catch (error) {
      console.error(console.error());
      setLoading(false);
      return {};
    }
  };

  const handleAdd = async () => {
    if (!searchTerm.showTerm || isEmpty(searchTerm.query))
      return notification.error({
        message: isAddPosition ? 'Please select one position' : 'Please select one talent',
        placement: 'topRight',
        duration: 1.5,
      });
    if (isAddPosition) {
      const position = await getDetailPositionOrTalent(searchTerm.query);
      const objPosition = position[0];
      if (objPosition.role_level === ROLE_SG_MAPPING.SENIOR_MANAGER) {
        setIsSeniorManager(true);
      }
      addPositionOrTalent(convertPositionSimulation(objPosition));
      setShow(false);
    } else {
      const talent = await getDetailPositionOrTalent(searchTerm.query);
      const objTalent = talent[0];
      if (objTalent.role_level === ROLE_SG_MAPPING.SENIOR_MANAGER) {
        setIsSeniorManager(true);
      }
      addPositionOrTalent(convertTalentSimulation(objTalent));
      setShow(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCancel}
        dialogClassName={styles.modal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          {isAddPosition ? (
            <>
              <div>Add Position to Mobility Simulation</div>
              <p>Enter Position name, Position ID, Staff Name or Staff ID to Mobility Simulation</p>
            </>
          ) : (
            <>
              <div>Add Talent to Mobility Simulation</div>
              <p>Enter Staff Name, Staff ID, Department or Position to Mobility Simulation</p>
            </>
          )}
        </Modal.Header>
        <Modal.Body className={styles.table}>
          <div className={styles.form}>
            <MobilitySearchPositionBox
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchList={searchList}
              setSearchList={setSearchList}
              isAddPosition={isAddPosition}
            />
          </div>
          <Divider plain className={styles.divider} />
          <BasicButton
            className={styles.submitButton}
            disabled={loading}
            mode="teal"
            onClick={handleAdd}
          >
            {isAddPosition ? (
              <span data-testid="add-pos-btn">Add Position</span>
            ) : (
              <span data-testid="add-talent-btn">Add Talent</span>
            )}
          </BasicButton>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MobilityAddPositionModal;
