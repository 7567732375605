import { get } from 'lodash';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { masterDataManagementApi } from '../../../../../services/masterDataManagementApi';
import { MASTER_DATA_MANAGEMENT } from '../../../../../utils/constants';
import styles from './add-new-business.module.scss';

const AddBusinessUnit = (props) => {
  const {
    setIsVisible,
    handleAddBusinessExist,
    handleAddBusinessSuccess,
    getBusinessList,
    query,
    setQuery,
  } = props;

  const [loading] = useState(false);
  const [loadingDetailMeeting] = useState(false);
  const [error, setError] = useState(false);
  const [business, setBusiness] = useState('');
  const roleActive = useSelector((state) => state.user.roleActive);

  const handleCancel = () => {
    setIsVisible(false);
  };

  const validate = () => {
    if (!business) {
      setError(true);
      return true;
    } else {
      return false;
    }
  };

  const handleClickAdd = async () => {
    const isValidate = validate();
    if (isValidate) return;

    try {
      await masterDataManagementApi.addBusinessUnit({ name: business.trim() }, roleActive);
      handleAddBusinessSuccess();
      if (query.page !== 1 || query.keyword) {
        setQuery((prev) => {
          return {
            ...prev,
            page: 1,
            keyword: '',
          };
        });
      }
      if (query.page === 1 && !query.keyword) {
        getBusinessList();
      }
    } catch (error) {
      console.log(error);
      const isExist = get(error, 'response.data.errors');
      if (isExist === MASTER_DATA_MANAGEMENT.ADD_BUSINESS_EXIST) handleAddBusinessExist();
    }
    setIsVisible(false);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => handleCancel()}
        dialogClassName={styles.modal}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div>Add New Business Unit</div>
        </Modal.Header>

        {loadingDetailMeeting ? (
          <div className="d-flex justify-content-center align-items-center w-100 mb-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Modal.Body className={styles.table}>
            <div className={styles.row}>
              <div className="col-8">
                <div className={`${styles.label} d-flex justify-content-start`}>
                  <div>Business Unit</div>
                  <div style={{ color: '#f85032' }}>*</div>
                </div>
                <input
                  onChange={(e) => {
                    if (e.target.value.slice(-1) === '') setBusiness('');
                    if (e.target.value.slice(-1).match(/[A-Za-z0-9\b]|\s/))
                      setBusiness(e.target.value);
                  }}
                  value={business}
                  className={error && !business ? styles.error : ''}
                  placeholder="Business Unit"
                />
                {error && !business && (
                  <span style={{ color: '#f85032', fontSize: '12px' }}>
                    Please fill in the required fields
                  </span>
                )}
              </div>
              <div>
                <div className={styles.add} onKeyDown={() => {}} onClick={() => handleClickAdd()}>
                  {loading && (
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                  )}
                  <div>Add</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default AddBusinessUnit;
