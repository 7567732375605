import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './view-more-video-modal.module.scss';
import { BasicButton } from '../../../../../../assets/common';
import { Spin } from 'antd';
import { GLOBAL_ALERT_MESSAGE_CONTAINER_ID, MESSAGE_TYPES } from '../../../../../../utils/constants';
import { pushMessage } from '../../../../../../store/alertMessageSlice';
import { useDispatch } from 'react-redux';
import GlobalAlertMessage from '../../../../../../components/GlobalAlertMessage/GlobalAlertMessage';
import talentProfileApi from '../../../../../../services/talentProfiles';
import TalentVideoItem from '../TalentVideoItem/TalentVideoItem';
import { isEmpty } from 'lodash';
import { ModelTC } from '../../../../../../components';

const INIT_DATA = { videos: [], total: 0 };
const INIT_PAGE = 3;
const LIMIT_PER_ROW = 4;

const ViewMoreVideoModal = (props) => {
  const { getVideos, roleId, openPopupAdd, setOpenPopupAdd, setRefreshAfterDelete } = props;
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(INIT_DATA);
  const [page, setPage] = useState(INIT_PAGE);
  const [isEnableDelete, setIsEnableDelete] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false);

  const handleToggleDelete = () => {
    setIsEnableDelete(!isEnableDelete);
    setSelectedDelete([]);
  };

  const handleClickAddVideo = async () => {
    setOpenPopupAdd({ open: true, isViewMore: true, isAdded: false });
  };

  const handleSetData = (loadMoreData) => {
    const videos = loadMoreData?.videos;
    if (isEmpty(videos)) return;
    setData((prev) => ({ ...prev, videos: [...prev.videos, ...videos] }));
  };

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (container.scrollHeight - container.scrollTop > container.clientHeight + 20) return;
    if (data.videos.length === data.total) return;
    setPage(page + 1);
  }, [data]);

  const handleClickDelete = () => {
    setIsShowDeleteConfirm(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await talentProfileApi.deleteTalentVideos(selectedDelete, roleId);
      if (res.status === 200) {
        setRefreshAfterDelete(true);
        await getVideos(LIMIT_PER_ROW * page, 1, setData, setLoading);
        setIsShowDeleteConfirm(false);
        dispatch(
          pushMessage({
            message: 'Talent Video successfully deleted.',
            type: MESSAGE_TYPES.SUCCESS,
            timeShow: 0,
            containerId: GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_VIDEO,
          })
        );
        handleToggleDelete();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    getVideos(LIMIT_PER_ROW * INIT_PAGE, 1, setData, setLoading);
  }, []);

  useEffect(() => {
    if (page === INIT_PAGE) return;
    getVideos(LIMIT_PER_ROW, page, handleSetData, setLoading);
  }, [page]);

  useEffect(() => {
    if (openPopupAdd.isAdded) getVideos(LIMIT_PER_ROW * page, 1, setData, setLoading);
  }, [getVideos, openPopupAdd.isAdded]);

  return (
    <div className={styles.wrapper}>
      <GlobalAlertMessage id={GLOBAL_ALERT_MESSAGE_CONTAINER_ID.TP_VIDEO} />
      <Spin spinning={loading}>
        <div className={styles.buttons}>
          {!isEnableDelete && <BasicButton onClick={handleToggleDelete}>Delete</BasicButton>}
          <BasicButton mode="teal" onClick={handleClickAddVideo}>
            Add Video
          </BasicButton>
        </div>
        <div className={styles.videos} ref={scrollContainerRef}>
          {data.videos.map((video) => (
            <div key={video.id}>
              <TalentVideoItem isPopup data={video} isEnableDelete={isEnableDelete} setSelectedDelete={setSelectedDelete} />
            </div>
          ))}
        </div>
        {isEnableDelete && (
          <div className={styles.buttonsBottom}>
            <BasicButton onClick={handleToggleDelete}>Cancel</BasicButton>
            <BasicButton mode="teal" onClick={handleClickDelete} disabled={!selectedDelete.length}>
              Delete
            </BasicButton>
          </div>
        )}
        <ModelTC
          info={{
            type: 'deleteTalentVideo',
            visible: isShowDeleteConfirm,
            setVisible: setIsShowDeleteConfirm,
            onClose: handleToggleDelete,
            handleSubmit: handleDelete,
            loading,
          }}
        />
      </Spin>
    </div>
  );
};

ViewMoreVideoModal.propTypes = {
  getVideos: PropTypes.func,
  roleId: PropTypes.number,
  openPopupAdd: PropTypes.object,
  setOpenPopupAdd: PropTypes.func,
  setRefreshAfterDelete: PropTypes.func,
};

export default ViewMoreVideoModal;
