import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { profileSelector } from '../../../selector';
import styles from '../overall-profile-user.module.scss';
import { useParams } from 'react-router-dom';

const NameAndPosition = (props) => {
  const { fromTalentReview } = props;
  const { id } = useParams();
  const { firstSectionProfile, loading } = useSelector(profileSelector);

  const displayInfoEmployee = () => {
    return (
      <>
        {firstSectionProfile && firstSectionProfile.birthName && (
          <p
            onKeyDown={() => {}}
            onClick={
              fromTalentReview
                ? () => window.open(`/profile/${firstSectionProfile.staffId || id}`, '_blank')
                : null
            }
            style={{
              cursor: `${fromTalentReview ? 'pointer' : 'default'}`,
            }}
          >
            {firstSectionProfile && firstSectionProfile.birthName}
          </p>
        )}
        {firstSectionProfile && firstSectionProfile.positionName && (
          <p>{firstSectionProfile && firstSectionProfile.positionName}</p>
        )}
        {firstSectionProfile && firstSectionProfile.department && (
          <p>{firstSectionProfile && firstSectionProfile.department}</p>
        )}
        {firstSectionProfile && firstSectionProfile.division && (
          <p>{firstSectionProfile && firstSectionProfile.division}</p>
        )}
        {firstSectionProfile && firstSectionProfile.businessUnit && (
          <p>{firstSectionProfile && firstSectionProfile.businessUnit}</p>
        )}
        {firstSectionProfile && firstSectionProfile.opu && (
          <p>{firstSectionProfile && firstSectionProfile.opu}</p>
        )}
      </>
    );
  };
  return (
    <div className={styles.userNameAndPosition}>
      <p>
        STAFF ID:
        {(!loading && firstSectionProfile && firstSectionProfile.staffId) || id}
        {loading && <Skeleton count={1} width={308} height={25} inline={true} />}
      </p>
      {loading && (
        <Skeleton count={5} width={308} height={22} className={styles.skeleton__marginBtn} />
      )}
      {!loading && displayInfoEmployee()}
    </div>
  );
};
export default NameAndPosition;
