import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { BasicButton } from '../../../../../../assets/common';
import {
  change_calendar,
  checked_manual_run_status,
  dropdown_icon,
} from '../../../../../../assets/img';

const Wrapper = styled.div`
  position: relative;
  .arrow {
    position: absolute;
    right: 10px;
    top: 12px;
  }
  .checked {
    position: absolute;
    left: 10px;
    top: 12px;
    width: 18px;
  }
  input {
    height: 42px;
    border-radius: 4px;
    border: 1px solid #d3dee8;
    &::placeholder {
      padding-left: 6px;
      color: #8d8d8d;
      font-size: 14px;
      line-height: 20px;
    }
    width: 270px;
  }
`;

const CalendarCustom = styled.div`
  .rasta-stripes > .react-datepicker__month-container > .react-datepicker__header--custom {
    background-color: #fff;
    .react-datepicker__header {
      border: none;
    }
  }
  .rasta-stripes {
    .react-datepicker__header {
      border: none;
    }
    .react-datepicker__day-name {
      color: #3f3c4c;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
    }
    .react-datepicker__day {
      width: 30px;
      height: 30px;
      border-radius: 0%;
      margin: 0;
    }
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      background-color: #d6dae0;
      color: #fff !important;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: #00a19c;
      border-radius: 50%;
      color: #fff;
    }
    .start-date-custom {
      background-color: #00a19c;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      border-radius: 50%;
      box-shadow: 11px 0px 0px #d6dae0;
    }
    .end-date-custom {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: #00a19c;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      border-radius: 50%;
      box-shadow: -11px 0px 0px #d6dae0;
    }
    .blur-date {
      color: #ccc;
    }
  }
`;
const FilterCalendar = (props) => {
  const { query, setQuery } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const calRef = useRef();
  const [month, setMonth] = useState(0);

  useEffect(() => {
    setDateRange(query.dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query.dateRange)]);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Wrapper>
      <input
        className="example-custom-input"
        onKeyDown={() => {}}
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Date"
        style={{ paddingLeft: `${isEmpty(value) ? '6px' : '35px'}`, cursor: 'pointer' }}
      />
      <img
        src={dropdown_icon}
        className="arrow"
        style={{ pointerEvents: 'all', cursor: 'pointer' }}
        onKeyDown={() => {}}
        onClick={onClick}
        alt="dropdown_icon"
      />
      {!isEmpty(value) && (
        <img src={checked_manual_run_status} className="checked" alt="checked_manual_run_status" />
      )}
    </Wrapper>
  ));

  const handleGetDateValue = (date) => {
    const firstCondition =
      moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY') ===
        moment(startDate, 'DD/MM/YYYY').format('DD/MM/YYYY') && endDate !== null;
    const secondCondition =
      moment(endDate, 'DD/MM/YYYY').format('DD/MM/YYYY') ===
      moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
    const thirCondition = moment(date, 'DD/MM/YYYY').format('M') !== month;

    if (firstCondition) {
      return 'start-date-custom';
    } else if (secondCondition) {
      return 'end-date-custom';
    } else if (thirCondition) {
      return 'blur-date';
    } else {
      return null;
    }
  };
  return (
    <CalendarCustom>
      <DatePicker
        ref={calRef}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        shouldCloseOnSelect={false}
        onChange={(update) => {
          setDateRange(update);
        }}
        onClickOutside={() => {
          setDateRange(query.dateRange);
        }}
        customInput={<ExampleCustomInput />}
        dateFormat="dd/MM/yyyy"
        calendarStartDay={1}
        calendarClassName="rasta-stripes"
        showDisabledMonthNavigation
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          monthDate,
        }) => {
          setMonth(moment(date).format('M'));
          return (
            <div
              style={{
                padding: '10px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#fff',
                color: '#3f3c4c',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '22px',
                letterSpacing: '0px',
                textAlign: 'center',
                width: '100%',
                gap: '10px',
              }}
            >
              <img
                src={change_calendar}
                alt="change_calendar"
                style={{
                  cursor: 'pointer',
                  pointerEvents: 'all',
                  padding: '5px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.15)',
                  userSelect: 'none',
                }}
                onKeyDown={() => {}}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              />

              <div className="d-flex">
                <div style={{ marginRight: '10px' }}>
                  {moment(date, 'DD/MM/YYYY').format('MMMM')}
                </div>
                <div>{moment(date, 'DD/MM/YYYY').format('YYYY')}</div>
              </div>

              <img
                src={change_calendar}
                alt="change_calendar"
                style={{
                  cursor: 'pointer',
                  pointerEvents: 'all',
                  padding: '5px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.1)',
                  transform: 'rotate(180deg)',
                  userSelect: 'none',
                }}
                onKeyDown={() => {}}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              />
            </div>
          );
        }}
        dayClassName={(date) => handleGetDateValue(date)}
      >
        <div className="d-flex justify-content-around p-2">
          <BasicButton
            mode="border-teal"
            onKeyDown={() => {}}
            onClick={() => {
              setDateRange(query.dateRange);
              calRef.current.setOpen(false);
            }}
          >
            Cancel
          </BasicButton>
          <BasicButton
            mode="teal"
            onKeyDown={() => {}}
            onClick={() => {
              calRef.current.setOpen(false);
              setQuery((prev) => {
                return {
                  ...prev,
                  dateRange: dateRange,
                };
              });
            }}
          >
            Apply
          </BasicButton>
        </div>
      </DatePicker>
    </CalendarCustom>
  );
};

export default FilterCalendar;
