import { PERMISSION, DASHBOARD_FILTER_ACTIONS } from '../utils/constants';
import { filterDemongraphic } from '../utils/filterDemographic';
import { filterSP } from '../utils/filterSP';
import { getPermissionsByRole } from '../utils/helper';
import axiosClient from './axiosClient';

export const fetchStatisticSuccess = ({ type, selectedBU, selectedCompany, role }) => {
  let itemParam = filterSP({
    type,
    selectedBU,
    selectedCompany,
  });
  return axiosClient.get('statistic-sucessions', {
    params: itemParam,
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchKeyPosition = ({ type, companyFilterType, companyFilterValue, role }) => {
  let itemParam = filterSP({
    type,
    companyFilterType,
    companyFilterValue,
  });
  return axiosClient.get('get-key-position', {
    params: itemParam,
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchSPReviewed = ({ type, selectedBU, selectedCompany, role }) => {
  let itemParam = filterSP({
    type,
    selectedBU,
    selectedCompany,
  });
  return axiosClient.get('get-total-retirement', {
    params: itemParam,
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchRetirementForeCast = ({
  selectedCompany,
  selectedBU,
  selectedTopTalent,
  role,
}) => {
  let itemParam = filterDemongraphic({
    selectedCompany,
    selectedBU,
    selectedTopTalent,
  });
  return axiosClient.get('get-retirement-forecast', {
    params: itemParam,
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchNationality = ({ selectedCompany, selectedBU, selectedTopTalent, role }) => {
  let itemParam = filterDemongraphic({
    selectedCompany,
    selectedBU,
    selectedTopTalent,
  });
  return axiosClient.get('get-national', {
    params: itemParam,
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchSuccessor = ({ selectedCompany, selectedBU, selectedTopTalent, role }) => {
  let itemParam = filterDemongraphic({
    selectedCompany,
    selectedBU,
    selectedTopTalent,
  });

  return axiosClient.get('get-successor', {
    params: itemParam,
    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchBusinessOPUDivion = ({
  selectedCompany,
  selectedBU,
  selectedTopTalent,
  role,
}) => {
  let itemParam = filterDemongraphic({
    selectedCompany,
    selectedBU,
    selectedTopTalent,
  });

  return axiosClient.get('talent-by-business-division-opu', {
    params: itemParam,

    headers: {
      permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      role: role,
    },
  });
};

export const fetchPositions = (value, type, roleId) => {
  const url = `succession-planning/positions`;
  if (type === 'search') {
    return axiosClient.get(url, {
      params: { keyword: value, _limit: 5, isSearch: 1 },
      headers: { permission: PERMISSION.CREATE_MEETING, role: roleId },
    });
  }
  if (type === 'position_type') {
    return axiosClient.get(url, {
      params: { type: value },
      headers: { permission: PERMISSION.CREATE_MEETING, role: roleId },
    });
  }
};

export const fetchPositionsSPDashboard = ({
  keyword,
  isSearch,
  isSearchSP,
  roleId,
  permissions,
  business,
  approvedDate,
  page,
  limit,
  role_level_filter,
  business_filter,
}) => {
  const permission = getPermissionsByRole({
    permissions,
    part: 'SEARCH_VIEW_SP_DASHBOARD',
  });

  const url = `/succession-planning/sp-dashboard-positions`;
  return axiosClient.get(url, {
    headers: {
      permission,
      role: roleId,
    },
    params: {
      keyword,
      isSearch,
      isSearchSP,
      business,
      approvedDate,
      check_group: 0,
      _page: page,
      _limit: limit,
      role_level_filter,
      business_filter,
    },
  });
};

export const getSuccessionPlanningViewDetail = ({ position_codes, roleUser, check_group }) => {
  const permission = getPermissionsByRole({
    permissions: roleUser.permissions,
    part: 'SEARCH_VIEW_SP_DASHBOARD',
  });

  const url = `/succession-planning/sp-dashboard-positions`;
  return axiosClient.get(url, {
    headers: {
      permission,
      role: roleUser.roleActive.roleId,
    },
    params: { position_codes, check_group },
  });
};

export const getPositionByCode = ({ positionCode, roleUser }) => {
  const permission = getPermissionsByRole({
    permissions: roleUser.permissions,
    part: 'SEARCH_VIEW_SP_DASHBOARD',
  });
  return axiosClient.get(`succession-planning/get-search-incumbent/${positionCode}`, {
    headers: {
      permission: permission,
      role: roleUser.roleActive.roleId,
    },
  });
};

export const addParamsToGetSuccessionPlanning = (params) => {
  localStorage.setItem(DASHBOARD_FILTER_ACTIONS.DASHBOARD_FILTERED, JSON.stringify(params));
};

export const getParamsToGetSuccessionPlanning = () => {
  return localStorage.getItem(DASHBOARD_FILTER_ACTIONS.DASHBOARD_FILTERED);
};

export const getListPosition = (code, excludePositions, roleActive) => {
  const url = '/agenda-talent-review/search-positions';
  return axiosClient.get(url, {
    headers: {
      role: roleActive.roleId,
    },
    params: {
      keyword: code,
      _limit: 10,
      _page: 1,
      exclude_positions: excludePositions.join(','),
    },
  });
};

export const getPositionDetail = (code, staffId, roleActive) => {
  const url = `/agenda-talent-review/get-position?position_code=${code}&staff_id=${staffId}`;
  return axiosClient.get(url, {
    headers: {
      role: roleActive.roleId,
    },
  });
};

export const getPositionGroup = (position_codes, check_group, roles) => {
  const { role, permission } = roles;
  const url = `succession-planning/positions?position_codes=${position_codes}&check_group=${check_group}`;
  return axiosClient.get(url, {
    headers: {
      role: role.roleId,
      permission,
    },
  });
};
