import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BasicButton, BasicInput, BasicLabel, BasicLoading } from '../../../../assets/common';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { REPORT_BUILDER_MESSAGE } from '../../constants';
import * as styles from './edit-report-name-modal.module.scss';
import { isEmpty } from 'lodash';

const CUSTOM_STYLE = {
  closePosition: 45,
  headerPaddingBottom: 40,
  containerPaddingTopBottom: 40,
  titleFontSize: 20,
  titleFontWeight: 500,
};

const EditColumnNameModal = ({
  open,
  setOpen,
  editColumn,
  setListOfTalents,
  setListOfColumnName,
  currentColumns,
  setCurrentColumns,
  fetchManualColumns,
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const listColumnName = useMemo(() => {
    return (!isEmpty(currentColumns) && currentColumns.map((item) => item.name)) || [];
  }, [currentColumns]);

  const handleOnChange = (event) => {
    setTitle(event.target.value);
  };

  const handleClose = (event) => {
    setOpen(false);
    setTitle('');
    setErrorMessage(null);
  };

  const handleEdit = async () => {
    if (listColumnName.includes(title.trim()) || !title.trim()) {
      return setErrorMessage(REPORT_BUILDER_MESSAGE.DUPLICATE_COLUMN_NAME);
    }

    setOpen(false);
    setListOfTalents((prev) =>
      prev.map((talent) => ({
        ...talent,
        columns: talent.columns.map((column) => {
          if (column.name === editColumn.name) {
            return {
              ...column,
              name: title,
            };
          } else {
            return column;
          }
        }),
      }))
    );
    setListOfColumnName((prev) =>
      prev.map((name) => {
        if (name === editColumn.name) {
          return title;
        } else {
          return name;
        }
      })
    );
    setCurrentColumns((prev) =>
      prev.map((item) => {
        if (item.name === editColumn.name) {
          return {
            ...item,
            name: title,
          };
        } else {
          return item;
        }
      })
    );

    if (editColumn?.id) {
      try {
        setLoading(true);
        const body = {
          report_id: editColumn?.report_id,
          column_id: editColumn.id,
          column_name: title,
        };
        await reportBuilderApi.updateColumnName(body);
        await fetchManualColumns();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.EDIT_COLUMN_NAME_SUCCESS,
        isScroll: true,
      })
    );
  };

  return (
    <CustomAntModal
      title="Edit Column Name"
      hideDefaultBtn
      destroyOnClose
      width={420}
      styles={CUSTOM_STYLE}
      open={open}
      setOpen={setOpen}
    >
      <BasicLabel>Column Name</BasicLabel>
      <BasicInput
        id="title"
        name="title"
        placeholder="Enter Column Name"
        autoComplete="off"
        maxLength="20"
        height={40}
        defaultValue={editColumn?.name}
        onChange={handleOnChange}
        style={errorMessage ? { borderColor: '#F36568' } : {}}
      />
      {errorMessage && <div className={styles.errorMsg}>{errorMessage}</div>}
      <div className={styles.btnWrapper}>
        <BasicButton height={36} onClick={handleClose}>
          Cancel
        </BasicButton>
        <BasicLoading spinning={loading}>
          <BasicButton mode="teal" height={36} disabled={!title} onClick={handleEdit}>
            Save
          </BasicButton>
        </BasicLoading>
      </div>
    </CustomAntModal>
  );
};

export default EditColumnNameModal;
