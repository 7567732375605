import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BasicButton } from '../../assets/common';
import { close_modal } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import FormDate from '../Form/FormDate/FormDate';
import FormDropdown from '../Form/FormDropdown/FormDropdown';
import FromTextArea from '../Form/FormTextArea/FromTextArea';
import styles from './historical-talent-status.module.scss';
import { pushMessage } from '../../store/alertMessageSlice';

const options = [
  {
    value: 'Business Talent',
    label: 'Business Talent',
    // disabled: true,
  },
  {
    value: 'Corporate Talent',
    label: 'Corporate Talent',
  },
  {
    value: 'Other Talent',
    label: 'Other Talent',
  },
];

const HistoricalPopupAdd = (props) => {
  const { show, setShow, fetchApi, historicalEdit, setHistoricalEdit } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    status: '',
    startDate: '',
    endDate: '',
    platform: '',
  });
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const roleActive = useSelector((state) => state.user.roleActive);
  const { id } = useParams();

  async function addHistorical() {
    try {
      setLoading(true);
      if (isEmpty(historicalEdit)) {
        await talentProfileApi.addHistoricalStatus(roleActive, id, {
          ...formData,
          start_date: moment(formData.startDate).format(),
          end_date: moment(formData.endDate).format(),
        });
        dispatch(pushMessage({
          type: 'success',
          message: 'Historical Talent Status successfully added ',
          timeShow: 0,
        }));
      }

      if (!isEmpty(historicalEdit)) {
        await talentProfileApi.editHistoricalStatus(roleActive, id, historicalEdit.id, {
          ...formData,
          start_date: moment(formData.startDate).format(),
          end_date: moment(formData.endDate).format(),
        });
        dispatch(pushMessage({
          type: 'success',
          message: 'Historical Talent Status successfully edited ',
          timeShow: 0,
        }));
      }

      fetchApi();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setShow(false);
  }

  function validateFrom() {
    if (
      !(formData.status && formData.startDate && formData.endDate && formData.platform) ||
      moment(moment(formData.startDate).format('YYYY-MM-DD')).isAfter(
        moment(moment(formData.endDate).format('YYYY-MM-DD'))
      )
    )
      return setIsValid(true);
    addHistorical();
  }

  useEffect(() => {
    if (isEmpty(historicalEdit)) return;

    setFormData({
      status: historicalEdit.status,
      startDate: historicalEdit.start_date,
      endDate: historicalEdit.end_date,
      platform: historicalEdit.platform,
    });
    return () => setHistoricalEdit({});
  }, [historicalEdit, setHistoricalEdit]);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={styles.talent_search_form}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <div className={styles.talent_search_content}>
          <Modal.Header className={styles.modal_header}>
            <div className="d-flex flex-column justify-content-start">
              <h1>{isEmpty(historicalEdit) ? 'Add' : 'Edit'} Historical Talent Status</h1>
              <p>
                {isEmpty(historicalEdit)
                  ? 'Add Historical Talent Status on the platform'
                  : 'Edit Historical Talent Status on the platform'}
              </p>
            </div>
            <img
              src={close_modal}
              alt="close_modal"
              style={{ pointerEvents: 'all', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setShow(false)}
            />
          </Modal.Header>

          <Modal.Body className={styles.modal_body}>
            <Spin spinning={loading} delay={500}>
              <div className="d-flex justify-content-between no-gutters">
                <div className="col-5 ">
                  <FormDropdown
                    label="Status"
                    messageErr="Please fill in the required fields"
                    required={true}
                    placeholder="Select Status"
                    value={formData.status}
                    options={options}
                    isError={isValid && !formData.status}
                    setFormData={(val) =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          status: val,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-3 ">
                  <FormDate
                    label="Start Date"
                    messageErr={
                      moment(moment(formData.startDate).format('YYYY-MM-DD')).isAfter(
                        moment(moment(formData.endDate).format('YYYY-MM-DD'))
                      )
                        ? 'Start date must be less than or equal to End date'
                        : 'Please fill in the required fields'
                    }
                    required={true}
                    placeholder="Select Start Date"
                    value={formData.startDate}
                    isError={
                      isValid &&
                      (!formData.startDate ||
                        moment(moment(formData.startDate).format('YYYY-MM-DD')).isAfter(
                          moment(moment(formData.endDate).format('YYYY-MM-DD'))
                        ))
                    }
                    setFormData={(val) =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          startDate: val,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-3 ">
                  <FormDate
                    label="End Date"
                    messageErr="Please fill in the required fields"
                    required={true}
                    placeholder="Select End Date"
                    value={formData.endDate}
                    isError={isValid && !formData.endDate}
                    setFormData={(val) =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          endDate: val,
                        };
                      })
                    }
                  />
                </div>
              </div>
              <FromTextArea
                label="Platform"
                messageErr="Please fill in the required fields"
                required={true}
                placeholder=""
                value={formData.platform}
                isError={isValid && !formData.platform}
                setFormData={(val) =>
                  setFormData((prev) => {
                    return {
                      ...prev,
                      platform: val,
                    };
                  })
                }
              />
              <div className="mt-3 d-flex justify-content-end ">
                <BasicButton
                  style={{ marginRight: '10px' }}
                  onKeyDown={() => {}}
                  onClick={() => setShow(false)}
                >
                  Cancel
                </BasicButton>
                <BasicButton mode="teal" onKeyDown={() => {}} onClick={() => validateFrom()}>
                  {isEmpty(historicalEdit) ? 'Add Historical Talent Status' : 'Save Changes'}
                </BasicButton>
              </div>
            </Spin>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default HistoricalPopupAdd;
