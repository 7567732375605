import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;
  color: #344563;

  .react-datepicker__day--outside-month {
    color: gray !important;
    opacity: 0.7;
  }

  .react-datepicker,
  .react-datepicker__header--custom {
    border: none;
    background-color: white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 145px;
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    white-space: normal !important;
    cursor: default;
  }
  .react-datepicker__week {
    display: flex;
  }
  .react-datepicker__day-name {
    margin: 0 15px;
    font-weight: 700;
    font-size: 18px;
  }
  .react-datepicker__day {
    border-top: 3px solid #3f3c4c;
    padding: 15px 5px;

    margin: 0 15px 10px;
    min-height: 250px;
  }

  .react-datepicker {
    position: relative;
  }

  .react-datepicker__day--selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__today-button {
    position: absolute;
    top: 0%;
    left: 16%;
    padding: 10px 20px;
    border: 1px solid #00a19c;
    border-radius: 6px;
    background-color: white;
    transform: translateY(17%);
    color: #00a19c;
    width: 80px;
    height: 40px;
  }
`;
