import styled, { css } from 'styled-components';
import { font } from '../../assets/common';
import { Checkbox } from 'antd';

const DropdownSp = styled.div`
  font-family: ${font.inter};

  .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
  }
`;

const CheckBoxCustom = styled(Checkbox)`
  ${(props) => {
    return css`
      display: flex;
      margin: 15px 10px !important;
      color: var(--Emphasis-Body-N800, #3f3c4c);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 22px; /* 157.143% */
      gap: 10px;
      text-transform: none;

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #00a19c;
        border-color: #00a19c;
        border-radius: 4px !important;
      }
    `;
  }}
`;

const Apply = styled.div`
  background-color: #00a19c;
  width: 90px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  border-radius: 40px;
  color: #fff;
  height: 20px;
  padding: 3px 0;
  cursor: pointer;
`;
export { DropdownSp, CheckBoxCustom, Apply };
