import { DatePicker, Pagination, Spin } from 'antd';
import { size, isEmpty, get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { calendar, pets_chevron_right } from '../../assets/img';
import shage from '../../assets/img/Shape.svg';
import FilterMultipleSP from '../../components/Filter/FilterMultipleSP/FilterMultipleSP';
import { fetchBusinessExperiences } from '../../services/talentProfiles';
import { SearchPositionSp } from './components';
import { TitleDropdown, Wrapper } from './styled';
import styles from './successionPlanningView.module.scss';
import SPViewPositionItem from './SPViewPositionItem';
import { paginationConfig } from '../../utils/helper';
import { adminApi } from '../../services/admin';
import { useSelector } from 'react-redux';
import CheckBoxSP from '../../components/CheckBoxSP/CheckBoxSP';

const STYLE_WIDTH_COLUMN = ['22%', '14%', '15%', '12%', '13%', '16%', '8%', '1%'];
const ROWS_LIMIT = 15;

export const SuccessionPlanningView = () => {
  const [positionList, setPositionList] = useState({
    data: [],
    total: 0,
  });
  const [searchParams, setSearchParams] = useState({
    search: '',
    page: 1,
    roleLevel: [],
    business: [],
  });
  const [loading, setLoading] = useState(false);
  const [searchPosition, setSearchPosition] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [approvedDate, setApprovedDate] = useState();
  const [businessUnit, setBusinessUnit] = useState();
  const [isActive, setIsActive] = useState(false);
  const [optionDropdown, setOptionDropdown] = useState({ roleLevel: [], business: [] });
  const [listSelect, setListSelect] = useState([]);
  const ref = useRef();
  const roleActive = useSelector((state) => state.user.roleActive);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prev) => ({ ...prev, page: pageNum }));
    };
    return paginationConfig(positionList.total, searchParams.page, onChange, ROWS_LIMIT);
  }, [searchParams, positionList.total]);

  useEffect(() => {
    if (!roleActive.roleId) return;
    const getBusinessList = async () => {
      try {
        let response = await fetchBusinessExperiences();
        if (response.status === 200 && response.data.result) {
          const rs = response.data.result.map((item) => ({
            label: item.businessUnit,
            value: item.businessUnit,
          }));
          setBusinessUnit(rs);
        }
      } catch (error) {
        setBusinessUnit({});
      }
    };
    getBusinessList();
    const getRoleLevelAndBusiness = async () => {
      try {
        let response = await adminApi.getRoleLevelAndBusiness(roleActive.roleId);
        const roleLevel = get(response, 'data.result.role_level_filter') || [];
        const business = get(response, 'data.result.business_filter') || [];
        setOptionDropdown({ roleLevel, business });
      } catch (error) {
        setOptionDropdown({ roleLevel: [], business: [] });
      }
    };
    getRoleLevelAndBusiness();
  }, [roleActive.roleId]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  const handleShowPositions = () => {
    if (size(positionList.data) > 0) {
      return (
        <>
          {positionList.data.map((pos, index) => (
            <SPViewPositionItem key={index} item={pos} styleWidth={STYLE_WIDTH_COLUMN} />
          ))}
        </>
      );
    }
    if (size(positionList.data) === 0 && searchPosition) {
      return <div style={{ textAlign: 'center', padding: '15px 20px' }}>No data</div>;
    }
    return <div></div>;
  };

  const handleChangeDate = (e) => {
    setSelectedDate(e._d.toLocaleDateString('en-ZA'));
  };

  return (
    <div className={styles.maxWidth}>
      <div className={styles.control}>
        <div className={styles.breadcrumb}>
          <Link to="/">
            <p>Dashboard</p>
          </Link>
          <img src={pets_chevron_right} alt="pets_chevron_right" />
          <p className={styles.link_active}>Succession Planning</p>
        </div>
      </div>

      <div className={styles.control}>
        <div className={styles.title_page}>Succession Planning</div>
      </div>

      <div className={styles.control}>
        <div className="mt-5">
          <div className="row">
            <div className="col-6">
              <SearchPositionSp
                placeholder="Search Position Name/ Position Code/ Current Incumbent Name"
                setLoading={setLoading}
                setSearchPosition={setSearchPosition}
                setPositionList={setPositionList}
                data-testid="input-search"
                businessUnit={listSelect}
                approvedDate={approvedDate}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                limit={ROWS_LIMIT}
              />
            </div>

            <div
              className="col-6 d-flex justify-content-end align-items-center"
              style={{ gap: '18px' }}
            >
              <CheckBoxSP
                options={optionDropdown.roleLevel}
                selected={searchParams.roleLevel}
                setSelected={(selection) => {
                  setPositionList({ data: [], total: 0 });
                  setSearchParams((prev) => ({ ...prev, page: 1, roleLevel: selection }));
                }}
                element={
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: '#FBFDFF',
                      borderRadius: '6px',
                      padding: '7px 18px',
                      width: '150px',
                      cursor: 'pointer',
                      border: '1px solid #E9EDF0',
                      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.04)',
                    }}
                  >
                    <TitleDropdown>Role Level</TitleDropdown>
                    <span className="icon-down">
                      <img src={shage} alt="" />
                    </span>
                  </div>
                }
              />
              <CheckBoxSP
                options={optionDropdown.business}
                selected={searchParams.business}
                setSelected={(selection) => {
                  setPositionList({ data: [], total: 0 });
                  setSearchParams((prev) => ({ ...prev, page: 1, business: selection }));
                }}
                element={
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: '#FBFDFF',
                      borderRadius: '6px',
                      padding: '7px 18px',
                      width: '160px',
                      cursor: 'pointer',
                      border: '1px solid #E9EDF0',
                      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.04)',
                    }}
                  >
                    <TitleDropdown>Business Filter</TitleDropdown>
                    <span className="icon-down">
                      <img src={shage} alt="" />
                    </span>
                  </div>
                }
              />

              <div className={styles.right}>
                <div className={styles.dropdown} ref={ref}>
                  <div
                    className={styles.dropdownBtn}
                    onKeyDown={() => {}}
                    onClick={(e) => setIsActive(!isActive)}
                  >
                    <span className="icon-calendar">
                      <img src={calendar} alt="" height="16" width="16" />
                    </span>

                    {selectedDate
                      ? moment(selectedDate).format('DD MMMM, YYYY')
                      : moment(selectedDate).format('[Today]')}

                    <span className="icon-down">
                      <img src={shage} alt="" />
                    </span>
                  </div>

                  <div className={styles.dropdownContent}>
                    <Wrapper style={{ position: 'relative' }} id="AiDatePicker">
                      {isActive && (
                        <DatePicker
                          open
                          showToday={false}
                          superNextIcon
                          superPrevIcon
                          className={styles.dropdownContentDatePicker}
                          onChange={(e) => handleChangeDate(e)}
                          getPopupContainer={() => document.getElementById('AiDatePicker')}
                          renderExtraFooter={() => {
                            return (
                              <div className={styles.dropdownBtnCalendar}>
                                <button
                                  className={styles.btnCancel}
                                  onKeyDown={() => {}}
                                  onClick={() => {
                                    setIsActive(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className={styles.btnApply}
                                  onKeyDown={() => {}}
                                  onClick={() => {
                                    setIsActive(false);
                                    setApprovedDate(selectedDate);
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            );
                          }}
                        />
                      )}
                    </Wrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className={styles.control}>
          <div className={styles.table}>
            <div className={styles.table_header}>
              <div style={{ width: STYLE_WIDTH_COLUMN[0] }}>Position Name</div>
              <div style={{ width: STYLE_WIDTH_COLUMN[1] }}>Current Incumbent</div>
              <div style={{ width: STYLE_WIDTH_COLUMN[2] }}>Department</div>
              <div style={{ width: STYLE_WIDTH_COLUMN[3] }}>Division</div>
              <div style={{ width: STYLE_WIDTH_COLUMN[4] }}>
                <FilterMultipleSP
                  listSelectType={listSelect}
                  setListSelectType={setListSelect}
                  filtersOption={businessUnit}
                  setSearchParams={setSearchParams}
                />
              </div>
              <div style={{ width: STYLE_WIDTH_COLUMN[5] }}>Platform</div>
              <div style={{ width: STYLE_WIDTH_COLUMN[6] }}>Date</div>
              <div style={{ width: STYLE_WIDTH_COLUMN[7] }} />
            </div>

            {handleShowPositions()}
          </div>
          {!isEmpty(positionList.data) && (
            <Pagination className={styles.pagination} {...pagination} />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SuccessionPlanningView;
