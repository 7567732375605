/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { editNote, ellipsis, redClose } from '../../../../../assets/img';
import { adminApi } from '../../../../../services/admin';
import {
  ACCESS_LEVEL,
  OPU_NAME_EXCEPTION,
  PERMISSION,
  USER_ACCESS_LEVEL,
  USER_ID,
} from '../../../../../utils/constants';
import { mapRoleInUserAccess } from '../../../../../utils/helper';
import { DropdownUserAccess } from '../DropdownUserAccess/DropdownUserAccess';
import styles from './role-table.module.scss';

const AccessLevel = (props) => {
  const {
    accessList,
    setAccessList,
    businessList,
    setBusinessList,
    setAccessUpdate,
    accessLevelOption,
  } = props;
  const updateAccessLevel = (value, index) => {
    setAccessList((prev) => {
      const accessLevelList = [...prev];
      accessLevelList[index] = value;
      return accessLevelList;
    });
    setAccessUpdate({ index, value });
  };

  const onDeleteAccess = (index) => {
    const cloneAccessList = [...accessList];
    cloneAccessList.splice(index, 1);
    setAccessList(cloneAccessList);

    const businessAccessList = [...businessList];
    businessAccessList.splice(index, 1);
    setBusinessList(businessAccessList);
  };
  const listItems = accessList?.map((item, index) => (
    <li
      key={`access-list-${item?.id}-${index}`}
      className="mb-2 d-flex justify-content-start"
      style={{ gap: '10px' }}
    >
      <DropdownUserAccess
        selected={item}
        setSelected={(e) => updateAccessLevel(e, index)}
        options={item.isDelete ? accessLevelOption : []}
      />

      {accessList?.length > 1 && (
        <>
          {item.isDelete && (
            <div
              className={styles.btn_remove}
              onKeyDown={() => {}}
              onClick={() => onDeleteAccess(index)}
            >
              <img src={redClose} alt="remove access level" />
            </div>
          )}
        </>
      )}
    </li>
  ));
  return <ul className={styles.menu_ul}>{listItems}</ul>;
};

const BusinessAccess = (props) => {
  const {
    accessList,
    setAccessList,
    businessList,
    setBusinessList,
    getBusinessOptions,
    accessUpdate,
    getBusinessDefault,
  } = props;
  const updateBusinessAccess = (value, index) => {
    setBusinessList((prev) => {
      const businessAccessList = [...prev];
      businessAccessList[index] = value;
      return businessAccessList;
    });
  };

  const onDeleteBusinessAccess = (index) => {
    const businessAccessList = [...businessList];
    businessAccessList.splice(index, 1);
    setBusinessList(businessAccessList);

    const cloneAccessList = [...accessList];
    cloneAccessList.splice(index, 1);
    setAccessList(cloneAccessList);
  };

  useEffect(() => {
    if (!accessUpdate.value) return;
    setBusinessList((prev) => {
      const businessAccessList = [...prev];
      businessAccessList[accessUpdate.index] = getBusinessDefault(accessUpdate.value);
      return businessAccessList;
    });
  }, [accessUpdate.value]);

  const listItems = businessList?.map((item, index) => (
    <li
      key={`business-list-${index}`}
      className="mb-2 d-flex justify-content-start"
      style={{ gap: '10px' }}
    >
      <DropdownUserAccess
        selected={item?.name || '-'}
        setSelected={(e) => updateBusinessAccess(e, index)}
        options={getBusinessOptions(accessList[index])}
      />

      {businessList?.length > 1 && (
        <>
          {item.isDelete && (
            <div
              className={styles.btn_remove}
              onKeyDown={() => {}}
              onClick={() => onDeleteBusinessAccess(index)}
            >
              <img src={redClose} alt="remove access level" />
            </div>
          )}
        </>
      )}
    </li>
  ));
  return <ul className={styles.menu_ul}>{listItems}</ul>;
};

const Roles = (props) => {
  const {
    item,
    index,
    length,
    isSelected,
    handleSelected,
    getBusinessOptions,
    getBusinessDefault,
    changeRole,
    rolesEdit,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  const roleCurrent = rolesEdit.find((d) => d.id === item.id);
  const roleAccessLevelDefault = mapRoleInUserAccess(item?.accessLevels);
  const accessLevelExistList = roleAccessLevelDefault.map((a) => a.accessLevel);
  const accessLevelOption = ACCESS_LEVEL.filter(
    (item) => accessLevelExistList.indexOf(item.key) === -1
  );
  const [accessList, setAccessList] = useState(() => {
    if (!isEmpty(roleCurrent) && !isEmpty(roleCurrent.userAccessLevels)) {
      return roleCurrent.userAccessLevels
        .filter((role) => role.accessLevel !== USER_ACCESS_LEVEL.OWN_JOB_FAMILY)
        .map((item) => {
          return ACCESS_LEVEL.find((d) => d.key === item.accessLevel);
        });
    }

    if (!isEmpty(roleAccessLevelDefault)) {
      return roleAccessLevelDefault.map((item) => {
        return { ...ACCESS_LEVEL.find((d) => d.key === item.accessLevel), isDelete: false };
      });
    }
    return [ACCESS_LEVEL[0]];
  });
  const [businessList, setBusinessList] = useState(() => {
    if (!isEmpty(roleCurrent) && !isEmpty(roleCurrent.userAccessLevels)) {
      return roleCurrent.userAccessLevels
        .filter((role) => role.accessLevel !== USER_ACCESS_LEVEL.OWN_JOB_FAMILY)
        .map((item) => {
          return getBusinessDefault(
            ACCESS_LEVEL.find((d) => d.key === item.accessLevel),
            item.businessAccess
          );
        });
    }

    if (!isEmpty(roleAccessLevelDefault)) {
      return roleAccessLevelDefault.map((item) => {
        return {
          ...getBusinessDefault(ACCESS_LEVEL.find((d) => d.key === item.accessLevel)),
          isDelete: false,
          from: item?.from || null,
        };
      });
    }

    return [getBusinessDefault(ACCESS_LEVEL[0])];
  });
  const [accessUpdate, setAccessUpdate] = useState({ index: -1, value: '' });

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  useEffect(() => {
    changeRole(isSelected, item, accessList, businessList);
  }, [isSelected, businessList, accessList]);

  return (
    <tr className={isSelected ? `${styles.table_body} ${styles.isSelected}` : styles.table_body}>
      <td className="d-flex justify-content-start align-items-center" style={{ width: '10%' }}>
        <input
          id={`role_${index}`}
          type="checkbox"
          onChange={() => handleSelected(item.id)}
          value={isSelected}
          checked={isSelected}
        />
      </td>
      <td className={styles.nameBody} style={{ width: '30%' }}>
        {item?.fullName}
      </td>
      <td className="d-flex justify-content-center" style={{ width: '30%' }}>
        <AccessLevel
          accessList={accessList}
          setAccessList={setAccessList}
          businessList={businessList}
          setBusinessList={setBusinessList}
          setAccessUpdate={setAccessUpdate}
          accessLevelOption={accessLevelOption}
        />
      </td>
      <td className={styles.nameBody} style={{ width: '20%' }}>
        <BusinessAccess
          accessList={accessList}
          setAccessList={setAccessList}
          businessList={businessList}
          setBusinessList={setBusinessList}
          getBusinessOptions={getBusinessOptions}
          accessUpdate={accessUpdate}
          getBusinessDefault={getBusinessDefault}
        />
      </td>
      <td style={{ width: '10%', position: 'relative' }} ref={ref}>
        <img
          className={styles.ellipsis}
          src={ellipsis}
          alt="ellipsis"
          onKeyDown={() => {}}
          onClick={() => setIsActive(!isActive)}
        />

        {isActive && (
          <div
            className={
              index + 1 === length
                ? `${styles.dropdownContent} ${styles.lastItem}`
                : styles.dropdownContent
            }
          >
            <div
              className={styles.function}
              onKeyDown={() => {}}
              onClick={() => {
                setIsActive(false);
                setAccessList((prev) => [...prev, accessLevelOption[0]]);
                setBusinessList((prev) => [...prev, getBusinessDefault(accessLevelOption[0])]);
              }}
            >
              <img src={editNote} className={`mr-2 ${styles.editIcon}`} alt="editNote" />
              <div className={styles.content_edit}>Add Access</div>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export function RoleTable(props) {
  const { userInfo, selected, setSelected, changeRole, rolesEdit } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const [roleList, setRoleList] = useState([]);
  const [opuDivisionList, setOpuDivisionList] = useState([]);
  const [jobFamilyList, setJobFamilyList] = useState([]);
  const [businessUnitList, setBusinessUnitList] = useState([]);

  useEffect(() => {
    if (!roleActive.roleId) return;
    async function fetchApi() {
      const roles = {
        role: roleActive,
        id: USER_ID,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      };

      await Promise.allSettled([
        adminApi.getAllRoleForCreateUser(roles),
        adminApi.getAllOpuAndDivsion(roles),
        adminApi.getJobFamilies(roles),
        adminApi.getBusinessUnit(roles),
      ])
        .then((results) => {
          setRoleList(results[0].status === 'fulfilled' ? results[0].value.data.result : []);
          setOpuDivisionList(results[1].status === 'fulfilled' ? results[1].value.data.result : []);
          setJobFamilyList(results[3].status === 'fulfilled' ? results[3].value.data.result : []);
          setBusinessUnitList(
            results[3].status === 'fulfilled' ? results[3].value.data.result : []
          );
        })
        .catch((error) => {
          console.error(Promise.resolve(error));
        });
    }

    fetchApi();
  }, [roleActive.roleId]);

  const getBusinessDefault = (accessLevel, businessAccessId) => {
    let businessValue;
    switch (accessLevel.name) {
      case 'Own Business':
        businessValue = { name: userInfo.businessUnit, isDelete: true };
        break;
      case 'Own OPU/Division':
        businessValue =
          userInfo?.company !== OPU_NAME_EXCEPTION
            ? { name: `${userInfo.company || userInfo.division || '-'}`, isDelete: true }
            : {
                name: `${userInfo?.division || '-'}`,
                isDelete: true,
              };
        break;
      default:
        let business = {};
        const businessList = getBusinessOptions(accessLevel);
        if (businessAccessId) {
          business = businessList.find((item) => item.id === businessAccessId);
        } else {
          business = businessList[0];
        }
        businessValue = {
          id: business?.id,
          name: business?.name || business?.businessUnit || `${business?.opu_division}`,
          type: business?.type,
          isDelete: true,
          from: business?.from || null,
        };
    }
    return businessValue;
  };
  const getBusinessOptions = (accessLevel) => {
    if (!accessLevel) return [];
    let list = [];
    switch (accessLevel.name) {
      case 'Other Business':
        list = [...businessUnitList];
        break;
      case 'Other OPU/Division':
        list = [...opuDivisionList];
        break;
      case 'Other Job Family':
        list = [...jobFamilyList];
        break;
      default:
    }
    return list;
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roleList.map((row) => {
        return row.id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelected = (_id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr className={styles.table_header}>
            <td
              className="d-flex justify-content-start align-items-center"
              style={{ width: '10%' }}
            >
              <input
                type="checkbox"
                onChange={(e) => handleSelectAllClick(e)}
                value={
                  selected.length > 0 && roleList.length > 0 && selected.length === roleList.length
                }
                checked={
                  selected.length > 0 && roleList.length > 0 && selected.length === roleList.length
                }
              />
            </td>
            <td className={styles.nameHeader} style={{ width: '30%' }}>
              Role
            </td>
            <td style={{ width: '30%' }}>Access Level</td>
            <td className={styles.nameHeader} style={{ width: '30%' }}>
              Business Access
            </td>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(roleList) &&
          !isEmpty(businessUnitList) &&
          !isEmpty(opuDivisionList) &&
          !isEmpty(jobFamilyList) ? (
            roleList.map((item, index) => {
              const isSelected = selected.indexOf(item.id) !== -1;
              return (
                <Roles
                  key={item?.id}
                  item={item}
                  index={index}
                  length={roleList.length}
                  isSelected={isSelected}
                  handleSelected={handleSelected}
                  getBusinessOptions={getBusinessOptions}
                  getBusinessDefault={getBusinessDefault}
                  changeRole={changeRole}
                  rolesEdit={rolesEdit}
                />
              );
            })
          ) : (
            <>
              <tr>
                <td colSpan={5}>
                  <div align="center">No data</div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
