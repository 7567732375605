import styled from 'styled-components';
import { Skeleton } from 'antd';

export const AiSkeleton = styled(Skeleton)`
  color: red;
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-top: 0;
    }
    .ant-skeleton-paragraph {
      margin-top: 0;
    }
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 0 !important;
  }
  /* .ant-skeleton-title {
    margin-top: 0;
  }
  .ant-skeleton-paragraph {
    margin-top: 0;
  }
  margin-bottom: 0;
  margin-top: 0; */
`;
