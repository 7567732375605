import { cloneDeep, flattenDeep, isArray, isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  SELECT_ALL_LABEL,
  TALENT_FILTER_OPTIONS,
  TALENT_FILTERS,
} from '../../../../utils/constants';
import DropdownFilterTalentBuilder from '../PopoutAddTalentBuilder/components/FinderOption/DropdownFilterTalentBuilder/DropdownFilterTalentBuilder';

let tempFilter = {
  top_talent_status: [],
  business: [],
  salary_grade: [],
  other_experience: [],
  personality: [],
  age: [],
  business_experience: [],
  years_in_position: [],
  nationality: [],
  state_of_birth: [],
  years_to_retire: [],
  gender: [],
  functional_experience: [],
  edge: [],
  successor: [],
  attr27: [],
  previous_business_experience: [],
  previous_functional_experience: [],
};

let filterTabActive = [];
let preTalentFilterOptions = {};

const TalentFinderFilter = (props) => {
  const { reportId } = useParams();
  const {
    filterOptions,
    setFilterFinderData,
    applyFinderFilterColumn,
    deleteColumn,
    isDeleteFinderColumn,
    setIsDeleteFinderColumn,
  } = props;
  const [talentFilterOptions, setTalentFilterOptions] = useState(filterOptions || {});
  const [isBlur, setIsBlur] = useState(false);

  // Reset filter tab active
  useEffect(() => {
    filterTabActive = [];
  }, [reportId]);

  const cloneState = (state) => {
    return JSON.parse(JSON.stringify(state));
  };

  const updateStateData = (filterName, options, newData) => {
    tempFilter[filterName] = options;
    setTalentFilterOptions(newData);
  };

  const getAllKeys = (tree) => {
    let result = [];
    tree.forEach((item) => {
      let childKeys = [];
      if (item?.children) {
        childKeys = getAllKeys(item.children);
      }
      result.push(...[{ label: item?.label, checked: item?.checked }, ...childKeys]);
    });

    return result;
  };

  const onChange = (currentNode) => {
    const filterName = currentNode.path.split('.')[0];
    let indexArr = getIndexList(currentNode.path);
    let newData = cloneState(talentFilterOptions);
    let options = newData[filterName];
    let selectedOption;
    const optionParentLv2 = options[indexArr[0]];

    const handleCheckedParent = (parentOption) => {
      const optionsUnChecked = parentOption.children.filter((optionLv2) => {
        return optionLv2.checked === false;
      });

      if (optionsUnChecked.length > 0) {
        parentOption.checked = false;
      } else {
        parentOption.checked = true;
      }
    };

    switch (indexArr.length) {
      case 1: //onChange lv1 of dropdown select tree
        selectedOption = options[indexArr[0]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        options.forEach((option) => {
          if (option.label !== currentNode.label) {
            option.expanded = false;

            if (option.children?.length) {
              option.children.forEach((childItem) => {
                childItem.expanded = false;
              });
            }
          }
        });

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = childOption?.disabled ? true : currentNode.checked;
            childOption.expanded = true;

            if (childOption.children?.length) {
              childOption.children.forEach((i) => {
                i.checked = i?.disabled ? true : currentNode.checked;
              });
            }
          });
        }
        break;
      case 2: //onChange lv2 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = childOption?.disabled ? true : currentNode.checked;
          });
        }

        handleCheckedParent(optionParentLv2);
        break;
      case 3: //onChange lv3 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]].children[indexArr[2]];
        selectedOption.checked = selectedOption?.disabled ? true : currentNode.checked;
        const optionParentLv3 = options[indexArr[0]]?.children[indexArr[1]];
        handleCheckedParent(optionParentLv3);
        handleCheckedParent(optionParentLv2);
        break;
      default:
    }

    //handle checked (Select All)
    if (options[0]?.label === SELECT_ALL_LABEL) {
      const allKeys = getAllKeys(options);
      const tempUncheckedItem = allKeys.filter(
        (item) => !item.checked && item.label !== SELECT_ALL_LABEL
      );
      if (tempUncheckedItem.length > 0) {
        options[0].checked = false;
      } else {
        options[0].checked = true;
      }
    }

    updateStateData(filterName, options, newData);
  };

  const onClickSelectAll = (filterName, isChecked) => {
    let newData = cloneState(talentFilterOptions);
    assignObjectPaths(newData[filterName], filterName, isChecked);
    updateStateData(filterName, newData[filterName], newData);
  };

  const onNodeToggle = (currentNode) => {
    const filterName = currentNode.path.split('.')[0];
    let indexArr = getIndexList(currentNode.path);
    const newData = cloneState(talentFilterOptions);
    const options = newData[filterName];
    let currentOption;

    const handleExpandChild = (option, isExpanded) => {
      if (option.children?.length) {
        option.children.forEach((childItem) => {
          childItem.expanded = isExpanded;
        });
      }
    };

    switch (indexArr.length) {
      case 1: //Expand level 1
        currentOption = options[indexArr[0]];

        if (currentNode.expanded) {
          currentOption.expanded = true;

          options.forEach((option) => {
            if (option.label !== currentNode.label) {
              option.expanded = false;
            }
            handleExpandChild(option, false);
          });

          handleExpandChild(currentOption, true);
        } else {
          currentOption.expanded = false;
          handleExpandChild(currentOption, false);
        }
        break;
      case 2: //Expand level 2
        currentOption = options[indexArr[0]].children[indexArr[1]];

        if (!currentNode.expanded) {
          currentOption.expanded = false;
        } else {
          currentOption.expanded = true;
        }
        break;
      default:
    }

    updateStateData(filterName, options, newData);
  };

  const getIndexList = (path) => {
    if (!path.includes('.')) return [];

    let tempArr = path.match(/\.\d+/g);
    tempArr =
      size(tempArr) > 0
        ? tempArr.map((item) => {
            return `${item.replace('.', '')}`;
          })
        : [];
    return tempArr;
  };

  const assignObjectPaths = (obj, stack, isChecked) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === 'object') {
        node.path = stack ? `${stack}.${k}` : k;
        node.checked = isChecked === undefined ? node.checked : node.disabled ? true : isChecked;
        assignObjectPaths(node, node.path, isChecked);
      }
    });
  };

  const assignObjectPathsToDisable = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === 'object') {
        if (node?.label) {
          node.disabled = node.checked;
        }
        assignObjectPathsToDisable(node, node.path);
      }
    });
  };

  const assignObjectPathsToRemoveColumn = (obj, record) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === 'object') {
        if (
          record?.name &&
          ((isArray(record?.subType)
            ? record?.subType.includes(node.label)
            : record?.subType === node.label) ||
            record?.name === node.label)
        ) {
          node.checked = false;
          node.disabled = false;
          if (node?.children) {
            node.children = updateValueForChildElem(node.children);
            node.children.forEach((item) => {
              if (item?.children) {
                item.children = updateValueForChildElem(item.children);
              }
            });
          }
        }
        assignObjectPathsToRemoveColumn(node, record);
      }
    });
  };

  const updateValueForChildElem = (data) => {
    let tempData = [];
    data.forEach((item) => {
      item.checked = false;
      item.disabled = false;
      tempData.push(item);
    });
    return tempData;
  };

  const flattenTreeData = (data) => {
    let tempData = [];
    data.forEach((item) => {
      if (Array.isArray(item?.children)) {
        tempData.push(...flattenDeep(item.children));
        item.children.forEach((child) => {
          if (Array.isArray(child?.children)) {
            tempData.push(...flattenDeep(child.children));
          }
        });
      } else {
        tempData = Array.isArray(item) ? tempData.push(...flattenDeep(item)) : [...tempData, item];
      }
    });
    return tempData;
  };

  useEffect(() => {
    if (!isEmpty(filterOptions)) {
      assignObjectPaths(filterOptions);
      setTalentFilterOptions({ ...filterOptions });
      preTalentFilterOptions = { ...filterOptions };

      Object.keys(filterOptions).forEach((key, value) => {
        const tempArr = flattenTreeData(filterOptions[key]);
        const hasItemChecked = tempArr.filter((item) => item.checked).length > 0;
        if (hasItemChecked && !filterTabActive.includes(key)) {
          filterTabActive.push(key);
        }
        if (!hasItemChecked) {
          filterTabActive = filterTabActive.filter((item) => item !== key);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  // Handle delete column
  useEffect(() => {
    if (isDeleteFinderColumn) {
      let cloneData = cloneDeep(talentFilterOptions);
      assignObjectPathsToRemoveColumn(cloneData, deleteColumn);

      const tempData = flattenTreeData(cloneData[deleteColumn?.type]);
      const hasItemChecked =
        tempData.filter((item) => item.checked && item.label !== SELECT_ALL_LABEL).length > 0;
      if (deleteColumn?.type === TALENT_FILTER_OPTIONS.SUCCESSOR && !hasItemChecked) {
        assignObjectPathsToRemoveColumn(cloneData, {
          is_manual: false,
          name: 'Successor',
          type: TALENT_FILTER_OPTIONS.SUCCESSOR,
          subType: SELECT_ALL_LABEL,
        });
      }

      if (hasItemChecked && !filterTabActive.includes(deleteColumn.type)) {
        filterTabActive.push(deleteColumn.type);
      }
      if (!hasItemChecked) {
        filterTabActive = filterTabActive.filter((item) => item !== deleteColumn.type);
      }
      preTalentFilterOptions = cloneData;
      setTalentFilterOptions(cloneData);
      setIsDeleteFinderColumn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteFinderColumn]);

  const handleClickApplyFilter = (item, key) => {
    if (size(tempFilter[key]) <= 0) {
      tempFilter[key] = item;
    }

    if (!filterTabActive.includes(key)) {
      filterTabActive.push(key);
    }

    const dataToPost = getDataToPost();
    setFilterFinderData(dataToPost);
    applyFinderFilterColumn(dataToPost);

    let cloneData = cloneState(talentFilterOptions);
    assignObjectPathsToDisable(cloneData);
    preTalentFilterOptions = cloneData;
    setTalentFilterOptions(cloneData);
    tempFilter[key] = cloneData[key];
  };

  const getDataToPost = () => {
    const top_talent_status = handleGetFirstLevel(TALENT_FILTER_OPTIONS.TOP_TALENT_STATUS);
    const salary_grade = handleGetFirstLevel(TALENT_FILTER_OPTIONS.SALARY_GRADE);
    const successor = !isEmpty(tempFilter[TALENT_FILTER_OPTIONS.SUCCESSOR])
      ? tempFilter[TALENT_FILTER_OPTIONS.SUCCESSOR]
      : null;
    const personality = !isEmpty(tempFilter[TALENT_FILTER_OPTIONS.PERSONALITY])
      ? tempFilter[TALENT_FILTER_OPTIONS.PERSONALITY]
      : null;
    const age = handleGetFirstLevel(TALENT_FILTER_OPTIONS.AGE);
    const attr27 = !isEmpty(tempFilter[TALENT_FILTER_OPTIONS.ATTRIBUTES])
      ? tempFilter[TALENT_FILTER_OPTIONS.ATTRIBUTES]
      : null;
    const years_in_position = handleGetFirstLevel(TALENT_FILTER_OPTIONS.YEARS_IN_POSITION);
    const nationality = handleGetFirstLevel(TALENT_FILTER_OPTIONS.NATIONALITY);
    const state_of_birth = handleGetFirstLevel(TALENT_FILTER_OPTIONS.STATE_OF_BIRTH);
    const years_to_retire = handleGetFirstLevel(TALENT_FILTER_OPTIONS.YEARS_TO_RETIRE);
    const gender = handleGetFirstLevel(TALENT_FILTER_OPTIONS.GENDER);
    const edge = !isEmpty(tempFilter[TALENT_FILTER_OPTIONS.EDGE])
      ? tempFilter[TALENT_FILTER_OPTIONS.EDGE]
      : null;
    const other_experience = !isEmpty(tempFilter[TALENT_FILTER_OPTIONS.OTHER_EXPERIENCE])
      ? tempFilter[TALENT_FILTER_OPTIONS.OTHER_EXPERIENCE]
      : null;
    const previous_business_experience = !isEmpty(
      tempFilter[TALENT_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE]
    )
      ? tempFilter[TALENT_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE]
      : null;
    const business_experience = handleGetFirstLevel(TALENT_FILTER_OPTIONS.BUSINESS_EXPERIENCE);
    const previous_functional_experience = !isEmpty(
      tempFilter[TALENT_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE]
    )
      ? tempFilter[TALENT_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE]
      : null;
    const functional_experience = handleGetFirstLevel(TALENT_FILTER_OPTIONS.FUNCTIONAL_EXPERIENCE);

    return {
      top_talent_status,
      salary_grade,
      successor,
      personality,
      age,
      attr27,
      years_in_position,
      years_to_retire,
      nationality,
      state_of_birth,
      gender,
      edge,
      business_experience,
      previous_business_experience,
      other_experience,
      functional_experience,
      previous_functional_experience,
    };
  };

  const handleGetFirstLevel = (name) => {
    if (!isEmpty(tempFilter[name])) {
      return {
        label: tempFilter[name][0]?.label,
        checked: tempFilter[name][0]?.checked,
        disabled: tempFilter[name][0]?.disabled,
      };
    } else {
      return null;
    }
  };

  const onBlur = (item, key) => {
    talentFilterOptions[key] = item;
    setTalentFilterOptions(preTalentFilterOptions);
    setIsBlur(true);
  };

  const handleReturnNumberColumns = (key) => {
    if (
      [
        TALENT_FILTER_OPTIONS.BUSINESS_EXPERIENCE,
        TALENT_FILTER_OPTIONS.PREVIOUS_BUSINESS_EXPERIENCE,
        TALENT_FILTER_OPTIONS.FUNCTIONAL_EXPERIENCE,
        TALENT_FILTER_OPTIONS.PREVIOUS_FUNCTIONAL_EXPERIENCE,
        TALENT_FILTER_OPTIONS.OTHER_EXPERIENCE,
      ].includes(key)
    ) {
      return 4;
    } else {
      return 2;
    }
  };

  return (
    <div className={'filter_section row px-0'}>
      {!isEmpty(talentFilterOptions) &&
        TALENT_FILTERS.map((item) =>
          Object.keys(talentFilterOptions).map((key) => {
            if (item.value === key) {
              return (
                <DropdownFilterTalentBuilder
                  key={`${key}`}
                  keyWord={key}
                  filterTabActive={filterTabActive}
                  label={item.label}
                  placeholder={item.label}
                  data={talentFilterOptions[key]}
                  onChange={onChange}
                  onNodeToggle={onNodeToggle}
                  onBlur={() => onBlur(talentFilterOptions[key], key)}
                  onClickSelectAll={(isChecked) => onClickSelectAll(key, isChecked)}
                  isBlur={isBlur}
                  onApply={() => handleClickApplyFilter(talentFilterOptions[key], key)}
                  filterName={key}
                  columns={handleReturnNumberColumns(key)}
                />
              );
            } else {
              return <></>;
            }
          })
        )}
    </div>
  );
};

export default TalentFinderFilter;
