import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { debounce, get, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { dropdown_icon } from '../../../../../assets/img';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { DATE_TIME_FORMAT, MESSAGE_TYPES } from '../../../../../utils/constants';
import { history } from '../../../../../utils/history';
import SearchBoxCustom from '../../../components/SearchBoxCustom/SearchBoxCustom';
import { ASSESSMENT_OPTIONS } from '../../constant';
import styles from './create-or-edit-top-talent-status.module.scss';

const { Option } = Select;

const initEmptyValue = {
  top_talent_status: undefined,
  from_date: undefined,
  to_date: undefined,
  assessment_type: undefined,
  assessment_date: undefined,
  approval_platform: undefined,
  assessment_result: undefined,
  cluster: undefined,
};

export default function CreateOrEditTopTalentStatus({ mode = 'create' }) {
  const [form] = Form.useForm();
  const userInfos = useSelector((state) => state.user);
  const roleActive = useSelector((state) => state.user.roleActive);
  const dispatch = useDispatch();

  const { topTalentStatusId, assessmentId } = useParams();
  const [staffName, setStaffName] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [searchList, setSearchList] = useState([]);
  const [category, setCategory] = useState([]);
  const [cluster, setCluster] = useState([]);
  const [isSubmitted, setIsSubmited] = useState(false);
  const staffIdWatch = Form.useWatch('staffId', form);
  const debounceLoadStaffName = useCallback(debounce(getStaffName, 500), []);

  useEffect(() => {
    if (mode !== 'create' || !staffIdWatch) return;
    const roles = {
      role: roleActive,
    };
    debounceLoadStaffName({ staffIdWatch, roles });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, staffIdWatch, roleActive]);

  useEffect(() => {
    if (searchTerm.type === 'submit') {
      setStaffName(searchTerm.value.birth_name);
    }
  }, [searchTerm.type, searchTerm.value.staff_id, searchTerm.value.birth_name]);

  useEffect(() => {
    if (mode === 'edit' && topTalentStatusId) {
      const fetchDetail = async () => {
        try {
          const res = await adminApi.getTopTalentStatusDetails(topTalentStatusId);
          if (res.status === 200) {
            const values = res.data.result[0];
            const { from_date, to_date, assessment_date, type, birth_name, staff_id } = values;
            form.setFieldsValue({
              ...values,
              assessment_type: type,
              from_date: from_date ? moment(moment(from_date), DATE_TIME_FORMAT.DATE_SPACE) : null,
              to_date: to_date ? moment(moment(to_date), DATE_TIME_FORMAT.DATE_SPACE) : null,
              assessment_date: assessment_date
                ? moment(moment(assessment_date), DATE_TIME_FORMAT.DATE_SPACE)
                : null,
            });
            setStaffName(birth_name);
            setSearchTerm((prevState) => ({
              ...prevState,
              showTerm: staff_id,
              value: { staff_id },
            }));
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchDetail();
    }
  }, [form, mode, topTalentStatusId]);

  useEffect(() => {
    (async function getOptionsTopTalentStatus() {
      try {
        const res = await adminApi.getOptionsTopTalentStatus(roleActive);
        const category = get(res, 'data.result.top_talent_status_categories');
        const cluster = get(res, 'data.result.clusters');
        setCategory(map(category, 'category'));
        setCluster(map(cluster, 'cluster'));
      } catch (error) {
        setCategory([]);
        setCluster([]);
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getStaffName({ staffIdWatch, roles }) {
    try {
      const result = await adminApi.getStaffNameTopTalentStatus(staffIdWatch, roles);
      const staff_name = result?.data?.result?.staff_name;
      form.setFieldValue('staffName', staff_name);
      form.validateFields(['staffName']);
    } catch (error) {
      form.setFieldValue('staffName', undefined);
    }
  }

  const onSubmit = () => {
    setIsSubmited(true);
    form
      .validateFields()
      .then((res) => {
        form.submit();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message:
              'There are items that require your attention. Please fill in the required field(s)',
          })
        );
      });
  };
  const onFinish = (values) => {
    const body = {
      staff_id: searchTerm.value.staff_id,
      ...values,
      to_date: moment(values.to_date).format('YYYY-MM-DD'),
      from_date: moment(values.from_date).format('YYYY-MM-DD'),
      assessment_date: moment(values.assessment_date).format('YYYY-MM-DD'),
    };

    if (mode === 'edit') {
      (async function updateTopTalentStatus() {
        try {
          await adminApi.updateTopTalentStatus(
            topTalentStatusId,
            assessmentId,
            body,
            userInfos,
            roleActive
          );
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: `Top Talent Status successfully edited`,
            })
          );
          history.push('/admin/profile-management/top-talent-status');
        } catch (error) {
          console.error(error);
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.ERROR,
              message: get(error, 'response.data.errors'),
            })
          );
        }
      })();
      return;
    }

    (async function addTopTalentStatus() {
      try {
        await adminApi.addTopTalentStatus(body, roleActive);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `Top Talent Status successfully added`,
          })
        );
        history.push('/admin/profile-management/top-talent-status');
      } catch (error) {
        console.error(error);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: get(error, 'response.data.errors'),
          })
        );
      }
    })();
  };

  const onCancel = () => {
    history.push('/admin/profile-management/top-talent-status');
  };

  return (
    <div className={styles.main}>
      <p className={styles.title}>Details</p>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          initialValues={mode === 'create' ? initEmptyValue : { topTalentStatusId }}
          autoComplete="off"
        >
          <Row gutter={16} className="mb-4">
            <Col span={12} className={styles.customize}>
              <label style={{ margin: '5px 0' }}>
                Staff ID<span style={{ color: 'red', fontSize: '16px' }}>*</span>
              </label>
              <SearchBoxCustom
                isReadOnly={mode === 'edit' || topTalentStatusId}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchList={searchList}
                setSearchList={setSearchList}
                fieldName={'staff_id_top_talent_status'}
                searchPlaceholder={'Enter Staff ID'}
                isSubmitted={isSubmitted}
              />
            </Col>
            <Col span={12} className={styles.customize}>
              <label style={{ margin: '5px 0' }}>Staff Name</label>
              <input
                value={staffName || ''}
                className={styles.positionName}
                placeholder="Staff Name"
                readOnly
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    rules={[{ required: true, message: '' }]}
                    name="top_talent_status"
                    labelCol={{ span: 24 }}
                    label="Top Talent Status"
                  >
                    <Select
                      data-testid="top-talent-status-option"
                      placeholder="Select"
                      suffixIcon={<img src={dropdown_icon} alt="" />}
                    >
                      {category.map((item) => (
                        <Option key={item} value={item}>
                          <span data-testid={item}>{item}</span>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    name="from_date"
                    labelCol={{ span: 24 }}
                    label="From Date"
                    rules={[{ required: true, message: '' }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%', height: '42px', borderRadius: '4px' }}
                      format="DD MMM YYYY"
                      allowClear={false}
                      onChange={() => {
                        if (
                          !isEmpty(form.getFieldValue('to_date')) &&
                          !isEmpty(form.getFieldValue('from_date'))
                        )
                          form.validateFields(['to_date']);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    name="to_date"
                    labelCol={{ span: 24 }}
                    label="To Date"
                    rules={[
                      { required: true, message: '' },
                      {
                        message: 'Date from cannot be greater than date to.',
                        validator: (_, value) => {
                          if (!value) return Promise.resolve();
                          const validDate = moment(
                            moment(form.getFieldValue('from_date')).format('YYYY-MM-DD')
                          ).isSameOrBefore(
                            moment(moment(form.getFieldValue('to_date')).format('YYYY-MM-DD'))
                          );

                          if (validDate) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Date to cannot be greater than date from.');
                          }
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%', height: '42px', borderRadius: '4px' }}
                      format="DD MMM YYYY"
                      allowClear={false}
                      onChange={() => {
                        if (
                          !isEmpty(form.getFieldValue('to_date')) &&
                          !isEmpty(form.getFieldValue('from_date'))
                        )
                          form.validateFields(['to_date']);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    name="assessment_type"
                    labelCol={{ span: 24 }}
                    label="Assessment Type"
                    rules={[{ required: true, message: '' }]}
                  >
                    <Select
                      data-testid="assessment-type-option"
                      placeholder="Select"
                      suffixIcon={<img src={dropdown_icon} alt="" />}
                    >
                      {ASSESSMENT_OPTIONS.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    rules={[{ required: true, message: '' }]}
                    name="assessment_date"
                    labelCol={{ span: 24 }}
                    label="Assessment Date"
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%', height: '42px', borderRadius: '4px' }}
                      format="DD MMM YYYY"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    name="approval_platform"
                    labelCol={{ span: 24 }}
                    label="Approval Platform"
                    rules={[{ required: false, message: '' }]}
                  >
                    <Input
                      style={{ width: '100%', height: '42px', borderRadius: '4px' }}
                      placeholder="Enter Approval Platform"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    name="assessment_result"
                    labelCol={{ span: 24 }}
                    label="Assessment Result"
                    rules={[{ required: false, message: '' }]}
                  >
                    <Input
                      style={{ width: '100%', height: '42px', borderRadius: '4px' }}
                      placeholder="Enter Assessment Result"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.customize}>
                  <Form.Item
                    name="cluster"
                    labelCol={{ span: 24 }}
                    label="Cluster"
                    rules={[{ required: false, message: '' }]}
                  >
                    <Select
                      data-testid="cluster-option"
                      placeholder="Select"
                      suffixIcon={<img src={dropdown_icon} alt="" />}
                    >
                      {cluster.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      <div className={styles.grBtn}>
        <Row gutter={8}>
          <Col>
            <Button data-testid="cancel-btn" onClick={onCancel} className={styles.cancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              data-testid="submit-btn"
              onClick={onSubmit}
              htmlType="submit"
              className={styles.add}
            >
              {mode === 'create' ? 'Add' : 'Save'}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
