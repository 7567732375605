import styled from 'styled-components';

const TableAuditLog = styled.div`
  width: 100%;
  margin-top: 20px;

  .ant-table-thead {
    .ant-table-cell {
      color: #8896a7;

      &::before {
        content: none !important;
      }
    }
  }
  .ant-table-selection-column {
    border-left: 1px solid #d3dee8;

    .ant-checkbox-wrapper {
      display: none;
    }
  }
  .ant-pagination {
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  .ant-pagination-item-active {
    background: #00a19c;
    color: #fff;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: white;
  }
  .ant-table-thead > tr > th {
    background: white;
  }
  .ant-table-thead .ant-table-cell {
    color: #8896a7;
    font-size: 14px;
    font-weight: 700;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #d3dee8;
    border-top: 1px solid #d3dee8;
  }
  .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #d3dee8;
  }
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #d3dee8;
  }
  .ant-table-thead.ant-table-cell:last-child {
    border-right: 1px solid #d3dee8;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #d3dee8;
  }
  .ant-table-tbody .ant-table-cell {
    vertical-align: top;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pagination-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
`;

export { TableAuditLog };
