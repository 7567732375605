/**
 * @author Swapna Chidre
 * @desc Populates the Best-Fit Calibrations data
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import styles from './logout.module.scss';

const TestApi = () => {
  return (
    <Row className={`${styles.loginPage} h-100`}>
      <Col md={{ span: 6, offset: 6 }} style={{ paddingRight: 62 }}>
        <Row className="h-100" style={{ alignItems: 'center' }}>
          <Col>
            <div className="data-area-div">
              <h1>Logout success</h1>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default withRouter(TestApi);
