import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { AGENDA_STATUS, MEETING_DETAIL_STATUS } from '../utils/constants';

export default function useConditionalTalentReviewRemark(props) {
  const { meetingRole, meetingStatus, agendaStatus, staffTalentReview } = props;

  const [showBtnAllRemarks, setShowBtnAllRemarks] = useState(false);
  const [isCreateOrEditRemarks, setIsCreateOrEditRemarks] = useState(false);

  useEffect(() => {
    if (!(!isEmpty(meetingRole) && meetingStatus && agendaStatus && staffTalentReview.staff_id))
      return;

    if (agendaStatus === AGENDA_STATUS.PAPER_IN_PROGRESS) {
      if (
        [
          MEETING_DETAIL_STATUS.IN_PLANNING,
          MEETING_DETAIL_STATUS.PUBLISHED,
          MEETING_DETAIL_STATUS.IN_PROGRESS,
        ].includes(meetingStatus)
      ) {
        if (meetingRole.is_secretariat_or_facilitator) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(true);
        } else if (
          meetingRole.is_advocator ||
          meetingRole.is_commitee ||
          meetingRole.is_chairman ||
          meetingRole.is_hr_partners
        ) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(false);
        }
      }
      return;
    }
    if (agendaStatus === AGENDA_STATUS.PAPER_READY) {
      if (
        [
          MEETING_DETAIL_STATUS.IN_PLANNING,
          MEETING_DETAIL_STATUS.PUBLISHED,
          MEETING_DETAIL_STATUS.CONCLUDED,
        ].includes(meetingStatus)
      ) {
        if (
          meetingRole.is_secretariat_or_facilitator ||
          meetingRole.is_advocator ||
          meetingRole.is_commitee ||
          meetingRole.is_chairman ||
          meetingRole.is_hr_partners
        ) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(false);
        }
      }

      if ([MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingStatus)) {
        if (meetingRole.is_secretariat_or_facilitator) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(true);
        } else if (
          meetingRole.is_advocator ||
          meetingRole.is_commitee ||
          meetingRole.is_chairman ||
          meetingRole.is_hr_partners
        ) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(false);
        }
      }

      return;
    }

    if (agendaStatus === AGENDA_STATUS.PAPER_FINALIZED) {
      if ([MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingStatus)) {
        if (meetingRole.is_secretariat_or_facilitator) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(true);
        } else if (
          meetingRole.is_advocator ||
          meetingRole.is_commitee ||
          meetingRole.is_chairman ||
          meetingRole.is_hr_partners
        ) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(false);
        }
      }

      if (
        [MEETING_DETAIL_STATUS.IN_PROGRESS, MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingStatus)
      ) {
        if (
          meetingRole.is_secretariat_or_facilitator ||
          meetingRole.is_advocator ||
          meetingRole.is_commitee ||
          meetingRole.is_chairman ||
          meetingRole.is_hr_partners
        ) {
          setShowBtnAllRemarks(true);
          setIsCreateOrEditRemarks(false);
        }
      }

      return;
    }

    if (agendaStatus === AGENDA_STATUS.PAPER_BRING) {
      if (
        meetingRole.is_advocator ||
        meetingRole.is_commitee ||
        meetingRole.is_chairman ||
        meetingRole.is_hr_partners ||
        meetingRole.is_secretariat_or_facilitator
      ) {
        setShowBtnAllRemarks(true);
        setIsCreateOrEditRemarks(false);
      }
    }
  }, [meetingRole, meetingStatus, agendaStatus, staffTalentReview.staff_id]);

  return { showBtnAllRemarks, isCreateOrEditRemarks };
}
