import styled from 'styled-components';
import { font } from '../../assets/common';

const Dropdown = styled.div`
  font-family: ${font.inter};
  padding: 0 10px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #3f3c4c;
  margin-bottom: 15px;

  div {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
  }
  label {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 10px;
  }

  .ant-checkbox + span {
    text-transform: capitalize;
    font-weight: 500;
    color: #3f3c4c;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00a19c;
    border-color: #00a19c;
  }
`;

export { Dropdown };
