import React from 'react';
import { homepage } from '../../assets/img';

function Homepage() {
  return (
    <div className="d-flex justify-content-center align-items-center ">
      <img
        src={homepage}
        alt="homepage"
        style={{ borderRadius: '4px', width: '100%', height: '100%' }}
      />
    </div>
  );
}

export default Homepage;
