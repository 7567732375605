import { Spin } from 'antd';
import { get, isEmpty, unionBy } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { dropdown_builder } from '../../../../assets/img';
import { AlertMessage } from '../../../../components';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import FormDropdown from '../../../../components/Form/FormDropdown/FormDropdown';
import { reportBuilderApi } from '../../../../services/reportBuilderApi';
import FinderOption from './components/FinderOption/FinderOption';
import MeetingOption from './components/MeetingOption/MeetingOption';

const Title = styled.div`
  color: var(--emphasis-body-n-800, #3f3c4c);
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  letter-spacing: 0.1px;
  margin-top: 40px;
`;

const SubTitle = styled.div`
  color: #999a9c;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
`;

const Popout = styled.div`
  .filter,
  .button {
    &:active {
      transform: translateY(1px);
    }
    &:hover {
      cursor: pointer;
    }
  }

  .button {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #00a19c;
    color: #fff;
    height: 44px;
    margin-top: 5px;
    width: 80px;
  }

  .search-result {
    color: var(--emphasis-body-n-800, #3f3c4c);
    /* Heading 6/Large */
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
  }
  .search-keyword {
    color: var(--base-black, #181818);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1px;
  }

  .report-button {
    width: 130px;
  }
`;

const options = [
  { value: 1, label: 'Talent Finder' },
  { value: 2, label: 'TC Meeting' },
];

const PopoutAddTalentBuilder = (props) => {
  const { open, setOpen, initData, setSelectedTalents, handleAddTalent } = props;
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState(options[0].value);

  const handleAddToReport = async (selectedTalents, staffIds, roleActive) => {
    if (!isEmpty(selectedTalents)) {
      try {
        setLoading(true);
        const res = await reportBuilderApi.getTalentsDetail(staffIds, roleActive);
        const columns =
          initData.list_of_talents[0]?.columns.map((column) => ({ ...column, value: '' })) || [];
        const talents = get(res, 'data.result').map((item) => ({ ...item, columns })) || [];
        const newListOfTalents = unionBy([...initData.list_of_talents, ...talents], 'staff_id');
        await handleAddTalent(newListOfTalents, setOpen);
        setSelectedTalents([]);
      } catch (error) {
        console.error(error);
        setOpen(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      width={1400}
      destroyOnClose
      hideDefaultBtn={true}
      styles={{
        containerPaddingTopBottom: 50,
        containerPaddingLeftRight: 50,
        closePosition: 50,
      }}
    >
      <Popout>
        <Spin spinning={loading} size="large">
          <div style={{ overflow: 'hidden' }}>
            <Title>Add Talent</Title>
            <SubTitle>
              You may select the category, then search and add talent to this report
            </SubTitle>
          </div>
          {showError && (
            <AlertMessage
              message="You need to enter a search term before pressing search"
              type="error"
              onClose={() => {
                setTimeout(() => {
                  setShowError(false);
                }, 300);
              }}
            />
          )}
          <div style={{ width: '300px', marginBottom: 20 }}>
            <FormDropdown
              label="Search from"
              value={optionSelected}
              options={options}
              setFormData={setOptionSelected}
              borderColor="#D3DEE8"
              iconArrow={<img src={dropdown_builder} alt="dropdown_builder" />}
            />
          </div>
          {optionSelected === 1 && (
            <FinderOption
              showError={showError}
              setShowError={setShowError}
              setLoading={setLoading}
              handleAddToReport={handleAddToReport}
            />
          )}
          {optionSelected === 2 && (
            <MeetingOption
              setShowError={setShowError}
              setLoading={setLoading}
              handleAddToReport={handleAddToReport}
            />
          )}
        </Spin>
      </Popout>
    </CustomAntModal>
  );
};

export default PopoutAddTalentBuilder;
