import { Dropdown, Menu, Space, Table } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicButton } from '../../../../../assets/common';
import { deleteNote, edit_delete, editNote } from '../../../../../assets/img';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { ALERT_MESSAGE_TYPE, ROLE_LEVEL_LIST } from '../../../../../utils/constants';
import * as SC from '../../../SPManagement/ManagingCriticalPosition/DataTable/styled';
import * as styles from './data-table-role-level-list.module.scss';

export default function DataTableRoleLevelList({
  data,
  totalData,
  setPage,
  page,
  limit,
  isEdit,
  setIsEdit,
  fetchData,
  setDeleteSelecteds,
  deleteSelecteds,
  setDeleteItem,
  setShowDeleteModal,
}) {
  const dispatch = useDispatch();
  const [roleLevel, setRoleLevel] = useState('');
  const [roleLevelSP, setRoleLevelSP] = useState('');
  const [alreadyExistRoleLevelEdit, setAlreadyExistRoleLevelEdit] = useState(false);
  const [alreadyExistRoleLevelSPEdit, setAlreadyExistRoleLevelSPEdit] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);

  const menu = (id, roleLevel, roleLevelSP) => (
    <Menu
      items={[
        {
          icon: <img src={editNote} alt="edit" />,
          label: (
            <div
              className={styles.editButton}
              onKeyDown={() => {}}
              onClick={() => {
                setIsEdit(id);
                setRoleLevel(roleLevel);
                setRoleLevelSP(roleLevelSP);
              }}
            >
              Edit
            </div>
          ),
          key: '0',
        },
        {
          icon: <img src={deleteNote} alt="delete" />,
          label: <div className={styles.deleteButton}>Delete</div>,
          key: '1',
          onClick: () => {
            setShowDeleteModal(true);
            setDeleteItem([id]);
          },
        },
      ]}
    />
  );

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: '10%',
      render: (_, __, index) => (
        <span className={styles.noField}>{(page - 1) * limit + 1 + index}</span>
      ),
    },
    {
      title: 'Role Level',
      dataIndex: 'role_level',
      width: '20%',
      align: 'left',
    },
    {
      title: 'Role Level for Succession Planning',
      dataIndex: 'role_level_sp',
      width: '38%',
      align: 'center',
    },
    {
      title: 'Updated by',
      dataIndex: 'updated_by',
      width: '25%',
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
      width: '7%',
    },
  ];

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => {
      pageNum !== page && setPage(pageNum);
    },
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
      })
    );
  };

  async function editRoleLevelList(role_level, role_level_sp, id) {
    if (!role_level) {
      setAlreadyExistRoleLevelEdit(true);
    }
    if (!role_level_sp) {
      setAlreadyExistRoleLevelSPEdit(true);
    }
    if (!role_level || !role_level_sp) {
      handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, ROLE_LEVEL_LIST.UPDATE_FAILED_MESSAGE);
      return;
    }
    try {
      await adminApi.updateRoleLevel(
        { role_level: role_level.trim(), role_level_sp: role_level_sp.trim() },
        id,
        roleActive
      );
      handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, ROLE_LEVEL_LIST.UPDATE_SUCCESS_MESSAGE);
      fetchData();
      setIsEdit(null);
    } catch (error) {
      const isExist = get(error, 'response.data.errors');
      if (isExist === ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_MESSAGE) {
        setAlreadyExistRoleLevelEdit(true);
        handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_MESSAGE);
      }
      if (isExist === ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_SP_MESSAGE) {
        setAlreadyExistRoleLevelSPEdit(true);
        handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, ROLE_LEVEL_LIST.EXIST_ROLE_LEVEL_SP_MESSAGE);
      }
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setDeleteSelecteds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    selectedRowKeys: deleteSelecteds,
  };

  return (
    <div style={{ marginTop: 24 }}>
      <SC.TableManagement>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data.map((item, index) => ({
            key: item.id,
            role_level:
              isEdit === item.id ? (
                <input
                  className={
                    !alreadyExistRoleLevelEdit ? styles.inputRoleLevel : styles.inputRoleLevelErorr
                  }
                  value={roleLevel}
                  onChange={(e) => {
                    setRoleLevel(e.target.value);
                    if (alreadyExistRoleLevelEdit) {
                      setAlreadyExistRoleLevelEdit(false);
                    }
                  }}
                  id="role-level-input-edit"
                />
              ) : (
                <span className={styles.role_level}>{item.role_level}</span>
              ),
            role_level_sp:
              isEdit === item.id ? (
                <input
                  className={
                    !alreadyExistRoleLevelSPEdit
                      ? styles.inputRoleLevel
                      : styles.inputRoleLevelErorr
                  }
                  value={roleLevelSP}
                  onChange={(e) => {
                    setRoleLevelSP(e.target.value);
                    if (alreadyExistRoleLevelSPEdit) {
                      setAlreadyExistRoleLevelSPEdit(false);
                    }
                  }}
                  id="role-level-sp-input-edit"
                />
              ) : (
                <span className={styles.role_level_sp}>{item.role_level_sp}</span>
              ),
            updated_by: <span className={styles.updated_by}>{item.updated_by}</span>,
            more:
              isEdit !== item.id ? (
                <Dropdown
                  overlay={menu(item.id, item.role_level, item.role_level_sp)}
                  trigger={['click']}
                  style={{ minWidth: 110 }}
                  placement="bottomRight"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <Space style={{ cursor: 'pointer' }}>
                    <img src={edit_delete} alt="dot" />
                  </Space>
                </Dropdown>
              ) : (
                <BasicButton
                  mode="teal"
                  onKeyDown={() => {}}
                  onClick={() =>
                    editRoleLevelList(
                      document.getElementById('role-level-input-edit').value,
                      document.getElementById('role-level-sp-input-edit').value,
                      isEdit
                    )
                  }
                >
                  Save
                </BasicButton>
              ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
}
