import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { approved_sp_data_not_found } from '../../../../assets/img';
import DataTable from './DataTable/DataTable';

import MenuBar from './MenuBar/MenuBar';
import { adminApi } from '../../../../services/admin';

import * as styles from './manage-approved-sp.module.scss';
import SPManagementPill from '../components/SPManagementPill/SPManagementPill';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';

export default function ManageApprovedSP(props) {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [positionLabelSelected, setPositionLabelSelected] = useState([]);
  const [businessSelected, setBusinessSelected] = useState([]);
  const [activeSPSelected, setActiveSPSelected] = useState([]);
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const roleActive = useSelector((state) => state.user.roleActive);

  const fetchData = useCallback(async () => {
    // if (
    //   isEmpty(keyword) &&
    //   isEmpty(businessSelected) &&
    //   isEmpty(positionLabelSelected) &&
    //   isEmpty(activeSPSelected)
    // ) {
    //   setData([]);
    //   return;
    // }
    try {
      setLoading(true);
      const query = {
        keyword,
        business_unit: businessSelected.map((item) => item.value).filter((item) => item !== 'all'),
        position_label: positionLabelSelected
          .map((item) => item.value)
          .filter((item) => item !== 'all'),
        active_approved_successor: activeSPSelected.map((item) => item.value),
      };
      const res = await adminApi.getApprovedSP(query, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, businessSelected.length, positionLabelSelected.length, activeSPSelected.length]);

  const handleDeleteRecord = useCallback(async () => {
    try {
      setLoading(true);
      const res = await adminApi.deleteApprovedSP(
        deleteRecord.position_code,
        deleteRecord?.approved_date && moment(deleteRecord.approved_date).format('YYYY-MM-DD'),
        roleActive
      );
      if (res.status === 200) {
        setDeleteRecord(null);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${deleteRecord?.position_code} ${deleteRecord?.plan_name} successfully deleted`,
          })
        );
        fetchData();
      }
    } catch (error) {
      console.log(error);
      setDeleteRecord(null);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, fetchData, roleActive.roleId]);

  useEffect(() => {
    if (!firstTimeRender) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    setFirstTimeRender(true);
  }, []);

  useEffect(() => {
    if (!deleteRecord) return;
    handleDeleteRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord]);

  return (
    <Spin spinning={loading} size="large">
      <MenuBar
        positionLabelSelected={positionLabelSelected}
        setPositionLabelSelected={setPositionLabelSelected}
        businessSelected={businessSelected}
        setBusinessSelected={setBusinessSelected}
        activeSPSelected={activeSPSelected}
        setActiveSPSelected={setActiveSPSelected}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        fetchData={fetchData}
      />
      <SPManagementPill
        showFilter={showFilter}
        positionLabelSelected={positionLabelSelected}
        setPositionLabelSelected={setPositionLabelSelected}
        businessSelected={businessSelected}
        setBusinessSelected={setBusinessSelected}
        activeSPSelected={activeSPSelected}
        setActiveSPSelected={setActiveSPSelected}
      />
      {!isEmpty(data) ? (
        <DataTable data={data} setDeleteRecord={setDeleteRecord} />
      ) : (
        <div className={styles.not_found}>
          <img src={approved_sp_data_not_found} alt="not_found" />
        </div>
      )}
    </Spin>
  );
}
