import React from 'react';

import { drag_icon_recommendation } from '../../../../../assets/img';
import { Droppable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Droppable';
import styles from './drop-card-recommendation.module.scss';
import { Divider } from 'antd';

const DropCardRecommendation = (props) => {
  const { cardInfo, isPrinting, sectionIndex, dropId } = props;
  return (
    <Droppable id={dropId} key={`${cardInfo.title}${sectionIndex}`} showborder={`true`}>
      <div className="d-flex justify-content-center">
        <div className={styles.dragDropBox}>
          <img src={drag_icon_recommendation} alt="" />
          <div>{cardInfo.desc}</div>
        </div>
      </div>
      <Divider style={{ width: '100%', height: '2px', margin: '15px 0 0 0', color: '#D6D6D6' }} />
    </Droppable>
  );
};

export default DropCardRecommendation;
