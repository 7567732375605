import React from 'react';
import { withRouter } from 'react-router-dom';

const AccessDenied = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <span style={{ marginTop: 25, display: 'block' }}>
        <h3>Access denied</h3>
      </span>
    </div>
  );
};

export default withRouter(AccessDenied);
