import { dashboard_breadcrumb } from '../../../assets/img';

export const RB_BREAD_CRUMB = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Report Builder' },
];

export const REPORT_BUILDER_ROW_LIMIT = 15;

export const REPORT_BUILDER_MESSAGE = {
  DELETE_REPORT: 'Report deleted successfully',
  DELETE_TALENT: 'Talent deleted successfully',
  DELETE_COLUMN: 'Column deleted successfully',
  EDIT_NAME_SUCCESS: 'Report Name updated successfully',
  EDIT_COLUMN_NAME_SUCCESS: 'Column Name updated successfully',
  EDIT_MANUAL_COLUMN_SUCCESS: 'Column updated successfully',
  DUPLICATE_COLUMN_SUCCESS: 'Column duplicated successfully',
  FIELDS_REQUIRED: 'There are items that require your attention. Please fill out this field. ',
  ADD_TALENT_SUCCESS: 'New talent added successfully.',
  ADD_COLUMN_SUCCESS: 'New columns added successfully',
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  DUPLICATE_COLUMN_NAME: 'Column Name is already in use. Please enter a different name.',
  EDIT_SUCCESS: 'Report edited successfully.',
};

export const REPORT_COLUMN_TYPE = [
  { value: `Free Text Field`, label: `Free Text Field` },
  { value: `Status Selection`, label: `Status Selection` },
  { value: `Yes/ No Selection`, label: `Yes/ No Selection` },
];

export const REPORT_COLUMN_TYPE_VALUE = {
  STATUS_SELECTION: [
    {
      value: '#0B882E',
      label: '#0B882E',
    },
    {
      value: '#FDB924',
      label: '#FDB924',
    },
    {
      value: '#DA2228',
      label: '#DA2228',
    },
    {
      value: '#7A7A7A',
      label: '#7A7A7A',
    },
  ],
  YES_NO_SELECTION: [
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
    {
      value: 'NA',
      label: 'NA',
    },
  ],
};

export const VALUE_TYPES = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

export const ACCESS_FIELD = [
  { label: 'Role', name: 'roles' },
  { label: 'Business', name: 'businesses' },
  { label: 'HR Partner', name: 'hr_partners' },
];
