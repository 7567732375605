import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';
import { font } from '../../../../../assets/common';

export const Title = styled.div`
  font-family: ${font.inter};
  color: var(--Light-Primary-800, #006360);
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  ${(props) => {
    if (props.isActive) {
      if (props.color) {
        return css`
          color: var(--Light-Primary-500, ${props.color});
        `;
      }
    }
  }}
`;

export const TitleEmptyState = styled.div`
  color: var(--Light-Gray-400, #a3a3a3);
  text-align: center;
  font-family: ${font.inter};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
`;

export const CheckboxFulfilment = styled(Checkbox)`
  cursor: default !important;
  .ant-checkbox-disabled .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid var(--Light-Blue-Grays-300, #c9d5e3) !important;
    background: var(--Light-Blue-Grays-200, #e7edf3);
    width: 16px;
    height: 16px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    padding: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;

    ${(props) => {
      if (props.checked) {
        if (props.color) {
          return css`
            background-color: ${props.color} !important;
            border-color: ${props.color} !important;
          `;
        }
      }
    }}
  }
  .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: #0b882e !important;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
  .ant-checkbox-disabled,
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: default !important;
  }
`;
