export const makeSelectStyle = ({ meta }) => ({
  control: (provider, state) => ({
    ...provider,
    boxShadow: 'none',
    border: `1px solid ${meta?.touched && meta?.error ? '#FF787F' : '#d3dee8'}`,
    borderRadius: '4px',
    width: '100%',
    height: '44px',
    '&:hover': {
      border: `1px solid ${meta?.touched && meta?.error ? '#FF787F' : '#d3dee8'}`,
      cursor: 'pointer',
    },
    backgroundColor: state.isDisabled ? '#e0e0e0' : undefined,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    fontFamily: "'Inter', sans-serif",
    color: state.isDisabled ? '#787587' : '#8D8D8D',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    fontFamily: "'Inter', sans-serif",
    color: '#C4C3D1',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '312px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: state.isSelected ? '#e5e5e5' : undefined,
    color: '#344563',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e5e5e5',
      color: '#344563',
    },
    '&:not(:last-of-type)': {
      borderBottom: '1px solid #d3dee8',
    },
  }),
});
