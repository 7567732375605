import React from 'react';
import styles from './switch-mode.module.scss';

const SwitchMode = ({ isPrivate, setIsPrivate }) => {
  const handleHidden = () => {
    setIsPrivate(!isPrivate);
  };

  return (
    <div className={styles.content}>
      <label className={styles.toggle} htmlFor="mode_note">
        <input type="checkbox" id="mode_note" onClick={() => handleHidden()} checked={isPrivate} />
        <div className={styles.slider}></div>
      </label>
      <p className={styles.hideText}>Private</p>
    </div>
  );
};

export default SwitchMode;
