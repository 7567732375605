import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { add_position, full_arrow_left } from '../../../../assets/img';
import { MOBILITY_MODAL_ADD_TYPE } from '../../constants';
import MobilityAddPositionModal from '../MobilityAddPositionModal/MobilityAddPositionModal';
import MobilityPositionCard from '../MobilityPositionCard/MobilityPositionCard';
import styles from './mobility-position.module.scss';

const MobilityPosition = (props) => {
  const {
    mobilitySimulation,
    addPositionOrTalent,
    isHighlighted,
    modalAddType,
    handleShowMessageSuccess,
    setIsHighlighted,
    removeItemOnChain,
    setIsSeniorManager,
    isSeniorManager,
  } = props;
  const [show, setShow] = useState(false);
  const flavoursContainer = document.getElementById('mobility');
  const flavoursScrollWidth = flavoursContainer?.scrollWidth;

  const handleOpenAddModal = () => {
    setShow(true);
  };

  useEffect(() => {
    if (!flavoursContainer && !flavoursScrollWidth) return;
    flavoursContainer.scrollLeft = flavoursScrollWidth;
  }, [flavoursContainer, flavoursScrollWidth]);

  return (
    <div className={styles.container} id="mobility">
      {!isEmpty(mobilitySimulation.dataChain) &&
        mobilitySimulation.dataChain.map((item, index) => (
          <React.Fragment key={index}>
            <div style={{ cursor: 'pointer' }}>
              <MobilityPositionCard
                data={item}
                index={index}
                isHighlighted={isHighlighted}
                handleShowMessageSuccess={handleShowMessageSuccess}
                removeItemOnChain={removeItemOnChain}
                setIsHighlighted={setIsHighlighted}
                setIsSeniorManager={setIsSeniorManager}
              />
            </div>
            {!isSeniorManager && (
              <div className="align-self-center">
                <img src={full_arrow_left} width={40} height={40} alt="full_arrow_left" />
              </div>
            )}
          </React.Fragment>
        ))}
      {!isSeniorManager && (
        <Droppable droppableId="mobilityPosition">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              onKeyDown={() => {}}
              onClick={handleOpenAddModal}
              className={styles.drop}
              style={{
                boxShadow: snapshot.isDraggingOver ? '0px 0px 5px 5px #c6abf1' : 'none',
              }}
            >
              <div
                className={`${styles.addPosition} ${
                  !mobilitySimulation.isValidate &&
                  isEmpty(mobilitySimulation.dataChain) &&
                  styles.addPositionError
                }`}
              >
                <img src={add_position} alt="add_position" />
                <div>
                  {modalAddType === MOBILITY_MODAL_ADD_TYPE.POSITION
                    ? 'Click plus icon to add Position'
                    : 'Click plus icon or drag and drop the card here to add Talent'}
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )}

      {show && (
        <MobilityAddPositionModal
          show={show}
          setShow={setShow}
          type={modalAddType}
          addPositionOrTalent={addPositionOrTalent}
          setIsSeniorManager={setIsSeniorManager}
        />
      )}
    </div>
  );
};

export default MobilityPosition;
