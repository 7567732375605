import React from 'react';
import { Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import styles from './error-page.module.scss';

const AccessDenied = (props) => {
  return (
    <Row className={`${styles.errorPage} h-100`}>
      <Row className="h-100" style={{ alignItems: 'center' }}>
        <div className={styles['brand-welcome']}>
          <span style={{ marginTop: 25, display: 'block' }}>
            <h3>An occur error</h3>
            <h4>
              <a href="https://mytalentx.petronas.com/">Click here</a> to redirect to login page
              <br></br> or please contact ICT to supported
            </h4>
          </span>
        </div>
      </Row>
    </Row>
  );
};

export default withRouter(AccessDenied);
