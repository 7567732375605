import { PERMISSION } from '../utils/constants';
import axiosClient from './axiosClient';

export const talentFinderApi = {
  getList: (body, role, permission) => {
    const url = `/find-talents`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  getTalentfilters: (isTalentFinder) => {
    return axiosClient.get(`/talent-filters`, {
      params: {
        is_talent_finder: isTalentFinder,
      },
    });
  },

  requestDataExport: (body, role) => {
    const url = `/export-find-talents`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.TALENT_EXPORT,
      },
    });
  },

  getDataExport: (url) => {
    return fetch(url)
      .then((response) => {
        if (response && response.status === 200) return response.json();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  confirmExport: (blockBlob) => {
    const url = `/confirm-export`;
    return axiosClient.delete(url, { params: { blockBlob } });
  },

  getExportStatus: (blockBlob) => {
    const url = `/export-status`;
    return axiosClient.get(url, { params: { blockBlob } });
  },
};

export default talentFinderApi;
