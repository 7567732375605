import { isEmpty } from 'lodash';
import queryString from 'query-string';

import { PERMISSION } from '../utils/constants';
import axiosClient from './axiosClient';

export const dashboardApi = {
  getTotalRetirement: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/get-total-retirement?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getStatisticSuccess: (query, roleId) => {
    const stringified = queryString.stringify(query);
    const url = `/statistic-sucessions?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getKeyPosition: (query, roleId) => {
    const stringified = queryString.stringify(query);
    const url = `/get-key-position?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },
  getAllTotal: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/get-all-position?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getTotalAttrition: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/get-total-attrition?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getTotalNotReview: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/get-total-not-review?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getTotalNewPosition: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/get-total-new-position?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getTotalPositionRatio: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/get-total-position-ratio?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getTotalPositionDelibrated: (query, roleId) => {
    const stringified = queryString.stringify(query);
    const url = `/get-total-position-delibrated?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalData: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/historical-data?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalAgeAndGender: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/historical-dashboard/talent-overview/age-and-gender?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalSuccessionPlanning: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/historical-dashboard/succession-plan?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalRetirementForecast: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/historical-dashboard/talent-overview/retirement-forecast?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalBusiness: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/historical-dashboard/talent-overview/business?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalSuccessor: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/historical-data/successor?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalTalentReviewStatus: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `historical-dashboard/talent-overview/talent-review-status?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalTalentNational: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `historical-dashboard/talent-overview/nationality?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalDashboardDetail: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `historical-dashboard/succession-plan/detail?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalDetails: (params) => {
    const {
      page,
      limit,
      keyword,
      isExport,
      run_id,
      roleId,
      topTalentCategory,
      salaryGrade,
      roleLevel,
      business,
      typeOfTalent,
      year,
      month,
      companyFilterType,
      companyFilterValue,
      talentFilter,
      companyFilterLabel,
      companyFilterValueLabel,
    } = params;
    const url = `/historical-dashboard/talent-overview/detail`;
    return axiosClient.get(url, {
      params: {
        limit,
        page,
        keyword: keyword || '',
        isExport,
        run_id,
        roleId,
        topTalentCategory:
          (!isEmpty(topTalentCategory) && topTalentCategory.map((item) => `${item}`).join(',')) ||
          '',
        salaryGrade:
          (!isEmpty(salaryGrade) && salaryGrade.map((item) => `${item}`).join(',')) || '',
        roleLevel: (!isEmpty(roleLevel) && roleLevel.map((item) => `${item}`).join(',')) || '',
        business: (!isEmpty(business) && business.map((item) => `${item}`).join(',')) || '',
        typeOfTalent,
        year,
        month,
        companyFilterType,
        companyFilterValue,
        talentFilter,
        companyFilterLabel,
        companyFilterValueLabel,
      },
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getFilterHistoricalDetails: (roleId) => {
    const url = `/historical-dashboard/talent-overview/detail/filters`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getPositioncategoryFilter: (role) => {
    const url = `/admin/position-category/dropdown-filter`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getTotalPositionDashboard: (query, roleId) => {
    const stringified = queryString.stringify(query);
    const url = `get-total-position?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.VIEW_TALENT_DASHBOARD,
      },
    });
  },

  getHistoricalDataApproveSp: (query, roleId) => {
    const stringified = queryString.stringify(query);
    const url = `meetings/approved-sp?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
};
