export const OptionChart = {
  yaxis: {
    min: 0,
    tickAmount: 5,
    max: (max) => {
      let valueMax = 50;
      while (max > valueMax) {
        valueMax = valueMax + 50;
      }
      return valueMax;
    },
    labels: {
      formatter: function (value) {
        if (value >= 10000) {
          return (value / 1000).toFixed(1) + 'k';
        } else {
          return value;
        }
      },
      style: {
        fontFamily: 'Rubik, sans-serif',
        fontWeight: 300,
        fontSize: '13px',
        colors: ['#444444'],
      },
    },
  },
  tooltip: {
    shared: false,
    intersect: true,
    x: {
      show: false,
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        `<p>` +
        w.globals.seriesNames[seriesIndex] +
        ' : ' +
        series[seriesIndex][dataPointIndex] +
        '</p>'
      );
    },
  },
  noData: {
    text: 'No data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
    },
  },
};

export const OptionChartSPandBU = {
  ...OptionChart,
  chart: {
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  colors: ['#90A0CD', '#7AC8C5', '#8BC5F5', '#BAC7D5', '#BB9FCC'],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%',
      endingShape: 'rounded',
      borderRadius: 3,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -20,
    style: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '10px',
      colors: ['#444444'],
      fontWeight: 300,
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['transparent'],
  },
};

export const OptionchartReAndNa = {
  ...OptionChart,
  chart: {
    width: 350,
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -20,
    style: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: '10px',
      colors: ['#444444'],
      fontWeight: 300,
    },
  },
  stroke: {
    show: true,
    width: 5,
    colors: ['transparent'],
  },
};
export const OptionNormal = {
  labels: {
    style: {
      fontFamily: 'Rubik, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      colors: ['#344563'],
    },
  },
  legendSPandBU: {
    show: true,
    showForSingleSeries: true,
    horizontalAlign: '',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Rubik, sans-serif',
  },
  barReandNa: {
    horizontal: false,
    endingShape: 'rounded',
    borderRadius: 3,
    dataLabels: {
      position: 'top', // top, center, bottom
    },
  },
};
