import { Spin } from 'antd';
import React, { useMemo } from 'react';

import { PersonalityArchetypesTable } from '../ExternalAssessments/ExternalAssessmentStyled';
import styles from '../GeneralExternalAssessments/general-external-assessments.module.scss';
import { ASSESSMENT_TYPE_VALUES } from '../ExternalAssessments/constant_assessments';

const PersonalityArchetypesAssessments = (props) => {
  const { assessmentData, loading } = props;

  const convertData = useMemo(() => {
    return assessmentData?.type === ASSESSMENT_TYPE_VALUES.PERSONALITY_ARCHETYPES ? assessmentData.data : {};
  }, [assessmentData]);

  const columns = [
    {
      title: 'Personality Archetypes',
      dataIndex: 'personalityArchetypes',
      key: 'personalityArchetypes',
      width: '50%',
      render: (_, record) => {
        return <span>{record.personalityArchetypes ?? '-'}</span>;
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: `50%`,
      render: (_, record) => {
        return record.rating ?? '-';
      },
    },
  ];

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.tableContent}>
        <Spin spinning={loading} size="small">
          <PersonalityArchetypesTable
            style={{ marginBottom: '8px' }}
            showHeader={false}
            dataSource={convertData?.categoryLevels ?? []}
            columns={columns}
            pagination={false}
            rowClassName={(record) => (record?.personalityArchetypes.includes('Strongest') ? 'strongest-item' : '')}
          />
          <PersonalityArchetypesTable dataSource={convertData?.personalityArchetypes ?? []} columns={columns} pagination={false} />
        </Spin>
      </div>
    </div>
  );
};

export default PersonalityArchetypesAssessments;
