import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicTable } from '../../../../../assets/common';
import { avatar, deleteNote } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES, MIN_RECORD_BEFORE_DELETE } from '../../../../../utils/constants';
import { decodeBase64ToImage, paginationConfig } from '../../../../../utils/helper';
import { PROFILE_MANAGEMENT_MESSAGES } from '../../constant';
import * as styles from './unsearchable-talent-table.module.scss';

const UnsearchableTalentTable = ({ data, fetchData, setSearchParams, searchParams }) => {
  const dispatch = useDispatch();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);

  const handleClickDelete = (record) => {
    setSelectedRecord(record);
    setShowDeleteModal(true);
  };

  const checkResetPage = useMemo(() => {
    return data.list.length <= MIN_RECORD_BEFORE_DELETE;
  }, [data.list.length]);

  const handleDeleteRecord = async () => {
    try {
      setDeleting(true);
      const res = await adminApi.deleteUnsearchableTalent(
        selectedRecord.staff_id,
        roleActive?.roleId
      );
      if (res.status === 200) {
        fetchData(Number(checkResetPage));
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${selectedRecord.birth_name || ''} ${
              PROFILE_MANAGEMENT_MESSAGES.UNSEARCHABLE_DELETE_SUCCESS
            }`,
          })
        );
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'No.',
        align: 'center',
        width: 12,
        render: (_, __, index) => (
          <span className={styles.noField}>
            {(searchParams.page - 1) * searchParams.limit + 1 + index}
          </span>
        ),
      },
      {
        title: 'Staff Name',
        dataIndex: 'birth_name',
        width: 344,
        render: (_, record) => (
          <div className={styles.staffName}>
            <img
              className={styles.customImg}
              src={decodeBase64ToImage(record?.photo) || avatar}
              alt="avatar"
            />
            <div>{record?.birth_name}</div>
          </div>
        ),
      },
      {
        title: 'Staff ID',
        dataIndex: 'staff_id',
        width: 50,
        align: 'center',
        render: (text) => <span className={`${styles.textField}`}>{text}</span>,
      },
      {
        title: 'Company',
        dataIndex: 'opu_name',
        align: 'center',
        render: (text) => <span className={`${styles.textField}`}>{text}</span>,
      },
      {
        title: 'Division',
        dataIndex: 'division',
        align: 'center',
        render: (text) => <span className={`${styles.textField}`}>{text}</span>,
      },
      {
        title: 'Department',
        dataIndex: 'department_name',
        align: 'center',
        render: (text) => <span className={`${styles.textField}`}>{text}</span>,
      },
      {
        title: 'Action',
        align: 'center',
        width: 12,
        render: (_, record) => (
          <span
            className={styles.deleteButton}
            onKeyDown={() => {}}
            onClick={() => handleClickDelete(record)}
          >
            <img src={deleteNote} alt="delete" />
          </span>
        ),
      },
    ];
  }, [searchParams.page, searchParams.limit]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams({ ...searchParams, page: pageNum });
    };
    return paginationConfig(data.total, searchParams.page, onChange, searchParams.limit);
  }, [data.total, searchParams, setSearchParams]);

  return (
    <>
      <BasicTable
        dataSource={data.list}
        columns={columns}
        rowKey="staff_id"
        pagination={pagination}
      />
      <ModelTC
        info={{
          type: 'deleteTalentProfile',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          handleSubmit: () => handleDeleteRecord(),
          loading: deleting,
          onClose: () => {
            setShowDeleteModal(false);
          },
        }}
      />
    </>
  );
};

export default UnsearchableTalentTable;
