import axios from 'axios';
import queryString from 'query-string';
import { getAccessToken } from '../msalConfig';
import { store } from '../store';
import { get } from 'lodash';
import { ERROR_MESSAGE_API } from '../utils/constants';
import { history } from '../utils/history';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:5007',
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(
  async (config) => {
    const userId = store.getState().user.userId;
    const accessTokenResponse = await getAccessToken();
    if (userId) {
      config.headers['user'] = userId;
    }
    if (accessTokenResponse?.accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessTokenResponse.accessToken;
      config.headers['IdToken'] = accessTokenResponse?.idToken;
    }

    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const response = error?.response;
    if (response?.status === 500) {
      const message = get(response, 'data.message');
      if (ERROR_MESSAGE_API.includes(message)) {
        localStorage.clear();
        history.push('/');
      }
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
