import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pushMessage } from '../../../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  CRITERIA_COMPLEMENTARY_TYPE,
  CRITERIA_MANDATORY_TYPE,
  CRITERIA_NO_RANKING,
  CRITERIA_SPIKE_TYPE,
} from '../../../../../../utils/constants';
import {
  addOrUpdateMatchingCriteria,
  updateEnteredMatchingCriteria,
  updateIsDeleteCriteria,
} from '../../../redux/manageCriteriaSetMatchingSlice';
import { CustomSelect } from '../SelectedCriteriaForm/styled';
import * as styles from './criteria-summary.module.scss';
import { CriteriaSummaryCustomTable } from './styled';

const MatchingCriteriaSummary = () => {
  const dispatch = useDispatch();
  const { set_index, set_matching_criteria } = useSelector(
    (state) => state.app.adminModule.manageCriteriaSetMatching
  );
  const [rankOptions, setRankOptions] = useState([]);

  const SHOW_CRITERIA_NAME_MAP = {
    [CRITERIA_SPIKE_TYPE.EDGE_SPIKE]: 'EDGE Spike',
    [CRITERIA_SPIKE_TYPE.ATTRIBUTES_SPIKE]: 'Attributes Spike',
    [CRITERIA_SPIKE_TYPE.BUSINESS_EXPOSURE_SPIKE]: 'Business Exposure Spike',
  };

  const SHOW_CRITERIA_MAP_COMPLEMENTARY_NAME = {
    [CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_MANDATORY]:
      CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_MANDATORY,
    [CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_OPTIONAL]:
      CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_OPTIONAL,
    [CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_SPIKE]: CRITERIA_COMPLEMENTARY_TYPE.FUNCTIONAL_SPIKE,
  };

  const isDuplicateRanking = useMemo(() => {
    if (set_matching_criteria[set_index]?.entered_criteria) {
      const ranks = set_matching_criteria[set_index].entered_criteria
        .filter((item) => item.rank)
        .map((item) => item.rank);
      return ranks.some((item, index) => ranks.indexOf(item) !== index);
    }
  }, [set_matching_criteria, set_index]);

  useEffect(() => {
    if (isDuplicateRanking) {
      dispatch(
        pushMessage({
          message: 'Ranking value is in use. Please select other ranking value for the criteria',
          type: ALERT_MESSAGE_TYPE.ERROR,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuplicateRanking]);

  const isEdit = useMemo(() => {
    return set_matching_criteria[set_index]?.isEditSetMatching;
  }, [set_matching_criteria, set_index]);

  const replaceSpikeOrComplementary = (value) => {
    if (Object.values(CRITERIA_SPIKE_TYPE).includes(value)) {
      return SHOW_CRITERIA_NAME_MAP[value];
    } else if (Object.values(CRITERIA_COMPLEMENTARY_TYPE).includes(value)) {
      return SHOW_CRITERIA_MAP_COMPLEMENTARY_NAME[value];
    } else {
      return value;
    }
  };

  const handleSelect = (value, index) => {
    if (set_matching_criteria[set_index].isDeleteCriteria) {
      dispatch(
        updateEnteredMatchingCriteria(
          set_matching_criteria[set_index].entered_criteria.map((item) => ({
            ...item,
            rank: null,
          }))
        )
      );
    }
    dispatch(updateIsDeleteCriteria({ set_index, value: false }));
    dispatch(
      addOrUpdateMatchingCriteria({
        ...set_matching_criteria[set_index].entered_criteria[index],
        rank: value,
      })
    );
  };

  const columns = [
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      width: '30%',
      render: (value) => (
        <div>
          <div>{replaceSpikeOrComplementary(value)}</div>
        </div>
      ),
    },
    {
      title: 'Ranking',
      width: '40%',
      align: 'center',
      render: (item, _, index) => (
        <>
          {!set_matching_criteria[set_index].entered_un_rank
            .map((i) => i.criteria)
            .includes(item.criteria) &&
            !CRITERIA_NO_RANKING.includes(item.criteria) && (
              <div>
                <CustomSelect
                  disabled={!isEdit}
                  data-testid="rank-select"
                  size="middle"
                  value={!set_matching_criteria[set_index].isDeleteCriteria ? item.rank : null}
                  placeholder="Select"
                  virtual={false}
                  style={{ width: 220 }}
                  onChange={(value) => {
                    handleSelect(value, index);
                  }}
                  options={rankOptions.map((item) => ({ label: item, value: item }))}
                />
              </div>
            )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setRankOptions(
      Array.apply(
        null,
        Array(
          set_matching_criteria[set_index].entered_criteria.filter(
            (item) => ![...CRITERIA_MANDATORY_TYPE, ...CRITERIA_NO_RANKING].includes(item.criteria)
          ).length
        )
      ).map(function (x, i) {
        return i + 1;
      })
    );
  }, [set_matching_criteria, set_index]);

  return (
    <div className={styles.wrapper}>
      <div className="d-flex justify-content-between">
        <h4 className={styles.title}>Summary</h4>
      </div>
      <CriteriaSummaryCustomTable
        columns={columns}
        dataSource={[
          ...set_matching_criteria[set_index].entered_criteria,
          ...set_matching_criteria[set_index].entered_un_rank,
        ]}
        pagination={false}
        rowKey={'criteria'}
      />
    </div>
  );
};

export default MatchingCriteriaSummary;
