import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { AsyncMultipleSelect } from '../../../../components';
import { MOBILITY_FORM_MODE } from '../../constants';
import * as styles from './mobility-form.module.scss';

const MobilityForm = (props) => {
  const { mode, data } = props;

  const handleSubmit = async (values) => {
    // do something
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    onSubmit: handleSubmit,
  });

  return (
    <div className={styles.list}>
      <div className={styles.titleDetail}>Mobility Simulation Details</div>
      <div className={styles.form}>
        <div>
          Mobility Simulation Name<span style={{ color: '#FF787F' }}>*</span>
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Mobility Simulation Name"
            className={styles.input}
            disabled={mode === MOBILITY_FORM_MODE.VIEW}
            value={formik?.values?.name}
          />
        </div>
      </div>
      <div className={styles.form}>
        <div>HR Partners</div>
        <div>
          <AsyncMultipleSelect
            placeholder={'Enter HR Partners Name'}
            value={
              formik?.values?.hr_partners &&
              formik.values.hr_partners.map((item) => {
                return { label: item.name, id: item.id };
              })
            }
            isDisabled={[MOBILITY_FORM_MODE.VIEW, MOBILITY_FORM_MODE.DUPLICATE].includes(mode)}
            className={styles.hideCloseBtn}
          />
        </div>
      </div>
    </div>
  );
};

MobilityForm.propTypes = {
  mode: PropTypes.string,
  data: PropTypes.object,
}

export default MobilityForm;
