import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, size, uniqBy, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { meetingApi } from '../../../services/tcMeeting';
import { AGENDA_TYPES } from '../../MobilitySimulation/constants';
import { DATE_TIME_FORMAT } from '../../../utils/constants';

const convertLineForSetAsMain = (line, agendaId) => {
  return line.map((item) => ({
    ...item,
    agenda_as_main: agendaId,
  }));
};

export const getAgendaTalents = createAsyncThunk(
  'spTemplate/getAgendaTalents',
  async (params, thunkAPI) => {
    try {
      const res = await meetingApi.getPersonBelongPositions(params);
      if (res.status === 200) {
        thunkAPI.dispatch(updateTalentsPopulate(res.data.result));
      }
    } catch (error) {
      console.error(error);
    }
  }
);
const handleOrderAndUniqe = (talentOrigin, talents) => {
  let combineTalents = [...talentOrigin, ...talents];
  let uniqByTalents = uniqBy(combineTalents, 'staff_id');
  return uniqByTalents;
};

const handleOrderAndUniqePopulate = (talentOrigin, talents) => {
  let combineTalents = [...talentOrigin, ...talents];
  let uniqByTalents = uniqBy(combineTalents, 'staff_id');
  let sortByRank = sortBy(uniqByTalents, 'rank');
  return sortByRank;
};

const handleOrderWhenRemoved = (talents) => {
  if (isEmpty(talents)) return [];
  let uniqByTalents = uniqBy(talents, 'staff_id');
  return uniqByTalents;
};

const angedaIncumbentInitialState = {
  agenda_id: '',
  succession_planning_name: '',
  start_time: '',
  end_time: '',
  advocators: [],
  positions: [],
  first_line: [],
  second_line: [],
  third_line: [],
  cc_list: [],
  remarks: '',
  business: 'CCP Succession Planning',
  meeting_id: '',
  version: 0,
  isEdit: false,
  action_type: '',
  agenda_as_main: null,
  agenda_as_main_platform: '',
  agenda_as_main_type: '',
  agenda_as_main_positions: [],
  agenda_as_main_start_at: '',
  remarks_from_pending_action: [],
};

const INITIAL_STATE = {
  agendaIncumbent: angedaIncumbentInitialState,
  position_code: [],
  agendas: [],
  removeList: [],
  changeStatusList: [],
  syndication: [],
  createdAgenda: [],
  existedAgenda: [],
  loading: false,
  filter_type: '',
  temporaryIdMsAgenda: '',
  temporaryIdTalentReviewAgenda: '',
  temporaryFiles: [],
  temporaryPrevAgenda: null,
};

const agendaSlice = createSlice({
  name: 'agenda',
  initialState: INITIAL_STATE,
  reducers: {
    getAgenda: (state, action) => {
      state.agendaIncumbent = action.payload;
    },
    addPosition: (state, action) => {
      state.agendaIncumbent.positions.push(action.payload);
      state.position_code = state.agendaIncumbent.positions.map((item) => item.position_code);
    },
    updatePositions: (state, action) => {
      state.agendaIncumbent.positions = action.payload;
      state.position_code = action.payload.map((item) => item.position_code);
    },
    updateTalents: (state, action) => {
      let cloneAgendaDetail = cloneDeep(state.agendaIncumbent);
      state.agendaIncumbent.first_line = handleOrderAndUniqe(cloneAgendaDetail.first_line, action.payload.first_line);
      state.agendaIncumbent.second_line = handleOrderAndUniqe(cloneAgendaDetail.second_line, action.payload.second_line);
      state.agendaIncumbent.third_line = handleOrderAndUniqe(cloneAgendaDetail.third_line, action.payload.third_line);
    },
    updateTalentsPopulate: (state, action) => {
      let cloneAgendaDetail = cloneDeep(state.agendaIncumbent);
      state.agendaIncumbent.first_line = handleOrderAndUniqePopulate(cloneAgendaDetail.first_line, action.payload.first_line);
      state.agendaIncumbent.second_line = handleOrderAndUniqePopulate(cloneAgendaDetail.second_line, action.payload.second_line);
      state.agendaIncumbent.third_line = handleOrderAndUniqePopulate(cloneAgendaDetail.third_line, action.payload.third_line);
    },
    updateAgendaLine: (state, action) => {
      state.agendaIncumbent.first_line = action.payload.first_line;
      state.agendaIncumbent.second_line = action.payload.second_line;
      state.agendaIncumbent.third_line = action.payload.third_line;
    },
    updateAgendaIncumbent: (state, action) => {
      state.agendaIncumbent.succession_planning_name = action.payload.succession_planning_name;
      state.agendaIncumbent.start_time = action.payload.start_time;
      state.agendaIncumbent.end_time = action.payload.end_time;
      state.agendaIncumbent.advocators = action.payload.advocators;
      state.agendaIncumbent.cc_list = action.payload.cc_list;
      state.agendaIncumbent.advocators = action.payload.advocators;
      state.agendaIncumbent.business = 'CCP Succession Planning';
      state.agendaIncumbent.meeting_id = '1';
      state.agendaIncumbent.version = 0;
      state.agendaIncumbent.version = 0;
      state.agendaIncumbent.first_line = action.payload.first_line;
      state.agendaIncumbent.second_line = action.payload.second_line;
      state.agendaIncumbent.third_line = action.payload.third_line;
      state.agendaIncumbent.isEdit = action.payload.isEdit;
      state.agendaIncumbent.remarks = action.payload.remarks;
      state.agendaIncumbent.filter_type = 'SP';
      state.agendaIncumbent.action_type = action.payload.action_type;
      state.agendaIncumbent.type_agenda = 'SP';
      state.agendaIncumbent.remarks_from_pending_action =
        action.payload.remarks_from_pending_action;
      //// Save temporary agenda
      if (!state.agendaIncumbent.agenda_id) {
        state.agendaIncumbent.agenda_id = uuidv4();
      }
      let createdAgenda = cloneDeep(state.createdAgenda);
      let agendas = cloneDeep(state.agendas);
      if (size(createdAgenda) === 0) {
        createdAgenda.push(state.agendaIncumbent);
      } else {
        if (size(createdAgenda) > 0) {
          let idxEle = createdAgenda.findIndex(
            (item) => item.agenda_id === state.agendaIncumbent.agenda_id
          );
          if (idxEle === -1) {
            createdAgenda.unshift(state.agendaIncumbent);
          } else {
            createdAgenda[idxEle] = state.agendaIncumbent;
          }
        }
      }
      if (size(agendas) === 0) {
        agendas.push(state.agendaIncumbent);
      } else {
        if (size(agendas) > 0) {
          let idxEle = agendas.findIndex(
            (item) => item.agenda_id === state.agendaIncumbent.agenda_id
          );

          if (idxEle === -1) {
            agendas.unshift(state.agendaIncumbent);
          } else {
            agendas[idxEle] = state.agendaIncumbent;
          }
        }
      }
      state.createdAgenda = createdAgenda;
      state.agendas = agendas;
    },
    updateExistedAgendas: (state, action) => {
      let existedAgenda = cloneDeep(state.existedAgenda);
      action.payload.forEach((item) => {
        existedAgenda.unshift(item);
      });
      state.existedAgenda = uniqBy(existedAgenda, 'agenda_id');
    },
    updateExistedAgendasToList: (state, action) => {
      let agendas = cloneDeep(state.agendas);
      agendas.unshift(...action.payload);
      state.agendas = uniqBy(agendas, 'agenda_id');
    },
    updateExistedAgendasWhenChangeStatus: (state, action) => {
      let agendas = cloneDeep(state.agendas);
      let newAgendas = [];
      newAgendas = agendas.map((d) => {
        if (d.agenda_id === action.payload.agenda_id) {
          return { ...d, status: 'Paper bring to forward', isSaved: 'isSaved' };
        }
        return d;
      });
      state.agendas = uniqBy(newAgendas, 'agenda_id');
    },
    getAgendas: (state, action) => {
      if (size(state.agendas) === 0) {
        state.agendas = action.payload;
      }
    },
    updateAgendaIncumbentRemark: (state, action) => {
      state.agendaIncumbent.remarks = action.payload;
    },
    removePosition: (state, action) => {
      state.agendaIncumbent.positions = state.agendaIncumbent.positions.filter(
        (item) => item.position_code !== action.payload
      );
      const isExistPositionCode = state.position_code.filter((item) => item !== action.payload);
      if (isEmpty(isExistPositionCode)) {
        state.agendaIncumbent.remarks = '';
        state.agendaIncumbent.remarks_from_pending_action = [];
      }
      state.position_code = state.position_code.filter((item) => item !== action.payload);
    },
    resetAsMainAgenda: (state, action) => {
      state.agendaIncumbent.agenda_as_main_positions = action.payload;
      if (isEmpty(action.payload)) {
        state.agendaIncumbent.agenda_as_main = null;
      }
    },
    setAsMainAgenda: (state, action) => {
      const mainAgenda = action.payload;
      const positionsCode = mainAgenda.positions.map((position) => position.position_code);
      state.agendaIncumbent.positions = mainAgenda.positions;
      state.agendaIncumbent.first_line = convertLineForSetAsMain(
        mainAgenda.first_line,
        mainAgenda.agenda_id
      );
      state.agendaIncumbent.second_line = convertLineForSetAsMain(
        mainAgenda.second_line,
        mainAgenda.agenda_id
      );
      state.agendaIncumbent.third_line = convertLineForSetAsMain(
        mainAgenda.third_line,
        mainAgenda.agenda_id
      );
      state.agendaIncumbent.agenda_as_main = mainAgenda.agenda_id;
      state.agendaIncumbent.agenda_as_main_type =
        mainAgenda.action_type || mainAgenda.agenda_as_main.action_type;
      state.agendaIncumbent.agenda_as_main_platform =
        mainAgenda.meeting_name || mainAgenda.agenda_as_main.meeting_name;
      state.agendaIncumbent.agenda_as_main_start_at =
        mainAgenda.meeting_start_at || mainAgenda.agenda_as_main.meeting_start_at;
      state.agendaIncumbent.agenda_as_main_positions = positionsCode;
      state.position_code = positionsCode;
      state.agendaIncumbent.remarks = mainAgenda.remarks;
    },
    removeAgenda: (state, action) => {
      state.agendas = state.agendas.filter((item) => item.agenda_id !== action.payload);
    },
    removeCreatedOrExistedAgenda: (state, action) => {
      state.createdAgenda = state.createdAgenda.filter((item) => item.agenda_id !== action.payload);
      state.existedAgenda = state.existedAgenda.filter((item) => item.agenda_id !== action.payload);
    },
    updateRemoveAgendas: (state, action) => {
      state.removeList.push(action.payload);
    },
    setAgendaIncumbent: (state, action) => {
      state.agendaIncumbent = state.createdAgenda[action.payload];
    },
    setInitialStateAgenda: (state) => {
      state.agendaIncumbent = angedaIncumbentInitialState;
      state.position_code = [];
    },

    clearAgendaState: (state) => {
      state.agendaIncumbent = angedaIncumbentInitialState;
      state.position_code = [];
      state.agendas = [];
      state.removeList = [];
      state.createdAgenda = [];
      state.existedAgenda = [];
      state.loading = false;
      state.temporaryIdMsAgenda = '';
      state.temporaryIdTalentReviewAgenda = '';
      state.changeStatusList = [];
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    getAgendaIncumbent: (state, action) => {
      // get Agenda Incumbent
      state.agendaIncumbent.agenda_id = action.payload.agenda_id;
      state.agendaIncumbent.succession_planning_name = action.payload.agenda_name;
      state.agendaIncumbent.start_time = action.payload.start_time
        ? moment(action.payload.start_time).format(DATE_TIME_FORMAT.TIME)
        : '';
      state.agendaIncumbent.end_time = action.payload.end_time
        ? moment(action.payload.end_time).format(DATE_TIME_FORMAT.TIME)
        : '';
      state.agendaIncumbent.advocators = action.payload.advocators.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      state.agendaIncumbent.positions = action.payload.positions;
      state.agendaIncumbent.first_line = action.payload.first_line;
      state.agendaIncumbent.second_line = action.payload.second_line;
      state.agendaIncumbent.third_line = action.payload.third_line;
      state.agendaIncumbent.cc_list = action.payload.ccList.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      state.agendaIncumbent.remarks = action.payload.remarks;
      state.agendaIncumbent.business = 'CCP Succession Planning';
      state.agendaIncumbent.meeting_id = action.payload.meeting_id;
      state.agendaIncumbent.version = action.payload.version;
      // get Position_code for bestfistrecommendation
      state.position_code = action.payload.positions.map((item) => item.position_code);
      state.agendaIncumbent.status = action.payload.status;
      state.agendaIncumbent.action_type = action.payload.action_type;
      state.agendaIncumbent.agenda_as_main = action.payload.agenda_as_main;
      state.agendaIncumbent.agenda_as_main_type = action.payload.agenda_as_main_type;
      state.agendaIncumbent.agenda_as_main_platform = action.payload.agenda_as_main_platform;
      state.agendaIncumbent.agenda_as_main_start_at = action.payload.agenda_as_main_start_at;
      state.agendaIncumbent.agenda_as_main_positions =
        action.payload.agenda_as_main_positions?.split(',') || [];
    },
    clearTemporaryIdMsAgenda: (state) => {
      state.temporaryIdMsAgenda = '';
    },
    mobilitySimulationAgendaSave: (state, action) => {
      const mobilitySimulationAgenda = action.payload;
      mobilitySimulationAgenda['type_agenda'] = 'MS';

      if (!mobilitySimulationAgenda.agenda_id) {
        mobilitySimulationAgenda.agenda_id = uuidv4();
        state.temporaryIdMsAgenda = mobilitySimulationAgenda.agenda_id;
      }
      let createdAgenda = cloneDeep(state.createdAgenda);
      let agendas = cloneDeep(state.agendas);
      if (size(createdAgenda) === 0) {
        createdAgenda.push(mobilitySimulationAgenda);
      } else {
        if (size(createdAgenda) > 0) {
          let idxEle = createdAgenda.findIndex(
            (item) => item.agenda_id === mobilitySimulationAgenda.agenda_id
          );
          if (idxEle === -1) {
            createdAgenda.unshift(mobilitySimulationAgenda);
          } else {
            createdAgenda[idxEle] = mobilitySimulationAgenda;
          }
        }
      }
      if (size(agendas) === 0) {
        agendas.push(mobilitySimulationAgenda);
      } else {
        if (size(agendas) > 0) {
          let idxEle = agendas.findIndex(
            (item) => item.agenda_id === mobilitySimulationAgenda.agenda_id
          );

          if (idxEle === -1) {
            agendas.unshift(mobilitySimulationAgenda);
          } else {
            agendas[idxEle] = mobilitySimulationAgenda;
          }
        }
      }

      state.createdAgenda = createdAgenda;
      state.agendas = agendas;
    },
    clearTemporaryTalentReviewAgenda: (state) => {
      state.temporaryIdTalentReviewAgenda = '';
    },
    talentReviewAgendaSave: (state, action) => {
      const talentReviewAgenda = action.payload;
      talentReviewAgenda['type_agenda'] = AGENDA_TYPES.TALENT;

      if (!talentReviewAgenda.agenda_id) {
        talentReviewAgenda.agenda_id = uuidv4();
        state.temporaryIdTalentReviewAgenda = talentReviewAgenda.agenda_id;
      }
      let createdAgenda = cloneDeep(state.createdAgenda);
      let agendas = cloneDeep(state.agendas);
      if (size(createdAgenda) === 0) {
        createdAgenda.push(talentReviewAgenda);
      } else {
        if (size(createdAgenda) > 0) {
          let idxEle = createdAgenda.findIndex(
            (item) => item.agenda_id === talentReviewAgenda.agenda_id
          );
          if (idxEle === -1) {
            createdAgenda.unshift(talentReviewAgenda);
          } else {
            createdAgenda[idxEle] = talentReviewAgenda;
          }
        }
      }
      if (size(agendas) === 0) {
        agendas.push(talentReviewAgenda);
      } else {
        if (size(agendas) > 0) {
          let idxEle = agendas.findIndex((item) => item.agenda_id === talentReviewAgenda.agenda_id);

          if (idxEle === -1) {
            agendas.unshift(talentReviewAgenda);
          } else {
            agendas[idxEle] = talentReviewAgenda;
          }
        }
      }

      state.createdAgenda = createdAgenda;
      state.agendas = agendas;
    },

    updateChangeStatusList: (state, action) => {
      state.changeStatusList = action.payload;
    },
    removeTalents: (state, action) => {
      if (action.payload.code === 'first_line') {
        state.agendaIncumbent.first_line = handleOrderWhenRemoved(action.payload.line);
      }
      if (action.payload.code === 'second_line') {
        state.agendaIncumbent.second_line = handleOrderWhenRemoved(action.payload.line);
      }
      if (action.payload.code === 'third_line') {
        state.agendaIncumbent.third_line = handleOrderWhenRemoved(action.payload.line);
      }
    },
    orderTalents: (state, action) => {
      state.agendaIncumbent.first_line = action.payload.first_line;
      state.agendaIncumbent.second_line = action.payload.second_line;
      state.agendaIncumbent.third_line = action.payload.third_line;
    },
    removeActionType: (state, action) => {
      const agendaId = action.payload;
      let createdAgenda = cloneDeep(state.createdAgenda);
      let agendas = cloneDeep(state.agendas);

      if (size(createdAgenda) > 0) {
        createdAgenda = createdAgenda.map((item) => {
          if (item.agenda_id === agendaId) {
            return {
              ...item,
              action_type: '',
            };
          } else {
            return item;
          }
        });
      }

      if (size(agendas) > 0) {
        agendas = agendas.map((item) => {
          if (item.agenda_id === agendaId) {
            return {
              ...item,
              action_type: '',
            };
          } else {
            return item;
          }
        });
      }

      state.createdAgenda = createdAgenda;
      state.agendas = agendas;
    },
    saveAsDraftOthersAgenda: (state, action) => {
      const { othersAgenda, isSavedAsDraft } = action.payload;
      othersAgenda.type_agenda = AGENDA_TYPES.OTHERS;
      let createdAgenda = cloneDeep(state.createdAgenda);
      let agendas = cloneDeep(state.agendas);

      if (!othersAgenda.agenda_id && !isSavedAsDraft) {
        othersAgenda.agenda_id = uuidv4();
        createdAgenda.unshift(othersAgenda);
        agendas.unshift(othersAgenda);
      }

      let currentCreatedIdx = -1;
      let currentAgendaIdx = -1;

      if (othersAgenda.agenda_id) {
        currentCreatedIdx = createdAgenda.findIndex((i) => i.agenda_id === othersAgenda.agenda_id);
        currentAgendaIdx = createdAgenda.findIndex((i) => i.agenda_id === othersAgenda.agenda_id);
      } else if (isSavedAsDraft) {
        othersAgenda.agenda_id = uuidv4();
        currentCreatedIdx = 0;
        currentAgendaIdx = 0;
      }

      if (currentCreatedIdx >= 0 && currentAgendaIdx >= 0) {
        createdAgenda[currentCreatedIdx] = othersAgenda;
        agendas[currentAgendaIdx] = othersAgenda;
      }

      state.createdAgenda = createdAgenda;
      state.agendas = agendas;
    },
    addNewFileForAgenda: (state, action) => {
      let files = cloneDeep(state.temporaryFiles);
      if (size(files) === 0) {
        files.push(action.payload);
      } else {
        if (size(files) > 0) {
          let idxEle = files.findIndex((item) => item.tempId === action.payload.tempId);
          if (idxEle === -1) {
            files.unshift(action.payload);
          } else {
            files[idxEle] = action.payload;
          }
        }
      }
      state.temporaryFiles = files;
    },
    removeTempFileUploadingFail: (state) => {
      let files = cloneDeep(state.temporaryFiles);
      files.splice(-1);
      state.temporaryFiles = files;
    },
    removeTempFileForAgenda: (state, action) => {
      let files = cloneDeep(state.temporaryFiles);
      let tempData = files.filter((item) => item.tempId !== action.payload.tempId);
      state.temporaryFiles = tempData;
    },
    resetFileForAgenda: (state) => {
      state.temporaryFiles = [];
    },
    updateTemporaryPrevAgenda: (state, action) => {
      state.temporaryPrevAgenda = action.payload;
    },
    resetTemporaryPrevAgenda: (state) => {
      state.temporaryPrevAgenda = null;
    },
  },
});

const { actions, reducer: agendaReducer } = agendaSlice;
export const {
  getAgenda,
  addPosition,
  updateTalents,
  updateAgendaLine,
  updateAgendaIncumbent,
  updateAgendaIncumbentRemark,
  removePosition,
  resetAsMainAgenda,
  setAsMainAgenda,
  setInitialStateAgenda,
  removeAgenda,
  setAgendaIncumbent,
  clearAgendaState,
  getAgendas,
  updateExistedAgendas,
  updateExistedAgendasToList,
  updateRemoveAgendas,
  removeCreatedOrExistedAgenda,
  updateLoading,
  getAgendaIncumbent,
  updateExistedAgendasWhenChangeStatus,
  mobilitySimulationAgendaSave,
  clearTemporaryIdMsAgenda,
  talentReviewAgendaSave,
  clearTemporaryTalentReviewAgenda,
  updateChangeStatusList,
  removeTalents,
  orderTalents,
  removeActionType,
  updateTalentsPopulate,
  saveAsDraftOthersAgenda,
  addNewFileForAgenda,
  removeTempFileUploadingFail,
  removeTempFileForAgenda,
  resetFileForAgenda,
  updateTemporaryPrevAgenda,
  resetTemporaryPrevAgenda,
  updatePositions,
} = actions;
export default agendaReducer;
