import { isArray } from 'lodash';
import React from 'react';

import { exclamation_point_blue, x_close_popup } from '../../assets/img';
import styles from './disclaimer.module.scss';

const Disclaimer = (props) => {
  const { hasCloseBtn, dataTitle, dataContent, customStyle } = props;

  const getDataContent = () => {
    if (isArray(dataContent)) {
      return dataContent.map((item) => (
        <li
          key={item?.id}
          className={`${styles.content_li} ${dataContent.length === 1 && styles.only_one_item}`}
        >
          {item?.label}
        </li>
      ));
    }
  };

  return (
    <div
      style={{
        marginTop: customStyle?.marginTop || '32px',
        marginBottom: customStyle?.marginBottom || 0,
      }}
    >
      <div className={styles.form_wrapper}>
        <div className={styles.title_info}>
          <img src={exclamation_point_blue} alt="exclamation_point_blue" />
          <span style={{ marginLeft: '12px', verticalAlign: 'middle' }}>Disclaimer</span>
          {hasCloseBtn && (
            <div className={styles.close_btn}>
              <img src={x_close_popup} alt="x_close_popup" />
            </div>
          )}
        </div>
        <div>
          <ul className={styles.content}>
            {dataTitle}
            {getDataContent()}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
