import __, { isEmpty, map, some } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import styles from './CustomFilterTable.module.scss';
import Spinner from '../Loading/Spinner';
import Checkbox from '../CheckBox/CheckBox';

export const CustomFilterTable = ({
  setSelectedKeys,
  selectedKeys,
  filters,
  isLoading,
  onApplyFilters,
  close,
}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    setSelectedFilters(
      map(filters, (filter) => ({
        label: filter.text,
        value: filter.value,
        isChecked: some(selectedKeys, (selected) => selected === filter.value),
      }))
    );
  }, [filters, selectedKeys]);

  const handleSelectFilter = useCallback((filters) => {
    setSelectedFilters(
      map(filters, (filter) => {
        return { text: filter.label, ...filter };
      })
    );
  }, []);

  const handleApply = useCallback(() => {
    const selectedKeys = __(selectedFilters)
      .filter((filter) => filter.isChecked)
      .map('value')
      .value();

    onApplyFilters(selectedKeys);
    close();
  }, [selectedFilters, onApplyFilters, close]);

  if (isLoading) {
    return (
      <div className={styles.flex_center}>
        <Spinner />
      </div>
    );
  }

  if (isEmpty(filters)) {
    return <div className={styles.flex_center}>No Filters</div>;
  }

  return (
    <>
      <div>
        <div className={styles.dropdown__filters}>
          {map(selectedFilters, (filter) => (
            <Checkbox
              key={filter.value}
              item={filter}
              select={selectedFilters}
              setSelect={handleSelectFilter}
              customStyle={true}
            />
          ))}
          {!isEmpty(selectedFilters) && (
            <Checkbox
              item={{ label: 'All', value: 'all' }}
              select={selectedFilters}
              setSelect={handleSelectFilter}
              customStyle={true}
            />
          )}
        </div>
        <div className={styles.dropdown__apply}>
          <button className={styles.dropdown__btn} onClick={handleApply}>
            Apply
          </button>
        </div>
      </div>
    </>
  );
};
