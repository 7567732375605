import { dashboard_breadcrumb } from '../../../assets/img';

export const TALENT_REVIEW_AGENDA_BREADCRUMB = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Meeting details', url: '' },
  { name: 'Agenda', url: '' },
  { name: 'Talent Review' },
];

export const SP_DASHBOARD_TALENT_REVIEW_BREADCRUMB = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Succession Planning', url: '/succession-planning-page' },
  { name: 'Talent Review' },
];

export const TALENT_REVIEW_FORM_MODE = {
  EDIT: 'edit',
  CREATE: 'create',
  VIEW: 'view',
};

export const TALENT_REVIEW_MESSAGE = {
  IN_VALID_FORM: 'There are items that require your attention. Please fill out this field.',
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  AGENDA_SAVE_SUCCESS: 'Agenda have been saved as draft',
  AGENDA_UPDATE_SUCCESS: 'Agenda have been saved',
  SAVE_TALENT_SUCCESS: 'Talent added successfully',
  DELETE_TALENT_SUCCESS: 'Talent removed successfully',
  PAPER_READY_SUCCESS: 'Talent Review is ready for the meeting',
  EDIT_PAPER_SUCCESS: 'Talent Review is ready to be edit',
  TALENT_STATUS_UPDATE_SCUCCESS: 'Talent Status successfully edited',
  ADD_POSITION_SUCCESS: 'Position added successfully to Succession Planning',
};

export const DEVELOPMENT_PLAN_MESSAGE = {
  EDITED: 'Development Plan successfully edited',
  DELETED: 'Development Plan successfully deleted',
};

export const TALENT_REVIEW_ROLE = {
  SM_M_EXECUTIVE: ['SM', 'M', 'EXECUTIVE', 'Manager', 'Senior Manager', 'Executive'],
  VP_SGM_GM: ['VP', 'SGM', 'GM', 'General Manager', 'Senior General Manager', 'Vice President'],
};

export const MOBILITY_PLAN_TYPE = {
  END_STATE: 'End State',
  NEXT_MOBILITY: 'Next Mobility',
  NEXT_2_MOBILITY: 'Next 2 Mobility',
};

export const TR_REMARK_CATEGORY = [
  { label: 'Notes', value: 'Notes' },
  { label: 'Key Strength', value: 'Key Strength' },
  { label: 'Development Areas', value: 'Development Areas' },
];
