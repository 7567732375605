import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { ModelTC, SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { masterDataManagementApi } from '../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { CONTENT_SEARCH_NOT_FOUND, MESSAGE_TYPES, SELECT_ALL_LABEL } from '../../../../utils/constants';
import MenuBarManaging from '../components/MenuBarManaging/MenuBarManaging';
import PillManaging from '../components/PillManaging/PillManaging';
import { PROFILE_MANAGEMENT_MESSAGES } from '../constant';
import DataTableManaging from './DataTableManaging/DataTableManaging';
import { setRoleActive } from '../../../../store/userSlice';

const INIT_SEARCH_PARAMS = {
  roleLevelSelected: [],
  businessSelected: [],
  keyword: [],
  page: 1,
};

export default function ListOfProfiles() {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const limit = 15;

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
        })
      );
    },
    [dispatch]
  );

  const handleResetData = useCallback(() => {
    setData([]);
    setTotalData(0);
    setLoading(false);
  }, [setData, setTotalData, setLoading]);

  const fetchData = useCallback(async () => {
    setData([]);
    const { keyword, page, roleLevelSelected, businessSelected } = searchParams;
    try {
      setLoading(true);
      const query = {
        page,
        limit,
        keyword,
        role_level: !isEmpty(roleLevelSelected)
          ? roleLevelSelected
              .filter((item) => item?.label !== SELECT_ALL_LABEL)
              .map((item) => {
                return item?.value || item?.label;
              })
          : [],
        business: !isEmpty(businessSelected)
          ? businessSelected
              .filter((item) => item?.label !== SELECT_ALL_LABEL)
              .map((item) => {
                return item?.value || item?.label;
              })
          : [],
      };
      const res = await masterDataManagementApi.getProfileList(query, roleActive?.roleId);
      if (res.status === 200 && !isEmpty(res.data.result?.profiles)) {
        const resData = res.data.result;
        setData(
          resData?.profiles?.map((item) => ({
            key: item.staff_id,
            ...item,
          }))
        );
        setTotalData(resData.total);
      } else {
        handleResetData();
        handleShowMessage(MESSAGE_TYPES.ERROR, PROFILE_MANAGEMENT_MESSAGES.SEARCH_NOT_FOUND);
      }
    } catch (error) {
      handleResetData();
      console.error(error);
    }
    setLoading(false);
  }, [searchParams, roleActive, handleResetData, setData, setTotalData, handleShowMessage]);

  const handleDeleteRecord = async () => {
    try {
      const ids = deleteRecord?.staff_id ? [deleteRecord?.staff_id] : deleteSelecteds.map((item) => item.staff_id);
      const res = await adminApi.deleteFromListProfiles(ids);
      if (res.status === 200) {
        const names = deleteRecord?.staff_id
          ? [deleteRecord?.staff_id]
          : deleteSelecteds.filter((item) => item.staff_name).map((item) => item.staff_name);
        handleShowMessage(MESSAGE_TYPES.SUCCESS, `${names.join(', ') || ''} ${PROFILE_MANAGEMENT_MESSAGES.LIST_PROFILE_DELETE_SUCCESS}`);
        if ((deleteRecord?.staff_id && data.length === 1) || (!deleteRecord?.staff_id && data.length === deleteSelecteds.length)) {
          setSearchParams(INIT_SEARCH_PARAMS);
          return;
        }
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteSelecteds([]);
      setDeleteRecord(null);
      setShowDeleteModal(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
        <MenuBarManaging
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setShowDeleteModal={setShowDeleteModal}
          loading={loading}
          deleteSelecteds={deleteSelecteds}
          totalData={totalData}
          fetchData={fetchData}
        />
        <PillManaging searchParams={searchParams} setSearchParams={setSearchParams} />
        {!isEmpty(data) ? (
          <DataTableManaging
            data={data}
            setDeleteRecord={setDeleteRecord}
            setDeleteSelecteds={setDeleteSelecteds}
            deleteSelecteds={deleteSelecteds}
            handleDeleteRecord={handleDeleteRecord}
            totalData={totalData}
            setShowDeleteModal={setShowDeleteModal}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            limit={limit}
            setIsSearch={setIsSearch}
          />
        ) : (
          <SearchDataTableNotFound content={CONTENT_SEARCH_NOT_FOUND.LIST_OF_PROFILES} notFoundIcon={search_data_not_found} />
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'deleteTalentProfile',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          handleSubmit: () => handleDeleteRecord(),
          loading,
          onClose: () => {
            setShowDeleteModal(false);
          },
        }}
      />
    </>
  );
}
