import { Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicButton } from '../../../../../assets/common';
import { avatar, staff_jg_sg_action } from '../../../../../assets/img';
import { profileManagementApi } from '../../../../../services/profileManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../../utils/helper';
import { Dropdown } from '../Dropdown/Dropdown';
import * as styles from './data-table.module.scss';
import * as SC from './styled';

const DataTable = ({
  data,
  isEdit,
  setIsEdit,
  query,
  setQuery,
  sgJgOptions,
  getStaffSgJgManagement,
}) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(query.page - 1) * query.limit + 1 + index}</span>
      ),
      width: '5%',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'avatar',
      render: (_, record) => (
        <span className={`${styles.textField}`} style={{ fontWeight: 700, color: '#3F3C4C' }}>
          <img
            className={styles.customImg}
            src={decodeBase64ToImage(record?.avatar) || avatar}
            alt="avatar"
          />
        </span>
      ),
      width: '5%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: 'Staff ID',
      dataIndex: 'staff_id',
      width: '5%',
    },
    {
      title: 'Business',
      dataIndex: 'business',
      width: '12%',
      align: 'center',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: '12%',
      align: 'center',
    },
    {
      title: 'Job Grade',
      dataIndex: 'job_grade',
      width: '15%',
      align: 'center',
    },
    {
      title: 'Salary Grade',
      dataIndex: 'salary_grade',
      width: '15%',
      align: 'center',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'x',
      align: ' center',
    },
  ];

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = (query.page - 1) * query.limit + query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.totalPage,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > query.totalPage ? query.totalPage : toResults}</b> of&nbsp;
        <b>{query.totalPage}</b> results
      </>
    ),
  };

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.SUCCESS].includes(type),
        })
      );
    },
    [dispatch]
  );

  async function editSgJgStaffManagement() {
    try {
      await profileManagementApi.editSgJgStaffManagement(roleActive, isEdit);
      await getStaffSgJgManagement();
      handleShowMessage(
        MESSAGE_TYPES.SUCCESS,
        `${isEdit.birth_name}'s Job Grade/Salary Grade has been successfully edited.`
      );
    } catch (error) {
      console.log(error);
    }
    setIsEdit(null);
  }

  async function validateSgJS() {
    if (!isEdit.salary_grade || !isEdit.job_grade) {
      handleShowMessage(
        MESSAGE_TYPES.ERROR,
        `There are items that require your attention. Please fill out this field.`
      );
      setIsEdit((prev) => {
        return {
          ...prev,
          error: true,
        };
      });
      return;
    }

    await editSgJgStaffManagement();
  }
  return (
    <div style={{ marginTop: 24, marginBottom: 150 }}>
      <SC.TableManagement>
        <Table
          columns={columns}
          dataSource={data.map((item, _index) => ({
            key: item.staff_id,
            avatar: item.image,
            name: <span className={styles.positionName}>{item.birth_name}</span>,
            staff_id: <span className={styles.font}>{item.staff_id}</span>,
            business: <div className={styles.font}>{item.bu_name}</div>,
            company: <div className={styles.font}>{item.opu_name}</div>,
            job_grade:
              isEdit?.staff_id === item.staff_id ? (
                <div className="d-flex justify-content-center">
                  <Dropdown
                    selected={isEdit?.job_grade}
                    setSelected={(value) => {
                      setIsEdit((prev) => {
                        return {
                          ...prev,
                          job_grade: value,
                        };
                      });
                    }}
                    options={sgJgOptions.jgOptions}
                    isError={isEdit && isEdit.error ? true : false}
                  />
                </div>
              ) : (
                <div className={styles.font}>{item.job_grade}</div>
              ),
            salary_grade:
              isEdit?.staff_id === item.staff_id ? (
                <div className="d-flex justify-content-center">
                  <Dropdown
                    selected={isEdit?.salary_grade}
                    setSelected={(value) => {
                      setIsEdit((prev) => {
                        return {
                          ...prev,
                          salary_grade: value,
                        };
                      });
                    }}
                    options={sgJgOptions.sgOptions}
                    isError={isEdit?.error ? true : false}
                  />
                </div>
              ) : (
                <div className={styles.font}>{item.salary_grade}</div>
              ),
            action:
              isEdit?.staff_id === item.staff_id ? (
                <BasicButton mode="teal" onKeyDown={() => {}} onClick={() => validateSgJS()}>
                  Save
                </BasicButton>
              ) : (
                <Space>
                  <img
                    src={staff_jg_sg_action}
                    alt="dot"
                    style={{ cursor: 'pointer', pointerEvents: 'all' }}
                    onKeyDown={() => {}}
                    onClick={() => {
                      setIsEdit(item);
                    }}
                  />
                </Space>
              ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.array,
  isEdit: PropTypes.object,
  setIsEdit: PropTypes.func,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  sgJgOptions: PropTypes.object,
  getStaffSgJgManagement: PropTypes.func,
};

export default DataTable;
