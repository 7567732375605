import React, { useState } from 'react';
import styles from './switch.module.scss';

const Switch = ({ setIsActive }) => {
  const [hide, setHide] = useState(true);

  const handleHidden = () => {
    setHide(!hide);
    setIsActive(!hide);
  };

  return (
    <>
      <label className={styles.toggle} htmlFor="toggle" data-testid="switch">
        <input type="checkbox" id="toggle" onClick={() => handleHidden()} value={hide} />
        <div className={styles.slider}></div>
      </label>
      <p className={styles.hideText}>{hide === true ? 'Hide' : 'Unhide'}</p>
    </>
  );
};
export default Switch;
