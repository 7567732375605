import React, { useEffect, useRef, useState } from 'react';

import Rectangle from '../../assets/img/Rectangle.svg';
import styles from './category-dropdown-custom.module.scss';
import { AiDropdown, AiDropdownCustom } from './Styled';
import { clear_icon } from '../../assets/img';

const CategoryDropdownCustom = ({
  selected,
  setSelected,
  options,
  placeholder,
  status,
  disabled,
  hasCustom,
  clearIcon = null,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isShowClearIcon, setIsShowClearIcon] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      {!hasCustom && (
        <AiDropdown
          onKeyDown={() => {}}
          onClick={() => setIsActive(!isActive)}
          status={status}
          disabled={disabled}
          onMouseEnter={() => selected && clearIcon !== null && setIsShowClearIcon(true)}
          onMouseLeave={() => selected && clearIcon !== null && setIsShowClearIcon(false)}
        >
          <p className={styles.dropdownSelect__label}>
            {selected?.name || (
              <span style={{ color: '#8D8D8D', fontSize: '14px' }}>{placeholder}</span>
            )}
          </p>
          {!isShowClearIcon && <img src={Rectangle} alt="" width="10" height="10" />}
        </AiDropdown>
      )}
      {isShowClearIcon && (
        <img
          src={clear_icon}
          alt=""
          height={20}
          className={styles.clearIcon}
          onKeyDown={() => {}}
          onClick={() => {
            clearIcon();
            setIsShowClearIcon(false);
          }}
          onMouseEnter={() => selected && clearIcon !== null && setIsShowClearIcon(true)}
          onMouseLeave={() => selected && clearIcon !== null && setIsShowClearIcon(false)}
        />
      )}

      {hasCustom && (
        <AiDropdownCustom
          onKeyDown={() => {}}
          onClick={() => setIsActive(!isActive)}
          status={status}
          disabled={disabled}
        >
          <p
            className={`${styles.dropdownSelect__label} ${
              placeholder && styles.dropdownSelect__placeholder
            }`}
            style={{ display: 'inline-block' }}
          >
            {selected?.name || placeholder}
          </p>
          <img src={Rectangle} alt="" width="10" height="10" />
        </AiDropdownCustom>
      )}

      <div
        className={styles.dropdownContent}
        style={{
          maxHeight: isActive && '260px',
          overflowY: isActive && 'auto',
          top: hasCustom ? '30px' : '50px',
        }}
      >
        {isActive &&
          !disabled &&
          options &&
          options?.map((option) => {
            return (
              <div
                key={option?.id}
                onKeyDown={() => {}}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={styles.dropdownItem}
              >
                {option?.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryDropdownCustom;
