import { createSlice } from '@reduxjs/toolkit';

const initialStateStepper = {
  currentTabActive: 1,
  currentTabFinished: [],
  tabsActive: []
};
const stepperSlice = createSlice({
  name: 'stepper',
  initialState: {
    stepper: initialStateStepper,
    loading: false,
  },
  reducers: {
    getStepper: (state, action) => { return state.stepper},
    addOrUpdateStepper: (state, action) => {
      state.stepper = action.payload;
    },
    clearStepper: (state, action) => {
      state.stepper = initialStateStepper;
    },
  },
});
const { actions, reducer: stepperReducer } = stepperSlice;
export const { getStepper, addOrUpdateStepper, clearStepper } = actions;
export default stepperReducer;
