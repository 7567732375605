import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BasicButton, BasicInput } from '../../../../../assets/common';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { CustomDropdownTreeSelect, CustomRangePicker, FilterPill } from '../../../../../components';
import { adminApi } from '../../../../../services/admin';
import { ASSESSMENT_OPTIONS } from '../../constant';
import styles from './top-talent-status-menu-bar.module.scss';

export default function TopTalentStatusMenuBar({ searchParams, setSearchParams, deleteParams, setShowDeleteConfirm }) {
  const history = useHistory();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [searchInput, setSearchInput] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [topTalentStatusOptions, setTopTalentStatusOptions] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const formik = useFormik({
    initialValues: {
      date_range: [null, null],
      top_talent_status: [],
      assessment_type: [],
    },
  });

  const emptySelected = useMemo(() => isEmpty(deleteParams.selectedRecords), [deleteParams.selectedRecords]);
  const activeClass = useMemo(() => (emptySelected ? styles.inActiveDelBtn : styles.activeDelBtn), [emptySelected]);

  const fetchFilter = async () => {
    try {
      const res = await adminApi.getOptionsTopTalentStatus(roleActive);
      if (res.status === 200) {
        setTopTalentStatusOptions(
          res.data.result.top_talent_status_categories.map((item) => ({
            label: item.category,
            value: item.category,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickAddNew = () => {
    history.push(`/admin/profile-management/add-new-top-talent-status`);
  };

  const handleClickSearch = () => {
    setSearchParams((prevState) => ({ ...prevState, staff_id: searchInput, page: 1 }));
  };

  const handleClickDelete = () => {
    setShowDeleteConfirm(true);
  };

  const handleFilter = () => {
    if (isFirstRender) setIsFirstRender(false);
  };

  const handleClickFilter = () => {
    if (showFilter) {
      formik.resetForm();
    }
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    fetchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      const { date_range, assessment_type, top_talent_status } = formik.values;
      const topTalentValues = top_talent_status.filter((item) => item.value !== 'all').map((item) => item.value);
      const assessmentTypeValues = assessment_type.filter((item) => item.value !== 'all').map((item) => item.value);

      setSearchParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          from_date: date_range[0] ? moment(date_range[0]).format('YYYY-MM-DD') : null,
          to_date: date_range[1] ? moment(date_range[1]).format('YYYY-MM-DD') : null,
          top_talent_status: topTalentValues,
          assessment_type: assessmentTypeValues,
        };
      });
    }
  }, [formik.values, setSearchParams, isFirstRender]);

  useEffect(() => {
    setSearchInput(searchParams.staff_id)
  }, [searchParams.staff_id]);

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <BasicInput
                data-testid="search-input"
                placeholder="Search Top Talent Status"
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickSearch}>
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <BasicButton
                mode="danger"
                onKeyDown={() => {}}
                onClick={handleClickDelete}
                className={`${styles.button} ${activeClass}`}
                disabled={emptySelected}
              >
                Confirm Delete Status
              </BasicButton>
            </Col>
            <Col>
              <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickAddNew}>
                Add New
              </BasicButton>
            </Col>
            <Col>
              <div className={styles.icons}>
                <img src={showFilter ? finder_filter_enable : finder_filter_disable} alt="Filter" onKeyDown={() => {}} onClick={handleClickFilter} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <>
          <Row gutter={16} className="mt-3">
            <Col span={6}>
              <CustomRangePicker
                dateRange={formik.values.date_range}
                setDateRange={(value) => formik.setFieldValue('date_range', value)}
                height={42}
                placeholder={['From Date', 'To Date']}
                onSelect={handleFilter}
              />
            </Col>
            <Col span={4}>
              <CustomDropdownTreeSelect
                data={topTalentStatusOptions}
                placeholder="Top Talent Status"
                value={formik.values.top_talent_status}
                setValue={(value) => formik.setFieldValue('top_talent_status', value)}
                onChange={handleFilter}
              />
            </Col>
            <Col span={4}>
              <CustomDropdownTreeSelect
                data={ASSESSMENT_OPTIONS}
                placeholder="Assessment Type"
                value={formik.values.assessment_type}
                setValue={(value) => formik.setFieldValue('assessment_type', value)}
                onChange={handleFilter}
              />
            </Col>
          </Row>

          <FilterPill
            onRemove={handleFilter}
            style={{
              marginTop: '20px',
              marginBottom: '6px',
            }}
            data={{
              top_talent_status: formik.values.top_talent_status,
              assessment_type: formik.values.assessment_type,
            }}
            setData={{
              top_talent_status: (value) => formik.setFieldValue('top_talent_status', value),
              assessment_type: (value) => formik.setFieldValue('assessment_type', value),
            }}
          />
        </>
      )}
    </div>
  );
}
