import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import {
  edit_report_name,
  mask_icon,
  purple_shadown_circle,
  right_open_arrow,
  yellow_shadown_circle,
} from '../../assets/img';
import Logosm from '../../assets/img/Logosm.png';
import { fetchCareerJourner } from '../../services/talentProfiles';
import { CAREER_JOURNEY_EXPERIENCE_TYPE } from '../../utils/constants';
import { formatDateWithMonthAndYear } from '../../utils/helper';
import styles from './career-journey.module.scss';
import CareerJourneyItem from './component/CareerJourneyItem';
import ComplementaryExperience from '../ComplementaryExperience/ComplementaryExperience';

const CareerJourney = ({
  id,
  roleUser,
  setCareerJourneyData,
  noDetail,
  customStyle,
  isPrinting,
}) => {
  const initCareerJourneyData = { functional_experience: [], complementary_experience: [] };
  const [resExp, setResExp] = useState([]);
  const [detailsCareerJourny, setDetailsCareerJourny] = useState(initCareerJourneyData);
  const [lastPoint, setLastPoint] = useState({});
  const [totalDuration, setTotalDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [openView, setOpenView] = useState(false);

  const getCareerJourny = async () => {
    if (!id) return;
    try {
      setLoading(true);
      const response = await fetchCareerJourner({ roleUser, id });
      if (response.data.result?.res_exp && response.data.result.res_exp.length > 0) {
        let resExpAfterCalculate = calculateTimeLine(cloneDeep(response.data.result.res_exp));
        setCareerJourneyData(response.data.result);
        setTotalDuration(response.data.result.totalPeriod);
        setResExp(handleMileStone(resExpAfterCalculate, response.data.result.totalPeriod));
        setLastPoint(handleCheckLastPoint(resExpAfterCalculate));
      } else {
        setResExp([]);
        setLastPoint({});
      }
      if (response.data.result) {
        let convertFunctionExData = await handleConvertCareerJourny(
          response.data.result?.details_func,
          'functions'
        );
        let convertComplementaryData = await handleConvertCareerJourny(
          response.data.result?.complementary_exp,
          'job_function'
        );
        setDetailsCareerJourny({
          functional_experience: convertFunctionExData,
          complementary_experience: convertComplementaryData,
        });
      } else {
        setDetailsCareerJourny(initCareerJourneyData);
      }
      setLoading(false);
    } catch (error) {
      setResExp([]);
      setLastPoint({});
      setDetailsCareerJourny(initCareerJourneyData);
      setLoading(false);
      console.error(error);
    }
  };

  const handleConvertCareerJourny = async (data, key) => {
    if (!isEmpty(data)) {
      let arrayConvert = data.map((item) => item[key]);
      return Array.from(new Set(arrayConvert));
    } else {
      return [];
    }
  };

  useEffect(() => {
    getCareerJourny();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, id]);

  const handleShowDuration = (year, month) => {
    let yearText = '';
    let monthText = '';
    if (year === 1) {
      yearText = '1 Year';
    }
    if (year > 1) {
      yearText = `${year} Years`;
    }
    if (month === 1) {
      monthText = '1 Month';
    }
    if (month > 1) {
      monthText = `${month} Months`;
    }
    if (yearText) {
      return `${yearText} ${monthText}`;
    } else {
      return `${monthText}`;
    }
  };
  const calculateTimeLine = (timeline) => {
    let timeLineTotal = [];
    let item = timeline[0];
    for (let i = 1; i <= timeline.length; i++) {
      if (timeline[i] && item.business === timeline[i].business && item.role === timeline[i].role) {
        let year = 0;
        let month = 0;
        let unitTimeDuration = item.duration.split(' ');
        let unitEleDuation = timeline[i].duration.split(' ');
        if (unitTimeDuration[1] === 'Years' || unitTimeDuration[1] === 'Year') {
          year += Number(unitTimeDuration[0]);
        }
        if (unitTimeDuration[1] === 'Months' || unitTimeDuration[1] === 'Month') {
          month += Number(unitTimeDuration[0]);
          if (month >= 12) {
            month -= 12;
            year += 1;
          }
        }
        if (unitTimeDuration[3] === 'Months' || unitTimeDuration[3] === 'Month') {
          month += Number(unitTimeDuration[2]);
          if (month >= 12) {
            month -= 12;
            year += 1;
          }
        }
        if (unitEleDuation[1] === 'Years' || unitEleDuation[1] === 'Year') {
          year += Number(unitEleDuation[0]);
        }
        if (unitEleDuation[1] === 'Months' || unitEleDuation[1] === 'Month') {
          month += Number(unitEleDuation[0]);
          if (month >= 12) {
            month -= 12;
            year += 1;
          }
        }
        if (unitEleDuation[3] === 'Months' || unitEleDuation[3] === 'Month') {
          month += Number(unitEleDuation[2]);
          if (month >= 12) {
            month -= 12;
            year += 1;
          }
        }
        item = {
          ...item,
          date_to_str: timeline[i].date_to_str,
          duration: handleShowDuration(year, month),
        };
      } else {
        timeLineTotal.push(item);
        item = timeline[i];
      }
    }
    return timeLineTotal;
  };

  const handleMileStone = (resExperience, totalDuration) => {
    return resExperience.map((item) => {
      return {
        ...item,
        date_from_str: item.date_from_str.split(' ')[1],
        date_to_str: item.date_to_str !== 'Present' ? item.date_to_str.split(' ')[1] : 'Present',
        widthByPercent: calcWidthByPercent(item.duration, totalDuration),
      };
    });
  };

  const calcWidthByPercent = (duration, totalDuration) => {
    const arrTotalDuration = totalDuration.split(' ');
    const arrDuration = duration.split(' ');
    const month = Number(arrDuration[0]) * 12 + Number(arrDuration[2]);
    const monthTotal = Number(arrTotalDuration[0]) * 12 + Number(arrTotalDuration[2]);
    return (month * 640) / monthTotal < 36 ? 36 : (month * 640) / monthTotal;
  };

  const handleCheckLastPoint = (responseExp) => {
    let lastObject = {};
    let lastPointCheck = responseExp[responseExp.length - 1];
    if (lastPointCheck.date_to_str === 'Present') {
      lastObject = {
        ...responseExp[responseExp.length - 1],
        date_from_str: formatDateWithMonthAndYear(new Date()).split(' ')[1],
        date_to_str: formatDateWithMonthAndYear(new Date()).split(' ')[1],
      };
    } else {
      lastObject = {
        ...responseExp[responseExp.length - 1],
        date_from_str: lastPointCheck.date_to_str.split(' ')[1],
        date_to_str: lastPointCheck.date_to_str.split(' ')[1],
      };
    }
    return lastObject;
  };

  const returnCareerJourneyTypeContent = (data, type) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => {
        return (
          <p className={styles.CareerJourney__expItem} key={index}>
            <img
              className={styles.CareerJourney__expIcon}
              src={
                type === CAREER_JOURNEY_EXPERIENCE_TYPE.FUNCTIONAL
                  ? purple_shadown_circle
                  : yellow_shadown_circle
              }
              alt=""
            />
            {item}
          </p>
        );
      });
    }
  };

  return (
    <div
      className={styles.CareerJourney}
      data-testid="career-journey"
      style={customStyle?.chartWrapper || {}}
    >
      {!isPrinting && (
        <h3 className={styles.CareerJourney__h3} style={customStyle?.mainTitle || {}}>
          Career Journey{' '}
        </h3>
      )}
      <div className={styles.CareerJourney__milestone}>
        {!loading && (
          <div className={styles.tenYears}>
            <div className={styles.logoPET}>
              <img src={Logosm} alt="" />
            </div>
            <p className={styles.label}>
              {totalDuration}
              <br />
              in PETRONAS
            </p>
          </div>
        )}
        {loading && <Skeleton circle={true} count={1} style={{ width: '70px', height: '70px' }} />}
        <div className={styles.CareerJourney__left} style={customStyle?.chartLeft || {}}>
          {loading && (
            <div style={{ margin: 'auto', width: '96%' }}>
              <Skeleton count={1} style={{ height: '35px', marginBottom: '20px' }} />
            </div>
          )}
          {!loading && (
            <div
              className={styles.CareerJourney__list}
              style={noDetail ? { boxShadow: 'none', width: '100%' } : {}}
            >
              {!isEmpty(resExp) &&
                resExp.map((item, index) => {
                  return <CareerJourneyItem key={index} item={item} />;
                })}

              <div className={styles.CareerJourney__item}>
                <div className={styles.CareerJourney__point_last}>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip className={styles.CareerJourney__tooltip}>
                        {lastPoint.date_to_str}
                      </Tooltip>
                    }
                  >
                    <img src={mask_icon} alt="1" className={styles.CareerJourney__mask} />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          )}
          {!noDetail && (
            <>
              <div>
                {!loading ? (
                  <>
                    <div className={styles.CareerJourney__listPosition}>
                      {returnCareerJourneyTypeContent(
                        detailsCareerJourny?.functional_experience,
                        CAREER_JOURNEY_EXPERIENCE_TYPE.FUNCTIONAL
                      )}
                    </div>
                    <div
                      className={styles.CareerJourney__listPosition}
                      style={{ paddingTop: '0px' }}
                    >
                      {returnCareerJourneyTypeContent(
                        detailsCareerJourny?.complementary_experience,
                        CAREER_JOURNEY_EXPERIENCE_TYPE.COMPLEMENTARY
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      count={3}
                      style={{ height: '20px', width: '100%', marginBottom: '12px' }}
                    />
                    <Skeleton
                      count={3}
                      style={{ height: '20px', width: '100%', marginBottom: '12px' }}
                    />
                  </>
                )}
              </div>
              {!loading && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className={styles.CareerJourney__listTagPosition}>
                    {Object.keys(CAREER_JOURNEY_EXPERIENCE_TYPE).map((key) => {
                      return (
                        <p>
                          <img
                            src={
                              CAREER_JOURNEY_EXPERIENCE_TYPE[key] ===
                              CAREER_JOURNEY_EXPERIENCE_TYPE.FUNCTIONAL
                                ? purple_shadown_circle
                                : yellow_shadown_circle
                            }
                            alt=""
                          />{' '}
                          {CAREER_JOURNEY_EXPERIENCE_TYPE[key]}
                        </p>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      lineHeight: '20px',
                      letterSpacing: '0.235px',
                      marginTop: '14px',
                      fontSize: '14px',
                      gap: '12px',
                      fontFamily: 'Inter, sans-serif',
                      cursor: 'pointer',
                      color: '#3F3C4C',
                    }}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <div
                      style={{ gap: '10px', cursor: 'pointer' }}
                      className="d-flex justify-content-between align-items-center"
                      onKeyDown={() => {}}
                      onClick={() => {
                        setOpenView(true);
                      }}
                    >
                      <span>VIEW COMPLEMENTARY EXPERIENCE</span>
                      <img src={right_open_arrow} alt="open" />
                    </div>
                    <img
                      src={edit_report_name}
                      alt="edit"
                      style={{ width: '18px' }}
                      onKeyDown={() => {}}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {openView && (
            <ComplementaryExperience
              openView={openView}
              setOpenView={setOpenView}
              roleId={roleUser.roleActive?.roleId}
              staffId={id}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CareerJourney.propTypes = {
  customStyle: PropTypes.object,
  setCareerJourneyData: PropTypes.func,
  isPrinting: PropTypes.bool,
  noDetail: PropTypes.bool,
  id: PropTypes.string,
  roleUser: PropTypes.object,
};

export default memo(CareerJourney);
