import { combineReducers } from '@reduxjs/toolkit';
import syndicationReducer from './syndicationSlice';

const agendaSlice = combineReducers({
  syndication: syndicationReducer,
});

const reducer = combineReducers({
  agenda: agendaSlice,
});

export default reducer;
