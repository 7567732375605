import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { search_data_not_found } from '../../../../assets/img';
import { ModelTC, SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { checkDeleteResetPage } from '../../../../utils/helper';
import { TOP_TALENT_CATEGORY_MESSAGE, TOP_TALENT_CATEGORY_TABLE_ROW_LIMIT } from '../constants';
import MenuBarTopTalentCategory from './MenuBarTopTalentCategory/MenuBarTopTalentCategory';
import TopTalentCategoryTable from './TopTalentCategoryTable/TopTalentCategoryTable';

const TopTalentCategory = (props) => {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [data, setData] = useState({ top_talent_category: [], total: 0 });
  const [searchParams, setSearchParams] = useState({
    keyword: '',
    page: 1,
    limit: TOP_TALENT_CATEGORY_TABLE_ROW_LIMIT,
  });
  const [deleteParams, setDeleteParams] = useState({
    selectedRecords: [],
    deleteRecord: null,
  });
  const [editRecord, setEditRecord] = useState(null);

  const handleDeleteSuccess = (arrDeleted) => {
    const { top_talent_category, total } = data;
    const { page } = searchParams;
    checkDeleteResetPage(arrDeleted, top_talent_category, total, page)
      ? setSearchParams({ ...searchParams, page: 1, keyword: '' })
      : fetchData();
    setDeleteParams({
      selectedRecords: [],
      deleteRecord: null,
    });
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: TOP_TALENT_CATEGORY_MESSAGE.DELETE_SUCCESS,
      })
    );
  };

  const handleDeleteRecord = async () => {
    const { selectedRecords, deleteRecord } = deleteParams;
    if (isEmpty(selectedRecords) && !deleteRecord) return;

    setDeleting(true);
    const talentIds = deleteRecord ? [deleteRecord?.id] : selectedRecords.map((item) => item.id);

    try {
      const res = await adminApi.deleteTopTalentCategory(talentIds, roleActive.roleId);
      if (res.status === 200) {
        handleDeleteSuccess(talentIds);
      }
    } catch (error) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: error.response.data.errors,
        })
      );
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const handleEditRecord = async (category) => {
    try {
      setLoading(true);
      const record = { ...editRecord, category };
      const res = await adminApi.editTopTalentCategory(record, roleActive.roleId);
      if (res.status === 200) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: TOP_TALENT_CATEGORY_MESSAGE.EDIT_SUCCESS,
          })
        );
        setEditRecord(null);
        fetchData();
      }
    } catch (error) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: error.response.data.errors,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await adminApi.searchTopTalentCategory(searchParams, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result);
        if (!isEmpty(res.data.result.top_talent_category) || isFirstRender) {
          setIsFirstRender(false);
          return;
        }
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: TOP_TALENT_CATEGORY_MESSAGE.SEARCH_NOT_FOUND,
          })
        );
      }
    } catch (error) {
      setData({ top_talent_category: [], total: 0 });
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, roleActive.roleId, searchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <MenuBarTopTalentCategory
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        deleteParams={deleteParams}
        setShowDeleteConfirm={setShowDeleteConfirm}
        editRecord={editRecord}
        setEditRecord={setEditRecord}
      />

      {!isEmpty(data.top_talent_category) ? (
        <>
          <TopTalentCategoryTable
            data={data}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setDeleteParams={setDeleteParams}
            setShowDeleteConfirm={setShowDeleteConfirm}
            editRecord={editRecord}
            setEditRecord={setEditRecord}
            onEdit={handleEditRecord}
          />
          <ModelTC
            info={{
              type: 'deleteTopTalentCategory',
              visible: showDeleteConfirm,
              setVisible: setShowDeleteConfirm,
              handleSubmit: handleDeleteRecord,
              loading: deleting,
              onClose: () => {
                setShowDeleteConfirm(false);
              },
            }}
          />
        </>
      ) : (
        <SearchDataTableNotFound
          content={TOP_TALENT_CATEGORY_MESSAGE.SEARCH_NOT_FOUND}
          notFoundIcon={search_data_not_found}
        />
      )}
    </Spin>
  );
};

export default TopTalentCategory;
