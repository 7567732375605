import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { edit_icon, key_strength_icon, remark_icon } from '../../../../assets/img';
import useConditionalTalentReviewRemark from '../../../../hooks/useConditionalTalentReviewRemark';
import { useGetRoleInMeeting } from '../../../../hooks/useGetRoleInMeeting';
import { TALENT_REMARK_CATEGORY } from '../../../../utils/constants';
import { remarksRemainingSelector, remarksSelector } from '../../../Meeting/selector';
import AddRemark from '../TalentReviewAndInfoSP/Remarks/AddRemark/AddRemark';
import RemarkTableDialog from '../TalentReviewAndInfoSP/Remarks/ViewRemarks/RemarkTableDialog';
import DataTable from './DataTable/DataTable';
import NoteDataTable from './DataTable/NoteDataTable';
import styles from './key-strength-development-areas.module.scss';
import TalentReviewCollapse from '../TalentReviewCollapse/TalentReviewCollapse';

const KeyStrengthAndDevelopmentAreas = (props) => {
  const {
    meetingDetail,
    agendaDetail,
    staffTalentReview,
    onlyViewTalentAgenda,
    handleShowMessage,
    isPrinting,
  } = props;
  const { idMeeting } = useParams();
  const remarks = useSelector(remarksRemainingSelector);
  const { loading } = useSelector(remarksSelector);
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const { showBtnAllRemarks, isCreateOrEditRemarks } = useConditionalTalentReviewRemark({
    meetingRole,
    meetingStatus: agendaDetail?.meeting_status,
    agendaStatus: agendaDetail?.status,
    staffTalentReview,
  });

  const [showAddRemark, setShowAddRemark] = useState({
    status: false,
    staff: '',
  });
  const [showAllRemarks, setShowAllRemarks] = useState(false);
  const [talentRemark, setTalentRemark] = useState([]);
  const [noteData, setNoteData] = useState([]);
  const [listKeyDev, setListKeyDev] = useState([]);

  const currentsRemarks = useMemo(() => {
    return !isEmpty(remarks) ? remarks : [];
  }, [remarks]);

  // Set data table
  useEffect(() => {
    const newData = currentsRemarks.filter((item) => item.remarksCategory);
    const keyStrength = currentsRemarks.filter(
      (item) =>
        item.remarksCategory && [TALENT_REMARK_CATEGORY.KEY_STRENGTH].includes(item.remarksCategory)
    );
    const developmentAreas = currentsRemarks.filter(
      (item) =>
        item.remarksCategory &&
        [TALENT_REMARK_CATEGORY.DEVELOPMENT_AREA].includes(item.remarksCategory)
    );
    const notes = currentsRemarks.filter(
      (item) =>
        item.remarksCategory && [TALENT_REMARK_CATEGORY.NOTES].includes(item.remarksCategory)
    );
    setNoteData(notes);
    setTalentRemark(newData);

    if (keyStrength.length > 0 || developmentAreas.length > 0) {
      handleCheckLengthOfArray([keyStrength, developmentAreas]);
      let listStrengthDev = handleConvertData([keyStrength, developmentAreas]);
      setListKeyDev(listStrengthDev);
    } else {
      setListKeyDev([]);
    }
  }, [setTalentRemark, setNoteData, currentsRemarks]);

  const handleCheckLengthOfArray = (arrayKeyDev) => {
    let differenceNumber = Number(arrayKeyDev[0].length - arrayKeyDev[1].length);
    if (differenceNumber > 0) {
      for (let i = 0; i < Math.abs(differenceNumber); i++) {
        arrayKeyDev[1].push({ development_areas: '' });
      }
    } else if (differenceNumber < 0) {
      for (let i = 0; i < Math.abs(differenceNumber); i++) {
        arrayKeyDev[0].push({ key_strength: '' });
      }
    }
    return arrayKeyDev;
  };

  const handleConvertData = (arrayKeyDev) => {
    let arrayAfterConvert = [];
    for (let i = 0; i < arrayKeyDev[0].length; i++) {
      let newObject = {
        key_strength: arrayKeyDev[0][i].remarks,
        development_areas: arrayKeyDev[1][i].remarks,
      };
      arrayAfterConvert.push(newObject);
    }
    return arrayAfterConvert;
  };

  const renderView = () => {
    return (
      <>
        {' '}
        {!loading && (
          <>
            {!isEmpty(talentRemark) ? (
              <>
                <div
                  className={styles.wrapperContent}
                  style={
                    !isPrinting
                      ? { borderColor: '#fffff' }
                      : {
                          border: '1px solid #d9d9d9',
                          borderRadius: '7px',
                        }
                  }
                >
                  {!isEmpty(listKeyDev) && <DataTable data={listKeyDev} />}
                </div>
                <div
                  className={styles.wrapperContent}
                  style={
                    !isPrinting
                      ? { borderColor: '#fffff', marginTop: '20px' }
                      : {
                          border: '1px solid #d9d9d9',
                          borderRadius: '7px',
                          marginTop: '20px',
                        }
                  }
                >
                  {!isEmpty(noteData) && <NoteDataTable data={noteData} />}
                </div>
              </>
            ) : (
              <div className={styles.wrapperNonContent}>
                <img src={key_strength_icon} alt="Key Strength and Development Areas" />
                <span>All the remarks will be displayed on this page.</span>
              </div>
            )}
          </>
        )}
        {showAddRemark.status && (
          <AddRemark
            meetingDetail={meetingDetail}
            setIsVisible={setShowAddRemark}
            showAddRemark={showAddRemark}
            agendaDetail={agendaDetail}
            handleShowMessage={handleShowMessage}
          />
        )}
        {showAllRemarks && (
          <RemarkTableDialog
            isRemark={showAllRemarks}
            setIsRemark={setShowAllRemarks}
            isViewModeAgenda={!isCreateOrEditRemarks}
            agendaDetail={agendaDetail}
            handleShowMessage={handleShowMessage}
            titleModal={'Key Strength and Development Area'}
          />
        )}
      </>
    );
  };
  return (
    <>
      {!isPrinting && (
        <TalentReviewCollapse
          print={isPrinting}
          header={
            <div className="d-flex justify-content-between align-items-center pt-3 mr-1">
              <div className={styles.mainTitle}>Key Strength and Development Areas</div>
              <div className={styles.groupTopBtn}>
                {isCreateOrEditRemarks && !onlyViewTalentAgenda && (
                  <div
                    style={{ alignSelf: 'center', cursor: 'pointer', marginRight: '12px' }}
                    onKeyDown={() => {}}
                    onClick={() =>
                      setShowAddRemark({
                        status: true,
                        staff: staffTalentReview,
                      })
                    }
                  >
                    <img src={remark_icon} alt="remark_icon" />
                  </div>
                )}

                {showBtnAllRemarks && isCreateOrEditRemarks && !onlyViewTalentAgenda && (
                  <div
                    className={styles.editButton}
                    style={isEmpty(talentRemark) ? { opacity: '0.3', cursor: 'no-drop' } : {}}
                    onKeyDown={() => {}}
                    onClick={() => !isEmpty(talentRemark) && setShowAllRemarks(true)}
                  >
                    <img src={edit_icon} alt="edit_icon" />
                  </div>
                )}
              </div>
            </div>
          }
        >
          <div className={!isPrinting ? styles.container : styles.container_print}>
            {renderView()}
          </div>
        </TalentReviewCollapse>
      )}
      {isPrinting && (
        <div className={!isPrinting ? styles.container : styles.container_print}>
          <div className={styles.wrapperHeader}>
            <div className={styles.mainTitle}>Key Strength and Development Areas</div>
            <div className={styles.groupTopBtn}>
              {isCreateOrEditRemarks && !onlyViewTalentAgenda && (
                <div
                  style={{ alignSelf: 'center', cursor: 'pointer', marginRight: '12px' }}
                  onKeyDown={() => {}}
                  onClick={() =>
                    setShowAddRemark({
                      status: true,
                      staff: staffTalentReview,
                    })
                  }
                >
                  <img src={remark_icon} alt="remark_icon" />
                </div>
              )}

              {showBtnAllRemarks && isCreateOrEditRemarks && !onlyViewTalentAgenda && (
                <div
                  className={styles.editButton}
                  style={isEmpty(talentRemark) ? { opacity: '0.3', cursor: 'no-drop' } : {}}
                  onKeyDown={() => {}}
                  onClick={() => !isEmpty(talentRemark) && setShowAllRemarks(true)}
                >
                  <img src={edit_icon} alt="edit_icon" />
                </div>
              )}
            </div>
          </div>
          {renderView()}
        </div>
      )}
    </>
  );
};

export default KeyStrengthAndDevelopmentAreas;
