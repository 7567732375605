import _ from 'lodash';
import { useCallback, useRef, useState } from 'react';

export const useFetch = (callback, options) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const optionsRef = useRef(options);
  optionsRef.current = options;

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const fetch = useCallback(async (...args) => {
    setLoading(true);
    const optionsChaining = _(optionsRef.current);
    try {
      const response = await callbackRef.current.apply({}, args);
      if (response.status === 200) {
        setData(response.data.result);
        optionsChaining.invoke('onSuccess', response.data.result);
      }
      setLoading(false);
    } catch (error) {
      setData(null);
      setLoading(false);
      setError(error);
      optionsChaining.invoke('onError', error);
    }
  }, []);
  return { fetch, isLoading, data, error };
};
