import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { BasicLoading } from '../../assets/common';
import { BreadCrumb, ModelTC } from '../../components';
import GlobalAlertMessage from '../../components/GlobalAlertMessage/GlobalAlertMessage';
import { reportBuilderApi } from '../../services/reportBuilderApi';
import { pushMessage } from '../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../utils/constants';
import { checkDeleteResetPage } from '../../utils/helper';
import ReportBuilderSearchBar from './components/ReportBuilderSearchBar/ReportBuilderSearchBar';
import ReportBuilderTable from './components/ReportBuilderTable/ReportBuilderTable';
import { RB_BREAD_CRUMB, REPORT_BUILDER_MESSAGE } from './constants';
import styles from './report-builder.module.scss';

export const ReportBuilder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [data, setData] = useState({ reports: [], total: 0 });
  const [searchParams, setSearchParams] = useState({
    keyword: '',
    page: 1,
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    selectedReports: [],
    deleteRecord: null,
  });
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleSearch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await reportBuilderApi.searchReportBuilder(searchParams, roleId);
      if (res.status === 200) {
        const result = res.data.result;
        setData({ reports: result, total: result[0]?.total || 0 });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        history.push('/access-denied');
      }
    } finally {
      setLoading(false);
    }
  }, [searchParams, roleId, history]);

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setDeleteParams((prev) => ({ ...prev, deleteRecord: null }));
  };

  const handleDeleteSuccess = (arrDeleted) => {
    const { reports, total } = data;
    const { page } = searchParams;
    checkDeleteResetPage(arrDeleted, reports, total, page)
      ? setSearchParams({ ...searchParams, page: 1 })
      : handleSearch();
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: REPORT_BUILDER_MESSAGE.DELETE_REPORT,
        isScroll: true,
      })
    );

    setDeleteParams((prev) => ({
      selectedReports: prev.deleteRecord
        ? prev.selectedReports.filter((record) => record.id !== prev.deleteRecord.id)
        : [],
      deleteRecord: null,
    }));
  };

  const handleDeleteRecord = async () => {
    const { selectedReports, deleteRecord } = deleteParams;
    if (isEmpty(selectedReports) && !deleteRecord) return;

    setDeleting(true);
    const reportIds = deleteRecord ? [deleteRecord?.id] : selectedReports.map((item) => item.id);

    try {
      const res = await reportBuilderApi.deleteReportBuilder(reportIds);
      if (res.status === 200) {
        handleDeleteSuccess(reportIds);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <div className={styles.wrapperContainer}>
      <BreadCrumb level={2} breadCrumbList={RB_BREAD_CRUMB} />
      <GlobalAlertMessage />
      <h2 className={styles.title}>Report Builder</h2>
      <ReportBuilderSearchBar
        setSearchParams={setSearchParams}
        setShowDeleteConfirm={setShowDeleteConfirm}
        selectedReports={deleteParams.selectedReports}
        isFirstRender={isFirstRender}
        setIsFirstRender={setIsFirstRender}
      />
      <BasicLoading spinning={loading}>
        <ReportBuilderTable
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          data={data}
          deleteParams={deleteParams}
          setDeleteParams={setDeleteParams}
          setShowDeleteConfirm={setShowDeleteConfirm}
          isFirstRender={isFirstRender}
        />
      </BasicLoading>
      <ModelTC
        info={{
          type: 'deleteReportBuilder',
          visible: showDeleteConfirm,
          setVisible: setShowDeleteConfirm,
          handleSubmit: handleDeleteRecord,
          loading: deleting,
          onClose: handleCancelDelete,
        }}
      />
    </div>
  );
};

export default ReportBuilder;
