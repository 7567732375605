import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { meetingApi } from '../services/tcMeeting';

export const useGetAgendas = ({ idMeeting, status }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const user = useSelector((state) => state.user);
  const getAgendas = async () => {
    setLoading(true);
    try {
      const response = await meetingApi.getAgendas({ idMeeting, roleUser: user, status });

      setData(response.data.result);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAgendas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMeeting]);
  return { data, loading, error };
};
