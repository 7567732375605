import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useShowPopupSaveAgenda = (isChanges) => {
  const history = useHistory();
  const [isShowPopupSaveAgenda, setIsShowPopupSaveAgenda] = useState(false);
  const [navigatingPathname, setNavigatingPathname] = useState('');

  const handleKeepNavigate = () => {
    history.push(navigatingPathname);
  };

  useEffect(() => {
    if (!isChanges) return;
    const unblock = history.block((location) => {
      if (isShowPopupSaveAgenda) {
        return;
      }
      setNavigatingPathname(location.pathname);
      setIsShowPopupSaveAgenda(true);
      return false;
    });

    return () => {
      unblock();
    };
  }, [history, isChanges, isShowPopupSaveAgenda]);

  return { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda };
};
