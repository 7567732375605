import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { utils } from 'xlsx-js-style';
import {
  historicalExportDeclareWorkSheet,
  historicalExportSetMergeAndColWidth,
  historicalExportStyleAllCells,
  historicalExportUnshiftHeader,
  historicalSetColorsAndWriteFile,
} from './CommonExportHistorical';

const ExportTalentReview = (props) => {
  const { setExportData, historicalData } = props;

  const exportRetirementForecast = useCallback(async () => {
    const fileName = 'Talent Review';

    const arr = [];
    historicalData.forEach((item) => {
      const valueItem = item.value;
      const rows = [
        item.month || 'N/A',
        item.noOfTopTalent || 'N/A',
        item.noOfCorporateTalent || 'N/A',
        item.noOfBusinessTalent || 'N/A',
        valueItem?.map((val) => Object.values({ total: val.total })).flat(),
      ].flat();
      arr.push(rows);
    });

    const length = arr[0].length;
    const headers1 = new Array(length).fill('');
    headers1[0] = 'Months (End of months)';
    headers1[1] = 'No of Top Talent';
    headers1[2] = 'Corporate Talent';
    headers1[3] = 'Business Talent';
    headers1[4] = 'Talent Review';
    const headers2 = new Array(length).fill('');
    headers2[0] = 'Months (End of months)';
    headers2[1] = 'No of Top Talent';
    headers2[2] = 'Corporate Talent';
    headers2[3] = 'Business Talent';
    headers2[4] = 'No of Talent Review Conducted';
    headers2[5] = 'Non Successors';
    headers2[6] = 'Not reviewed > 5 years';
    headers2[7] = 'Yet to be Reviewed';

    historicalExportUnshiftHeader(arr, [headers2, headers1]);

    if (arr.length > 0) {
      let [ws, wb] = historicalExportDeclareWorkSheet(arr);
      utils.book_append_sheet(wb, ws, 'Talent Review');

      const shortHandMerges = [
        [0, 0],
        [1, 0],
        [0, 1],
        [1, 1],
        [0, 2],
        [1, 2],
        [0, 3],
        [1, 3],
        [0, 4],
        [0, length - 1],
      ];

      historicalExportSetMergeAndColWidth(ws, shortHandMerges, length, 10, [
        [4, 30],
        [5, 20],
        [6, 25],
        [7, 20],
      ]);

      historicalExportStyleAllCells(ws);

      const colorCellMapping = {
        '00a9b1': ['A1', 'B1', 'B2', 'C1', 'C2', 'D1', 'D2', 'E1'],
        '7030a0': ['E2', 'F2', 'G2', 'H2'],
      };

      historicalSetColorsAndWriteFile(ws, wb, fileName, colorCellMapping);
    }
  }, [historicalData]);

  useEffect(() => {
    exportRetirementForecast();
    setExportData(false);
  }, [setExportData, exportRetirementForecast]);
  return <></>;
};

ExportTalentReview.propTypes = {
  setExportData: PropTypes.func,
  historicalData: PropTypes.array,
};

export default ExportTalentReview;
