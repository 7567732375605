import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModelTC } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { ALERT_MESSAGE_TYPE, ROLE_LEVEL_LIST } from '../../../../utils/constants';
import AddNewRoleLevelDialog from './AddNewRoleLevelDialog/AddNewRoleLevelDialog';
import DataTableRoleLevelList from './DataTableRoleLevelList/DataTableRoleLevelList';
import MenuBarRoleLevelList from './MenuBarRoleLevelList/MenuBarRoleLevelList';

export default function RoleLevelList() {
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [keyword, setKeyword] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [data, setData] = useState([]);
  const [dataOnChange, setDataOnChange] = useState([]);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [deleteItem, setDeleteItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSearchValue, setIsSearchValue] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 15;

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const query = {
        page,
        limit,
        keyword: keyword || searchInput,
      };
      const res = await adminApi.getRoleLevelSearch(query);
      if (res.status === 200) {
        const totalRecord = !isEmpty(res.data.result) ? res.data.result[0].total : 0;
        setData(res.data.result);
        setDataOnChange(res.data.result);
        setTotalData(totalRecord);
      }
    } catch (error) {
      console.log(error);
      setData([]);
      setTotalData(0);
    } finally {
      setLoading(false);
    }
  }, [keyword, searchInput, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData, keyword, page, searchInput]);

  const handleShowMessage = (type, message, description) => {
    dispatch(
      pushMessage({
        type,
        message,
        description,
        timeShow: 3000,
      })
    );
  };

  const deleteRoleLevel = async (ids) => {
    try {
      await adminApi.deleteRoleLevel(ids, roleActive?.roleId);
      setDeleteItem([]);
      setDeleteSelecteds([]);
      handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, ROLE_LEVEL_LIST.DELETE_SUCCESS_MESSAGE);
      if (ids.length === data.length) {
        setSearchInput('');
        setKeyword('');
        setPage(1);
        return;
      }
      fetchData();
    } catch (error) {
      console.error(error);
      handleShowMessage(
        ALERT_MESSAGE_TYPE.WARNING,
        ROLE_LEVEL_LIST.ROLE_LEVEL_USE,
        ROLE_LEVEL_LIST.ROLE_LEVEL_USE_CHILD
      );
    }
  };

  return (
    <Spin spinning={loading} size="large">
      <MenuBarRoleLevelList
        setKeyword={setKeyword}
        loading={loading}
        setIsShow={setIsShow}
        data={dataOnChange}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        setPage={setPage}
        setIsSearchValue={setIsSearchValue}
        isSearchValue={isSearchValue}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setShowDeleteModal={setShowDeleteModal}
        deleteSelecteds={deleteSelecteds}
      />
      <DataTableRoleLevelList
        data={data}
        totalData={totalData}
        setPage={setPage}
        page={page}
        limit={limit}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        fetchData={fetchData}
        setDeleteSelecteds={setDeleteSelecteds}
        deleteSelecteds={deleteSelecteds}
        setDeleteItem={setDeleteItem}
        setShowDeleteModal={setShowDeleteModal}
      />
      {isShow && (
        <AddNewRoleLevelDialog setIsShow={setIsShow} isShow={isShow} fetchData={fetchData} />
      )}
      <ModelTC
        info={{
          type: 'deleteRoleLevel',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => {
            setShowDeleteModal(false);
            setDeleteItem([]);
            setDeleteSelecteds([]);
          },
          handleSubmit: () => {
            setShowDeleteModal(false);
            !isEmpty(deleteItem) ? deleteRoleLevel(deleteItem) : deleteRoleLevel(deleteSelecteds);
          },
        }}
      />
    </Spin>
  );
}
