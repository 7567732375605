import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  talent_finder_export,
  talent_finder_export_inactive,
} from '../../../../../../../assets/img';
import FormInput from '../../../../../../../components/Form/FormInput/FormInput';

const Filter = styled.div`
  margin-top: 5px;
  .filter {
    width: 40px;
    height: 40px;
    padding: 12px;
    background: #fbfdff;
    border: 1px solid #00a19c;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin: 0 12px;
    cursor: pointer;
    height: 45px;
    width: 45px;

    &.inActive {
      border: 1px solid #bac7d5;
    }
  }
`;
const MenuBarFilterTalentBuilder = (props) => {
  const {
    showFilter,
    setShowFilter,
    setKeyword,
    keyword,
    setShowError,
    setIsSearch,
    filterFinderData,
    isFilter,
    setIsFilter,
  } = props;
  const [keySearch, setKeySearch] = useState('');

  function handleSearch() {
    if (!keySearch && isEmpty(filterFinderData)) {
      setShowError(true);
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
    setKeyword(keySearch);
  }
  useEffect(() => {
    setKeySearch(keyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setKeyword]);

  // Update key search field value
  useEffect(() => {
    if (isFilter && isEmpty(keySearch)) {
      setKeySearch(keyword);
      setIsFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilter, keyword]);

  return (
    <div className="d-flex justify-content-start">
      <div style={{ width: '500px' }}>
        <FormInput
          value={keySearch}
          placeholder="Search by Staff Name or Staff ID"
          setFormData={setKeySearch}
          borderColor="#D3DEE8"
        />
      </div>
      {/* <img
        src={filter_talent_builder}
        className="filter"
        alt="Filter"
        height={57}
         onKeyDown={() => {}} onClick={() => {
          setShowFilter(!showFilter);
        }}
      /> */}
      <Filter>
        <img
          src={showFilter ? talent_finder_export : talent_finder_export_inactive}
          className={`filter ${!showFilter ? 'inActive' : ''}`}
          alt="Filter"
          onKeyDown={() => {}}
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        />
      </Filter>

      <div className="button" onKeyDown={() => {}} onClick={handleSearch}>
        Search
      </div>
    </div>
  );
};

export default MenuBarFilterTalentBuilder;
