import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './project-involvement.module.scss';
import { BasicTable } from '../../assets/common';
import { Spin } from 'antd';
import talentProfileApi from '../../services/talentProfiles';
import { paginationConfig } from '../../utils/helper';
import moment from 'moment';

const NOT_AVAILABLE = 'Not Available';
const COLUMNS = [
  {
    title: 'Name',
    width: 121,
    dataIndex: 'project_name',
    render: (text) => <span className={styles.text1}>{text}</span>,
  },
  {
    title: 'Description',
    dataIndex: 'project_description',
    render: (text) => <span className={styles.text1} dangerouslySetInnerHTML={{ __html: text }} />,
  },
  {
    title: 'Roles',
    width: 120,
    dataIndex: 'project_role',
    render: (text) => <span className={styles.text2}>{text}</span>,
  },
  {
    title: 'Level',
    width: 120,
    dataIndex: 'project_level',
    render: (text) => <span className={styles.text2}>{text}</span>,
  },
  {
    title: 'Start Date',
    width: 120,
    dataIndex: 'project_start_date',
    render: (text) => <span className={styles.text2}>{text === NOT_AVAILABLE ? text : moment(text).format('DD MMM YYYY')}</span>,
  },
  {
    title: 'Completion Date',
    width: 146,
    dataIndex: 'project_end_date',
    render: (text) => <span className={styles.text2}>{text === NOT_AVAILABLE ? text : moment(text).format('DD MMM YYYY')}</span>,
  },
];
const LIMIT_ROWS = 5;
const TABLE_STYLE = {
  headerColor: '#181818',
  headerWeight: 500,
  borderRadius: 6,
  roundPagination: true,
};
const INIT_DATA = { data: [], total: 0 };

const ProjectInvolvement = ({ staffId, role }) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState(INIT_DATA);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setPage(pageNum);
    };
    return paginationConfig(projects.total, page, onChange, LIMIT_ROWS);
  }, [projects.total, page]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await talentProfileApi.getProjectInvolvement(staffId, page, role);
      if (res.status === 200) {
        setProjects(res.data.result);
      }
    } catch (error) {
      console.error(error);
      setProjects(INIT_DATA);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <div className={styles.title}>Project Involvement</div>
      <Spin spinning={loading}>
        <BasicTable
          rowKey="id"
          styles={TABLE_STYLE}
          dataSource={projects.data}
          columns={COLUMNS}
          pagination={pagination}
          rowClassName={styles.customTable}
        />
      </Spin>
    </div>
  );
};

ProjectInvolvement.propTypes = {
  staffId: PropTypes.string,
  role: PropTypes.object,
};

export default ProjectInvolvement;
