import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { MenuSidebar, UserRole } from '../index';
import styles from './sidebar.module.scss';

export const Sidebar = (props) => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const username = useSelector((state) => state.user.username);
  const isLogin = Boolean(Number(process.env.REACT_APP_BYPASS_LOGIN) || 0);

  return (
    <>
      {((currentAccount?.name && username) || isLogin) && (
        <div className={styles.sidebar}>
          <UserRole {...props} />
          <MenuSidebar {...props} />
        </div>
      )}
    </>
  );
};

export default Sidebar;
