import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;

  position: relative;

  .btn-today {
    position: absolute;
    padding: 10px 20px;
    border: 1px solid #00a19c;
    border-radius: 6px;
    color: #00a19c;
    top: 0;
    transform: translateX(193px);
    cursor: pointer;
    width: 80px;
    height: 40px;
  }

  .menu-calendar {
    margin-top: 8px;
  }
`;
