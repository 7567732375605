import { isEmpty } from 'lodash';
import React from 'react';
import { releaseChecklistNumber } from '../../../../utils/helper';
import MobilityLegend from '../MobilityLegend/MobilityLegend';
import * as styles from './mobility-plan-tooltip.module.scss';

const MobilityPlanTooltip = (props) => {
  const { mobilityPlan, releaseChecklist } = props;
  const managerReleasability = releaseChecklist?.manager_releasability === true ? 'green' : 'red';
  const willingToRelocate = releaseChecklist?.willing_to_relocate === true ? 'green' : 'red';
  const newInPosition = releaseChecklist?.new_in_position === true ? 'green' : 'red';
  const crossBusiness = releaseChecklist?.cross_business === true ? 'green' : 'red';

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Mobility Plan</h4>
      <div>
        <h4 className={styles.title}>Next Mobility Plan</h4>
        <ul>
          {!isEmpty(mobilityPlan?.next_mobility) ? (
            mobilityPlan?.next_mobility.map((item) => <li>{item}</li>)
          ) : (
            <li>N/A</li>
          )}
        </ul>
      </div>
      <div>
        <h4 className={styles.title}>Next 2 Mobility Plan</h4>
        <ul>
          {!isEmpty(mobilityPlan?.next_2_mobility) ? (
            mobilityPlan?.next_2_mobility.map((item) => <li>{item}</li>)
          ) : (
            <li>N/A</li>
          )}
        </ul>
      </div>
      <div>
        <h4 className={styles.title}>End State</h4>
        <ul>
          {!isEmpty(mobilityPlan?.end_state) ? (
            mobilityPlan?.end_state.map((item) => <li>{item}</li>)
          ) : (
            <li>N/A</li>
          )}
        </ul>
      </div>
      <div>
        <h4 className={styles.title}>
          Release Checklist:&nbsp;{releaseChecklistNumber(releaseChecklist)}
        </h4>
        <ul>
          <li>
            Manager Releasability:&nbsp;
            <span>
              {!isEmpty(releaseChecklist) ? (
                <MobilityLegend color={managerReleasability} />
              ) : (
                ' N/A'
              )}
            </span>
          </li>
          <li>
            Willing to Relocate:&nbsp;
            <span>
              {!isEmpty(releaseChecklist) ? <MobilityLegend color={willingToRelocate} /> : ' N/A'}
            </span>
          </li>
          <li>
            New in Position:&nbsp;
            <span>
              {!isEmpty(releaseChecklist) ? <MobilityLegend color={newInPosition} /> : ' N/A'}
            </span>
          </li>
          <li>
            Cross Business:&nbsp;
            <span>
              {!isEmpty(releaseChecklist) ? <MobilityLegend color={crossBusiness} /> : ' N/A'}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobilityPlanTooltip;
