import React, { useState } from 'react';
import { Container } from './styled';
import { sp_chevron_down } from '../../../../assets/img';

const renderYear = (date) => {
  const yearArr = [];
  for (let i = 0; i < 12; i++) {
    const data = date.getFullYear() + i;
    yearArr.push({ id: data, value: data });
  }
  return yearArr;
};

const YearCalendar = (props) => {
  const { date, setIsShowYearCalendar, setStartDate } = props;
  const [yearArr, setYearArr] = useState(() => {
    return renderYear(date);
  });
  const [yearSelected, setYearSelected] = useState(date.getFullYear());

  const handleApplyYear = () => {
    const newDate = new Date();
    newDate.setFullYear(yearSelected);
    setStartDate(newDate);
    setIsShowYearCalendar(false);
  };

  return (
    <Container>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: '0 10px', marginBottom: '10px' }}
      >
        <div data-testid="year-calendar">{date.getFullYear()}</div>
        <div>
          <img
            src={sp_chevron_down}
            alt=""
            style={{ pointerEvents: 'all' }}
            onKeyDown={() => {}}
            onClick={() => {
              setYearArr((prev) => {
                return prev.map((item) => {
                  return { id: item.id + 12, value: item.value + 12 };
                });
              });
            }}
            data-testid="year-calendar-next"
          />
          <img
            src={sp_chevron_down}
            style={{ transform: 'rotate(180deg)', marginLeft: '10px', pointerEvents: 'all' }}
            alt=""
            onKeyDown={() => {}}
            onClick={() => {
              setYearArr((prev) => {
                return prev.map((item) => {
                  return { id: item.id - 12, value: item.value - 12 };
                });
              });
            }}
            data-testid="year-calendar-back"
          />
        </div>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              {yearArr.map((item) => (
                <td
                  style={{ minWidth: '50px' }}
                  key={item.id}
                  className={item.id === yearSelected ? 'active' : null}
                  onKeyDown={() => {}}
                  onClick={() => setYearSelected(item.id)}
                  data-testid="year-calendar-select"
                >
                  {item.value}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end align-items-center" style={{ padding: '0 10px' }}>
        <div
          className="button-month-calendar"
          onKeyDown={() => {}}
          onClick={() => {
            setIsShowYearCalendar(false);
          }}
        >
          Cancel
        </div>
        <div
          className="button-month-calendar"
          style={{ marginLeft: '10px', backgroundColor: '#00A19C', color: 'white' }}
          onKeyDown={() => {}}
          onClick={handleApplyYear}
        >
          Apply
        </div>
      </div>
    </Container>
  );
};

export default YearCalendar;
