import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { adminApi } from '../../../../../../../services/admin';
import { COLUMNS_MAP_CRITERIA, CRITERIA_TYPE } from '../../../../../../../utils/constants';
import CriteriaColumnsPopout from './CriteriaColumnsPopout';
import * as styles from './form-popout.module.scss';
import { CriteriaCustomTable } from './styled';

const FormPopout = (props) => {
  const { data, currentSetMatching } = props;
  const dispatch = useDispatch();

  const [functionContents, setFunctionContents] = useState({ index: -1, options: [[]] });
  const [inputValue, setInputValue] = useState({
    isUpdate: false,
    value: currentSetMatching[0].selected_criteria?.value || [],
  });

  useEffect(() => {
    if (currentSetMatching.length && !isEmpty(currentSetMatching[0]?.selected_criteria)) {
      setInputValue({
        isUpdate: true,
        value:
          currentSetMatching[0].selected_criteria.find((item) => item.criteria === data.criteria)
            ?.value || [],
      });
    }
  }, [data, currentSetMatching, setInputValue]);

  const fetchFunctionContents = async (functionCriteria, index) => {
    try {
      const res = await adminApi.getFunctionCriteriaContent(functionCriteria);
      if (res.status === 200) {
        setFunctionContents((prevState) => {
          const cloneState = cloneDeep(prevState);
          cloneState.options[index] = res.data.result;
          cloneState.index = -1;
          return cloneState;
        });
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // get content options when select function field of Functional
  useEffect(() => {
    if (![CRITERIA_TYPE.FUNCTIONAL, CRITERIA_TYPE.FUNCTIONAL_OPTIONAL].includes(data.criteria))
      return;
    const currentIndex = functionContents.index;
    if (currentIndex < 0) return;
    fetchFunctionContents(inputValue.value[currentIndex].function, functionContents.index);
  }, [data.criteria, functionContents.index, inputValue]);

  const renderColumns = (data) => {
    return CriteriaColumnsPopout(dispatch)[`${COLUMNS_MAP_CRITERIA[data.criteria]}`](
      data,
      inputValue,
      setInputValue,
      functionContents,
      setFunctionContents
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className="d-flex justify-content-between">
        <h4 className={styles.title}>{data?.criteria}</h4>
      </div>
      <CriteriaCustomTable
        columns={renderColumns(data)}
        dataSource={[data?.criteria]}
        pagination={false}
        rowKey={1}
      />
    </div>
  );
};

export default FormPopout;
