/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Dropdown, Menu, Pagination, Row, Space, Table } from 'antd';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  deleteNote,
  editNote,
  edit_delete,
  exclamation_point,
  green_dot,
  red_dot,
} from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import * as styles from './data-table.module.scss';
import * as SC from './styled';

export default function DataTable({
  data = [],
  total,
  filterSearch,
  setFilterSearch,
  deleteSelecteds,
  setDeleteSelecteds,
  updateStatusVisible,
  setUpdateStatusVisible,
  deleteCriticalPosition,
  loading,
  deleteOneItem,
  setDeleteOneItem,
}) {
  const onChangePage = (page) => {
    setFilterSearch({ ...filterSearch, _page: page });
  };
  const onClickDelete = (item) => {
    setDeleteOneItem(item);
    setUpdateStatusVisible(true);
  };
  const menu = (record) => (
    <Menu
      items={[
        {
          icon: <img src={editNote} alt="" />,
          label: (
            <Link
              to={`/admin/sp-management/edit-critical-position/${record.position_code}`}
              style={{ textDecoration: 'none' }}
              className={styles.editButton}
            >
              Edit
            </Link>
          ),
          key: '0',
        },
        {
          icon: <img src={deleteNote} alt="" />,
          label: (
            <div
              data-testid="delete-one-record-btn"
              style={{ textDecoration: 'none' }}
              className={styles.deleteButton}
              onKeyDown={() => {}}
              onClick={() => {
                onClickDelete({
                  position_code: record.position_code,
                  position_name: record.position_name,
                });
              }}
            >
              Delete
            </div>
          ),
          key: '1',
        },
      ]}
    />
  );
  const columns = [
    {
      title: 'Position Code',
      dataIndex: 'position_code',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
    {
      title: 'Position Name',
      dataIndex: 'position_name',
      width: '28%',
      render: (text, item) => {
        return (
          <div>
            <span style={{ color: '#3F3C4C', fontWeight: 700 }}>{text}</span>&nbsp;
            <OverlayTrigger
              placement="bottom-end"
              overlay={
                <Tooltip id="button-tooltip" className={'bs-custom-tooltip'}>
                  <span className={styles.flagMachingCriteria__tooltip}>
                    <img
                      src={item.statusApprovedSuccessionPlanning ? green_dot : red_dot}
                      alt="1"
                      className={styles.flagMachingCriteria__mask}
                    />
                    &nbsp;{' '}
                    {item.statusApprovedSuccessionPlanning
                      ? 'Succession Planning Available'
                      : 'Succession Planning not Available'}{' '}
                    <br />
                  </span>
                  <span className={styles.flagMachingCriteria__tooltip}>
                    <img
                      src={item.statusCriteriaPosition ? green_dot : red_dot}
                      alt="1"
                      className={styles.flagMachingCriteria__mask}
                    />
                    &nbsp;{' '}
                    {item.statusCriteriaPosition
                      ? 'Matching Criteria Available'
                      : 'Matching Criteria not Available'}
                  </span>
                </Tooltip>
              }
            >
              <img src={exclamation_point} alt="1" className={styles.flagMachingCriteria__mask} />
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      title: 'Position Label',
      dataIndex: 'position_label',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
    {
      title: 'Business',
      dataIndex: 'business',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
    {
      title: 'Included in Ratio',
      dataIndex: 'included_in_ratio',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (_, record) => {
        return (
          <Dropdown
            overlay={() => menu(record)}
            trigger={['click']}
            style={{ minWidth: 110 }}
            placement="bottomRight"
          >
            <a onKeyDown={() => {}} onClick={(e) => e.preventDefault()}>
              <Space>
                <img data-testid="three-dots" src={edit_delete} alt="" />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];
  const handleSubmit = () => {
    deleteCriticalPosition([deleteOneItem.position_code]);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setDeleteSelecteds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    selectedRowKeys: deleteSelecteds,
  };

  return (
    <div style={{ paddingTop: 24 }}>
      <ModelTC
        info={{
          type: 'deleteManagingCriticalPosition',
          visible: updateStatusVisible,
          setVisible: setUpdateStatusVisible,
          handleSubmit: () => {
            handleSubmit();
          },
          loading,
          onClose: () => {
            setUpdateStatusVisible(false);
            setDeleteOneItem({});
          },
        }}
      />
      <SC.TableManagement>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
        />
      </SC.TableManagement>
      <Row justify="space-between" align="bottom" style={{ marginTop: 22 }}>
        <Col style={{ fontSize: 14 }}>
          Showing&nbsp;
          <span style={{ fontWeight: 700 }}>
            {data.length > 0 ? filterSearch._limit * (filterSearch._page - 1) + 1 : 0}
          </span>
          &nbsp; to&nbsp;
          <span style={{ fontWeight: 700 }}>
            {filterSearch._limit * (filterSearch._page - 1) + data.length}
          </span>
          &nbsp; of&nbsp;
          <span style={{ fontWeight: 700 }}>{total}</span>&nbsp; results
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Pagination
            onChange={onChangePage}
            size="large"
            pageSize={filterSearch._limit}
            current={filterSearch._page}
            total={total}
            showSizeChanger={false}
          />
        </Col>
      </Row>
    </div>
  );
}
