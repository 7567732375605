import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicButton } from '../../../../../assets/common';
import styles from './menu-bar-managing-position-category.module.scss';

const MenuBarManagingPositionCategory = ({
  searchParams,
  setSearchParams,
  selectedRecords,
  setShowDeleteConfirm,
}) => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');

  const emptySelected = useMemo(() => isEmpty(selectedRecords), [selectedRecords]);
  const activeClass = useMemo(
    () => (emptySelected ? styles.inActiveDelBtn : styles.activeDelBtn),
    [emptySelected]
  );

  const handleClickAddNew = () => {
    history.push(`/admin/master-data-management/managing-position-category/add`);
  };

  const handleClickSearch = () => {
    setSearchParams((prevState) => ({ ...prevState, keyword: searchInput, page: 1 }));
  };

  const handleClickDelete = () => {
    setShowDeleteConfirm(true);
  };

  useEffect(() => {
    setSearchInput(searchParams.keyword);
  }, [searchParams.keyword])

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={18}>
          <Row align="bottom" gutter={16}>
            <Col span={14}>
              <input
                data-testid="search-input"
                placeholder="Search Category"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton mode="teal" onClick={handleClickSearch}>
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="middle" justify="space-between" gutter={12}>
            <Col>
              <BasicButton
                mode="danger"
                onClick={handleClickDelete}
                className={`${styles.button} ${activeClass}`}
                disabled={emptySelected}
              >
                Confirm Delete Category
              </BasicButton>
            </Col>
            <Col>
              <BasicButton mode="teal" onClick={handleClickAddNew}>
                Add New
              </BasicButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MenuBarManagingPositionCategory;
