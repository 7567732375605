/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { hr_info } from '../../../../assets/img';
import { AsyncMultipleSelect, SingleSelect } from '../../../../components';
import { isCheckError, onChangeInputData, showError } from '../../../../hooks/useFormik';
import { useGetRoleInMeeting } from '../../../../hooks/useGetRoleInMeeting';
import {
  AGENDA_STATUS,
  DATE_TIME_FORMAT,
  MEETING_DETAIL_STATUS,
} from '../../../../utils/constants';
import {
  getAsyncOptionAdvocatorsHrPartners,
  isDisabledFiledInAgendaForm,
  optionSelectStatusTalentReview,
} from '../../../../utils/helper';
import {
  AiAgendaForm,
  AiInput,
  AiLabel,
  AiTimePickerField,
  AiTitle,
} from '../../../Meeting/AgendaDetails/Styled';

const TalentReviewAgendaForm = (props) => {
  const { agendaDetail, line, type, role, isShowTalentDetail, formik, addTalent, setAddTalent } =
    props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const { idMeeting, agendaId } = useParams();
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });

  const isDisabledField = useMemo(() => {
    return isDisabledFiledInAgendaForm(agendaId, agendaDetail, meetingRole);
  }, [agendaDetail, agendaId, meetingRole]);

  // useEffect(() => {
  //   formik.setFieldValue(
  //     'option_action',
  //     optionSelectStatusTalentReview(isShowTalentDetail, roleActive.roleName, type, line, role)
  //   );
  //   formik.setFieldValue('is_has_staff', isShowTalentDetail ? true : false);
  //   if (formik.values?.action_type === null) formik.setFieldValue('action_type', '');
  // }, [
  //   optionSelectStatusTalentReview(isShowTalentDetail, roleActive.roleName, type, line, role)
  //     .length,
  //   isShowTalentDetail,
  // ]);

  const isDisableActionType = useMemo(() => {
    if (
      !isNaN(Number(agendaId)) &&
      [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        agendaDetail?.meeting_status
      ) &&
      agendaDetail?.status !== AGENDA_STATUS.PAPER_IN_PROGRESS
    ) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      MEETING_DETAIL_STATUS.IN_PROGRESS === agendaDetail?.meeting_status &&
      ![AGENDA_STATUS.PAPER_IN_PROGRESS, AGENDA_STATUS.PAPER_READY].includes(agendaDetail?.status)
    ) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      MEETING_DETAIL_STATUS.IN_REVIEW === agendaDetail?.meeting_status &&
      agendaDetail?.status !== AGENDA_STATUS.PAPER_FINALIZED
    ) {
      return true;
    }
    if (
      !isNaN(Number(agendaId)) &&
      [
        MEETING_DETAIL_STATUS.PENDING_APPROVAL,
        MEETING_DETAIL_STATUS.MOM_REJECTED,
        MEETING_DETAIL_STATUS.CANCELED,
        MEETING_DETAIL_STATUS.MEETING_COMPLETED,
        MEETING_DETAIL_STATUS.CONCLUDED,
      ].includes(agendaDetail?.meeting_status)
    ) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaDetail?.status, agendaId, agendaDetail?.meeting_status]);

  const actionTypeOptions = useMemo(() => {
    return optionSelectStatusTalentReview(
      isShowTalentDetail,
      roleActive.roleName,
      type,
      line,
      role
    );
  }, [isShowTalentDetail, roleActive.roleName, type, line, role, formik.values.action_type]);

  const actionType = useMemo(() => {
    if (!roleActive.roleName && !type && !role) return null;
    return actionTypeOptions.find((item) => item.value === formik.values.action_type) || '';
  }, [actionTypeOptions]);

  useEffect(() => {
    if (addTalent?.top_talent?.is_updated) {
      formik.setFieldValue('action_type', actionType.value || '');
      setAddTalent((prev) => ({
        ...prev,
        top_talent: { ...prev.top_talent, is_updated: false },
      }));
    }
  }, [addTalent?.top_talent?.is_updated]);

  return (
    <AiAgendaForm>
      <AiTitle>Talent Review Session Details</AiTitle>
      <Row gutter={16} className="mb-3">
        <Col span={16} className="gutter-row">
          <AiLabel>Talent Review Name</AiLabel>
          <AiInput
            placeholder="Enter Talent Review Name"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            status={isCheckError(formik, 'title') ? 'error' : ''}
            disabled={isDisabledField}
          />
          {showError(formik, 'title')}
        </Col>

        <Col span={4} className="gutter-row">
          <AiLabel>Start time</AiLabel>
          <AiTimePickerField
            placeholder={'Select Time'}
            value={
              formik.values.start_time
                ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                : null
            }
            style={{ width: '100%' }}
            onChange={(_event, value) => onChangeInputData(formik, value, 'start_time')}
            status={isCheckError(formik, 'start_time') ? 'error' : ''}
            showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
            format={DATE_TIME_FORMAT.TIME}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={isDisabledField}
          />
          {showError(formik, 'start_time')}
        </Col>
        <Col span={4} className="gutter-row">
          <AiLabel>End time</AiLabel>
          <AiTimePickerField
            placeholder={'Select Time'}
            value={
              formik.values.end_time
                ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                : null
            }
            style={{ width: '100%' }}
            onChange={(_event, value) => onChangeInputData(formik, value, 'end_time')}
            status={isCheckError(formik, 'end_time') ? 'error' : ''}
            showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
            format={DATE_TIME_FORMAT.TIME}
            showNow={false}
            inputReadOnly={true}
            allowClear={false}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={isDisabledField}
          />
          {showError(formik, 'end_time')}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <AiLabel>Advocators</AiLabel>
          <AsyncMultipleSelect
            value={formik.values.advocators}
            placeholder="Enter Advocators Name"
            loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
            onChange={(e) => onChangeInputData(formik, e, 'advocators')}
            status={isCheckError(formik, 'advocators') ? 'error' : ''}
            isDisabled={isDisabledField}
          />
          {showError(formik, 'advocators')}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={24}>
          <AiLabel>
            HR Partners
            {/* <Tooltip
              placement="top"
              title="Please include HR personnel involved during review, endorsement and approval"
              overlayClassName="custom-tooltip"
              {...{
                overlayInnerStyle: {
                  width: '260px',
                  fontSize: '12px',
                  lineHeight: '18px',
                  gap: '24px',
                  padding: '12px',
                  borderRadius: '4px',
                },
              }}
            >
              <span style={{ marginLeft: '5px' }}>
                <img src={hr_info} alt="" />
              </span>
            </Tooltip> */}
          </AiLabel>
          <AsyncMultipleSelect
            name="hr_partners"
            value={formik.values.hr_partners}
            placeholder="Enter HR Partners Name"
            loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
            onChange={(e) => onChangeInputData(formik, e, 'hr_partners')}
            status={isCheckError(formik, 'hr_partners') ? 'error' : ''}
            isDisabled={isDisabledField}
          />
          {showError(formik, 'hr_partners')}
        </Col>
      </Row>
      {/* <Row gutter={16} className="mb-3 mt-3">
        <Col span={6} className="gutter-row">
          <AiLabel>Action Type</AiLabel>
          <div data-testid="select">
            <SingleSelect
              value={
                optionSelectStatusTalentReview(
                  isShowTalentDetail,
                  roleActive.roleName,
                  type,
                  line,
                  role
                ).find((item) => item.value === formik.values.action_type) || ''
              }
              placeholder="Select Action Type"
              options={optionSelectStatusTalentReview(
                isShowTalentDetail,
                roleActive.roleName,
                type,
                line,
                role
              )}
              onChange={(e) => onChangeInputData(formik, e?.value, 'action_type')}
              status={isCheckError(formik, 'action_type') ? 'error' : ''}
              isDisabled={isDisableActionType}
            />
            {showError(formik, 'action_type')}
          </div>
        </Col>
      </Row> */}
    </AiAgendaForm>
  );
};

export default TalentReviewAgendaForm;
