import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 20px;
  width: 1202px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .today {
    padding: 8px 20px;
    border: 1px solid #e9edf0;
    border-radius: 6px;
    color: #00a19c;
    cursor: pointer;
    width: 175px;

    display: flex;
    justify-content: between;
    align-items: center;
    gap: 10px;
  }

  .create {
    padding: 8px 20px;
    border: 1px solid #e9edf0;
    border-radius: 6px;
    color: #00a19c;
    cursor: pointer;
    width: 160px;

    display: flex;
    justify-content: between;
    align-items: center;
    gap: 10px;
  }

  .monthly-and-yearly {
    display: inline-block;
    padding: 6px 18px;
    border: 1px solid #00a19c;
    font-size: 14px;
    cursor: pointer;
    color: #00a19c;
    border: 1px solid #e0e0e0;
  }

  .active {
    background-color: #00a19c;
    color: white;
  }

  .custom-antd-picker {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
  }

  .month-year {
    position: relative;

    img {
      margin-left: 10px;
    }
  }

  .btn-today {
    padding: 10px 20px;
    border: 1px solid #00a19c;
    border-radius: 6px;
    color: #00a19c;
    top: 0;
    cursor: pointer;
    width: 80px;
    height: 40px;
  }

  .datePicker {
    visibility: hidden;
    position: absolute;
    transform: translate(-36px, -16px);
    border: 1px solid #c4c3d1;
    border-radius: 6px;
    background-color: white;
    z-index: 10;
    color: #3f3c4c;
  }
  .ant-picker-dropdown,
  .ant-picker-dropdown-placement-bottomLeft {
    top: 40px !important;
    left: 0 !important;
  }
  .ant-picker-panel-container {
    border-radius: 6px;
  }
  .ant-picker-header {
    cursor: default;
  }
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    visibility: hidden;
  }

  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn {
    border-radius: 50%;
    background-color: #fff;
    transform: translateX(10px);
  }

  .ant-picker-header-prev-btn {
    transform: translateX(-10px);
  }
  .ant-picker-cell-week {
    display: none !important;
  }

  .ant-picker-content {
    thead > tr > th:first-child {
      display: none !important;
    }
    thead > tr > th {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: transparent;
  }
  .custom-day {
    height: 36px;
    line-height: 36px;
  }
  .custom-day-of-week {
    height: 36px;
    line-height: 36px;
    background: rgba(52, 69, 99, 0.2);
    color: #3f3c4c;
  }

  .end-of-week {
    height: 36px;
    line-height: 36px;
    background: rgba(52, 69, 99, 0.2);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    position: relative;
  }

  .start-of-week {
    height: 36px;
    line-height: 36px;
    background: rgba(52, 69, 99, 0.2);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    position: relative;
  }

  .override-day {
    position: absolute;
    height: 36px;
    width: 36px;
    top: 0;
    left: 0;
    background-color: #00a19c;
    color: #fff;
    border-radius: 50%;
  }
  .ant-picker-year-btn {
    display: none;
  }

  .ant-picker-month-btn {
    visibility: hidden;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
  }

  .ant-picker-footer-extra,
  .ant-picker-footer {
    line-height: 14px;
  }
  .button-month-calendar {
    margin: 10px 0;
    background-color: white;
    color: #00a19c;
    padding: 8px 25px;
    border: 1px solid #00a19c;
    border-radius: 6px;
    font-size: 10px;
    cursor: pointer;
  }
  .date-in-month {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    cursor: pointer;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3f3c4c;
  }
`;
