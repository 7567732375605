import { isEmpty } from 'lodash';
import React from 'react';

import styles from './search-box-custom.module.scss';

const SearchListOption = (props) => {
  const { data, setSearchTerm, fieldName, setIsActive, isEdit } = props;

  const handleSelectOption = async (item) => {
    await setSearchTerm({ value: item?.value, type: 'submit', showTerm: item?.label || '' });
  };

  const handleShowListItems = (fieldName) => {
    return (
      <ul
        className={`${styles.search_list_scroll} ${isEdit && styles.is_edit}`}
        data-testid="data-is-true"
      >
        {data.map((item, index) => {
          return (
            <li
              key={index}
              className={styles.search_item}
              onKeyDown={() => {}}
              onClick={() => {
                handleSelectOption(item);
                setIsActive(false);
              }}
            >
              <div className={styles.individual}>
                <div className={styles.name}>
                  <p>{item?.label || ''}</p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {/* Loading */}
      {isEmpty(data) && (
        <div className={styles.search_list} data-testid="loading">
          <div data-testid="loading-pos" className={styles.dot_flashing}></div>
        </div>
      )}

      {/* Show item */}
      {!isEmpty(data) && <>{handleShowListItems(fieldName)}</>}

      {/* Not Found */}
      {/* <div className={styles.search_list} data-testid="data-is-false">
        <p className={styles.search_notFound}>Search not found ...</p>
      </div> */}
    </>
  );
};

export default SearchListOption;
