import React from 'react';
import { FieldGroupStyled, Label } from './styled';

export default React.memo(({ className, width, label, children, name }) => {
  return (
    <FieldGroupStyled width={width} className={className}>
      <Label>{label}</Label>
      {children}
    </FieldGroupStyled>
  );
});
