import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { succession_planning_copy } from '../../../../assets/img';
import { adminApi } from '../../../../services/admin';
import DataTable from './DataTable/DataTable';
import MenuBarCopySP from './MenuBarCopySP/MenuBarCopySP';
import SearchPositionToCopy from './SearchPositionToCopy/SearchPositionToCopy';
import * as styles from './copy-succession-planning.module.scss';

export default function CopySuccessionPlanning(props) {
  const [isVisible, setIsVisible] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [dataCopyBtn, setDataCopyBtn] = useState({});
  const [data, setData] = useState([]);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const query = {
        keyword,
      };
      setLoading(true);
      const res = await adminApi.getApprovedSP(query, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result);
      }
      setLoading(false);
    } catch (error) {
      setData([]);
      console.log(error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (!firstTimeRender) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    setFirstTimeRender(true);
  }, []);

  return (
    <div>
      <Spin spinning={loading} size="large">
        <MenuBarCopySP setKeyword={setKeyword} keyword={keyword} fetchData={fetchData} />
        {!isEmpty(data) ? (
          <DataTable data={data} setIsVisible={setIsVisible} setDataCopyBtn={setDataCopyBtn} />
        ) : (
          <div className={styles.not_found}>
            <img src={succession_planning_copy} alt="not_found" />
          </div>
        )}
        {isVisible && (
          <SearchPositionToCopy
            fetchDatas={fetchData}
            searchPositionToCopyDialog={isVisible}
            setIsVisible={setIsVisible}
            dataCopyBtn={dataCopyBtn}
          />
        )}
      </Spin>
    </div>
  );
}
