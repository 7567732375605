import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import moment from 'moment';
import { SearchTalentComparison } from '../../../../components';
import { getTalentDetails } from '../../../../services/talentProfiles';
import { talentReviewApi } from '../../../../services/talentReviewApi';
import styles from './talent-search-form.module.scss';
const antIcon = (
  <LoadingOutlined style={{ fontSize: 16, color: '#fff', marginLeft: '10px' }} spin />
);

const TalentSearchForm = (props) => {
  const {
    isShowTalentSearchModal,
    setIsShowTalentSearchModal,
    setTalentSearchDetail,
    setAddTalent,
  } = props;
  const [talentDetail, setTalentDetail] = useState({});
  const user = useSelector((state) => state.user);
  const [loadingSuccesionPlanning, setLoadingSuccessionPlanning] = useState(false);
  const setLoading = useState(false)[1];

  const addTalent = async (data) => {
    if (data) {
      setTalentDetail({
        talent: data,
      });
      try {
        setLoading(true);
        const response = await getTalentDetails({ staffId: data?.staffId, roleUser: user });
        if (response.status === 200) {
          const talentDetails = {
            ...response?.data?.result,
            id: data?.staffId,
          };
          setTalentDetail((prevState) => {
            return { ...prevState, detail: talentDetails };
          });
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  const onClickAddTalent = async () => {
    if (!isEmpty(talentDetail)) {
      setTalentSearchDetail(talentDetail);
      try {
        setLoadingSuccessionPlanning(true);
        const res = await talentReviewApi.getSuccessionPlanForTalentReview(
          talentDetail.talent.staffId
        );
        const firstLine = get(res, 'data.result.first_line') || [];
        const secondLine = get(res, 'data.result.second_line') || [];
        const thirdLine = get(res, 'data.result.third_line') || [];

        setAddTalent((prev) => {
          return {
            ...prev,
            succession_planning: {
              first_line: firstLine.map((item) => {
                return {
                  ...item,
                  color:
                    item?.approved_date &&
                    item?.last_approved_date &&
                    moment(moment(item.approved_date).format('YYYY-MM-DD')).isSame(
                      moment(item.last_approved_date).format('YYYY-MM-DD')
                    )
                      ? '#66B2C4'
                      : '',
                };
              }),
              second_line: secondLine.map((item) => {
                return {
                  ...item,
                  color:
                    item?.approved_date &&
                    item?.last_approved_date &&
                    moment(moment(item.approved_date).format('YYYY-MM-DD')).isSame(
                      moment(item.last_approved_date).format('YYYY-MM-DD')
                    )
                      ? '#BDB6EA'
                      : '',
                };
              }),
              third_line: thirdLine.map((item) => {
                return {
                  ...item,
                  color:
                    item?.approved_date &&
                    item?.last_approved_date &&
                    moment(moment(item.approved_date).format('YYYY-MM-DD')).isSame(
                      moment(item.last_approved_date).format('YYYY-MM-DD')
                    )
                      ? '#BCC6E1'
                      : '',
                };
              }),
              agreed_to_drop: prev?.succession_planning?.agreed_to_drop,
            },
          };
        });
      } catch (error) {
        console.log(error);
      }
      setLoadingSuccessionPlanning(false);
    } else {
      setTalentSearchDetail();
    }
    setIsShowTalentSearchModal(false);
  };

  return (
    <div>
      <Modal
        show={isShowTalentSearchModal}
        onHide={() => setIsShowTalentSearchModal(false)}
        dialogClassName={styles.talent_search_form}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <div className={styles.talent_search_content}>
          <Modal.Header className={styles.modal_header} closeButton>
            <h1>Add Talent to Talent Review</h1>
          </Modal.Header>

          <Modal.Body className={styles.modal_body}>
            <SearchTalentComparison
              placeholder="Search"
              addTalent={addTalent}
              newStyle={{ width: '100%' }}
            />
            <div className={styles.talent_search_button}>
              <button
                className={styles.add_talent_btn}
                onClick={onClickAddTalent}
                disabled={isEmpty(talentDetail)}
              >
                Add Talent
                {loadingSuccesionPlanning && <Spin indicator={antIcon} />}
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default TalentSearchForm;
