import { DatePicker, Select } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';
import { DEVELOPMENT_PLAN_STATUS, ROLE_LEVEL_LIST } from '../../utils/constants';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './development-plan.module.scss';

const AddEditDevelopmentPlan = ({ open, setOpen, isEdit, handleSubmit, dataEdit }) => {
  const formik = useFormik({
    initialValues: {
      platform: dataEdit?.library_goal || '',
      date: dataEdit?.start_date ? moment(dataEdit.start_date) : null,
      status: dataEdit?.status || null,
      development_plan: dataEdit?.description || '',
    },
    validationSchema: Yup.object({
      platform: Yup.string().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      date: Yup.date().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      status: Yup.string().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      development_plan: Yup.string().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (value) => {
      const dataNote = {
        ...value,
        date: new Date(value.date).toISOString().replace('Z', ''),
      };
      handleSubmit(dataNote, formik.handleReset);
    },
  });
  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      title={isEdit ? 'Edit Development Plan' : 'Add Development Plan'}
      onOk={formik.submitForm}
      onCancel={() => formik.handleReset()}
      okText={isEdit ? 'Save Changes' : 'Add Development Plan'}
      width={1000}
      destroyOnClose
    >
      <div className={styles.body}>
        <h4 className={styles.title}>
          {isEdit
            ? 'Edit Development Plan on the platform'
            : 'Add Development Plan on the platform'}
        </h4>

        <div className="row mb-3">
          <div className="col">
            <div className={`${styles.label}`}>
              Platform <span style={{ color: 'red' }}>*</span>
            </div>
            <input
              name="platform"
              className={styles.formInput}
              placeholder="Select Platform"
              value={formik.values.platform}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            {formik.errors.platform && formik.touched.platform && (
              <div className={styles.text_error}> {formik.errors.platform}</div>
            )}
          </div>
          <div className="col">
            <div className={`${styles.label}`}>
              Date<span style={{ color: 'red' }}>*</span>
            </div>
            <DatePicker
              name="date"
              getPopupContainer={(trigger) => trigger.parentElement}
              value={formik.values.date}
              className={styles.formInput}
              onChange={(newDate) => {
                formik.setFieldValue('date', newDate);
              }}
              format="DD MMMM YYYY"
              placeholder="Select Date"
              inputReadOnly
            />
            {formik.errors.date && formik.touched.date && (
              <div className={styles.text_error}> {formik.errors.date}</div>
            )}
          </div>
          <div className="col">
            <div className={`${styles.label}`}>
              Status<span style={{ color: 'red' }}>*</span>
            </div>
            <Select
              size="middle"
              options={DEVELOPMENT_PLAN_STATUS}
              getPopupContainer={(trigger) => trigger.parentElement}
              name="status"
              style={{ width: '100%' }}
              placeholder="Select Status"
              value={formik.values.status}
              onChange={(value) => {
                formik.setFieldValue('status', value);
              }}
            />
            {formik.errors.status && formik.touched.status && (
              <div className={styles.text_error}> {formik.errors.status}</div>
            )}
          </div>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <div className={`${styles.label}`} style={{ alignItems: 'unset' }}>
            Development Plan<span style={{ color: 'red' }}>*</span>
          </div>
          <textarea
            name="development_plan"
            className={styles.formInput}
            value={formik.values.development_plan}
            onChange={formik.handleChange}
            style={{ height: '94px' }}
            data-testid="note-textarea"
          />
          {formik.errors.development_plan && formik.touched.development_plan && (
            <div className={styles.text_error}> {formik.errors.development_plan}</div>
          )}
        </div>
      </div>
    </CustomAntModal>
  );
};

export default AddEditDevelopmentPlan;
