import styled from 'styled-components';
import { font } from '../../../../../assets/common';

const KeyStrengthTable = styled.div`
  width: 100%;
  border-right: 1px solid #ececec;

  &:last-child {
    border-right: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0168em;
        color: #3d3d3d;
        background: #ffffff;

        &:first-child {
          border-right: 1px solid #ececec;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        font-family: ${font.inter};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.0144em;
        padding-left: 30px;
        color: #181818;
        vertical-align: top;
        white-space: pre-wrap;

        &:first-child {
          border-right: 1px solid #ececec;
        }

        span {
          display: inline-block;
        }

        .normalContent {
          position: relative;

          &::before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: #3d3d3d;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: -10px;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    .ant-empty-normal {
      display: none;
    }
  }
`;

const NoteTable = styled.div`
  width: 100%;

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0168em;
        color: #3d3d3d;
        background: #ffffff;
      }
    }
  }

  .ant-table-thead,
  .ant-table-tbody {
    tr {
      th:first-child,
      td:first-child {
        border-right: 1px solid #ececec;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        font-family: ${font.inter};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.0144em;
        padding-left: 30px;
        color: #181818;
        vertical-align: top;
        white-space: pre-wrap;

        .noteTitle {
          font-family: ${font.inter};
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.0168em;
          color: #3d3d3d;
        }

        .normalContent {
          position: relative;

          &::before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: #3d3d3d;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: -10px;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

export { KeyStrengthTable, NoteTable };
