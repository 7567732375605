import React from 'react';
import styles from './talent-finder-detail.module.scss';
import TalentFinderGrid from './Grid/TalentFinderGrid';
import TalentFinderList from './List/TalentFinderList';

const TalentFinderDetail = (props) => {
  const { mode, page, limit, totalData } = props;
  const fromResult = (page - 1) * limit + 1;
  const toResults = (page - 1) * limit + limit;
  return (
    <div className={styles.detail_section} data-testid="talent-finder-detail">
      {mode ? (
        <TalentFinderGrid
          totalResult={totalData}
          fromResult={fromResult}
          toResults={toResults}
          {...props}
        />
      ) : (
        <TalentFinderList
          totalResult={totalData}
          fromResult={fromResult}
          toResults={toResults}
          {...props}
        />
      )}
    </div>
  );
};

export default TalentFinderDetail;
