import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { BasicButton } from '../../../../assets/common';
import { dashboardApi } from '../../../../services/dashboardApi';
import {
  ColumnName,
  ColumnNo,
  ColumnPlatformAndDate,
  CustomNumber,
  InputSearch,
  SearchBtn,
  SearchDiv,
  TableData,
  TableDiv,
  Text,
  Title,
  Wrapper,
} from './historicalApprovedSpPopoutStyled';
import { AGENDA_TYPES } from '../../../MobilitySimulation/constants';

export default function HistoricalApprovedSpPopout(props) {
  const { openApprovedSp, setOpenApprovedSp, positionCode, roleId } = props;
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    keyword: '',
    limit: 10,
    isClicked: false,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
    },
    {
      title: 'Agenda Name/ Position Name',
      dataIndex: 'name',
      key: 'name',
      width: '35%',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      width: '25%',
    },
    {
      title: 'Meeting Date',
      dataIndex: 'date',
      key: 'date',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '15%',
    },
  ];

  const handleViewApprove = (item) => {
    if (item.type === AGENDA_TYPES.TALENT) {
      window.open(
        `/meeting/${item.meeting_id}/talent-review-agenda/${item.agenda_id}?history=sp-dashboard`
      );
    } else {
      window.open(`/historical-approved-sp/${item.agenda_id}`);
    }
  };

  useEffect(() => {
    if (!positionCode || !roleId) return;
    (async function getHistoricalDataApproveSp() {
      setLoading(true);
      try {
        const res = await dashboardApi.getHistoricalDataApproveSp(
          {
            page: query.page,
            keyword: query.keyword,
            limit: query.limit,
            position_code: positionCode,
          },
          roleId
        );
        const data = get(res, 'data.result.data') || [];
        const total = get(res, 'data.result.total') || 0;
        setData(data);
        setQuery((prev) => ({ ...prev, total }));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [positionCode, roleId, query.page, query.isClicked]);

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = query.page * query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.total,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <Text>
        Showing <CustomNumber>{fromResult}</CustomNumber> to{' '}
        <CustomNumber>{toResults > query.total ? query.total : toResults}</CustomNumber> of{' '}
        <CustomNumber>{query.total}</CustomNumber> results
      </Text>
    ),
  };

  return (
    <Wrapper
      title={<Title>List of Historical Approved SP and Associated TR</Title>}
      open={openApprovedSp}
      onCancel={() => setOpenApprovedSp(false)}
      width={1450}
      footer={null}
    >
      <Spin size="large" spinning={loading}>
        <SearchDiv>
          <InputSearch
            placeholder="Search Agenda Name/ Platform Name"
            onChange={(e) => {
              const value = e.target.value;
              setQuery((prev) => ({ ...prev, keyword: value }));
            }}
          />
          <SearchBtn
            onClick={() => setQuery((prev) => ({ ...prev, page: 1, isClicked: !prev.isClicked }))}
          >
            Search
          </SearchBtn>
        </SearchDiv>
        <TableDiv>
          <TableData
            dataSource={
              !isEmpty(data) &&
              data.map((item, _index) => ({
                no: <ColumnNo>{(query.page - 1) * query.limit + _index + 1}.</ColumnNo>,
                name: (
                  <>
                    <ColumnName>{item.agenda_name}</ColumnName>
                  </>
                ),
                platform: <ColumnPlatformAndDate>{item.platform}</ColumnPlatformAndDate>,
                date: (
                  <ColumnPlatformAndDate>
                    {moment(item.approved_date).format('DD MMM YYYY')}
                  </ColumnPlatformAndDate>
                ),
                action: (
                  <BasicButton
                    onClick={() => handleViewApprove(item)}
                    disabled={!item.agenda_name && !item.position_name}
                    style={
                      item.type === AGENDA_TYPES.TALENT ? { width: `200px`, padding: `5px` } : {}
                    }
                  >
                    {item.type === AGENDA_TYPES.TALENT
                      ? 'View changes from TR in SP'
                      : 'View Approved SP'}
                  </BasicButton>
                ),
              }))
            }
            columns={columns}
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
        </TableDiv>
      </Spin>
    </Wrapper>
  );
}
