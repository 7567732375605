import { createSelector } from '@reduxjs/toolkit';
import { invoke } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import userApi from '../services/userApi';
import { PERMISSION } from '../utils/constants';

const selectRoleActive = createSelector(
  (state) => state.user.roleActive,
  (active) => active
);

export const useProfilesSearch = (options) => {
  const roleActive = useSelector(selectRoleActive);
  const [loading, setLoading] = useState(false);

  const optionsRef = useRef(options);
  optionsRef.current = options;

  const roleId = roleActive?.roleId;
  const searchProfiles = useCallback(
    async (search, agenda_id) => {
      const permission = {
        permission: PERMISSION.SEARCH_TALENT_PROFILE,
        role: roleId,
      };
      const body = { search, agenda_id, limit: 4, page: 1 };
      try {
        const response = await userApi.getSearchList(permission, body);
        if (response.data && response.data.result && response.data.result.profiles?.length > 0) {
          const { profiles } = response.data.result;
          const dataMapped = optionsRef.current.mapData
            ? optionsRef.current.mapData(profiles)
            : profiles;
          setLoading(false);
          invoke(optionsRef.current, 'onSuccess', dataMapped);
          return dataMapped;
        }
      } catch (error) {}
      setLoading(false);
      return [];
    },
    [roleId]
  );

  return { loading, searchProfiles };
};
