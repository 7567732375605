import { invoke } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { meetingApi } from '../services/tcMeeting';

export const useCreateMeeting = (options) => {
  const optionsRef = useRef(options);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const roleActive = useSelector((state) => state.user.roleActive);

  useEffect(() => {
    optionsRef.current = options;
  });

  const handleOnSuccess = useCallback((response) => {
    setSuccess(true);
    invoke(optionsRef.current, 'onSuccess', response.data);
  }, []);

  const handleOnError = useCallback((errorMessage) => {
    setSuccess(false);
    invoke(optionsRef.current, 'onError', errorMessage);
    setError(errorMessage);
  }, []);

  const createMeeting = useCallback(
    async (meetingData) => {
      try {
        setSuccess(false);
        setError(null);
        const response = await meetingApi.createMeeting(meetingData, roleActive);
        handleOnSuccess(response);
        return response.data;
      } catch (err) {
        handleOnError(err);
        return {};
      }
    },
    [handleOnError, handleOnSuccess, roleActive]
  );

  return { isSuccess, error, createMeeting };
};
