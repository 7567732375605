export const PROFILE_MANAGEMENT_MESSAGES = {
  AN_UNEXPECTED_ERROR: 'An unexpected error occurred.',
  FILL_ALL_FIELD_REQUIRED: 'Please fill in the required field(s).',
  SEARCH_NOT_FOUND: 'No matching staff found.',
  LIST_PROFILE_DELETE_SUCCESS: 'Successfully deleted from List of Profiles',
  LIST_PROFILE_ADD_SUCCESS: 'Successfully added to List of Profiles',
  UNSEARCHABLE_DELETE_SUCCESS: 'Successfully Deleted from Unsearchable list',
  UNSEARCHABLE_ADD_SUCCESS: 'Successfully added to Unsearchable list',
};

export const LIST_OF_PROFILES_FILTER_TYPES = {
  ROLE_LEVEL: 'Role Level',
  BUSINESS: 'Business',
};

export const STAFF_SG_JG_FILTER_TYPES = {
  SUCCESSOR: 'Successor',
  TOP_TALENT_STATUS: 'Top Talent Status',
  MISSING_DATA: 'Missing Data',
};

export const LIST_OF_PROFILES_EXPORT_COLUMNS = [
  {
    title: 'Staff Name',
  },
  {
    title: 'Staff ID',
  },
  {
    title: 'Business',
  },
  {
    title: 'Role Level',
  },
];

export const LIST_OF_PROFILES_EXPORT_FILE_NAME = 'myTalentX List of Profiles';

export const SEARCH_INPUT_PLACEHOLDER = 'Search Staff Name or Staff ID';

export const ADD_TALENT_FORM_TYPE = {
  LIST_PROFILES: 'List Profiles',
  UNSEARCHABLE_LIST: 'Unsearchable List',
};

//Top Talent Status constants
export const TOP_TALENT_TABLE_LIMIT = 15;
export const TOP_TALENT_MESSAGE = {
  SEARCH_NOT_FOUND: 'No matching talent found.',
  DELETE_SUCCESS: 'Top Talent Status successfully deleted',
};
export const ASSESSMENT_OPTIONS = [
  { value: 'DC', label: 'DC' },
  { value: 'DW', label: 'DW' },
  { value: 'EP', label: 'EP' },
];

//External Talents Constants
export const EXTERNAL_TALENTS_TABLE_ROW_LIMIT = 15;
export const EXTERNAL_TALENT_MESSAGE = {
  SEARCH_NOT_FOUND: 'No matching talent found.',
  DELETE_SUCCESS: 'External Talent successfully deleted',
};
