import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import styles from './work-preference.module.scss';
import talentProfileApi from '../../services/talentProfiles';

const INIT_DATA = {
  willing_to_relocate: '',
  option: '',
  constraint_details: '',
};

const WorkPreference = (props) => {
  const { profileId } = props;
  const user = useSelector((state) => state.user);
  const [workPreference, setWorkPeference] = useState(INIT_DATA);
  const [loading, setLoading] = useState(false);

  async function fetchApi() {
    try {
      setLoading(true);
      const response = await talentProfileApi.fetchWorkPreferenceStatus({
        profileId,
        roleUser: user,
      });
      if (response.status === 200) {
        const result = response?.data?.result;
        if (result) {
          setWorkPeference(result);
        }
      }
    } catch (error) {
      setWorkPeference(INIT_DATA);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user.roleActive) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleActive.roleId, profileId]);

  return (
    <div className={styles.SectionPlan} data-testid="historical-talent">
      <div className={styles.SectionName}>
        <span>Work Preference</span>
      </div>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table table-borderless">
            <thead className={styles.header}>
              <tr className={styles.tablePlanRow}>
                <th className={styles.leftItem}>
                  <span>Item</span>
                </th>
                <th className={styles.rightItem}>
                  <span>Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tablePlanRow}>
                <th scope="row" className={styles.leftItem}>
                  Willing to relocate
                </th>
                <td className={styles.rightItem}>{workPreference.willing_to_relocate || '-'}</td>
              </tr>
              <tr className={styles.tablePlanRow}>
                <th scope="row" className={styles.leftItem}>
                  Option
                </th>
                <td className={styles.rightItem}>{workPreference.option || '-'}</td>
              </tr>
              <tr className={styles.tablePlanRow}>
                <th scope="row" className={styles.leftItem}>
                  Constraint Details
                </th>
                <td className={styles.rightItem}>{workPreference.constraint_details || '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Spin>
    </div>
  );
};

export default WorkPreference;
