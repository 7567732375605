import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { utils } from 'xlsx-js-style';
import {
  HISTORICAL_COMMON_STYLE,
  historicalExportDeclareWorkSheet,
  historicalExportSetMergeAndColWidth,
  historicalExportStyleAllCells,
  historicalExportUnshiftHeader,
  historicalSetColorsAndWriteFile,
} from './CommonExportHistorical';

const ExportAgeAndGender = (props) => {
  const { setExportData, historicalData } = props;

  const exportAgeGender = useCallback(async () => {
    if (!historicalData) return [];

    const fileName = 'Age Gender';
    const tempData = [];
    historicalData.forEach((item) => {
      const valueItem = item.value;
      const rows = [
        item.month,
        valueItem.map((val) => Object.values({ male: val.male, female: val.female })).flat(),
      ].flat();
      tempData.push(rows);
    });

    const length = tempData[0].length;
    const headers1 = new Array(length).fill('');
    headers1[0] = 'Months (End of months)';
    headers1[1] = 'Age and Gender';
    const headers2 = new Array(length).fill('');
    headers2[0] = 'Months (End of months)';
    headers2[1] = '20-29';
    headers2[3] = '30-39';
    headers2[5] = '40-49';
    headers2[7] = '50-59';
    headers2[9] = 'Above 60';
    let headers3 = new Array(length).fill('');
    headers3 = headers3.map((item, index) => {
      if (index === 0) item = 'Months (End of months)';
      if (index !== 0 && index % 2 === 0) item = 'Female';
      if (index !== 0 && index % 2 !== 0) item = 'Male';
      return item;
    });

    historicalExportUnshiftHeader(tempData, [headers3, headers2, headers1]);

    if (tempData.length > 0) {
      let [ws, wb] = historicalExportDeclareWorkSheet(tempData);
      utils.book_append_sheet(wb, ws, 'Age & Gender');

      const shortHandMerges = [
        [0, 0],
        [2, 0],
        [0, 1],
        [0, length - 1],
        [1, 1],
        [1, 2],
        [1, 3],
        [1, 4],
        [1, 5],
        [1, 6],
        [1, 7],
        [1, 8],
        [1, 9],
        [1, 10],
      ];

      historicalExportSetMergeAndColWidth(ws, shortHandMerges, length, 10);

      historicalExportStyleAllCells(ws);

      const colorCellMapping = {
        '00a9b1': ['A1', 'B1'],
      };

      historicalSetColorsAndWriteFile(ws, wb, fileName, colorCellMapping, () => {
        for (let i = 66; i <= 75; i++) {
          const value1 = ws[`${String.fromCharCode(i)}${2}`].s;
          const value2 = ws[`${String.fromCharCode(i)}${3}`].s;
          ws[`${String.fromCharCode(i)}${2}`].s = {
            ...value1,
            ...HISTORICAL_COMMON_STYLE,
            fill: { fgColor: { rgb: '7030a0' } },
          };
          ws[`${String.fromCharCode(i)}${3}`].s = {
            ...value2,
            ...HISTORICAL_COMMON_STYLE,
            fill: { fgColor: { rgb: '7030a0' } },
          };
        }
      });
    }
  }, [historicalData]);

  useEffect(() => {
    exportAgeGender();
    setExportData(false);
  }, [setExportData, exportAgeGender]);
  return <></>;
};

ExportAgeAndGender.propTypes = {
  setExportData: PropTypes.func,
  historicalData: PropTypes.array,
};

export default ExportAgeAndGender;
