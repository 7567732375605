import React from 'react';
import { Dropdown, Menu, Space } from 'antd';
import * as styles from './mobility-dropdown.module.scss';
import { deleteIcon, duplicate, editNote } from '../../../../assets/img';
import { ENDORSED_STATUS } from '../../constants';

const MobilityDropdown = (props) => {
  const { id, handleEdit, setVisibleDelete, handleDuplicate, endorsed } = props;

  const menu = (
    <Menu
      items={[
        {
          label: (
            <div
              className={`${styles.dropdownBtn} ${styles.editBtn}`}
              onKeyDown={() => {}}
              onClick={() => handleEdit(id)}
            >
              <img src={editNote} alt="edit" />
              <span>Edit</span>
            </div>
          ),
        },
        {
          label: (
            <div
              className={`${styles.dropdownBtn} ${styles.deleteBtn}`}
              onKeyDown={() => {}}
              onClick={() => setVisibleDelete(true)}
            >
              <img src={deleteIcon} alt="delete" />
              <span>Delete</span>
            </div>
          ),
        },
      ]}
    />
  );

  const menuEndorsed = (
    <Menu
      items={[
        {
          label: (
            <div
              className={`${styles.dropdownBtn} ${styles.editBtn}`}
              onKeyDown={() => {}}
              onClick={() => handleDuplicate(id)}
            >
              <img src={duplicate} alt="duplicate" />
              <span>Duplicate</span>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <Dropdown
        overlay={ENDORSED_STATUS.includes(endorsed) ? menuEndorsed : menu}
        trigger={['click']}
        placement="bottomRight"
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <Space>...</Space>
      </Dropdown>
    </>
  );
};

export default MobilityDropdown;
