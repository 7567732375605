import React from 'react';

import TalentFinderPagination from '../Pagination/TalentFinderPagination';
import TalentFinderTooltip from '../TalentFinderTooltip/TalentFinderTooltip';
import styles from './talent-finder-grid.module.scss';
import { MODE_TYPES } from '../../../../utils/constants';

const TalentFinderGrid = (props) => {
  const { page, toResults, limit, fromResult, totalResult, data, onPageChange } = props;

  return (
    <div className={styles.detail_grid_section} data-testid="talent-finder-grid">
      {data.map((d) => (
        <div className={styles.card} key={`talent-finder-grid${d.key}`}>
          <div className={styles.content}>
            <div className={styles.avatar_wrapper} style={{ marginBottom: '20px' }}>
              <img
                src={d.avatar}
                alt=""
                width={96}
                height={96}
                style={{ borderRadius: '50%', border: '5px solid #F0F3F7' }}
              />
              <TalentFinderTooltip item={d} mode={MODE_TYPES.GIRD} />
            </div>
            <div className={styles.name}>{d.name}</div>
            <div>{d.positionName}</div>
            <div>{d.department}</div>
            <div>{d.companyOpu}</div>
            <div>{d.businessUnit}</div>
          </div>

          <div
            className={styles.action}
            onKeyDown={() => {}}
            onClick={() => window.open(`/profile/${d.staffId}`, '_blank')}
          >
            {d.action}
          </div>
        </div>
      ))}
      <TalentFinderPagination
        currentPage={page}
        totalResult={totalResult}
        limit={limit}
        startRecordNumber={fromResult}
        endRecordNumber={toResults > totalResult ? totalResult : toResults}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default TalentFinderGrid;
