import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { meetingApi } from '../../../services/tcMeeting';

const handleApiCall = async (apiCall, params) => {
  try {
    const response = await apiCall(params);
    return response;
  } catch (error) {
    notification.error({
      message: error.message,
      placement: 'topRight',
      duration: 1.5,
    });
    throw new Error(error);
  }
};

const createFilterRemarks = (items, key) => {
  return uniqBy(
    items
      .filter((item) => item[key])
      .map((item) => ({
        value: item[key],
        label: item[key],
      })),
    'label'
  );
};

export const getRemarks = createAsyncThunk('remarks/getRemarks', async (params) => {
 
  const response = await handleApiCall(meetingApi.getRemarks, params);
  const data = response.data.result;
  const dataConvert = data.map((item) => {
    return {
      birthName: item.birth_name,
      meetingDate: item.meeting_date,
      meetingId: item.meeting_id,
      meetingName: item.meeting_name,
      remarks: item.remarks,
      remarksCategory: item.category,
      staffId: item.staff_id,
      remarkId: item.remark_id,
    };
  });

  return dataConvert;
});

export const updateRemarks = createAsyncThunk('remarks/updateRemarks', async (params, thunkAPI) => {
  const response = await handleApiCall(meetingApi.updateRemarks, params);
  if (response.data.code === 200) {
    const data = response.data.result;
    notification.success({
      message: `Updated successfuly`,
      placement: 'topRight',
      duration: 1.5,
    });
    thunkAPI.dispatch(
      editRemark({
        remarkId: data.remark_id,
        category: data.category,
        remarks: data.remark,
      })
    );
    thunkAPI.dispatch(addOneFilterRemark());
  }
});

export const deleteRemarks = createAsyncThunk('remarks/deleteRemark', async (params, thunkAPI) => {
  const { remark_id: remarkId } = params;
  const response = await handleApiCall(meetingApi.deleteRemarks, params);
  if (response.data.code === 200) {
    thunkAPI.dispatch(deleteRemark({ remarkId }));
    thunkAPI.dispatch(addFilterRemark());
  }
});

const remarkSlice = createSlice({
  name: 'remarks',
  initialState: {
    loading: false,
    remarks: [],
    rootRemarks: [],
    prevRemarks: [],
    filterByName: [],
    filterByCategory: [],
    filterList: {},
    error: '',
    createList: [],
    editList: [],
    deleteList: [],
  },
  reducers: {
    editRemark: (state, action) => {
      const { remarkId, category, remarks } = action.payload;
      state.remarks = state.remarks.map((item, _idx) => {
        if (remarkId === item.remarkId) {
          let itemUpdate = { ...item };
          if (remarks) {
            itemUpdate = {
              ...itemUpdate,
              remarks: remarks,
            };
          }
          if (category) {
            itemUpdate = {
              ...itemUpdate,
              remarksCategory: category,
            };
          }

          return itemUpdate;
        }
        return item;
      });
    },
    deleteRemark: (state, action) => {
      const { remarkId } = action.payload;
      state.remarks = state.remarks.filter((item, _index) => item.remarkId !== remarkId);
    },

    addFilterRemark: (state) => {
      state.filterByName = createFilterRemarks(state.remarks, 'birthName');
      state.filterByCategory = createFilterRemarks(state.remarks, 'remarksCategory');
    },

    addOneFilterRemark: (state) => {
      state.filterByCategory = createFilterRemarks(state.remarks, 'remarksCategory');
    },
    addFilterListByUser: (state, action) => {
      const { filterList } = action.payload;
      state.filterList = filterList;
    },
    clearRemarks: (state) => {
      state.remarks = [];
      state.rootRemarks = [];
      state.filterList = {};
      state.filterByName = [];
      state.filterByCategory = [];
      state.createList = [];
      state.editList = [];
      state.deleteList = [];
      state.prevRemarks = [];
    },
    saveRemarks: (state, action) => {
      const remark = action.payload;
      if (remark.type === 'create') {
        const newRemark = { ...remark, remarkId: uuidv4() };
        let createList = cloneDeep(state.createList);
        createList.push(newRemark);
        state.createList = createList;

        let remarks = cloneDeep(state.remarks);
        remarks.push(newRemark);
        state.remarks = remarks;
        return;
      }
      if (remark.type === 'edit') {
        let editList = cloneDeep(state.editList);
        if (isNaN(Number(remark.remarkId))) {
          let createList = cloneDeep(state.createList);
          createList = createList.map((item) => {
            return item.remarkId === remark.remarkId
              ? {
                  ...item,
                  remarksCategory: remark.body.category,
                  remarks: remark.body.remarks,
                  type: 'create',
                }
              : item;
          });
          state.createList = createList;
          return;
        }
        const exitsRemark = editList.find((item) => item.remarkId === remark.remarkId);
        if (!exitsRemark) {
          editList.push(remark);
        } else {
          editList = editList.map((item) => {
            return item.remarkId === remark.remarkId ? remark : item;
          });
        }
        state.editList = editList;
        return;
      }
      if (remark.type === 'delete') {
        if (isNaN(Number(remark.remarkId))) {
          let createList = cloneDeep(state.createList);
          createList = createList.filter((item) => item.remarkId !== remark.remarkId);
          state.createList = createList;
          return;
        }
        let deleteList = cloneDeep(state.deleteList);
        const exitsRemark = deleteList.find((item) => item.remarkId === remark.remarkId);
        if (!exitsRemark) deleteList.push(remark);
        state.deleteList = deleteList;

        let editList = cloneDeep(state.editList);
        const exitsRemarkInEdit = editList.find((item) => item.remarkId === remark.remarkId);
        if (exitsRemarkInEdit) {
          editList = editList.filter((item) => item.remarkId !== remark.remarkId);
          state.editList = editList;
        }
      }
    },
    savePreRemarks: (state, action) => {
      const type = action.payload;
      if (type === 'SP') {
        const remarks = cloneDeep(state.remarks);
        state.prevRemarks = remarks;
      }
      if (type === 'TR') {
        state.prevRemarks = state.remarks;
      }
    },
    getRemarkFromPendingAction: (state, action) => {
      state.createList = action.payload;
      state.prevRemarks = action.payload;
      state.remarks = action.payload;
      return;
    },
    createNewDeleteRemark: (state, action) => {
      if (isEmpty(state.rootRemarks)) {
        state.rootRemarks = action.payload;
        state.deleteList = action.payload;
      }
      return;
    },
    createNewCreateRemark: (state, action) => {
      state.createList = action.payload;
      state.remarks = action.payload;

      return;
    },
  },
  extraReducers: {
    [getRemarks.fulfilled]: (state, action) => {
      state.loading = false;
      state.remarks = action.payload;
      state.prevRemarks = action.payload;
      state.filterByName = createFilterRemarks(action.payload, 'birthName');
      state.filterByCategory = createFilterRemarks(action.payload, 'remarksCategory');
    },
    [getRemarks.rejected]: (state) => {
      state.loading = false;
    },
    [getRemarks.pending]: (state) => {
      state.loading = true;
    },
  },
});

export const { reducer: remarksReducer, actions } = remarkSlice;
export const {
  editRemark,
  deleteRemark,
  addFilterRemark,
  addOneFilterRemark,
  addFilterListByUser,
  clearRemarks,
  saveRemarks,
  savePreRemarks,
  getRemarkFromPendingAction,
  createNewDeleteRemark,
  createNewCreateRemark,
} = actions;
export default remarksReducer;
