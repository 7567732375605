import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 14px;
  font-weight: 400;
  padding: 20px;
  width: 300px;

  position: absolute;
  z-index: 10;
  transform: translateY(7%);

  border: 1px solid #c4c3d1;
  border-radius: 6px;
  background-color: white;

  td {
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 6px;
  }
  td:hover {
    background-color: antiquewhite;
  }

  .button-month-calendar {
    margin-top: 10px;
    background-color: white;
    color: #00a19c;
    padding: 8px 20px;
    border: 1px solid #00a19c;
    border-radius: 6px;
    font-size: 10px;
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .active {
    background-color: #00a19c !important;
    color: white !important;
  }

  .year-selected {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;
