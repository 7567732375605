export const SYNDICATION_TEXT = {
  TITLE: 'Send Syndication',
  SUB_TITLE: 'Sent invite for Syndication on the platform',
  SYNDICATION: 'Syndication for',
  PLACE_HOLDER_SYNDICATION: 'Talent Syndication',
  PLACE_HOLDER_INVITE_LIST: 'Syndication Facilitators',
  DATE: 'Date',
  START_TIME: 'Start time',
  END_TIME: 'End time',
  TIME_12_PM: '12:00 PM',
  INVITE_LIST: 'Invite list',
};
export const REQUIRED_TEXT = {
  SYNDICATION: 'Talent Syndication is required',
  DATE: 'Date is required',
  START_TIME: 'Start time is required',
  END_TIME: 'End time is required',
  INVITE_LIST: 'Invite list is required',
};
export const NOTIFICATION = {
  SUCCESS: 'Send Syndication Successful.',
  ERROR: 'Send Syndication Failed.',
};
