import { RightOutlined } from '@ant-design/icons';
import { get, isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { matching_green, matching_orange, matching_red } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import { handleRenderListPersonality } from '../../utils/helper';
import CommonLoading from '../CommonLoading/CommonLoading';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './personality.module.scss';

const Personality = ({ personality, loading, id }) => {
  const [open, setOpen] = useState(false);
  const [dataViewMore, setDataViewMore] = useState([]);
  const [loadingViewMore, setLoadingViewMore] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);

  useEffect(() => {
    (async function getPersonalityViewMore() {
      try {
        setLoadingViewMore(true);
        const res = await talentProfileApi.getPersonalityViewMore(id, roleActive);
        const data = get(res, 'data.result') || [];
        setDataViewMore(data);
      } catch (error) {
        console.error(error);
        setDataViewMore([]);
      } finally {
        setLoadingViewMore(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive.roleId, id]);

  return (
    <div className={styles.Personality}>
      <h3 className={styles.Personality__h3}>Personality </h3>
      {loading || loadingViewMore ? (
        <CommonLoading />
      ) : (
        <>
          <div className={styles.Personality__table}>
            <table>
              <thead>
                <tr>
                  <th>
                    <p>Personality Archetypes</p>
                  </th>
                  <th>
                    <p>Details</p>
                  </th>
                </tr>
              </thead>
              <tbody>{handleRenderListPersonality(personality)}</tbody>
            </table>
          </div>
          {!isEmpty(personality) && (
            <Footer className="d-flex justify-content-end align-items-center w-100 mt-3">
              <div
                onKeyDown={() => {}}
                onClick={() => {
                  setOpen(true);
                }}
              >
                View more
              </div>
              &nbsp;
              <RightOutlined />
            </Footer>
          )}
        </>
      )}
      {open && <Popout open={open} setOpen={setOpen} data={dataViewMore} />}
    </div>
  );
};

const Table = styled.div`
  width: 700px;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--light-blue-grays-200, #e7edf3);
  border-radius: 6px;

  .table-header {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .header-cell {
    display: flex;
    padding: 16px 12px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }

  .header-cell-style-header {
    color: var(--emphasis-body-n-800, #3f3c4c);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.235px;
    border-bottom: 1px solid var(--light-blue-grays-200, #e7edf3);
  }

  .header-cell-style-body {
    color: var(--base-black, #181818);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.173px;
  }

  .header-cell-first-col-style {
    color: var(--dark-blue-grays-300, #4f5f6e);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.235px;
  }

  .table-body {
    width: 100%;
    display: flex;
  }
  .no-bottom-border {
    border-bottom: none;
  }
  .dot {
    margin: 0;
    padding: 0;
    color: #181818;
  }
`;

const PersonalityTitle = styled.div`
  color: var(--light-gray-800, #3d3d3d);
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: 0.1px;
  padding: 30px 0 50px;
`;

const Footer = styled.div`
  color: var(--light-primary-600, #00908c);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.307px;
  cursor: pointer;
`;

const Popout = (props) => {
  const { open, setOpen, data } = props;
  return (
    <div>
      <CustomAntModal
        open={open}
        setOpen={setOpen}
        width={800}
        destroyOnClose
        hideDefaultBtn={true}
        styles={{
          containerPaddingTopBottom: 50,
          containerPaddingLeftRight: 50,
          closePosition: 50,
        }}
      >
        <PersonalityTitle>Personality</PersonalityTitle>
        <Table>
          <div className="table-header">
            <div className="header-cell header-cell-style-header  col-4">Personality Archetype</div>
            <div className="header-cell header-cell-style-header  col-5">Details</div>
            <div className="header-cell header-cell-style-header  col-3">Proficiency</div>
          </div>
          {data.map((item) => {
            return (
              <>
                <div className="table-body">
                  <div className="header-cell header-cell-first-col-style col-4">
                    {item.personality_archetype}
                  </div>
                  <div className="header-cell header-cell-style-body col-5">
                    <ul className="dot">
                      <li>{item.details}</li>
                    </ul>
                  </div>
                  <div className="header-cell header-cell-style-body col-3 ">
                    {item.proficiency}{' '}
                    {item.proficiency === 'High' && (
                      <img src={matching_green} style={{ marginTop: '3px' }} alt="" />
                    )}
                    {item.proficiency === 'Medium' && (
                      <img src={matching_orange} style={{ marginTop: '3px' }} alt="" />
                    )}
                    {item.proficiency === 'Low' && (
                      <img src={matching_red} style={{ marginTop: '3px' }} alt="" />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </Table>
      </CustomAntModal>
    </div>
  );
};

export default memo(Personality);
