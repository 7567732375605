import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  BasicErrorText,
  BasicInput,
  BasicLabel,
  BasicSelect,
  BasicTextArea,
} from '../../assets/common';
import { CURRENT_TAG_OPTION, ROLE_LEVEL_LIST } from '../../utils/constants';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './other-experience.module.scss';

const AddEditOtherExperience = ({ open, setOpen, isEdit, handleSubmit, dataEdit }) => {
  const source = 'Manual Input'; //This field will be update to selector in the future

  const formik = useFormik({
    initialValues: {
      experience: dataEdit?.experience || null,
      source: source,
      remarks: dataEdit?.remarks || '',
    },
    validationSchema: Yup.object({
      experience: Yup.string().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
      remarks: Yup.string().nullable().required(ROLE_LEVEL_LIST.REQUIRED_FIELDS),
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (value) => {
      const data = {
        ...value,
      };
      handleSubmit(data, formik.handleReset);
    },
  });

  const isErrorSentences = useMemo(
    () => formik.errors.remarks && formik.touched.remarks,
    [formik.errors.remarks, formik.touched.remarks]
  );
  const isErrorCurrentTag = useMemo(
    () => formik.errors.experience && formik.touched.experience,
    [formik.errors.experience, formik.touched.experience]
  );

  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      title={isEdit ? 'Edit Other Experience' : 'Add New Other Experience'}
      onOk={formik.submitForm}
      onCancel={() => formik.handleReset()}
      okText={isEdit ? 'Save' : 'Add'}
      width={1000}
      destroyOnClose
    >
      <div className={styles.body}>
        <h4 className={styles.title}>
          {isEdit
            ? 'Edit Other Experience on the platform'
            : 'Add New Other Experience on the platform'}
        </h4>

        <div className="row mb-3">
          <div className="col">
            <BasicLabel required htmlFor="experience">
              Current Tag
            </BasicLabel>
            <BasicSelect
              id="experience"
              data-testid="experience"
              size="middle"
              options={CURRENT_TAG_OPTION.map((item) => ({ label: item, value: item }))}
              getPopupContainer={(trigger) => trigger.parentElement}
              name="experience"
              placeholder="Enter Platform"
              value={formik.values.experience}
              error={isErrorCurrentTag?.toString()}
              onChange={(value) => formik.setFieldValue('experience', value)}
            />
            <BasicErrorText show={isErrorCurrentTag?.toString()}>
              {formik.errors.experience}
            </BasicErrorText>
          </div>
          <div className="col">
            <BasicLabel>Source</BasicLabel>
            <BasicInput name="source" value={source} readOnly placeholder="Manual Input" />
          </div>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <BasicLabel required htmlFor="remarks">
            Sentences
          </BasicLabel>
          <BasicTextArea
            id="remarks"
            name="remarks"
            data-testid="remarks"
            resize="none"
            error={isErrorSentences?.toString()}
            value={formik.values.remarks}
            onChange={formik.handleChange}
          />
          <BasicErrorText show={isErrorSentences}>{formik.errors.remarks}</BasicErrorText>
        </div>
      </div>
    </CustomAntModal>
  );
};

export default AddEditOtherExperience;
