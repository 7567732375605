import React from 'react';
import Collapse from 'react-bootstrap/Collapse';

import { close, triangle_edit } from '../../../../../assets/img';
import { useComponentVisible } from '../../../../../hooks';
import styles from './add-position-dialog.module.scss';

const SearchPositionByCategory = (props) => {
  const { select, searchTerm, options, setSelect } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleDisplayDropdown = () => {
    setIsComponentVisible((preState) => !preState);
  };

  const handleRemoveSelectCategory = () => {
    setSelect('');
    setIsComponentVisible(false);
  };

  const handleSelectItem = (item) => {
    setSelect(item);
    setIsComponentVisible(false);
  };
  return (
    <div ref={ref}>
      <input
        id="position"
        data-testid="position-input"
        name="position"
        placeholder="Attrition within the next 1 year"
        className={styles.position_input}
        onKeyDown={() => {}}
        onClick={handleDisplayDropdown}
        value={select}
        disabled={searchTerm.value}
        style={{ cursor: `${searchTerm.value && 'not-allowed'}` }}
        readOnly
      />

      {select && (
        <span
          className={styles.close_icon}
          onKeyDown={() => {}}
          onClick={handleRemoveSelectCategory}
        >
          <img src={close} alt="close" />
        </span>
      )}
      <img
        data-testid="toggle-category"
        src={triangle_edit}
        alt="triangle_edit"
        onKeyDown={() => {}}
        onClick={() => handleDisplayDropdown()}
        style={{ cursor: 'pointer' }}
      />

      <Collapse
        in={isComponentVisible}
        aria-controls="example-collapse-text"
        aria-expanded={isComponentVisible}
      >
        <div data-testid="group-options" className={styles.dropdown}>
          {options &&
            options.length > 0 &&
            options.map((item, index) => (
              <div
                data-testid={`${index}-group`}
                onKeyDown={() => {}}
                onClick={() => handleSelectItem(item)}
                key={index}
              >
                {item}
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  );
};
export default SearchPositionByCategory;
