import { Col, Row, Spin, Tooltip } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AiButton } from '../../../../../assets/common';
import { tooltip_black_icon, tooltip_white_icon } from '../../../../../assets/img';
import { CategoryDropdownCustom } from '../../../../../components';
import { useDebounce } from '../../../../../hooks';
import { isCheckError, showError } from '../../../../../hooks/useFormik';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  MAPPING_DATA_TYPES,
  MESSAGE_TYPES,
  SELECT_ALL_LABEL,
  SG_JG_MAPPING_MESSAGE,
} from '../../../../../utils/constants';
import { getMappingData } from '../../../../../utils/helper';
import {
  AiFormWrapper,
  AiLabel,
  AiTitle,
} from '../../../components/NewSuccessionPlanningForm/Styled';
import SearchBoxCustom from '../components/SearchBoxCustom/SearchBoxCustom';
import fetch_data from '../MenuBarMapping/fetch-filter-option.json';
import * as styles from './create-new-sg-jg-mapping.module.scss';
import {
  formMappingvalidationSchema,
  handleCheckEmptyValue,
  handleValidateJGInOracleDistinction,
  handleValidateJGInOracleDuplication,
  handleValidateJGInPSeries,
} from './formMappingValidation';

const CreateNewSGJGMapping = () => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const history = useHistory();
  const dispatch = useDispatch();

  // Use state
  const [loadingPage, setLoadingPage] = useState(false);
  const [allGJGMappingData, setAllSGJGMappingData] = useState([]);
  const [isInvalidJGInOracle, setIsInvalidJGInOracle] = useState(false);
  const [isInvalidJGInPSeries, setIsInvalidJGInPSeries] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [isFirstValueChange, setIsFirstValueChange] = useState(false);

  // State search field
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [searchTermJG, setSearchTermJG] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      role_level: '',
      sg_in_oracle: '',
      jg_in_oracle: '',
      jg_in_p_series: '',
    },
    validationSchema: formMappingvalidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useEffect(() => {
    formik.setFieldValue('sg_in_oracle', searchTerm.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    formik.setFieldValue('jg_in_oracle', searchTermJG.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermJG]);

  // Use Debounce to get value
  const jgInPSeriesValue = useDebounce(formik.values.jg_in_p_series, 300);
  const sgInOracleValue = useDebounce(formik.values.sg_in_oracle, 300);
  const jgInOracleValue = useDebounce(formik.values.jg_in_oracle, 300);
  const roleLevelValue = useDebounce(formik.values.role_level, 300);

  const handleAddNewSGJGMapping = async () => {
    await formik.submitForm();
    const values = formik.values;
    const isCheck = handleCheckEmptyValue(values);

    if (isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, SG_JG_MAPPING_MESSAGE.FILL_ALL_FIELD_REQUIRED);
      return;
    }

    if (!formik.isValid) return;

    if (isInvalidJGInOracle || isInvalidJGInPSeries) {
      return;
    }

    fetchApiToAddNewSGJGMapping();
  };

  const fetchApiToAddNewSGJGMapping = async () => {
    try {
      setLoadingPage(true);
      const values = formik.values;
      const body = {
        role_level: values?.role_level?.id,
        sg_in_oracle: values?.sg_in_oracle,
        jg_in_oracle: values?.jg_in_oracle,
        jg_in_p_series: values?.jg_in_p_series?.id,
      };
      const res = await adminApi.createSGJGMapping(body, roleActive?.roleId);
      if (res.status === 200) {
        handleCancel();
        handleResetForm();
        handleShowMessage(MESSAGE_TYPES.SUCCESS, SG_JG_MAPPING_MESSAGE.SG_JG_MAPPING_ADD_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      const dataError = error?.response?.data;
      if (dataError?.code === 500 && dataError?.errors) {
        handleShowMessage(MESSAGE_TYPES.ERROR, dataError?.errors);
      } else {
        handleShowMessage(MESSAGE_TYPES.ERROR, SG_JG_MAPPING_MESSAGE.AN_UNEXPECTED_ERROR);
      }
    }
    setLoadingPage(false);
  };

  const fetchFilterOptions = useCallback(async () => {
    try {
      const res = await adminApi.getSGJGMappingFilter(roleActive?.roleId, {
        sg: '',
        job_grade: '',
      });
      if (res.status === 200) {
        setFilterOptions({
          role_level: res.data.result?.role_level,
          sg_in_oracle: res.data.result?.sg_in_oracle,
          jg_in_oracle: res.data.result?.jg_in_oracle,
          jg_in_p_series: fetch_data.jg_in_p_series,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [roleActive]);

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  const handleCancel = () => {
    history.push(`/admin/master-data-management/sg-jg-mapping`);
  };

  const fetchDataSGJGMapping = useCallback(async () => {
    try {
      setLoadingPage(true);
      const query = {
        page: 1,
        limit: 15,
        keyword: '',
      };
      const res = await adminApi.getSGJGMapping(query, roleActive?.roleId);
      if (res.status === 200 && !isEmpty(res.data.result)) {
        let tempRoleLevels = {};
        res.data.result.forEach((item) => {
          tempRoleLevels[`${item.role_level}`] = [];
        });
        const tempData = getMappingData(
          tempRoleLevels,
          res.data.result,
          MAPPING_DATA_TYPES.SG_JG_MAPPING
        );
        setAllSGJGMappingData(tempData);
      } else {
        setAllSGJGMappingData([]);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingPage(false);
  }, [roleActive]);

  useEffect(() => {
    fetchDataSGJGMapping();
  }, [fetchDataSGJGMapping]);

  // Checking JG in P Series field
  useEffect(() => {
    if (isEmpty(jgInPSeriesValue) || isEmpty(roleLevelValue)) return;

    const isCheck = handleValidateJGInPSeries(
      formik.values,
      jgInPSeriesValue?.id,
      allGJGMappingData
    );
    if (isCheck) {
      setIsInvalidJGInPSeries(true);
      handleShowMessage(MESSAGE_TYPES.ERROR, SG_JG_MAPPING_MESSAGE.JG_IN_P_SERIES_SAME_ROLE);
    } else {
      setIsInvalidJGInPSeries(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jgInPSeriesValue, roleLevelValue]);

  // Checking JG in Oracle
  useEffect(() => {
    const isCheckDuplication = handleValidateJGInOracleDuplication(
      formik.values,
      jgInOracleValue,
      allGJGMappingData
    );
    const isCheckDistinction = handleValidateJGInOracleDistinction(
      formik.values,
      jgInOracleValue,
      allGJGMappingData
    );
    if (isCheckDuplication) {
      setIsInvalidJGInOracle(true);
      handleShowMessage(MESSAGE_TYPES.ERROR, SG_JG_MAPPING_MESSAGE.SG_JG_MAPPING_ALREADY_EXIST);
    } else if (isCheckDistinction) {
      setIsInvalidJGInOracle(true);
      handleShowMessage(
        MESSAGE_TYPES.ERROR,
        SG_JG_MAPPING_MESSAGE.JG_IN_ORACLE_MAPPED_ONLY_ONE_JG_IN_P_SERIES
      );
    } else {
      setIsInvalidJGInOracle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleLevelValue, jgInOracleValue, jgInPSeriesValue, sgInOracleValue]);

  const handleResetForm = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({}, false);
  };

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: type === MESSAGE_TYPES.ERROR,
      })
    );
  };

  return (
    <div className="max-width">
      <h3 className={styles.mainTitle}>
        Add New SG/JG Mapping
        <span className={styles.subText} data-testid="noteTitle">
          Master Data Management
        </span>
      </h3>
      <Spin spinning={loadingPage} size="large">
        <AiFormWrapper>
          <AiTitle>Add New Details</AiTitle>
          <Row gutter={[16, 16]} className="mb-3">
            <Col span={6}>
              <AiLabel>Role Level</AiLabel>
              <CategoryDropdownCustom
                selected={formik.values.role_level}
                setSelected={(event) => formik.setFieldValue('role_level', event)}
                options={
                  !isEmpty(filterOptions.role_level)
                    ? filterOptions.role_level
                        .filter((item) => item.label !== SELECT_ALL_LABEL)
                        .map((option) => {
                          return {
                            id: `${option?.value}`,
                            name: `${option?.label}`,
                          };
                        })
                    : []
                }
                placeholder="Select"
                status={isCheckError(formik, 'role_level') ? 'error' : ''}
              />
              {showError(formik, 'role_level')}
            </Col>
            <Col span={6} style={{ position: 'relative', paddingRight: '10px' }}>
              <AiLabel>SG in Oracle</AiLabel>
              <SearchBoxCustom
                setIsFirstValueChange={setIsFirstValueChange}
                isFirstValueChange={isFirstValueChange}
                isEdit={false}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchList={filterOptions?.sg_in_oracle ?? []}
                fieldName={'SG_In_Oracle'}
                searchPlaceholder={'Select or type SG value'}
                status={isCheckError(formik, 'sg_in_oracle') ? 'error' : ''}
              />
              <Tooltip
                className={styles.customTooltip}
                overlayClassName={'custom-tooltip-mapping'}
                overlayStyle={{ minWidth: '354px' }}
                title={
                  <div className={styles.tooltipContent}>
                    <img className={styles.tooltipIcon} src={tooltip_white_icon} alt="" />
                    <span>{SG_JG_MAPPING_MESSAGE.CHARACTER_MUST_BE_UPPERCASE_TOOLTIP}</span>
                  </div>
                }
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
              >
                <img className={styles.tooltipIcon} src={tooltip_black_icon} alt="" />
              </Tooltip>
              {showError(formik, 'sg_in_oracle')}
            </Col>
            <Col span={6} style={{ position: 'relative', paddingRight: '10px' }}>
              <AiLabel>JG in Oracle</AiLabel>
              <SearchBoxCustom
                setIsFirstValueChange={setIsFirstValueChange}
                isFirstValueChange={isFirstValueChange}
                isEdit={false}
                searchTerm={searchTermJG}
                setSearchTerm={setSearchTermJG}
                searchList={filterOptions?.jg_in_oracle ?? []}
                fieldName={'JG_In_Oracle'}
                searchPlaceholder={'Select or type JG value'}
                status={isCheckError(formik, 'jg_in_oracle') || isInvalidJGInOracle ? 'error' : ''}
              />
              <Tooltip
                className={styles.customTooltip}
                overlayClassName={'custom-tooltip-mapping'}
                overlayStyle={{ minWidth: '358px' }}
                title={
                  <div className={styles.tooltipContent}>
                    <img className={styles.tooltipIcon} src={tooltip_white_icon} alt="" />
                    <span>{SG_JG_MAPPING_MESSAGE.FIRST_CHARACTER_MUST_BE_UPPERCASE_TOOLTIP}</span>
                  </div>
                }
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
              >
                <img className={styles.tooltipIcon} src={tooltip_black_icon} alt="" />
              </Tooltip>
              {showError(formik, 'jg_in_oracle')}
            </Col>
            <Col span={6}>
              <AiLabel>JG in P Series</AiLabel>
              <CategoryDropdownCustom
                selected={formik.values.jg_in_p_series}
                setSelected={(event) => formik.setFieldValue('jg_in_p_series', event)}
                options={
                  !isEmpty(filterOptions.jg_in_p_series)
                    ? filterOptions.jg_in_p_series
                        .filter((item) => item.label !== SELECT_ALL_LABEL)
                        .map((option) => {
                          return {
                            id: `${option?.value}`,
                            name: `${option?.label}`,
                          };
                        })
                    : []
                }
                placeholder="Select"
                status={
                  isCheckError(formik, 'jg_in_p_series') || isInvalidJGInPSeries ? 'error' : ''
                }
              />
              {showError(formik, 'jg_in_p_series')}
            </Col>
          </Row>
        </AiFormWrapper>
      </Spin>

      <div className={styles.controlBar} style={{ marginBottom: '30px' }}>
        <AiButton onClick={handleCancel}>Cancel</AiButton>
        <AiButton mode={'teal'} onClick={() => handleAddNewSGJGMapping()}>
          Add
        </AiButton>
      </div>
    </div>
  );
};

export default CreateNewSGJGMapping;
