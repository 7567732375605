import __, { filter, isEmpty } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { DATE_TIME_FORMAT } from '../../../utils/constants';

const getRoleName = (secretariat) => secretariat?.transientValue?.roleName;

const getBusinessId = (secretariat) => secretariat?.transientValue?.businessId;

const countBusinessType = (meeting_secretariats, businessType) =>
  filter(meeting_secretariats, (secretariat) => secretariat?.value?.business_type === businessType)
    .length;

const countBusinessTypeBusiness = (meeting_secretariats) =>
  countBusinessType(meeting_secretariats, 'Business');

const haveHrCoeRole = (meeting_secretariats) =>
  __(meeting_secretariats)
    .map(getRoleName)
    .some((secretariat) => secretariat === 'HR COE');

const isOnlyBusinessIdWithoutHrCoe = (meeting_secretariats) => {
  const count = __(meeting_secretariats)
    .filter((secre) => getRoleName(secre) !== 'HR COE')
    .map(getBusinessId)
    .uniq()
    .size();
  return count === 1;
};

const validateSecretariatWithCategoryIsBusiness = (meeting_secretariats) => {
  const hasHrCoe = haveHrCoeRole(meeting_secretariats);
  if (meeting_secretariats.length === 1) {
    const [secretarat] = meeting_secretariats;
    return hasHrCoe || secretarat?.value?.business_type === 'Business';
  }

  if (
    countBusinessTypeBusiness(meeting_secretariats) !==
    meeting_secretariats.length - (hasHrCoe ? 1 : 0)
  ) {
    return false;
  }

  return isOnlyBusinessIdWithoutHrCoe(meeting_secretariats);
};

const validateSecretariatWithCategoryIsOPU = (meeting_secretariats) => {
  const countOpu = countBusinessType(meeting_secretariats, 'OPU');
  const countDivision = countBusinessType(meeting_secretariats, 'Division');
  const countOpuAndDivisionType = countOpu + countDivision;

  const hasHrCoe = haveHrCoeRole(meeting_secretariats);

  if (meeting_secretariats.length === 1) {
    return hasHrCoe || countOpuAndDivisionType === 1;
  }

  if (countOpu > 0 && countDivision > 0) {
    return false;
  }

  if (countOpuAndDivisionType !== meeting_secretariats.length - (hasHrCoe ? 1 : 0)) {
    return false;
  }

  return isOnlyBusinessIdWithoutHrCoe(meeting_secretariats);
};

const secretariaWithCategoryValidator = (category) => {
  return (meeting_secretariats) => {
    if (!category || !category.name) {
      return false;
    }
    switch (category.name) {
      case 'PETRONAS':
        return haveHrCoeRole(meeting_secretariats);
      case 'Business':
        return validateSecretariatWithCategoryIsBusiness(meeting_secretariats);
      case 'OPU/Division':
        return validateSecretariatWithCategoryIsOPU(meeting_secretariats);
      default:
        return false;
    }
  };
};
const handleRequiredSecretariats = (categoryName) => {
  if (categoryName?.name === 'PETRONAS') {
    return `HR COE is required`;
  }
  if (categoryName?.name === 'Business') {
    return `Please select Meeting Secretariat of the same Business`;
  }
  if (categoryName?.name === 'OPU/Division') {
    return `Please select Meeting Secretariat of the same OPU/Division`;
  }
  return `Please select the meeting category`;
};
const validationSchema = yup.object({
  meeting_name: yup.string().required('Meeting Name is required.'),
  category: yup
    .object()
    .shape({
      id: yup.string().required('Category is required.'),
      name: yup.string().required('Category required.'),
    })
    .required('Category is required.'),
  category_value: yup
    .object()
    .nullable()
    .when('category.name', (name, schema) => {
      if (name === 'Business') {
        return schema.required('Business is required');
      } else if (name === 'OPU/Division') {
        return schema.required('OPU/Division is required');
      } else {
        return schema;
      }
    }),
  start_time: yup
    .string()
    .required('Start Time is required.')
    .test('is-greater', 'Start Time must be less than or equal to End Time', function (value) {
      const { end_time } = this.parent;
      const startTimeMoment = moment(value, DATE_TIME_FORMAT.TIME);
      const endTimeMoment = moment(end_time, DATE_TIME_FORMAT.TIME);
      if (isEmpty(end_time) || end_time === value) return true;
      return endTimeMoment.isAfter(startTimeMoment);
    }),
  end_time: yup.string().required('End Time is required.'),
  date: yup.string().required('Date is required.'),
  chairman: yup.array().min(1, 'Chairman is required.').max(1, 'Chairman must be only one person.'),
  meeting_secretariats: yup
    .array()
    .min(1, 'Secretariat is required.')
    .when('category', (category, schema) => {
      return schema.test(
        'withCategory',
        handleRequiredSecretariats(category),
        secretariaWithCategoryValidator(category)
      );
    }),
});

export { validationSchema };
