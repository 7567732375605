import React from 'react';

const Button = (props) => {
  const { children, className, ...rest } = props;
  let classes = '';
  if (className !== undefined) {
    classes += ' ' + className;
  }
  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

export default Button;
