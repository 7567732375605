import { get, isDate, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ReactExport from 'react-data-export';
import { Tooltip } from 'antd';
import { commonTooltipStyles } from '../../../../../assets/common/tooltipStyles';

import { auditLogApi } from '../../../../../services/auditLog';
import { AUDIT_LOG_VALUES } from '../../../../../utils/constants';
import { auditLogExportColumns } from './audit-log-export.constant';
import { AiButton } from '../../../../../assets/common';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../../../../hooks/useShowAlertWhenRefresh';

const AuditLogExport = (props) => {
  const { searchData, roleId, totalData, setShowExporting } = props;
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const fileName = 'Audit Log';
  const [isExport, setIsExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const handleOnExport = async () => {
    setIsLoading(true);
    setShowExporting(true);
    setIsUploadingOrDownloading(true);
    setShowAlertRefresh(true);
    try {
      const res = await auditLogApi.getAuditLogs(
        { ...searchData, page: null, limit: null, isExport: true },
        roleId
      );
      if (res.status === 200) {
        const auditLogList = get(res, 'data.result', []);
        setData(auditLogList.length ? auditLogList : [{}]);
        setIsExport(true);
        setTimeout(() => {
          setIsExport(false);
        }, 300);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShowExporting(false);
      setIsUploadingOrDownloading(false);
      setShowAlertRefresh(false);
    }
  };

  const generateDataSet = (data) => {
    if (!(data || data?.length)) return;
    const dataExport = data.map((item, index) => {
      const oldData = handleGetValue(item?.old_value);
      const newData = handleGetValue(item?.new_value);
      return [
        { value: index + 1 },
        { value: item?.action || '' },
        { value: item?.feature_name ? `${item?.feature_name}` : `${item?.module_name}` },
        {
          value: !isEmpty(item?.old_value) ? JSON.stringify(oldData) : '',
        },
        {
          value: !isEmpty(item?.new_value) ? JSON.stringify(newData) : '',
        },
        { value: item?.action_at ? moment(item?.action_at).format('DD-MMMM-YYYY HH:mm:ss') : '' },
        { value: item?.action_by || '' },
      ];
    });
    return [
      {
        columns: [...auditLogExportColumns],
        data: [...dataExport],
      },
    ];
  };

  const handleGetValue = (data) => {
    const isCheck = isJSON(data);
    if (!isCheck) return;

    const formatData = JSON.parse(data);
    if (isEmpty(formatData)) return;

    let tempArr = [];
    Object.keys(formatData).forEach((key) => {
      Object.keys(AUDIT_LOG_VALUES).forEach((auditKey) => {
        if (key === auditKey) {
          let tempValue = '';
          if (isDate(formatData[key])) {
            tempValue = moment(formatData[key]).format('DD-MMM-YYYY');
          } else {
            tempValue = formatData[key];
          }
          tempArr.push(`${tempValue} (${AUDIT_LOG_VALUES[auditKey]})`);
        }
      });
    });
    return tempArr;
  };

  const isJSON = (value) => {
    if (typeof value != 'string') {
      value = JSON.stringify(value);
    }

    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      {isLoading || !totalData ? (
        <Tooltip title="No record to export" {...commonTooltipStyles}>
          <AiButton
            mode="teal"
            disabled={!totalData || isLoading}
            onClick={handleOnExport}
            style={{
              marginTop: '8px',
              display: 'flex',
              lineHeight: '15px',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            Export {isLoading && <Spinner animation="border" size="sm" />}
          </AiButton>
        </Tooltip>
      ) : (
        <AiButton
          mode="teal"
          disabled={!totalData || isLoading}
          onClick={handleOnExport}
          style={{
            marginTop: '8px',
            display: 'flex',
            lineHeight: '15px',
            alignItems: 'center',
            marginLeft: 'auto',
          }}
        >
          Export {isLoading && <Spinner animation="border" size="sm" />}
        </AiButton>
      )}
      {isExport && (
        <ExcelFile hideElement={true} filename={fileName}>
          <ExcelSheet dataSet={generateDataSet(data)} name="Sheet1" />
        </ExcelFile>
      )}
    </>
  );
};

export default AuditLogExport;
