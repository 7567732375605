import { Col, Row } from 'antd';
import React, { memo, useRef } from 'react';

import { BasicButton, AiLabel } from '../../../../../assets/common';
import CustomAntModal from '../../../../../components/CustomAntModal/CustomAntModal';
import { UNSEARCHABLE_POSITION_MESSAGE } from '../../../../../utils/constants';
import styles from './add-unsearchable-positions.module.scss';

export function SearchListUnsearchablePositionAdd(props) {
  const {
    data,
    setSearchInputAdd,
    loading,
    setIsSearchValueAdd,
    setIsSelected,
    setPositionNameAdd,
  } = props;

  const handleOnClick = (positionCode, positionName) => {
    setSearchInputAdd(positionCode);
    setPositionNameAdd(positionName);
    setIsSearchValueAdd(false);
    setIsSelected(true);
  };

  const Loading = () => {
    return (
      <div
        className={styles.search_list}
        style={{ height: '35px', overflowY: 'hidden' }}
        data-testid="loading"
      >
        <div className={styles.dot_flashing}></div>
      </div>
    );
  };

  if (loading === true) {
    return <Loading />;
  }

  if (data && data.length > 0) {
    const listItems = data.map((item, index) => {
      return (
        <li
          className={styles.search_item}
          key={item.id}
          onMouseDown={() => handleOnClick(item.position_code, item.position_name)}
        >
          <div className={styles.individual}>
            <div className={styles.name}>
              {item.position_name || ''} {`(${item.position_code})`}
            </div>
          </div>
        </li>
      );
    });
    return (
      <ul
        className={styles.search_list}
        style={data && data.length < 4 ? { overflowY: 'hidden' } : { overflowY: 'scroll' }}
        data-testid="data-is-true"
      >
        {listItems}
      </ul>
    );
  } else {
    return (
      <div
        className={styles.search_list}
        style={{ height: '32px', overflowY: 'hidden', top: '34px' }}
        data-testid="data-is-false"
      >
        Search not found ...
      </div>
    );
  }
}

const AddUnsearchablePositions = (props) => {
  const {
    setIsShow,
    isShow,
    searchInputAdd,
    setSearchInputAdd,
    isSearchValueAdd,
    setIsSearchValueAdd,
    loading,
    data,
    setPositionNameAdd,
    handleAdd,
    alreadyExistAddPosition,
    setAlreadyExistAddPosition,
    setIsSelected,
    isSelected,
  } = props;

  const customStyle = {
    containerPaddingLeftRight: 76,
    containerPaddingTopBottom: 41,
    titleFontSize: 18,
    titleFontWeight: 600,
    borderRadius: 6,
    closePosition: 27,
  };

  const typingTimeoutRef = useRef(null);
  const handleOnchange = (value) => {
    setSearchInputAdd(value);
    setIsSelected(false);
    setAlreadyExistAddPosition(false);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    value ? setIsSearchValueAdd(true) : setIsSearchValueAdd(false);
  };

  return (
    <CustomAntModal
      title="Add Position"
      styles={customStyle}
      open={isShow}
      setOpen={setIsShow}
      width={655}
      hideDefaultBtn
      destroyOnClose
    >
      <Row>
        <Col className={styles.customize}>
          <AiLabel className={styles.label}>Position or Position ID</AiLabel>
          <div style={{ display: 'flex', marginBottom: '20px', width: '487px' }}>
            <input
              data-testid="search-input"
              placeholder="Search Position or Position  ID"
              className={styles.searchInput}
              onChange={(e) => handleOnchange(e.target.value)}
              value={searchInputAdd}
              style={
                alreadyExistAddPosition
                  ? {
                      border: '1px solid #DA1E28',
                      borderRadius: '4px',
                      height: '44px',
                      width: '100%',
                    }
                  : {
                      borderRadius: '4px',
                      height: '44px',
                      width: '100%',
                    }
              }
            />
            <span
              style={{
                color: '#DA1E28',
                fontSize: '12px',
                position: 'absolute',
                marginTop: '48px',
              }}
            >
              {alreadyExistAddPosition
                ? UNSEARCHABLE_POSITION_MESSAGE.SEARCH_EXIST_POSITION_ADD
                : ''}
            </span>
            {isSearchValueAdd && (
              <SearchListUnsearchablePositionAdd
                data={data}
                loading={loading}
                setSearchInputAdd={setSearchInputAdd}
                setIsSearchValueAdd={setIsSearchValueAdd}
                setIsSelected={setIsSelected}
                setPositionNameAdd={setPositionNameAdd}
              />
            )}
            <BasicButton
              data-testid="addPositionBtn"
              mode="teal"
              disabled={isSelected ? false : true}
              onClick={() => {
                handleAdd();
                setIsSearchValueAdd(false);
              }}
              style={{ margin: '7px 0 0 17px', width: '137px' }}
            >
              Add Position
            </BasicButton>
          </div>
        </Col>
      </Row>
    </CustomAntModal>
  );
};

export default memo(AddUnsearchablePositions);
