import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { avatar } from '../../assets/img';
import useApi from '../../services/userApi';
import { PERMISSION } from '../../utils/constants';
import { decodeBase64ToImage } from '../../utils/helper';
import styles from './search-talent-comparison.module.scss';

const Loading = ({ newStyle }) => {
  return (
    <div className={styles.search_list} style={newStyle} data-testid="loading">
      <div className={styles.dot_flasing}></div>
    </div>
  );
};

export function SearchList(props) {
  const { data, handleOnClick, loading, handleScroll, listInnerRef, newStyle } = props;

  if (loading === true) {
    return <Loading newStyle={newStyle} />;
  }

  if (data && data.length > 0) {
    const listItems = data.map((item, index) => {
      return (
        <li
          className={styles.search_item}
          key={`${item.staffId}-${index}`}
          onMouseDown={() => handleOnClick(item)}
          data-testid="list-click"
        >
          <div className={styles.avatar}>
            <img src={decodeBase64ToImage(item && item.image) || avatar} alt="avatar" />
          </div>
          <div className={styles.invidual}>
            <div data-testid="name" className={styles.name}>
              {item.birthName} ({item.staffId})
            </div>
            <div data-testid="opu" className={styles.name1}>
              {item.opu}
            </div>
            <div data-testid="sg" className={styles.name1}>
              {item.sg}
            </div>
          </div>
        </li>
      );
    });
    return (
      <ul
        className={styles.search_list}
        data-testid="data-is-true"
        onScroll={handleScroll}
        ref={listInnerRef}
        style={newStyle}
      >
        {listItems}
      </ul>
    );
  } else {
    return (
      <div className={styles.search_list} data-testid="data-is-false" style={newStyle}>
        Search not found ...
      </div>
    );
  }
}

const SearchTalentComparison = ({ placeholder, addTalent, newStyle, limit }) => {
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState({ value: '', page: 1 });
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const typingTimeoutRef = useRef(null);
  const listInnerRef = useRef(null);
  const searchPrev = useRef(null);
  const [totalItem, setTotalItem] = useState(0);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (clientHeight + Math.ceil(scrollTop) + 1 >= scrollHeight) {
        if (searchList.length < totalItem)
          setSearch((prev) => {
            return {
              ...prev,
              page: prev.page + 1,
            };
          });
      }
    }
  };
  function expand() {
    setExpanded(true);
  }

  function close() {
    setExpanded(false);
  }

  const handleOnchange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setLoading(true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setSearch({
        value: value,
        page: 1,
      });
    }, 500);
    value ? expand() : close();
  };

  const handleOnClick = (item) => {
    const tempName = `${item?.birthName || ''} ${item?.opu || ''} ${item?.sg || ''} (${
      item?.staffId || ''
    })`;
    addTalent(item);
    setSearchTerm(newStyle ? tempName : '');
    setSearch('');
    close();
  };

  useEffect(() => {
    async function fetchAPI() {
      setLoading(true);

      try {
        const permission = {
          permission: PERMISSION.ADD_TALENT_COMPARISION,
          role: roleActive.roleId,
        };
        const body = { search: search.value, limit: limit || 4, page: search.page };
        const response = await useApi.getSearchList(permission, body);
        if (response.data && response.data.result && response.data.result.profiles?.length > 0) {
          if (searchPrev.current === search.value) {
            setSearchList((prev) => {
              return [...prev, ...response.data.result.profiles];
            });
          } else {
            setSearchList(response.data.result.profiles);
          }

          searchPrev.current = search.value;
          setLoading(false);
          setTotalItem(response.data.result.totalProfiles);
        } else {
          setSearchList([]);
          setLoading(false);
        }
      } catch (error) {
        setSearchList([]);
        setLoading(false);
      }
    }
    search.value ? fetchAPI() : setSearchList([]);
  }, [search, roleActive.roleId, limit]);

  return (
    <div
      className={styles.search_box}
      tabIndex={0}
      onBlur={close}
      onFocus={searchTerm ? expand : close}
      data-testid="search-box"
    >
      <input
        type="search"
        id="search"
        data-testid="search-input"
        name="search"
        aria-label="cost-input"
        placeholder={placeholder}
        className={styles.search_talent}
        onChange={(e) => handleOnchange(e)}
        value={searchTerm}
        autoComplete="off"
      />

      {expanded && (
        <SearchList
          data={searchList}
          handleOnClick={handleOnClick}
          loading={loading}
          handleScroll={handleScroll}
          listInnerRef={listInnerRef}
          newStyle={newStyle}
        />
      )}
    </div>
  );
};

export default SearchTalentComparison;
