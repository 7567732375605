import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BasicButton } from '../../../../../assets/common';
import FormDropdown from '../../../../../components/Form/FormDropdown/FormDropdown';
import { useGetRoleLevel } from '../../../../../hooks/useGetRoleLevel';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import * as styles from './form-edit-readiness.module.scss';

const EditSpReadiness = () => {
  const { group_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState([
    {
      label: '1st Line',
      position_jg_min: '',
      position_jg_max: '',
      talent_sg_min: '',
      talent_sg_max: '',
      matching_percentage_cut_off: '',
      min_years_to_retirement: '',
      remove_contract: '',
      group_id: '',
    },
    {
      label: '2nd Line',
      position_jg_min: '',
      position_jg_max: '',
      talent_sg_min: '',
      talent_sg_max: '',
      matching_percentage_cut_off: '',
      min_years_to_retirement: '',
      remove_contract: '',
      group_id: '',
    },
    {
      label: '3rd Line',
      position_jg_min: '',
      position_jg_max: '',
      talent_sg_min: '',
      talent_sg_max: '',
      matching_percentage_cut_off: '',
      min_years_to_retirement: '',
      remove_contract: '',
      group_id: '',
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [roleSelected, setRoleSelected] = useState('');
  const [roleLevelList, setRoleLevelList] = useState([]);
  const [isError, setIsError] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const { data: roleLevelData } = useGetRoleLevel({ keyword: '', limit: 1000, page: 1 });

  useEffect(() => {
    if (!isEmpty(roleLevelData)) {
      setRoleLevelList(
        roleLevelData.map((item) => {
          return { value: item.role_level_sp, label: item.role_level_sp };
        })
      );
    }
  }, [roleLevelData]);

  function setData(value, index, fieldName) {
    return setFormValue((prev) => {
      return prev.map((v, i) => {
        if (i === index) v[fieldName] = value;
        return v;
      });
    });
  }
  function onValidate() {
    let validate = false;
    formValue.forEach((item) => {
      if (validate === true) return;
      validate = Object.values(item).some((value) => {
        return !value.toString();
      });
    });
    if (validate) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `There are items that require your attention. Please fill out this field. `,
        })
      );
      setIsError(true);
    }

    if (validate) return validate;

    formValue.forEach((item) => {
      if (validate === true) return;
      if (Number(item.position_jg_max) < Number(item.position_jg_min)) {
        return (validate = true);
      }
    });

    if (validate) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `Please ensure Position JG (Max) is greater than or equal Position JG (Min).`,
        })
      );
      setIsError(true);
    }

    if (validate) return validate;

    formValue.forEach((item) => {
      if (validate === true) return;
      if (Number(item.talent_sg_max) < Number(item.talent_sg_min)) {
        return (validate = true);
      }
    });

    if (validate) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `Please ensure Talent SG (Max) is greater than or equal Talent SG (Min).`,
        })
      );
      setIsError(true);
    }

    return validate;
  }

  async function editReadiness() {
    const isValidate = onValidate();
    if (isValidate) return;
    setIsError(false);

    try {
      setLoading(true);
      await adminApi.editReadinessRules(
        {
          role_code: roleSelected,
          first_line: formValue[0],
          second_line: formValue[1],
          third_line: formValue[2],
          group_id,
        },
        roleActive
      );
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: `Rules successfully edited`,
        })
      );
      history.push('/admin/sp-management/readiness-rules');
    } catch (error) {
      console.error(error);
      const errMessage = get(error, 'response.data.errors');
      if (errMessage === 'The rules already exist. Please enter new rules')
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.WARNING,
            message: `The rules already exist. Please enter new rules`,
          })
        );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!group_id) return;

    (async function getDetailReadinessRule() {
      try {
        setLoading(true);

        const res = await adminApi.getDetailReadiness(group_id, roleActive);
        const data = get(res, 'data.result');
        setRoleSelected(data?.role_code);
        setFormValue([
          {
            label: '1st Line',
            position_jg_min: data?.first_line?.position_jg_min,
            position_jg_max: data?.first_line?.position_jg_max,
            talent_sg_min: data?.first_line?.talent_sg_min,
            talent_sg_max: data?.first_line?.talent_sg_max,
            matching_percentage_cut_off: data?.first_line?.matching_percentage_cut_off,
            min_years_to_retirement: data?.first_line?.min_years_to_retirement,
            remove_contract: data?.first_line?.remove_contract,
            group_id: data?.group_id,
          },
          {
            label: '2nd Line',
            position_jg_min: data?.second_line?.position_jg_min,
            position_jg_max: data?.second_line?.position_jg_max,
            talent_sg_min: data?.second_line?.talent_sg_min,
            talent_sg_max: data?.second_line?.talent_sg_max,
            matching_percentage_cut_off: data?.second_line?.matching_percentage_cut_off,
            min_years_to_retirement: data?.second_line?.min_years_to_retirement,
            remove_contract: data?.second_line?.remove_contract,
            group_id: data?.group_id,
          },
          {
            label: '3rd Line',
            position_jg_min: data?.third_line?.position_jg_min,
            position_jg_max: data?.third_line?.position_jg_max,
            talent_sg_min: data?.third_line?.talent_sg_min,
            talent_sg_max: data?.third_line?.talent_sg_max,
            matching_percentage_cut_off: data?.third_line?.matching_percentage_cut_off,
            min_years_to_retirement: data?.third_line?.min_years_to_retirement,
            remove_contract: data?.third_line?.remove_contract,
            group_id: data?.group_id,
          },
        ]);
      } catch (error) {
        console.log(error);
        setFormValue([
          {
            label: '1st Line',
            position_jg_min: '',
            position_jg_max: '',
            talent_sg_min: '',
            talent_sg_max: '',
            matching_percentage_cut_off: '',
            min_years_to_retirement: '',
            remove_contract: '',
            group_id: '',
          },
          {
            label: '2nd Line',
            position_jg_min: '',
            position_jg_max: '',
            talent_sg_min: '',
            talent_sg_max: '',
            matching_percentage_cut_off: '',
            min_years_to_retirement: '',
            remove_contract: '',
            group_id: '',
          },
          {
            label: '3rd Line',
            position_jg_min: '',
            position_jg_max: '',
            talent_sg_min: '',
            talent_sg_max: '',
            matching_percentage_cut_off: '',
            min_years_to_retirement: '',
            remove_contract: '',
            group_id: '',
          },
        ]);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id]);

  return (
    <>
      <h3 className={styles.mainTitle}>
        Edit Rules
        <span className={styles.subText} data-testid="noteTitle">
          Succession Planning Readiness Rules
        </span>
      </h3>
      <Spin spinning={loading} size="large">
        <div className={styles.add_new_detail}>
          <div
            style={{ color: '#3F3C4C', fontSize: '18px', fontWeight: '600', marginBottom: '10px' }}
          >
            Details
          </div>
          <div className="dropdown" style={{ width: '390px' }}>
            <FormDropdown
              label="Role"
              options={roleLevelList}
              value={roleSelected.toString()}
              setFormData={setRoleSelected}
              placeholder="Select"
              disabled={true}
            />
          </div>
        </div>
        {roleSelected &&
          !isEmpty(formValue) &&
          formValue.map((item, index) => (
            <div className={styles.add_new_detail} key={index}>
              <div
                style={{
                  color: '#3F3C4C',
                  fontSize: '18px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                {item.label}
              </div>
              <div
                className="d-flex justify-content-start"
                style={{ gap: '15px', marginTop: '20px' }}
              >
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Position JG (Min)"
                    options={Array(20)
                      .fill()
                      .map((_x, i) => {
                        return { value: i + 1, label: `P${i + 1}` };
                      })}
                    value={item.position_jg_min}
                    setFormData={(value) => {
                      setData(value, index, 'position_jg_min');
                    }}
                    placeholder="Select"
                    isError={
                      isError &&
                      (!item.position_jg_min.toString() ||
                        Number(item.position_jg_max) < Number(item.position_jg_min))
                    }
                  />
                </div>
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Position JG (Max)"
                    options={Array(20)
                      .fill()
                      .map((_x, i) => {
                        return { value: i + 1, label: `P${i + 1}` };
                      })}
                    value={item.position_jg_max}
                    setFormData={(value) => {
                      setData(value, index, 'position_jg_max');
                    }}
                    placeholder="Select"
                    isError={
                      isError &&
                      (!item.position_jg_min.toString() ||
                        Number(item.position_jg_max) < Number(item.position_jg_min))
                    }
                  />
                </div>
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Talent SG (Min)"
                    options={Array(20)
                      .fill()
                      .map((_x, i) => {
                        return { value: i + 1, label: i + 1 };
                      })}
                    value={item.talent_sg_min}
                    setFormData={(value) => {
                      setData(value, index, 'talent_sg_min');
                    }}
                    placeholder="Select"
                    isError={
                      isError &&
                      (!item.talent_sg_min.toString() ||
                        Number(item.talent_sg_max) < Number(item.talent_sg_min))
                    }
                  />
                </div>
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Talent SG (Max)"
                    options={Array(20)
                      .fill()
                      .map((_x, i) => {
                        return { value: i + 1, label: i + 1 };
                      })}
                    value={item.talent_sg_max}
                    setFormData={(value) => {
                      setData(value, index, 'talent_sg_max');
                    }}
                    placeholder="Select"
                    isError={
                      isError &&
                      (!item.talent_sg_min.toString() ||
                        Number(item.talent_sg_max) < Number(item.talent_sg_min))
                    }
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-start"
                style={{ gap: '15px', marginTop: '20px' }}
              >
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Matching Percentage Cut Off"
                    options={Array(101)
                      .fill()
                      .map((_x, i) => {
                        return { value: i, label: i };
                      })}
                    value={item.matching_percentage_cut_off.toString()}
                    setFormData={(value) => {
                      setData(value, index, 'matching_percentage_cut_off');
                    }}
                    placeholder="Select"
                    isError={isError && !item.matching_percentage_cut_off.toString()}
                  />
                </div>
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Minimum Years to Retirement"
                    options={Array(51)
                      .fill()
                      .map((_x, i) => {
                        return { value: i, label: i };
                      })}
                    value={item.min_years_to_retirement.toString()}
                    setFormData={(value) => {
                      setData(value, index, 'min_years_to_retirement');
                    }}
                    placeholder="Select"
                    isError={isError && !item.min_years_to_retirement.toString()}
                  />
                </div>
                <div className="dropdown" style={{ width: '250px' }}>
                  <FormDropdown
                    label="Remove Contract"
                    options={Array(2)
                      .fill()
                      .map((_x, i) => {
                        return { value: i, label: i };
                      })}
                    value={item.remove_contract.toString()}
                    setFormData={(value) => {
                      setData(value, index, 'remove_contract');
                    }}
                    placeholder="Select"
                    isError={isError && !item.remove_contract.toString()}
                  />
                </div>
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-end">
          <BasicButton
            mode="cancel"
            onClick={() => history.push('/admin/sp-management/readiness-rules')}
          >
            Cancel
          </BasicButton>
          <BasicButton
            mode="teal"
            disabled={roleSelected ? false : true}
            style={{ marginLeft: '10px' }}
            onClick={() => editReadiness()}
            data-testid="save"
          >
            Save
          </BasicButton>
        </div>
      </Spin>
    </>
  );
};

export default EditSpReadiness;
