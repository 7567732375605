import React, { useState, useEffect } from 'react';
import { ErrorIcon, Successfully, WarningBlueIcon, warning_icon } from '../../assets/img';
import { AiAlert, AiIconAlert } from './Styled';

const AlertNotification = (props) => {
  const { type, message, show, setShow, timeShow, style, closable } = props;
  const types = ['error', 'success', 'normal', 'warning'];
  const icons = [ErrorIcon, Successfully, WarningBlueIcon, warning_icon];
  const indexType = types.indexOf(type);
  const [display, setDisplay] = useState(true);
  const handleOnClose = () => {
    setTimeout(() => {
      setShow(false);
      setDisplay(false);
      setDisplay(true);
    }, 300);
  };
  useEffect(() => {
    if (timeShow && show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, timeShow);

      return () => clearTimeout(timer);
    }
  }, [timeShow, show, setShow]);

  return (
    <>
      {display && (
        <AiAlert
          style={
            !show
              ? { height: 0, padding: 0, border: 0, overflow: 'hidden', opacity: 0 }
              : { ...style }
          }
          message={message}
          type={type}
          showIcon
          icon={<AiIconAlert src={icons[indexType]} />}
          closable={closable === undefined ? true : closable}
          onClose={handleOnClose}
        />
      )}
    </>
  );
};

export default AlertNotification;
