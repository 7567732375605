import { Radio, Tooltip } from 'antd';
import { cloneDeep, findIndex, isEmpty, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-bootstrap';

import { AiTextarea, BasicButton } from '../../../../../assets/common';
import { expand_fullfillment, mobility_description } from '../../../../../assets/img';
import { talentReviewApi } from '../../../../../services/talentReviewApi';
import {
  MOBILITY_PLAN_CATEGORY_TYPE,
  MOBILITY_PLAN_DROP_CARD_INFO,
  ROUTE_BACK_HISTORY,
} from '../../../../../utils/constants';
import PositionItem from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/PositionItem';
import TalentReviewCollapse from '../../TalentReviewCollapse/TalentReviewCollapse';
import ConfirmDelete from '../ConfirmDelete/ConfirmDelete';
import DropCardRecommendation from '../DropCardRecommendation/DropCardRecommendation';
import Fullfillment from '../Fullfillment/Fullfillment';
import RecommendationTableMobilityPlan from '../RecommendationTableForMobilityPlan/RecommendationsModilityPlan';
import SelectPositionMobilityPlan from '../SelectPositionMobilityPlan/SelectPositionMobilityPlan';
import * as styles from './mobility-form.module.scss';
import { getMaxLengthFiledInArrayMobilityPlan } from '../../../../../utils/helper';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { arrayMove, insertAtIndex, removeAtIndex } from '../../../../../utils/helper';
import MobilityItem from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/MobilityItem';

const MOBILITY_PLAN_TYPE = [
  { label: 'Next Mobility', fieldValue: 'next_mobility' },
  { label: 'Next 2 Mobility', fieldValue: 'next_2_mobility' },
  { label: 'End State', fieldValue: 'end_state' },
];

const MobilityForm = (props) => {
  const {
    onlyViewTalentAgenda,
    index,
    item,
    isTheLast,
    lengthMobility,
    isPrinting,
    setAddTalent,
    addTalent,
    positionOptions,
    currentStaffId,
    setFormMobilityPlan,
  } = props;
  const [status, setStatus] = useState('Save');
  const [data, setData] = useState(item);
  const [openDelete, setOpenDelete] = useState(null);
  const [positionSelected, setPositionSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEndState, setLoadingEndState] = useState(false);
  const [endStateRecommendation, setEndStateRecommendation] = useState([]);
  const [nextmobilityRecommendation, setNextMobilityRecommendation] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(MOBILITY_PLAN_CATEGORY_TYPE[0].value);
  const [currentManualValue, setCurrentManualValue] = useState({
    next_mobility: data?.next_mobility?.manual,
    next_2_mobility: data?.next_2_mobility?.manual,
    end_state: data?.end_state?.manual,
  });

  const onlyViewTalent = useMemo(() => {
    const pageHistory = new URLSearchParams(window.location.search).get('history');
    return pageHistory === ROUTE_BACK_HISTORY.SP_DASHBOARD;
  }, []);

  const checkNoInput = useCallback((checkingData) => {
    return (
      !(checkingData?.next_mobility?.selection?.length || checkingData?.next_mobility?.manual) &&
      !(
        checkingData?.next_2_mobility?.selection?.length || checkingData?.next_2_mobility?.manual
      ) &&
      !(checkingData?.end_state?.selection?.length || checkingData?.end_state?.manual)
    );
  }, []);

  const onChangeCategoryType = (e) => {
    setCurrentCategory(e.target.value);
  };

  const fetchNextMobilityRecommendation = useCallback(async () => {
    const params = {
      staff_id: currentStaffId,
      position_code: !isEmpty(data.end_state.selection)
        ? data.end_state.selection[0].position_code
        : null,
      category: currentCategory,
    };
    try {
      setLoading(true);
      const res = await talentReviewApi.getNextMobilityRecommendation(params);
      if (res.status === 200) {
        setNextMobilityRecommendation(res?.data?.result || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [data.end_state.selection, currentStaffId, currentCategory]);

  // Get End State Recommendation Table
  useEffect(() => {
    if (!currentStaffId) return;
    const fetchEndStateRecommendation = async () => {
      try {
        setLoadingEndState(true);
        const res = await talentReviewApi.getEndStateRecommendation(currentStaffId);
        if (res.status === 200) {
          setEndStateRecommendation(res?.data?.result || []);
          setAddTalent((prev) => {
            if (!prev.end_state_recommendation) {
              return { ...prev, end_state_recommendation: res?.data?.result || [] };
            }
            return prev;
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEndState(false);
      }
    };
    fetchEndStateRecommendation();
  }, [currentStaffId, setAddTalent]);

  useEffect(() => {
    setCurrentManualValue({
      next_mobility: data?.next_mobility?.manual,
      next_2_mobility: data?.next_2_mobility?.manual,
      end_state: data?.end_state?.manual,
    });
  }, [data]);

  // Get Next Mobility Recommendation Table
  useEffect(() => {
    fetchNextMobilityRecommendation();
  }, [data.end_state.selection, currentCategory, fetchNextMobilityRecommendation]);

  const isNoInput = useMemo(() => checkNoInput(data), [data, checkNoInput]);

  useEffect(() => {
    if (!setFormMobilityPlan) return;
    if (!checkNoInput(data) && status !== 'View') {
      setFormMobilityPlan((prev) =>
        [...prev].find((v) => v === index + 1) ? [...prev] : [...prev, index + 1]
      );
    } else {
      setFormMobilityPlan((prev) =>
        [...prev].find((v) => v === index + 1)
          ? [...prev].filter((v) => v !== index + 1)
          : [...prev]
      );
    }
  }, [checkNoInput, data, index, setFormMobilityPlan, status]);

  const handleOnChangeManual = (e, type) => {
    const value = e?.target?.value;
    setData((prev) => {
      return {
        ...prev,
        [type]: {
          ...prev[type],
          manual: value,
        },
      };
    });
  };

  const handleEdit = (idx) => {
    setAddTalent((prev) => {
      return {
        ...prev,
        mobility_plan: !isEmpty(prev?.mobility_plan)
          ? prev.mobility_plan.map((item) => {
              if (item.idx === idx) {
                return {
                  ...item,
                  next_mobility: data.next_mobility,
                  next_2_mobility: data.next_2_mobility,
                  end_state: data.end_state,
                };
              }
              return item;
            })
          : prev?.mobility_plan,
      };
    });
    setStatus('View');
  };

  const handleRemove = (idx) => {
    setAddTalent((prev) => {
      return {
        ...prev,
        mobility_plan: prev.mobility_plan.filter((item) => item.idx !== idx),
      };
    });
  };

  useEffect(() => {
    if (checkNoInput(item)) return;
    setStatus('View');
  }, [checkNoInput, item]);
  const [open, setOpen] = useState(false);

  // Drag drop state
  const [draggingItem, setDraggingItem] = useState(null);
  const [dragItem, setDragItem] = useState({});
  const [draggingOver, setDraggingOver] = useState(null);

  const handleDragStart = (event) => {
    const data = event.active.data.current;

    setDraggingItem(event.active.id);
    if (data) {
      setDragItem(data);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }

    if (!active.data.current.sortable) return;
    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;

    if (!overContainer) return;
    const convertOverContainer = overContainer.includes('default')
      ? overContainer.replace('_default', '')
      : overContainer;
    const isExist = data[convertOverContainer]?.selection.find(
      (item) => Number(item.position_code) === Number(active.data.current.position_code)
    );
    if (isExist && activeContainer !== convertOverContainer) return;

    if (activeContainer === 'recommendation') {
      if (convertOverContainer === 'end_state') {
        setData((prev) => ({
          ...prev,
          [convertOverContainer]: {
            ...prev[convertOverContainer],
            selection: [
              { ...active.data.current, sortable: { containerId: convertOverContainer } },
            ],
          },
        }));
      }
      if (convertOverContainer !== 'end_state') {
        const concatArr = [
          ...(!isEmpty(data?.next_mobility?.selection) ? data.next_mobility.selection : []),
          ...(!isEmpty(data?.next_2_mobility?.selection) ? data.next_2_mobility.selection : []),
        ].map((el) => el?.priority || 0);
        const isCurrentPriority = isEmpty(concatArr) ? 1 : Math.max(...concatArr) + 1;

        setData((prev) => ({
          ...prev,
          [convertOverContainer]: {
            ...prev[convertOverContainer],
            selection: [
              ...(prev[convertOverContainer]?.selection || []),
              {
                ...active.data.current,
                priority: isCurrentPriority,
                sortable: { containerId: convertOverContainer },
              },
            ],
          },
        }));
      }
    }

    if (activeContainer !== 'recommendation') {
      if (active.id === over.id) return;
      if (activeContainer === 'end_state') return;
      if (convertOverContainer === 'end_state') return;
      const convertActiveId = active.id.includes('next_mobility')
        ? active.id.replace('next_mobility', '')
        : active.id.includes('next_2_mobility')
        ? active.id.replace('next_2_mobility', '')
        : active.id;
      const convertOverId = over.id.includes('next_mobility')
        ? over.id.replace('next_mobility', '')
        : over.id.includes('next_2_mobility')
        ? over.id.replace('next_2_mobility', '')
        : over.id;
      const activeIndex = data[activeContainer]?.selection.findIndex(
        (item) => Number(item.position_code) === Number(convertActiveId)
      );
      const overIndex = data[convertOverContainer]?.selection.findIndex(
        (item) => Number(item.position_code) === Number(convertOverId)
      );

      setData((items) => {
        let newItems;
        if (activeContainer === convertOverContainer) {
          newItems = {
            ...items,
            [convertOverContainer]: {
              ...items[convertOverContainer],
              selection: arrayMove(items[convertOverContainer]?.selection, activeIndex, overIndex),
            },
          };
        } else {
          newItems = moveBetweenContainers(
            items,
            activeContainer,
            activeIndex,
            convertOverContainer,
            overIndex,
            active.data.current
          );
        }

        return newItems;
      });
    }
  };

  const moveBetweenContainers = (
    items,
    activeContainer,
    activeIndex,
    convertOverContainer,
    overIndex,
    item
  ) => {
    return {
      ...items,
      [activeContainer]: {
        ...items[activeContainer],
        selection: removeAtIndex(items[activeContainer]?.selection || [], activeIndex),
      },
      [convertOverContainer]: {
        ...items[convertOverContainer],
        selection: insertAtIndex(
          items[convertOverContainer]?.selection || [],
          overIndex === -1 ? items[convertOverContainer]?.selection.length : overIndex,
          {
            ...item,
            sortable: { containerId: convertOverContainer },
          }
        ),
      },
    };
  };

  const maxLenghtCharactersNM = getMaxLengthFiledInArrayMobilityPlan(data.next_mobility?.selection);
  const maxLenghtCharactersN2M = getMaxLengthFiledInArrayMobilityPlan(
    data.next_2_mobility?.selection
  );
  const maxLenghtCharactersES = getMaxLengthFiledInArrayMobilityPlan(data.end_state?.selection);

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        autoScroll={{ enabled: true }}
      >
        <div style={{ gap: '10px' }} className={`d-flex ${styles.formWrapper}`}>
          <div className="w-100" style={{ borderTop: '3px solid #3f3c4c' }}>
            <div className="position-relative">
              <div className={styles.separatedLabel} style={{ right: open ? '279px' : '362px' }} />
              <div
                className={`${styles.labelRow} ${
                  onlyViewTalentAgenda ? styles.labelRowCustom : ''
                }`}
              >
                <label htmlFor="next-mobility">{MOBILITY_PLAN_TYPE[0].label}</label>
                <label htmlFor="next-2-mobility">{MOBILITY_PLAN_TYPE[1].label}</label>
                <label htmlFor="end-state">{MOBILITY_PLAN_TYPE[2].label}</label>
                <div className={styles.title}>
                  {!isPrinting ? `Plan ${index + 1}` : `Mobility Plan ${index + 1}`}
                </div>
              </div>
            </div>
            <div className="position-relative">
              <div
                className={styles.separatedContent}
                style={{ right: open ? '279px' : '362px' }}
              />
              <div
                className={`${styles.inputRow} ${
                  !isTheLast && !isPrinting ? styles.inputRowBorder : ''
                }`}
              >
                {['Save', 'Edit'].includes(status) && !onlyViewTalentAgenda && (
                  <div className={styles.inputWrapper}>
                    <div className={styles.inputCell}>
                      <SelectPositionMobilityPlan
                        sectionIndex={index}
                        options={positionOptions}
                        data={data}
                        setData={setData}
                        type={MOBILITY_PLAN_TYPE[0].fieldValue}
                        typeAnother={MOBILITY_PLAN_TYPE[1].fieldValue}
                        maxLength={Math.max(
                          maxLenghtCharactersNM,
                          maxLenghtCharactersN2M,
                          maxLenghtCharactersES
                        )}
                        isOpenFulfilMent={open}
                        setPositionSelected={setPositionSelected}
                        positionSelected={positionSelected}
                      />
                      <DropCardRecommendation
                        cardInfo={{
                          ...MOBILITY_PLAN_DROP_CARD_INFO.NEXT_MOBILITY,
                          desc: `Drag the card from Recommendation table for Plan ${
                            index + 1
                          } Next Mobility and Next 2 Mobility to Add Position`,
                        }}
                        sectionIndex={index}
                        dropId="next_mobility_default"
                      />
                      <AiTextarea
                        rows={3}
                        style={
                          status === 'View' || onlyViewTalentAgenda
                            ? { marginBottom: 0 }
                            : { marginBottom: '10px' }
                        }
                        className={styles.textareaField}
                        placeholder="Enter Next Mobility"
                        name="next_mobility"
                        id="next-mobility"
                        data-testid="next-mobility"
                        onChange={(e) => {
                          setCurrentManualValue((prev) => {
                            return {
                              ...prev,
                              next_mobility: e?.target?.value,
                            };
                          });
                          handleOnChangeManual(e, MOBILITY_PLAN_TYPE[0].fieldValue);
                        }}
                        value={currentManualValue.next_mobility || null}
                      />
                    </div>
                    <div className={styles.inputCell}>
                      <SelectPositionMobilityPlan
                        sectionIndex={index}
                        options={positionOptions}
                        data={data}
                        setData={setData}
                        type={MOBILITY_PLAN_TYPE[1].fieldValue}
                        typeAnother={MOBILITY_PLAN_TYPE[0].fieldValue}
                        maxLength={Math.max(
                          maxLenghtCharactersNM,
                          maxLenghtCharactersN2M,
                          maxLenghtCharactersES
                        )}
                        isOpenFulfilMent={open}
                        setPositionSelected={setPositionSelected}
                        positionSelected={positionSelected}
                      />
                      <DropCardRecommendation
                        cardInfo={{
                          ...MOBILITY_PLAN_DROP_CARD_INFO.NEXT_2_MOBILITY,
                          desc: `Drag the card from Recommendation table for Plan ${
                            index + 1
                          } Next Mobility and Next 2 Mobility to Add Position`,
                        }}
                        sectionIndex={index}
                        dropId="next_2_mobility_default"
                      />
                      <AiTextarea
                        rows={3}
                        style={
                          status === 'View' || onlyViewTalentAgenda
                            ? { marginBottom: 0 }
                            : { marginBottom: '10px' }
                        }
                        className={styles.textareaField}
                        placeholder="Enter Next 2 Mobility"
                        name="next_2_mobility"
                        id="next-2-mobility"
                        data-testid="next-2-mobility"
                        onChange={(e) => {
                          setCurrentManualValue((prev) => {
                            return {
                              ...prev,
                              next_2_mobility: e?.target?.value,
                            };
                          });
                          handleOnChangeManual(e, MOBILITY_PLAN_TYPE[1].fieldValue);
                        }}
                        value={currentManualValue.next_2_mobility || null}
                      />
                    </div>
                    <div className={styles.inputCell}>
                      <SelectPositionMobilityPlan
                        sectionIndex={index}
                        options={positionOptions}
                        data={data}
                        setData={setData}
                        type={MOBILITY_PLAN_TYPE[2].fieldValue}
                        maxLength={Math.max(
                          maxLenghtCharactersNM,
                          maxLenghtCharactersN2M,
                          maxLenghtCharactersES
                        )}
                        isOpenFulfilMent={open}
                      />
                      {isEmpty(data.end_state.selection) && (
                        <DropCardRecommendation
                          cardInfo={{
                            ...MOBILITY_PLAN_DROP_CARD_INFO.END_STATE,
                            desc: `Drag the card from Recommendation table for Plan ${
                              index + 1
                            } End State to Add Position`,
                          }}
                          sectionIndex={index}
                          dropId="end_state_default"
                        />
                      )}
                      <AiTextarea
                        rows={3}
                        style={
                          status === 'View' || onlyViewTalentAgenda
                            ? { marginBottom: 0 }
                            : { marginBottom: '10px' }
                        }
                        className={styles.textareaField}
                        placeholder="Enter End State"
                        name="end_state"
                        id="end-state"
                        data-testid="end-state"
                        onChange={(e) => {
                          setCurrentManualValue((prev) => {
                            return {
                              ...prev,
                              end_state: e?.target?.value,
                            };
                          });
                          handleOnChangeManual(e, MOBILITY_PLAN_TYPE[2].fieldValue);
                        }}
                        value={currentManualValue.end_state || null}
                      />
                    </div>
                  </div>
                )}
                {(status === 'View' || onlyViewTalentAgenda) && (
                  <div className={styles.inputWrapper}>
                    <div
                      className={styles.viewCell}
                      style={
                        !isPrinting
                          ? { backgroundColor: '#EDF1F8', borderColor: '#9fb0c2' }
                          : { backgroundColor: '#ffffff', borderColor: '#ffffff' }
                      }
                    >
                      <SelectPositionMobilityPlan
                        sectionIndex={index}
                        isView
                        data={data}
                        type={MOBILITY_PLAN_TYPE[0].fieldValue}
                        maxLength={Math.max(
                          maxLenghtCharactersNM,
                          maxLenghtCharactersN2M,
                          maxLenghtCharactersES
                        )}
                        isOpenFulfilMent={open}
                        setPositionSelected={setPositionSelected}
                        positionSelected={positionSelected}
                      />
                      <div className={styles.textareaField}>{data?.next_mobility?.manual}</div>
                    </div>
                    <div
                      className={styles.viewCell}
                      style={
                        !isPrinting
                          ? { backgroundColor: '#EDF1F8', borderColor: '#9FB0C2' }
                          : { backgroundColor: '#ffffff', borderColor: '#ffffff' }
                      }
                    >
                      <SelectPositionMobilityPlan
                        sectionIndex={index}
                        isView
                        data={data}
                        type={MOBILITY_PLAN_TYPE[1].fieldValue}
                        maxLength={Math.max(
                          maxLenghtCharactersNM,
                          maxLenghtCharactersN2M,
                          maxLenghtCharactersES
                        )}
                        isOpenFulfilMent={open}
                        setPositionSelected={setPositionSelected}
                        positionSelected={positionSelected}
                      />
                      <div className={styles.textareaField}>{data?.next_2_mobility?.manual}</div>
                    </div>
                    <div
                      className={styles.viewCell}
                      style={
                        !isPrinting
                          ? { backgroundColor: '#EDF1F8', borderColor: '#9FB0C2' }
                          : { backgroundColor: '#ffffff', borderColor: '#ffffff' }
                      }
                    >
                      <SelectPositionMobilityPlan
                        sectionIndex={index}
                        isView
                        data={data}
                        type={MOBILITY_PLAN_TYPE[2].fieldValue}
                        maxLength={Math.max(
                          maxLenghtCharactersNM,
                          maxLenghtCharactersN2M,
                          maxLenghtCharactersES
                        )}
                        isOpenFulfilMent={open}
                      />
                      <div className={styles.textareaField}>{data?.end_state?.manual}</div>
                    </div>
                  </div>
                )}
                {!onlyViewTalentAgenda && (
                  <div className={`d-flex justify-content-between`}>
                    <div>
                      {lengthMobility !== 1 && (
                        <BasicButton
                          mode="remove"
                          onClick={() => setOpenDelete(true)}
                          style={{ width: '120px' }}
                        >
                          Remove Plan
                        </BasicButton>
                      )}
                    </div>
                    <div>
                      {status === 'View' && (
                        <BasicButton
                          mode="teal"
                          onClick={() => setStatus('Edit')}
                          style={{ width: '100px', marginLeft: '15px' }}
                        >
                          Edit
                        </BasicButton>
                      )}
                      {['Save'].includes(status) && (
                        <>
                          <BasicButton
                            mode="border-teal"
                            onClick={() => {
                              setData(item);
                            }}
                            style={{ width: '100px', marginLeft: '15px' }}
                            disabled={isNoInput}
                          >
                            Cancel
                          </BasicButton>
                          <BasicButton
                            mode="teal"
                            onClick={() => handleEdit(data.idx)}
                            style={{ width: '100px', marginLeft: '15px' }}
                            disabled={isNoInput}
                          >
                            Save
                          </BasicButton>
                        </>
                      )}
                      {['Edit'].includes(status) && (
                        <>
                          <BasicButton
                            mode="border-teal"
                            onClick={() => {
                              setData(item);
                              setStatus('View');
                            }}
                            style={{ width: '100px', marginLeft: '15px' }}
                          >
                            Cancel
                          </BasicButton>
                          <BasicButton
                            mode="teal"
                            onClick={() => handleEdit(data.idx)}
                            style={{ width: '100px', marginLeft: '15px' }}
                            disabled={isNoInput}
                          >
                            Save
                          </BasicButton>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Recommendation Category */}
            {!onlyViewTalent && !isPrinting && (
              <div className={styles.recommendationCategoryWrapper}>
                <TalentReviewCollapse
                  header={
                    <h3 className={styles.recommendationCategoryTitle}>Recommendations Category</h3>
                  }
                  isCollapsed={onlyViewTalentAgenda}
                >
                  <div className={styles.recommendationCategory}>
                    <div>
                      <Radio.Group
                        onChange={onChangeCategoryType}
                        value={currentCategory}
                        style={{ marginBottom: '12px', gap: '32px' }}
                        className="d-flex justify-content-start align-items-center"
                      >
                        {MOBILITY_PLAN_CATEGORY_TYPE.map((category) => {
                          return (
                            <Radio
                              value={category.value}
                              className={styles.customLabel}
                              key={category.value}
                            >
                              <div
                                className="d-flex justify-content-start align-items-center"
                                style={{ gap: '5px' }}
                              >
                                {category.name}{' '}
                                <Tooltip
                                  placement="top"
                                  title={<div>{category.desc}</div>}
                                  color={'black'}
                                  key={'black'}
                                  overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
                                >
                                  <span>
                                    <img src={mobility_description} alt="mobility_description" />
                                  </span>
                                </Tooltip>
                              </div>
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </div>

                    <div className={styles.recommendationCategoryContent}>
                      <RecommendationTableMobilityPlan
                        recommendationList={
                          !isEmpty(nextmobilityRecommendation)
                            ? nextmobilityRecommendation.map((item) => ({
                                ...item,
                                position_code: item.mobility_code,
                                position_name: item.mobility_name,
                              }))
                            : []
                        }
                        setRecommendationList={() => {}}
                        tableName={`Recommendations for Plan ${
                          index + 1
                        } : Next Mobility and Next 2 Mobility`}
                        type={MOBILITY_PLAN_DROP_CARD_INFO.NEXT_MOBILITY.type}
                        tableIndex={index}
                        loading={loading}
                      />
                      <RecommendationTableMobilityPlan
                        recommendationList={endStateRecommendation}
                        setRecommendationList={() => {}}
                        tableName={`Recommendations for Plan ${index + 1} : End State`}
                        type={MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type}
                        tableIndex={index}
                        loading={loadingEndState}
                      />
                    </div>
                    <DragOverlay
                      dropAnimation={{
                        duration: 500,
                      }}
                    >
                      {draggingItem ? (
                        <div
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #bdb6ea',
                            width: dragItem.isMobility ? '320px' : '450px',
                          }}
                        >
                          {dragItem.isMobility ? (
                            <MobilityItem
                              item={dragItem}
                              index={dragItem.index}
                              draggingItem={draggingItem}
                            />
                          ) : (
                            <PositionItem
                              item={dragItem}
                              index={dragItem.index}
                              draggingItem={draggingItem}
                            />
                          )}
                        </div>
                      ) : null}
                    </DragOverlay>
                  </div>
                </TalentReviewCollapse>
              </div>
            )}
          </div>
          {!onlyViewTalent && !isPrinting && (
            <div>
              <div
                onClick={() => setOpen(!open)}
                onKeyDown={() => {}}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                style={{
                  width: open ? '280px' : '30px',
                  height: '56px',
                  transition: 'width 250ms',
                  background: '#677C91',
                  display: 'flex',
                  justifyContent: open ? 'flex-start' : 'center',
                  alignItems: 'center',
                  gap: '15px',
                  border: 'none',
                  padding: '0 15px',
                  cursor: 'pointer',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
              >
                <img
                  src={expand_fullfillment}
                  alt="expand_fullfillment"
                  style={{
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 250ms',
                  }}
                />
                {open && (
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '20px',
                      letterSpacing: '0.173px',
                    }}
                  >
                    Criteria Fulfilment
                  </span>
                )}
              </div>
              <div style={{ minHeight: '210px' }}>
                <Collapse in={open} dimension="width">
                  <div id="example-collapse-text">
                    <Fullfillment
                      staffId={currentStaffId}
                      data={data}
                      positionSelected={positionSelected}
                    />
                  </div>
                </Collapse>
              </div>
            </div>
          )}
        </div>
        {openDelete && (
          <ConfirmDelete
            info={{
              type: 'confirmToDelete',
              visible: openDelete,
              setVisible: setOpenDelete,
              handleSubmit: () => {
                handleRemove(data.idx);
                setOpenDelete(null);
              },
              onClose: () => setOpenDelete(null),
              title: `Are you sure you want to delete “Plan ${index + 1}” from Mobility Plan ?`,
              desc: `You can't undo this action.`,
            }}
          />
        )}
      </DndContext>
    </>
  );
};

MobilityForm.propTypes = {
  onlyViewTalentAgenda: PropTypes.bool,
  index: PropTypes.number,
  item: PropTypes.object,
  setAddTalent: PropTypes.func,
  isTheLast: PropTypes.bool,
  lengthMobility: PropTypes.number,
  isPrinting: PropTypes.bool,
  positionOptions: PropTypes.array,
};

export default MobilityForm;
