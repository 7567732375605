import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useRef } from 'react';

import { dropdown_icon } from '../../../../../assets/img';
import {
  DEVELOPMENT_PLAN_OPTIONS,
  FORM_MODE_DEVELOPMENT_PLAN,
} from '../../../../../utils/constants';
import styles from './form-development-plan.module.scss';
import { Label, SelectOption } from './styled';

const { Option } = Select;
const { TextArea } = Input;

const init = {
  no: null,
  category: null,
  details: null,
};
export default function FormDevelopentPlan({
  dataSelected = init,
  mode = FORM_MODE_DEVELOPMENT_PLAN.CREATE,
  flagReset,
  onAdd = () => {},
  onSave = () => {},
  onCancel = () => {},
}) {
  const [form] = Form.useForm();

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (mode === FORM_MODE_DEVELOPMENT_PLAN.CREATE) {
      form.setFieldsValue(init);
    } else {
      form.setFieldsValue(dataSelected);
      setInputFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected, mode, flagReset]);

  const onSubmit = () => {
    form.submit();
  };
  const onFinish = (value) => {
    if (mode === FORM_MODE_DEVELOPMENT_PLAN.CREATE) {
      onAdd(value);
    } else {
      onSave(value);
    }
  };
  return (
    <div>
      <Form form={form} initialValues={dataSelected} onFinish={onFinish}>
        <Row>
          <Col span={9}>
            <Form.Item name="category" label={<Label>Category</Label>} labelCol={{ span: 24 }}>
              <SelectOption
                placeholder="Select Category"
                suffixIcon={<img src={dropdown_icon} alt="" />}
                popupClassName={'custom-seclect-option'}
              >
                {DEVELOPMENT_PLAN_OPTIONS.map((item, idx) => (
                  <Option key={item.value} value={item.value}>
                    <span data-testid={`option-${idx}`} className={'option'}>
                      {item.label}
                    </span>
                  </Option>
                ))}
              </SelectOption>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ height: 200 }}>
          <Col span={24}>
            <Form.Item name="details" label={<Label>Details</Label>} labelCol={{ span: 24 }}>
              <TextArea
                ref={inputRef}
                placeholder={''}
                style={{ height: 144, resize: 'none', display: 'block' }}
                data-testid="details-input"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Col className={styles.grBtn}>
            <Button className={styles.add} onClick={onSubmit} data-testid="btn-submit">
              {mode === FORM_MODE_DEVELOPMENT_PLAN.CREATE ? 'Add' : 'Save'}
            </Button>
            <Button className={styles.cancel} onClick={onCancel} data-testid="btn-cancel">
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
