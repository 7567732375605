import { isEmpty } from 'lodash';
import React from 'react';

import { circle_checked, plus_green_icon, un_select_icon } from '../../../../assets/img';
import PopupListExistingColumns from '../PopupListExistingColumns/PopupListExistingColumns';
import styles from './list-other-column.module.scss';

const ListOtherColumn = ({
  isEditMode,
  currentColumns,
  handleAddRemoveColumn,
  listColumnName,
  addNewColumn,
  handleEditColumns,
  setListExistingColumn,
}) => {
  const checkManualColumn = (name) => {
    const tempManualColumn = currentColumns.map((item) => item.name);
    return tempManualColumn.includes(name);
  };

  return (
    <>
      {isEditMode ? (
        <>
          <h3 className={styles.columnTitle}>Other Columns</h3>
          <div className={styles.wrapperColumn}>
            <div className={styles.listColumnName}>
              {currentColumns.map((item) => (
                <>
                  {checkManualColumn(item.name) && (
                    <div
                      key={`${item.name}.${item?.id}`}
                      className={styles.columnNameItem}
                      onClick={() => handleAddRemoveColumn(item)}
                      onKeyDown={() => handleAddRemoveColumn(item)}
                    >
                      <img
                        src={listColumnName.includes(item.name) ? circle_checked : un_select_icon}
                        alt=""
                      />
                      <span>{item.name}</span>
                    </div>
                  )}
                </>
              ))}
              <PopupListExistingColumns
                setListExistingColumn={setListExistingColumn}
                handleEditColumns={handleEditColumns}
                listColumnName={listColumnName}
              />
              <button className={styles.addColumnBtn} type="button" onClick={addNewColumn}>
                <img src={plus_green_icon} alt="" />
                <span>Add New Column</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {!isEmpty(listColumnName) && <h3 className={styles.columnTitle}>Column Selected:</h3>}
          {!isEmpty(listColumnName) && (
            <div className={styles.wrapperColumn}>
              <div className={styles.listColumnName}>
                {listColumnName.map((name) => (
                  <div
                    style={{
                      background: checkManualColumn(name) ? '#ebe1f1' : '#E7EDF3',
                      marginBottom: '10px',
                    }}
                    className={styles.columnNameLabel}
                    key={name}
                  >
                    {name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ListOtherColumn;
