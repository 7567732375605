import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

import { ButtonCS, DropdownEditTagging } from '..';
import {
  fetchFeedbacksSummaryEditTag,
  updateFeedbacksSummary,
  updateOtherOtherExperience,
} from '../../services/talentProfiles';
import { FEED_BACK, OTHER_EXPERIENCE } from '../../utils/constants';
import styles from './edit-tagging.module.scss';

const EditTagging = ({
  show,
  setShow,
  type,
  list,
  setEditList,
  info,
  setIsCheckSubmitFB,
  setOtherExperience,
  loading,
}) => {
  const handleClose = () => setShow(false);
  const [listEdit, setListEdit] = useState([]);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [loadingTableBody, setLoadingTableBody] = useState(true);
  const [listOption, setListOption] = useState([]);
  const [listOptionEDGE, setListOptionEDGE] = useState([]);
  const [listOptionAttribute, setListOptionAttribute] = useState([]);
  const [isCheckDropdown, setIsCheckDropdown] = useState({
    kind: '',
    isChecked: false,
  });
  const [itemChoose, setItemChoose] = useState({});
  const [isDisableSaveBtn, setIsDisableSaveBtn] = useState(false);
  const hide = [true, false];

  const handleUpdateFeedbacks = async () => {
    let response;
    setIsDisableSaveBtn(true);
    if (type === FEED_BACK) {
      const list = listEdit.filter((item) => item?.edit === true);
      if (list && list.length === 0) setShow(false);
      response = await updateFeedbacksSummary({ id: info.id, roleUser: info.roleUser, body: list });
    }

    if (type === OTHER_EXPERIENCE) {
      const dataUpdate = handleConverDateUpdateExp(list);
      if (dataUpdate && dataUpdate.length === 0) setShow(false);
      response = await updateOtherOtherExperience({
        id: info.id,
        roleUser: info.roleUser,
        body: list,
      });
    }

    if (response && response.data && response.data.code === 200) {
      setShow(false);
      if (type === FEED_BACK) {
        setIsCheckSubmitFB((preState) => !preState);
      }
    }
    setIsDisableSaveBtn(false);
  };

  const handleConverDateUpdateExp = (list) => {
    return list
      .filter((item) => item?.edit === true)
      .map((ele) => {
        return {
          id: ele.id,
          status: ele.status,
        };
      });
  };
  const handleListOption = (options, type) => {
    let listOption = options.map((item) => {
      if (type === 'EDGE') {
        return item.edge;
      }
      if (type === 'ATTRIBUTE') {
        return item.attr27;
      }
      return null;
    });
    return Array.from(new Set(listOption));
  };
  const getFeedbacksSummaryEditTag = async (params) => {
    if (!params) {
      setLoadingTableBody(true);
    }
    setLoadingDropdown(true);
    const response = await fetchFeedbacksSummaryEditTag({
      id: info.id,
      roleUser: info.roleUser,
      params,
    });
    if (response && response.data && response.data.code === 200) {
      if (!params) {
        let feedbacks = response.data.result;
        feedbacks = feedbacks.map((fb) => {
          fb.update_flag = fb.update_flag ? parseInt(fb.update_flag) : 0;

          return fb;
        });
        setListEdit(feedbacks);
      } else {
        setListOption(response.data.result);
        setListOptionAttribute(handleListOption(response.data.result, 'ATTRIBUTE'));
        setListOptionEDGE(handleListOption(response.data.result, 'EDGE'));
        setIsCheckDropdown((preState) => {
          return {
            ...preState,
            isChecked: false,
          };
        });
      }
      setLoadingDropdown(false);
      setLoadingTableBody(false);
    }
  };

  useEffect(() => {
    if (!type) return;
    if (type === FEED_BACK && show) getFeedbacksSummaryEditTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, show]);

  useEffect(() => {
    if (isCheckDropdown.isChecked) {
      let params = handleCheckQuery();
      getFeedbacksSummaryEditTag(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckDropdown]);

  const handleCheckQuery = () => {
    if (isCheckDropdown.kind === 'ATTRIBUTE') return { edge: isCheckDropdown.query };
    if (isCheckDropdown.kind === 'EDGE') return { attr27: isCheckDropdown.query };
  };

  const handleCheckType = () => {
    if (!type) return;
    if (type === OTHER_EXPERIENCE) return ['Experience', 'Remarks', 'Hide'];
    if (type === FEED_BACK) return ['Edge', 'Attribute', 'Sentiment Score', 'Sentence', 'Hide'];
  };
  const renderTableHeader = () => {
    let listLabelHeader = handleCheckType();
    return listLabelHeader.map((item, index) => {
      let style = {
        width:
          type === OTHER_EXPERIENCE && index === 1 ? { width: '831px', paddingLeft: '10px' } : {},
        paddingLeft: type === OTHER_EXPERIENCE && index === 2 ? { paddingRight: ' 35px' } : {},
      };
      return (
        <th key={index} style={style.width}>
          <p style={style.paddingLeft}>{item}</p>
        </th>
      );
    });
  };
  useEffect(() => {
    if (!itemChoose) {
      // dont do anything
      return;
    }

    itemChoose?.expTalent &&
      setOtherExperience((preState) => {
        return preState.map((item, index) => {
          if (index === itemChoose.indexEleEdit) {
            return {
              ...itemChoose,
            };
          }
          return item;
        });
      });
    itemChoose?.attr27 &&
      setListEdit((preState) => {
        return preState.map((item, index) => {
          if (index === itemChoose.indexEleEdit) {
            let attr27 = itemChoose.attr27;
            if (itemChoose.edge === 'Unclustered' || itemChoose.edge === 'Dropped') {
              attr27 = 'Not applicable';
            }
            return {
              ...itemChoose,
              attr27: attr27,
            };
          }
          return item;
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemChoose]);
  const renderTableBodyFb = () => {
    return listEdit.map((ele, index) => {
      return (
        <tr key={index}>
          <td>
            <div>
              <DropdownEditTagging
                listOption={listOptionEDGE}
                listOptionAttribute={listOptionAttribute}
                listOptionRaw={listOption}
                setListEdit={setListEdit}
                ele={ele}
                setIsCheckDropdown={setIsCheckDropdown}
                kind={'EDGE'}
                loading={loadingDropdown}
                indexEleEdit={index}
                setItemChoose={setItemChoose}
              />
            </div>
          </td>
          <td>
            <div>
              <DropdownEditTagging
                listOption={listOptionAttribute}
                ele={ele}
                setListEdit={setListEdit}
                indexEleEdit={index}
                setIsCheckDropdown={setIsCheckDropdown}
                kind={'ATTRIBUTE'}
                loading={loadingDropdown}
                setItemChoose={setItemChoose}
              />
            </div>
          </td>
          <td>
            <div>{ele.proficiency || ''}</div>
          </td>
          <td>
            <div>{ele.feedbackDisplay || ''}</div>
          </td>
          <td>
            <div>
              {' '}
              <DropdownEditTagging
                listOption={hide}
                type={'Short'}
                ele={ele}
                kind={'HIDE'}
                indexEleEdit={index}
                setListEdit={setListEdit}
                setItemChoose={setItemChoose}
                setIsCheckDropdown={setIsCheckDropdown}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableBodyOtherExp = () => {
    return list.map((ele, index) => {
      return (
        <tr key={index}>
          <td>
            <div>{ele.expTalent || ''}</div>
          </td>
          <td style={{ width: 'auto', paddingLeft: '10px' }}>
            <div>{ele.comment || ''}</div>
          </td>
          <td>
            <div>
              {' '}
              <DropdownEditTagging
                listOption={hide}
                type={'Short'}
                ele={ele}
                kind={'HIDE'}
                indexEleEdit={index}
                setItemChoose={setItemChoose}
                setListEdit={setEditList}
                setIsCheckDropdown={setIsCheckDropdown}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableBody = () => {
    if (!type) return;
    if (type === OTHER_EXPERIENCE) return renderTableBodyOtherExp();
    if (type === FEED_BACK) return renderTableBodyFb();
  };
  const divStyle = {
    maxHeight: '700px',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'block',
  };
  const dBlock = {
    display: 'block',
  };
  const handleCheckLoading = () => {
    if (!type) return;
    if (type === OTHER_EXPERIENCE) return loading;
    if (type === FEED_BACK) return loadingTableBody;
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName={styles.containerModal}
        data-testid="edit-tagging"
      >
        <Modal.Header closeButton className={styles.editTagging__header}>
          <Modal.Title className={styles.editTagging__title}>Edit Tagging</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.editTagging__body}>
          <div className={styles.editTagging__table}>
            <table>
              <thead style={dBlock}>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody style={divStyle}>{!handleCheckLoading() && renderTableBody()}</tbody>
            </table>
            {handleCheckLoading() && (
              <div className={styles.editTagging__loading}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
          {!handleCheckLoading() && (
            <div className={styles.editTagging__wrapBtn}>
              <ButtonCS className={'btn btn__label--blue btn__bg--white'} onClick={handleClose}>
                Cancel
              </ButtonCS>
              <ButtonCS
                className={'btn btn__label--white btn__bg--blue btn__margin-left'}
                disabled={isDisableSaveBtn}
                onClick={!isDisableSaveBtn && handleUpdateFeedbacks}
              >
                {(isDisableSaveBtn && <Spinner animation="border" size="sm" />) || 'Save'}
              </ButtonCS>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default EditTagging;
