import '../../SuccessionPlanningSection/RecommendationTable/box.scss';

import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { left_icon_gray, sp_chevron_down, up_icon_green } from '../../../../../assets/img';
import {
  MOBILITY_PLAN_DROP_CARD_INFO,
  MOBILITY_PLAN_MOVEMENT_TYPE,
  SHORT_FORM_RECOMMENDATION_MOBILITY,
} from '../../../../../utils/constants';
import { handleShowYIPAndYISG } from '../../../../../utils/helper';
import { Draggable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Draggable';
import styles from '../../SuccessionPlanningSection/RecommendationTable/recommendations.module.scss';

const SEARCH_TABLE_WIDTH = [10, 50, 15, 5, 5, 17, 5];

const Item = React.memo((props) => {
  const { item, index, type, tableIndex } = props;
  const [collapseMenu, setCollapseMenu] = useState(false);

  const showHide = () => {
    setCollapseMenu(!collapseMenu);
  };

  return (
    <>
      <tr className={styles.tableBody} id={item.staff_id} onClick={showHide}>
        <td style={{ width: `${SEARCH_TABLE_WIDTH[0]}%` }}>{index + 1}</td>
        <td className={styles.name} style={{ width: `${SEARCH_TABLE_WIDTH[1]}%` }}>
          <Tooltip
            placement="top"
            color={'#181818'}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            title={
              item?.dimension &&
              MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type === type && (
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    paddingTop: '10px',
                    paddingLeft: '15px',
                  }}
                >
                  Criteria Fulfilment:
                  {item?.contents && (
                    <ul style={{ marginLeft: '15px', padding: 0 }}>
                      {item.contents.map((content) => (
                        <li style={{ fontWeight: 500, paddingLeft: '10px' }} key={content}>
                          {content}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )
            }
          >
            <div
              style={{
                textDecoration: 'none',
                fontFamily: `'Inter', sans-serif`,
                fontSize: '12px',
                fontWeight: 600,
                textTransform: 'none',
                lineheight: '20px',
                color: ' #50a4b9',
                width: 'fit-content',
              }}
              draggable="false"
            >
              {item.position_name}
            </div>
          </Tooltip>
        </td>
        {type !== MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type && (
          <td style={{ width: `${SEARCH_TABLE_WIDTH[3]}%`, textAlign: 'center' }}>
            {[
              MOBILITY_PLAN_MOVEMENT_TYPE.ONE_LEVEL_PROMOTION,
              MOBILITY_PLAN_MOVEMENT_TYPE.TWO_LEVEL_PROMOTION,
            ].includes(item?.mobility_type) ? (
              <img src={up_icon_green} alt="" />
            ) : (
              <></>
              // <img src={left_icon_gray} alt="" />
            )}
          </td>
        )}
        <td style={{ width: `${SEARCH_TABLE_WIDTH[2]}%`, textAlign: 'left' }}>
          {SHORT_FORM_RECOMMENDATION_MOBILITY[item.business] || item.business}
        </td>
        <td style={{ width: `${SEARCH_TABLE_WIDTH[4]}%`, textAlign: 'left' }}>{item.job_grade}</td>
        <td style={{ width: `${SEARCH_TABLE_WIDTH[6]}%` }} onKeyDown={() => {}}>
          <img src={sp_chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
        </td>
      </tr>

      <tr className={styles.tableBodyCard} style={!collapseMenu ? { height: 0 } : {}}>
        <td colSpan={5} style={!collapseMenu ? { padding: 0 } : {}} id={item.staff_id}>
          <Collapse in={collapseMenu}>
            <Draggable
              id={`${
                type === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_MOBILITY.type
                  ? `recommendationNextMobility${tableIndex}`
                  : `recommendationEndState${tableIndex}`
              }-drap-${item.position_code}-${index}-mobility-plan`}
              className={`collapseBody__lineRecommendation`}
              data={{ ...item, index, sortable: { containerId: 'recommendation' } }}
            >
              <div className={styles.card_box}>
                <div className="row no-gutters justify-content-start align-items-stretch">
                  <div
                    className="row no-gutters mt-1"
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#50A4B9',
                      gap: '5px',
                      width: '100%',
                      marginBottom: '10px',
                      textAlign: 'left',
                    }}
                  >
                    <div>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span
                              style={{
                                fontSize: '14px',
                                lineHeight: '19px',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                backgroundcolor: '#000',
                                color: '#fff',
                              }}
                            >
                              {item.birth_name || 'N/A'} (YIP:{' '}
                              {handleShowYIPAndYISG(item.years_in_position, item.date_in_position)})
                            </span>
                          </Tooltip>
                        }
                      >
                        <span
                          onKeyDown={() => {}}
                          onClick={() =>
                            window.open(
                              '/agenda/succession-planning-dashboard/' + item.position_code,
                              '_blank'
                            )
                          }
                        >
                          {item.position_name} {item.position_code && ` (${item.position_code})`}
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className={styles.position}>
                    <span>{item.job_grade || 'N/A'}</span>
                    <br />
                    <span>{item.department || 'N/A'}</span>
                    <br />
                    <span>{item.business || 'N/A'}</span>
                    <br />
                    <span>
                      {item.division || 'N/A'}, {item.opu || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </Draggable>
          </Collapse>
        </td>
      </tr>
    </>
  );
});
Item.displayName = 'RecommendationTableItem';

const Table = React.memo((props) => {
  const { dataTable, setSelectedItems, selectedItems, type, tableIndex } = props;

  return (
    <>
      {!isEmpty(dataTable) ? (
        <table style={{ resize: 'both', overflow: 'auto' }}>
          <thead>
            <tr className={`${styles.tableHeader} `}>
              <td
                style={{
                  width: `${SEARCH_TABLE_WIDTH[0]}%`,
                }}
              ></td>
              <td
                className={styles.nameHeader}
                style={{
                  width: `${SEARCH_TABLE_WIDTH[1]}%`,
                  paddingLeft: 0,
                }}
              >
                Position
              </td>
              {type !== MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type && (
                <td style={{ width: `${SEARCH_TABLE_WIDTH[3]}%`, textAlign: 'center' }}></td>
              )}
              <td style={{ width: `${SEARCH_TABLE_WIDTH[2]}%`, textAlign: 'left' }}>BU</td>
              <td style={{ width: `${SEARCH_TABLE_WIDTH[4]}%`, textAlign: 'left' }}>JG</td>
              <td style={{ width: `${SEARCH_TABLE_WIDTH[6]}%` }}></td>
            </tr>
          </thead>
          <tbody style={dataTable.length > 5 ? { maxHeight: 170 } : { maxHeight: 'none' }}>
            {dataTable.map((item, index) => (
              <Item
                key={`${item.position_code}-${index}`}
                item={item}
                index={index}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                type={type}
                tableIndex={tableIndex}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <table style={{ resize: 'both', overflow: 'auto' }}>
          <tbody style={{ maxHeight: 'none', border: 'none' }}>
            <tr>
              <td
                colSpan={5}
                className={styles.no_data}
                style={{
                  height: 170,
                  fontSize: '13px',
                  fontWeight: 400,
                  color: '#A3A3A3',
                }}
              >
                <span style={{ display: 'inline-block', maxWidth: '250px' }}>
                  {type !== MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type
                    ? `Enter End State to get the recommendation position for Next Mobility and Next 2 Mobility`
                    : `No data`}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
});
Table.displayName = 'Table';

const RecommendationTableMobilityPlan = (props) => {
  const { recommendationList, setRecommendationList, tableName, type, tableIndex, loading } = props;

  return (
    <>
      <div className={styles.collapse}>
        <div className={styles.collapseHeader}>
          <div>{tableName}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.table}>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Table
                  dataTable={recommendationList}
                  setDataTable={setRecommendationList}
                  type={type}
                  tableIndex={tableIndex}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(RecommendationTableMobilityPlan);
