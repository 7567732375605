import { Tooltip } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import {
  no_recommended_replacement,
  no_recommended_replacement_sm,
  tooltip_icon,
} from '../../../../assets/img';
import mobilitySimulationApi from '../../../../services/mobilitySimulationApi';
import { returnDataToUpdateMobility } from '../../../../utils/helper';
import { Legend } from '../MobilityLegend/style';
import MobilityPositionCard from '../MobilityPositionCard/MobilityPositionCard';
import styles from './mobility-recommended-agenda.module.scss';

const updateIndexArr = (arr) => {
  if (isEmpty(arr)) return [];
  return returnDataToUpdateMobility(arr);
};

const MobilityRecommendedAgenda = (props) => {
  const {
    mobilitySimulation,
    setDataRecommended,
    dataRecommended,
    isShowRemark,
    agendaDetail,
    setShowAddRemarkSuccess,
    isHighlighted,
    isSeniorManager,
  } = props;
  const [loading, setLoading] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);

  const isMobilityAgenda = useMemo(() => {
    return !window.location.href.includes(`mobility-simulation`);
  }, []);

  // Implement function get data from API
  useEffect(() => {
    if (!isHighlighted.position_code) {
      setDataRecommended({
        approveList: [],
        aiList: [],
        reportList: [],
      });
      return;
    }
    const position_code = isHighlighted.position_code;
    const ids = [];

    if (mobilitySimulation?.dataChain) {
      mobilitySimulation.dataChain.forEach((item) => item?.staff_id && ids.push(item.staff_id));
    }

    async function getRecommendedReplacement() {
      try {
        if (isSeniorManager) return;
        setLoading(true);
        const response = await mobilitySimulationApi.getRecommendedReplacement(
          { position_code, ids },
          roleActive
        );
        const data = get(response, 'data.result');
        setDataRecommended({
          approveList: updateIndexArr(data.approved_sp),
          aiList: updateIndexArr(data.recommendation_ai),
          reportList: updateIndexArr(data.direct_reports),
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setDataRecommended({
          approveList: [],
          aiList: [],
          reportList: [],
        });
      }
    }
    getRecommendedReplacement();
  }, [
    mobilitySimulation.dataChain,
    isHighlighted.position_code,
    roleActive,
    roleActive.roleId,
    setDataRecommended,
    isSeniorManager,
  ]);

  const handleGetTooltipContent = (type, content) => {
    return (
      <Tooltip
        overlayStyle={{ minWidth: '304px' }}
        placement="bottomRight"
        title={
          <div className={styles.tooltipCustom}>
            <span className={styles.firstText}>{content?.firstText}</span>
            {!isEmpty(content?.lastText) && (
              <span className={styles.lastText}>{content?.lastText}</span>
            )}
          </div>
        }
        color={'black'}
        key={'black'}
        overlayInnerStyle={{ borderRadius: '6px' }}
      >
        <img className={styles.tooltipIcon} src={tooltip_icon} alt="" />
      </Tooltip>
    );
  };

  const handleGetApprovedDate = () => {
    return !isEmpty(dataRecommended?.approveList)
      ? moment(dataRecommended?.approveList[0]?.approved_date).format('DD/MM/YYYY')
      : '';
  };

  const isShowRecommemded = useMemo(() => {
    return (
      !isEmpty(dataRecommended.approveList) ||
      !isEmpty(dataRecommended.aiList) ||
      !isEmpty(dataRecommended.reportList)
    );
  }, [dataRecommended]);

  return (
    <div
      className={styles.recommended}
      style={isMobilityAgenda ? { width: '811px', height: '1141px' } : {}}
    >
      <div className={styles.recommendedHeader}>
        <div className={styles.recommendedTitle}>Recommended Replacement</div>
        {isShowRecommemded && (
          <div className="d-flex justify-content-center align-items-center" style={{ gap: '20px' }}>
            <div className="d-flex justify-content-center align-items-center">
              <Legend color="#FEDC92" />
              <span className={styles.recommendedLegendLabel} style={{ marginLeft: '5px' }}>
                Approved Succession Planning
              </span>
              <div style={{ display: 'inline-block' }}>
                {handleGetTooltipContent('APPROVED_SP', {
                  firstText: 'Approved Date Succession Planning',
                  lastText: handleGetApprovedDate(),
                })}
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Legend color="#ECF3C1" />
              <span className={styles.recommendedLegendLabel} style={{ marginLeft: '5px' }}>
                AI Recommendation
              </span>
              <div style={{ display: 'inline-block' }}>
                {handleGetTooltipContent('AI_RECOMMENDATION', {
                  firstText: 'Disclaimer',
                  lastText: 'Missing SG/JG data might impact the AI Recommendation List',
                })}
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Legend color="#BDB6EA" />
              <span className={styles.recommendedLegendLabel} style={{ marginLeft: '5px' }}>
                Direct Reports
              </span>
              <div style={{ display: 'inline-block' }}>
                {handleGetTooltipContent('DIRECT_REPORTS', {
                  firstText: 'Disclaimer',
                  lastText: 'Direct reports will only include top talents.',
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div
          style={{ minHeight: '300px' }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {!loading && isShowRecommemded ? (
        <div
          className={styles.recommendedBody}
          style={
            isMobilityAgenda
              ? {
                  marginTop: '32px',
                  display: 'flex',
                  flexFlow: 'row wrap',
                  justifyContent: 'space-between',
                }
              : {
                  marginTop: '50px',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: '20px',
                  maxHeight: '500px',
                  overflowY: 'scroll',
                }
          }
        >
          {[
            ...dataRecommended.approveList,
            ...dataRecommended.aiList,
            ...dataRecommended.reportList,
          ].map((item, _index) => (
            <Droppable
              droppableId={item.key}
              index={Number(item.key)}
              key={Number(item.key)}
              isDropDisabled
            >
              {(provided, snapshot) => (
                <div
                  className={` ${snapshot.isDraggingOver ? 'dragactive' : ''}`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={isMobilityAgenda ? { marginBottom: '20px', width: '49%' } : {}}
                >
                  <Draggable draggableId={item.key} index={Number(item.key)} key={Number(item.key)}>
                    {(provided, _snapshot) => (
                      <MobilityPositionCard
                        data={item}
                        provided={provided}
                        isAutoWidth={true}
                        isShowRemark={isShowRemark}
                        agendaDetail={agendaDetail}
                        setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                      />
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      ) : (
        <>
          {!isSeniorManager ? (
            <div className={styles.no_recommended_replacement}>
              <img src={no_recommended_replacement} alt="no_recommended_replacement" />
            </div>
          ) : (
            <div className={styles.no_recommended_replacement}>
              <img src={no_recommended_replacement_sm} alt="no_recommended_replacement_sm" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MobilityRecommendedAgenda;
