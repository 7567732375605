import { Tooltip } from 'antd';
import { chunk, isEmpty } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  full_arrow_left,
  full_arrow_right,
  full_arrow_top,
  sp_trash,
  tooltip_green_icon,
} from '../../../../assets/img';
import { pushMessage } from '../../../../store/alertMessageSlice';
import {
  MESSAGE_TYPES,
  MS_AGENDA_DISCLAIMER,
  SP_NOTES_PERFORMANCE_RATING,
} from '../../../../utils/constants';
import { convertPositionSimulation, convertTalentSimulation } from '../../../../utils/helper';
import MobilityPositionCard from '../MobilityPositionCard/MobilityPositionCard';
import * as styles from './mobility-chain-simulation-agenda.module.scss';

const MobilityChainSimulationAgenda = (props) => {
  const {
    data,
    isMain,
    handleIsMain,
    handleEditSimulation,
    handleDeleteSimulation,
    isHiddenEditMSField,
    isShowRemark,
    agendaDetail,
    setShowAddRemarkSuccess,
  } = props;
  const dispatch = useDispatch();
  const [maxWidthCard, setMaxWidthCard] = useState('100%');
  const wrapperRef = useRef(null);
  const chunkData = useMemo(() => {
    let positions = [];
    let talents = [];
    if (!isEmpty(data.position)) {
      positions = data.position.map((item) => {
        return convertPositionSimulation(item);
      });
    }
    if (!isEmpty(data.talents)) {
      talents = data.talents.map((item) => {
        return convertTalentSimulation(item);
      });
    }
    const arr = [...positions, ...talents];
    return chunk(arr, 3);
  }, [data]);

  const isLastItem = (array, index) => {
    return array.length === index + 1;
  };

  useLayoutEffect(() => {
    if (!wrapperRef?.current?.offsetWidth) return;
    setMaxWidthCard(`${(wrapperRef.current.offsetWidth - 180) / 3}px`);
  }, [wrapperRef]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {!isHiddenEditMSField && (
        <button
          type="button"
          className={styles.deleteBtn}
          onKeyDown={() => {}}
          onClick={() => handleDeleteSimulation(data.mbsm_id)}
        >
          <img src={sp_trash} alt="sp_trash" className={styles.sp_trash_top} />
        </button>
      )}
      <div className={styles.mainTitleWrapper}>
        <h3 className={styles.mainTitle}>
          {data.name}
          <span
            onKeyDown={() => {}}
            onClick={() =>
              dispatch(
                pushMessage({
                  message: SP_NOTES_PERFORMANCE_RATING,
                  type: MESSAGE_TYPES.NORMAL,
                  timeShow: 0,
                })
              )
            }
          >
            <Tooltip
              className={styles.customTooltip}
              overlayStyle={{ minWidth: '317px' }}
              title={
                <div className={styles.tooltipContent}>
                  <span>{MS_AGENDA_DISCLAIMER[0].label}</span>
                </div>
              }
              color={'white'}
              key={'white'}
              overlayInnerStyle={{ borderRadius: '6px' }}
            >
              <img className={styles.tooltipIcon} src={tooltip_green_icon} alt="" />
            </Tooltip>
          </span>
        </h3>
        {!isHiddenEditMSField && (
          <label className={styles.radioContainer}>
            Set as Main
            <input
              type="radio"
              name="setAsMain"
              checked={isMain}
              onChange={() => handleIsMain(data.mbsm_id)}
            />
            <span className={styles.radioCheckmark}></span>
          </label>
        )}
      </div>
      <div className={styles.btnWrapper}>
        {!isHiddenEditMSField && (
          <button
            type="button"
            className={styles.editBtn}
            onKeyDown={() => {}}
            onClick={() => handleEditSimulation(data.mbsm_id)}
          >
            Edit Mobility Simulation
          </button>
        )}
      </div>
      {chunkData.map((dataRow, indexRow) => {
        const isOddRow = indexRow % 2 !== 0;
        return (
          <div key={indexRow} className={`${styles.row} ${isOddRow && styles.rowReverse}`}>
            {dataRow.map((card, indexCard) => {
              const isLastCard = isLastItem(dataRow, indexCard);
              return (
                <React.Fragment key={indexCard}>
                  <div className={styles.card} style={{ maxWidth: maxWidthCard }}>
                    {isLastCard ? (
                      <>
                        <MobilityPositionCard
                          data={card}
                          width={maxWidthCard}
                          isShowRemark={isShowRemark}
                          agendaDetail={agendaDetail}
                          setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                        />
                        {!isLastItem(chunkData, indexRow) && (
                          <img
                            src={full_arrow_top}
                            alt="full_arrow_top"
                            className={styles.arrowTop}
                          />
                        )}
                      </>
                    ) : (
                      <MobilityPositionCard
                        data={card}
                        width={maxWidthCard}
                        isShowRemark={isShowRemark}
                        agendaDetail={agendaDetail}
                        setShowAddRemarkSuccess={setShowAddRemarkSuccess}
                      />
                    )}
                  </div>
                  {!isLastCard && (
                    <img src={isOddRow ? full_arrow_right : full_arrow_left} alt="full_arrow_top" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default MobilityChainSimulationAgenda;
