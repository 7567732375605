import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ApprovedSP,
  BoardMembership,
  CareerAspiration,
  CareerJourney,
  ContractDetails,
  EDGEView,
  Experiences,
  OtherContribution,
  Qualification,
  SpokenLanguage,
  TalentSketch,
} from '..';
import PrintApprovedSp from '../../pages/Agenda/components/PrintApprovedSP/PrintApprovedSp';
import OverAllProfileUser from '../../pages/TalentProfilePage/components/OverAllProfileUser/OverAllProfileUser';
import { profileSelector } from '../../pages/TalentProfilePage/selector';
import AssessmentPrint from './components/AssessmentPrint/AssessmentPrint';
import DevelopmentPlanPrint from './components/DevelopmentPlanPrint/DevelopmentPlanPrint';
import HistoricalTalentStatusPrint from './components/HistoricalTalentStatusPrint/HistoricalTalentStatusPrint';
import MobilityPlanPrint from './components/MobilityPlanPrint/MobilityPlanPrint';
import OtherExperiencePrint from './components/OtherExperiencePrint/OtherExperiencePrint';
import PersonalityPrint from './components/PersonalityPrint/PersonalityPrint';
import styles from './talent-profile-print-preview.module.scss';

// this code of page meeting detail
const TalentProfilePrintPreview = (props) => {
  const { id } = useParams();
  const printRef = React.createRef();
  const { firstSectionProfile } = useSelector(profileSelector);
  const customFileName = `${firstSectionProfile.staffId ?? id}_${
    firstSectionProfile.birthName ? firstSectionProfile.birthName.split(' ').join('_') : ''
  }.pdf`;

  return (
    <>
      <Modal
        onHide={() => props?.closeModal()}
        dialogClassName={styles.containerModal}
        show={props?.isPrinting}
        style={{ overflowX: 'auto' }}
        centered
      >
        <Modal.Body>
          <div ref={printRef}>
            <div className={styles.overallProfileUser}>
              <OverAllProfileUser isPrintPdf={true} />
            </div>
            <div className="mt-3">
              <div className={styles.Talentprofile}>
                <div className={styles.Talentprofile__left}>
                  <div style={{ background: 'white' }} className={styles.Card}>
                    <div style={{ marginTop: '39px' }}>
                      <>
                        <div
                          className={styles.distance}
                          style={{
                            breakAfter: 'page',
                            breakInside: 'auto',
                          }}
                        >
                          <CareerJourney
                            id={id}
                            roleUser={props?.roleUser}
                            setCareerJourneyData={() => {}}
                            noDetail={false}
                            customStyle={{}}
                          />
                        </div>
                        <div className={styles.distance}>
                          <CareerAspiration carreerAspiration={props?.carreerAspiration} />
                        </div>
                        <div className={styles.distance}>
                          <TalentSketch id={id} roleUser={props?.roleUser} />
                        </div>
                        <div className={styles.distance}>
                          <PersonalityPrint personality={props?.personality} />
                        </div>
                        <div className={styles.distance}>
                          <BoardMembership langAndBoard={props?.langAndBoard} />
                        </div>
                        <div className={styles.distance}>
                          <Qualification qualification={props?.qualification} />
                        </div>
                        <div
                          className={styles.distance}
                          style={{
                            breakBefore: 'page',
                            breakAfter: 'page',
                            breakInside: 'auto',
                          }}
                        >
                          <ApprovedSP id={id} roleUser={props?.roleUser} />
                        </div>
                        <div className={styles.distance}>
                          <SpokenLanguage langAndBoard={props?.lang} />
                        </div>
                      </>
                      {props?.showEdge && (
                        <div
                          className={`${styles.container} mt-3`}
                          style={{
                            breakBefore: 'page',
                            breakAfter: 'page',
                            breakInside: 'auto',
                          }}
                        >
                          <EDGEView id={id} roleUser={props?.roleUser} />
                        </div>
                      )}

                      <>
                        <div
                          className={`${styles.container} ${styles.distance}`}
                          style={{
                            breakBefore: 'page',
                            breakAfter: 'page',
                            breakInside: 'auto',
                          }}
                        >
                          <Experiences
                            profileId={id}
                            roleUser={props?.roleUser}
                            isPrinting={true}
                          />
                        </div>
                        <div className={'mt-3'}>
                          <OtherExperiencePrint
                            profileId={id}
                            roleUser={props?.roleUser}
                            isPrintPdf={true}
                          />
                        </div>
                        <div className={'mt-3'}>
                          <OtherContribution
                            profileId={id}
                            roleUser={props?.roleUser}
                            isPrintPdf={true}
                          />
                        </div>
                      </>
                      <>
                        <div className={'mt-3'}>
                          <DevelopmentPlanPrint
                            profileId={id}
                            roleUser={props?.roleUser}
                            isPrintPdf={true}
                          />
                        </div>
                        <div className={'mt-3'}>
                          <ContractDetails
                            profileId={id}
                            roleUser={props?.roleUser}
                            isPrintPdf={true}
                          />
                        </div>
                        <div className={'mt-3'}>
                          <MobilityPlanPrint
                            profileId={id}
                            roleUser={props?.roleUser}
                            isPrintPdf={true}
                          />
                        </div>
                        <div
                          className={'mt-3'}
                          style={{
                            breakBefore: 'page',
                            breakInside: 'auto',
                          }}
                        >
                          <AssessmentPrint profileId={id} isPrintPdf={true} />
                        </div>
                        <div
                          className={`${styles.container}  mt-3`}
                          style={{
                            breakBefore: 'page',
                            breakAfter: 'page',
                            breakInside: 'auto',
                          }}
                        >
                          <HistoricalTalentStatusPrint profileId={id} />
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <PrintApprovedSp
              componentRef={printRef}
              positionName={'test'}
              dateApproved={''}
              customFileName={customFileName}
              style={{ backgroundColor: ' #00a19c', color: 'white' }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(TalentProfilePrintPreview);
