import { Col, Row, Space } from 'antd';
import { useFormik } from 'formik';
import { isEmpty, size } from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AiButton } from '../../../assets/common';
import { AlertMessage, AsyncMultipleSelect, FieldError } from '../../../components';
import userApi from '../../../services/userApi';
import { DATE_TIME_FORMAT, USER_ID } from '../../../utils/constants';
import MeetingCategory from './components/MeetingCategory';
import styles from './meeting-details.module.scss';
import {
  AiContainerGrBtnModel,
  AiDatePicker,
  AiDesc,
  AiFormWrap,
  AiInput,
  AiLabel,
  AiModel,
  AiTimePickerField,
  AiTitle,
} from './Styled';
import { isCheckError, onChangeInputData, onChangeSelectData } from '../../../hooks/useFormik';
import { getAsyncOptionAdvocatorsHrPartners } from '../../../utils/helper';

const ModalMeetingDetails = ({
  setVisible,
  visible,
  initialValues,
  initialErrors,
  validationSchema,
  setValues,
  getAsyncOptionsForSecretarial,
  businessOption,
  opuDivisionOption,
}) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const meeting_category_option = [
    {
      id: '1',
      name: 'PETRONAS',
    },
    {
      id: '2',
      name: 'Business',
    },
    {
      id: '3',
      name: 'OPU/Division',
    },
  ];
  const formik = useFormik({
    initialValues,
    initialErrors,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      setValues(values);
      setVisible(false);
    },
    initialTouched: Object.keys(initialValues).reduce(
      (result, key) => ({ ...result, [key]: true }),
      {}
    ),
  });

  const [isChangeCategory, setIsChangeCategory] = useState(false);

  const categoryValueOptions = useMemo(() => {
    return formik.values.category.name === meeting_category_option[1].name
      ? businessOption
      : opuDivisionOption;
  }, [businessOption, formik.values.category.name, meeting_category_option, opuDivisionOption]);

  useEffect(() => {
    if (isChangeCategory) {
      formik.setFieldValue('category_value', null);
      setIsChangeCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeCategory]);

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    const weekStart = moment().startOf('day');
    return !weekStart.isSameOrBefore(current);
  };

  return (
    <AiModel
      open={visible}
      width={1329}
      height={670}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      {size(formik.errors) > 0 && (
        <Row>
          <Col span={24} style={{ marginBottom: '30px' }}>
            <AlertMessage
              message="There are items that require your attention. Please fill out this field."
              type="error"
            />
          </Col>
        </Row>
      )}

      <Row align="middle">
        <Col>
          <AiTitle>Fill in the field below to save as draft</AiTitle>
          <AiDesc>You have left a field empty and a value must be entered.</AiDesc>
        </Col>
      </Row>
      <form onSubmit={formik.handleSubmit}>
        <AiFormWrap>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Name</AiLabel>
              <AiInput
                placeholder="Meeting Name"
                name="meeting_name"
                value={formik.values.meeting_name}
                onChange={formik.handleChange}
                status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
              />
            </Col>
            <FieldError name="meeting_name" {...formik} />
          </Row>
          <div className={styles.rowInput}>
            <div className={styles.inputItem}>
              <AiLabel>Date</AiLabel>
              <Space direction="vertical" style={{ width: '100%' }}>
                <AiDatePicker
                  disabledDate={disabledDate}
                  onChange={onChange}
                  format={DATE_TIME_FORMAT.DATE_SPACE}
                  status={isCheckError(formik, 'date') ? 'error' : ''}
                  value={
                    formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)
                  }
                  placeholder="Select Date"
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
                <FieldError name="date" {...formik} />
              </Space>
            </div>
            <div className={styles.inputItem}>
              <AiLabel>Meeting Category</AiLabel>
              <MeetingCategory
                selected={formik.values.category}
                setSelected={(event) => {
                  formik.setFieldValue('category', event);
                  setIsChangeCategory(true);
                }}
                options={meeting_category_option}
                placeholder="Select Meeting Category"
                status={isCheckError(formik, 'category') ? 'error' : ''}
              />
              <FieldError
                name="category.id"
                {...formik}
                forceTouched={isCheckError(formik, 'category')}
              />
            </div>
            {!isEmpty(formik.values.category) &&
              formik.values.category.name !== meeting_category_option[0].name && (
                <div className={styles.inputItem}>
                  <AiLabel>{formik.values.category.name}</AiLabel>
                  <MeetingCategory
                    selected={formik.values.category_value}
                    setSelected={(event) => {
                      formik.setFieldValue('category_value', event);
                    }}
                    options={categoryValueOptions}
                    placeholder={`Select ${formik.values.category.name}`}
                    status={isCheckError(formik, 'category_value') ? 'error' : ''}
                  />
                  <FieldError name="category_value" {...formik} />
                </div>
              )}
            <div className={styles.inputItem}>
              <AiLabel>Start time</AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={
                  formik.values.start_time
                    ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local()
                    : null
                }
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(formik, value, 'start_time')}
                status={isCheckError(formik, 'start_time') ? 'error' : ''}
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
              <FieldError name="start_time" {...formik} />
            </div>
            <div className={styles.inputItem}>
              <AiLabel>End time</AiLabel>
              <AiTimePickerField
                placeholder={'Select Time'}
                value={
                  formik.values.end_time
                    ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local()
                    : null
                }
                style={{ width: '100%' }}
                onChange={(_event, value) => onChangeInputData(formik, value, 'end_time')}
                status={isCheckError(formik, 'end_time') ? 'error' : ''}
                showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                format={DATE_TIME_FORMAT.TIME}
                showNow={false}
                inputReadOnly={true}
                allowClear={false}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
              <FieldError name="end_time" {...formik} />
            </div>
          </div>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Chairman</AiLabel>
              <AsyncMultipleSelect
                value={formik.values.chairman}
                placeholder="Enter Chairman Name"
                loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                onChange={(e) => onChangeSelectData(formik, e, 'chairman')}
                status={isCheckError(formik, 'chairman') ? 'error' : ''}
                isDisabled={false}
              />
              <FieldError name="chairman" {...formik} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              <AiLabel>Meeting Secretariat</AiLabel>
              <AsyncMultipleSelect
                placeholder={'Enter Meeting Secretariat Name'}
                onChange={(e) => onChangeSelectData(formik, e, 'meeting_secretariats')}
                loadOptions={getAsyncOptionsForSecretarial}
                status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                value={formik.values.meeting_secretariats}
              />
              <FieldError name="meeting_secretariats" {...formik} />
            </Col>
          </Row>
        </AiFormWrap>
        <AiContainerGrBtnModel>
          <AiButton
            onClick={() => {
              setVisible(false);
              formik.resetForm();
            }}
            border={true}
          >
            Cancel
          </AiButton>
          <AiButton mode="teal" className="ml-2" onClick={formik.handleSubmit}>
            Save as Draft
          </AiButton>
        </AiContainerGrBtnModel>
      </form>
    </AiModel>
  );
};

export default memo(ModalMeetingDetails);
