import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiButton } from '../../../../../assets/common';
import { delete_ms_from_agenda } from '../../../../../assets/img';
import { AiDesc, AiModel, AiTitle } from './Styled';
import TextArea from 'antd/lib/input/TextArea';
import { useRef } from 'react';

const configure = {
  confirmToMoveTalentCard: {
    title: 'Are you sure you want to move this position card to Agreed to Drop Section?',
    desc: 'You can undo this action by revert the card.',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
};

const ConfirmPopup = (dataModal, { ...rest }) => {
  let { info } = dataModal;
  const [itemIncumbent, setItemIncumbent] = useState({
    title: '',
    desc: '',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: false,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: '',
    img: '',
  });

  const ref = useRef('');
  useEffect(() => {
    if (info.title && info.desc) {
      setItemIncumbent({ ...configure[info.type], title: info.title, desc: info.desc });
      return;
    }
    setItemIncumbent(configure[info.type]);
  }, [info.type, info.title, info.desc]);

  const handleClose = () => {
    info.setVisible(null);
    info.onClose && info.onClose();
  };

  return (
    <AiModel
      {...rest}
      centered
      open={info.visible}
      width={1283}
      height={670}
      footer={null}
      onCancel={() => {
        info.setVisible(null);
        info.onClose && info.onClose();
      }}
      zIndex={2000}
    >
      <Row align="middle">
        <Col span={12}>
          <AiTitle>{itemIncumbent.title}</AiTitle>
          <AiDesc>{itemIncumbent.desc}</AiDesc>
          <div>
            <TextArea
              rows={4}
              placeholder="Enter your remarks here"
              style={{
                border: '1px solid #C9D5E3',
                borderRadius: '6px',
                marginBottom: '20px',
                width: '450px',
                padding: '10px',
                fontSize: '14px',
                color: '#7A7A7A',
              }}
              onChange={(e) => {
                ref.current = e.target.value;
              }}
            />
          </div>
          <div style={{ width: '450px' }} className="d-flex justify-content-end">
            <AiButton
              border={`${itemIncumbent?.borderCancel}`}
              mode={itemIncumbent.modeCancel}
              onClick={handleClose}
              disabled={info.loading}
              data-testid="btn-cancel-modelTC"
              style={{
                width: '110px',
                height: '45px',
                color: 'black',
                border: '1px solid #C9D5E3',
              }}
            >
              {itemIncumbent.btnCancel}
            </AiButton>
            <AiButton
              border={`${itemIncumbent?.borderSubmit}`}
              mode="EC2E2E"
              className="ml-2"
              onClick={() => {
                info.handleSubmit(ref.current.trim());
              }}
              disabled={info.loading}
              loading={info.loading}
              data-testid="btn-submit-modelTC"
              style={{ height: '45px' }}
            >
              {itemIncumbent.btnSubmit}
            </AiButton>
          </div>
        </Col>
        <Col span={12}>
          <img src={itemIncumbent.img} alt="img" />
        </Col>
      </Row>
    </AiModel>
  );
};
export default ConfirmPopup;
