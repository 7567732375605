import React, { memo } from 'react';

import { search_data_not_found } from '../../assets/img';
import styles from './search-not-found.module.scss';

const SearchDataTableNotFound = ({ content, notFoundIcon }) => {
  return (
    <div className={styles.searchNotFoundWrapper}>
      <div className={styles.searchNotFoundIcon}>
        <img src={notFoundIcon || search_data_not_found} alt={''} />
      </div>
      <div className={styles.searchNotFoundContent}>{content || 'Search not found.'}</div>
    </div>
  );
};
export default memo(SearchDataTableNotFound);
