import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import MenuBarViewResultMatching from '../components/MenuBarViewResultMatching/MenuBarViewResultMatching';
import DataTableViewResult from './DataTableViewResult/DataTableViewResult';
import * as styles from './view-result-set-matching.module.scss';

const ViewResultSetMatching = (props) => {
  const dispatch = useDispatch();
  const { positionCode, runId } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterMatching, setFilterMatching] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [page, setPage] = useState(1);
  const [isExport, setIsExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const limit = 15;

  const [roleLevelSelected, setRoleLevelSelected] = useState([]);
  const [salaryGradelSelected, setSalaryGradelSelected] = useState([]);
  const [spReadinessSelected, setSpReadinessSelected] = useState([]);
  const [sortMatchingPercentage, setSortMatchingPercentage] = useState(null);
  const [expandRecord, setExpandRecord] = useState(null);

  const [searchTerm, setSearchTerm] = useState({
    type: 'search',
    value: '',
  });

  const fetchMatchingCriteriaList = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        roleId: roleActive?.roleId,
        page,
        limit,
        isExport,
        run_id: runId,
        role_level: roleLevelSelected || [],
        salary_grade: salaryGradelSelected || [],
        sp_readiness: spReadinessSelected || [],
        keyword: searchTerm.value || '',
        position_code: positionCode,
        sort_matching_percentage: sortMatchingPercentage,
      };

      if (isExport) {
        setLoading(false);
        setLoadingExport(true);
      }

      const response = await adminApi.getMatchingCriteriaList(params);
      const data = response?.data?.result;
      if (response.status === 200 && data) {
        setData(data);
        setTotal(data?.total || 0);
        if (isExport) {
          setExportData(data);
          setLoadingExport(false);
          setIsExport(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingExport(false);
      dispatch(
        pushMessage({
          type: 'error',
          message: `An unexpected error occurred.`,
        })
      );
    } finally {
      setLoading(false);
      setExpandRecord(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    roleActive,
    isExport,
    page,
    limit,
    runId,
    roleLevelSelected,
    salaryGradelSelected,
    spReadinessSelected,
    searchTerm,
    positionCode,
    sortMatchingPercentage,
  ]);

  const fetchFilterMatching = useCallback(async () => {
    try {
      setLoading(true);
      const response = await adminApi.getFilterMatchingCriteria(roleActive?.roleId);
      if (response.status === 200 && response?.data?.result) {
        setFilterMatching(response?.data?.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [roleActive]);

  useEffect(() => {
    if (isEmpty(filterMatching)) {
      fetchFilterMatching();
    }
    fetchMatchingCriteriaList();
  }, [filterMatching, fetchMatchingCriteriaList, fetchFilterMatching]);

  return (
    <div className="max-width">
      <h3 className={styles.mainTitle}>
        <span>
          {data?.position_name
            ? `List of Best Fit Recommendation for ${data?.position_name || ''}: `
            : `List of Best Fit Recommendation`}
        </span>
        <span style={{ color: '#677C91' }}>{data?.set_name || ''}</span>
        <p className={styles.noteTitle} data-testid="noteTitle">
          List of Staff SG/JG Management
        </p>
      </h3>

      <Spin spinning={loading} size="large">
        <MenuBarViewResultMatching
          setIsExport={setIsExport}
          exportData={exportData}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          loadingExport={loadingExport}
          setPage={setPage}
        />
        <DataTableViewResult
          data={data?.result || []}
          positionName={data?.position_name}
          totalData={total}
          filterMatching={filterMatching}
          page={page}
          setPage={setPage}
          limit={limit}
          setRoleLevelSelected={setRoleLevelSelected}
          roleLevelSelected={roleLevelSelected}
          setSalaryGradelSelected={setSalaryGradelSelected}
          salaryGradelSelected={salaryGradelSelected}
          setSpReadinessSelected={setSpReadinessSelected}
          spReadinessSelected={spReadinessSelected}
          setSortMatchingPercentage={setSortMatchingPercentage}
          expandRecord={expandRecord}
          setExpandRecord={setExpandRecord}
        />
      </Spin>
    </div>
  );
};

export default ViewResultSetMatching;
