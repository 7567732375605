import { useCallback, useEffect, useState } from 'react';

import { adminApi } from '../services/admin';

export const useGetRoleLevel = ({ keyword, limit, page, ...agrs }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const response = await adminApi.getRoleLevelSearch({ keyword, limit, page });
      setData(response.data.result);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [keyword, limit, page]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { fetch, data, loading, error };
};
