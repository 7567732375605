export const TYPE_KEY_STR_DEV = {
  KEY_STRENGTH_DETAIL: 'keyStrengthsAll',
  KEY_DEV_DETAIL: 'keyDevelopmentAll',
  KEY_STENGTH_GENERAL: 'keyStrengths',
  KEY_DEV_GENERAL: 'DevArea',
};
export const TYPE_ELE_1_ = {
  EDGE: 'edge',
  ATTR: 'attr',
};
