import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ModelTC, Pagination } from '..';
import { BasicButton } from '../../assets/common';
import { edit_talent_profile } from '../../assets/img';
import talentProfileApi from '../../services/talentProfiles';
import { pushMessage } from '../../store/alertMessageSlice';
import { PERMISSION } from '../../utils/constants';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import More3DotDropdown from '../More3DotDropdown/More3DotDropdown';
import AddEditAssessment from './AddEditAssessment';
import styles from './assessment.module.scss';

const DataTable = (props) => {
  const { loading, isModal, assessments, total, page, setPage, limit, handleEdit, handleDelete } =
    props;
  return (
    <>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  No.
                </th>
                <th scope="col" className="text-center">
                  Assessment Type
                </th>
                <th scope="col">Date</th>
                <th scope="col" style={{ width: '45%' }}>
                  Recommendation Result
                </th>
                {isModal && <th scope="col"></th>}
              </tr>
            </thead>
            <tbody>
              {!loading && !!assessments && assessments.length > 0 ? (
                assessments?.map((data, idx) => (
                  <tr data-testid="list-assessment" key={data.id}>
                    <td className="text-center">{(page - 1) * limit + idx + 1}</td>
                    <td className="text-center">{data.type || '-'}</td>
                    <td>{data.dates ? moment(data.dates).format('D MMMM YYYY') : '-'}</td>
                    <td>{data.assessment_result || '-'}</td>
                    {isModal && (
                      <td data-testid="more">
                        <More3DotDropdown
                          item={data}
                          handleClickEdit={handleEdit}
                          handleClickDelete={handleDelete}
                        />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr data-testid="no-list-assessment" key="0" className={styles.tablePlanBody}>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      <div>
        {!loading && assessments && assessments.length > 0 && (
          <div data-testid="list-assessment-paging">
            <Pagination page={page} rowPerPages={4} totalResults={total} setPage={setPage} />
          </div>
        )}
      </div>
    </>
  );
};

const Assessment = (props) => {
  const { profileId, roleUser } = props;
  const { roleActive, permissions } = roleUser;
  const dispatch = useDispatch();
  const [listAssessment, setListAssessment] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(1);
  const limit = 4;

  const [loading, setLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);

  const isEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_ASSESSMENT_DATA);
  }, [permissions]);

  const handleClickAdd = () => {
    setDataEdit(null);
    setIsEdit(false);
    setOpenAddEdit(true);
    setOpenViewModal(false);
  };

  const handleClickEdit = (item) => {
    setDataEdit(item);
    setIsEdit(true);
    setOpenAddEdit(true);
    setOpenViewModal(false);
  };

  const handleClickDelete = (item) => {
    setDataEdit(item);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    try {
      const res = await talentProfileApi.deleteAssessmentRecord(dataEdit.id, roleUser);
      if (res.status === 200) {
        setPage(1);
        fetchApi();
        setOpenDelete(false);
        setOpenViewModal(false);
        dispatch(
          pushMessage({
            message: `Assessment Data successfully deleted`,
            type: 'success',
            timeShow: 0,
          })
        );
      }
    } catch (error) {}
  };

  const handleSubmitAddEdit = async (data, handleResetForm) => {
    const body = { staff_id: profileId, ...data };
    const handleSuccess = () => {
      handleResetForm();
      setOpenAddEdit(false);
      fetchApi();
      dispatch(
        pushMessage({
          message: `Assessment Data successfully ${isEdit ? 'edited' : 'added'}`,
          type: 'success',
          timeShow: 0,
        })
      );
    };
    try {
      if (isEdit) {
        const res = await talentProfileApi.editAssessmentRecord(dataEdit.id, body, roleUser);
        if (res.status === 200) {
          handleSuccess();
        }
      } else {
        const res = await talentProfileApi.addAssessmentRecord(body, roleUser);
        if (res.status === 201) {
          handleSuccess();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);
      const body = { page, limit };
      const response = await talentProfileApi.fetchAssessmentData({
        profileId,
        roleUser: { roleActive, permissions },
        body,
      });
      if (response.status === 200 && response.data.result) {
        setListAssessment(response.data.result?.profiles);
        setTotalResults(response.data.result?.totalAssessment);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, profileId, permissions, roleActive]);

  useEffect(() => {
    if (roleUser.roleActive) {
      fetchApi();
    }
  }, [roleUser.roleActive, fetchApi]);

  return (
    <>
      <div className={styles.SectionPlan}>
        <div className={styles.header}>
          <div className={styles.SectionName}>Assessment Data</div>
          {isEditPermission && (
            <img
              alt="btn_edit"
              src={edit_talent_profile}
              onKeyDown={() => {}}
              onClick={() => setOpenViewModal(true)}
            />
          )}
        </div>
        <DataTable
          assessments={listAssessment}
          total={totalResults}
          page={page}
          setPage={setPage}
          limit={limit}
          loading={loading}
        />
      </div>

      <CustomAntModal
        open={openViewModal}
        setOpen={setOpenViewModal}
        title={'Assessment Data'}
        width={1000}
        styles={{
          titleFontWeight: 600,
          titleColor: '#787587',
        }}
        hideDefaultBtn
      >
        <div className="d-flex justify-content-end">
          <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickAdd}>
            Add Assessment Data
          </BasicButton>
        </div>
        <DataTable
          isModal
          assessments={listAssessment}
          total={totalResults}
          page={page}
          setPage={setPage}
          limit={limit}
          handleEdit={handleClickEdit}
          handleDelete={handleClickDelete}
          loading={loading}
        />
      </CustomAntModal>

      <AddEditAssessment
        open={openAddEdit}
        setOpen={setOpenAddEdit}
        isEdit={isEdit}
        dataEdit={dataEdit}
        handleSubmit={handleSubmitAddEdit}
      />

      <ModelTC
        info={{
          type: 'deleteAssessmentData',
          visible: openDelete,
          setVisible: setOpenDelete,
          handleSubmit: () => handleDelete(),
          onClose: () => setOpenDelete(false),
        }}
      />
    </>
  );
};

export default Assessment;
