import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './searchPositionSp.module.scss';
import { fetchPositionsSPDashboard } from '../../../../services/successionplanningratio';
import { BasicInput } from '../../../../assets/common';

const SearchPositionSp = (props) => {
  const {
    placeholder,
    setPositionList,
    setLoading,
    setSearchPosition,
    businessUnit,
    approvedDate,
    searchParams,
    setSearchParams,
    limit,
    selectedDropdown,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const { roleActive, permissions } = useSelector((state) => state.user);

  const handleOnchange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const fetchAPI = useCallback(async () => {
    if (!searchParams.search && !searchParams.roleLevel.length && !searchParams.business.length)
      return;
    try {
      setLoading(true);
      let business = '';
      if (businessUnit.length > 0) {
        business = businessUnit.map((item) => `'${item.value}'`).join(',');
      }
      const response = await fetchPositionsSPDashboard({
        keyword: searchParams.search,
        isSearchSP: 1,
        isSearch: 1,
        roleId: roleActive.roleId,
        permissions,
        business,
        approvedDate,
        page: searchParams.page,
        limit,
        role_level_filter: searchParams?.roleLevel.map((item) => `'${item}'`).join(','),
        business_filter: searchParams?.business.map((item) => `'${item}'`).join(','),
      });
      setPositionList(response?.data?.result);
    } catch (error) {
      setPositionList({
        data: [],
        total: 0,
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approvedDate,
    businessUnit,
    limit,
    permissions,
    roleActive.roleId,
    searchParams,
    setLoading,
    setPositionList,
    selectedDropdown,
  ]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      setPositionList({ data: [], total: 0 });
      setSearchParams((prev) => ({ ...prev, page: 1, search: searchTerm }));
    }, 500);
    return () => clearTimeout(debounceSearch);
  }, [searchTerm, setSearchParams, setPositionList]);

  useEffect(() => {
    if (
      searchParams.search ||
      searchParams.roleLevel.length > 0 ||
      searchParams.business.length > 0
    ) {
      setSearchPosition(true);
    }
    if (
      !searchParams.search &&
      searchParams.roleLevel.length === 0 &&
      searchParams.business.length === 0
    ) {
      setSearchPosition(false);
    }
  }, [searchParams, setSearchParams, setSearchPosition]);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <div className={styles.search_positions_dsp} tabIndex={0} data-testid="search-positions-dsp">
      <BasicInput
        id="search"
        data-testid="search-input"
        name="search"
        aria-label="cost-input"
        placeholder={placeholder}
        className={styles.search_positions}
        onChange={handleOnchange}
        value={searchTerm}
        autoComplete="off"
      />
    </div>
  );
};

export default SearchPositionSp;
