import { utils, writeFile } from 'xlsx-js-style';

export const genCommonStyle = ({ style = 'thin', fontColor = 'fffff', bold = true, fontName }) => {
  return {
    border: {
      right: {
        style,
        color: '000000',
      },
      left: {
        style,
        color: '000000',
      },
      top: {
        style,
        color: '000000',
      },
      bottom: {
        style,
        color: '000000',
      },
    },
    font: {
      color: {
        rgb: fontColor,
      },
      bold,
      name: fontName,
    },
    alignment: {
      vertical: 'center',
      horizontal: 'center',
      wrapText: '1',
    },
  };
};

export const HISTORICAL_COMMON_STYLE = genCommonStyle({});

export const historicalExportDeclareWorkSheet = (arr) => {
  return [utils.aoa_to_sheet(arr), utils.book_new()];
};

export const historicalExportUnshiftHeader = (data, headersArr) => {
  headersArr.forEach((headers) => {
    data.unshift(headers);
  });
};

const createMerges = (shortHandMerges) => {
  const merges = [];
  for (let i = 0; i < shortHandMerges.length; i++) {
    const [r, c] = shortHandMerges[i];
    if (i % 2 === 0) {
      merges.push({
        s: { r: r, c: c },
        e: { r: shortHandMerges[i + 1][0], c: shortHandMerges[i + 1][1] },
      });
    }
  }
  return merges;
};

export const historicalExportSetMergeAndColWidth = (
  ws,
  shortHandMerges,
  length,
  width,
  indexAndWidthArr
) => {
  const merges = createMerges(shortHandMerges);
  ws['!merges'] = merges;
  const colWidth = new Array(length).fill({ width });
  colWidth[0] = { width: 30 };
  if (indexAndWidthArr) {
    indexAndWidthArr.forEach((item, index) => {
      colWidth[item[0]] = { width: item[1] };
    });
  }
  ws['!cols'] = colWidth;
};

export const historicalExportStyleAllCells = (ws, callBack) => {
  for (let i in ws) {
    if (typeof ws[i] != 'object') continue;

    ws[i].s = {
      ...HISTORICAL_COMMON_STYLE,
      font: {
        color: {
          rgb: '000000',
        },
      },
      alignment: {
        vertical: 'left',
        horizontal: 'left',
        wrapText: '1',
      },
    };
    if (callBack) callBack(i);
  }
};

export const historicalSetColorsAndWriteFile = (ws, wb, fileName, colorCellMapping, callBack) => {
  Object.keys(colorCellMapping).forEach((rgb) => {
    colorCellMapping[rgb].forEach((cell) => {
      ws[cell].s = {
        ...ws[cell].s,
        ...HISTORICAL_COMMON_STYLE,
        fill: { fgColor: { rgb } },
      };
    });
  });

  if (callBack) callBack();

  writeFile(wb, `${fileName}.xlsx`);
};
