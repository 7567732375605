import { Spin, Tooltip } from 'antd';
import { concat, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { BasicButton } from '../../../assets/common';
import { tooltip_green_icon } from '../../../assets/img';
import { AlertMessage, BreadCrumb } from '../../../components';
import AlertNotification from '../../../components/AlertMessage/AlertNotification';
import mobilitySimulationApi from '../../../services/mobilitySimulationApi';
import {
  MESSAGE_TYPES,
  MS_AGENDA_DISCLAIMER,
  SP_NOTES_PERFORMANCE_RATING,
} from '../../../utils/constants';
import { convertPositionSimulation, convertTalentSimulation } from '../../../utils/helper';
import MobilityChainSimulation from '../components/MobilityChainSimulation/MobilityChainSimulation';
import MobilityForm from '../components/MobilityForm/MobilityForm';
import { Legend } from '../components/MobilityLegend/style';
import {
  ENDORSED_STATUS,
  MOBILITY_BREADCRUMB_LIST,
  MOBILITY_FORM_MODE,
  MOBILITY_MESSAGE,
} from '../constants';
import * as styles from './mobility-simulation-view.module.scss';

const MobilitySimulationView = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataChain, setDataChain] = useState([]);
  const [msg, setMsg] = useState({});
  const [showNote, setShowNote] = useState(false);

  const handleBack = () => {
    history.push('/mobility-simulation');
  };

  const handleDuplicate = () => {
    history.push(`/mobility-simulation/${id}/duplicate`);
  };

  const handleEdit = () => {
    history.push(`/mobility-simulation/${id}/edit`);
  };

  const fetchMobilityDetail = async (id) => {
    try {
      setLoading(true);
      const res = await mobilitySimulationApi.getMobilityDetail(id, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result);
        let positions = [];
        let talents = [];
        if (!isEmpty(res.data.result.position)) {
          positions = res.data.result.position.map((item) => {
            return convertPositionSimulation(item);
          });
        }
        if (!isEmpty(res.data.result.talents)) {
          talents = res.data.result.talents.map((item) => {
            const obj = convertTalentSimulation(item);
            return {
              ...obj,
              years_in_position: item.year_in_position || 'N/A',
              years_in_sg: item.year_in_sg || 'N/A',
            };
          });
        }
        setDataChain(concat(positions, talents));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMobilityDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const message = location?.state?.message;
    if (!message) return;
    if (message === 'add-success') {
      history.replace();
      return setMsg({
        type: 'success',
        message: MOBILITY_MESSAGE.MOBILITY_SAVE_SUCCESSFUL,
        onClose: () => setMsg({}),
        timeShow: 5000,
      });
    }
  }, [history, location]);

  return (
    <div className="max-width">
      <BreadCrumb level={2} breadCrumbList={MOBILITY_BREADCRUMB_LIST} />
      {!isEmpty(msg) && (
        <div style={{ marginBottom: '20px' }}>
          <AlertMessage {...msg} />
        </div>
      )}
      <AlertNotification
        style={{ marginBottom: '20px' }}
        message={SP_NOTES_PERFORMANCE_RATING}
        type={MESSAGE_TYPES.NORMAL}
        show={showNote}
        setShow={setShowNote}
      />
      <h3 className={styles.title}>Mobility Simulation</h3>
      <div className={styles.buttonPosition}>
        <BasicButton onKeyDown={() => {}} onClick={handleBack}>
          Back
        </BasicButton>
        {ENDORSED_STATUS.includes(data?.status) ? (
          <BasicButton onKeyDown={() => {}} onClick={handleDuplicate}>
            Duplicate Mobility Simulation
          </BasicButton>
        ) : (
          <BasicButton onKeyDown={() => {}} onClick={handleEdit}>
            Edit Mobility Simulation
          </BasicButton>
        )}
      </div>
      <Spin spinning={loading} size="large">
        <MobilityForm mode={MOBILITY_FORM_MODE.VIEW} data={data} />
        <div className={styles.section}>
          <div className="d-flex justify-content-between mb-4">
            <div className={styles.titleDetail}>
              {data?.name}
              <span onKeyDown={() => {}} onClick={() => setShowNote(true)}>
                <Tooltip
                  className={styles.customTooltip}
                  overlayStyle={{ minWidth: '317px' }}
                  title={
                    <div className={styles.tooltipContent}>
                      <span>{MS_AGENDA_DISCLAIMER[0].label}</span>
                    </div>
                  }
                  color={'white'}
                  key={'white'}
                  overlayInnerStyle={{ borderRadius: '6px' }}
                >
                  <img className={styles.tooltipIcon} src={tooltip_green_icon} alt="" />
                </Tooltip>
              </span>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '20px' }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <Legend color="#FEDC92" />
                <span
                  className={styles.mobilitySimulationLegendLabel}
                  style={{ marginLeft: '5px' }}
                >
                  Approved Succession Planning
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Legend color="#ECF3C1" />
                <span
                  className={styles.mobilitySimulationLegendLabel}
                  style={{ marginLeft: '5px' }}
                >
                  AI Recommendation
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Legend color="#BDB6EA" />
                <span
                  className={styles.mobilitySimulationLegendLabel}
                  style={{ marginLeft: '5px' }}
                >
                  Direct Reports
                </span>
              </div>
            </div>
          </div>
          <MobilityChainSimulation data={dataChain} />
        </div>
      </Spin>
    </div>
  );
};

export default MobilitySimulationView;
