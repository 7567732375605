export const auditLogExportColumns = [
  {
    title: 'No.',
  },
  {
    title: 'Action',
  },
  {
    title: 'Module',
  },
  {
    title: 'Old Value',
  },
  {
    title: 'New Value',
  },
  {
    title: 'Action At',
  },
  {
    title: 'Action By',
  },
];

export const UserActivityLogExportColumns = [
  {
    title: 'No.',
  },
  {
    title: 'Email',
  },
  {
    title: 'Type',
  },
  {
    title: 'URL',
  },
  {
    title: 'Date & Time',
  },
];
