import styled from 'styled-components';

export const TooltipDesc = styled.div`
  font-family: 'Inter', sans-serif;
  color: var(--Base-White, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
