import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import 'moment/locale/en-gb'; // important!
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import ReactDOM from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { init as initApm } from '@elastic/apm-rum';
import App from './App';
import './assets/scss/style.scss';
import { msalInstance } from './msalConfig';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store';

try {
  const apm = initApm({
    serviceName: 'MyTalentX-fe',
    serverUrl: process.env.REACT_APP_ELASTIC_SERVER_URL,
    environment: process.env.REACT_APP_ELASTIC_ENV,
    distributedTracingOrigins: [process.env.REACT_APP_API_URL],
    propagateTracestate: true,
  });
  apm.addLabels({ application: 'MyTalentX-fe' });
} catch (error) {
  console.error(error);
}

moment.locale('en-gb');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={en_GB}>
        <App instance={msalInstance} />
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// });
