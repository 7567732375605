import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 600;

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
    border-top: none !important;
    width: 290px;
  }
  .ant-collapse {
    display: flex;
    justify-content: space-between;
  }
  .ant-collapse-header {
    font-size: 18px !important;
    font-weight: 700;
  }

  .add-more {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    margin-top: 10px;
    color: #3f3c4c;
    text-decoration: underline;
  }

  .container-popup {
    position: relative;
  }

  .meeting-path {
    transform: translate(-36px, -50%);
    position: absolute;
    top: -200px;
  }
`;
