import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-data-export';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { BasicButton } from '../../../../../../assets/common';
import { returnDataToExportViewResult } from '../../../../../../utils/helper';
import * as styles from './menu-bar-view-result-matching.module.scss';
import { VIEW_RESULT_MATCHING_EXPORT_COLUMNS } from './view-result-export.constant';

const MenuBarViewResultMatching = ({
  searchTerm,
  setSearchTerm,
  exportData,
  setIsExport,
  loadingExport,
  setPage,
}) => {
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const history = useHistory();
  const { positionCode } = useParams();
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const fileName = `Best Fit Recommendation for ${positionCode || ''} ${
    exportData?.set_name || ''
  }`;
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  useEffect(() => {
    setSearchTerm({ type: 'search', value: debouncedInputValue });
    setPage(1);
  }, [debouncedInputValue, setSearchTerm, setPage]);

  const handleBack = () => {
    if (searchParams?.type === 'manual') {
      history.push(`/admin/sp-management/managing-criteria-for-critical-position?type=2`);
    } else {
      history.push(`/admin/sp-management/edit-criteria/${positionCode}`);
    }
  };

  const generateDataSet = () => {
    const tempDataExport = returnDataToExportViewResult(exportData?.result);
    return [
      {
        columns: [...VIEW_RESULT_MATCHING_EXPORT_COLUMNS],
        data: [...tempDataExport],
      },
    ];
  };

  return (
    <div className={styles.menuWrapper}>
      <div style={{ width: '416px' }} className={styles.searchBox}>
        <input
          name={`searchPosition`}
          placeholder={'Search by Staff Name and Staff ID'}
          className={`${styles.searchInput}`}
          onChange={(e) => handleInputChange(e)}
          value={searchTerm.showTerm}
          autoComplete="off"
        />
      </div>

      <div className={styles.groupBtn} style={{ width: '220px' }}>
        <BasicButton style={{ minWidth: '100px', height: '36px' }} onClick={() => handleBack()}>
          Back
        </BasicButton>
        <BasicButton
          mode="teal"
          style={{ minWidth: '100px', height: '36px' }}
          onClick={() => setIsExport(true)}
          disabled={loadingExport}
        >
          Export
        </BasicButton>
        {!loadingExport && !isEmpty(exportData?.result) && (
          <ExcelFile hideElement={true} filename={fileName}>
            <ExcelSheet dataSet={generateDataSet()} name={fileName} />
          </ExcelFile>
        )}
      </div>
    </div>
  );
};

export default MenuBarViewResultMatching;
