import { createSlice } from '@reduxjs/toolkit';

const calculateLessThen3FirstLine = (ratio) => {
  let total = 0;
  ratio.firstLineSuccessors.map((item) => {
    if (item.noSuccessors) {
      total += item.noSuccessors;
    }
    if (item.lessThanTwoSuccessors) {
      total += item.lessThanTwoSuccessors;
    }
    return null;
  });
  return Number(total);
};
const spRatioSlice = createSlice({
  name: 'spRatio',
  initialState: {
    ratioEKP: {},
    ratioBKP: {},
  },
  reducers: {
    addSPTypeEKB: (state, action) => {
      let result = calculateLessThen3FirstLine(action.payload);
      state.ratioEKP = { ...action.payload, lessthan3firstLine: result };
    },
    addSPTypeBKP: (state, action) => {
      let result = calculateLessThen3FirstLine(action.payload);
      state.ratioBKP = { ...action.payload, lessthan3firstLine: result };
    },
  },
});
export const { reducer: spRatioReducer, actions } = spRatioSlice;
export const { addSPTypeEKB, addSPTypeBKP } = actions;
export default spRatioReducer;
