import { Spin } from 'antd';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '../../../../../components';
import Input from '../Input/Input';
import styles from './popup-save-version.module.scss';

const PopupSaveVersion = (props) => {
  const { name, setName, show, setShow, onSaveVersion, loadingSaveVersion } = props;
  const [isValid, setIsValid] = useState(true);

  const onSave = async () => {
    if (!name) {
      setIsValid(false);
    } else {
      await onSaveVersion(name);
    }
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={styles.save_version}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div data-testid="modal-save-version-title">
            <p className={styles.title}>Save version</p>
            <span className={styles.subTitle}>
              Please take note that this version will be saved as main SP template
            </span>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.modal_body}>
          <div className={`mb-1 ${styles.label_input}`}>Name</div>
          <div className="row" style={{ alignItems: 'baseline' }}>
            <div className="col-9">
              <Input
                style={{ border: !isValid && !name && '1px solid #f85032' }}
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              {!isValid && !name && (
                <div className={`mt-1 ${styles.text_error}`}>Name is required</div>
              )}
            </div>
            <Spin spinning={loadingSaveVersion} delay={500}>
              <div className="col-3">
                <Button data-testid="popup-btn-save" className={styles.btn_save} onClick={onSave}>
                  Save
                </Button>
              </div>
            </Spin>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PopupSaveVersion;
