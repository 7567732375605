import { Spin } from 'antd';
import { concat, get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BasicButton } from '../../../../assets/common';
import { mobility_endorsed, mobility_simulation_not_found } from '../../../../assets/img';
import mobilitySimulationApi from '../../../../services/mobilitySimulationApi';
import { NOTIFICATION_TYPE } from '../../../../utils/constants';
import { convertPositionSimulation, convertTalentSimulation } from '../../../../utils/helper';
import { showNotification } from '../../../../utils/notification';
import {
  ENDORSED_STATUS,
  MOBILITY_LIST_EXISTED_COLUMNS,
  MOBILITY_SIMULATION,
} from '../../constants';
import { ModalMobilityPreview } from '../../MobilitySimulationAdd/styled';
import { TableCheckbox } from '../MobilityListView/styled';
import * as styles from './mobility-agenda-add-exist.module.scss';

const MobilityAgendaAddExist = (props) => {
  const {
    open,
    setOpen,
    setMobilitySimulationIds,
    mobilitySimulationIds,
    setCreatedMS,
    setIsCreateOrEdit,
    removeItemOnChain,
    setLoadingPage,
    loadingPage,
    setIsHighlighted,
  } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const [search, setSearch] = useState({ keyword: '', isSearch: true });
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(0);
  const defaultPageSize = 10;

  const fetchListMobilities = async (query) => {
    try {
      setLoadingPage(true);
      const res = await mobilitySimulationApi.getList(query, roleActive.roleId);
      if (res.status === 200) {
        setData(res.data.result.mobilitity_simulations);
        setTotalRecord(res.data.result.total);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingPage(false);
  };

  const handleSearch = () => {
    setSearch({ ...search, isSearch: true });
  };

  const handleConfirmAdd = () => {
    let isAdd = true;
    mobilitySimulationIds.forEach((b) => {
      if (selected.includes(b.id)) {
        let name = data.filter((x) => x.mbsm_id === b.id)[0].name;
        showNotification(`${name} already exists`, NOTIFICATION_TYPE.FAILED);
        isAdd = false;
      }
    });
    if (isAdd) {
      setMobilitySimulationIds((prev) => {
        const simulationIds = selected.map((id, idx) => {
          return { id, is_main: false };
        });
        return [...prev, ...simulationIds];
      });
      setOpen(false);
      setSelected([]);
    }
  };

  const handleOnChangePagination = (page) => {
    setPage(page);
    fetchListMobilities({
      page,
      limit: defaultPageSize,
      keyword: search.keyword,
      from: MOBILITY_SIMULATION.CREATED_FROM_AGENDA,
    });
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys: [...selected],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      if (mobilitySimulationIds.length + selected.length === 3) {
        return {
          disabled: !selected.includes(record.key),
        };
      } else {
        return {
          disabled: ENDORSED_STATUS.includes(record.status),
          name: record.mbsm_id,
        };
      }
    },
  };

  const paginationConfig = {
    defaultPageSize: defaultPageSize,
    total: totalRecord,
    current: page,
    onChange: (page) => {
      handleOnChangePagination(page);
    },
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  useEffect(() => {
    if (search.isSearch) {
      fetchListMobilities({
        page: 1,
        limit: defaultPageSize,
        keyword: search.keyword,
        from: MOBILITY_SIMULATION.CREATED_FROM_AGENDA,
      });
      setPage(1);
      setSearch({ ...search, isSearch: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setSearch]);

  const handleAddMobilitySimulation = async (data) => {
    const mbsm_id = data.mbsm_id;
    const id = mobilitySimulationIds.find((x) => x.id === mbsm_id);
    if (id) return showNotification('This mobility simulation added', NOTIFICATION_TYPE.FAILED);
    if (!ENDORSED_STATUS.includes(data.status)) {
      setMobilitySimulationIds((prev) => {
        const simulationIds = { id: data.mbsm_id, is_main: false };
        return [...prev, simulationIds];
      });
      setOpen(false);
      setSelected([]);
    } else {
      try {
        setLoadingPage(true);
        const res = await mobilitySimulationApi.getMobilityDetail(data.mbsm_id, roleActive.id);
        let positions = [];
        let talents = [];
        if (!isEmpty(res.data.result.position)) {
          positions = res.data.result.position.map((item) => {
            return convertPositionSimulation(item);
          });
          const lastPosition = positions[positions.length - 1];
          setIsHighlighted({ key: lastPosition?.key || 0, position_code: lastPosition?.position_code || 0 });
        }
        if (!isEmpty(res.data.result.talents)) {
          talents = res.data.result.talents.map((item) => {
            return convertTalentSimulation(item);
          });
        }
        setCreatedMS({
          isValidate: true,
          isShowMessage: {},
          name: 'Mobility Simulation',
          dataChain: concat(positions, talents).map((item) => {
            return {
              ...item,
              isRemove: true,
              isDisableIndex: true,
              removeItemOnChain: removeItemOnChain,
            };
          }),
          hrPartners: get(res, 'data.result.hr_partners')
            ? get(res, 'data.result.hr_partners').map((item) => {
                return { label: item.name, id: item.id, value: item.id };
              })
            : [],
        });
        setIsCreateOrEdit(true);
        setOpen(false);
      } catch (error) {
        console.error(error);
        showNotification('Something went wrong', NOTIFICATION_TYPE.FAILED);
      } finally {
        setLoadingPage(false);
      }
    }
  };

  return (
    <ModalMobilityPreview
      title="Add Existing Mobility Simulation"
      centered
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      destroyOnClose={true}
      width={1345}
    >
      <div className={styles.controlBar}>
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="Search Mobility Simulation"
            className={styles.searchInput}
            onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            value={search.keyword}
          />
          <BasicButton mode="teal" onClick={handleSearch}>
            Search
          </BasicButton>
        </div>
        <div className={styles.controlBtn}>
          <BasicButton mode="teal" onClick={handleConfirmAdd} disabled={!selected.length}>
            Confirm add Mobility Simulation
          </BasicButton>
        </div>
      </div>
      {!isEmpty(data) ? (
        <Spin spinning={loadingPage} size="large">
          <TableCheckbox
            columns={MOBILITY_LIST_EXISTED_COLUMNS}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            dataSource={data.map((d) => {
              return {
                key: d.mbsm_id,
                status: d.status,
                name: (
                  <div className={styles.simulation_name}>
                    <span>{d.name}</span>
                    {ENDORSED_STATUS.includes(d.status) && (
                      <img className={styles.endorsed} src={mobility_endorsed} alt="endorsed" />
                    )}
                  </div>
                ),
                last_updated_by: <div className={styles.last_update_by}>{d.last_updated_by}</div>,
                last_updated_at: (
                  <div className={styles.last_update_date}>
                    {moment(d.last_updated_at).format('D MMMM YYYY')}
                  </div>
                ),
                view: (
                  <BasicButton
                    className={styles.addMobilitySimulation}
                    onClick={() => {
                      setOpen(false);
                      handleAddMobilitySimulation(d);
                    }}
                  >
                    {ENDORSED_STATUS.includes(d.status)
                      ? 'Duplicate Mobility Simulation'
                      : 'Add Mobility Simulation'}
                  </BasicButton>
                ),
              };
            })}
            className={styles.detail_list_table}
            pagination={{ position: ['bottomRight'], ...paginationConfig }}
          />
        </Spin>
      ) : (
        <div className={`${styles.not_found} position-relative`}>
          <div className="center-loading">
            <Spin spinning={loadingPage} size="large"></Spin>
          </div>
          <img
            src={mobility_simulation_not_found}
            className={styles.notFound}
            alt="talent_finder_not_found"
            style={loadingPage ? { opacity: '0.3' } : null}
          />
        </div>
      )}
    </ModalMobilityPreview>
  );
};

export default MobilityAgendaAddExist;
