import styled from 'styled-components';
import { font } from '../../assets/common';

const Dropdown = styled.div`
  margin: 24px 0;
  font-family: ${font.inter};
  font-size: 12px;
  font-weight: 500;

  .ant-select {
    width: ${(props) => props.size?.width || '200px'};
  }

  .ant-select-selector {
    height: ${(props) => `${props.size?.height || '45px'} !important`};
    border-radius: 6px !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    line-height: ${(props) => `${props.size?.height || '45px'} !important`};
  }
`;

export { Dropdown };
