import React, { useEffect } from 'react';

import { icon_after } from '../../../../../assets/img';
import styles from './pill.module.scss';

const types = {
  POSITION_LABEL: 'POSITION_LABEL',
  BUSINESS: 'BUSINESS',
};
export default function Pill({
  showFilter,
  positionLabelSelecteds = [],
  setPositionLabelSelecteds,
  businessSelecteds = [],
  setBusinessSelecteds,
}) {
  useEffect(() => {
    setPositionLabelSelecteds([]);
    setBusinessSelecteds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter]);

  const onRemove = (type, value) => {
    if (type === types.POSITION_LABEL) {
      const remain = positionLabelSelecteds.filter((item) => item.value !== value);
      setPositionLabelSelecteds(remain);
      return;
    }
    const remain = businessSelecteds.filter((item) => item.value !== value);
    setBusinessSelecteds(remain);
  };
  const onRemoveAll = (type) => {
    if (type === types.POSITION_LABEL) {
      setPositionLabelSelecteds([]);
      return;
    }
    setBusinessSelecteds([]);
  };
  return (
    <div
      style={{ marginTop: positionLabelSelecteds.length + businessSelecteds.length > 0 ? 17 : 0 }}
    >
      {!positionLabelSelecteds.find((item) => item.value === 'all') &&
        positionLabelSelecteds.map((item, idx) => {
          return (
            <span key={idx} className={styles.pillItem}>
              <span>{item.label}</span>
              <img
                alt=""
                data-testid="remove-btn-pos"
                src={icon_after}
                onKeyDown={() => {}}
                onClick={() => onRemove(types.POSITION_LABEL, item.value)}
              />
            </span>
          );
        })}
      {positionLabelSelecteds.find((item) => item.value === 'all') && (
        <span className={styles.pillItem}>
          <span>Position Label - All</span>
          <img
            alt=""
            data-testid="remove-all-btn-pos"
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() => onRemoveAll(types.POSITION_LABEL)}
          />
        </span>
      )}
      {businessSelecteds.find((item) => item.value === 'all') && (
        <span className={styles.pillItem}>
          <span>Business - All</span>
          <img
            alt=""
            data-testid="remove-all-btn-bus"
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() => onRemoveAll(types.BUSINESS)}
          />
        </span>
      )}
      {!businessSelecteds.find((item) => item.value === 'all') &&
        businessSelecteds.map((item, idx) => {
          return (
            <span
              key={idx}
              className={styles.pillItem}
              onKeyDown={() => {}}
              onClick={() => onRemove(types.BUSINESS, item.value)}
            >
              <span>{item.label}</span>
              <img alt="" data-testid="remove-btn-bus" src={icon_after} />
            </span>
          );
        })}
    </div>
  );
}
