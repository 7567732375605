import styled from 'styled-components';
import { font } from '../../../../assets/common';
import { Table } from 'antd';

const GeneralTable = styled(Table)`
  width: 100%;

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.235px;
        color: #8896a7;
        padding: 16px 12px;
        background: transparent;

        &:first-child {
          border-right: none;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 12px;

        span {
          font-family: ${font.inter};
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.235px;
          white-space: pre-wrap;
          color: var(--Light-Gray-800, #3d3d3d);
        }

        &:first-child {
          border-right: none;
        }
      }
    }
  }
`;

const PersonalityArchetypesTable = styled(Table)`
  width: 100%;

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.235px;
        color: #8896a7;
        padding: 16px 12px;
        background: transparent;

        &:first-child {
          border-right: none;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 12px;
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.235px;
        white-space: pre-wrap;
        color: var(--Light-Gray-800, #3d3d3d);

        &:first-child {
          border-right: none;
        }
      }
    }
  }

  .strongest-item {
    background: var(--Light-Primary-50, #f4fbfb);

    td {
      border-top: 1px solid var(--Light-Blue-Grays-200, #e7edf3);
    }

    span {
      font-weight: 700 !important;
      color: var(--Light-Gray-900, #292929) !important;
    }
  }
`;

const LearningAgilitiesTable = styled(Table)`
  width: 100%;

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.235px;
        color: #8896a7;
        padding: 16px 12px;
        background: transparent;
        border-right: solid 1px #e7edf3;

        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 12px;
        border-right: solid 1px #e7edf3;
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.235px;
        white-space: pre-wrap;
        color: var(--Light-Gray-800, #3d3d3d);
      }
    }
  }

  .border-right-none {
    border-right: none !important;
  }
`;

const DriversTable = styled(Table)`
  width: 100%;

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.235px;
        color: #8896a7;
        padding: 16px 12px;
        background: transparent;
        border-right: solid 1px #e7edf3;

        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 12px;
        border-right: solid 1px #e7edf3;
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.235px;
        white-space: pre-wrap;
        color: var(--Light-Gray-800, #3d3d3d);
      }
    }
  }

  .border-right-none {
    border-right: none !important;
  }
`;

const DerailmentRisksTable = styled(Table)`
  width: 100%;

  .ant-table-thead {
    tr {
      th {
        font-family: ${font.inter};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.235px;
        color: #8896a7;
        padding: 16px 12px;
        background: transparent;
        border-right: solid 1px #e7edf3;

        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 12px;
        border-right: solid 1px #e7edf3;

        span {
          font-family: ${font.inter};
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.235px;
          white-space: pre-wrap;
          color: var(--Light-Gray-800, #3d3d3d);
        }
      }
    }
    
    .group-top{
      td{
        border-bottom: 3px solid #CBD6E2;
      }
    }
  }

  .border-right-none {
    border-right: none !important;
  }
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TooltipTitle = styled.h3`
  font-family: 'Inter', sans-serif;
  color: var(--Base-White, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 5px;
`;

const TooltipDesc = styled.div`
  font-family: 'Inter', sans-serif;
  color: var(--Base-White, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NormalText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.235px;
  margin-right: 10px;
`;

const StatusBgd = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background: ${(props) => (props.bgdColor ? `${props.bgdColor}` : '#D6D6D6')};
`;

const AssessmentStatus = styled.div`
  margin-left: 12px;
  min-width: 120px;

  .status-title {
    color: var(--Light-Gray-700, #575757);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .status-content {
    display: flex;
    align-items: center;
  }
  .status-label {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
  .status-bgd {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-right: 10px;
  }
`;

export {
  GeneralTable,
  LearningAgilitiesTable,
  DriversTable,
  FieldWrap,
  TooltipTitle,
  TooltipDesc,
  FieldItemWrap,
  NormalText,
  StatusBgd,
  DerailmentRisksTable,
  PersonalityArchetypesTable,
  AssessmentStatus,
};
