import { Radio, Space, notification } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BasicButton,
  BasicInput,
  BasicLabel,
  BasicLoading,
  BasicRadio,
  BasicTable,
} from '../../../../../../assets/common';
import { avatar } from '../../../../../../assets/img';
import {
  decodeBase64ToImage,
  getTalentStatusIcon,
  paginationConfig,
} from '../../../../../../utils/helper';
import { updateTalents } from '../../../../redux/agendaSlice';
import * as styles from './add-talent.module.scss';

const FILTER_SEARCH_RESULT = [
  { label: 'Show only exact match', value: 'matching_result' },
  { label: 'Show all phrase/ partial match', value: 'related_result' },
];
const INIT_SEARCH_PARAM = {
  page: 1,
  staff_id: '',
  staff_name: '',
  position_code: '',
  position_name: '',
  filter_search_result: FILTER_SEARCH_RESULT[0].value,
  searching: false,
};

const AdvancedSearchTalent = (props) => {
  const { limit, fetchData, currentLine, setIsVisible } = props;
  const dispatch = useDispatch();
  const { first_line, second_line, third_line } = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent
  );
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAM);
  const [data, setData] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: 'No',
        width: '11%',
        render: (_, record, index) => (
          <div className={styles.no}>
            <span>{(searchParams.page - 1) * limit + index + 1}</span>
            <div className={styles.talentStatus}>
              <img src={decodeBase64ToImage(record.image) || avatar} alt="avatar" />
              <img src={getTalentStatusIcon(record.assignment_status_type)} alt="talent_status" />
            </div>
          </div>
        ),
      },
      {
        title: 'Name/ Staff ID',
        width: '20%',
        dataIndex: 'category',
        render: (_, record) => (
          <div>
            <div className={styles.name}>{record.birth_name}</div>
            <div className={styles.staff_id}>{record.staff_id}</div>
          </div>
        ),
      },
      {
        title: 'Position Name',
        width: '14%',
        dataIndex: 'position_name',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Department',
        width: '20%',
        dataIndex: 'department_name',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Company/ OPU',
        width: '20%',
        dataIndex: 'opu',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Business Unit',
        width: '20%',
        dataIndex: 'business_unit',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
    ],
    [limit, searchParams.page]
  );

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedIds(selectedRows.map((item) => item.staff_id));
    },
    selectedRowKeys: selectedIds,
  };

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum, searching: true }));
    };
    return paginationConfig(data?.[0]?.total, searchParams.page, onChange, limit);
  }, [data, limit, searchParams.page]);

  const handleChangeField = (fieldName, value) => {
    setSearchParams((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleApply = () => {
    setSearchParams((prevState) => ({ ...prevState, page: 1, searching: true }));
  };

  const handleReset = () => {
    setSearchParams(INIT_SEARCH_PARAM);
    setData(null);
  };

  const handleAdd = () => {
    const allTalents = [...(first_line || []), ...(second_line || []), ...(third_line || [])];
    const existTalentIds = allTalents
      .filter((talent) => selectedIds.includes(talent.staff_id))
      .map((talent) => talent.staff_id);
    if (existTalentIds.length === selectedIds.length) {
      notification.error({
        message: `Talent is already in SP readiness table.`,
        placement: 'topRight',
        duration: 2,
      });
    } else {
      const addingTalentIds = selectedIds.filter((id) => !existTalentIds.includes(id));
      const addingTalents = data.filter((talent) => addingTalentIds.includes(talent.staff_id));

      switch (currentLine) {
        case '1st':
          const newLines1 = [...first_line, ...addingTalents];
          dispatch(updateTalents({ first_line: newLines1, second_line, third_line }));
          break;
        case '2nd':
          const newLines2 = [...second_line, ...addingTalents];
          dispatch(updateTalents({ first_line, second_line: newLines2, third_line }));
          break;
        case '3rd':
          const newLines3 = [...third_line, ...addingTalents];
          dispatch(updateTalents({ first_line, second_line, third_line: newLines3 }));
          break;
        default:
          break;
      }

      notification.success({
        message: `Add successful.`,
        placement: 'topRight',
        duration: 2,
      });
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (!searchParams.searching) return;
    fetchData({ ...searchParams, limit }, setData, setLoading, 1);
    setSearchParams((prev) => ({ ...prev, searching: false }));
  }, [fetchData, limit, searchParams]);

  return (
    <BasicLoading spinning={loading}>
      <div className={styles.advanced}>
        <div className={styles.search}>
          <h5>Advanced Search</h5>
          <div className={styles.inputRow}>
            <div>
              <BasicLabel>Staff Name</BasicLabel>
              <BasicInput
                placeholder="Enter Name"
                value={searchParams.staff_name}
                onChange={(event) => {
                  handleChangeField('staff_name', event.target.value);
                }}
              />
            </div>
            <div>
              <BasicLabel>Staff ID</BasicLabel>
              <BasicInput
                placeholder="Enter Staff ID"
                value={searchParams.staff_id}
                onChange={(event) => {
                  handleChangeField('staff_id', event.target.value);
                }}
              />
            </div>
            <div>
              <BasicLabel>Position Code</BasicLabel>
              <BasicInput
                placeholder="Enter Position Code"
                value={searchParams.position_code}
                onChange={(event) => {
                  handleChangeField('position_code', event.target.value);
                }}
              />
            </div>
            <div>
              <BasicLabel>Position Name</BasicLabel>
              <BasicInput
                placeholder="Enter Position Name"
                value={searchParams.position_name}
                onChange={(event) => {
                  handleChangeField('position_name', event.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.optionsRow}>
            <div>
              <BasicLabel>Staff Name Search Result</BasicLabel>
              <BasicRadio
                value={searchParams.filter_search_result}
                onChange={(event) => {
                  handleChangeField('filter_search_result', event.target.value);
                }}
              >
                <Space direction="vertical">
                  {FILTER_SEARCH_RESULT.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Space>
              </BasicRadio>
            </div>
            <div className={styles.buttonRows}>
              <BasicButton onClick={handleReset}>Reset</BasicButton>
              <BasicButton mode="teal" onClick={handleApply}>
                Apply
              </BasicButton>
            </div>
          </div>
        </div>
        {data && (
          <div className={styles.result}>
            <h5>Search Result:</h5>
            <BasicTable
              rowKey="staff_id"
              rowSelection={rowSelection}
              dataSource={data}
              columns={columns}
              pagination={pagination}
              styles={{ selectPadding: 23 }}
            />
          </div>
        )}
      </div>
      {data && (
        <div className="d-flex justify-content-end pt-3">
          <BasicButton mode="teal" onClick={handleAdd} disabled={isEmpty(selectedIds)}>
            Add
          </BasicButton>
        </div>
      )}
    </BasicLoading>
  );
};

export default AdvancedSearchTalent;
