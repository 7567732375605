import styled from 'styled-components';
import { color, font, fontSize } from '../../../../assets/common';

export const AiAgendaForm = styled.div`
  background-color: white;
  margin-bottom: 24px;
  border-radius: 6px;
`;
export const AiTalentForm = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid #d3dee8;
  border-radius: 6px;
  margin-top: 32px;
`;
export const AiTitle = styled.p`
  font-family: ${font.inter};
  font-size: ${fontSize.sizable};
  font-weight: 700;
  color: ${color.brightGray2};
`;

export const AiContent = styled.div`
  font-family: ${font.inter};
  font-weight: 400;
  font-size: ${fontSize.normal};
  padding-bottom: 8px;
`;

export const AiSPContent = styled.div`
  font-family: ${font.inter};
  font-weight: 400;
  font-size: ${fontSize.normal};
  padding-bottom: 8px;
`;
