import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BasicButton, BasicInput } from '../../../../../assets/common';

const MenuBarCopySP = ({ keyword, setKeyword, fetchData }) => {
  const [searchInput, setSearchInput] = useState('');
  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={5}>
            <Col span={18}>
              <BasicInput
                id="position"
                data-testid="position-input"
                name="position"
                placeholder="Search Succession Planning"
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                autoComplete="off"
              />
            </Col>
            <Col>
              <BasicButton
                mode="teal"
                onClick={() => {
                  keyword === searchInput ? fetchData() : setKeyword(searchInput);
                }}
              >
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

MenuBarCopySP.propTypes = {
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  fetchData: PropTypes.func,
};

export default MenuBarCopySP;
