import queryString from 'query-string';
import axiosClient from './axiosClient';

export const masterDataManagementApi = {
  getBusinessUnitList: (role, query) => {
    const stringified = queryString.stringify(query);
    const url = `/business-units/search?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  deleteBusinessUnit: (ids, role) => {
    const url = `/business-units/delete`;
    return axiosClient.delete(url, {
      headers: {
        role: role.roleId,
      },
      params: {
        bu_ids: ids.join(','),
      },
    });
  },

  addBusinessUnit: (body, role) => {
    const url = `/business-units/add`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  updateBusinessUnit: (body, id, role) => {
    const url = `/business-units/${id}/update`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  // BU/OPU/Division Mapping APIs
  getBuMappingFilters: (params, roleId) => {
    const request = `${params
      ?.map((item) => {
        return `fields[]=${item}`;
      })
      ?.join('&')}`;
    const url = `/admin/bu-mapping/filters?${request}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getBuMapping: (body, roleId) => {
    const url = `/admin/bu-mapping/list`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getBusinessOptions: (roleId) => {
    const url = `/admin/bu-mapping/options`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  createBuMapping: (body, roleId) => {
    const url = `/admin/bu-mapping/create`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  updateBuMappingById: (body, roleId) => {
    const url = `/admin/bu-mapping/${body.id}/update`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteBuMapping: (ids, roleId) => {
    const request = `${ids
      ?.map((id) => {
        return `id[]=${id}`;
      })
      ?.join('&')}`;
    const url = `/admin/bu-mapping/delete?${request}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
    });
  },

  // Function List APIs
  getFunctionListFilter: (roleId) => {
    const url = `/admin/function/filters`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getFunctionList: (body, roleId) => {
    const url = `/admin/function/list`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteFunctionList: (ids, roleId) => {
    const request = `${ids
      ?.map((id) => {
        return `id[]=${id}`;
      })
      ?.join('&')}`;
    const url = `/admin/function/delete?${request}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
    });
  },

  addFunctionList: (body, roleId) => {
    const url = `/admin/function/add-record`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  editFunctionList: (body, roleId) => {
    const url = `/admin/edit-func-list/${body?.id}`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getProfileList: (params, roleId) => {
    const { page, limit, keyword, role_level, business } = params;
    const request = `page=${page}&limit=${limit}&keyword=${keyword}&${role_level
      ?.map((item) => `role_level[]=${item.replace('&', '%26')}`)
      ?.join('&')}&${business?.map((item) => `business[]=${item.replace('&', '%26')}`)?.join('&')}
    `;
    const url = `/admin/profile-management/profile/all?${request}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getProfileManagementFilter: (roleId) => {
    const url = `/admin/profile-management/profile/filters`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  addTopTalentCategory: (body, roleId) => {
    const url = `/admin/top-talent-category/create`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },
};
