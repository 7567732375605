import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { avatar, edit_talent_review } from '../../../../assets/img';
import { CareerAspiration } from '../../../../components';
import { avatarSelector, profileSelector } from '../../../../pages/TalentProfilePage/selector';
import {
  getFirstProfiles,
  getSecondProfiles,
  getThirdProfiles,
} from '../../../../pages/TalentProfilePage/store/profileSlice';
import { fetchTalentProfileSummary } from '../../../../services/talentProfiles';
import {
  ASSIGNMENT_STATUS_TYPES,
  MEETING_DETAIL_STATUS,
  PAGE_NAME,
  PERMISSION,
} from '../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../utils/helper';
import TalentFinderTooltip from '../../../TalentFinderPage/TalentFinderDetail/TalentFinderTooltip/TalentFinderTooltip';
import NameAndPosition from '../../../TalentProfilePage/components/OverAllProfileUser/components/NameAndPosition';
import UserCareer from '../../../TalentProfilePage/components/OverAllProfileUser/components/UserCareer';
import UserInfoDetail from '../../../TalentProfilePage/components/OverAllProfileUser/components/UserInfoDetail';
import UserMileStone from '../../../TalentProfilePage/components/OverAllProfileUser/components/UserMileStone';
import styles from '../../../TalentProfilePage/components/OverAllProfileUser/overall-profile-user.module.scss';
import EditTopTalentDialog from './EditTopTalentDialog/EditTopTalentDialog';
import { AiAgendaForm, AiTitle } from './Styled';

const customStyle = {
  mainTitle: {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#3D3D3D',
    marginTop: '32px',
  },
  borderLayout: {
    border: '1px solid #d9d9d9',
    borderRadius: '7px',
    marginTop: '32px',
  },
  borderUserLayout: {
    border: '1px solid #d9d9d9',
    borderRadius: '7px',
    padding: '20px',
  },
};

const TalentReviewAndInfoSP = (props) => {
  const {
    setAddTalent = () => {},
    setShowSaveTalentStatusEdited,
    data,
    setData,
    agendaDetail,
    onlyViewTalentAgenda,
    isPrinting,
    addTalent,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const { secondSectionProfile } = useSelector(profileSelector);
  const [prevStatus, setprevStatus] = useState();
  const [carreerAspiration, setCarreerAspiration] = useState({});
  const [loadingCarreerAspiration, setLoadingCarreerAspiration] = useState(false);

  const { agendaId } = useParams();

  useLayoutEffect(() => {
    if (!isEmpty(data)) {
      setAddTalent((prevState) => {
        return {
          ...prevState,
          top_talent: {
            ...prevState?.top_talent,
            ...data,
          },
        };
      });
    }
  }, [data, setAddTalent]);

  useLayoutEffect(() => {
    setprevStatus(addTalent?.top_talent?.top_talent_status_previous || null);
  }, [addTalent]);

  useEffect(() => {
    if (!isPrinting && !isEmpty(agendaDetail)) {
      setData({
        ...agendaDetail?.top_talent,
        remarks: agendaDetail?.top_talent?.remarks,
      });
    }
  }, [agendaDetail, setData, isPrinting]);

  const isHiddenEditTopTalentBtn = useMemo(() => {
    return (
      [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(
        agendaDetail?.meeting_status
      ) ||
      onlyViewTalentAgenda ||
      isNaN(Number(agendaId))
    );
  }, [agendaDetail, onlyViewTalentAgenda, agendaId]);

  const { roleActive, permissions } = useSelector((state) => state.user);
  const roleUser = {
    id: data.staff_id,
    roleActive,
    permissions,
  };

  const avatarImage = useSelector(avatarSelector);
  const { firstSectionProfile, loading } = useSelector(profileSelector);
  const dispatch = useDispatch();
  const isHasEditPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_HIDE_UNHIDE_TALENT_PROFILE);
  }, [permissions]);

  useEffect(() => {
    if (!roleUser.roleActive.roleId || !roleUser.id) return;
    (async function getDate() {
      dispatch(getThirdProfiles(roleUser));
      dispatch(getFirstProfiles(roleUser))
        .unwrap()
        .then((_originalPromiseResult) => {
          // handle result here
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          //   if (rejectedValueOrSerializedError.status === 403) history.push('/profile-block');
          console.error(rejectedValueOrSerializedError);
        });
      dispatch(getSecondProfiles(roleUser));
    })();
    (async function getDesired() {
      try {
        setLoadingCarreerAspiration(true);
        const response = await fetchTalentProfileSummary({ roleUser, id: roleUser.id });
        const res = get(response, 'data.result.careerAspirationAll') || [];
        setCarreerAspiration(res);
      } catch (error) {
        console.error(error);
      }
      setLoadingCarreerAspiration(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, roleUser.id, roleUser.permissions]);

  useLayoutEffect(() => {
    if (!data?.line)
      setData((prevState) => {
        return {
          ...prevState,
          line: secondSectionProfile?.line,
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondSectionProfile?.topTalentStatus, data?.top_talent_status, data?.line]);

  const returnBackgroundImage = () => {
    let bgColor = '';
    switch (firstSectionProfile?.assignment_status_type) {
      case ASSIGNMENT_STATUS_TYPES.ACTIVE:
        bgColor = '#14919B';
        break;
      case ASSIGNMENT_STATUS_TYPES.INACTIVE:
        bgColor = '#DA2228';
        break;
      case ASSIGNMENT_STATUS_TYPES.SUSPENDED:
        bgColor = '#FC930D';
        break;
      default:
        bgColor = '#F0F3F7';
        break;
    }
    return bgColor;
  };

  const talentCategoryUpdate = useMemo(() => {
    return {
      previousStatus: prevStatus,
      currentStatus: data?.top_talent_status || null,
    };
  }, [prevStatus, data]);

  return (
    <>
      <AiAgendaForm>
        <div className="d-flex justify-content-between align-items-center">
          <AiTitle>{data?.birth_name} Talent Review</AiTitle>
          {!isHiddenEditTopTalentBtn && (
            <div
              style={{ alignSelf: 'center', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setOpenModal(true)}
            >
              <img src={edit_talent_review} alt="edit_talent_review" />
            </div>
          )}
        </div>
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
            padding: '20px 0',
          }}
        >
          <div style={!isPrinting ? { borderColor: '#fffff' } : customStyle.borderUserLayout}>
            <div className={styles.userInfo} style={{ justifyContent: 'space-between' }}>
              <div className={styles.avatar_wrapper} style={{ marginBottom: '20px' }}>
                <img
                  src={!loading && !isEmpty(addTalent) && decodeBase64ToImage(avatarImage)}
                  alt=""
                  width={155}
                  height={155}
                  style={{
                    borderRadius: '50%',
                    borderWidth: '3px',
                    borderStyle: 'solid',
                    borderColor: (!loading && returnBackgroundImage()) || '#F0F3F7',
                  }}
                />
                {!loading && firstSectionProfile && (
                  <TalentFinderTooltip
                    item={firstSectionProfile}
                    pageName={PAGE_NAME.TALENT_PROFILE}
                  />
                )}
              </div>
              {!isEmpty(addTalent) && (
                <>
                  <NameAndPosition fromTalentReview={true} />
                  <UserInfoDetail
                    isActive={true}
                    isPrintPdf={true}
                    isHasEditPermission={isHasEditPermission}
                    talentCategoryUpdate={talentCategoryUpdate}
                  />
                </>
              )}
            </div>
            <div className={styles.userCareer}>
              <div className={styles.userCareerLeft}>
                <UserCareer />
              </div>
              <div className={styles.userCareerRight}>
                <UserMileStone />
              </div>
            </div>
          </div>
          {isPrinting && <h3 style={customStyle.mainTitle}>Career Statement </h3>}
          <div
            className={styles.distance}
            style={!isPrinting ? { borderColor: '#fffff' } : customStyle.borderLayout}
          >
            <Spin spinning={loadingCarreerAspiration}>
              <CareerAspiration
                carreerAspiration={carreerAspiration}
                isFromTalentReview={true}
                isPrinting={isPrinting}
              />
            </Spin>
          </div>
        </div>
        {openModal && (
          <EditTopTalentDialog
            openModal={openModal}
            setOpenModal={setOpenModal}
            setAddTalent={setAddTalent}
            setShowSaveTalentStatusEdited={setShowSaveTalentStatusEdited}
            data={data}
            setData={setData}
            setprevStatus={setprevStatus}
            prevState={prevStatus}
          />
        )}
      </AiAgendaForm>
    </>
  );
};

export default TalentReviewAndInfoSP;
