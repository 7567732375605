import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { masterDataManagementApi } from '../services/masterDataManagementApi';

export const useGetFilterOptions = () => {
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await masterDataManagementApi.getProfileManagementFilter(roleActive?.roleId);
      if (res.status === 200 && res?.data?.result) {
        setData(res.data.result);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [roleActive]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { filterOptions: data, loading, error, fetch };
};
