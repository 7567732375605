import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { meetingApi } from '../../../services/tcMeeting';

export const searchUserSync = createAsyncThunk('sync/searchUser', async (params) => {
  try {
    const response = await meetingApi.searchUserSync(params);
    const data = response.data.result;
    if (data.length > 0) {
      return data.map((item) => ({ value: item.id, label: item.name }));
    }
    return [];
  } catch (error) {
    throw new Error(error);
  }
});

export const sendSyndication = createAsyncThunk('sync/sendSyndication', async (params) => {
  try {
    await meetingApi.sendSyndication(params);
  } catch (error) {
    return error.response.data.errors;
  }
});

const syndicationSlice = createSlice({
  name: 'sync',
  initialState: {
    users: [],
    loading: false,
    error: '',
  },
  reducers: {
    resetApp: () => null,
  },
  extraReducers: {
    [sendSyndication.pending]: (state) => {
      state.loading = true;
    },
    [sendSyndication.rejected]: (state) => {
      state.loading = false;
    },
    [sendSyndication.fulfilled]: (state) => {
      state.loading = false;
    },
  },
});
export const { reducer: syndicationReducer, actions } = syndicationSlice;
export const { resetApp } = actions;
export default syndicationReducer;
