import React, { useEffect } from 'react';

import {
  ErrorIcon,
  green_right_arrow,
  right_arrow_icon,
  Successfully,
  warning_icon,
  WarningBlueIcon,
} from '../../assets/img';
import { AiAlert, AiAlertWrapper, AiIconAlert } from './Styled';

const AlertMessage = ({
  type,
  message,
  onClose,
  replaceMessage,
  onReplace,
  timeShow,
  closable,
  description,
  styles,
}) => {
  useEffect(() => {
    if (!type) return;
    if (!timeShow) return;

    const timer = setTimeout(() => {
      onClose();
    }, timeShow);

    return () => clearTimeout(timer);
  }, [type, timeShow, onClose]);

  if (type === 'error') {
    return (
      <AiAlert
        message={message}
        type={type}
        showIcon
        description={description}
        icon={<AiIconAlert src={ErrorIcon} />}
        closable={closable === undefined ? true : closable}
        onClose={onClose}
      />
    );
  }
  if (type === 'success') {
    return (
      <AiAlertWrapper>
        <AiAlert
          message={message}
          type={type}
          showIcon
          description={description}
          icon={<AiIconAlert src={Successfully} />}
          closable={closable === undefined ? true : closable}
          onClose={onClose}
          style={styles || {}}
        />
        {replaceMessage && (
          <div
            className={'replace-msg'}
            onKeyDown={() => {}}
            onClick={() => {
              onReplace(true);
            }}
            style={{ right: '30px', color: '#00580f' }}
          >
            {replaceMessage}
            <img src={green_right_arrow} title="" alt="" />
          </div>
        )}
      </AiAlertWrapper>
    );
  }
  if (type === 'normal') {
    return (
      <AiAlert
        message={message}
        type={type}
        showIcon
        description={description}
        icon={<AiIconAlert src={WarningBlueIcon} />}
        closable={closable === undefined ? true : closable}
        onClose={onClose}
      />
    );
  }
  if (type === 'warning') {
    return (
      <AiAlertWrapper>
        <AiAlert
          message={message}
          type={type}
          showIcon
          description={description}
          icon={<AiIconAlert src={warning_icon} />}
          closable={closable === undefined ? true : closable}
          onClose={onClose}
        />
        {replaceMessage && (
          <div
            className={'replace-msg'}
            onKeyDown={() => {}}
            onClick={() => {
              onReplace(true);
            }}
          >
            {replaceMessage}
            <img src={right_arrow_icon} title="" alt="" />
          </div>
        )}
      </AiAlertWrapper>
    );
  }
};

export default AlertMessage;
