import { Button, Col, Form, Row, Select, Spin, Switch } from 'antd';
import { debounce, get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { dropdown_icon } from '../../../../../assets/img';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import { checkIntegerNumber } from '../../../../../utils/helper';
import { history } from '../../../../../utils/history';
import SearchBoxCustom from '../../../components/SearchBoxCustom/SearchBoxCustom';
import styles from './create-or-edit.module.scss';
import { BasicInput, BasicLabel } from '../../../../../assets/common';
const { Option } = Select;

const Wrapper = styled.div`
  .ant-select-selector .ant-select-selection-search-input {
    padding-left: 0px !important;
  }
`;
const initEmptyValue = {
  positionLabel: undefined,
  includedInRatio: undefined,
  business: undefined,
  role: undefined,
};
const includedInRatioOptions = [
  { value: 'Y', label: 'Y' },
  { value: 'N', label: 'N' },
];
async function getAllPosition({ keyword, roleActive }) {
  const result = (
    await adminApi.searchCriticalPositions({ keyword, _page: 1, _limit: 1, roleActive })
  ).data?.result?.data[0];
  return result;
}
async function getAllOptions() {
  const result = await adminApi.getOptionsCriticalPositions();
  return result;
}
async function update(
  {
    positionCode,
    position_label,
    business,
    included_in_ratio,
    role,
    manual,
    status,
    new_position_code,
    new_position_name,
    sg,
    jg,
  },
  roles
) {
  const result = await adminApi.updateCriticalPosition(
    {
      positionCode,
      position_label,
      business,
      included_in_ratio,
      role,
      manual,
      status,
      new_position_code,
      new_position_name,
      sg,
      jg,
    },
    roles
  );
  return result;
}
async function create(
  {
    position_code,
    position_name,
    position_label,
    included_in_ratio,
    business,
    role,
    manual,
    status,
    sg,
    jg,
  },
  roles
) {
  const result = await adminApi.addNewCriticalPosition(
    {
      position_code,
      position_name,
      position_label,
      included_in_ratio,
      business,
      role,
      manual,
      status,
      sg,
      jg,
    },
    roles
  );
  return result;
}

export default function CreateOrEdit({ mode = 'create' }) {
  const [form] = Form.useForm();
  const roleActive = useSelector((state) => state.user.roleActive);
  const dispatch = useDispatch();
  const { positionCode } = useParams();
  const [positionLabelOptions, setPositionLabelOptions] = useState([]);
  const [positionName, setPositionName] = useState('');
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });
  const [searchList, setSearchList] = useState([]);
  const [businessOptions, setBusinessOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const positionCodeWatch = Form.useWatch('positionCode', form);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLoadPositionName = useCallback(debounce(getPositionName, 500), []);
  const [manualToggle, setManualToggle] = useState(0);
  const [status, setStatus] = useState('active');
  const [errorPositionCode, setErrorPositionCode] = useState(false);
  const [oldPosition, setOldPosition] = useState('');
  const [oldManual, setOldManual] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jgList, setJgList] = useState([]);
  const [sgList, setSgList] = useState([]);
  const [sgSelected, setSgSelected] = useState(null);
  const [jgSelected, setJgSelected] = useState(null);
  const [showArrowJg, setShowArrowJg] = useState(true);
  const [showArrowSg, setShowArrowSg] = useState(true);
  const [requiredPositionCode, setRequiredPositionCode] = useState(false);
  const [requiredPositionName, setRequiredPositionName] = useState(false);

  useEffect(() => {
    if (mode !== 'create' || !positionCodeWatch) return;
    const roles = {
      role: roleActive,
    };
    debounceLoadPositionName({ positionCodeWatch, roles });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, positionCodeWatch, roleActive]);

  useEffect(() => {
    setLoading(true);
    getAllOptions()
      .then((res) => {
        const positionLabels = (res?.data?.result?.position_labels || [])
          .filter((item) => item !== null)
          .map((item) => {
            return { value: item, label: item };
          });
        const businesses = (res?.data?.result?.businesses || [])
          .filter((item) => item !== null)
          .map((item) => {
            return { value: item, label: item };
          });
        const roles = (res?.data?.result?.roles || [])
          .filter((item) => item !== null)
          .map((item) => {
            return { value: item, label: item };
          });
        setPositionLabelOptions(positionLabels);
        setBusinessOptions(businesses);
        setRoleOptions(roles);
      })
      .catch((err) => {
        console.log(err);
      });

    (async function getListByGrade() {
      try {
        const jgList = await adminApi.getListByGrade({ type: 'jg' });
        setJgList(
          get(jgList, 'data.result').map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        );
        const sgList = await adminApi.getListByGrade({ type: 'sg' });
        setSgList(
          get(sgList, 'data.result').map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    })();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (searchTerm.type === 'submit') {
      setRequiredPositionCode(false);
      setPositionName(searchTerm.value.position_name);
    }
    if (searchTerm.type === 'search') {
      setPositionName('');
    }
  }, [searchTerm.type, searchTerm.value.position_code, searchTerm.value.position_name]);

  useEffect(() => {
    if (mode !== 'edit' || !positionCode) return;
    setLoading(true);
    getAllPosition({ keyword: positionCode, roleActive })
      .then((res) => {
        const {
          business,
          included_in_ratio,
          position_code,
          position_label,
          position_name,
          role,
          status,
          manual,
          sg,
          jg,
        } = res;
        setOldPosition(position_code);
        setSearchTerm({ showTerm: position_code, value: { position_code, position_name } });
        setPositionName(position_name);
        setStatus(status);
        setSgSelected(sg);
        setJgSelected(jg);
        setManualToggle(manual);
        setOldManual(manual);
        form.setFieldsValue({
          positionLabel: position_label || undefined,
          includedInRatio: included_in_ratio || undefined,
          business: business || undefined,
          role: role || undefined,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, positionCode]);

  async function getPositionName({ positionCodeWatch, roles }) {
    try {
      const result = await adminApi.getPositionNameByPositionCode({
        positionCode: positionCodeWatch,
        roles,
      });
      const position_name = result?.data?.result?.position_name;
      form.setFieldValue('positionName', position_name);
      form.validateFields(['positionName']);
    } catch (error) {
      form.setFieldValue('positionName', undefined);
    }
  }
  const onSubmit = () => {
    if (!searchTerm?.value || searchTerm?.type === 'search') {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message:
            'There are items that require your attention. Please fill in the required field(s)',
        })
      );
      form.validateFields();
      setRequiredPositionCode(true);
      setRequiredPositionName(true);
      return;
    }
    const positionCode = parseInt(searchTerm?.value?.position_code?.trim());
    form
      .validateFields()
      .then((res) => {
        const isValid = checkIntegerNumber(positionCode);
        if (!isValid) {
          setErrorPositionCode(true);
          return;
        }
        setErrorPositionCode(false);
        form.submit();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message:
              'There are items that require your attention. Please fill in the required field(s)',
          })
        );
      });
  };
  const onFinish = (values) => {
    const roles = {
      role: roleActive,
    };
    const positionCode = searchTerm.value.position_code.trim();

    const data = {
      position_label: values.positionLabel,
      included_in_ratio: values.includedInRatio,
      business: values.business,
      role: values.role,
      manual: manualToggle,
      status: status,
      sg: sgSelected,
      jg: jgSelected,
    };
    if (mode === 'edit') {
      data.positionCode = oldPosition;
      data.new_position_code = positionCode;
      data.new_position_name = positionName.trim();
      update(data, roles)
        .then((res) => {
          history.push('/admin/sp-management/managing-critical-position');
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: `${positionName} ${positionCode} successfully Edited`,
            })
          );
        })
        .catch((err) => {
          console.log(err);
          const error = get(err, 'response.data.errors');
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.ERROR,
              message: error,
            })
          );
        });
      return;
    }

    data.position_code = positionCode;
    data.position_name = positionName.trim();

    create(data, roles)
      .then((res) => {
        history.push('/admin/sp-management/managing-critical-position');
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${positionName} (${positionCode}) successfully added`,
          })
        );
      })
      .catch((err) => {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: err?.response?.data?.errors,
          })
        );
      });
  };

  const onCancel = () => {
    history.push('/admin/sp-management/managing-critical-position');
  };

  const changeInputPositionCode = (e) => {
    const val = e.target.value;
    setSearchTerm((prev) => {
      return {
        ...prev,
        value: {
          position_code: val,
        },
      };
    });
  };

  return (
    <Spin spinning={loading}>
      <h3 className={styles.mainTitle}>
        {mode === 'create' ? 'Add New Critical Position' : 'Edit Critical Position'}
        <span className={styles.subText} data-testid="noteTitle">
          Succession Planning Management
        </span>
      </h3>
      <div className={styles.main}>
        <div>
          <Row justify="space-between">
            <p className={styles.title}>{mode === 'create' ? 'Add New Details' : 'Details'}</p>
            <Col>
              <Switch
                onChange={(checked) => {
                  setManualToggle(checked ? 1 : 0);
                  setPositionName('');
                  setSearchTerm({
                    type: '',
                    value: '',
                    showTerm: '',
                  });
                  setRequiredPositionCode(false);
                  setRequiredPositionName(false);
                }}
                checked={manualToggle}
                style={{ backgroundColor: `${manualToggle ? '#009089' : ''}` }}
                disabled={mode === 'edit'}
              />
              <span style={{ marginLeft: '10px' }}>Manual</span>
            </Col>
          </Row>
        </div>
        <div>
          <Form
            onFinish={onFinish}
            form={form}
            initialValues={mode === 'create' ? initEmptyValue : { positionCode }}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col span={12} className={styles.customize}>
                <BasicLabel required style={{ margin: '5px 0' }}>
                  Position Code
                </BasicLabel>
                {!manualToggle && (
                  <SearchBoxCustom
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchList={searchList}
                    setSearchList={setSearchList}
                    fieldName={'position_code_critical'}
                    searchPlaceholder={'Search Position Code'}
                    isReadOnly={!oldManual && mode === 'edit'}
                    isShowRequired={requiredPositionCode}
                  />
                )}
                {Boolean(manualToggle) && (
                  <BasicInput
                    height={44}
                    plhColor="#bfbfbf"
                    value={searchTerm.value.position_code || ''}
                    onChange={changeInputPositionCode}
                    error={
                      (requiredPositionCode && !searchTerm?.value?.position_code) ||
                      (searchTerm?.value?.position_code && errorPositionCode)
                    }
                    placeholder="Position Code"
                    disabled={!manualToggle}
                  />
                )}
                {searchTerm?.value?.position_code && errorPositionCode && (
                  <span style={{ color: '#f85032', fontSize: '12px' }}>
                    Position Code input is invalid. Please enter in numbering format
                  </span>
                )}
              </Col>
              <Col span={12} className={`${styles.customize} mb-3`}>
                <BasicLabel required={Boolean(manualToggle)} style={{ margin: '5px 0' }}>
                  Position Name
                </BasicLabel>
                <BasicInput
                  height={44}
                  plhColor="#bfbfbf"
                  value={positionName || ''}
                  onChange={(e) => {
                    const val = e.target.value;
                    setPositionName(val);
                  }}
                  error={requiredPositionName && !positionName}
                  placeholder="Position Name"
                  disabled={!manualToggle}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={12} className={styles.customize}>
                    <Form.Item
                      rules={[{ required: true, message: '' }]}
                      name="positionLabel"
                      labelCol={{ span: 24 }}
                      label="Position Label"
                    >
                      <Select
                        data-testid="position-label-option"
                        placeholder="Select"
                        suffixIcon={<img alt="" src={dropdown_icon} />}
                      >
                        {positionLabelOptions.map((item) => (
                          <Option key={item.value} value={item.value}>
                            <span data-testid={item.label}>{item.label}</span>{' '}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} className={styles.customize}>
                    <Form.Item
                      name="includedInRatio"
                      labelCol={{ span: 24 }}
                      label="Included in Ratio"
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select
                        data-testid="included-in-ratio-option"
                        placeholder="Select"
                        suffixIcon={<img alt="" src={dropdown_icon} />}
                      >
                        {includedInRatioOptions.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={12} className={styles.customize}>
                    <Form.Item
                      name="business"
                      labelCol={{ span: 24 }}
                      label="Business"
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select
                        data-testid="business-option"
                        placeholder="Select"
                        suffixIcon={<img alt="" src={dropdown_icon} />}
                      >
                        {businessOptions.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} className={styles.customize}>
                    <Form.Item
                      name="role"
                      labelCol={{ span: 24 }}
                      label="Role"
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select
                        data-testid="role-option"
                        placeholder="Select"
                        suffixIcon={<img alt="" src={dropdown_icon} />}
                      >
                        {roleOptions.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12} className={styles.customize}>
                <Row gutter={16}>
                  <Col span={12} className={styles.customize}>
                    <div>Status</div>
                    <Select
                      data-testid="status-option"
                      placeholder="Select"
                      suffixIcon={<img alt="" src={dropdown_icon} />}
                      style={{ width: '100%' }}
                      value={status}
                      onChange={(e) => setStatus(e)}
                    >
                      <Option key="active" value="active">
                        Active
                      </Option>
                      <Option key="in-active" value="in-active">
                        InActive
                      </Option>
                    </Select>
                  </Col>
                  {Boolean(manualToggle) && (
                    <Col span={12} className={styles.customize}>
                      <Wrapper>
                        <div>SG</div>
                        <Select
                          data-testid="sg-option"
                          placeholder="Select"
                          suffixIcon={<img alt="" src={dropdown_icon} />}
                          style={{ width: '100%' }}
                          value={sgSelected}
                          onChange={(e) => setSgSelected(e)}
                          className={styles.search_input}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={sgList}
                          allowClear
                          showArrow={showArrowSg}
                          onMouseEnter={() =>
                            sgSelected ? setShowArrowSg(false) : setShowArrowSg(true)
                          }
                          onMouseLeave={() => setShowArrowSg(true)}
                        />
                      </Wrapper>
                    </Col>
                  )}
                </Row>
              </Col>
              {Boolean(manualToggle) && (
                <Col span={12} className={styles.customize}>
                  <Row gutter={16}>
                    <Col span={12} className={styles.customize}>
                      <Wrapper>
                        <div>JG</div>
                        <Select
                          data-testid="jg-option"
                          placeholder="Select"
                          suffixIcon={<img alt="" src={dropdown_icon} />}
                          style={{ width: '100%' }}
                          value={jgSelected}
                          onChange={(e) => setJgSelected(e)}
                          className={styles.search_input}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={jgList}
                          allowClear
                          showArrow={showArrowJg}
                          onMouseEnter={() =>
                            jgSelected ? setShowArrowJg(false) : setShowArrowJg(true)
                          }
                          onMouseLeave={() => setShowArrowJg(true)}
                        />
                      </Wrapper>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Form>
        </div>
        <div className={styles.grBtn}>
          <Row gutter={8}>
            <Col>
              <Button data-testid="cancel-btn" onClick={onCancel} className={styles.cancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                data-testid="submit-btn"
                onClick={onSubmit}
                htmlType="submit"
                className={styles.add}
              >
                {mode === 'create' ? 'Add' : 'Save'}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
}
