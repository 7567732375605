import { cloneDeep, orderBy, sortBy, uniqBy } from 'lodash';
import { meetingApi } from '../services/tcMeeting';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getAgendaTalents = createAsyncThunk(
  'spTemplate/getAgendaTalents',
  async (params, thunkAPI) => {
    try {
      const res = await meetingApi.getPersonBelongPositions(params);
      if (res.status === 200) {
        thunkAPI.dispatch(updateTalentsForAgenda(res.data.result));
      }
    } catch (error) {}
  }
);

export const getRatioAgenda = createAsyncThunk(
  'spTemplate/getRatioAgenda',
  async (params, thunkAPI) => {
    try {
      const res = await meetingApi.getPositionRatio(params);
      if (res.status === 200) {
        thunkAPI.dispatch(updateRatioAgenda(res.data.result));
      }
    } catch (error) {}
  }
);
export const getAgendaIncumbent = createAsyncThunk(
  'spTemplate/getAgendaIncumbent',
  async (params, thunkAPI) => {
    const { agendaId, id, versionId, roleId } = params;
    try {
      if (!Number(agendaId) || !id) return;
      const res = !versionId
        ? await meetingApi.getAgendaDetail(params)
        : await meetingApi.getAgendaDetailByVersion({ agendaId, version: versionId }, roleId);
      if (res.status === 200) {
        thunkAPI.dispatch(
          saveLines({
            first_line_ids: res.data.result.first_line,
            second_line_ids: res.data.result.second_line,
            third_line_ids: res.data.result.third_line,
          })
        );
        thunkAPI.dispatch(saveAgendaDetail(res.data.result));
        thunkAPI.dispatch(
          updateStatusAgenda({
            approve: res.data.result.permissions.can_approved,
            disapprove: res.data.result.permissions.can_disapproved,
          })
        );
        return res.data.result.version;
      }
    } catch (error) {}
  }
);

export const getVersionAgenda = createAsyncThunk(
  'spTemplate/getVersionAgenda',
  async (params, thunkAPI) => {
    try {
      const res = await meetingApi.setAsMain(params);
      if (res.status === 200) {
        thunkAPI.dispatch(
          saveLines({
            first_line_ids: res.data.result.first_line,
            second_line_ids: res.data.result.second_line,
            third_line_ids: res.data.result.third_line,
          })
        );
        thunkAPI.dispatch(saveAgendaDetail(res.data.result));
        thunkAPI.dispatch(
          updateStatusAgenda({
            approve: res.data.result.permissions.can_approved,
            disapprove: res.data.result.permissions.can_disapproved,
          })
        );
        return res.data.result.version;
      }
      return true;
    } catch (error) {
      return false;
    }
  }
);

const handleOrderAndUniqe = (talentOrigin, talents) => {
  let combineTalents = [...talentOrigin, ...talents];
  let orderTalents = orderBy(combineTalents, 'matching_percentage', 'desc');
  let uniqByTalents = uniqBy(orderTalents, 'staff_id');
  let sortByRank = sortBy(uniqByTalents, 'rank');
  return sortByRank;
};
const initialState = {
  succession_planning_name: '',
  start_time: '',
  end_time: '',
  advocator_ids: [],
  remarks: '',
  cc_ids: [],
  first_line_ids: [],
  second_line_ids: [],
  third_line_ids: [],
  position_codes: [],
  status: {
    approve: false,
    disapprove: false,
  },
  agendaRatio: 0,
  loading: {
    version: false,
  },
};
const initialStateAngeda = {
  first_line: [],
  second_line: [],
  third_line: [],
};
const spTemplateSlice = createSlice({
  name: 'spTemplate',
  initialState: {
    saveAgenda: initialState,
    agendaDetail: initialStateAngeda,
    loading: {
      addPosition: false,
    },
  },
  reducers: {
    saveSuccessionPlanningSession: (state, action) => {
      state.saveAgenda.succession_planning_name = action.payload.succession_planning_name;
      state.saveAgenda.start_time = action.payload.start_time;
      state.saveAgenda.end_time = action.payload.end_time;
      state.saveAgenda.advocator_ids = action.payload.advocator_ids;
      state.saveAgenda.cc_ids = action.payload.cc_ids;
    },
    savePosition: (state, action) => {
      state.saveAgenda.position_codes = action.payload.position_codes;
    },
    saveLines: (state, action) => {
      state.saveAgenda.first_line_ids = action.payload.first_line_ids;
      state.saveAgenda.second_line_ids = action.payload.second_line_ids;
      state.saveAgenda.third_line_ids = action.payload.third_line_ids;
    },
    saveAgendaDetail: (state, action) => {
      state.agendaDetail = action.payload;
    },
    updateStatusAgenda: (state, action) => {
      state.saveAgenda.status.approve = action.payload.approve;
      state.saveAgenda.status.disapprove = action.payload.disapprove;
    },
    updateRatioAgenda: (state, action) => {
      state.saveAgenda.agendaRatio = action.payload;
    },
    resetAgendaDetail: (state, action) => {
      state.saveAgenda = initialState;
      state.agendaDetail = initialStateAngeda;
    },
    updateTalentsForAgenda: (state, action) => {
      let cloneAgendaDetail = cloneDeep(state.agendaDetail);
      cloneAgendaDetail.first_line = handleOrderAndUniqe(
        cloneAgendaDetail.first_line,
        action.payload.first_line
      );
      cloneAgendaDetail.second_line = handleOrderAndUniqe(
        cloneAgendaDetail.second_line,
        action.payload.second_line
      );
      cloneAgendaDetail.third_line = handleOrderAndUniqe(
        cloneAgendaDetail.third_line,
        action.payload.third_line
      );
      state.saveAgenda.first_line_ids = cloneAgendaDetail.first_line.map((ele) => ({
        staff_id: ele.staff_id,
        ranking: ele.rank,
      }));
      state.saveAgenda.second_line_ids = cloneAgendaDetail.second_line.map((ele) => ({
        staff_id: ele.staff_id,
        ranking: ele.rank,
      }));
      state.saveAgenda.third_line_ids = cloneAgendaDetail.third_line.map((ele) => ({
        staff_id: ele.staff_id,
        ranking: ele.rank,
      }));
      state.agendaDetail = cloneAgendaDetail;
    },
    detelePositionAgenda: (state, action) => {
      let first_line = state.agendaDetail.first_line.filter(
        (ele) => ele.position_code !== action.payload
      );
      let second_line = state.agendaDetail.second_line.filter(
        (ele) => ele.position_code !== action.payload
      );
      let third_line = state.agendaDetail.third_line.filter(
        (ele) => ele.position_code !== action.payload
      );
      state.agendaDetail.first_line = first_line;
      state.agendaDetail.second_line = second_line;
      state.agendaDetail.third_line = third_line;
      state.saveAgenda.first_line_ids = first_line.map((fele) => ({
        staff_id: fele.staff_id,
        ranking: fele.rank,
      }));
      state.saveAgenda.second_line_ids = second_line.map((fele) => ({
        staff_id: fele.staff_id,
        ranking: fele.rank,
      }));
      state.saveAgenda.third_line_ids = third_line.map((fele) => ({
        staff_id: fele.staff_id,
        ranking: fele.rank,
      }));
    },
  },
  extraReducers: {
    [getVersionAgenda.pending]: (state, action) => {
      state.saveAgenda.loading.version = true;
    },
    [getVersionAgenda.rejected]: (state, action) => {
      state.saveAgenda.loading.version = false;
    },
    [getVersionAgenda.fulfilled]: (state, action) => {
      state.saveAgenda.loading.version = false;
    },
    [getAgendaTalents.pending]: (state, action) => {
      state.loading.addPosition = true;
    },
    [getAgendaTalents.rejected]: (state, action) => {
      state.loading.addPosition = false;
    },
    [getAgendaTalents.fulfilled]: (state, action) => {
      state.loading.addPosition = false;
    },
  },
});

const { reducer, actions } = spTemplateSlice;
export const {
  saveSuccessionPlanningSession,
  savePosition,
  saveLines,
  saveAgendaDetail,
  resetAgendaDetail,
  updateStatusAgenda,
  updateRatioAgenda,
  updateTalentsForAgenda,
  detelePositionAgenda,
} = actions;
export default reducer;
