import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './form-date.module.scss';

const FormDate = (props) => {
  const {
    label,
    messageErr,
    required,
    placeholder,
    value,
    setFormData,
    isError,
    borderColor,
    disabledDate,
  } = props;

  const [defaultValue, setDefaultValue] = useState('');

  const onChange = (date, _dateString) => {
    setFormData && setFormData(date);
  };

  useEffect(() => {
    setDefaultValue(value || undefined);
  }, [value]);

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.label}>{label}</span>
        <span style={{ color: '#FF787F' }}>{required && '*'}</span>
      </div>
      <div>
        <DatePicker
          placeholder={placeholder}
          onChange={onChange}
          style={{
            width: '100%',
            height: '44px',
            borderColor: `${borderColor}`,
            borderRadius: '4px ',
          }}
          value={defaultValue && moment(defaultValue)}
          format="DD MMMM YYYY"
          allowClear={false}
          className={isError ? styles.borderErr : ''}
          disabledDate={disabledDate}
          data-testid="form-date"
        />
      </div>
      <div className={styles.error}>{isError && messageErr}</div>
    </div>
  );
};

export default FormDate;
