import styled from 'styled-components';
import { color, font, fontSize } from '../../../assets/common';

export const AiContainerAgendas = styled.div`
  padding: 46px 36px;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 32px;
`;

export const AiTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;
export const AiH3 = styled.h3`
  font-family: ${font.inter};
  font-weight: 600;
  font-size: ${fontSize.sizable};
  color: ${color.brightGray2};
  margin-bottom: 0;
`;
export const AiTitleFlex = styled.div`
  display: flex;
  align-items: center;
`;
export const AiContainerGrBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
