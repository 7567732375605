import { Tooltip } from 'antd';
import _, { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip as BSToolTip } from 'react-bootstrap';

import { avatar, contract_info, previous_top_talent_status } from '../../../../../assets/img';
import { Switch } from '../../../../../components';
import CustomAntModal from '../../../../../components/CustomAntModal/CustomAntModal';
import {
  capitalizeFirstLetter,
  decodeBase64ToImage,
  formatDate,
  getPermissionForHiddenBtnByRole,
  hideOtherTalentLabel,
} from '../../../../../utils/helper';
import { avatarSelector, profileSelector } from '../../../selector';
import styles from '../overall-profile-user.module.scss';
import NameAndPosition from './NameAndPosition';
import { USER_INFO_DETAIL } from '../constants';

const UserInfoDetail = ({
  setIsActive,
  isActive,
  isPrintPdf,
  isHasEditPermission,
  talentCategoryUpdate,
}) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const {
    secondSectionProfile,
    loading,
    thirdSectionProfile: { assignment_status_type, jg, job_role_level },
  } = useSelector(profileSelector);

  const avatarImage = useSelector(avatarSelector);

  const [showAboutMePopup, setShowAboutMePopup] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);

  const displayInfoEmployee = useCallback(
    (text, text2) => {
      if (loading) {
        return (
          <b>
            <Skeleton count={1} height={17}></Skeleton>
          </b>
        );
      } else {
        return (
          <b>
            {text} {text2}
          </b>
        );
      }
    },
    [loading]
  );

  const displayInfoEmployeeTooltip = useCallback(
    (text, text2) => {
      console.log(text, text2);
      if (loading) {
        return (
          <b>
            <Skeleton count={1} height={17}></Skeleton>
          </b>
        );
      } else {
        return (
          <div>
            {text} {text2}
          </div>
        );
      }
    },
    [loading]
  );

  const talentLabel = useMemo(() => {
    const status = talentCategoryUpdate?.currentStatus || secondSectionProfile?.topTalentStatus;
    const label = capitalizeFirstLetter(status);
    const isVPTalent = hideOtherTalentLabel(assignment_status_type, job_role_level, label);
    return isVPTalent ? displayInfoEmployee('Top Management') : displayInfoEmployee(label);
  }, [
    assignment_status_type,
    displayInfoEmployee,
    job_role_level,
    secondSectionProfile,
    talentCategoryUpdate,
  ]);

  useEffect(() => {
    if (!roleActive) return;
    setShowSwitch(getPermissionForHiddenBtnByRole(roleActive));
  }, [roleActive]);

  const handleShowAboutMe = (data) => {
    if (!data) return;
    if (data.length > 660) {
      return (
        <>
          {data.slice(0, 660)}...
          <span
            className={styles.viewMore}
            onKeyDown={() => {}}
            onClick={() => setShowAboutMePopup(true)}
          >
            View more
          </span>
        </>
      );
    } else {
      return data;
    }
  };

  const getYearsOfRetirementDate = useMemo(() => {
    return (
      (secondSectionProfile.yearsToRetirement > 0 && secondSectionProfile.yearsToRetirement) ||
      (secondSectionProfile.yearsContractEndDate > 0 &&
        secondSectionProfile.yearsContractEndDate) ||
      null
    );
  }, [secondSectionProfile]);

  return (
    <>
      <div className={styles.userInfoDetail}>
        {!isPrintPdf && showSwitch && isHasEditPermission && (
          <div className={styles.switch}>
            <Switch setIsActive={setIsActive} isActive={isActive} />
          </div>
        )}
        {isActive && !isEmpty(secondSectionProfile) && (
          <div className={styles.listInfo}>
            <div className={styles.itemInfo}>
              <p>Age</p>
              {displayInfoEmployee(secondSectionProfile?.age)}
            </div>
            <div className={styles.itemInfo}>
              <p>Date of Birth</p>
              {displayInfoEmployee(
                secondSectionProfile && formatDate(secondSectionProfile.dateOfBirth)
              )}
            </div>
            <div className={styles.itemInfo}>
              {secondSectionProfile?.salary_grade?.startsWith('CONTRACT') ||
              secondSectionProfile?.salary_grade?.startsWith('EXPAT') ? (
                <>
                  <p>
                    Contract End Date
                    <Tooltip
                      placement="top"
                      title={
                        <>
                          <div>Retirement Date : </div>
                          <div>
                            {displayInfoEmployeeTooltip(
                              <div>
                                {!_.isEmpty(secondSectionProfile.retirement_date)
                                  ? formatDate(secondSectionProfile.retirement_date)
                                  : 'N/A'}{' '}
                                {!_.isEmpty(secondSectionProfile.retirement_date) &&
                                  getYearsOfRetirementDate &&
                                  `[${getYearsOfRetirementDate} years]`}
                              </div>
                            )}
                          </div>
                        </>
                      }
                      overlayClassName="custom-tooltip"
                      {...{
                        overlayInnerStyle: {
                          fontFamily: '"Inter", sans-serif',
                          fontSize: '12px',
                          lineHeight: '18px',
                          gap: '24px',
                          padding: '12px',
                          borderRadius: '4px',
                        },
                      }}
                    >
                      <span style={{ marginLeft: '5px' }}>
                        <img
                          src={contract_info}
                          alt="contract_info"
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </Tooltip>
                  </p>
                </>
              ) : (
                <p>Retirement Date</p>
              )}

              {secondSectionProfile?.salary_grade?.startsWith('CONTRACT') ||
              secondSectionProfile?.salary_grade?.startsWith('EXPAT')
                ? displayInfoEmployee(
                    <>
                      {!_.isEmpty(secondSectionProfile.contract_end_date)
                        ? `${formatDate(secondSectionProfile.contract_end_date)} ${
                            secondSectionProfile.yearsContractEndDate
                              ? `[${secondSectionProfile.yearsContractEndDate} years]`
                              : '[N/A]'
                          }`
                        : 'N/A'}{' '}
                    </>
                  )
                : displayInfoEmployee(
                    <>
                      {!_.isEmpty(secondSectionProfile.retirement_date)
                        ? formatDate(secondSectionProfile.retirement_date)
                        : 'N/A'}{' '}
                      {getYearsOfRetirementDate && `[${getYearsOfRetirementDate} years]`}
                    </>
                  )}
            </div>
            <div className={styles.itemInfo}>
              <p>Nationality</p>
              {secondSectionProfile &&
              secondSectionProfile?.country &&
              secondSectionProfile?.country === USER_INFO_DETAIL.IS_MALAYSIA ? (
                <OverlayTrigger
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <BSToolTip className="stateOfBirthTooltip">
                      <div
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          lineHeight: '20px',
                          letterSpacing: '0.173px',
                          color: '#fff',
                          textAlign: 'left',
                          padding: '5px',
                        }}
                      >
                        State of Birth: {secondSectionProfile?.stateOfBirth?.trim() || 'N/A'}
                      </div>
                    </BSToolTip>
                  }
                >
                  {displayInfoEmployee(secondSectionProfile?.country)}
                </OverlayTrigger>
              ) : (
                displayInfoEmployee(secondSectionProfile?.country)
              )}
            </div>
            <div className={styles.itemInfo}>
              <p>Top Talent Category</p>
              {talentCategoryUpdate?.previousStatus &&
                talentCategoryUpdate?.previousStatus !== talentCategoryUpdate?.currentStatus && (
                  <Tooltip
                    title={`Previous Top Talent Status : ${talentCategoryUpdate?.previousStatus}`}
                    overlayInnerStyle={{ borderRadius: '6px' }}
                    overlayStyle={{ width: '212px' }}
                  >
                    <img
                      src={previous_top_talent_status}
                      alt="previous_top_talent_status"
                      style={{ marginRight: '10px', width: '8px', cursor: 'pointer' }}
                    />
                  </Tooltip>
                )}
              {talentLabel}
            </div>
          </div>
        )}
        {isActive && !isEmpty(secondSectionProfile) && (
          <div className={styles.aboutMe}>
            <p>About Me: </p>
            {loading && <Skeleton count={1} height={30} width={647}></Skeleton>}
            <span>
              {!loading && secondSectionProfile && handleShowAboutMe(secondSectionProfile?.aboutMe)}
            </span>
          </div>
        )}
      </div>

      <CustomAntModal
        open={showAboutMePopup}
        setOpen={setShowAboutMePopup}
        width={1000}
        centered
        styles={{
          borderRadius: 8,
          closePosition: 50,
          containerPaddingLeftRight: 50,
          containerPaddingTopBottom: 50,
        }}
        hideDefaultBtn
      >
        <div className={styles.aboutMeModal}>
          <div className={styles.header}>
            <img
              className={styles.avatar}
              src={decodeBase64ToImage(avatarImage) || avatar}
              alt="avatar"
            />
            <NameAndPosition />
          </div>
          <div className={styles.detail}>
            <div className={styles.title}>About Me</div>
            <div className={styles.content}>{secondSectionProfile?.aboutMe}</div>
          </div>
        </div>
      </CustomAntModal>
    </>
  );
};

export default UserInfoDetail;
