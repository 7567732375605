import React, { useState, useRef, useEffect } from 'react';
import styles from './dropdown-level-role.module.scss';
import Rectangle from '../../../../assets/img/Rectangle.svg';

const DropdownAccessRole = ({ selected, setSelected, options, placeholder, disabled }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  const handleClickAccessRole = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={`${styles.dropdown_btn} ${disabled && styles.dropdown_disabled}`}
        onKeyDown={() => {}}
        onClick={() => handleClickAccessRole()}
        data-testid="dropdown-access-role"
      >
        <p className={styles.dropdown_select_label} data-testid="name">
          {selected?.name || (
            <span style={{ color: '#9EA0A5', fontSize: '13px' }}>{placeholder}</span>
          )}
        </p>
        <img src={Rectangle} alt="" />
      </div>

      {isActive && (
        <div
          className={styles.dropdown_content}
          style={{ maxHeight: '200px', overflowY: 'auto' }}
          data-testid="dropdown-access-role-list"
        >
          {options &&
            options?.map((option, index) => {
              return (
                <div
                  key={index}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className={styles.dropdown_item}
                  data-testid="click-dropdown-access"
                >
                  {option?.name}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default DropdownAccessRole;
