import { Collapse } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import DetailMonthlyYearView from '../DetailMonthlyYearView/DetailMonthlyYearView';
import { Container } from './styled';
const { Panel } = Collapse;

const MonthlyCollapse = (props) => {
  const { monthly } = props;

  return (
    <div>
      <Container>
        <Collapse
          bordered={false}
          defaultActiveKey={monthly.map((item) => item.key)}
          expandIconPosition="end"
        >
          {!isEmpty(monthly) &&
            monthly.map((item) => (
              <Panel header={item.header} key={item.key}>
                <DetailMonthlyYearView value={item.value} {...props} />
              </Panel>
            ))}
        </Collapse>
      </Container>
    </div>
  );
};

export default MonthlyCollapse;
