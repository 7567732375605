import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { meetingApi } from '../services/tcMeeting';
import { updateRoles } from '../pages/Meeting/redux/meetingDetailSlice';

export const useGetRoleInMeeting = ({ idMeeting }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getRoleInMeeting = useCallback(async () => {
    setLoading(true);
    try {
      const response = await meetingApi.getRoleInMeeting({ idMeeting, roleUser: user });

      setData(response.data.result);
      dispatch(updateRoles(response.data.result));
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMeeting, user?.roleActive?.roleId]);

  useEffect(() => {
    if (isEmpty(idMeeting)) {
      return;
    }
    getRoleInMeeting();
  }, [idMeeting, getRoleInMeeting]);
  return { data, loading, error };
};
