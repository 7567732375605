import { dashboard_breadcrumb } from '../../../assets/img';

export const MOBILITY_LIST_VIEW_COLUMNS = [
  {
    title: 'Mobility Simulation Name',
    dataIndex: 'name',
    width: 350,
  },
  {
    title: 'Last Update by',
    dataIndex: 'last_updated_by',
  },
  {
    title: 'Last Update Date',
    dataIndex: 'last_updated_at',
  },
  {
    title: '',
    dataIndex: 'view',
  },
  {
    title: '',
    dataIndex: 'more',
  },
];

export const MOBILITY_LIST_EXISTED_COLUMNS = [
  {
    title: 'Mobility Simulation Name',
    dataIndex: 'name',
    width: 350,
  },
  {
    title: 'Last Update by',
    dataIndex: 'last_updated_by',
  },
  {
    title: 'Last Update date',
    dataIndex: 'last_updated_at',
  },
  {
    title: '',
    dataIndex: 'view',
  },
  {
    title: '',
    dataIndex: 'more',
  },
];
export const ENDORSED_STATUS = ['endorsed', 'concluded'];

export const MOBILITY_BREADCRUMB_LIST = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Mobility Simulation' },
];

export const MOBILITY_AGENDA_BREADCRUMB_LIST = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Mobility Discussion' },
];

export const MOBILITY_FORM_MODE = {
  EDIT: 'edit',
  CREATE: 'create',
  VIEW: 'view',
  DUPLICATE: 'duplicate',
};

export const MOBILITY_MODAL_ADD_TYPE = {
  POSITION: 'position',
  TALENT: 'talent',
};

export const MOBILITY_MODE = {
  AGENDA: 'agenda',
};

export const MOBILITY_MESSAGE = {
  IN_VALID: 'There are items that require your attention. Please fill out this field.',
  ADD_POSITION_SUCCESSFUL: 'Position added successfully to Mobility Simulation',
  MOBILITY_SAVE_SUCCESSFUL: 'Mobility Simulation successfully saved',
  MOBILITY_DELETE_SUCCESSFUL: 'Mobility Simulation deleted successfully',
  MOBILITY_EDIT_SUCCESSFUL: 'Mobility Simulation successfully added to the list',
  ADD_TALENT_SUCCESSFUL: 'Talent added successfully to Mobility Simulation',
  MOBILITY_DUPLICATE_SUCCESSFUL: 'Mobility Simulation successfully duplicate and added to the list',
  DELETE_TALENT_SUCCESS: 'Talent successfully deleted',
  DELETE_POSITION_SUCCESS: 'Position successfully deleted',
};

export const TOP_TALENT = [
  { name: 'Corporate Talent', symbol: 'CT' },
  { name: 'Business Talent', symbol: 'BT' },
];

export const MOBILITY_CATEGORY_COLOR = [
  { name: 'Approved Succession Planning', color: '#FEDC92' },
  { name: 'AI Recommendation', color: '#ECF3C1' },
  { name: 'Direct Reports', color: '#BDB6EA' },
];

export const MOBILITY_CATEGORY = {
  APPROVED_SUCCESSION_PLANNING: 'Approved Succession Planning',
  AI_RECOMMENDATION: 'AI Recommendation',
  DIRECT_REPORTS: 'Direct Reports',
};

export const MOBILITY_SIMULATION = {
  CREATED_FROM_AGENDA: 'agenda',
  CREATED_FROM_MS_MODULE: 'ms_module',
};

export const AGENDA_TYPES = {
  SP: 'SP',
  MS: 'MS',
  TALENT: 'TR',
  OTHERS: 'OT',
};

export const COPY_SUCCESS_ADMIN = {
  SUCCESS: 'Succession Planning Successfully Copy to ',
};
