import React from 'react';
import './buttoncs.scss';
const ButtonCS = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};
export default ButtonCS;
