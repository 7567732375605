import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AiButton, BasicButton } from '../../../../../assets/common';
import { circle_checked, finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { CommonFilterManaging } from '../../../../../components/CommonFilterManaging';
import fetch_filter_option from './fetch-filter-option.json';
import * as styles from './menu-bar-mapping.module.scss';

export default function MenuBarMapping(props) {
  const {
    showFilter,
    setShowFilter,
    updateStatusVisible,
    setUpdateStatusVisible,
    deleteSelecteds,
    handleDeleteRecord,
    loading,
    setIsEdit,
    isEdit,
    filterOptions,
    setIsApply,
    setIsClear,
    searchParams,
    setSearchParams,
  } = props;
  const { keyword, dataFieldSelected, dataContentSelected } = searchParams;

  const history = useHistory();

  // Use state
  const [searchInput, setSearchInput] = useState('');

  const handleAddNew = () => {
    history.push(`/admin/master-data-management/add-new-mapping-for-business`);
  };

  const setSelectedOptions = (selectedOption, type) => {
    setSearchParams((prev) => ({
      ...prev,
      [type]: selectedOption,
    }));
  };

  const handleClearFilter = () => {
    setIsApply(false);
    setIsClear(true);
    setSelectedOptions([], 'dataFieldSelected');
    setSelectedOptions([], 'dataContentSelected');
  };

  const isShowClearFilter = useMemo(() => {
    return !isEmpty(dataFieldSelected) || !isEmpty(dataContentSelected);
  }, [dataFieldSelected, dataContentSelected]);

  useEffect(() => {
    setSearchInput(keyword);
  }, [keyword]);

  return (
    <>
      {!isEdit && (
        <div>
          <Row align="bottom" justify="space-between">
            <ModelTC
              info={{
                type: 'deleteBuMapping',
                visible: updateStatusVisible,
                setVisible: setUpdateStatusVisible,
                handleSubmit: () => handleDeleteRecord(),
                loading,
                onClose: () => {
                  setUpdateStatusVisible(false);
                },
              }}
            />
            <Col span={16}>
              <Row align="bottom" gutter={8}>
                <Col span={18}>
                  <input
                    data-testid="search-input"
                    placeholder="Search Business Unit"
                    className={styles.searchInputField}
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                </Col>
                <Col>
                  <BasicButton mode="teal" onKeyDown={() => {}} onClick={() => setSelectedOptions(searchInput, 'keyword')}>
                    Search
                  </BasicButton>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row align="bottom" justify="space-between" gutter={8}>
                <Col>
                  <BasicButton
                    onKeyDown={() => {}}
                    onClick={() => deleteSelecteds.length > 0 && setUpdateStatusVisible(true)}
                    className={`${styles.button} ${!deleteSelecteds.length ? styles.inActiveDelBtn : styles.activeDelBtn}`}
                    disabled={!deleteSelecteds.length}
                  >
                    Confirm Delete Mapping
                  </BasicButton>
                </Col>
                <Col>
                  <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleAddNew}>
                    Add New
                  </BasicButton>
                </Col>
                <Col>
                  <div className={styles.icons}>
                    <img
                      src={showFilter ? finder_filter_enable : finder_filter_disable}
                      alt="Filter"
                      onKeyDown={() => {}}
                      onClick={() => setShowFilter(!showFilter)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {showFilter && (
            <>
              <Row style={{ marginTop: 22 }}>
                <Col>
                  <Row gutter={16}>
                    <Col className={styles.wrapperFilter}>
                      <CommonFilterManaging
                        setIsSearch={() => {}}
                        setIsApply={setIsApply}
                        hasCustom={true}
                        selectedOptions={dataFieldSelected}
                        setSelectedOptions={(state) => {
                          setSelectedOptions(state, 'dataFieldSelected');
                        }}
                        allOptions={
                          !isEmpty(fetch_filter_option?.data_field)
                            ? [
                                {
                                  label: '(Select All)',
                                  value: 'all',
                                },
                                ...fetch_filter_option.data_field,
                              ]
                            : []
                        }
                        placeholder="Data Field"
                      />
                      {!isEmpty(dataFieldSelected) && <img className={styles.activeIcon} src={circle_checked} alt="" />}
                    </Col>
                    <Col className={styles.wrapperFilter}>
                      <CommonFilterManaging
                        setIsSearch={() => {}}
                        setIsApply={setIsApply}
                        hasCustom={true}
                        selectedOptions={dataContentSelected}
                        setSelectedOptions={(state) => {
                          setSelectedOptions(state, 'dataContentSelected');
                        }}
                        allOptions={
                          !isEmpty(filterOptions?.data_content)
                            ? [
                                {
                                  label: '(Select All)',
                                  value: 'all',
                                },
                                ...filterOptions.data_content,
                              ]
                            : []
                        }
                        placeholder="Data Content"
                        isDisabled={isEmpty(filterOptions?.data_content)}
                      />
                      {!isEmpty(dataContentSelected) && <img className={styles.activeIcon} src={circle_checked} alt="" />}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {isShowClearFilter && (
                <Row>
                  <Col>
                    <button className={styles.clearFilterButton} onKeyDown={() => {}} onClick={handleClearFilter}>
                      Clear Filters
                    </button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      )}

      {isEdit && (
        <Row align="bottom" justify="end">
          <AiButton onKeyDown={() => {}} onClick={() => setIsEdit(false)}>
            Back
          </AiButton>
        </Row>
      )}
    </>
  );
}
