import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { right_head_arrow } from '../../assets/img';
import {
  calculateYearMonthBetween,
  formatDate,
  handleShowRetirementDate,
  retiredAge,
} from '../../utils/helper';
import styles from './successionPlanningView.module.scss';

const ToolTipTitle = ({ incumbent }) => {
  const date = new Date();
  return (
    <div className={styles.tooltip}>
      <div>
        <div>
          <div>
            <span>{incumbent?.name}</span>
          </div>
          <div>
            <span>YIP: </span>
            {calculateYearMonthBetween(incumbent?.date_in_position, date)}
          </div>
        </div>
        <div>
          <div>
            <span>{incumbent?.staff_id}</span>
          </div>
          <div>
            <span>Age: </span>
            {calculateYearMonthBetween(incumbent?.date_of_birth, date, true)}
          </div>
        </div>
      </div>
      <div>
        <span>YISG: </span>
        {`${incumbent?.salary_grade} (${calculateYearMonthBetween(incumbent?.date_in_sg, date)})`}
      </div>
      <div>
        <span>Retirement: </span>
        {`${handleShowRetirementDate(incumbent?.retirement_date)} ${retiredAge(
          incumbent?.retirement_date,
          incumbent?.date_of_birth
        )}`}
      </div>
      <div>
        <span>Performance Rating: </span>
        {incumbent?.performance_rating}
      </div>
    </div>
  );
};
ToolTipTitle.propTypes = {
  incumbent: PropTypes.object,
};

const SPViewPositionItem = (props) => {
  const { item, styleWidth } = props;
  const history = useHistory();

  const onViewSuccessionPlanning = () => {
    history.push(`/agenda/succession-planning-dashboard/${item.position_code}`);
  };

  return (
    <div className={styles.body_position}>
      <div style={{ width: styleWidth[0] }}>
        <div className={styles.position_name}>{item?.position_name || '--'}</div>
      </div>
      <div style={{ width: styleWidth[1] }}>
        <Tooltip
          overlayClassName={styles.tooltipContainer}
          title={<ToolTipTitle incumbent={item?.incumbent} />}
        >
          <div className={styles.font_general}>{item?.incumbent?.name || '--'}</div>
        </Tooltip>
      </div>
      <div style={{ width: styleWidth[2] }}>
        <div className={styles.font_general}>{item?.department || '--'}</div>
      </div>
      <div style={{ width: styleWidth[3] }}>
        <div className={styles.font_general}>{item?.division || '--'}</div>
      </div>
      <div style={{ width: styleWidth[4] }}>
        <div className={styles.font_general}>{item?.business || '--'}</div>
      </div>
      <div style={{ width: styleWidth[5] }}>
        <div className={styles.font_dimmed}>{item?.platform || '--'}</div>
      </div>
      <div style={{ width: styleWidth[6] }}>
        <div className={styles.font_dimmed}>{formatDate(item?.last_approved_date) || '--'}</div>
      </div>
      <div style={{ width: styleWidth[7] }}>
        <img
          src={right_head_arrow}
          alt="right_head_arrow"
          onKeyDown={() => {}}
          onClick={onViewSuccessionPlanning}
        />
      </div>
    </div>
  );
};

SPViewPositionItem.propTypes = {
  item: PropTypes.object,
  styleWidth: PropTypes.array,
};

export default SPViewPositionItem;
