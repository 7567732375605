import { Table } from 'antd';
import React from 'react';
import * as styles from './data-table.module.scss';
import * as SC from './styled';

export default function DataTable({ data, query, setQuery }) {
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.font}>{(query.page - 1) * query.limit + 1 + index}.</span>
      ),
      width: '10%',
      align: 'left',
    },

    {
      title: 'Position Name',
      dataIndex: 'position_name',
      width: '40%',
      align: 'left',
    },
    {
      title: 'Position Code',
      dataIndex: 'position_code',
      width: '10%',
      align: 'left',
    },
    {
      title: 'Business Unit',
      dataIndex: 'business_unit',
      width: '20%',
      align: 'left',
    },
    {
      title: 'Job Grade',
      dataIndex: 'role_level',
      width: '20%',
      align: 'left',
    },
  ];

  const fromResult = (query.page - 1) * query.limit + 1;
  const toResults = (query.page - 1) * query.limit + query.limit;

  const paginationConfig = {
    current: query.page,
    total: query.totalPage,
    showSizeChanger: false,
    defaultPageSize: query.limit,
    onChange: (pageNum) =>
      pageNum !== query.page &&
      setQuery((prev) => {
        return {
          ...prev,
          page: pageNum,
        };
      }),
    showTotal: () => (
      <>
        Showing <b>{fromResult}</b> to&nbsp;
        <b>{toResults > query.totalPage ? query.totalPage : toResults}</b> of&nbsp;
        <b>{query.totalPage}</b> results
      </>
    ),
  };

  return (
    <div style={{ marginTop: 24, marginBottom: 150 }}>
      <SC.TableManagement mode="historical-dashboard-detail">
        <Table
          columns={columns}
          dataSource={data.map((item, _index) => ({
            key: item.position_code,
            position_name: <span className={styles.positionName}>{item.position_name}</span>,
            position_code: <span className={styles.font}>{item.position_code}</span>,
            business_unit: <div className={styles.font}>{item.business_unit}</div>,
            role_level: <div className={styles.font}>{item.role_level}</div>,
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
}
