import { cloneDeep } from 'lodash';
import { filterDemongraphic } from '../utils/filterDemographic';
import axiosClient from './axiosClient';

export const api = {
  getAll: (params) => {
    const url = '/users';
    return axiosClient.get(url, { params });
  },
};

const userApi = {
  getAll: (params) => {
    const url = '/users';
    return axiosClient.get(url, { params });
  },

  getUserById: (userId) => {
    const url = `employee-roles`;
    const isLogin = Boolean(Number(process.env.REACT_APP_BYPASS_LOGIN) || 0);
    if (isLogin === true)
      return axiosClient.get(url, {
        headers: {
          user: userId,
        },
      });
    return axiosClient.get(url);
  },

  getServerStatus: () => {
    const url = '/server-status';
    return axiosClient.get(url);
  },

  getAgeGender: (params, filter) => {
    const url = '/talent-statistic-by-age';
    let itemParam = filterDemongraphic(filter);
    const options = {
      headers: params,
      params: itemParam,
    };
    return axiosClient.get(url, options);
  },

  getTalentCategory: (params, filter) => {
    const url = '/talent-statistic-by-category';
    let itemParam = filterDemongraphic(filter);
    const options = {
      headers: params,
      params: itemParam,
    };
    return axiosClient.get(url, options);
  },

  getSearchList: (role, body) => {
    const url = '/talent-profiles';
    const options = {
      headers: { ...role },
      params: body,
    };
    return axiosClient.get(url, options);
  },

  getUsers: (roleId, params) => {
    const { keyword, limit } = params;
    const url = `/users/_search`;

    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params: {
        keyword: keyword,
        _limit: limit,
      },
    });
  },

  login: (body) => {
    const url = `/login`;
    return axiosClient.post(url, body);
  },
  getSecretarial: ({ roleUser, params }) => {
    const { keyword, limit } = params;
    const url = `/get-role/meeting-secretariat`;

    return axiosClient.get(url, {
      headers: {
        role: roleUser.roleId,
      },
      params: {
        keyword: keyword,
        _limit: limit,
      },
    });
  },
  logAction: ({ url, type }, accessToken) => {
    if (type === 'TIMEOUT') {
      let newAxiosClient = cloneDeep(axiosClient);
      newAxiosClient.interceptors.request.use(
        async (config) => {
          if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
          }

          config.headers['Content-Type'] = 'application/json';
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
      return newAxiosClient.post('/user-access/log', { url: '', type: 'TIMEOUT' });
    }
    return axiosClient.post('/user-access/log', { url, type });
  },

  byPassLogin: (email) => {
    const url = `/auth/login`;
    return axiosClient.post(url, {
      email,
    });
  },

  getTokenImage: (headers) => {
    const url = `/user-photo/token`;
    if (headers) return axiosClient.get(url, { headers });
    return axiosClient.get(url);
  },
};

export default userApi;
