import { Tooltip } from 'antd';
import { isNaN } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  calculateTime,
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  hideOtherTalentLabel,
} from '../../../../../../utils/helper';
import { positionsSelector } from '../../../../selector';
import styles from '../Box/box.module.scss';

const CardItem = React.forwardRef((props, ref) => {
  const { item, index } = props;
  const positions = useSelector(positionsSelector);
  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }

    return '';
  };

  return (
    <div style={{ width: '437px', backgroundColor: '#fff' }}>
      <div className={styles.card_box} ref={ref}>
        <div className={`col-3 ${styles.left}`}>
          <div className="row flex-column no-gutters  align-items-center h-100">
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.image}>
              <img src={decodeBase64ToImage(item?.image)} alt="avatar" />
            </div>
            <div style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>
              {item?.staff_id}
            </div>
            <div className={styles.position}>
              <Tooltip
                placement="bottom"
                title={handleShowTextTooltip(item, positions)}
                color={'black'}
                key={'black'}
                overlayInnerStyle={{ borderRadius: '6px' }}
              >
                {handleShowTooltip(item, positions)}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={`col-9 ${styles.right}`} style={{ padding: '10px' }}>
          <div className="row  no-gutters justify-content-between align-item?s-stretch">
            <div className={styles.name}>
              <Link
                to={`/profile/${item?.staff_id}`}
                target="_blank"
                className={styles.recommendation__name}
                draggable="false"
              >
                {item?.birth_name} &#160;
                {!item?.isUnsearchable &&
                  !hideOtherTalentLabel(item.assignment_status_type, item.role_level, item.ct) &&
                  !['119463', '1032501'].includes(item?.staff_id) &&
                  handleShowCT(item.ct)}
                {!item?.isUnsearchable
                  ? item?.matching_percentage
                    ? `${item?.matching_percentage}%`
                    : ''
                  : ''}
              </Link>
            </div>
          </div>

          <div className="mb-2">
            <span>Position: </span>
            {item?.position_name}
          </div>
          <div className="mb-2">
            <span>YIP: </span>
            {calculateTime(item?.date_in_position)}&#160;&#160;&#160;<span>YISG: </span>
            {item?.salary_grade} ({calculateTime(item?.date_in_sg)})
          </div>
          <div className="mb-2">
            <span>Age: </span>
            {item?.age}&#160;&#160;&#160;<span>Retirement: </span>
            {handleShowRetirementDate(item.retirement_date)} {handleShowRetirement(item)}
          </div>
          <div className="mb-2">
            <span>Performance Rating: </span>
            {item?.performance_rating}
          </div>
        </div>
      </div>
    </div>
  );
});
export default CardItem;
