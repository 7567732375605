import styled, { css } from 'styled-components';
import { color, font, fontSize } from '../../../../assets/common';
import { AGENDA_STATUS_IMG } from '../../../../utils/constants';

export const AiStatus = styled.div`
  /* position: absolute; */
  /* right: 0;
  top: 55%; */
  /* transform: translateY(-50%); */
  display: flex;
  align-items: center;
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${fontSize.small};
  line-height: 16px;
  letter-spacing: 0.25px;
  width: fit-content;
  ${(props) => {
    switch (props.status) {
      case AGENDA_STATUS_IMG.READY:
      case AGENDA_STATUS_IMG.FINALIZED:
        return css`
          color: ${color.funGreen};
          background: ${color.frostee};
        `;
      case AGENDA_STATUS_IMG.FORWARD:
        return css`
          color: #005db9;
          background: #e2f1fc;
        `;
      case AGENDA_STATUS_IMG.PROGRESS:
        return css`
          color: ${color.tangerine};
          background: ${color.halfDutchWhite};
        `;
      default:
        return;
    }
  }}

  padding: 4px;
  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
`;
