import styled from 'styled-components';
import { color, font, fontSize } from '../../../../assets/common';

export const AiContainerAgendaItem = styled.div`
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
`;
export const AiTime = styled.p`
  font-family: ${font.inter};
  color: ${color.brightGray2};
  font-size: ${fontSize.medium};
  margin-bottom: 5px;
`;
export const AiTitleAgenda = styled.p`
  font-family: ${font.inter};
  color: ${color.brightGray2};
  font-size: ${fontSize.medium};
  font-weight: 600;
  margin-bottom: 0;
  word-break: break-word;
`;
export const AiDesc = styled.p`
  font-family: ${font.inter};
  color: ${color.brightGray2};
  font-size: ${fontSize.normal};
  font-weight: 500;
  margin-bottom: 2px;
`;
export const AiStatus = styled.div`
  /* position: absolute; */
  /* right: 0;
  top: 55%; */
  /* transform: translateY(-50%); */
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${fontSize.small};
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #005db9;
  background: #e2f1fc;
  padding: 4px;
  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
`;
export const AiGroupAction = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;
export const AiImg = styled.img`
  width: 16px;
  height: 16px;
`;
