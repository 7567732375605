import { isArray, isEmpty, size } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import Rectangle from '../../assets/img/Rectangle.svg';
import { EDGE_OPTION_LABEL, EDGE_OPTION_VALUE } from '../../utils/constants';
import Checkbox from '../CheckBox/CheckBox';
import styles from './dropdown-edge.module.scss';

const DropdownEDGE = ({
  listSelectType,
  setListSelectType,
  isPrinting,
  onChangeSelectedYear,
  setIsAllBtnDisabled,
}) => {
  const optionsEDGE = [
    {
      label: EDGE_OPTION_LABEL.SMA,
      value: EDGE_OPTION_VALUE.SMA,
    },
    {
      label: EDGE_OPTION_LABEL.EXTERNAL,
      value: EDGE_OPTION_VALUE.EXTERNAL,
    },
    {
      label: EDGE_OPTION_LABEL.MODEL,
      value: EDGE_OPTION_VALUE.MODEL,
    },
  ];
  const selectAll = {
    label: EDGE_OPTION_LABEL.ALL,
    value: EDGE_OPTION_VALUE.ALL,
  };
  const [select, setSelect] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    handleSetDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);
  const handleShowSelection = () => {
    let listOptions = ' ';
    if (listSelectType.length > 0 && isArray(listSelectType)) {
      if (size(listSelectType) >= 3) {
        return 'All';
      }
      if (size(listSelectType) === 2) {
        listSelectType.forEach((ele) => {
          listOptions += ' ' + ele.label;
        });
        return String(listOptions).trim().replace(' ', '/');
      }
      if (size(listSelectType) === 1) {
        listSelectType.forEach((ele) => {
          listOptions += ' ' + ele.label;
        });
        return String(listOptions).trim();
      }
    } else {
      return 'SMA';
    }
  };
  const handleSearchChart = () => {
    let listSelect = select.filter((ele) => ele?.isChecked === true);
    if (listSelect && listSelect.length === 3) {
      setIsAllBtnDisabled(true);
    } else {
      setIsAllBtnDisabled(false);
    }
    setListSelectType(listSelect);
    onChangeSelectedYear(listSelect?.[0]?.label, listSelect.length);
    setIsActive(false);
  };
  const handleSetDefault = () => {
    let listChosen = [];
    let listSelection = [];
    if (isPrinting) {
      listSelection = optionsEDGE.map((ele) => {
        listChosen.push({ ...ele, isChecked: true });
        return { ...ele, isChecked: true };
      });
    } else {
      listSelection = optionsEDGE.map((ele) => {
        if (ele.label === EDGE_OPTION_LABEL.MODEL) {
          listChosen.push({ ...ele, isChecked: true });
          return { ...ele, isChecked: true };
        }
        return ele;
      });
    }

    setSelect(listSelection);
    setListSelectType(listChosen);
  };
  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={styles.dropdown__select}
        onKeyDown={() => {}}
        onClick={(e) => setIsActive(!isActive)}
      >
        <p>{handleShowSelection()}</p>
        <img src={Rectangle} alt="" />
      </div>
      {isActive && (
        <div className={styles.dropdown__list}>
          {!isEmpty(select) &&
            select.map((item, index) => {
              return (
                <div className={styles.dropdown__item} key={index}>
                  <Checkbox
                    item={item}
                    select={select}
                    setSelect={setSelect}
                    disabled={item?.disabled}
                    customStyle={true}
                  />
                </div>
              );
            })}
          <div className={styles.dropdown__item}>
            <Checkbox item={selectAll} select={select} setSelect={setSelect} customStyle={true} />
          </div>
          <div className={styles.dropdown__apply}>
            <button
              className={styles.dropdown__btn}
              onKeyDown={() => {}}
              onClick={() => handleSearchChart()}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default DropdownEDGE;
