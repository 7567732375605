import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BasicButton } from '../../../../../assets/common';
import AddBusinessUnit from '../../components/AddNewBusiness/AddNewBusiness';
import styles from './menu-bar.module.scss';

export default function MenuBar(props) {
  const {
    deleteSelecteds,
    isEdit,
    setIsEdit,
    setQuery,
    handleAddBusinessExist,
    handleAddBusinessSuccess,
    getBusinessList,
    query,
    setShowDeleteModal,
  } = props;

  const [searchInput, setSearchInput] = useState('');
  const [openAddNew, setOpenAddNew] = useState(false);

  const handleAddNew = () => {
    setOpenAddNew(true);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleSearch = () => {
    setQuery((prev) => {
      return {
        ...prev,
        keyword: searchInput,
        page: 1,
      };
    });
  };

  useEffect(() => {
    setSearchInput(query.keyword);
  },[query.keyword]);

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          {!isEdit && (
            <Row align="bottom" gutter={8}>
              <Col span={18}>
                <input
                  data-testid="search-input"
                  placeholder="Search Business Unit"
                  className={styles.searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                  onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                />
              </Col>
              <Col>
                <BasicButton mode="teal" onKeyDown={() => {}} onClick={() => handleSearch()}>
                  Search
                </BasicButton>
              </Col>
            </Row>
          )}
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              {!isEdit ? (
                <>
                  <BasicButton
                    mode="danger"
                    disabled={isEmpty(deleteSelecteds) ? true : false}
                    onKeyDown={() => {}}
                    onClick={handleDelete}
                    style={{ marginRight: '10px' }}
                  >
                    Confirm Delete Unit
                  </BasicButton>
                  <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleAddNew}>
                    Add New
                  </BasicButton>
                </>
              ) : (
                <BasicButton mode="cancel" onKeyDown={() => {}} onClick={() => setIsEdit('')}>
                  Back
                </BasicButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {openAddNew && (
        <AddBusinessUnit
          setIsVisible={setOpenAddNew}
          handleAddBusinessExist={handleAddBusinessExist}
          handleAddBusinessSuccess={handleAddBusinessSuccess}
          getBusinessList={getBusinessList}
          query={query}
          setQuery={setQuery}
        />
      )}
    </div>
  );
}
