import { Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import { deleteNote } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { DATE_TIME_FORMAT, FORM_TYPES } from '../../../../../utils/constants';
import * as SC from './styled';

export default function DataTable({ data, setCandidates, mode, setDeleteItems }) {
  const [deleteItem, setDeleteItem] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);

  const columns = [
    {
      title: 'Candidate Name',
      dataIndex: 'candidate_name',
      align: 'left',
      render: (text) => (
        <span style={{ color: '#3F3C4C', fontWeight: 700, display: 'block', maxWidth: '288px' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Candidate ID',
      dataIndex: 'staff_id',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
    {
      title: 'Readiness',
      dataIndex: 'readiness',
      render: (text) => <span style={{ color: '#999A9C' }}>{text?.name}</span>,
    },
    {
      title: 'Ranking',
      dataIndex: 'ranking',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{text?.name}</span>,
    },
    {
      title: 'Active Approved Successor',
      dataIndex: 'active_approved_successor',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{text?.name}</span>,
    },
    {
      title: 'Talent Review Date',
      dataIndex: 'talent_review_date',
      align: 'center',
      render: (text) => (
        <span style={{ color: '#999A9C' }}>
          {text ? moment(text).format(DATE_TIME_FORMAT.DATE_SPACE) : ''}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      align: 'center',
      render: (_, record) => {
        return (
          <div
            placement="bottomRight"
            style={{ minWidth: 110, cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() => {
              setDeleteItem(record);
              if (mode !== FORM_TYPES.EDIT) {
                handleDeleteItem(record);
              } else {
                setIsShowModal(true);
              }
            }}
          >
            <img src={deleteNote} alt={'delete'} />
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    hideSelectAll: true,
  };

  const handleDeleteItem = (record) => {
    let tempArr = JSON.parse(JSON.stringify(data));
    tempArr.forEach((elem, index) => {
      if (elem.key === record.key) {
        tempArr.splice(index, 1);
      }
    });
    setCandidates(tempArr);

    if (mode === FORM_TYPES.EDIT) {
      setIsShowModal(false);
      setDeleteItems((prevState) => {
        return [...prevState, deleteItem.key];
      });
    }
  };

  return (
    <div style={{ paddingTop: 24 }}>
      <SC.TableNewCandidate>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </SC.TableNewCandidate>
      <ModelTC
        info={{
          type: 'confirmRemoveSuccessor',
          visible: isShowModal,
          setVisible: setIsShowModal,
          handleSubmit: () => handleDeleteItem(deleteItem),
          onClose: () => setIsShowModal(false),
        }}
      />
    </div>
  );
}
