import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as styles from './top-talent-potential.module.scss';
import { BasicTable } from '../../../../assets/common';
import { isEmpty } from 'lodash';
import { ASSESSMENT_TYPE_VALUES } from '../ExternalAssessments/constant_assessments';
import { Spin } from 'antd';

const INIT_DATA = [
  {
    dimension: 'Learning Agilities (3 out of 5)',
    categories: [
      {
        category: 'Change Agility',
        fitPotential: '-',
      },
      {
        category: 'Mental Agility',
        fitPotential: '-',
      },
      {
        category: 'People Agility',
        fitPotential: '-',
      },
      {
        category: 'Results Agility',
        fitPotential: '-',
      },
      {
        category: 'Situational Self-Awareness',
        fitPotential: '-',
      },
    ],
    numberOfPotentialMet: '-',
  },
  {
    dimension: 'Traits (3 out of 4)',
    categories: [
      {
        category: 'Assertiveness',
        fitPotential: '-',
      },
      {
        category: 'Optimism',
        fitPotential: '-',
      },
      {
        category: 'Persistence',
        fitPotential: '-',
      },
      {
        category: 'Tolerance of Ambiguity',
        fitPotential: '-',
      },
    ],
    numberOfPotentialMet: '-',
  },
  {
    dimension: 'Drivers (2 out of 3)',
    categories: [
      {
        category: 'Challenge',
        fitPotential: '-',
      },
      {
        category: 'Collaboration',
        fitPotential: '-',
      },
      {
        category: 'Power',
        fitPotential: '-',
      },
    ],
    numberOfPotentialMet: '-',
  },
  {
    dimension: 'Derailments Risks (3 out of 3)',
    categories: [
      {
        category: 'Closed',
        fitPotential: '-',
      },
      {
        category: 'Micro Managing',
        fitPotential: '-',
      },
      {
        category: 'Volatile',
        fitPotential: '-',
      },
    ],
    numberOfPotentialMet: '-',
  },
];

const COLUMNS = [
  {
    title: 'Potential Dimension',
    width: 180,
    dataIndex: 'dimension',
    render: (text) => <div className={styles.text1}>{text}</div>,
  },
  {
    title: (
      <div className={styles.textFits}>
        <div>Potential</div>
      </div>
    ),
    dataIndex: 'dimension',
    onCell: () => ({
      style: {
        padding: 0,
      },
    }),
    render: (_, item) => (
      <div className={styles.fitContainer}>
        {item.categories.map(({ category, fitPotential }) => (
          <div key={category} className={styles.fitRow}>
            <div>{category}</div>
            <div className={styles.fitCell}>{fitPotential}</div>
          </div>
        ))}
      </div>
    ),
  },
  {
    title: 'No. of Potential met',
    align: 'center',
    width: 210,
    dataIndex: 'numberOfPotentialMet',
    render: (text) => <span className={styles.text2}>{text}</span>,
  },
];

const TopTalentPotential = (props) => {
  const { assessmentData, assessmentType, loading } = props;

  const data = useMemo(() => {
    if (isEmpty(assessmentData) || loading) return INIT_DATA;
    if (assessmentType === ASSESSMENT_TYPE_VALUES.All) {
      const potentialData = assessmentData.find(
        (item) => item.type === ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL
      );
      return potentialData?.data || INIT_DATA;
    }
    if (
      assessmentType === ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL &&
      assessmentData.type === ASSESSMENT_TYPE_VALUES.TOP_TALENT_POTENTIAL &&
      !isEmpty(assessmentData.data)
    ) {
      return assessmentData.data;
    }
    return INIT_DATA;
  }, [assessmentData, assessmentType, loading]);

  return (
    <div className={styles.wrapper}>
      {assessmentType === ASSESSMENT_TYPE_VALUES.All && (
        <div className={styles.title}>Top Talent Potential (KF)</div>
      )}
      <Spin spinning={loading}>
        <BasicTable
          bordered
          pagination={false}
          styles={{ borderRadius: 6 }}
          dataSource={data}
          columns={COLUMNS}
        />
      </Spin>
    </div>
  );
};

export default TopTalentPotential;

TopTalentPotential.propTypes = {
  assessmentData: PropTypes.object || PropTypes.array,
  assessmentType: PropTypes.string,
  loading: PropTypes.bool,
};
