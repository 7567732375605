import { Button } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { info_disclaimer } from '../../assets/img';
import downloadFile from '../../assets/img/Download.svg';
import file from '../../assets/img/fileIcon.svg';
import {
  addDocument,
  cancelUploadDocument,
  deleteDocument,
  fetchDisclaimerRecords,
} from '../../services/talentProfiles';
import { MESSAGE_TYPES, PERMISSION, STATUS_UPLOAD } from '../../utils/constants';
import AlertMessage from '../AlertMessage/AlertMessage';
import styles from './document-modal.module.scss';
import { useShowAlertUploadDownloadWhenNavigate } from '../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../hooks/useShowAlertWhenRefresh';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataCancelUploadDocument, setDataCancelUploadDocument } from '../../store/cancelUploadSlice';

const DocumentList = ({
  index,
  data,
  onPreview,
  onDownload,
  isCustomFiles,
  onItemSelected,
  setIsError,
  setTextError,
  selectedFiles,
  loading,
}) => {
  const [isSelected, setIsSelected] = useState(() => {
    return selectedFiles?.includes(data);
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 mb';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const onSelected = (data) => {
    if (!isSelected) {
      if (data?.is_open_text !== 'manual') {
        setIsError(true);
        setTextError("Can't select this document!");
        return;
      }
      setIsSelected(true);
      onItemSelected(index, 'add');
    } else {
      setIsSelected(false);
      onItemSelected(index, 'remove');
    }
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <span
          style={{
            fontSize: '14px',
            lineHeight: '19px',
            fontStyle: 'normal',
            fontWeight: 'normal',
          }}
        >
          {data?.file_name}
        </span>
      </Tooltip>
    );
  };

  useEffect(() => {
    setIsSelected(selectedFiles?.includes(data));
  }, [selectedFiles, data]);

  return (
    <div className="col-6">
      <div
        className={styles.content_general}
        style={{
          border: isSelected ? '1px solid #344563' : '1px solid #d9d9d9',
          cursor: isCustomFiles && 'pointer',
          display: isCustomFiles && 'flex',
          gap: isCustomFiles && '15px',
        }}
        onKeyDown={() => {}}
        onClick={() => {
          if (isCustomFiles) {
            onSelected(data);
          }
        }}
      >
        {isCustomFiles && data?.is_open_text === 'manual' && (
          <div className={styles.content_document_left} data-testid={`selected-icon-${index}`}>
            <div
              data-testid={`click-selected-${index}`}
              className={styles.circle}
              style={
                isSelected
                  ? { border: '1px solid #344563', background: '#344563' }
                  : { border: '1px solid #e5e5e5', background: '#fff' }
              }
            >
              {isSelected && (
                <div data-testId={`icon-active-${index}`} className={styles.circle_active}></div>
              )}
            </div>
          </div>
        )}

        <div className={styles.content_document_right}>
          <div className={styles.content_file}>
            <div className={styles.file_info}>
              <div className={styles.file} style={{ border: '2px solid #344563' }}>
                <img src={file} alt="" />
              </div>
              <OverlayTrigger
                placement="top-end"
                delay={{ show: 80, hide: 0 }}
                overlay={renderTooltip}
              >
                <div
                  className={styles.file_name}
                  onKeyDown={() => {}}
                  onClick={() => {
                    if (!isCustomFiles) {
                      onPreview(data?.file_url, data?.file_name);
                    }
                  }}
                  style={{
                    cursor: !isCustomFiles && 'pointer',
                  }}
                  data-testid={`click-preview-${index}`}
                >
                  {data?.file_name}
                </div>
              </OverlayTrigger>
            </div>
            {loading.status && loading.location === index && loading.type === 'modal' ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <img
                src={downloadFile}
                alt=""
                className={styles.icon_download}
                style={{
                  cursor: !isCustomFiles && 'pointer',
                  position: 'absolute',
                  top: '5px',
                  right: 0,
                }}
                onKeyDown={() => {}}
                onClick={() => {
                  if (!isCustomFiles) {
                    onDownload(data?.file_url, data?.file_name, index, 'modal');
                  }
                }}
                data-testid={`click-download-${index}`}
              />
            )}
          </div>

          <div className={styles.info_size_lasted_file}>
            <div className={styles.file_size}>Size: {formatBytes(data?.size)}</div>
            <div className={styles.file_lasted}>
              Last Edited: {moment(data?.last_editted_at).format('Do MMMM YYYY')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const validateFile = (file) => {
  const { type, size } = file;
  const isLt100M = size / 1024 / 1024 < 100;

  const isPdfOrWordOrExcel =
    type === 'application/pdf' ||
    type === 'application/.pdf' ||
    type === 'application/.csv' ||
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    type === 'application/vnd.ms-excel' ||
    type === 'application/vnd.xlsx' ||
    type === 'application/vnd.xls' ||
    type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    type === 'application/msword' ||
    type === '.doc' ||
    type === '.docx';

  let isFileValid = true;
  let errorMessages = [];

  if (!isPdfOrWordOrExcel) {
    isFileValid = false;
    errorMessages.push('Unsupported format. Please try again !');
  }

  if (isPdfOrWordOrExcel) {
    if (!isLt100M) {
      isFileValid = false;
      errorMessages.push('File exceeds maximum allowed upload size of 100M. Please try again !');
    }
  }

  return { isFileValid, errorMessages };
};

const Disclaimer = ({ records }) => {
  return (
    <>
      Please ensure your documents following the standard naming guide as follows: 8 digits staff
      id_record list_year; e.g. 01056309_DC HR_2022. For more details naming guide, please refer
      User Guide in Help Document. If there is new document not under{' '}
      <span className={styles.info_disclaimer}>
        <img src={info_disclaimer} alt="info_disclaimer"></img> record list
        <div className={styles.records}>
          Record List
          <ul>
            {records?.map((record) => (
              <li key={record.id}>{record.name}</li>
            ))}
          </ul>
        </div>
      </span>
      kindly approach TTM team for further assistance.
    </>
  );
};

const DocumentModal = ({
  profileId,
  isOpen,
  setIsOpen,
  data,
  onPreview,
  onDownload,
  reload,
  setReload,
  loading,
  roleUser,
  isHasEditPermission,
}) => {
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [textError, setTextError] = useState('');
  const [textSuccess, setTextSuccess] = useState('');
  const [isCustomFiles, setIsCustomFiles] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [records, setRecords] = useState([]);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate('upload');
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();
  const dispatch = useDispatch();
  const { uploadDocument } = useSelector((state) => state.cancelUpload);
  const { id } = useParams();

  const { permissions } = roleUser;

  const isHasDeletePermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_DELETE_DOCUMENT);
  }, [permissions]);

  const isHasAddPermission = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_ADD_DOCUMENT);
  }, [permissions]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const { isFileValid, errorMessages } = validateFile(fileUploaded);
    if (!isFileValid) {
      setIsError(true);
      setIsSuccess(false);
      setTextError(errorMessages[0]);
    }
    setFile(fileUploaded);
  };

  const onItemSelected = (index, type) => {
    const item = data[index];
    if (type === 'add') {
      let documents = [...selectedFiles];
      documents.push(item);
      setSelectedFiles(documents);
    } else {
      let documents = [...selectedFiles];
      const result_documents = documents.filter((value) => value !== item);
      setSelectedFiles(result_documents);
    }
  };

  const onDeleteDocs = async () => {
    const arr_deleted = [];
    selectedFiles.forEach((item) => {
      arr_deleted.push({
        url: item.file_url,
      });
    });
    try {
      const response = await deleteDocument({ profileId, roleUser, body: arr_deleted });
      if (response && response?.data?.code === 200) {
        setSelectedFiles([]);
        setReload(!reload);
        setIsError(false);
        setIsSuccess(true);
        setTextSuccess('Delete file is successful.');
        setIsCustomFiles(false);
      }
    } catch (e) {
      setIsSuccess(false);
      setIsError(true);
      setTextError('Delete file is failed !');
    }
  };

  useEffect(() => {
    if (file) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      async function fetchApi() {
        try {
          setIsLoading(true);
          setIsUploadingOrDownloading(true);
          setShowAlertRefresh(true);
          dispatch(setDataCancelUploadDocument({ staff_id: id, file_name: file.name }));
          const response = await addDocument({ profileId, roleUser, file }, source);
          if (response && response?.data?.code === 200) {
            setReload(!reload);
            setIsError(false);
            setIsSuccess(true);
            setTextSuccess('Add file is successful.');
          }
        } catch (e) {
          if (axios.isCancel(e)) {
            const bodyCancelled = {
              staff_id: id,
              status: STATUS_UPLOAD.CANCEL,
              file_name: file.name,
            };
            cancelUploadDocument(bodyCancelled);
          }
          dispatch(clearDataCancelUploadDocument());
          setIsSuccess(false);
          setIsError(true);
          setTextError('Add file is failed !');
        } finally {
          setIsLoading(false);
          setIsUploadingOrDownloading(false);
          setShowAlertRefresh(false);
        }
      }
      fetchApi();
      return () => {
        source.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, profileId]);

  useEffect(() => {
    (async function fetchRecords() {
      try {
        const res = await fetchDisclaimerRecords();
        if (res.status === 200) {
          setRecords(res.data.result);
        }
      } catch (error) {
        setRecords([]);
        console.error(error);
      }
    })();
  }, []);

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        setIsOpen(false);
        setSelectedFiles([]);
        setIsCustomFiles(false);
        setIsError(false);
        setIsSuccess(false);
      }}
      dialogClassName={styles.view_note}
      aria-labelledby="example-custom-modal-styling-title"
      scrollable={true}
      data-testid="documents-modal"
    >
      <Modal.Header className={styles.modal_header} closeButton>
        <h1 data-testid="view-document-title">Documents</h1>
      </Modal.Header>

      <div className={styles.disclaimer}>
        <AlertMessage
          type={MESSAGE_TYPES.NORMAL}
          message={<span className={styles.disclaimer_title}>Disclaimer</span>}
          description={<Disclaimer records={records} />}
          closable={false}
        />
      </div>

      <div className={styles.content}>
        {isHasDeletePermission && (
          <div
            className={styles.button}
            onKeyDown={() => {}}
            onClick={() => {
              if (selectedFiles?.length > 0) {
                setSelectedFiles([]);
              }
              setIsCustomFiles(!isCustomFiles);
            }}
            data-testid="click-custom-file"
          >
            Delete File
          </div>
        )}
        {isHasAddPermission && (
          <div
            className={styles.button_add}
            onKeyDown={() => {}}
            onClick={handleClick}
            data-testid="click-add-file"
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                data-testid="spinner"
              ></span>
            )}
            Add File
          </div>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={hiddenFileInput}
          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, .xlsx, .xls, .pdf"
          value=""
          onChange={handleChange}
          data-testid="input-file"
        />
      </div>

      {isError && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          style={{ marginLeft: '25px', marginRight: '30px' }}
          role="alert"
          data-testid="message-error"
        >
          {textError}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onKeyDown={() => {}}
            onClick={() => setIsError(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      {isSuccess && (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginLeft: '25px', marginRight: '35px' }}
          role="alert"
          data-testid="message-success"
        >
          {textSuccess}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onKeyDown={() => {}}
            onClick={() => setIsSuccess(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      <Modal.Body className={styles.modal_body}>
        {!!data && data?.length > 0 && (
          <div className={styles.menu} data-testid="document-list">
            <div className="row" style={{ gap: '30px 0px' }}>
              {data?.map((item, idx) => (
                <DocumentList
                  key={idx}
                  index={idx}
                  data={item}
                  loading={loading}
                  onDownload={onDownload}
                  onPreview={onPreview}
                  isCustomFiles={isCustomFiles}
                  onItemSelected={onItemSelected}
                  setIsError={setIsError}
                  setTextError={setTextError}
                  selectedFiles={selectedFiles}
                />
              ))}
            </div>
          </div>
        )}
      </Modal.Body>

      <div className={styles.content_footer}>
        {isCustomFiles && (
          <div
            data-testid="cancel"
            className={styles.button_cancel}
            onKeyDown={() => {}}
            onClick={() => {
              setIsOpen(false);
              setIsCustomFiles(false);
              setSelectedFiles([]);
              setIsError(false);
              setIsSuccess(false);
            }}
          >
            Cancel
          </div>
        )}

        {isHasEditPermission && isCustomFiles && (
          <Button
            data-testid="click-delete"
            disabled={selectedFiles.length <= 0}
            className={styles.button_delete}
            style={{
              backgroundColor: selectedFiles.length > 0 && '#344563',
              color: selectedFiles.length > 0 && 'white',
            }}
            onKeyDown={() => {}}
            onClick={onDeleteDocs}
          >
            Delete
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default DocumentModal;
