import { isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  AUDIT_LOG_FILTER_OPTIONS,
  AUDIT_LOG_FILTERS,
  FUNCTION_LIST_FILTER_OPTIONS,
  FUNCTION_LIST_FILTERS,
  MULTIPLE_FILTER_TYPE,
  SELECT_ALL_LABEL,
  SG_JG_FILTER_OPTIONS,
  SG_JG_FILTERS,
} from '../../../../../utils/constants';
import DropdownTreeSelectContainer from '../../../../TalentFinderPage/DropdownTreeSelectContainer/DropdownTreeSelectContainer';

let tempFilter = {
  sp_management_filter: [],
  action_filter: [],
  user_filter_type: [],
  role_level: [],
  jg_in_p_series: [],
  function_list: [],
};

let filterTabActive = [];
let preFilterOptions = {};

const AuditLogFilter = (props) => {
  const { filterOptions, setFilterFinderData, filterType, isClearFilter = false, minWidth } = props;
  const [auditLogFilterOptions, setFilterOptions] = useState({});
  const [auditLogFilters, setAuditLogFilters] = useState([]);
  const [isBlur, setIsBlur] = useState(false);

  // Set filter data
  useEffect(() => {
    switch (filterType) {
      case MULTIPLE_FILTER_TYPE.AUDIT_LOG:
        setAuditLogFilters(AUDIT_LOG_FILTERS);
        break;
      case MULTIPLE_FILTER_TYPE.USER_ACTIVITY_LOG:
        setAuditLogFilters(AUDIT_LOG_FILTERS);
        break;
      case MULTIPLE_FILTER_TYPE.SG_JG_MAPPING:
        setAuditLogFilters(SG_JG_FILTERS);
        break;
      case MULTIPLE_FILTER_TYPE.FUNCTION_LIST:
        setAuditLogFilters(FUNCTION_LIST_FILTERS);
        break;
      default:
        break;
    }
  }, [filterType]);

  const cloneState = (state) => {
    return JSON.parse(JSON.stringify(state));
  };

  const updateStateData = (filterName, options, newData) => {
    tempFilter[filterName] = options;
    setFilterOptions(newData);
  };

  const getAllKeys = (tree) => {
    let result = [];
    tree.forEach((item) => {
      let childKeys = [];
      if (item?.children) {
        childKeys = getAllKeys(item.children);
      }
      result.push(...[{ label: item?.label, checked: item?.checked }, ...childKeys]);
    });

    return result;
  };

  const onChange = (currentNode) => {
    const filterName = currentNode?.path ? currentNode?.path.split('.')[0] : '';
    let indexArr = getIndexList(currentNode?.path);
    const newData = cloneState(auditLogFilterOptions);
    let options = newData[filterName];
    let selectedOption;

    const optionParentLv2 = options[indexArr[0]];

    const handleCheckedParent = (parentOption) => {
      const optionsChecked = parentOption.children.filter((optionLv2) => {
        return optionLv2.checked === true;
      });

      if (optionsChecked.length > 0) {
        parentOption.checked = true;
      } else {
        parentOption.checked = false;
      }
    };

    switch (indexArr.length) {
      case 1: //onChange lv1 of dropdown select tree
        selectedOption = options[indexArr[0]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        options.forEach((option) => {
          if (option.label !== currentNode.label) {
            option.expanded = false;

            if (option.children?.length) {
              option.children.forEach((childItem) => {
                childItem.expanded = false;
              });
            }
          }
        });

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
            childOption.expanded = true;

            if (childOption.children?.length) {
              childOption.children.forEach((i) => {
                i.checked = currentNode.checked;
              });
            }
          });
        }
        break;
      case 2: //onChange lv2 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]];
        selectedOption.checked = currentNode.checked;
        selectedOption.expanded = true;

        if (selectedOption.children?.length) {
          selectedOption.children.forEach((childOption) => {
            childOption.checked = currentNode.checked;
          });
        }

        handleCheckedParent(optionParentLv2);
        break;
      case 3: //onChange lv3 of dropdown select tree
        selectedOption = options[indexArr[0]].children[indexArr[1]].children[indexArr[2]];
        selectedOption.checked = currentNode.checked;
        const optionParentLv3 = options[indexArr[0]]?.children[indexArr[1]];
        handleCheckedParent(optionParentLv3);
        handleCheckedParent(optionParentLv2);
        break;
      default:
    }

    //handle checked (Select All)
    const allKeys = getAllKeys(options);
    const tempUncheckedItem = allKeys.filter(
      (item) => !item.checked && item.label !== SELECT_ALL_LABEL
    );
    if (tempUncheckedItem.length > 0) {
      options[0].checked = false;
    } else {
      options[0].checked = true;
    }

    updateStateData(filterName, options, newData);
  };

  const onClickSelectAll = (filterName, isChecked) => {
    const newData = cloneState(auditLogFilterOptions);
    assignObjectPaths(newData[filterName], filterName, isChecked);
    updateStateData(filterName, newData[filterName], newData);
  };

  const onNodeToggle = (currentNode) => {
    const filterName = currentNode?.path ? currentNode?.path.split('.')[0] : '';
    let indexArr = getIndexList(currentNode?.path);
    const newData = cloneState(auditLogFilterOptions);
    const options = newData[filterName];
    let currentOption;

    const handleExpandChild = (option, isExpanded) => {
      if (option.children?.length) {
        option.children.forEach((childItem) => {
          childItem.expanded = isExpanded;
        });
      }
    };

    switch (indexArr.length) {
      case 1: //Expand level 1
        currentOption = options[indexArr[0]];

        if (currentNode.expanded) {
          currentOption.expanded = true;

          options.forEach((option) => {
            if (option.label !== currentNode.label) {
              option.expanded = false;
            }
            handleExpandChild(option, false);
          });

          handleExpandChild(currentOption, true);
        } else {
          currentOption.expanded = false;
          handleExpandChild(currentOption, false);
        }
        break;
      case 2: //Expand level 2
        currentOption = options[indexArr[0]].children[indexArr[1]];

        if (!currentNode.expanded) {
          currentOption.expanded = false;
        } else {
          currentOption.expanded = true;
        }
        break;
      default:
    }

    updateStateData(filterName, options, newData);
  };

  const getIndexList = (path) => {
    if (!path?.includes('.')) return [];

    let tempArr = path.match(/\.\d+/g);
    tempArr =
      size(tempArr) > 0
        ? tempArr.map((item) => {
            return `${item.replace('.', '')}`;
          })
        : [];
    return tempArr;
  };

  const assignObjectPaths = (obj, stack, isChecked) => {
    if (isEmpty(obj)) return;

    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node !== 'object') return;

      if (!stack && node[0]?.label !== SELECT_ALL_LABEL) {
        node.unshift({
          label: SELECT_ALL_LABEL,
          checked: isChecked === undefined ? true : isChecked,
        });
      }
      node.checked = isChecked === undefined ? true : isChecked;
      node.path = stack ? `${stack}.${k}` : k;
      assignObjectPaths(node, node.path, isChecked);
    });
  };

  useEffect(() => {
    if (!isEmpty(filterOptions)) {
      assignObjectPaths(filterOptions);
      setFilterOptions({ ...filterOptions });
      preFilterOptions = { ...filterOptions };
    }

    //  Handle clean the state
    return () => {
      handleClearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const handleClickApplyFilter = (item, key) => {
    if (size(tempFilter[key]) <= 0) {
      tempFilter[key] = item;
    }

    if (!filterTabActive?.includes(key)) {
      filterTabActive.push(key);
    }

    const dataToPost = getDataToPost();
    setFilterFinderData(dataToPost);
    preFilterOptions = cloneState(auditLogFilterOptions);
  };

  const getDataToPost = () => {
    let tempFilterData = {};
    switch (filterType) {
      case MULTIPLE_FILTER_TYPE.AUDIT_LOG:
        const sp_management_filter = tempFilter[AUDIT_LOG_FILTER_OPTIONS.SP_MANAGEMENT_FILTER];
        const action_filter = tempFilter[AUDIT_LOG_FILTER_OPTIONS.ACTION];
        tempFilterData = {
          sp_management_filter,
          action_filter,
        };
        break;
      case MULTIPLE_FILTER_TYPE.USER_ACTIVITY_LOG:
        const user_filter_type = tempFilter[AUDIT_LOG_FILTER_OPTIONS.USER_FILTER_TYPE];
        tempFilterData = {
          user_filter_type,
        };
        break;
      case MULTIPLE_FILTER_TYPE.SG_JG_MAPPING:
        const role_level = tempFilter[SG_JG_FILTER_OPTIONS.ROLE_LEVEL];
        const jg_in_p_series = tempFilter[SG_JG_FILTER_OPTIONS.JG_IN_P_SERIES];
        tempFilterData = {
          role_level,
          jg_in_p_series,
        };
        break;
      case MULTIPLE_FILTER_TYPE.FUNCTION_LIST:
        const function_list = tempFilter[FUNCTION_LIST_FILTER_OPTIONS.FUNCTION_LIST];
        tempFilterData = {
          function_list,
        };
        break;
      default:
        break;
    }
    return tempFilterData;
  };

  const onBlur = (item, key) => {
    auditLogFilterOptions[key] = item;
    setFilterOptions(preFilterOptions);
    setIsBlur(true);
  };

  const handleClearFilter = () => {
    Object.keys(tempFilter).forEach((key) => {
      tempFilter[key] = [];
    });
    filterTabActive = [];
    setFilterFinderData({});
    assignObjectPaths(filterOptions);
    setFilterOptions({ ...filterOptions });
    preFilterOptions = { ...filterOptions };
  };

  return (
    <div className={'filter_section row px-0'} style={{ margin: 0 }}>
      {!isEmpty(auditLogFilterOptions) &&
        !isEmpty(auditLogFilters) &&
        auditLogFilters.map((item) =>
          Object.keys(auditLogFilterOptions).map((key) => {
            if (item.value === key) {
              return (
                <DropdownTreeSelectContainer
                  key={key}
                  keyWord={key}
                  filterTabActive={filterTabActive}
                  label={item.label}
                  placeholder={item.placeholder}
                  data={auditLogFilterOptions[key]}
                  onChange={onChange}
                  onNodeToggle={onNodeToggle}
                  onBlur={() => onBlur(auditLogFilterOptions[key], key)}
                  onClickSelectAll={(isChecked) => onClickSelectAll(key, isChecked)}
                  isBlur={isBlur}
                  onApply={() => handleClickApplyFilter(auditLogFilterOptions[key], key)}
                  filterName={key}
                  minWidth={minWidth}
                />
              );
            }
            return null;
          })
        )}
      {size(filterTabActive) > 0 && isClearFilter && (
        <div className={'col-12 px-0'}>
          <button className={'clear_filter_button'} onClick={handleClearFilter}>
            Clear Filters
          </button>
        </div>
      )}
    </div>
  );
};

export default AuditLogFilter;
