import React from 'react';

import { MEETING_DETAIL_STATUS } from '../../../../utils/constants';
import { AiMeetingStatus } from './Styled';

const MeetingStatus = ({ status }) => {
  if (status === MEETING_DETAIL_STATUS.PUBLISHED) {
    return (
      <AiMeetingStatus status={status}>
        <p>{MEETING_DETAIL_STATUS.PUBLISHED}</p>
      </AiMeetingStatus>
    );
  }
  if (status === MEETING_DETAIL_STATUS.IN_PROGRESS) {
    return (
      <AiMeetingStatus status={status}>
        <p>{MEETING_DETAIL_STATUS.IN_PROGRESS}</p>
      </AiMeetingStatus>
    );
  }
  if (status === MEETING_DETAIL_STATUS.IN_REVIEW) {
    return (
      <AiMeetingStatus status={status}>
        <p>{MEETING_DETAIL_STATUS.IN_REVIEW}</p>
      </AiMeetingStatus>
    );
  }
  if (status === MEETING_DETAIL_STATUS.CANCELED) {
    return (
      <AiMeetingStatus status={status}>
        <p>{MEETING_DETAIL_STATUS.CANCELED}</p>
      </AiMeetingStatus>
    );
  }
  if (status === MEETING_DETAIL_STATUS.IN_REVIEW) {
    return (
      <AiMeetingStatus status={status}>
        <p>{MEETING_DETAIL_STATUS.IN_REVIEW}</p>
      </AiMeetingStatus>
    );
  }
  if (status === MEETING_DETAIL_STATUS.CONCLUDED) {
    return (
      <AiMeetingStatus status={status}>
        <p>{MEETING_DETAIL_STATUS.CONCLUDED}</p>
      </AiMeetingStatus>
    );
  }
  return (
    <AiMeetingStatus status={status}>
      <p>In-Planning</p>
    </AiMeetingStatus>
  );
};
export default MeetingStatus;
