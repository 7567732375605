import { useDroppable } from '@dnd-kit/core';
import React from 'react';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: props.data,
    info: props.data,
    disabled: props.disabled,
  });

  let className = props.className;
  if (isOver && !props.showborder) {
    className += '__over';
  }

  return (
    <div ref={setNodeRef} {...props} className={className || ''}>
      {props.children}
    </div>
  );
}
