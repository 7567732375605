import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { DropdownCustomLg } from '..';
import { GENDER_TYPES } from '../../pages/Dashboard/constants';
import { SIZE_DROPDOWN } from '../../utils/constants';
import CommonLoading from '../CommonLoading/CommonLoading';
import styles from './age-gender.module.scss';

function getSeries(ageGender) {
  return ageGender.reduce(function (newAgeGender, item) {
    newAgeGender.push({
      name: item.gender,
      data: [item.greaterthan60, item.lessthan60, item.lessthan50, item.lessthan40, item.lessthan30],
    });
    return newAgeGender;
  }, []);
}

function getMaxToTal(ageGender) {
  return ageGender.reduce((max, currentValue) => {
    return Math.max(max, Math.max(...currentValue.data));
  }, 0);
}

function formatNumber(number) {
  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(number);
}

function optionsChart(ageGender) {
  return {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
        barHeight: '60%',
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },

    tooltip: {
      enabled: true,
      y: {
        formatter: function (value, { _series, _seriesIndex, _dataPointIndex, _w }) {
          return formatNumber(value);
        },
      },
    },
    xaxis: {
      categories: ['>= 60', '50-59', '40-49', '30-39', '20-29'],
      axisBorder: {
        show: true,
      },
      tickAmount: getMaxToTal(ageGender) <= 2 ? 2 : '',
      labels: {
        formatter: function (value) {
          if (value >= 1000) {
            return (value / 1000).toFixed(0) + 'k';
          } else {
            return value;
          }
        },
        style: {
          colors: ['#5E6C84'],
          fontSize: '12px',
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 500,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: ['#5E6C84'],
          fontSize: '12px',
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 500,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    grid: {
      strokeDashArray: 6,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    fill: {
      colors: ['#6554C0', '#64C8BC'],
    },
    legend: {
      show: false,
    },
  };
}

function getPercentFemale(ageGender) {
  const totalMale = ageGender[0].gender === GENDER_TYPES.MALE ? ageGender[0].total : ageGender[1].total;
  const totalFemale = ageGender[1].gender === GENDER_TYPES.FEMALE ? ageGender[1].total : ageGender[0].total;
  return ((totalFemale / (totalMale + totalFemale)) * 100).toFixed(0);
}

export const AgeGender = ({ seriesChart, totalOfMaleFemale, loading }) => {
  const [selected, setSelected] = useState({
    label: 'All',
    value: 'all',
  });
  const optionsGender = [
    { label: 'All', value: 'all' },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const [ageGender, setAgeGender] = useState(() => {
    return {
      series: getSeries(seriesChart),
      options: optionsChart(getSeries(seriesChart)),
      percentFemale: getPercentFemale(seriesChart),
    };
  });

  useEffect(() => {
    if (selected.value === 'all') {
      setAgeGender({
        series: getSeries(seriesChart),
        options: optionsChart(getSeries(seriesChart)),
        percentFemale: getPercentFemale(seriesChart),
      });
    } else {
      const index = seriesChart.findIndex((item) => item.gender !== selected.value);
      const newItem = {
        ...seriesChart[index],
        ...{
          lessthan30: 0,
          lessthan40: 0,
          lessthan50: 0,
          lessthan60: 0,
          greaterthan60: 0,
          total: 0,
        },
      };
      const newSeries = seriesChart.map((item) => {
        if (item.gender !== selected.value) {
          return newItem;
        } else {
          return item;
        }
      });
      setAgeGender({
        series: getSeries(newSeries),
        options: optionsChart(getSeries(newSeries)),
        percentFemale: getPercentFemale(seriesChart),
      });
    }
  }, [selected, seriesChart]);

  const { series, options, percentFemale } = ageGender;
  return (
    <>
      <div className={styles.age_gender}>
        <div className={styles.title}>
          <p className={styles.h2}>Age & Gender</p>
          <DropdownCustomLg setSelected={setSelected} selected={selected} options={optionsGender} type={SIZE_DROPDOWN.SSM} />
        </div>
        {loading ? (
          <div className="center-loading">
            <CommonLoading />
          </div>
        ) : (
          <>
            <div className={styles.legend}>
              <p className={styles.h6}>
                {`Male: ${totalOfMaleFemale.male > 0 ? totalOfMaleFemale.male : ''} `}(
                <span className={styles.male}>{Number(percentFemale) ? 100 - percentFemale + '%' : '0%'}</span>)
              </p>
              <p className={styles.h6}>
                {`Female: ${totalOfMaleFemale.female > 0 ? totalOfMaleFemale.female : ''} `}(
                <span className={styles.female}>{Number(percentFemale) ? percentFemale + '%' : '0%'}</span>)
              </p>
            </div>
            <div className={styles.chart}>
              <ReactApexChart options={options} series={series} type="bar" height={250} width={300} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AgeGender;
