import './box.scss';

import { Col, Row, Tooltip as TooltipAntd } from 'antd';
import { difference, get, isEmpty } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { AiCheckBox, BasicButton, BasicInput, BasicSelect } from '../../../../../assets/common';
import {
  chevron_down,
  edit_white,
  filter_tr,
  sort_white,
  sp_chevron_down,
  star,
} from '../../../../../assets/img';
import { talentReviewApi } from '../../../../../services/talentReviewApi';
import { handleShowYIPAndYISG, sortByField } from '../../../../../utils/helper';
import { Draggable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Draggable';
import styles from './recommendations.module.scss';

const SEARCH_TABLE_WIDTH = [8, 8, 30, 11, 16, 17, 10];

const Item = React.memo((props) => {
  const { item, index, setSelectedItems, selectedItems, showCheckBox } = props;
  const [collapseMenu, setCollapseMenu] = useState(false);

  const isChecked = useMemo(() => {
    return selectedItems.find(
      (selectedItem) =>
        selectedItem.position_code === item.position_code &&
        selectedItem.position_name === item.position_name
    );
  }, [item.position_code, item.position_name, selectedItems]);

  const handleClickCheckBox = (event) => {
    if (event.target.checked) {
      setSelectedItems(selectedItems.concat(item));
    } else {
      setSelectedItems(
        selectedItems.filter(
          (selectedItem) =>
            !(
              selectedItem.position_code === item.position_code &&
              selectedItem.position_name === item.position_name
            )
        )
      );
    }
  };

  const showHide = () => {
    setCollapseMenu(!collapseMenu);
  };

  return (
    <>
      <tr className={styles.tableBody} id={item.staff_id}>
        {showCheckBox && (
          <td style={{ width: `${SEARCH_TABLE_WIDTH[0]}%` }}>
            <AiCheckBox onChange={handleClickCheckBox} checked={isChecked} />
          </td>
        )}
        <td style={{ width: `${SEARCH_TABLE_WIDTH[1]}%` }}>{index + 1}</td>
        <td className={styles.name} style={{ width: `${SEARCH_TABLE_WIDTH[2]}%` }}>
          <div
            className={styles.recommendation__name}
            draggable="false"
            onKeyDown={() => {}}
            onClick={() =>
              window.open('/agenda/succession-planning-dashboard/' + item.position_code, '_blank')
            }
          >
            {item.position_name}
          </div>
        </td>
        <td style={{ width: `${SEARCH_TABLE_WIDTH[3]}%` }}>{item.position_label}</td>
        <td
          style={{ width: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          title={item.job_grade}
        >
          {item.job_grade}
        </td>

        <td style={{ width: `${SEARCH_TABLE_WIDTH[5]}%` }}>
          <div
            style={{ display: `inline-flex` }}
            className="justify-content-between align-items-center w-100"
          >
            {item.matching_percentage && item.matching_percentage + '%'}
            {/* {item.isStar && (
              <TooltipAntd
                placement="top"
                title={
                  <>
                    <div>
                      <span
                        style={{
                          color: 'var(--base-white, #FFF)',
                          fontSize: '12px',
                          fontWeight: 600,
                          lineHeight: '18px',
                        }}
                      >
                        Matching of Aspiration
                      </span>
                    </div>
                    {!isEmpty(item.minilmjobfunctions) &&
                      item.minilmjobfunctions.map((i) => (
                        <div style={{ marginTop: '5px' }}>
                          <span
                            style={{
                              height: '5px',
                              width: '5px',
                              backgroundColor: '#fff',
                              borderRadius: '50%',
                              display: 'inline-block',
                              transform: 'translateY(-2px)',
                              marginRight: '8px',
                              marginLeft: '8px',
                              opacity: '0.8',
                            }}
                          ></span>
                          <span
                            style={{
                              color: 'var(--base-white, #FFF)',
                              fontSize: '12px',
                              fontWeight: 400,
                              lineHeight: '18px',
                              opacity: '0.8',
                            }}
                          >
                            {i}
                          </span>
                        </div>
                      ))}
                  </>
                }
                overlayClassName="custom-tooltip"
                {...{
                  overlayInnerStyle: {
                    width: '260px',
                    fontSize: '12px',
                    lineHeight: '18px',
                    gap: '24px',
                    padding: '12px',
                    borderRadius: '4px',
                    backgroundColor: '#000',
                  },
                }}
              >
                <span style={{ cursor: 'pointer' }}>
                  <img src={star} alt="star" style={{ width: '16px' }} />
                </span>
              </TooltipAntd>
            )} */}
          </div>
        </td>
        <td style={{ width: `${SEARCH_TABLE_WIDTH[6]}%` }} onKeyDown={() => {}} onClick={showHide}>
          <img src={sp_chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
        </td>
      </tr>

      <tr className={styles.tableBodyCard} style={!collapseMenu ? { height: 0 } : {}}>
        <td colSpan={5} style={!collapseMenu ? { padding: 0 } : {}} id={item.staff_id}>
          <Collapse in={collapseMenu}>
            <Draggable
              id={`recommendation-drap-${item.position_code}-${index}-talent-review`}
              className={`collapseBody__lineRecommendation`}
              data={{ ...item, index }}
            >
              <div className={styles.card_box}>
                <div className="row no-gutters justify-content-start align-items-stretch">
                  <div
                    className="row justify-content-between no-gutters mt-1"
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#50A4B9',
                      gap: '5px',
                      width: '100%',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ maxWidth: '260px', textAlign: 'left' }}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span
                              style={{
                                fontSize: '14px',
                                lineHeight: '19px',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                backgroundcolor: '#000',
                                color: '#fff',
                              }}
                            >
                              {item.birth_name || 'N/A'} (YIP:{' '}
                              {handleShowYIPAndYISG(item.years_in_position, item.date_in_position)})
                            </span>
                          </Tooltip>
                        }
                      >
                        <span
                          onKeyDown={() => {}}
                          onClick={() =>
                            window.open(
                              '/agenda/succession-planning-dashboard/' + item.position_code,
                              '_blank'
                            )
                          }
                        >
                          {item.position_name} {item.position_code && ` (${item.position_code})`}
                        </span>
                      </OverlayTrigger>
                    </div>
                    <div>{item.matching_percentage}%</div>
                  </div>
                  <div className={styles.position}>
                    <span>{item.job_grade || 'N/A'}</span>
                    <br />
                    <span>{item.department_name || 'N/A'}</span>
                    <br />
                    <span>{item.business_unit || 'N/A'}</span>
                    <br />
                    <span>
                      {item.division || 'N/A'}, {item.opu_name || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </Draggable>
          </Collapse>
        </td>
      </tr>
    </>
  );
});
Item.displayName = 'RecommendationTableItem';

const Table = React.memo((props) => {
  const {
    dataTable,
    setDataTable,
    setSelectedItems,
    selectedItems,
    setAscSort,
    ascSort,
    showFilter,
    isView,
    showEditAdded,
    filteredTable,
  } = props;

  const checkedAll = useMemo(() => {
    return selectedItems.length !== 0 && selectedItems.length === dataTable.length;
  }, [dataTable.length, selectedItems.length]);

  const showCheckBox = useMemo(() => {
    if (filteredTable) {
      return !isView || showEditAdded;
    }
    return showFilter;
  }, [filteredTable, isView, showEditAdded, showFilter]);

  const handleClickCheckBoxAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(dataTable);
    } else {
      setSelectedItems([]);
    }
  };

  const handleClickSort = () => {
    const currentSort = !ascSort;
    setDataTable((prev) => {
      return sortByField(prev, 'matching_percentage', currentSort);
    });
    setAscSort(currentSort);
  };

  return (
    <table>
      <thead>
        <tr className={`${styles.tableHeader} `}>
          {showCheckBox && (
            <td style={{ width: `${SEARCH_TABLE_WIDTH[0]}%` }}>
              <AiCheckBox onChange={handleClickCheckBoxAll} checked={checkedAll} />
            </td>
          )}
          <td
            className={styles.nameHeader}
            style={{ width: `${SEARCH_TABLE_WIDTH[1] + SEARCH_TABLE_WIDTH[2] - 2}%` }}
          >
            Position
          </td>
          <td style={{ width: `${SEARCH_TABLE_WIDTH[3]}%` }}>Type</td>
          <td style={{ width: 70 }}>JG</td>
          <td style={{ width: `${SEARCH_TABLE_WIDTH[5] + SEARCH_TABLE_WIDTH[6]}%` }}>
            <img
              src={sort_white}
              alt="sort"
              className={`${styles.sortIcon} ${!ascSort ? styles.sortDesc : ''}`}
              onKeyDown={() => {}}
              onClick={handleClickSort}
            />
            Matching (%)
          </td>
        </tr>
      </thead>
      <tbody>
        {dataTable && dataTable.length > 0 ? (
          dataTable.map((item, index) => (
            <Item
              key={`${item.position_code}-${index}`}
              item={item}
              index={index}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              showFilter={showFilter}
              showCheckBox={showCheckBox}
            />
          ))
        ) : (
          <tr>
            <td colSpan={5} className={styles.no_data}>
              No data
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
});
Table.displayName = 'Table';

const INIT_SEARCH_PARAMS = {
  type: null,
  job_grade: null,
  first_matching: '',
  second_matching: '',
  position_name: '',
};

const RecommendationTable = (props) => {
  const { staffTalentReview, recommendationList, setRecommendationList } = props;

  const { agendaId } = useParams();
  const [showRecommendation, setShowRecommendation] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterOptions, setFilterOptions] = useState({ type: [], job_grade: [] });
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [searching, setSearching] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [ascRecommendation, setAscRecommendation] = useState(false);

  const [showFilteredResult, setShowFilteredResult] = useState(true);
  const [isView, setIsView] = useState(true);
  const [showEditAdded, setShowEditAdded] = useState(false);
  const [addedFilterItems, setAddedFilterItems] = useState([]);
  const [selectedFilteredItems, setSelectedFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ascAddedItems, setAscAddedItems] = useState(false);

  const disableAddToFiltered = useMemo(() => {
    return isEmpty(selectedItems);
  }, [selectedItems]);

  const isShowFiltered = useMemo(() => {
    return agendaId && !isEmpty(addedFilterItems);
  }, [addedFilterItems, agendaId]);

  const disabledRemoveAndFinalize = useMemo(() => {
    return isEmpty(selectedFilteredItems);
  }, [selectedFilteredItems]);

  const handleClickFilterIcon = () => {
    if (showFilter) {
      setSelectedItems([]);
    }
    setShowFilter(!showFilter);
  };

  const handleClickEditIcon = () => {
    if (showEditAdded && isView) {
      setSelectedFilteredItems([]);
    }
    setShowEditAdded(!showEditAdded);
  };

  const getSelectedFilteredItems = useCallback(async () => {
    if (isNaN(Number(agendaId)) || !staffTalentReview.staff_id) return;
    try {
      setLoading(true);
      const tempAgendaId = !isNaN(Number(agendaId)) ? agendaId : null;
      const res = await talentReviewApi.getFinalizedRecommendations(
        staffTalentReview.staff_id,
        tempAgendaId
      );
      if (res.status === 200) {
        setAddedFilterItems(res.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [agendaId, staffTalentReview.staff_id]);

  const handleChangeField = (fieldName, value) => {
    setSearchParams((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleAddToFiltered = () => {
    setAddedFilterItems((prev) => {
      return sortByField([...prev, ...selectedItems], 'matching_percentage', ascAddedItems);
    });
    const newRecommendationList = difference(recommendationList, selectedItems);
    setRecommendationList(newRecommendationList);
    setSelectedItems([]);
    setIsView(false);
  };

  const handleReset = async () => {
    setSearchParams(INIT_SEARCH_PARAMS);
    setSearching(true);
  };

  const handleRemoveOnDb = async (removeIds) => {
    if (isEmpty(removeIds)) return;
    try {
      setLoading(true);
      await talentReviewApi.removeRecommendationsFinalized(removeIds);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkFilterMatching = (talent) => {
    if (searchParams.type && searchParams.type !== talent.position_label) {
      return false;
    }
    if (searchParams.job_grade && searchParams.job_grade !== talent.job_grade) {
      return false;
    }
    if (searchParams.position_name && !talent.position_name.includes(searchParams.position_name)) {
      return false;
    }
    if (searchParams.first_matching && searchParams.first_matching > talent.matching_percentage) {
      return false;
    }
    if (searchParams.second_matching && searchParams.second_matching < talent.matching_percentage) {
      return false;
    }
    return true;
  };

  const handleRemove = async () => {
    const removeIds = selectedFilteredItems.filter((item) => item.id).map((item) => item.id);
    const revertToRecommendations = selectedFilteredItems.filter((item) =>
      checkFilterMatching(item)
    );
    setRecommendationList((prev) => {
      return sortByField(
        [...prev, ...revertToRecommendations],
        'matching_percentage',
        ascRecommendation
      );
    });
    const newAddedFilterList = difference(addedFilterItems, selectedFilteredItems);
    setAddedFilterItems(newAddedFilterList);
    setSelectedFilteredItems([]);
    await handleRemoveOnDb(removeIds);
    setShowEditAdded(false);
  };

  const handleFinalize = async () => {
    try {
      setLoading(true);
      const body = selectedFilteredItems.map((item) => ({
        id: item.id,
        agenda_id: !isNaN(Number(agendaId)) ? agendaId : null,
        position_code: item.position_code,
      }));
      const res = await talentReviewApi.finalizeRecommendations(body);
      if (res.status === 201) {
        await getSelectedFilteredItems();
        const unSavedTalents = addedFilterItems.filter((item) => !item.id);
        const unFinalizeTalents = difference(unSavedTalents, selectedFilteredItems);
        setRecommendationList((prev) => {
          return sortByField(
            [...prev, ...unFinalizeTalents],
            'matching_percentage',
            ascRecommendation
          );
        });
        setIsView(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setSelectedFilteredItems([]);
    }
  };

  useEffect(() => {
    const getRecommendations = async () => {
      try {
        const tempAgendaId = !isNaN(Number(agendaId)) ? agendaId : null;
        const res = await talentReviewApi.getRecommendationTalentReview(
          staffTalentReview.staff_id,
          searchParams,
          tempAgendaId
        );
        const arr = get(res, 'data.result') || [];
        setRecommendationList(arr);
        setSelectedItems([]);
      } catch (error) {
        console.error(error);
      } finally {
        setSearching(false);
      }
    };
    if (!searching || !staffTalentReview.staff_id) return;
    getRecommendations();
  }, [agendaId, searchParams, searching, setRecommendationList, staffTalentReview.staff_id]);

  useEffect(() => {
    const getFilterOptions = async () => {
      try {
        setLoadingOptions(true);
        const res = await talentReviewApi.getRecommendationFilter();
        if (res.status === 200) {
          setFilterOptions(res.data.result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOptions(false);
      }
    };
    getFilterOptions();
  }, []);

  useEffect(() => {
    getSelectedFilteredItems();
  }, [getSelectedFilteredItems]);

  return (
    <>
      <div className={styles.collapse}>
        <div className={styles.collapseHeader}>
          <div>Recommendations</div>
          <div className={styles.recommendationFilter}>
            {!isNaN(Number(agendaId)) && (
              <img
                src={filter_tr}
                alt="filter"
                onKeyDown={() => {}}
                onClick={handleClickFilterIcon}
              />
            )}
            <img
              src={chevron_down}
              alt="chevron_down"
              className={showRecommendation ? styles.animate : ''}
              onKeyDown={() => {}}
              onClick={() => setShowRecommendation(!showRecommendation)}
            />
          </div>
        </div>
        <div className={styles.body} style={{ maxHeight: showRecommendation ? '1160px' : '0' }}>
          <div className={styles.content}>
            <Row
              gutter={[8, 8]}
              className={styles.filterForm}
              style={{ maxHeight: showFilter ? '156px' : 0 }}
            >
              <Col span="7">
                <BasicSelect
                  placeholder="Type"
                  options={filterOptions.type}
                  value={searchParams.type}
                  loading={loadingOptions}
                  onChange={(value) => handleChangeField('type', value)}
                />
              </Col>
              <Col span="7">
                <BasicSelect
                  placeholder="JG"
                  options={filterOptions.job_grade}
                  value={searchParams.job_grade}
                  loading={loadingOptions}
                  onChange={(value) => handleChangeField('job_grade', value)}
                />
              </Col>
              <Col span="10">
                <BasicInput
                  placeholder="Enter Position"
                  value={searchParams.position_name}
                  onChange={(event) => handleChangeField('position_name', event.target.value)}
                />
              </Col>

              <Col span="12">
                <BasicInput
                  placeholder="From (%)"
                  height={32}
                  value={searchParams.first_matching}
                  onChange={(event) => handleChangeField('first_matching', event.target.value)}
                />
              </Col>
              <Col span="12">
                <BasicInput
                  placeholder="To (%)"
                  height={32}
                  value={searchParams.second_matching}
                  onChange={(event) => handleChangeField('second_matching', event.target.value)}
                />
              </Col>

              <Col span="7">
                <BasicButton
                  mode="teal"
                  width="100%"
                  onKeyDown={() => {}}
                  onClick={() => setSearching(true)}
                >
                  Search
                </BasicButton>
              </Col>
              <Col span="10">
                <BasicButton
                  type="submit"
                  disabled={disableAddToFiltered}
                  width="100%"
                  onKeyDown={() => {}}
                  onClick={handleAddToFiltered}
                >
                  Add to Filtered
                </BasicButton>
              </Col>
              <Col span="7">
                <BasicButton width="100%" onKeyDown={() => {}} onClick={handleReset}>
                  Reset
                </BasicButton>
              </Col>
            </Row>
            <div className={styles.table}>
              {searching ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Table
                  dataTable={recommendationList}
                  setDataTable={setRecommendationList}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  ascSort={ascRecommendation}
                  setAscSort={setAscRecommendation}
                  showFilter={showFilter}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {!isNaN(Number(agendaId)) && (
        <div className={styles.collapse}>
          <div className={styles.collapseHeader}>
            <div>Filtered Result</div>
            <div className={styles.recommendationFilter}>
              <img
                src={edit_white}
                alt="edit"
                onKeyDown={() => {}}
                onClick={handleClickEditIcon}
                className={isEmpty(addedFilterItems) ? styles.disabled : ''}
              />
              <img
                src={chevron_down}
                alt="chevron_down"
                className={showFilteredResult ? styles.animate : ''}
                onKeyDown={() => {}}
                onClick={() => setShowFilteredResult(!showFilteredResult)}
              />
            </div>
          </div>
          <div className={styles.body} style={{ maxHeight: showFilteredResult ? '420px' : '0' }}>
            {loading ? (
              <div className="d-flex justify-content-center p-2">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {isEmpty(addedFilterItems) ? (
                  <div className={styles.noFiltered}>No filtered result has been added.</div>
                ) : (
                  <div className={styles.content}>
                    {isShowFiltered && (
                      <div className={styles.filteredTableButton}>
                        {showEditAdded ? (
                          <>
                            <BasicButton onKeyDown={() => {}} onClick={handleClickEditIcon}>
                              Cancel
                            </BasicButton>
                            <BasicButton
                              mode="danger"
                              disabled={disabledRemoveAndFinalize}
                              onKeyDown={() => {}}
                              onClick={handleRemove}
                            >
                              Remove
                            </BasicButton>
                          </>
                        ) : (
                          <>
                            {!isView && (
                              <BasicButton
                                mode="teal"
                                disabled={disabledRemoveAndFinalize}
                                onKeyDown={() => {}}
                                onClick={handleFinalize}
                              >
                                Finalize
                              </BasicButton>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className={`${styles.table} ${styles.filteredResult}`}>
                      <Table
                        dataTable={addedFilterItems}
                        setDataTable={setAddedFilterItems}
                        setSelectedItems={setSelectedFilteredItems}
                        selectedItems={selectedFilteredItems}
                        ascSort={ascAddedItems}
                        setAscSort={setAscAddedItems}
                        showFilter={showFilter}
                        isView={isView}
                        showEditAdded={showEditAdded}
                        filteredTable
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(RecommendationTable);
