import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { Rectangle } from '../../../../../../assets/img';
import styles from './search-box-custom.module.scss';
import SearchListOption from './SearchListOption';

const SearchBoxCustom = (props) => {
  const {
    searchTerm,
    setSearchTerm,
    searchList,
    searchPlaceholder,
    fieldName,
    isSubmitted,
    isReadOnly,
    status,
    isEdit,
    setIsFirstValueChange,
    isFirstValueChange,
  } = props;
  const [hasError, setHasError] = useState(false);
  const [cloneSearchList, setCloneSearchList] = useState([]);

  useEffect(() => {
    setCloneSearchList(cloneDeep(searchList));
  }, [searchList]);

  const ref = useRef();
  const [isActive, setIsActive] = useState(false);

  const handleOnChange = (value) => {
    setIsFirstValueChange(false);
    setSearchTerm({ value, type: 'search', showTerm: value });
    if (!isEmpty(value)) {
      const tempData = cloneDeep(searchList);
      setCloneSearchList(tempData.filter((item) => item.value.includes(value)));
    } else {
      setCloneSearchList(searchList);
    }
    setIsActive(true);
  };

  useEffect(() => {
    if (isFirstValueChange) return setIsActive(false);

    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive, setIsActive, isFirstValueChange]);

  useEffect(() => {
    if (isSubmitted) {
      setHasError(isEmpty(searchTerm?.showTerm));
    }
  }, [isSubmitted, searchTerm]);

  return (
    <div className={styles.search_box} tabIndex={0} data-testid="search-box" ref={ref}>
      <input
        id={fieldName}
        data-testid={fieldName}
        name={fieldName}
        placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
        className={`${styles.search_input} ${hasError && isSubmitted ? styles.error : ''} ${
          isEdit && styles.is_edit
        }`}
        onChange={(e) => handleOnChange(e.target.value)}
        onFocus={() => {
          setIsFirstValueChange(false);
          setIsActive(true);
        }}
        value={searchTerm.showTerm}
        autoComplete="off"
        style={
          status === 'error'
            ? { border: '1px solid red' }
            : { border: `1px solid rgb(211, 222, 232)` }
        }
        readOnly={isReadOnly}
      />
      <button
        type="button"
        className={`${styles.dropdownIcon} ${isEdit && styles.dropdownIcon_edit}`}
        onClick={() => {
          setIsFirstValueChange(false);
          setIsActive(!isActive);
        }}
      >
        <img src={Rectangle} alt="" width="10" height="10" />
      </button>
      <div
        style={
          isActive ? { visibility: 'visible', opacity: 1 } : { visibility: 'hidden', opacity: 0 }
        }
      >
        <SearchListOption
          data={cloneSearchList}
          setSearchTerm={setSearchTerm}
          fieldName={fieldName}
          setIsActive={setIsActive}
          isEdit={isEdit}
        />
      </div>
    </div>
  );
};

export default SearchBoxCustom;
