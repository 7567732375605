import { Spin, notification } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AddNewNote, ViewNote } from '..';
import { ModelTC } from '../../components';
import { addNotes, deleteNotes, updateNotes } from '../../services/talentProfiles';
import { PERMISSION } from '../../utils/constants';
import styles from './note.module.scss';

const NoteList = (props) => {
  const { notes } = props;
  if (!notes || notes.length === 0) return <></>;
  const listItems = notes.map((note, index) => {
    return (
      index < 6 && (
        <li className={styles.content} key={index}>
          <span className={styles.platform}>
            {note.platform} ({moment(note.date).format('DD-MMM-YYYY')}),
          </span>
          <span className={styles.text}>{note.notes_content}</span>
        </li>
      )
    );
  });
  return <ul className={styles.menu}>{listItems}</ul>;
};

const Notes = ({ profileId, notes, allNotes, fetchNotes, roleUser, loadingNotes }) => {
  const { permissions } = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [itemChoose, setItemChoose] = useState({});
  const [isPopupDelete, setIsPopupDelete] = useState(false);
  const [isOpenNote, setIsOpenNote] = useState(false);

  const isAddEditNonPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.TP_EDIT_NON_PRIVATE_NOTE);
  }, [permissions]);

  const isAddEditPrivateNote = useMemo(() => {
    if (isEmpty(permissions)) return false;
    return permissions.includes(PERMISSION.VIEW_EDIT_PRIVACY_NOTES);
  }, [permissions]);

  const showViewNote = () => {
    if (notes && notes.totalNotes > 0 && (!allNotes || allNotes.length === 0)) {
      fetchNotes(0, notes.totalNotes, true);
      setIsClicked(true);
      return;
    }
    setIsClicked(true);
    setIsOpen(true);
  };

  const handleRenderData = useMemo(() => {
    return (
      <NoteList
        notes={notes.data}
        isAddEditNonPrivateNote={isAddEditNonPrivateNote}
        isAddEditPrivateNote={isAddEditPrivateNote}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes.data]);

  const handleNote = async (data, isPrivate) => {
    if (!data?.id) {
      try {
        const response = await addNotes({ profileId, roleUser, data, isPrivate });
        if (response && response?.data?.code === 200) {
          fetchNotes(0, 5, false);
          setIsEdit(false);
          setIsOpenNote(false);
          notification.success({
            message: `Add new note successful.`,
            placement: 'topRight',
            duration: 2,
          });
        }
      } catch (e) {
        notification.error({
          message: `Add new note error.`,
          placement: 'topRight',
          duration: 2,
        });
      }
    } else {
      try {
        const response = await updateNotes({
          profileId,
          noteId: data.id,
          roleUser,
          body: data,
          isPrivate,
        });
        if (response && response?.data?.code === 200) {
          fetchNotes(0, 5, false);
          setIsEdit(false);
          setIsOpenNote(false);
          notification.success({
            message: `Update note successful.`,
            placement: 'topRight',
            duration: 2,
          });
        }
      } catch (e) {
        notification.error({
          message: `Update note error.`,
          placement: 'topRight',
          duration: 2,
        });
      }
    }
  };

  const onDeleteItem = async (item) => {
    try {
      const response = await deleteNotes({
        profileId,
        noteId: item.id,
        roleUser,
        isPrivate: item.is_private,
      });
      if (response && response?.data?.code === 200) {
        fetchNotes(0, 5, false);
        setIsPopupDelete(false);
        notification.success({
          message: `Delete note successful.`,
          placement: 'topRight',
          duration: 2,
        });
      }
    } catch (e) {
      notification.error({
        message: `Delete note error.`,
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (isClicked) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotes && allNotes.length]);

  useEffect(() => {
    setIsClicked(isOpen);
  }, [isOpen]);

  return (
    <Spin spinning={loadingNotes}>
      <div className={styles.notes} data-testid="notes">
        <h1 data-testid="title">Notes</h1>
        {handleRenderData}
        <p
          className={styles.view_more}
          onKeyDown={() => {}}
          onClick={showViewNote}
          data-testid="show-more"
        >
          View more notes
        </p>
        <ViewNote
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={allNotes}
          setIsEdit={setIsEdit}
          setIsPopupDelete={setIsPopupDelete}
          setIsOpenNote={setIsOpenNote}
          onChooseItem={(item) => setItemChoose(item)}
        />
        {(isAddEditNonPrivateNote || isAddEditPrivateNote) && (
          <AddNewNote
            isOpen={isOpenNote}
            setIsOpenNote={setIsOpenNote}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            dataEdit={itemChoose}
            handleNote={handleNote}
            isAddEditNonPrivateNote={isAddEditNonPrivateNote}
            isAddEditPrivateNote={isAddEditPrivateNote}
          />
        )}
        <ModelTC
          info={{
            type: 'deleteTalentProfileNote',
            visible: isPopupDelete,
            setVisible: setIsPopupDelete,
            handleSubmit: () => onDeleteItem(itemChoose),
            onClose: () => setIsPopupDelete(false),
          }}
        />
      </div>
    </Spin>
  );
};

export default Notes;
