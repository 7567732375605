import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { AiCheckBox, BasicButton, BasicSelect } from '../../../../../assets/common';
import { delete_mobility_plan } from '../../../../../assets/img';
import { MOBILITY_PLAN_DROP_CARD_INFO } from '../../../../../utils/constants';
import { Droppable } from '../../../../Meeting/AgendaDetails/components/Recommendations/wrappers/Droppable';
import * as styles from './select-position-mobility-plan.module.scss';
import SortableItem from '../../../../../components/SortableItem/SortableItem';

const { Option } = BasicSelect;

const SelectPositionMobilityPlan = ({
  isView,
  options,
  data,
  setData,
  type,
  sectionIndex,
  maxLength,
  isOpenFulfilMent,
  setPositionSelected,
  positionSelected,
  anotherType,
}) => {
  const [open, setOpen] = useState(false);
  const [renderOptions, setRenderOptions] = useState([]);
  const [selectedPositionCodes, setSelectedPositionCodes] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const addedOptions = useMemo(() => {
    return data[type]?.selection || [];
  }, [data, type]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleDropdownVisibleChange = (visible) => {
    setSearchValue('');
    setOpen(visible);
  };

  const handleAdd = () => {
    const selectedOptions = options.filter((option) =>
      selectedPositionCodes.includes(option.position_code)
    );

    if (type === MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type) {
      if (
        !isEmpty(data?.end_state?.selection) &&
        selectedPositionCodes[0] === data.end_state.selection[0].position_code
      ) {
        return;
      } else {
        setData((prev) => ({
          ...prev,
          [type]: {
            ...prev?.[type],
            selection: [...selectedOptions],
          },
        }));
      }
    } else {
      const concatArr = [
        ...(!isEmpty(data?.next_mobility?.selection) ? data.next_mobility.selection : []),
        ...(!isEmpty(data?.next_2_mobility?.selection) ? data.next_2_mobility.selection : []),
      ].map((el) => el?.priority || 0);
      const isCurrentPriority = isEmpty(concatArr) ? 1 : Math.max(...concatArr) + 1;

      setData((prev) => ({
        ...prev,
        [type]: {
          ...prev?.[type],
          selection: [
            ...(prev?.[type]?.selection || []),
            ...selectedOptions.map((item) => ({ ...item, priority: isCurrentPriority })),
          ],
        },
      }));
    }
    setSearchValue('');
    setOpen(false);
  };

  const handleOnSelect = (selectedPositionCode) => {
    const checkedPositionCode = selectedPositionCodes.find(
      (positionCode) => positionCode === selectedPositionCode
    );
    if (checkedPositionCode) {
      setSelectedPositionCodes((prev) =>
        prev.filter((positionCode) => positionCode !== checkedPositionCode)
      );
    } else {
      setSelectedPositionCodes((prev) => [...prev, selectedPositionCode]);
    }
  };

  const handleDelete = (deletePositionCode) => {
    if (type !== MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type) {
      const isPriorityDeleted = addedOptions.find(
        (f) => f.position_code == deletePositionCode
      ).priority;
      const deletedData = addedOptions
        .filter((option) => option.position_code != deletePositionCode)
        .map((option) => ({
          ...option,
          priority: isPriorityDeleted < option.priority ? option.priority - 1 : option.priority,
        }));

      const anotherData = (data[anotherType]?.selection || []).map((option) => ({
        ...option,
        priority: isPriorityDeleted < option.priority ? option.priority - 1 : option.priority,
      }));

      setData((prev) => ({
        ...prev,
        [type]: { ...prev[type], selection: deletedData },
        [anotherType]: { ...prev[anotherType], selection: anotherData },
      }));
    } else {
      const deletedData = addedOptions.filter(
        (option) => option.position_code != deletePositionCode
      );
      setData((prev) => ({
        ...prev,
        [type]: { ...prev[type], selection: deletedData },
      }));
    }
  };

  const handleFilterOption = (input, option) => {
    const positionName = option.children.props.children.props.children[0];
    const positionCode = option.children.props.children.props.children[2].props.children;
    return `${positionName} ${positionCode}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const dropdownRender = (menu) => {
    return (
      <div>
        {menu}
        <div className={styles.addButton}>
          <BasicButton mode="teal" onClick={handleAdd} disabled={isEmpty(selectedPositionCodes)}>
            Add
          </BasicButton>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isEmpty(options)) return;
    let map = new Map();
    for (let option of addedOptions) {
      map.set(option.position_code, true);
    }
    setRenderOptions(
      options
        .filter((option) => !map.has(option.position_code))
        .map((option, index) => ({ ...option, id: index }))
    );
  }, [addedOptions, options]);

  useEffect(() => {
    if (!open) setSelectedPositionCodes([]);
  }, [open]);

  const isDisableSearchEndState = useMemo(() => {
    if (type === MOBILITY_PLAN_DROP_CARD_INFO.END_STATE.type) {
      return selectedPositionCodes.length > 0;
    } else {
      return false;
    }
  }, [selectedPositionCodes, type]);

  const height = isOpenFulfilMent
    ? maxLength < 61
      ? '84px'
      : maxLength < 70
      ? '106px'
      : '128px'
    : maxLength < 61
    ? '62px'
    : maxLength < 70
    ? '84px'
    : '106px';

  const color =
    type === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_MOBILITY.type
      ? '#005CB9'
      : type === MOBILITY_PLAN_DROP_CARD_INFO.NEXT_2_MOBILITY.type
      ? '#763F98'
      : '#00A19C';
  const { setNodeRef } = useDroppable({ id: type });

  const renderCardContent = () => {
    return (
      <>
        <div className={styles.added}>
          {isView &&
            addedOptions.map((option) => (
              <div
                key={`${option.position_code}${option.position_name}`}
                className={styles.positionName}
                style={{
                  height,
                  color,
                  borderLeftColor: color === '#00A19C' ? '#66B2C4' : color,
                  boxShadow:
                    positionSelected?.position_code === option.position_code &&
                    type === positionSelected?.typeMobility
                      ? '0px 4px 6px 0px rgba(52, 63, 75, 0.10), 0px 2px 4px 0px rgba(52, 63, 75, 0.06)'
                      : 'unset',
                }}
                onClick={() =>
                  setPositionSelected &&
                  setPositionSelected(
                    positionSelected?.position_code === option.position_code &&
                      type === positionSelected?.typeMobility
                      ? null
                      : { position_code: option.position_code, typeMobility: type }
                  )
                }
                onKeyDown={() => {}}
              >
                <Tooltip
                  placement="top"
                  color={'#181818'}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  title={
                    <>
                      {option.position_name}
                      <ul>
                        {option.department && <li>Department: {option.department}</li>}
                        {option.division && <li>Division: {option.division}</li>}
                        {option.opu && <li>OPU: {option.opu}</li>}
                        {option.business && <li>Business: {option.business}</li>}
                      </ul>
                    </>
                  }
                >
                  {option.position_name}{' '}
                  <span style={{ fontWeight: 400 }}>({option.position_code})</span>{' '}
                  {option.job_grade && (
                    <span style={{ fontWeight: 400 }}>({option.job_grade})</span>
                  )}
                </Tooltip>
                {!isView && (
                  <img
                    src={delete_mobility_plan}
                    alt="delete"
                    onClick={() => handleDelete(option.position_code)}
                    onKeyDown={() => {}}
                  />
                )}
              </div>
            ))}
          {!isView && (
            <SortableContext
              id={type}
              items={addedOptions.map((item) => item.position_code + type)}
              strategy={verticalListSortingStrategy}
            >
              <div>
                {addedOptions.map((item) => (
                  <SortableItem
                    key={item.position_code}
                    id={item.position_code + type}
                    item={item}
                    positionSelected={positionSelected}
                    setPositionSelected={setPositionSelected}
                    isView={isView}
                    color={color}
                    type={type}
                    height={height}
                    styles={styles}
                    handleDelete={handleDelete}
                  />
                ))}
              </div>
            </SortableContext>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper} style={isEmpty(options) ? { border: 'none' } : {}}>
      {!isView && (
        <BasicSelect
          mode={'multiple'}
          bordered={false}
          searchValue={searchValue}
          onSearch={handleSearch}
          value={[]}
          showSearch
          autoClearSearchValue={false}
          open={open}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          getPopupContainer={(trigger) => trigger.parentElement}
          placeholder="Search by Position Name or Position ID"
          dropdownRender={dropdownRender}
          filterOption={handleFilterOption}
        >
          {renderOptions.map((option) => (
            <Option
              key={option.id}
              value={option.position_code}
              disabled={
                isDisableSearchEndState && !selectedPositionCodes.includes(option.position_code)
              }
            >
              <div
                onClick={() => {
                  if (
                    !isDisableSearchEndState ||
                    (isDisableSearchEndState &&
                      selectedPositionCodes.includes(option.position_code))
                  ) {
                    handleOnSelect(option.position_code);
                  }
                }}
                className={styles.option}
                onKeyDown={() => {}}
              >
                <AiCheckBox
                  checked={selectedPositionCodes.includes(option.position_code)}
                  onChange={(e) => e.preventDefault()}
                  disabled={
                    isDisableSearchEndState && !selectedPositionCodes.includes(option.position_code)
                  }
                >
                  {option.position_name}
                  <br />
                  <span style={{ fontWeight: 400 }}>{option.position_code}</span>
                </AiCheckBox>
              </div>
            </Option>
          ))}
        </BasicSelect>
      )}

      {!isEmpty(addedOptions) && <>{renderCardContent()}</>}
    </div>
  );
};

SelectPositionMobilityPlan.propTypes = {
  isView: PropTypes.bool,
  options: PropTypes.array,
  data: PropTypes.object,
  setData: PropTypes.func,
  type: PropTypes.string,
};

export default SelectPositionMobilityPlan;
