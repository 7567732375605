import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Popover } from 'antd';
import { Apply, CheckBoxCustom, DropdownSp } from './styled';
const CheckBoxSP = (props) => {
  const { selected, options, element, setSelected } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  function onCheckboxChange(selection) {
    setSelectedItems(selection);
  }

  function onApply() {
    setSelected(selectedItems);
    hide();
  }
  const CheckboxRender = () => {
    return (
      <DropdownSp>
        <Checkbox.Group
          onChange={onCheckboxChange}
          value={selectedItems}
          style={{ maxHeight: '300px', overflowY: 'scroll' }}
        >
          {options.map((item, index) => {
            return (
              <CheckBoxCustom key={item.value + index} value={item.value}>
                {item.label}
              </CheckBoxCustom>
            );
          })}
        </Checkbox.Group>
        <Divider />
        <div className="d-flex justify-content-end">
          <Apply onClick={onApply}>Apply</Apply>
        </div>
      </DropdownSp>
    );
  };
  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      content={<CheckboxRender />}
      trigger="click"
      placement="bottom"
    >
      {element}
    </Popover>
  );
};
export default CheckBoxSP;
