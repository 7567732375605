import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BasicButton, BasicInput, BasicTable } from '../../../../../assets/common';
import More3DotDropdown from '../../../../../components/More3DotDropdown/More3DotDropdown';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { FILL_OUT_REQUIRE_MESSAGE, MESSAGE_TYPES } from '../../../../../utils/constants';
import { paginationConfig } from '../../../../../utils/helper';
import { TOP_TALENT_CATEGORY_TABLE_ROW_LIMIT } from '../../constants';
import * as styles from './top-talent-category-table.module.scss';

const TopTalentCategoryTable = ({
  data,
  searchParams,
  setSearchParams,
  setDeleteParams,
  setShowDeleteConfirm,
  editRecord,
  setEditRecord,
  onEdit,
}) => {
  const dispatch = useDispatch();

  const [editInput, setEditInput] = useState('');
  const [errorInput, setErrorInput] = useState(false);

  const columns = useMemo(() => {
    const handleClickDelete = (record) => {
      setShowDeleteConfirm(true);
      setDeleteParams((prevState) => ({ ...prevState, deleteRecord: record }));
    };

    const handleClickEdit = (record) => {
      setEditRecord(record);
      setEditInput(record.category);
    };

    const handleOnChange = (e) => {
      setEditInput(e.target.value);
    };

    const handleOnSave = () => {
      const category = editInput.trim();
      if (!category) {
        setErrorInput(true);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: FILL_OUT_REQUIRE_MESSAGE,
          })
        );
        return;
      }
      onEdit(category);
    };

    return [
      {
        title: 'No.',
        width: 100,
        dataIndex: 'no',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Category',
        width: 800,
        dataIndex: 'category',
        render: (text, record) => (
          <>
            {editRecord?.id === record.id ? (
              <div className={styles.editInput}>
                <BasicInput
                  data-testid={`input-${record.id}`}
                  height={44}
                  defaultValue={record.category}
                  onBlur={handleOnChange}
                  error={errorInput}
                />
              </div>
            ) : (
              <span className={styles.category}>{text}</span>
            )}
          </>
        ),
      },
      {
        title: '',
        width: 100,
        align: 'right',
        render: (_, record) => (
          <div className="mr-4">
            {editRecord?.id === record.id && (
              <BasicButton mode="teal" onClick={handleOnSave}>
                Save
              </BasicButton>
            )}
            {!editRecord && (
              <More3DotDropdown
                item={record}
                handleClickEdit={handleClickEdit}
                handleClickDelete={handleClickDelete}
              />
            )}
          </div>
        ),
      },
    ];
  }, [
    dispatch,
    editInput,
    editRecord,
    errorInput,
    onEdit,
    setDeleteParams,
    setEditRecord,
    setShowDeleteConfirm,
  ]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(
      data.total,
      searchParams.page,
      onChange,
      TOP_TALENT_CATEGORY_TABLE_ROW_LIMIT
    );
  }, [data.total, searchParams.page, setSearchParams]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setDeleteParams({
        selectedRecords: selectedRows,
        deleteRecord: null,
      });
    },
  };

  return (
    <div className="mt-4">
      <BasicTable
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={data.top_talent_category}
        columns={columns}
        pagination={pagination}
        styles={{ selectPadding: '0' }}
      />
    </div>
  );
};

export default TopTalentCategoryTable;
