import axiosClient from './axiosClient';
import queryString from 'query-string';
import { PERMISSION } from '../utils/constants';

export const mobilitySimulationApi = {
  getList: (query, roleId) => {
    const url = `/mobility-simulations`;
    return axiosClient.get(url, {
      params: {
        ...query,
      },
      headers: {
        // permission: PERMISSION.MOBILITY_SIMULATION,
        role: roleId,
      },
    });
  },
  delete: (ids, type) => {
    const url = `/mobility-simulations/delete`;
    return axiosClient.delete(url, {
      data: { mbsm_ids: ids, from: type },
    });
  },
  getMobilityDetail: (id, roleId) => {
    const url = `/mobility-simulations/${id}/details`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        // permission: PERMISSION.MOBILITY_SIMULATION,
      },
    });
  },

  searchPosition: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/mobility-simulations/positions?${stringified}`;
    const headers = {
      role: role.roleId,
      permission: PERMISSION.ADD_INITIAL_POSITION,
    };
    return axiosClient.get(url, {
      headers,
    });
  },

  saveMobilitySimulation: (body, roleActive) => {
    const url = `/mobility-simulations`;
    const headers = { role: roleActive.roleId };
    return axiosClient.post(url, body, {
      headers: headers,
    });
  },

  getPositionDetail: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/mobility-simulations/position-infos?${stringified}`;
    const headers = {
      role: role.roleId,
    };
    return axiosClient.get(url, {
      headers,
    });
  },

  getTalentDetail: (query, role) => {
    const stringified = queryString.stringify(query);
    const url = `/mobility-simulations/talents-infos?${stringified}`;
    const headers = {
      role: role.roleId,
    };
    return axiosClient.get(url, {
      headers,
    });
  },

  getRecommendedReplacement: (query, role) => {
    const { position_code, ids } = query;
    //2105625
    const staffIds = ids.join(',');
    const url = `/mobility-simulations/recommended-replacement?staffIds=${staffIds}&position_code=${position_code}`;
    const headers = {
      role: role.roleId,
      permission: PERMISSION.VIEW_RECOMMENDED_REPLACEMENT,
    };
    return axiosClient.get(url, {
      headers,
    });
  },

  editMobilitySimulation: (body, roleActive) => {
    const url = `/mobility-simulations/${body.mbsm_id}`;
    const headers = { role: roleActive.roleId };
    return axiosClient.put(url, body, {
      headers: headers,
    });
  },
};

export default mobilitySimulationApi;
