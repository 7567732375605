import React from 'react';
import styled from 'styled-components';
import { font } from '../../assets/common';
import { uniqueId } from 'lodash';
import { Spin } from 'antd';

const EdgeWrapper1 = styled.div`
  font-family: ${font.inter};
  display: grid;
  grid-template-columns: [col-1] 100px [col-2] 64px [col-3] 399px [col-4] 196px;
  grid-template-rows: repeat(9, 56px);
  padding-bottom: 20px;
  background-color: #ffffff;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  justify-content: ${(props) => (props?.justify === 'center' ? 'center' : 'flex-start')};
  align-items: center;
  background-color: ${(props) => props?.background};
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #3d3d3d;
  border-bottom: ${(props) => (props?.isBorder ? '1px solid #E7EDF3' : 'none')};
`;
const Header = styled.div`
  display: flex;
  justify-content: ${(props) => (props?.justify === 'center' ? 'center' : 'flex-start')};
  align-items: center;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #8896a7;
`;
const ItemCol = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background-color: ${(props) => props?.background};
  border-bottom: ${(props) => (props?.isBorder ? '1px solid #E7EDF3' : 'none')};
  grid-column: 1;
  grid-row: ${(props) => `${props?.number * 2} / ${props?.number * 2 + 2}`};
`;
const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  color: #181818;
  padding: 10px 17px;
  background-color: ${(props) => props?.background};
`;

const EdgeWrapper2 = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
`;

const EdgeWrapper3 = styled.div`
  font-family: ${font.inter};
  background-color: ${(props) => props?.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e7edf3;
`;

const EdgeWrapper2ColTitle = styled.div`
  width: 20%;
  padding: 10px 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;
const EdgeWrapper2ColContent = styled.div`
  width: 30%;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const EdgeAsessmentRole = styled.div`
  font-family: ${font.inter};
  color: var(--Emphasis-High-N600, #787587);
  font-size: var(--font-size-body-sm, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
  letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
  margin-bottom: 12px;
`;
export const ExternalAssessmentEdge = (props) => {
  const { assessmentData, loading } = props;

  const energise = assessmentData?.data?.edges?.find((f) => f.mainCategory === 'Energise');
  const decide = assessmentData?.data?.edges?.find((f) => f.mainCategory === 'Decide');
  const grow = assessmentData?.data?.edges?.find((f) => f.mainCategory === 'Grow');
  const execute = assessmentData?.data?.edges?.find((f) => f.mainCategory === 'Execute');
  const edge2 = {
    strongest:
      assessmentData?.data?.categoryLevels?.find((f) => f.category === 'Strongest')?.level || '-',
    second_strongest:
      assessmentData?.data?.categoryLevels?.find((f) => f.category === 'Second Strongest')?.level ||
      '-',
  };
  const edge3 = {
    lowest:
      assessmentData?.data?.categoryLevels?.find((f) => f.category === 'Lowest')?.level || '-',
    second_lowest:
      assessmentData?.data?.categoryLevels?.find((f) => f.category === 'Second Lowest')?.level ||
      '-',
  };

  const data = {
    edge1: [
      {
        title: 'ENERGISE',
        title_background: ' #6AC2BE',
        children: [
          {
            number: 1,
            number_background: '#BDE7E5',
            background: '#F4FBFB',
            category: 'Interpersonal Effectiveness',
            proficiency_level:
              energise?.children?.find((f) => f?.category === 'Interpersonal Effectiveness')
                ?.proficiencyLevel || '-',
          },
          {
            number: 2,
            number_background: '#BDE7E5',
            background: '#F4FBFB',
            category: 'Foster Collaboration & Teamwork',
            proficiency_level:
              energise?.children?.find((f) => f?.category === 'Foster Collaboration & Teamwork')
                ?.proficiencyLevel || '-',
          },
        ],
      },
      {
        title: 'DECIDE',
        title_background: ' #8B58B1',
        children: [
          {
            number: 3,
            number_background: '#D4C0E4',
            background: '#F4F0F8',
            category: 'Analysis & Problem Solving',
            proficiency_level:
              decide?.children?.find((f) => f?.category === 'Analysis & Problem Solving')
                ?.proficiencyLevel || '-',
          },
          {
            number: 4,
            number_background: '#D4C0E4',
            background: '#F4F0F8',
            category: 'Set Goal & Drive Direction',
            proficiency_level:
              decide?.children?.find((f) => f?.category === 'Set Goal & Drive Direction')
                ?.proficiencyLevel || '-',
          },
        ],
      },
      {
        title: 'GROW',
        title_background: '#3685D3',
        children: [
          {
            number: 5,
            number_background: '#BEE2FE',
            background: '#F0F9FF',
            category: 'Lead Change & Innovation',
            proficiency_level:
              grow?.children?.find((f) => f?.category === 'Lead Change & Innovation')
                ?.proficiencyLevel || '-',
          },
          {
            number: 6,
            number_background: '#BEE2FE',
            background: '#F0F9FF',
            category: 'Commitment to Learning & Development',
            proficiency_level:
              grow?.children?.find((f) => f?.category === 'Commitment to Learning & Development')
                ?.proficiencyLevel || '-',
          },
        ],
      },
      {
        title: 'EXECUTE',
        title_background: '#68B172',
        children: [
          {
            number: 7,
            number_background: '#C1DCC4',
            background: '#F0F8F0',
            category: 'Deliver Performance',
            proficiency_level:
              execute?.children?.find((f) => f?.category === 'Deliver Performance')
                ?.proficiencyLevel || '-',
          },
          {
            number: 8,
            number_background: '#C1DCC4',
            background: '#F0F8F0',
            category: 'Professionalism & Expertise',
            proficiency_level:
              execute?.children?.find((f) => f?.category === 'Professionalism & Expertise')
                ?.proficiencyLevel || '-',
          },
        ],
      },
    ],
    edge2,
    edge3,
  };
  const accessedRole = assessmentData?.data?.accessRole ?? 'N/A';

  return (
    <Spin spinning={loading} size="small">
      <div>
        <EdgeAsessmentRole>Assessed Role: {accessedRole}</EdgeAsessmentRole>
        <EdgeWrapper2>
          <EdgeWrapper3 background="#E0F3F3">
            <EdgeWrapper2ColTitle>Strongest</EdgeWrapper2ColTitle>
            <EdgeWrapper2ColContent>{data.edge2.strongest}</EdgeWrapper2ColContent>
            <EdgeWrapper2ColTitle>Second Strongest</EdgeWrapper2ColTitle>
            <EdgeWrapper2ColContent>{data.edge2.second_strongest}</EdgeWrapper2ColContent>
          </EdgeWrapper3>
          <EdgeWrapper3 background="#F4FBFB">
            <EdgeWrapper2ColTitle>Lowest</EdgeWrapper2ColTitle>
            <EdgeWrapper2ColContent>{data.edge3.lowest}</EdgeWrapper2ColContent>
            <EdgeWrapper2ColTitle>Second Lowest</EdgeWrapper2ColTitle>
            <EdgeWrapper2ColContent>{data.edge3.second_lowest}</EdgeWrapper2ColContent>
          </EdgeWrapper3>
        </EdgeWrapper2>
        <EdgeWrapper1>
          <Item></Item>
          <Item></Item>
          <Header>Category</Header>
          <Header justify="center">Proficiency Level</Header>
          {data?.edge1.map((item, index) => (
            <React.Fragment key={uniqueId()}>
              <ItemCol number={index + 1} background={item.title_background} isBorder={true}>
                {item.title}
              </ItemCol>
              {item.children.map((child) => (
                <React.Fragment key={uniqueId()}>
                  <Item background={child.background} justify="center">
                    <NumberWrapper background={child.number_background}>
                      {child.number}
                    </NumberWrapper>
                  </Item>
                  <Item background={child.background}>{child.category}</Item>
                  <Item background={child.background} justify="center">
                    {child.proficiency_level}
                  </Item>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </EdgeWrapper1>
      </div>
    </Spin>
  );
};

export default ExternalAssessmentEdge;
