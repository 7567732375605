import { Select } from 'antd';
import styled from 'styled-components';

import { font } from '../../../../../assets/common';

export const Title = styled.p`
  font-family: ${font.inter};
  color: #3f3c4c;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const Label = styled.span`
  font-family: ${font.inter};
  font-style: normal;
  line-height: 20px;
  text-transform: none;
  font-size: 12px;
  font-weight: 500;
  color: #525252;
`;

export const SelectOption = styled(Select)`
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d8d8d;

  &::placeholder {
    font-family: ${font.inter};
  }
`;
