import { isEmpty, isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';

import { DEVELOPMENT_PLAN_MODE } from '../../../../utils/constants';
import DevelopmentPlan from '../DevelopmentPlan/DevelopmentPlan';
import KeyStrengthAndDevelopmentAreas from '../KeyStrengthAndDevelopmentAreas/KeyStrengthAndDevelopmentAreas';
import SuccessionPlanning from '../SuccessionPlanningSection/SuccessionPlanning';
import TalentReviewAndInfoSP from '../TalentReviewAndInfoSP/TalentReviewAndInfoSP';
import TalentReviewMobilityPlan from '../TalentReviewMobilityPlan/TalentReviewMobilityPlan';
import CareerJourneySection from '../CareerJourneySection/CareerJourneySection';

const AddTalentDetail = (props) => {
  const {
    meetingDetail,
    addTalent,
    setAddTalent,
    agendaDetail,
    setShowSaveTalentStatusEdited,
    staffTalentReview,
    handleShowMessage,
    setStaffTalentReview,
    isCreateOrEditRemarks,
    onlyViewTalentAgenda,
    remarksOfConcludedAgenda,
    setFormMobilityPlan,
  } = props;

  const [mode, setMode] = useState(DEVELOPMENT_PLAN_MODE.CREATE);

  useEffect(() => {
    if (isEmpty(agendaDetail)) {
      setMode(DEVELOPMENT_PLAN_MODE.CREATE);
      return;
    }
    if (!isNumber(agendaDetail?.agenda_id)) {
      setMode(DEVELOPMENT_PLAN_MODE.EDIT_DRAFT);
      return;
    }
    setMode(DEVELOPMENT_PLAN_MODE.EDIT);
  }, [agendaDetail]);

  return (
    <div>
      <TalentReviewAndInfoSP
        setAddTalent={setAddTalent}
        addTalent={addTalent}
        setShowSaveTalentStatusEdited={setShowSaveTalentStatusEdited}
        data={staffTalentReview}
        setData={setStaffTalentReview}
        agendaDetail={agendaDetail}
        handleShowMessage={handleShowMessage}
        isCreateOrEditRemarks={isCreateOrEditRemarks}
        staffTalentReview={staffTalentReview}
        onlyViewTalentAgenda={onlyViewTalentAgenda}
      />
      <CareerJourneySection addTalent={addTalent} />
      <KeyStrengthAndDevelopmentAreas
        meetingDetail={meetingDetail}
        agendaDetail={agendaDetail}
        staffTalentReview={staffTalentReview}
        onlyViewTalentAgenda={onlyViewTalentAgenda}
        handleShowMessage={handleShowMessage}
        remarksOfConcludedAgenda={remarksOfConcludedAgenda}
      />
      <SuccessionPlanning
        agendaDetail={agendaDetail}
        addTalent={addTalent}
        setAddTalent={setAddTalent}
        setShowSaveTalentStatusEdited={setShowSaveTalentStatusEdited}
        staffTalentReview={staffTalentReview}
        handleShowMessage={handleShowMessage}
        onlyViewTalentAgenda={onlyViewTalentAgenda}
      />
      <TalentReviewMobilityPlan
        setAddTalent={setAddTalent}
        addTalent={addTalent}
        staffTalentReview={staffTalentReview}
        agendaDetail={agendaDetail}
        onlyViewTalentAgenda={onlyViewTalentAgenda}
        setFormMobilityPlan={setFormMobilityPlan}
      />
      <DevelopmentPlan
        mode={mode}
        addTalent={addTalent}
        setAddTalent={setAddTalent}
        agendaDetail={agendaDetail}
        onlyViewTalentAgenda={onlyViewTalentAgenda}
      />
    </div>
  );
};

export default AddTalentDetail;
