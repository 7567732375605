import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { BasicButton } from '../../../../../assets/common';
import { isCheckError, onChangeInputData } from '../../../../../hooks/useFormik';
import { masterDataManagementApi } from '../../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import {
  ALERT_MESSAGE_TYPE,
  MESSAGE_TYPES,
  TOP_TALENT_STATUS_CATEGORY,
} from '../../../../../utils/constants';
import { AiInput, AiLabel } from '../../../components/NewSuccessionPlanningForm/Styled';
import * as styles from './add-top-talent-category.module.scss';

const AddTopTalentCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  const formValidationSchema = yup.object({
    category: yup.string().required(true),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      category: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  const handleCheckEmptyValue = (values) => {
    let isCheck = false;
    Object.keys(values).forEach((key) => {
      if (isEmpty(values[key])) {
        isCheck = true;
        return true;
      }
    });
    return isCheck;
  };

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  const handleClickBack = () => {
    formik.resetForm();
    history.push('/admin/master-data-management/top-talent-category');
  };

  const handleAddNewCategory = async () => {
    await formik.submitForm();
    const values = formik.values;
    const isCheck = handleCheckEmptyValue(values);
    if (!formik.isValid || isCheck) {
      handleShowMessage(MESSAGE_TYPES.ERROR, TOP_TALENT_STATUS_CATEGORY.REQUIRED_FIELDS);
      return;
    }

    const body = {
      category: values.category.trim(),
    };
    try {
      const res = await masterDataManagementApi.addTopTalentCategory(body, roleActive?.roleId);
      if (res.status === 200) {
        history.push('/admin/master-data-management/top-talent-category');
        handleShowMessage(
          ALERT_MESSAGE_TYPE.SUCCESS,
          TOP_TALENT_STATUS_CATEGORY.ADD_TOP_TALENT_CATEGORY_SUCCESS
        );
      }
    } catch (error) {
      console.error(error);
      const errors = error.response.data.errors;
      switch (errors) {
        case TOP_TALENT_STATUS_CATEGORY.TOP_TALENT_CATEGORY_ALREADY_EXIST:
          handleShowMessage(
            ALERT_MESSAGE_TYPE.ERROR,
            TOP_TALENT_STATUS_CATEGORY.TOP_TALENT_CATEGORY_ALREADY_EXIST
          );
          break;
        case TOP_TALENT_STATUS_CATEGORY.AN_UNEXPECTED_ERROR:
          handleShowMessage(
            ALERT_MESSAGE_TYPE.ERROR,
            TOP_TALENT_STATUS_CATEGORY.AN_UNEXPECTED_ERROR
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="max-width">
      <h3 className={styles.mainTitle}>
        Add New Category
        <p className={styles.noteTitle}>Top Talent Status Category</p>
      </h3>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Add New Details </h4>
        <div className="row">
          <div className="col-5">
            <AiLabel>Category</AiLabel>
            <AiInput
              name="category"
              value={formik.values.category}
              status={isCheckError(formik, 'category') ? 'error' : ''}
              placeholder="Enter Category"
              onChange={(e) => onChangeInputData(formik, e.target.value, 'category')}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-2">
        <BasicButton
          mode="cancel"
          onClick={() => handleClickBack()}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </BasicButton>
        <BasicButton mode="teal" onClick={() => handleAddNewCategory()}>
          Add
        </BasicButton>
      </div>
    </div>
  );
};

export default AddTopTalentCategory;
