import { Button } from 'antd';
import styled, { css } from 'styled-components';
import { color, font, fontSize } from '../../../assets/common';

export const MeetingStatus = styled.div`
  padding: 5.5px 8px;
  border: 1px solid ${color.geyser};
  border-radius: 6px;
  margin-left: 10px;
  p {
    font-size: ${fontSize.small};
    color: ${color.darkBlue};
    font-family: ${font.rubik};
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    margin-left: 20px;
    &::before {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background-color: ${(props) =>
    props.status === 'published' ? `${color.darkGreen}` : `${color.mySin}`};
      left: -13px;
      top: 49%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const AiButton = styled(Button)`
  border-radius: 6px;
  padding: 0px 20px;
  font-weight: 500;
  font-size: ${fontSize.small};
  font-family: ${font.inter};
  border: ${(props) => (props.border ? `1px solid ${color.gallery} !important` : '')};
  ${(props) => {
    switch (props.mode) {
      case 'danger':
        return css`
          background-color: ${color.froly};
          border-color: ${color.froly};
          color: white;
        `;
      case 'teal':
        return css`
          background-color: ${color.teal};
          border-color: ${color.teal};
          color: white;
        `;
      default:
        return css`
          background-color: ${color.white};
          border-color: ${color.white};
          color: ${color.teal};
        `;
    }
  }}

  &:hover {
    ${(props) => {
    switch (props.mode) {
      case 'danger':
        return css`
            background-color: ${color.froly};
            border-color: ${color.froly};
            color: white;
          `;
      case 'teal':
        return css`
            background-color: ${color.teal};
            border-color: ${color.teal};
            color: white;
          `;
      default:
        return css`
            background-color: ${color.white};
            border-color: ${color.white};
            color: ${color.teal};
          `;
    }
  }}
  }
  &:focus {
    ${(props) => {
    switch (props.mode) {
      case 'danger':
        return css`
            background-color: ${color.froly};
            border-color: ${color.froly};
            color: white;
          `;
      case 'teal':
        return css`
            background-color: ${color.teal};
            border-color: ${color.teal};
            color: white;
          `;
      default:
        return css`
            background-color: ${color.white};
            border-color: ${color.white};
            color: ${color.teal};
          `;
    }
  }}
  }
  cursor: none;
`;

export const AiGroupBtn = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AiTitleStartMeeting = styled.p`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.superLarge};
  color: ${color.brightGray2};
  line-height: 38.73px;
  padding-bottom: 20px;
  width: 90%;
`;
export const AiDescStartMeeting = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: 13;
  color: ${color.santasGray};
  line-height: 20px;
  padding-bottom: 35px;
  width: 58%;
`;
export const AiContainerStartMeeting = styled.div`
  background-color: white;
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 107px;
`;
