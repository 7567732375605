import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import { BasicTable } from '../../../../../assets/common';
import More3DotDropdown from '../../../../../components/More3DotDropdown/More3DotDropdown';
import { END_GROUP_ROW_CLASS_NAME } from '../../../../../utils/constants';
import { paginationConfig } from '../../../../../utils/helper';
import { READINESS_LINE, READINESS_TABLE_ROW_LIMIT } from '../../constants';
import * as styles from './sp-readiness-rules-table.module.scss';

const SpReadinessRulesTable = ({
  data,
  searchParams,
  setSearchParams,
  setDeleteParams,
  setShowDeleteConfirm,
}) => {
  const history = useHistory();

  const handleClickDelete = useCallback(
    async (record) => {
      setShowDeleteConfirm(true);
      setDeleteParams((prevState) => ({ ...prevState, deleteRecord: record }));
    },
    [setShowDeleteConfirm, setDeleteParams]
  );

  const handleClickEdit = useCallback(
    async (record) => {
      history.push(`/admin/sp-management/edit-readiness/${record?.group_id}`);
    },
    [history]
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Role',
        dataIndex: 'role_code',
        render: (text, record) => (
          <>
            {record.readiness === READINESS_LINE.FIRST_LINE && (
              <span className={styles.roleCode}>{text}</span>
            )}
          </>
        ),
      },
      {
        title: 'Position JG (Min)',
        dataIndex: 'position_jg_min',
        align: 'center',
        render: (text) => <span className={styles.textField}>{`P${text}`}</span>,
      },
      {
        title: 'Position JG (Max)',
        dataIndex: 'position_jg_max',
        align: 'center',
        render: (text) => <span className={styles.textField}>{`P${text}`}</span>,
      },
      {
        title: 'Readiness',
        dataIndex: 'readiness',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Talent SG (Min)',
        dataIndex: 'talent_sg_min',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Talent SG (Max)',
        dataIndex: 'talent_sg_max',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Matching Percentage Cut Off',
        dataIndex: 'matching_percentage_cut_off',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Min Years to Retirement',
        dataIndex: 'min_years_to_retirement',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        title: 'Remove Contract',
        dataIndex: 'remove_contract',
        align: 'center',
        render: (text) => <span className={styles.textField}>{text}</span>,
      },
      {
        width: 80,
        render: (_, record) =>
          record.readiness === READINESS_LINE.FIRST_LINE && (
            <More3DotDropdown
              item={record}
              handleClickEdit={async () => await handleClickEdit(record)}
              handleClickDelete={async () => await handleClickDelete(record)}
            />
          ),
      },
    ];
  }, [handleClickDelete, handleClickEdit]);

  const pagination = useMemo(() => {
    const onChange = (pageNum) => {
      setSearchParams((prevState) => ({ ...prevState, page: pageNum }));
    };
    return paginationConfig(data.total, searchParams.page, onChange, READINESS_TABLE_ROW_LIMIT);
  }, [data.total, searchParams.page, setSearchParams]);

  const rowSelection = {
    onSelect: (_, __, selectedRows) => {
      setDeleteParams({
        selectedRecords: selectedRows.filter((item) => item !== undefined),
        deleteRecord: null,
      });
    },
    onSelectAll: (_, selectedRows) => {
      const filtered = selectedRows.filter((item) => item?.readiness === READINESS_LINE.FIRST_LINE);
      setDeleteParams({
        selectedRecords: filtered,
        deleteRecord: null,
      });
    },
    renderCell: (_, record, __, originNode) =>
      record.readiness === READINESS_LINE.FIRST_LINE && originNode,
  };

  return (
    <>
      <BasicTable
        groupRow
        rowKey={(record) => `${record.group_id} ${record.readiness}`}
        rowSelection={rowSelection}
        dataSource={data.sp_readiness_rules}
        columns={columns}
        pagination={pagination}
        indentSize={10}
        rowClassName={(_, index) => (index + 1) % 3 === 0 && END_GROUP_ROW_CLASS_NAME}
      />
    </>
  );
};

export default SpReadinessRulesTable;
