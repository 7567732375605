export const READINESS_LINE = {
  FIRST_LINE: '1st Line',
  SECOND_LINE: '2nd Line',
  THIRD_LINE: '3rd Line',
};

export const READINESS_TABLE_ROW_LIMIT = 15;

export const READINESS_DELETE_SUCCESS = 'Rules successfully deleted';

export const TAB_URL_INDEX = 4;

export const MANUAL_RUN_SP_CRITICAL_POSITION_STATUS = {
  RUNNING: 'Running',
  FAILED: 'Failed',
  COMPLETED: 'Completed',
  QUEUE: 'Queue',
};
