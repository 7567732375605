import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { add_external_picture, choose_avatar } from '../../../../../../assets/img';
import FormDate from '../../../../../../components/Form/FormDate/FormDate';
import FormDropdown from '../../../../../../components/Form/FormDropdown/FormDropdown';
import FormInput from '../../../../../../components/Form/FormInput/FormInput';
import styles from './personal-info.module.scss';
import UploadInfor from './UploadInfor/UploadInfor';

export const FirstCol = (props) => {
  const { data, setData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className={styles.sub_title}>Profile Picture</div>
      <div
        style={{ padding: '30px', textAlign: 'center' }}
        onKeyDown={() => {}}
        onClick={() => setIsModalOpen(true)}
      >
        {data.profile_picture && (
          <div className={styles.div_avatar}>
            <img src={data.profile_picture} alt={add_external_picture} className={styles.avatar} />
            <img src={choose_avatar} alt={choose_avatar} className={styles.choose_avatar} />
          </div>
        )}
        {!data.profile_picture && <img src={add_external_picture} alt={add_external_picture} />}
      </div>
      {isModalOpen && (
        <UploadInfor
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setFile={(value) => setData('personal_infor', { ...data, profile_picture: value })}
        />
      )}
    </>
  );
};

export const SecondCol = (props) => {
  const { data, setData, isError, errorSameEmail, setErrorSameEmail } = props;
  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>IC/ Passport No.</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter IC/ Passport No."
          setFormData={(value) => setData('personal_infor', { ...data, passport: value })}
          value={data.passport}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>
          Date of Birth<span style={{ color: '#DA2228' }}>*</span>
        </div>
        <FormDate
          borderColor="#D3DEE8"
          placeholder="Select Date of Birth"
          value={data.date_of_birth}
          setFormData={(value) => {
            setData('personal_infor', {
              ...data,
              date_of_birth: moment(value).format(),
              age: new Date().getFullYear() - moment(value).year(),
            });
          }}
          isError={isError && !data.date_of_birth}
          messageErr="Please fill in this field."
          disabledDate={(current) => current.isAfter(moment())}
        />
      </div>
      <div>
        <div className={styles.sub_title}>
          Email<span style={{ color: '#DA2228' }}>*</span>
        </div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Email"
          value={data.email}
          setFormData={(value) => {
            setData('personal_infor', { ...data, email: value });
            setErrorSameEmail('');
          }}
          isError={
            isError &&
            (!data.email ||
              !data.email.match(
                /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              ) ||
              errorSameEmail)
          }
          messageErr={
            !data.email
              ? 'Please fill in this field.'
              : !data.email.match(
                  /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                )
              ? 'Invalid email address'
              : errorSameEmail === 'Record with the same email is exist. Please enter new record.'
              ? 'Record with the same email is exist. Please enter new record.'
              : null
          }
        />
      </div>
    </>
  );
};

export const ThirdCol = (props) => {
  const { data, setData, nationalities, isError } = props;
  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>
          Full Name<span style={{ color: '#DA2228' }}>*</span>
        </div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Enter Full Name"
          value={data.full_name}
          setFormData={(value) => setData('personal_infor', { ...data, full_name: value })}
          isError={isError && !data.full_name}
          messageErr="Please fill in this field."
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Age</div>
        <FormInput
          borderColor="#D3DEE8"
          placeholder="Age"
          disabled={true}
          value={data.age}
          setFormData={(value) => setData('personal_infor', { ...data, age: value })}
        />
      </div>
      <div>
        <div className={styles.sub_title}>Nationality</div>
        <FormDropdown
          borderColor="#D3DEE8"
          placeholder="Select Nationality"
          options={nationalities}
          value={data.nationality}
          setFormData={(value) => setData('personal_infor', { ...data, nationality: value })}
        />
      </div>
    </>
  );
};

const PersonalInfo = (props) => {
  return (
    <div className={styles.margin}>
      <div className={styles.title} data-testid="external-personal-info">
        Personal Info
      </div>
      <Row justify="space-between">
        <Col style={{ width: '31%' }}>
          <FirstCol {...props} />
        </Col>
        <Col style={{ width: '31%' }}>
          <SecondCol {...props} />
        </Col>
        <Col style={{ width: '31%' }}>
          <ThirdCol {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default PersonalInfo;
