import { Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';

import * as SC from './styled';

export default function DataTableUserActivity({
  data,
  mode,
  page,
  limit,
  onPageChange,
  totalData,
}) {
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{(page - 1) * limit + text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'left',
      render: (text) => (
        <span style={{ color: '#3F3C4C', fontWeight: 700, display: 'block', maxWidth: '288px' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'left',
      render: (text) => (
        <span style={{ color: '#999A9C', display: 'block', maxWidth: '300px', minWidth: '200px' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      align: 'left',
      render: (text) => (
        <span style={{ color: '#999A9C', display: 'block', maxWidth: '300px', minWidth: '200px' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'time',
      align: 'left',
      render: (text) => (
        <span style={{ color: '#999A9C', minWidth: '140px', display: 'inline-block' }}>
          {!isEmpty(text) ? moment(text).format('DD-MMMM-YYYY HH:mm:ss') : ''}
        </span>
      ),
    },
  ];

  const rowSelection = {
    hideSelectAll: true,
  };

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== page && onPageChange(pageNum),
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  return (
    <div style={{ paddingTop: 24 }}>
      <SC.TableAuditLog>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableAuditLog>
    </div>
  );
}
