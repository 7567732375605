const titles = [
  'staff_id',
  'staff_name',
  'position_code',
  'set_name',
  'years_in_position',
  'years_in_sg',
  'age',
  'retirement_date',
  'current_position',
  'talent_level',
  'sg',
  'matching_percentage',
  'sp_readiness',
  'edge_match',
  'edge_spike_match',
  'attr27_match',
  'attr27_spike_match',
  'function_score',
  'business_score',
  'function_spike_score',
  'business_spike_score',
  'ppa_score',
  'vp_score',
  'sgm_score',
  'edge',
  'edge_spike',
  'attr27',
  'attr27_spike',
  'functional',
  'business',
  'functional_spike',
  'business_spike',
  'ppa',
  'vp',
  'sgm',
  'filter_incumbent',
  'top_talent_experience_optional',
  'personality_archetype_optional',
];

export const VIEW_RESULT_MATCHING_EXPORT_COLUMNS = titles.map((title) => ({ title }));
