import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import shage from '../../assets/img/Shape.svg';
import { SIZE_DROPDOWN } from '../../utils/constants';
import styles from './dropdown-custom-lg.module.scss';

const DropdownCustomLg = ({
  selected,
  setSelected,
  options,
  type,
  showOption = false,
  customStyle,
  loading,
  testId,
}) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  const display = {
    btn: () => {
      if (!type) return;
      if (type === SIZE_DROPDOWN.NOR) return `${styles.dropdownBtn} ${styles.dropdownBtnNormal}`;
      if (type === SIZE_DROPDOWN.SM || type === SIZE_DROPDOWN.SSM)
        return `${styles.dropdownBtn} ${styles.dropdownBtnSM}`;
      return `${styles.dropdownBtn}`;
    },
    content: () => {
      if (!type) return;
      if (type === SIZE_DROPDOWN.NOR)
        return `${styles.dropdownContent} ${styles.dropdownContentNormal}`;
      if (type === SIZE_DROPDOWN.SM) return `${styles.dropdownContent} ${styles.dropdownContentSM}`;
      if (type === SIZE_DROPDOWN.SSM)
        return `${styles.dropdownContent} ${styles.dropdownContentSM} ${styles.dropdownContentSSM}`;
      return `${styles.dropdownBtn}`;
    },
    item: () => {
      if (!type) return;
      if (type === SIZE_DROPDOWN.NOR) return `${styles.dropdownItem} ${styles.dropdownItemNormal}`;
      if (type === SIZE_DROPDOWN.SM || type === SIZE_DROPDOWN.SSM)
        return `${styles.dropdownItem} ${styles.dropdownItemSM}`;

      return `${styles.dropdownItem}`;
    },
    showOption: showOption
      ? `${styles.dropdownSelectLabel} ${styles.dropdownSelectLabel__Hidden}`
      : `${styles.dropdownSelectLabel}`,
  };
  const content = {
    active: isActive
      ? `${display.content()} ${styles.dropdownContent__active}`
      : `${display.content()}`,
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={display.btn()}
        onKeyDown={() => {}}
        onClick={() => !loading && setIsActive(!isActive)}
        style={customStyle?.dropdownBorder || {}}
      >
        <p
          className={display.showOption}
          data-testid="selected"
          style={customStyle?.dropdownLabel || { color: '#00A19C' }}
        >
          {selected.label}
        </p>
        <img src={shage} alt="" className={styles.imgColor} />
      </div>

      <div className={content.active}>
        {!isEmpty(options) &&
          options.map((option, index) => {
            return (
              <div
                key={index}
                onKeyDown={() => {}}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={display.item()}
              >
                <p
                  className={styles.dropdownItemLabel}
                  data-testid={testId ? `select-${index}-${testId}` : `select-${index}`}
                  style={customStyle?.dropdownItemLabel}
                >
                  {option.label}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default DropdownCustomLg;
