import React from 'react';

import { Warning } from '../components';

export const onChangeInputData = (formik, value, key) => {
  formik.setFieldValue(key, value);
};

export const isCheckError = (formik, name) => {
  if (!formik.errors[`${name}`] && !formik.touched[`${name}`]) return false;
  if (formik.errors[`${name}`] && formik.touched[`${name}`]) return true;
};

export const showError = (formik, name) => {
  if (!formik.errors[`${name}`] && !formik.touched[`${name}`]) return null;
  if (formik.errors[`${name}`] && formik.touched[`${name}`])
    return <Warning>{formik.errors[`${name}`]}</Warning>;
};

export const onChangeSelectData = (formik, event, key) => {
  formik.setFieldValue(key, event);
};
