import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { BasicButton } from '../../assets/common';
import AlertNotification from '../AlertMessage/AlertNotification';
import styles from './add-new-note.module.scss';
import SwitchMode from './components/SwitchMode';

const ErrorInput = ({ isError, inputValue }) => {
  return (
    <>
      {isError && !inputValue && (
        <div className={`mt-1 ${styles.text_error}`}>Please fill in the required fields</div>
      )}
    </>
  );
};

const AddNewNote = ({
  isOpen,
  setIsOpenNote,
  isEdit,
  setIsEdit,
  handleNote,
  dataEdit,
  isAddEditNonPrivateNote,
  isAddEditPrivateNote,
}) => {
  const [enteredPlatform, setEnteredPlatform] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [enteredNote, setEnteredNote] = useState('');
  const [isError, setIsError] = useState(false);
  const [isPrivate, setIsPrivate] = useState(!isAddEditNonPrivateNote && isAddEditPrivateNote);

  const onHandleNote = () => {
    if (!enteredPlatform || !selectedDate || !enteredNote) {
      setIsError(true);
      return;
    }

    if (isEdit && dataEdit) {
      const dataNote = {
        id: dataEdit?.id,
        platform: enteredPlatform,
        date: selectedDate,
        notes: enteredNote,
        is_private: isPrivate,
      };
      handleNote(dataNote, isPrivate);
    } else {
      const dataNote = {
        platform: enteredPlatform,
        date: selectedDate,
        notes: enteredNote,
        is_private: (!isAddEditNonPrivateNote && isAddEditPrivateNote && !isEdit) || isPrivate,
      };
      handleNote(dataNote, dataEdit.is_private);
    }

    resetState();
  };

  const onClosePopup = () => {
    setIsOpenNote(false);
    resetState();
  };

  const resetState = () => {
    setIsError(false);
    setIsEdit(false);
    setEnteredPlatform('');
    setSelectedDate('');
    setEnteredNote('');
    setIsPrivate(false);
  };

  useEffect(() => {
    if (isEdit && dataEdit) {
      setEnteredPlatform(dataEdit.platform);
      setSelectedDate(dataEdit.date);
      setEnteredNote(dataEdit.notes_content);
      setIsPrivate(dataEdit.is_private);
    } else {
      setEnteredPlatform('');
      setSelectedDate('');
      setEnteredNote('');
    }
  }, [dataEdit, isEdit]);

  return (
    <Modal
      show={isOpen}
      onHide={onClosePopup}
      dialogClassName={styles.view_note}
      aria-labelledby="example-custom-modal-styling-title"
      scrollable={true}
    >
      <Modal.Header className={styles.modal_header} closeButton={true}>
        <div className={styles.modal_header_title} data-testid="note-update-title">
          {isEdit ? 'Edit Notes' : 'Add New Note'}
        </div>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <AlertNotification
          style={{ marginBottom: '12px' }}
          show={isError}
          setShow={setIsError}
          type="error"
          message="There are items that require your attention. Please fill out this field."
        />
        <h4>{isEdit ? 'Edit Current Notes' : 'Add New Note on the platform'}</h4>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={`${styles.label}`}>
              Platform<span style={{ color: 'red' }}>*</span>
            </div>
            <input
              type="text"
              className={styles.formInput}
              value={enteredPlatform}
              placeholder="Platform"
              onChange={(e) => setEnteredPlatform(e.target.value)}
            />
            <ErrorInput isError={isError} inputValue={enteredPlatform} />
          </div>
          <div className={styles.col}>
            <div className={`${styles.label}`}>
              Date<span style={{ color: 'red' }}>*</span>
            </div>
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentElement}
              defaultValue={isEdit ? moment(dataEdit.date) : null}
              className={styles.formInput}
              onChange={(_, value) => setSelectedDate(value)}
              format="DD MMMM YYYY"
              inputReadOnly
            />
            <ErrorInput isError={isError} inputValue={selectedDate} />
          </div>
          {(isAddEditNonPrivateNote || isAddEditPrivateNote) && (
            <div className={styles.switchInput}>
              <div className={`${styles.label}`}>
                Visibility<span style={{ color: 'red' }}>*</span>
              </div>
              <SwitchMode
                isPrivate={
                  (!isAddEditNonPrivateNote && isAddEditPrivateNote && !isEdit) || isPrivate
                }
                setIsPrivate={setIsPrivate}
              />
            </div>
          )}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <div className={`${styles.label}`} style={{ alignItems: 'unset' }}>
            Notes<span style={{ color: 'red' }}>*</span>
          </div>
          <textarea
            className={styles.formInput}
            value={enteredNote}
            onChange={(e) => setEnteredNote(e.target.value)}
            style={{ height: '94px' }}
            data-testid="note-textarea"
          />
          <ErrorInput isError={isError} inputValue={enteredNote} />
        </div>

        <div className="row">
          <div className={`col ${styles.content}`}>
            <BasicButton onClick={onClosePopup} data-testid="cancel">
              Cancel
            </BasicButton>

            <BasicButton className="ml-3" mode="teal" onClick={onHandleNote} data-testid="submit">
              {isEdit ? 'Save Changes' : 'Add'}
            </BasicButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewNote;
