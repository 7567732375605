import { Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { copy_sp } from '../../../../../assets/img';
import * as SC from '../../ManagingCriticalPosition/DataTable/styled';

export default function DataTable({ data, setIsVisible, setDataCopyBtn }) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [data.length]);

  const columns = (setIsVisible, data) => [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span style={{ color: '#999A9C' }}>{(currentPage - 1) * 15 + 1 + index}</span>
      ),
    },
    {
      title: 'Position Code',
      dataIndex: 'position_code',
      width: '28%',
      render: (text) => <span style={{ color: '#3F3C4C', fontWeight: 700 }}>{text}</span>,
    },
    {
      title: 'Matching Criteria',
      dataIndex: 'matching_criteria',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{text ? 'Yes' : 'No'}</span>,
    },
    {
      title: 'Approved Date',
      dataIndex: 'approved_date',
      render: (text) => (
        <span style={{ color: '#999A9C' }}>{moment(text).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      title: '',
      dataIndex: 'copy',
      key: 'x',
    },
  ];

  const paginationConfig = {
    current: currentPage,
    showSizeChanger: false,
    defaultPageSize: 15,
    onChange: (pageNum) => pageNum !== currentPage && setCurrentPage(pageNum),
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  return (
    <div style={{ paddingTop: 24 }}>
      <SC.TableManagement>
        <Table
          columns={columns(setIsVisible, data)}
          dataSource={data.map((d) => ({
            ...d,
            key: d.id,
            copy: (
              <div
                style={{ cursor: 'pointer' }}
                onKeyDown={() => {}}
                onClick={() => {
                  setDataCopyBtn(d);
                  setIsVisible(true);
                }}
              >
                <Space>
                  <img data-testid="copy-btn" src={copy_sp} alt="" />
                </Space>
              </div>
            ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
    </div>
  );
}
