import { useMsal } from '@azure/msal-react';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Spinner } from './components';
import { getAccessToken } from './msalConfig';
import { confirmAutoLogout, isPermittedItem, isTokenExpired } from './utils/helper';

const SpinnerCol = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LayoutRoute = ({
  component: Component,
  layout: Layout,
  requireAuth,
  permission,
  componentProps,
  ...rest
}) => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const username = useSelector((state) => state.user.username);
  const permissions = useSelector((state) => state.user.permissions);

  const location = useLocation();

  useEffect(() => {
    const handleCheckTokenExpired = async () => {
      const accessTokenResponse = await getAccessToken();
      if (accessTokenResponse?.accessToken) {
        const isCheckTokenExpired = isTokenExpired(accessTokenResponse.accessToken);
        if (isCheckTokenExpired) {
          confirmAutoLogout();
          instance.logoutRedirect({ postLogoutRedirectUri: '/' });
        }
      }
    };
    handleCheckTokenExpired();
  }, [instance]);

  const isLogin = Boolean(Number(process.env.REACT_APP_BYPASS_LOGIN) || 0);
  if (
    location.pathname !== '/' &&
    !((currentAccount?.name && username) || isLogin) &&
    requireAuth === true
  ) {
    return <Redirect to="/" />;
  }
  const checkPermission = () => {
    if (permission) {
      const hasPermitted = isPermittedItem(permission, permissions);
      if (!hasPermitted) {
        return false;
      }
    }
    return true;
  };
  const isPermitted = checkPermission();

  if (!isPermitted) return <Redirect to="/access-denied" />;

  const additionalProps = componentProps || {};

  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="wrapper">
          <div className="container">
            <Layout>
              <Suspense
                fallback={
                  <SpinnerCol>
                    <Spinner />
                  </SpinnerCol>
                }
              >
                <Component {...props} {...additionalProps} />
              </Suspense>
            </Layout>
          </div>
        </div>
      )}
    />
  );
};
export default LayoutRoute;
