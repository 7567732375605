import { MenuOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { Avatar, Notification, SearchBox } from '..';
import { logo, talent } from '../../assets/img/';
import userApi from '../../services/userApi';
import { saveStatusLeftMenu } from '../../store/pageSlice';
import { ACTION_LOG } from '../../utils/constants';
import styles from './header.module.scss';

const Header = () => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const statusLeftMenu = useSelector((state) => state.page.statusLeftMenu);
  const username = useSelector((state) => state.user.username);
  const isLogin = Boolean(Number(process.env.REACT_APP_BYPASS_LOGIN) || 0);

  const userLogout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    try {
      const res = await userApi.logAction({ url: '', type: ACTION_LOG.LOGOUT });
      console.log('res', res);
    } catch (error) {
      console.error(error);
    }
  };

  const directToHome = () => {
    history.push('/homepage');
  };

  const toggleMenu = () => {
    dispatch(saveStatusLeftMenu(!statusLeftMenu));
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div
            onKeyDown={() => {}}
            onClick={() => directToHome()}
            className={styles.logo}
            data-testid="click-direct-to-home"
          >
            <img src={logo} alt="Logo" className={styles.icon} />
            <img src={talent} alt="Talent" className={styles.text} />
          </div>
          {currentAccount?.name && username && (
            <div
              onKeyDown={() => {}}
              onClick={() => toggleMenu()}
              className={styles.toggle}
              data-testid="click-toggle"
            >
              <MenuOutlined className={styles.menuOutlined} />
            </div>
          )}
        </div>
        <div className={styles.right}>
          {((currentAccount?.name && username) || isLogin === true) && (
            <>
              <SearchBox placeholder="Search for talent using Name, Department, Designation or Petronas ID" />
              <div className={styles.header_group}>
                <Notification />
                <Avatar />
                <div className={styles.user_name}>{username}</div>
                {isLogin === false && (
                  <DropdownButton
                    menuAlign="right"
                    id="dropdown-menu-align-right"
                    className={styles.dropdown}
                    title=""
                    data-testid="toggle-logout"
                  >
                    <Dropdown.Item
                      eventKey="4"
                      onKeyDown={() => {}}
                      onClick={() => userLogout()}
                      data-testid="click-logout"
                    >
                      Logout
                    </Dropdown.Item>
                  </DropdownButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
