import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchBoxCustom from '../../../../components/SearchBoxCustom/SearchBoxCustom';
import {
  resetCriteriaState,
  updateMatchingPosition,
} from '../../../redux/manageCriteriaSetMatchingSlice';
import { updatePosition } from '../../../redux/manageCriteriaSlice';
import * as styles from './add-criteria-position-form.module.scss';

const AddMatchingCriteriaPositionForm = (props) => {
  const { isEdit, isSummary } = props;
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState([]);
  const { position_code, position_name } = useSelector(
    (state) => state.app.adminModule.manageCriteriaSetMatching
  );
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  useEffect(() => {
    dispatch(resetCriteriaState);
  });

  useEffect(() => {
    if (searchTerm.type === 'submit') {
      dispatch(
        updatePosition({
          position_code: searchTerm.value.position_code,
          position_name: searchTerm.value.position_name,
        })
      );
      dispatch(
        updateMatchingPosition({
          position_code: searchTerm.value.position_code,
          position_name: searchTerm.value.position_name,
        })
      );
    }
  }, [searchTerm.type, dispatch, searchTerm.value.position_code, searchTerm.value.position_name]);

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Add New Details </h4>
      <div className="row">
        <div className="col-4">
          <label>Position Code</label>
          {isEdit ? (
            <input
              value={position_code}
              className={styles.positionName}
              placeholder="Position Code"
              readOnly
            />
          ) : (
            <SearchBoxCustom
              isReadOnly={isSummary || isEdit}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchList={searchList}
              setSearchList={setSearchList}
              fieldName={'position_code_criteria'}
              searchPlaceholder={'Search Position Code'}
            />
          )}
        </div>
        <div className="col-4">
          <label>Position Name</label>
          <input
            value={position_name || ''}
            className={styles.positionName}
            placeholder="Position Name"
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default AddMatchingCriteriaPositionForm;
