import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../../hooks';
import mobilitySimulationApi from '../../../../services/mobilitySimulationApi';
import userApi from '../../../../services/userApi';
import { PERMISSION } from '../../../../utils/constants';
import MobilitySearchPositionOption from '../MobilitySearchPositionOption/MobilitySearchPositionOption';
import * as styles from './mobility-search-position-box.module.scss';

const MobilitySearchPositionBox = (props) => {
  const { selectedSp, searchTerm, setSearchTerm, searchList, setSearchList, isAddPosition } = props;
  const [loading, setLoading] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const debounceSearchQuery = useDebounce(searchTerm, 500);

  const handleOnChange = (value) => {
    setSearchTerm((prev) => {
      return {
        ...prev,
        value,
        type: 'search',
        showTerm: value,
      };
    });
  };

  useEffect(() => {
    if (debounceSearchQuery.type === 'search') {
      fetchAPI();
    }
    async function fetchAPI() {
      if (!isEmpty(debounceSearchQuery.value)) {
        setLoading(true);
        try {
          const response = isAddPosition
            ? await mobilitySimulationApi.searchPosition(
                { keyword: debounceSearchQuery.value },
                roleActive
              )
            : await userApi.getSearchList(
                {
                  permission: PERMISSION.ADD_TALENT_MANUALLY,
                  role: roleActive.roleId,
                },
                { search: debounceSearchQuery.value, limit: 5, page: 1, type: 'MS' }
              );
          const arr = isAddPosition
            ? get(response, 'data.result')
            : get(response, 'data.result.profiles');
          setSearchList(arr || []);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setSearchList([]);
          setLoading(false);
        }
      }
      if (isEmpty(debounceSearchQuery.value)) {
        setSearchList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchQuery, roleActive]);

  return (
    <div className={styles.search_box} tabIndex={0} data-testid="search-box">
      <input
        id="position"
        data-testid="position-input"
        name="position"
        placeholder={isAddPosition ? 'Search Position' : 'Search Talent'}
        className={styles.position_input}
        onChange={(e) => handleOnChange(e.target.value)}
        value={searchTerm.showTerm}
        autoComplete="off"
        disabled={selectedSp}
      />
      {searchTerm.value && searchTerm.type === 'search' && (
        <MobilitySearchPositionOption
          data={searchList}
          setSearchTerm={setSearchTerm}
          loading={loading}
          debounceSearchQuery={debounceSearchQuery}
          isAddPosition={isAddPosition}
        />
      )}
    </div>
  );
};

export default MobilitySearchPositionBox;
