import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { utils } from 'xlsx-js-style';

import { ROLES_DASHBOARD } from '../../../constants';
import {
  historicalExportDeclareWorkSheet,
  historicalExportSetMergeAndColWidth,
  historicalExportStyleAllCells,
  historicalExportUnshiftHeader,
  historicalSetColorsAndWriteFile,
} from './CommonExportHistorical';

const ExportBusiness = (props) => {
  const { setExportData, historicalData } = props;

  const convertDataToExport = useCallback(async () => {
    let tempData = [];
    historicalData.forEach((item) => {
      if (!isEmpty(item.value)) {
        ROLES_DASHBOARD.forEach((role, index) => {
          tempData.push({
            month: index === 0 ? item.month : '',
            role: `${role}: `,
            upstream: `${item.value[0][role]}`,
            downstream: `${item.value[1][role]}`,
            gas: `${item.value[2][role]}`,
            pdt: `${item.value[3][role]}`,
            corporate: `${item.value[4][role]}`,
            other: `${item.value[5][role]}`,
          });
        });
      }
    });
    return tempData;
  }, [historicalData]);

  const exportRetirementForecast = useCallback(async () => {
    const fileName = 'Business';
    const length = 8;
    const headers1 = new Array(length).fill('');
    headers1[0] = 'Months (End of months)';
    headers1[1] = 'Business';
    const headers2 = new Array(length).fill('');
    headers2[0] = 'Months (End of months)';
    headers2[1] = 'Role';
    headers2[2] = 'Upstream';
    headers2[3] = 'Downstream';
    headers2[4] = 'GAS';
    headers2[5] = 'PD & T';
    headers2[6] = 'Corporate';
    headers2[7] = 'Other';
    const arr = [];
    const newData = await convertDataToExport();
    newData.forEach((item) => {
      const rows = [
        item.month,
        item.role,
        item.upstream,
        item.downstream,
        item.gas,
        item.pdt,
        item.corporate,
        item.other,
      ].flat();
      arr.push(rows);
    });
    
    const shortHandMerges = [
      [0, 0],
      [1, 0],
      [0, 1],
      [0, length - 1],
    ];

    historicalExportUnshiftHeader(arr, [headers2, headers1]);

    if (arr.length > 0) {
      let [ws, wb] = historicalExportDeclareWorkSheet(arr);
      utils.book_append_sheet(wb, ws, 'Business');

      historicalExportSetMergeAndColWidth(ws, shortHandMerges, length, 15);

      historicalExportStyleAllCells(ws);

      const colorCellMapping = {
        '00a9b1': ['A1', 'B1'],
        '7030a0': ['B2', 'C2', 'D2', 'E2', 'F2', 'G2', 'H2'],
      };

      historicalSetColorsAndWriteFile(ws, wb, fileName, colorCellMapping);
    }
  }, [convertDataToExport]);

  useEffect(() => {
    exportRetirementForecast();
    setExportData(false);
  }, [setExportData, exportRetirementForecast]);
  return <></>;
};

ExportBusiness.propTypes = {
  setExportData: PropTypes.func.isRequired,
  historicalData: PropTypes.array.isRequired,
};

export default ExportBusiness;
