import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import { BEPCB } from '../../../../../utils/constants';
import { profileSelector } from '../../../selector';
import styles from '../overall-profile-user.module.scss';

const UserCareer = () => {
  const [performance, setPerformnace] = useState({});
  const { thirdSectionProfile, loading } = useSelector(profileSelector);
  const handlePerformance = () => {
    let fy = [];
    let PPA = [];
    let bepcb = [];
    let desgination = [];

    thirdSectionProfile.performances.forEach((item, _index) => {
      fy.push(String(item.year).slice(-2));
      PPA.push(item.perfomanceRating);
      handlebePCB(item.bepcbRating, bepcb);
      desgination.push(item.positions);
    });
    let desginationAfterConvert = handleConverDataOfDesgination(desgination);
    let arrTotal = [fy, PPA, bepcb, desginationAfterConvert];
    let { arrLackItem, max } = handleCheckingSize(arrTotal);
    hanleFillOut(arrTotal, arrLackItem, max);
    setPerformnace({
      fy,
      PPA,
      bepcb,
      desgination: desginationAfterConvert,
    });
  };
  const handleCheckingSize = (arr) => {
    let max = 0;
    let arrLackItem = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].length >= max) {
        max = arr[i].length;
      } else {
        arrLackItem.push({
          size: arr[i].length,
          index: i,
        });
      }
    }
    return { arrLackItem, max };
  };
  const hanleFillOut = (arrTotal, arrLackItem, max) => {
    if (arrLackItem.length === 0) return;
    arrLackItem.forEach((item) => {
      arrTotal[item.index].push(...new Array(max - item.size));
    });
  };
  const handleConverDataOfDesgination = (desgination) => {
    let newDesgination = desgination.map((item) => {
      if (item[0]) return item[0];
      return '-';
    });
    return handleCheckRepeatOfDesgination(newDesgination);
  };
  const handleCheckRepeatOfDesgination = (desgination) => {
    let newDesgination = [];
    let numberCheck = desgination[0];
    let count = 1;
    for (let i = 1; i <= desgination.length; i++) {
      if (numberCheck !== desgination[i]) {
        newDesgination.push({
          repeat: count,
          value: numberCheck,
        });
        numberCheck = desgination[i];
        count = 1;
      } else {
        ++count;
      }
    }
    return newDesgination;
  };

  const handlebePCB = (item, bepcb) => {
    switch (item) {
      case BEPCB.DEVELOPING:
        bepcb.push('Dev');
        break;
      case BEPCB.NEEDS_DEVELOPMENT:
        bepcb.push('N.Dev');
        break;
      case BEPCB.EFFECTIVE:
        bepcb.push('Eff');
        break;
      case BEPCB.DISTINCTIVE:
        bepcb.push('Dist');
        break;
      case BEPCB.PROFICIENT:
        bepcb.push('Prof');
        break;
      case BEPCB.EXCEPTIONAL:
        bepcb.push('Excp');
        break;
      case BEPCB.ROLE_MODEL:
        bepcb.push('R.M');
        break;
      default:
        bepcb.push('');
        break;
    }
  };

  useEffect(() => {
    setPerformnace({});
    if (isEmpty(thirdSectionProfile)) return;
    if (thirdSectionProfile.performances) {
      let isCheck = thirdSectionProfile.performances.every(
        (item) => item.year !== null && item.year !== undefined
      );
      if (isCheck) {
        handlePerformance();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdSectionProfile]);
  const displayPerformance = (text, type) => {
    if (loading) {
      if (type === 'th') {
        return (
          <th style={{ backgroundColor: '#f4f5f8' }}>
            <Skeleton count={1} height={21} width={490}></Skeleton>
          </th>
        );
      } else {
        return (
          <td>
            <Skeleton count={1} height={21} width={490}></Skeleton>
          </td>
        );
      }
    } else {
      return <>{text}</>;
    }
  };
  return (
    <>
      <table>
        <thead>
          <tr>
            {displayPerformance(
              <>
                <th>FY</th>
                {performance.fy &&
                  performance.fy.map((item, index) => {
                    return <th key={index}>'{item}</th>;
                  })}
              </>,
              'th'
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {displayPerformance(
              <>
                <td>Performance Rating</td>
                {performance &&
                  performance.PPA &&
                  performance.PPA.length > 0 &&
                  performance.PPA.map((item, index) => {
                    if (item) return <td key={index}>{item}</td>;
                    return <td key={index}></td>;
                  })}
              </>
            )}
          </tr>
          <tr>
            {displayPerformance(
              <>
                <td>BePCB</td>
                {performance &&
                  performance.bepcb &&
                  performance.bepcb.length > 0 &&
                  performance.bepcb.map((item, index) => {
                    if (item) return <td key={index}>{item}.</td>;
                    return <td key={index}></td>;
                  })}
              </>
            )}
          </tr>
          <tr>
            {displayPerformance(
              <>
                <td className={styles.designation}>Designation</td>
                {!isEmpty(performance?.desgination) &&
                  performance.desgination.map((item, index) => {
                    if (item) {
                      return (
                        <td key={index} colSpan={item.repeat}>
                          <Tooltip
                            placement={'bottom'}
                            className={styles.customTooltip}
                            overlayStyle={{ minWidth: '150px' }}
                            title={
                              <div className={styles.tooltipContent}>
                                <span style={{ fontWeight: 600 }}>{item.value}</span>
                              </div>
                            }
                            overlayInnerStyle={{ borderRadius: '6px' }}
                          >
                            <div
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: `${item.repeat * 70}px`,
                                display: 'block',
                                padding: '5px',
                              }}
                              className={styles.desginationText}
                            >
                              {item.value}
                            </div>
                          </Tooltip>
                        </td>
                      );
                    }
                    return null;
                  })}
              </>
            )}
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default UserCareer;
