import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as styles from './talent-video-item.module.scss';
import { video } from '../../../../../../assets/img';
import moment from 'moment';
import { AiCheckBox } from '../../../../../../assets/common';

const TalentVideoItem = (props) => {
  const { data, isPopup, isEnableDelete, setSelectedDelete } = props;

  const display = useMemo(() => {
    return {
      dateFormat: isPopup ? 'Do MMMM YYYY' : 'DD MMM YYYY',
      style: isPopup ? { border: '1px solid #d9d9d9' } : { padding: '15px 0' },
    };
  }, [isPopup]);

  const handleClickCheckbox = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedDelete((prev) => [...prev, data.id]);
      return;
    }
    setSelectedDelete((prev) => prev.filter((id) => id !== data.id));
  };

  return (
    <a target="_blank" rel="noreferrer" className={styles.wrapper} style={display.style} href={data?.link}>
      {isEnableDelete && <AiCheckBox onClick={handleClickCheckbox} data-testid={data?.id} />}
      <img src={video} alt="video-icon" />
      <div className={styles.content}>
        <div className={styles.title}>{data?.title}</div>
        <div className={styles.date}>Last Edited: {moment(data?.created_at).format(display.dateFormat)}</div>
      </div>
    </a>
  );
};

TalentVideoItem.propTypes = {
  data: PropTypes.object,
  isPopup: PropTypes.bool,
  isEnableDelete: PropTypes.bool,
  setSelectedDelete: PropTypes.func,
};

export default TalentVideoItem;
