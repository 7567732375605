import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { OptionChartSPandBU, OptionNormal } from '../../share';
import './index.scss';

const SuccessorChart = ({ data, categories }) => {
  const [series, setSeries] = useState([]);

  let option = {
    ...OptionChartSPandBU,
    xaxis: {
      categories: categories,
      labels: OptionNormal.labels,
    },
    legend: {
      ...OptionNormal.legendSPandBU,
      colors: ['#444444'],
      offsetX: 50,
      offsetY: 15,
      markers: {
        width: 18,
        height: 18,
        radius: 5,
        fillColors: ['#90A0CD', '#7AC8C5', '#8BC5F5', '#BAC7D5', '#BB9FCC'],
        offsetX: -5,
        offsetY: 0,
      },
      itemMargin: {
        // horizontal: 90,
        vertical: 10,
      },
    },
  };
  useEffect(() => {
    setSeries(data);
  }, [data, categories]);
  const [options] = React.useState(option);

  return (
    <div id="successorChart">
      <div>
        {' '}
        <Chart options={options} series={series} type="bar" height={386} width={1201} />
      </div>
    </div>
  );
};
export default SuccessorChart;
