import styled from 'styled-components';
import { color } from '../../../../assets/common';
export const AiDropdown = styled.div`
  background: ${(props) => (props.disabled ? '#e0e0e0' : 'white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  padding: 12px 16px;
  height: 44px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  img {
    // padding-left: 6px;
    // padding-top: 4px;
    color: #bababa;
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      border-color: ${color.teal} !important;
    }
  }
`;
