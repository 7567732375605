import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { dot_active_fulfilment } from '../../../../../assets/img';
import { talentReviewApi } from '../../../../../services/talentReviewApi';
import { CheckboxFulfilment, Title, TitleEmptyState } from './fullFillmentStyled';

export default function Fullfillment(props) {
  const { staffId, data, positionSelected } = props;
  const [dataRender, setDataRender] = useState([]);

  const next_mobility_codes = [
    ...new Set([...data.next_mobility.selection.map((item) => item?.position_code?.toString())]),
  ];
  const next_2_mobility_codes = [
    ...new Set([...data.next_2_mobility.selection.map((item) => item?.position_code?.toString())]),
  ];
  const position_code = [
    ...new Set([...data.end_state.selection.map((item) => item?.position_code?.toString())]),
  ];
  useEffect(() => {
    if (!staffId || isEmpty(position_code)) return setDataRender([]);
    (async function getFulfilment() {
      try {
        const res = await talentReviewApi.getCriteriaFulfilment({
          staff_id: staffId,
          position_code: position_code.join(','),
          next_mobility_codes: next_mobility_codes.join(','),
          next_2_mobility_codes: next_2_mobility_codes.join(','),
        });
        const endState = get(res, 'data.result.endStateData') || [];
        const nextMobility = get(res, 'data.result.nextMobilityData') || [];
        const next2Mobility = get(res, 'data.result.next2MobilityData') || [];

        const dataRender = [
          ...endState.map((item) => {
            return {
              isActive: item.dimension === 'Strength',
              ...item,
            };
          }),
        ];

        nextMobility.forEach((item) => {
          const existedEndstate = dataRender.find((endstate) => endstate?.content == item?.content);
          if (!existedEndstate) {
            dataRender.push({ ...item, isActive: item?.dimension === 'Strength' });
          }

          existedEndstate.isDot = item?.dimension === 'Strength';
          if (!existedEndstate.isDot) {
            existedEndstate.mobility_code = existedEndstate?.mobility_code
              ? existedEndstate.mobility_code + `,${item?.mobility_code}`
              : item?.mobility_code;
          }
          if (!existedEndstate.isActive) existedEndstate.isActive = item?.dimension === 'Gap';
        });
        next2Mobility.forEach((item) => {
          const existedEndstate = dataRender.find((endstate) => endstate?.content == item?.content);
          if (!existedEndstate) {
            dataRender.push({ ...item, isActive: item?.dimension === 'Strength' });
          }

          existedEndstate.isDot = item?.dimension === 'Strength';
          if (!existedEndstate.isDot) {
            existedEndstate.mobility_code = existedEndstate?.mobility_code
              ? existedEndstate.mobility_code + `,${item?.mobility_code}`
              : item?.mobility_code;
          }
          if (!existedEndstate.isActive) existedEndstate.isActive = item?.dimension === 'Gap';
        });

        dataRender.forEach((ele) => {
          if (ele.isActive && ele.mobility_code) {
            const totalNextArray = [
              ...data.next_mobility.selection.map((ele) => ({
                ...ele,
                typeMobility: 'next_mobility',
              })),
              ...data.next_2_mobility.selection.map((ele) => ({
                ...ele,
                typeMobility: 'next_2_mobility',
              })),
            ];
            const totalNextArrayPriority = totalNextArray
              .filter((i) => ele.mobility_code.toString().includes(i.position_code?.toString()))
              .map((i) => i?.priority);
            const isPiorityMatching = isEmpty(totalNextArrayPriority)
              ? null
              : Math.min(...totalNextArrayPriority);
            const isPositionMobilityMatching = totalNextArray.find(
              (ele) => ele.priority === isPiorityMatching
            );
            const isMobilityTypeMatching = isPositionMobilityMatching?.typeMobility;
            const isColorActive =
              isMobilityTypeMatching === 'next_mobility'
                ? '#005CB9'
                : isMobilityTypeMatching === 'next_2_mobility'
                ? '#763F98'
                : '#00a19c';
            ele.isColorActive = isColorActive;
            ele.highlightSelected = {
              typeMobility: isPositionMobilityMatching?.typeMobility,
              position_code: isPositionMobilityMatching?.position_code,
            };
          }
        });

        setDataRender(dataRender);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [
    staffId,
    JSON.stringify(next_mobility_codes),
    JSON.stringify(next_2_mobility_codes),
    JSON.stringify(position_code),
  ]);

  return (
    <div>
      <Card
        style={{
          width: '280px',
          minHeight: '260px',
          color: '#000',
          backgroundColor: '#FFFAEF',
          padding: '10px',
        }}
      >
        {!isEmpty(dataRender) &&
          dataRender.map((item, index) => (
            <div
              className="d-flex justify-content-start align-items-center"
              style={{
                gap: '10px',
                padding: '5px',
                border: '1px solid var(--Light-Gray-200, #EBEBEB)',
                backgroundColor:
                  item?.highlightSelected &&
                  positionSelected &&
                  item?.highlightSelected?.typeMobility === positionSelected?.typeMobility &&
                  item?.highlightSelected?.position_code === positionSelected?.position_code
                    ? '#F2F4F8'
                    : '#FFF',
              }}
              key={`fulfilment-${index}`}
            >
              <div
                className="col-10 d-flex justify-content-start"
                style={{
                  gap: '10px',
                }}
              >
                <CheckboxFulfilment
                  disabled
                  checked={item.isActive}
                  color={
                    item.isActive && item.isColorActive
                      ? item.isColorActive
                      : item.isActive
                      ? '#00a19c'
                      : undefined
                  }
                />

                <Title
                  isActive={item.isActive}
                  color={
                    item.isActive && item.isColorActive
                      ? item.isColorActive
                      : item.isActive
                      ? '#00a19c'
                      : undefined
                  }
                >
                  {item.content}
                </Title>
              </div>
              {item.isDot && <img src={dot_active_fulfilment} alt="dot_active_fulfilment" />}
            </div>
          ))}
        {isEmpty(dataRender) && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: '#FFFAEF',
              minHeight: '260px',
            }}
          >
            <TitleEmptyState>Enter end state to get the talent gap functions list</TitleEmptyState>
          </div>
        )}
      </Card>
    </div>
  );
}
