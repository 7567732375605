import { Dropdown, Menu, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { BasicButton } from '../../assets/common';
import { deleteNote, edit_delete, editNote } from '../../assets/img';
import { BUSINESS_NAME_TALENT_REVIEW, DATA_FROM } from '../../utils/constants';
import styles from './talent-review-modal.module.scss';

function TalentReviewList(props) {
  const {
    data,
    setTalentReviewObj,
    setIsOpenDeleteTalentReview,
    setIsOpenAddTalentReview,
    setIsOpen,
    isHasEditPermission,
  } = props;

  const menu = (data) => {
    return (
      <Menu
        items={[
          {
            icon: <img src={editNote} alt="edit" />,
            label: (
              <div
                className={styles.editButton}
                onKeyDown={() => {}}
                onClick={() => {
                  setTalentReviewObj(data);
                  setIsOpen(false);
                  setIsOpenAddTalentReview(true);
                }}
              >
                Edit
              </div>
            ),
            key: '0',
          },
          {
            icon: <img src={deleteNote} alt="delete" />,
            label: (
              <div
                className={styles.deleteButton}
                onKeyDown={() => {}}
                onClick={() => {
                  setTalentReviewObj(data);
                  setIsOpen(false);
                  setIsOpenDeleteTalentReview(true);
                }}
              >
                Delete
              </div>
            ),
            key: '1',
          },
        ]}
      />
    );
  };

  const listItems = data.map((item, index) => {
    const business = BUSINESS_NAME_TALENT_REVIEW.find(
      (value) => value.name.indexOf(item.business) !== -1
    );
    const styleBackGround = { backgroundColor: `${business?.color || 'gray'}` };
    return (
      <li className={styles.content} key={index}>
        <div className={styles.old}>
          <div className={styles.platform} style={styleBackGround}>
            {business?.symbol}
          </div>
          <div className={styles.right}>
            <p className={styles.date}>{moment(item.reviewDate).format('D MMMM YYYY') || '-'}</p>
            <p>{item.platform}</p>
          </div>
        </div>
        {isHasEditPermission && item.from === DATA_FROM.MANUAL && (
          <div>
            <Dropdown
              overlay={menu(item)}
              trigger={['click']}
              style={{ minWidth: 110 }}
              placement="bottomRight"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Space style={{ cursor: 'pointer' }}>
                <img src={edit_delete} alt="dot" />
              </Space>
            </Dropdown>
          </div>
        )}
      </li>
    );
  });
  return <ul className={styles.menu}>{listItems}</ul>;
}

const TalentReviewModal = ({
  isOpen,
  setIsOpen,
  data,
  setIsOpenAddTalentReview,
  setIsOpenDeleteTalentReview,
  setTalentReviewObj,
  isHasEditPermission,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      dialogClassName={styles.view_note}
      aria-labelledby="example-custom-modal-styling-title"
      data-testid="talent-review-modal"
    >
      <Modal.Header className={styles.modal_header} closeButton>
        <h1>Talent Review</h1>
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <div className="d-flex justify-content-end" style={{ padding: '0 40px' }}>
          {isHasEditPermission && (
            <BasicButton
              mode="teal"
              onKeyDown={() => {}}
              onClick={() => {
                setIsOpen(false);
                setIsOpenAddTalentReview(true);
              }}
            >
              Add Talent Review
            </BasicButton>
          )}
        </div>
        <TalentReviewList
          data={data}
          setTalentReviewObj={setTalentReviewObj}
          setIsOpenDeleteTalentReview={setIsOpenDeleteTalentReview}
          setIsOpen={setIsOpen}
          setIsOpenAddTalentReview={setIsOpenAddTalentReview}
          isHasEditPermission={isHasEditPermission}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TalentReviewModal;
