import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import FormDate from '../../../../../../components/Form/FormDate/FormDate';
import FormDropdown from '../../../../../../components/Form/FormDropdown/FormDropdown';
import FromTextArea from '../../../../../../components/Form/FormTextArea/FromTextArea';
import styles from './about.module.scss';

const FirstCol = (props) => {
  const { data, setData } = props;
  return (
    <>
      <div className={styles.sub_title}>About me</div>
      <FromTextArea
        borderColor="#D3DEE8"
        placeholder="Enter Description"
        rows={6}
        value={data.about_me}
        setFormData={(value) => setData('about', { ...data, about_me: value })}
      />
    </>
  );
};

const SecondCol = (props) => {
  const { data, setData, top_talent_categories, role_levels } = props;
  return (
    <>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Top Talent Category</div>
        <FormDropdown
          borderColor="#D3DEE8"
          placeholder="Select Top Talent Category"
          value={data.top_talent_category}
          setFormData={(value) => setData('about', { ...data, top_talent_category: value })}
          options={top_talent_categories}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.sub_title}>Role Level Equivalent</div>
        <FormDropdown
          borderColor="#D3DEE8"
          placeholder="Select Role Level Equivalent"
          value={data.role_level_equivalent}
          setFormData={(value) => setData('about', { ...data, role_level_equivalent: value })}
          options={role_levels}
        />
      </div>
    </>
  );
};

const ThirdCol = (props) => {
  const { data, setData } = props;
  return (
    <>
      <div>
        <div className={styles.sub_title}>Retirement Date</div>
        <FormDate
          borderColor="#D3DEE8"
          placeholder="Select Retirement Date"
          value={data.retirement_date}
          setFormData={(value) =>
            setData('about', { ...data, retirement_date: moment(value).format() })
          }
          data-testid="retirement-date"
        />
      </div>
    </>
  );
};

const About = (props) => {
  const { data, setData } = props;
  return (
    <div className={styles.margin}>
      <div className={styles.title}>About</div>
      <Row justify="space-between">
        <Col style={{ width: '31%' }}>
          <FirstCol data={data} setData={setData} />
        </Col>
        <Col style={{ width: '31%' }}>
          <SecondCol data={data} setData={setData} {...props} />
        </Col>
        <Col style={{ width: '31%' }}>
          <ThirdCol data={data} setData={setData} />
        </Col>
      </Row>
    </div>
  );
};

export default About;
