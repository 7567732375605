import { size } from 'lodash';
import { adminApi } from '../services/admin';
import userApi from '../services/userApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getUser = createAsyncThunk('user/getUser', async (params, { rejectWithValue }) => {
  try {
    const response = await userApi.getUserById(params);
    return response.data.result;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPermissions = createAsyncThunk(
  'user/getPermissions',
  async (roleId, { rejectWithValue }) => {
    try {
      const response = await adminApi.getPermissionsByUserRole(roleId);
      return response.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialValue = {
  employeeName: '',
  roles: [
    {
      roleName: '',
      roleId: '',
      businessUnit: '',
      division: '',
      opu: '',
    },
  ],
  search: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: initialValue,
    roleActive: initialValue.roles[0],
    search: initialValue.search,
    loading: false,
    errorMessage: '',
    username: '',
    token: '',
    permissions: [],
    userId: 0,
    roles: initialValue.roles[0],
    tokenImage: '',
    createdTokenDate: '',
  },
  reducers: {
    setRoleActive: (state, action) => {
      state.roleActive = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setSearchKey: (state, action) => {
      state.search = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    reset: (state, action) => {
      state.data = initialValue;
      state.roleActive = initialValue.roles[0];
      state.search = initialValue.search;
      state.loading = false;
      state.errorMessage = '';
      state.username = '';
      state.token = '';
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setTokenImage: (state, action) => {
      state.tokenImage = action.payload;
      state.createdTokenDate = new Date();
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      if (size(action.payload.roles > 0)) {
        const role = action.payload.roles.filter((e) => e.roleId === state.roleActive.roleId);
        state.roleActive = role && role.length > 0 ? role[0] : action.payload.roles[0];
      }
      state.loading = false;
    },
    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.permissions = action.payload;
      state.loading = false;
    },
    [getPermissions.pending]: (state, action) => {
      state.loading = true;
    },
    [getPermissions.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer, actions } = userSlice;
export const {
  setRoleActive,
  setSearchKey,
  setUsername,
  setToken,
  reset,
  setRoles,
  setData,
  setUserId,
  setTokenImage,
} = actions;
export default reducer;
