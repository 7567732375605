import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FilterMultiple } from '../../../../../../components';
import {
  addFilterListByUser,
  addFilterRemark,
  addOneFilterRemark,
  deleteRemark,
  editRemark,
  saveRemarks,
} from '../../../../../Meeting/redux/remarksSlice';
import { remarksRemainingSelector, remarksSelector } from '../../../../../Meeting/selector';
import { TR_REMARK_CATEGORY } from '../../../../constants';
import styles from './remark-table-dialog.module.scss';
import RemarkTableBody from './RemarkTableBody';

const RemarkTableDialog = (props) => {
  const { isRemark, setIsRemark, isViewModeAgenda, agendaDetail, handleShowMessage, titleModal } =
    props;
  const [listSelectName] = useState([]);
  const [listSelectCategory, setListSelectCategory] = useState([]);
  const dispatch = useDispatch();
  const remarks = useSelector(remarksRemainingSelector);
  const { loading } = useSelector(remarksSelector);
  const [talentRemark, setTalentRemark] = useState([]);
  const { agendaId } = useParams();
  const [idEdit, setIdEdit] = useState(null);

  useEffect(() => {
    const newData = remarks.filter((item) => item.remarksCategory);
    setTalentRemark(newData);
  }, [remarks]);

  useEffect(() => {
    if (!isRemark) return;

    const filterList = {
      listSelectName,
      listSelectCategory,
    };
    dispatch(addFilterListByUser({ filterList }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemark, listSelectName, listSelectCategory]);

  const onDeleteItem = (remarkId) => {
    //delete to push into remark list for delete
    dispatch(
      saveRemarks({
        agendaId: agendaDetail?.agenda_id || agendaId,
        remarkId: remarkId,
        type: 'delete',
      })
    );

    //delete to display
    dispatch(
      deleteRemark({
        remarkId,
      })
    );
    dispatch(addFilterRemark());
    setIsRemark(false);
    handleShowMessage('success', 'Remark deleted successfully from  Development Area');
  };

  const onSaveItem = (data) => {
    //edit to push into remark list for edit
    dispatch(
      saveRemarks({
        agendaId: agendaDetail?.agenda_id || agendaId,
        remarkId: data.remarkId,
        body: { category: data.category, remarks: data.remarks },
        type: 'edit',
      })
    );

    //edit to display
    dispatch(
      editRemark({
        remarkId: data.remarkId,
        category: data.category,
        remarks: data.remarks,
      })
    );
    handleShowMessage('success', 'Remark updated successfully.');
    dispatch(addOneFilterRemark());
  };

  return (
    <div>
      <Modal
        show={isRemark}
        onHide={() => setIsRemark(false)}
        dialogClassName={styles.remark}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
        centered
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div className={styles.title} data-testid="modal-remark-title">
            {titleModal || 'All Remarks'}
          </div>
        </Modal.Header>

        <div className={styles.table}>
          <div className={styles.table_header}>
            <div style={{ width: '21%' }}>
              <div className="d-flex align-items-baseline" style={{ gap: '9px' }}>
                Category
                <FilterMultiple
                  listSelectType={listSelectCategory}
                  setListSelectType={setListSelectCategory}
                  filtersOption={TR_REMARK_CATEGORY}
                />
              </div>
            </div>

            <div style={{ width: '72%' }}>Remark</div>
          </div>

          <Modal.Body className={styles.modal_body}>
            <div data-testid="render-remark">
              {!loading &&
                talentRemark?.map((remark, idx) => (
                  <RemarkTableBody
                    item={remark}
                    key={remark.remarkId}
                    index={idx}
                    length={remarks.length}
                    onDeleteItem={onDeleteItem}
                    onSaveItem={onSaveItem}
                    isViewModeAgenda={isViewModeAgenda}
                    setIdEdit={setIdEdit}
                    idEdit={idEdit}
                  />
                ))}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default memo(RemarkTableDialog);
