import styled from 'styled-components';

import { font } from '../../../../assets/common';

export const FileUploadContainer = styled.section`
  position: relative;
  border: 2px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const InputLabel = styled.label`
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
`;

export const DragDropText = styled.p`
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #222222;
`;

export const DragDropTextLimit = styled.p`
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9f9eae;
  margin-bottom: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #9f9eae;
  }

  strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    display: inline-block;
    margin: 10px auto;
  }
`;

export const UploadFileBtn = styled.button`
  width: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #00a19c;
  border-radius: 4px;
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #00a19c;

  &:after {
    content: '';
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 5.75px solid #26afab;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 0;
    margin-left: 10px;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`;

export const FilePreviewContainer = styled.article`
  position: relative;

  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const FileMetaData = styled.div`
  display: ${(props) => (props.isImageFile ? 'none' : 'flex')};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const RemoveFileIcon = styled.i`
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  width: 20%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;

  &:hover {
    opacity: 0.55;

    ${FileMetaData} {
      display: flex;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
  }
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;

export const UploadIcon = styled.img`
  width: 80px;
  height: 55px;
`;

export const DocumentIcon = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
`;

export const TrashIcon = styled.img`
  width: 16px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 20px;
`;

export const FileUploadTitle = styled.h3`
  font-family: ${font.inter};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: #3f3c4c;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .progress-time {
    font-weight: 400;
    font-size: 12px;
    color: #a1a1a1;
  }

  strong {
    color: #3f3c4c;
  }
`;

export const UploadFileProgress = styled.div`
  background: grey;
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 8px;

  span {
    background: #00a19c;
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 20px;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 12px;
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-left: 35px;
  padding-right: 24px;
`;
