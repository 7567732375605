import { v4 as uuidv4 } from 'uuid';
const { createSlice } = require('@reduxjs/toolkit');

const alertMessageSlice = createSlice({
  name: 'alertMessage',
  initialState: [],
  reducers: {
    pushMessage: (state, action) => {
      const { timeShow, message, type, isOnly, containerId } = action.payload;
      if (isOnly) {
        let isReplaced = false;
        for (let i = 0; i < state.length; i++) {
          if (state[i].containerId === containerId) {
            state[i] = action.payload;
            isReplaced = true;
            break;
          }
        }
        if (isReplaced) return;
      }
      if (timeShow !== 0) {
        state.push({ id: uuidv4(), ...action.payload });
        return;
      }
      const isDuplicate = state.find((item) => item.message === message && item.type === type);
      if (isDuplicate) return;
      state.push({ id: uuidv4(), ...action.payload });
    },
    removeMessage: (state, action) => {
      state.splice(action.payload, 1);
    },
    removeMessageByContainer: (state, action) => {
      return state.filter((message) => message.containerId !== action.payload);
    },
    resetGlobalMessage: (state) => {
      state.length = 0;
    },
  },
});

const { reducer, actions } = alertMessageSlice;
export const { pushMessage, removeMessage, removeMessageByContainer, resetGlobalMessage } = actions;
export default reducer;
