import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { remark_calendar, triangle_edit } from '../../../../../../assets/img';
import { useComponentVisible } from '../../../../../../hooks';
import { formatDate } from '../../../../../../utils/helper';
import { saveRemarks } from '../../../../../Meeting/redux/remarksSlice';
import styles from './add-remark.module.scss';

const Category = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { setCategory, category, error } = props;

  const handleClickInput = (item) => {
    setCategory(item?.category);
    setIsComponentVisible(!isComponentVisible);
  };

  const [options] = useState([
    { id: 1, category: 'Notes' },
    { id: 2, category: 'Development Areas' },
    { id: 3, category: 'Key Strength' },
  ]);

  return (
    <>
      <div className="col" ref={ref}>
        <div className={`${styles.label} d-flex justify-content-start`}>
          <div>Category</div>
          <div style={{ color: '#f85032' }}>*</div>
        </div>
        <input
          onKeyDown={() => {}}
          onClick={() => handleClickInput()}
          readOnly
          value={category}
          className={`${styles.category} ${error && !category ? styles.error : ''}`}
          placeholder="Select Category"
        />
        <img src={triangle_edit} className={styles.arrow} alt="" />

        {error && !category && (
          <span style={{ color: '#f85032', fontSize: '12px' }}>
            Please fill in the required fields
          </span>
        )}

        {isComponentVisible && (
          <div className={styles.dropdown}>
            {options &&
              options.length > 0 &&
              options.map((item) => (
                <div key={item.id} onKeyDown={() => {}} onClick={() => handleClickInput(item)}>
                  {item.category}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

const AddRemark = (props) => {
  const { setIsVisible, showAddRemark, agendaDetail, meetingDetail, handleShowMessage } = props;
  const { ref } = useComponentVisible(false);
  const [category, setCategory] = useState('');
  const [loading] = useState(false);
  const [loadingDetailMeeting] = useState(false);
  const [error, setError] = useState(false);
  const [remark, setRemark] = useState('');

  const { agendaId } = useParams();
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsVisible(false);
  };

  const validate = () => {
    if (!remark || !category) {
      setError(true);
      return true;
    } else {
      return false;
    }
  };

  const handleClickAdd = async () => {
    const isValidate = validate();
    if (isValidate) return;

    const params = {
      staffId: showAddRemark.staff.staff_id,
      agendaId: agendaDetail?.agenda_id || agendaId,
      remarks: remark,
      remarksCategory: category,
      version: 0,
      birthName: showAddRemark.staff.birth_name,
      meetingDate: new Date(),
      remarkId: uuidv4(),
      type: 'create',
      meetingName: agendaDetail?.meeting_name,
    };
    dispatch(saveRemarks(params));
    handleShowMessage('success', 'Talent Remarks added Successfully');
    setIsVisible(false);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => handleCancel()}
        dialogClassName={styles.modal}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div>Add Remark</div>
        </Modal.Header>

        {loadingDetailMeeting ? (
          <div className="d-flex justify-content-center align-items-center w-100 mb-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Modal.Body className={styles.table}>
            <div className={styles.row}>
              <div className="col-6">
                <div className={styles.label}>Name</div>
                <input
                  readOnly
                  value={showAddRemark.staff.birth_name}
                  className={styles.dateInput}
                />
              </div>
              <Category setCategory={setCategory} category={category} error={error} />
            </div>

            <div className={styles.row}>
              <div className="col-6">
                <div className={styles.label}>Meeting Name</div>
                <input readOnly value={agendaDetail?.meeting_name} className={styles.dateInput} />
              </div>
              <div className="col" ref={ref}>
                <div className={`${styles.label} ${styles.date}`}>Date</div>
                <input
                  value={formatDate(meetingDetail.date)}
                  readOnly
                  className={styles.dateInput}
                />
                <div className={styles.calendar}>
                  <img
                    src={remark_calendar}
                    alt="remark_calendar"
                    style={{ pointerEvents: 'all' }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className="col">
                <div className={`${styles.label} d-flex justify-content-start`}>
                  <div>Remark</div>
                  <div style={{ color: '#f85032' }}>*</div>
                </div>
                <textarea
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark}
                  className={error && !remark ? styles.error : ''}
                  placeholder="Enter your remarks here"
                />
                {error && !remark && (
                  <span style={{ color: '#f85032', fontSize: '12px' }}>
                    Please fill in the required fields
                  </span>
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className="col d-flex justify-content-end align-items-center">
                <div className={styles.add} onKeyDown={() => {}} onClick={() => handleClickAdd()}>
                  {loading && (
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                  )}
                  <div>Add Remark</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default AddRemark;
