import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { ModelTC, SearchDataTableNotFound } from '../../../../components';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import { checkDeleteResetPage } from '../../../../utils/helper';
import { TOP_TALENT_MESSAGE, TOP_TALENT_TABLE_LIMIT } from '../constant';
import TopTalentStatusMenuBar from './TopTalentStatusMenuBar/TopTalentStatusMenuBar';
import TopTalentStatusTable from './TopTalentStatusTable/TopTalentStatusTable';

const INIT_SEARCH_PARAMS = {
  staff_id: '',
  limit: TOP_TALENT_TABLE_LIMIT,
  page: 1,
  from_date: null,
  to_date: null,
  assessment_type: [],
  top_talent_status: [],
};

export default function TopTalentStatus(props) {
  const dispatch = useDispatch();
  const userInfos = useSelector((state) => state.user);
  const roleActive = useSelector((state) => state.user.roleActive);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [data, setData] = useState({ searched_data: [], total: 0 });
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [deleteParams, setDeleteParams] = useState({
    selectedRecords: [],
    deleteRecord: null,
  });

  const handleDeleteSuccess = (arrDeleted) => {
    const { searched_data, total } = data;
    const { page } = searchParams;

    checkDeleteResetPage(arrDeleted, searched_data, total, page) ? setSearchParams(INIT_SEARCH_PARAMS) : fetchData();

    setDeleteParams({
      selectedRecords: deleteParams.selectedRecords.filter((item) => !arrDeleted.includes(item.top_talent_status_id)),
      deleteRecord: null,
    });
    dispatch(
      pushMessage({
        type: MESSAGE_TYPES.SUCCESS,
        message: TOP_TALENT_MESSAGE.DELETE_SUCCESS,
      })
    );
  };

  const handleDeleteRecord = async () => {
    const { selectedRecords, deleteRecord } = deleteParams;
    if (isEmpty(selectedRecords) && !deleteRecord) return;

    setDeleting(true);
    const ids = deleteRecord ? [deleteRecord?.top_talent_status_id] : selectedRecords.map((item) => item.top_talent_status_id);
    const staffIds = deleteRecord ? [deleteRecord?.staff_id] : selectedRecords.map((item) => item.staff_id);

    try {
      const res = await adminApi.deleteTopTalentStatus(ids, staffIds, userInfos, roleActive);
      if (res.status === 200) {
        handleDeleteSuccess(ids);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await adminApi.searchTopTalentStatus(searchParams, roleActive);
      if (res.status === 200) {
        setData({ searched_data: res.data.result, total: res.data.result[0]?.total || 0 });
      }
    } catch (error) {
      setData({ searched_data: [], total: 0 });
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: error.response.data.errors,
        })
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={loading} size="large" wrapperClassName={'customLoading'}>
      <TopTalentStatusMenuBar
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        deleteParams={deleteParams}
        setShowDeleteConfirm={setShowDeleteConfirm}
      />

      {!isEmpty(data.searched_data) ? (
        <>
          <TopTalentStatusTable
            data={data}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setDeleteParams={setDeleteParams}
            setShowDeleteConfirm={setShowDeleteConfirm}
          />
          <ModelTC
            info={{
              type: 'deleteTopTalentStatus',
              visible: showDeleteConfirm,
              setVisible: setShowDeleteConfirm,
              handleSubmit: handleDeleteRecord,
              loading: deleting,
              onClose: () => {
                setShowDeleteConfirm(false);
              },
            }}
          />
        </>
      ) : (
        <SearchDataTableNotFound content={TOP_TALENT_MESSAGE.SEARCH_NOT_FOUND} notFoundIcon={search_data_not_found} />
      )}
    </Spin>
  );
}
