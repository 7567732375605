import { Col, Row } from 'antd';
import React from 'react';
import { BasicButton } from '../../../../../assets/common';
import styles from './menu-bar-unsearchable.module.scss';

export default function MenuBarUnsearchable(props) {
  const { setKeyword, setIsShow, setSearchInput, setIsSearch, setSearchInputAdd, searchInput } =
    props;

  const handleAddNew = () => {
    setIsShow(true);
    setSearchInputAdd('');
  };

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <input
                data-testid="search-input"
                placeholder="Search Position Name or Position ID"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton
                mode="teal"
                onClick={() => {
                  setKeyword(searchInput);
                  setIsSearch(true);
                }}
              >
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <BasicButton mode="teal" onClick={handleAddNew}>
                Add Position
              </BasicButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
