import { Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { managing_critical_position } from '../../../../assets/img';
import { adminApi } from '../../../../services/admin';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import DataTable from './DataTable/DataTable';
import MenuBarSpAdmin from './MenuBar/MenuBarSpAdmin';
import Pill from './Pill/Pill';
import styles from './managing-critical-position.module.scss';

async function getAllPosition({ keyword, position_labels, businesses, _limit, _page, roleActive }) {
  const result = await adminApi.searchCriticalPositions({
    keyword,
    position_labels,
    businesses,
    _limit,
    _page,
    roleActive,
  });
  return result;
}
export default function ManagingCriticalPosition(props) {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [positionLabelSelecteds, setPositionLabelSelecteds] = useState([]);
  const [businessSelecteds, setBusinessSelecteds] = useState([]);
  const [dataTable, setDataTable] = useState({
    data: [],
    total: 0,
  });
  const [filterSearch, setFilterSearch] = useState({
    keyword: '',
    _page: 1,
    _limit: 15,
  });

  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [deleteOneItem, setDeleteOneItem] = useState({});
  const [updateStatusVisibleDeleteOne, setUpdateStatusVisibleDeleteOne] = useState(false);
  const [updateStatusVisibleDeleteMultiple, setUpdateStatusVisibleDeleteMultiple] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [fistTimeRender, setFirstTimeRender] = useState(false);

  useEffect(() => {
    if (!fistTimeRender) return;
    const body = {
      ...filterSearch,
      position_labels: positionLabelSelecteds
        .filter((item) => item.value !== 'all')
        .map((item) => item.value),
      businesses: businessSelecteds
        .filter((item) => item.value !== 'all')
        .map((item) => item.value),
    };
    setLoadingTable(true);
    getAllPosition({ ...body, roleActive })
      .then((res) => {
        const data = (res?.data?.result?.data || []).map((item) => {
          return { ...item, key: item.position_code };
        });

        setDataTable({
          ...dataTable,
          data: data,
          total: res?.data?.result?.total || 0,
        });
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch, businessSelecteds.length, positionLabelSelecteds.length]);

  useEffect(() => {
    setFirstTimeRender(true);
  }, []);

  const deleteCriticalPosition = async (ids) => {
    setLoading(true);
    try {
      ids = ids.map((item) => !isEmpty(item) && item);
      const res = await adminApi.deleteCriticalPosition(ids, roleActive);
      setUpdateStatusVisibleDeleteOne(false);
      setUpdateStatusVisibleDeleteMultiple(false);
      const warningMessage = get(res, 'data.result.positionListInUse');
      if (!isEmpty(warningMessage)) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.WARNING,
            message: 'Key Position is in use.',
            description: `Please ensure this key position is not relate with other module to proceed with the
            key position deletion`,
          })
        );
      }
      const successMessage = get(res, 'data.result.deletedPositionlist');
      if (!isEmpty(successMessage)) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${
              !isEmpty(successMessage) &&
              successMessage.map((item) => `${item.position_code} ${item.position_name}`).join(',')
            } successfully deleted`,
          })
        );
        const body = {
          ...filterSearch,
          position_labels: showFilter
            ? positionLabelSelecteds
                .filter((item) => item.value !== 'all')
                .map((item) => item.value)
            : [],
          businesses: showFilter
            ? businessSelecteds.filter((item) => item.value !== 'all').map((item) => item.value)
            : [],
        };
        const getListWhenDeleteSuccess = await getAllPosition({ ...body, roleActive });
        const data = get(getListWhenDeleteSuccess, 'data.result.data') || [];
        setDataTable({
          ...dataTable,
          data: data.map((item) => {
            return { ...item, key: item.position_code };
          }),
          total: get(getListWhenDeleteSuccess, 'data.result.total') || 0,
        });
      }
      setDeleteOneItem({});
      setDeleteSelecteds([]);
      setLoading(false);
    } catch (error) {
      setUpdateStatusVisibleDeleteOne(false);
      setUpdateStatusVisibleDeleteMultiple(false);
      setDeleteOneItem({});
      setDeleteSelecteds([]);
      setLoading(false);
      const err = get(error, 'response.data');
      console.log(err);
      if (err) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: err?.errors,
          })
        );
      }
    }
  };
  return (
    <div className={styles.container}>
      <Spin spinning={loadingTable} size="large">
        <MenuBarSpAdmin
          positionLabelSelecteds={positionLabelSelecteds}
          setPositionLabelSelecteds={setPositionLabelSelecteds}
          businessSelecteds={businessSelecteds}
          setBusinessSelecteds={setBusinessSelecteds}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          filterSearch={filterSearch}
          setFilterSearch={setFilterSearch}
          deleteSelecteds={deleteSelecteds}
          setDeleteSelecteds={setDeleteSelecteds}
          updateStatusVisible={updateStatusVisibleDeleteMultiple}
          setUpdateStatusVisible={setUpdateStatusVisibleDeleteMultiple}
          loading={loading}
          deleteCriticalPosition={deleteCriticalPosition}
        />
        <Pill
          showFilter={showFilter}
          positionLabelSelecteds={positionLabelSelecteds}
          setPositionLabelSelecteds={setPositionLabelSelecteds}
          businessSelecteds={businessSelecteds}
          setBusinessSelecteds={setBusinessSelecteds}
        />
        {!isEmpty(dataTable?.data) ? (
          <DataTable
            data={dataTable.data}
            total={dataTable.total}
            filterSearch={filterSearch}
            setFilterSearch={setFilterSearch}
            deleteSelecteds={deleteSelecteds}
            setDeleteSelecteds={setDeleteSelecteds}
            updateStatusVisible={updateStatusVisibleDeleteOne}
            setUpdateStatusVisible={setUpdateStatusVisibleDeleteOne}
            deleteCriticalPosition={deleteCriticalPosition}
            loading={loading}
            deleteOneItem={deleteOneItem}
            setDeleteOneItem={setDeleteOneItem}
          />
        ) : (
          <div className={styles.not_found}>
            <img src={managing_critical_position} alt="not_found" />
          </div>
        )}
      </Spin>
    </div>
  );
}
