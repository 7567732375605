import React from 'react';
import Modal from 'react-bootstrap/Modal';
import SpReadinessNew from '../SpReadinessNew/SpReadinessNew';
import styles from './sucession-view-popup.module.scss';

const SucessionViewPopup = (props) => {
  const { isShowSearchPopup, setIsShowSearchPopup, data, spReadiness, isPrinting } = props;

  return (
    <div>
      <Modal
        show={isShowSearchPopup}
        onHide={() => setIsShowSearchPopup(false)}
        dialogClassName={styles.talent_search_form}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className={styles.talent_search_content}>
          <Modal.Header className={styles.modal_header} closeButton>
            <h1>Succession Planning</h1>
          </Modal.Header>

          <Modal.Body className={styles.modal_body}>
            {spReadiness.map((item) => (
              <div className={item.className} key={item.title}>
                <SpReadinessNew
                  title={item.title}
                  backgroundcolor={item.backgroundcolor}
                  isExpand={false}
                  isExistExpand={false}
                  data={data?.succession_planning[item.code]}
                  readOnly={true}
                  isPrinting={isPrinting}
                  viewPopupSuccession={isShowSearchPopup}
                />
              </div>
            ))}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default SucessionViewPopup;
