import { Table } from 'antd';
import React from 'react';

import * as SC from './TableStyled';

const NoteDataTable = ({ data }) => {
  const columns = [
    {
      title: 'Notes',
      dataIndex: 'remarksCategory',
      key: 'remarksCategory',
      width: '186px',
      render: (_, record) => {
        return <span className={'noteTitle'}>{'Notes'}</span>;
      },
      align: 'center',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      width: `100% - 186px`,
      render: (_, record) => {
        return record.remarks ? (
          <span className={'normalContent'}>{record.remarks}</span>
        ) : (
          <span></span>
        );
      },
    },
  ];

  return (
    <SC.NoteTable>
      <Table
        dataSource={data}
        columns={columns}
        className={'customNoteTable'}
        pagination={false}
        showHeader={false}
      />
    </SC.NoteTable>
  );
};

export default NoteDataTable;
