import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { AiAlert, AiButton, AiSpin, WrapperBtn } from '../../../../../assets/common';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import { COPY_SUCCESS_ADMIN } from '../../../../MobilitySimulation/constants';
import styles from './search-position-copy-dialog.module.scss';
import SearchPositionBoxCopy from './SearchPositionBoxCopy/SearchPositionBoxCopy';

const SearchPositionToCopy = (props) => {
  const { setIsVisible, searchPositionToCopyDialog, dataCopyBtn, fetchDatas } = props;
  const [select] = useState('');
  const dispatch = useDispatch();
  const setIsShowReview = useState(false)[1];
  const [searchList, setSearchList] = useState([]);
  const { loading } = useSelector((state) => state.spTemplate);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  const roleActive = useSelector((state) => state.user.roleActive);

  const fetchData = useCallback(async () => {
    try {
      const res = await adminApi.getCopyApprovedSP(searchTerm.value);
      if (res.status === 200) {
        setSearchList(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  }, [searchTerm]);

  const handleCancel = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (select) {
      setIsShowReview(true);
    } else {
      setIsShowReview(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  const handleCopyPosition = async () => {
    try {
      const approvalDateFormat = moment(dataCopyBtn?.approved_date).format('DD/MM/YYYY');
      const query = {
        position_code: searchTerm?.value?.position_code,
        source_position_code: dataCopyBtn?.position_code,
        approval_date: approvalDateFormat,
      };

      const res = await adminApi.copySPToPosition(query, roleActive);
      if (res.status === 200) {
        fetchDatas();
        setIsVisible(false);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${COPY_SUCCESS_ADMIN.SUCCESS}${dataCopyBtn?.plan_name}`,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleCancel}
        dialogClassName={styles.modal}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div>Search Position to Copy</div>
        </Modal.Header>
        <Modal.Body className={styles.table}>
          {loading.addPosition && (
            <AiSpin tip="Loading...">
              <AiAlert />
            </AiSpin>
          )}
          {!loading.addPosition && (
            <>
              <div className={styles.form}>
                <div>Position</div>

                <div style={{ display: 'flex' }}>
                  <div style={{ width: '395px' }}>
                    <SearchPositionBoxCopy
                      fetchData={fetchData}
                      selectedSp={select}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      searchList={searchList}
                      setSearchList={setSearchList}
                      searchPositionToCopyDialog={searchPositionToCopyDialog}
                      dataCopyBtn={dataCopyBtn}
                    />
                  </div>
                  <div style={{ marginTop: '16px' }}>
                    <WrapperBtn isLoading={loading.addPosition}>
                      <AiButton disabled={loading.addPosition} onClick={handleCopyPosition}>
                        <span data-testid="add-pos-btn">Copy</span>
                      </AiButton>
                    </WrapperBtn>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchPositionToCopy;
