import { Col, Row, Space, Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  audit_log_search_not_found,
  circle_checked,
  dashboard_breadcrumb,
} from '../../../assets/img';
import { BreadCrumb } from '../../../components';
import AlertNotification from '../../../components/AlertMessage/AlertNotification';
import { auditLogApi } from '../../../services/auditLog';
import { AUDIT_LOG_TABS, DATE_TIME_FORMAT, USER_ACTIVITY_TYPE } from '../../../utils/constants';
import { convertAuditLogData, convertUserActivitytLogData } from '../../../utils/helper';
import { AiRangePicker } from '../components/NewSuccessionPlanningForm/Styled';
import SearchBoxCustom from '../components/SearchBoxCustom/SearchBoxCustom';
import styles from './audit-logs.module.scss';
import AiPipelineRun from './components/AiPipelineRun/AiPipelineRun';
import AuditLogExport from './components/AuditLogExport/AuditLogExport';
import UserActivityLogExport from './components/AuditLogExport/UserActivityLogExport';
import AuditLogFilter from './components/AuditLogFilter/AuditLogFilter';
import mockFilterData from './components/AuditLogFilter/data.json';
import DataTable from './components/DataTable/DataTable';
import DataTableUserActivity from './components/DataTable/DataTableUserActivity';
import MenuBar from './components/MenuBar/MenuBar';

const breadCrumbList = [
  { name: 'Dashboard', icon: dashboard_breadcrumb, url: '/dashboard' },
  { name: 'Admin' },
  { name: 'Audit Log' },
];

export default function AuditLogs() {
  // Using state
  const roleActive = useSelector((state) => state.user.roleActive);
  const [mode] = useState(true);
  const [data, setData] = useState([]);
  const [dataUserActivity, setDataUserActivity] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalUserActivityData, setTotalUserActivityData] = useState(0);
  const [filterOptions, setFilterOptions] = useState(mockFilterData);
  const [userLogOptions] = useState(USER_ACTIVITY_TYPE);
  const [loading, setLoading] = useState(false);
  const [loadingFilter] = useState(false);
  const setShowExporting = useState(false)[1];
  const [filterFinderData, setFilterFinderData] = useState({});
  const [showSearchField, setShowSearchField] = useState(false);
  const [actionBy, setActionBy] = useState('');
  const [isOpenDateTimepicker, setIsOpenDateTimepicker] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 15;
  const [message, setMessage] = useState({
    isOpen: false,
    isError: false,
    content: '',
  });
  const [dateRange, setDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  const [keySearch, setKeySearch] = useState('');

  // Use state
  const [select] = useState('');
  const [auditTab, setAuditTab] = useState(AUDIT_LOG_TABS[0]);
  const [searchList, setSearchList] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    type: '',
    value: '',
    showTerm: '',
  });

  const onClickDateTimepicker = () => {
    setIsOpenDateTimepicker(true);
  };

  const onChangeDateAt = (date, dateString) => {
    setDateRange((prevState) => {
      return {
        ...prevState,
        fromDate: dateString[0],
        toDate: dateString[1],
      };
    });
  };

  const onSelectDateRange = () => {
    setIsOpenDateTimepicker(false);
    if (isEmpty(dateRange.fromDate)) {
      setDateRange({
        fromDate: moment(new Date()).format(DATE_TIME_FORMAT.DATE_SPACE),
        toDate: moment(new Date()).format(DATE_TIME_FORMAT.DATE_SPACE),
      });
    }
  };

  useEffect(() => {
    const fetchAllFilter = async () => {
      try {
        setLoading(true);
        const res = await auditLogApi.getAuditLogFilterOptions(roleActive?.roleId);
        if (res.status === 200) {
          setFilterOptions({
            sp_management_filter: res.data.result,
            action_filter: mockFilterData.action_filter,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchAllFilter();

    return () => {
      setFilterOptions();
    };
  }, [roleActive]);

  useEffect(() => {
    if (
      (!isEmpty(filterFinderData) && auditTab === AUDIT_LOG_TABS[0]) ||
      auditTab === AUDIT_LOG_TABS[1]
    ) {
      setShowSearchField(true);
    } else {
      setShowSearchField(false);
    }
  }, [filterFinderData, auditTab]);

  useEffect(() => {
    if (
      (!isEmpty(searchTerm?.value) && searchTerm?.type === 'submit') ||
      (isEmpty(searchTerm?.value) && searchTerm?.type === 'search')
    ) {
      setActionBy(searchTerm?.value.value);
    }
  }, [searchTerm]);

  async function fetchApiAuditLogs() {
    const body = getBodyToGetAuditLog();
    try {
      setLoading(true);
      const res = await auditLogApi.getAuditLogs({ ...body, page, limit }, roleActive?.roleId);
      if (res.status === 200) {
        const newData = convertAuditLogData(res.data.result);
        const totalRecord = !isEmpty(res.data.result) ? res.data.result[0]?.total : 0;
        setData(newData);
        setTotalData(totalRecord);
      }
    } catch (error) {
      console.error(error);
      setData([]);
      setTotalData(0);
    }
    setLoading(false);
  }

  async function fetchUserActivityLogs() {
    const body = getBodyToGetUserActivityLogs();
    try {
      setLoading(true);
      const res = await auditLogApi.getUserActivityLogs(
        { ...body, page, limit },
        roleActive?.roleId
      );
      if (res.status === 200) {
        const newData = convertUserActivitytLogData(res.data.result);
        const totalRecord = !isEmpty(res.data.result) ? res.data.result[0]?.total : 0;
        setDataUserActivity(newData);
        setTotalUserActivityData(totalRecord);
      }
    } catch (error) {
      console.error(error);
      setDataUserActivity([]);
      setTotalUserActivityData(0);
    }
    setLoading(false);
  }

  const getBodyToGetUserActivityLogs = () => {
    const dateFormatToPost = 'YYYY-MM-DD';
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      email: actionBy || '',
      type: getFilterUserType() || [],
      date_first: dateRange.fromDate ? moment(dateRange.fromDate).format(dateFormatToPost) : '',
      date_end: dateRange.toDate ? moment(dateRange.toDate).format(dateFormatToPost) : '',
      keyword: keySearch,
      time_zone,
    };
  };

  const getBodyToGetAuditLog = () => {
    const dateFormatToPost = 'YYYY-MM-DD';
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      action_by: actionBy || '',
      action: getFilterAction() || [],
      action_at_first: dateRange.fromDate
        ? moment(dateRange.fromDate).format(dateFormatToPost)
        : '',
      action_at_end: dateRange.toDate ? moment(dateRange.toDate).format(dateFormatToPost) : '',
      functional_module: filterFinderData?.sp_management_filter,
      keyword: keySearch,
      time_zone,
    };
  };

  // Set Audit Log data
  useEffect(() => {
    if (filterFinderData && auditTab === AUDIT_LOG_TABS[0]) {
      fetchApiAuditLogs();
    }

    if (filterFinderData && auditTab === AUDIT_LOG_TABS[1]) {
      fetchUserActivityLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFinderData, dateRange, actionBy, page, auditTab]);

  const getFilterAction = () => {
    if (isEmpty(filterFinderData?.action_filter)) return;

    let temArr = [];
    filterFinderData.action_filter.forEach((item) => {
      if (item.checked && item.label !== '(Select All)') {
        temArr.push(item.value);
      }
    });
    return temArr;
  };

  const getFilterUserType = () => {
    if (isEmpty(filterFinderData?.user_filter_type)) return;

    let temArr = [];
    filterFinderData.user_filter_type.forEach((item) => {
      if (item.checked && item.label !== '(Select All)') {
        temArr.push(item.value);
      }
    });
    return temArr;
  };

  const handleSearch = () => {
    if (!keySearch && isEmpty(filterFinderData)) return;

    if (!isEmpty(filterFinderData) && auditTab === AUDIT_LOG_TABS[0]) {
      // Handle search audit log
      fetchApiAuditLogs();
    } else {
      // Handle search user activity log
      fetchUserActivityLogs();
    }
  };

  const isActiveSearchField = useMemo(() => {
    return !isEmpty(searchTerm?.value) && searchTerm?.type === 'submit';
  }, [searchTerm]);

  const handleChangeTab = (tab) => {
    setAuditTab(tab);
    setSearchList([]);
    setSearchTerm({});
    setDateRange({
      fromDate: null,
      toDate: null,
    });
    setActionBy('');
    setKeySearch('');
    setTotalData(0);
    setData([]);
    setPage(1);
  };

  return (
    <>
      <div className={styles.max_width} data-testid="talent-finder">
        <div className={styles.control}>
          <BreadCrumb level={3} breadCrumbList={breadCrumbList} />
          <div style={{ marginBottom: '20px' }}>
            {message.isOpen && (
              <AlertNotification
                type={message.isError ? 'error' : 'success'}
                message={message.content}
                show={message?.isOpen}
                setShow={() => setMessage(false)}
                timeShow={5000}
              />
            )}
          </div>
          <h3 className={styles.title}>
            Audit Logs
            <h4 className={styles.subTitle}>List of Audit Logs</h4>
          </h3>
        </div>

        {/* Tab menu */}
        <div className={styles.auditLogTab}>
          {AUDIT_LOG_TABS.map((tab) => (
            <div
              data-testid={tab}
              key={tab}
              onKeyDown={() => {}}
              onClick={() => handleChangeTab(tab)}
              className={auditTab === tab ? styles.active : null}
              style={{ cursor: 'pointer', padding: '10px 15px' }}
            >
              {tab}
            </div>
          ))}
        </div>

        {/* Audit Log filter */}
        {auditTab === AUDIT_LOG_TABS[0] && (
          <Spin spinning={loadingFilter} size="large">
            <Row gutter={[16, 16]} className="mb-3">
              <Col span={11}>
                <AuditLogFilter
                  filterOptions={filterOptions}
                  setFilterFinderData={setFilterFinderData}
                  filterType={AUDIT_LOG_TABS[0]}
                  minWidth={'290'}
                />
              </Col>
              <Col span={6}>
                <div className={styles.fieldWrapper}>
                  {!isEmpty(dateRange.fromDate) && (
                    <img className={styles.checkedIcon} src={circle_checked} alt="" />
                  )}
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <AiRangePicker
                      format={DATE_TIME_FORMAT.DATE_SPACE}
                      value={[
                        dateRange.fromDate
                          ? moment(dateRange.fromDate, DATE_TIME_FORMAT.DATE_SPACE)
                          : null,
                        dateRange.toDate
                          ? moment(dateRange.toDate, DATE_TIME_FORMAT.DATE_SPACE)
                          : null,
                      ]}
                      onChange={onChangeDateAt}
                      placeholder={['Action At']}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      open={isOpenDateTimepicker}
                      renderExtraFooter={() => (
                        <div className={'groupBtnBottom'}>
                          <button
                            className={'cancelBtn'}
                            onKeyDown={() => {}}
                            onClick={() => setIsOpenDateTimepicker(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className={'selectBtn'}
                            onKeyDown={() => {}}
                            onClick={() => onSelectDateRange()}
                          >
                            Select
                          </button>
                        </div>
                      )}
                      onKeyDown={() => {}}
                      onClick={onClickDateTimepicker}
                      className={
                        dateRange.fromDate
                          ? 'customInputDateRangepicker hasValue'
                          : 'customInputDateRangepicker'
                      }
                      popupClassName={'customDateRangepicker'}
                    />
                  </Space>
                </div>
              </Col>
              <Col span={5}>
                <div className={styles.fieldWrapper}>
                  {!isEmpty(searchTerm?.value) && searchTerm?.type === 'submit' && (
                    <img className={styles.checkedIcon} src={circle_checked} alt="" />
                  )}
                  <SearchBoxCustom
                    selectedSp={select}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchList={searchList}
                    setSearchList={setSearchList}
                    fieldName={'action_by'}
                    searchPlaceholder={'Enter Action By'}
                    isActive={isActiveSearchField}
                  />
                </div>
              </Col>
              <Col span={2}>
                <AuditLogExport
                  roleId={roleActive?.roleId}
                  searchData={getBodyToGetAuditLog()}
                  totalData={totalData}
                  setShowExporting={setShowExporting}
                />
              </Col>
            </Row>
          </Spin>
        )}

        {/* User Activity Log filter */}
        {auditTab === AUDIT_LOG_TABS[1] && (
          <Spin spinning={loadingFilter} size="large">
            <Row gutter={[16, 16]} className="mb-3">
              <Col span={6}>
                <AuditLogFilter
                  filterOptions={userLogOptions}
                  setFilterFinderData={setFilterFinderData}
                  filterType={AUDIT_LOG_TABS[1]}
                  minWidth={'315'}
                />
              </Col>
              <Col span={6}>
                <div className={styles.fieldWrapper}>
                  {!isEmpty(searchTerm?.value) && searchTerm?.type === 'submit' && (
                    <img className={styles.checkedIcon} src={circle_checked} alt="" />
                  )}
                  <SearchBoxCustom
                    selectedSp={select}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchList={searchList}
                    setSearchList={setSearchList}
                    fieldName={'user_log_email'}
                    searchPlaceholder={'Email'}
                    isActive={isActiveSearchField}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.fieldWrapper}>
                  {!isEmpty(dateRange.fromDate) && (
                    <img className={styles.checkedIcon} src={circle_checked} alt="" />
                  )}
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <AiRangePicker
                      format={DATE_TIME_FORMAT.DATE_SPACE}
                      value={[
                        dateRange.fromDate
                          ? moment(dateRange.fromDate, DATE_TIME_FORMAT.DATE_SPACE)
                          : null,
                        dateRange.toDate
                          ? moment(dateRange.toDate, DATE_TIME_FORMAT.DATE_SPACE)
                          : null,
                      ]}
                      onChange={onChangeDateAt}
                      placeholder={['Date Range']}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      open={isOpenDateTimepicker}
                      renderExtraFooter={() => (
                        <div className={'groupBtnBottom'}>
                          <button
                            className={'cancelBtn'}
                            onKeyDown={() => {}}
                            onClick={() => setIsOpenDateTimepicker(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className={'selectBtn'}
                            onKeyDown={() => {}}
                            onClick={() => onSelectDateRange()}
                          >
                            Select
                          </button>
                        </div>
                      )}
                      onKeyDown={() => {}}
                      onClick={onClickDateTimepicker}
                      className={
                        dateRange.fromDate
                          ? 'customInputDateRangepicker hasValue'
                          : 'customInputDateRangepicker'
                      }
                      popupClassName={'customDateRangepicker'}
                    />
                  </Space>
                </div>
              </Col>
              <Col span={6}>
                <UserActivityLogExport
                  roleId={roleActive?.roleId}
                  searchData={getBodyToGetUserActivityLogs()}
                  totalData={totalUserActivityData}
                  setShowExporting={setShowExporting}
                />
              </Col>
            </Row>
          </Spin>
        )}

        {showSearchField && (
          <MenuBar
            mode={mode}
            handleSearch={handleSearch}
            setKeySearch={setKeySearch}
            keySearch={keySearch}
            searchType={auditTab}
          />
        )}
        <Spin spinning={loading} size="large">
          {auditTab === AUDIT_LOG_TABS[0] && (
            <>
              {!isEmpty(data) ? (
                <DataTable
                  mode={mode}
                  data={data}
                  page={page}
                  limit={limit}
                  onPageChange={setPage}
                  totalData={totalData}
                />
              ) : (
                <div className={`${styles.not_found} position-relative`}>
                  <img
                    src={audit_log_search_not_found}
                    alt="audit_log_search_not_found"
                    style={loading ? { opacity: '0.3' } : null}
                  />
                </div>
              )}
            </>
          )}
          {auditTab === AUDIT_LOG_TABS[1] && (
            <DataTableUserActivity
              mode={mode}
              data={dataUserActivity}
              page={page}
              limit={limit}
              onPageChange={setPage}
              totalData={totalUserActivityData}
            />
          )}
        </Spin>
        {auditTab === AUDIT_LOG_TABS[2] && <AiPipelineRun />}
      </div>
    </>
  );
}
