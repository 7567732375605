import React, { useMemo } from 'react';
import { Table } from 'antd';

import { avatar, deleteNote } from '../../../../../assets/img';
import { decodeBase64ToImage, paginationConfig } from '../../../../../utils/helper';
import { TableManagement } from '../../../MasterDataManagement/FunctionList/DataTableManaging/styled';
import * as styles from './data-table-managing.module.scss';

export default function DataTableManaging({
  data,
  setDeleteRecord,
  setShowDeleteModal,
  setDeleteSelecteds,
  deleteSelecteds,
  totalData,
  searchParams,
  setSearchParams,
  limit,
}) {
  const columns = [
    {
      title: 'Staff Name',
      dataIndex: 'staff_name',
      width: '30%',
      render: (_, record) => (
        <span className={`${styles.textField}`} style={{ fontWeight: 700, color: '#3F3C4C' }}>
          <img
            className={styles.customImg}
            src={decodeBase64ToImage(record?.image) || avatar}
            alt="avatar"
          />
          {record?.staff_name}
        </span>
      ),
    },
    {
      title: 'Staff ID',
      dataIndex: 'staff_id',
      width: '20%',
      align: ' center',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.staff_id}</span>,
    },
    {
      title: 'Business',
      dataIndex: 'business_unit',
      width: '20%',
      align: ' center',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.business_unit}</span>,
    },
    {
      title: 'Role Level',
      dataIndex: 'role_level',
      width: '20%',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.role_level}</span>,
      align: ' center',
    },
    {
      title: 'Action',
      dataIndex: 'more',
      align: ' center',
      width: '10%',
      render: (_, record) => (
        <span
          className={styles.deleteButton}
          onKeyDown={() => {}}
          onClick={() => {
            setDeleteRecord(record);
            setShowDeleteModal(true);
          }}
        >
          <img className={styles.deleteIcon} src={deleteNote} alt="delete" />
        </span>
      ),
    },
  ];

  const setPage = (page) => {
    setSearchParams((prev) => ({
      ...prev,
      page,
    }));
  };

  const pagination = useMemo(() => paginationConfig(totalData, searchParams.page, setPage, limit), [limit, searchParams.page, setPage, totalData]);

  const rowSelection = {
    onChange: (_selectedRowKeys, selectedRows) => {
      setDeleteSelecteds(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.staff_id,
    }),
    selectedRowKeys: deleteSelecteds.map((item) => item.staff_id),
  };

  return (
    <div style={{ marginTop: 24 }}>
      <TableManagement>
        <Table
          rowKey="staff_id"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          pagination={pagination}
        />
      </TableManagement>
    </div>
  );
}
