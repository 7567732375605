import { Table } from 'antd';
import React, { useState } from 'react';

import { deleteNote } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { TableManagement } from '../../../MasterDataManagement/FunctionList/DataTableManaging/styled';
import * as styles from './data-table-unsearchable.module.scss';

export default function DataTableUnsearchable({
  data,
  setDeleteRecord,
  totalData,
  setPage,
  page,
  limit,
  setIsSearch,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: '5%',
      render: (_, __, index) => (
        <span className={styles.noField}>{(page - 1) * limit + 1 + index}</span>
      ),
    },
    {
      title: 'Position ID',
      dataIndex: 'position_id',
      width: '10%',
      align: ' center',
      render: (_, record) => (
        <span className={`${styles.textField}`} style={{ fontWeight: 700, color: '#3F3C4C' }}>
          {record?.position_id}
        </span>
      ),
    },
    {
      title: 'Position Name',
      dataIndex: 'position_name',
      width: '25%',
      align: ' center',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.position_name}</span>,
    },
    {
      title: 'Business',
      dataIndex: 'business',
      width: '10%',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.business}</span>,
      align: ' center',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: '20%',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.company}</span>,
      align: ' center',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: '20%',
      render: (_, record) => <span className={`${styles.textField}`}>{record?.department}</span>,
      align: ' center',
    },
    {
      title: 'Action',
      dataIndex: 'more',
      align: ' center',
      width: '5%',
      render: (_, record) => (
        <span
          className={styles.deleteButton}
          onKeyDown={() => {}}
          onClick={() => {
            setSelectedRecord(record);
            setShowDeleteModal(true);
          }}
        >
          <img className={styles.deleteIcon} src={deleteNote} alt="delete" />
        </span>
      ),
      key: 'x',
    },
  ];

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNumber) => handlePageChange(pageNumber),
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
      setIsSearch(false);
    }
  };

  return (
    <div style={{ marginTop: 24 }}>
      <TableManagement>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </TableManagement>
      <ModelTC
        info={{
          type: 'deleteUnsearchablePosition',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => setShowDeleteModal(false),
          handleSubmit: () => {
            setDeleteRecord(selectedRecord);
            setShowDeleteModal(false);
          },
        }}
      />
    </div>
  );
}
