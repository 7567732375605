import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { icon_after } from '../../../../../assets/img';
import { STAFF_SG_JG_FILTER_TYPES } from '../../constant';
import styles from './pill-managing.module.scss';

const PillManaging = (props) => {
  const { query, setQuery } = props;

  const isSelectAllSuccessor = useMemo(
    () => query.successor.find((item) => item.value === 'all'),
    [query.successor]
  );

  const isSelectAllTopTalentStatus = useMemo(
    () => query.topTalentStatus.find((item) => item.value === 'all'),
    [query.topTalentStatus]
  );

  const isSelectAllMissingData = useMemo(
    () => query.missingData.find((item) => item.value === 'all'),
    [query.missingData]
  );

  const onRemove = (type, value) => {
    let remain;
    switch (type) {
      case STAFF_SG_JG_FILTER_TYPES.SUCCESSOR:
        remain = query.successor.filter((item) => item.value !== value);
        setQuery((prev) => {
          return {
            ...prev,
            successor: remain,
            page: 1,
          };
        });
        return;
      case STAFF_SG_JG_FILTER_TYPES.TOP_TALENT_STATUS:
        remain = query.topTalentStatus.filter((item) => item.value !== value);
        setQuery((prev) => {
          return {
            ...prev,
            topTalentStatus: remain,
            page: 1,
          };
        });
        return;
      case STAFF_SG_JG_FILTER_TYPES.MISSING_DATA:
        remain = query.missingData.filter((item) => item.value !== value);
        setQuery((prev) => {
          return {
            ...prev,
            missingData: remain,
            page: 1,
          };
        });
        return;
      default:
        return;
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        marginTop:
          query.successor.length + query.topTalentStatus.length + query.missingData.length > 0
            ? 22
            : 0,
      }}
    >
      {isSelectAllTopTalentStatus ? (
        <span className={styles.pillItem}>
          <span>Top Talent Status - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() =>
              setQuery((prev) => {
                return {
                  ...prev,
                  topTalentStatus: [],
                  page: 1,
                };
              })
            }
            alt="remove-all-top-talent-status"
          />
        </span>
      ) : (
        query.topTalentStatus.map((item, idx) => {
          return (
            <span
              data-testid={`top-talent-status-${idx}`}
              key={item.value}
              className={styles.pillItem}
              onKeyDown={() => {}}
              onClick={() => onRemove(STAFF_SG_JG_FILTER_TYPES.TOP_TALENT_STATUS, item.value)}
            >
              <span>{item.label}</span>
              <img src={icon_after} alt="remove" />
            </span>
          );
        })
      )}
      {isSelectAllSuccessor ? (
        <span className={styles.pillItem}>
          <span>Successor - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() =>
              setQuery((prev) => {
                return {
                  ...prev,
                  successor: [],
                  page: 1,
                };
              })
            }
            alt="remove-all-successor"
          />
        </span>
      ) : (
        query.successor.map((item, idx) => {
          const renameLabel =
            item.label === 'Yes'
              ? 'Successor - Yes'
              : item.label === 'No'
              ? 'Successor - No'
              : item.label;
          return (
            <span key={item.value} className={styles.pillItem}>
              <span>{renameLabel}</span>
              <img
                data-testid={`successor-${idx}`}
                src={icon_after}
                onKeyDown={() => {}}
                onClick={() => onRemove(STAFF_SG_JG_FILTER_TYPES.SUCCESSOR, item.value)}
                alt="remove"
              />
            </span>
          );
        })
      )}
      {isSelectAllMissingData ? (
        <span className={styles.pillItem}>
          <span>Missing Data - All</span>
          <img
            src={icon_after}
            onKeyDown={() => {}}
            onClick={() =>
              setQuery((prev) => {
                return {
                  ...prev,
                  missingData: [],
                  page: 1,
                };
              })
            }
            alt="remove-all-missing-data"
          />
        </span>
      ) : (
        query.missingData.map((item, idx) => {
          const renameLabel =
            item.label === 'Yes'
              ? 'Missing Data - Yes'
              : item.label === 'No'
              ? 'Missing Data - No'
              : item.label;
          return (
            <span
              data-testid={`missing-data-${idx}`}
              key={item.value}
              className={styles.pillItem}
              onKeyDown={() => {}}
              onClick={() => onRemove(STAFF_SG_JG_FILTER_TYPES.MISSING_DATA, item.value)}
            >
              <span>{renameLabel}</span>
              <img src={icon_after} alt="remove" />
            </span>
          );
        })
      )}
    </div>
  );
}

PillManaging.propTypes = {
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default PillManaging;
