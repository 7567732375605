import { Col, Row, Table } from 'antd';
import { cloneDeep, isEmpty, size } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { AiButton } from '../../../../../assets/common';
import { meetingApi } from '../../../../../services/tcMeeting';
import { USER_ID } from '../../../../../utils/constants';
import { formatDate, formatTime } from '../../../../../utils/helper';
import { clearNotification, showNotification } from '../../../../../utils/notification';
import { getAgendaIncumbent } from '../../../redux/agendaSlice';
import { Ai2Button, AiInput, AiTable, Search } from './styled';
import styles from './version-history.module.scss';
import { saveAgendaDetail } from '../../../../../store/spTemplateSlice';
const VersionHistory = (props) => {
  const {
    meetingRoles: { is_secretariat_or_facilitator },
    isVisible,
    setIsVisible,
    isPaperFinalized,
  } = props;
  const [notification, setNotification] = useState({ isShow: false });
  const roleActive = useSelector((state) => state.user.roleActive);
  const saveAgenda = useSelector((state) => state.spTemplate.saveAgenda);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const { agendaId } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingD, setLoadingD] = useState(false);
  const [versions, setVersions] = useState([]);
  const [page, setPage] = useState(1);

  const setAsMain = async ({ version }) => {
    const params = {
      agendaId,
      version: Number(version),
      id: USER_ID,
      role: roleActive,
    };
    const res = await meetingApi.setAsMain(params);
    if (res.status === 200) {
      dispatch(getAgendaIncumbent(res.data.result));
      dispatch(saveAgendaDetail(res.data.result));
      setIsVisible(false);
    }
  };

  // deleteVersionHistory
  const handleDeleteVersionHistory = async () => {
    let params = {
      ids: selectedRowKeys.join(','),
    };
    try {
      setLoading(true);
      const reponse = await meetingApi.deleteVersionHistory(USER_ID, roleActive, agendaId, params);
      if (reponse.status === 200) {
        await handleGetVersionHistory();
        showNotification('Deleted successful', 'successful');
        handleGetVersionHistory();
        setSelectedRowKeys([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotification('Delete failed', 'failed');
    }
  };
  const handleCancel = () => {
    setIsVisible(false);
  };

  const { url } = useRouteMatch();
  const defaultColumns = [
    {
      title: 'Version Name',
      dataIndex: 'versionName',
    },
    {
      title: 'Created Time',
      dataIndex: 'createdTime',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, _record, _index) => (
        <>
          <Link target="_blank" to={`${url}/versions/${text}`}>
            <Ai2Button className={styles.version} color="blue">
              View Version
            </Ai2Button>
          </Link>
          {is_secretariat_or_facilitator && !isPaperFinalized && (
            <Ai2Button onClick={() => setAsMain({ version: text })} bgcolor="blue" mgl="10px">
              Set as Main
            </Ai2Button>
          )}
        </>
      ),
    },
  ];
  const [columns, setColumns] = useState(defaultColumns);
  const handleGetVersionHistory = async () => {
    let params = {
      _limit: 1000,
      _page: page,
      keyword: searchTerm,
    };
    try {
      setLoading(true);
      const response = await meetingApi.getVersionHistory(USER_ID, roleActive, agendaId, params);
      const responseData = response.data?.result?.data;
      if (!isEmpty(responseData) && response.status === 200) {
        const arrayConvert = responseData.map((item) => ({
          key: item.version,
          versionName: item.version_name,
          syndicatorList:
            size(item.syndications) > 0
              ? item.syndications.map((item) => item.syndication_title)[0]
              : '-',
          createdTime: formatTime(item.created_at),
          createdDate: formatDate(item.created_at),
          action: item.version,
        }));
        let versionName = [];
        arrayConvert.forEach((item) => {
          versionName.push({ text: item.versionName, value: item.versionName });
        });
        let newColumns = cloneDeep(columns);
        newColumns = newColumns.map((item) => {
          if (item.dataIndex === 'versionName') {
            item.filters = versionName;
            item.onFilter = (value, record) => record.versionName.includes(value);
          }
          if (item.dataIndex === 'action') {
            item.render = (text, _record, _index) => (
              <>
                <Link target="_blank" to={`${url}/versions/${text}`}>
                  <Ai2Button className={styles.version} color="blue">
                    View Version
                  </Ai2Button>
                </Link>
                {is_secretariat_or_facilitator && !isPaperFinalized && (
                  <Ai2Button onClick={() => setAsMain({ version: text })} bgcolor="blue" mgl="10px">
                    Set as Main
                  </Ai2Button>
                )}
              </>
            );
          }
          return item;
        });
        setColumns(newColumns);
        setVersions(arrayConvert);
      } else {
        setVersions([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!roleActive) return;
    handleGetVersionHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive]);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleSearch = async () => {
    setLoadingD(true);
    await handleGetVersionHistory();
    setLoadingD(false);
  };
  function onChange(pagination, _filters, _sorter, _extra) {
    setPage(pagination.current);
  }
  return (
    <>
      <Modal show={isVisible} onHide={() => handleCancel()} dialogClassName={styles.modal}>
        <Modal.Header className={styles.modal_header} closeButton>
          <div>Version History</div>
        </Modal.Header>
        <div className={styles.message}>
          {notification.isShow && (
            <div className={`alert alert-${notification.alert}`} role="alert">
              {notification.message}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setNotification(clearNotification())}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
        </div>
        <Modal.Body className={styles.table}>
          <div>
            <div style={{ marginBottom: '16px' }}>
              <Row align="middle">
                <Col span={8}>
                  <AiInput
                    placeholder="Search Version"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Col>
                <Col span={4}>
                  <Search type="primary" onClick={handleSearch} loading={loadingD}>
                    Search
                  </Search>
                </Col>
                <Col
                  span={12}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  {is_secretariat_or_facilitator && !isPaperFinalized && (
                    <AiButton
                      mode="danger"
                      onClick={handleDeleteVersionHistory}
                      disabled={!hasSelected || loading}
                      loading={loading}
                    >
                      Delete Version History
                    </AiButton>
                  )}
                </Col>
              </Row>
            </div>
            <AiTable data-testid="version-history">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={versions}
                loading={loading || saveAgenda.loading.version}
                onChange={onChange}
                rowClassName="editable-row"
                pagination={false}
              />
            </AiTable>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(VersionHistory);
