import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Tooltip } from 'antd';
import { delete_mobility_plan } from '../../assets/img';

const SortableItem = (props) => {
  const {
    item,
    setPositionSelected,
    positionSelected,
    isView,
    styles,
    color,
    type,
    height,
    handleDelete,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.id,
    data: { ...item, isMobility: true, typeMobility: type },
  });
  const itemStyle = {
    // transform: CSS.Transform.toString(transform),
    // transition,
    cursor: 'grab',
    margin: '5px 0',
    height,
    borderLeftColor: color === '#00A19C' ? '#66B2C4' : color,
    color,
    boxShadow:
      positionSelected?.position_code === item.position_code &&
      type === positionSelected?.typeMobility
        ? '0px 4px 6px 0px rgba(52, 63, 75, 0.10), 0px 2px 4px 0px rgba(52, 63, 75, 0.06)'
        : 'unset',
  };

  return (
    <div
      style={itemStyle}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      // disabled={isReadOnly}
      className={styles.positionName}
      onKeyDown={() => {}}
      onMouseDown={() => {
        if (type && type !== 'end_state') {
          setPositionSelected(
            positionSelected?.position_code === item.position_code &&
              positionSelected?.typeMobility === type
              ? null
              : { position_code: item.position_code, typeMobility: type }
          );
        }
      }}
    >
      <Tooltip
        placement="top"
        color={'#181818'}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        title={
          <>
            {item.position_name}
            <ul>
              {item.department && <li>Department: {item.department}</li>}
              {item.division && <li>Division: {item.division}</li>}
              {item.opu && <li>OPU: {item.opu}</li>}
              {item.business && <li>Business: {item.business}</li>}
            </ul>
          </>
        }
      >
        {item.position_name} <span style={{ fontWeight: 400 }}>({item.position_code})</span>{' '}
        {item.job_grade && <span style={{ fontWeight: 400 }}>({item.job_grade})</span>}
      </Tooltip>
      {!isView && (
        <img
          src={delete_mobility_plan}
          alt="delete"
          onClick={() => handleDelete(item.position_code)}
          onMouseDown={() => {
            handleDelete(item.position_code);
            if (type && type !== 'end_state') {
              setPositionSelected(null);
            }
          }}
          onKeyDown={() => {}}
        />
      )}
    </div>
  );
};

export default SortableItem;
