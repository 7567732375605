import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { utils } from 'xlsx-js-style';

import { ROLES_DASHBOARD, SUCCESSOR_TYPES } from '../../../constants';
import {
  HISTORICAL_COMMON_STYLE,
  historicalExportDeclareWorkSheet,
  historicalExportSetMergeAndColWidth,
  historicalExportStyleAllCells,
  historicalExportUnshiftHeader,
  historicalSetColorsAndWriteFile,
} from './CommonExportHistorical';

const ExportSuccessor = (props) => {
  const { setExportData, historicalData, categoryList } = props;

  const convertDataToExport = useCallback(async () => {
    let tempData = [];
    historicalData.forEach((item, itemIndex) => {
      if (!isEmpty(item.value)) {
        let tempBothItem = item.value.find((item) => item.type === SUCCESSOR_TYPES.BOTH_SUCCESSOR);
        let tempNonItem = item.value.find((item) => item.type === SUCCESSOR_TYPES.NON_SUCCESSOR);
        let tempDataCategory1;
        let tempDataCategory2;
        if (categoryList.length === 1) {
          tempDataCategory1 = item.value.find(
            (item) => item.type === `${categoryList[0].value}_Successor`
          );
          ROLES_DASHBOARD.forEach((role, index) => {
            tempData.push({
              month: index === 0 ? item.month : '',
              role: `${role}: `,
              Both_Successor: `${tempBothItem[role]}`,
              Non_Successor: `${tempNonItem[role]}`,
              [`${categoryList[0].value}_Successor`]: `${tempDataCategory1[role]}`,
            });
          });
        } else if (categoryList.length === 2) {
          tempDataCategory1 = item.value.find(
            (item) => item.type === `${categoryList[0].value}_Successor`
          );
          tempDataCategory2 = item.value.find(
            (item) => item.type === `${categoryList[1].value}_Successor`
          );
          ROLES_DASHBOARD.forEach((role, index) => {
            tempData.push({
              month: index === 0 ? item.month : '',
              role: `${role}: `,
              Both_Successor: `${tempBothItem[role]}`,
              Non_Successor: `${tempNonItem[role]}`,
              [`${categoryList[0].value}_Successor`]: `${tempDataCategory1[role]}`,
              [`${categoryList[1].value}_Successor`]: `${tempDataCategory2[role]}`,
            });
          });
        } else {
          ROLES_DASHBOARD.forEach((role, index) => {
            tempData.push({
              month: index === 0 ? item.month : '',
              role: `${role}: `,
              Both_Successor: `${tempBothItem[role]}`,
              Non_Successor: `${tempNonItem[role]}`,
            });
          });
        }
      }
    });
    return tempData;
  }, [historicalData, categoryList]);

  const exportRetirementForecast = useCallback(async () => {
    const fileName = 'Successor';

    const arr = [];
    const newData = await convertDataToExport();
    newData.forEach((item) => {
      let rows;
      if (categoryList.length === 1) {
        rows = [
          item.month,
          item.role,
          item.Both_Successor,
          item.Non_Successor,
          item[`${categoryList[0].value}_Successor`],
        ].flat();
      } else if (categoryList.length === 2) {
        rows = [
          item.month,
          item.role,
          item.Both_Successor,
          item.Non_Successor,
          item[`${categoryList[0].value}_Successor`],
          item[`${categoryList[0].value}_Successor`],
        ].flat();
      } else {
        rows = [item.month, item.role, item.Both_Successor, item.Non_Successor].flat();
      }
      arr.push(rows);
    });

    const length = categoryList.length + 4;
    const headers1 = new Array(length).fill('');
    headers1[0] = 'Months (End of months)';
    headers1[1] = 'Business';
    const headers2 = new Array(length).fill('');
    headers2[0] = 'Months (End of months)';
    headers2[1] = 'Role';
    headers2[2] = `Both Successor`;
    headers2[3] = 'Non Successor';
    if (categoryList.length === 1) {
      headers2[4] = `${categoryList[0].value} Successor`;
    }
    if (categoryList.length === 2) {
      headers2[4] = `${categoryList[0].value} Successor`;
      headers2[5] = `${categoryList[1].value} Successor`;
    }

    historicalExportUnshiftHeader(arr, [headers2, headers1]);

    if (arr.length > 0) {
      let [ws, wb] = historicalExportDeclareWorkSheet(arr);
      utils.book_append_sheet(wb, ws, 'Successor');

      const shortHandMerges = [
        [0, 0],
        [1, 0],
        [0, 1],
        [0, length - 1],
      ];
      historicalExportSetMergeAndColWidth(ws, shortHandMerges, length, 15);

      historicalExportStyleAllCells(ws);

      const colorCellMapping = {
        '00a9b1': ['A1', 'B1'],
        '7030a0': ['B2', 'C2', 'D2', 'E2'],
      };
      historicalSetColorsAndWriteFile(ws, wb, fileName, colorCellMapping, () => {
        if (categoryList.length === 2) {
          ws['F2'].s = {
            ...ws['F2'].s,
            ...HISTORICAL_COMMON_STYLE,
            fill: { fgColor: { rgb: '7030a0' } },
          };
        }
      });
    }
  }, [convertDataToExport, categoryList]);

  useEffect(() => {
    exportRetirementForecast();
    setExportData(false);
  }, [setExportData, exportRetirementForecast]);
  return <></>;
};

ExportSuccessor.propTypes = {
  setExportData: PropTypes.func,
  historicalData: PropTypes.array,
  categoryList: PropTypes.array,
};

export default ExportSuccessor;
