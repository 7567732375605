import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicButton } from '../../../../../assets/common';
import { talent_finder_export, talent_finder_export_inactive } from '../../../../../assets/img';
import { CustomDropdownTreeSelect, FilterPill } from '../../../../../components';
import { adminApi } from '../../../../../services/admin';
import styles from './menu-bar-external-talents.module.scss';

export default function MenuBarExternalTalents({ searchParams, setSearchParams, deleteParams, setShowDeleteConfirm }) {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [filterOptions, setFilterOptions] = useState({ roles: [], companies: [] });

  const formik = useFormik({
    initialValues: {
      role: [],
      company: [],
    },
  });

  const emptySelected = useMemo(() => isEmpty(deleteParams.selectedRecords), [deleteParams.selectedRecords]);
  const activeClass = useMemo(() => (emptySelected ? styles.inActiveDelBtn : styles.activeDelBtn), [emptySelected]);

  const handleClickAddNew = () => {
    history.push(`/admin/profile-management/add-external-talent`);
  };

  const handleClickSearch = () => {
    setSearchParams((prevState) => ({ ...prevState, keyword: searchInput, page: 1 }));
  };

  const handleClickDelete = () => {
    setShowDeleteConfirm(true);
  };

  const handleFilter = () => {
    if (isFirstRender) setIsFirstRender(false);
  };

  const handleClickFilter = () => {
    if (showFilter) {
      formik.resetForm();
    }
    setShowFilter(!showFilter);
  };

  const fetchFilter = async () => {
    try {
      const res = await adminApi.getExternalTalentFilter();
      if (res.status === 200) {
        setFilterOptions(res.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFilter();
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      const { role, company } = formik.values;
      const roleValue = role.filter((item) => item.value !== 'all').map((item) => item.value);
      const companyValue = company.filter((item) => item.value !== 'all').map((item) => item.value);

      setSearchParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          role: roleValue,
          company: companyValue,
        };
      });
    }
  }, [formik.values, setSearchParams, isFirstRender]);

  useEffect(() => {
    setSearchInput(searchParams.keyword)
  }, [searchParams.keyword]);

  return (
    <div>
      <Row align="middle" justify="space-between">
        <Col span={16}>
          <Row align="middle" gutter={16}>
            <Col span={12}>
              <input
                data-testid="search-input"
                placeholder="Search by Name and Email"
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickSearch}>
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="middle" justify="space-between" gutter={12}>
            <Col>
              <BasicButton
                mode="danger"
                onKeyDown={() => {}}
                onClick={handleClickDelete}
                className={`${styles.button} ${activeClass}`}
                disabled={emptySelected}
              >
                Confirm Delete Talent
              </BasicButton>
            </Col>
            <Col>
              <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickAddNew}>
                Add New
              </BasicButton>
            </Col>
            <Col>
              <div className={`${styles.icons} ${showFilter ? styles.enable : ''}`} onKeyDown={() => {}} onClick={handleClickFilter}>
                <img src={showFilter ? talent_finder_export : talent_finder_export_inactive} alt="Filter" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <>
          <Row gutter={16} className="mt-4">
            <Col span={4}>
              <CustomDropdownTreeSelect
                data={filterOptions.roles.map((role) => ({ value: role, label: role }))}
                placeholder="Role"
                value={formik.values.role}
                setValue={(value) => formik.setFieldValue('role', value)}
                onChange={handleFilter}
              />
            </Col>
            <Col span={4}>
              <CustomDropdownTreeSelect
                data={filterOptions.companies.map((company) => ({
                  value: company,
                  label: company,
                }))}
                placeholder="Company"
                value={formik.values.company}
                setValue={(value) => formik.setFieldValue('company', value)}
                onChange={handleFilter}
              />
            </Col>
          </Row>

          <FilterPill
            onRemove={handleFilter}
            style={{
              marginTop: '20px',
              marginBottom: '6px',
            }}
            data={{
              role: formik.values.role,
              company: formik.values.company,
            }}
            setData={{
              role: (value) => formik.setFieldValue('role', value),
              company: (value) => formik.setFieldValue('company', value),
            }}
          />
        </>
      )}
    </div>
  );
}
