import { Dropdown, Space, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteNote, edit_delete, editNote } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import * as SC from '../../ManagingCriticalPosition/DataTable/styled';
import { updateIsEditSetMatching } from '../../redux/manageCriteriaSetMatchingSlice';
import * as styles from './data-table-managing-criteria-cp.module.scss';

export default function DataTableManagingCriteriaCP({
  data,
  setDeleteRecord,
  setDeleteSelecteds,
  deleteSelecteds,
  handleDeleteRecord,
  totalData,
  setPage,
  page,
  limit,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEditRecord = () => {
    history.push(`/admin/sp-management/edit-criteria/${selectedRecord?.position_code}`);
    dispatch(updateIsEditSetMatching({ set_index: 'all', value: false }));
  };

  const menuItems = [
    {
      icon: <img src={editNote} alt="edit" />,
      label: (
        <div className={styles.editButton} onKeyDown={() => {}} onClick={handleEditRecord}>
          Edit
        </div>
      ),
      key: '0',
    },
    {
      icon: <img src={deleteNote} alt="delete" />,
      label: <div className={styles.deleteButton}>Delete</div>,
      key: '1',
      onClick: () => {
        setShowDeleteModal(true);
      },
    },
  ];

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (_, __, index) => (
        <span className={styles.noField}>{(page - 1) * limit + 1 + index}</span>
      ),
    },
    {
      title: 'Position Code',
      dataIndex: 'position_code',
      width: '25%',
    },
    {
      title: 'Position Name',
      dataIndex: 'position_name',
      width: '40%',
    },
    {
      title: 'Updated Date & Time',
      dataIndex: 'updated_date',
      width: '20%',
      align: ' center',
    },
    {
      title: '',
      dataIndex: 'more',
      key: 'x',
    },
  ];

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== page && setPage(pageNum),
    showTotal: (total, range) => (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    ),
  };

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setDeleteSelecteds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    selectedRowKeys: deleteSelecteds,
  };

  return (
    <div style={{ marginTop: 24 }}>
      <SC.TableManagement>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data.map((item, index) => ({
            key: item.number,
            position_code: <span className={styles.positionCode}>{item.position_code}</span>,
            position_name: <span className={styles.positionName}>{item.position_name}</span>,
            updated_date: (
              <span className={styles.updated_date}>
                {moment(item.updated_date).format('DD/MM/YYYY hh:mmA')}
              </span>
            ),
            more: (
              <Dropdown
                menu={{ items: menuItems }}
                trigger={['click']}
                style={{ minWidth: 110 }}
                placement="bottomRight"
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Space style={{ cursor: 'pointer' }}>
                  <img
                    src={edit_delete}
                    alt="dot"
                    onKeyDown={() => {}}
                    onClick={() => setSelectedRecord(item)}
                  />
                </Space>
              </Dropdown>
            ),
          }))}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableManagement>
      <ModelTC
        info={{
          type: 'deleteManagingCriteriaCP',
          visible: showDeleteModal,
          setVisible: setShowDeleteModal,
          onClose: () => setShowDeleteModal(false),
          handleSubmit: () => {
            setDeleteRecord(selectedRecord);
            setShowDeleteModal(false);
          },
        }}
      />
    </div>
  );
}
