import { Spin, Tooltip as TooltipAntd } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { info_circle } from '../../../../assets/img';
import { returnBgdColorByScoreVal } from '../../../../utils/helper';
import {
  DerailmentRisksTable,
  FieldItemWrap,
  FieldWrap,
  NormalText,
  StatusBgd,
  TooltipDesc,
} from '../ExternalAssessments/ExternalAssessmentStyled';
import styles from '../GeneralExternalAssessments/general-external-assessments.module.scss';

const DerailmentRisksAssessments = (props) => {
  const { assessmentData, loading } = props;

  const convertData = useMemo(() => {
    return !isEmpty(assessmentData?.data?.categories)
      ? assessmentData?.data?.categories.map((item) => ({
          ...item,
          numberOfLAMeet: assessmentData?.data?.numberOfDRMets,
        }))
      : [];
  }, [assessmentData]);

  const columns = [
    {
      title: 'Derailment Risks',
      dataIndex: 'category',
      key: 'category',
      width: '40%',
      className: `border-right-none`,
      render: (_, record) => {
        return (
          <FieldWrap>
            <FieldItemWrap>
              <NormalText>
                {record.category && record.category !== '-' ? record.category : '-'}
              </NormalText>
              <TooltipAntd
                title={
                  record && (
                    <div>
                      {!isEmpty(record?.popupContent) ? (
                        record.popupContent.map((item) => {
                          return <TooltipDesc key={item.content}>{item.content}</TooltipDesc>;
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                }
                {...{
                  overlayInnerStyle: {
                    minWidth: '298px',
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: 600,
                    fontFamily: `'Inter', sans-serif`,
                    padding: '12px',
                    borderRadius: '4px',
                    backgroundColor: '#000',
                  },
                }}
              >
                <img src={info_circle} alt="" />
              </TooltipAntd>
            </FieldItemWrap>
          </FieldWrap>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: `10%`,
      align: 'center',
      render: (_, record) => {
        return (
          <FieldItemWrap>
            <NormalText style={{ minWidth: '22px' }}>
              {record.score && record.score !== '-' ? record.score : '-'}
            </NormalText>
            {record.score && record.score !== '-' && (
              <StatusBgd bgdColor={returnBgdColorByScoreVal(record)}></StatusBgd>
            )}
          </FieldItemWrap>
        );
      },
    },
    {
      title: 'Derailment Risk Rating',
      dataIndex: 'numberOfLAMeet',
      key: 'numberOfLAMeet',
      width: `30%`,
      align: 'center',
      onCell: (_, _rowIndex) => {
        return {
          rowSpan: _rowIndex % convertData.length === 0 ? convertData.length : 0,
        };
      },
      render: (_, record) => (
        <span>
          {record.numberOfLAMeet && record.numberOfLAMeet !== '-' ? record.numberOfLAMeet : '-'}
        </span>
      ),
    },
  ];

  return (
    <div className={styles.wrapperContent}>
      <div className={styles.tableContent}>
        <Spin spinning={loading} size="small">
          <DerailmentRisksTable
            dataSource={convertData}
            columns={columns}
            pagination={false}
            rowClassName={(record, index) => {
              if (index === 2) return 'group-top';
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default DerailmentRisksAssessments;
