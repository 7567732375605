import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './popup-meeting-details.module.scss';

const PopupMeetingDetails = ({
  onSaveDetails,
  isPopupMeetingDetails,
  setIsPopupMeetingDetails,
}) => {
  return (
    <Modal
      show={isPopupMeetingDetails}
      onHide={() => setIsPopupMeetingDetails(false)}
      dialogClassName={styles.meeting_details}
      aria-labelledby="example-custom-modal-styling-title"
      scrollable={true}
    >
      <div className={styles.modal}>
        <Modal.Header className={styles.modal_header} closeButton>
          <div className={styles.modal_header_title} data-testid="meeting-details-title">
            Save this details?
          </div>
        </Modal.Header>

        <Modal.Body className={styles.body}>
          <div style={{ marginBottom: '40px' }} className={styles.label}>
            Please ensure the meeting secretariat group is correct. Would you like to save it now?
          </div>
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <div className={styles.content}>
            <div
              className={styles.button_cancel}
              onKeyDown={() => {}}
              onClick={() => setIsPopupMeetingDetails(false)}
              data-testid="cancel"
            >
              Cancel
            </div>
            <div
              className={styles.button_confirm}
              onKeyDown={() => {}}
              onClick={onSaveDetails}
              data-testid="save"
            >
              Save
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default PopupMeetingDetails;
