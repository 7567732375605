import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { BasicButton } from '../../../assets/common';
import {
  finder_grid_disable,
  finder_grid_enable,
  finder_list_disable,
  finder_list_enable,
  talent_finder_export,
  talent_finder_export_inactive,
} from '../../../assets/img';
import TalentExport from '../TalentExport/TalentExport';
import styles from './menu-bar.module.scss';

const MenuBar = (props) => {
  const {
    showFilter,
    setShowFilter,
    mode,
    setMode,
    setKeyword,
    keyword,
    data,
    setShowError,
    setIsSearch,
    setPage,
    filterFinderData,
    role,
    totalData,
    setShowExporting,
    isFilter,
    setIsFilter,
    setKeySearch,
    keySearch,
  } = props;

  function handleSearch() {
    if (!keySearch && isEmpty(filterFinderData)) {
      setShowError(true);
      setIsSearch(false);
    } else {
      setIsSearch(true);
      setPage(1);
    }
    setKeyword(keySearch);
  }
  useEffect(() => {
    setKeySearch(keyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setKeyword]);

  // Update key search field value
  useEffect(() => {
    if (isFilter && isEmpty(keySearch)) {
      setKeySearch(keyword);
      setIsFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilter, keyword]);

  return (
    <div className={styles.search_section}>
      <input
        type="text"
        placeholder="Search Talent"
        className={styles.search}
        onChange={(e) => setKeySearch(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleSearch();
        }}
        value={keySearch}
        data-testid="talent-finder-input"
      />
      <div className="d-flex justify-content-between align-items-end w-100">
        <div className="d-flex justify-content-between align-items-end w-100">
          <div className="d-flex">
            <img
              src={showFilter ? talent_finder_export : talent_finder_export_inactive}
              className={`${styles.filterBtn} ${!showFilter ? styles.inActive : ''}`}
              alt="Filter"
              onKeyDown={() => {}}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            />
            <BasicButton
              className={styles.button}
              height={40}
              mode="teal"
              onKeyDown={() => {}}
              onClick={handleSearch}
              data-testid="talent-finder-btn-search"
            >
              Search
            </BasicButton>
          </div>
          <TalentExport
            role={role}
            filter={filterFinderData}
            keyword={keyword}
            totalData={totalData}
            setShowExporting={setShowExporting}
          />
        </div>
        <div className={styles.icons}>
          <img
            src={mode && !isEmpty(data) ? finder_grid_enable : finder_grid_disable}
            alt="Grid"
            data-testid="talent-finder-btn-grid"
            onKeyDown={() => {}}
            onClick={() => {
              setMode(true);
            }}
          />
          <img
            src={!mode && !isEmpty(data) ? finder_list_enable : finder_list_disable}
            alt="List"
            data-testid="talent-finder-btn-list"
            onKeyDown={() => {}}
            onClick={() => {
              setMode(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
