import React from 'react';

import { AiButton } from '../../../../assets/common';
import { document_icon } from '../../../../assets/img';
import {
  DocumentIcon,
  FilePreviewContainer,
  FileUploadTitle,
  ProgressContainer,
  ProgressContent,
} from './Styled';

const ViewMoMFile = ({ meetingDetail }) => {
  const handleViewMoMFile = () => {
    if (meetingDetail?.file_url) {
      window.open(meetingDetail?.file_url, '_blank', 'noopener, noreferrer');
    }
  };

  return (
    <>
      <FilePreviewContainer>
        <ProgressContainer>
          <DocumentIcon src={document_icon} alt=""></DocumentIcon>
          <ProgressContent>
            <FileUploadTitle>
              <span
                className={'progress-file-name'}
                data-testid="fileName"
              >{`Minute of Meeting.pdf`}</span>
              {meetingDetail?.file_size && (
                <span className={'progress-file-size'} data-testid="fileSize">
                  {Math.round(meetingDetail?.file_size / 1024)} KB
                </span>
              )}
            </FileUploadTitle>
            <AiButton
              border={true}
              borderColor={'#00A19C'}
              data-testid="viewMoMBtn"
              onClick={() => handleViewMoMFile()}
            >
              View MoM
            </AiButton>
          </ProgressContent>
        </ProgressContainer>
      </FilePreviewContainer>
    </>
  );
};

export default ViewMoMFile;
