import queryString from 'query-string';
import { PERMISSION } from '../utils/constants';
import axiosClient from './axiosClient';

export const auditLogApi = {
  getAuditLogFilterOptions: (roleId) => {
    const url = '/admin/filter/audit-logs';
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getAuditLogs: (body, roleId) => {
    const url = `/admin/audit-logs`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getUserActivityLogs: (body, roleId) => {
    const url = `/admin/user-activity-log`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getAuditLogFilterByEmail: (email, roleId) => {
    const url = `/admin/filter/by-email?email=${email}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getAuditLogFilterByUserLogEmail: (email, roleId) => {
    const url = `/admin/get-by-email/user-activity-log?email=${email}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getAiPipelineRun: (query, roleActive) => {
    const stringified = queryString.stringify(query);
    const url = `/admin/audit-logs/ai-pipeline-run?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
        permission: PERMISSION.AUDIT_LOG_AI_PIPELINE,
      },
    });
  },
};
