import { notification } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { NotificationDetail, Task } from '..';
import { nofication } from '../../assets/img/';
import { useComponentVisible } from '../../hooks';
import { meetingApi } from '../../services/tcMeeting';
import { MEETING_DETAIL_STATUS, NOTIFICATION_MEETING_TYPE } from '../../utils/constants';
import styles from './notification.module.scss';

const Notification = () => {
  const [tabSelected, setTabSelected] = useState('Notification Detail');
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [tasks, setTasks] = useState([]);
  const history = useHistory();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { roleActive } = useSelector((state) => state.user);

  const countNotifications = async () => {
    try {
      setTotalNotifications(0);
      const response = await meetingApi.countNotifications(roleActive);
      const rs = get(response, 'data.result.total_unseen');
      setTotalNotifications(rs || 0);
    } catch (error) {
      setTotalNotifications(0);
    }
  };

  const getNotifications = async () => {
    try {
      setNotifications([]);
      const response = await meetingApi.getNotifications(roleActive);
      const rs = get(response, 'data.result.notifications');
      setNotifications(rs || []);
    } catch (error) {
      setNotifications([]);
    }
  };

  const getTasks = async () => {
    try {
      setTasks([]);
      const response = await meetingApi.getTasks(roleActive.roleId);
      const rs = get(response, 'data.result');
      setTasks(rs || []);
    } catch (error) {
      setTasks([]);
    }
  };

  const cleanNotification = async () => {
    try {
      if (isComponentVisible) {
        setIsComponentVisible(!isComponentVisible);
        return;
      }

      await meetingApi.cleanTotalNotifications();
      setTotalNotifications(0);
    } catch (error) {
      notification.error({
        message: `Clean notification fail`,
        placement: 'topRight',
        duration: 1.5,
      });
    }

    setIsComponentVisible(!isComponentVisible);
  };

  const viewNotification = async (notificationItem) => {
    try {
      if (!notificationItem || notificationItem.id === undefined) return;

      await meetingApi.updateSeenNotification(notificationItem.id);
      setIsComponentVisible(false);
      const path =
        notificationItem.notification_type ===
        NOTIFICATION_MEETING_TYPE.SUCCESSION_PLANNING_SYNDICATION
          ? `meeting/${notificationItem.meeting_id}/agenda`
          : 'meeting/detail';
      history.push(`/${path}/${notificationItem.ref_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const viewTask = (task) => {
    try {
      if (!task || task.meeting_id === undefined) return;
      setIsComponentVisible(false);
      let path;
      if (task.on_step === 1) {
        path = `/meeting/${task.meeting_id}`;
      }
      if (task.on_step === 2) {
        path = `/meeting/${task.meeting_id}/agendas`;
      }
      if (task.status === MEETING_DETAIL_STATUS.IN_REVIEW) {
        path = `/meeting/detail/${task.meeting_id}`;
      }
      history.push(path);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    countNotifications();
  }, []);

  useEffect(() => {
    if (isComponentVisible) {
      getNotifications();
      getTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={styles.nofication}
        onKeyDown={() => {}}
        onClick={cleanNotification}
        data-testid="icon"
      >
        <img src={nofication} alt="nofication" />
        {totalNotifications !== 0 && (
          <div className={styles.nofication_number}>{totalNotifications}</div>
        )}
      </div>
      {isComponentVisible && (
        <div className={styles.detail}>
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ gap: '10px', marginBottom: '20px' }}
          >
            <div
              className={'Notification Detail' === tabSelected ? styles.active : null}
              style={{ padding: '10px 15px', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setTabSelected('Notification Detail')}
            >
              Notification
              {totalNotifications !== 0 && (
                <span
                  style={{
                    padding: '6px',
                    color: '#fff',
                    backgroundColor: '#00847C',
                    borderRadius: '50%',
                    marginLeft: '10px',
                  }}
                >
                  {totalNotifications}
                </span>
              )}
            </div>
            <div
              className={'Task' === tabSelected ? styles.active : null}
              style={{ padding: '5px 10px', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setTabSelected('Task')}
            >
              Task
            </div>
          </div>
          {tabSelected === 'Notification Detail' ? (
            <NotificationDetail notifications={notifications} viewNotification={viewNotification} />
          ) : (
            <Task tasks={tasks} viewTask={viewTask} />
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(Notification);
