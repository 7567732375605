import styled from 'styled-components';

import { color, font, fontSize } from '../../../../assets/common';
import { MEETING_DETAIL_STATUS } from '../../../../utils/constants';

const handleBackgroundColorType = (status) => {
  switch (status) {
    case MEETING_DETAIL_STATUS.CONCLUDED:
    case MEETING_DETAIL_STATUS.PUBLISHED:
      return color.darkGreen;
    case MEETING_DETAIL_STATUS.IN_PROGRESS:
      return color.mySin;
    case MEETING_DETAIL_STATUS.CANCELED:
      return color.froly;
    default:
      return color.mySin;
  }
};

export const AiMeetingStatus = styled.div`
  padding: 5.5px 8px;
  border: 1px solid ${color.geyser};
  border-radius: 6px;
  margin-left: 10px;
  p {
    font-size: ${fontSize.small};
    color: ${color.darkBlue};
    font-family: ${font.rubik};
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    margin-left: 20px;
    &::before {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background-color: ${(props) => handleBackgroundColorType(props.status)};
      left: -13px;
      top: 49%;
      transform: translate(-50%, -50%);
    }
  }
`;
