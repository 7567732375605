import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, isNumber, size, uniqBy } from 'lodash';

import { adminApi } from '../../../../services/admin';
import { formatDate, formatTime } from '../../../../utils/helper';

export const getRoles = createAsyncThunk('roles/getListRoles', async (params, thunkAPI) => {
  const { id, role, limit, page } = params;
  try {
    const res = await adminApi.getRoles({ id, role, limit, page });
    const { result } = res.data;
    if (size(result) > 0) {
      const roles = result.roles.map((ele, _index) => ({
        key: ele.id,
        name: ele.fullname,
        created: ele.updatedBy || '-',
        date: formatDate(ele.updated_at) || '-',
        time: formatTime(ele.updated_at) || '-',
      }));
      thunkAPI.dispatch(getListRoles(roles));
      return result.total;
    }
  } catch (error) {
    throw new Error(error);
  }
});

export const createRoles = createAsyncThunk('roles/createRole', async (params) => {
  try {
    const res = await adminApi.createRole(params);
    if (res.status === 200) {
      let roleId = res.data.result[0]?.id;
      return {
        roleId,
      };
    }
  } catch (error) {
    return false;
  }
});

export const deleteRoles = createAsyncThunk('roles/deleteRole', async (params) => {
  const result = true;
  try {
    const res = await adminApi.deleteRoles(params);
    if (res.status === 200) {
      return result;
    }

    return !result;
  } catch (error) {
    return !result;
  }
});

/*
 * Function: Get list role levels
 * Goal : Get list role access levels for role detail page
 * Input: id: number, role: object
 * Return object {error : boolean , message : string}
 * Note : Res {a:[{a1},{a2}],b:[{b1},{b2}]} => [a,[{a1},{a2}]]
 */
export const getPermissions = createAsyncThunk('roles/getPermissions', async (params, thunkAPI) => {
  const { role } = params;
  try {
    const res = await adminApi.getModulePermission({ role });
    if (res.status === 200 && size(res.data.result) > 0) {
      let convertResToArray = Object.keys(res.data.result).map((key) => [
        key,
        res.data.result[key],
      ]);
      let convertEle1ToArray = convertResToArray.map((item) => {
        return item.map((ele, idx) => {
          if (idx === 1) {
            Object.keys(ele).forEach((key) => ele[key]);
          }
          return ele;
        });
      });
      thunkAPI.dispatch(getRolePermissions(convertEle1ToArray));
    }
    return {
      error: false,
      message: '',
    };
  } catch (error) {
    return {
      error: true,
      message: "Can't get list permissions",
    };
  }
});

export const getRoleLevels = createAsyncThunk('roles/getRoleLevels', async (params, thunkAPI) => {
  try {
    let converResName = [
      {
        id: 1,
        level: 1,
        name: 'Vice President',
      },
      {
        id: 2,
        level: 2,
        name: 'Senior General Manager',
      },
      {
        id: 3,
        level: 3,
        name: 'General Manager',
      },
      {
        id: 4,
        level: 4,
        name: 'Senior Manager',
      },
      {
        id: 5,
        level: 5,
        name: 'Executive',
      },
      {
        id: 6,
        level: 6,
        name: 'Manager',
      },
    ];
    converResName.push({
      id: 0,
      level: 0,
      name: 'All',
    });
    thunkAPI.dispatch(updatePermissions(converResName));
    return {
      error: false,
      message: '',
      size: size(converResName) - 1,
    };
  } catch (error) {
    return {
      error: true,
      message: "Can't get role access",
      size: 0,
    };
  }
});
const handleCheckAccessLv = (accessLevel) => {
  if (accessLevel === 1) {
    return {
      id: 1,
      name: 'PETRONAS',
    };
  }
  if (accessLevel === 2) {
    return {
      id: 2,
      name: 'Business',
    };
  }

  if (accessLevel === 3) {
    return {
      id: 3,
      name: 'OPU/Division',
    };
  }

  if (accessLevel === 4) {
    return {
      id: 4,
      name: 'Job Family',
    };
  }
};
const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    loading: {
      createRole: false,
      getRoles: false,
      getPermission: false,
    },
    permissions: [],
  },
  reducers: {
    getListRoles: (state, action) => {
      state.roles = action.payload;
    },
    getRolePermissions: (state, action) => {
      state.permissions = action.payload;
    },
    getSettingRole: (state, action) => {
      let defineModule = {};
      let clonePermissions = [...current(state.permissions)];
      let moduleIds = uniqBy(
        action.payload.map((item) => ({
          module_id: item.module_id,
          name: item.name,
        })),
        'module_id'
      );

      moduleIds.forEach((moduleId) => {
        for (let md in moduleId) {
          if (md === 'name') {
            defineModule[moduleId[md]] = [];
          }
        }
      });

      for (let df in defineModule) {
        action.payload.forEach((ele) => {
          if (ele.name === df) {
            defineModule[df].push(ele);
          }
        });
      }

      for (let df in defineModule) {
        let permission;
        let idxPermission;

        clonePermissions.forEach((ele, idxPer) => {
          for (let per = 0; per <= ele.length; per++) {
            if (per === 0 && ele[0] === df) {
              permission = ele;
              idxPermission = idxPer;
            }
          }
        });

        let modifyPermision = permission.map((ele) => {
          if (typeof ele !== 'string') {
            let tempGroupPermission =
              cloneDeep(ele.filter((item) => item?.groupName && item.permissions))[0]
                ?.permissions || null;

            return ele.map((subPer) => {
              let define = subPer;
              defineModule[df].forEach((dfItem) => {
                if (dfItem.permission_id === subPer.idPermission) {
                  define = {
                    ...subPer,
                    accessLevel: handleCheckAccessLv(dfItem.bu_access),
                    permission_checked: dfItem?.permission_checked,
                    option:
                      !isEmpty(subPer?.option) &&
                      subPer.option.map((opt) => {
                        let idxOption = dfItem.role_access.role.findIndex(
                          (idxR) => idxR === opt.id
                        );
                        if (idxOption !== -1) {
                          return {
                            ...opt,
                            checked: true,
                          };
                        }
                        return opt;
                      }),
                  };
                }

                // If have group permissions
                if (
                  isNumber(dfItem?.group_id) &&
                  dfItem?.group_id === subPer?.group_id &&
                  subPer.permissions
                ) {
                  const tempIndex = tempGroupPermission.findIndex(
                    (group) => group.idPermission === dfItem.permission_id
                  );
                  if (tempIndex !== -1) {
                    tempGroupPermission[tempIndex] = {
                      ...tempGroupPermission[tempIndex],
                      accessLevel: handleCheckAccessLv(dfItem.bu_access),
                      permission_checked: dfItem?.permission_checked,
                      option:
                        !isEmpty(tempGroupPermission[tempIndex].option) &&
                        tempGroupPermission[tempIndex].option.map((opt) => {
                          let idxOption = dfItem.role_access.role.findIndex(
                            (idxR) => idxR === opt.id
                          );
                          if (idxOption !== -1) {
                            return {
                              ...opt,
                              checked: true,
                            };
                          }
                          return opt;
                        }),
                    };
                  }
                  define = {
                    ...subPer,
                    permissions: tempGroupPermission,
                  };
                }
              });
              return define;
            });
          }
          return ele;
        });
        clonePermissions[idxPermission] = modifyPermision;
      }
      state.permissions = clonePermissions;
    },
    updatePermissions: (state, action) => {
      // Add option and accessLevel default for permission
      state.permissions = state.permissions.map((item) => {
        return item.map((ele, idx) => {
          if (idx === 1) {
            return ele.map((subEle) => {
              let childPer = [];
              if (subEle?.permissions) {
                childPer = subEle?.permissions.map((per) => {
                  return {
                    ...per,
                    permission_checked: per?.permission_checked ? true : false,
                    option: action.payload.map((opt) => ({
                      ...opt,
                      checked: false,
                    })),
                    accessLevel: {
                      id: 1,
                      name: 'PETRONAS',
                    },
                  };
                });
              }

              return {
                ...subEle,
                permission_checked: subEle?.permission_checked ? true : false,
                option: action.payload.map((opt) => ({
                  ...opt,
                  checked: false,
                })),
                accessLevel: {
                  id: 1,
                  name: 'PETRONAS',
                },
                permissions: subEle?.permissions ? childPer : null,
              };
            });
          }
          return ele;
        });
      });
    },
    updateStatusPermission: (state, action) => {
      let clonePermissions = [...current(state.permissions)];
      let permission;
      let idxPermission;

      // Find Permission
      clonePermissions.forEach((ele, idxPer) => {
        for (let per = 0; per <= ele.length; per++) {
          if (per === 0 && ele[0] === action.payload.permission) {
            permission = ele;
            idxPermission = idxPer;
          }
        }
      });

      // Modify field Option
      let modifyPermision = permission.map((ele) => {
        if (typeof ele === 'string') return ele;

        return ele.map((subPer) => {
          // Check option with permissions
          let tempPermissions = [];
          if (subPer?.permissions) {
            subPer.permissions.forEach((childPer) => {
              if (action.payload.namePermission === childPer?.namePermission) {
                if (action.payload.optId === 0) {
                  let findOpt = childPer.option.find((otpEle) => otpEle.id === 0);
                  tempPermissions.push({
                    ...childPer,
                    option:
                      !isEmpty(childPer?.option) &&
                      childPer.option.map((opt) => {
                        return {
                          ...opt,
                          checked: !findOpt.checked ? true : false,
                        };
                      }),
                  });
                } else {
                  tempPermissions.push({
                    ...childPer,
                    option:
                      !isEmpty(childPer?.option) &&
                      childPer.option.map((opt) => {
                        if (opt.id === action.payload.optId) {
                          return {
                            ...opt,
                            checked: !opt.checked,
                          };
                        }
                        return opt;
                      }),
                  });
                }
              } else {
                tempPermissions.push(childPer);
              }
            });
          }

          // Check option without permissions
          if (
            (subPer.namePermission || subPer.groupName) === action.payload.namePermission &&
            !subPer?.permissions
          ) {
            if (action.payload.optId === 0) {
              let findOpt = subPer.option.find((otpEle) => otpEle.id === 0);
              return {
                ...subPer,
                option:
                  !isEmpty(subPer?.option) &&
                  subPer.option.map((opt) => {
                    return {
                      ...opt,
                      checked: !findOpt.checked ? true : false,
                    };
                  }),
              };
            }
            return {
              ...subPer,
              option:
                !isEmpty(subPer?.option) &&
                subPer.option.map((opt) => {
                  if (opt.id === action.payload.optId) {
                    return {
                      ...opt,
                      checked: !opt.checked,
                    };
                  }
                  return opt;
                }),
            };
          }

          return {
            ...subPer,
            permissions: (subPer?.permissions && tempPermissions) || null,
          };
        });
      });

      // Handle trigger click Option type ALL
      let modifyPermissionTypeAll = modifyPermision.map((ele) => {
        if (typeof ele !== 'string') {
          return ele.map((subPer) => {
            if (subPer?.permissions) {
              let tempChildPer = [];
              subPer.permissions.forEach((childPer) => {
                if (action.payload.namePermission === childPer?.namePermission) {
                  let cloneOptions = !isEmpty(childPer.option) && cloneDeep(childPer.option);
                  if (!isEmpty(cloneOptions)) {
                    cloneOptions.pop();
                    let isCheck = cloneOptions.every((item) => item.checked === true);
                    tempChildPer.push({
                      ...childPer,
                      option:
                        !isEmpty(childPer?.option) &&
                        childPer.option.map((opt) => {
                          if (opt.id === 0) {
                            return {
                              ...opt,
                              checked: isCheck,
                            };
                          }
                          return opt;
                        }),
                    });
                  }
                } else {
                  tempChildPer.push(childPer);
                }
              });
              return {
                ...subPer,
                permissions: tempChildPer,
              };
            }

            if (subPer.namePermission === action.payload.namePermission) {
              let cloneOptions = !isEmpty(subPer.option) && cloneDeep(subPer.option);
              if (!isEmpty(cloneOptions)) {
                cloneOptions.pop();
                let isCheck = cloneOptions.every((item) => item.checked === true);
                return {
                  ...subPer,
                  option:
                    !isEmpty(subPer?.option) &&
                    subPer.option.map((opt) => {
                      if (opt.id === 0) {
                        return {
                          ...opt,
                          checked: isCheck,
                        };
                      }
                      return opt;
                    }),
                };
              }
            }
            return subPer;
          });
        }
        return ele;
      });
      clonePermissions[idxPermission] = modifyPermissionTypeAll;
      state.permissions = clonePermissions;
    },
    updateDisablePermission: (state, action) => {
      let clonePermissions = [...current(state.permissions)];
      let permission;
      let idxPermission;
      clonePermissions.forEach((ele, idxPer) => {
        for (let per = 0; per <= ele.length; per++) {
          if (per === 0 && ele[0] === action.payload.permission) {
            permission = ele;
            idxPermission = idxPer;
          }
        }
      });
      let modifyPermission = permission.map((ele) => {
        if (typeof ele === 'string') return ele;
        return ele.map((subPer) => {
          // If have the sub permissions
          let tempPermissions = [];
          if (subPer?.permissions) {
            subPer.permissions.forEach((childPer) => {
              if (action.payload.namePermission === childPer?.namePermission) {
                tempPermissions.push({
                  ...childPer,
                  permission_checked: action.payload.isEnable,
                  option:
                    !isEmpty(childPer?.option) &&
                    childPer.option.map((opt) => {
                      return {
                        ...opt,
                        checked: action.payload.isEnable,
                      };
                    }),
                });
              } else {
                tempPermissions.push(childPer);
              }
            });

            return {
              ...subPer,
              permissions: tempPermissions || null,
            };
          } else {
            if (subPer.namePermission === action.payload.namePermission) {
              return {
                ...subPer,
                permission_checked: action.payload.isEnable,
                option:
                  !isEmpty(subPer?.option) &&
                  subPer.option.map((opt) => {
                    return {
                      ...opt,
                      checked: action.payload.isEnable,
                    };
                  }),
              };
            }
          }
          return subPer;
        });
      });
      clonePermissions[idxPermission] = modifyPermission;
      state.permissions = clonePermissions;
    },
    updateBuAccessPermission: (state, action) => {
      let clonePermissions = [...current(state.permissions)];
      let permission;
      let idxPermission;
      // Find Permission
      clonePermissions.forEach((ele, idxPer) => {
        for (let per = 0; per <= ele.length; per++) {
          if (per === 0 && ele[0] === action.payload.permission) {
            permission = ele;
            idxPermission = idxPer;
          }
        }
      });
      // Modify field accessLevel
      let modifyPermision = permission.map((ele) => {
        if (typeof ele !== 'string') {
          return ele.map((subPer) => {
            // If have the sub permissions
            let tempPermissions = [];
            if (subPer?.permissions) {
              subPer.permissions.forEach((childPer) => {
                if (action.payload.idPermission === childPer?.idPermission) {
                  tempPermissions.push({
                    ...childPer,
                    accessLevel: {
                      id: action.payload.id,
                      name: action.payload.name,
                    },
                    permission_checked: action?.payload.permission_checked,
                  });
                } else {
                  tempPermissions.push(childPer);
                }
              });

              return {
                ...subPer,
                permissions: tempPermissions || null,
              };
            } else {
              if (subPer.idPermission === action.payload.idPermission) {
                return {
                  ...subPer,
                  accessLevel: {
                    id: action.payload.id,
                    name: action.payload.name,
                  },
                  permission_checked: action?.payload.permission_checked,
                };
              }
            }
            return subPer;
          });
        }
        return ele;
      });

      clonePermissions[idxPermission] = modifyPermision;
      state.permissions = clonePermissions;
    },
  },
  extraReducers: {
    [createRoles.pending]: (state) => {
      state.loading.createRole = true;
    },
    [createRoles.rejected]: (state) => {
      state.loading.createRole = false;
    },
    [createRoles.fulfilled]: (state) => {
      state.loading.createRole = false;
    },
    [getRoles.pending]: (state) => {
      state.loading.getRoles = true;
    },
    [getRoles.rejected]: (state) => {
      state.loading.getRoles = false;
    },
    [getRoles.fulfilled]: (state) => {
      state.loading.getRoles = false;
    },
    [getPermissions.pending]: (state) => {
      state.loading.getPermission = true;
    },
    [getPermissions.rejected]: (state) => {
      state.loading.getPermission = false;
    },
    [getRoleLevels.rejected]: (state) => {
      state.loading.getPermission = false;
    },
    [getRoleLevels.fulfilled]: (state) => {
      state.loading.getPermission = false;
    },
  },
});
const { actions, reducer: rolesReducer } = rolesSlice;
export const {
  getListRoles,
  getRolePermissions,
  updatePermissions,
  updateStatusPermission,
  updateBuAccessPermission,
  getSettingRole,
  updateDisablePermission,
} = actions;
export default rolesReducer;
