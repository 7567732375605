import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import styles from './popup-matching-criteria-list.module.scss';
import { adminApi } from '../../services/admin';
import { Spin } from 'antd';
import { contract_info } from '../../assets/img';
import { BasicPopover } from '../../assets/common';

const COL_WIDTH = ['20%', '36%', '17%', '15%', '12%'];
const COLOR_STATUS = {
  red: '#DA2228',
  green: '#68B172',
  yellow: '#FDB924',
};
const INIT_DATA = { table_data: [], total_percentage: 0, talent_infor: {} };

const PopupMatchingCriteriaList = (props) => {
  const { open, setOpen, nameSet, positionCode, staffId, idSet, positionName } = props;
  const [data, setData] = useState(INIT_DATA);
  const [loading, setLoading] = useState(false);

  const displayPercentage = useCallback((data, numDecimal = 1) => {
    if (!data && data !== 0) return '-';
    let multiplier = Math.pow(10, numDecimal);
    return `${Math.trunc(data * multiplier) / multiplier}%`;
  }, []);

  const fetchData = useCallback(async () => {
    if (!open) return;
    try {
      setLoading(true);
      const res = await adminApi.getDetailMatchingPercentage({
        position_code: positionCode,
        staff_id: staffId,
        set_id: idSet,
      });
      if (res.status === 200) {
        setData(res.data.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [idSet, open, positionCode, staffId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <CustomAntModal
        open={open}
        setOpen={setOpen}
        afterClose={() => setData(INIT_DATA)}
        width={1342}
        destroyOnClose
        hideDefaultBtn={true}
        styles={{
          containerPaddingTopBottom: 30,
          containerPaddingLeftRight: 30,
          closePosition: 30,
        }}
      >
        <div className={styles.container}>
          <div className={styles.title1}>
            <span>Matching Criteria for {positionName}: </span>
            <span className="title-2">{nameSet}</span>
          </div>
          <div className={styles.title2}>Matching Criteria List</div>
          <div className={styles.staff_infor}>Staff Name: {data.talent_infor?.birth_name}</div>
          <div className={styles.staff_infor}>Staff ID: {data.talent_infor?.staff_id}</div>
          <Spin spinning={loading}>
            <div className={styles.table}>
              <div className={styles.header}>
                <div className={styles.headerCell} style={{ flexBasis: COL_WIDTH[0] }}>
                  Criteria
                </div>
                <div className={styles.headerCell} style={{ flexBasis: COL_WIDTH[1] }}>
                  Item
                </div>
                <div className={styles.headerCell} style={{ flexBasis: COL_WIDTH[2] }}>
                  Percentage Breakdown
                  <BasicPopover
                    content="Value may differ due to truncating."
                    trigger="hover"
                    getPopupContainer={(trigger) => trigger}
                    styles={{
                      hideArrow: false,
                      px: 12,
                      py: 12,
                      bg: '#181818',
                      color: '#fff',
                      w: 180,
                    }}
                  >
                    <span className={styles.indicator}>
                      <img src={contract_info} alt="info" />
                    </span>
                  </BasicPopover>
                </div>
                <div className={styles.headerCell} style={{ flexBasis: COL_WIDTH[3] }}>
                  Matching Percentage
                </div>
                <div className={styles.headerCell} style={{ flexBasis: COL_WIDTH[4] }}>
                  Matching Status
                </div>
              </div>
              <div className={styles.body}>
                {data.table_data.map(({ criteria, items, matchingPercentage }) => {
                  return (
                    <div className={styles.row} key={criteria}>
                      <div
                        className={styles.criteriaCell}
                        style={{ flexBasis: COL_WIDTH[0], padding: '16px 12px' }}
                      >
                        {criteria}
                      </div>
                      <div className={styles.cellData} style={{ flexBasis: COL_WIDTH[1] }}>
                        {items.map((item) => (
                          <div className={styles.cellRow} key={item.name}>
                            {item.name}
                          </div>
                        ))}
                      </div>
                      <div className={styles.cellData} style={{ flexBasis: COL_WIDTH[2] }}>
                        {items.map((item) => (
                          <div className={styles.cellRow} key={item.name}>
                            {criteria.includes('Mandatory')
                              ? '-'
                              : displayPercentage(item.percentageBreakdown, 2)}
                          </div>
                        ))}
                      </div>
                      <div className={styles.cellData} style={{ flexBasis: COL_WIDTH[3] }}>
                        {matchingPercentage.map((i) => (
                          <div className={styles.cellRow} key={i}>
                            {criteria.includes('Mandatory') ? '-' : i}
                          </div>
                        ))}
                      </div>
                      <div className={styles.cellData} style={{ flexBasis: COL_WIDTH[4] }}>
                        {items.map((item) => (
                          <div key={item.name} className={styles.cellRow}>
                            <div
                              className={styles.matchingStatus}
                              style={{ background: COLOR_STATUS[item.matchingStatus] }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.total}>
                <div className={styles.criteriaCell}>Total Percentage</div>
                <div className={styles.percentage}>{`${displayPercentage(
                  data.total_percentage
                )} /100%`}</div>
              </div>
            </div>
          </Spin>
        </div>
      </CustomAntModal>
    </div>
  );
};

PopupMatchingCriteriaList.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  nameSet: PropTypes.string,
  positionCode: PropTypes.string,
  staffId: PropTypes.string,
  idSet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positionName: PropTypes.string,
};

export default PopupMatchingCriteriaList;
