import { isEmpty, size } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  finder_grid_disable,
  finder_list_disable,
  view_grid_enable,
  view_list_enable,
} from '../../../../../assets/img';
import { INIT_POSITION_VALUES } from '../../../../../utils/constants';
import {
  decodeBase64ToImage,
  getRetirementContractEndLabel,
  handleShowCT,
  handleShowInformation,
  handleShowRetirementDate,
  handleShowRetirementToView,
  handleShowYIPAndYISG,
} from '../../../../../utils/helper';
import ViewBox from '../Recommendations/Box/ViewBox';
import styles from './view-sucession-plan-popup.module.scss';

const GroupButton = styled.div`
  display: flex;
  align-items: flex-end;
  transform: translate(4%, 20%);
  position: absolute;
  bottom: 0;
  right: -30px;

  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    pointer-events: all;
  }

  img:active {
    transform: translateY(3%);
  }
`;

export const blockStyleForSP = {
  first_line: {
    headerColor: `#66B2C4`,
    inforColor: `#D9F2F7`,
    typeColor: `#BCE1EB`,
    textColor: `#525252`,
    typeTextColor: `#00847C`,
  },
  second_line: {
    headerColor: `#BDB6EA`,
    inforColor: `#BDB6EA`,
    typeColor: `#A9A2D6 `,
    textColor: `#525252`,
    typeTextColor: `#4F4873`,
  },
  third_line: {
    headerColor: `#BCC6E1`,
    inforColor: `#BCC6E1`,
    typeColor: `#9EA8C3`,
    textColor: `#525252`,
    typeTextColor: `#4E5873`,
  },
};

const ViewSucessionPlanPopup = (props) => {
  const { isShowSuccessionPlanPopup, setIsShowSuccessionPlanPopup, positions } = props;

  const agendaIncumbent = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const [line1, setLine1] = useState([]);
  const [line2, setLine2] = useState([]);
  const [line3, setLine3] = useState([]);
  const [columnMode, setColumnMode] = useState(false);
  const [information, setInformation] = useState({
    ...INIT_POSITION_VALUES,
  });

  const isShowViewMore = useMemo(() => {
    return (size(line1) || size(line2) || size(line3)) > 4;
  }, [line1, line2, line3]);

  useEffect(() => {
    setLine1(agendaIncumbent.first_line);
    setLine2(agendaIncumbent.second_line);
    setLine3(agendaIncumbent.third_line);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(agendaIncumbent.first_line),
    JSON.stringify(agendaIncumbent.second_line),
    JSON.stringify(agendaIncumbent.third_line),
  ]);

  const handleDataInformationStaff = useCallback(() => {
    setInformation({
      ...INIT_POSITION_VALUES,
    });

    positions.map((item) => {
      if (!isEmpty(item?.incumbent)) {
        setInformation((preState) => {
          return {
            ...preState,
            name: [
              ...preState.name,
              `${item.incumbent?.name ?? 'None '} ${
                item.incumbent?.ct ? handleShowCT(item.incumbent?.ct) : ''
              }`,
            ],
            age: [...preState.age, item.incumbent.age],
            retirementOrContractEndDate: [
              ...preState.retirementOrContractEndDate,
              `${handleShowRetirementDate(
                item.incumbent?.retirement_date || item.incumbent?.contract_end_date
              )} (${
                item.incumbent?.retirement_date || item.incumbent?.contract_end_date
                  ? handleShowRetirementToView(item.incumbent)
                  : 'None'
              })`,
            ],
            proposedSuccessionPlanning: [
              ...preState.proposedSuccessionPlanning,
              `${item.incumbent?.position_name} (Position ID: ${item?.position_code})`,
            ],
            roleAndJG: [
              ...preState.roleAndJG,
              `${item.incumbent?.role_level} ${
                item.incumbent?.jg_new ? `(${item.incumbent?.jg_new})` : ''
              }`,
            ],
            yearsInPositionAndSince: [
              ...preState.yearsInPositionAndSince,
              `${handleShowYIPAndYISG(
                item.incumbent?.years_in_position,
                item.incumbent?.date_in_position
              )} ${
                item.incumbent?.date_in_position
                  ? `(since ${moment(new Date(item.incumbent?.date_in_position)).format(
                      'DD/MM/YYYY'
                    )})`
                  : ''
              }`,
            ],
            incumbents: !isEmpty(item?.incumbent)
              ? [...preState.incumbents, item.incumbent]
              : preState.incumbents,
          };
        });
      }
      return null;
    });
  }, [positions]);

  useEffect(() => {
    handleDataInformationStaff();
  }, [positions, handleDataInformationStaff]);

  return (
    <div>
      <Modal
        show={isShowSuccessionPlanPopup}
        onHide={() => setIsShowSuccessionPlanPopup(false)}
        dialogClassName={styles.viewSusccessionPlanPopup}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div
          className={styles.viewSusccessionPlanContent}
          style={columnMode ? { paddingBottom: 0 } : {}}
        >
          <Modal.Header className={styles.modalHeader} closeButton={true}>
            <div className={styles.inforWrapper}>
              <h2 className={styles.mainTitle}>
                Proposed Succession Planning -{' '}
                {handleShowInformation(information.proposedSuccessionPlanning)}
              </h2>
              <div className={styles.wrapperInfo}>
                <div className={styles.itemInfoAvatar}>
                  <div className={styles.mainAvatar}>
                    <img
                      src={
                        !isEmpty(positions) && decodeBase64ToImage(positions[0]?.incumbent?.image)
                      }
                      alt="avatar"
                    />
                  </div>
                </div>
                <div className={styles.itemIfo}>
                  <div className={styles.rowItem}>
                    <div className={styles.fontMinimal}>
                      Name: {handleShowInformation(information.name)}
                    </div>
                    <div className={styles.fontMinimal}>
                      Role: {handleShowInformation(information.roleAndJG)}
                    </div>
                  </div>
                  <div className={styles.rowItem}>
                    <div className={styles.fontMinimal}>
                      Age: {handleShowInformation(information.age)}
                    </div>
                    <div className={styles.fontMinimal}>
                      {getRetirementContractEndLabel(information.incumbents)}{' '}
                      {handleShowInformation(information.retirementOrContractEndDate)}
                    </div>
                  </div>
                  <div className={styles.rowItem}>
                    <div className={styles.fontMinimal}>
                      Years In Position:{' '}
                      {handleShowInformation(information.yearsInPositionAndSince)}
                    </div>
                  </div>
                </div>
              </div>
              <GroupButton>
                <img
                  src={columnMode ? view_grid_enable : finder_grid_disable}
                  alt="Grid"
                  onKeyDown={() => {}}
                  onClick={() => {
                    setColumnMode(true);
                  }}
                />
                <img
                  src={!columnMode ? view_list_enable : finder_list_disable}
                  alt="List"
                  onKeyDown={() => {}}
                  onClick={() => {
                    setColumnMode(false);
                  }}
                />
              </GroupButton>
            </div>
          </Modal.Header>

          <Modal.Body className={styles.modalBody}>
            {!columnMode ? (
              <div className="d-flex justify-content-between align-items-start no-gutters">
                <div className={`col-12`}>
                  <ViewBox
                    columnMode={columnMode}
                    blockStyle={blockStyleForSP.first_line}
                    text={'1st'}
                    line={line1}
                  />
                  <ViewBox
                    columnMode={columnMode}
                    blockStyle={blockStyleForSP.second_line}
                    text={'2nd'}
                    line={line2}
                  />
                  <ViewBox
                    columnMode={columnMode}
                    blockStyle={blockStyleForSP.third_line}
                    text={'3rd'}
                    line={line3}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.cardBoxColumnView}>
                <div className={styles.columnViewItem}>
                  <ViewBox
                    isShowViewMore={isShowViewMore}
                    columnMode={columnMode}
                    blockStyle={blockStyleForSP.first_line}
                    text={'1st'}
                    line={line1}
                  />
                </div>
                <div className={styles.columnViewItem}>
                  <ViewBox
                    isShowViewMore={isShowViewMore}
                    columnMode={columnMode}
                    blockStyle={blockStyleForSP.second_line}
                    text={'2nd'}
                    line={line2}
                  />
                </div>
                <div className={styles.columnViewItem}>
                  <ViewBox
                    isShowViewMore={isShowViewMore}
                    columnMode={columnMode}
                    blockStyle={blockStyleForSP.third_line}
                    text={'3rd'}
                    line={line3}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ViewSucessionPlanPopup;
