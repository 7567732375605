import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
export const Preview = () => {
  const [data, setData] = useState({
    url: '',
    name: '',
  });
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      const param = new URLSearchParams(search);

      const url = param.get('url');
      const name = param.get('name');
      setData({ ...data, url, name });
      document.title = name;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <object
      data={data.url}
      type="application/pdf"
      width="100%"
      height="100%"
      aria-label=""
    ></object>
  );
};
