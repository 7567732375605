import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { DropdownCustomLg } from '../../components';
import { ROLE_SHORT_NAME, SIZE_DROPDOWN } from '../../utils/constants';
import CommonLoading from '../CommonLoading/CommonLoading';
import styles from './talent-category.module.scss';

function getSeries(talentCategory, filter) {
  if (filter?.value === 'top talent') {
    return talentCategory.reduce(
      function (newTalentCategory, item) {
        newTalentCategory[0] = newTalentCategory[0] + item?.corporate || 0;
        newTalentCategory[1] = newTalentCategory[1] + item?.business || 0;
        return newTalentCategory;
      },
      [0, 0]
    );
  } else {
    return talentCategory.reduce(
      function (newTalentCategory, item) {
        newTalentCategory[0] = newTalentCategory[0] + item?.corporate || 0;
        newTalentCategory[1] = newTalentCategory[1] + item?.business || 0;
        newTalentCategory[2] = newTalentCategory[2] + item?.other || 0;
        return newTalentCategory;
      },
      [0, 0, 0]
    );
  }
}

function optionsChart(filter) {
  return {
    chart: {
      type: 'donut',
      height: '1000px',
    },
    labels: filter?.value === 'top talent' ? ['Corporate', 'Business'] : ['Corporate', 'Business', 'Others'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: filter?.value === 'top talent' ? ['#7AC8C5', '#9F79B7'] : ['#7AC8C5', '#9F79B7', '#90A0CD'],
    },
    colors: filter?.value === 'top talent' ? ['#7AC8C5', '#9F79B7'] : ['#7AC8C5', '#9F79B7', '#90A0CD'],
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          size: '75%',
          background: '#F9F9F9',
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    legend: {
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Rubik, sans-serif',
      fontWeight: 400,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },

      markers: {
        radius: 4,
        fillColors: filter?.value === 'top talent' ? ['#7AC8C5', '#9F79B7'] : ['#7AC8C5', '#9F79B7', '#90A0CD'],
      },
    },
  };
}

export const TalentCategory = ({ selectedTopTalent, seriesChart, loadingTalentCategory }) => {
  const [selected, setSelected] = useState({
    label: 'All',
    value: 'all',
  });
  const optionsTalentCategory = [
    { label: 'All', value: 'all' },
    { label: 'Manager', value: ROLE_SHORT_NAME.ROLE_MANAGER },
    { label: 'Senior Manager', value: ROLE_SHORT_NAME.SENIOR_MANAGER },
    { label: 'General Manager', value: ROLE_SHORT_NAME.GENERAL_MANAGER },
    { label: 'Senior General Manager', value: ROLE_SHORT_NAME.SENIOR_GENERAL_MANAGER },
    { label: 'Executive', value: ROLE_SHORT_NAME.ROLE_EXECUTIVE },
  ];
  const [talentCategory, setTalentCategory] = useState(() => {
    return {
      series: getSeries(seriesChart, selectedTopTalent),
      options: optionsChart(selectedTopTalent),
    };
  });

  useEffect(() => {
    setTalentCategory(null);
    if (selected.value === 'all') {
      setTalentCategory({
        series: getSeries(seriesChart, selectedTopTalent),
        options: optionsChart(selectedTopTalent),
      });
    } else {
      const newItem = seriesChart.find((item) => {
        return item.role === selected.value;
      });
      if (newItem) {
        setTalentCategory({
          series: getSeries([newItem], selectedTopTalent),
          options: optionsChart(selectedTopTalent),
        });
      } else {
        setTalentCategory({
          series: getSeries(
            [
              {
                role: selected.value,
                corporate: 0,
                business: 0,
                others: 0,
              },
            ],
            selectedTopTalent
          ),
          options: optionsChart(selectedTopTalent),
        });
      }
    }
  }, [selected, seriesChart, selectedTopTalent]);

  const { series, options } = talentCategory;

  return (
    <>
      <div className={styles.talent_category}>
        <div className={styles.title}>
          <p className={styles.h2}>Talent Category</p>
          <div className={styles.dropdown}>
            <DropdownCustomLg
              setSelected={setSelected}
              selected={selected}
              options={optionsTalentCategory}
              type={SIZE_DROPDOWN.SM}
              showOption={true}
            />
          </div>
        </div>
        {loadingTalentCategory ? (
          <div className="center-loading">
            <CommonLoading />
          </div>
        ) : (
          <div className={styles.chart}>
            <ReactApexChart options={options} series={series} type="donut" width={'100%'} height={265} />
            <div className={styles.text}>
              <p className={styles.total}>total</p>
              <p className={styles.number_total}>{series ? series.reduce((total, currentValue) => total + currentValue) : 0}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TalentCategory;
