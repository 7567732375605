import React, { useEffect, useState } from 'react';
import { BasicButton, BasicInput } from '../../../../../assets/common';
import AddTalentForm from '../../components/AddTalentForm/AddTalentForm';
import { ADD_TALENT_FORM_TYPE, SEARCH_INPUT_PLACEHOLDER } from '../../constant';
import * as styles from './unsearchable-search-bar.module.scss';

const UnsearchableSearchBar = ({ fetchData, setSearchParam, searchParams }) => {
  const [keyword, setKeyword] = useState('');
  const [openAdd, setOpenAdd] = useState(false);

  const handleChangeInput = (e) => {
    setKeyword(e.target.value);
  };

  const handleClickSearch = () => {
    setSearchParam({ ...searchParams, keyword });
  };

  const handleClickAdd = () => {
    setOpenAdd(true);
  };

  useEffect(() => {
    setKeyword(searchParams.keyword);
  }, [searchParams.keyword])

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.searchBar}>
          <BasicInput
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            onChange={handleChangeInput}
            value={keyword}
          />
          <BasicButton mode="teal" onClick={handleClickSearch}>
            Search
          </BasicButton>
        </div>
        <BasicButton mode="teal" onClick={handleClickAdd}>
          Add Talent
        </BasicButton>
      </div>
      <AddTalentForm
        open={openAdd}
        setOpen={setOpenAdd}
        fetchData={fetchData}
        type={ADD_TALENT_FORM_TYPE.UNSEARCHABLE_LIST}
      />
    </>
  );
};

export default UnsearchableSearchBar;
