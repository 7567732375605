export const EXPORT_DETAILS_COLUMNS = [
  {
    title: 'No.',
  },
  {
    title: 'Staff Name',
  },
  {
    title: 'Staff ID',
  },
  {
    title: 'Top Talent Status',
  },
  {
    title: 'Salary Grade',
  },
  {
    title: 'Role Level',
  },
  {
    title: 'Business Unit',
  },
];
