import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import Select, { components } from 'react-select';

import { BasicButton } from '../../assets/common';
import { ar_down } from '../../assets/img';
import styles from './common-filter-managing.module.scss';

const InputOption = ({ Icon, getStyles, isFocused, isDisabled, children, innerProps, isSelected, ...rest }) => {
  const onMouseLeave = () => setIsActive(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const [isActive, setIsActive] = useState(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#00a19c';

  const style = {
    backgroundColor: bg,
    alignItems: 'center',
    display: 'flex ',
    color: 'inherit',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseUp,
    onMouseDown,
    style,
    onMouseLeave,
  };

  return (
    <components.Option {...rest} isFocused={isFocused} isDisabled={isDisabled} getStyles={getStyles} innerProps={props} isSelected={isSelected}>
      <Row gutter={4}>
        <Col>
          <input type="checkbox" className={styles.checkedFilter} checked={isSelected} onChange={(e) => e.preventDefault()} />
        </Col>
        <Col>{children}</Col>
      </Row>
    </components.Option>
  );
};

const CommonFilterManaging = (props) => {
  const { allOptions, placeholder, selectedOptions, setSelectedOptions, isDisabled, hasCustom, setIsApply, setIsSearch, isMinWidth } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOnChange = (options, action, selectedOption) => {
    if (!Array.isArray(options)) return;

    const isSelect = action === 'select-option';
    const isDeselect = action === 'deselect-option';
    const isSelectAll = action === 'select-option' && selectedOption.value === 'all';
    const isDeSelectAll = action === 'deselect-option' && selectedOption.value === 'all';
    const numOptionsWithoutAllOption = allOptions.length - 1;
    if (setIsSearch) setIsSearch(true);

    if (!isSelectAll && !isDeSelectAll) {
      if (isSelect && options.length === numOptionsWithoutAllOption && allOptions.length !== 2) {
        setSelectedOptions(allOptions);
        return;
      }
      if (isDeselect && options.length === numOptionsWithoutAllOption) {
        setSelectedOptions(options.filter((item) => item.value !== 'all'));
        return;
      }
      setSelectedOptions(options);
      return;
    }

    if (isDeSelectAll) {
      setSelectedOptions([]);
      return;
    }
    if (isSelectAll) {
      setSelectedOptions(allOptions);
    }
  };

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) => {
    return !isDisabled ? (
      <div ref={innerRef} {...innerProps} className={hasCustom ? `custom-react-select-menu-list has-apply-btn` : `custom-react-select-menu-list`}>
        {children}
        {hasCustom && (
          <div className={`${styles.wrapperButton} custom-react-select-apply-btn`}>
            <BasicButton mode="teal" onClick={() => handleClickApplyBtn()}>
              Apply
            </BasicButton>
          </div>
        )}
      </div>
    ) : null;
  };

  const handleClickApplyBtn = () => {
    setIsOpen(false);
    setIsApply(true);
  };

  return (
    <Select
      menuIsOpen={isOpen}
      defaultMenuIsOpen={false}
      autoFocus={false}
      value={selectedOptions}
      placeholder={placeholder}
      isMulti
      defaultValue={[]}
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      onChange={(options, { action, option }) => {
        handleOnChange(options, action, option);
      }}
      options={allOptions}
      components={{
        Option: InputOption,
        IndicatorSeparator: null,
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <img src={ar_down} alt="drop_down" />
          </components.DropdownIndicator>
        ),
        MenuList: CustomMenu,
      }}
      isSearchable={!hasCustom}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      classNamePrefix={'custom-react-select'}
      className={!isEmpty(selectedOptions) && hasCustom ? 'custom-react-select active' : 'custom-react-select'}
      styles={{
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          margin: 0,
          height: 40,
          minWidth: 186,
          border: '1px solid #D3DEE8',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          borderRadius: 4,
          '&:hover': {
            border: '1px solid #D3DEE8',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          },
        }),
        option: (provided) => ({
          ...provided,
          padding: 10,
          fontSize: '12px',
          backgroundColor: 'white',
          '&:active': {
            backgroundColor: 'white',
          },
          '&:hover': {
            backgroundColor: 'white',
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#8D8D8D',
          fontSize: 14,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          paddingRight: 20,
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: isMinWidth ? '' : '300px',
        }),
      }}
      isDisabled={isDisabled}
    />
  );
};

export default CommonFilterManaging;
