import { Table } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

import { comment_stack, delete_dev, edit_dev } from '../../../../../assets/img';
import {
  AiImg,
  AiNoAgenda,
  AiNoAgendaDesc,
  AiNoAgendaTitle,
  No,
  TableManagement,
  Text,
  Title,
  Cell,
} from './styled';

export default function TableDevelopmentPlan({
  data,
  onSelectEdit,
  onSelectDelete,
  onlyViewTalentAgenda,
  isPrinting,
}) {
  let columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      render: (text) => <No>{text}</No>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '15%',
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text) => <Text>{text}</Text>,
    },
  ];

  const getColumns = () => {
    return !onlyViewTalentAgenda
      ? [
          ...columns,
          {
            title: 'Action',
            key: 'action',
            width: '20%',
            align: 'center',
            render: (record) => (
              <Cell>
                <img
                  onKeyDown={() => {}}
                  onClick={() => onSelectEdit(record)}
                  style={{ cursor: 'pointer', marginRight: '9px' }}
                  src={edit_dev}
                  alt=""
                  data-testid="edit-btn"
                />
                <img
                  onKeyDown={() => {}}
                  onClick={() => onSelectDelete(record)}
                  style={{ cursor: 'pointer' }}
                  src={delete_dev}
                  alt=""
                  data-testid="delete-btn"
                />
              </Cell>
            ),
          },
        ]
      : columns;
  };

  return (
    <div>
      {!isPrinting && <Title>Development Plan List</Title>}
      {isEmpty(data) && (
        <AiNoAgenda>
          <AiImg src={comment_stack} alt="" />
          <AiNoAgendaTitle>No Details, yet.</AiNoAgendaTitle>
          <AiNoAgendaDesc>
            No details in this section, yet. Post your details to start evaluation.
          </AiNoAgendaDesc>
        </AiNoAgenda>
      )}
      {!isEmpty(data) && (
        <TableManagement>
          <Table
            columns={getColumns()}
            dataSource={data.map((val, index) => ({ ...val, no: index + 1 }))}
            pagination={false}
            rowKey={(record) => record.no}
          />
        </TableManagement>
      )}
    </div>
  );
}
