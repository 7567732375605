import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { meetingApi } from '../../services/tcMeeting';
import { USER_ID } from '../../utils/constants';
import { formatDate } from '../../utils/helper';
import { clearNotification, getNotification } from '../../utils/notification';
import styles from './version-history.module.scss';
const Item = (props) => {
  const { item, setNotification, roleActive, agendaId } = props;

  const [loadingSetAsMain, setLoadingSetAsMain] = useState(false);

  const setAsMain = async () => {
    try {
      setLoadingSetAsMain(true);
      const headers = { id: USER_ID, role: roleActive };
      const params = { meeting_id: 1, agenda_id: agendaId, version: item.version };
      const response = await meetingApi.setAsMain(headers, params);
      if (response.status === 200) {
        setNotification(getNotification('successful'));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setNotification(getNotification('failed'));
    }
    setLoadingSetAsMain(false);
  };

  return (
    <>
      <tr>
        <td className={styles.firstCol} width="20%">
          {item.version_name || '-'}
        </td>
        <td className={styles.firstCol} width="25%">
          {item.name}
        </td>
        <td width="25%">{formatDate(item.created_at) || '-'}</td>
        <td width="30%" className={styles.btn}>
          <div
            className={styles.version}
            onKeyDown={() => {}}
            onClick={() => {
              window.open(`${window.location.href}/${item.version}`, '_blank').focus();
            }}
            data-testid="view-version"
          >
            View Version
          </div>
          <div
            className={`${item.set_main_at ? styles.btn_disable : ''} ${styles.main}`}
            onKeyDown={() => {}}
            onClick={!item.set_main_at ? () => setAsMain() : null}
            data-testid="set-as-main"
          >
            {loadingSetAsMain && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Set as Main
          </div>
        </td>
      </tr>
    </>
  );
};

const VersionHistory = (props) => {
  const { setIsVisible, name, updateVersionHistory } = props;
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ isShow: false });
  const [data, setData] = useState([]);
  const roleActive = useSelector((state) => state.user.roleActive);
  const { agendaId } = useParams();

  const handleCancel = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    async function fetchAPI() {
      setLoading(true);
      try {
        const response = await meetingApi.getVersionHistory(USER_ID, roleActive, agendaId);
        if (response.status === 200 && response.data.result && response.data.result.length > 0) {
          setData(response.data.result);
        }
      } catch (error) {
        setData([]);
      }
      setLoading(false);
    }
    if (roleActive?.roleId) fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleActive, updateVersionHistory]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => handleCancel()}
        dialogClassName={styles.modal}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div>Version History</div>
        </Modal.Header>

        <div className={styles.message}>
          {notification.isShow && (
            <div className={`alert alert-${notification.alert}`} role="alert">
              {notification.message}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onKeyDown={() => {}}
                onClick={() => setNotification(clearNotification())}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
        </div>

        <Modal.Body className={styles.table}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th className={styles.firstCol} width="20%">
                  Name
                </th>
                <th className={styles.firstCol} width="25%">
                  Syndicator list
                </th>
                <th width="25%">Syndication Date</th>
                <th width="30%"></th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td align="center" colSpan="4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!loading &&
                (data && data.length > 0 ? (
                  data.map((item, index) => (
                    <Item
                      name={name}
                      key={index}
                      item={item}
                      setNotification={setNotification}
                      roleActive={roleActive}
                      agendaId={agendaId}
                    />
                  ))
                ) : (
                  <tr key="version-history">
                    <td className={styles.firstCol} width="20%">
                      -
                    </td>
                    <td className={styles.firstCol} width="25%">
                      -
                    </td>
                    <td width="25%">-</td>
                    <td width="30%" className={styles.btn}>
                      -
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VersionHistory;
