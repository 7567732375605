import { Button, Col, Row, Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetRoleLevel } from '../../../../../hooks/useGetRoleLevel';
import { profileManagementApi } from '../../../../../services/profileManagementApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { MESSAGE_TYPES } from '../../../../../utils/constants';
import { convertExternalTalents } from '../../../../../utils/helper';
import { history } from '../../../../../utils/history';
import About from './About/About';
import AssessmentExternal from './AssessmentExternal/AssessmentExternal';
import styles from './external-talents-form.module.scss';
import OtherInfor from './OtherInfor/OtherInfor';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import WorkingExperience from './WorkingExperience/WorkingExperience';

export default function ExternalTalenstForm() {
  const roleActive = useSelector((state) => state.user.roleActive);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: roleLevelData } = useGetRoleLevel({ keyword: '', limit: 100000, page: 1 });

  const [options, setOptions] = useState({
    nationalities: [],
    top_talent_categories: [],
    role_levels: [],
  });
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorSameEmail, setErrorSameEmail] = useState('');
  const [externalTalents, setExternalTalents] = useState({
    personal_infor: {
      profile_picture: '',
      passport: '',
      date_of_birth: undefined,
      email: '',
      full_name: '',
      age: '',
      nationality: '',
    },
    about: {
      about_me: '',
      top_talent_category: '',
      role_level_equivalent: '',
      retirement_date: undefined,
    },
    assessment: {
      performance_rating: [
        { year: moment().year() - 5, description: '' },
        { year: moment().year() - 4, description: '' },
        { year: moment().year() - 3, description: '' },
        { year: moment().year() - 2, description: '' },
        { year: moment().year() - 1, description: '' },
      ],
      be_pcb: [
        { year: moment().year() - 5, description: '' },
        { year: moment().year() - 4, description: '' },
        { year: moment().year() - 3, description: '' },
        { year: moment().year() - 2, description: '' },
        { year: moment().year() - 1, description: '' },
      ],
      external_link: [],
    },
    working_experience: [],
    others: {
      focal: '',
      source: '',
    },
  });

  const setFormData = (field, value) => {
    setExternalTalents((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  useEffect(() => {
    (async function getOptions() {
      try {
        setLoading(true);
        const res = await profileManagementApi.getExternalOptions(roleActive.roleId);
        setOptions((prev) => {
          return {
            ...prev,
            nationalities:
              get(res, 'data.result.nationalities').map((item) => {
                return { value: item, label: item };
              }) || [],
            top_talent_categories:
              get(res, 'data.result.top_talent_categories').map((item) => {
                return { value: item, label: item };
              }) || [],
          };
        });
      } catch (error) {
        console.error(error);
        setOptions((prev) => {
          return {
            ...prev,
            nationalities: [],
            top_talent_categories: [],
          };
        });
      } finally {
        setLoading(false);
      }
    })();

    if (!isEmpty(roleLevelData)) {
      setOptions((prev) => {
        return {
          ...prev,

          role_levels:
            roleLevelData.map((item) => {
              return { value: item.role_level, label: item.role_level };
            }) || [],
        };
      });
    }
  }, [roleActive.roleId, roleLevelData]);

  useEffect(() => {
    if (!id) return;
    (async function getExternalTalents() {
      try {
        const res = await profileManagementApi.getDetailExternalTalent(id, roleActive.roleId);
        const data = get(res, 'data.result');
        setExternalTalents(convertExternalTalents(data));
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onValidate = () => {
    let isValidate = true;
    if (
      !externalTalents.personal_infor.full_name ||
      !externalTalents.personal_infor.date_of_birth ||
      !externalTalents.personal_infor.email
    ) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: `There are items that require your attention. Please fill out this field.`,
        })
      );
      isValidate = false;
    }

    if (
      externalTalents.personal_infor.email &&
      !externalTalents.personal_infor.email.match(
        /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      if (isValidate === false) return;
      isValidate = false;
    }

    if (!isEmpty(externalTalents.working_experience)) {
      if (isValidate === false) return;
      externalTalents.working_experience.forEach((item) => {
        if (isValidate === false) return;
        if (
          item.from_date &&
          item.to_date &&
          moment(moment(item.from_date).format('YYYY-MM-DD')).isAfter(
            moment(moment(item.to_date).format('YYYY-MM-DD'))
          )
        ) {
          isValidate = false;
        }
      });
    }
    isValidate ? setIsError(false) : setIsError(true);
    return isValidate;
  };

  async function onSubmit() {
    try {
      if (id) {
        await profileManagementApi.editExternalTalent(id, externalTalents, roleActive.roleId);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `Talent Profile has been updated successfully.`,
          })
        );
      }
      if (!id) {
        await profileManagementApi.addExternalTalent(externalTalents, roleActive.roleId);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `Talent has been added successfully to the list.`,
          })
        );
      }

      history.push('/admin/profile-management/external-talents');
    } catch (error) {
      console.error(error);
      const errMessage = get(error, 'response.data.errors');
      if (errMessage === 'Record with the same email is exist. Please enter new record.') {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: `Record with the same email is exist. Please enter new record.`,
          })
        );
        setErrorSameEmail('Record with the same email is exist. Please enter new record.');
        setIsError(true);
      }
    }
  }

  return (
    <Spin spinning={loading} size="large">
      <div className={styles.main}>
        <PersonalInfo
          data={externalTalents.personal_infor}
          setData={setFormData}
          nationalities={options.nationalities}
          isError={isError}
          errorSameEmail={errorSameEmail}
          setErrorSameEmail={setErrorSameEmail}
          setIsError={setIsError}
        />
        <About
          data={externalTalents.about}
          setData={setFormData}
          top_talent_categories={options.top_talent_categories}
          role_levels={options.role_levels}
        />
        <AssessmentExternal data={externalTalents.assessment} setData={setFormData} />
        <WorkingExperience
          data={externalTalents.working_experience}
          setData={setFormData}
          isError={isError}
          role_levels={options.role_levels}
        />
        <OtherInfor data={externalTalents.others} setData={setFormData} />
        <Row gutter={8} className={styles.btn} justify="end">
          <Col>
            <Button
              data-testid="cancel-btn"
              onClick={() => history.push('/admin/profile-management/external-talents')}
              className={styles.cancel}
            >
              {!id ? 'Cancel' : 'Back'}
            </Button>
          </Col>
          <Col>
            <Button
              data-testid="submit-btn"
              onClick={() => {
                const isValidate = onValidate();
                if (isValidate) onSubmit();
              }}
              htmlType="submit"
              className={styles.add}
            >
              {!id ? 'Add' : 'Save Changes'}
            </Button>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}
