export const commonTooltipStyles = {
  color: 'black',
  overlayStyle: { height: '48px' },
  overlayInnerStyle: {
    maxWidth: '142px',
    fontSize: '12px',
    lineHeight: '18px',
    gap: '24px',
    padding: '12px',
    borderRadius: '4px',
  },
};
