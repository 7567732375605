import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicButton } from '../../assets/common';
import { BreadCrumb } from '../../components';
import AlertNotification from '../../components/AlertMessage/AlertNotification';
import MobilityListView from './components/MobilityListView/MobilityListView';
import { MOBILITY_BREADCRUMB_LIST, MOBILITY_MESSAGE } from './constants';
import styles from './mobility-simulation.module.scss';

const MobilitySimulation = () => {
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showEditSuccess, setShowEditSuccess] = useState(false);
  const [showDuplicateSuccess, setShowDuplicateSuccess] = useState(false);
  const [isDisableDelete, setIsDisableDelete] = useState(true);
  const [search, setSearch] = useState('');
  const mobilityListRef = useRef(null);

  const history = useHistory();

  const handleSearch = () => {
    mobilityListRef.current.handleSearch(search);
  };

  function handleDeleteMultiple() {
    mobilityListRef.current.handleDeleteMultiple();
  }

  const handleCreate = () => {
    history.push('/mobility-simulation/add');
  };

  useEffect(() => {
    const message = history.location?.state?.message;
    if (!message) return;
    if (message === 'edit-success') {
      history.replace();
      return setShowEditSuccess(true);
    }
    if (message === 'duplicate-success') {
      history.replace();
      return setShowDuplicateSuccess(true);
    }
  }, [history]);

  return (
    <div className="max-width">
      <BreadCrumb level={2} breadCrumbList={MOBILITY_BREADCRUMB_LIST} />
      <h3 className={styles.title}>Mobility Simulation</h3>

      <AlertNotification
        type="success"
        message={MOBILITY_MESSAGE.MOBILITY_DELETE_SUCCESSFUL}
        show={showDeleteSuccess}
        setShow={setShowDeleteSuccess}
        timeShow={5000}
        style={{ margin: '-25px 0 30px 0' }}
      />
      <AlertNotification
        type="success"
        message={MOBILITY_MESSAGE.MOBILITY_EDIT_SUCCESSFUL}
        show={showEditSuccess}
        setShow={setShowDeleteSuccess}
        timeShow={5000}
        style={{ margin: '-25px 0 30px 0' }}
      />
      <AlertNotification
        type="success"
        message={MOBILITY_MESSAGE.MOBILITY_DUPLICATE_SUCCESSFUL}
        show={showDuplicateSuccess}
        setShow={setShowDuplicateSuccess}
        timeShow={5000}
        style={{ margin: '-25px 0 30px 0' }}
      />
      <div className={styles.controlBar}>
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="Search Mobility Simulation"
            className={styles.searchInput}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            value={search.keyword}
          />
          <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleSearch}>
            Search
          </BasicButton>
        </div>
        <div className={styles.controlBtn}>
          <BasicButton
            mode="danger"
            onKeyDown={() => {}}
            onClick={handleDeleteMultiple}
            disabled={isDisableDelete}
          >
            Confirm Delete Mobility Simulation
          </BasicButton>
          <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleCreate}>
            Create Mobility Simulation
          </BasicButton>
        </div>
      </div>

      <MobilityListView
        ref={mobilityListRef}
        setIsDisableDelete={setIsDisableDelete}
        setShowDeleteSuccess={setShowDeleteSuccess}
        search={search}
        setSearch={setSearch}
      />
    </div>
  );
};

export default MobilitySimulation;
