import { Col, Dropdown, List, Row } from 'antd';
import _, { filter, find, includes, map } from 'lodash';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { AiSpin } from '../../../../../assets/common';
import { circle_checked, commentAllLines, comment_stack, editNote, sp_trash, shape } from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { AiH3 } from '../../Styled';
import styles from './view-remarks.module.scss';
import {
  EllipsisButton,
  FilterCol,
  MenuItemStyled,
  MenuWithStyle,
  NoRemarkCol,
  NoRemarkDescription,
  NoRemarkTitle,
  RemarkCategory,
  RemarkHeader,
  RemarkIcon,
  RemarkItemBody,
  RemarkItemCardDescription,
  RemarkItemCardTitle,
  RemarkItemStyled,
  RemarksBody,
  RemarkTalentName,
  ShapeIcon,
  Title,
} from './styled';

const FilterSelect = ({ closeMenuOnSelect, placeholder, value, options, onChange, isMulti, mode = 'remark' }) => {
  const [longest, setLongest] = useState(0)

  useEffect(() => {
    if (!_.isEmpty(options)) {
      const longest = options.sort(
        function (a, b) {
          return b.label.length - a.label.length;
        }
      )[0];
      setLongest(longest?.label?.length)
    }

  }, [options])

  return (
    <ReactSelect

      maxMenuHeight={mode === 'agenda' ? 150 : ''}
      placeholder={placeholder}
      isClearable={false}
      isSearchable={false}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      hideSelectedOptions={false}
      styles={{

        menu: (provided) => (mode === 'agenda' ? {
          ...provided,
          width: `${(longest * 6 + 200)}px`,
          right: 0,
        } : { ...provided }),
        control: (provided) => ({
          ...provided,
          background: '#FBFDFF',
          border: '1px solid #00A19C',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
          borderRadius: '4px',
          '&:hover': {
            border: '1px solid #00A19C',
            cursor: 'pointer',
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          fontFamily: "'Inter', sans-serif",
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '15px',
          /* identical to box height */
          color: '#00A19C',
        }),
        placeholder: (provided) => ({
          ...provided,
          fontFamily: "'Inter', sans-serif",
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '15px',
          /* identical to box height */
          color: '#00A19C',
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: '14px',
          fontFamily: "'Inter', sans-serif",
          fontWeight: 'bold',
          backgroundColor: 'white',
          overflow: 'ellipsis',
          color: '#344563',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#e5e5e5',
            color: '#344563',
          },
          '&:not(:last-of-type)': {
            borderBottom: '1px solid #d3dee8',
          },
        }),
      }}
      components={mode === 'agenda' ? {
        ValueContainer: CustomValueContainer,
        Option: Option,
        IndicatorSeparator: () => <></>,
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            {!props.hasValue ? <ShapeIcon /> :
              <img src={shape} alt='' />}
          </components.DropdownIndicator>
        ),
      } : {
        IndicatorSeparator: () => <></>,
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <ShapeIcon />
          </components.DropdownIndicator>
        ),
      }}
    />
  );
};


const CustomValueContainer = ({ children, label, ...props }) => {
  const { hasValue } = props;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>

      <Row align='middle' gutter={8} style={{ fontSize: 14, color: 'black' }}>
        <Col><img height='20px' src={circle_checked} alt="" /></Col>
        <Col>{props.selectProps.placeholder}</Col>
      </Row>
      {children[1]}

    </components.ValueContainer >
  );
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <Row align='top' gutter={2} justify='space-between'>
          <Col span={2}>
            <input
              className={styles.checkedFilter}
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
          </Col>
          <Col span={20}>
            <label style={{ color: '#344563', fontWeight: 'normal', textTransform: 'capitalize' }}>{props.label}</label>

          </Col>
        </Row>

      </components.Option>
    </div>
  );
};

const NoRemarks = React.memo(() => (
  <NoRemarkCol>
    <img src={comment_stack} alt="Comment Stack" />
    <NoRemarkTitle>No Remark, yet.</NoRemarkTitle>
    <NoRemarkDescription>
      No remarks in this section, yet. Post your remarks to start evaluation.
    </NoRemarkDescription>
  </NoRemarkCol>
));

const RemarkItemUI = React.memo(
  ({ icon, headerName, subTitle, remarks, category, onDelete, onEdit, isHiddenAction }) => {
    const handleMenuClick = useCallback(
      ({ key, domEvent }) => {
        if (key === 'edit') {
          onEdit && onEdit(domEvent);
        }

        if (key === 'delete') {
          onDelete && onDelete(domEvent);
        }
      },
      [onEdit, onDelete]
    );
    return (
      <RemarkItemStyled>
        <RemarkHeader>
          <RemarkTalentName>
            <RemarkIcon>{icon || <img src={commentAllLines} alt="Comment All Lines" />}</RemarkIcon>
            {headerName}
          </RemarkTalentName>
          <Row gutter={12}>
            {category && <RemarkCategory>Category : {category}</RemarkCategory>}
            <Col>
              {!isHiddenAction && (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <MenuWithStyle onClick={handleMenuClick}>
                      <MenuItemStyled key="edit" icon={<img src={editNote} alt="Edit Icon" />}>
                        <span>Edit</span>
                      </MenuItemStyled>
                      <MenuItemStyled
                        key="delete"
                        icon={<img src={sp_trash} alt="Edit Icon" />}
                        $isDelete
                      >
                        <span>Delete</span>
                      </MenuItemStyled>
                    </MenuWithStyle>
                  }
                  placement="bottomRight"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <EllipsisButton />
                </Dropdown>
              )}
            </Col>
          </Row>
        </RemarkHeader>
        <div style={{ marginTop: 20 }} />
        <RemarkItemBody>
          <RemarkItemCardTitle>{subTitle}</RemarkItemCardTitle>
          <RemarkItemCardDescription>{remarks}</RemarkItemCardDescription>
        </RemarkItemBody>
      </RemarkItemStyled>
    );
  }
);

const DeleteConfirmation = ({ visible, setVisible, onConfirmDelete, loading }) => (
  <ModelTC
    info={{
      type: 'confirmDeleteRemark',
      visible,
      setVisible,
      loading,
      handleSubmit: onConfirmDelete,
    }}
  />
);

const RemarkItem = React.memo(
  ({ remarkType, talentName, agendaName, staffId, remarks, category, remarkId, agendaId, onDelete, onEdit, isHiddenAction }) => {
    const handleDelete = useCallback(() => {
      onDelete({ remarkId, agendaId, remarkType });
    }, [onDelete, remarkId, agendaId, remarkType]);

    const handleEdit = useCallback(() => {
      onEdit({ remarkId, agendaId, agendaName, remarkType, staffId, talentName, remarks, category });
    }, [onEdit, remarkId, agendaId, agendaName, remarkType, staffId, talentName, remarks, category]);

    if (remarkType === 'Talent') {
      const firstCharacterNames = talentName?.split(/\s+/)
      return (
        <RemarkItemUI
          icon={firstCharacterNames ? [firstCharacterNames[0].charAt(0), firstCharacterNames[1].charAt(0)].join('') : null}
          headerName={`${talentName || '-'}`}
          category={category}
          subTitle={`${agendaName || ''}`}
          remarks={remarks}
          onDelete={handleDelete}
          onEdit={handleEdit}
          isHiddenAction={isHiddenAction}
        />
      );
    }

    return (
      <RemarkItemUI
        headerName={<RemarkItemCardTitle as="span">{agendaName}</RemarkItemCardTitle>}
        remarks={remarks}
        onDelete={handleDelete}
        onEdit={handleEdit}
        isHiddenAction={isHiddenAction}
      />
    );
  }
);

const viewRemarkReducer = (state, action) => {
  if (action.type === 'UPDATE') {
    return { ...state, ...action.payload };
  }
  return state;
};

const useRemarkFiltersReducer = (initialState) => {
  const [remarkFilters, dispatch] = useReducer(viewRemarkReducer, initialState);

  const updateFilters = useCallback((filterName, filterValue) => {
    dispatch({ type: 'UPDATE', payload: { [filterName]: filterValue } });
  }, []);

  return { remarkFilters, updateFilters };
};

const REMARK_TYPE_OPTIONS = [
  { label: 'All', value: null },
  { label: 'General', value: 'General' },
  { label: 'Talent', value: 'Talent' },
];

export default React.memo(
  ({
    className,
    isLoading,
    onFilters,
    onDeleteRemark,
    agendaOptions,
    remarks,
    isDeleteRemarkLoading,
    onEditRemark,
    isHiddenAction,
  }) => {
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(null);
    const paginationConfig = {
      defaultPageSize: 3,
      showTotal: (total, range) => (
        <>
          Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
        </>
      )
    }

    const { remarkFilters, updateFilters } = useRemarkFiltersReducer({
      remarkType: null,
      agendas: [],
    });

    useEffect(() => {
      onFilters && onFilters(remarkFilters);
    }, [onFilters, remarkFilters]);

    const deleteRemarkParamsRef = useRef(null);

    const openDeleteConfirmation = useCallback((params) => {
      setIsConfirmationDelete(true);
      deleteRemarkParamsRef.current = params;
    }, []);

    const handleDeleteRemark = useCallback(() => {
      onDeleteRemark(deleteRemarkParamsRef.current, {
        closeDeletePopup: () => setIsConfirmationDelete(false),
      });
    }, [onDeleteRemark]);

    const handleEditRemark = useCallback((item) => {
      onEditRemark(item)
    }, [onEditRemark])

    const handleAgendaFiltersChange = useCallback(
      (options) => {
        updateFilters('agendas', map(options, 'value'));
      },
      [updateFilters]
    );

    const handleRemarkTypeFiltersChange = useCallback(
      (option) => {
        updateFilters('remarkType', option.value);
      },
      [updateFilters]
    );
    return (
      <>
        <Col className={className}>
          <Row justify="space-between" align="center">
            <Title as="div">
              <AiH3>All Remark</AiH3>
            </Title>

            <FilterCol>
              <FilterSelect
                value={filter(agendaOptions, ({ value }) => includes(remarkFilters.agendas, value))}
                onChange={handleAgendaFiltersChange}
                isMulti
                options={agendaOptions}
                placeholder="Select Agenda"
                closeMenuOnSelect={false}
                mode='agenda'

              />
              <FilterSelect
                value={find(agendaOptions, { value: remarkFilters.remarkType })}
                onChange={handleRemarkTypeFiltersChange}
                options={REMARK_TYPE_OPTIONS}
                // placeholder="Select Remark type"
                placeholder="All"
              />
            </FilterCol>
          </Row>

          <RemarksBody>
            <AiSpin wrapperClassName="w-100" spinning={isLoading} tip="Loading...">
              {remarks.length ? <List
                pagination={paginationConfig}
                itemLayout="vertical"
                dataSource={remarks}
                grid={{ gutter: 28, column: 1 }}
                renderItem={(item) => (
                  <List.Item className="w-100">
                    <RemarkItem onDelete={openDeleteConfirmation} onEdit={() => handleEditRemark(item)} {...item} isHiddenAction={isHiddenAction} />
                  </List.Item>
                )}
              /> : <NoRemarks />}
            </AiSpin>
          </RemarksBody>
        </Col>
        <DeleteConfirmation
          visible={isConfirmationDelete}
          setVisible={setIsConfirmationDelete}
          onConfirmDelete={handleDeleteRemark}
          loading={isDeleteRemarkLoading}
        />
      </>
    );
  }
);
