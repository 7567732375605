import { Col, Row } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { ar_down } from '../../../../../assets/img';
import styles from './select-menu.module.scss';

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);

  let bg = 'transparent';
  if (isActive) bg = '#B2D4FF';
  if (isFocused) bg = '#eee';
  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  const props = {
    ...innerProps,
    style,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
  };

  return (
    <components.Option
      {...rest}
      isFocused={isFocused}
      isDisabled={isDisabled}
      isSelected={isSelected}
      innerProps={props}
      getStyles={getStyles}
    >
      <Row gutter={6} align="middle">
        <Col>
          <input className={styles.checkedFilter} type="checkbox" checked={isSelected} />
        </Col>
        <Col>{children}</Col>
      </Row>
    </components.Option>
  );
};

export default function SelectMenu({
  defaultMenuIsOpen = false,
  allOptions = [],
  placeholder,
  selectedOptions = [],
  setSelectedOptions,
  mode = 'POSITION_LABEL',
}) {
  const [longest, setLongest] = useState(0);
  useEffect(() => {
    const allOptions_ = JSON.parse(JSON.stringify(allOptions));
    if (!_.isEmpty(allOptions_)) {
      const longest = allOptions_.sort(function (a, b) {
        return b.label.length - a.label.length;
      })[0];
      setLongest(longest?.label?.length);
    }
  }, [allOptions]);
  return (
    <Select
      defaultMenuIsOpen={defaultMenuIsOpen}
      value={selectedOptions}
      placeholder={placeholder}
      isMulti
      defaultValue={[]}
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      onChange={(options) => {
        if (Array.isArray(options)) {
          if (selectedOptions.find((option) => option.value === 'all')) {
            if (!options.find((option) => option.value === 'all')) setSelectedOptions([]);
            else {
              const selectedOptions_ = options.filter((item) => item.value !== 'all');
              setSelectedOptions(selectedOptions_);
            }
          } else if (options.find((option) => option.value === 'all'))
            setSelectedOptions(allOptions);
          else if (options.length === allOptions.length - 1) setSelectedOptions(allOptions);
          else setSelectedOptions(options);
        }
      }}
      options={allOptions}
      components={{
        Option: InputOption,
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <img src={ar_down} alt="" />
          </components.DropdownIndicator>
        ),
      }}
      styles={{
        menu: (provided) =>
          mode === 'POSITION_LABEL'
            ? {
                ...provided,
                width: `${longest * 5 + 150}px`,
                right: 0,
              }
            : { ...provided },
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          margin: 0,
          height: 40,
          width: 186,
          border: '1px solid #D3DEE8',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          borderRadius: 4,
          '&:hover': {
            border: '1px solid #D3DEE8',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          padding: 10,
          fontSize: 12,
          backgroundColor: 'white',
          '&:active': {
            backgroundColor: 'white !important',
          },
          '&:hover': {
            backgroundColor: 'white',
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#8D8D8D',
          fontSize: 14,
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          paddingRight: 20,
        }),
      }}
    />
  );
}
