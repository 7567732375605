import { Checkbox } from 'antd';
import styled from 'styled-components';
import { font } from '../../../assets/common';

export const TitleDiv = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Light-Primary-600, #00908c);
  margin-bottom: 14px;
  cursor: pointer;
`;

export const Title = styled.div`
  color: var(--Base-White, #fff);
  font-family: ${font.inter};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

export const TitleEmptyState = styled.div`
  color: var(--Light-Gray-400, #a3a3a3);
  text-align: center;
  font-family: ${font.inter};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
`;

export const CheckboxFulfilment = styled(Checkbox)`
  cursor: default !important;
  .ant-checkbox-disabled .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid var(--Light-Blue-Grays-300, #c9d5e3) !important;
    background: var(--Light-Blue-Grays-200, #e7edf3);
    width: 16px;
    height: 16px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0b882e !important;
    border-color: #0b882e !important;
    padding: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: #0b882e !important;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
  .ant-checkbox-disabled,
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: default !important;
  }
`;
