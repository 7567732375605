import React, { useEffect, useState } from 'react';
import { fetchBestFitRecommendation } from '../../services/talentProfiles';
import styles from './best-fit-recommendation.module.scss';
import { star } from '../../assets/img';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';

const BestFitRecommendation = (props) => {
  const { profileId, roleUser } = props;

  const [isActiveFirstLine, setIsActiveFirstLine] = useState(true);
  const [isActiveSecondLine, setIsActiveSecondLine] = useState(false);
  const [isActiveThirdLine, setIsActiveThirdLine] = useState(false);

  const [rank, setRank] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFirstActive = () => {
    setIsActiveFirstLine(true);
    setIsActiveSecondLine(false);
    setIsActiveThirdLine(false);
    setRank(1);
  };

  const handleSecondActive = () => {
    setIsActiveFirstLine(false);
    setIsActiveSecondLine(true);
    setIsActiveThirdLine(false);
    setRank(2);
  };

  const handleThirdActive = () => {
    setIsActiveFirstLine(false);
    setIsActiveSecondLine(false);
    setIsActiveThirdLine(true);
    setRank(3);
  };

  useEffect(() => {
    async function fetchApi() {
      setLoading(true);
      const body = {
        rank: rank,
      };
      const response = await fetchBestFitRecommendation({ profileId, roleUser, body });
      if (response.status === 200) {
        setDataList(response?.data?.result || []);
      }

      setLoading(false);
    }
    if (roleUser.roleActive.roleId) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser.roleActive.roleId, profileId, rank]);

  return (
    <div className={styles.SectionPlan}>
      <div className={styles.SectionName}>Right Fit Recommendation</div>

      <div className={styles.chart}>
        <div className="d-flex">
          <div
            className={styles.layoutColumn}
            style={{
              justifyContent: !isActiveFirstLine && 'flex-end',
            }}
          >
            {isActiveFirstLine && (
              <div
                data-testid="title-first-line"
                className={styles.font}
                style={{ marginBottom: '10px' }}
              >
                1st Line
              </div>
            )}

            <div
              className={styles.layoutRow}
              style={{
                marginBottom: isActiveFirstLine ? '30px' : '50px',
                alignItems: 'center',
                marginLeft: !isActiveFirstLine && '15px',
              }}
            >
              {isActiveFirstLine ? (
                <div data-testid="first-line-active" className={styles.circleActive}>
                  <div className={styles.elipseActive}></div>
                </div>
              ) : (
                <div
                  data-testid="click-first-line"
                  className={styles.elipse}
                  style={{ cursor: 'pointer' }}
                  onKeyDown={() => {}}
                  onClick={handleFirstActive}
                ></div>
              )}

              <div
                className={styles.crossbar}
                style={{
                  width: (isActiveFirstLine || isActiveThirdLine) && '264px',
                }}
              ></div>
            </div>

            <div
              data-testid="label-year-first-line"
              className={`${!isActiveFirstLine ? styles.font : styles.fontActive}`}
            >
              0 - 3 Years
            </div>
          </div>

          <div
            className={styles.layoutColumn}
            style={{
              justifyContent: !isActiveSecondLine && 'flex-end',
            }}
          >
            {isActiveSecondLine && (
              <div
                data-testid="title-second-line"
                className={styles.font}
                style={{ marginBottom: '10px', marginLeft: '10px' }}
              >
                2nd Line
              </div>
            )}

            <div
              className={styles.layoutRow}
              style={{
                marginBottom: isActiveSecondLine ? '30px' : '50px',
                alignItems: 'center',
              }}
            >
              {isActiveSecondLine ? (
                <div data-testid="second-line-active" className={styles.circleActive}>
                  <div className={styles.elipseActive}></div>
                </div>
              ) : (
                <>
                  <div className={styles.crossbarFirst}></div>
                  <div
                    data-testid="click-second-line"
                    style={{ cursor: 'pointer' }}
                    className={styles.elipse}
                    onKeyDown={() => {}}
                    onClick={handleSecondActive}
                  ></div>
                </>
              )}

              <div className={styles.crossbar}></div>
            </div>

            <div
              data-testid="label-year-second-line"
              className={`${!isActiveSecondLine ? styles.font : styles.fontActive}`}
            >
              3 - 5 Years
            </div>
          </div>

          <div
            className={styles.layoutColumn}
            style={{
              justifyContent: !isActiveThirdLine && 'flex-end',
            }}
          >
            {isActiveThirdLine && (
              <div
                data-testid="title-third-line"
                className={styles.font}
                style={{ marginBottom: '10px', marginLeft: '10px' }}
              >
                3rd Line
              </div>
            )}

            <div
              className={styles.layoutRow}
              style={{
                marginBottom: isActiveThirdLine ? '30px' : '50px',
                alignItems: 'center',
              }}
            >
              {isActiveThirdLine ? (
                <div data-testid="third-line-active" className={styles.circleActive}>
                  <div className={styles.elipseActive}></div>
                </div>
              ) : (
                <>
                  <div
                    data-testid="click-third-line"
                    className={styles.elipse}
                    style={{ cursor: 'pointer' }}
                    onKeyDown={() => {}}
                    onClick={handleThirdActive}
                  ></div>
                </>
              )}
            </div>

            <div
              data-testid="label-year-third-line"
              className={`${!isActiveThirdLine ? styles.font : styles.fontActive}`}
            >
              {'>'} 5 Years
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '40px' }}>
        {isActiveFirstLine && (
          <div
            data-testid="content-first-line"
            className={styles.font}
            style={{ fontSize: '18px', lineHeight: '22px' }}
          >
            <div>1st Line</div>
            <div className={styles.bold}>0 - 3 Years</div>
          </div>
        )}

        {isActiveSecondLine && (
          <div
            data-testid="content-second-line"
            className={styles.font}
            style={{ fontSize: '18px', lineHeight: '22px' }}
          >
            <div>2nd Line</div>
            <div className={styles.bold}>3 - 5 Years</div>
          </div>
        )}

        {isActiveThirdLine && (
          <div
            data-testid="content-third-line"
            className={styles.font}
            style={{ fontSize: '18px', lineHeight: '22px' }}
          >
            <div>3rd Line</div>
            <div className={styles.bold}>{'>'} 5 Years</div>
          </div>
        )}
      </div>

      <div className={styles.tablePlan}>
        <table className="table">
          <thead>
            <tr className={styles.tablePlanThead}>
              <th scope="col" style={{ width: '35%' }}>
                Position Name
              </th>
              <th scope="col" className="text-center" style={{ width: '20%' }}>
                Job Grade
              </th>
              <th scope="col" className="text-center">
                Division
              </th>
              <th scope="col" className="text-left" style={{ width: '30%' }}>
                Matching (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td align="center" colSpan="4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              (dataList && dataList.length > 0 ? (
                dataList?.map((data, index) => (
                  <tr data-testid="list-best-fit" key={index} className={styles.tablePlanBody}>
                    <td data-testid="role" style={{ fontSize: '14px' }}>
                      {data.position_name || '-'}
                    </td>
                    <td
                      data-testid="job_grade"
                      className="text-center"
                      style={{ fontSize: '14px' }}
                    >
                      {data.job_grade || '-'}
                    </td>
                    <td
                      data-testid="position_name"
                      className="text-center"
                      style={{ width: '20%', fontSize: '14px' }}
                    >
                      {data.division || '-'}
                    </td>
                    <td
                      data-testid="percent_of_matching"
                      className="text-left d-flex  justify-content-between align-items-center"
                      style={{ fontWeight: '500', fontSize: '14px' }}
                    >
                      {data.percent_of_matching ? data.percent_of_matching + '%' : '-'}
                      {data.isStar && (
                        <Tooltip
                          placement="top"
                          title={
                            <>
                              <div>
                                <span
                                  style={{
                                    color: 'var(--base-white, #FFF)',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    lineHeight: '18px',
                                  }}
                                >
                                  Matching of Aspiration
                                </span>
                              </div>

                              {!isEmpty(data.minilmjobfunctions) &&
                                data.minilmjobfunctions.map((i) => (
                                  <div style={{ marginTop: '5px' }}>
                                    <span
                                      style={{
                                        height: '5px',
                                        width: '5px',
                                        backgroundColor: '#fff',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        transform: 'translateY(-2px)',
                                        marginRight: '8px',
                                        marginLeft: '8px',
                                        opacity: '0.8',
                                      }}
                                    ></span>
                                    <span
                                      style={{
                                        color: 'var(--base-white, #FFF)',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        opacity: '0.8',
                                      }}
                                    >
                                      {i}
                                    </span>
                                  </div>
                                ))}
                            </>
                          }
                          overlayClassName="custom-tooltip"
                          {...{
                            overlayInnerStyle: {
                              width: '260px',
                              fontSize: '12px',
                              lineHeight: '18px',
                              gap: '24px',
                              padding: '12px',
                              borderRadius: '4px',
                              backgroundColor: '#000',
                            },
                          }}
                        >
                          <span style={{ cursor: 'pointer', marginRight: '80px' }}>
                            <img src={star} alt="star" style={{ width: '20px' }} />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr data-testid="no-list-best-fit" key="0" className={styles.tablePlanBody}>
                  <td>-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BestFitRecommendation;
