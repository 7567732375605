import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import * as styles from './print-approved-sp.module.scss';
import { BasicButton } from '../../../../assets/common';
import { Spin } from 'antd';

const PrintApprovedSp = (props) => {
  const { componentRef, positionName, dateApproved, customFileName, style, isDisabled } = props;
  const [loading, setLoading] = useState(false);
  const fileName = `SP_${positionName}_${dateApproved ? moment(dateApproved).format('YYYYMMDD') : ''}`;

  const reactToPrintTrigger = () => {
    return (
      <div className={styles.control}>
        <div className="mt-2">
          <div className="d-flex align-items-center justify-content-end">
            <Spin spinning={loading} size="small">
              <BasicButton disabled={loading || !positionName || isDisabled} data-testid="printBtn" style={style}>
                Print
              </BasicButton>
            </Spin>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ReactToPrint
        content={() => componentRef.current}
        removeAfterPrint
        documentTitle={customFileName || fileName}
        onBeforeGetContent={() => setLoading(true)}
        onBeforePrint={() => setLoading(false)}
        trigger={reactToPrintTrigger}
      />
    </div>
  );
};
PrintApprovedSp.propTypes = {
  componentRef: PropTypes.object,
  positionName: PropTypes.string,
  dateApproved: PropTypes.string,
  customFileName: PropTypes.string,
  style: PropTypes.object,
};

export default PrintApprovedSp;
