import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Warning } from '../../../../../components';
import { adminApi } from '../../../../../services/admin';
import { PERMISSION, USER_ID } from '../../../../../utils/constants';
import { WrapBtn } from '../styled';
import styles from './form-input.module.scss';

function userInfoRender(user) {
  return {
    fullName: user.birth_name,
    businessUnit: user.business_unit,
    jobFamily: user.job_family,
    positionName: user.position_name,
    staffId: user.staff_id,
    company: user.opu_name,
    department: user.department_name,
    division: user.division,
    section: user.section,
  };
}
export function FormInput(props) {
  const {
    userInfo,
    setUserInfo,
    isValid,
    setIsValid,
    inputEmail,
    setInputEmail,
    userId,
    setSelected,
    setMessageError,
    messageError,
  } = props;
  const roleActive = useSelector((state) => state.user.roleActive);

  const onSearchUser = async () => {
    setIsValid(true);
    setSelected([]);
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&' * +/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!inputEmail.trim()) {
      setMessageError((prev) => {
        return { ...prev, status: true, email: 'Email is required' };
      });
      setInputEmail('');
      setUserInfo({});
    } else if (inputEmail.match(validEmailRegex)) {
      setMessageError({
        status: false,
        message: '',
      });
      try {
        const roles = {
          role: roleActive,
          id: USER_ID,
          permission: PERMISSION.SEARCH_USER_BY_EMAIL,
        };
        const res = await adminApi.getUserByEmail(roles, inputEmail);
        if (res.status === 200) {
          if (isEmpty(res.data.result)) {
            setMessageError((prev) => {
              return { ...prev, status: true, email: 'Email is not existed' };
            });
            setUserInfo({});
          } else {
            setUserInfo(userInfoRender(res.data.result));
            setMessageError((prev) => {
              return { ...prev, status: false, email: '' };
            });
          }
        }
      } catch (error) {
        setUserInfo({});
      }
    } else {
      setMessageError((prev) => {
        return { ...prev, status: true, email: 'Invalid email address' };
      });
      setUserInfo({});
    }
  };

  const onResetUser = () => {
    setInputEmail('');
    setUserInfo({});
    setIsValid(true);
    setSelected([]);
    setMessageError({
      status: false,
      message: '',
    });
  };

  return (
    <>
      <div className="mb-2">
        <div className="row">
          <div className="col-10">
            <div className={styles.label_input}>
              {!userId ? (
                <>
                  Email<span style={{ color: 'red' }}>*</span>
                </>
              ) : (
                'Email'
              )}
            </div>

            {!userId ? (
              <input
                type="text"
                aria-label="email"
                className={`${styles.input} ${
                  (messageError.status || (!isValid && !userInfo.email)) && styles.input_error
                }`}
                value={inputEmail}
                placeholder="Users Email"
                onChange={(event) => setInputEmail(event.target.value)}
                data-testid="email-input"
              />
            ) : (
              <input
                type="text"
                aria-label="email"
                className={`${styles.input} ${messageError.status && styles.input_error}`}
                value={inputEmail}
                placeholder="Users Email"
                data-testid="email-input-with-user-id"
                disabled={true}
              />
            )}
          </div>

          <div className="col-2 d-flex align-items-end">
            {!userId && (
              <div className="d-flex" style={{ gap: '8px' }}>
                <WrapBtn
                  color="#FFFFFF"
                  bg="#00A19C"
                  onClick={onSearchUser}
                  data-testid="button-search"
                >
                  Search
                </WrapBtn>

                <WrapBtn
                  color="#00A19C"
                  bg="#FFFFFF"
                  border="1px solid #00A19C"
                  onClick={onResetUser}
                  data-testid="btn-reset"
                >
                  Reset
                </WrapBtn>
              </div>
            )}
          </div>
        </div>
        {messageError.status && <Warning>{messageError.email}</Warning>}
      </div>

      <div className="mb-5">
        <div className="row">
          <div className="col-10">
            <div className="row" style={{ rowGap: '10px' }}>
              <div className="col-6">
                <div className={styles.label_input}>Full Name</div>
                <input
                  type="text"
                  aria-label="fullName"
                  className={`${styles.input} ${
                    !isValid && !userInfo.fullName && styles.input_error
                  }`}
                  value={userInfo.fullName || ''}
                  placeholder="Full Name"
                  disabled={true}
                />
                {!isValid && !userInfo.fullName && <Warning>Full Name is required</Warning>}
              </div>

              <div className="col-6">
                <div className={styles.label_input}>Position Name</div>
                <input
                  type="text"
                  aria-label="positionName"
                  className={styles.input}
                  value={userInfo.positionName || ''}
                  placeholder="Position Name"
                  disabled={true}
                />
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-4">
                    <div className={styles.label_input}>Staff ID</div>
                    <input
                      type="text"
                      aria-label="staffId"
                      className={`${styles.input} ${
                        !isValid && !userInfo.staffId && styles.input_error
                      }`}
                      value={userInfo.staffId || ''}
                      placeholder="Staff ID"
                      disabled={true}
                    />
                    {!isValid && !userInfo.staffId && <Warning>Staff Id is required</Warning>}
                  </div>

                  <div className="col-8">
                    <div className={styles.label_input}>Company</div>
                    <input
                      type="text"
                      aria-label="company"
                      className={styles.input}
                      value={userInfo.company || ''}
                      placeholder="Company"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className={styles.label_input}>Department</div>
                <input
                  type="text"
                  aria-label="department"
                  className={styles.input}
                  value={userInfo.department || ''}
                  placeholder="Department"
                  disabled={true}
                />
              </div>

              <div className="col-6">
                <div className={styles.label_input}>Division</div>
                <input
                  type="text"
                  aria-label="division"
                  className={styles.input}
                  value={userInfo.division || ''}
                  placeholder="Division"
                  disabled={true}
                />
              </div>

              <div className="col-6">
                <div className={styles.label_input}>Section</div>
                <input
                  type="text"
                  aria-label="section"
                  className={styles.input}
                  value={userInfo.section || ''}
                  placeholder="Section"
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="col-2"></div>
        </div>
      </div>
    </>
  );
}
