import React, { useEffect, useState } from 'react';
import { stylesDanger, stylesDisabled, stylesNormal, theme } from './Styled';
import { arrow_dropdown_filter } from '../../../assets/img';
import ReactSelect from 'react-select';

const SingleSelect = ({ options, placeholder, onChange, value, status, isDisabled, className }) => {
  const [currentStatus, setCurrentStatus] = useState(stylesNormal);
  useEffect(() => {
    setCurrentStatus(status === 'error' ? stylesDanger : stylesNormal);
  }, [status]);
  return (
    <ReactSelect
      isDisabled={isDisabled}
      isSearchable
      placeholder={placeholder}
      styles={isDisabled ? stylesDisabled : currentStatus}
      theme={theme}
      options={options}
      onChange={onChange}
      value={value}
      menuPlacement="auto"
      maxMenuHeight={180}
      components={{
        DropdownIndicator: () => (
          <img
            src={arrow_dropdown_filter}
            alt={'arrow_dropdown_filter'}
            style={{ marginRight: '10px' }}
          />
        ),
        IndicatorSeparator: () => null,
      }}
      className={className}
    />
  );
};
export default SingleSelect;
