import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FilterMultiple } from '../../../../../components';
import {
  addFilterListByUser,
  editRemark,
  addOneFilterRemark,
  addFilterRemark,
  deleteRemark,
  saveRemarks,
} from '../../../redux/remarksSlice';
import { remarksRemainingSelector, remarksSelector } from '../../../selector';
import styles from './remark-table-dialog.module.scss';
import RemarkTableBody from './RemarkTableBody';

const RemarkTableDialog = (props) => {
  const { isRemark, setIsRemark, isViewModeAgenda, agendaDetail } = props;
  const [listSelectName, setListSelectName] = useState([]);
  const [listSelectCategory, setListSelectCategory] = useState([]);
  const dispatch = useDispatch();
  const { filterByName, filterByCategory, loading } = useSelector(remarksSelector);
  const remarks = useSelector(remarksRemainingSelector);
  const [talentRemark, setTalentRemark] = useState([]);
  const { agendaId } = useParams();

  useEffect(() => {
    const newData = remarks.filter((item) => item.remarksCategory);
    setTalentRemark(newData);
  }, [remarks]);

  useEffect(() => {
    if (!isRemark) return;
    const filterList = {
      listSelectName,
      listSelectCategory,
    };
    dispatch(addFilterListByUser({ filterList }));
  }, [isRemark, listSelectName, listSelectCategory, dispatch]);

  const onDeleteItem = (remarkId) => {
    //delete to push into remark list for delete
    dispatch(
      saveRemarks({
        agendaId: agendaDetail?.agenda_id || agendaId,
        remarkId: remarkId,
        type: 'delete',
      })
    );

    //delete to display
    dispatch(
      deleteRemark({
        remarkId,
      })
    );
    dispatch(addFilterRemark());
    setIsRemark(false);
  };

  const onSaveItem = (data) => {
    //edit to push into remark list for edit
    dispatch(
      saveRemarks({
        agendaId: agendaDetail?.agenda_id || agendaId,
        remarkId: data.remarkId,
        body: { category: data.category, remarks: data.remarks },
        type: 'edit',
      })
    );

    //edit to display
    dispatch(
      editRemark({
        remarkId: data.remarkId,
        category: data.category,
        remarks: data.remarks,
      })
    );
    dispatch(addOneFilterRemark());
  };

  return (
    <div>
      <Modal
        show={isRemark}
        onHide={() => setIsRemark(false)}
        dialogClassName={styles.remark}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
        centered
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <div className={styles.title} data-testid="modal-remark-title">
            Remark
          </div>
        </Modal.Header>

        <div className={styles.table}>
          <div className={styles.table_header}>
            <div style={{ width: '20%' }}>
              <div className="d-flex align-items-baseline" style={{ gap: '9px' }}>
                Name
                <FilterMultiple
                  listSelectType={listSelectName}
                  setListSelectType={setListSelectName}
                  filtersOption={filterByName}
                />
              </div>
            </div>
            <div style={{ width: '15%' }}>
              <div className="d-flex align-items-baseline" style={{ gap: '9px' }}>
                Category
                <FilterMultiple
                  listSelectType={listSelectCategory}
                  setListSelectType={setListSelectCategory}
                  filtersOption={filterByCategory}
                />
              </div>
            </div>
            <div style={{ width: '15%' }}>Date</div>
            <div style={{ width: '20%' }}>Meeting Name</div>
            <div style={{ width: '30%' }}>Remarks</div>
          </div>

          <Modal.Body className={styles.modal_body}>
            <div data-testid="render-remark">
              {!loading &&
                talentRemark?.map((remark, idx) => (
                  <RemarkTableBody
                    item={remark}
                    key={remark.remarkId}
                    index={idx}
                    length={remarks.length}
                    onDeleteItem={onDeleteItem}
                    onSaveItem={onSaveItem}
                    isViewModeAgenda={isViewModeAgenda}
                  />
                ))}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default memo(RemarkTableDialog);
