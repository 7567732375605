import { Modal } from 'antd';
import styled, { css } from 'styled-components';
import { font } from '../../assets/common';

export const AiModal = styled(Modal)`
  font-family: ${font.inter};
  ${({ styles }) => {
    return css`
      .ant-modal-content {
        border-radius: ${styles.borderRadius}px;
      }

      .ant-modal-close {
        position: absolute;
        ${typeof styles.closePosition === 'number'
          ? `right: ${styles.closePosition}px;
        top: ${styles.closePosition}px;`
          : `right: ${styles.closePosition[0]}px;
          top: ${styles.closePosition[1]}px;`}

        &:hover {
          opacity: 0.75;
        }

        .ant-modal-close-x {
          width: fit-content;
          height: fit-content;
          line-height: 0;
        }
      }

      .ant-modal-header {
        padding: ${styles.containerPaddingTopBottom}px ${styles.containerPaddingLeftRight}px;
        padding-bottom: ${styles.headerPaddingBottom}px;
        border-bottom: ${styles.headerBorderBottom};
        border-radius: ${styles.borderRadius}px ${styles.borderRadius}px 0 0;

        .ant-modal-title {
          font-size: ${styles.titleFontSize}px;
          font-weight: ${styles.titleFontWeight};
          color: ${styles.titleColor};
        }
      }
      .ant-modal-body {
        padding: ${styles.containerPaddingTopBottom}px ${styles.containerPaddingLeftRight}px;
        padding-top: 0;
      }
    `;
  }}
`;
