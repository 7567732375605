import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { SG_JG_MAPPING_MESSAGE } from '../../../../../utils/constants';

export const formMappingvalidationSchema = yup.object({
  role_level: yup.object().required(true),
  sg_in_oracle: yup
    .string()
    .required(true)
    .matches(/^(?!.*[a-z])/, {
      message: SG_JG_MAPPING_MESSAGE.SG_JG_UPPERCASE,
    }),
  jg_in_oracle: yup
    .string()
    .required(true)
    .test(
      'is-start-capital',
      `${SG_JG_MAPPING_MESSAGE.FIRST_CHARACTER_MUST_BE_UPPERCASE}`,
      (word) => {
        return !isEmpty(word) ? /[A-Z0-9]/.test(word.charAt(0)) : false;
      }
    ),
  jg_in_p_series: yup.object().required(true),
});

export const handleValidateJGInOracleDuplication = (formValue, fieldValue, mappingData) => {
  if (
    isEmpty(formValue.role_level) ||
    isEmpty(formValue.sg_in_oracle) ||
    isEmpty(formValue.jg_in_oracle) ||
    isEmpty(formValue.jg_in_p_series) ||
    isEmpty(mappingData)
  ) {
    return false;
  }

  const tempArr = mappingData.filter(
    (item) =>
      item?.jg_in_oracle === fieldValue &&
      item?.sg_in_oracle === formValue.sg_in_oracle &&
      item?.jg_in_p_series === formValue.jg_in_p_series.id &&
      item?.role_level === formValue.role_level.id
  );
  return tempArr.length;
};

export const handleValidateJGInOracleDistinction = (formValue, fieldValue, mappingData, isEdit) => {
  if (
    isEmpty(formValue.role_level) ||
    isEmpty(formValue.jg_in_oracle) ||
    isEmpty(formValue.jg_in_p_series) ||
    isEmpty(mappingData)
  ) {
    return false;
  }

  let tempArr = [];
  if (!isEdit) {
    tempArr = mappingData.filter(
      (item) =>
        item?.jg_in_oracle === fieldValue &&
        (item?.jg_in_p_series !== formValue.jg_in_p_series.id ||
          item?.role_level !== formValue.role_level.id)
    );
  } else {
    tempArr = mappingData.filter(
      (item) =>
        item?.jg_in_oracle === fieldValue &&
        ((item?.jg_in_p_series === formValue.jg_in_p_series.id &&
          item?.role_level === formValue.role_level.id &&
          item?.jg_in_p_series === formValue.jg_in_p_series.id &&
          item?.role_level !== formValue.role_level.id) ||
          (item?.jg_in_p_series !== formValue.jg_in_p_series.id &&
            item?.role_level === formValue.role_level.id))
    );
  }
  return tempArr.length;
};

export const handleValidateJGInPSeries = (formValue, fieldValue, mappingData) => {
  if (isEmpty(mappingData) || isEmpty(fieldValue) || isEmpty(formValue.role_level)) {
    return false;
  }

  const tempArr = mappingData.filter(
    (item) => item?.jg_in_p_series === fieldValue && formValue.role_level.id !== item?.role_level
  );
  return tempArr.length;
};

export const handleCheckEmptyValue = (values) => {
  let isCheck = false;
  Object.keys(values).forEach((key) => {
    if (isEmpty(values[key])) {
      isCheck = true;
      return true;
    }
  });
  return isCheck;
};
