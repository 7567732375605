import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { AGENDA_STATUS, MEETING_DETAIL_STATUS } from '../utils/constants';

export default function useConditionalMsRemark(props) {
  const { meetingRole, meetingStatus, agendaStatus } = props;

  const [showBtnAllRemarks, setShowBtnAllRemarks] = useState(false);
  const [isCreateOrEditRemarks, setIsCreateOrEditRemarks] = useState(false);

  useEffect(() => {
    if (!(!isEmpty(meetingRole) && meetingStatus && agendaStatus)) return;

    if (
      meetingRole.is_secretariat_or_facilitator &&
      [MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.IN_PROGRESS].includes(
        meetingStatus
      ) &&
      agendaStatus === AGENDA_STATUS.PAPER_READY
    ) {
      setShowBtnAllRemarks(true);
      setIsCreateOrEditRemarks(true);
      return;
    }

    if (
      meetingRole.is_secretariat_or_facilitator &&
      meetingStatus === MEETING_DETAIL_STATUS.IN_PROGRESS &&
      agendaStatus === AGENDA_STATUS.PAPER_FINALIZED
    ) {
      setShowBtnAllRemarks(true);
      setIsCreateOrEditRemarks(false);
      return;
    }

    if (
      meetingRole.is_secretariat_or_facilitator &&
      meetingStatus === MEETING_DETAIL_STATUS.IN_REVIEW &&
      agendaStatus === AGENDA_STATUS.PAPER_FINALIZED
    ) {
      setShowBtnAllRemarks(true);
      setIsCreateOrEditRemarks(true);
      return;
    }

    if (
      (meetingRole.is_advocator ||
        meetingRole.is_commitee ||
        meetingRole.is_chairman ||
        meetingRole.is_hr_partners) &&
      meetingStatus === MEETING_DETAIL_STATUS.IN_REVIEW &&
      agendaStatus === AGENDA_STATUS.PAPER_FINALIZED
    ) {
      setShowBtnAllRemarks(true);
      setIsCreateOrEditRemarks(false);
      return;
    }

    if (
      (meetingRole.is_advocator ||
        meetingRole.is_commitee ||
        meetingRole.is_chairman ||
        meetingRole.is_hr_partners ||
        meetingRole.is_secretariat_or_facilitator) &&
      meetingStatus === MEETING_DETAIL_STATUS.CONCLUDED
    ) {
      setShowBtnAllRemarks(true);
      setIsCreateOrEditRemarks(false);
    }
  }, [meetingRole, meetingStatus, agendaStatus]);

  return { showBtnAllRemarks, isCreateOrEditRemarks };
}
