import { invoke } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { meetingApi } from '../services/tcMeeting';

export const useUpdateStatusAgenda = (options) => {
  const optionsRef = useRef(options);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    optionsRef.current = options;
  });

  const handleOnSuccess = useCallback((response) => {
    invoke(optionsRef.current, 'onSuccess', response.data);
    setLoading(false);
  }, []);

  const handleOnError = useCallback((error) => {
    invoke(optionsRef.current, 'onError', error);
    setError(error);
    setLoading(false);
  }, []);

  const updateStatusAgenda = useCallback(
    async ({ agendaId, status }) => {
      try {
        setError(null);
        setLoading(true);
        const response = await meetingApi.updateStatusAgenda({ agendaId, status });
        handleOnSuccess(response);
        return response.data;
      } catch (err) {
        handleOnError(err);
        return {};
      }
    },
    [handleOnError, handleOnSuccess]
  );
  
  const finalizeSPAgenda = useCallback(
    async (agendaId) => {
      try {
        setError(null);
        setLoading(true);
        const response = await meetingApi.finalizeSPAgenda(agendaId);
        handleOnSuccess(response);
        return response.data;
      } catch (err) {
        handleOnError(err);
        return {};
      }
    },
    [handleOnError, handleOnSuccess]
  );
  
  const unfinalizeSPAgenda = useCallback(
    async (agendaId) => {
      try {
        setError(null);
        setLoading(true);
        const response = await meetingApi.unfinalizeSPAgenda(agendaId);
        handleOnSuccess(response);
        return response.data;
      } catch (err) {
        handleOnError(err);
        return {};
      }
    },
    [handleOnError, handleOnSuccess]
  );

  return { isLoading, error, updateStatusAgenda, finalizeSPAgenda, unfinalizeSPAgenda };
};
