import { Col, Row, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-data-export';

import { AiButton, BasicButton, BasicLoading } from '../../../../../assets/common';
import { commonTooltipStyles } from '../../../../../assets/common/tooltipStyles';
import { finder_filter_disable, finder_filter_enable } from '../../../../../assets/img';
import { CommonFilterManaging } from '../../../../../components/CommonFilterManaging';
import { useGetFilterOptions } from '../../../../../hooks/useGetProfileManagement';
import { adminApi } from '../../../../../services/admin';
import { SELECT_ALL_LABEL } from '../../../../../utils/constants';
import AddTalentForm from '../../components/AddTalentForm/AddTalentForm';
import { ADD_TALENT_FORM_TYPE, LIST_OF_PROFILES_EXPORT_COLUMNS, LIST_OF_PROFILES_EXPORT_FILE_NAME, SEARCH_INPUT_PLACEHOLDER } from '../../constant';
import styles from './menu-bar-mapping.module.scss';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../../../../hooks/useShowAlertWhenRefresh';

export default function MenuBarManaging(props) {
  const { deleteSelecteds, setShowDeleteModal, totalData, fetchData, searchParams, setSearchParams } = props;
  const { keyword, roleLevelSelected, businessSelected } = searchParams;
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const [searchInput, setSearchInput] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [openAddTalent, setOpenAddTalent] = useState(false);
  const [data, setData] = useState([]);
  const [isExport, setIsExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate();
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();

  const { filterOptions } = useGetFilterOptions();

  const handleClickAddNew = () => {
    setOpenAddTalent(true);
  };

  const handleClickFilter = () => {
    if (showFilter && roleLevelSelected.length) {
      setSearchParams((prev) => ({
        ...prev,
        roleLevelSelected: [],
      }));
    }
    if (showFilter && businessSelected.length) {
      setSearchParams((prev) => ({
        ...prev,
        businessSelected: [],
      }));
    }
    setShowFilter(!showFilter);
  };

  const generateDataSet = (data) => {
    if (!(data || data?.length)) return;
    const dataExport = data.map((item, i) => {
      return [
        { value: item?.staff_name || '' },
        { value: item?.staff_id || '' },
        { value: item?.business_unit || '' },
        { value: item?.role_level || '' },
      ];
    });
    return [
      {
        columns: [...LIST_OF_PROFILES_EXPORT_COLUMNS],
        data: [...dataExport],
      },
    ];
  };

  const handleOnExport = async () => {
    setIsLoading(true);
    setIsUploadingOrDownloading(true);
    setShowAlertRefresh(true);
    try {
      const query = {
        keyword,
        role_level: !isEmpty(roleLevelSelected)
          ? roleLevelSelected
              .filter((item) => item?.label !== SELECT_ALL_LABEL)
              .map((item) => {
                return item?.value || item?.label;
              })
          : [],
        business: !isEmpty(businessSelected)
          ? businessSelected
              .filter((item) => item?.label !== SELECT_ALL_LABEL)
              .map((item) => {
                return item?.value || item?.label;
              })
          : [],
      };
      const res = await adminApi.exportFromListOfProfiles(query);
      if (res.status === 200) {
        const talents = res.data.result || [{}];
        setData(talents);
        setIsExport(true);
        setTimeout(() => {
          setIsExport(false);
        }, 300);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploadingOrDownloading(false);
      setShowAlertRefresh(false);
      setIsLoading(false);
    }
  };

  const setSelectedOptions = (selectedOption, type) => {
    setSearchParams((prev) => ({
      ...prev,
      [type]: selectedOption,
    }));
  };

  useEffect(() => {
    setSearchInput(keyword);
  }, [keyword]);

  return (
    <div>
      <Row align="bottom" justify="space-between">
        <Col span={16}>
          <Row align="bottom" gutter={8}>
            <Col span={18}>
              <input
                data-testid="search-input"
                placeholder={SEARCH_INPUT_PLACEHOLDER}
                className={styles.searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </Col>
            <Col>
              <BasicButton
                mode="teal"
                onKeyDown={() => {}}
                onClick={() => {
                  setSearchParams((prev) => ({
                    ...prev,
                    keyword: searchInput,
                    page: 1,
                  }));
                }}
              >
                Search
              </BasicButton>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="bottom" justify="space-between" gutter={8}>
            <Col>
              <BasicButton
                onKeyDown={() => {}}
                onClick={() => deleteSelecteds?.length > 0 && setShowDeleteModal(true)}
                className={`${styles.button} ${!deleteSelecteds?.length ? styles.inActiveDelBtn : styles.activeDelBtn}`}
                disabled={!deleteSelecteds?.length}
              >
                Confirm delete Talent
              </BasicButton>
            </Col>
            <Col>
              <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickAddNew}>
                Add Talent
              </BasicButton>
            </Col>
            <Col>
              {isLoading || !totalData ? (
                <Tooltip title="No record to export" {...commonTooltipStyles}>
                  <AiButton mode="teal" onKeyDown={() => {}} onClick={handleOnExport} disabled={isLoading || !totalData}>
                    Export <BasicLoading spinning={isLoading} pxsize={12} inline="true" />
                  </AiButton>
                </Tooltip>
              ) : (
                <AiButton mode="teal" onKeyDown={() => {}} onClick={handleOnExport} disabled={isLoading || !totalData}>
                  Export <BasicLoading spinning={isLoading} pxsize={12} inline="true" />
                </AiButton>
              )}

              {isExport && (
                <ExcelFile hideElement={true} filename={LIST_OF_PROFILES_EXPORT_FILE_NAME}>
                  <ExcelSheet dataSet={generateDataSet(data)} name="Sheet1" />
                </ExcelFile>
              )}
            </Col>
            <Col>
              <div className={styles.icons}>
                <img src={showFilter ? finder_filter_enable : finder_filter_disable} alt="Filter" onKeyDown={() => {}} onClick={handleClickFilter} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilter && (
        <Row style={{ marginTop: 22 }}>
          <Col>
            <Row gutter={16}>
              <Col>
                <CommonFilterManaging
                  selectedOptions={roleLevelSelected}
                  setSelectedOptions={(state) => {
                    setSelectedOptions(state, 'roleLevelSelected');
                  }}
                  allOptions={[
                    {
                      label: '(Select All)',
                      value: 'all',
                    },
                    ...(filterOptions?.role_level || []),
                  ]}
                  placeholder="Role Level"
                />
              </Col>
              <Col>
                <CommonFilterManaging
                  selectedOptions={businessSelected}
                  setSelectedOptions={(state) => {
                    setSelectedOptions(state, 'businessSelected');
                  }}
                  allOptions={[
                    {
                      label: '(Select All)',
                      value: 'all',
                    },
                    ...(filterOptions?.business_unit || []),
                  ]}
                  placeholder="Business"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <AddTalentForm open={openAddTalent} setOpen={setOpenAddTalent} type={ADD_TALENT_FORM_TYPE.LIST_PROFILES} fetchData={fetchData} />
    </div>
  );
}
