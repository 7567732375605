import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search_data_not_found } from '../../../../assets/img';
import { SearchDataTableNotFound } from '../../../../components';
import { masterDataManagementApi } from '../../../../services/masterDataManagementApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { BU_MAPPING_MESSAGE, CONTENT_SEARCH_NOT_FOUND, MAPPING_DATA_TYPES, MESSAGE_TYPES } from '../../../../utils/constants';
import { getMappingData } from '../../../../utils/helper';
import DataTableManaging from './DataTableManaging/DataTableManaging';
import fetch_filter_option from './MenuBarMapping/fetch-filter-option.json';
import MenuBarMapping from './MenuBarMapping/MenuBarMapping';

const INIT_SEARCH_PARAMS = {
  keyword: '',
  dataFieldSelected: [],
  dataContentSelected: [],
};

export default function MappingBuOpuDevisionList(props) {
  const { setMessage } = props;
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);

  // Use state
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteSelecteds, setDeleteSelecteds] = useState([]);
  const [seletedRows, setSeletedRows] = useState([]);
  const [updateStatusVisibleDeleteMultiple, setUpdateStatusVisibleDeleteMultiple] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const limit = 15;
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRecordEdit, setSelectedRecordEdit] = useState(null);
  const [filterOptions, setFilterOptions] = useState(fetch_filter_option);
  const [isApply, setIsApply] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const fetchDataContentFilterOptions = useCallback(
    async (dataField) => {
      try {
        const res = await masterDataManagementApi.getBuMappingFilters(dataField, roleActive?.roleId);
        if (res.status === 200 && res?.data?.result) {
          setFilterOptions((prevState) => {
            return {
              ...prevState,
              data_content: res.data.result,
            };
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [roleActive]
  );

  useEffect(() => {
    if (!isApply) return;
    const dataFieldSelected = searchParams.dataFieldSelected;

    if (!isEmpty(dataFieldSelected)) {
      const fields = dataFieldSelected.map((item) => {
        return item.value;
      });
      fetchDataContentFilterOptions(fields);
    } else {
      fetchDataContentFilterOptions([]);
      setSearchParams((prev) => ({
        ...prev,
        dataFieldSelected: [],
      }));
    }
  }, [searchParams.dataFieldSelected, isApply, fetchDataContentFilterOptions]);

  const fetchData = useCallback(async () => {
    const { keyword, dataFieldSelected, dataContentSelected } = searchParams;
    try {
      setLoading(true);
      const body = {
        keyword,
        fields: !isEmpty(dataFieldSelected)
          ? dataFieldSelected
              .filter((item) => item.value !== 'all')
              .map((item) => {
                return item.value;
              })
          : [],
        contents: !isEmpty(dataContentSelected)
          ? dataContentSelected
              .filter((item) => item.value !== 'all')
              .map((item) => {
                return item.value;
              })
          : [],
      };
      const res = await masterDataManagementApi.getBuMapping(body, roleActive?.roleId);
      if (res.status === 200 && !isEmpty(res.data.result)) {
        let tempRoleLevels = {};
        res.data.result.forEach((item) => {
          tempRoleLevels[`${item.business_unit}`] = [];
        });
        const tempData = getMappingData(tempRoleLevels, res.data.result, MAPPING_DATA_TYPES.BU_MAPPING);
        setData(tempData);
        setTotalData(res.data.result[0].total);
      } else {
        handleResetData();
        setMessage({
          isOpen: true,
          isError: true,
          content: BU_MAPPING_MESSAGE.SEARCH_NOT_FOUND,
        });
      }
    } catch (error) {
      handleResetData();
      console.error(error);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, roleActive, setData, setTotalData]);

  const handleResetData = () => {
    setData([]);
    setTotalData(0);
    setLoading(false);
  };

  const handleDeleteRecord = useCallback(async () => {
    try {
      if (!isEmpty(deleteRecord) && !updateStatusVisibleDeleteMultiple) {
        setLoading(true);
        const res = await masterDataManagementApi.deleteBuMapping([deleteRecord.id], roleActive?.roleId);
        if (res.status === 200) {
          setMessage({
            isOpen: true,
            isError: false,
            content: BU_MAPPING_MESSAGE.RECORD_DELETE_SUCCESS,
          });
          setDeleteRecord(null);
          if (data.length === 1) {
            setSearchParams(INIT_SEARCH_PARAMS);
            setIsClear(true);
          } else {
            await fetchData();
          }
        }
      } else {
        setLoading(true);
        const res = await masterDataManagementApi.deleteBuMapping(deleteSelecteds, roleActive?.roleId);
        if (res.status === 200) {
          setMessage({
            isOpen: true,
            isError: false,
            content: BU_MAPPING_MESSAGE.RECORD_DELETE_SUCCESS,
          });
          setDeleteSelecteds([]);
          setSeletedRows([]);
          if (data.length === deleteSelecteds.length) {
            setSearchParams(INIT_SEARCH_PARAMS);
            setIsClear(true);
          } else {
            await fetchData();
          }
        }
      }
    } catch (error) {
      console.error(error);
      handleErrorMsg(error?.response?.data);
    }
    setUpdateStatusVisibleDeleteMultiple(false);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, deleteRecord, deleteSelecteds, fetchData, setMessage]);

  // Get data to view
  useEffect(() => {
    const { keyword, dataFieldSelected, dataContentSelected } = searchParams;
    if (keyword || !showFilter || isClear || (showFilter && isApply && (dataFieldSelected || dataContentSelected))) {
      fetchData();
      setIsApply(false);
      setIsClear(false);
    }
    setLoading(false);
  }, [searchParams, isApply, showFilter, isClear, fetchData]);

  useEffect(() => {
    if (!deleteRecord) return;
    handleDeleteRecord();
  }, [deleteRecord, handleDeleteRecord]);

  const hancleEditRecord = useCallback(async () => {
    try {
      if (isEmpty(selectedRecordEdit)) return;

      const res = await masterDataManagementApi.updateBuMappingById(selectedRecordEdit, roleActive?.roleId);
      if (res.status === 200) {
        setMessage({
          isOpen: true,
          isError: false,
          content: BU_MAPPING_MESSAGE.BU_UPDATE_SUCCESS,
        });
        setSelectedRecordEdit(null);
        setTimeout(() => {
          fetchData();
        });
      }
    } catch (error) {
      console.error(error);
      const dataError = error?.response?.data;
      if (dataError?.code === 500 && dataError?.errors.includes(BU_MAPPING_MESSAGE.MAPPING_ALREADY_EXIST)) {
        handleShowMessage(MESSAGE_TYPES.WARNING, BU_MAPPING_MESSAGE.MAPPING_ALREADY_EXIST);
      } else if (dataError?.errors.includes(BU_MAPPING_MESSAGE.FINANCE_BUSINESS_UNIT_IN_USE.TITLE)) {
        handleShowMessage(MESSAGE_TYPES.WARNING, dataError?.errors);
      } else if (dataError?.errors === BU_MAPPING_MESSAGE.DATA_ORACLE_EXISTED) {
        handleShowMessage(MESSAGE_TYPES.ERROR, BU_MAPPING_MESSAGE.DATA_ORACLE_EXISTED);
      } else {
        handleShowMessage(MESSAGE_TYPES.ERROR, BU_MAPPING_MESSAGE.AN_UNEXPECTED_ERROR);
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordEdit]);

  const handleShowMessage = (type, message) => {
    dispatch(
      pushMessage({
        type,
        message,
        timeShow: 3000,
        isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
      })
    );
  };

  const handleErrorMsg = (data) => {
    setMessage({
      isOpen: true,
      isError: true,
      content: data?.code === 500 && data?.errors ? data?.errors : BU_MAPPING_MESSAGE.AN_UNEXPECTED_ERROR,
    });
  };

  useEffect(() => {
    if (!selectedRecordEdit) return;
    hancleEditRecord();
  }, [selectedRecordEdit, hancleEditRecord]);

  return (
    <Spin spinning={loading} size="large">
      <MenuBarMapping
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        fetchData={fetchData}
        setDeleteSelecteds={setDeleteSelecteds}
        deleteSelecteds={deleteSelecteds}
        updateStatusVisible={updateStatusVisibleDeleteMultiple}
        setUpdateStatusVisible={setUpdateStatusVisibleDeleteMultiple}
        handleDeleteRecord={handleDeleteRecord}
        loading={loading}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        data={data}
        filterOptions={filterOptions}
        setIsApply={setIsApply}
        setIsClear={setIsClear}
      />
      {!isEmpty(data) ? (
        <DataTableManaging
          data={data}
          setDeleteRecord={setDeleteRecord}
          setDeleteSelecteds={setDeleteSelecteds}
          setSeletedRows={setSeletedRows}
          seletedRows={seletedRows}
          handleDeleteRecord={handleDeleteRecord}
          totalData={totalData}
          limit={limit}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          setSelectedRecordEdit={setSelectedRecordEdit}
          filterOptions={filterOptions}
        />
      ) : (
        <SearchDataTableNotFound content={CONTENT_SEARCH_NOT_FOUND.MAPPING_BU_OPU_DIVISION} notFoundIcon={search_data_not_found} />
      )}
    </Spin>
  );
}
