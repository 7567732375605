import { Dropdown, Space } from 'antd';
import React from 'react';

import { deleteNote, edit_delete, editNote } from '../../assets/img';
import * as styles from './more3-dot-dropdown.module.scss';

const More3DotDropdown = ({ item, handleClickEdit, handleClickDelete, editable }) => {
  return (
    <>
      {(editable === undefined ? true : editable) && (
        <Dropdown
          menu={{
            items: [
              {
                icon: <img src={editNote} alt="edit" />,
                label: <div className={styles.editButton}>Edit</div>,
                key: '0',
                onClick: () => handleClickEdit(item),
              },
              {
                icon: <img src={deleteNote} alt="delete" />,
                label: <div className={styles.deleteButton}>Delete</div>,
                key: '1',
                onClick: () => handleClickDelete(item),
              },
            ],
          }}
          trigger={['click']}
          style={{ minWidth: 110 }}
          placement="bottomRight"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <Space style={{ cursor: 'pointer' }}>
            <img src={edit_delete} alt="dot" />
          </Space>
        </Dropdown>
      )}
    </>
  );
};

export default More3DotDropdown;
