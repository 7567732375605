import React from 'react';
import { arrow_forward, paperInProgress, paperReady } from '../../../../assets/img';
import { AGENDA_STATUS_IMG } from '../../../../utils/constants';
import { AiStatus } from './Styled';

const AgendaStatus = ({ status }) => {
  if (status === AGENDA_STATUS_IMG.READY) {
    return (
      <AiStatus status={status}>
        <img src={paperReady} alt="" /> Paper Ready
      </AiStatus>
    );
  }
  if (status === AGENDA_STATUS_IMG.PROGRESS) {
    return (
      <AiStatus status={status}>
        <img src={paperInProgress} alt="" />
        <span>Paper in Progress</span>
      </AiStatus>
    );
  }
  if (status === AGENDA_STATUS_IMG.FORWARD) {
    return (
      <AiStatus status={status}>
        <img src={arrow_forward} alt="" /> Paper bring to forward
      </AiStatus>
    );
  }
  if (status === AGENDA_STATUS_IMG.FINALIZED) {
    return (
      <AiStatus status={status}>
        <img src={paperReady} alt="" /> Paper Finalized
      </AiStatus>
    );
  }
  return <div>None</div>;
};
export default AgendaStatus;
