import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { BasicButton } from '../../assets/common';
import { close_custom_ant_modal, close_modal, close_modal_2 } from '../../assets/img';
import { AiModal } from './styled';

const initStyle = {
  borderRadius: 4,
  closePosition: 20,
  containerPaddingLeftRight: 30,
  containerPaddingTopBottom: 30,
  headerPaddingBottom: 20,
  headerBorderBottom: 'none',
  titleFontSize: 24,
  titleFontWeight: 700,
  titleColor: '#3F3C4C',
  submitBtnMode: 'teal',
};

const CustomAntModal = (props) => {
  const {
    setOpen,
    cancelText,
    okText,
    onCancel,
    onOk,
    styles,
    hideDefaultBtn,
    closeType,
    isEdit,
    onCancelModal,
    destroyOnClose,
    children,
    ...args
  } = props;

  const handleCancel = () => {
    if (isEdit) {
      onCancelModal();
    } else {
      if (onCancel) onCancel();
      if (setOpen) setOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (!onOk) return;
    await onOk();
  };

  const customCloseIcon = useMemo(() => {
    switch (closeType) {
      case 1:
        return <img src={close_modal_2} alt="close-modal" />;
      case 2:
        return <img src={close_modal} alt="close-modal" />;
      default:
        return (
          <img
            style={{ width: '15px', height: '15px' }}
            src={close_custom_ant_modal}
            alt="close-modal"
          />
        );
    }
  }, [closeType]);

  return (
    <AiModal
      {...args}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose={destroyOnClose !== undefined ? destroyOnClose : true}
      styles={{ ...initStyle, ...styles }}
      closeIcon={customCloseIcon}
    >
      {children}
      {!hideDefaultBtn && (
        <div className="d-flex justify-content-end">
          <BasicButton onClick={handleCancel} data-testid="cancel">
            {cancelText || 'Cancel'}
          </BasicButton>

          <BasicButton
            className="ml-3"
            mode={styles?.submitBtnMode || initStyle.submitBtnMode}
            onClick={handleSubmit}
            data-testid="submit"
          >
            {okText || 'Ok'}
          </BasicButton>
        </div>
      )}
    </AiModal>
  );
};

CustomAntModal.propTypes = {
  setOpen: PropTypes.func,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  styles: PropTypes.object,
  hideDefaultBtn: PropTypes.bool,
  closeType: PropTypes.number,
  isEdit: PropTypes.bool,
  onCancelModal: PropTypes.func,
  destroyOnClose: PropTypes.bool,
  children: PropTypes.node,
};

export default CustomAntModal;
